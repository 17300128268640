/* eslint-disable react/display-name */
import React, { useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import AssetInput from 'src/components/inputs/AssetInput';
import SimpleRichText from 'src/components/inputs/SimpleRichText';
import QuestionTypeIcon from 'src/components/shared/QuestionTypeIcon';
import IconButton from 'src/components/elements/IconButton';
import DetatchedScreenerModal from 'src/components/shared/DetatchedScreenerModal';
import { Tooltip } from 'react-tippy';
import './styles.scss';
import misc from 'src/utilities/misc';
import QuillMentionUtil from 'src/components/inputs/SimpleRichText/QuillMentionUtil';
import helpers from 'src/config/constants.js';
import { Iconof } from '@upsiide/ui-components';

const className = 'question-header';
const el = (name, mod) => cn(className, name, mod);

const QuestionHeader = React.forwardRef(
	(
		{
			study,
			question,
			section,
			sections,
			audience,
			language,
			studyQuestionNumber,
			assetId,
			onAssetChange,
			setNewValue,
			tempTemplate,
			setTempTemplate,
			blockUpdateOrBreakTemplate,
			onFocus,
			onBlur,
			newValue,
			updateQuestionLabel,
			updateCustomQualifierLabel,
			deleteQuestion,
			filterImportQuestions,
			focusOnFirstOption,
			updateScreeningQuestionLabel,
		},
		ref,
	) => {
		const [isQuestionLabelActive, setIsQuestionLabelActive] = useState(false);
		const [showScreenerDetachModal, setShowScreenerDetachModal] = useState(false);
		const [resetLabel, setResetLabel] = useState(false);
		const { formattingOptions } = helpers;
		const questionRef = useRef(null);

		const saveQuestionlabel = useCallback(
			content => {
				if (tempTemplate) {
					updateScreeningQuestionLabel(question.id, content, language);
				} else {
					let contentCopy = content;

					// If question label has piped question, label must be updated to remove quill-mention updates and acquire desired payload format or {{questionId:___}}
					const isPiped = content.includes('</span>');
					if (isPiped) {
						contentCopy = QuillMentionUtil.pipedQuestionLabelCleanup(contentCopy);
					}
					updateQuestionLabel(question.id, contentCopy, language, false);
				}
			},
			[language, question, tempTemplate, updateScreeningQuestionLabel, updateQuestionLabel],
		);

		const changeContent = useCallback(async () => {
			const newContent = newValue === '<p><br></p>' ? '' : newValue;
			if (question.label !== newContent) {
				saveQuestionlabel(newContent);
			}
		}, [newValue, question.label, saveQuestionlabel]);

		const getQuestionLabel = useMemo(() => {
			let questionLabel = '';
			if (question.translations && question.translations.length > 0) {
				const lang = typeof language === 'object' && language?.languageCode ? language?.languageCode : language;

				const currentQuestionTranslation = question.translations.find(
					translation => translation.languageCode === lang,
				);
				if (currentQuestionTranslation) {
					questionLabel = currentQuestionTranslation.label;
				}
			} else if (question.label !== 'Untitled') {
				questionLabel = question.label;
			}
			// Check if question label has piped question --> look for {{}} format
			if (QuillMentionUtil.isPipedQuestion(questionLabel)) {
				const pipableQuestions = QuillMentionUtil.getPipedQuestionsList(question, section, sections);
				questionLabel = QuillMentionUtil.filterPipedQuestionLabel(questionLabel, pipableQuestions);
			}

			return questionLabel;
		}, [language, question, section, sections]);

		const getPlaceholderText = useCallback(() => {
			const defaultLanguage = 'en';
			if (study) {
				const { defaultLanguage } = study;
			}

			if (question.translations) {
				// Look for default translation
				const defaultTranslation = question.translations.find(
					translation => translation.languageCode === defaultLanguage,
				);

				if (defaultTranslation && defaultTranslation.label) {
					let questionLabel = misc.stripHtmlTags(defaultTranslation.label);
					// Check if question label has piped question --> look for {{}} format
					if (QuillMentionUtil.isPipedQuestion(questionLabel)) {
						const pipableQuestions = QuillMentionUtil.getPipedQuestionsList(question, section, sections);
						questionLabel = QuillMentionUtil.filterPipedQuestionLabel(
							questionLabel,
							pipableQuestions,
							true,
						);
					}

					return questionLabel;
				}
			}

			if (audience) {
				// audience questions dont have piping
				return 'Type question here...';
			}
			return 'Type here or use @ for recall';
		}, [audience, question, section, sections, study]);

		const getDemographicGroup = id => {
			if (!audience?.demographicGroups?.length) return null;
			const demographic = audience.demographicGroups.find(group => group.id === id);
			// return demographic?.countryLanguage?.countryLabel;
			return misc.renderAudienceName(demographic);
		};

		const renderGroups = () => {
			const numberOfGroups =
				tempTemplate?.demographicGroups?.filter(group => question?.allowList?.includes(group?.id))?.length || 0;

			return (
				<div className={el('tag')}>
					{question?.libraryName ? <span className={el('tag_span')}>{question.libraryName}</span> : null}
					{numberOfGroups > 0 ? (
						<Tooltip
							id={`${className}-${question.id}`}
							animation="fade"
							trigger="mouseenter"
							html={
								<div className={el('tooltip_group')}>
									<span className={el('tag_group_tooltip_title')}>Shown in: </span>
									{question?.allowList?.map((id, i) => (
										<span key={`group-${i}`} className={el('tag_group_tooltip')}>
											{getDemographicGroup(id)}
										</span>
									))}
								</div>
							}
						>
							<span className={el('tag_group')}>
								Shown in:{' '}
								{numberOfGroups === audience.demographicGroups?.length ? 'All' : numberOfGroups} groups
							</span>
						</Tooltip>
					) : (
						<span className={`${el('tag_group')} ${el('no-group-tag')}`}>
							<Iconof icon="warning" className={el('warning-icon')} />
							No groups selected
						</span>
					)}
				</div>
			);
		};

		return (
			<div className={el('tag_container')}>
				{audience ? renderGroups() : null}
				<div className={className}>
					{!audience ? (
						<div className={el('drag')}>
							<Iconof className={el('drag-icon')} icon="drag" size="large" />
						</div>
					) : null}
					{audience ? null : <QuestionTypeIcon questionType={question.style} size="large" />}
					<div className={el('question-label')} ref={questionRef}>
						<span>{studyQuestionNumber}.</span>
						<SimpleRichText
							pipingDropdownStyle={{ top: '55px' }}
							ref={ref}
							onTab={v => {
								focusOnFirstOption(!v.shiftKey);
							}} // override default tabbing, move focus to first answer
							value={getQuestionLabel}
							onChange={setNewValue}
							placeholder={getPlaceholderText()}
							className={isQuestionLabelActive ? `active` : ''}
							onFocus={() => {
								if (tempTemplate)
									blockUpdateOrBreakTemplate(() => {
										setIsQuestionLabelActive(true);
										onFocus(question.id);
									});
								else {
									setIsQuestionLabelActive(true);
									onFocus(question.id);
								}
							}}
							onBlur={() => {
								setIsQuestionLabelActive(false);
								if (question?.parentId && question?.audienceCollectionId) {
									setShowScreenerDetachModal(true);
								} else {
									onBlur();
								}
								changeContent();
							}}
							question={question}
							section={section}
							sections={sections}
							language={language}
							audience={audience}
							toolbarType={formattingOptions.questionStatement}
							filterImportQuestions={filterImportQuestions}
							resetLabel={resetLabel}
						/>
					</div>
					{!filterImportQuestions && question?.style !== 'heatmap' ? (
						<div className={el('option-icons')}>
							<Tooltip
								id={`${className}-tooltip-${question.id}`}
								className={el('tooltip')}
								animation="shift"
								animationFill={false}
								trigger="mouseenter"
								theme="basic-text-tooltip"
								html={<div>{assetId ? 'Replace Media' : 'Add Media'}</div>}
							>
								<AssetInput
									value={assetId ? [assetId] : null}
									onChange={onAssetChange}
									blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
									option
									connectedToQuestionLibrary={question?.parentId}
								/>
							</Tooltip>
						</div>
					) : null}
					{filterImportQuestions && (
						<div className={el('filter-import-delete')}>
							<IconButton
								icon="delete_outlined"
								onClick={() => {
									deleteQuestion(question?.id);
								}}
								tooltip="Delete"
							/>
						</div>
					)}
				</div>
				<DetatchedScreenerModal
					show={showScreenerDetachModal}
					onClose={async () => {
						setShowScreenerDetachModal(false);
						setResetLabel(true);
						setTimeout(() => {
							setResetLabel(false);
						}, 500);
					}}
					onConfirm={() => {
						setResetLabel(false);
						onBlur();
						changeContent();
						setShowScreenerDetachModal(false);
					}}
				/>
			</div>
		);
	},
);

QuestionHeader.propTypes = {
	study: PropTypes.any,
	question: PropTypes.any,
	section: PropTypes.any,
	sections: PropTypes.any,
	audience: PropTypes.any,
	language: PropTypes.any,
	studyQuestionNumber: PropTypes.number,
	assetId: PropTypes.any,
	onAssetChange: PropTypes.func,
	setNewValue: PropTypes.func,
	tempTemplate: PropTypes.any,
	setTempTemplate: PropTypes.func,
	blockUpdateOrBreakTemplate: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	newValue: PropTypes.any,
	updateQuestionLabel: PropTypes.func,
	updateCustomQualifierLabel: PropTypes.func,
	deleteQuestion: PropTypes.func,
	focusOnFirstOption: PropTypes.func,
	filterImportQuestions: PropTypes.any,
	updateScreeningQuestionLabel: PropTypes.func,
};

export default QuestionHeader;
