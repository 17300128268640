import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { Iconof } from '@upsiide/ui-components';
import * as services from 'src/services';
import _ from 'src/utilities/lodashReplacer';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'age-range-input';
const el = (name, mod) => cn(className, name, mod);

const AgeRangeInput = ({
	data,
	onRemove,
	onEditAgeRangeValues,
	onCreateOption,
	ageRangeErrors,
	setAgeRangeErrors,
	getDemographicUuid,
	search,
}) => {
	const [localData, setLocalData] = useState(data);
	const { uuid, studyId } = getDemographicUuid();
	const minTimeoutRef = useRef();
	const maxTimeoutRef = useRef();
	const recentlyAddedRange = useRef();

	useEffect(() => {
		setLocalData(data.filter(item => item?.audienceQuestionOptionId));
		setAgeRangeErrors({});

		return () => {
			setLocalData(data.filter(item => item?.audienceQuestionOptionId));
			setAgeRangeErrors({});
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (localData?.length !== data?.length) setLocalData(data);
	}, [data, localData]);

	const rangesToDisplay = useMemo(() => {
		if (search) {
			return localData.filter(
				item =>
					item?.min?.toString()?.includes(search) ||
					item?.max?.toString()?.includes(search) ||
					item?.label?.includes(search),
			);
		}
		return localData;
	}, [localData, search]);

	const checkForErrors = useCallback(
		(rangeData, update = true) => {
			const error = {};
			for (let i = 0; i < rangeData?.length; i++) {
				if (
					`${rangeData[i]?.min}`?.includes('.') ||
					`${rangeData[i]?.max}`?.includes('.') ||
					rangeData[i]?.label?.includes('.') ||
					rangeData[i]?.value?.includes('.')
				) {
					error[i] = {
						text: 'Age must be a whole number',
						min: `${rangeData[i]?.min}`?.includes('.'),
						max: `${rangeData[i]?.max}`?.includes('.'),
					};
					if (update) {
						setAgeRangeErrors(error);
					}
					break;
				}
				if (rangeData[i]?.min >= rangeData[i]?.max) {
					error[i] = { text: 'Order: Younger to Older', min: true, max: true };
					if (update) {
						setAgeRangeErrors(error);
					}
					break;
				}
				if (rangeData[i]?.min < 18 || rangeData[i]?.max < 18) {
					error[i] = {
						text: 'Age must be 18+',
						min: rangeData[i]?.min < 18,
						max: rangeData[i]?.max < 18,
					};
					if (update) {
						setAgeRangeErrors(error);
					}
					break;
				}

				if (rangeData[i]?.min > 99 || rangeData[i]?.max > 99) {
					error[i] = {
						text: 'Age must be less than 100',
						min: rangeData[i]?.min > 99,
						max: rangeData[i]?.max > 99,
					};
					if (update) {
						setAgeRangeErrors(error);
					}
					break;
				}

				if (rangeData[i]?.min === '' || rangeData[i]?.max === '' || !rangeData[i]?.min || !rangeData[i]?.max) {
					error[i] = {
						text: 'Missing input',
						min: rangeData[i]?.min === '' || !rangeData[i]?.min,
						max: rangeData[i]?.max === '' || !rangeData[i]?.max,
					};
					if (update) {
						setAgeRangeErrors(error);
					}
					break;
				}
			}
			// Check overlapping ages
			rangeData.forEach((ageRange, key) => {
				rangeData.forEach((secondAgeRange, keyTwo) => {
					if (key !== keyTwo) {
						let ageRangeMin = ageRange?.min;
						let ageRangeMax = ageRange?.max;
						if (!ageRange?.max) {
							const splitAge = ageRange?.label?.split(' - ');
							ageRangeMin = Number(splitAge?.[0] || 0);
							ageRangeMax = Number(splitAge?.[1] || 0);
						}

						let secondAgeRangeMin = secondAgeRange?.min;
						let secondAgeRangeMax = secondAgeRange?.max;
						if (!secondAgeRange?.max) {
							const splitSecondAge = secondAgeRange?.label?.split(' - ');
							secondAgeRangeMin = Number(splitSecondAge?.[0] || 0);
							secondAgeRangeMax = Number(splitSecondAge?.[1] || 0);
						}

						if (
							(ageRangeMin >= secondAgeRangeMin && ageRangeMin <= secondAgeRangeMax) ||
							(ageRangeMax >= secondAgeRangeMin && ageRangeMax <= secondAgeRangeMax)
						) {
							// 'Ages cannot overlap'
							error.overlap = true;
							error[key] = 'overlap';
							error[keyTwo] = 'overlap';
							if (update) {
								setAgeRangeErrors(error);
							}
						}
					}
				});
			});
			return error;
		},
		[setAgeRangeErrors],
	);

	const handleShowError = (item, index) => {
		if (ageRangeErrors[index] && ageRangeErrors[index] !== 'overlap') {
			if (ageRangeErrors[index]?.text === 'Missing input' && !item?.audienceQuestionOptionId) {
				return false;
			}
			return true;
		}
		return false;
	};

	const handleShowInputError = (item, index) => {
		if (ageRangeErrors[index]?.min || ageRangeErrors[index] === 'overlap') {
			if (ageRangeErrors[index]?.text === 'Missing input' && !item?.audienceQuestionOptionId) {
				return false;
			}
			return true;
		}
		return false;
	};

	const updateRanges = useCallback(
		async rangeData => {
			const error = await checkForErrors(rangeData);
			const newRangeAdded = rangeData?.find(item => !item?.audienceQuestionOptionId);
			if (Object.keys(error)?.length === 0 && !_.isEqual(rangeData, data)) {
				if (newRangeAdded) {
					const item = {
						min: newRangeAdded?.min,
						max: newRangeAdded?.max,
						label: `${newRangeAdded?.min} - ${newRangeAdded?.max}`,
						value: `${newRangeAdded?.min} - ${newRangeAdded?.max}`,
						isSelected: true,
					};
					const originalData = rangeData.filter(opt => opt?.audienceQuestionOptionId);
					if (item?.min && item?.max && !_.isEqual(item, recentlyAddedRange.current)) {
						const testRange = [item, recentlyAddedRange.current];
						const error = await checkForErrors(testRange, false);
						if (!error?.overlap) {
							recentlyAddedRange.current = item;
							onCreateOption(item, originalData);
						}
					}
				} else {
					onEditAgeRangeValues(rangeData);
				}
				try {
					const response = await services.studySampleService.getAudience(studyId, uuid);
					const updatedOptions = response?.data?.demographicQuestions?.find(
						question => question?.class === 'age',
					)?.options;

					if (updatedOptions) {
						const newLocalData = rangeData.map((item, index) => {
							if (!item?.audienceQuestionOptionId) {
								const matchingOption =
									updatedOptions.find(option => option.min === item.min && option.max === item.max) ||
									updatedOptions[index];
								return {
									...item,
									audienceQuestionOptionId: matchingOption?.audienceQuestionOptionId,
									audienceQuestionId: matchingOption?.audienceQuestionId,
								};
							}
							return item;
						});
						if (newRangeAdded) {
							onEditAgeRangeValues(newLocalData);
						}
						setLocalData(newLocalData);
					}
				} catch (e) {
					console.error(e);
					toastr.error('Error syncing newly added age range');
				}
			}
			if (Object.keys(error)?.length === 0) {
				setAgeRangeErrors({});
			}
		},
		[data, onEditAgeRangeValues, onCreateOption, setAgeRangeErrors, studyId, uuid, checkForErrors],
	);

	const onMinChange = useCallback(
		(index, range, value) => {
			if (minTimeoutRef.current) {
				clearTimeout(minTimeoutRef.current);
			}
			const newLocalData = localData?.map(item => {
				if (item?.audienceQuestionOptionId === range?.audienceQuestionOptionId) {
					return {
						...item,
						min: value === 0 ? undefined : value,
						label: `${value === 0 ? 'undefined' : value} - ${item.max}`,
						value: `${value === 0 ? 'undefined' : value} - ${item.max}`,
					};
				}
				return item;
			});

			setLocalData(newLocalData);
			minTimeoutRef.current = setTimeout(() => {
				updateRanges(newLocalData);
			}, 300);
		},
		[localData, updateRanges],
	);

	const onMaxChange = useCallback(
		(index, range, value) => {
			if (maxTimeoutRef.current) {
				clearTimeout(maxTimeoutRef.current);
			}
			const newLocalData = localData?.map(item => {
				if (item?.audienceQuestionOptionId === range?.audienceQuestionOptionId) {
					return {
						...item,
						max: value === 0 ? undefined : value,
						label: `${item.min} - ${value === 0 ? 'undefined' : value}`,
						value: `${item.min} - ${value === 0 ? 'undefined' : value}`,
					};
				}
				return item;
			});

			setLocalData(newLocalData);
			maxTimeoutRef.current = setTimeout(() => {
				updateRanges(newLocalData);
			}, 300);
		},
		[localData, updateRanges],
	);

	return (
		<div className={className}>
			{rangesToDisplay.map((range, index) => (
				<div key={index} className={el('range')}>
					<div className={el('inputs')}>
						<input
							type="number"
							className={`${handleShowInputError(range, index) ? el('error-input') : ''}`}
							value={range.min}
							onChange={e => {
								onMinChange(index, range, Number(e.target.value));
							}}
						/>
						<span>-</span>
						<input
							type="number"
							className={`${handleShowInputError(range, index) ? el('error-input') : ''}`}
							value={range.max}
							onChange={e => {
								onMaxChange(index, range, Number(e.target.value));
							}}
						/>
					</div>
					{handleShowError(range, index) && (
						<div className={el('range-error')}>
							<Iconof icon="warning" className={el('error-icon')} />
							<p>{ageRangeErrors[index]?.text}</p>
						</div>
					)}
					<Iconof
						icon="clear"
						className={el('remove_item')}
						onClick={async e => {
							e.stopPropagation();
							e.preventDefault();
							onRemove(range);
							const newLocalData = localData.filter(
								item => item?.audienceQuestionOptionId !== range?.audienceQuestionOptionId,
							);
							setLocalData(newLocalData);
							const filteredData = data.filter(
								item => item?.audienceQuestionOptionId !== range?.audienceQuestionOptionId,
							);
							if (!_.isEqual(newLocalData, filteredData)) {
								await updateRanges(newLocalData);
							}
							recentlyAddedRange.current = null;

							const filteredNewLocalData = newLocalData.filter(item => item?.audienceQuestionOptionId);
							const newErrors = checkForErrors(filteredNewLocalData);
							setAgeRangeErrors(newErrors);
						}}
					/>
				</div>
			))}
		</div>
	);
};

AgeRangeInput.propTypes = {
	data: PropTypes.array,
	onRemove: PropTypes.func,
	onEditAgeRangeValues: PropTypes.func,
	onCreateOption: PropTypes.func,
	ageRangeErrors: PropTypes.object,
	setAgeRangeErrors: PropTypes.func,
	getDemographicUuid: PropTypes.func,
	search: PropTypes.string,
};

export default AgeRangeInput;
