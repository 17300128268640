import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SelectedRecall from 'public/images/masking/selected_recall.svg';
import UnSelectedRecall from 'public/images/masking/unselected_recall.svg';
import DisabledRecall from 'public/images/masking/disabled_recall_bold.svg';
import SingleSelectedRecall from 'public/images/masking/single_selected_recall.svg';
import SingleUnSelectedRecall from 'public/images/masking/single_unselected_recall.svg';
import SingleDisabledRecall from 'public/images/masking/single_disabled_recall_bold.svg';

import cn from 'src/utilities/bem-cn';
import useOutsideClick from 'src/hooks/useOutsideClick';
import QuillMentionUtil from 'src/components/inputs/SimpleRichText/QuillMentionUtil';
import RecallRankedDropdown from 'src/components/shared/RecallRankedDropdown';
import RecallQuestionHeader from 'src/components/shared/RecallQuestionHeader';
import RecallQuestionButton from 'src/components/shared/RecallQuestionButton';
import './styles.scss';

const className = 'piping-recalling';
const el = (name, mod) => cn(className, name, mod);

const PipingDropdownRecalling = ({
	studyType = 'study',
	pipeableQuestions,
	allQuestions,
	currentQuestion = false,
	onChangeRecall,
	onClose,
	style = {},
	unsavedPiping = [],
	containerHeight,
	isManageTranslation,
	shouldBlockDuplication = true,
	sections,
}) => {
	const pipingTopContainer = useMemo(() => `${containerHeight || 42}px`, [containerHeight]);

	const ref = useRef(null);
	const studyLanguage = useSelector(store => store.manageStudy?.language);
	const blueprintLanguage = useSelector(store => store.manageBlueprint?.language);

	const isFirstQuestion =
		!pipeableQuestions?.length || !pipeableQuestions[0] || pipeableQuestions[0]?.id === currentQuestion?.id;

	const pipedQuestions = QuillMentionUtil.getCompletePipedQuestions(
		currentQuestion?.isStatement ? currentQuestion : currentQuestion?.label,
		pipeableQuestions,
		currentQuestion?.isStatement,
		studyLanguage || blueprintLanguage,
	);

	const currentLanguage = useMemo(() => {
		if (studyType === 'study') return studyLanguage;
		if (studyType === 'blueprint') return blueprintLanguage;
	}, [blueprintLanguage, studyLanguage, studyType]);

	const ideaSplitQuestions = useMemo(
		() =>
			allQuestions.filter(
				q => q?.sectionType === 'monadic_split' && q?.sectionId === currentQuestion?.sectionId,
			) || [],
		[allQuestions, currentQuestion],
	);

	const currentQuestionIndex = useMemo(
		() => [...pipeableQuestions, currentQuestion].findIndex(q => q?.id === currentQuestion?.id),
		[pipeableQuestions, currentQuestion],
	);

	const currentIdeaSplitsIndex = useMemo(
		() => ideaSplitQuestions.findIndex(e => e.id === currentQuestion.id),
		[ideaSplitQuestions, currentQuestion],
	);

	const questionSection = useMemo(
		() =>
			pipeableQuestions?.filter(
				(question, index) => question?.sectionType !== 'monadic_split' && currentQuestionIndex > index,
			) || [],
		[pipeableQuestions, currentQuestionIndex],
	);

	useOutsideClick(ref, () => {
		onClose();
	});

	const renderEmptyOptions = msg => (
		<div className={el('dropdown_content')}>
			<div className={el('dropdown_content_empty')}>
				<span className={el('dropdown_content_no_data')}>{msg}</span>
			</div>
		</div>
	);

	const selectQuestion = (question, logic, position = null, rank = null) => {
		const questionIndex = (allQuestions.findIndex(q => question?.id === q?.id) || 0) + 1;
		const piped = { questionIndex, maskedQuestionId: question.id, logic };

		if (position && rank) {
			piped.position = position;
			piped.rank = rank;
		}

		onChangeRecall(piped);
	};

	const getQuestionIndex = useCallback(
		(list, question) => list.findIndex(quest => quest?.id === question?.id) + 1,
		[],
	);	

	const getIconBasedStatus = useCallback((logic, question) => {
		const isMultiSelect = question?.settings?.find(s => s?.label === 'multi-select')?.value === 'true';

		if (logic === 'selected') return isMultiSelect ? SelectedRecall : SingleSelectedRecall;
		if (logic === 'not-selected') return isMultiSelect ? UnSelectedRecall : SingleUnSelectedRecall;
		return isMultiSelect ? DisabledRecall : SingleDisabledRecall;
	}, []);

	const renderOption = (question, index = 0) => {
		if (question?.id === currentQuestion?.id && question?.sectionType !== 'statement') return null;
		let pipedInfo = [];
		if (shouldBlockDuplication) {
			pipedInfo =
				unsavedPiping || isManageTranslation
					? unsavedPiping.filter(pipe => Number(pipe?.questionId) === Number(question?.id)) || []
					: pipedQuestions.filter(pipe => Number(pipe.questionId) === Number(question?.id)) || [];
		}

		return (
			<div className={el('dropdown_content_item')} key={`recalling-${question?.id}`}>
				<RecallQuestionHeader 
					question={question}
					index={index}
					currentLanguage={currentLanguage}
					allQuestions={allQuestions}
				/>
				{question?.style === 'ranked' 
				? 
					<div className={el('dropdown_ranked_container')}>
						<img style={{marginRight: 10}} src={SelectedRecall} alt="" className={el('icon')} />
						<span style={{ minWidth: 63 }}>Ranked as</span>
						<RecallRankedDropdown 
							question={question}
							maskingType="selected"
							selectQuestion={selectQuestion}
							sections={sections}
						/>
					</div>
				: <div className={el('dropdown_button_container')}>
					<RecallQuestionButton
						question={question}
						maskingType="selected"
						selectQuestion={selectQuestion}
						getIconBasedStatus={getIconBasedStatus}
						pipedInfo={pipedInfo}
					/>
					<RecallQuestionButton
						question={question}
						maskingType="not-selected"
						selectQuestion={selectQuestion}
						getIconBasedStatus={getIconBasedStatus}
						pipedInfo={pipedInfo}
					/>
				</div>}
			</div>
		);
	};

	const renderQuestionSection = () => (
		<>
			{questionSection.length ? <div className={el('header_splits')} /> : null}
			{questionSection?.map(question => {
				const index = getQuestionIndex(allQuestions, question);
				return renderOption(question, index);
			})}
		</>
	);

	const renderIdeasSplit = () => (
		<>
			{ideaSplitQuestions?.length
				? ideaSplitQuestions?.filter(
						(q, index) =>
							currentIdeaSplitsIndex > index &&
							q?.style !== 'open-ended' &&
							q?.style !== 'grid' &&
							q?.style !== 'heatmap',
				  )?.length > 0 && (
						<div className={el('header_splits')}>
							<p className={el('header_title')}>This splits section:</p>
						</div>
				  )
				: null}
			{ideaSplitQuestions?.map((question, i) => {
				if (
					currentIdeaSplitsIndex > i &&
					question?.style !== 'open-ended' &&
					question?.style !== 'grid' &&
					question?.style !== 'heatmap'
				) {
					const index = getQuestionIndex(ideaSplitQuestions, question);
					return renderOption(question, index);
				}
				return null;
			})}
		</>
	);

	return (
		<div
			aria-hidden
			className={el('dropdown_container')}
			ref={ref}
			onMouseDown={event => {
				event.stopPropagation();
				event.preventDefault();
			}}
			style={{ ...style, top: pipingTopContainer }}
		>
			<div className={el('dropdown_header')}>
				<span className={el('dropdown_title')}>RECALL</span>
				<span className={el('dropdown_subtitle')}>Recall selected responses from a previous question</span>
			</div>
			{isFirstQuestion ? (
				renderEmptyOptions('No questions available to recall into this location.')
			) : (
				<div className={el('dropdown_content')}>
					{questionSection?.length ? renderQuestionSection() : null}
					{ideaSplitQuestions?.length ? renderIdeasSplit() : null}
				</div>
			)}
		</div>
	);
};
PipingDropdownRecalling.propTypes = {
	studyType: PropTypes.string,
	currentQuestion: PropTypes.object,
	onChangeRecall: PropTypes.func,
	allQuestions: PropTypes.array,
	pipeableQuestions: PropTypes.array,
	onClose: PropTypes.func,
	style: PropTypes.any,
	unsavedPiping: PropTypes.array,
	containerHeight: PropTypes.number,
	isManageTranslation: PropTypes.bool,
	shouldBlockDuplication: PropTypes.bool,
	sections: PropTypes.any,
};
export default PipingDropdownRecalling;
