const prefix = 'public-study';

// QUESTIONS

export const ADD_SECTION_QUESTION = `${prefix}/ADD_SECTION_QUESTION`;
export const addSectionQuestion = (sectionId, type, sortOrder, questionId) => ({
	type: ADD_SECTION_QUESTION,
	payload: { sectionId, type, sortOrder, questionId },
});

export const DELETE_SECTION_QUESTION = `${prefix}/DELETE_SECTION_QUESTION`;
export const deleteSectionQuestion = questionId => ({ type: DELETE_SECTION_QUESTION, payload: { questionId } });

export const REORDER_QUESTIONS = `${prefix}/REORDER_QUESTIONS`;
export const reorderQuestions = (sourceIndex, destinationIndex) => ({
	type: REORDER_QUESTIONS,
	payload: { sourceIndex, destinationIndex },
});

export const UPDATE_QUESTION_LABEL = `${prefix}/UPDATE_QUESTION_LABEL`;
export const updateQuestionLabel = (questionId, label, languageCode, refetch = false) => ({
	type: UPDATE_QUESTION_LABEL,
	payload: { questionId, label, languageCode, refetch },
});

export const PATCH_QUESTION = `${prefix}/PATCH_QUESTION`;
export const patchQuestion = (questionId, data, section = null) => ({
	type: PATCH_QUESTION,
	payload: { questionId, data, section },
});

export const CREATE_QUESTION_SETTING = `${prefix}/CREATE_QUESTION_SETTING`;
export const createQuestionSetting = (questionId, data) => ({
	type: CREATE_QUESTION_SETTING,
	payload: { questionId, data },
});

export const PATCH_QUESTION_SETTING = `${prefix}/PATCH_QUESTION_SETTING`;
export const patchQuestionSetting = (studyId, questionId, settingId, data) => ({
	type: PATCH_QUESTION_SETTING,
	payload: { studyId, questionId, settingId, data },
});

// OPTIONS

export const ADD_OPTION_PLACEHOLDER = `${prefix}/ADD_OPTION_PLACEHOLDER`;
export const addOptionPlaceholder = questionId => ({
	type: ADD_OPTION_PLACEHOLDER,
	payload: { questionId },
});

export const REMOVE_OPTION_PLACEHOLDER = `${prefix}/REMOVE_OPTION_PLACEHOLDER`;
export const removeOptionPlaceholder = (questionId, answerId) => ({
	type: REMOVE_OPTION_PLACEHOLDER,
	payload: { questionId, answerId },
});

export const ADD_QUESTION_OPTION = `${prefix}/ADD_QUESTION_OPTION`;
export const addQuestionOption = (questionId, option) => ({
	type: ADD_QUESTION_OPTION,
	payload: { questionId, option },
});

export const REMOVE_QUESTION_OPTION = `${prefix}/REMOVE_QUESTION_OPTION`;
export const removeQuestionOption = (questionId, optionId) => ({
	type: REMOVE_QUESTION_OPTION,
	payload: { questionId, optionId },
});

export const UPDATE_OPTION_LABEL = `${prefix}/UPDATE_OPTION_LABEL`;
export const updateOptionLabel = (optionId, translationId, questionId, label, languageCode, filterImports) => ({
	type: UPDATE_OPTION_LABEL,
	payload: { optionId, translationId, questionId, label, languageCode, filterImports },
});

export const REORDER_OPTIONS = `${prefix}/REORDER_OPTIONS`;
export const reorderOptions = (questionId, optionId, sourceIndex, destinationIndex) => ({
	type: REORDER_OPTIONS,
	payload: { questionId, optionId, sourceIndex, destinationIndex },
});

export const PATCH_QUESTION_OPTION = `${prefix}/PATCH_QUESTION_OPTION`;
export const patchQuestionOption = (studyId, questionId, optionId, data) => ({
	type: PATCH_QUESTION_OPTION,
	payload: { studyId, questionId, optionId, data },
});

// CUSTOM QUALIFIERS

export const ADD_AUDIENCE_QUESTION = `${prefix}/ADD_AUDIENCE_QUESTION`;
export const addAudienceQuestion = (audienceUuid, type, sortOrder, questionId) => ({
	type: ADD_AUDIENCE_QUESTION,
	payload: { audienceUuid, type, sortOrder, questionId },
});

export const DELETE_CUSTOM_QUALIFIER = `${prefix}/DELETE_CUSTOM_QUALIFIER`;
export const deleteCustomQualifier = (questionId, audience) => ({
	type: DELETE_CUSTOM_QUALIFIER,
	payload: { questionId, audience },
});

export const UPDATE_CUSTOM_QUALIFIER_LABEL = `${prefix}/UPDATE_CUSTOM_QUALIFIER_LABEL`;
export const updateCustomQualifierLabel = (questionId, label, languageCode, audience, refetch = false) => ({
	type: UPDATE_CUSTOM_QUALIFIER_LABEL,
	payload: { questionId, label, languageCode, audience, refetch },
});

export const ADD_CUSTOM_QUALIFIER_OPTION_PLACEHOLDER = `${prefix}/ADD_CUSTOM_QUALIFIER_OPTION_PLACEHOLDER`;
export const addCustomQualifierOptionPlaceholder = questionId => ({
	type: ADD_CUSTOM_QUALIFIER_OPTION_PLACEHOLDER,
	payload: { questionId },
});

export const ADD_CUSTOM_QUALIFIER_ATTRIBUTE_PLACEHOLDER = `${prefix}/ADD_CUSTOM_QUALIFIER_ATTRIBUTE_PLACEHOLDER`;
export const addCustomQualifierAttributePlaceholder = questionId => ({
	type: ADD_CUSTOM_QUALIFIER_ATTRIBUTE_PLACEHOLDER,
	payload: { questionId },
});

// ATTRIBUTES

export const ADD_ATTRIBUTE_PLACEHOLDER = `${prefix}/ADD_ATTRIBUTE_PLACEHOLDER`;
export const addAttributePlaceholder = questionId => ({
	type: ADD_ATTRIBUTE_PLACEHOLDER,
	payload: { questionId },
});

export const REMOVE_ATTRIBUTE_PLACEHOLDER = `${prefix}/REMOVE_ATTRIBUTE_PLACEHOLDER`;
export const removeAttributePlaceholder = (questionId, attributeId) => ({
	type: REMOVE_ATTRIBUTE_PLACEHOLDER,
	payload: { questionId, attributeId },
});

export const ADD_QUESTION_ATTRIBUTE = `${prefix}/ADD_QUESTION_ATTRIBUTE`;
export const addQuestionAttribute = (questionId, attribute) => ({
	type: ADD_QUESTION_ATTRIBUTE,
	payload: { questionId, attribute },
});

export const REMOVE_QUESTION_ATTRIBUTE = `${prefix}/REMOVE_QUESTION_ATTRIBUTE`;
export const removeQuestionAttribute = (questionId, attributeId) => ({
	type: REMOVE_QUESTION_ATTRIBUTE,
	payload: { questionId, attributeId },
});

export const UPDATE_ATTRIBUTE_LABEL = `${prefix}/UPDATE_ATTRIBUTE_LABEL`;
export const updateAttributeLabel = (attributeId, translationId, questionId, label, languageCode) => ({
	type: UPDATE_ATTRIBUTE_LABEL,
	payload: { attributeId, translationId, questionId, label, languageCode },
});

export const REORDER_ATTRIBUTES = `${prefix}/REORDER_ATTRIBUTES`;
export const reorderAttributes = (questionId, attributeId, sourceIndex, destinationIndex) => ({
	type: REORDER_ATTRIBUTES,
	payload: { questionId, attributeId, sourceIndex, destinationIndex },
});

export const PATCH_QUESTION_ATTRIBUTE = `${prefix}/PATCH_QUESTION_ATTRIBUTE`;
export const patchQuestionAttribute = (studyId, questionId, attributeId, data) => ({
	type: PATCH_QUESTION_ATTRIBUTE,
	payload: { studyId, questionId, attributeId, data },
});

//

export const SET_SECTION_QUESTIONS = `${prefix}/SET_SECTION_QUESTIONS`;
export const setSectionQuestions = questions => ({
	type: SET_SECTION_QUESTIONS,
	payload: { questions },
});

export const SET_AUDIENCE_QUESTIONS = `${prefix}/SET_AUDIENCE_QUESTIONS`;
export const setAudienceQuestions = questions => ({
	type: SET_AUDIENCE_QUESTIONS,
	payload: { questions },
});

export const FETCH_SECTION_QUESTIONS = `${prefix}/FETCH_SECTION_QUESTIONS`;
export const fetchSectionQuestions = sectionId => ({ type: FETCH_SECTION_QUESTIONS, payload: { sectionId } });

export const UPDATE_SECTION_QUESTION = `${prefix}/UPDATE_SECTION_QUESTION`;
export const updateSectionQuestion = (questionId, data) => ({
	type: UPDATE_SECTION_QUESTION,
	payload: { questionId, data },
});

export const ADD_SECTION_LOGIC = `${prefix}/ADD_SECTION_LOGIC`;
export const addSectionLogic = (sectionId, data) => ({ type: ADD_SECTION_LOGIC, payload: { sectionId, data } });
