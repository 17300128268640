import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import { Iconof } from '@upsiide/ui-components';
import * as services from 'src/services';
import cn from 'src/utilities/bem-cn';
import EllipsisMenu from 'src/components/elements/EllipsisMenu';
import Loader from 'src/components/shared/Loader';
import * as actions from 'src/domains/manage-study/actions';
import { renderAudienceName } from 'src/utilities/misc';

import './styles.scss';

const className = 'demographic-overview';
const el = (name, mod) => cn(className, name, mod);

const defaultIncidentRate = 40;

const DemographicOverview = ({
	template,
	setCurrentTab,
	onAddGroup,
	setDemographicGroup = false,
	viewMode = false,
	setTemplate,
	setIndexGroup,
	setTemplateModalCurrentAudience = false,
	customCss = {},
}) => {
	const [duplicateLoading, setDuplicateLoading] = useState(false);
	const onSelectGroup = group => {
		setCurrentTab('demographic');
		setDemographicGroup(group);
		if (setTemplateModalCurrentAudience) setTemplateModalCurrentAudience(group?.uuid);
		if (setIndexGroup) setIndexGroup(template?.demographicGroups?.findIndex(g => g?.uuid === group?.uuid) || 0);
	};
	const dispatch = useDispatch();

	const onDelete = group => {
		setTemplate({
			...template,
			demographicGroups: template?.demographicGroups?.filter(gp => gp?.id !== group?.id),
		});
		dispatch(actions.deleteAudience(template?.studyId, group?.uuid));
	};

	const duplicateAudience = async groupToDuplicate => {
		setDuplicateLoading(true);
		const groupName =
			groupToDuplicate?.name ||
			(groupToDuplicate?.countryLanguage?.countryLabel && groupToDuplicate?.countryLanguage?.languageLabel
				? `${groupToDuplicate.countryLanguage.countryLabel} - ${groupToDuplicate.countryLanguage.languageLabel}`
				: 'Untitled Group');
		const audienceToPost = {
			sampleSize: groupToDuplicate?.sampleSize,
			incidenceRate: groupToDuplicate?.estimatedIncidenceRate || defaultIncidentRate,
			languageCode: groupToDuplicate?.languageCode,
			provider: groupToDuplicate?.provider || 'LUCID',
			countryId: groupToDuplicate?.countryLanguage?.countryId,
			customQuestions: [],
			name: `${groupName} (Copy)`,
			providerQuestions: [],
			audienceCollectionId: template?.id,
			demographicQuestions: groupToDuplicate?.demographicQuestions || [],
			templateAudienceId: groupToDuplicate?.id,
		};
		try {
			await services.studySampleService.createAudience(template?.studyId, audienceToPost).then(data => {
				services.audienceService.getCollection(template?.studyId, template?.id).then(result => {
					setTemplate(result.data);
				});
				toastr.success('Audience successfully created');
			});
			setDuplicateLoading(false);
		} catch (e) {
			console.error(e);
			toastr.error('Audience duplication failed');
			setDuplicateLoading(false);
		}
	};

	return (
		<div className={el('overview')}>
			{duplicateLoading && (
				<div className={el('loader-container')}>
					<Loader />
				</div>
			)}
			<div className={el('overview_header')}>
				<span className={el('overview_title')}>Demographic Groups</span>
				{!viewMode && (
					<span className={el('add_group')} aria-hidden onClick={onAddGroup}>
						Add Group
					</span>
				)}
			</div>

			<div className={el('overview_container')} style={{ ...customCss }}>
				<div className={el('overview_content')}>
					<div className={el('overview_title_name')}>Group Name</div>
					<div className={el('overview_title_responses')}>Responses</div>
					<div className={el('overview_title_price')}>Price</div>
				</div>
				{template?.demographicGroups?.length ? (
					template?.demographicGroups?.map((group, i) => (
						<div
							key={group?.id || i}
							className={el(`overview_row${i % 2 ? '_2' : ''}`)}
							aria-hidden
							onClick={() => onSelectGroup(group)}
						>
							<div className={el('overview_name')}>
								<span className="label">{renderAudienceName(group)}</span>
								<Iconof icon="made_call" />
							</div>
							<div className={el('overview_responses')}>{group?.sampleSize}</div>
							<div className={el('overview_price')}>
								{group?.priceInCents ? `$${(group.priceInCents / 100).toFixed(2)}` : 0}
								<span className={el('overview_total_price')}>
									(${group?.totalPriceInCents ? (group.totalPriceInCents / 100).toFixed(2) : 0})
								</span>
							</div>
							{!viewMode && (
								<div className={el('menu')}>
									<EllipsisMenu>
										<DropdownItem
											className={el('dropdown-item')}
											onClick={() => {
												setDemographicGroup(group);
												setCurrentTab('demographic');
											}}
										>
											Edit
										</DropdownItem>
										<DropdownItem
											className={el('dropdown-item')}
											onClick={() => {
												duplicateAudience(group);
											}}
										>
											Duplicate
										</DropdownItem>
										<DropdownItem
											className={el('delete-dropdown-item')}
											onClick={() => {
												onDelete(group);
											}}
										>
											Delete
										</DropdownItem>
									</EllipsisMenu>
								</div>
							)}
						</div>
					))
				) : (
					<div>No groups </div>
				)}
			</div>
		</div>
	);
};

DemographicOverview.displayName = 'DemographicOverview';
DemographicOverview.propTypes = {
	template: PropTypes.object,
	setDemographicGroup: PropTypes.func,
	setCurrentTab: PropTypes.func,
	viewMode: PropTypes.bool,
	onAddGroup: PropTypes.func,
	setTemplate: PropTypes.func,
	setIndexGroup: PropTypes.func,
	setTemplateModalCurrentAudience: PropTypes.func,
	customCss: PropTypes.any,
};

export default DemographicOverview;
