import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import * as misc from 'src/utilities/misc';

import ChevronLeft from 'src/components/icons/ChevronLeft';
import ChevronRight from 'src/components/icons/ChevronRight';

import './styles.scss';
import TextInput from 'src/components/elements/TextInput';

const className = 'audience-navigation';
const el = (name, mod) => cn(className, name, mod);

const AudienceTemplateNavigation = ({
	indexGroup,
	setIndexGroup,
	template,
	disabled = false,
	onChangeAudienceName,
	currentTab,
	step,
	setSelectedAudienceTemplate,
	setTemplateModalCurrentAudience = false,
	customCss = {},
}) => {
	const [audienceName, setAudienceName] = useState(template?.name || '');

	useEffect(() => {
		if (template?.name) setAudienceName(template?.name);
	}, [template]);

	const nextDemographic = () => {
		if (template?.demographicGroups?.length > 1 && !disabled) {
			if (indexGroup + 1 <= template.demographicGroups.length - 1) {
				setIndexGroup(prev => prev + 1);
				if (setTemplateModalCurrentAudience)
					setTemplateModalCurrentAudience(template?.demographicGroups?.[indexGroup + 1]?.uuid);
			}
		}
	};

	const previousDemographic = () => {
		if (template?.demographicGroups?.length > 1 && !disabled) {
			if (indexGroup - 1 >= 0) {
				setIndexGroup(prev => prev - 1);
				if (setTemplateModalCurrentAudience)
					setTemplateModalCurrentAudience(template?.demographicGroups?.[indexGroup - 1]?.uuid);
			}
		}
	};

	const renderBullets = () => {
		if (!template?.demographicGroups?.length || disabled) return null;
		if (template?.demographicGroups?.length > 4) {
			if (indexGroup > 3) {
				return (
					<>
						<div className={el('bullet')} />
						<div className={el('bullet')} />
						<div className={el('bullet')} />
						<div className={el('bullet_active')} />
					</>
				);
			}
			return template.demographicGroups
				.slice(0, 4)
				.map((group, i) => <div className={el(`${i === indexGroup ? 'bullet_active' : 'bullet'}`)} />);
		}
		return template?.demographicGroups?.map((group, i) => (
			<div className={el(`${i === indexGroup ? 'bullet_active' : 'bullet'}`)} />
		));
	};

	const renderName = () => {
		if (currentTab === 'overview') return null;
		if (currentTab === 'demographic')
			return (
				template?.demographicGroups?.[indexGroup]?.name ||
				misc.renderAudienceName(template?.demographicGroups?.[indexGroup])
			);
		return template?.name || '';
	};

	return (
		<div className={className} style={{ ...customCss }}>
			<div className={el('name_container')}>
				{step === 'preview' ? <span className={el('name')}>{renderName()}</span> : null}
			</div>
			{currentTab !== 'demographic' ? null : (
				<div className={el('button_container')}>
					<span className={el('arrow', 'left')} aria-hidden onClick={previousDemographic}>
						<ChevronLeft color="#3B3B3B" />
					</span>

					{renderBullets()}

					<span className={el('arrow', 'right')} aria-hidden onClick={nextDemographic}>
						<ChevronRight color="#3B3B3B" />
					</span>
				</div>
			)}
		</div>
	);
};

AudienceTemplateNavigation.propTypes = {
	template: PropTypes.object,
	indexGroup: PropTypes.any,
	setIndexGroup: PropTypes.func,
	disabled: PropTypes.bool,
	onChangeAudienceName: PropTypes.func,
	currentTab: PropTypes.string,
	step: PropTypes.string,
	setSelectedAudienceTemplate: PropTypes.func,
	setTemplateModalCurrentAudience: PropTypes.func,
	customCss: PropTypes.any,
};

export default AudienceTemplateNavigation;
