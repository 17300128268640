import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

const TOKEN_KEY = process.env.userJwtName;
const STORED_TOKEN_KEY = process.env.originalUserJwtName;

let cookiePrefix = '';
if (process.env.development) {
	cookiePrefix = 'DEV__';
} else if (process.env.staging) {
	cookiePrefix = 'STAGING__';
}

const cookieHandler = new Cookies();

const cookieOptions = {
	path: '/',
	maxAge: 60 * 60 * 24 * 7,
	domain: '',
};
const tempCookieOptions = {
	path: '/',
	maxAge: 60 * 60 * 24 * 7,
	domain: '.upsiide.com',
};

if (process.env.production || process.env.staging || process.env.development) {
	cookieOptions.secure = true;
}

if (process.env.production) {
	cookieOptions.domain = '.upsiide.com';
}

const clearToken = () => {
	if (cookieHandler) {
		cookieHandler.remove(TOKEN_KEY, cookieOptions);
		cookieHandler.remove(TOKEN_KEY, tempCookieOptions);
	}
};

const clearStoredToken = () => cookieHandler && cookieHandler.remove(STORED_TOKEN_KEY, cookieOptions);

const getOriginalToken = () => cookieHandler && cookieHandler.get(STORED_TOKEN_KEY);

const getOriginalTokenData = () => jwtDecode(getOriginalToken());

const getToken = () => cookieHandler && cookieHandler.get(TOKEN_KEY);

const getTokenData = token => jwtDecode(token || getToken());

const setToken = token => cookieHandler && cookieHandler.set(TOKEN_KEY, token, cookieOptions);

const storeOriginalToken = token => cookieHandler && cookieHandler.set(STORED_TOKEN_KEY, token, cookieOptions);

const getUpsiideAccountToken = () => cookieHandler && cookieHandler.get(`${cookiePrefix}UPSIIDE_ACCOUNT_TOKEN`);

const setUpsiideAccountToken = uuid =>
	cookieHandler && cookieHandler.set(`${cookiePrefix}UPSIIDE_ACCOUNT_TOKEN`, uuid, cookieOptions);

const removeUpsiideAccountToken = () => cookieHandler && cookieHandler.remove(`${cookiePrefix}UPSIIDE_ACCOUNT_TOKEN`);

// * Ghost
const getLogoutGhost = () => cookieHandler && cookieHandler.get(`${cookiePrefix}LOGOUT_GHOST`);

const setLogoutGhost = value => cookieHandler && cookieHandler.set(`${cookiePrefix}LOGOUT_GHOST`, value);

const removeLogoutGhost = () => cookieHandler && cookieHandler.remove(`${cookiePrefix}LOGOUT_GHOST`);

const getGhost = () => cookieHandler && cookieHandler.get(`${cookiePrefix}GHOST`);

const setGhost = value =>
	cookieHandler && cookieHandler.set(`${cookiePrefix}GHOST`, value, { path: '/', maxAge: 1000 * 60 * 60 * 24 * 7 });

const removeGhost = () => cookieHandler && cookieHandler.remove(`${cookiePrefix}GHOST`);

// * Client Id
const setClientId = value => cookieHandler && cookieHandler.set(`${cookiePrefix}CLIENT_ID`, value, cookieOptions);

const getClientId = () => cookieHandler && cookieHandler.get(`${cookiePrefix}CLIENT_ID`);

const removeClientId = () => cookieHandler && cookieHandler.remove(`${cookiePrefix}CLIENT_ID`);

// * Study Id
const setStudyId = value => cookieHandler && cookieHandler.set(`${cookiePrefix}STUDY_ID`, value, cookieOptions);

const getStudyId = () => cookieHandler && cookieHandler.get(`${cookiePrefix}STUDY_ID`);

const removeStudyId = () => cookieHandler && cookieHandler.remove(`${cookiePrefix}STUDY_ID`);

const setStudyUuid = value => cookieHandler && cookieHandler.set(`${cookiePrefix}STUDY_UUID`, value, cookieOptions);

const getStudyUuid = () => cookieHandler && cookieHandler.get(`${cookiePrefix}STUDY_UUID`);

const removeStudyUuid = () => cookieHandler && cookieHandler.remove(`${cookiePrefix}STUDY_UUID`);

// * utm
const setUtmData = value => cookieHandler && cookieHandler.set(`${cookiePrefix}UTM`, value, cookieOptions);

const getUtmData = () => cookieHandler && cookieHandler.get(`${cookiePrefix}UTM`);

const removeUtmData = () => cookieHandler && cookieHandler.remove(`${cookiePrefix}UTM`);

const getUpsiideUTMData = () => cookieHandler && cookieHandler.get('__gtm_campaign_url');

// * price change
const setPriceChangedStudies = value =>
	cookieHandler && cookieHandler.set(`${cookiePrefix}PriceChangedStudies`, value, cookieOptions);

const getPriceChangedStudies = () => cookieHandler && cookieHandler.get(`${cookiePrefix}PriceChangedStudies`);

// * set company size for new users signup
const setCompanySize = value => cookieHandler && cookieHandler.set(`${cookiePrefix}COMPANY_SIZE`, value, cookieOptions);

const getCompanySize = () => cookieHandler && cookieHandler.get(`${cookiePrefix}COMPANY_SIZE`);

export default {
	// * Token
	clearToken,
	clearStoredToken,
	getOriginalToken,
	getOriginalTokenData,
	storeOriginalToken,
	getToken,
	getTokenData,
	setToken,
	// * Upsiide account token
	getUpsiideAccountToken,
	setUpsiideAccountToken,
	removeUpsiideAccountToken,
	// * Ghost
	getLogoutGhost,
	setLogoutGhost,
	removeLogoutGhost,
	getGhost,
	setGhost,
	removeGhost,
	// * Client id
	setClientId,
	getClientId,
	removeClientId,
	// * Study id / uuid
	setStudyId,
	getStudyId,
	removeStudyId,
	setStudyUuid,
	getStudyUuid,
	removeStudyUuid,
	// * utm
	setUtmData,
	getUtmData,
	removeUtmData,
	getUpsiideUTMData,
	// * Price Change
	setPriceChangedStudies,
	getPriceChangedStudies,
	// * Company Size
	setCompanySize,
	getCompanySize,
};
