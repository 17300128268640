import React, { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

import completeMark from 'public/images/audience/checkmark_circle.svg';

import './styles.scss';

const CustomPercentageCircle = ({
	customId,
	customClass,
	percentage = 0,
	customDuration = 800,
	width = 24,
	height = 24,
	foregroundColor = '#008855',
	backgroundColor = '#e3f4ec',
}) => {
	const donutRef = useRef();
	const donutContainerRef = useRef();
	const assignDonutContainerRef = node => (donutContainerRef.current = node);
	const assignDonutRef = node => (donutRef.current = node);

	const updateDonut = useCallback(() => {
		const twoPi = 2 * Math.PI;
		let progress = 0;

		const arc = d3
			.arc()
			.startAngle(0)
			.innerRadius(width / 4)
			.outerRadius(width / 2);
		d3.select(donutContainerRef.current);

		d3.select(donutRef.current).selectAll('*').remove();

		const svg = d3.select(donutRef.current).attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

		svg.exit().remove();

		const meter = svg.append('g').attr('class', 'meter');
		meter.append('path').attr('d', arc.endAngle(twoPi)).style('fill', backgroundColor);
		const i = d3.interpolate(progress, percentage / 100);
		const foreground = meter.append('path').attr('d', arc.endAngle(twoPi)).style('fill', foregroundColor);
		meter
			.transition()
			.duration(customDuration)
			.tween('progress', () => {
				return t => {
					progress = i(t);
					foreground.attr('d', arc.endAngle(twoPi * progress));
				};
			});
	}, [width, height, percentage, foregroundColor, backgroundColor, customDuration]);

	useEffect(() => {
		updateDonut();
	}, [updateDonut]);

	return percentage >= 100 ? (
		<img className={customClass} alt="complete" src={completeMark} width={width} height={height} />
	) : (
		<div className={`percentage-circle ${customClass || ''}`}>
			<svg width={width} height={height} ref={assignDonutContainerRef}>
				<g className={`d3-donut custom-pctg-donut-${customId}}`} ref={assignDonutRef} />
			</svg>
		</div>
	);
};

CustomPercentageCircle.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	customId: PropTypes.any,
	customClass: PropTypes.any,
	percentage: PropTypes.number,
	customDuration: PropTypes.number,
	foregroundColor: PropTypes.any,
	backgroundColor: PropTypes.any,
};

export default CustomPercentageCircle;
