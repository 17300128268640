import * as services from 'src/services';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import toastr from 'toastr';
import * as actions from '../actions';
import * as authActions from '../../auth/actions';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};

const confirmToken = async (store, { type, payload }) => {
	if (type === actions.CONFIRM_USER_SETUP_TOKEN) {
		try {
			const { token } = payload;
			store.dispatch(actions.setLoading(true));
			store.dispatch(authActions.setHasAccount(true));
			const userData = await services.userService.checkAccountSetupToken(token);
			store.dispatch(actions.setUser({ email: userData.shareId }));
			store.dispatch(actions.setToken(token));
			store.dispatch(actions.setLoading(false));
		} catch (error) {
			store.dispatch(actions.setTokenError(error));
			store.dispatch(actions.setLoading(false));
			console.group('Error while confirming user creation token');
			console.error('Error:', error);
			console.groupEnd();
		}
	}
};

const setupUser = async (store, { type, payload }) => {
	if (type === actions.SETUP_USER) {
		try {
			store.dispatch(actions.setLoading(true));
			const PatchResp = await services.userService.setupSignUpUser(payload.user);
			store.dispatch(actions.setUser(payload.user));
			store.dispatch(actions.setLoading(false));
			if (PatchResp.status === 200) {
				navigate('/account-details');
			}
		} catch (error) {
			store.dispatch(actions.setLoading(false));
			toastr.error('Something went wrong, try again later');
			console.group('Error while updating User');
			console.error('Error:', error);
			console.groupEnd();
		}
	}
};

export default [confirmToken, setupUser];
