import { HeapTracking } from './heap';
import { IntercomTracking } from './intercom';

const heapTracking = new HeapTracking();
const intercomTracking = new IntercomTracking();

const onlyPrimitives = obj => {
	const primitives = ['string', 'number', 'boolean'];
	return Object.keys(obj).reduce((acc, key) => {
		if (primitives.includes(typeof obj[key])) {
			acc[key] = obj[key];
		}
		return acc;
	}, {});
};

export const TRACKING_ACTIONS = {
	load: 'load',
	boot: 'boot',
	trackPageView: 'trackPageView',
	identify: 'identify',
	track: 'track',
	trackEvent: 'trackEvent',
	updateUser: 'updateUser',
	logoutUser: 'logoutUser',
	updateEventData: 'updateEventData',
};

export const trackingEvent = (action, data) => {
	switch (action) {
		case TRACKING_ACTIONS.load: {
			const heapEnv = process.env.production ? '1539514402' : '656670118';
			const intercomWorkspace = process.env.production ? 'gbd7mpwj' : 'oaflgw21';

			if (!heapTracking.isLoaded) {
				heapTracking.load(heapEnv);
			}

			if (!intercomTracking.isLoaded) {
				intercomTracking.load(intercomWorkspace);
			}
			break;
		}
		case TRACKING_ACTIONS.boot: {
			intercomTracking.boot(data);
			break;
		}
		case TRACKING_ACTIONS.trackPageView: {
			intercomTracking.update();
			break;
		}
		case TRACKING_ACTIONS.identify:
			heapTracking.identify(data);
			break;
		case TRACKING_ACTIONS.updateUser: {
			let roles;
			if (!data?.roles?.loading && data?.roles?.content?.length) {
				roles = data?.roles?.content?.reduce((acc, { name, level }) => {
					acc[level] = name;
					return acc;
				}, {});
			} else {
				roles = {
					1: 'Guest',
					2: 'Space Viewer',
					3: 'Space Creator',
					4: 'Space Admin',
					5: 'Account Admin',
					6: 'Dig Employee',
					7: 'Dig Admin',
				};
			}

			let highestRole = 'Guest';
			let highestRoleLevel = 1;

			data?.privileges?.forEach(({ roleLevel }) => {
				if (roleLevel > highestRoleLevel) {
					highestRoleLevel = roleLevel;
				}
			});

			highestRole = roles[highestRoleLevel];

			let numberOfAccountObjects = 1;
			let accountsKey = `userAccounts`;
			const userAccountsString = data?.privileges?.reduce((acc, { displayName }) => {
				const accountString = `${displayName}`;

				if ((acc[accountsKey]?.length || 0) + accountString.length > 1024) {
					numberOfAccountObjects += 1;
					accountsKey = `userAccounts${numberOfAccountObjects}`;
				}

				if (!acc[accountsKey]) {
					acc[accountsKey] = accountString;
				} else {
					acc[accountsKey] = `${acc[accountsKey]}, ${accountString}`;
				}

				return acc;
			}, {});

			const includeKeysForIntercom = ['accountName', 'name', 'email', 'company'];
			const newIntercomData = Object.keys(data || {})
				.filter(key => includeKeysForIntercom.includes(key))
				.reduce((obj, key) => {
					obj[key] = data[key];
					return obj;
				}, {});

			const intercomData = {
				...intercomTracking.userData,
				...newIntercomData,
				highestRole,
			};

			intercomTracking.userData = intercomData;
			intercomTracking.update();

			const includeKeysForHeap = ['accountName', 'name', 'email'];
			const newHeapData = Object.keys(data || {})
				.filter(key => includeKeysForHeap.includes(key))
				.reduce((obj, key) => {
					obj[key] = data[key];
					return obj;
				}, {});
			const heapData = {
				...newHeapData,
				company: data?.company?.name || '',
				upsiideCompanySize: data?.company?.upsiideCompanySize || '',
			};

			heapTracking.userData = {
				...heapTracking.userData,
				...heapData,
				...userAccountsString,
				highestRole,
			};

			heapTracking.addUserProperties();
			break;
		}
		case TRACKING_ACTIONS.updateEventData:
			heapTracking.eventProperties = {
				...heapTracking.eventProperties,
				...data,
			};
			heapTracking.addEventProperties();
			break;
		case TRACKING_ACTIONS.logoutUser:
			heapTracking.userData = {};
			heapTracking.resetIdentity();

			intercomTracking.userData = {};
			intercomTracking.shutdown();
			break;
		case TRACKING_ACTIONS.track: {
			const { eventName, metadata, heap = true, intercom = true } = data;
			if (heap) {
				heapTracking.track(eventName, metadata);
			}
			if (intercom) {
				intercomTracking.track(eventName, metadata);
			}
			break;
		}
		default:
			break;
	}
};
