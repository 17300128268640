import LogicOptionsUtil from 'src/components/shared/LogicCondition/Util/LogicOptionsUtil';
import misc from 'src/utilities/misc';

export const generateLogicOptions = (studyId, sections, currentSection, audienceCollection, props) => {
	const { questionId, currentLanguage, language, triggerItemId, isAudienceLogic } = props;

	const audienceCollectionContent = audienceCollection?.content || {};

	const currentSectionContent = !isAudienceLogic
		? currentSection?.content
		: {
				...audienceCollectionContent,
				type: 'questions',
				questions: audienceCollectionContent.screeningQuestions,
		  };

	const { uuid: sectionUuid } = currentSectionContent || {};
	const isProductLogic = currentSectionContent?.type === 'swipe';

	const question = isAudienceLogic
		? audienceCollectionContent?.screeningQuestions?.find(q => q.id === questionId)
		: currentSectionContent?.questions?.find(q => q.id === questionId);

	const studyQuestionNumber = !isAudienceLogic
		? question?.studyQuestionNumber
		: (audienceCollectionContent?.screeningQuestions?.findIndex(q => q.id === questionId) || 0) + 1;

	const currentSectionIndex = !isAudienceLogic ? sections.content.findIndex(s => s.uuid === sectionUuid) : 0;

	const comparisonId = !Number.isNaN(triggerItemId) ? triggerItemId : questionId;

	const previousSections = !isAudienceLogic ? sections.content.slice(0, currentSectionIndex) : [];
	previousSections.push(currentSectionContent);
	// GET and enhance sections with itemIndex, itemShortName, sidebarIndex,
	const allSections = !isAudienceLogic ? sections : { content: [currentSectionContent] };
	const enhancedSection = LogicOptionsUtil.getEnhancedSectionData(previousSections, allSections, studyId);

	const sectionsAhead = !isAudienceLogic
		? sections.content.filter(({ order }) => order >= currentSectionContent.order)
		: [currentSectionContent];
	const destinationSections = LogicOptionsUtil.getEnhancedSectionData(sectionsAhead, allSections, studyId);

	const selectableSections = enhancedSection.filter(
		s => s.type === 'monadic_split' || s.type === 'questions' || s.type === 'swipe',
	);

	// GET items and attributes from allowed sections
	const options = [];

	let questionsList = [];
	let productsList = [];

	// Push the initial title option
	const productLogicHeading = 'Choose an idea';
	let questionLogicHeading = 'Choose a prior location';
	if (!isProductLogic) {
		if (question?.style === 'open-ended') {
			questionLogicHeading = 'Choose a location';
		}
	}
	const title = isProductLogic ? productLogicHeading : questionLogicHeading;

	options.push(LogicOptionsUtil.getSelectTitleOption(title, 0));

	const selectedLanguage = language || currentLanguage;

	/**
	 * DEFINE A LIST OF OPTIONS
	 * NOTE: Options contain Questions, Attributes AND Answers
	 */
	selectableSections.forEach((s, index) => {
		// TRIGGER
		if (isProductLogic) {
			// Render the products for the current section
			if (currentSectionContent.id === s.id) {
				// populate header
				if (s.products.length > 0) {
					// Push the heading to the top of the list – e.g. 'Questions – Fun Questions'
					const header = LogicOptionsUtil.getSelectHeadingOption(s.type, s.name);
					// Increment the index (The index is important for react-select rendering)
					options.push(header);
				}

				// Populate the select options
				s.products.forEach((item, sPindex) => {
					const translation = item.translations
						? item.translations.filter(t => t.languageCode === selectedLanguage)[0]
						: item;
					let translatedLabel = translation && translation.label ? translation.label : '';
					// * This conditional is here to accommodate new ideas format - translation will not have a label for ideas it has field one, two and three
					if (translatedLabel.length === 0) {
						translatedLabel = item.name;
					}
					const labelText =
						translatedLabel && translatedLabel.length === 0
							? ''
							: misc.cleanHtmlString(translatedLabel || '');
					const questionIdText = item.itemShortname || `${item.sidebarIndex + 1}-${item.itemIndex + 1}. `;
					const indexesUndefined = Number.isNaN(item.sidebarIndex) || Number.isNaN(item.itemIndex);
					const prefixText = indexesUndefined ? 'Q. ' : questionIdText;
					const nameText = `${prefixText}. ${labelText}`;
					const unexpandedClass = 'section';
					const expandedClass = sPindex + 1 === s.products.length ? 'question borderBottom' : 'question';

					const optionData = LogicOptionsUtil.getSelectQuestionItemOption(
						item,
						s,
						0,
						sections,
						unexpandedClass,
						prefixText,
						nameText,
						expandedClass,
					);

					options.push(optionData);
				});
			}
		} else {
			// Render the current and previous sections
			if (
				s.type === 'questions' ||
				(s.id === currentSectionContent.id && s.type === 'monadic_split' && s.questions)
			) {
				// If it's an open ended question remove those options otherwise just use the section questions
				questionsList = s.questions.slice().filter(q => q.style !== 'open-ended' && q.style !== 'heatmap');

				// Filter the section question list from the start until the current index + 1
				const slicedQuestions = questionsList.filter(q =>
					q?.studyQuestionNumber
						? q?.studyQuestionNumber <= studyQuestionNumber
						: q?.itemIndex <= studyQuestionNumber,
				);

				if (slicedQuestions.length > 0) {
					// Push the heading to the top of the list – e.g. 'Questions – Fun Questions'
					const header = LogicOptionsUtil.getSelectHeadingOption(
						isAudienceLogic ? 'Audience' : s.type,
						s.name,
					);
					options.push(header);
				}

				// Populate the select options
				slicedQuestions.forEach((item, sPindex) => {
					const translation = item.translations
						? item.translations.filter(t => t.languageCode === selectedLanguage)[0]
						: item;
					let translatedLabel = translation && translation.label ? translation.label : '';
					// * This conditional is here to accommodate new ideas format - translation will not have a label for ideas it has field one, two and three
					if (translatedLabel.length === 0) {
						translatedLabel = item.name;
					}
					const labelText =
						translatedLabel && translatedLabel.length === 0
							? ''
							: misc.cleanHtmlString(translatedLabel || '');
					const questionIdText = item.itemShortname || `${item.sidebarIndex + 1}-${item.itemIndex + 1}. `;
					const indexesUndefined = Number.isNaN(item.sidebarIndex) || Number.isNaN(item.itemIndex);
					const prefixText = indexesUndefined ? 'Q. ' : questionIdText;
					const nameText = `${prefixText}. ${labelText}`;
					const unexpandedClass = 'section';
					const expandedClass = sPindex + 1 === slicedQuestions.length ? 'question borderBottom' : 'question';

					const optionData = LogicOptionsUtil.getSelectQuestionItemOption(
						item,
						s,
						0,
						sections,
						unexpandedClass,
						prefixText,
						nameText,
						expandedClass,
					);

					options.push(optionData);
				});
			}
			if (s.type === 'swipe') {
				// Get the index of the current product
				const productIndex = s.products.findIndex(opt => opt.id === question.id);
				const pStartIndex = productIndex + 1;
				const pEndIndex = s.products.length;
				// Filter the section product list from the start until the current index + 1
				productsList = productIndex !== -1 ? s.products.slice().splice(pStartIndex, pEndIndex) : s.products;
				// Populate the select options

				if (productsList.length > 0) {
					// Push the heading to the top of the list – e.g. 'Questions – Fun Questions'
					const header = LogicOptionsUtil.getSelectHeadingOption(s.type, s.name);
					// Increment the index (The index is important for react-select rendering)
					options.push(header);
				}

				// Populate the select options
				productsList.forEach((item, sPindex) => {
					const translation = item.translations
						? item.translations.filter(t => t.languageCode === selectedLanguage)[0]
						: item;
					let translatedLabel = translation && translation.label ? translation.label : '';
					// * This conditional is here to accommodate new ideas format - translation will not have a label for ideas it has field one, two and three
					if (translatedLabel.length === 0) {
						translatedLabel = item.name;
					}
					const labelText =
						translatedLabel && translatedLabel.length === 0
							? ''
							: misc.cleanHtmlString(translatedLabel || '');
					const questionIdText = item.itemShortname || `${item.sidebarIndex + 1}-${item.itemIndex + 1}. `;
					const indexesUndefined = Number.isNaN(item.sidebarIndex) || Number.isNaN(item.itemIndex);
					const prefixText = indexesUndefined ? 'Q. ' : questionIdText;
					const nameText = `${prefixText}. ${labelText}`;
					const unexpandedClass = 'section';
					const expandedClass = sPindex + 1 === productsList.length ? 'question borderBottom' : 'question';

					const optionData = LogicOptionsUtil.getSelectQuestionItemOption(
						item,
						s,
						0,
						sections,
						unexpandedClass,
						prefixText,
						nameText,
						expandedClass,
					);

					options.push(optionData);
				});
			}
		}
	});

	/**
	 * DEFINE A LIST OF DESTINATIONS
	 */
	const destinations = [];

	destinations.push(LogicOptionsUtil.getSelectTitleOption('Choose a destination'));

	destinationSections.forEach((s, index) => {
		if (
			(s.type === 'questions' && currentSectionContent.type !== 'monadic_split') ||
			(s.type === 'monadic_split' && currentSectionContent.id === s.id)
		) {
			// Get the index of the current question
			const sectionItemId = question?.id || currentSectionContent?.id;

			const sQuestionIndex = s.questions.findIndex(opt => opt.id === sectionItemId);
			const qStartIndex = sQuestionIndex + 1;
			const qEndIndex = s.questions.length;
			// Filter the section question list from the start until the current index + 1
			questionsList = sQuestionIndex !== -1 ? s.questions.slice().splice(qStartIndex, qEndIndex) : s.questions;

			if (questionsList.length > 0) {
				// Push the heading to the top of the list – e.g. 'Questions – Fun Questions'
				const selectHeader = LogicOptionsUtil.getSelectHeadingOption(
					isAudienceLogic ? 'Audience' : s.type,
					s.name,
				);
				// Increment the index (The index is important for react-select rendering)
				destinations.push(selectHeader);
			}

			// Populate the select options
			questionsList.forEach((item, sPindex) => {
				const translation = item.translations
					? item.translations.filter(t => t.languageCode === selectedLanguage)[0]
					: item;
				let translatedLabel = translation && translation.label ? translation.label : '';
				// * This conditional is here to accommodate new ideas format - translation will not have a label for ideas it has field one, two and three
				if (translatedLabel.length === 0) {
					translatedLabel = item.name;
				}
				const labelText =
					translatedLabel && translatedLabel.length === 0 ? '' : misc.cleanHtmlString(translatedLabel || '');
				const questionIdText = item.itemShortname || `${item.sidebarIndex + 1}-${item.itemIndex + 1}. `;
				const indexesUndefined = Number.isNaN(item.sidebarIndex) || Number.isNaN(item.itemIndex);
				const prefixText = indexesUndefined ? 'Q. ' : questionIdText;
				const nameText = `${prefixText}. ${labelText}`;
				const unexpandedClass = 'section';
				const expandedClass = sPindex + 1 === questionsList.length ? 'question borderBottom' : 'question';

				const optionData = LogicOptionsUtil.getSelectQuestionItemOption(
					item,
					s,
					0,
					sections,
					unexpandedClass,
					prefixText,
					nameText,
					expandedClass,
				);

				destinations.push(optionData);
			});
		}
		if (
			s.type === 'monadic_split' &&
			currentSectionContent.id !== s.id &&
			currentSectionContent.type !== 'monadic_split'
		) {
			destinations.push(
				LogicOptionsUtil.getSelectOtherItemOptions(
					{ language: 0, currentLanguage: 0 },
					'Idea Split',
					s,
					`section-${s.id}`,
				),
			);
		}
		if (
			s.type === 'statement' &&
			currentSectionContent.id !== s.id &&
			currentSectionContent.type !== 'monadic_split'
		) {
			destinations.push(
				LogicOptionsUtil.getSelectOtherItemOptions(
					{ language: 0, currentLanguage: 0 },
					'Statement',
					s,
					`section-${s.id}`,
				),
			);
		}
		if (s.type === 'swipe' && s.id !== currentSectionContent.id && currentSectionContent.type !== 'monadic_split') {
			destinations.push(
				LogicOptionsUtil.getSelectOtherItemOptions(
					{ language: 0, currentLanguage: 0 },
					'Idea Screen',
					s,
					`section-${s.id}`,
				),
			);
		}
	});

	if (isAudienceLogic) {
		destinations.push(LogicOptionsUtil.getSelectDisqualificationOption('disqualify'));
	}

	/**
	 * DEFINE ATTRIBUTES ANS ANSWERS
	 */
	let answers = [];
	let attributes = [];
	let selectedItem = null;
	// let selectedItemIndex = 0;

	options.forEach((item, index) => {
		if (item.data.itemId && item.data.itemId === comparisonId) {
			answers = item.data.options;
			// selectedItemIndex = index;
			selectedItem = item;
		}

		// todo - make attributes work
		if (item.data.itemId && item.data.itemId === comparisonId) {
			attributes = item.data.attributes;
			// selectedItemIndex = index;
			selectedItem = item;
		}
	});

	// If we are evaluating question logic
	if (!isProductLogic) {
		// and the current question is an open-ended question
		const isOpenEnded = question?.style === 'open-ended';
		if (isOpenEnded && comparisonId === question.id) {
			const priorQuestions = [];

			currentSectionContent?.questions?.forEach((q, qIndex) => {
				if (q.id === comparisonId && currentSectionContent.questions[qIndex - 1]) {
					priorQuestions.push(currentSectionContent.questions[qIndex - 1]);
				}
			});

			// If a question was found then loop through the select options
			if (priorQuestions.length > 0) {
				// Loop through the select options
				options.forEach((i, iIndex) => {
					// If the item exists and you find an ID match
					if (Object.keys(i).length > 0 && i.data.itemId === priorQuestions[0].id) {
						// Set the answer select options, the currently selected index and the chosen item
						answers = i.data.options;
						attributes = i.data.attributes;

						// selectedItemIndex = iIndex;
						selectedItem = i;
					}
				});
			}
		}
	}

	// todo maybe consolidate getAnswersSelectOptions with disableSelectedAnswers
	const newOptions = LogicOptionsUtil.getAnswersSelectOptions(answers, language || currentLanguage || 'en');
	const newAttributes = LogicOptionsUtil.getAnswersSelectOptions(attributes, language || currentLanguage || 'en');

	return {
		sections: selectableSections,
		options,
		destinations,
		attributes: newAttributes,
		answers: newOptions,
		selectedItem,
		// selectedItemIndex,
	};
};
