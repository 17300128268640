import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { uniqBy, sortBy } from 'lodash';
import { MasonryLayout, Container, Row, Col } from '@upsiide/ui-components';
import Button from 'src/components/elements/Button';
import { element } from 'src/utilities/bem-cn';
import toastr from 'toastr';
import Divider from 'src/components/elements/Divider';
import { getStudyId } from 'src/domains/manage-study/selectors';
import Loader from 'src/components/shared/Loader';
import { getStudyId as getBlueprintStudyId } from 'src/domains/manage-blueprints/selectors';
import Modal from 'src/components/shared/Modal';
import { getAssetVariationUrl, pluralize } from 'src/utilities/misc';
import * as services from 'src/services';
import GallerySearchBar from '../Shared/GallerySearchBar';
import AssetGalleryAsset from '../Shared/AssetGalleryAsset';
import ScrollContainer from '../Shared/ScrollContainer';
import GalleryTabs from '../Shared/GalleryTabs';
import { useScroll, useFetchAssetsOnLoad, useResetDataOnUnMount } from '../hooks';

import './styles.scss';

const className = 'asset-gallery';
const el = element(className);

/**
 * Assets Service API
 */
const fetch = (searchString, limit, offset, studyId) => services.assetService.get(searchString, limit, offset, studyId);
const fetchOne = id => services.assetService.getOne(id);
const deleteAsset = id => services.assetService.delete(id);

/**
 * Assets Gallery
 */
const AssetGallery = ({
	multi,
	onChange,
	picked,
	setError,
	assets,
	setAssets,
	searchValue,
	setSearchValue,
	currentPageRef,
	tabs,
	setTabs,
	initialDataLoaded,
	setInitialDataLoaded,
	activeTab,
	setActiveTab,
	givenStudyId,
}) => {
	const scrollRef = useRef();
	const [scrollRefVisible, setScrollRefVisible] = useState(false);
	const [showDeleteAssetModal, setShowDeleteAssetModal] = useState(false);
	const [searchLoading, setSearchLoading] = useState(false);
	const [pickedAsset, setPickedAsset] = useState({});
	const [deletedAssets, setDeletedAssets] = useState([]);
	const oldTabRef = useRef(activeTab);

	const studyId = useSelector(getStudyId);
	const blueprintId = useSelector(getBlueprintStudyId);
	const studyBlueprintId = givenStudyId || studyId || blueprintId;

	const allFetched = useRef(false);
	const scrollLoading = useRef(false);

	const perPage = 30;

	const currentTab = useMemo(() => tabs.find(tab => tab.name === activeTab), [activeTab, tabs]);

	const add = useCallback(
		id => {
			onChange(picked.concat([id]).slice(-(multi ? 100 : 1)));
		},
		[multi, onChange, picked],
	);

	const isPicked = useCallback(id => picked.indexOf(id) > -1, [picked]);

	const remove = useCallback(
		id => {
			onChange(picked.filter($id => id !== $id));
		},
		[onChange, picked],
	);

	const toggle = useCallback(id => (isPicked(id) ? remove(id) : add(id)), [add, isPicked, remove]);

	const fetchAssets = useCallback(
		(search, tab = activeTab, refreshResults = false) => {
			if (allFetched.current) return;

			const isNewTab = activeTab !== oldTabRef.current;

			if ((scrollLoading.current || searchLoading) && !isNewTab) {
				return;
			}

			oldTabRef.current = activeTab;

			const offset = perPage * currentPageRef.current;

			scrollLoading.current = true;
			fetch(search, perPage, offset, tab === 'study-assets' ? studyBlueprintId : null)
				.then(results => {
					currentPageRef.current += 1;

					allFetched.current = allFetched.current || !results.assets || results.assets.length === 0;

					if (results.assets.length > 0) {
						if (refreshResults) {
							setAssets(results.assets);
						} else {
							setAssets(currentAssets => uniqBy(currentAssets.concat(results.assets), asset => asset.id));
						}
					} else if (results.assets.length === 0 && offset === 0) {
						setAssets([]);
					}

					const newTabs = tabs.map(tabItem =>
						tabItem.name === tab
							? {
									...tabItem,
									count: results.count || 0,
							  }
							: tabItem,
					);

					setTabs(newTabs);

					scrollLoading.current = false;
					setInitialDataLoaded(true);
					setSearchLoading(false);
				})
				.catch(error => {
					setError(error.message);
					setSearchLoading(false);
					scrollLoading.current = false;
				});
		},
		[
			activeTab,
			currentPageRef,
			searchLoading,
			setAssets,
			setError,
			setInitialDataLoaded,
			setTabs,
			studyBlueprintId,
			tabs,
		],
	);

	const resetAllThings = useCallback(() => {
		allFetched.current = false;
		currentPageRef.current = 0;
	}, [currentPageRef]);

	const handleOnSearch = useCallback(
		val => {
			resetAllThings();
			fetchAssets(val, activeTab, true);
		},
		[activeTab, fetchAssets, resetAllThings],
	);

	const handleSetActiveTab = useCallback(
		(tab, e) => {
			e.preventDefault();
			e.stopPropagation();
			// reset all the things
			resetAllThings();
			setAssets([]);
			setInitialDataLoaded(false);
			setActiveTab(tab);
		},
		[resetAllThings, setActiveTab, setAssets, setInitialDataLoaded],
	);

	const handleOnDelete = useCallback(
		(e, id) => {
			e.stopPropagation();

			setShowDeleteAssetModal(id);
		},
		[setShowDeleteAssetModal],
	);

	const handleIsSearching = useCallback(
		searching => {
			if (searching) {
				setAssets([]);
				setInitialDataLoaded(false);
			}
			setSearchLoading(searching);
		},
		[setAssets, setInitialDataLoaded],
	);

	const handleConfirmDeleteAsset = useCallback(
		id => {
			// remove from picked
			if (isPicked(id)) {
				remove(id);
			}
			// Optimistically remove asset
			setAssets(currentAssets => currentAssets.filter(asset => asset.id !== id));
			deleteAsset(id)
				.then(() => {
					// Add assetId to deletedAssets array
					setDeletedAssets([...deletedAssets, id]);
				})
				.catch(() => {
					toastr.error('Failed to delete asset. Please try again.');
				});

			// close modal
			setShowDeleteAssetModal(false);
		},
		[deletedAssets, isPicked, remove, setAssets],
	);

	useEffect(() => {
		if (picked && assets.length === 0) {
			picked.forEach(id => {
				if (!id) return;
				fetchOne(id).then(asset => {
					if (asset.mediaType !== 'video' && !asset.deletedFromGalleryAt) {
						setPickedAsset(asset);
					} else {
						// unselect asset if it's a video
						remove(asset.id);
					}
				});
			});
		}
		// deps are disabled as this is inteded to run on first load only
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [remove]);

	// reset on unmount
	useResetDataOnUnMount({ resetAllThings, setAssets, setInitialDataLoaded });

	// Initial load fetch assets
	useFetchAssetsOnLoad({ loading: scrollLoading.current, assets, fetchAssets, searchValue });

	// Scroll detection
	useScroll({ scrollRef, scrollRefVisible, fetchAssets, searchValue });

	const noAssetsString = useMemo(() => {
		let string = 'No images to see here';

		if (activeTab === 'study-assets') {
			string = `Uploaded images in this ${blueprintId ? 'template' : 'study'} will appear here`;
		}

		if (searchValue !== '') {
			string = 'No matches found';
		}

		return string;
	}, [activeTab, blueprintId, searchValue]);

	const orderedAssets = pickedAsset ? sortBy(assets, asset => (asset.id === pickedAsset.id ? 0 : 1)) : assets;

	const allAssets =
		assets.length > 0 ? uniqBy([pickedAsset, ...orderedAssets], concatedAsset => concatedAsset.id) : [];

	const finalAssets = allAssets.filter(asset => !deletedAssets.includes(asset.id));
	return (
		<div>
			<Divider customClasses={el('divider-top')} />

			<div className={el('header')}>
				<GalleryTabs tabs={tabs} setActiveTab={(val, e) => handleSetActiveTab(val, e)} activeTab={activeTab} />

				<GallerySearchBar
					onSearch={handleOnSearch}
					value={searchValue}
					setValue={setSearchValue}
					placeholder="Search images"
					isSearching={handleIsSearching}
				/>
			</div>

			<ScrollContainer
				ref={refEl => {
					scrollRef.current = refEl;
					setScrollRefVisible(!!refEl);
				}}
			>
				{currentTab.count > 0 && assets.length > 0 && (
					<div className={el('total-assets')}>
						{activeTab === 'study-assets'
							? `uploaded images in ${blueprintId ? 'template' : 'study'}`
							: 'all images'}{' '}
						<span>
							{currentTab.count} {pluralize(currentTab.count, 'image')}
						</span>
					</div>
				)}

				{finalAssets.length ? (
					<MasonryLayout cols={3} gap={8}>
						{finalAssets.map(asset => (
							<div className={el('item', isPicked(asset.id) ? 'selected' : '')} key={asset.id}>
								<AssetGalleryAsset
									id={asset.id}
									url={
										getAssetVariationUrl(asset, ['thumbnail']) ||
										'https://app.upsiide.com/public/images/signup/upsiide-logo.svg'
									}
									title={asset.title}
									onSelect={toggle}
									onDelete={activeTab === 'study-assets' ? e => handleOnDelete(e, asset.id) : null}
									selected={isPicked(asset.id)}
									isImage
									border
								/>
							</div>
						))}
					</MasonryLayout>
				) : (
					<>
						{!searchLoading && initialDataLoaded ? (
							<div className={el('no-images-found')}>
								<span>{noAssetsString}</span>
							</div>
						) : null}
					</>
				)}
			</ScrollContainer>

			{(searchLoading || !initialDataLoaded) && (
				<div className={el('loader')}>
					<Loader centered />
				</div>
			)}

			<Divider />

			<Modal
				show={Boolean(showDeleteAssetModal)}
				onClose={() => setShowDeleteAssetModal(false)}
				width={500}
				padding={24}
			>
				<Container className={el('modal-container')}>
					<Row>
						<Col justifyContent="flex-start">
							<span className={el('are-you-sure-heading')}>Delete Image</span>

							<span className={el('are-you-sure-body')}>
								Deleting an image will remove it from My Gallery. It won’t affect any studies using this
								image.
							</span>
						</Col>
					</Row>

					<Row margin="16px 0 0 0">
						<Col padding="0px" justifyContent="flex-end">
							<Button
								htmlType="button"
								label="Cancel"
								onClick={() => setShowDeleteAssetModal(false)}
								type="text"
								className={el('are-you-sure-button')}
							/>
							<Button
								htmlType="button"
								label="Delete"
								onClick={() => handleConfirmDeleteAsset(showDeleteAssetModal)}
								type="red"
								className={el('are-you-sure-button')}
							/>
						</Col>
					</Row>
				</Container>
			</Modal>
		</div>
	);
};

AssetGallery.propTypes = {
	multi: PropTypes.any,
	onChange: PropTypes.func,
	picked: PropTypes.array,
	setError: PropTypes.func,
	assets: PropTypes.array,
	setAssets: PropTypes.func,
	searchValue: PropTypes.string,
	setSearchValue: PropTypes.func,
	currentPageRef: PropTypes.any,
	tabs: PropTypes.array,
	setTabs: PropTypes.func,
	initialDataLoaded: PropTypes.bool,
	setInitialDataLoaded: PropTypes.func,
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
	givenStudyId: PropTypes.number,
};

export default AssetGallery;
