import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import cn from 'src/utilities/bem-cn';

const className = 'line-clamp-tool-tip';
const el = (name, mod) => cn(className, name, mod);

const LineClampTooltip = ({ tooltipHtml, delay = 1000, children }) => {
	const [needsTooltip, setNeedsTooltip] = useState(false);

	const refCallback = useCallback(node => {
		if (node?.scrollHeight > node?.clientHeight) {
			setNeedsTooltip(true);
		}
	}, []);

	if (needsTooltip) {
		return (
			<Tooltip
				className={el('tooltip')}
				animation="shift"
				animationFill={false}
				interactive
				trigger="mouseenter"
				position="top"
				theme="dark-blured"
				distance={2}
				html={tooltipHtml}
				delay={delay}
			>
				{children}
			</Tooltip>
		);
	}

	return React.cloneElement(children, {
		ref: refCallback,
	});
};

LineClampTooltip.propTypes = {
	tooltipHtml: PropTypes.any,
	delay: PropTypes.number,
	children: PropTypes.node,
};

export default LineClampTooltip;
