import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import { Iconof, Show } from '@upsiide/ui-components';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import misc, { getDuplicateAudienceTemplateName } from 'src/utilities/misc';
import Card from 'src/components/shared/Card/index';
import { audienceService, blueprintService } from 'src/services';
import logoUser from 'public/images/audience/user.png';
import logo from 'public/images/digSolutionsIcon.svg';
import NorthEastArrow from 'src/components/icons/NorthEastArrow';
import toastr from 'toastr';
import { useIsBlueprint, useIsMobile } from 'src/hooks';
import StudyTemplateModal from 'src/domains/manage-study/containers/StudyTemplateModal';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import * as manageBlueprintActions from 'src/domains/manage-blueprints/actions';
import * as manageStudyActions from 'src/domains/manage-study/actions';
import { createStudy } from 'src/domains/create-study/actions';
import { useSelector, useDispatch } from 'react-redux';
import * as authSelectors from 'src/domains/auth/selectors';
import * as accountSelectors from 'src/domains/account/selectors';
import Modal from 'src/components/shared/Modal';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import StudyImage from 'src/components/shared/SearchResults/shared/StudyImage/index.js';
import audienceStatusUtil from 'src/utilities/audiences-status-error-helper';
import { getCostMarkup } from 'src/domains/checkout/utilities/checkout';
import StudyCountryAndLanguages from 'src/components/shared/SearchResults/shared/StudyCountryAndLanguages/index.js';
import { forYouCategories } from 'src/domains/manage-study/containers/AudienceTemplateModal/audienceTemplateCategories.mock';
import { Highlighted } from '../SmartSearch';
import { urlParams } from '../SearchResults';
import { TemplateCardsContext } from './context';
import Loader from '../Loader';

import './styles.scss';

const initialTemplateData = {
	visible: false,
	selectedTemplate: null,
	step: 'templates',
};

const SelectTemplateWarningModal = () => {
	const {
		showApplyTemplateModal,
		setShowApplyTemplateModal,
		setApplyTemplateLoading,
		applyTemplateLoading,
		setTemplateToUse,
		onSelect,
		templateToUse,
		setSelectClicked,
	} = useContext(TemplateCardsContext);

	const el = (name, mod) => cn(className, name, mod);
	const className = 'select-template-warning-modal';

	const headerText = 'Apply Template';
	let bodyText = (
		<p className={el('apply-template')}>
			Study sections will be overwritten immediately. <span>This action cannot be undone.</span> Would you like to
			apply the template?
		</p>
	);

	if (templateToUse?.type === 'combined') {
		bodyText = (
			<p className={el('apply-template')}>
				All current Study and Audience content will be overwritten immediately.{' '}
				<span>This action cannot be undone.</span> Would you like to apply the template?
			</p>
		);
	} else if (templateToUse?.type === 'audience') {
		bodyText = (
			<p className={el('apply-template')}>
				Current Audience content will be overwritten immediately. <span>This action cannot be undone.</span>{' '}
				Would you like to apply the template?
			</p>
		);
	}

	return (
		<Modal
			show={showApplyTemplateModal}
			width={600}
			padding={0}
			onClose={() => {
				setShowApplyTemplateModal(false);
				setApplyTemplateLoading(false);
				setTemplateToUse(null);
			}}
			customModalClass="apply-template-modal"
		>
			<AreYouSureForm
				onCancel={() => {
					setShowApplyTemplateModal(false);
					setApplyTemplateLoading(false);
					setTemplateToUse(null);
					setSelectClicked(false);
				}}
				onConfirm={async () => {
					setApplyTemplateLoading(true);
					setShowApplyTemplateModal(false);
					await onSelect(templateToUse, true);
					toastr.success('Template has been applied');
					setApplyTemplateLoading(false);
					setTemplateToUse(null);
				}}
				headerText={headerText}
				bodyText={bodyText}
				confirmText="Apply"
				confirmState={applyTemplateLoading ? 'loading' : 'active'}
				confirmType="red"
			/>
		</Modal>
	);
};

export const TemplateCardsProvider = ({
	onClosedTemplateModalCallback,
	onDuplicatedTemplateCallback,
	onDeletedTemplateCallback,
	onFavoriteCallback,
	onSelectCallback,
	onClosedPreviewModalCallback,
	onClosedEditModalCallback,
	onPreviewedCallback,
	previewModalMode = false,
	isWizard = false,
	bypassPreview = false,
	useTemplateCallback = null,
	defaultActiveTab = 'studyTemplate',
	disableMoreOptions = false,
	children,
}) => {
	const { isBlueprint } = useIsBlueprint();

	const className = 'template-cards-context';
	const el = (name, mod) => cn(className, name, mod);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const isInLaunch = location?.pathname?.includes('/audiences');
	const clientId = useSelector(authSelectors.getClientId);

	const domain = new URL(window.location.href);

	const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
	const [templateToDelete, setTemplateToDelete] = useState(null);
	const [selectClicked, setSelectClicked] = useState(false);
	const [templateToUse, setTemplateToUse] = useState(null);
	const [showApplyTemplateModal, setShowApplyTemplateModal] = useState(false);
	const [applyTemplateLoading, setApplyTemplateLoading] = useState(false);
	const [savedAudienceTemplateData, setSavedAudienceTemplateData] = useState(null);
	const [currentTab, setCurrentTab] = useState('overview');
	const [useTemplateLoading, setUseTemplateLoading] = useState(false);

	const [onDuplicatedTemplateCallbackOverride, setonDuplicatedTemplateCallbackOverride] = useState(null);
	const [onDeletedTemplateCallbackOverride, setonDeletedTemplateCallbackOverride] = useState(null);

	const [templateModalData, setTemplateModalData] = useState(initialTemplateData);

	// Needed for actions within then manage study domain
	const selector = isBlueprint ? manageBlueprintSelectors : manageStudySelectors;
	const currentStudy = useSelector(selector.getStudy);
	const currentStudySections = useSelector(selector.getSections);
	const currentStudyCollections = useSelector(selector.getAudienceCollections);

	const study = useSelector(manageStudySelectors.getStudy);

	const actions = isBlueprint ? manageBlueprintActions : manageStudyActions;

	const setAudienceCollection = a => dispatch(actions.setAudienceCollection(a));
	const fetchCollections = useCallback(
		studyId => dispatch(actions.fetchAllAudienceCollection(studyId)),
		[actions, dispatch],
	);
	const validateStudy = studyId => dispatch(actions.validateStudy(studyId));
	const onEditTemplate = useCallback(
		async (template, isMultiStep = false) => {
			setSelectClicked(true);

			if (template?.type !== 'audience') {
				navigate(`/templates/${template?.uuid || template?.id}/create`);
			} else {
				try {
					const audienceTemplateToEdit = await audienceService.getCollection(template.studyId, template.id);
					setTemplateModalData(prev => ({
						...prev,
						selectedTemplate: audienceTemplateToEdit.data,
						visible: true,
						step: 'editTemplate',
						isMultiStep,
					}));

					setSelectClicked(false);
				} catch (e) {
					console.error(e);
				}
			}
		},
		[navigate],
	);

	const onDuplicateTemplate = useCallback(
		async (template, blueprintAccessLevel = 'space', copyToMyTemplates = false, errorCallback = null) => {
			try {
				if (template.type !== 'audience') {
					await blueprintService
						.createBlueprintFromStudyOrDuplicate(template.id, clientId, blueprintAccessLevel, false)
						.then(res => {
							if (copyToMyTemplates) {
								toastr.success(
									`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
										<p style="font-size: 16px;">Template copied to My Templates!</p>
										<a style="color: #008855; padding-left:54px; font-size: 14px; font-weight: 700" href="http://${domain.host}/templates/${res?.data?.id}/create">View</a>
									</div>`,
								);
							} else {
								toastr.success(
									`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
										<p style="font-size: 16px;">Study has been duplicated!</p>
										<a style="color: #008855; padding-left:54px; font-size: 14px; font-weight: 700" href="http://${domain.host}/templates/${res?.data?.id}/create">View</a>
									</div>`,
								);
							}

							if (
								typeof onDuplicatedTemplateCallback === 'function' &&
								!onDuplicatedTemplateCallbackOverride
							) {
								onDuplicatedTemplateCallback();
							} else if (onDuplicatedTemplateCallbackOverride) {
								onDuplicatedTemplateCallbackOverride();
							}
						});
				} else {
					await audienceService.saveCollectionAsTemplate(template.studyId, {
						collectionId: template.id,
						languageCode: 'en',
						description: template.description || '',
						name: getDuplicateAudienceTemplateName(template.name),
					});
					if (copyToMyTemplates) {
						toastr.success(
							`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
								<p style="font-size: 16px;">Template copied to My Templates!</p>
							</div>`,
						);
					} else {
						toastr.success(
							`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
								<p style="font-size: 16px;">Audience has been duplicated!</p>
							</div>`,
						);
					}
					if (typeof onDuplicatedTemplateCallback === 'function' && !onDuplicatedTemplateCallbackOverride) {
						onDuplicatedTemplateCallback();
					} else if (typeof onDuplicatedTemplateCallbackOverride === 'function') {
						onDuplicatedTemplateCallbackOverride();
					}
				}
				if (templateModalData?.visible) {
					handleSetStep('templates', { category: 'All Templates' });
				}
			} catch (e) {
				console.error(e);
				if (errorCallback) errorCallback();
			}
		},
		[
			clientId,
			domain.host,
			onDuplicatedTemplateCallback,
			onDuplicatedTemplateCallbackOverride,
			handleSetStep,
			templateModalData?.visible,
		],
	);

	const handleDeleteClick = useCallback(template => {
		setTemplateToDelete(template);
		setShowDeleteTemplateModal(true);
	}, []);

	const onDeleteTemplate = useCallback(
		async template => {
			try {
				if (template?.type !== 'audience') {
					await blueprintService.deleteBlueprint(template.id, clientId);
				} else {
					await audienceService.deleteCollection(template.studyId, template.id);
				}

				if (typeof onDeletedTemplateCallback === 'function' && !onDeletedTemplateCallbackOverride) {
					onDeletedTemplateCallback(template);
				} else if (typeof onDeletedTemplateCallbackOverride === 'function') {
					onDeletedTemplateCallbackOverride(template);
				}
				if (templateModalData?.visible) {
					handleSetStep('templates');
				}
			} catch (e) {
				console.error(e);
			}
		},
		[
			clientId,
			onDeletedTemplateCallback,
			onDeletedTemplateCallbackOverride,
			handleSetStep,
			templateModalData?.visible,
		],
	);

	const onFavorite = useCallback(
		async (template, study, favorite) => {
			if (template?.type !== 'audience') {
				await blueprintService.saveFavorite(template?.id, {
					value: favorite,
				});
			} else {
				await audienceService.saveFavorite(study?.id, {
					collectionId: template?.id,
					value: favorite,
				});
			}

			if (typeof onFavoriteCallback === 'function') {
				onFavoriteCallback(template, favorite);
			}
		},
		[onFavoriteCallback],
	);

	const onSelect = useCallback(
		async (template, bypassSafetyCheck = false) => {
			if (isBlueprint && isInLaunch) {
				setSelectClicked(true);
				const hasContent = !!(
					currentStudyCollections?.content?.[0]?.demographicGroups?.length ||
					currentStudyCollections?.content?.[0]?.screeningQuestions?.length
				);
				if (hasContent) {
					if (!bypassSafetyCheck) {
						setTemplateToUse(template);
						setShowApplyTemplateModal(true);
						setSelectClicked(false);
						return;
					}
					await audienceService.deleteCollection(currentStudy?.id, currentStudyCollections?.content?.[0]?.id);
				} else if (currentStudyCollections?.content?.length) {
					await audienceService.deleteCollection(currentStudy?.id, currentStudyCollections?.content?.[0]?.id);
				}

				const { data: collection } = await audienceService.createCollectionFromTemplate(
					currentStudy?.id,
					template?.id,
					null,
				);

				setAudienceCollection({ content: collection });
				const updatedStudy = await blueprintService.getBlueprint(currentStudy?.id);

				dispatch(actions.setStudy(updatedStudy.data));

				validateStudy(currentStudy?.id);
				fetchCollections(currentStudy?.id);
				navigate(`../../audiences`);
			} else {
				let canOverwrite = false;
				let skipConfirmation = false;
				setSelectClicked(true);

				const numSections = currentStudySections?.content?.length || 0;
				const numCollections = currentStudyCollections?.content?.length || 0;

				if (currentStudy && !bypassSafetyCheck) {
					// apply collections
					if (template?.type === 'audience' && numCollections === 0) {
						await audienceService.createCollectionFromTemplate(currentStudy?.id, template?.id, null);
						navigate(`/studies/${currentStudy?.id}/audiences`);
						window.location.reload();
						return;
					}

					// overwrite whole thing
					if (
						(template?.type === 'combined' || template?.type === 'study') &&
						numSections === 0 &&
						numCollections === 0
					) {
						canOverwrite = true;
					}

					// only apply study sections, maintain collections
					if (template?.type === 'study' && numSections === 0 && numCollections > 0) {
						try {
							setUseTemplateLoading(true);
							await blueprintService.overwriteStudyWithBlueprint(currentStudy?.id, template.id);
							navigate(`/studies/${currentStudy?.id}/create`);
							skipConfirmation = true;
							setTemplateModalData(prev => ({
								...prev,
								visible: false,
							}));
							setSelectClicked(false);
							setUseTemplateLoading(false);
						} catch (e) {
							console.error(e);
							toastr.error('Error applying study template');
							setUseTemplateLoading(false);
						}
					}

					// Show overwrite confirmation modal
					if (!canOverwrite && !skipConfirmation) {
						setTemplateToUse(template);
						setShowApplyTemplateModal(true);
						setSelectClicked(false);
						setTemplateModalData(prev => ({
							...prev,
							visible: false,
						}));
					}
				} else if (currentStudy && bypassSafetyCheck) {
					// Checks have been confirmed and accepted
					try {
						if (template.type === 'audience') {
							setUseTemplateLoading(true);
							if (numCollections > 0) {
								await audienceService.deleteCollection(
									currentStudy?.id,
									currentStudyCollections?.content[0]?.id,
								);
							}
							const collectionResult = await audienceService.createCollectionFromTemplate(
								currentStudy?.id,
								template?.id,
								study?.language || study?.languages?.[0],
							);
							dispatch(
								manageStudyActions.setAudienceCollection({
									...currentStudyCollections,
									content: collectionResult.data,
								}),
							);
							dispatch(
								manageStudyActions.setAudienceCollections({
									...currentStudyCollections,
									content: [{ ...collectionResult.data }],
								}),
							);
							navigate(`/studies/${currentStudy?.id}/audiences`);
							setUseTemplateLoading(false);
						} else {
							setUseTemplateLoading(true);
							await blueprintService.overwriteStudyWithBlueprint(currentStudy?.id, template.id);
							navigate(`/studies/${currentStudy?.id}/create`);
							setUseTemplateLoading(false);
						}

						// stop
						setSelectClicked(false);
						return;
					} catch (e) {
						console.error(e);
					}
				} else {
					// Proceed with creating study / audience
					canOverwrite = true;
				}

				if (canOverwrite) {
					setSelectClicked(true);

					// NEW STUDY POST - CREATE BASED ON AUDIENCE TEMPLATE
					if (template.type === 'audience') {
						dispatch(
							createStudy(
								{
									name: 'Untitled Study',
									description: '',
									defaultLanguage: 'en',
									translations: [
										{
											introText: '',
											languageCode: 'en',
										},
									],
									settings: {
										layout: 'two-field',
										commitmentLayout: 'field-one',
										mobileOnly: false,
										commitments: true,
										sendWinningProduct: false,
										sendLikedProducts: false,
										enableDetailView: true,
										redirectUrl: null,
										productIndex: 0,
										enableBarChart: true,
										enableIdeaMap: true,
										enableQuadrantChart: true,
										enableOptimizer: false,
										enableVirtualMarkets: false,
										optimizerUrl: null,
										virtualMarketsUrl: null,
										useSampleProvider: false,
										preStudyCheck: true,
									},
									collectionTemplateId: template.id,
								},
								'audiences',
							),
						);
					} else {
						dispatch(
							createStudy({
								templateBlueprintId: template.id,
							}),
						);
					}

					if (typeof onSelectCallback === 'function') {
						onSelectCallback(template);
					}
				}
			}
			dispatch(manageStudyActions.setSelectedTemplateCard(null));
		},
		[
			isBlueprint,
			currentStudyCollections,
			currentStudy,
			setAudienceCollection,
			dispatch,
			actions,
			validateStudy,
			fetchCollections,
			navigate,
			currentStudySections,
			onSelectCallback,
			isInLaunch,
		],
	);

	const onCloseTemplateModal = useCallback(() => {
		handleResetData();

		if (templateModalData.step === 'templates' && typeof onClosedTemplateModalCallback === 'function') {
			onClosedTemplateModalCallback();
		} else if (templateModalData.step === 'editTemplate' && typeof onClosedEditModalCallback === 'function') {
			onClosedEditModalCallback();
		} else if (templateModalData.step === 'preview' && typeof onClosedPreviewModalCallback === 'function') {
			onClosedPreviewModalCallback();
		}
	}, [
		handleResetData,
		templateModalData.step,
		onClosedTemplateModalCallback,
		onClosedEditModalCallback,
		onClosedPreviewModalCallback,
	]);

	const onShowPreview = useCallback(
		(template, additional) => {
			if (!bypassPreview) {
				handleSetStep('preview', {
					...templateModalData,
					...additional,
					visible: true,
					selectedTemplate: template,
				});
			}

			if (typeof onPreviewedCallback === 'function') {
				onPreviewedCallback(template);
			}
		},
		[bypassPreview, handleSetStep, onPreviewedCallback, templateModalData],
	);

	const handleSetStep = useCallback((step, additional) => {
		setTemplateModalData(prev => ({
			...prev,
			...additional,
			step,
			visible: true,
		}));
	}, []);

	const setSelectedTemplate = useCallback(template => {
		setTemplateModalData(prev => ({
			...prev,
			selectedTemplate: template,
		}));
	}, []);

	const handleResetData = useCallback(() => {
		setTemplateModalData({ ...initialTemplateData });
		setCurrentTab('overview');
	}, []);

	const handleSaveStudyTemplate = useCallback(
		async (cloneCollection, ignoreStudySections, name) => {
			try {
				const studyId = currentStudy?.id || templateModalData?.selectedTemplate?.id;

				await blueprintService
					.createBlueprintFromStudyOrDuplicate(
						studyId,
						clientId,
						'space',
						false,
						cloneCollection,
						ignoreStudySections,
						name,
					)
					.then(data => {
						toastr.success(
							`<a href='${window.location.origin}/templates/${data.data.uuid}/create' class='template-saved-message' style="color:#5cc09f;"}>
								Template Saved, click here to edit
							</a>`,
						);
						setTemplateModalData({ ...initialTemplateData });
					});
			} catch (e) {
				toastr.error('Something went wrong with creating the template');
				console.error(e);
			}
		},
		[currentStudy, templateModalData, clientId],
	);

	const saveAudienceAsTemplate = useCallback(
		async name => {
			if (templateModalData?.selectedTemplate?.audienceCollectionId) {
				try {
					audienceService
						.saveCollectionAsTemplate(templateModalData?.selectedTemplate?.id, {
							collectionId: templateModalData?.selectedTemplate?.audienceCollectionId,
							languageCode: study?.language || study?.languages[0],
							name,
						})
						.then(async result => {
							const { data } = result;
							const savedAudienceCollectionTemplate = await audienceService.getCollection(
								data.studyId,
								data.id,
							);
							setSavedAudienceTemplateData(savedAudienceCollectionTemplate.data);
							toastr.success(`Audience Template Created Successfully, click here to edit.`, null, {
								onclick: () =>
									handleSetStep('editTemplate', {
										selectedTemplate: savedAudienceCollectionTemplate.data,
										visible: true,
										step: 'editTemplate',
										isMultiStep: false,
									}),
							});
							setTemplateModalData({ ...initialTemplateData });
						});
				} catch (err) {
					toastr.error('Error saving audience as a template');
					console.error(err);
				}
			} else {
				console.error('Audience collection id not found');
				toastr.error('Error saving audience as template');
			}
		},
		[study?.language, templateModalData],
	);

	const saveTemplateCallback = useCallback(
		(saveStudy, saveAudience, name = null) => {
			const cloneCollection = false;
			const ignoreStudySections = false;
			if (saveStudy && !saveAudience) {
				handleSaveStudyTemplate(cloneCollection, ignoreStudySections, name);
			} else if (!saveStudy && saveAudience) {
				saveAudienceAsTemplate(name);
			}
		},
		[handleSaveStudyTemplate, saveAudienceAsTemplate],
	);

	const contextValue = useMemo(
		() => ({
			onEditTemplate,
			handleDeleteClick,
			onDuplicateTemplate,
			onDeleteTemplate,
			onCloseTemplateModal,
			onShowPreview,
			onFavorite,
			onSelect,
			setTemplateModalData,
			templateModalData,
			setSelectedTemplate,
			handleSetStep,
			showApplyTemplateModal,
			setShowApplyTemplateModal,
			setApplyTemplateLoading,
			applyTemplateLoading,
			setTemplateToUse,
			templateToUse,
			setonDuplicatedTemplateCallbackOverride,
			setonDeletedTemplateCallbackOverride,
			setSelectClicked,
			selectClicked,
			saveTemplateCallback,
			disableMoreOptions,
			currentTab,
			setCurrentTab,
			useTemplateLoading,
		}),
		[
			onEditTemplate,
			handleDeleteClick,
			onDuplicateTemplate,
			onDeleteTemplate,
			onCloseTemplateModal,
			onShowPreview,
			onFavorite,
			onSelect,
			setTemplateModalData,
			templateModalData,
			setSelectedTemplate,
			handleSetStep,
			showApplyTemplateModal,
			setShowApplyTemplateModal,
			setApplyTemplateLoading,
			applyTemplateLoading,
			setTemplateToUse,
			templateToUse,
			setonDuplicatedTemplateCallbackOverride,
			setonDeletedTemplateCallbackOverride,
			setSelectClicked,
			selectClicked,
			saveTemplateCallback,
			disableMoreOptions,
			currentTab,
			setCurrentTab,
			useTemplateLoading,
		],
	);

	return (
		<TemplateCardsContext.Provider value={contextValue}>
			{selectClicked && (
				<div className={el('selected-loader')}>
					<Loader centered />
				</div>
			)}

			<SelectTemplateWarningModal />

			<StudyTemplateModal
				onClose={onCloseTemplateModal}
				data={templateModalData}
				setData={setTemplateModalData}
				resetData={handleResetData}
				setTemplate={setSelectedTemplate}
				selectClicked={selectClicked}
				step={templateModalData.step}
				setStep={handleSetStep}
				previewModalMode={previewModalMode}
				defaultActiveTab={defaultActiveTab}
				saveTemplateCallback={saveTemplateCallback}
				useTemplateCallback={useTemplateCallback}
				isWizard={isWizard}
				currentTab={currentTab}
				setCurrentTab={setCurrentTab}
			/>
			<Modal
				show={showDeleteTemplateModal}
				width={600}
				padding={0}
				onClose={() => setShowDeleteTemplateModal(false)}
			>
				<AreYouSureForm
					headerText="Delete Template"
					bodyText="The template will be deleted permanently. Are you sure you want to delete it?"
					confirmText="Delete"
					cancelText="Cancel"
					onConfirm={() => {
						onDeleteTemplate(templateToDelete);
						setShowDeleteTemplateModal(false);
					}}
					onCancel={() => setShowDeleteTemplateModal(false)}
				/>
			</Modal>

			{children}
		</TemplateCardsContext.Provider>
	);
};
TemplateCardsProvider.propTypes = {
	onClosedTemplateModalCallback: PropTypes.func,
	onDuplicatedTemplateCallback: PropTypes.func,
	onDeletedTemplateCallback: PropTypes.func,
	onFavoriteCallback: PropTypes.func,
	onSelectCallback: PropTypes.func,
	onClosedPreviewModalCallback: PropTypes.func,
	onClosedEditModalCallback: PropTypes.func,
	onPreviewedCallback: PropTypes.func,
	bypassPreview: PropTypes.bool,
	previewModalMode: PropTypes.bool,
	isWizard: PropTypes.bool,
	useTemplateCallback: PropTypes.any,
	children: PropTypes.any,
	defaultActiveTab: PropTypes.string,
	disableMoreOptions: PropTypes.bool,
};

const TemplateCard = ({
	id,
	uuid,
	name,
	study,
	description,
	languages,
	countryIds,
	isFavorite,
	cardType,
	isGlobal,
	ownerName,
	template,
	previewMultiStep = true,
	isWizard = false,
	preSelectedStudyTemplateId = null,
	selectedStudyTemplateId = null,
	preSelectedAudienceTemplateId = null,
	selectedAudienceTemplateId = null,
	wizardCardOnClick = null,
}) => {
	const dispatch = useDispatch();
	const isTabletWidth = useIsMobile(960);
	const { onEditTemplate, onDuplicateTemplate, handleDeleteClick, onFavorite, onShowPreview, onSelect } =
		useContext(TemplateCardsContext);

	const containsLiveOrCompleteGroup =
		audienceStatusUtil.haveCompleteAudienceGroups() || audienceStatusUtil.haveLiveAudienceGroups();

	const [searchParams, setSearchParams] = useSearchParams();

	const colorCode = template?.category?.color || null;

	const account = useSelector(state => accountSelectors.getAccount(state));

	const isOwner = useMemo(
		() =>
			account?.content?.ownerUuid === template?.owner?.uuid ||
			!!account?.content?.clients?.find(client => client?.uuid === template?.owner?.uuid),
		[template, account],
	);

	const [showEllipsisMenu, setShowEllipsisMenu] = useState(false);
	const searchString = searchParams.get(urlParams.query);
	const className = 'template-card';
	const el = (elName, mod) => cn(className, elName, mod);
	const [isFavoriteLocal, setIsFavoriteLocal] = useState(isFavorite || study?.isFavorite || template?.isFavorite);

	useEffect(() => {
		setIsFavoriteLocal(study?.isFavorite || template?.isFavorite);
	}, [study, template]);

	const selectedTemplateCard = useSelector(manageStudySelectors.getSelectedTemplateCard);

	const isCardSelected = useMemo(
		() => selectedTemplateCard?.id === template?.id,
		[selectedTemplateCard?.id, template?.id],
	);

	const isWizardSelected = useMemo(() => {
		if (template?.type === 'study') {
			if (preSelectedStudyTemplateId) {
				if (preSelectedStudyTemplateId === template?.id) {
					return true;
				}
			}
			if (selectedStudyTemplateId && !preSelectedStudyTemplateId) {
				if (selectedStudyTemplateId === template?.id) {
					return true;
				}
			}
		} else if (template?.type === 'audience') {
			if (preSelectedAudienceTemplateId) {
				if (preSelectedAudienceTemplateId === template?.id) {
					return true;
				}
			}
			if (selectedAudienceTemplateId && preSelectedAudienceTemplateId) {
				if (selectedAudienceTemplateId === template?.id) {
					return true;
				}
			}
		}
		return false;
	}, [
		preSelectedStudyTemplateId,
		selectedStudyTemplateId,
		template?.id,
		preSelectedAudienceTemplateId,
		selectedAudienceTemplateId,
		template?.type,
	]);

	// Type
	let type = 'Study';
	if (cardType === 'audienceTemplate' || cardType === 'audience') {
		type = 'Audience';
	}
	if (cardType === 'combinedTemplate' || cardType === 'combined') {
		type = 'Study + Audience';
	}

	// Owner
	let authorLogo = logoUser;
	let author = ownerName;
	const categoryLabel = template?.category?.label;
	if (isGlobal) {
		author = 'Dig Insights';
		authorLogo = logo;
	} else if (categoryLabel) {
		author = categoryLabel;
		if (categoryLabel === 'Dig Solutions') {
			authorLogo = '/public/images/digSolutionsIcon.svg';
		} else {
			authorLogo = misc.getAssetVariationUrl(template?.category?.asset, ['thumbnail']) || authorLogo;
		}
	}

	const getPlaceholderName = useCallback(() => {
		if (template.type === 'audience') {
			return 'Untitled Audience';
		}
		return 'Untitled Study';
	}, [template]);

	const handleOnFavorite = async favorite => {
		try {
			setIsFavoriteLocal(favorite);
			onFavorite(template, study, favorite);
		} catch (error) {
			setIsFavoriteLocal(!favorite);
			console.error(error);
		}
	};

	const renderEllipsisMenu = useCallback(
		temp => (
			<div className={el('menu')}>
				<div
					onKeyDown={() => {}}
					tabIndex={0}
					role="button"
					className={el('menu-button')}
					onClick={() => {
						setShowEllipsisMenu(false);
						onEditTemplate(temp, previewMultiStep);
					}}
				>
					Edit
				</div>
				<div
					onKeyDown={() => {}}
					tabIndex={0}
					role="button"
					className={`${el('menu-button')}`}
					onClick={() => {
						setShowEllipsisMenu(false);
						onDuplicateTemplate(temp);
					}}
				>
					Duplicate
				</div>

				<div
					onKeyDown={() => {}}
					tabIndex={0}
					role="button"
					className={`${el('menu-button')} ${el('delete-button')}`}
					onClick={() => {
						setShowEllipsisMenu(false);
						handleDeleteClick(temp);
					}}
				>
					Delete
				</div>
			</div>
		),
		[onEditTemplate, previewMultiStep, onDuplicateTemplate, handleDeleteClick, template, onSelect],
	);

	const renderAudienceGroupText = () => {
		const { groups } = template;
		if (!groups) return false;
		return `${groups} group${groups > 1 ? 's' : ''}`;
	};
	const renderAudienceQuestionText = () => {
		const { questions } = template;
		if (!questions) return false;
		return `${questions} question${questions > 1 ? 's' : ''}`;
	};

	const getAudienceDesc = () => {
		const { groups, questions } = template;
		if (groups && questions)
			return `This template contains ${renderAudienceGroupText()} and ${renderAudienceQuestionText()}.`;
		if (groups && !questions) return `This template contains ${renderAudienceGroupText()}.`;
		if (!groups && questions) return `This template contains ${renderAudienceQuestionText()}.`;
		return `This template does not contain any group or question.`;
	};

	const renderDescription = () => {
		if (description?.length || template?.description?.length)
			return <span className={el('description')}>{description || template?.description}</span>;
		if (cardType === 'audience') return <span className={el('description')}>{getAudienceDesc()}</span>;
		return null;
	};

	const lengthOfInterviewLabel = lengthOfInterview => {
		if (lengthOfInterview <= 5) return '1-5';
		if (lengthOfInterview <= 10) return '6-10';
		if (lengthOfInterview <= 15) return '11-15';
		return '15+';
	};

	return (
		<Card
			variant="study"
			className={`${className} ${isCardSelected || isWizardSelected ? 'wizard-selected-template-card' : ''}`}
			onClick={e => {
				if (
					!e.target.classList.contains('tio-more_vertical') &&
					!e.target.classList.contains('tio-star') &&
					!e.target.classList.contains('tio-star_outlined') &&
					!e.target.classList.contains('template-card__view-details')
				) {
					if (isTabletWidth) {
						return;
					}
					if (isWizard) {
						wizardCardOnClick();
					} else if (template?.id === selectedTemplateCard?.id)
						dispatch(manageStudyActions.setSelectedTemplateCard(null));
					else dispatch(manageStudyActions.setSelectedTemplateCard(template));
				}
			}}
		>
			<div
				className={el('left-container')}
				style={
					colorCode && {
						background: `radial-gradient(at top left, ${colorCode}0D 1%, ${colorCode}05 30%, #fff 45%, #fff 100%)`,
						borderTopLeftRadius: '10px',
						borderBottomLeftRadius: '10px',
					}
				}
			>
				{colorCode && (
					// empty div with height and width for the colored bar
					// eslint-disable-next-line
					<div
						className={el('left-container-color-bar')}
						style={colorCode && { background: `${colorCode}` }}
					></div>
				)}
				<div className={el('left-container-content')}>
					<div className={el('image-text-container')}>
						{((template?.asset && template?.assetId) || template?.emoji) && (
							<StudyImage asset={template?.asset} emoji={template?.emoji} size="large" />
						)}
						<div className={el('title-desc-container')}>
							<div
								className={`${el('title')} ${!name && !template?.name ? el('placeholder-title') : ''}`}
							>
								<Highlighted
									text={!name && !template?.name ? getPlaceholderName() : name || template?.name}
									highlight={searchString}
									highlightClassName="results-table__highlighted-result"
								/>
							</div>
							{renderDescription() ? (
								<div className={el('description-container')}>{renderDescription()}</div>
							) : null}
							<Show above={960}>
								<div
									className={el('view-details')}
									onClick={() => {
										onShowPreview(template, { isMultiStep: previewMultiStep });
									}}
									onKeyDown={e => {
										if (e.key === 'Enter') {
											onShowPreview(template, { isMultiStep: previewMultiStep });
										}
									}}
									role="button"
									tabIndex="0"
								>
									View details
									<NorthEastArrow className={el('arrow-icon')} />
								</div>
							</Show>
						</div>
					</div>
					<div className={el('owner-lang-sections-container')}>
						<div className={el('owner-language')}>
							<Show above={960}>
								<Iconof
									className={`${el('wizard-select-icon')} ${
										isCardSelected || isWizardSelected ? el('selected-icon') : ''
									}`}
									icon={isCardSelected || isWizardSelected ? 'checkmark_circle' : 'circle_outlined'}
								/>
							</Show>
							<div className={el('createdBy')}>
								<div className={el('logoContainer')}>
									<img src={authorLogo} alt="logo" />
								</div>
								<span className={el('author')}>
									<Tooltip
										position="top"
										animation="shift"
										animationFill={false}
										theme="dark-blured author-tooltip"
										html={<div className={el('author-tooltip')}>{author}</div>}
										interactive
										trigger="mouseenter"
									>
										{author}
									</Tooltip>
								</span>
							</div>
							<StudyCountryAndLanguages
								study={{
									languages: template?.type === 'audience' ? [] : languages || template?.languages,
									countryIds: countryIds || template?.countryIds,
									uuid: uuid || template?.uuid,
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				className={`${el('right-container')} ${
					type === 'Study' ? el('study-background') : el('audience-background')
				}`}
			>
				<div className={el('study-favourite')}>
					<p className={el('type')}>{type}</p>
					<div
						className={`${el('star')} ${!(isOwner && !!renderEllipsisMenu) ? el('star-shifted') : ''} ${
							isFavoriteLocal ? el('star-active') : ''
						}`}
					>
						<Iconof
							icon={isFavoriteLocal ? 'star' : 'star_outlined'}
							onClick={() => handleOnFavorite(!isFavoriteLocal)}
							className={el('icon')}
						/>
					</div>
				</div>
				<div className={el('price-loi-menu-container')}>
					{type === 'Study' ? (
						<div className={el('loi')}>
							<h3 className={el('loi')}>{lengthOfInterviewLabel(template?.lengthOfInterview)} min</h3>
							<p className={el('info-text')}>Est. study length</p>
						</div>
					) : (
						<div className={el('price')}>
							<p
								className={`${el('audience-price')} ${
									template?.startingAtPrice ? '' : el('grey-price')
								}`}
							>
								<span>
									<Iconof
										icon="label"
										className={`${el('tag-icon')} ${
											template?.startingAtPrice ? '' : el('grey-price')
										}`}
									/>
								</span>{' '}
								{template?.startingAtPrice
									? getCostMarkup(template.startingAtPrice / 100, null)
									: '$0.00'}
							</p>
							<p className={el('price-info-text')}>Starting price</p>
						</div>
					)}
				</div>
			</div>
			{isOwner && !!renderEllipsisMenu && (
				<Tooltip
					arrow={false}
					interactive
					position="left"
					distance={25}
					popperOptions={{
						modifiers: {
							preventOverflow: {
								enabled: false,
							},
							flip: {
								enabled: false,
							},
						},
					}}
					open={showEllipsisMenu}
					theme={`${'light'} ${className}`}
					onRequestClose={() => {
						setShowEllipsisMenu(false);
					}}
					html={renderEllipsisMenu(template)}
				>
					<Show above={960}>
						<Iconof
							icon="more_vertical"
							className={`${el('ellipsis-menu')} ${el('icon')}`}
							onClick={() => setShowEllipsisMenu(true)}
						/>
					</Show>
				</Tooltip>
			)}
		</Card>
	);
};
TemplateCard.propTypes = {
	id: PropTypes.number,
	uuid: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	study: PropTypes.object,
	languages: PropTypes.array,
	countryIds: PropTypes.array,
	isFavorite: PropTypes.bool,
	isGlobal: PropTypes.bool,
	ownerName: PropTypes.string,
	cardType: PropTypes.oneOf([
		'combined',
		'audience',
		'study',
		'audienceTemplate',
		'studyTemplate',
		'combinedTemplate',
	]),
	template: PropTypes.object,
	previewMultiStep: PropTypes.bool,
	isWizard: PropTypes.bool,
	preSelectedStudyTemplateId: PropTypes.number,
	selectedStudyTemplateId: PropTypes.number,
	preSelectedAudienceTemplateId: PropTypes.number,
	selectedAudienceTemplateId: PropTypes.number,
	wizardCardOnClick: PropTypes.any,
};

export default TemplateCard;
