import * as actions from '../actions';

const INITIAL_STATE = {
	languages: null,
	clients: null,
	geographies: [],
	loading: 0,
	error: null,
	sections: { status: 'loading', content: null },
	searchQuery: null,
	blueprints: { content: [], loading: false, initialLoad: true, hasBlueprints: false, total: 0, hasSearched: false },
	recommendedBlueprints: {
		content: [],
		loading: false,
		hasRecommended: false,
		initialLoad: true,
		hasMore: true,
		total: 0,
	},
	blueprintFilters: {},
	showCreateNewModal: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_CLIENTS:
			return { ...state, clients: action.payload.clients };

		case actions.SET_LANGUAGES:
			return { ...state, languages: action.payload.languages };

		case actions.INC_LOADING:
			return { ...state, loading: state.loading + 1 };

		case actions.DEC_LOADING:
			return { ...state, loading: state.loading - 1 };

		case actions.SET_ERROR:
			return { ...state, error: action.payload.error };

		case actions.SET_GEOGRAPHIES:
			return { ...state, geographies: action.payload.geographies };

		case actions.SET_SECTION:
			return { ...state, currentSection: { ...state.currentSection, ...action.payload.currentSection } };

		case actions.SET_SECTIONS:
			return { ...state, sections: action.payload.sections };

		case actions.SET_SEARCH_QUERY:
			return { ...state, searchQuery: action.payload.searchQuery };

		case actions.SET_BLUEPRINTS:
			return {
				...state,
				blueprints: { ...state.blueprints, ...action.payload.blueprints },
			};

		case actions.SET_BLUEPRINT_FILTERS:
			return { ...state, blueprintFilters: action.payload.blueprintFilters };

		case actions.SET_SHOW_CREATE_NEW_MODAL:
			return { ...state, showCreateNewModal: action.payload.showCreateNewModal };

		case actions.SET_RECOMMENDED_BLUEPRINTS:
			return {
				...state,
				recommendedBlueprints: { ...state.recommendedBlueprints, ...action.payload.recommendedBlueprints },
			};
		default:
			return state;
	}
};
