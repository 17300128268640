import React from 'react';

const OpenMailboxRaisedFlag = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
		<path d="M38.4375 39.375H25.3125V58.125H38.4375V39.375Z" fill="url(#paint0_linear_584_2020)" />
		<path
			d="M42.9527 5.625C34.5743 5.625 16.0596 6.58781 16.0596 6.58781L31.2321 45.9375L58.1252 40.2544V19.4297C58.1252 11.2734 51.3321 5.625 42.9527 5.625Z"
			fill="url(#paint1_radial_584_2020)"
		/>
		<path
			d="M34.6743 45.3206C33.8521 45.7312 31.2318 45.9375 31.2318 45.9375C31.2318 45.9375 30.7537 42.5784 31.2318 39.4922C31.8065 41.6991 33.343 40.4175 34.678 41.1431C36.2165 41.9775 35.9962 44.6587 34.6743 45.3206Z"
			fill="#687A82"
		/>
		<path
			d="M16.0594 6.58789C7.68094 6.58789 1.875 13.202 1.875 21.3573V43.6923L31.2319 45.9376V21.3573C31.2319 13.202 24.4388 6.58789 16.0594 6.58789Z"
			fill="#6E8189"
		/>
		<path
			d="M15.7925 7.2207C7.7047 7.2207 2.13501 13.6295 2.13501 21.5363V43.3754L30.4391 44.6898V21.5363C30.4391 13.6295 23.8822 7.2207 15.7925 7.2207Z"
			fill="url(#paint2_linear_584_2020)"
		/>
		<path d="M2.13501 43.3762L30.4391 45.3037V37.4746L2.13501 43.3762Z" fill="url(#paint3_linear_584_2020)" />
		<path
			d="M34.7609 43.2859C34.7609 45.1599 32.3496 45.1599 32.3496 43.2859C32.3496 41.4099 34.7609 41.4099 34.7609 43.2859Z"
			fill="url(#paint4_radial_584_2020)"
		/>
		<path
			d="M46.5234 1.875L33.3984 2.8125V30.6591C33.3984 33.1584 37.1484 33.1584 37.1484 30.6591V11.9203L46.5234 10.5084V1.875Z"
			fill="url(#paint5_linear_584_2020)"
		/>
		<path d="M38.5584 27.0984L32.6053 33.9843L31.875 34.1127V27.6009L38.5584 26.6934V27.0984Z" fill="#1A1626" />
		<path
			d="M38.5585 27.0977L32.3269 27.9855L32.5997 28.263L32.6053 33.9836L38.9232 32.8811L38.9007 27.3114L38.5585 27.0977Z"
			fill="url(#paint6_linear_584_2020)"
		/>
		<path
			d="M6.16772 21.8242L10.6996 44.6898L30.4387 41.6402V30.9808C30.4387 30.9808 16.0349 23.6214 6.16772 21.8242Z"
			fill="url(#paint7_linear_584_2020)"
		/>
		<path
			d="M24.1273 24.7598L10.6995 44.6901C17.9473 41.6123 24.9513 40.3551 30.4385 39.8966V30.4607L24.1273 24.7598Z"
			fill="#99A8AE"
		/>
		<path
			d="M10.6995 44.6899L30.4385 41.6402V30.9809L24.2595 25.459L10.6995 44.6899Z"
			fill="url(#paint8_linear_584_2020)"
		/>
		<path
			d="M6.16772 21.8242L25.5515 35.9552C26.0137 36.2917 26.5921 36.2233 26.9052 35.7967L30.4396 30.9798V30.4595C30.4387 30.4605 16.2177 23.7311 6.16772 21.8242Z"
			fill="#99A8AE"
		/>
		<path
			d="M25.453 35.3522C25.9049 35.67 26.4618 35.6053 26.7749 35.2013L30.4387 30.4622V21.5372C30.4387 21.3638 30.419 21.1959 30.4115 21.0234L6.16772 21.8259L25.453 35.3522Z"
			fill="url(#paint9_linear_584_2020)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_584_2020"
				x1="25.312"
				y1="48.75"
				x2="38.4375"
				y2="48.75"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.1697" stopColor="#9E6C48" />
				<stop offset="0.348" stopColor="#996948" />
				<stop offset="0.55" stopColor="#8C6148" />
				<stop offset="0.7394" stopColor="#785647" />
			</linearGradient>
			<radialGradient
				id="paint1_radial_584_2020"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(28.727 16.4755) rotate(-95.1525) scale(10.4192 53.2776)"
			>
				<stop stopColor="#ABBDC5" />
				<stop offset="0.2599" stopColor="#A7B8C0" />
				<stop offset="0.5664" stopColor="#9AAAB2" />
				<stop offset="0.8952" stopColor="#86949A" />
				<stop offset="1" stopColor="#7E8B91" />
			</radialGradient>
			<linearGradient
				id="paint2_linear_584_2020"
				x1="2.13492"
				y1="25.9552"
				x2="30.4394"
				y2="25.9552"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7B9099" />
				<stop offset="1" stopColor="#302C3B" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_584_2020"
				x1="2.13492"
				y1="41.389"
				x2="30.4394"
				y2="41.389"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7B9099" />
				<stop offset="0.146" stopColor="#697882" />
				<stop offset="0.3831" stopColor="#505763" />
				<stop offset="0.6102" stopColor="#3E3F4D" />
				<stop offset="0.8211" stopColor="#343140" />
				<stop offset="1" stopColor="#302C3B" />
			</linearGradient>
			<radialGradient
				id="paint4_radial_584_2020"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(33.4042 42.9041) scale(1.47891 1.72544)"
			>
				<stop offset="0.0909" stopColor="#CFD8DD" />
				<stop offset="0.5899" stopColor="#A0ABB0" />
				<stop offset="1" stopColor="#7E8A8F" />
			</radialGradient>
			<linearGradient
				id="paint5_linear_584_2020"
				x1="39.9609"
				y1="32.5342"
				x2="39.9609"
				y2="1.87547"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#CF0000" />
				<stop offset="1" stopColor="#FF000E" />
			</linearGradient>
			<linearGradient
				id="paint6_linear_584_2020"
				x1="36.1918"
				y1="33.416"
				x2="35.2581"
				y2="27.5211"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.8303" stopColor="#302C3B" />
				<stop offset="0.8747" stopColor="#343240" />
				<stop offset="0.9346" stopColor="#3E434F" />
				<stop offset="1" stopColor="#4E5E67" />
			</linearGradient>
			<linearGradient
				id="paint7_linear_584_2020"
				x1="21.7242"
				y1="43.6352"
				x2="19.5163"
				y2="20.5479"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#E6EBED" />
				<stop offset="0.512" stopColor="#DBE2E5" />
				<stop offset="1" stopColor="#CCD5D9" />
			</linearGradient>
			<linearGradient
				id="paint8_linear_584_2020"
				x1="21.724"
				y1="43.6353"
				x2="20.0246"
				y2="25.8641"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#E6EBED" />
				<stop offset="0.2602" stopColor="#E3E9EB" />
				<stop offset="0.4539" stopColor="#DAE1E4" />
				<stop offset="0.5758" stopColor="#CFD8DD" />
			</linearGradient>
			<linearGradient
				id="paint9_linear_584_2020"
				x1="21.1981"
				y1="36.0222"
				x2="19.7166"
				y2="20.5305"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#E6EBED" />
				<stop offset="0.582" stopColor="#E3E9EC" />
				<stop offset="1" stopColor="#DAE3E8" />
			</linearGradient>
		</defs>
	</svg>
);

export default OpenMailboxRaisedFlag;
