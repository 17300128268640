import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TippyToolTip } from 'react-tippy';
import cn from 'src/utilities/bem-cn';
import ProductCard from 'src/components/manage/products/ProductCard';
import { getSectionSetting, getColor, productHasVideoAsset } from 'src/utilities/misc';
import { Container } from 'reactstrap';
import ResponsiveModal from 'src/components/shared/ResponsiveModal';
import { Iconof } from '@upsiide/ui-components';
import ProductTagsList from '../ProductTagsList';
import ProductReportDonutChart from '../ProductsGrid/ProductReportDonutChart';

import './styles.scss';

const className = 'product-details-tooltip';
const el = (name, mod) => cn(className, name, mod);

const ProductDetailsTooltip = ({
	show,
	currentSection,
	product,
	study,
	productNorms,
	customClass,
	modal,
	onClose,
	attachTo,
	boundToParent = false,
	topOffset = 0,
	extraContent,
}) => {
	let enableCommitments = getSectionSetting(currentSection, 'include_commitment') !== 'false';
	if (product?.includeCommitment) {
		enableCommitments = product?.includeCommitment !== 'false';
	}

	if (modal) {
		if (show) document.body.classList.add('blur-study');
		else document.body.classList.remove('blur-study');
	}

	const renderProductCard = () => {
		const { language: studyLanguage, currentLanguage: studyCurrentLanguage } = study;
		const language = studyLanguage || studyCurrentLanguage;
		const imageSize = product?.imageSize || getSectionSetting(currentSection, 'image_size');
		const imageCropping = product?.imageCropping || getSectionSetting(currentSection, 'image_cropping');
		const ideaScreenLayout = product?.ideaScreenLayout || getSectionSetting(currentSection, 'idea_screen_layout');
		const productObject = currentSection?.products?.find(p => p.id === product.id);
		const { productIndex } = study?.settings || {};
		return (
			<ProductCard
				study={study}
				language={language}
				product={productObject || product || currentSection?.products[productIndex]}
				imageSize={imageSize || 'medium'}
				imageCropping={imageCropping || 'fit'}
				ideaScreenLayout={ideaScreenLayout}
				assetVariationSize={productHasVideoAsset(product) ? 'video' : 'thumbnail'}
			/>
		);
	};

	const getProductColor = () => {
		if (!enableCommitments) return null;

		return getColor(product.idea, study?.settings?.productIndex, productNorms.length ? productNorms : null);
	};

	const renderProductNumbers = () => {
		const color = getProductColor();

		return (
			<div className={el('numbers')}>
				<ProductReportDonutChart
					data={{
						label: 'Idea Score',
						value: product.idea,
						color: enableCommitments ? color : null,
					}}
					strokeWidth={2.5}
				/>

				<div className={el('score-row')}>
					<div className={el('number-text')}>Interest Score</div>
					<div className={el('number-content')}>{product.interest ? product.interest : 0}</div>
				</div>

				{enableCommitments && (
					<div className={el('score-row')}>
						<div className={el('number-text')}>Commitment Score</div>
						<div className={el('number-content')}>{product.commitment ? product.commitment : 0}</div>
					</div>
				)}
			</div>
		);
	};

	if (!product || !study) {
		return null;
	}

	const closeModal = e => {
		onClose();
		if (e) e.stopPropagation();
	};

	const renderContentBody = () => (
		<div className={el('wrap')}>
			<div className={el('title-container')}>
				<div className={el('title')}>{product.name}</div>
				<div className={el('close')} onClick={closeModal} aria-hidden>
					<Iconof icon="clear" />
				</div>
			</div>
			<div className={el('tooltip-content')}>
				<div className={el('product-card')}>{renderProductCard()}</div>
				<div className={el('product-details')}>
					{renderProductNumbers()}
					{product.tags && product.tags.length > 0 && <ProductTagsList product={product} modal />}
				</div>
			</div>
			{extraContent}
		</div>
	);

	const renderTooltip = () => {
		if (!attachTo) return;

		const modifiers = {
			modifiers: {
				preventOverflow: {
					enabled: true,
					boundariesElement: boundToParent ? attachTo.parentElement : 'viewport',
				},
				flip: { enabled: true },
				hide: { enabled: true },
			},
		};

		const rect = attachTo.getBoundingClientRect();
		const scrollX = window.pageXOffset || window.scrollX || document.documentElement.scrollLeft;
		const scrollY = window.pageYOffset || window.scrollY || document.documentElement.scrollTop;
		const left = rect.left + rect.width / 2 + scrollX;
		const top = rect.top + rect.height / 2 + scrollY + topOffset;

		const style = {
			left,
			top,
			position: 'fixed',
			transform: `translateX(-18px) translateY(-12px)`,
			zIndex: 100,
		};
		return (
			<TippyToolTip
				open={show}
				position="right"
				className={className}
				theme={`${'light'} ${className} ${customClass}__tooltip`}
				animationFill={false}
				arrow
				interactive
				style={style}
				// TODO: Can potentially make flip and flip behaviour a prop used to customize behaviour
				flip // Determines if the tippy flips so that it is placed within the viewport as best it can be if there is not enough space.
				flipOnUpdate // Determines if the tippy should flip when necessary if its position updates while showing (for example, while scrolling, resize, or if the size of the tooltip changed).
				flipBehavior={['bottom', 'left']} // Use an array such as ["bottom", "left"] to prefer the "left" placement if "bottom" is unavailable, instead of "top", if using the "bottom" placement.
				transitionFlip
				boundary="window" // Possible values: "scrollParent", "window", "viewport", or an HTMLElement
				onRequestClose={onClose}
				html={renderContentBody()}
				popperOptions={modifiers}
			/>
		);
	};

	const renderModal = () => (
		<div className={el('modal')}>
			<ResponsiveModal show={show} onClose={onClose} customClass={el('custom-responsive-modal')} customContent>
				<Container className={el('modal-container')}>{renderContentBody()}</Container>
			</ResponsiveModal>
		</div>
	);

	return <div aria-hidden>{modal ? renderModal() : renderTooltip()}</div>;
};

ProductDetailsTooltip.propTypes = {
	show: PropTypes.bool,
	product: PropTypes.object,
	study: PropTypes.any,
	currentSection: PropTypes.object,
	customClass: PropTypes.string,
	productNorms: PropTypes.array,
	modal: PropTypes.bool,
	onClose: PropTypes.func,
	attachTo: PropTypes.any,
	boundToParent: PropTypes.bool,
	topOffset: PropTypes.number,
	extraContent: PropTypes.any,
};

export default ProductDetailsTooltip;
