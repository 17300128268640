import React, { useCallback, useLayoutEffect, useMemo, useRef, useState, useEffect } from 'react';
import { Iconof } from '@upsiide/ui-components';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'src/components/elements/Button';
import * as actions from 'src/domains/manage-study/actions';
import useOutsideClick from 'src/hooks/useOutsideClick';
import cn from 'src/utilities/bem-cn';
import FiltersSum from '../FilterSum';

import './styles.scss';

const className = 'audience-filter-dropdown';
const el = (name, mod) => cn(className, name, mod);

const AudienceFilterDropdown = ({
	showModal,
	customIcon,
	selectedCountries = [],
	setSelectedCountries = () => {},
	selectedLanguages = [],
	setSelectedLanguages = () => {},
	showCountries = true,
}) => {
	const [query, setQuery] = useState('');
	const [show, setShow] = useState(false);

	const { languages, countries } = useSelector(state => state.manageStudy);
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		if (!countries?.length) dispatch(actions.fetchCountries());
	}, [countries, dispatch]);

	useEffect(() => {
		if (!showModal) {
			setSelectedLanguages([]);
			setSelectedCountries([]);
			setQuery('');
		}
	}, [showModal]);

	useEffect(() => {
		setSelectedCountries([]);
		setSelectedFilterType('');
	}, [showCountries, setSelectedCountries]);

	const filterTypes = useMemo(() => {
		const filters = [];
		if (showCountries) {
			filters.push({
				value: 'countries',
				label: 'Country',
				items: countries,
			});
		}
		filters.push({
			value: 'languages',
			label: 'Language',
			items: languages,
		});
		return filters;
	}, [countries, languages, showCountries]);

	const [selectedFilterType, setSelectedFilterType] = useState();

	const getFitersByType = filterType => {
		if (filterType === 'countries') {
			return [selectedCountries, setSelectedCountries];
		}

		return [selectedLanguages, setSelectedLanguages];
	};

	const [selectedFilters, setSelectedFilters] = getFitersByType(selectedFilterType?.value);

	const onSelectFilterToggle = ({ e, countryId, languageCode }) => {
		e.preventDefault();
		e.stopPropagation();
		const code = selectedFilterType?.value === 'countries' ? countryId : languageCode;

		if (!selectedFilters.includes(code)) {
			setSelectedFilters(prev => [...prev, code]);
			return;
		}
		setSelectedFilters(prev => prev.filter(filter => filter !== code));
	};

	const getSelectedFiltersSumByType = useCallback(
		filterType => {
			if (filterType === 'countries') {
				return selectedCountries.length;
			}

			return selectedLanguages.length;
		},
		[selectedCountries.length, selectedLanguages.length],
	);

	const renderFilterTypesPanel = () => (
		<ul className={`${el('filter-types')} custom-scrollbar`}>
			{filterTypes?.map(filterType => (
				<li key={filterType.value}>
					<button
						type="button"
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							setSelectedFilterType(filterType);
						}}
					>
						<div className={el('filter-number-container')}>
							{filterType.label}{' '}
							{getSelectedFiltersSumByType(filterType.value) ? (
								<span className={el('filter-number')}>
									{getSelectedFiltersSumByType(filterType.value)}
								</span>
							) : (
								''
							)}
						</div>
						<Iconof icon="chevron_right" size="large" />
					</button>
				</li>
			))}
		</ul>
	);

	const filtersByType = useMemo(() => {
		if (!selectedFilterType) return [];

		const selectedFiltesByType = filterTypes.find(({ value }) => value === selectedFilterType.value);

		if (!selectedFiltesByType?.items?.length) return [];

		if (!query) return selectedFiltesByType.items;

		const selectedFiltesByTypeFiltered = selectedFiltesByType.items.filter(({ label }) =>
			label.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
		);
		return selectedFiltesByTypeFiltered;
	}, [filterTypes, query, selectedFilterType]);

	const renderFiltersPanel = () => (
		<div className={el('search-filters')}>
			<button
				className={el('back')}
				type="button"
				onClick={e => {
					e.stopPropagation();
					e.preventDefault();
					setSelectedFilterType('');
				}}
			>
				<Iconof icon="chevron_left" />
				<span>{selectedFilterType?.label}</span>
			</button>
			<Input
				className={el('search-input')}
				placeholder="Search"
				value={query}
				onChange={e => setQuery(e.target.value)}
			/>
			<div className={el('search')}>
				<Iconof icon="search" size="large" />
			</div>
			<ul className={`${el('filter-list')}  custom-scrollbar`}>
				{filtersByType?.map(({ id, label, languageCode }) => (
					<li
						key={id}
						aria-hidden
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							onSelectFilterToggle({ e, languageCode, countryId: id });
						}}
					>
						{selectedFilterType?.value === 'countries' ? (
							<Iconof
								className={el(`check${selectedFilters.includes(id) ? '-active' : ''}`)}
								icon={selectedFilters.includes(id) ? 'done' : ''}
							/>
						) : (
							<Iconof
								className={el(`check${selectedFilters.includes(languageCode) ? '-active' : ''}`)}
								icon={selectedFilters.includes(languageCode) ? 'done' : ''}
							/>
						)}
						<span>{label}</span>
					</li>
				))}
			</ul>
		</div>
	);

	const renderPanels = () => {
		let modClass = 'noSelection';
		if (selectedFilterType?.value) {
			modClass = 'withSection';
		}
		if (selectedFilterType?.backTo === 'template') {
			modClass = 'withSubSection';
		}

		return (
			<div className={el('panels')}>
				<div className={el('panel-content')}>
					<div className={el('animation-panels', modClass)}>
						<div className={el('root-panel')}>{renderFilterTypesPanel()}</div>

						<div className={el('secondary-panel')}>{renderFiltersPanel()}</div>
					</div>

					<div className={el('footer')}>
						<small>
							{filtersSum} {filtersSum === 1 ? 'filter' : 'filters'} applied
						</small>
						<Button
							type="text"
							label="Clear selections"
							state={!filtersSum ? 'disabled' : 'active'}
							onClick={e => {
								e.stopPropagation();
								e.preventDefault();
								setSelectedLanguages([]);
								setSelectedCountries([]);
								setQuery('');
							}}
						/>
					</div>
				</div>
			</div>
		);
	};

	const filtersSum = useMemo(
		() => (selectedLanguages?.length || 0) + (selectedCountries?.length || 0),
		[selectedCountries?.length, selectedLanguages?.length],
	);

	const ref = useRef(null);

	useOutsideClick(ref, () => {
		setShow(false);
	});
	return (
		<div className={className} ref={ref}>
			<div aria-hidden onClick={() => setShow(prev => !prev)} className={el('content')}>
				{customIcon ? <img src={customIcon} alt="" /> : <Iconof icon="filter_list" />}
				{filtersSum ? <FiltersSum sum={filtersSum} /> : null}
				<Iconof icon={show ? 'chevron_up' : 'chevron_down'} />
			</div>
			{show && renderPanels()}
		</div>
	);
};

AudienceFilterDropdown.propTypes = {
	showModal: PropTypes.any,
	customIcon: PropTypes.any,
	selectedCountries: PropTypes.array,
	setSelectedCountries: PropTypes.func,
	selectedLanguages: PropTypes.array,
	setSelectedLanguages: PropTypes.func,
	showCountries: PropTypes.bool,
};

export default AudienceFilterDropdown;
