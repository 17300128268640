import CONSTANTS from 'src/config/constants';
import { createSelector } from 'reselect';
import { orderBy, isEqual } from 'lodash';
import { getFloatRounded } from 'src/utilities/misc';
import { getPublicReportData } from '../../selectors';

export const getStudy = state => getPublicReportData(state).study;
export const getLanguages = state => getPublicReportData(state).languages;
export const getLanguage = state => getPublicReportData(state).language;
export const getFilters = state => getPublicReportData(state).filters;
export const getLocalFilters = state => getPublicReportData(state).localFilters;
export const getProducts = state => getPublicReportData(state).products;
export const getProductSort = state => getPublicReportData(state).productSort;
export const getProductSortDescending = state => getPublicReportData(state).productSortDescending;
export const getProductTags = state => getPublicReportData(state).productTags;
export const getResponseData = state => getPublicReportData(state).responseData;
export const getActiveBaseFilters = state => getPublicReportData(state).activeBaseFilters;
export const getIdeaMap = state => getPublicReportData(state).ideaMap;
export const getError = state => getPublicReportData(state).error;
export const getProductViewMode = state => getPublicReportData(state).productViewMode;
export const getQuestionViewMode = state => getPublicReportData(state).questionViewMode;
export const getIdeaMapLoading = state => getPublicReportData(state).ideaMapLoading;
export const getGenerateReportModal = state => getPublicReportData(state).generateReportModal;
export const getSections = state => getPublicReportData(state).sections;
export const getCurrentSection = state => getPublicReportData(state).currentSection;
export const getCurrentSectionProducts = state => getPublicReportData(state).currentSection.content.products;
export const getSortBy = state => getPublicReportData(state).sortBy;
export const getTranslationsModal = state => getPublicReportData(state).translationsModal;
export const getTranslationPreview = state => getPublicReportData(state).translationPreview;
export const getDiscardProductChangesModal = state => getPublicReportData(state).discardProductChangesModal;
export const getQuestionReportingPhotoPreviewUrl = state => getPublicReportData(state).questionReportingPhotoPreviewUrl;
export const getQuestionReportingVideoPreviewUrl = state => getPublicReportData(state).questionReportingVideoPreviewUrl;

// Audience Reports
export const getAudiencesReportingData = state => getPublicReportData(state).audienceReports;
export const getAudienceReportingData = (state, audienceUuid) =>
	getPublicReportData(state).audienceReports.find(audienceReport => audienceReport.audienceUuid === audienceUuid);
export const getShowAudienceReports = state => getPublicReportData(state).showAudienceReports;
export const getAudienceCollectionReport = state => getPublicReportData(state).audienceCollectionReportData;

// Device Preview Mode
export const getShowDevicePreviewMode = state => getPublicReportData(state).showDevicePreviewMode;
export const getDevicePreviewMode = state => getPublicReportData(state).devicePreviewMode;
export const getShowDevicePreviewIframeBlocker = state => getPublicReportData(state).showDevicePreviewIframeBlocker;
export const getDevicePreviewUrl = state => getPublicReportData(state).devicePreviewUrl;

// Filter sidebar
export const getShowFilterSidebar = state => getPublicReportData(state).showFilterSidebar;

// Launch Audience Modal
export const getLaunchAudienceModal = state => getPublicReportData(state).launchAudienceModal;

// Splits
export const getMonadicSplitReportingData = state => getPublicReportData(state).monadicSplitReportingData;
export const getMonadicSplitPin = state => getPublicReportData(state).monadicSplitPin;
export const getMonadicSplitSortOrder = state => getPublicReportData(state).monadicSplitSortOrder;
export const getMonadicSplitSortQuestionId = state => getPublicReportData(state).monadicSplitSortQuestionId;
export const getMonadicSplitSortQuestionOptionId = state => getPublicReportData(state).monadicSplitSortQuestionOptionId;

//  Customize report
export const getCustomizeReport = state => getPublicReportData(state).customizeReport;

// Share of Choice
export const getShareOfChoice = state => getPublicReportData(state).shareOfChoice;
export const getShareOfChoiceBaseCase = state => getPublicReportData(state).shareOfChoiceBaseCase;
export const getShareOfChoiceExistingProductIds = state => getPublicReportData(state).shareOfChoiceExistingProductIds;
export const getShareOfChoiceProgress = state => getPublicReportData(state).shareOfChoiceProgress;
export const getShareOfChoiceInnovationProductIds = state =>
	getPublicReportData(state).shareOfChoiceInnovationProductIds;
export const getShareOfChoiceView = state => getPublicReportData(state).shareOfChoiceView;
export const getShareOfChoiceSortOrder = state => getPublicReportData(state).shareOfChoiceSortOrder;
export const getShareOfChoiceSortDirection = state => getPublicReportData(state).shareOfChoiceSortDirection;
export const getShareOfChoiceExcludedProducts = state => getPublicReportData(state).shareOfChoiceExcludedProducts;
export const getShareOfChoiceSummaryCard = state => getPublicReportData(state).shareOfChoiceSummaryCard;
export const getShareOfChoiceDataAvailable = state => getPublicReportData(state).shareOfChoiceDataAvailable;
export const getShareOfChoiceResponseCount = state => getPublicReportData(state).shareOfChoiceResponseCount;
export const getShareOfChoiceGroups = state => getPublicReportData(state).shareOfChoiceGroups;
export const getShareOfChoiceLoading = state => getPublicReportData(state).shareOfChoiceLoading;
export const getLengthOfInterview = (state) => getPublicReportData(state).lengthOfInterview;

export const getShareOfChoiceScenarioProducts = createSelector(
	getShareOfChoiceInnovationProductIds,
	getShareOfChoiceExistingProductIds,
	getCurrentSectionProducts,
	(existingProductIds, innovationProductIds, products) => {
		if (existingProductIds.length || innovationProductIds.length) {
			return products.filter(product =>
				[...innovationProductIds, ...existingProductIds].includes(product.localProductId),
			);
		}

		return products;
	},
);

export const getShareOfChoiceHasBaseCase = createSelector(getShareOfChoiceGroups, groups =>
	groups.some(group => group.isBaseCase),
);

export const getShareOfChoiceFilteredGroups = createSelector(getShareOfChoiceGroups, groups =>
	groups.filter(group => !group.isBaseCase),
);

export const getShareOfChoiceFilteredGroupIds = createSelector(getShareOfChoiceFilteredGroups, groups =>
	groups.map(group => group.id),
);

export const getShareOfChoiceBaseCaseProductIds = createSelector(
	getShareOfChoiceScenarioProducts,
	getShareOfChoiceInnovationProductIds,
	(products, innovationProductIds) =>
		products
			.filter(product => !innovationProductIds.includes(product.localProductId))
			.map(product => product.localProductId),
);

export const getShareOfChoiceProductIds = createSelector(
	getShareOfChoiceScenarioProducts,
	getShareOfChoiceExcludedProducts,
	(products, excludedProducts) =>
		products
			.filter(product => !excludedProducts.includes(product.localProductId))
			.map(product => product.localProductId),
);

export const getShareOfChoiceProducts = createSelector(
	getCurrentSectionProducts,
	getShareOfChoice,
	getShareOfChoiceFilteredGroups,
	(sectionProducts, shareOfChoice, groups) => {
		if (shareOfChoice.products) {
			const data = sectionProducts.map(product => {
				const socItem = (shareOfChoice.products || []).find(
					soc => soc.localProductId === product.localProductId,
				) || {
					shareOfChoice: 0,
					change: -100, // forces item to bottom of list when sorting by change
					changePercent: -100, // forces item to bottom of list when sorting by changePercent
				};

				const itemGroup = (groups || []).find(group => group.productIds.includes(product.localProductId));

				return {
					...product,
					...socItem,
					shareOfChoiceRounded: getFloatRounded(socItem.shareOfChoice).toFixed(1),
					changeRounded: getFloatRounded(socItem.change).toFixed(1),
					changePercentRounded: getFloatRounded(socItem.changePercent, 0),
					...(itemGroup ? { groupColor: itemGroup?.hex, groupName: itemGroup?.name } : {}),
				};
			});

			return data;
		}

		return [];
	},
);

export const getShareOfChoiceSummary = createSelector(getShareOfChoice, shareOfChoice => {
	if (shareOfChoice.summary) {
		return shareOfChoice.summary;
	}

	return [];
});

export const getShareOfChoiceSummaryGroups = createSelector(
	getShareOfChoiceFilteredGroups,
	getShareOfChoice,
	(groups, shareOfChoice) => {
		if (groups.length && shareOfChoice.groups) {
			const groupData = groups.map(group => {
				const socGroupData = shareOfChoice.groups.find(socGroup => socGroup.id === group.id) || {
					shareOfChoice: 0,
					change: 0,
					changePercent: 0,
				};

				return {
					...socGroupData,
					...group,
				};
			});

			const orderedGroupsData = orderBy(groupData, [group => group.name.toLowerCase()]);

			return orderedGroupsData;
		}

		return [];
	},
);

export const getShareOfChoiceProductData = createSelector(
	getShareOfChoiceProducts,
	getShareOfChoiceSortOrder,
	getShareOfChoiceSortDirection,
	(data, sort, sortDirection) => {
		const sortBy = sort || 'name';
		const mainSortDirection = sortDirection || CONSTANTS.shareOfChoice.sortDirectionDefault[sort || 'name'];

		let orderedData;
		if (sortBy === CONSTANTS.shareOfChoice.sortOrder.name) {
			orderedData = orderBy(data, 'name', mainSortDirection);
		}

		if (sortBy === CONSTANTS.shareOfChoice.sortOrder.share) {
			orderedData = orderBy(data, ['shareOfChoice', 'name'], [mainSortDirection, 'asc']);
		}

		if (sortBy === CONSTANTS.shareOfChoice.sortOrder.change) {
			orderedData = orderBy(
				data,
				['change', 'changePercentRounded', 'name'],
				[mainSortDirection, mainSortDirection, 'asc'],
			);
		}

		if (sortBy === CONSTANTS.shareOfChoice.sortOrder.percent) {
			orderedData = orderBy(
				data,
				['changePercent', 'changeRounded', 'name'],
				[mainSortDirection, mainSortDirection, 'asc'],
			);
		}

		return orderedData;
	},
);

export const getShareOfChoiceHasSubscript = createSelector(
	getShareOfChoiceExcludedProducts,
	getShareOfChoiceInnovationProductIds,
	(excludedProducts, innovationProductIds) => {
		if (innovationProductIds.length) {
			return !isEqual(excludedProducts.sort(), innovationProductIds.sort());
		}

		return !!excludedProducts.length;
	},
);

export const getShareOfChoiceHasData = createSelector(
	getShareOfChoice,
	data => data?.products && data?.products.some(item => item.shareOfChoice !== 0),
);
