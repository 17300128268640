import { getCreateStudyData } from '../../selectors';

export const getLanguages = state => getCreateStudyData(state).languages;
export const getClients = state => getCreateStudyData(state).clients;
export const getGeographies = state => getCreateStudyData(state).geographies;
export const getLoading = state => getCreateStudyData(state).loading;
export const getError = state => getCreateStudyData(state).error;
export const getSections = state => getCreateStudyData(state).sections;
export const getSearchQuery = state => getCreateStudyData(state).searchQuery;
export const getBlueprints = state => getCreateStudyData(state).blueprints;
export const getRecommendedBlueprints = state => getCreateStudyData(state).recommendedBlueprints;
export const getBlueprintFilters = state => getCreateStudyData(state).blueprintFilters;
export const getShowCreateNewModal = state => getCreateStudyData(state).showCreateNewModal;
