import React from 'react';
import { Tooltip } from 'react-tippy';
import { formatRelativeDate } from 'src/utilities/misc';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';

import './styles.scss';

const className = 'revised-date';
const el = (name, mod) => cn(className, name, mod);

const RevisedAt = ({ date, shortFormat = false }) => {
	const formattedDate = formatRelativeDate(date, shortFormat);

	if (!formattedDate?.showActualDate) {
		return (
			<Tooltip
				className={el('tooltip')}
				animation="shift"
				animationFill={false}
				interactive
				trigger="mouseenter"
				position="top"
				theme="dark-blured"
				distance={2}
				html={<span style={{ fontSize: '12px', fontWeight: 600 }}>{formattedDate?.actualDate}</span>}
			>
				{formattedDate?.date}
			</Tooltip>
		);
	}

	return formattedDate?.date;
};

RevisedAt.propTypes = {
	date: PropTypes.string,
	shortFormat: PropTypes.bool,
};
export default RevisedAt;
