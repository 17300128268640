/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Labelled from 'src/components/inputs/Labelled';
import Select from 'src/components/inputs/Select';
import StudiesUtil from 'src/components/shared/Util/StudiesUtil';
import Modal from 'src/components/shared/Modal';
import Button from 'src/components/elements/Button';
import MagicWandIcon from 'src/components/elements/AskAIModal/magic_wand.js';

import './styles.scss';

const className = 'choose-language-modal';
const el = name => cn(className, name);

const ChooseLanguageModal = ({
	languages = [],
	setShow,
	show,
	onClick,
	title = 'Choose Study Language',
	subtitle = 'Primary language',
	tooltipText = "This is the language you'll write the study in. You can add additional languages later.",
	loading,
	autoDetect = false,
	onIdentify = false,
	globalLanguages = [],
}) => {
	const [selectedLanguage, setSelectedLanguage] = useState(null);
	const [loadingIdentify, setLoadingIdentify] = useState(false);
	const defaultOptions = useMemo(
		() => (languages && StudiesUtil.mapLanguagesToFitSelect(languages)) || [],
		[languages],
	);
	const [options, setOptions] = useState(defaultOptions);
	const [erroMsg, setErroMsg] = useState('');

	useEffect(() => {
		const localLanguage = window.localStorage.getItem('@UPSIIDE_CREATE_STUDY_LANGUAGE');
		if (localLanguage) setSelectedLanguage(localLanguage);
	}, []);

	useEffect(() => {
		setOptions(defaultOptions);
	}, [defaultOptions]);

	useEffect(() => {
		setErroMsg('');
	}, [show]);

	const onIdentifyLanguage = async () => {
		try {
			setOptions([{ value: 'ai', label: 'Detecting...' }]);
			setSelectedLanguage('ai');
			setLoadingIdentify(true);

			const response = await onIdentify();

			if (response.message) {
				setErroMsg(response.message);
			} else {
				if (response.language === 'und')
					return setErroMsg('Could not identify the language, please fill your study with more data.');

				const validOption = defaultOptions.find(opt => opt.value === response.language);

				if (!validOption) {
					const lang = globalLanguages.find(l => l.languageCode === response.language)?.label;
					return setErroMsg(`${lang} already exists in the study.`);
				}
				setErroMsg('');
				setSelectedLanguage(response.language);
				onClick(response.language);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingIdentify(false);
			setOptions(defaultOptions);
		}
	};

	return (
		<Modal show={show} onClose={() => setShow(false)} width={500} removeOverflow>
			<div className={className}>
				<div className={el('header')}>
					<h4>{title}</h4>
				</div>
				<Labelled hint={tooltipText} label={subtitle}>
					<Select
						style={{ zIndex: 999 }}
						value={selectedLanguage}
						isDisabled={loadingIdentify}
						onChange={value => {
							setSelectedLanguage(value);
						}}
						options={options}
					/>

					<div className={el('footer')}>
						<div className={el('footer_left')}>{erroMsg}</div>

						<div className={el('footer_right')}>
							{autoDetect && (
								<Button
									state={loadingIdentify ? 'disabled' : 'active'}
									label={
										<>
											<MagicWandIcon />
											<span className={el('ai_detect')}>AI Detect</span>
										</>
									}
									onClick={onIdentifyLanguage}
									type="keyline"
								/>
							)}
							{loadingIdentify ? (
								<Button state="disabled" label="Select" onClick={() => onClick(selectedLanguage)} />
							) : (
								<Button
									state={loading ? 'loading' : 'active'}
									label="Select"
									onClick={() => onClick(selectedLanguage)}
								/>
							)}
						</div>
					</div>
				</Labelled>
			</div>
		</Modal>
	);
};

ChooseLanguageModal.propTypes = {
	languages: PropTypes.array,
	setShow: PropTypes.func,
	show: PropTypes.bool,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	tooltipText: PropTypes.string,
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	autoDetect: PropTypes.bool,
	onIdentify: PropTypes.func,
	globalLanguages: PropTypes.array,
};

export default ChooseLanguageModal;
