import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { termService } from 'src/services';
import studyService from 'src/services/study.service';
import { Button, Iconof } from '@upsiide/ui-components';
import SectionCard from 'src/components/shared/SectionCard';
import Tabs from 'src/components/shared/Tabs';
import { useIsPublic } from 'src/hooks';
import Chip from 'src/components/shared/Chip';
import { publicTabs, tabs } from 'src/components/shared/SmartSearch';
import { getClientId } from 'src/domains/auth/selectors';
import Auth from 'src/utilities/auth';
import { FiltersSum } from 'src/components/shared/FilterDropdown';
import FilterDropdownContext from 'src/components/shared/FilterDropdown/context';
import FILTER from 'public/images/filter_empty.svg';
import {
	getOrderedCollections,
	getOrderedStudyTags,
	getOrderedProductTags,
	getStudyFilterData,
	getPublicStudyFilterData,
	getCollections,
	getCountriesFromLanguages,
} from '../../../domains/all-studies/selectors';
import {
	upsertCollections,
	upsertStudyTags,
	upsertProductTags,
	resetStudyTags,
	resetProductTags,
	resetCollections,
} from '../../../domains/all-studies/actions';
import { FilterDropdownProvider } from '../FilterDropdown';
import Header from './shared/Header';
import Studies from './Studies';
import Ideas from './Ideas';
import Questions from './Questions';
import Templates from './Templates';
import SearchResultsContext from './context';

import './styles.scss';

export const tableClassName = 'results-table';

export const urlParams = {
	studyPage: 'studyPage',
	ideaPage: 'ideaPage',
	questionPage: 'questionPage',
	templatePage: 'templatePage',
	filters: 'filters',
	status: 'status',
	query: 'query',
	type: 'type',
	subtype: 'subtype',
	studyOrderBy: 'studyOrderBy',
	studyOrderByDirection: 'studyOrderByDirection',
	questionOrderBy: 'questionOrderBy',
	questionOrderByDirection: 'questionOrderByDirection',
	ideaOrderBy: 'ideaOrderBy',
	ideaOrderByDirection: 'ideaOrderByDirection',
	templateOrderBy: 'templateOrderBy',
	templateOrderByDirection: 'templateOrderByDirection',
	clientId: 'clientId',
	refetch: 'refetch',
	templatePreview: 'templatePreview',
};

const normalizeTemplateFilterData = (data, type = 'studyTemplate') => {
	if (type === 'studyTemplate') {
		return data?.map(item => ({
			id: item?.id,
			name: item?.name,
			value: item?.id,
			type,
		}));
	}
	if (type === 'audienceTemplate') {
		return data?.map(item => ({
			id: item?.id,
			name: item?.name,
			value: item?.id,
			type,
		}));
	}
	if (type === 'combinedTemplate') {
		return data?.map(item => ({
			id: item?.id,
			name: item?.name,
			value: item?.id,
			type,
		}));
	}
	return data;
};

export const FilterDropdownButton = () => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const { filtersSum, show, setShow } = useContext(FilterDropdownContext);

	return (
		<div aria-hidden onClick={() => setShow(prev => !prev)} className={el('filter-dropdown-button')}>
			<img style={{ height: '10px' }} src={FILTER} alt="" />
			<span className={el('filter-dropdown-button-text')}>Filters</span>
			{filtersSum ? <FiltersSum sum={filtersSum} /> : null}
			<Iconof className={el('filter-dropdown-chev')} icon={show ? 'chevron_up' : 'chevron_down'} />
		</div>
	);
};

export const SearchHeader = () => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const { isPublic } = useIsPublic();

	const [searchParams, setSearchParams] = useSearchParams();
	const { totals } = useContext(SearchResultsContext);
	const { handleSetSelectedFilterType } = useContext(FilterDropdownContext);
	const currentClientId = useSelector(getClientId) || null;
	const isGuest = Auth.isDigGuest(currentClientId);

	// const totals = null;

	const activeTab = searchParams.get(urlParams.type) || tabs?.find(tab => tab.id === 'studies')?.id;

	const handleChangeTab = useCallback(
		tab => {
			const type = tabs?.find(t => t.label === tab)?.id;
			setSearchParams(params => {
				params.set(urlParams.type, type);
				let pageParam = urlParams.studyPage;

				if (type === 'ideas') {
					pageParam = urlParams.ideaPage;
				} else if (type === 'questions') {
					pageParam = urlParams.questionPage;
				} else if (type === 'templates') {
					pageParam = urlParams.templatePage;
				}
				params.set(pageParam, 1);

				return params;
			});

			// reset filter dropdown position
			handleSetSelectedFilterType({});
		},
		[handleSetSelectedFilterType, setSearchParams],
	);

	const tabsToUse = isPublic || isGuest || !Auth.userCan('study:update') ? publicTabs : tabs;
	const tabsWithTotals = useMemo(
		() => tabsToUse?.map(tab => ({ ...tab, count: totals?.[tab.id] || 0 })),
		[tabsToUse, totals],
	);

	return (
		<Header customClass="flex-direction-column">
			<div className={el('search-page-tab-wrapper')}>
				<Link className={el('back-link')} to={`${isPublic ? '/public' : ''}/studies`}>
					<Iconof icon="chevron_left" />
					Back
				</Link>
				<Tabs
					tabs={tabsWithTotals}
					activeTab={activeTab}
					setActiveTab={handleChangeTab}
					disableEmptyTabs={false}
				/>
			</div>
		</Header>
	);
};
SearchHeader.propTypes = {};

export const FilteredBy = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const collections = useSelector(getCollections);
	const countries = useSelector(getCountriesFromLanguages);
	const filterTypes = useSelector(getStudyFilterData);

	const { setActiveFilters } = useContext(FilterDropdownContext);

	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const urlFilters = searchParams.get(urlParams.filters);
	const parsedUrlFilters = JSON.parse(urlFilters);

	const activeTab = searchParams.get(urlParams.status) || 'all';

	const filters = [];
	if (parsedUrlFilters?.country?.length) {
		parsedUrlFilters.country.map(item =>
			filters.push({
				id: item,
				label: countries?.find(country => country.id === item)?.label || item,
				type: 'country',
			}),
		);
	}
	if (parsedUrlFilters?.language?.length) {
		parsedUrlFilters.language.map(item =>
			filters.push({
				id: item,
				label:
					filterTypes
						?.find(type => type.value === 'language')
						?.items?.find(typeItem => typeItem?.value === item)?.label || item,
				type: 'language',
			}),
		);
	}
	if (parsedUrlFilters?.studyTemplate?.length) {
		parsedUrlFilters.studyTemplate.map(item =>
			filters.push({
				id: item,
				label: collections[item]?.name || item,
				type: 'studyTemplate',
			}),
		);
	}
	if (parsedUrlFilters?.audienceTemplate?.length) {
		parsedUrlFilters.audienceTemplate.map(item =>
			filters.push({
				id: item,
				label: collections[item]?.name || item,
				type: 'audienceTemplate',
			}),
		);
	}
	if (parsedUrlFilters?.combinedTemplate?.length) {
		parsedUrlFilters.combinedTemplate.map(item =>
			filters.push({
				id: item,
				label: collections[item]?.name || item,
				type: 'combinedTemplate',
			}),
		);
	}
	if (parsedUrlFilters?.studyTag?.length) {
		parsedUrlFilters.studyTag.map(item =>
			filters.push({
				id: item,
				label: item,
				type: 'studyTag',
			}),
		);
	}
	if (parsedUrlFilters?.ideaType?.length) {
		parsedUrlFilters.ideaType.map(item =>
			filters.push({
				id: item,
				label:
					filterTypes
						?.find(type => type.value === 'ideaType')
						?.items?.find(typeItem => typeItem?.value === item)?.label || item,
				type: 'ideaType',
			}),
		);
	}
	if (parsedUrlFilters?.ideaTag?.length) {
		parsedUrlFilters.ideaTag.map(item =>
			filters.push({
				id: item,
				label: item,
				type: 'ideaTag',
			}),
		);
	}
	if (parsedUrlFilters?.questionType) {
		parsedUrlFilters.questionType.map(item =>
			filters.push({
				id: item,
				label:
					filterTypes
						?.find(type => type.value === 'questionType')
						?.items?.find(typeItem => typeItem?.value === item)?.label || item,
				type: 'questionType',
			}),
		);
	}

	const handleRemoveFilter = filter => {
		setSearchParams(params => {
			const currentUrlFilters = params.get(urlParams.filters);
			const parsedFilters = JSON.parse(currentUrlFilters);

			const index = parsedFilters[filter?.type].findIndex(item => item === filter.id);
			if (index > -1) {
				parsedFilters[filter?.type].splice(index, 1);
			}

			let pageParam = urlParams.studyPage;
			if (activeTab === 'ideas') {
				pageParam = urlParams.ideaPage;
			} else if (activeTab === 'questions') {
				pageParam = urlParams.questionPage;
			} else if (activeTab === 'templates') {
				pageParam = urlParams.templatePage;
			}
			params.set(pageParam, 1);

			params.set(
				urlParams.filters,
				JSON.stringify({
					...parsedFilters,
				}),
			);

			setActiveFilters(parsedFilters);

			return params;
		});
	};

	const handleClearAllFilters = () => {
		setSearchParams(params => {
			setActiveFilters({});
			params.delete(urlParams.filters);
			return params;
		});
	};

	if (!filters?.length) {
		return <div />;
	}

	return (
		<div className={el('filtered-by-wrapper')}>
			<span className={el('filtered-by-text')}>Filtered by:</span>{' '}
			<div className={el('filter-by-list')}>
				{filters?.map(filter => (
					<Chip key={`${filter.label}-${filter.type}`} customClass={el('filter-by-list-item-chip')}>
						{filter.label}
						<Iconof
							icon="clear"
							className={el('filter-by-list-remove-filter')}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								handleRemoveFilter(filter);
							}}
						/>
					</Chip>
				))}
			</div>
			<Button
				buttonClassName={el('clear-all-filters')}
				variant="text"
				label="Clear All"
				onClick={() => handleClearAllFilters()}
			/>
		</div>
	);
};
FilteredBy.propTypes = {};

export const HomeHeader = ({ hideBackButton = false }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const { isPublic } = useIsPublic();

	const [searchParams, setSearchParams] = useSearchParams();
	const { totals } = useContext(SearchResultsContext);
	const { handleSetSelectedFilterType } = useContext(FilterDropdownContext);

	const activeTab = searchParams.get(urlParams.status) || 'all';

	const getStatusFilterId = useCallback(tab => {
		if (tab === 'All') {
			return 'all';
		}
		if (tab === 'Live') {
			return 'active';
		}
		if (tab === 'Drafts') {
			return 'draft';
		}
		if (tab === 'Complete') {
			return 'closed';
		}
		if (tab === 'Needs Review') {
			return 'needs-review';
		}
		return tab;
	}, []);

	const handleChangeTab = useCallback(
		tab => {
			const status = getStatusFilterId(tab);
			setSearchParams(params => {
				let pageParam = urlParams.studyPage;

				if (activeTab === 'studies') {
					params.set(urlParams.studyPage, 1);
				}
				if (activeTab === 'ideas') {
					pageParam = urlParams.ideaPage;
					params.set(urlParams.ideaPage, 1);
				}
				if (activeTab === 'question') {
					pageParam = urlParams.questionPage;
					params.set(urlParams.questionPage, 1);
				}
				if (activeTab === 'templates') {
					pageParam = urlParams.templatePage;
					params.set(urlParams.templatePage, 1);
				}

				params.set(pageParam, 1);
				params.set(urlParams.status, status);

				return params;
			});

			// reset filter dropdown position
			handleSetSelectedFilterType({});
		},
		[getStatusFilterId, setSearchParams, handleSetSelectedFilterType, activeTab],
	);

	const tabsWithTotals = useMemo(() => {
		let totalItems = 0;
		const tabData = [
			{ id: 'all', label: 'All', count: 0 },
			{ id: 'active', label: 'Live', count: 0 },
			{ id: 'draft', label: 'Drafts', count: 0 },
			{ id: 'closed', label: 'Complete', count: 0 },
			{ id: 'needs-review', label: 'Needs Review', count: 0 },
		];

		totals?.forEach?.(item => {
			totalItems += item.total;
			const index = tabData.findIndex(tab => tab.id === item.status);
			if (index !== -1) tabData[index].count = item.total;
		});

		tabData[0].count = totalItems || 0; // 'All' count

		return tabData;
	}, [totals]);

	return hideBackButton ? (
		<Header title="All Studies" customClass="flex-direction-column">
			<div className={el('all-studies-tab-wrapper')}>
				<Link className={el('back-link')} to={`${isPublic ? '/public' : ''}/studies`}>
					<Iconof icon="chevron_left" />
					Back
				</Link>
				<Tabs tabs={tabsWithTotals} activeTab={activeTab} setActiveTab={handleChangeTab} />
			</div>
		</Header>
	) : (
		<Header title="All Studies">
			<Tabs tabs={tabsWithTotals} activeTab={activeTab} setActiveTab={handleChangeTab} />
		</Header>
	);
};

HomeHeader.propTypes = {
	hideBackButton: PropTypes.bool,
};

export const SearchResultsProvider = ({ children }) => {
	const [totals, setTotals] = useState({});

	const contextValue = useMemo(
		() => ({
			totals,
			setTotals,
		}),
		[totals],
	);

	return <SearchResultsContext.Provider value={contextValue}>{children}</SearchResultsContext.Provider>;
};
SearchResultsProvider.propTypes = {
	children: PropTypes.any,
};

export const WrapperComponent = ({ children, hidden }) => (
	<SectionCard style={hidden ? { display: 'none' } : {}}>{children}</SectionCard>
);
WrapperComponent.propTypes = {
	children: PropTypes.any,
	hidden: PropTypes.bool,
};

const SearchResults = ({ limit = 20, pagination = false, isHome = false, allStudies = false }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const { isPublic } = useIsPublic();

	const currentClientId = useSelector(getClientId) || null;

	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	let currentFilters = null;
	if (searchParams.get(urlParams.filters)) {
		const allFilters = JSON.parse(searchParams.get(urlParams.filters));
		currentFilters = allFilters;
	}

	const activeTab = searchParams.get(urlParams.type) || 'studies';

	const collections = useSelector(getOrderedCollections);
	const studyTags = useSelector(getOrderedStudyTags);
	const productTags = useSelector(getOrderedProductTags);

	const isGuest = Auth.isDigGuest(currentClientId);
	const filterTypes = useSelector(isPublic || isGuest ? getPublicStudyFilterData : getStudyFilterData);

	const fetchCollectionData = useCallback(
		async (
			page = 0,
			search = '',
			setHideScrollObserver = null,
			types = ['studyTemplate', 'audienceTemplate'], // 'combinedTemplate'],
		) => {
			const collectionLimit = 10;
			const offset = collectionLimit * page;

			const baseFilters = {
				orderBy: 'id',
				orderByDirection: 'desc',
				limit: collectionLimit,
				search,
				offset,
			};

			const [studyTemplate, combinedTemplates, audienceTemplates] = await Promise.allSettled([
				types?.includes('studyTemplate')
					? studyService.searchStudies({
							filter: {
								...baseFilters,
								type: 'template',
								subtype: 'studyTemplate',
							},
							isPublic,
					  })
					: Promise.resolve(),
				types?.includes('combinedTemplate')
					? studyService.searchStudies({
							filter: {
								...baseFilters,
								type: 'template',
								subtype: 'combinedTemplate',
							},
							isPublic,
					  })
					: Promise.resolve(),
				types?.includes('audienceTemplate')
					? studyService.searchStudies({
							filter: {
								...baseFilters,
								type: 'template',
								subtype: 'audienceTemplate',
							},
							isPublic,
					  })
					: Promise.resolve(),
			]);

			const studyTemplateData = [...(studyTemplate?.value?.data?.items || [])];
			const combinedTemplateData = [...(combinedTemplates?.value?.data?.items || [])];
			const audienceTemplateData = [...(audienceTemplates?.value?.data?.items || [])];

			const studyTemplateUpsertData = normalizeTemplateFilterData(studyTemplateData, 'studyTemplate');
			const combinedTemplateUpsertData = normalizeTemplateFilterData(combinedTemplateData, 'combinedTemplate');
			const audienceTemplateUpsertData = normalizeTemplateFilterData(audienceTemplateData, 'audienceTemplate');

			const currentItems = collections || [];
			if (page === 0) {
				dispatch(
					upsertCollections([
						...studyTemplateUpsertData,
						...combinedTemplateUpsertData,
						...audienceTemplateUpsertData,
					]),
				);
			} else {
				dispatch(
					upsertCollections([
						...currentItems,
						...studyTemplateUpsertData,
						...combinedTemplateUpsertData,
						...audienceTemplateUpsertData,
					]),
				);
			}

			if (
				studyTemplateUpsertData?.length ||
				combinedTemplateUpsertData?.length ||
				audienceTemplateUpsertData?.length
			) {
				if (typeof setHideScrollObserver === 'function') {
					setHideScrollObserver(false);
				}
			}
		},
		[collections, dispatch, isPublic],
	);

	const fetchStudyTags = useCallback(
		async (page = 0, search = '', setHideScrollObserver = null) => {
			const tagLimit = 10;
			const offset = tagLimit * page;
			const searchQuery = search;

			const { data: allTagData } = await termService.getAllTerms(
				tagLimit,
				offset,
				searchQuery,
				'studyTags',
				isPublic,
			);

			const studyTagData = allTagData.filter(tag => tag.taxonomy === 'studyTags');

			if (page === 0) {
				dispatch(upsertStudyTags(studyTagData));
			} else {
				dispatch(upsertStudyTags([...(studyTags || []), ...studyTagData]));
			}

			if (studyTagData?.length) {
				if (typeof setHideScrollObserver === 'function') {
					setHideScrollObserver(false);
				}
			}
		},
		[dispatch, isPublic, studyTags],
	);

	const fetchIdeaTags = useCallback(
		async (page = 0, search = '', setHideScrollObserver = null) => {
			const tagLimit = 10;
			const offset = tagLimit * page;
			const searchQuery = search;

			const { data: allTagData } = await termService.getAllTerms(
				tagLimit,
				offset,
				searchQuery,
				'productTags',
				isPublic,
			);

			const productTagData = allTagData.filter(tag => tag.taxonomy === 'productTags');

			if (page === 0) {
				dispatch(upsertProductTags(productTagData));
			} else {
				dispatch(upsertProductTags([...(productTags || []), ...productTagData]));
			}

			if (productTagData?.length) {
				if (typeof setHideScrollObserver === 'function') {
					setHideScrollObserver(false);
				}
			}
		},
		[dispatch, isPublic, productTags],
	);

	const handleFilterSearch = useCallback(
		(type, page, search, callback) => {
			if (type === 'studyTag') {
				fetchStudyTags(page, search, callback);
			}

			if (type === 'ideaTag') {
				fetchIdeaTags(page, search, callback);
			}

			if (type === 'studyTemplate') {
				fetchCollectionData(page, search, callback, ['studyTemplate']);
			}

			if (type === 'audienceTemplate') {
				fetchCollectionData(page, search, callback, ['audienceTemplate']);
			}

			if (type === 'combinedTemplate') {
				fetchCollectionData(page, search, callback, ['combinedTemplate']);
			}
		},
		[fetchStudyTags, fetchIdeaTags, fetchCollectionData],
	);

	const handleChangeFilters = useCallback(
		(newFilters, clearFilters) => {
			setSearchParams(params => {
				const currentUrlFilters = params.get(urlParams.filters);
				let parsedCurrentFilters = JSON.parse(currentUrlFilters);

				// clear all filters from URL
				if (clearFilters) {
					parsedCurrentFilters = {};
				}

				let pageParam = urlParams.studyPage;
				if (activeTab === 'ideas') {
					pageParam = urlParams.ideaPage;
				} else if (activeTab === 'questions') {
					pageParam = urlParams.questionPage;
				} else if (activeTab === 'templates') {
					pageParam = urlParams.templatePage;
				}
				params.set(pageParam, 1);

				params.set(
					urlParams.filters,
					JSON.stringify({
						...parsedCurrentFilters,
						...newFilters,
					}),
				);

				return params;
			});
		},
		[activeTab, setSearchParams],
	);

	// redirect guest users if they receive a link with template search params
	useEffect(() => {
		if ((isGuest || isPublic) && searchParams.get(urlParams.type) === 'templates') {
			setSearchParams(params => {
				params.delete(urlParams.type);
				params.delete(urlParams.templatePage);
				params.delete(urlParams.subtype);
				return params;
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGuest, searchParams.get(urlParams.type), setSearchParams]);

	// Initial load only
	useEffect(() => {
		fetchStudyTags();
		fetchIdeaTags();
		fetchCollectionData();

		return () => {
			dispatch(resetStudyTags());
			dispatch(resetProductTags());
			dispatch(resetCollections());
		};

		// Disable lint - on mount only!
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentClientId]);

	return (
		<SearchResultsProvider>
			<FilterDropdownProvider
				key={`studies-${currentClientId}`}
				filterTypes={filterTypes?.filter(type => (type?.filtersFor || []).includes(activeTab))}
				onChangeCallback={handleChangeFilters}
				searchCallback={handleFilterSearch}
				defaultFilters={currentFilters || null}
			>
				<Studies
					limit={limit}
					pagination={pagination}
					isHome={isHome}
					allStudies={allStudies}
					hidden={activeTab !== 'studies'}
					wrapperStyle={activeTab === 'studies' ? 'block' : 'hidden'}
				/>

				{!isHome ? (
					<>
						<Ideas
							limit={limit}
							pagination={pagination}
							hidden={activeTab !== 'ideas'}
							wrapperStyle={activeTab === 'ideas' ? 'block' : 'hidden'}
						/>
						<Questions
							limit={limit}
							pagination={pagination}
							hidden={activeTab !== 'questions'}
							wrapperStyle={activeTab === 'questions' ? 'block' : 'hidden'}
						/>
						<Templates
							pagination={pagination}
							hidden={activeTab !== 'templates'}
							wrapperStyle={activeTab === 'templates' ? 'block' : 'hidden'}
						/>
					</>
				) : null}
			</FilterDropdownProvider>
		</SearchResultsProvider>
	);
};

SearchResults.propTypes = {
	limit: PropTypes.number,
	pagination: PropTypes.bool,
	isHome: PropTypes.bool,
	allStudies: PropTypes.bool,
};

export default SearchResults;
