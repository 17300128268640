import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Button from 'src/components/elements/Button';
import { StudyAudienceDescription } from '../../../../../../components/shared/PreviewTemplateModal/elements/SideBar';

import './styles.scss';

const className = 'audience-preview-sidebar';
const el = (name, mod) => cn(className, name, mod);

const AudiencePreviewSidebar = React.memo(
	({
		selectLanguage,
		languages,
		onSelectLanguage,
		currentTab = 'demographic',
		setCurrentTab,
		audienceTemplate,
		children,
	}) => {
		// if there is no selected language, select the first one
		useEffect(() => {
			if (
				(!selectLanguage && languages?.length) ||
				(selectLanguage && !languages?.find(l => l.languageLabel === selectLanguage))
			) {
				onSelectLanguage(languages[0]);
			}
		}, [selectLanguage, languages, onSelectLanguage]);

		return (
			<div className={className}>
				<div className={el('preview-modes')}>
					<Button
						className={`${currentTab === 'overview' || currentTab === 'demographic' ? 'selected' : ''}`}
						onClick={() => setCurrentTab('overview')}
					>
						<h3 className={el('demographic-title')}>Demographic Groups</h3>
					</Button>
					<Button
						className={`${
							currentTab === 'screening_overview' || currentTab === 'screening' ? 'selected' : ''
						}`}
						onClick={() => setCurrentTab('screening_overview')}
					>
						<span>Screening Questions</span>
					</Button>
				</div>
				<div>
					<div className={`${el('title')}`}>
						<h3>Languages</h3>
					</div>
					<div className={el('languages')}>
						{languages?.map(lang => (
							<Button
								key={lang?.languageLabel}
								className={`${el('language')} ${
									lang?.languageLabel === selectLanguage ? 'selected' : ''
								}`}
								onClick={() => onSelectLanguage(lang)}
							>
								<span>{lang?.languageLabel}</span>
							</Button>
						))}
					</div>
				</div>
				<StudyAudienceDescription
					description={audienceTemplate?.description}
					audienceId={audienceTemplate?.id}
					studyId={audienceTemplate?.studyId}
					canEdit
				/>
				{children}
			</div>
		);
	},
);

AudiencePreviewSidebar.displayName = 'AudiencePreviewSidebar';
AudiencePreviewSidebar.propTypes = {
	selectLanguage: PropTypes.string,
	languages: PropTypes.array,
	onSelectLanguage: PropTypes.func,
	currentTab: PropTypes.string,
	setCurrentTab: PropTypes.func,
	audienceTemplate: PropTypes.object,
	children: PropTypes.any,
};

export default AudiencePreviewSidebar;
