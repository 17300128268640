import React, { useState, useRef, useCallback, useMemo, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import ReactDOM from 'react-dom';
import { Input } from 'reactstrap';
import cn from 'src/utilities/bem-cn';
import Button from 'src/components/elements/Button';
import PropTypes from 'prop-types';
import { useIsPublic } from 'src/hooks';
import useIsMobile from 'src/hooks/useIsMobile';
import { Iconof } from '@upsiide/ui-components';
import FILTER from 'public/images/filter_empty.svg';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { ColorChip } from 'src/components/shared/StudyTagManager';
import FilterDropdownContext from './context';
import './styles.scss';

const className = 'filter-dropdown';
const el = (name, mod) => cn(className, name, mod);

export const FiltersSum = ({ sum }) => {
	if (sum && sum > 0) {
		return <span className="filters-sum">{sum}</span>;
	}

	return null;
};
FiltersSum.propTypes = {
	sum: PropTypes.number,
};

export const FilterDropdownPanel = () => {
	const {
		onSelectFilterToggle,
		selectedFilterType,
		query,
		handleSearch,
		activeFilters,
		handleScrollPagination,
		setHideScrollObserver,
		hideScrollObserver,
		handleSetSelectedFilterType,
		filterTypes,
		filtersByType,
	} = useContext(FilterDropdownContext);

	const { isPublic } = useIsPublic();

	let backTo = null;
	if (selectedFilterType?.backTo) {
		backTo = filterTypes?.find(type => type.value === selectedFilterType.backTo) || null;
	}

	const scrollObserver = useRef();

	const endOfListRef = useCallback(
		node => {
			if (scrollObserver.current) scrollObserver.current.disconnect();
			scrollObserver.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					setHideScrollObserver(true);
					handleScrollPagination();
				}
			});
			if (node) scrollObserver.current.observe(node);
		},
		[handleScrollPagination, setHideScrollObserver],
	);

	return (
		<div className={el('sub-animation-panels')}>
			<div
				className={el(
					'subType',
					!selectedFilterType?.subItems?.length && selectedFilterType?.backTo !== 'template' ? 'hidden' : '',
				)}
			>
				<div className={el('search-filters')}>
					<button
						className={el('back')}
						type="button"
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							handleSetSelectedFilterType(backTo);
						}}
					>
						<Iconof icon="chevron_left" />
						<span>{selectedFilterType?.label}</span>
					</button>
				</div>
				<FilterDropdownSelectType types={selectedFilterType?.subItems} />
			</div>

			<div className={el('search-filters')}>
				<div className={el('search-filters-header')}>
					<button
						className={el('back')}
						type="button"
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							handleSetSelectedFilterType(backTo);
						}}
					>
						<Iconof icon="chevron_left" />
						<span>{selectedFilterType?.label}</span>
					</button>
					<Input
						className={el('search-input')}
						placeholder="Search"
						value={query}
						onChange={e => handleSearch(selectedFilterType?.value, e.target.value)}
					/>
					<div className={el('search')}>
						<Iconof icon="search" size="large" />
					</div>
				</div>

				<ul className={el('filter-list')}>
					{filtersByType?.map(filter => (
						<li
							key={`${filter?.key ? filter?.key : filter.value}-${filter.label}`}
							aria-hidden
							onClick={e => {
								e.stopPropagation();
								e.preventDefault();
								onSelectFilterToggle(e, filter.value, selectedFilterType.value);
							}}
						>
							<Iconof
								className={el(
									`check${
										activeFilters?.[selectedFilterType?.value]?.includes(filter.value)
											? '-active'
											: ''
									}`,
								)}
								icon={activeFilters?.[selectedFilterType?.value]?.includes(filter.value) ? 'done' : ''}
							/>
							{filter.color ? (
								<ColorChip tag={filter} isPublic={isPublic} />
							) : (
								<span>{filter.label}</span>
							)}
						</li>
					))}

					{!hideScrollObserver ? <li ref={endOfListRef} className={el('scroll-observer')} /> : null}
				</ul>
			</div>
		</div>
	);
};

const FilterDropdownSelectType = ({ types }) => {
	const { getSelectedFiltersSumByType, handleSetSelectedFilterType } = useContext(FilterDropdownContext);

	return (
		<ul className={el('filter-types')}>
			{types?.map(filterType => (
				<li key={filterType.value}>
					<button
						type="button"
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							handleSetSelectedFilterType(filterType);
						}}
					>
						<span>
							{filterType.label}{' '}
							{getSelectedFiltersSumByType(filterType.value) ? (
								<span className={el('filter-number')}>
									{getSelectedFiltersSumByType(filterType.value)}
								</span>
							) : (
								''
							)}
						</span>
						<Iconof icon="chevron_right" size="large" />
					</button>
				</li>
			))}
		</ul>
	);
};
FilterDropdownSelectType.propTypes = {
	types: PropTypes.array,
};

const FilterDropdownMenuContent = ({ handleHideMobileMenu, startHideMobile }) => {
	const panelsRef = useRef();
	const isMobile = useIsMobile(960);
	const location = useLocation();
	const isTemplates = location.pathname.includes('/templates');

	const { show } = useContext(FilterDropdownContext);

	const { selectedFilterType, filterTypes, setActiveFilters, filtersSum, onChangeCallback } =
		useContext(FilterDropdownContext);

	let modClass = 'noSelection';
	if (selectedFilterType?.value) {
		modClass = 'withSection';
	}
	if (selectedFilterType?.backTo === 'template') {
		modClass = 'withSubSection';
	}

	const handleMobileDone = useCallback(() => {
		handleHideMobileMenu();
	}, [handleHideMobileMenu]);

	useEffect(() => {
		if (show) {
			if (!panelsRef?.current) {
				return;
			}
			// check if there is enough space to the left to show panelsRef
			const { left } = panelsRef.current.getBoundingClientRect();
			if (left < 0) {
				panelsRef.current.classList.add('panels-right');
			}
		}
	}, [show]);

	return (
		<div className={el('panels', `home-page ${startHideMobile ? 'hide' : ''}`)} ref={panelsRef}>
			<div className={el('panel-content')}>
				{isMobile ? (
					<div className={el('panel-header')}>
						<div>
							<span className={el('panel-header-title')}>{isTemplates ? 'Filters' : 'Filters'}</span>

							{filtersSum > 0 ? <FiltersSum sum={filtersSum} /> : null}
						</div>

						<div>
							<span
								className={el('panel-header-done')}
								role="button"
								tabIndex={0}
								onClick={() => handleMobileDone()}
								onKeyUp={() => {}}
								label="close filter dropdown"
							>
								Done
							</span>
						</div>
					</div>
				) : null}
				<div className={el('animation-panels', modClass)}>
					<div className={el('root-panel')}>
						<FilterDropdownSelectType types={filterTypes} />
					</div>

					<div className={el('secondary-panel')}>
						<FilterDropdownPanel />
					</div>
				</div>

				<div className={el('footer')}>
					<small>
						{filtersSum} {filtersSum === 1 ? 'filter' : 'filters'} applied
					</small>
					<Button
						type="text"
						label="Clear selections"
						state={filtersSum === 0 ? 'disabled' : 'active'}
						className={el('clear-selections-button')}
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							setActiveFilters(null);
							if (typeof onChangeCallback === 'function') {
								onChangeCallback(null, true);
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};
FilterDropdownMenuContent.propTypes = {
	handleHideMobileMenu: PropTypes.func,
	startHideMobile: PropTypes.func,
};

export const FilterDropdownMenu = () => {
	const isMobile = useIsMobile(960);
	const { setShow } = useContext(FilterDropdownContext);
	const [startHideMobile, setStartHideMobile] = useState(false);

	const handleHideMobileMenu = useCallback(() => {
		setStartHideMobile(true);

		setTimeout(() => {
			setShow(false);
			setStartHideMobile(false);
		}, 200);
	}, [setShow]);

	if (isMobile) {
		return ReactDOM.createPortal(
			<>
				<div
					className={el('mobile-overlay', startHideMobile ? 'hide' : '')}
					onClick={() => handleHideMobileMenu()}
					role="button"
					tabIndex={0}
					onKeyDown={() => {}}
					label="close filter dropdown"
				/>
				<FilterDropdownMenuContent
					handleHideMobileMenu={handleHideMobileMenu}
					startHideMobile={startHideMobile}
				/>
			</>,
			document.body,
		);
	}

	return <FilterDropdownMenuContent />;
};

export const FilterDropdownButton = () => {
	const { filtersSum, show, setShow } = useContext(FilterDropdownContext);

	return (
		<div aria-hidden onClick={() => setShow(prev => !prev)} className={el('content')}>
			<img src={FILTER} alt="" />
			{filtersSum ? <FiltersSum sum={filtersSum} /> : null}
			<Iconof icon={show ? 'chevron_up' : 'chevron_down'} />
		</div>
	);
};
FilterDropdownButton.propTypes = {};

export const FilterDropdown = ({ ButtonComponent = FilterDropdownButton }) => {
	const ref = useRef(null);
	const isMobile = useIsMobile(960);

	const { show, setShow } = useContext(FilterDropdownContext);

	useOutsideClick(ref, () => {
		if (!isMobile) {
			setShow(false);
		}
	});

	return (
		<div className={className} ref={ref}>
			<ButtonComponent />

			{show ? <FilterDropdownMenu /> : null}
		</div>
	);
};
FilterDropdown.propTypes = {
	ButtonComponent: PropTypes.any,
};

export const FilterDropdownProvider = ({ filterTypes, onChangeCallback, searchCallback, defaultFilters, children }) => {
	const searchTimeoutRef = useRef();

	const [query, setQuery] = useState('');
	const [show, setShow] = useState(false);
	const [selectedFilterType, setSelectedFilterType] = useState({});
	const [activeFilters, setActiveFilters] = useState(defaultFilters);
	const [pagination, setPagination] = useState({});
	const [hideScrollObserver, setHideScrollObserver] = useState(false);

	const filtersSum = activeFilters
		? Object.values(activeFilters)?.reduce((acc, filterType) => acc + filterType.length, 0)
		: 0;

	const getSelectedFiltersSumByType = useCallback(
		filterType => activeFilters?.[filterType]?.length || 0,
		[activeFilters],
	);

	const filtersByType = useMemo(() => {
		if (!selectedFilterType?.value) return [];

		let selectedFilterByType = null;

		filterTypes.find(item => {
			if (item.value === selectedFilterType.value) {
				selectedFilterByType = item;
				return true;
			}

			if (item.subItems) {
				return item.subItems?.find(subItem => {
					if (subItem.value === selectedFilterType.value) {
						selectedFilterByType = subItem;
						return true;
					}
					return false;
				});
			}

			return false;
		});

		if (!selectedFilterByType?.items?.length) return [];

		if (!query) return selectedFilterByType?.items;

		const selectedFilterByTypeFiltered = selectedFilterByType?.items.filter(({ label }) =>
			label.toLowerCase().includes(query.toLowerCase()),
		);
		return selectedFilterByTypeFiltered;
	}, [filterTypes, query, selectedFilterType]);

	const onSelectFilterToggle = useCallback(
		(e, newFilter, key) => {
			e.preventDefault();
			e.stopPropagation();

			setActiveFilters(prev => {
				let newItems = [...(prev?.[key] || [])];

				if (!prev?.[key]?.length || !prev?.[key].some(filter => filter === newFilter)) {
					newItems.push(newFilter);
				} else {
					newItems = prev?.[key].filter(filter => filter !== newFilter);
				}

				const finalFilters = {
					...prev,
					[key]: newItems,
				};

				if (onChangeCallback) {
					onChangeCallback(finalFilters);
				}

				return finalFilters;
			});

			// re-enable scroll pagination
			setHideScrollObserver(false);
		},
		[onChangeCallback],
	);

	const handleScrollPagination = useCallback(() => {
		if (typeof searchCallback === 'function') {
			setPagination(prev => {
				const nextPage = (prev[selectedFilterType.value] || 0) + 1;

				const newVal = {
					...prev,
					[selectedFilterType.value]: nextPage,
				};

				searchCallback(selectedFilterType.value, nextPage, query, setHideScrollObserver);

				return newVal;
			});
		}
	}, [searchCallback, selectedFilterType, query]);

	const handleSearch = useCallback(
		(type, val = '') => {
			setQuery(val);

			if (typeof searchCallback === 'function') {
				if (searchTimeoutRef.current) {
					clearTimeout(searchTimeoutRef.current);
				}

				searchTimeoutRef.current = setTimeout(() => {
					setPagination(prev => {
						const newVal = {
							...prev,
							[type]: 0,
						};

						searchCallback(type, 0, val, setHideScrollObserver);

						return newVal;
					});
				}, 500);
			}
		},
		[searchCallback],
	);

	const handleSetSelectedFilterType = useCallback(
		filterType => {
			setQuery('');
			setSelectedFilterType(filterType);

			if (filterType?.value) {
				handleSearch(filterType?.value, '');
			}
		},
		[handleSearch],
	);

	const contextValue = useMemo(
		() => ({
			onSelectFilterToggle,
			filtersByType,
			getSelectedFiltersSumByType,
			filtersSum,
			selectedFilterType,
			setSelectedFilterType,
			show,
			setShow,
			filterTypes,
			query,
			setQuery,
			setActiveFilters,
			activeFilters,
			onChangeCallback,
			handleScrollPagination,
			pagination,
			setPagination,
			hideScrollObserver,
			setHideScrollObserver,
			handleSearch,
			handleSetSelectedFilterType,
		}),
		[
			filterTypes,
			filtersByType,
			getSelectedFiltersSumByType,
			onSelectFilterToggle,
			selectedFilterType,
			show,
			setShow,
			query,
			setQuery,
			setActiveFilters,
			activeFilters,
			filtersSum,
			onChangeCallback,
			handleScrollPagination,
			pagination,
			setPagination,
			hideScrollObserver,
			setHideScrollObserver,
			handleSearch,
			handleSetSelectedFilterType,
		],
	);

	return <FilterDropdownContext.Provider value={contextValue}>{children}</FilterDropdownContext.Provider>;
};

FilterDropdownProvider.propTypes = {
	filterTypes: PropTypes.any,
	onChangeCallback: PropTypes.func,
	searchCallback: PropTypes.func,
	defaultFilters: PropTypes.object,
	children: PropTypes.any,
};
