// Todo: create new StudyStatus ui-component
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import './styles.scss';
import Chip from '../Chip';

const className = 'study-status';
const el = (name, mod) => cn(className, name, mod);

const StudyStatus = ({ errorStatus, status, totalCompletes, totalSampleSize }) => {
	const responsePercent = ((totalCompletes || 0) / (totalSampleSize || 0)) * 100;

	if (errorStatus) {
		return (
			<Chip customClass={el('error')}>
				<div className={el('status-dot', 'error')} />
				<div className={el('status')}>{errorStatus}</div>
			</Chip>
		);
	}

	if (status === 'active') {
		return (
			<Chip customClass={el('live')}>
				<div className={el('status-dot', 'live')} />
				<div className={el('status')}>Live</div>
				<div className={el('status-separator', 'live')}>|</div>
				<div className={el('response-status')}>
					<span className={el('responses-total', 'live')}>{totalCompletes}</span>
					{Number.isFinite(responsePercent) ? (
						<>
							<span className={el('response-separator')}>•</span>
							<span className={el('response-percent')}>{Math.floor(responsePercent)}%</span>
						</>
					) : null}
				</div>
				<div className={el('percent-bg')} style={{ width: `${responsePercent}%` }} />
			</Chip>
		);
	}

	if (status === 'draft') {
		return (
			<Chip customClass={el('draft')}>
				<div className={el('status-dot', 'draft')} />
				<div className={el('status')}>Draft</div>
			</Chip>
		);
	}

	if (status === 'closed') {
		return (
			<Chip customClass={el('closed')}>
				<div className={el('status-dot', 'complete')} />
				<div className={el('status')}>Complete</div>
				{totalCompletes ? (
					<>
						<div className={el('status-separator', 'closed')}>|</div>
						<span className={el('responses-total', 'closed')}>{totalCompletes}</span>
					</>
				) : null}
			</Chip>
		);
	}

	return status ? <Chip>{status}</Chip> : null;
};

StudyStatus.propTypes = {
	errorStatus: PropTypes.string,
	status: PropTypes.string,
	totalCompletes: PropTypes.number,
	totalSampleSize: PropTypes.number,
};

export default StudyStatus;
