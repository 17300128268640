/**
 * @param {*} key - key to set
 * @param {*} value - value to set
 * @param {*} ttl - when storage expires
 */
export const setStorageWithExpiry = (key, value, ttl, storage = window.localStorage) => {
	const now = new Date();

	const item = {
		value,
		expiry: now.getTime() + ttl,
	};

	storage.setItem(key, JSON.stringify(item));
};

/**
 * @param {*} key - key to look up in local storage
 * @returns local storage key value
 */
export const getStorageWithExpiry = (key, storage = window.localStorage) => {
	const itemStr = storage.getItem(key);

	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);
	const now = new Date();

	if (now.getTime() > item.expiry) {
		window.localStorage.removeItem(key);
		return null;
	}
	return item.value;
};

export const getSocLocalStorageKey = (studyId, sectionId) =>
	`UPSIIDE_STUDY_${studyId}_SECTION_${sectionId}_SOC_INNOVATION_PRODUCT_IDS`;
