import React from 'react';

/**
 * This component renders it's content only if `show` prop passed is truthy
 *
 * But the trick is that when you change show prop from true to false or backwards
 * It only stops/starts showing it's content after some daly so you can apply
 * css transitions in that time
 *
 * `hideDelay` or `showDelay` or just `delay`
 */
class VisibilityTransition extends React.Component {
	constructor(props) {
		super(props);

		this.state = { visible: props.show };
	}

	componentDidUpdate(prevProps) {
		if (this.props.show !== prevProps.show) {
			if (this.props.show) {
				setTimeout(() => {
					this.setState({ visible: this.props.show });
				}, this.props.showDelay || this.props.delay || 0);
			}

			if (!this.props.show) {
				setTimeout(() => {
					this.setState({ visible: this.props.show });
				}, this.props.hideDelay || this.props.delay || 0);
			}
		}
	}

	render() {
		const { children } = this.props;
		const { visible } = this.state;

		return visible ? children : null;
	}
}

export const animation = config => ({ state, children }) => (
	<div style={{ ...config.common, ...config[state] }}>{children}</div>
);

export default VisibilityTransition;
