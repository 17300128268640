import * as services from 'src/services';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import toastr from 'toastr';
import debounce from 'lodash/debounce';
import Cookies from 'src/utilities/cookies';

import * as actions from '../actions';
import * as selectors from '../selectors';

let PER_PAGE = 24;
const RECOMMENDED_PER_PAGE = 8;
let allCount = null;

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};

const performInitialFetch = (store, action) => {
	if (action.type === actions.PERFORM_INITIAL_FETCH) {
		store.dispatch(actions.fetchClients());
		store.dispatch(actions.fetchLanguages());
		store.dispatch(actions.fetchGeographies());
	}
};

const fetchClients = (store, { type }) => {
	if (type === actions.FETCH_CLIENTS) {
		store.dispatch(actions.incLoading());
		services.clientsService
			.getClients()
			.then(({ data }) => {
				store.dispatch(actions.setClients(data));
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				console.group('Error while fetching clients');
				console.error('error:', error);
				console.groupEnd();
			})
			.then(() => {
				store.dispatch(actions.decLoading());
			});
	}
};

const fetchLanguages = (store, { type }) => {
	if (type === actions.FETCH_LANGUAGES) {
		store.dispatch(actions.incLoading());
		services.languageService
			.getLanguages()
			.then(({ data }) => {
				store.dispatch(actions.setLanguages(data));
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				console.group('Error while fetching languages');
				console.error('error:', error);
				console.groupEnd();
			})
			.then(() => {
				store.dispatch(actions.decLoading());
			});
	}
};

const createStudy = async (store, { type, payload }) => {
	if (type === actions.CREATE_STUDY) {
		store.dispatch(actions.incLoading());
		store.dispatch(actions.setError(false));
		const { study, navigateTo } = payload;
		try {
			services.studyService
				.createStudy(study)
				.then(newStudy => {
					navigate(`/studies/${newStudy?.data?.uuid || newStudy?.data?.id}/${navigateTo || 'create'}`);
				})
				.then(() => {
					store.dispatch(actions.decLoading());
				});
		} catch (error) {
			toastr.error('There was an error creating your study please try again, if the error persists contact support');
			store.dispatch(actions.setError(error));
			store.dispatch(actions.decLoading());
			console.group('Error while creating study');
			console.error('error:', error);
			console.groupEnd();
		}
	}
};

const fetchGeographies = (store, { type }) => {
	if (type === actions.FETCH_GEOGRAPHIES) {
		services.languageService.getGeographies().then(({ data }) => {
			store.dispatch(actions.setGeographies(data));
		});
	}
};

/**
 * Triggers a fetch request when search query is changed
 */
const ACTIONS_THAT_TRIGGER_FETCH = [actions.SET_SEARCH_QUERY];
const triggerFetch = (store, { type, payload }) => {
	if (ACTIONS_THAT_TRIGGER_FETCH.indexOf(type) > -1) {
		if (payload.initialLoad) {
		} else {
			fetchBlueprintsDebounced(store);
			fetchRecommendedBlueprintsDebounced(store);
		}
	}
};

const fetchBlueprintsDebounced = debounce(store => {
	const upsiideAccountToken = Cookies.getUpsiideAccountToken();
	const { loggedIn } = store.getState().auth;
	if (loggedIn) {
		if (upsiideAccountToken) {
			store.dispatch(actions.fetchBlueprints({ dropPagination: true }));
		} else {
			store.dispatch(actions.fetchBlueprints({ dropPagination: true }));
		}
	}
}, 500);

const fetchRecommendedBlueprintsDebounced = debounce(store => {
	const upsiideAccountToken = Cookies.getUpsiideAccountToken();
	const { loggedIn } = store.getState().auth;
	if (loggedIn) {
		if (upsiideAccountToken) {
			store.dispatch(actions.fetchRecommendedBlueprints({ dropPagination: true }));
		} else {
			store.dispatch(actions.fetchRecommendedBlueprints({ dropPagination: true }));
		}
	}
}, 500);

// Used to prevent calling the API when no more data is available to fetch
let hasMore = true;

const fetchBlueprints = (store, { type, payload }) => {
	if (type === actions.FETCH_BLUEPRINTS) {
		const { dropPagination, initialFetch } = payload;
		const searchQuery = initialFetch ? null : selectors.getSearchQuery(store.getState());
		const blueprints = selectors.getBlueprints(store.getState());
		const filters = selectors.getBlueprintFilters(store.getState());

		const isLoading = blueprints.loading;

		if (!blueprints || !blueprints.content || (blueprints && !blueprints.content.length) || searchQuery !== null) {
			hasMore = true;
		}

		if (isLoading) {
			return;
		}

		if (!hasMore) {
			return;
		}

		if (initialFetch) {
			store.dispatch(actions.setSearchQuery(null, true));
		}

		if (!dropPagination && allCount !== null && blueprints.content.length === allCount) return;
		if (dropPagination) {
			allCount = null;
			store.dispatch(actions.setBlueprints({ content: [] }));
		}

		store.dispatch(actions.setBlueprints({ loading: true }));
		store.dispatch(actions.setError(null));

		// Rebuilding so that it doesn't effect the redux version.
		const cleanedFilters = {};

		Object.keys(filters).forEach(key => {
			cleanedFilters[key] = [...filters[key]];
			if (
				key === 'status' &&
				cleanedFilters[key].includes('draft') &&
				!cleanedFilters[key].includes('needs-review')
			) {
				cleanedFilters[key].push('needs-review');
			}
		});

		// fetch more products on first fetch to fix the issue where larger screens don't have any scroll
		if (blueprints.content.length === 0) PER_PAGE = 36;

		services.blueprintService
			.getBlueprints(
				searchQuery,
				cleanedFilters,
				PER_PAGE,
				dropPagination ? 0 : blueprints.content.length,
				'create',
				blueprints.sortBy ? blueprints.sortBy : 'name',
				blueprints.sortDirection ? blueprints.sortDirection : 'asc',
			)
			.then(({ data }) => {
				data.blueprints = data.studies;
				const blueprints = selectors.getBlueprints(store.getState());
				const clients = data.clients.sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1));

				if (data.blueprints.length === 0) {
					allCount = Math.max(allCount, blueprints.content.length);
				}

				if (data.blueprints.length < PER_PAGE) hasMore = false;

				store.dispatch(actions.setClients(clients));

				store.dispatch(
					actions.setBlueprints({
						content: dropPagination ? data.blueprints : blueprints.content.concat(data.blueprints),
					}),
				);
				store.dispatch(
					actions.setBlueprints({
						loading: false,
					}),
				);
				store.dispatch(
					actions.setBlueprints({
						...(data.studies.length > 0 ? { hasBlueprints: true } : {}),
					}),
				);
				store.dispatch(
					actions.setBlueprints({
						total: data.total,
						initialLoad: false,
						hasSearched: searchQuery && searchQuery.length,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				store.dispatch(actions.setBlueprints({ loading: false }));
			});
	}
};

// Used to prevent calling the API when no more data is available to fetch
let hasMoreRecommended = false;

const fetchRecommendedBlueprints = (store, { type, payload }) => {
	if (type === actions.FETCH_RECOMMENDED_BLUEPRINTS) {
		const { dropPagination, initialFetch } = payload;
		const searchQuery = initialFetch ? '' : selectors.getSearchQuery(store.getState());
		const blueprints = selectors.getRecommendedBlueprints(store.getState());
		const filters = selectors.getBlueprintFilters(store.getState());
		const isLoading = blueprints.loading;
		let hasMore = true;
		let itemsPerPage = RECOMMENDED_PER_PAGE;
		if (!blueprints || !blueprints.content || (blueprints && !blueprints.content.length) || searchQuery !== '') {
			hasMoreRecommended = true;
		}

		if (isLoading) {
			return;
		}

		if (!hasMoreRecommended) {
			return;
		}

		if (!dropPagination && allCount !== null && blueprints.content.length === allCount) return;
		if (dropPagination) {
			allCount = null;
			store.dispatch(actions.setRecommendedBlueprints({ content: [], initialLoad: true }));
		}

		store.dispatch(actions.setRecommendedBlueprints({ loading: true }));
		store.dispatch(actions.setError(null));

		// fetch more products on first fetch to fix the issue where larger screens don't have any scroll
		if (blueprints.content.length === 0 || dropPagination) itemsPerPage = 4;

		services.blueprintService
			.getRecommendedBlueprints(searchQuery, itemsPerPage, dropPagination ? 0 : blueprints.content.length)
			.then(({ data }) => {
				if (data.blueprints.length === 0) {
					allCount = Math.max(allCount, blueprints.content.length);
				}

				if (data.blueprints.length < itemsPerPage) hasMore = false;
				store.dispatch(
					actions.setRecommendedBlueprints({
						content: dropPagination ? data.blueprints : blueprints.content.concat(data.blueprints),
						total: data.total,
						initialLoad: false,
						loading: false,
						hasMore: dropPagination
							? data.blueprints.length < data.total
							: blueprints.content.concat(data.blueprints).length < data.total,
						...(data.blueprints && data.blueprints.length > 0 ? { hasRecommended: true } : {}),
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				store.dispatch(actions.setRecommendedBlueprints({ loading: false }));
			});
	}
};

const fetchSections = (store, action) => {
	if (action.type === actions.FETCH_SECTIONS) {
		const { studyId, refetch, loadFirst } = action.payload;
		if (!refetch) {
			store.dispatch(actions.setSections({ status: 'loading' }));
		}
		services.sections
			.blueprintGetAll(studyId)
			.then(response => {
				const content = response.data;
				// TODO - remove when ordering is fixed on the API
				content.sort((a, b) => (a.order > b.order ? 1 : -1));
				store.dispatch(actions.setSections({ status: 'ready', content }));
				if (loadFirst && content.length) {
					store.dispatch(actions.fetchSection(content[0].id, 'edit', false, studyId));
				}
			})
			.catch(error => {
				store.dispatch(actions.setSections({ status: 'error', error }));
			});
	}
};

export default [
	performInitialFetch,
	fetchClients,
	fetchLanguages,
	createStudy,
	fetchGeographies,
	fetchBlueprints,
	fetchSections,
	triggerFetch,
	fetchRecommendedBlueprints,
];
