import React from 'react';
import PropTypes from 'prop-types';
import './Divider.scss';

const className = 'divider';

const Divider = ({ customClasses }) => <div className={`${className} ${customClasses}`}></div>;

Divider.propTypes = {
	customClasses: PropTypes.string,
};

export default Divider;
