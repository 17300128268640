import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { stripHtmlTags } from 'src/utilities/misc';
import QuillMentionUtil, { filterPipedQuestionLabel } from 'src/components/inputs/SimpleRichText/QuillMentionUtil';
import './styles.scss';

const className = 'dropdown-recalling';
const el = (name, mod) => cn(className, name, mod);

const RecallQuestionHeader = ({ question, currentLanguage, allQuestions, index = 0 }) => {
	const labelWithHtml =
		question?.translations?.find(translation => translation.languageCode === currentLanguage)?.label ||
		question?.label;
	let label = stripHtmlTags(labelWithHtml);
	if (QuillMentionUtil.isPipedQuestion(label)) {
		label = filterPipedQuestionLabel(label, allQuestions);
	}

	const html = `
		<div class="mention-dropdown__list-item">
			<p class="mention-dropdown__label">
			<strong>Q${index}</strong>&nbsp;${label}
			</p>
		</div>
	`;
	return (
		<div
			className={el('dropdown_content_item_title')}
			style={{ minWidth: question?.style === 'ranked' ? 'calc(100% - 305px)' : 'calc(100% - 208px)' }}
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	);
};

RecallQuestionHeader.propTypes = {
	question: PropTypes.object,
	index: PropTypes.any,
	currentLanguage: PropTypes.any,
	allQuestions: PropTypes.any,
};

export default RecallQuestionHeader;
