const prefix = 'header';

export const SET_CREATE_ICON = `${prefix}/SET_CREATE_ICON`;
export const setCreateIcon = iconState => ({ type: SET_CREATE_ICON, payload: iconState });
export const SET_CREATE_ERROR_ICON = `${prefix}/SET_CREATE_ERROR_ICON`;
export const setCreateErrorIcon = iconState => ({ type: SET_CREATE_ERROR_ICON, payload: iconState });
export const SET_REPORT_ICON = `${prefix}/SET_REPORT_ICON`;
export const setReportIcon = iconState => ({ type: SET_REPORT_ICON, payload: iconState });
export const SET_AUDIENCE_ICON = `${prefix}/SET_AUDIENCE_ICON`;
export const setAudienceIcon = iconState => ({ type: SET_AUDIENCE_ICON, payload: iconState });
export const SET_AUDIENCE_ERROR_ICON = `${prefix}/SET_AUDIENCE_ERROR_ICON`;
export const setAudienceErrorIcon = iconState => ({ type: SET_AUDIENCE_ERROR_ICON, payload: iconState });
export const SET_WIZARD_STUDY_ICON = `${prefix}/SET_WIZARD_STUDY_ICON`;
export const setWizardStudyIcon = iconState => ({ type: SET_WIZARD_STUDY_ICON, payload: iconState });
export const SET_WIZARD_AUDIENCE_ICON = `${prefix}/SET_WIZARD_AUDIENCE_ICON`;
export const setWizardAudienceIcon = iconState => ({ type: SET_WIZARD_AUDIENCE_ICON, payload: iconState });
