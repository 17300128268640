import axios from 'axios';
import { debounce } from 'lodash';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import Cookies from 'src/utilities/cookies';
import * as authActions from 'src/domains/auth/actions';
import store from '../store';

// Using a debounce in case multiple API calls run and all return revalidate.
const debounceRevalidate = debounce(jwtToken => {
	store.dispatch(authActions.reauthenticate(jwtToken));
}, 500);

// TODO - add in 401/403 status detection - this should force logout the user and kick back to the Login screen.

/* take auth token from localStorage */
const token = Cookies && Cookies.getToken();

/* Update headers with auth token from storage */
if (token) {
	axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axios.interceptors.response.use(
	response => {
		// Do something before request is sent
		if (response.headers['x-was-revalidated'] && response.headers['x-was-revalidated'] === 'true') {
			debounceRevalidate(token);
		}
		return response;
	},
	error => {
		if (error.response) {
			// Do something before request is sent
			if (error.response.headers['x-was-revalidated'] && error.response.headers['x-was-revalidated'] === 'true') {
				debounceRevalidate(token);
			}

			// Forbidden - the user's session has expired, or has been removed/forced logout.
			if (error.response.status === 403) {
				Cookies.clearToken();
				window.location.reload();
			}

			if (error.response.status === 429) {
				store.dispatch(authActions.setRateLimit(true, error?.response?.data?.msBeforeRetry || undefined));
			}

			// Lost access to this Space/Account - redirect to the main page.
			if (error.response.data.message === 'UNAUTHORIZED_CLIENT_UUID') {
				navigate('/studies');
			}

			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
		} else {
			// Something happened in setting up the request that triggered an Error
			// console.error('error', error.message);
		}
		return Promise.reject(error);
	},
);

const download = (src, name, params = null) =>
	axios({
		url: src,
		method: 'GET',
		params,
		responseType: 'blob',
	}).then(response => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', name);
		document.body.appendChild(link);
		link.click();
	});

const postDownload = async (src, data, name, dataType) =>
	axios({
		url: src,
		method: 'POST',
		data,
		responseType: 'blob',
		dataType,
	}).then(async (response) => {
		// filename is set by backend
		// Check if we're getting a do not return file message
		let responseData;
		try {
			responseData = JSON.parse(await response.data.text());
		} catch (e) {
			console.log('Error attempting to parse response', e);
		}

		if(responseData && responseData?.delivered === true) return;

		let filename = name;
		if (response.headers['content-disposition']) {
			filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
		}
		const url = window.URL.createObjectURL(
			dataType ? new Blob([response.data], dataType) : new Blob([response.data]),
		);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		return response;
	});

export default {
	...axios,
	download,
	postDownload,
	/* Update the token */
	setToken: newToken => {
		/* Set in axios headers */
		axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
	},
	setClientId: clientId => {
		if (clientId) {
			axios.defaults.headers.common['x-client-uuid'] = clientId;
		} else {
			delete axios.defaults.headers.common['x-client-uuid'];
		}
	},
	setAccountUuid: accountUuid => {
		if (accountUuid) {
			axios.defaults.headers.common['x-account-uuid'] = accountUuid;
		} else {
			delete axios.defaults.headers.common['x-account-uuid'];
		}
	},
};
