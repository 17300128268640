import React from 'react';
import moment from 'moment';
import { Iconof } from '@upsiide/ui-components';
import { isNumber } from 'lodash';
import { formatDistanceToNow, differenceInMonths } from 'date-fns';
import { countriesWithFlags } from 'src/utilities/upsiideCountriesWithFlag';

/**
 * Retrieves a Preview image from the product
 */
import CONSTANTS from 'src/config/constants';

export const tooltipDebounceDelay = 200;

export const internalCostCalculator = 0.35;

export const getUrlParameter = name => {
	const cleanName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp(`[\\?&]${cleanName}=([^&#]*)`);
	const results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 * Get Preview image from a product
 * If sizeType is not specified, the default url will be returned
 * fieldOne and fieldTwo refer to the different "sections" of an Idea Screen, the image is present in either one of the fields
 * @param product
 * @param sizeType 'full', 'large', 'thumbnail'
 * @returns
 */
export const getPreviewImageFromProduct = (product, sizeType = '') => {
	if (product.fieldOne && product.fieldOneType === 'asset' && product.fieldOne[0] && product.fieldOne[0].url) {
		if (product.fieldOne[0].variations) {
			if (Array.isArray(sizeType)) {
				const filteredTypes = product.fieldOne[0].variations.filter(variation =>
					sizeType.includes(variation.type),
				);

				if (filteredTypes && filteredTypes.length) {
					const sortedTypes = filteredTypes.sort(
						(a, b) => sizeType.indexOf(a.type) - sizeType.indexOf(b.type),
					);

					const requestedAsset = sortedTypes.find(variation => sizeType.includes(variation.type));

					if (requestedAsset) {
						return requestedAsset.url;
					}
				}
			} else {
				const foundSizeType = product.fieldOne[0].variations.find(v => v.type === sizeType);
				if (foundSizeType) return foundSizeType.url;
			}
		}
		return product.fieldOne[0].url;
	}
	if (product.fieldTwo && product.fieldTwoType === 'asset' && product.fieldTwo[0] && product.fieldTwo[0].url) {
		if (product.fieldTwo[0].variations) {
			if (Array.isArray(sizeType)) {
				const filteredTypes = product.fieldTwo[0].variations.filter(variation =>
					sizeType.includes(variation.type),
				);

				if (filteredTypes && filteredTypes.length) {
					const sortedTypes = filteredTypes.sort(
						(a, b) => sizeType.indexOf(a.type) - sizeType.indexOf(b.type),
					);

					const requestedAsset = sortedTypes.find(variation => sizeType.includes(variation.type));

					if (requestedAsset) {
						return requestedAsset.url;
					}
				}
			} else {
				const foundSizeType = product.fieldTwo[0].variations.find(v => v.type === sizeType);
				if (foundSizeType) return foundSizeType.url;
			}
		}
		return product.fieldTwo[0].url;
	}
	return null;
};

export const importAll = r => {
	const images = {};
	r.keys().map((item, index) => {
		images[item.replace('./', '')] = r(item);
	});
	return images;
};

const studyLayoutImages = importAll(require.context('public/images/study/layouts', false, /\.(png|jpe?g|svg)$/));
export const studyLayoutOptions = [
	{
		value: 'classic',
		label: 'Classic',
		image: studyLayoutImages['classic.png'].default,
		desc: 'Perfect for studies with photos. Allows for multiple images per idea.',
	},
	{
		value: 'one-field',
		label: 'One Field',
		image: studyLayoutImages['one-field.png'].default,
		desc: 'Perfect for photo-only or text claim ideas.',
	},
	{
		value: 'two-field',
		label: 'Two Field',
		image: studyLayoutImages['two-field.png'].default,
		desc: 'The most flexible option. Perfect for studies with Multiple images or multiple sets of text.',
	},
];

/**
 * Study Filters management code
 */

export const isNull = v => v === null;
export const isUndefined = v => typeof v === 'undefined';
export const isNullOrUndefined = v => isNull(v) || isUndefined(v);
export const isArray = v => Array.isArray(v);
export const isObject = v => typeof v === 'object' && !isNull(v) && !isArray(v);
export const isUrl = v => {
	const urlRegexpThatSupportsCurveBrackets = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z{}\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z{}\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z{}\\d_]*)?$',
		'i',
	); // fragment locator

	return !!urlRegexpThatSupportsCurveBrackets.test(v);
};

/* Transforms values that represent collections to set of [key, value] pairs */
const fixUrlValue = (key, value) => {
	if (isObject(value)) {
		return Object.keys(value).reduce((acc, k) => acc.concat(fixUrlValue(`${key}[${k}]`, value[k])), []);
	}
	if (isArray(value)) {
		return value.reduce((acc, v) => acc.concat(fixUrlValue(`${key}[]`, v)), []);
	}
	return [[key, value]];
};

/* Creates URL from js entity */
export const toURL = config =>
	Object.entries(config)
		/* entries array */
		.reduce((acc, [key, value]) => acc.concat(fixUrlValue(key, value)), [])
		/* Remove empty strings and null values */
		.filter(([key, value]) => !isNullOrUndefined(value) && value !== '')
		/* Transform to URL string */
		.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
		.join('&');

/* Saves study filters to URL as search string */
export const saveFiltersToParams = filters => {
	const params = toURL(filters);

	const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
	window.history.replaceState({}, '', baseUrl + (params ? '?' : '') + params);
};

/** Process html by stripping html tags and limiting character to 40 */
export const processHtmlForProductCard = text => {
	const stripHtmlTags = text.replace(/<[^>]*>?/gm, '');
	let ret = stripHtmlTags.substring(0, 40);

	ret += ret.length < stripHtmlTags.length ? '...' : '';
	return ret;
};

export const getAllUrlParams = (url = location.href) => {
	// get query string from url (optional) or window
	let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
	// we'll store the parameters here
	const obj = {};
	// if query string exists
	if (queryString) {
		// stuff after # is not part of query string, so get rid of it
		queryString = queryString.split('#')[0];
		// split our query string into its component parts
		const arr = queryString.split('&');
		for (let i = 0; i < arr.length; i++) {
			// separate the keys and the values
			const a = arr[i].split('=');
			// set parameter name and value (use 'true' if empty)
			const paramName = a[0];
			const paramValue = typeof a[1] === 'undefined' ? true : decodeURIComponent(a[1]);
			// if the paramName ends with 2 levels of square brackets, e.g. colors[something][] or colors[something][2]
			if (paramName.match(/\[(\w+)?\]\[(\w+)?\]$/)) {
				// create key if it doesn't exist
				const key = paramName.replace(/\[(\w+)?\]\[(\w+)?\]/, '');
				if (!obj[key]) obj[key] = {};
				const result = /\[(\w+)?\]\[(\w+)?\]$/.exec(paramName);
				const firstKey = result[1];
				const secondKey = result[2];

				if (!obj[key][firstKey]) obj[key][firstKey] = [];

				if (secondKey) {
					obj[key][firstKey][secondKey] = paramValue;
				} else {
					obj[key][firstKey].push(paramValue);
				}
				// if (paramName.match(/\[\w+\]$/)) {
				// 	// get the index value and add the entry at the appropriate position
				// 	var index = /\[(\w+)\]/.exec(paramName)[1];
				// 	obj[key].push({ name: index, value: paramValue });
				// } else {
				// 	// otherwise add the value to the end of the array
				// 	obj[key].push(paramValue);
				// }

				// if the paramName ends with square brackets, e.g. colors[] or colors[2]
			} else if (paramName.match(/\[(\w+)?\]$/)) {
				// create key if it doesn't exist
				const key = paramName.replace(/\[(\w+)?\]/, '');
				if (!obj[key]) obj[key] = [];
				// if it's an indexed array e.g. colors[2]
				if (paramName.match(/\[\d+\]$/)) {
					// get the index value and add the entry at the appropriate position
					var index = /\[(\d+)\]/.exec(paramName)[1];
					obj[key][index] = paramValue;
				} else if (paramName.match(/\[\w+\]$/)) {
					// get the index value and add the entry at the appropriate position
					var index = /\[(\w+)\]/.exec(paramName)[1];
					obj[key].push({ name: index, value: paramValue });
				} else {
					// otherwise add the value to the end of the array
					obj[key].push(paramValue);
				}
			} else {
				// we're dealing with a string
				if (!obj[paramName]) {
					// if it doesn't exist, create property
					obj[paramName] = paramValue;
				} else if (obj[paramName] && typeof obj[paramName] === 'string') {
					// if property does exist and it's a string, convert it to an array
					obj[paramName] = [obj[paramName]];
					obj[paramName].push(paramValue);
				} else {
					// otherwise add the property
					obj[paramName].push(paramValue);
				}
			}
		}
	}
	return obj;
};

export const isBrowserIE = () => {
	const ua = window.navigator.userAgent;
	// Check for IE11
	if (ua.match(/Trident.*rv\:11\./)) return 11;

	// Check for IE10 and below
	return ua.toLowerCase().indexOf('msie') !== -1 ? parseInt(ua.toLowerCase().split('msie')[1]) : false;
};

export const stripHtmlTags = string => (string ? string.replace(/(<([^>]+)>)/gi, '') : '');

/** Strip HTML characters */
export const decodeEntities = (function () {
	// this prevents any overhead from creating the object each time
	const element = document.createElement('div');

	function decodeHTMLEntities(str) {
		if (str && typeof str === 'string') {
			// strip script/html tags
			str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
			str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
			element.innerHTML = str;
			str = element.textContent;
			element.textContent = '';
		}

		return str;
	}

	return decodeHTMLEntities;
})();

export const cleanHtmlString = string => (string ? decodeEntities(stripHtmlTags(string)) : '');

export const getDummyImage = (width = 100, height = 100, fillStyle = 'magenta', quality = 1.0) => {
	const canvas = document.createElement('canvas');
	canvas.setAttribute('width', width);
	canvas.setAttribute('height', height);
	const context = canvas.getContext('2d');
	context.beginPath();
	context.rect(0, 0, width, height);
	context.fillStyle = fillStyle;
	context.fill();
	const src = canvas.toDataURL('image/jpeg', quality);
	const image = new Image();
	image.src = src;
	return image;
};

export const getQuestionSetting = (question, setting) => {
	if (question && question.settings) {
		if (question.settings.find(s => s.label === setting)) {
			return question.settings.find(s => s.label === setting).value;
		}
		return null;
	}
	return null;
};

export const getSectionSetting = (section, setting) => {
	if (section && section.settings) {
		if (section.settings.find(s => s.label === setting)) {
			return section.settings.find(s => s.label === setting).value;
		}
		return null;
	}
	return null;
};

export const encodeStr = str =>
	str
		.replace(/&/g, '&amp;')
		.replace(/>/g, '&gt;')
		.replace(/</g, '&lt;')
		.replace(/"/g, '&quot;')
		.replace(/\//g, '&sol;')
		.replace(/\\/g, '&sol;')
		.replace(/'/g, '&apos;')
		.replace(/`/g, '&acute;')
		.replace(/./g, '&period;');

export const getColor = (score, productIndex, productNorms) => {
	if (productNorms) {
		const normMin = productNorms.find(norm => norm.label === 'norm_min')
			? productNorms.find(norm => norm.label === 'norm_min').value.toString()
			: 0;

		const normMax = productNorms.find(norm => norm.label === 'norm_max')
			? productNorms.find(norm => norm.label === 'norm_max').value.toString()
			: 0;

		if (normMin && normMax) {
			if (score >= normMax) return 'green';
			if (score <= normMin) return 'red';
			return 'yellow';
		}
	}

	const value = score / productIndex;

	if (productIndex > 0) {
		if (value <= 0.8 && !isNaN(productIndex)) {
			return 'red';
		}
		if (value > 0.8 && value < 1.2) {
			return 'yellow';
		}
		if (value >= 1.2) {
			return 'green';
		}
	}
	return 'grey';
};

export const getChartColors = color => {
	switch (color) {
		case 'green':
			return {
				stroke: '#8ED3B4',
				text: '#3B3B3B',
				fill: '#28B681',
			};
		case 'yellow':
			return {
				stroke: '#FFF1CB',
				text: '#3B3B3B',
				fill: '#FFC72F',
			};
		case 'red':
			return {
				stroke: '#ED7B89',
				text: '#3B3B3B',
				fill: '#FF3C41',
			};
		default:
			return {
				stroke: '#8cc3d0',
				text: '#3B3B3B',
				fill: '#8cc3d0',
			};
	}
};

export const getStudyQuestionNumber = (question, section, sections) => {
	const sectionsCopy = sections && sections.content ? sections.content : sections;
	const allPreviousQuestions = [];
	const allPreviousQuestionSections = [];

	if (sectionsCopy && sectionsCopy.length && section && section.type !== CONSTANTS.sections.type.ideaSplit) {
		const filteredQuestions = sectionsCopy.filter(
			s => s.order < section.order && s.type === CONSTANTS.sections.type.questions,
		);
		allPreviousQuestionSections.push(...filteredQuestions);
	} else if (sectionsCopy && section && section.type === CONSTANTS.sections.type.ideaSplit) {
		return question.sortOrder + 1;
	}

	if (section && sections && allPreviousQuestionSections) {
		allPreviousQuestionSections.forEach(s => {
			if (s.questions && s.questions.length > 0) {
				s.questions.forEach(q => allPreviousQuestions.push(q.id));
			}
		});
		section.questions &&
			section.questions.forEach(q => {
				if (q.sortOrder <= question.sortOrder) {
					allPreviousQuestions.push(q.id);
				}
			});
	}

	return question ? allPreviousQuestions.indexOf(question.id) + 1 : '';
};

export const hashObjectBy = (array, keyName) => {
	const finalObject = {};
	const ArrayKeys = {};
	for (const item of array) {
		if (typeof finalObject[String(item[String(keyName)])] !== 'undefined') {
			// there is a record already.
			if (ArrayKeys[item[keyName]]) {
				// is already array
				finalObject[item[keyName]].push(item);
			} else {
				// not array yet
				const temp = finalObject[item[keyName]];
				finalObject[item[keyName]] = Array();
				finalObject[item[keyName]].push(temp);
				finalObject[item[keyName]].push(item);
				ArrayKeys[item[keyName]] = true;
			}
		} else {
			finalObject[item[keyName]] = item;
		}
	}
	return finalObject;
};

export const pluralize = (amount, word, suffix = 's') => {
	if (amount === 1) {
		return word;
	}

	return `${word}${suffix}`;
};

// used for idea splits auto net edge case total percentage bar widths if its over 100
export const percentageOverride = percentage => (percentage >= 100 ? 100 : percentage);

export const isLocal = () =>
	!(process.env.production || process.env.staging || process.env.development || process.env.isProto);

export const getFloatRounded = (value = 0, digits = 1) => parseFloat(value.toFixed(digits));

export const ideaScreenLayoutNames = {
	noTitle: 'No title',
	imageOnly: 'Image Only',
	textOnly: 'Text Only',
	doubleText: 'Double Text',
	titleBottom: 'Tittle Bottom',
	titleTop: 'Title Top',
	sideBySide: 'Side by Side',
};

export const productHasVideoAsset = product => {
	if (product?.fieldOneType === 'asset' && product?.fieldOne && product?.fieldOne[0]?.mediaType === 'video') {
		return true;
	}
	if (product?.fieldTwoType === 'asset' && product?.fieldTwo && product?.fieldTwo[0]?.mediaType === 'video') {
		return true;
	}
	if (product?.fieldThreeType === 'asset' && product?.fieldThree && product?.fieldThree[0]?.mediaType === 'video') {
		return true;
	}
	return false;
};

export const assetIsVideo = asset => {
	if (!asset?.mediaType) {
		const variations = asset?.variations || asset?.assetVariations;
		if (variations) {
			return variations.some(variation => variation.type === 'video');
		}
	} else if (asset?.mediaType === 'video') {
		return true;
	}

	return false;
};

export const getAssetVariationUrl = (asset, requestType = ['thumbnail']) => {
	// return an emptry string if no asset is provided
	if (!asset) {
		return '';
	}

	// If asset is a string, send it back
	if (typeof asset === 'string') {
		return asset;
	}

	// Look up the requested variation
	if (asset?.variations?.length || asset?.assetVariations?.length) {
		const variations = asset?.variations || asset?.assetVariations;

		const filteredTypes = variations.filter(assetVariation => requestType.includes(assetVariation.type));

		if (filteredTypes && filteredTypes.length) {
			const sortedTypes = filteredTypes.sort((a, b) => requestType.indexOf(a.type) - requestType.indexOf(b.type));

			const requestedAsset = sortedTypes.find(assetVariation => requestType.includes(assetVariation.type));

			if (requestedAsset) {
				return requestedAsset.url || requestedAsset.location;
			}
		}
	}

	// Defailt return asset URL
	return asset.url || '';
};

export const csvFileToArray = async file => {
	if (file?.type !== 'text/csv') {
		throw new Error('File is not a csv');
	}

	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			if (!reader.result) {
				reject();
			}

			// Reads each character of text and adds to array
			// Handles commas in cells
			// Reference: https://stackoverflow.com/questions/54860670/how-to-split-a-string-containing-csv-data-with-arbitrary-text-into-a-javascript
			const parseRow = row => {
				let isInQuotes = false;
				let val = '';
				const values = [];

				// eslint-disable-next-line no-plusplus
				for (let i = 0; i < row.length; i++) {
					switch (row[i]) {
						case ',':
							if (isInQuotes) {
								val += row[i];
							} else {
								values.push(val);
								val = '';
							}
							break;

						case '"':
							if (isInQuotes && i + 1 < row.length && row[i + 1] === '"') {
								val += '"';
								// eslint-disable-next-line no-plusplus
								i++;
							} else {
								isInQuotes = !isInQuotes;
							}
							break;

						default:
							val += row[i];
							break;
					}
				}

				values.push(val);

				return values;
			};

			// Split the CSV string on newline characters
			// remove any empty rows
			const csvRows = reader.result.split(/\r?\n/).filter(row => row);

			const csvData = csvRows.map(row => {
				const parsedRowData = row.split(/\r?\n/).map(parseRow).flat();
				return parsedRowData;
			});

			resolve(csvData);
		};

		reader.readAsText(file);
	});
};

export const reorderReport = (reportData, questionData) => {
	const orderMaskedOptions = (maskedResponses = [], maskedOptions) => {
		const returnResponses = [];
		for (const maskedOption of maskedOptions) {
			if (maskedOption?.maskedOptions?.length) {
				returnResponses.push(orderMaskedOptions(maskedResponses, maskedOption.maskedOptions));
			} else {
				returnResponses.push([...maskedResponses.filter(resp => resp?.id === maskedOption?.id)]);
			}
		}
		return returnResponses;
	};

	const orderedReport = [];

	// add sort order to main question options(considering masked as one option)
	reportData.forEach(data => {
		if (questionData?.options?.length) {
			const currentOption = questionData.options.find(o => o.id === data.id);
			data.sortOrder = !isNaN(currentOption?.order) ? currentOption?.order : data?.sortOrder;
			if (data?.maskedQuestionId) {
				const maskedOption = questionData?.options?.find(
					option => option?.maskedQuestionId === data?.maskedQuestionId && option?.logic === data?.logic,
				);
				data.sortOrder = !isNaN(maskedOption?.order) ? maskedOption?.order : data?.sortOrder;
			}
		} else {
			// is a custom target
			data.sortOrder = data?.id;
		}
	});

	// sort the report to make sure surface options are ordered
	reportData.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));

	// get ordered surface option ids (considering masking option as one)
	const orderedReportIds = [
		...new Set(
			reportData.map(data => {
				if (data?.maskedQuestionId) {
					const maskedOption = questionData?.options?.find(
						option => option?.maskedQuestionId === data?.maskedQuestionId && option?.logic === data?.logic,
					);
					return maskedOption?.id;
				}
				return data?.id;
			}),
		),
	];

	// all responses that are on the report and are resulting from masked options
	const maskedResponses = reportData.filter(option => option?.maskedQuestionId);
	for (const optionId of orderedReportIds) {
		// grab the complete option from report data
		const option = reportData?.find(option => option?.id === optionId);
		// if it is a surface option (original, add it to final array)
		if (option) {
			orderedReport.push(option);
		} else {
			// else order it recursively
			const mask = questionData?.options?.find(option => option?.id === optionId);
			orderedReport.push(...orderMaskedOptions(maskedResponses, mask?.maskedOptions || []).flat(Infinity));
		}
	}

	// after we got the assigned option to each position, set the sort order to avoid
	// having collisions on sortOrder value.
	reportData.forEach(data => {
		data.sortOrder = orderedReport.findIndex(option => option?.id === data?.id);
	});

	// then sort the reportData by the sortOrder(finally uff);
	reportData.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));
};

export const getBrowserName = () => {
	const { userAgent } = navigator;

	if (userAgent.match(/chrome|chromium|crios/i)) return 'chrome';

	if (userAgent.match(/firefox|fxios/i)) return 'firefox';

	if (userAgent.match(/safari/i)) return 'safari';

	if (userAgent.match(/opr\//i)) return 'opera';

	if (userAgent.match(/edg/i)) return 'edge';

	return 'chrome'; // default
};

export const removeDuplicatedByProp = (list, prop) => {
	const map = Object.create(null);
	for (const item of list) {
		const id = item[prop];
		if (!map[id]) map[id] = item;
	}
	return Object.values(map);
};

export const ERROR_TYPES = {
	noContent: 'NO_CONTENT',
	systemStopped: 'SYSTEM_STOPPED',
	missingContent: 'MISSING_CONTENT',
	missingTranslation: 'MISSING_TRANSLATION',
	ideaSplitsLow: 'IDEA_SPLITS_LOW',
	pipingError: 'PIPING_ERROR',
	maskingError: 'MASKING_ERROR',
	invalidSample: 'INVALID_SAMPLE',
	missingCountryLanguage: 'MISSING_COUNTRY_LANGUAGE',
	screenerWithoutGroup: 'SCREENER_WITHOUT_GROUP',
};

export const arrayUniqueByKey = (key, array = []) => [...new Map(array?.map(item => [item[key], item])).values()];

export const renderAudienceFlag = group => {
	const flag = countriesWithFlags.find(({ id }) => id === group?.countryLanguage.countryId)?.flag;

	if (!flag && group?.provider.toLowerCase() === 'byo') {
		return <Iconof icon="link" />;
	}

	return flag;
};

export const renderAudienceName = group => {
	const countryAndLanguageSet = !!group?.languageCode;
	const noNameSet = group?.name === '';
	if (noNameSet) {
		return countryAndLanguageSet
			? `${group?.countryLanguage?.countryLabel} - ${group?.countryLanguage?.languageLabel}`
			: 'Untitled group';
	}
	return group?.name;
};

export const getPngExportAudienceName = audienceObject => {
	if (audienceObject.currentAudience) {
		if (!audienceObject?.currentAudience?.name) {
			return renderAudienceName(audienceObject?.currentAudience);
		}
		return audienceObject?.currentAudience?.name;
	}
	if (!audienceObject?.name) {
		return 'Untitled Audience Collection';
	}
	return audienceObject.name;
};

export const getPricingTiers = lengthOfInterview => {
	if (lengthOfInterview <= 5) return 1;
	if (lengthOfInterview <= 10) return 2;
	if (lengthOfInterview <= 15) return 3;
	return 4;
};

export const getLoiLabelWithPricingTier = pricingTier => {
	if (pricingTier === 1) return '1-5 minutes';
	if (pricingTier === 2) return '6-10 minutes';
	if (pricingTier === 3) return '11-15 minutes';
	return '15+ minutes';
};

export const getDuplicateAudienceTemplateName = name => {
	if (name.includes('(Copy)')) return name;
	if (name === '') return 'Untitled Audience (Copy)';
	return `${name} (Copy)`;
};

export const tagColors = [
	{
		darker: '#764040',
		lighter: '#FFEAED',
		name: 'Red',
	},
	{
		darker: '#6C3E09',
		lighter: '#FFE9CE',
		name: 'Orange',
	},
	{
		darker: '#684924',
		lighter: '#FFF1CB',
		name: 'Yellow',
	},
	{
		darker: '#36420A',
		lighter: '#F2FAD6',
		name: 'Green',
	},
	{
		darker: '#0D4A61',
		lighter: '#E6F2F5',
		name: 'Blue',
	},
	{
		darker: '#113B6D',
		lighter: '#EFF3FF',
		name: 'Dark Blue',
	},
	{
		darker: '#621F68',
		lighter: '#FFEEFD',
		name: 'Pink',
	},
	{
		darker: '#2A2A78',
		lighter: '#DEDEFF',
		name: 'Purple',
	},
];

// Converts the givenDate to ms and compares it to the current date
// If the givenDate was more than 1 month ago, return the actualDate
// If not, return the givenDate relative to today. i.e. 7 hours ago, 3 days ago, etc.
export const formatRelativeDate = (givenDate, shortFormat = false) => {
	if (givenDate) {
		const timeNowMs = new Date().getTime();
		const revisedAtMs = Date.parse(givenDate); // convert revisedAt to MS for comparison
		if (Number.isNaN(revisedAtMs)) return null;
		const showActualDate = differenceInMonths(timeNowMs, revisedAtMs) > 0;
		const relativeDate = formatDistanceToNow(new Date(revisedAtMs)) + (shortFormat ? '' : ' ago');
		const actualDate = moment(revisedAtMs).format('MMM DD, YYYY');

		return {
			date: showActualDate ? actualDate : relativeDate,
			relativeDate,
			actualDate,
			showActualDate,
		};
	}
	return null;
};

export const formatEmojiUnicode = code => {
	const parts = code.split('-');
	const formattedParts = parts.map(part => `0x${part}`);
	const emoji = String.fromCodePoint(...formattedParts);

	return emoji;
};

export const generateOrdinalSuffix = n => {
	if (!isNumber(n)) return '';

	let ord = 'th';

	if (n % 10 === 1 && n % 100 !== 11) {
		ord = 'st';
	} else if (n % 10 === 2 && n % 100 !== 12) {
		ord = 'nd';
	} else if (n % 10 === 3 && n % 100 !== 13) {
		ord = 'rd';
	}

	return ord;
};

export default {
	getPreviewImageFromProduct,
	cleanHtmlString,
	getDummyImage,
	getQuestionSetting,
	stripHtmlTags,
	getStudyQuestionNumber,
	assetIsVideo,
	getAssetVariationUrl,
	productHasVideoAsset,
	csvFileToArray,
	reorderReport,
	getBrowserName,
	removeDuplicatedByProp,
	renderAudienceName,
	getPricingTiers,
	getLoiLabelWithPricingTier,
	formatRelativeDate,
	getDuplicateAudienceTemplateName,
	formatEmojiUnicode,
	renderAudienceFlag,
};
