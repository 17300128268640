const prefix = 'public-report';

export * from './reporting-sections';

export const SET_STUDY = `${prefix}/SET_STUDY`;
export const setStudy = study => ({ type: SET_STUDY, payload: { study } });

export const SET_LANGUAGES = `${prefix}/SET_LANGUAGES`;
export const setLanguages = languages => ({ type: SET_LANGUAGES, payload: { languages } });

export const SET_LANGUAGE = `${prefix}/SET_LANGUAGE`;
export const setLanguage = language => ({ type: SET_LANGUAGE, payload: { language } });

export const SET_STUDY_FILTERS = `${prefix}/SET_STUDY_FILTERS`;
export const setFilters = filters => ({
	type: SET_STUDY_FILTERS,
	payload: { filters },
});

export const SET_LOCAL_FILTERS = `${prefix}/SET_LOCAL_FILTERS`;
export const setLocalFilters = (questionId, questionFilters = []) => ({
	type: SET_LOCAL_FILTERS,
	payload: { questionId, questionFilters },
});

export const FETCH_QUESTION_REPORT = `${prefix}/FETCH_QUESTION_REPORT`;
export const fetchQuestionReport = (questionId, selectedBaseFilters) => ({
	type: FETCH_QUESTION_REPORT,
	payload: { questionId, selectedBaseFilters },
});

export const FETCH_IDEA_SPLIT_QUESTION_REPORT = `${prefix}/FETCH_IDEA_SPLIT_QUESTION_REPORT`;
export const fetchIdeaSplitQuestionReport = (questionId, selectedBaseFilters) => ({
	type: FETCH_IDEA_SPLIT_QUESTION_REPORT,
	payload: { questionId, selectedBaseFilters },
});

/** audience report */
export const FETCH_AUDIENCE_SECTION = `${prefix}/FETCH_AUDIENCE_SECTION`;
export const fetchAudienceSection = (audienceUuid, filters = []) => ({
	type: FETCH_AUDIENCE_SECTION,
	payload: { audienceUuid, filters },
});

export const SET_AUDIENCE_REPORTS = `${prefix}/SET_AUDIENCE_REPORTS`;
export const setAudienceReports = (audienceUuid, data) => ({
	type: SET_AUDIENCE_REPORTS,
	payload: { audienceUuid, data },
});

export const SET_SHOW_AUDIENCE_REPORTS = `${prefix}/SET_SHOW_AUDIENCE_REPORTS`;
export const setShowAudienceReports = showReports => ({
	type: SET_SHOW_AUDIENCE_REPORTS,
	payload: { showReports },
});

export const PATCH_SHOW_AUDIENCE_REPORTS = `${prefix}/PATCH_SHOW_AUDIENCE_REPORTS`;
export const patchShowAudienceReports = itemData => ({
	type: PATCH_SHOW_AUDIENCE_REPORTS,
	payload: { itemData },
});

export const PATCH_SHOW_AUDIENCE_REPORT_ITEM = `${prefix}/PATCH_SHOW_AUDIENCE_REPORT_ITEM`;
export const patchShowAudienceReportItem = (audienceUuid, questionId, itemData) => ({
	type: PATCH_SHOW_AUDIENCE_REPORT_ITEM,
	payload: { audienceUuid, questionId, itemData },
});

export const SET_PRODUCTS = `${prefix}/SET_PRODUCTS`;
export const setProducts = products => ({
	type: SET_PRODUCTS,
	payload: { products },
});

export const SORT_PRODUCTS = `${prefix}/SORT_PRODUCTS`;
export const sortProducts = (productSortBy, productSortDescending) => ({
	type: SORT_PRODUCTS,
	payload: { productSortBy, productSortDescending },
});
export const SET_PRODUCT_SORT = `${prefix}/SET_PRODUCT_SORT`;
export const setProductSort = (productSort, productSortDescending) => ({
	type: SET_PRODUCT_SORT,
	payload: { productSort, productSortDescending },
});

export const SET_PRODUCT_TERMS = `${prefix}/SET_PRODUCT_TERMS`;
export const setProductTerms = terms => ({
	type: SET_PRODUCT_TERMS,
	payload: terms,
});

export const SET_RESPONSE_DATA = `${prefix}/SET_RESPONSE_DATA`;
export const setResponseData = responseData => ({
	type: SET_RESPONSE_DATA,
	payload: { responseData },
});

export const FETCH_FILTERED_BASE = `${prefix}/FETCH_FILTERED_BASE`;
export const fetchFilteredBase = (id, filters) => ({
	type: FETCH_FILTERED_BASE,
	payload: { id, filters },
});

export const SET_ERROR = `${prefix}/SET_ERROR`;
export const setError = error => ({ type: SET_ERROR, payload: { error } });

export const FETCH_DATA = `${prefix}/FETCH_DATA`;
export const fetchData = id => ({ type: FETCH_DATA, payload: { id } });

export const FETCH_PRODUCTS = `${prefix}/FETCH_PRODUCTS`;
export const fetchProducts = id => ({
	type: FETCH_PRODUCTS,
	payload: { id },
});
export const FETCH_PRODUCTS_RESPONSE_DATA = `${prefix}/FETCH_PRODUCTS_RESPONSE_DATA`;
export const fetchProductsAndResponseData = (id, preloadFilters) => ({
	type: FETCH_PRODUCTS_RESPONSE_DATA,
	payload: { id, preloadFilters },
});

export const FETCH_RESPONSE_DATA = `${prefix}/FETCH_RESPONSE_DATA`;
export const fetchResponseData = (id, filters) => ({
	type: FETCH_RESPONSE_DATA,
	payload: { id, filters },
});

export const SET_QUARTILE_VALUE = `${prefix}/SET_QUARTILE_VALUE`;
export const setQuartileValue = (id, filters, quartile) => ({
	type: SET_QUARTILE_VALUE,
	payload: { id, filters, quartile },
});

export const SET_IDEA_MAP = `${prefix}/SET_IDEA_MAP`;
export const setIdeaMap = ideaMapData => ({
	type: SET_IDEA_MAP,
	payload: ideaMapData,
});

export const DRAFT_STUDY = `${prefix}/DRAFT_STUDY`;
export const draftStudy = id => ({ type: DRAFT_STUDY, payload: { id } });

export const PUBLISH_STUDY = `${prefix}/PUBLISH_STUDY`;
export const publishStudy = id => ({ type: PUBLISH_STUDY, payload: { id } });

export const CLOSE_STUDY = `${prefix}/CLOSE_STUDY`;
export const closeStudy = id => ({ type: CLOSE_STUDY, payload: { id } });

export const PERFORM_INITIAL_FETCH = `${prefix}/PERFORM_INITIAL_FETCH`;
export const performInitialFetch = (id, sectionId = null, preloadFilters = false) => ({
	type: PERFORM_INITIAL_FETCH,
	payload: { id, sectionId, preloadFilters },
});

export const PATCH_STUDY = `${prefix}/PATCH_STUDY`;
export const patchStudy = (id, data) => ({ type: PATCH_STUDY, payload: { id, data } });

export const PATCH_STUDY_SETTINGS = `${prefix}/PATCH_STUDY_SETTINGS`;
export const patchStudySettings = (id, data) => ({ type: PATCH_STUDY_SETTINGS, payload: { id, data } });

export const SET_PRODUCT_VIEW_MODE = `${prefix}/SET_PRODUCT_VIEW_MODE`;
export const setProductViewMode = productViewMode => ({
	type: SET_PRODUCT_VIEW_MODE,
	payload: { productViewMode },
});

export const SET_QUESTION_VIEW_MODE = `${prefix}/SET_QUESTION_VIEW_MODE`;
export const setQuestionViewMode = questionViewMode => ({
	type: SET_QUESTION_VIEW_MODE,
	payload: { questionViewMode },
});

export const SET_IDEA_MAP_LOADING = `${prefix}/SET_IDEAMAP_LOADING`;
export const setIdeaMapLoading = isLoading => ({ type: SET_IDEA_MAP_LOADING, payload: { isLoading } });

export const SET_GENERATE_REPORT_MODAL = `${prefix}/SET_GENERATE_REPORT_MODAL`;
export const setGenerateReportModal = generateReportModal => ({
	type: SET_GENERATE_REPORT_MODAL,
	payload: { generateReportModal },
});

export const GENERATE_REPORT = `${prefix}/GENERATE_REPORT`;
export const generateReport = (studyId, name, data) => ({
	type: GENERATE_REPORT,
	payload: {
		studyId,
		name,
		data,
	},
});

export const GENERATE_INTEREST_CSV = `${prefix}/GENERATE_INTEREST_CSV`;
export const generateInterestCsv = (studyId, studyName, sectionId, data) => ({
	type: GENERATE_INTEREST_CSV,
	payload: {
		studyId,
		studyName,
		sectionId,
		data,
	},
});

export const GENERATE_SOC_CSV = `${prefix}/GENERATE_SOC_CSV`;
export const generateSocCsv = (studyId, studyName, sectionId, data) => ({
	type: GENERATE_SOC_CSV,
	payload: {
		studyId,
		studyName,
		sectionId,
		data,
	},
});

export const SET_SORT_BY = `${prefix}/SET_SORT_BY`;
export const setSortBy = sortObject => ({
	type: SET_SORT_BY,
	payload: { sortObject },
});

export const SET_MANAGE_LANGUAGES_MODAL = `${prefix}/SET_MANAGE_LANGUAGES_MODAL`;
export const setManageLanguagesModal = manageLanguagesModal => ({
	type: SET_MANAGE_LANGUAGES_MODAL,
	payload: manageLanguagesModal,
});

export const SET_TRANSLATIONS_MODAL = `${prefix}/SET_TRANSLATIONS_MODAL`;
export const setTranslationsModal = translationsModal => ({
	type: SET_TRANSLATIONS_MODAL,
	payload: translationsModal,
});

export const SET_TRANSLATION_PREVIEW = `${prefix}/SET_TRANSLATION_PREVIEW`;
export const setTranslationPreview = translationData => ({
	type: SET_TRANSLATION_PREVIEW,
	payload: translationData,
});

export const SET_DISCARD_PRODUCT_CHANGES_MODAL = `${prefix}/SET_DISCARD_PRODUCT_CHANGES_MODAL`;
export const setDiscardProductChangesModal = discardProductChangesModal => ({
	type: SET_DISCARD_PRODUCT_CHANGES_MODAL,
	payload: discardProductChangesModal,
});

/*
 * Device Preview Mode Actions
 */

export const SET_DEVICE_PREVIEW_MODE = `${prefix}/SET_DEVICE_PREVIEW_MODE`;
export const setDevicePreviewMode = devicePreviewMode => ({
	type: SET_DEVICE_PREVIEW_MODE,
	payload: {
		devicePreviewMode,
	},
});

export const SHOW_DEVICE_PREVIEW_MODE = `${prefix}/SHOW_DEVICE_PREVIEW_MODE`;
export const setShowDevicePreviewMode = showDevicePreviewMode => ({
	type: SHOW_DEVICE_PREVIEW_MODE,
	payload: {
		showDevicePreviewMode,
	},
});

export const SET_DEVICE_PREVIEW_URL = `${prefix}/SET_DEVICE_PREVIEW_URL`;
export const setDevicePreviewUrl = devicePreviewUrl => ({
	type: SET_DEVICE_PREVIEW_URL,
	payload: {
		devicePreviewUrl,
	},
});

export const SET_SHOW_DEVICE_PREVIEW_IFRAME_BLOCKER = `${prefix}/SET_SHOW_DEVICE_PREVIEW_IFRAME_BLOCKER`;
export const setShowDevicePreviewIframeBlocker = showDevicePreviewIframeBlocker => ({
	type: SET_SHOW_DEVICE_PREVIEW_IFRAME_BLOCKER,
	payload: {
		showDevicePreviewIframeBlocker,
	},
});

export const SET_LAUNCH_AUDIENCE_MODAL = `${prefix}/SET_LAUNCH_AUDIENCE_MODAL`;
export const setLaunchAudienceModal = launchAudienceModal => ({
	type: SET_LAUNCH_AUDIENCE_MODAL,
	payload: { launchAudienceModal },
});

/*
 * Customize Report
 */

export const PATCH_CUSTOMIZE_REPORT_ITEM = `${prefix}/PATCH_CUSTOMIZE_REPORT_ITEM`;
export const patchCustomizeReportItem = (studyId, sectionId, itemId, itemData, activeBaseFilters) => ({
	type: PATCH_CUSTOMIZE_REPORT_ITEM,
	payload: { studyId, sectionId, itemId, itemData, activeBaseFilters },
});

export const SET_CUSTOMIZE_REPORT = `${prefix}/SET_CUSTOMIZE_REPORT`;
export const setCustomizeReport = (customizeReport, customizeReportId, activeBaseFilters, toggleSetting = false) => ({
	type: SET_CUSTOMIZE_REPORT,
	payload: { customizeReport, customizeReportId, activeBaseFilters, toggleSetting },
});

export const SET_ACTIVE_BASE_FILTERS = `${prefix}/SET_ACTIVE_BASE_FILTERS`;
export const setActiveBaseFilters = activeBaseFilters => ({
	type: SET_ACTIVE_BASE_FILTERS,
	payload: { activeBaseFilters },
});

/*
 * Filter Sidebar
 */
export const SET_SHOW_FILTER_SIDEBAR = `${prefix}/SET_SHOW_FILTER_SIDEBAR`;
export const setShowFilterSidebar = showFilterSidebar => ({
	type: SET_SHOW_FILTER_SIDEBAR,
	payload: { showFilterSidebar },
});

/*
 * Question Reporting Asset Preview
 */
export const SET_SHOW_QUESTION_REPORTING_PHOTO_PREVIEW = `${prefix}/SET_SHOW_QUESTION_REPORTING_PHOTO_PREVIEW`;
export const setShowQuestionReportingPhotoPreview = questionReportingPhotoPreviewUrl => ({
	type: SET_SHOW_QUESTION_REPORTING_PHOTO_PREVIEW,
	payload: { questionReportingPhotoPreviewUrl },
});
export const SET_SHOW_QUESTION_REPORTING_VIDEO_PREVIEW = `${prefix}/SET_SHOW_QUESTION_REPORTING_VIDEO_PREVIEW`;
export const setShowQuestionReportingVideoPreview = questionReportingVideoPreviewUrl => ({
	type: SET_SHOW_QUESTION_REPORTING_VIDEO_PREVIEW,
	payload: { questionReportingVideoPreviewUrl },
});

/*
 * Idea Split
 */
export const POST_MONADIC_SPLIT_REPORTING = `${prefix}/POST_MONADIC_SPLIT_REPORTING`;
export const postMonadicSplitReporting = (studyId, sectionId, data) => ({
	type: POST_MONADIC_SPLIT_REPORTING,
	payload: { studyId, sectionId, data },
});

export const SET_MONADIC_SPLIT_REPORTING_DATA = `${prefix}/SET_MONADIC_SPLIT_REPORTING_DATA`;
export const setMonadicSplitReportingData = monadicSplitReportingData => ({
	type: SET_MONADIC_SPLIT_REPORTING_DATA,
	payload: { monadicSplitReportingData },
});

export const EXPORT_MONADIC_SPLIT = `${prefix}/EXPORT_MONADIC_SPLIT`;
export const exportMonadicSplit = (studyId, fileType) => ({
	type: EXPORT_MONADIC_SPLIT,
	payload: { studyId, fileType },
});

export const EXPORT_MONADIC_SPLIT_COMPONENT = `${prefix}/EXPORT_MONADIC_SPLIT_COMPONENT`;
export const exportMonadicSplitComponent = (studyId, studyName, sectionId, data, fileType) => ({
	type: EXPORT_MONADIC_SPLIT_COMPONENT,
	payload: { studyId, studyName, sectionId, data, fileType },
});

export const SET_MONADIC_SPLIT_PIN = `${prefix}/SET_MONADIC_SPLIT_PIN`;
export const setMonadicSplitPin = monadicSplitPin => ({
	type: SET_MONADIC_SPLIT_PIN,
	payload: { monadicSplitPin },
});

export const SET_MONADIC_SPLIT_SORT_ORDER = `${prefix}/SET_MONADIC_SPLIT_SORT_ORDER`;
export const setMonadicSplitSortOrder = monadicSplitSortOrder => ({
	type: SET_MONADIC_SPLIT_SORT_ORDER,
	payload: { monadicSplitSortOrder },
});

export const SET_MONADIC_SPLIT_SORT_QUESTION_ID = `${prefix}/SET_MONADIC_SPLIT_SORT_QUESTION_ID`;
export const setMonadicSplitSortQuestionId = monadicSplitSortQuestionId => ({
	type: SET_MONADIC_SPLIT_SORT_QUESTION_ID,
	payload: { monadicSplitSortQuestionId },
});

export const SET_MONADIC_SPLIT_SORT_QUESTION_OPTION_ID = `${prefix}/SET_MONADIC_SPLIT_SORT_QUESTION_OPTION_ID`;
export const setMonadicSplitSortQuestionOptionId = monadicSplitSortQuestionOptionId => ({
	type: SET_MONADIC_SPLIT_SORT_QUESTION_OPTION_ID,
	payload: { monadicSplitSortQuestionOptionId },
});

export const FETCH_SHARE_OF_CHOICE = `${prefix}/FETCH_SHARE_OF_CHOICE`;
export const fetchShareOfChoice = (sectionId, studyId, selectedBaseFilters) => ({
	type: FETCH_SHARE_OF_CHOICE,
	payload: { sectionId, studyId, selectedBaseFilters },
});

export const SET_FULL_SHARE_OF_CHOICE = `${prefix}/SET_FULL_SHARE_OF_CHOICE`;
export const setFullShareOfChoice = data => ({
	type: SET_FULL_SHARE_OF_CHOICE,
	payload: { data },
});

export const SET_SHARE_OF_CHOICE = `${prefix}/SET_SHARE_OF_CHOICE`;
export const setShareOfChoice = shareOfChoice => ({ type: SET_SHARE_OF_CHOICE, payload: { shareOfChoice } });

export const SET_SHARE_OF_CHOICE_BASE_CASE = `${prefix}/SET_SHARE_OF_CHOICE_BASE_CASE`;
export const setShareOfChoiceBaseCase = shareOfChoiceBaseCase => ({
	type: SET_SHARE_OF_CHOICE_BASE_CASE,
	payload: { shareOfChoiceBaseCase },
});

export const SET_SHARE_OF_CHOICE_EXISTING_PRODUCT_IDS = `${prefix}/SET_SHARE_OF_CHOICE_EXISTING_PRODUCT_IDS`;
export const setShareOfChoiceExistingProductIds = shareOfChoiceExistingProductIds => ({
	type: SET_SHARE_OF_CHOICE_EXISTING_PRODUCT_IDS,
	payload: { shareOfChoiceExistingProductIds },
});

export const FETCH_SHARE_OF_CHOICE_PROGRESS = `${prefix}/FETCH_SHARE_OF_CHOICE_PROGRESS`;
export const fetchShareOfChoiceProgress = (sectionId, studyId) => ({
	type: FETCH_SHARE_OF_CHOICE_PROGRESS,
	payload: { sectionId, studyId },
});

export const SET_SHARE_OF_CHOICE_PROGRESS = `${prefix}/SET_SHARE_OF_CHOICE_PROGRESS`;
export const setShareOfChoiceProgress = progress => ({ type: SET_SHARE_OF_CHOICE_PROGRESS, payload: { progress } });

export const SET_SHARE_OF_CHOICE_INNOVATION_PRODUCT_IDS = `${prefix}/SET_SHARE_OF_CHOICE_INNOVATION_PRODUCT_IDS`;
export const setShareOfChoiceInnovationProductIds = shareOfChoiceInnovationProductIds => ({
	type: SET_SHARE_OF_CHOICE_INNOVATION_PRODUCT_IDS,
	payload: { shareOfChoiceInnovationProductIds },
});

export const SET_SHARE_OF_CHOICE_VIEW = `${prefix}/SET_SHARE_OF_CHOICE_VIEW`;
export const setShareOfChoiceView = shareOfChoiceView => ({
	type: SET_SHARE_OF_CHOICE_VIEW,
	payload: { shareOfChoiceView },
});

export const SET_SHARE_OF_CHOICE_SORT_ORDER = `${prefix}/SET_SHARE_OF_CHOICE_SORT_ORDER`;
export const setShareOfChoiceSortOrder = shareOfChoiceSortOrder => ({
	type: SET_SHARE_OF_CHOICE_SORT_ORDER,
	payload: { shareOfChoiceSortOrder },
});

export const SET_SHARE_OF_CHOICE_SORT_DIRECTION = `${prefix}/SET_SHARE_OF_CHOICE_SORT_DIRECTION`;
export const setShareOfChoiceSortDirection = shareOfChoiceSortDirection => ({
	type: SET_SHARE_OF_CHOICE_SORT_DIRECTION,
	payload: { shareOfChoiceSortDirection },
});

export const SET_SHARE_OF_CHOICE_EXCLUDED_PRODUCTS = `${prefix}/SET_SHARE_OF_CHOICE_EXCLUDED_PRODUCTS`;
export const setShareOfChoiceExcludedProducts = shareOfChoiceExcludedProducts => ({
	type: SET_SHARE_OF_CHOICE_EXCLUDED_PRODUCTS,
	payload: { shareOfChoiceExcludedProducts },
});

export const SET_SHARE_OF_CHOICE_DATA_AVAILABLE = `${prefix}/SET_SHARE_OF_CHOICE_DATA_AVAILABLE`;
export const setShareOfChoiceDataAvailable = shareOfChoiceDataAvailable => ({
	type: SET_SHARE_OF_CHOICE_DATA_AVAILABLE,
	payload: shareOfChoiceDataAvailable,
});

export const PROCESS_SHARE_OF_CHOICE = `${prefix}/PROCESS_SHARE_OF_CHOICE`;
export const processShareOfChoice = (studyId, sectionId) => ({
	type: PROCESS_SHARE_OF_CHOICE,
	payload: { studyId, sectionId },
});

export const SET_SHARE_OF_CHOICE_SUMMARY_CARD = `${prefix}/SET_SHARE_OF_CHOICE_SUMMARY_CARD`;
export const setShareOfChoiceSummaryCard = shareOfChoiceSummaryCard => ({
	type: SET_SHARE_OF_CHOICE_SUMMARY_CARD,
	payload: { shareOfChoiceSummaryCard },
});

export const SHARE_OF_CHOICE_CREATE_GROUP = `${prefix}/SHARE_OF_CHOICE_CREATE_GROUP`;
export const createShareOfChoiceGroup = (studyId, sectionId, name, productIds, isBaseCase = false) => ({
	type: SHARE_OF_CHOICE_CREATE_GROUP,
	payload: { studyId, sectionId, name, productIds, isBaseCase },
});

export const UPDATE_SHARE_OF_CHOICE_CREATE_GROUP = `${prefix}/UPDATE_SHARE_OF_CHOICE_CREATE_GROUP`;
export const updateShareOfChoiceGroup = (studyId, sectionId, groupId, name, productIds, isBaseCase = false) => ({
	type: UPDATE_SHARE_OF_CHOICE_CREATE_GROUP,
	payload: { studyId, sectionId, groupId, name, productIds, isBaseCase },
});

export const FETCH_SHARE_OF_CHOICE_GROUPS = `${prefix}/FETCH_SHARE_OF_CHOICE_GROUPS`;
export const fetchShareOfChoiceGroups = (studyId, sectionId) => ({
	type: FETCH_SHARE_OF_CHOICE_GROUPS,
	payload: { studyId, sectionId },
});

export const SET_SHARE_OF_CHOICE_GROUPS = `${prefix}/SET_SHARE_OF_CHOICE_GROUPS`;
export const setShareOfChoiceGroups = shareOfChoiceGroups => ({
	type: SET_SHARE_OF_CHOICE_GROUPS,
	payload: { shareOfChoiceGroups },
});

export const DELETE_SHARE_OF_CHOICE_GROUP = `${prefix}/DELETE_SHARE_OF_CHOICE_GROUP`;
export const deleteShareOfChoiceGroup = (studyId, sectionId, groupId, isBaseCase = false) => ({
	type: DELETE_SHARE_OF_CHOICE_GROUP,
	payload: { studyId, sectionId, groupId, isBaseCase },
});

export const RESET_SHARE_OF_CHOICE = `${prefix}/RESET_SHARE_OF_CHOICE`;
export const resetShareOfChoice = () => ({
	type: RESET_SHARE_OF_CHOICE,
});

export const START_SHARE_OF_CHOICE = `${prefix}/START_SHARE_OF_CHOICE`;
export const startShareOfChoice = (studyId, sectionId) => ({
	type: START_SHARE_OF_CHOICE,
	payload: { studyId, sectionId },
});

export const SET_SHARE_OF_CHOICE_LOADING = `${prefix}/SET_SHARE_OF_CHOICE_LOADING`;
export const setShareOfChoiceLoading = shareOfChoiceLoading => ({
	type: SET_SHARE_OF_CHOICE_LOADING,
	payload: { shareOfChoiceLoading },
});

export const SET_SHARE_OF_CHOICE_RESPONSE_COUNT = `${prefix}/SET_SHARE_OF_CHOICE_RESPONSE_COUNT`;
export const setShareOfChoiceResponseCount = shareOfChoiceResponseCount => ({
	type: SET_SHARE_OF_CHOICE_RESPONSE_COUNT,
	payload: { shareOfChoiceResponseCount },
});

export const RESTORE_SHARE_OF_CHOICE = `${prefix}/RESTORE_SHARE_OF_CHOICE`;
export const restoreShareOfChoice = shareOfChoiceExcludedProducts => ({
	type: RESTORE_SHARE_OF_CHOICE,
	payload: { shareOfChoiceExcludedProducts },
});

export const SET_LENGTH_OF_INTERVIEW = `${prefix}/SET_LENGTH_OF_INTERVIEW`;
export const setLengthOfInterview = (lengthOfInterview) => ({
	type: SET_LENGTH_OF_INTERVIEW,
	payload: { lengthOfInterview },
});

export const FETCH_LENGTH_OF_INTERVIEW = `${prefix}/FETCH_LENGTH_OF_INTERVIEW`;
export const fetchStudyLoi = (studyUuid) => ({
	type: FETCH_LENGTH_OF_INTERVIEW,
	payload: { studyUuid },
});

export const FETCH_AUDIENCE_COLLECTION_REPORT_DATA = `${prefix}/FETCH_AUDIENCE_COLLECTION_REPORT_DATA`;
export const fetchAudienceCollectionReportData = (studyUuid, collectionId, filters = []) => ({
	type: FETCH_AUDIENCE_COLLECTION_REPORT_DATA,
	payload: { studyUuid, collectionId, filters },
});

export const SET_AUDIENCE_COLLECTION_REPORT_DATA = `${prefix}/SET_AUDIENCE_COLLECTION_REPORT_DATA`;
export const setAudienceCollectionReportData = (collectionId, data) => ({
	type: SET_AUDIENCE_COLLECTION_REPORT_DATA,
	payload: { collectionId, data },
});

export const FETCH_DEMOGRAPHIC_QUESTIONS_REPORT_DATA = `${prefix}/FETCH_DEMOGRAPHIC_QUESTIONS_REPORT_DATA`;
export const fetchDemographicQuestionsReportData = (studyUuid, groupUuid, filters = []) => ({
	type: FETCH_DEMOGRAPHIC_QUESTIONS_REPORT_DATA,
	payload: { studyUuid, groupUuid, filters },
});

export const SET_DEMOGRAPHIC_QUESTIONS_REPORT_DATA = `${prefix}/SET_DEMOGRAPHIC_QUESTIONS_REPORT_DATA`;
export const setDemographicQuestionsReportData = (groupUuid, data) => ({
	type: SET_DEMOGRAPHIC_QUESTIONS_REPORT_DATA,
	payload: { groupUuid, data },
});
