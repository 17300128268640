import { combineReducers } from 'redux';

import auth from './auth/reducer';
import allStudies from './all-studies/reducer';
import allBlueprints from './all-blueprints/reducer';
import admin from './admin/reducers';
import createStudy from './create-study/reducer';
import createClient from './create-client/reducer';
import loginUser from './login/reducer';
import manageStudy from './manage-study/reducer';
import publicStudy from './public-study/reducer';
import manageBlueprint from './manage-blueprints/reducer';
import client from './client/reducer';
import clients from './clients/reducer';
import signUp from './signup/reducer';
import confirmSignUp from './confirm-signup/reducer';
import confirmEmail from './validate-email/reducer';
import accountDetails from './account-details/reducer';
import billingDetails from './billing-details/reducer';
import choosePlan from './choose-plan/reducer';
import inviteUsers from './invite-users/reducer';
import study from './manage-study/reducer/reporting';
import publicReport from './public-study/reducer/reporting';
import userProfile from './profile/reducer';
import userResetPassword from './reset-password/reducer';
import userAccountSetup from './account-setup/reducer';
import checkout from './checkout/reducer';
import header from './header/reducer';

// Account management
import account from './account/reducer';

export default combineReducers({
	auth,
	account,
	admin,
	allStudies,
	allBlueprints,
	createStudy,
	createClient,
	loginUser,
	manageStudy,
	publicStudy,
	client,
	clients,
	signUp,
	confirmSignUp,
	confirmEmail,
	accountDetails,
	billingDetails,
	choosePlan,
	inviteUsers,
	study,
	publicReport,
	userProfile,
	userResetPassword,
	userAccountSetup,
	checkout,
	manageBlueprint,
	header,
});
