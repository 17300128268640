import CONSTANTS from 'src/config/constants';
import { clone } from 'lodash';
import LZString from 'lz-string';
import * as services from 'src/services';
import { navigate, location } from 'src/utilities/router/routerScopeLeaker';
import { matchPath } from 'react-router';
import * as actions from '../actions/reporting';
import * as manageStudyActions from '../actions';
import * as selectors from '../selectors/reporting';
import * as helpers from '../../../components/helpers';

const getSortedMonadicSplitData = (store, data) => {
	const currentSection = selectors.getCurrentSection(store.getState());
	const section = { ...currentSection.content };
	const monadicSplitPin = selectors.getMonadicSplitPin(store.getState());
	const monadicSplitSortOrder = selectors.getMonadicSplitSortOrder(store.getState());
	const monadicSplitSortQuestionId = selectors.getMonadicSplitSortQuestionId(store.getState());
	const monadicSplitSortQuestionOptionId = selectors.getMonadicSplitSortQuestionOptionId(store.getState());

	const findQuestion = () => data.find(q => q.questionId === monadicSplitSortQuestionId);
	const findQuestionOption = q => q.options.find(o => o.optionId === monadicSplitSortQuestionOptionId);
	const sortDescending = (a, b) => b.percent - a.percent;
	const sortAscending = (a, b) => a.percent - b.percent;
	const sortDefault = (a, b) => a.id - b.id;
	const getSortMethod = () => {
		if (monadicSplitSortOrder === 'descending') {
			return sortDescending;
		}
		if (monadicSplitSortOrder === 'ascending') {
			return sortAscending;
		}
		return sortDefault;
	};
	const getSingleOrMultiPercentObject = p => ({
		id: p.productId,
		percent: (p.base / p.totalQuestionBase) * 100,
	});
	const getOpenEndedPercentObject = p => {
		const openEndedOptions = ['positive', 'negative', 'neutral'];
		const openEndedKey = openEndedOptions[monadicSplitSortQuestionOptionId];
		return {
			id: p.productId,
			percent: (p[openEndedKey] / p.total) * 100,
		};
	};

	// * Find the question
	const foundQuestion = findQuestion();
	let newProductOrder = [];
	if (foundQuestion) {
		let productsToSort = [];
		if (foundQuestion.options) {
			// * Single or multi
			const foundQuestionOption = findQuestionOption(foundQuestion);
			const { products } = foundQuestionOption;
			productsToSort = products.slice().map(p => getSingleOrMultiPercentObject(p));
		} else {
			// * Open ended
			const { products } = foundQuestion;
			productsToSort = products.slice().map(p => getOpenEndedPercentObject(p));
		}
		const filteredProducts = section.products
			.slice()
			.map(p => {
				const found = productsToSort.find(v => p.id === v.id);
				return found || { id: p.id, percent: -1 };
			})
			.sort(getSortMethod());
		newProductOrder = filteredProducts.map(p => p.id);
	}
	if (monadicSplitPin) {
		newProductOrder.sort((a, b) => {
			if (a === monadicSplitPin) {
				return -1;
			}
			if (b === monadicSplitPin) {
				return 1;
			}
			return 0;
		});
	}
	const order = {};
	for (const [index, id] of newProductOrder.entries()) {
		order[id] = index;
	}

	const attributesArray = data.filter(d => d.attributes); // pull out attributes

	const newData = data.reduce((acc, d) => {
		// * Sort each question options products in that order
		if (d.options) {
			d.options.forEach(o => o.products.sort((a, b) => order[a.productId] - order[b.productId])); // * Single or multi

			// try to find corresponding attributes, and attach to object
			const foundAttributes = attributesArray.find(attr => attr.questionId === d.questionId);

			if (foundAttributes) {
				d.gridAttributes = foundAttributes.attributes;
			}
			acc.push(d);
		} else if (!d.attributes) {
			d.products.sort((a, b) => order[a.productId] - order[b.productId]); // * Open ended
			acc.push(d);
		}
		return acc;
	}, []);

	if (section && section.products) {
		section.products.sort((a, b) => order[a.id] - order[b.id]); // * Sort the section products in that order
	}
	store.dispatch(actions.setSection({ status: 'ready', content: section })); // * Set the section
	return newData; // * Send the updated monadic data
};

/**
 * Fetch sections for the table
 */
const fetchSections = (store, action) => {
	if (action.type === actions.FETCH_SECTIONS) {
		const { studyId, refetch, loadFirst, sectionId = null, preloadFilters } = action.payload;
		if (!refetch) {
			store.dispatch(actions.setSections({ status: 'loading' }));
		}
		const study = selectors.getStudy(store.getState());
		const cachedPreloadFilters = clone(preloadFilters);
		const questionFilterIds = [];
		study?.questionFilters?.forEach(questionFilter => {
			questionFilterIds.push(questionFilter?.id);
		});
		if (Object.keys(preloadFilters).length) {
			Object.keys(preloadFilters).forEach(filterKey => {
				if (!questionFilterIds?.includes(+filterKey.split('-')[0]) && filterKey !== 'audience') {
					delete preloadFilters[filterKey];
				}
			});
		}

		services.sections
			.getAll(studyId)
			.then(response => {
				const content = response.data;
				content.sort((a, b) => (a.order > b.order ? 1 : -1));
				store.dispatch(actions.setSections({ status: 'ready', content }));
				if (loadFirst && content.length) {
					// Get the first
					const firstSection = content.find(section => section.isDisplayedInReporting);
					const firstSectionIndex = content.findIndex(section => section.isDisplayedInReporting);
					const newSectionId =
						content.find(section => section?.uuid === sectionId)?.id ||
						content.find(section => section?.id === sectionId)?.id;
					const sectionIdExistsInSections = content.find(section => section?.id === newSectionId);
					if (firstSectionIndex < 0) {
						// TODO - Toastr notification
						store.dispatch(actions.setSection({ status: 'error', error: 'No sections found' }));
					} else if (
						window.location.href.includes('audience-data') ||
						window.location.href.includes('audiences/report')
					) {
						store.dispatch(
							actions.setSection({
								status: 'ready',
								content: {
									id: 'audience',
									type: 'audience',
								},
							}),
						);
						if (Object.keys(cachedPreloadFilters).length) {
							store.dispatch(actions.setActiveBaseFilters(preloadFilters));
							store.dispatch(actions.fetchFilteredBase(studyId, preloadFilters, true));
						}
					} else if (
						window.location.href.includes('demographic-groups') ||
						window.location.href.includes('screening-questions')
					) {
						const locationString = window.location.href.includes('demographic-groups')
							? 'demographic-groups'
							: 'screening-questions';
						store.dispatch(
							actions.setSection({
								status: 'ready',
								content: {
									id: locationString,
									type: locationString,
								},
							}),
						);
						if (Object.keys(cachedPreloadFilters).length) {
							store.dispatch(actions.setActiveBaseFilters(preloadFilters));
							store.dispatch(actions.fetchFilteredBase(studyId, preloadFilters, true));
						}
					} else if (
						!(firstSectionIndex < 0) &&
						!sectionIdExistsInSections &&
						!window.location.href.includes('report/audiences') &&
						!window.location.href.includes('audiences/report') &&
						!window.location.href.includes('demographic-groups') &&
						!window.location.href.includes('screening-questions')
					) {
						let questionPath = '';
						if (firstSection?.type === 'questions') {
							const firstQuestionIndex = firstSection.questions?.findIndex(
								q => q?.isDisplayedInReporting,
							);
							questionPath =
								firstQuestionIndex !== -1
									? `/questions/${
											firstSection.questions[firstQuestionIndex || 0]?.uuid ||
											firstSection.questions[firstQuestionIndex || 0].id
									  }`
									: '';
						}
						let numericStudyIdInUrl = false;
						const urlSplitStringArray = location.pathname.split('/');
						urlSplitStringArray.forEach(urlString => {
							// eslint-disable-next-line
							if (urlString == studyId) {
								numericStudyIdInUrl = true;
							}
						});
						if (
							(matchPath('/studies/*', location?.pathname) && location?.pathname?.includes('/report')) ||
							numericStudyIdInUrl
						) {
							navigate(
								`/studies/${study?.uuid || studyId}/report/sections/${
									content[firstSectionIndex || 0]?.uuid || content[firstSectionIndex || 0].id
								}${questionPath}`,
							);
						}
						if (Object.keys(cachedPreloadFilters).length) {
							store.dispatch(actions.setActiveBaseFilters(preloadFilters));
							store.dispatch(actions.fetchFilteredBase(studyId, preloadFilters, true));
						}
						store.dispatch(
							actions.fetchSection(newSectionId || content[firstSectionIndex || 0].id, preloadFilters),
						);
					} else {
						if (Object.keys(cachedPreloadFilters).length) {
							store.dispatch(actions.setActiveBaseFilters(preloadFilters));
							store.dispatch(actions.fetchFilteredBase(studyId, preloadFilters, true));
						}
						store.dispatch(
							actions.fetchSection(newSectionId || content[firstSectionIndex || 0].id, preloadFilters),
						);
					}
				}
			})
			.catch(error => {
				store.dispatch(actions.setSections({ status: 'error', error }));
			});
	}
};

/**
 * Fetch sections for the table
 */
const fetchSection = async (store, action) => {
	if (action.type === actions.FETCH_SECTION) {
		const { sectionId, refetch, filters = [], mode } = action.payload;
		const study = selectors.getStudy(store.getState());
		const sortBy = selectors.getSortBy(store.getState());

		if (!refetch) {
			store.dispatch(actions.setSection({ status: 'loading' }));
		}

		const { language } = store.getState().study;

		// lookup question specific filters
		const localFilters = selectors.getLocalFilters(store.getState()) || [];

		try {
			const sectionResponse = await services.sections.get(study?.id, sectionId, mode, language);
			const section = sectionResponse.data;
			const { id, products, questions } = section;

			let responseDataResponse = null;
			let questionDataResponse = null;
			let newProducts = null;

			const formattedFilters = [];
			let audienceFilter = [];

			Object.keys(filters).forEach(questionId => {
				const values = filters[questionId];

				if (questionId === 'audience') {
					// Audience Filter
					audienceFilter = values?.filter(audience => audience !== 'exclude-disquals');
				} else if (values && values.length) {
					// Normal Filters
					formattedFilters.push({ questionId, values });
				} else if (values && values.attributeIds && values.attributeIds.length > 0) {
					// Attribute Filters
					const qId = questionId.split('-')[0];
					const attributeId = questionId.split('-')[1];

					formattedFilters.push({
						questionId: qId,
						values: values.value,
						attributeIds: [parseInt(attributeId)],
					});
				} else if (values && values.rank && values.value?.length) {
					formattedFilters.push({
						questionId,
						values: values.value,
						rank: parseInt(values.rank),
					});
				} else if (values && values.min && values.max) {
					// Age Filter
					formattedFilters.push({ questionId, ...values });
				}
			});
			const hasFilters = formattedFilters.length || audienceFilter.length;

			switch (section.type) {
				case 'monadic_split':
					services.sections
						.postMonadicSplitReporting(study.id, sectionId, {
							filters: formattedFilters,
							audienceUuids: audienceFilter,
							sectionId,
							localFilters,
						})
						.then(response => {
							setTimeout(() => {
								const formattedData = getSortedMonadicSplitData(store, response.data.data);
								store.dispatch(actions.setMonadicSplitReportingData(formattedData));
								store.dispatch(actions.setResponseData({
									base: response.data.base,
									filteredBase: response.data.filteredBase,
								}));
							}, 0);
						});
					break;
				case 'swipe':
					// ResponseData call
					newProducts = products;
					if (hasFilters) {
						// There's filters, so we need to do an initial call to get originals and set them on newProducts
						const allResponseDataResponse = await services.responseDataService.getResponseData(
							study.id,
							[],
							id,
							[],
						);

						newProducts = helpers.attachResponseDataToProducts(
							allResponseDataResponse.data,
							products,
							true,
						);
					}
					// Response Data
					responseDataResponse = await services.responseDataService.getResponseData(
						study.id,
						formattedFilters,
						id,
						audienceFilter,
					);
					section.reportData = responseDataResponse.data;

					store.dispatch(actions.setSectionResponseData(sectionId, responseDataResponse.data));

					section.products = helpers.sortProducts(
						// Only adding originals if there's no filters in this initial call.
						helpers.attachResponseDataToProducts(responseDataResponse.data, newProducts, !hasFilters),
						selectors.getProductSort(store.getState()),
						selectors.getProductSortDescending(store.getState()),
					);
					store.dispatch(manageStudyActions.getAudienceNorms({ studyId: study.id, sectionId }));
					break;
				case 'questions':
					// question report data call
					questionDataResponse = await services.responseDataService.getReportData(study.id, {
						sectionId,
						filters: formattedFilters,
						localFilters,
						audienceUuids: audienceFilter,
						languages: [],
					});

					section.reportData = questionDataResponse.data;
					store.dispatch(actions.setResponseData({
						base: questionDataResponse.data.base,
						filteredBase: questionDataResponse.data.filteredBase,
					}));

					// For open-ends, do calls for sentiment
					section.questions = await Promise.all(
						questions.map(async question => {
							if (question.style === 'open-ended') {
								const isNumeric =
									question.settings &&
									question.settings.find(setting => setting.label === 'numeric') &&
									question.settings.find(setting => setting.label === 'numeric').value === 'true';

								if (!isNumeric) {
									const report = await services.questionService.getQuestionReport(
										study.id,
										question.id,
										{
											sortType: 'desc',
											sortMethod: 'date',
											filters: formattedFilters,
											audienceUuids: audienceFilter,
											limit: 10,
											localFilters,
											// languages: [study.currentLanguage],
											// audiences : 'todo',
										},
									);
									question.reportData = report.data;

									const keywords = await services.questionService.getQuestionKeywords(
										study.id,
										question.id,
										{
											...sortBy,
											filters: formattedFilters,
											audienceUuids: audienceFilter,
											localFilters,
											// languages: [study.currentLanguage],
											// audiences : 'todo',
										},
									);
									question.keywords = keywords.data;
								}
							}
							return question;
						}),
					);

					break;
				default:
					break;
			}
			store.dispatch(actions.setSection({ status: 'ready', content: section }));
		} catch (error) {
			console.error(error);
			store.dispatch(actions.setSection({ status: 'error', error }));
		}
	}
};

/**
 * Fetch idea map data for the table
 */
const fetchIdeaMapData = async (store, action) => {
	if (action.type === actions.FETCH_IDEA_MAP) {
		const { sectionId, refetch, filters = [], mode } = action.payload;
		const study = selectors.getStudy(store.getState());

		if (!refetch) {
			store.dispatch(actions.setSection({ status: 'loading' }));
		}

		const { language } = store.getState().study;

		try {
			const sectionResponse = await services.sections.get(study?.id, sectionId, mode, language);
			const section = sectionResponse.data;
			const { id } = section;

			let ideaMapDataResponse = null;

			const formattedFilters = [];
			let audienceFilter = [];

			Object.keys(filters).forEach(questionId => {
				const values = filters[questionId];

				if (questionId === 'audience') {
					// Audience Filter
					audienceFilter = values;
				} else if (values && values.length) {
					// Normal Filters
					formattedFilters.push({ questionId, values });
				} else if (values && values.attributeIds && values.attributeIds.length > 0) {
					// Attribute Filters
					const qId = questionId.split('-')[0];
					const attributeId = questionId.split('-')[1];

					formattedFilters.push({
						questionId: qId,
						values: values.value,
						attributeIds: [parseInt(attributeId)],
					});
				} else if (values && values.min && values.max) {
					// Age Filter
					formattedFilters.push({ questionId, ...values });
				}
			});

			switch (section.type) {
				case 'swipe':
					ideaMapDataResponse = await services.ideaMapService.getIdeaMapData(
						study.id,
						formattedFilters,
						0.7,
						id,
						audienceFilter,
					);

					section.products = helpers.sortProducts(
						selectors.getCurrentSection(store.getState()).content.products,
						selectors.getProductSort(store.getState()),
						selectors.getProductSortDescending(store.getState()),
					);

					// idea map call
					section.ideaMap = helpers.attachProductsToIdeaMapData(ideaMapDataResponse.data, section.products);
					store.dispatch(actions.setIdeaMapLoading(false));

					break;
				default:
					break;
			}

			const currentSection = selectors.getCurrentSection(store.getState());

			store.dispatch(actions.setSection({ status: 'ready', content: { ...currentSection.content, ...section } }));
			store.dispatch(manageStudyActions.getAudienceNorms({ studyId: study.id, sectionId }));
		} catch (error) {
			console.error(error);
			store.dispatch(actions.setSection({ status: 'error', error }));
		}
	}
};

/**
 * Fetch sections for the table
 */
const refetchCurrentSection = async (store, action) => {
	if (action.type === actions.REFETCH_CURRENT_SECTION) {
		const { filters, quartile } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState());
		const sortBy = selectors.getSortBy(store.getState());
		const section = { ...currentSection.content };

		// lookup question specific filters
		const localFilters = selectors.getLocalFilters(store.getState()) || [];

		try {
			const { id, products, questions } = section;

			let responseDataResponse = null;
			let ideaMapDataResponse = null;
			let questionDataResponse = null;

			const formattedFilters = [];
			let audienceFilter = [];
			Object.keys(filters).forEach(questionId => {
				const values = filters[questionId];
				if (questionId === 'audience') {
					// Audience Filter
					audienceFilter = values;
				} else if (values && values.length) {
					// Normal Filters
					formattedFilters.push({ questionId, values });
				} else if (values && values.attributeIds && values.attributeIds.length > 0) {
					// Attribute Filters
					const qId = questionId.split('-')[0];
					const attributeId = questionId.split('-')[1];

					formattedFilters.push({
						questionId: qId,
						values: values.value,
						attributeIds: [parseInt(attributeId)],
					});
				} else if (values && values.rank && values.value?.length) {
					formattedFilters.push({
						questionId,
						values: values.value,
						rank: parseInt(values.rank),
					});
				} else if (values && values.min && values.max) {
					// Age Filter
					formattedFilters.push({ questionId, ...values });
				}
			});

			switch (section.type) {
				case 'monadic_split':
					services.sections
						.postMonadicSplitReporting(study.id, section.id, {
							filters: formattedFilters,
							audienceUuids: audienceFilter,
							sectionId: section.id,
							localFilters,
						})
						.then(response => {
							setTimeout(() => {
								const formattedData = getSortedMonadicSplitData(store, response.data.data);
								store.dispatch(actions.setMonadicSplitReportingData(formattedData));
								store.dispatch(actions.setResponseData({
									base: response.data.base,
									filteredBase: response.data.filteredBase,
								}));
							}, 0);
						});
					break;
				case 'swipe':
					// ResponseData call

					// Response Data
					// TODO - handle filters and "originals"
					responseDataResponse = await services.responseDataService.getResponseData(
						study.id,
						formattedFilters,
						id,
						audienceFilter,
					);
					section.reportData = responseDataResponse.data;
					store.dispatch(actions.setResponseData({
						base: responseDataResponse.data.base,
						filteredBase: responseDataResponse.data.filteredBase,
					}));

					section.products = helpers.sortProducts(
						helpers.attachResponseDataToProducts(responseDataResponse.data, products, false),
						selectors.getProductSort(store.getState()),
						selectors.getProductSortDescending(store.getState()),
					);

					// TODO - idea map call

					if (window.location.href.indexOf(CONSTANTS.ideaScreenSections.type.ideaMap) > -1) {
						// Idea map call
						ideaMapDataResponse = await services.ideaMapService.getIdeaMapData(
							study.id,
							formattedFilters,
							quartile,
							id,
							audienceFilter,
						);
						section.ideaMap = helpers.attachProductsToIdeaMapData(
							ideaMapDataResponse.data,
							section.products,
						);
						store.dispatch(actions.setIdeaMapLoading(false));
					}

					break;
				case 'questions':
					// question report data call
					questionDataResponse = await services.responseDataService.getReportData(study.id, {
						sectionId: section.id,
						audienceUuids: audienceFilter,
						filters: formattedFilters,
						localFilters,
						languages: [],
					});

					section.reportData = questionDataResponse.data;
					store.dispatch(actions.setResponseData({
						base: questionDataResponse.data.base,
						filteredBase: questionDataResponse.data.filteredBase,
					}));

					// For open-ends, do calls for sentiment
					section.questions = await Promise.all(
						questions.map(async question => {
							if (question.style === 'open-ended') {
								const foundQuestion = localFilters.find(
									q => q.pipedQuestionId === question.id.toString(),
								);
								const isNumeric =
									question.settings &&
									question.settings.find(setting => setting.label === 'numeric') &&
									question.settings.find(setting => setting.label === 'numeric').value === 'true';
								if (!isNumeric) {
									const report = await services.questionService.getQuestionReport(
										study.id,
										question.id,
										{
											sortType: 'desc',
											sortMethod: 'date',
											filters: formattedFilters,
											audienceUuids: audienceFilter,
											questionFilters: foundQuestion ? foundQuestion.questionFilters : [],
											// languages: [study.currentLanguage],
											// audiences : 'todo',
										},
									);
									question.reportData = report.data;

									const keywords = await services.questionService.getQuestionKeywords(
										study.id,
										question.id,
										{
											...sortBy,
											filters: formattedFilters,
											audienceUuids: audienceFilter,
											questionFilters: foundQuestion ? foundQuestion.questionFilters : [],
											// languages: [study.currentLanguage],
											// audiences : 'todo',
										},
									);
									question.keywords = keywords.data;
								}
							}
							return question;
						}),
					);
					break;
				case 'audience':
					// check if specific audience open
					if (section.audienceUuid) {
						questionDataResponse = await services.responseDataService.getAudienceReportData(
							study.id,
							section.audienceUuid,
							{
								audienceUuids: audienceFilter,
								filters: formattedFilters,
								languages: [],
							},
						);
						store.dispatch(actions.setAudienceReports(section.audienceUuid, questionDataResponse.data));
					}
					break;
				default:
					break;
			}
			store.dispatch(actions.setSection({ status: 'ready', content: section }));
		} catch (error) {
			store.dispatch(actions.setSection({ status: 'error', error }));
			console.error(error);
		}
	}
};

const sortSectionProducts = (store, { type, payload }) => {
	if (type === actions.SORT_SECTION_PRODUCTS) {
		const { productSortBy, productSortDescending } = payload;
		const currentSection = selectors.getCurrentSection(store.getState());
		const section = { ...currentSection.content };

		section.products = helpers.sortProducts(section.products, productSortBy, productSortDescending);

		store.dispatch(actions.setProductSort(productSortBy, productSortDescending));
		store.dispatch(actions.setSection({ status: 'ready', content: section }));
	}
};

const fetchQuestionResponseData = async (store, { type, payload }) => {
	if (type === actions.FETCH_QUESTION_RESPONSE_DATA) {
		const { questionId, filters, data } = payload;
		const study = selectors.getStudy(store.getState());

		const currentSection = selectors.getCurrentSection(store.getState());
		const section = { ...currentSection.content, questionIdToHighlight: questionId };
		const formattedFilters = [];
		let audienceFilter = [];

		Object.keys(filters).forEach(filterQuestionId => {
			const values = filters[filterQuestionId];

			if (filterQuestionId === 'audience') {
				// Audience Filter
				audienceFilter = values;
			} else if (values && values.length) {
				// Normal Filters
				formattedFilters.push({ filterQuestionId, values });
			} else if (values && values.attributeIds && values.attributeIds.length > 0) {
				// Attribute Filters
				const qId = filterQuestionId.split('-')[0];
				const attributeId = filterQuestionId.split('-')[1];

				formattedFilters.push({
					questionId: qId,
					values: values.value,
					attributeIds: [parseInt(attributeId)],
				});
			} else if (values && values.min && values.max) {
				// Age Filter
				formattedFilters.push({ filterQuestionId, ...values });
			}
		});

		const report = await services.questionService.getQuestionReport(study.id, questionId, {
			sortType: 'desc',
			sortMethod: 'date',
			filters: formattedFilters,
			audienceUuids: audienceFilter,
			offset: data.offset || 0,
			limit: 10,
			productId: data.productId || null,
			// languages: [study.currentLanguage],
			// audiences : 'todo',
		});

		const questionIndex = section.questions.findIndex(sectionQuestion => sectionQuestion.id === questionId);
		const question = section.questions[questionIndex];

		let reportData = question.reportData || null;
		if (!reportData || data.offset === 0) {
			reportData = report.data;
		} else {
			reportData.answers = [...reportData.answers, ...report.data.answers];
		}

		// Update the question and set it back into the section
		section.questions[questionIndex] = question;
		store.dispatch(actions.setSection({ status: 'ready', content: section }));
	}
};

export default [
	fetchSections,
	fetchSection,
	refetchCurrentSection,
	sortSectionProducts,
	fetchQuestionResponseData,
	fetchIdeaMapData,
];
