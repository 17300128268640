import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'text-input-quota';
const el = (name, mod) => cn(className, name, mod);
function TextInputQuota({
	customClasses,
	value,
	onChange,
	onBlur,
	onFocus,
	maxLength = 50,
	complement = false,
	viewMode = false,
}) {
	const textAreaRef = useRef();

	const onClickOut = e => e.stopPropagation();

	const onChangeQuota = e => {
		if (Number.isNaN(parseInt(e.target.value))) {
			onChange(null);
			return;
		}
		const numberQuota = Number(e.target.value);
		if (numberQuota < 0) return;
		onChange(numberQuota);
	};

	const renderGroupDescField = () => (
		<div className={el('percentage')}>
			{viewMode ? (
				<span className="new-textarea form-control">{Number(value) ? Number(value).toFixed(2) : value}</span>
			) : (
				<input
					rows={1}
					type="number"
					min="0"
					ref={textAreaRef}
					value={value}
					maxLength={maxLength}
					className="new-textarea form-control input-text-quota"
					onKeyDown={e => {
						if (e.key === 'Enter') {
							e.stopPropagation();
						}
					}}
					onChange={onChangeQuota}
					onBlur={onBlur}
					suppressContentEditableWarning
					onClick={onClickOut}
					onFocus={() => onFocus()}
					disabled={customClasses?.includes('is-interlocked')}
				/>
			)}
			{complement && !(value == null || value === '') ? <span aria-hidden>{complement}</span> : null}
		</div>
	);

	const focusInput = () => textAreaRef?.current && textAreaRef?.current.focus();

	return <span className={`${className} ${customClasses}`}>{renderGroupDescField()}</span>;
}

TextInputQuota.propTypes = {
	customClasses: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	maxLength: PropTypes.number,
	complement: PropTypes.string,
	viewMode: PropTypes.bool,
};

export default TextInputQuota;
