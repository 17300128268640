import React, { useCallback } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import LogicSelect from '../LogicSelect';
import { useLogicConditionContext } from '../../LogicConditionContext';

import '../../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

// OPERAND can equal AND or OR
const LogicOperandSelect = ({ options, index: rowIndex, setParentRefZIndex }) => {
	const {
		setShowLoader,
		question,
		section,
		sections,
		saveData,
		logicItem,
		isProductLogic,
		saveLogic,
		isAudienceLogic,
		audience,
		operandSelectIndex,
		operatorSelectIndex,
		setOperandSelectIndex,
		selectedItem,
	} = useLogicConditionContext();

	const onChange = useCallback(
		operandIndex => {
			setShowLoader(true);
			setOperandSelectIndex(operandIndex);

			const { id: sectionItemId } = question;
			const { id: sectionId } = section || {};

			const savedTriggerOptions = logicItem?.triggerOptions;

			const foundOptionToUpdate = savedTriggerOptions?.[rowIndex]?.id;

			if (!foundOptionToUpdate) {
				savedTriggerOptions[rowIndex] = {
					id: selectedItem.data.itemId,
					label: selectedItem.data.label,
					chipLabel: selectedItem.data.chipLabel,
					order: selectedItem.data.order,
				};
			}

			const saveDataClone = {
				...saveData,
				logic: saveData?.logic?.map((item, index) => {
					if (item?.id === logicItem?.id) {
						return {
							...item,
							id: logicItem.id,
							sectionId,
							sectionItemId: isProductLogic ? null : sectionItemId,
							operator: operatorSelectIndex ? 'is_not' : 'is',
							operand: operandIndex ? 'or' : 'and',
							triggerItemId: selectedItem?.data?.itemId,
							triggerOptions: logicItem.triggerOptions,
							...(isAudienceLogic
								? {
										audienceUuid: audience?.uuid,
										itemType: 'question',
								  }
								: {
										triggerType:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].type,
										triggerSectionId:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].id,
								  }),
						};
					}

					return item;
				}),
			};

			saveLogic(saveDataClone);
		},
		[
			audience?.uuid,
			isAudienceLogic,
			isProductLogic,
			logicItem,
			operatorSelectIndex,
			question,
			rowIndex,
			saveData,
			saveLogic,
			section,
			sections.content,
			selectedItem,
			setOperandSelectIndex,
			setShowLoader,
		],
	);

	return (
		<LogicSelect
			columnWidth={2}
			selectClassName="operand"
			options={options}
			onChange={onChange}
			menuMinWidth={80}
			value={operandSelectIndex}
			placeholder="condition"
			setParentRefZIndex={setParentRefZIndex}
		/>
	);
};

LogicOperandSelect.propTypes = {
	options: PropTypes.any,
	index: PropTypes.number,
	setParentRefZIndex: PropTypes.any,
};

export default LogicOperandSelect;
