import React, { useState, useEffect } from 'react';

import cn from 'src/utilities/bem-cn';
import AudienceScreeningQuestions from 'src/components/shared/AudienceScreeningQuestions';
import AudienceScreeningQuestionsViewMode from 'src/components/shared/AudienceScreeningQuestionsViewMode';
import GreenArrowBack from 'public/images/audience/green_arrow_left.svg';
import PropTypes from 'prop-types';

import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'screening';

const Screening = ({ audience, selectedLanguage, currentTab, setCurrentTab }) => {
	if (currentTab === 'screening_overview')
		return (
			<div className={el('overview')}>
				<span className={el('title')}>Screening Questions</span>
				<div className={el('content')}>
					<AudienceScreeningQuestions
						audienceTemplate={audience}
						setCurrentTab={setCurrentTab}
						selectedLanguage={selectedLanguage}
						step="preview"
					/>
				</div>
			</div>
		);
	return (
		<div className={el('view_mode')}>
			<div className={el('back_container')} aria-hidden onClick={() => setCurrentTab('screening_overview')}>
				<img src={GreenArrowBack} alt="" />
				<span className={el('back_button')}>Back</span>
				<span className={el('back_screening')}>Screening Questions</span>
			</div>

			<AudienceScreeningQuestionsViewMode audienceTemplate={audience} selectedLanguage={selectedLanguage} />
		</div>
	);
};

Screening.displayName = 'Screening';
Screening.propTypes = {
	audience: PropTypes.any,
	selectedLanguage: PropTypes.any,
	currentTab: PropTypes.string,
	setCurrentTab: PropTypes.func,
};

export default Screening;
