import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import useIsMobile from 'src/hooks/useIsMobile';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';

import './styles.scss';

const className = 'audience-templates-empty-state';
const el = name => cn(className, name);

const AudienceTemplatesEmptyState = ({
	templatesType = 'audience',
	category = '',
	onCreateYourOwn = false,
	noFiltersOrQueryApplied = true,
	description = `No ${templatesType === 'audience' ? 'Audience' : 'Study'} Templates found`,
}) => {
	const isMobile = useIsMobile();

	const study = useSelector(manageStudySelectors.getStudy);
	const getEmptyTemplatesText = useMemo(() => {
		if (templatesType === 'audience' || templatesType === 'audienceCreate') {
			return 'Audience Templates';
		}
		if (templatesType === 'all') {
			return 'Templates';
		}
		if (templatesType === 'study') {
			return 'Study Templates';
		}
	}, [templatesType]);

	const renderDescription = () => {
		if (noFiltersOrQueryApplied) {
			return (
				<div className={el('desc_container')}>
					<div className={el('full_description')}>
						{category === 'Recently Used'
							? 'You have no recently used templates.'
							: `You haven’t created any ${getEmptyTemplatesText} yet.`}
					</div>
					{!isMobile ? (
						<div className={el('copy_template')}>
							{category === 'Recently Used'
								? 'Use an existing template or'
								: 'Copy an existing template or'}{' '}
							{templatesType === 'audience' && !study ? (
								'save a new template from within an existing study'
							) : (
								<span aria-hidden onClick={onCreateYourOwn}>
									Create Your Own.
								</span>
							)}
						</div>
					) : null}
				</div>
			);
		}
		return <div className={el('description')}>{description}</div>;
	};

	return <div className={el('container')}>{renderDescription()}</div>;
};

AudienceTemplatesEmptyState.propTypes = {
	templatesType: PropTypes.string,
	category: PropTypes.string,
	onCreateYourOwn: PropTypes.func,
	noFiltersOrQueryApplied: PropTypes.bool,
	description: PropTypes.string,
};

export default AudienceTemplatesEmptyState;
