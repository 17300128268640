import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/shared/Modal';

export const ConfirmationModal = React.forwardRef(({ customCloseIcon, children }, ref) => {
	const [show, setShow] = useState();
	const [promise, setPromise] = useState({});

	const onCancel = () => {
		promise.resolve(false);
		setShow(false);
	};

	const onDismiss = () => {
		promise.resolve(false);
		setShow(false);
	};

	const onConfirm = () => {
		promise.resolve(true);
		setShow(false);
	};

	const onShow = async () =>
		new Promise((resolve, reject) => {
			setPromise({
				resolve,
				reject,
			});
			setShow(true);
		});

	ref.current = { onShow, onCancel, onConfirm };

	return (
		<Modal
			show={show}
			width={500}
			padding={0}
			onClose={onDismiss}
			stopMouseDownPropagation
			customCloseIcon={customCloseIcon}
		>
			{children}
		</Modal>
	);
});
ConfirmationModal.displayName = 'ConfirmationModal';
ConfirmationModal.propTypes = {
	children: PropTypes.any,
	customCloseIcon: PropTypes.any,
};

export default ConfirmationModal;
