import last from 'lodash/last';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import orderBy from 'lodash/orderBy';
import differenceBy from 'lodash/differenceBy';
import toastr from 'toastr';
import CONSTANTS from 'src/config/constants';
import studyUtilities from 'src/utilities/study';
import { getMeanScoreScaleOrder, isExcludedMeanScoreOption } from 'src/components/helpers';
import _ from 'lodash';
import * as services from 'src/services';
import * as actions from '../actions';
import * as selectors from '../selectors';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};

// QUESTIONS

const addSectionQuestion = async (store, action) => {
	if (action.type === actions.ADD_SECTION_QUESTION) {
		const { sectionId, type, sortOrder, questionId } = action.payload;
		const state = store.getState();
		const studyId = selectors.getStudy(state).id;
		const studyUuid = selectors.getStudy(state)?.uuid;
		const studyLanguages = selectors.getStudyLanguages(state);
		const currentSection = selectors.getCurrentSection(state).content;
		const { questions } = currentSection;

		const question = {
			type: 'custom',
			style: type,
			label: '',
			filterLabel: '',
			status: 'public',
			sortOrder,
			isFilter: type !== 'open-ended' && currentSection.type !== 'monadic_split',
			randomizeOptions: type === 'emoji',
			translations: studyLanguages.map(lang => ({
				label: '',
				languageCode: lang.languageCode,
			})),
			sectionId,
		};

		await services.questionService
			.create(studyId, question)
			.then(res => {
				// reorder newly created question
				const newQuestionId = res.insertId;
				const destinationIndex = questions.findIndex(q => q.id === questionId) + 1;

				const order = questions.map(q => q.id);
				order.splice(destinationIndex, 0, newQuestionId);

				services.questionService.reorder(studyId, order).then(() => {
					store.dispatch(actions.fetchSections(studyId, true));
					store.dispatch(actions.fetchSection(sectionId, 'edit', true));
					if (currentSection?.type === 'questions' && questions?.length < 2) {
						navigate(
							`/studies/${studyUuid || studyId}/create/sections/${
								currentSection?.uuid || sectionId
							}/questions/${res?.uuid || newQuestionId}`,
						);
					}
					store.dispatch(actions.fetchStudyLoi(studyId));
				});
			})
			.catch(error => {
				store.dispatch(actions.setSection({ status: 'error', error }));
			});
	}
};

const deleteSectionQuestion = async (store, action) => {
	if (action.type === actions.DELETE_SECTION_QUESTION) {
		const { questionId } = action.payload;
		const state = store.getState();
		const studyId = selectors.getStudy(state).id;
		const studyUuid = selectors.getStudy(state)?.uuid;
		const currentSection = selectors.getCurrentSection(state).content;
		const { questions } = currentSection;
		const question = (questions && questions.find(q => q.id === questionId)) || {};
		const precedingQuestions = (questions && questions.filter(q => q.sortOrder < question.sortOrder)) || [];
		const laterQuestions = (questions && questions.filter(q => q.sortOrder > question.sortOrder)) || [];
		const updatedLaterQuestions = laterQuestions.map(q => {
			const newQ = q;
			if (newQ.sortOrder) newQ.sortOrder -= 1;
			if (newQ.studyQuestionNumber) newQ.studyQuestionNumber -= 1;
			return newQ;
		});
		const newQuestions = precedingQuestions.concat(updatedLaterQuestions);
		const newSortedQuestionIds = newQuestions.map(section => section.id);
		store.dispatch(actions.setSectionQuestions(newQuestions));

		// Promise.all works because order of operations does not matter
		Promise.all([
			services.questionService.delete(studyId, questionId),
			services.questionService.reorder(studyId, newSortedQuestionIds),
		])
			.then(() => {
				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
				store.dispatch(actions.fetchSections(studyId, true));
				store.dispatch(actions.fetchStudyLoi(studyId));
				if (currentSection?.type === 'questions' && questions?.length - 1 === 0)
					navigate(
						`/studies/${studyUuid || studyId}/create/sections/${
							currentSection?.uuid || currentSection?.id
						}`,
					);
			})
			.catch(error => {
				// doesn't determine which operation failed
				store.dispatch(actions.setSection({ status: 'error', error }));
			});
	}
};

const getNewQuestionsOrder = (questions, sourceIndex, destinationIndex) => {
	const newQuestions = [];
	if (sourceIndex === destinationIndex) {
		return questions;
	}

	questions.forEach((section, index) => {
		if (index === destinationIndex) {
			if (sourceIndex < destinationIndex) {
				newQuestions.push(section);
			}
			newQuestions.push(questions[sourceIndex]);
			if (sourceIndex > destinationIndex) {
				newQuestions.push(section);
			}
		} else if (index !== sourceIndex) {
			newQuestions.push(section);
		}
	});

	return newQuestions;
};

const reorderQuestions = (store, action) => {
	if (action.type === actions.REORDER_QUESTIONS) {
		const { sourceIndex, destinationIndex } = action.payload;
		const study = selectors.getStudy(store.getState());
		const sections = selectors.getSections(store.getState()).content;
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		let newSortedQuestions;

		// Get the new sorted order
		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			newSortedQuestions = getNewQuestionsOrder(currentAudience.customQuestions, sourceIndex, destinationIndex);
		} else {
			newSortedQuestions = getNewQuestionsOrder(currentSection.questions, sourceIndex, destinationIndex);
		}

		// Re-number the questions based on the full list
		let questionCount = 0;
		sections.find(previousSection => {
			if (currentSection && previousSection.id === currentSection.id) {
				// Stop at this section
				return true;
			}

			if (previousSection.type === 'questions') {
				questionCount += previousSection.questions.length;
			}
			return false;
		});

		newSortedQuestions.forEach(question => {
			questionCount += 1;
			question.studyQuestionNumber = questionCount;
		});

		const newSortedQuestionIds = newSortedQuestions.map(section => section.id);
		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			store.dispatch(actions.setAudienceQuestions(newSortedQuestions));
			services.studySampleService.getAudience(study.id, currentAudience.uuid).then(({ data }) => {
				store.dispatch(
					actions.setAudience({
						content: data,
						loading: false,
					}),
				);
			});
		} else {
			store.dispatch(actions.setSectionQuestions(newSortedQuestions));
		}
		services.questionService
			.reorder(study.id, newSortedQuestionIds)
			.then(() => {
				store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
				store.dispatch(actions.fetchSections(study.id, true));
			})
			.catch(error => {
				// TODO
				console.error("couldn't re-order questions", error);
			});
	}
};

const updateQuestionLabel = (store, action) => {
	if (action.type === actions.UPDATE_QUESTION_LABEL) {
		const { questionId, label, languageCode, refetch, screeningQuestion } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		const currentSectionReference = selectors.getCurrentSection(state).content;
		const sections = selectors?.getSections(state);

		// Updating Screener
		const audienceCollection = selectors.getAudienceCollection(store.getState()).content;

		const [currentSection] =
			sections?.content?.filter(section => section?.questions?.find(question => question?.id === questionId)) ||
			[];
		const questionsSectionedAndSectionless = selectors.getQuestions(state);

		// grab the question under current section
		if (currentSection || (screeningQuestion && audienceCollection)) {
			let questions;

			if (screeningQuestion) {
				questions = audienceCollection.screeningQuestions;
			} else {
				questions = currentSection.questions;
			}

			let patchedQuestion = questions?.find(question => question.id === questionId);

			// If patched question doesn't exist, check other sections, as may be updating question labels in other sections if handling piping errors onDelete or onReorder of sections
			if (!patchedQuestion) {
				let allQuestions = [];
				sections.content.forEach(sect => {
					if (sect.questions && sect.questions.length > 0) {
						allQuestions = allQuestions.concat(sect.questions);
					}
				});
				patchedQuestion =
					allQuestions.find(question => question.id === questionId) ||
					questionsSectionedAndSectionless?.content.find(question => question.id === questionId);
			}

			if (patchedQuestion) {
				// rename the question under current section
				patchedQuestion.label = label;
				const patchedQuestionLanguage = languageCode || study.currentLanguage;

				if (!screeningQuestion) {
					// grab the question under sections (data used to populate questions under logic)
					const patchedSection = sections.content.find(section => section.id === currentSection.id);
					const { questions: sectionsQuestions } = patchedSection;
					const patchedSectionsQuestion = sectionsQuestions?.find(question => question.id === questionId);

					// rename the question under sections
					if (patchedSectionsQuestion) {
						patchedSectionsQuestion.label = label;
					}
				}

				// add new translation item under current section's updated question
				const translations = [];
				patchedQuestion.translations = patchedQuestion.translations.map(translation => {
					if (translation.languageCode === patchedQuestionLanguage) {
						translation.label = label;
					}
					translations.push({
						languageCode: translation.languageCode,
						label: translation.label,
						assetId: translation.assetId,
					});
					return translation;
				});

				study.translations.forEach(studyTranslation => {
					const translationExists = translations.find(
						translation => translation.languageCode === studyTranslation.languageCode,
					);
					if (!translationExists) {
						translations.push({
							languageCode: studyTranslation.languageCode,
							label: studyTranslation.languageCode === patchedQuestionLanguage ? label : '',
						});
					}
				});

				services.questionService
					.patch(study.id, questionId, { label, translations })
					.then(result => {
						store.dispatch(actions.fetchSections(study.id, true));
						if (currentSection) {
							// change questions by reference to avoid re-render
							if (currentSectionReference) {
								currentSectionReference.questions = questions;
							}
							store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
						}
						if (screeningQuestion) {
							store.dispatch(actions.fetchAudienceCollection(study.id, audienceCollection.id, false));
						}
					})
					.catch(error => {
						toastr.error('There was a problem updating the question. Please refresh and try again.');
						console.error("couldn't patch question", error);
					});
			}
		}
	}
};

const patchQuestion = (store, action) => {
	if (action.type === actions.PATCH_QUESTION) {
		const { questionId, data, section } = action.payload;
		const study = selectors.getStudy(store.getState());
		const sections = selectors.getSections(store.getState());
		const audienceCollection = selectors.getAudienceCollection(store.getState()).content;
		const { screeningQuestion } = data;
		let currentSection;
		if (section) {
			currentSection = section;
		} else {
			currentSection = selectors.getCurrentSection(store.getState()).content;
		}

		if (data.options) {
			const { options } = data;
			const nonPlaceholderArray = options.filter(opt => !opt.placeholder);
			const cleanedOptions = studyUtilities.cleanUpOptionsForPatchQuestion(nonPlaceholderArray);
			data.options = cleanedOptions;
		}
		if (data.attributes) {
			const { attributes } = data;
			const nonPlaceholderArray = attributes.filter(attr => !attr.placeholder);
			const cleanedAttributes = [
				...nonPlaceholderArray.map(attr => {
					delete attr.asset;
					delete attr.assetVariations;
					delete attr.isNoneOfTheAbove;
					delete attr.isOtherSpecify;
					attr.translations.forEach(translation => {
						delete translation.asset;
						delete translation.assetVariations;
					});
					const validAttribute = {};
					Object.keys(attr).map(key =>
						['questionId', 'deletedAt'].includes(key) ? null : (validAttribute[key] = attr[key]),
					);
					return validAttribute;
				}),
			];
			data.attributes = cleanedAttributes;
		}

		let shouldFetchLoi;
		if (data.translations?.length) {
			const [translation] = data.translations;
			if ('assetId' in translation) {
				const questionTranslation = sections.content
					.find(({ id }) => id === currentSection.id)
					?.questions?.find(({ id }) => id === questionId)
					?.translations?.find(({ id }) => id === translation?.id);

				shouldFetchLoi = questionTranslation?.assetId !== translation?.assetId;
			}
		}

		if (Object.prototype.hasOwnProperty.call(data, 'screeningQuestion')) delete data.screeningQuestion;

		services.questionService
			.patch(study.id, questionId, data)
			.then(() => {
				if (screeningQuestion) {
					store.dispatch(actions.fetchAudienceCollection(study.id, audienceCollection.id, false));
				} else {
					store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
					store.dispatch(actions.fetchSections(study.id, true));
					store.dispatch(actions.fetchStudy(study.id));
					if (shouldFetchLoi) {
						store.dispatch(actions.fetchStudyLoi(study.id));
					}
				}
			})
			.catch(error => {
				toastr.error('There was a problem updating the question. Please refresh and try again.');
			});
	}
};

const createQuestionSetting = (store, action) => {
	if (action.type === actions.CREATE_QUESTION_SETTING) {
		const { questionId, data } = action.payload;
		const study = selectors.getStudy(store.getState());
		services.questionService
			.createSetting(study.id, questionId, [data])
			.then(response => {
				const currentSection = selectors.getCurrentSection(store.getState()).content;

				store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));

				if (data.label === 'multi-select') {
					store.dispatch(actions.fetchStudyLoi(study.id));
				}
			})
			.catch(error => {
				toastr.error('There was a problem creating the question setting. Please refresh and try again.');
				console.error(error);
			});
	}
};

const patchQuestionSetting = (store, action) => {
	if (action.type === actions.PATCH_QUESTION_SETTING) {
		const { studyId, questionId, settingId, data } = action.payload;
		services.questionService
			.patchSetting(studyId, questionId, settingId, data)
			.then(response => {
				const currentSection = selectors.getCurrentSection(store.getState()).content;

				if (currentSection?.id) {
					store.dispatch(actions.fetchSection(currentSection?.id, 'edit', true));
				}
				if (data.label === 'multi-select') {
					store.dispatch(actions.fetchStudyLoi(studyId));
				}
				store.dispatch(actions.fetchSections(studyId, true, false));
			})
			.catch(error => {
				toastr.error('There was a problem updating the question setting. Please refresh and try again.');
				console.error(error);
			});
	}
};

// OPTIONS
const setOptionScalePointValue = (store, action) => {
	if (action.type !== actions.SET_OPTION_SCALE_POINT_VALUE) return;

	const { questionId, optionId, scalePoint } = action.payload;

	const studyId = selectors.getStudy(store.getState())?.id;

	if (!studyId || !questionId || !optionId) return;

	const state = store.getState();

	const currentSection = selectors.getCurrentSection(store.getState())?.content;
	const currentAudience = selectors.getAudience(state)?.content;
	const audienceCollection = selectors.getAudienceCollection(state)?.content;

	const questions =
		currentAudience && Object.keys(currentAudience).length !== 0
			? currentAudience.customQuestions
			: currentSection.questions;

	// const targetQuestion = (currentSection?.questions ?? audienceCollection?.screeningQuestions)?.find(
	// 	({ id }) => id === questionId,
	// );

	const targetQuestion = questions?.find(({ id }) => id === questionId);

	const targetOption = targetQuestion?.options?.find(({ id }) => id === optionId);

	if (!targetOption) return;

	targetOption.scalePoint = scalePoint;

	// * Update state with the updated scale point value
	if (currentAudience && Object.keys(currentAudience).length !== 0) {
		store.dispatch(actions.setAudienceQuestions(questions ?? []));
	} else {
		store.dispatch(actions.setSectionQuestions(questions ?? []));
	}
	// currentSection && store.dispatch(actions.setSectionQuestions(currentSection?.questions ?? []));
	// audienceCollection && store.dispatch(actions.setAudienceQuestions(audienceCollection?.screeningQuestions ?? []));
};

const updateOptionScalePoint = async (store, action) => {
	if (action.type !== actions.UPDATE_OPTION_SCALE_POINT) return;

	const { questionId, optionId, scalePoint } = action.payload;

	const studyId = selectors.getStudy(store.getState())?.id;

	if (!studyId || !questionId || !optionId) return;

	const optionDataToUpdate = { scalePoint };

	try {
		const result = await services.questionService.patchOption(studyId, questionId, optionId, optionDataToUpdate);

		const updatedQuestion = result.data ?? {};
		const updatedOptions = result.data?.options ?? [];

		const state = store.getState();
		const currentSection = selectors.getCurrentSection(state)?.content;
		const currentAudience = selectors.getAudience(state)?.content;
		const audienceCollection = selectors.getAudienceCollection(state)?.content;

		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		const currentQuestion = questions?.find(q => q.id === questionId) ?? updatedQuestion;
		// const currentAudienceQuestion =
		// 	audienceCollection?.screeningQuestions?.find(q => q.id === questionId) ?? updatedQuestion;

		// * After editing the option, we need to look and see if there were any placeholders options that need to be restored
		const placeholderOption = currentQuestion?.options?.find(({ placeholder = false }) => placeholder);
		// const audiencePlaceholderOption = currentAudienceQuestion?.options?.find(
		// 	({ placeholder = false }) => placeholder,
		// );

		// * Check current options against latest options to see if a placeholder is needed
		if (placeholderOption) {
			// * Add placeholder back into options
			updatedOptions.push(placeholderOption);
		}

		// if (audiencePlaceholderOption) {
		// 	// * Add placeholder back into options
		// 	updatedOptions.push(audiencePlaceholderOption);
		// }

		const orderedUpdatedOptions = _.orderBy(updatedOptions, 'order', 'asc');

		if (currentQuestion) {
			currentQuestion.options = orderedUpdatedOptions;
		}

		// if (currentAudienceQuestion) {
		// 	currentAudienceQuestion.options = orderedUpdatedOptions;
		// }

		// * Update state with the updated scale point value
		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			store.dispatch(actions.setAudienceQuestions(questions ?? []));
			store.dispatch(actions.fetchAudienceCollection(studyId, audienceCollection.id, false));
		} else {
			store.dispatch(actions.setSectionQuestions(questions ?? []));

			// ? Otherwise current section questions are not updated
			store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
			store.dispatch(actions.fetchSections(studyId, true));
		}

		// if (currentSection) {
		// 	store.dispatch(actions.setSectionQuestions(currentSection?.questions ?? []));

		// 	// ? Otherwise current section questions are not updated
		// 	store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
		// 	store.dispatch(actions.fetchSections(studyId, true));
		// }

		// if (currentAudienceQuestion) {
		// 	store.dispatch(actions.setAudienceQuestions(audienceCollection?.screeningQuestions ?? []));
		// 	store.dispatch(actions.fetchAudienceCollection(studyId, audienceCollection.id, false));
		// }
	} catch (error) {
		toastr.error("There was a problem updating the option's scale point. Please refresh and try again.");
	}
};

const addOptionPlaceholder = (store, action) => {
	if (action.type === actions.ADD_OPTION_PLACEHOLDER) {
		const { questionId } = action.payload;

		const study = selectors.getStudy(store.getState());

		const currentSection = selectors.getCurrentSection(store.getState())?.content;
		if (currentSection) {
			const { questions } = currentSection;

			const patchedQuestion = questions.find(question => question.id === questionId);

			const highOrder = patchedQuestion.options
				.filter(option => !option?.isNoneOfTheAbove && !option?.isOtherSpecify)
				.map(option => option.order)
				.reduce((a, b) => Math.max(a, b), 0);

			const canAddPlaceholder = !patchedQuestion.options.find(
				option => option.placeholder && !option?.translations.find(translation => translation?.label?.length),
			);

			if (canAddPlaceholder) {
				const { isAsc, isDesc } = getMeanScoreScaleOrder(patchedQuestion?.options);

				// * excludedOptions don't have scale point by default so we have to exclude them to get `maxScalePoint`
				const excludedOptions = patchedQuestion?.options?.filter(option => isExcludedMeanScoreOption(option));
				let includedOptions = patchedQuestion?.options?.filter(option => !isExcludedMeanScoreOption(option));

				// * Max scale point including newly added option
				const maxScalePoint = (includedOptions?.length ?? 0) + 1;

				let scalePoint = null;

				if (isAsc) {
					scalePoint = maxScalePoint;
				}

				if (isDesc) {
					scalePoint = 1;

					includedOptions = _.orderBy(includedOptions, 'order', 'asc').map((option, index) => ({
						...option,
						scalePoint: isExcludedMeanScoreOption(option) ? null : maxScalePoint - index,
					}));
				}

				const placeholderOption = {
					value: '',
					placeholder: true,
					id: `placeholder-${patchedQuestion.options.length}`,
					order: highOrder ? highOrder + 1 : 1,
					[CONSTANTS.questions.options.isOtherSpecify]: false,
					[CONSTANTS.questions.options.isNoneOfTheAbove]: false,
					[CONSTANTS.questions.options.lockOrder]: false,
					scalePoint,
					translations: [
						{
							languageCode: study.language || study.currentLanguage,
							label: '',
						},
					],
				};

				patchedQuestion.options = [
					...includedOptions,
					placeholderOption, // * Excluded from mean score options' position is fixed at the end of the options array
					...excludedOptions,
				];

				store.dispatch(actions.setSectionQuestions(questions));
			}
		}
	}
};

const removeOptionPlaceholder = (store, action) => {
	if (action.type === actions.REMOVE_OPTION_PLACEHOLDER) {
		const { questionId, answerId } = action.payload;
		const state = store.getState();
		const sections = selectors.getSections(state);
		const currentSection = selectors.getCurrentSection(state)?.content ?? {};
		const currentAudience = selectors.getAudience(state)?.content ?? {};

		// * On adding placeholder the scale points are modified locally so we need to revert their values in case we don't submit adding new option
		const defaultScalePoints = _.fromPairs(
			sections?.content
				?.find(({ id }) => id === currentSection?.id)
				?.questions?.find(({ id }) => id === questionId)
				?.options?.map(({ id, scalePoint }) => [id, scalePoint]),
		);

		// const questions = currentAudience?.customQuestions ?? currentSection?.questions ?? [];

		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		const patchedQuestion = questions.find(question => question.id === questionId);

		patchedQuestion.options = patchedQuestion.options
			.filter(option => option.id !== answerId)
			?.map(option => ({ ...option, scalePoint: defaultScalePoints?.[option.id] ?? null }));

		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			store.dispatch(actions.setAudienceQuestions(questions));
		} else {
			store.dispatch(actions.setSectionQuestions(questions));
		}

		// if (Object.prototype.hasOwnProperty.call(currentAudience, 'customQuestions')) {
		// 	store.dispatch(actions.setAudienceQuestions(questions));
		// }

		// if (Object.prototype.hasOwnProperty.call(currentSection, 'questions')) {
		// 	store.dispatch(actions.setSectionQuestions(questions));
		// }
	}
};

const addQuestionOption = (store, action) => {
	if (action.type === actions.ADD_QUESTION_OPTION) {
		const { questionId, option } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		const currentSection = selectors.getCurrentSection(state).content;
		const currentAudience = selectors.getAudience(state).content;

		// const questions = currentAudience?.customQuestions ?? currentSection?.questions ?? [];

		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		const currentQuestion = questions?.find(q => q.id === questionId);

		services.questionService
			.createOption(study.id, questionId, option)
			.then(results => {
				let updatedAnswers = results.data.options;

				const brandNewOption = results.data?.options?.find(({ order }) => order === option.order);

				if (typeof brandNewOption?.scalePoint !== 'number') {
					brandNewOption.scalePoint =
						currentQuestion?.options?.find(existingOption => existingOption.order === brandNewOption.order)
							?.scalePoint ?? null;
				}

				const updatedOptions =
					currentQuestion?.options?.map(({ isDisplayedInReporting, ...existingOption }) =>
						existingOption.order === option.order
							? {
									...brandNewOption,
									scalePoint: existingOption?.scalePoint ?? brandNewOption?.scalePoint ?? null,
							  }
							: existingOption,
					) ?? [];

				currentQuestion.options = updatedOptions;

				const { isDesc } = getMeanScoreScaleOrder(updatedOptions);

				if (currentQuestion.options.length > updatedAnswers.length) {
					// Add placeholder back into options
					const placeholderOptions = differenceBy(currentQuestion.options, updatedAnswers, 'order');
					updatedAnswers = _.orderBy([...updatedAnswers, ...placeholderOptions], 'order');
				}

				currentQuestion.options = updatedAnswers;

				if (isDesc && !isExcludedMeanScoreOption(option) && option?.meanScore !== 1) {
					// * Update scale points on extending a descending auto scale
					store.dispatch(actions.patchQuestion(questionId, { options: updatedOptions }));
				}
			})
			.then(() => {
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					store.dispatch(actions.setAudienceQuestions(questions));
				}

				if (currentSection) {
					store.dispatch(actions.setSectionQuestions(questions));
					store.dispatch(actions.fetchSections(study.id, true));
				}
			})
			.catch(error => {
				toastr.error('There was a problem creating the option. Please refresh and try again.');
			});
	}
};

const removeQuestionOption = (store, action) => {
	if (action.type === actions.REMOVE_QUESTION_OPTION) {
		const { questionId, optionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;

		// const questions = currentAudience?.customQuestions ?? currentSection?.questions ?? [];

		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		let currentQuestion = questions?.find(({ id }) => id === questionId);

		let deletingFilter = false;
		// Deleting a filter instead of a question section
		if (currentQuestion === undefined) {
			currentQuestion = study?.questionFilters && study?.questionFilters?.find(f => f.id === questionId);
			deletingFilter = currentQuestion && true;
		}
		// Assign the latest answers with current Ids
		const updatedAnswers = currentQuestion.options.filter(option => option.id !== optionId);

		// Update current question and set store state
		currentQuestion.options = updatedAnswers;
		if (currentAudience && Object.keys(currentAudience).length !== 0 && currentAudience?.customQuestions) {
			store.dispatch(actions.setAudienceQuestions(questions));

			currentAudience.uuid &&
				services.studySampleService.getAudience(study.id, currentAudience.uuid).then(({ data }) => {
					store.dispatch(
						actions.setAudience({
							content: data,
							loading: false,
						}),
					);
				});
		} else if (deletingFilter && updatedAnswers) {
			store.dispatch(actions.setStudy({ ...study }));
			store.dispatch(actions.fetchQuestions(study.id));
		}

		if (currentSection) {
			store.dispatch(actions.setSectionQuestions(questions));
			// store.dispatch(actions.fetchSections(study.id, true, false));
		}

		// remove option on database
		if (!optionId.toString()?.includes('placeholder')) {
			services.questionService.deleteOption(study.id, questionId, optionId).catch(error => {
				toastr.error('There was a problem deleting the option. Please refresh and try again.');
			});
		}

		// store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
	}
};

const updateOptionLabel = (store, action) => {
	if (action.type === actions.UPDATE_OPTION_LABEL) {
		const { questionId, translationId, optionId, languageCode, filterImports, screeningQuestion } = action.payload;
		let { label } = action.payload;
		if (label && typeof label !== 'string') label = JSON.stringify(label);
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questionState = selectors.getQuestions(store.getState()).content;
		const audienceCollection = selectors.getAudienceCollection(store.getState()).content;

		// let questions = currentAudience?.customQuestions ?? currentSection?.questions ?? [];

		let questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		if (filterImports && !questions) {
			questions = questionState;
		}

		if (screeningQuestion) {
			questions = audienceCollection.screeningQuestions;
		}

		const study = selectors.getStudy(store.getState());
		// let updatingFilter = false;
		let optionData = { value: label };

		const question = questions.find(q => q.id === questionId) || questionState.find(q => q.id === questionId);
		const questionOption = question?.options?.find(option => option.id === optionId);
		const optionTranslation = questionOption?.translations?.find(translation => translation.id === translationId);
		if (questionOption) {
			if (optionTranslation) {
				if (optionTranslation.asset) delete optionTranslation.asset;
				if (optionTranslation.assetVariations) delete optionTranslation.assetVariations;
				optionTranslation.label = label;
				optionData.translations = questionOption.translations;
			} else {
				// Create New Translation with label and languagae code and add to current translation object
				optionData = { ...optionData, translations: [...questionOption.translations] };
				optionData.translations.push({
					languageCode,
					label,
				});
			}
			if (optionData.label || optionData.label === '') delete optionData.label;
			if (optionData.asset || optionData.asset === '') delete optionData.asset;
			if (optionData.assetVariations) delete optionData.assetVariations;
			if (optionData.maskedOptions) delete optionData.maskedOptions;
			if (optionData.translations) {
				optionData.translations.forEach(translation => {
					delete translation.asset;
					delete translation.assetVariations;
				});
			}

			services.questionService
				.patchOption(study.id, questionId, optionId, optionData)
				.then(res => {
					if (currentAudience && Object.keys(currentAudience).length !== 0) {
						currentAudience.uuid &&
							services.studySampleService.getAudience(study.id, currentAudience.uuid).then(() => {
								store.dispatch(actions.setAudienceQuestions(questions));

								store.dispatch(
									actions.setAudience({
										content: res.data,
										loading: false,
									}),
								);
							});
					} else if (!filterImports && !screeningQuestion) {
						// After editing the option, we need to look and see if there were any placeholders options that need to be restored
						services.sections.get(study.id, currentSection.id, 'edit').then(() => {
							const newSection = selectors.getCurrentSection(store.getState()).content;

							if (currentSection?.id === newSection?.id) {
								store.dispatch(actions.setSectionQuestions(questions));
							}

							store.dispatch(actions.fetchSections(study.id, true));
						});
					} else if (screeningQuestion) {
						store.dispatch(actions.fetchAudienceCollection(study.id, audienceCollection.id, false));
					} else store.dispatch(actions.fetchQuestions(study.id));
				})
				.catch(error => {
					console.log(error);
					toastr.error('There was a problem updating the option. Please refresh and try again.');
				});
		}
	}
};

const reorderOptions = (store, action) => {
	if (action.type === actions.REORDER_OPTIONS) {
		const moveInArray = (arr, from, to) => {
			const copy = [...arr];
			// Delete the item from it's current position
			const [item] = copy.splice(from, 1);
			// Move the item to its new position
			copy.splice(to, 0, item);
			return copy;
		};
		const { questionId, sourceIndex, destinationIndex } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;
		const patchedQuestion = questions?.find(question => question.id === Number(questionId));
		const { options } = window.structuredClone(patchedQuestion);
		// uncomment the 2nd check to disable others/none to stay on the bottom
		const optionsWithoutPlaceholders = options.filter(opt => !opt?.placeholder /* && opt.order <= 99 */);
		// Order options based on order attribute
		optionsWithoutPlaceholders.sort((a, b) => (a.order > b.order ? 1 : -1));

		// Move option to new position in array
		const indexOrder = moveInArray(
			optionsWithoutPlaceholders.map((e, i) => i),
			sourceIndex,
			destinationIndex,
		);
		// optionsWithoutPlaceholders = optionsWithoutPlaceholders.filter(option => !!option);

		// Set new order attributes based on new array
		optionsWithoutPlaceholders.forEach((option, key) => {
			// reorder only common options, avoid others/none
			if (option.order <= 99) {
				option.order = indexOrder.findIndex(i => i === key);
			}
			delete option.assetVariations;
			if (option.translations) {
				option.translations.forEach(translation => {
					delete translation.assetVariations;
				});
			}
			if (option.maskedOptions) delete option.maskedOptions;
		});

		// Set questions with new options
		const newQuestions = [
			...questions.map(q =>
				q.id !== patchedQuestion.id
					? q
					: { ...patchedQuestion, options: options.sort((a, b) => (a.order > b.order ? 1 : -1)) },
			),
		];

		store.dispatch(actions.setSectionQuestions(newQuestions, questionId)); // reducer only

		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			services.studySampleService.getAudience(study.id, currentAudience.uuid).then(res => {
				store.dispatch(actions.setAudienceQuestions(newQuestions));
				store.dispatch(
					actions.setAudience({
						content: res.data,
						loading: false,
					}),
				);
			});
		} else {
			store.dispatch(actions.updateSectionQuestion(patchedQuestion.id, patchedQuestion));
		}

		services.questionService
			.patch(study.id, questionId, {
				options: optionsWithoutPlaceholders.map(option => {
					const newOption = { ...option };
					delete newOption.isDisplayedInReporting;
					delete newOption.asset;
					if (newOption.translations) {
						newOption.translations.forEach(translation => {
							delete translation.assetVariations;
						});
					}
					return newOption;
				}),
			})
			.then(() => {
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(res => {
						store.dispatch(actions.setAudienceQuestions(newQuestions));
						store.dispatch(
							actions.setAudience({
								content: res.data,
								loading: false,
							}),
						);
					});
				}
			})
			.catch(error => {
				toastr.error('There was a problem re-ordering the options. Please refresh and try again.');
			});
	}
};

const reorderOptionsWithoutSections = (store, action) => {
	if (action.type === actions.REORDER_OPTIONS_WITHOUT_SECTIONS) {
		const { studyId, questionId, options } = action.payload;
		services.questionService
			.patch(studyId, questionId, {
				options: options.map(option => {
					const newOption = { ...option };
					delete newOption.isDisplayedInReporting;
					delete newOption.asset;
					delete newOption.maskedOptions;
					delete newOption.assetVariations;
					if (newOption.translations) {
						newOption.translations.forEach(translation => {
							delete translation.assetVariations;
						});
					}
					return newOption;
				}),
			})
			.catch(error => {
				toastr.error('There was a problem re-ordering the options. Please refresh and try again.');
			});
	}
};

const reorderAttributesWithoutSections = (store, action) => {
	if (action.type === actions.REORDER_ATTRIBUTES_WITHOUT_SECTIONS) {
		const { studyId, questionId, attributes } = action.payload;

		services.questionService
			.patch(studyId, questionId, {
				attributes: attributes.map(attribute => {
					const newAttribute = { ...attribute };
					delete newAttribute.assetVariations;
					delete newAttribute.parentId;
					delete newAttribute.asset;
					if (newAttribute.translations) {
						newAttribute.translations.forEach(translation => {
							delete translation.assetVariations;
							delete translation.asset;
						});
					}
					return newAttribute;
				}),
			})
			.catch(error => {
				toastr.error('There was a problem re-ordering the options. Please refresh and try again.');
			});
	}
};

const patchQuestionOption = async (store, action) => {
	if (action.type === actions.PATCH_QUESTION_OPTION) {
		const { studyId, questionId, optionId, data } = action.payload;
		const state = store.getState();
		const currentSection = state.study.currentSection.content || selectors.getCurrentSection(state).content;
		if (data.translations && data.translations.length > 0) {
			data.translations.forEach(translation => {
				delete translation?.assetVariations;
				delete translation?.asset;
			});
		}

		const audienceTemplateModal = state.manageStudy.audienceEditTemplate.content;
		services.questionService.patchOption(studyId, questionId, optionId, data).then(response => {
			if (currentSection) {
				const { id: sectionId } = currentSection;
				store.dispatch(actions.fetchSection(sectionId, 'edit', true));
				store.dispatch(actions.fetchSections(studyId, true));
			}
			if (data.assetId && response?.data?.type === 'qualifier' && response?.data?.audienceCollectionId) {
				services.audienceService.getCollection(studyId, response?.data?.audienceCollectionId).then(res => {
					if (audienceTemplateModal) {
						store.dispatch(actions.setAudienceEditTemplate({ loading: false, content: res?.data || null }));
					} else {
						store.dispatch(actions.setAudienceCollection({ loading: false, content: res?.data || null }));
					}
				});
			}
		});
	}
};

// CUSTOM QUALIFIERS

const addAudienceQuestion = async (store, action) => {
	if (action.type === actions.ADD_AUDIENCE_QUESTION) {
		const { audienceUuid, type, sortOrder, questionId } = action.payload;
		const state = store.getState();
		const studyId = selectors.getStudy(state).id;
		const studyLanguages = selectors.getStudyLanguages(state);
		const currentAudience = selectors.getAudience(state).content;
		const questions = currentAudience.customQuestions;

		const question = {
			type: 'custom',
			style: type,
			label: '',
			filterLabel: '',
			status: 'public',
			sortOrder,
			isFilter: type !== 'open-ended',
			randomizeOptions: type === 'emoji',
			translations: studyLanguages.map(lang => ({
				label: '',
				languageCode: lang.languageCode,
			})),
			audienceUuid,
		};

		await services.questionService
			.create(studyId, question)
			.then(res => {
				// reorder newly created question
				const newQuestionId = res.insertId;
				const destinationIndex = questions.findIndex(q => q.id === questionId) + 1;

				const order = questions.map(q => q.id);
				order.splice(destinationIndex, 0, newQuestionId);

				services.questionService.reorder(studyId, order).then(() => {
					store.dispatch(actions.fetchAudiences(studyId));
					services.studySampleService.getAudience(studyId, audienceUuid).then(({ data }) => {
						store.dispatch(
							actions.setAudience({
								content: data,
								loading: false,
							}),
						);
					});
				});
			})
			.catch(error => {
				store.dispatch(actions.setAudience({ status: 'error', error }));
			});
	}
};

const deleteCustomQualifier = async (store, action) => {
	if (action.type === actions.DELETE_CUSTOM_QUALIFIER) {
		const state = store.getState();
		const studyId = selectors.getStudy(state).id;
		const { questionId } = action.payload;
		const currentAudience = action.payload.audience;
		const questions = currentAudience.customQuestions;

		const remainingQuestions = questions
			.filter(q => q.id !== questionId)
			.map((question, index) => {
				question.sortOrder = index;
				question.studyQuestionNumber = index + 1;
				return question;
			});

		store.dispatch(actions.setAudienceQuestions(remainingQuestions));

		await services.questionService
			.delete(studyId, questionId)
			.then(() => {
				services.studySampleService.getAudience(studyId, currentAudience.uuid).then(({ data }) => {
					store.dispatch(
						actions.setAudience({
							content: data,
							loading: false,
						}),
					);
				});
			})
			.catch(error => {
				store.dispatch(actions.setAudience({ status: 'error', error }));
			});
	}
};

const updateCustomQualifierLabel = (store, action) => {
	if (action.type === actions.UPDATE_CUSTOM_QUALIFIER_LABEL) {
		const { questionId, label, languageCode, audience } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		const questions = audience.screeningQuestions;

		const patchedQuestion = questions.find(question => question.id === questionId);
		patchedQuestion.label = label;

		// add new translation item under current section's updated question
		const translations = [];
		patchedQuestion.translations = patchedQuestion.translations.map(translation => {
			if (translation.languageCode === languageCode) {
				translation.label = label;
			}
			translations.push({
				languageCode: translation.languageCode,
				label: translation.label,
				assetId: translation.assetId,
			});
			return translation;
		});

		services.questionService
			.patch(study.id, questionId, { label, translations })
			.then(result => {
				store.dispatch(actions.fetchAudiences(study.id));
			})
			.catch(error => {
				toastr.error('There was a problem updating the question. Please refresh and try again.');
				console.error("couldn't patch question", error);
			});
	}
};

const addCustomQualifierOptionPlaceholder = (store, action) => {
	if (action.type === actions.ADD_CUSTOM_QUALIFIER_OPTION_PLACEHOLDER) {
		const { questionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions = currentAudience.customQuestions;
		const patchedQuestion = questions.find(question => question.id === questionId);
		if (patchedQuestion) {
			patchedQuestion.options.push({
				value: '',
				placeholder: true,
				id: `placeholder-${patchedQuestion.options.length}`,
				order:
					last(patchedQuestion.options) &&
					!last(patchedQuestion.options).isNoneOfTheAbove &&
					!last(patchedQuestion.options).isOtherSpecify
						? last(orderBy(patchedQuestion.options, 'order')).order + 1
						: patchedQuestion.options.length,
				[CONSTANTS.questions.options.isOtherSpecify]: false,
				[CONSTANTS.questions.options.isNoneOfTheAbove]: false,
				[CONSTANTS.questions.options.lockOrder]: false,
				translations: [
					{
						languageCode: study.language || study.currentLanguage,
						label: '',
					},
				],
			});
		}

		store.dispatch(actions.setAudienceQuestions(questions));
	}
};

const addCustomQualifierAttributePlaceholder = (store, action) => {
	if (action.type === actions.ADD_CUSTOM_QUALIFIER_ATTRIBUTE_PLACEHOLDER) {
		const { questionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions = currentAudience.customQuestions;
		const patchedQuestion = questions.find(question => question.id === questionId);

		if (patchedQuestion) {
			patchedQuestion.attributes.push({
				value: '',
				placeholder: true,
				id: `placeholder-${patchedQuestion.attributes.length}`,
				order:
					last(patchedQuestion.attributes) &&
					!last(patchedQuestion.attributes).isNoneOfTheAbove &&
					!last(patchedQuestion.attributes).isOtherSpecify
						? last(orderBy(patchedQuestion.attributes, 'order')).order + 1
						: patchedQuestion.attributes.length,
				[CONSTANTS.questions.attributes.lockOrder]: false,
				translations: [
					{
						languageCode: study.language || study.currentLanguage,
						label: '',
					},
				],
			});
		}

		store.dispatch(actions.setAudienceQuestions(questions));
	}
};

// ATTRIBUTES

const addAttributePlaceholder = (store, action) => {
	if (action.type === actions.ADD_ATTRIBUTE_PLACEHOLDER) {
		const { questionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const { questions } = currentSection;
		const patchedQuestion = questions.find(question => question.id === questionId);

		if (!patchedQuestion.attributes) {
			patchedQuestion.attributes = [];
		}

		const canAddPlaceholder = !patchedQuestion.attributes.find(
			attribute =>
				attribute?.placeholder && !attribute?.translations.find(translation => translation?.label?.length),
		);

		if (canAddPlaceholder) {
			patchedQuestion.attributes.push({
				placeholder: true,
				id: `placeholder-${patchedQuestion.attributes.length}`,
				order: last(patchedQuestion.attributes)
					? last(orderBy(patchedQuestion.attributes, 'order')).order + 1
					: patchedQuestion.attributes.length,
				[CONSTANTS.questions.attributes.lockOrder]: false,
				translations: [
					{
						languageCode: study.language || study.currentLanguage,
						label: '',
					},
				],
			});

			store.dispatch(actions.setSectionQuestions(questions));
		}
	}
};

const removeAttributePlaceholder = (store, action) => {
	if (action.type === actions.REMOVE_ATTRIBUTE_PLACEHOLDER) {
		const { questionId, attributeId } = action.payload;
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		let questions = [];

		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			questions = currentAudience.customQuestions;
		} else {
			questions = currentSection.questions;
		}

		const patchedQuestion = questions.find(question => question.id === questionId);

		patchedQuestion.attributes = patchedQuestion.attributes.filter(attribute => attribute.id !== attributeId);

		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			store.dispatch(actions.setAudienceQuestions(questions));
		} else {
			store.dispatch(actions.setSectionQuestions(questions));
		}
	}
};

const addQuestionAttribute = (store, action) => {
	if (action.type === actions.ADD_QUESTION_ATTRIBUTE) {
		const { questionId, attribute } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		services.questionService
			.createAttribute(study.id, questionId, attribute)
			.then(results => {
				// After creating the new attribute, we need to look and see if there were any placeholders attributes that need to be restored
				const currentQuestion = questions.find(q => q.id === questionId);

				// Last attribute is placeholder
				const lastAttribute = last(currentQuestion.attributes);

				// Assign the latest answers with current Ids
				let updatedAnswers = results.data.attributes;

				// Check current attributes against latest attributes to see if a placeholder is needed
				if (currentQuestion.attributes.length > updatedAnswers.length) {
					// Add placeholder back into attributes
					lastAttribute.order = last(updatedAnswers).order + 1;
					updatedAnswers = updatedAnswers.concat(lastAttribute);
				}

				// Update current question and set store state
				currentQuestion.attributes = updatedAnswers;

				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					store.dispatch(actions.setAudienceQuestions(questions));
				} else {
					const newSection = selectors.getCurrentSection(store.getState()).content;
					if (newSection?.id === currentSection?.id) {
						store.dispatch(actions.setSectionQuestions(questions));
					}
				}
			})
			.catch(error => {
				toastr.error('There was a problem creating the attribute. Please refresh and try again.');
			});
	}
};

const removeQuestionAttribute = (store, action) => {
	if (action.type === actions.REMOVE_QUESTION_ATTRIBUTE) {
		const { questionId, attributeId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		const currentQuestion = questions.find(q => q.id === questionId);
		// Assign the latest answers with current Ids
		const updatedAnswers = currentQuestion.attributes.filter(attribute => attribute.id !== attributeId);

		// Update current question and set store state
		currentQuestion.attributes = updatedAnswers;
		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			store.dispatch(actions.setAudienceQuestions(questions));

			services.studySampleService.getAudience(study.id, currentAudience.uuid).then(({ data }) => {
				store.dispatch(
					actions.setAudience({
						content: data,
						loading: false,
					}),
				);
			});
		} else {
			store.dispatch(actions.setSectionQuestions(questions));
		}

		if (!attributeId.toString()?.includes('placeholder')) {
			services.questionService.deleteAttribute(study.id, questionId, attributeId).catch(error => {
				toastr.error('There was a problem deleting the attribute. Please refresh and try again.');
			});
		}
	}
};

const updateAttributeLabel = (store, action) => {
	if (action.type === actions.UPDATE_ATTRIBUTE_LABEL) {
		const { questionId, translationId, attributeId, label, languageCode, screeningQuestion } = action.payload;
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const audienceCollection = selectors.getAudienceCollection(store.getState()).content;
		let questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		if (screeningQuestion) {
			questions = audienceCollection.screeningQuestions;
		}

		const study = selectors.getStudy(store.getState());
		let attributeData = { label };
		const question = questions.find(q => q.id === questionId);
		const questionAttribute = question?.attributes?.find(attribute => attribute.id === attributeId);
		const attributeTranslation = questionAttribute?.translations?.find(
			translation => translation.id === translationId,
		);
		if (questionAttribute) {
			if (attributeTranslation) {
				if (!attributeTranslation.assetId) delete attributeTranslation.assetId;
				delete attributeTranslation.asset;
				delete attributeTranslation.assetVariations;
				delete attributeTranslation.id;
				attributeTranslation.label = label;
				attributeTranslation.studyTranslationId = translationId;
				attributeData = attributeTranslation;
			} else {
				// Create New Translation with label and language code and add to current translation object
				attributeData = { ...questionAttribute };
				// delete attributeData.id;
				attributeData = {
					languageCode,
					label,
				};
			}

			const serviceRoute = attributeTranslation?.studyTranslationId
				? services.questionService.patchAttributeTranslation(
						study.id,
						questionId,
						attributeId,
						attributeTranslation?.studyTranslationId,
						attributeData,
				  )
				: services.questionService.postAttributeTranslation(study.id, questionId, attributeId, [attributeData]);

			serviceRoute
				.then(() => {
					if (currentAudience && Object.keys(currentAudience).length !== 0) {
						services.studySampleService.getAudience(study.id, currentAudience.uuid).then(res => {
							const currentQuestion = questions.find(q => q.id === questionId);

							// Last attribute is placeholder
							const lastAttribute = last(currentQuestion.attributes);

							// Assign the latest answers with current Ids
							let updatedAnswers = res.data.customQuestions.find(q => q.id === questionId).attributes;

							// Check current attributes against latest attributes to see if a placeholder is needed
							if (currentQuestion.attributes.length > updatedAnswers.length) {
								// Add placeholder back into attributes
								updatedAnswers = updatedAnswers.concat(lastAttribute);
							}

							// Update current question and set store state
							currentQuestion.attributes = updatedAnswers;
							store.dispatch(actions.setAudienceQuestions(questions));

							store.dispatch(
								actions.setAudience({
									content: res.data,
									loading: false,
								}),
							);
						});
					} else if (!screeningQuestion) {
						// After editing the attribute, we need to look and see if there were any placeholders attributes that need to be restored
						services.sections.get(study.id, currentSection.id, 'edit').then(res => {
							const currentQuestion = questions.find(q => q.id === questionId);

							// Last attribute is placeholder
							const lastAttribute = last(currentQuestion.attributes);

							// Assign the latest answers with current Ids
							let updatedAnswers =
								res.data.questions &&
								res.data.questions.find(q => q.id === questionId) &&
								res.data.questions.find(q => q.id === questionId).attributes;

							// Check current attributes against latest attributes to see if a placeholder is needed
							if (currentQuestion.attributes.length > updatedAnswers.length) {
								// Add placeholder back into attributes
								updatedAnswers = updatedAnswers.concat(lastAttribute);
							}

							// Update current question and set store state
							currentQuestion.attributes = updatedAnswers;
							store.dispatch(actions.setSectionQuestions(questions));
						});
					} else {
						// Updating Screener question, so we need to set audience collection and screenering questions
						store.dispatch(actions.fetchAudienceCollection(study.id, audienceCollection.id, false));
					}
				})
				.catch(error => {
					toastr.error('There was a problem updating the attribute. Please refresh and try again.');
				});
		}
	}
};

const reorderAttributes = (store, action) => {
	if (action.type === actions.REORDER_ATTRIBUTES) {
		const moveInArray = function (arr, from, to) {
			// Delete the item from it's current position
			const item = arr.splice(from, 1);
			// Move the item to its new position
			arr.splice(to, 0, item[0]);
		};

		const { questionId, attributeId, sourceIndex, destinationIndex } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;
		const patchedQuestion = questions.find(question => question.id === Number(questionId));
		const attributes = patchedQuestion && patchedQuestion.attributes;

		// uncomment the 2nd check to disable others/none to stay on the bottom
		let attributesWithoutPlaceholders = attributes.filter(
			attribute => !attribute.placeholder /* && attribute.order < 99 */,
		);

		// Order attributes based on order attribute
		attributesWithoutPlaceholders.sort((a, b) => (a.order > b.order ? 1 : -1));

		// Move attribute to new position in array
		moveInArray(attributesWithoutPlaceholders, sourceIndex, destinationIndex);

		attributesWithoutPlaceholders = attributesWithoutPlaceholders.filter(attribute => !!attribute);

		// Set new order attributes based on new array
		attributesWithoutPlaceholders.forEach((attribute, key) => {
			attribute.order = key;
			delete attribute.assetVariations;
			if (attribute.translations) {
				attribute.translations.forEach(translation => {
					delete translation.assetVariations;
				});
			}
		});

		// Do not send in isDisplayedInReporting or asset property for question attributes
		questions.forEach(question => {
			if (question.attributes) {
				question.attributes.forEach(attribute => {
					delete attribute.isDisplayedInReporting;
					delete attribute.asset;
					if (attribute.translations) {
						attribute.translations.forEach(translation => {
							delete translation.asset;
							delete translation.assetVariations;
						});
					}
				});
			}
		});

		// Set questions with new attributes
		const newQuestions = [
			...questions.map(q => (q.id !== patchedQuestion.id ? q : { ...patchedQuestion, attributes })),
		];

		services.questionService
			.patch(study.id, questionId, {
				attributes: attributesWithoutPlaceholders,
			})
			.then(() => {
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(res => {
						store.dispatch(actions.setAudienceQuestions(newQuestions));
						store.dispatch(
							actions.setAudience({
								content: res.data,
								loading: false,
							}),
						);
					});
				} else {
					store.dispatch(actions.setSectionQuestions(newQuestions, questionId));
					if (currentSection) {
						store.dispatch(actions.fetchSection(currentSection.id, 'edit', true, 0, questionId));
					}
				}
			})
			.catch(error => {
				toastr.error('There was a problem re-ordering the attributes. Please refresh and try again.');
			});
	}
};

const patchQuestionAttribute = (store, action) => {
	if (action.type === actions.PATCH_QUESTION_ATTRIBUTE) {
		const { studyId, questionId, attributeId, data } = action.payload;
		const state = store.getState();
		const currentSection = state.study.currentSection.content || selectors.getCurrentSection(state).content;

		if (data.translations && data.translations.length > 0) {
			data.translations.forEach(translation => {
				delete translation?.assetVariations;
				delete translation?.asset;
			});
		}
		services.questionService.patchAttribute(studyId, questionId, attributeId, data).then(response => {
			if (currentSection) {
				const { id: sectionId } = currentSection;
				store.dispatch(actions.fetchSection(sectionId, 'edit', true));
			}
		});
	}
};

const reorderQuestionsWithoutSections = (store, action) => {
	if (action.type === actions.REORDER_QUESTIONS_WITHOUT_SECTIONS) {
		const { studyId, questionIds } = action.payload;
		services.questionService
			.reorder(studyId, questionIds)
			.then(() => {
				store.dispatch(actions.fetchQuestions(studyId));
			})
			.catch(error => {
				console.error("couldn't re-order sections", error);
			});
	}
};

const addEasyLogic = (store, action) => {
	if (action.type === actions.ADD_EASY_LOGIC) {
		const { studyId, audienceCollectionId, questionId, logic, isAudienceTemplate } = action.payload;

		services.audienceService
			.addLogicIntoQuestion(studyId, audienceCollectionId, questionId, logic)
			.then(response => {
				services.audienceService
					.getCollection(studyId, audienceCollectionId)
					.then(({ data }) => {
						if (isAudienceTemplate)
							store.dispatch(actions.setAudienceEditTemplate({ loading: false, content: data }));
						else store.dispatch(actions.setAudienceCollection({ loading: false, content: data }));
					})
					.catch(e => console.error(e));
			})
			.catch(error => {
				console.error(error);
			});
	}
};

const removeEasyLogic = async (store, action) => {
	if (action.type === actions.REMOVE_EASY_LOGIC) {
		const { studyId, audienceCollectionId, questionId, logicId, isAudienceTemplate } = action.payload;

		services.audienceService
			.deleteLogicFromQuestion(studyId, audienceCollectionId, questionId, logicId)
			.then(response => {
				services.audienceService
					.getCollection(studyId, audienceCollectionId)
					.then(({ data }) => {
						if (isAudienceTemplate)
							store.dispatch(actions.setAudienceEditTemplate({ loading: false, content: data }));
						else store.dispatch(actions.setAudienceCollection({ loading: false, content: data }));
					})
					.catch(e => console.error(e));
			})
			.catch(e => console.error(e));
	}
};

export default [
	// QUESTIONS
	addSectionQuestion,
	deleteSectionQuestion,
	reorderQuestions,
	updateQuestionLabel,
	patchQuestion,
	createQuestionSetting,
	patchQuestionSetting,

	// OPTIONS
	addOptionPlaceholder,
	removeOptionPlaceholder,
	addQuestionOption,
	removeQuestionOption,
	updateOptionLabel,
	reorderOptions,
	patchQuestionOption,
	reorderOptionsWithoutSections,
	setOptionScalePointValue,
	updateOptionScalePoint,

	// CUSTOM QUALIFIERS
	addAudienceQuestion,
	deleteCustomQualifier,
	updateCustomQualifierLabel,
	addCustomQualifierOptionPlaceholder,
	addCustomQualifierAttributePlaceholder,

	// ATTRIBUTES
	addAttributePlaceholder,
	removeAttributePlaceholder,
	addQuestionAttribute,
	removeQuestionAttribute,
	updateAttributeLabel,
	reorderAttributes,
	patchQuestionAttribute,
	reorderAttributesWithoutSections,
	reorderQuestionsWithoutSections,

	// Easy Logic
	addEasyLogic,
	removeEasyLogic,
];
