import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import { bem } from 'src/components/helpers';

const FAB_CLASS = 'FAB';

const FAB = ({ size, icon, iconClass, className, onClick, children, ...props }) => {
	const generateClasses = () => `${FAB_CLASS} ${bem(FAB_CLASS, 'trigger', size)} ${className || ''}`;

	return (
		<div
			{...props}
			className={generateClasses()}
			onClick={onClick}
			onKeyPress={e => {
				if (e.key === 'Enter') onClick();
			}}
			role="button"
			tabIndex={0}
		>
			{icon && <Iconof className={iconClass} icon="help_outlined" size="large" />}
			{children}
		</div>
	);
};

FAB.propTypes = {
	size: PropTypes.oneOf(['small', 'medium', 's', 'm']),
	onClick: PropTypes.func,
	icon: PropTypes.string,
	iconClass: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.element,
};
FAB.defaultProps = {
	size: 'medium',
	onClick: () => {},
};
export default FAB;
