import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { Iconof } from '@upsiide/ui-components';
import './styles.scss';

const SearchResultsOrder = ({ isActive, currentOrderDirection, type, handleChangeOrder, label }) => {
	const className = 'search-results-sort-order';
	const el = (name, mod) => cn(className, name, mod);

	const onChangeOrder = useCallback(
		direction => {
			handleChangeOrder(type, direction);
		},
		[handleChangeOrder, type],
	);

	return (
		<div
			className={className}
			onClick={() => onChangeOrder(currentOrderDirection === 'asc' ? 'desc' : 'asc')}
			onKeyDown={() => {}}
			role="button"
			tabIndex={0}
		>
			{label}{' '}
			<div className={el('icons')}>
				<Iconof
					className={el('order-icon', isActive && currentOrderDirection === 'asc' ? 'active' : '')}
					icon="chevron_up"
				/>
				<Iconof
					className={el('order-icon', isActive && currentOrderDirection === 'desc' ? 'active' : '')}
					icon="chevron_down"
				/>
			</div>
		</div>
	);
};

SearchResultsOrder.propTypes = {
	isActive: PropTypes.bool,
	currentOrderDirection: PropTypes.string,
	type: PropTypes.string,
	handleChangeOrder: PropTypes.func,
	label: PropTypes.string,
};

export default SearchResultsOrder;
