import { navigate } from 'src/utilities/router/routerScopeLeaker';
import * as services from 'src/services';
import toastr from 'toastr';

import * as SignupActions from 'src/domains/confirm-signup/actions';

import Cookies from 'src/utilities/cookies';
import UtmDataMapper from 'src/utilities/utm-data-mapper';

import * as confirmSignUpActions from '../../confirm-signup/actions';
import * as actions from '../actions';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};

const USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS';

const signUpUser = async (store, { type, payload }) => {
	if (type === actions.SIGNUP_USER) {
		try {
			store.dispatch(actions.incLoading());
			const sourceUrl = window.location.href;
			const utmCookieData = Cookies.getUtmData();
			const utmData = UtmDataMapper.mapCookieToApiUtmDatFormat(utmCookieData);
			const requestData = Object.assign({ ...payload.user, sourceUrl, ...utmData });
			const resp = await services.userService.selfSignUp(requestData);
			if (resp.status < 200 || resp.status >= 300) {
				throw resp;
			}
			await store.dispatch(confirmSignUpActions.setUser(payload.user.email));
			await store.dispatch(SignupActions.setIsSignupFlow(false));
			await store.dispatch(actions.decLoading());
			navigate('/account-details');

			// Don't show the What's New modal for new signups
			window.localStorage.setItem('UPSIIDE__HIDE_ANNOUNCEMENT', true);
		} catch (error) {
			store.dispatch(actions.decLoading());
			if (error.data && error.data.message === USER_ALREADY_EXISTS) {
				toastr.error('User already exists'); // todo improve error message (?)
			} else {
				toastr.error('Something went wrong, try again later');
			}
		}
	}
};

export default [signUpUser];
