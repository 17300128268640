import * as actions from '../actions';

const INITIAL_STATE = {
	commonError: null,
	clientId: null,
	clients: { loading: false, content: [], error: null },
	users: { loading: true, content: [], error: null },
	groups: { loading: false, content: [], error: null },
	addGroupModal: { visible: false, loading: false, error: null },
	addUserLoading: false,
	addUserComplete: false,
	deleteUserModal: { visible: false },
	deleteGroupModal: { visible: false },
	roles: { loading: false, content: [], error: null },
	manageGroupModal: { group: null, visible: false, loading: false, users: [], error: null },
	sorting: { field: 'role', direction: 'desc' },
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_COMMON_ERROR:
			return { ...state, commonError: action.payload.commonError };

		case actions.SET_CLIENT_ID:
			return { ...state, clientId: action.payload.clientId };

		case actions.SET_REDUCER_CLIENT_ID:
			return { ...state, clientId: action.payload.clientId };

		case actions.SET_CLIENTS:
			return { ...state, clients: { ...state.clients, ...action.payload.clients } };

		case actions.SET_USERS:
			return { ...state, users: { ...state.users, ...action.payload.users } };

		case actions.SET_GROUPS:
			return { ...state, groups: { ...state.groups, ...action.payload.groups } };

		case actions.SET_ADD_USER_LOADING:
			return { ...state, addUserLoading: action.payload.isLoading };

		case actions.SET_ADD_USER_COMPLETE:
			return { ...state, addUserComplete: action.payload.complete };

		case actions.SET_ADD_GROUP_MODAL:
			return { ...state, addGroupModal: { ...state.addGroupModal, ...action.payload.addGroupModal } };

		case actions.SET_DELETE_USER_MODAL:
			return { ...state, deleteUserModal: { ...state.deleteUserModal, ...action.payload.deleteUserModal } };

		case actions.SET_DELETE_GROUP_MODAL:
			return { ...state, deleteGroupModal: { ...state.deleteGroupModal, ...action.payload.deleteGroupModal } };

		case actions.SET_ROLES:
			return { ...state, roles: { ...state.roles, ...action.payload.roles } };

		case actions.SET_MANAGE_GROUP_MODAL:
			return { ...state, manageGroupModal: { ...state.manageGroupModal, ...action.payload.manageGroupModal } };

		case actions.SET_SORTING:
			return { ...state, sorting: { ...state.sorting, ...action.payload.sorting } };

		default:
			return state;
	}
};
