import React from 'react';
import cn from 'src/utilities/bem-cn';
import Sad from 'src/components/icons/Emojis/sadButRelieved.svg';

import './styles.scss';

const className = 'empty-search-results';
const el = (name, mod) => cn(className, name, mod);

const EmptySearchResults = () => (
	<div className={className}>
		<div
			className={el('sad')}
			style={{
				backgroundImage: `url(${Sad})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				height: '28px',
				width: '28px',
			}}
		/>
		<span className={el('header')}>NO RESULTS</span>
		<span className={el('sub-header')}>
			Try using different keywords, checking for typos, or adjusting your filters.
		</span>
	</div>
);

export default EmptySearchResults;
