import React from 'react';

import './styles.scss';

const QuestionCheckmark = ({ color = '#BABABA' }) => (
	<svg
		className="question-checkmark"
		width="16px"
		height="16px"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<polygon
			fill={color}
			points="0 24 24 24 24 10.6896 11.7184 19.9 3.8784 12.06 8.1208 7.8176 12.2808 11.9776 24 3.188 24 0 0 0"
		></polygon>
	</svg>
);

export default QuestionCheckmark;
