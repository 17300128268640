import React from 'react';

import './styles.scss';

export default ({ ...props }) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
		<g fill="none" fillRule="evenodd">
			<path
				fill="#3B3B3B"
				d="M13.648 5.166c.13-.129.338-.129.467 0s.129.338 0 .467L8.177 11.57c-.129.129-.337.129-.466 0L1.773 5.633c-.129-.13-.129-.338 0-.467.13-.129.338-.129.467 0l5.704 5.704 5.704-5.704z"
			/>
		</g>
	</svg>
);
