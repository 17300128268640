import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

const adminService = {
	validateTemplate: formData => axios.post(`${API_PATH}/admin/templates`, formData),
	editTemplate: (templateId, formData) => axios.put(`${API_PATH}/admin/templates/${templateId}`, formData),
	fetchAllTemplates: () => axios.get(`${API_PATH}/admin/templates`),
	fetchAccountUsage: (accountUuids, clientUuids, startDate, endDate, isBilled, component, limit, offset) =>
		axios.get(`${API_PATH}/admin/accounts/usage`, {
			params: { accountUuids, clientUuids, startDate, endDate, isBilled, component, limit, offset },
		}),
	exportAccountUsage: (accountUuids, clientUuids, startDate, endDate, isBilled, component) =>
		axios.download(`${API_PATH}/admin/accounts/export/usage`, 'Usage.csv', {
			accountUuids,
			clientUuids,
			startDate,
			endDate,
			isBilled,
			component,
		}),
	editAccountUsage: (usageId, formData) => axios.patch(`${API_PATH}/admin/accounts/usage/${usageId}`, formData),
	fetchAccountTemplates: (accountUuid, clientUuid) =>
		axios.get(`${API_PATH}/admin/clientsampletemplates`, { params: { accountUuid, clientUuid } }),
	assignTemplate: data => axios.post(`${API_PATH}/admin/clientsampletemplates`, data),
	editClientSampleTemplate: (templateId, data) =>
		axios.put(`${API_PATH}/admin/clientsampletemplates/${templateId}`, data),
	updateAccountSettings: (accountUuid, formData) =>
		axios.patch(`${API_PATH}/admin/accounts/${accountUuid}/settings`, formData),
	fetchAudiences: filters => axios.get(`${API_PATH}/admin/audiences`, { params: { ...filters } }),
	fetchCustomQualifiers: countryLanguageId =>
		axios.get(`${API_PATH}/admin/audience/targets`, { params: { countryLanguageId } }),
	createCustomQualifier: formData => axios.post(`${API_PATH}/admin/audience/targets`, formData),
	fetchAudience: audienceUuid => axios.get(`${API_PATH}/admin/audiences/${audienceUuid}`),
	pauseAudience: audienceUuid => axios.post(`${API_PATH}/admin/audiences/${audienceUuid}/pause`),
	resumeAudience: audienceUuid => axios.post(`${API_PATH}/admin/audiences/${audienceUuid}/resume`),
	stopAudienceVelocity: (audienceUuid, newBid) =>
		axios.post(`${API_PATH}/admin/audiences/${audienceUuid}/stop-velocity`, { newBid }),
	getBaseSizeThreshold() {
		// TODO: Return base size from admin setting endpoint
		return new Promise(resolve => resolve(process.env.baseSizeThreshold));
	},
	fetchSubscriptionFeatures: () => axios.get(`${API_PATH}/admin/subscription-features`),
	createSubscriptionFeature: (featureId, subscriptionId) =>
		axios.post(`${API_PATH}/admin/subscription-features`, { subscriptionId, featureId, isEnabled: true }),
	patchSubscriptionFeature: (subscriptionFeatureId, isEnabled) =>
		axios.patch(`${API_PATH}/admin/subscription-features/${subscriptionFeatureId}`, { isEnabled }),
};

export default adminService;
