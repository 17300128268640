const prefix = 'auth';

export const AUTHENTICATE = `${prefix}/AUTHENTICATE`;
export const authenticate = token => ({
	type: AUTHENTICATE,
	payload: { token },
});

// Used when the API tells us the user has access changes
export const REAUTHENTICATE = `${prefix}/REAUTHENTICATE`;
export const reauthenticate = token => ({
	type: REAUTHENTICATE,
	payload: { token },
});

export const LOGIN_AS_USER = `${prefix}/LOGIN_AS_USER`;
export const loginAsUser = uuid => ({ type: LOGIN_AS_USER, payload: { uuid } });

export const IS_GHOST_USER = `${prefix}/IS_GHOST_USER`;
export const isGhostUser = ghost => ({ type: IS_GHOST_USER, payload: { ghost } });

export const LOGOUT = `${prefix}/LOGOUT`;
export const logout = () => ({ type: LOGOUT });

export const SET_LOGOUT = `${prefix}/SET_LOGOUT`;
export const setLogout = () => ({ type: SET_LOGOUT });

export const SWITCH_BACK = `${prefix}/SWITCH_BACK`;
export const switchToOriginalUser = () => ({ type: SWITCH_BACK });

export const SET_ADD_USER_MODAL = `${prefix}/SET_ADD_USER_MODAL`;
export const setAddUserModal = addUserModal => ({ type: SET_ADD_USER_MODAL, payload: { addUserModal } });

export const SET_USERDATA = `${prefix}/SET_USERDATA`;
export const setUserData = payload => ({ type: SET_USERDATA, payload });

export const SET_NO_USER = `${prefix}/SET_NO_USER`;
export const setNoUser = () => ({ type: SET_NO_USER });

export const SET_ERROR = `${prefix}/SET_ERROR`;
export const setError = () => ({ type: LOGOUT });

export const SET_CLIENT_ID = `${prefix}/SET_CLIENT_ID`;
export const setClientId = clientId => ({
	type: SET_CLIENT_ID,
	payload: { clientId },
});

export const SET_ACCOUNT_UUID = `${prefix}/SET_ACCOUNT_UUID`;
export const setAccountUuid = accountUuid => ({
	type: SET_ACCOUNT_UUID,
	payload: { accountUuid },
});

export const GO_TO_SUPPORT = `${prefix}/GO_TO_SUPPORT`;
export const goToSupport = returnTo => ({
	type: GO_TO_SUPPORT,
	payload: {
		returnTo,
	},
});

export const REDIRECT_TO_CANNY = `${prefix}/REDIRECT_TO_CANNY`;
export const redirectToCanny = redirect => ({ type: REDIRECT_TO_CANNY, payload: { redirect } });

export const SET_HAS_ACCOUNT = `${prefix}/SET_HAS_ACCOUNT`;
export const setHasAccount = hasAccount => ({ type: SET_HAS_ACCOUNT, payload: { hasAccount } });

export const SET_RATE_LIMIT = `${prefix}/SET_RATE_LIMIT`;
export const setRateLimit = (flagged, timer) => ({ type: SET_RATE_LIMIT, payload: { flagged, timer } });
