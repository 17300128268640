import * as actions from '../actions';

const INITIAL_STATE = {
	token: null,
	user: null,
	loading: 0,
	error: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_TOKEN:
			return { ...state, token: action.payload.token };

		case actions.SET_USER:
			return { ...state, user: action.payload.user };

		case actions.INC_LOADING:
			return { ...state, loading: state.loading + 1 };

		case actions.DEC_LOADING:
			return { ...state, loading: state.loading - 1 };

		case actions.SET_ERROR:
			return { ...state, error: action.payload.error };

		default:
			return state;
	}
};
