import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { MasonryLayout } from '@upsiide/ui-components';
import { element } from 'src/utilities/bem-cn';
import Divider from 'src/components/elements/Divider';
import Loader from 'src/components/shared/Loader';
import * as services from 'src/services';
import GallerySearchBar from '../Shared/GallerySearchBar';
import AssetGalleryAsset from '../Shared/AssetGalleryAsset';
import ScrollContainer from '../Shared/ScrollContainer';
import { useScroll, useFetchAssetsOnLoad, useResetDataOnUnMount } from '../hooks';

import './style.scss';

const className = 'asset-gallery-unsplash';
const el = element(className);

const Unsplash = ({
	assets,
	setAssets,
	selectedUnsplashAsset,
	setSelectedUnsplashAsset,
	searchValue,
	setSearchValue,
	currentPageRef,
	initialDataLoaded,
	setInitialDataLoaded,
}) => {
	const scrollRef = useRef();
	const [scrollRefVisible, setScrollRefVisible] = useState(false);
	const [searchLoading, setSearchLoading] = useState(false);

	const allFetched = useRef();
	const scrollLoading = useRef(false);

	const perPage = 30;

	const handleOnSelectAsset = useCallback(
		id => {
			if (selectedUnsplashAsset === id) {
				setSelectedUnsplashAsset(null);
			} else {
				setSelectedUnsplashAsset(id);
			}
		},
		[selectedUnsplashAsset, setSelectedUnsplashAsset],
	);

	const fetchAssets = useCallback(
		(search, refreshResults = false) => {
			if (allFetched.current) return;

			if (scrollLoading.current || searchLoading) {
				return;
			}

			scrollLoading.current = true;
			const searchPage = currentPageRef.current;

			services.assetService
				.searchUnsplash(search || 'random', perPage, searchPage)
				.then(data => {
					currentPageRef.current += 1;

					allFetched.current = allFetched.current || data.length === 0;

					if (data.length > 0) {
						if (refreshResults) {
							setAssets(data);
						} else {
							setAssets(currentAssets => uniqBy(currentAssets.concat(data), asset => asset.id));
						}
					} else if (data.length === 0 && searchPage === 1) {
						setAssets([]);
					}

					scrollLoading.current = false;
					setSearchLoading(false);
					setInitialDataLoaded(true);
				})
				.catch(e => {
					scrollLoading.current = false;
					setSearchLoading(false);
					console.log('error', e);
				});
		},
		[currentPageRef, searchLoading, setAssets, setInitialDataLoaded],
	);

	const handleOnSearch = useCallback(
		val => {
			// reset all the things
			allFetched.current = false;
			currentPageRef.current = 1;

			// Fetch new assets
			fetchAssets(val, true);
		},
		[currentPageRef, fetchAssets],
	);

	const handleIsSearching = useCallback(
		searching => {
			if (searching) {
				setAssets([]);
				setInitialDataLoaded(false);
			}
			setSearchLoading(searching);
		},
		[setAssets, setInitialDataLoaded],
	);

	const resetAllThings = useCallback(() => {
		allFetched.current = false;
		currentPageRef.current = 0;
	}, [currentPageRef]);

	// reset on unmount
	useResetDataOnUnMount({ resetAllThings, setAssets, setInitialDataLoaded });

	// Initial load fetch assets
	useFetchAssetsOnLoad({ loading: scrollLoading.current, assets, fetchAssets, searchValue });

	// Scroll detection
	useScroll({ scrollRef, scrollRefVisible, fetchAssets, searchValue });

	return (
		<div className={className}>
			<Divider customClasses={el('divider-top')} />

			<div className={el('header')}>
				<GallerySearchBar
					onSearch={handleOnSearch}
					value={searchValue}
					setValue={setSearchValue}
					placeholder="Find an image in Unsplash"
					isSearching={handleIsSearching}
				/>
			</div>

			<ScrollContainer
				ref={refEl => {
					scrollRef.current = refEl;
					setScrollRefVisible(!!refEl);
				}}
			>
				{assets.length ? (
					<MasonryLayout cols={3} gap={8}>
						{assets.map(asset => (
							<div
								className={el('item', selectedUnsplashAsset === asset.id ? 'selected' : '')}
								key={asset.id}
							>
								<AssetGalleryAsset
									isImage
									id={asset.id}
									url={asset.urls.thumb}
									alt={asset.alt_decription}
									title={asset.owner}
									onSelect={handleOnSelectAsset}
									linkToAuthor={asset.ownerProfile}
								/>
							</div>
						))}
					</MasonryLayout>
				) : (
					<>
						{!searchLoading && initialDataLoaded ? (
							<div className={el('no-images-found')}>
								<span>No images match your search</span>
							</div>
						) : null}
					</>
				)}
			</ScrollContainer>

			{(searchLoading || !initialDataLoaded) && (
				<div className={el('loader')}>
					<Loader centered />
				</div>
			)}

			<Divider />
		</div>
	);
};

Unsplash.propTypes = {
	assets: PropTypes.array,
	setAssets: PropTypes.func,
	selectedUnsplashAsset: PropTypes.string,
	setSelectedUnsplashAsset: PropTypes.func,
	searchValue: PropTypes.string,
	setSearchValue: PropTypes.func,
	currentPageRef: PropTypes.any,
	initialDataLoaded: PropTypes.bool,
	setInitialDataLoaded: PropTypes.any,
};

export default Unsplash;
