import React from 'react';
import PropTypes from 'prop-types';

const QuestionLady = ({ width = '180', height = '136' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 180 136">
		<g fill="none" fillRule="evenodd">
			<path
				fill="#E3F4EC"
				d="M31.65 91.567c8.972-1.01 14.859-8.134 25.096-6.963 10.714 1.224 18.062 27.178 42.134 33.564 18.7 4.962 57.77 1.935 74.57-35.79 16.8-37.725-.675-78.642-30.178-78.377-21.386.193-37.25 9.083-54.763 10.123-16.25.966-34.065-11.065-49.152-9.976C15.025 5.905 4.51 27.634.795 44.278c-3.54 15.862 4.4 50.267 30.855 47.289"
				transform="translate(0 -4)"
			/>
			<g>
				<path
					fill="#FFF"
					d="M62.198 21h54.854c2.319 0 4.198 1.88 4.198 4.198v50.8c0 2.318-1.88 4.197-4.198 4.197H62.198c-2.319 0-4.198-1.879-4.198-4.197v-50.8C58 22.879 59.88 21 62.198 21z"
					transform="translate(0 -4) translate(11)"
				/>
				<g>
					<path
						fill="#7AA7DB"
						d="M15.587 12.386c0 1.768-1.434 3.2-3.202 3.2H3.202c-1.768 0-3.201-1.432-3.202-3.2V3.202C0 2.353.337 1.54.938.938 1.538.338 2.353 0 3.202 0h9.183c.849 0 1.663.337 2.264.938.6.6.938 1.415.938 2.264v9.184z"
						opacity=".394"
						transform="translate(0 -4) translate(11) translate(67 28)"
					/>
					<path
						fill="#DCECF8"
						d="M21.524.877H34.17c.875 0 1.585.71 1.585 1.585s-.71 1.584-1.585 1.584H21.524c-.875 0-1.585-.71-1.585-1.584 0-.875.71-1.585 1.585-1.585zM21.524 6.01h21.495c.875 0 1.585.71 1.585 1.585s-.71 1.584-1.585 1.584H21.524c-.875 0-1.585-.71-1.585-1.584 0-.875.71-1.584 1.585-1.584zM21.616 11.111h21.496c.875 0 1.584.71 1.584 1.585s-.71 1.584-1.584 1.584H21.616c-.875 0-1.584-.71-1.584-1.584 0-.875.71-1.585 1.584-1.585z"
						transform="translate(0 -4) translate(11) translate(67 28)"
					/>
				</g>
				<path
					fill="#353B6E"
					fillRule="nonzero"
					d="M116.712 80.559H62.785c-2.867-.003-5.19-2.327-5.194-5.195V25.492c.003-2.868 2.327-5.191 5.194-5.195h53.927c2.868.004 5.192 2.327 5.195 5.195v49.872c-.003 2.868-2.327 5.192-5.195 5.195zM62.785 21.363c-2.279.003-4.125 1.85-4.128 4.129v49.872c.003 2.28 1.85 4.126 4.128 4.129h53.927c2.28-.003 4.126-1.85 4.129-4.129V25.492c-.003-2.28-1.85-4.126-4.129-4.129H62.785z"
					transform="translate(0 -4) translate(11)"
				/>
				<g>
					<path
						fill="#FFD5C0"
						d="M30.844 67.87s2.09 23.26 3.528 25.742c1.437 2.483-2.876 4.051-3.92 1.046-1.046-3.006-7.057-19.47-4.836-25.743 2.222-6.273 5.228-1.046 5.228-1.046zM16.6 70.352S5.44 90.867 5.26 93.73c-.18 2.864-5.007 2.963-4.21-.118.796-3.081 5.294-21.301 10.62-25.289 5.328-3.988 4.93 2.03 4.93 2.03z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
					<path
						fill="#7AA7DB"
						d="M29.456 97.86v3.92h2.091l.033-3.84s3.822 3.817 7.056 3.817h2.09s-4.769-1.72-6.039-7.775h-4.286c-.158.288-1.034 1.974-.945 3.877zM.018 97.86v3.92h2.09l.033-3.84s3.822 3.817 7.057 3.817h2.09s-5.299-2.853-6.04-7.775H.964c-.158.288-1.034 1.974-.945 3.877zM21.474.316c-.31-.086-3.688-.954-6.386 1.317-2.792 2.351-2.383 5.984-2.331 6.386.304 2.345 1.588 2.373 1.824 4.257.485 3.87-4.742 5.28-4.865 9.63-.086 3.056 2.47 3.17 2.636 6.994.153 3.552-1.979 5.199-.811 7.704.085.184.964 1.999 2.736 2.331 1.375.258 2.444-.854 4.765-2.939 5.27-4.736 5.037-4.02 5.574-4.865 2.38-3.739-7.707-4.035-6.081-10.744.72-2.97 2.66-4.245 1.723-9.022-1.013-5.17 7.946-4.833 7.602-5.879-1.13-3.428-5.156-4.799-6.386-5.17z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
					<path
						fill="#7AA7DB"
						d="M32.725 4.17c-.716-1.747-3.167-2.47-4.156-2.435-.96.04-1.9.294-2.75.745-.692.361-1.737.906-2.318 2.012-.947 1.801-.173 4.192 1.216 5.58.838.837 2.405 1.752 3.244 1.181 1.015-.691.096-2.969 1.216-3.756.845-.595 1.776.415 2.737-.215.853-.559 1.233-2.084.811-3.112z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
					<path
						fill="#FFD5C0"
						d="M31.601 3.643s2.875 6.664-1.176 6.534c0 0-1.96 1.83-.523 6.403 4.445 22.557-9.903 9.185-10.557 6.44-.654-2.743 5.004-8.988 6.572-10.948 1.568-1.96 1.045-3.267 1.045-3.267s-1.437-2.222.523-1.7c0 0 3.07.328 4.116-3.462z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
					<path
						fill="#404884"
						d="M21.753 17.92s-3.523 2.364-2.306 8.344c1.216 5.98 1.52 6.791-2.13 13.38-3.648 6.59-10.135 31.727-10.135 31.727s14.089 5.27 24.225.506c0 0-2.534-29.395-.101-43.788 0 0 2.838-2.737 0-6.994s-6.842-3.917-9.553-3.175z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
					<path
						fill="#3B619B"
						d="M21.753 17.92s-3.523 2.364-2.306 8.344c1.216 5.98 1.52 6.791-2.13 13.38-3.648 6.59-10.135 31.727-10.135 31.727s14.089 5.27 24.225.506c0 0-2.534-29.395-.101-43.788 0 0 2.838-2.737 0-6.994s-6.842-3.917-9.553-3.175z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
					<path
						fill="#FFD5C0"
						d="M40.665 17.036c.188-.38.247-.811.168-1.228-.144-.65.386-2.456 0-2.866 0 0 .82-.264.747 1.036-.072 1.3-.048.722-.048.722s1.733-4.02 2.094-4.045c.362-.024.025.819-.915 3.178 0 0 2.625.82 2.071 1.71-.554.89-1.132 1.445-1.902 2.552-.77 1.108-2.215-1.06-2.215-1.06z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
					<path
						fill="#FFD5C0"
						d="M33.154 25.575l7.7-8.933s2.124-.867 2.007 1.31l-8.21 13.989c-6.994.81-1.497-6.366-1.497-6.366z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
					<path
						fill="#FFD5C0"
						d="M33.13 25.555l-4.966-5.98-3.75 5.575 7.635 6.622s4.63-3.176 1.081-6.217z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
					<path
						fill="#7AA7DB"
						d="M29.5 18.553c.443.532.817 1.118 1.114 1.743.17.369.172.793.008 1.164-.165.37-.48.653-.867.776l-.097.031c-.668.214-1.159.785-1.27 1.477l-.042.263c-.11.685-.638 1.227-1.32 1.353l-.292.054c-.727.135-1.213.825-1.095 1.555l.095.593c.036.22-.064.44-.253.559-.189.118-.43.112-.613-.016-1.527-1.07-4.21-3.254-4.712-5.692-.205-.997.09-2.046.614-3.033 1.762-3.317 6.347-3.73 8.73-.827z"
						transform="translate(0 -4) translate(11) translate(22.054 37.746)"
					/>
				</g>
				<g>
					<path
						fill="#7AA7DB"
						d="M15.587 12.386c0 1.768-1.434 3.2-3.202 3.2H3.202c-1.768 0-3.201-1.432-3.202-3.2V3.202C0 2.353.337 1.54.938.938 1.538.338 2.353 0 3.202 0h9.183c.849 0 1.663.337 2.264.938.6.6.938 1.415.938 2.264v9.184z"
						opacity=".394"
						transform="translate(0 -4) translate(11) translate(67 54)"
					/>
					<path
						fill="#DCECF8"
						d="M21.524.877H34.17c.875 0 1.585.71 1.585 1.585s-.71 1.584-1.585 1.584H21.524c-.875 0-1.585-.71-1.585-1.584 0-.875.71-1.585 1.585-1.585zM21.524 6.01h21.495c.875 0 1.585.71 1.585 1.585s-.71 1.584-1.585 1.584H21.524c-.875 0-1.585-.71-1.585-1.584 0-.875.71-1.584 1.585-1.584zM21.616 11.111h21.496c.875 0 1.584.71 1.584 1.585s-.71 1.584-1.584 1.584H21.616c-.875 0-1.584-.71-1.584-1.584 0-.875.71-1.585 1.584-1.585z"
						transform="translate(0 -4) translate(11) translate(67 54)"
					/>
				</g>
			</g>
		</g>
	</svg>
);

QuestionLady.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

export default QuestionLady;
