import React, { useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import cn from 'src/utilities/bem-cn';
import { getUser } from 'src/domains/profile/selectors';
import OpenMailboxRaisedFlag from 'src/components/icons/OpenMailboxRaisedFlag';
import AccountService from 'src/services/account.service';
import { pluralize } from 'src/utilities/misc';

import './styles.scss';

const className = 'contact-us-thank-you';
const el = name => cn(className, name);

const ContactUsThankYou = ({ daysUntilRenewal, handleSoundsGoodClick }) => {
	const emailSent = useRef(false); // ensure email doesn't get sent twice if component re-renders
	const { email } = useSelector(getUser) || {};

	const handleContactRequest = useCallback(async () => {
		try {
			if (email) {
				await AccountService.addEmailEnterpriseContact({ email });
			}
		} catch (e) {
			toastr.error(`${e}. Something went wrong, try again later`);
		}
	}, [email]);

	useEffect(() => {
		if (email && !emailSent.current) {
			handleContactRequest();
			emailSent.current = true;
		}
	}, [handleContactRequest, email]);

	return (
		<div className={`${el('contact-me')} show-contact-me`}>
			<OpenMailboxRaisedFlag />
			<h2 className={el('header')}>Thanks! Someone from our team will reach out soon.</h2>
			<p className={el('body')}>
				{daysUntilRenewal ? (
					<>
						In the meantime, feel free to keep exploring Upsiide.{' '}
						<span>
							You've got {Math.max(daysUntilRenewal, 0)} {pluralize(daysUntilRenewal, 'day')} left in your{' '}
							free trial.
						</span>
					</>
				) : null}
			</p>
			<div
				className={el('contact-me-button')}
				onClick={() => handleSoundsGoodClick()}
				onKeyDown={() => {}}
				role="button"
				tabIndex={0}
			>
				Sounds good!
			</div>
		</div>
	);
};

ContactUsThankYou.propTypes = {
	daysUntilRenewal: PropTypes.number,
	handleSoundsGoodClick: PropTypes.func,
};

export default ContactUsThankYou;
