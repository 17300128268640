import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { Tooltip } from 'react-tippy';
import Tag from 'src/components/elements/Tag';
import { Iconof } from '@upsiide/ui-components';
import QuotaIcon from 'public/images/audience/quota-icon.svg';
import InterlockQuotasIcon from 'public/images/audience/interlock-quotas-icon.png';

import './styles.scss';

const className = 'styled-drop-down-group';

const el = (name, mod) => cn(className, name, mod);

const DropDownGroup = ({
	title,
	questionId,
	hasQuotas = false,
	hasInterlockedQuotas = false,
	enableQuota = false,
	options,
	onChangeOptions,
	children,
	viewMode,
	active = false,
	canChangeOptions = false,
	canEdit = false,
	ageError = false,
}) => {
	const roundedTotalQuota = useMemo(() => {
		if (!options?.length) return;
		const quotas = options?.filter(option => option?.isSelected)?.map(option => option?.quota?.[0]?.quotaMax);
		const totalQuota = quotas?.reduce((acc, quota) => acc + (quota || 0), 0);
		if (!totalQuota) return;
		return Math.ceil(totalQuota);
	}, [options]);

	const renderPartialTag = () => {
		if (roundedTotalQuota >= 100) return;
		if (!options?.length) return;

		const quotas = options?.filter(option => option?.isSelected)?.map(option => option?.quota?.[0]?.quotaMax);
		const nullQuotas = quotas.filter(quota => quota == null);
		const notEmptyQuotas = quotas.filter(quota => quota != null);

		if (!nullQuotas?.length || !notEmptyQuotas?.length) return;

		return (
			<Tooltip
				className={el('soft')}
				theme="dark-blured"
				position="top"
				delay={[0, 1000]}
				interactive
				html={
					<div className={el('soft-quota-warning')}>
						When total entered quotas are below 100%,<b> partial quotas </b> will apply. All answer options
						without a quota value will be grouped together in one quota.
						<a
							href="https://support.upsiide.com/en/articles/5192123-setting-up-quotas#h_c4bcb26595"
							target="blank"
						>
							{'Learn more ->'}
						</a>
					</div>
				}
			>
				<Tag className="partial" label="Partial" />
			</Tooltip>
		);
	};

	const renderSoftQuotaTag = quotasSum => {
		const finalQuota = Math.ceil(Number(quotasSum))?.toFixed(2);
		if (finalQuota <= 100) return;

		return (
			<Tooltip
				className={el('soft')}
				theme="dark-blured"
				position="top"
				delay={[0, 1000]}
				interactive
				html={
					<div className={el('soft-quota-warning')}>
						When quota exceeds 100%, <b> soft quotas </b> will apply. Each quota will be treated as a max
						value.
						<a href="https://support.upsiide.com/en/articles/5192123" target="blank">
							{'Learn more ->'}
						</a>
					</div>
				}
			>
				<Tag className="soft-quota" label="Soft" />
			</Tooltip>
		);
	};

	const parseQuota = (a, b) => Number((a / b).toFixed(2));

	const distributeQuotasEvenly = useCallback(() => {
		const optionsWithQuotas = options.filter(({ isSelected }) => isSelected);
		const changedOptions = optionsWithQuotas.map(option => ({
			...option,
			quota: {
				id: option?.quota?.[0]?.id,
				quotaMin: parseQuota(100, optionsWithQuotas.length),
				quotaMax: parseQuota(100, optionsWithQuotas.length),
			},
			isSelected: true,
		}));

		onChangeOptions(questionId, changedOptions);
	}, [onChangeOptions, options, questionId]);

	const renderQuotaText = () => {
		if (!enableQuota) {
			return (
				<div className={el('quotas_msg')}>
					<span>Quotas unavailable</span>
				</div>
			);
		}

		if (!hasInterlockedQuotas) {
			return (
				<div className={quotaWarning() ? el('quotas') : el('quotas_msg')}>
					{quotaWarning() || <span>Click on option to edit quota</span>}
					{canChangeOptions ? (
						<Tooltip
							className={el('distribute-evenly')}
							theme="dark-blured"
							position="top"
							html={
								<div className="interlock-quota-warning">
									<p>Distribute evenly</p>
								</div>
							}
						>
							<img
								aria-hidden
								onClick={distributeQuotasEvenly}
								className={el('quotas-icon')}
								src={QuotaIcon}
								alt="Quotas"
							/>
						</Tooltip>
					) : null}
				</div>
			);
		}

		return (
			<div className={el('quotas')}>
				{quotaWarning()}
				<Tooltip
					theme="dark-blured"
					position="top"
					html={
						<div className="interlock-quota-warning">
							<b>Interlocked quota</b>
							<p>
								Click on
								<img
									className={el('interlock-quotas-icon')}
									src={InterlockQuotasIcon}
									alt="Interlock Quotas"
								/>
								on top of your
							</p>
							<p>demographic details table to</p>
							<p>go to interlock page</p>
						</div>
					}
				>
					<img className={el('interlock-quotas-icon')} src={InterlockQuotasIcon} alt="Interlock Quotas" />
				</Tooltip>
			</div>
		);
	};

	const hasZeroQuotas = useCallback(() => {
		if (!options?.length) return;
		const useQuota = !!options?.find(option => option?.quota?.[0]?.quotaMax != null);
		if (!useQuota) return;

		const quotas = options?.filter(({ isSelected }) => isSelected)?.map(option => option?.quota?.[0]?.quotaMax);
		const totalQuotas = quotas.reduce((acc, curr) => acc + (curr || 0), 0);
		const hasNullQuotas = quotas.includes(null) || quotas.includes(undefined);
		if ((quotas.length - 1) % 3 === 0) {
			if (Math.ceil(totalQuotas) >= 100 && hasNullQuotas) {
				return true;
			}
		} else if (totalQuotas >= 100 && hasNullQuotas) {
			return true;
		}
		return quotas.includes(0);
	}, [options]);

	const hasQuotaBellow100 = useCallback(() => {
		if (!options?.length) return;
		const useQuota = !!options?.find(option => option?.quota?.[0]?.quotaMax != null);
		if (!useQuota) return;
		if (roundedTotalQuota > 100) return;

		const quotas = options?.filter(option => option?.isSelected)?.map(option => option?.quota?.[0]?.quotaMax);
		const hasEmptyQuotas = quotas.includes(null);
		return !hasEmptyQuotas && roundedTotalQuota < 100;
	}, [roundedTotalQuota, options]);

	const hasIndividualInvalid = useCallback(() => {
		if (!options?.length) return;
		const useQuota = !!options?.find(option => option?.quota?.[0]?.quotaMax != null);
		if (!useQuota) return;

		const quotas = options
			?.filter(option => option?.isSelected)
			?.some(option => option?.quota?.[0]?.quotaMax > 100);
		return !!quotas;
	}, [options]);

	const partialQuotasAbove100 = useCallback(() => {
		if (!options?.length) return;
		const useQuota = !!options?.find(option => option?.quota?.[0]?.quotaMax != null);
		if (!useQuota) return;

		const quotas = options?.filter(option => option?.isSelected)?.map(option => option?.quota?.[0]?.quotaMax);
		const hasEmptyQuotas = quotas.includes(null);
		return hasEmptyQuotas && roundedTotalQuota > 100;
	}, [roundedTotalQuota, options]);

	const quotaWarning = useCallback(() => {
		const quotasUnder100Percent = hasQuotaBellow100();
		const zeroQuotas = hasZeroQuotas();
		const hasIndividualInvalidQuotas = hasIndividualInvalid();
		const partialAbove = partialQuotasAbove100();
		let errorMessage = '';
		if (zeroQuotas) {
			errorMessage = 'Quotas cannot be zero';
		} else if (quotasUnder100Percent) {
			errorMessage = 'Quotas are lower than 100%';
		} else if (partialAbove) {
			errorMessage = 'Quotas cannot be zero';
		} else if (hasIndividualInvalidQuotas) {
			errorMessage = 'Some quota values are invalid';
		}

		if (!errorMessage) return null;

		return (
			<div className={el('interlock-quota-warning')}>
				<p>
					<Iconof icon="warning" />
					{errorMessage}
				</p>
			</div>
		);
	}, [hasIndividualInvalid, hasQuotaBellow100, hasZeroQuotas, partialQuotasAbove100]);

	return (
		<div className={`${el(`container`)} ${active ? 'active' : ''} ${quotaWarning() ? 'error' : ''}`}>
			<div className={el('group-header')}>
				<span className={el('title')} title={title}>
					{title}
					{ageError && <Iconof icon="warning" />}
				</span>
				{!viewMode && hasQuotas && (
					<span className={el('quota')}>
						{canEdit && renderQuotaText()}
						{enableQuota || roundedTotalQuota ? (
							<>
								{roundedTotalQuota ? <span className={el('quota-label')}>Quota</span> : null}
								<span className="percentage">{roundedTotalQuota ? `${roundedTotalQuota}%` : null}</span>
								{roundedTotalQuota ? renderSoftQuotaTag(roundedTotalQuota) || renderPartialTag() : null}
							</>
						) : null}
					</span>
				)}
			</div>
			<div className={el('group-spacer')} />
			<div className={el('group-children')}>{children}</div>
		</div>
	);
};

DropDownGroup.displayName = 'Demographic';
DropDownGroup.propTypes = {
	questionId: PropTypes.number,
	title: PropTypes.string,
	hasQuotas: PropTypes.bool,
	canChangeOptions: PropTypes.bool,
	hasInterlockedQuotas: PropTypes.bool,
	enableQuota: PropTypes.bool,
	options: PropTypes.array,
	onChangeOptions: PropTypes.func,
	viewMode: PropTypes.string,
	active: PropTypes.bool,
	children: PropTypes.any,
	canEdit: PropTypes.bool,
	ageError: PropTypes.bool,
};

export default DropDownGroup;
