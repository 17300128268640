/**
 * This one ideally should be imported from the root of the application
 * since here we don't know what field in state is used to store domains data
 */
const getDomainsState = state => state;

export const getHeaderData = state => getDomainsState(state).header;
export const getPublicStudyData = state => getDomainsState(state).publicStudy;
export const getPublicReportData = state => getDomainsState(state).publicReport;

export const getAuthData = state => getDomainsState(state).auth;
export const getAllStudiesData = state => getDomainsState(state).allStudies;
export const getAllBlueprintsData = state => getDomainsState(state).allBlueprints;
export const getAdminData = state => getDomainsState(state).admin;
export const getStudyData = state => getDomainsState(state).study;
export const getCreateStudyData = state => getDomainsState(state).createStudy;
export const getManageStudyData = state => getDomainsState(state).manageStudy;
export const getManageBlueprintData = state => getDomainsState(state).manageBlueprint;
export const getLoginUserData = state => getDomainsState(state).loginUser;
export const getClientData = state => getDomainsState(state).client;
export const getClientsData = state => getDomainsState(state).clients;
export const getCreateClientData = state => getDomainsState(state).createClient;

// User-specific
export const getUserProfileData = state => getDomainsState(state).userProfile;
export const getUserResetPasswordData = state => getDomainsState(state).userResetPassword;
export const getUserAccountSetupData = state => getDomainsState(state).userAccountSetup;

// Account Management
export const getAccountData = state => getDomainsState(state).account;

// Checkout
export const getCheckoutData = state => getDomainsState(state).checkout;

// Self Signup
export const getSignUpData = state => getDomainsState(state).signUp;
export const getConfirmSignUpData = state => getDomainsState(state).confirmSignUp;
export const getConfirmEmailData = state => getDomainsState(state).confirmEmail;
export const getAccountDetailsData = state => getDomainsState(state).accountDetails;
export const getBillingDetailsData = state => getDomainsState(state).billingDetails;
export const getPreviewInfoData = state => getDomainsState(state).previewInfo;
export const getChoosePlanData = state => getDomainsState(state).choosePlan;
export const getInviteUsersData = state => getDomainsState(state).inviteUsers;
export const getDownloadFileData = state => getDomainsState(state).downloadFile;