import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import cn from 'src/utilities/bem-cn';
import Auth from 'src/utilities/auth';
import UpgradeTooltip from 'src/components/elements/UpgradeTooltip';
import { getProfessionalLicenseTrialStatus } from 'src/domains/account/selectors';
import './styles.scss';

const className = 'simple-tabs';
const el = name => cn(className, name);

const SimpleTabs = ({ tabs, small, partialMatch, replace, onClick, isPublic }) => {
	const isTrialing = useSelector(getProfessionalLicenseTrialStatus);
	const location = useLocation();
	const ideaMapAccess = Auth.accountHasFeature('ideaMap') || isPublic;
	const marketSimulatorAccess = Auth.accountHasFeature('marketSimulator') || isPublic;

	const linkProps = obj => {
		const current = partialMatch ? obj.isPartiallyCurrent : obj.isCurrent;
		return {
			className: `${el('link')} ${current ? 'active' : ''}`,
		};
	};

	const tabActive = tab => {
		const splitUrl = tab.split('?filter');
		const locationWithoutFilterParams = tab.includes('?filter') ? splitUrl[0] : tab;
		return !!(location?.pathname?.indexOf(locationWithoutFilterParams) > -1);
	};

	const handleDisabledLinkClick = e => e.preventDefault();

	return (
		<div className={`${className} ${small ? `small` : ``}`}>
			{tabs.map((tab, index) => {
				const isDisabled =
					((!ideaMapAccess || isTrialing) && tab.label === 'Idea Map') ||
					((!marketSimulatorAccess || isTrialing) && tab.label === 'Market Simulator');

				return tab.external === true ? (
					<div key={index} href={tab.to} className={el('link')} target="_blank" rel="noopener noreferrer">
						{tab.label}
					</div>
				) : (
					<Link
						key={index}
						to={tab.to}
						replace={replace}
						onClick={isDisabled && !isTrialing ? handleDisabledLinkClick : () => onClick(tab.label)}
						className={`${el('link')} ${tabActive(tab.to) ? 'active' : ''}`}
					>
						<div className={el('label-upgrade')}>
							<div className={`${isDisabled && !isTrialing && el('idea-map-disabled')}`}>{tab.label}</div>
							{isDisabled && <UpgradeTooltip position="bottom" isTrialing={isTrialing} />}
						</div>
					</Link>
				);
			})}
		</div>
	);
};

SimpleTabs.propTypes = {
	/** An array of Objects to define the tabs. Follows the format of `{to: '', label: ''}`. */
	tabs: PropTypes.array,
	small: PropTypes.bool,
	partialMatch: PropTypes.bool,
	replace: PropTypes.bool,
	onClick: PropTypes.func,
	isPublic: PropTypes.bool,
};

SimpleTabs.defaultProps = {
	tabs: null,
	small: false,
	partialMatch: false,
	replace: false,
	onClick: () => { },
};
export default SimpleTabs;
