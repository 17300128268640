import React from 'react';

import './styles.scss';

export default ({ ...props }) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
		<g fill="none" fillRule="evenodd">
			<g fill="#5CC09F">
				<path
					d="M17.898 11.395c-.126-.28-.404-.457-.71-.457h-3.125V.78c0-.431-.35-.781-.782-.781h-3.125c-.431 0-.781.35-.781.781v10.156H6.25c-.306 0-.584.18-.71.458-.13.28-.08.607.122.838l5.47 6.25c.148.17.362.267.587.267.225 0 .439-.098.587-.267l5.469-6.25c.203-.23.25-.558.123-.838z"
					transform="translate(3.75 2.5)"
				/>
				<path
					d="M20.313 17.188v4.687H3.125v-4.688H0v6.25C0 24.302.7 25 1.563 25h20.312c.864 0 1.563-.698 1.563-1.563v-6.25h-3.125z"
					transform="translate(3.75 2.5)"
				/>
			</g>
		</g>
	</svg>
);
