import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Auth from 'src/utilities/auth';
import { internalCostCalculator } from 'src/utilities/misc';
import { getCostMarkup } from 'src/domains/checkout/utilities/checkout';
import * as services from 'src/services';
import { Iconof } from '@upsiide/ui-components';
import { Tooltip } from 'react-tippy';
import useOutsideClick from 'src/hooks/useOutsideClick';

import './styles.scss';

const className = 'dig_pricing';
const el = (name, mod) => cn(className, name, mod);

const TooltipHeader = () => (
	<div className={el('dig_price_header')}>
		<Iconof icon="info_outined" />
		This is for <strong>Dig Staff</strong> view only
	</div>
);

const TooltipContent = ({ priceInCents, sampleSize, studyId, countryLanguageId }) => {
	const [show, setShow] = useState(false);
	const [loadingIRPrice, setLoadingIRPrice] = useState(true);
	const [irPrice, setIRPrice] = useState({
		external: priceInCents,
		internal: priceInCents * internalCostCalculator,
	});
	const ref = useRef(null);

	useOutsideClick(ref, () => {
		setTimeout(() => {
			setShow(false);
		}, 500);
	});

	const fetchPricing = useCallback(async () => {
		await services.studySampleService
			.getMarketplaceAudiencePrice(studyId, {
				incidenceRate: 20,
				currency: 'usd',
				countryLanguageId,
			})
			.then(({ data }) => {
				setLoadingIRPrice(false);
				setIRPrice({
					internal: (data?.priceInCents || 0) * internalCostCalculator,
					external: data?.priceInCents || 0,
				});
			});
	}, [studyId, countryLanguageId]);

	useEffect(() => {
		async function fetchData() {
			setLoadingIRPrice(true);
			fetchPricing();
		}
		if (studyId) {
			fetchData();
		}
	}, [fetchPricing, studyId]);

	const handleSetShow = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();
			setShow(!show);
		},
		[show],
	);

	if (show) {
		return (
			<div ref={ref}>
				<TooltipHeader />

				<div className={el('dig_price_container')}>
					<span className={el('dig_price_container_header')}>Estimated Dig pricing details</span>
					<div className={el('dig_price_row')}>
						<span className={el('dig_price_row_label')}>Internal Price</span>
						<span className={el('dig_price_row_value')}>
							{getCostMarkup((priceInCents * internalCostCalculator) / 100, null)}
						</span>
					</div>
					<div className={el('dig_price_row')}>
						<span className={el('dig_price_row_label')}>Internal Total</span>
						<span className={el('dig_price_row_value')}>
							{getCostMarkup((priceInCents * internalCostCalculator * sampleSize) / 100, null)}
						</span>
					</div>

					<div className={el('divider')} />

					<div className={el('dig_price_row')}>
						<span className={el('dig_price_row_label')}>List Price</span>
						<span className={el('dig_price_row_value')}>{getCostMarkup(priceInCents / 100, null)}</span>
					</div>
					<div className={el('dig_price_row')}>
						<span className={el('dig_price_row_label')}>List Total</span>
						<span className={el('dig_price_row_value')}>
							{getCostMarkup((priceInCents * sampleSize) / 100, null)}
						</span>
					</div>

					<div className={el('divider')} />

					{!loadingIRPrice ? (
						<span className={el('dig_price_info')}>
							If IR rate is lower than predicted (35%-100%) price will be increased:
							<ul>
								<li className={el('dig_price_info_item')}>
									{getCostMarkup(irPrice.external / 100, null)} list price
								</li>
								<li className={el('dig_price_info_item')}>
									{getCostMarkup(irPrice.internal / 100, null)} internal price
								</li>
							</ul>
						</span>
					) : null}
				</div>

				<button type="button" className={el('show_hide_button')} onClick={handleSetShow}>
					Hide
				</button>
			</div>
		);
	}

	return (
		<div>
			<TooltipHeader />

			<button type="button" className={el('show_hide_button')} onClick={handleSetShow}>
				Show
			</button>
		</div>
	);
};
TooltipContent.propTypes = {
	priceInCents: PropTypes.number,
	sampleSize: PropTypes.number,
	studyId: PropTypes.number,
	countryLanguageId: PropTypes.number,
};

const DigPricingTooltip = ({
	isLive,
	priceInCents,
	sampleSize,
	disableTooltip,
	studyId,
	countryLanguageId,
	possiblyEmployeeBilling,
}) => {
	const isAdminOrEmployee = Auth.isDigAdmin() || Auth.isDigEmployee();

	if (!possiblyEmployeeBilling) {
		return null;
	}

	return (
		<div>
			<Tooltip
				className={`${className}__tooltip`}
				animation="shift"
				animationFill={false}
				interactive
				trigger="click"
				position="bottom"
				theme="light dig-pricing-tooltip"
				disabled={disableTooltip || !isAdminOrEmployee}
				html={
					<TooltipContent
						priceInCents={priceInCents}
						sampleSize={sampleSize}
						studyId={studyId}
						countryLanguageId={countryLanguageId}
					/>
				}
			>
				{isLive ? (
					<div className={el('icon_wrapper', 'live')}>
						<Iconof className={el('icon', 'live')} icon="lock" />
						<span className={el('launched_by')}>Launched by Upsiide</span>
					</div>
				) : (
					<div className={el('icon_wrapper')}>
						<Iconof className={el('icon')} icon="lock" />
					</div>
				)}
			</Tooltip>
		</div>
	);
};

DigPricingTooltip.propTypes = {
	priceInCents: PropTypes.number,
	sampleSize: PropTypes.number,
	isLive: PropTypes.bool,
	disableTooltip: PropTypes.bool,
	studyId: PropTypes.number,
	countryLanguageId: PropTypes.number,
	possiblyEmployeeBilling: PropTypes.bool,
};

export default DigPricingTooltip;
