/* eslint-disable no-unreachable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { some } from 'lodash';
import computerImage from 'public/images/computer.svg';
import CONSTANTS from 'src/config/constants';
import Button from 'src/components/elements/Button';
import useIsMobile from '../../../hooks/useIsMobile';
import './styles.scss';

const className = 'mobile-lock';
const el = name => cn(className, name);

const MobileLock = ({ sendMobileBlockLink, displayRenderLock = true, allowPaths = [], children }) => {
	const isMobile = useIsMobile();

	const allowedPath = some(allowPaths, path => window.location.href.includes(path));

	const getLogoSrc = () => {
		if (window.innerWidth < CONSTANTS.screenSize.screenMdMin) {
			return '/public/images/signup/upsiide-logo-small.svg';
		}

		return '/public/images/signup/upsiide-logo.svg';
	};

	const handleBackToHomepage = () => window.location.replace('https://upsiide.com');

	if (!process.env.production && !process.env.beta) {
		return children;
	}

	if (isMobile && !allowedPath) {
		if (!displayRenderLock) {
			return null;
		}

		return (
			<div className={el('background')}>
				<div className={el('upsiide-logo-container')}>
					<img className={el('upsiide-logo')} src={getLogoSrc()} alt="Upsiide logo" />
				</div>
				<div className={el('children-container')}>
					<div className={el('card')}>
						<div className={el('card-container')}>
							<img className={el('computer')} src={computerImage} alt="computer" />

							<h3 className={el('title')}>Get a better view</h3>

							<p className={el('copy')}>Switch to a desktop for the optimal experience on Upsiide</p>

							<div className={el('actions')}>
								<Button
									label="Back to Homepage"
									onClick={() => handleBackToHomepage()}
									className={el('back-button')}
								/>
								{sendMobileBlockLink && (
									<a className={el('send-link')} href="#" onClick={() => sendMobileBlockLink()}>
										Send me a link
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className={el('dig-logo-container')}>
					<div className={el('dig-text')}>Powered by</div>
					<img className={el('dig-logo')} src="/public/images/signup/dig-logo.svg" alt="Dig Logo" />
				</div>
			</div>
		);
	}

	return children;
};

MobileLock.propTypes = {
	sendMobileBlockLink: PropTypes.func,
	allowPaths: PropTypes.array,
	displayRenderLock: PropTypes.bool,
	children: PropTypes.any,
};

export default MobileLock;
