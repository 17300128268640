import * as actions from '../actions';

const INITIAL_STATE = {
	planFrequency: process.env.professionalYearlyHandle,
	productHandle: 'professional',
	pricePointHandle: process.env.professionalYearlyHandle,
	pricePointsData: {},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SAVE_PLAN_DETAILS:
			return { ...state, ...action.payload };

		default:
			return state;
	}
};
