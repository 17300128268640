import React from 'react';

import './styles.scss';

export default ({ ...props }) => (
	<svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || '24'}
		height={props.heiht || '24'}
		viewBox="0 0 24 24"
	>
		<g fill="none" fillRule="evenodd">
			<path
				fill={props.color || '#3B3B3B'}
				d="M9.093 3.027c-.266 0-.533.106-.72.293l-8 8.027c-.4.4-.4 1.04 0 1.44l8 8.026c.374.374 1.067.374 1.44 0 .4-.4.4-1.04 0-1.44L3.52 13.08h19.413c.56 0 1.014-.453 1.014-1.013 0-.56-.454-1.014-1.014-1.014H3.52L9.787 4.76c.4-.4.4-1.04 0-1.44-.16-.187-.427-.293-.694-.293z"
			/>
		</g>
	</svg>
);
