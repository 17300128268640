import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import cn from 'src/utilities/bem-cn';
import GirlOnBicycle from 'public/images/girl-bicycle.svg';
import * as actions from 'src/domains/auth/actions';
import './styles.scss';

const className = 'fallback-component';
const el = (name, modifier) => cn(className, name, modifier);

class FallbackComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
		this.studiesPath = '/studies';
		this.supportPath = 'https://support.upsiide.com/';
		this.statusPath = 'https://status.upsiide.com/';
		this.altText = 'girl on a bicycle';
		this.oopsText = 'Oops, something went wrong…';
		this.cantText = 'Something unexpected happened here. ';
		this.backText = 'Back to studies list';
		this.supportText = 'Contact Support';
		this.statusText = 'Check Status';
	}

	componentDidCatch = (error, errorInfo) => {
		this.setState({ error, errorInfo });

		if (process.env.logToSentry) {
			Sentry.withScope(scope => {
				Object.keys(errorInfo).forEach(key => {
					scope.setExtra(key, errorInfo[key]);
				});
				Sentry.captureException(error);
			});
		}
	};

	contactSupport = () => {
		const { goToSupport } = this.props;
		goToSupport();
	};

	render() {
		const { errorInfo } = this.state;
		const { children } = this.props;
		if (errorInfo && !process.env.production) {
			return (
				<div className={className}>
					<div className={el('content')}>
						<img className={el('image')} src={GirlOnBicycle} alt={this.altText} />
						<div className={el('title')}>{this.oopsText}</div>
						<div className={el('subtitle')}>{this.cantText}</div>
						<a className={el('studies-button')} href={this.studiesPath} target="_self">
							{this.backText}
						</a>
					</div>
					<div className={el('footer')}>
						<button className={el('support-button')} type="button" onClick={this.contactSupport}>
							{this.supportText}
						</button>
						<a
							className={el('status-button')}
							href={this.statusPath}
							target="_blank"
							rel="noopener noreferrer"
						>
							{this.statusText}
						</a>
					</div>
				</div>
			);
		}
		return children;
	}
}

FallbackComponent.propTypes = {
	children: PropTypes.any,
	goToSupport: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
	goToSupport: () => dispatch(actions.goToSupport()),
});

export default connect(null, mapDispatchToProps)(FallbackComponent);
