/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import MonadicSplitIcon from 'src/components/icons/MonadicSplit';
import { Iconof } from '@upsiide/ui-components';
import { Tooltip } from 'react-tippy';
import './styles.scss';

const className = 'question-type-icon';
const el = (name, mod) => cn(className, name, mod);

const QuestionTypeIcon = ({ questionType, sectionType, customClasses = '', size = 'default', tooltip = true }) => {
	const sectionTypeTooltip = useMemo(() => {
		if (sectionType === 'swipe') return 'Idea Screen';
		if (sectionType === 'monadic_split') return 'Idea Split';
		if (sectionType === 'link_routing') return 'URL Redirect';
		return 'Statement';
	}, [sectionType]);

	const renderTooltip = useCallback(() => {
		const questionTypeTooltip =
			questionType === 'open-ended'
				? 'Open-ended question'
				: questionType === 'single-select' || questionType === 'multi-select'
				? 'Select question'
				: questionType === 'grid'
				? 'Grid question'
				: questionType === 'heatmap'
				? 'Heatmap question'
				: questionType === 'emoji'
				? 'Emoji question'
				: questionType === 'heatmap'
				? 'Heatmap'
				: questionType === 'ranked'
				? 'Rank question'
				: null;

		return <div>{questionTypeTooltip || sectionTypeTooltip}</div>;
	}, [questionType, sectionTypeTooltip]);

	return (
		<div className={customClasses ? `${className} ${customClasses}` : className}>
			<Tooltip
				id={`blueprint-preview-icon__tooltip-${Math.ceil(Math.random() * 1000)}`}
				className={el('section-type-tooltip')}
				animation="shift"
				animationFill={false}
				trigger="mouseenter"
				position="top"
				theme="basic-text-tooltip"
				arrow={false}
				html={renderTooltip()}
				delay={300}
				popperOptions={{
					modifiers: {
						preventOverflow: {
							enabled: false,
							boundariesElement: 'window',
							escapeWithReference: true,
						},
					},
				}}
				disabled={!tooltip}
			>
				{(questionType === 'multi-select' || questionType === 'single-select') && (
					<Iconof className={el('multi-select-icon')} icon="checkmark_square" size={size} />
				)}
				{questionType === 'open-ended' && (
					<Iconof className={el('open-ended-icon')} icon="comment_text" size={size} />
				)}
				{sectionType === 'statement' && <Iconof className={el('statement-icon')} icon="quotes" size={size} />}
				{sectionType === 'link_routing' && (
					<Iconof className={el('link-routing-icon')} icon="route_dashed" size={size} />
				)}
				{sectionType === 'monadic_split' && <MonadicSplitIcon className={el('splits-icon')} />}
				{sectionType === 'swipe' && (
					<Iconof className={el('swipe-icon')} icon="gesture_swipe_left_right" size={size} />
				)}
				{questionType === 'grid' && <Iconof className={el('grid-report-icon')} icon="grid" size={size} />}
				{questionType === 'emoji' && <Iconof className={el('emoji-select-icon')} icon="smile" size={size} />}
				{questionType === 'library' && <Iconof className={el('library-icon')} icon="book" size={size} />}
				{questionType === 'heatmap' && <Iconof className={el('heatmap-icon')} icon="image" size={size} />}
				{questionType === 'ranked' && <Iconof className={el('ranked-icon')} icon="chart_bar_3" size={size} />}
			</Tooltip>
		</div>
	);
};

QuestionTypeIcon.propTypes = {
	questionType: PropTypes.string,
	sectionType: PropTypes.string,
	customClasses: PropTypes.string,
	size: PropTypes.string,
	tooltip: PropTypes.bool,
};

export default QuestionTypeIcon;
