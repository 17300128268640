import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import { Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import ProgressBar from 'src/domains/manage-study/components/audience/ProgressBar';
import './styles.scss';

const className = 'interlock-quotas-progress-cel';
const el = (name, mod) => cn(className, name, mod);

const InterlockQuotasProgressCel = memo(
	({ sample, percent, respondents = 0, disabled = false, audiencePercentage = 0 }) => {
		const isValid = useMemo(() => {
			if (disabled) return true;
			return percent > audiencePercentage - 20;
		}, [audiencePercentage, disabled, percent]);

		const getClasses = () => {
			let classes = `${className}`;
			classes += !isValid ? ' error' : '';
			return classes;
		};

		const formatQuota = quotaNumber => {
			if (typeof quotaNumber !== 'number') {
				return quotaNumber;
			}
			return Math.floor(quotaNumber);
		};

		const renderIcon = () => (
			<Tooltip className={el('icon')} theme="dark-blured" position="top" html="Save to recalculate progress">
				<Iconof icon="info_outined" />
			</Tooltip>
		);

		return (
			<div className={getClasses()}>
				<Tooltip
					className={el('icon')}
					theme="dark-blured"
					position="top"
					html={`${respondents} / ${sample} Respondents`}
				>
					<div className={`${el('content')} ${disabled ? 'disabled' : ''}`}>
						<div className={el('progress-bar')}>
							<ProgressBar
								percent={disabled ? 0 : percent}
								width={100}
								customClasses={isValid ? 'green' : 'red'}
							/>
						</div>
						<span className={el('quota')}>{formatQuota(disabled ? 0 : percent) || 0}%</span>
					</div>
				</Tooltip>
				{disabled ? renderIcon() : null}
			</div>
		);
	},
);

InterlockQuotasProgressCel.displayName = 'InterlockQuotasProgressCel';
InterlockQuotasProgressCel.propTypes = {
	percent: PropTypes.number,
	disabled: PropTypes.number,
	sample: PropTypes.number,
	respondents: PropTypes.number,
	audiencePercentage: PropTypes.number,
};

export default InterlockQuotasProgressCel;
