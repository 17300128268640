const prefix = 'account';

/* State setters */

export const SET_COMMON_ERROR = `${prefix}/SET_COMMON_ERROR`;
export const setCommonError = commonError => ({
	type: SET_COMMON_ERROR,
	payload: { commonError },
});

export const SET_ACCOUNT = `${prefix}/SET_ACCOUNT`;
export const setAccount = account => ({
	type: SET_ACCOUNT,
	payload: { account },
});

export const SET_ACCOUNT_UUID = `${prefix}/SET_ACCOUNT_UUID`;
export const setAccountUuid = accountUuid => ({
	type: SET_ACCOUNT_UUID,
	payload: { accountUuid },
});

export const SET_ACCOUNT_SUBSCRIPTION = `${prefix}/SET_ACCOUNT_SUBSCRIPTION`;
export const setAccountSubscription = subscription => ({
	type: SET_ACCOUNT_SUBSCRIPTION,
	payload: { subscription },
});

export const SET_ACCOUNT_INVOICES = `${prefix}/SET_ACCOUNT_INVOICES`;
export const setAccountInvoices = invoices => ({
	type: SET_ACCOUNT_INVOICES,
	payload: { invoices },
});

export const SET_UPDATE_ACCOUNT_MODAL = `${prefix}/SET_UPDATE_ACCOUNT_MODAL`;
export const setUpdateAccountModal = updateAccountModal => ({
	type: SET_UPDATE_ACCOUNT_MODAL,
	payload: { updateAccountModal },
});
export const SET_UPDATE_CREDIT_CARD_MODAL = `${prefix}/SET_UPDATE_CREDIT_CARD_MODAL`;
export const setUpdateCreditCardModal = updateCreditCardModal => ({
	type: SET_UPDATE_CREDIT_CARD_MODAL,
	payload: { updateCreditCardModal },
});

export const SET_CREATE_ACCOUNT_MODAL = `${prefix}/SET_CREATE_ACCOUNT_MODAL`;
export const setCreateAccountModal = createAccountModal => ({
	type: SET_CREATE_ACCOUNT_MODAL,
	payload: { createAccountModal },
});

export const SET_USERS = `${prefix}/SET_USERS`;
export const setUsers = users => ({ type: SET_USERS, payload: { users } });

export const SET_ROLES = `${prefix}/SET_ROLES`;
export const setRoles = roles => ({ type: SET_ROLES, payload: { roles } });

export const SET_CLIENT_ID = `${prefix}/SET_CLIENT_ID`;
export const setClientId = (clientId, clientUuid, fetchUsersAndRoles = true) => ({
	type: SET_CLIENT_ID,
	payload: { clientId, clientUuid, fetchUsersAndRoles },
});

export const SET_DELETE_USER_CONFIRMATION_MODAL = `${prefix}/SET_DELETE_USER_CONFIRMATION_MODAL`;
export const setDeleteUserConfirmationModal = confirmationModal => ({
	type: SET_DELETE_USER_CONFIRMATION_MODAL,
	payload: { confirmationModal },
});

export const SET_LOADING = `${prefix}/SET_LOADING`;
export const setLoading = isLoading => ({
	type: SET_LOADING,
	payload: { loading: isLoading },
});

export const SET_ERROR = `${prefix}/SET_ERROR`;
export const setError = error => ({ type: SET_ERROR, payload: { error } });

/* Account Modal */

export const SET_ACCOUNT_MODAL_STATE = `${prefix}/SET_ACCOUNT_MODAL_STATE`;
export const setAccountModalState = accountModalState => ({
	type: SET_ACCOUNT_MODAL_STATE,
	payload: { accountModalState },
});

/* Events */

export const FETCH_ACCOUNT = `${prefix}/FETCH_ACCOUNT`;
export const fetchAccount = (uuid, fetchUsersAndRoles = true) => ({
	type: FETCH_ACCOUNT,
	payload: { uuid, fetchUsersAndRoles },
});

export const FETCH_ACCOUNT_SUBSCRIPTION = `${prefix}/FETCH_ACCOUNT_SUBSCRIPTION`;
export const fetchAccountSubscription = uuid => ({
	type: FETCH_ACCOUNT_SUBSCRIPTION,
	payload: { uuid },
});

export const FETCH_ACCOUNT_INVOICES = `${prefix}/FETCH_ACCOUNT_INVOICES`;
export const fetchAccountInvoices = uuid => ({
	type: FETCH_ACCOUNT_INVOICES,
	payload: { uuid },
});

export const CREATE_ACCOUNT = `${prefix}/CREATE_ACCOUNT`;
export const createAccount = (uuid, data) => ({
	type: CREATE_ACCOUNT,
	payload: { uuid, data },
});

export const UPDATE_ACCOUNT = `${prefix}/UPDATE_ACCOUNT`;
export const updateAccount = (uuid, data) => ({
	type: UPDATE_ACCOUNT,
	payload: { uuid, data },
});

export const UPDATE_DEFAULT_CREDIT_CARD = `${prefix}/UPDATE_DEFAULT_CREDIT_CARD`;
export const updateDefaultCreditCard = (accountUuid, cardId) => ({
	type: UPDATE_DEFAULT_CREDIT_CARD,
	payload: { accountUuid, cardId },
});

export const UPDATE_ACCOUNT_CREDIT_CARD = `${prefix}/UPDATE_ACCOUNT_CREDIT_CARD`;
export const updateCreditCard = (accountUuid, stripeToken) => ({
	type: UPDATE_ACCOUNT_CREDIT_CARD,
	payload: { accountUuid, stripeToken },
});

export const ADD_CHARGIFY_CREDIT_CARD = `${prefix}/ADD_CHARGIFY_CREDIT_CARD`;
export const addChargifyCreditCard = (chargifyJsToken, accountUuid) => ({
	type: ADD_CHARGIFY_CREDIT_CARD,
	payload: { chargifyJsToken, accountUuid },
});
export const REMOVE_CHARGIFY_CREDIT_CARD = `${prefix}/REMOVE_CHARGIFY_CREDIT_CARD`;
export const removeChargifyCreditCard = (cardId, accountUuid) => ({
	type: REMOVE_CHARGIFY_CREDIT_CARD,
	payload: { cardId, accountUuid },
});
export const UPDATE_DEFAULT_CHARGIFY_CREDIT_CARD = `${prefix}/UPDATE_DEFAULT_CHARGIFY_CREDIT_CARD`;
export const updateDefaultChargifyCreditCard = (cardId, accountUuid) => ({
	type: UPDATE_DEFAULT_CHARGIFY_CREDIT_CARD,
	payload: { cardId, accountUuid },
});

export const DELETE_CREDIT_CARD = `${prefix}/DELETE_CREDIT_CARD`;
export const deleteCreditCard = creditCardId => ({
	type: DELETE_CREDIT_CARD,
	payload: { creditCardId },
});

export const FETCH_ROLES = `${prefix}/FETCH_ROLES`;
export const fetchRoles = () => ({ type: FETCH_ROLES });

export const FETCH_USERS = `${prefix}/FETCH_USERS`;
export const fetchUsers = (search = '', refetch = false) => ({
	type: FETCH_USERS,
	payload: { search, refetch },
});

export const ADD_USER = `${prefix}/ADD_USER`;
export const addUser = data => ({
	type: ADD_USER,
	payload: { data },
});

export const GIVE_ROLE_NEW = `${prefix}/GIVE_ROLE_NEW`;
export const giveRoleForNoAccess = (clientId, emails, role) => ({
	type: GIVE_ROLE_NEW,
	payload: { clientId, emails, role },
});

export const CREATE_CLIENT = `${prefix}/CREATE_CLIENT`;
export const createClient = (client, uuid) => ({
	type: CREATE_CLIENT,
	payload: { client, uuid },
});

// export const DELETE_CLIENT = `${prefix}/DELETE_CLIENT`;
// export const deleteClient = (clientUuid, uuid) => ({ type: DELETE_CLIENT, payload: { clientUuid, uuid } });

export const CHANGE_USER_ROLE = `${prefix}/CHANGE_USER_ROLE`;
export const changeUserRole = (clientId, userId, roleId) => ({
	type: CHANGE_USER_ROLE,
	payload: { clientId, userId, roleId },
});

export const DELETE_USER = `${prefix}/DELETE_USER`;
export const deleteUser = (userUuid, clientUuids) => ({
	type: DELETE_USER,
	payload: { userUuid, clientUuids },
});

export const RESEND_INVITATION = `${prefix}/RESEND_INVITATION`;
export const resendInvitation = (email, clientUuid, accountName) => ({
	type: RESEND_INVITATION,
	payload: { email, clientUuid, accountName },
});

// Account Usage
export const FETCH_ACCOUNT_USAGE = `${prefix}/FETCH_ACCOUNT_USAGE`;
export const fetchAccountUsage = accountUsage => ({
	type: FETCH_ACCOUNT_USAGE,
	payload: { accountUsage },
});

export const SET_ACCOUNT_USAGE = `${prefix}/SET_ACCOUNT_USAGE`;
export const setAccountUsage = accountUsage => ({
	type: SET_ACCOUNT_USAGE,
	payload: { accountUsage },
});

export const SET_ACCOUNT_USAGE_USER_COUNT = `${prefix}/SET_ACCOUNT_USAGE_USER_COUNT`;
export const setAccountUsageUserCount = count => ({
	type: SET_ACCOUNT_USAGE_USER_COUNT,
	payload: { count },
});

export const FETCH_ACCOUNTS = `${prefix}/FETCH_ACCOUNTS`;
export const fetchAccounts = () => ({
	type: FETCH_ACCOUNTS,
});

export const SET_ACCOUNTS = `${prefix}/SET_ACCOUNTS`;
export const setAccounts = accounts => ({
	type: SET_ACCOUNTS,
	payload: { accounts },
});

export const CHANGE_USER_SORTING = `${prefix}/CHANGE_USER_SORTING`;
export const changeUserSorting = sorting => ({
	type: CHANGE_USER_SORTING,
	payload: { sorting },
});

export const CHANGE_SPACE_SORTING = `${prefix}/CHANGE_SPACE_SORTING`;
export const changeSpaceSorting = sorting => ({
	type: CHANGE_SPACE_SORTING,
	payload: { sorting },
});

export const REMOVE_ACCESS = `${prefix}/REMOVE_ACCESS`;
export const removeAccess = (clientUuid, userUuid) => ({
	type: REMOVE_ACCESS,
	payload: { clientUuid, userUuid },
});

export const SET_INVITATION_STATUS = `${prefix}/SET_INVITATION_STATUS`;
export const setInvitationStatus = (email, status) => ({ type: SET_INVITATION_STATUS, payload: { email, status } });

/* 
	Chargify API specific actions: 
	billing/subscription
	billing/invoice
	billing/usage
*/
export const FETCH_CHARGIFY_SUBSCRIPTIONS = `${prefix}/FETCH_CHARGIFY_SUBSCRIPTIONS`;
export const fetchChargifySubscriptions = () => ({
	type: FETCH_CHARGIFY_SUBSCRIPTIONS,
});

export const SET_CHARGIFY_SUBSCRIPTIONS = `${prefix}/SET_CHARGIFY_SUBSCRIPTIONS`;
export const setChargifySubscriptions = chargifySubscriptions => ({
	type: SET_CHARGIFY_SUBSCRIPTIONS,
	payload: chargifySubscriptions,
});

export const ACTIVATE_CHARGIFY_SUBSCRIPTION = `${prefix}/ACTIVATE_CHARGIFY_SUBSCRIPTION`;
export const activateChargifySubscription = () => ({
	type: ACTIVATE_CHARGIFY_SUBSCRIPTION,
});

export const CANCEL_CHARGIFY_SUBSCRIPTION = `${prefix}/CANCEL_CHARGIFY_SUBSCRIPTION`;
export const cancelChargifySubscription = () => ({
	type: CANCEL_CHARGIFY_SUBSCRIPTION,
});

export const REACTIVATE_CHARGIFY_SUBSCRIPTION = `${prefix}/REACTIVATE_CHARGIFY_SUBSCRIPTION`;
export const reactivateChargifySubscription = () => ({
	type: REACTIVATE_CHARGIFY_SUBSCRIPTION,
});

export const SET_MANAGE_SUBSCRIPTION_MODAL = `${prefix}/SET_MANAGE_SUBSCRIPTION_MODAL`;
export const setManageSubscriptionModal = manageSubscriptionModal => ({
	type: SET_MANAGE_SUBSCRIPTION_MODAL,
	payload: { manageSubscriptionModal },
});

export const FETCH_CHARGIFY_INVOICES = `${prefix}/FETCH_CHARGIFY_INVOICES`;
export const fetchChargifyInvoices = year => ({
	type: FETCH_CHARGIFY_INVOICES,
	payload: { year },
});

export const SET_CHARGIFY_INVOICES = `${prefix}/SET_CHARGIFY_INVOICES`;
export const setChargifyInvoices = chargifyInvoices => ({
	type: SET_CHARGIFY_INVOICES,
	payload: chargifyInvoices,
});

export const FETCH_CHARGIFY_USAGE = `${prefix}/FETCH_CHARGIFY_USAGE`;
export const fetchChargifyUsage = () => ({
	type: FETCH_CHARGIFY_USAGE,
});

export const SET_CHARGIFY_USAGE = `${prefix}/SET_CHARGIFY_USAGE`;
export const setChargifyUsage = chargifyUsage => ({
	type: SET_CHARGIFY_USAGE,
	payload: chargifyUsage,
});

export const FETCH_ACCOUNT_AUDIENCES = `${prefix}/FETCH_ACCOUNT_AUDIENCES`;
export const fetchAccountAudiences = () => ({
	type: FETCH_ACCOUNT_AUDIENCES,
});

export const SET_ACCOUNT_AUDIENCES = `${prefix}/SET_ACCOUNT_AUDIENCES`;
export const setAccountAudiences = accountAudiences => ({
	type: SET_ACCOUNT_AUDIENCES,
	payload: accountAudiences,
});

export const FETCH_ACCOUNT_BILLING_PERIODS = `${prefix}/FETCH_ACCOUNT_BILLING_PERIODS`;
export const fetchAccountBillingPeriods = () => ({
	type: FETCH_ACCOUNT_BILLING_PERIODS,
});

export const SET_ACCOUNT_BILLING_PERIODS = `${prefix}/SET_ACCOUNT_BILLING_PERIODS`;
export const setAccountBillingPeriods = accountBillingPeriods => ({
	type: SET_ACCOUNT_BILLING_PERIODS,
	payload: accountBillingPeriods,
});

export const SEND_MOBILE_BLOCK_EMAIL = `${prefix}/SEND_MOBILE_BLOCK_EMAIL`;
export const sendMobileBlockLink = () => ({
	type: SEND_MOBILE_BLOCK_EMAIL,
});
