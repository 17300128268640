import React, { useCallback } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import LogicSelect from '../LogicSelect';
import { useLogicConditionContext } from '../../LogicConditionContext';

import '../../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

const LogicTopBottomSelect = ({ setParentRefZIndex, index: answersPositionIndex, placeholder }) => {
	const {
		setShowLoader,
		sections,
		saveData,
		logicItem,
		saveLogic,
		isAudienceLogic,
		audience,
		selectedItem,
		topBottomOptions,
		answerOptions,
	} = useLogicConditionContext();

	const answerValue = logicItem?.triggerOptions[answersPositionIndex]?.id || logicItem?.triggerOptions?.[0]?.id;
	const matchedTriggerItem = logicItem?.triggerOptions?.find(item => item.id === answerValue);

	const topBottomSelectOnChange = useCallback(
		position => {
			setShowLoader(true);

			// // update trigger options with new data
			let foundOptionToUpdate = false;
			const itemData = answerOptions?.find(answer => answer.id === answerValue);
			const savedTriggerOptions = logicItem?.triggerOptions?.map(item => {
				if (item.id === answerValue) {
					foundOptionToUpdate = true;
				}

				return {
					...item,
					...{ position: position ? 'bottom' : 'top' },
				};
			});

			// // Nothing to update, must be adding a new option
			if (!foundOptionToUpdate) {
				savedTriggerOptions.push({
					...itemData,
					...{ position: position ? 'bottom' : 'top' },
				});
			}

			const saveDataClone = {
				...saveData,
				logic: saveData?.logic?.map((item, itemIndex) => {
					if (item?.id === logicItem?.id) {
						return {
							...item,
							id: logicItem.id,
							triggerItemId: selectedItem?.data?.itemId,
							triggerOptions: savedTriggerOptions,
							...(isAudienceLogic
								? {
										audienceUuid: audience?.uuid,
										itemType: 'question',
								  }
								: {
										triggerType:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].type,
										triggerSectionId:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].id,
								  }),
						};
					}

					return item;
				}),
			};

			saveLogic(saveDataClone);
		},
		[
			setShowLoader,
			logicItem?.triggerOptions,
			logicItem.id,
			saveData,
			saveLogic,
			selectedItem,
			sections.content,
			isAudienceLogic,
			audience?.uuid,
			answerOptions,
			answerValue,
		],
	);

	return (
		<LogicSelect
			columnWidth={2}
			selectClassName="top-bottom"
			options={topBottomOptions}
			onChange={topBottomSelectOnChange}
			menuMinWidth={40}
			// eslint-disable-next-line no-nested-ternary
			value={matchedTriggerItem?.position ? (matchedTriggerItem?.position === 'top' ? 0 : 1) : null}
			placeholder={placeholder}
			setParentRefZIndex={setParentRefZIndex}
		/>
	);
};

LogicTopBottomSelect.propTypes = {
	setParentRefZIndex: PropTypes.any,
	index: PropTypes.any,
	placeholder: PropTypes.string,
};

export default LogicTopBottomSelect;
