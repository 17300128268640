const getAllQuestions = sectionsWithQuestionNumbers => {
	const questionNumbers = {};
	// eslint-disable-next-line no-unused-expressions
	sectionsWithQuestionNumbers?.content && sectionsWithQuestionNumbers?.content.length
		? sectionsWithQuestionNumbers?.content
				?.filter(s => s?.type === 'questions')
				?.map(s => s?.questions)
				?.flat(Infinity)
				?.forEach(q => {
					questionNumbers[q.id] = q.studyQuestionNumber;
				})
		: [];
	return questionNumbers;
};

export const formatOptions = (options, sectionsWithQuestionNumbers) => {
	const allQuestions = getAllQuestions(sectionsWithQuestionNumbers);

	return options.map(option => ({
		id: option.id,
		label: option.label,
		...(option.maskedQuestionId
			? {
					maskedParameters: {
						maskedQuestionId: option.maskedQuestionId,
						logic: option.logic,
						questionNumber: allQuestions[option.maskedQuestionId],
					},
			  }
			: {}),
	}));
};
