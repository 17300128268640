import React from 'react';

import './styles.scss';

const MaskedErrorSingle = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.7489 16.8015C19.7141 15.4322 21 13.1557 21 10.5789C21 6.39321 17.6068 3 13.4211 3C10.8443 3 8.56783 4.28595 7.19849 6.25111C8.42553 5.3961 9.91738 4.89474 11.5263 4.89474C15.7121 4.89474 19.1053 8.28795 19.1053 12.4737C19.1053 14.0826 18.6039 15.5745 17.7489 16.8015Z"
			fill="#FF3C41"
		/>
		<circle cx="10.5" cy="13.5" r="7.5" fill="#FF3C41" />
		<path
			d="M13.5096 14.8488C12.8312 14.8488 12.2675 15.3368 12.086 15.9873H10.8917V11.9925V11.1386H10.0892H8.8949C8.72293 10.4779 8.14968 10.0001 7.47134 10.0001C6.65924 9.98994 6 10.7015 6 11.5655C6 12.4296 6.65924 13.1411 7.48089 13.1411C8.15924 13.1411 8.72293 12.6532 8.90446 12.0026H10.0987V16.0077V16.8615H10.9013H12.0955C12.2675 17.5222 12.8408 18 13.5191 18C14.3312 18 15 17.2986 15 16.4244C14.9809 15.5604 14.3217 14.8488 13.5096 14.8488Z"
			fill="white"
		/>
	</svg>
);

export default MaskedErrorSingle;
