import React from 'react';

import './styles.scss';

export default ({ ...props }) => (
	<svg {...props} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.0016 14.94C13.915 14.7974 12.904 14.3062 12.1206 13.54L4.45801 5.88002C3.89575 5.31725 3.13294 5.00072 2.33729 5.00002H1.497C1.22076 5.00002 0.996826 4.77616 0.996826 4.50002V3.50002C0.996826 3.22388 1.22076 3.00002 1.497 3.00002H2.33729C3.66473 2.99765 4.93867 3.52288 5.8785 4.46002L8.99957 7.59002L12.1206 4.46002C12.904 3.69388 13.915 3.2026 15.0016 3.06002V1.00002C15.0004 0.798166 15.1208 0.615398 15.3067 0.536675C15.4927 0.457952 15.7077 0.498717 15.8519 0.640021L18.8529 3.64002C19.0448 3.83783 19.0448 4.15221 18.8529 4.35002L15.8519 7.35002C15.709 7.49008 15.4964 7.53151 15.3113 7.45534C15.1263 7.37917 15.0044 7.20006 15.0016 7.00002V5.08002C14.449 5.20361 13.9427 5.48096 13.5411 5.88002L10.41 9.00002L13.5411 12.12C13.9427 12.5191 14.449 12.7964 15.0016 12.92V11C15.0004 10.7982 15.1208 10.6154 15.3067 10.5367C15.4927 10.458 15.7077 10.4987 15.8519 10.64L18.8529 13.64C19.0448 13.8378 19.0448 14.1522 18.8529 14.35L15.8519 17.35C15.709 17.4901 15.4964 17.5315 15.3113 17.4553C15.1263 17.3792 15.0044 17.2001 15.0016 17V14.94ZM5.8785 13.54L7.76914 11.64L6.35866 10.23L4.45801 12.12C3.89575 12.6828 3.13294 12.9993 2.33729 13H1.497C1.22076 13 0.996826 13.2239 0.996826 13.5V14.5C0.996826 14.7762 1.22076 15 1.497 15H2.33729C3.66473 15.0024 4.93867 14.4772 5.8785 13.54Z"
			fill="#666666"
		/>
	</svg>
);
