import React, { useCallback, useContext, useState, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Iconof, Show, TextBlockPlaceholder } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import studyService from 'src/services/study.service';
import parse from 'html-react-parser';
import { useIsPublic } from 'src/hooks';
import useIsMobile from 'src/hooks/useIsMobile';
import auth from 'src/utilities/auth';
import { cleanHtmlString } from 'src/utilities/misc';
import { Table, TableHead, TableBody, TableRow, TableCell } from 'src/components/shared/Table';
import QuestionTypeIcon from 'src/components/shared/QuestionTypeIcon';
import SelectSpace from 'src/domains/all-studies/components/SelectSpace';
import { WrapperComponent } from 'src/components/shared/SearchResults';
import SearchResultsOrder from '../shared/SearchResultsOrder';
import { urlParams, tableClassName, SearchHeader, FilterDropdownButton, FilteredBy } from '../index';
import SearchResultsContext from '../context';
import { useFetch } from '../hooks';
import StudyTagsAndTemplates from '../shared/StudyTagsAndTemplates';
import { upsertStudy } from '../../../../domains/all-studies/actions';
import { useSearchHistory } from '../../SmartSearch/hooks';
import { FilterDropdown } from '../../FilterDropdown';
import { Highlighted } from '../../SmartSearch';
import RevisedAt from '../shared/RevisedAt';
import LineClampTooltip from '../../LineClampTooltip';
import EmptySearchResults from '../shared/EmptySearchResults';
import { simplePipedQuestionLabel } from '../../../inputs/SimpleRichText/QuillMentionUtil';
import StudyImage from '../shared/StudyImage';
import { StudyLink } from '../Studies/index';

import './styles.scss';

const LoadingComponent = ({ limit }) => {
	const rows = [];

	for (let i = 0; i < limit; i += 1) {
		rows.push(
			<TableRow key={`key-${i}`}>
				<TableCell>
					<TextBlockPlaceholder color="#F4F4F4" rows={2} />
				</TableCell>
				<TableCell>
					<TextBlockPlaceholder color="#F4F4F4" rows={1} />
				</TableCell>
				<Show above={960}>
					<TableCell>
						<TextBlockPlaceholder color="#F4F4F4" rows={1} />
					</TableCell>
				</Show>
			</TableRow>,
		);
	}
	return rows;
};
LoadingComponent.propTypes = {
	limit: PropTypes.number,
};

const QuestionLink = ({ item, linkUrl, handleOnClick, searchString }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const isMobile = useIsMobile();

	if (isMobile) {
		return (
			<span className={el('study-name')}>
				<Highlighted
					text={item?.question?.label}
					piped={item?.question?.piped}
					highlight={searchString}
					highlightClassName={el('highlighted-result')}
				/>
			</span>
		);
	}

	return (
		<Link
			to={linkUrl}
			className={el('study-name')}
			onClick={() => handleOnClick(item?.question?.id, item?.question?.studyTranslationId)}
		>
			<Highlighted
				text={item?.question?.label}
				piped={item?.question?.piped}
				highlight={searchString}
				highlightClassName={el('highlighted-result')}
			/>
		</Link>
	);
};
QuestionLink.propTypes = {
	item: PropTypes.object,
	linkUrl: PropTypes.string,
	handleOnClick: PropTypes.func,
	searchString: PropTypes.string,
};

const Questions = ({ limit = 20, pagination = false, hidden = false, wrapperStyle }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isMobile = useIsMobile();

	const paginationInputRef = useRef(null);
	const timeoutRef = useRef();

	const { setTotals } = useContext(SearchResultsContext);
	const searchHistory = useSearchHistory();

	const { isPublic } = useIsPublic();
	const [searchParams, setSearchParams] = useSearchParams();
	const searchString = searchParams.get(urlParams.query);

	const [questionData, setQuestionData] = useState(null);
	const [loading, setLoading] = useState(true);

	const questions = questionData?.items || [];

	// Pagination
	const currentPage = searchParams.get(urlParams.questionPage) || 1;
	const paginationData = questionData?.pagination || {};
	const paginationPages = paginationData?.pageCount;

	const orderBy = searchParams.get(urlParams.questionOrderBy) || 'revisedAt';
	const orderByDirection = searchParams.get(urlParams.questionOrderByDirection) || 'desc';

	const fetchQuestionsCallback = useCallback(async () => {
		setLoading(true);
		const offset = (currentPage - 1) * limit;

		let applyFilters = { filter: {} };

		// Filter dropdown filters (country, language, tags, templates)
		const filters = searchParams.get(urlParams.filters);
		const parsedFilters = JSON.parse(filters);
		const finalFilters = {};

		if (parsedFilters?.questionType) {
			finalFilters.questionType = parsedFilters.questionType;
		}
		if (parsedFilters?.language) {
			finalFilters.language = parsedFilters.language;
		}
		if (parsedFilters?.studyTag) {
			finalFilters.studyTag = parsedFilters.studyTag;
		}

		if (filters) {
			applyFilters = {
				...applyFilters,
				filter: {
					...applyFilters.filter,
					...finalFilters,
				},
			};
		}

		let serviceFunction = studyService.getStudies;
		let serviceFunctionProps = {
			limit,
			offset,
			orderBy,
			orderByDirection,
		};

		serviceFunction = studyService.searchStudies;

		applyFilters = {
			...applyFilters,
			search: searchString,
			type: 'question',
		};

		serviceFunctionProps = {
			...serviceFunctionProps,
			searchString: searchString || '',
		};

		serviceFunctionProps = {
			...serviceFunctionProps,
			filter: applyFilters,
			isPublic,
		};

		const response = await serviceFunction(serviceFunctionProps);

		// upsert data!
		const studyResponseData = response?.data?.items?.map(item => item?.study);
		dispatch(upsertStudy(studyResponseData));

		setTotals(prev => ({
			...prev,
			questions: response?.data?.pagination?.totalCount || 0,
		}));

		setQuestionData(response?.data);
		setLoading(false);
	}, [currentPage, dispatch, isPublic, limit, orderBy, orderByDirection, searchParams, searchString, setTotals]);

	const handlePrevPage = useCallback(() => {
		setSearchParams(params => {
			const current = Number(params.get(urlParams.questionPage)) || 1;
			const prevPage = current - 1;
			const newPage = Math.max(1, prevPage);

			params.set(urlParams.questionPage, newPage);
			paginationInputRef.current.value = newPage;
			return params;
		});
	}, [setSearchParams]);

	const handleNextPage = useCallback(() => {
		setSearchParams(params => {
			const current = Number(params.get(urlParams.questionPage)) || 1;
			const newPage = Math.min(paginationData.pageCount, current + 1);

			params.set(urlParams.questionPage, newPage);
			paginationInputRef.current.value = newPage;

			return params;
		});
	}, [paginationData.pageCount, setSearchParams]);

	const handlePaginationChange = e => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		let value = Number(e.target.value);
		if (!e.target.value || Number(e.target.value) < 1) {
			paginationInputRef.current.value = 1;
			value = 1;
		}
		if (Number(e.target.value) > paginationPages) {
			paginationInputRef.current.value = paginationPages;
			value = paginationPages;
		}

		timeoutRef.current = setTimeout(() => {
			setSearchParams(params => {
				params.set(urlParams.questionPage, value);
				return params;
			});
		}, 100);
	};

	const handleOnClick = useCallback(
		(question, studyTranslationId) => {
			searchHistory.addHistory({ objectId: question, type: 'question', studyTranslationId });
		},
		[searchHistory],
	);

	const handleChangeOrder = useCallback(
		(type, direction) => {
			setSearchParams(params => {
				params.set(urlParams.questionOrderBy, type);
				params.set(urlParams.questionOrderByDirection, direction);
				params.set(urlParams.questionPage, 1);

				return params;
			});
		},
		[setSearchParams],
	);

	const navigateToQuestion = useCallback(
		(item, linkUrl) => {
			if (!isMobile) {
				handleOnClick(item?.question?.id, item?.question?.studyTranslationId);
				navigate(linkUrl);
			}
		},
		[handleOnClick, isMobile, navigate],
	);

	const handleQuestionNavigation = useCallback(
		(e, item, linkUrl) => {
			if (
				typeof e.target.className?.includes === 'function' &&
				(e.target.className?.includes('table__cell') || e.target.className?.includes('table-cell-clickable'))
			) {
				e.preventDefault();
				e.stopPropagation();
				navigateToQuestion(item, linkUrl);
			}
		},
		[navigateToQuestion],
	);

	// searchParams Change refetch questions
	useFetch({ callback: fetchQuestionsCallback, type: 'questions' });

	if (hidden) {
		return null;
	}

	const cellWidths = {
		question: '45%',
		study: '37%',
		revised: '18%',
	};

	return (
		<>
			<Show below={961}>
				<SelectSpace />
			</Show>
			<WrapperComponent>
				<div className={el('results-table-wrapper', wrapperStyle)}>
					<div className={el('header')}>
						<SearchHeader />

						<div className={el('filters-container')}>
							<Show above={960}>
								<SelectSpace />
							</Show>
							<FilterDropdown ButtonComponent={FilterDropdownButton} />
						</div>
					</div>

					<FilteredBy />

					{!loading && !questions?.length ? (
						<EmptySearchResults />
					) : (
						<>
							<div className="table-overflow-wrapper questions">
								<Table customClass={`${className} questions-table`}>
									<TableHead>
										<TableRow>
											<TableCell header style={{ width: cellWidths.question }}>
												<div className={el('order-cell-wrapper')}>
													<SearchResultsOrder
														label="Question"
														isActive={orderBy === 'questionName'}
														currentOrderDirection={orderByDirection}
														type="questionName"
														handleChangeOrder={handleChangeOrder}
													/>
												</div>
											</TableCell>
											<TableCell header style={{ width: cellWidths.study }}>
												<div className={el('order-cell-wrapper')}>
													<SearchResultsOrder
														label="Study"
														isActive={orderBy === 'studyName'}
														currentOrderDirection={orderByDirection}
														type="studyName"
														handleChangeOrder={handleChangeOrder}
													/>
												</div>
											</TableCell>
											<Show above={960}>
												<TableCell header style={{ width: cellWidths.revised }}>
													<div className={el('order-cell-wrapper')}>
														<SearchResultsOrder
															label="Revised"
															isActive={orderBy === 'revisedAt'}
															currentOrderDirection={orderByDirection}
															type="revisedAt"
															handleChangeOrder={handleChangeOrder}
														/>
													</div>
												</TableCell>
											</Show>
										</TableRow>
									</TableHead>
									{loading ? (
										<TableBody>
											<LoadingComponent limit={limit} />
										</TableBody>
									) : (
										<TableBody>
											{questions?.map(item => {
												let tooltipHtml = parse(cleanHtmlString(item?.question?.label));
												const sectionTypeMap = {
													null: 'Audience Screening',
													questions: 'Questions Section',
													monadic_split: 'Idea Split',
												};
												const formattedSectionType =
													sectionTypeMap[item?.question?.sectionType] ||
													item?.question?.sectionType;
												if (item?.question?.piped) {
													tooltipHtml = parse(
														simplePipedQuestionLabel(
															cleanHtmlString(item?.question?.label),
															item?.question?.piped,
														),
													);
												}

												let linkUrl = `${isPublic ? '/public' : ''}/studies/${
													item?.study?.uuid || item?.study?.id
												}/${
													['active', 'closed', 'needs-review'].includes(item?.study?.status)
														? 'report'
														: 'create'
												}/sections/${item?.question?.sectionUuid}/questions/${
													item?.question?.questionUuid
												}`;

												if (!item?.question?.sectionUuid) {
													linkUrl = `${isPublic ? '/public' : ''}/studies/${
														item?.study?.uuid || item?.study?.id
													}/${
														['active', 'closed', 'needs-review'].includes(
															item?.study?.status,
														)
															? 'report/screening-questions'
															: 'audiences/questions'
													}`;
												}

												return (
													<TableRow
														customClass={el('row-with-link')}
														key={item?.question?.id}
														onClick={e => handleQuestionNavigation(e, item, linkUrl)}
													>
														<TableCell onClick={() => navigateToQuestion(item, linkUrl)}>
															<div className={el('question-title-wrapper')}>
																<QuestionTypeIcon
																	questionType={item?.question?.style}
																	size="small"
																/>
																<div className={el('question-description-wrapper')}>
																	<span className={el('question-type')}>
																		{formattedSectionType}
																	</span>

																	<LineClampTooltip tooltipHtml={tooltipHtml}>
																		<QuestionLink
																			item={item}
																			linkUrl={linkUrl}
																			handleOnClick={handleOnClick}
																			searchString={searchString}
																		/>
																	</LineClampTooltip>
																</div>
															</div>
														</TableCell>
														<TableCell customClass={el('study-details-table-cell')}>
															<div>
																<StudyImage
																	asset={item?.study?.asset}
																	emoji={item?.study?.emoji}
																/>
																<div className={el('study-details-info')}>
																	<LineClampTooltip tooltipHtml={item?.study?.name}>
																		<StudyLink
																			studyLink={`${
																				isPublic ? '/public' : ''
																			}/studies/${
																				item?.study?.uuid || item?.study?.id
																			}/${
																				[
																					'active',
																					'closed',
																					'needs-review',
																				].includes(item?.study?.status)
																					? 'report'
																					: 'create'
																			}/sections/${
																				item?.question?.sectionUuid
																			}/questions/${
																				item?.question?.questionUuid
																			}`}
																			study={item?.study}
																			handleOnClick={() =>
																				handleOnClick(
																					item?.question?.id,
																					item?.question?.studyTranslationId,
																				)
																			}
																		/>
																	</LineClampTooltip>
																	<StudyTagsAndTemplates
																		studyId={item?.study?.id}
																		canEdit={auth.userCan('study:update')}
																	/>
																</div>
															</div>
														</TableCell>
														<Show above={960}>
															<TableCell
																onClick={() => navigateToQuestion(item, linkUrl)}
															>
																<RevisedAt date={item?.study?.revisedAt} />
															</TableCell>
														</Show>
													</TableRow>
												);
											})}
										</TableBody>
									)}
								</Table>
							</div>

							{pagination ? (
								<div className={el('pagination')}>
									<div>
										<Iconof icon="chevron_left" onClick={handlePrevPage} />
									</div>
									<div className={el('pagination-pages')}>
										<span>Page</span>
										<input
											ref={paginationInputRef}
											className={el('pagination-current-page')}
											defaultValue={currentPage}
											type="number"
											max={paginationPages}
											min={1}
											onChange={handlePaginationChange}
										/>
										<span>of</span>
										<span>{paginationPages}</span>
									</div>
									<div>
										<Iconof icon="chevron_right" onClick={handleNextPage} />
									</div>
								</div>
							) : null}
						</>
					)}
				</div>
			</WrapperComponent>
		</>
	);
};

Questions.propTypes = {
	limit: PropTypes.number,
	pagination: PropTypes.bool,
	hidden: PropTypes.bool,
	wrapperStyle: PropTypes.string,
};

export default Questions;
