// Todo: create new Table ui-component
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'table';
const el = (name, mod) => cn(className, name, mod);

export const TableCell = ({ customClass, header = false, children, ...restProps }) => {
	if (header) {
		return (
			<th className={`${el('header')}${` ${customClass}` || ''}`} {...restProps}>
				{children}
			</th>
		);
	}
	return (
		<td className={`${el('cell')}${` ${customClass || ''}`}`} {...restProps}>
			{children}
		</td>
	);
};
TableCell.propTypes = {
	header: PropTypes.bool,
	children: PropTypes.any,
	customClass: PropTypes.string,
};

export const TableRow = ({ customClass, children, ...rest }) => (
	<tr className={`${el('row')}${` ${customClass || ''}`}`} {...rest}>
		{children}
	</tr>
);
TableRow.propTypes = {
	children: PropTypes.any,
	onClick: PropTypes.func,
	customClass: PropTypes.string,
};

export const TableBody = ({ customClass, children }) => (
	<thead className={`${el('body')}${` ${customClass || ''}`}`}>{children}</thead>
);
TableBody.propTypes = {
	children: PropTypes.any,
	customClass: PropTypes.string,
};

export const TableHead = ({ customClass, children }) => (
	<thead className={`${el('head')}${` ${customClass || ''}`}`}>{children}</thead>
);
TableHead.propTypes = {
	children: PropTypes.any,
	customClass: PropTypes.string,
};

export const Table = ({ customClass, children }) => (
	<table className={`${el('root')}${` ${customClass || ''}`}`}>{children}</table>
);
Table.propTypes = {
	children: PropTypes.any,
	customClass: PropTypes.string,
};
