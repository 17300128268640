import React from 'react';
import PropTypes from 'prop-types';

export const ModalContext = React.createContext(null);

const ModalProvider = ({ children }) => {
	const modalContainerRef = React.useRef();

	return (
		<ModalContext.Provider value={modalContainerRef}>
			<div ref={modalContainerRef} />
			{children}
		</ModalContext.Provider>
	);
};

export const useModalContext = () => React.useContext(ModalContext);

ModalProvider.propTypes = {
	children: PropTypes.any,
};
export default ModalProvider;
