import React from 'react';

import './styles.scss';

export default ({ ...props }) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<g fill="none" fillRule="evenodd">
			<path d="M0 0H24V24H0z" />
			<path
				fill={props.color || '#FFC72F'}
				d="M23.496 18.969L13.729 2.052c-.357-.619-1.017-1-1.732-1-.714 0-1.375.381-1.732 1L.428 19.091c-.357.618-.357 1.38 0 2 .357.618 1.018 1 1.732 1h19.68c1.105 0 2-.896 2-2 0-.416-.127-.802-.344-1.122zM10.882 8.352c0-.617.5-1.118 1.118-1.118.617 0 1.118.5 1.118 1.118v6.666c0 .617-.5 1.118-1.118 1.118-.617 0-1.118-.5-1.118-1.118V8.352zm2.127 10.877c-.266.265-.635.42-1.009.42-.375 0-.743-.155-1.011-.42-.266-.266-.418-.635-.418-1.009 0-.377.152-.746.418-1.011.268-.266.634-.418 1.011-.418.374 0 .743.152 1.009.418.268.265.42.634.42 1.011 0 .374-.152.743-.42 1.009z"
			/>
		</g>
	</svg>
);
