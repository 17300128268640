import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col, Show, Iconof } from '@upsiide/ui-components';
import { Link, useSearchParams } from 'react-router-dom';
import { useIsPublic } from 'src/hooks';
import { SmartSearch } from 'src/components/shared/SmartSearch';
import AccountSelectorDropdown from 'src/domains/header/components/shared/AccountSelectorDropdown';
import cn from 'src/utilities/bem-cn';
import * as selectors from 'src/domains/selectors';
import * as profileSelectors from 'src/domains/profile/selectors';
import * as actions from 'src/domains/all-studies/actions';
import { urlParams } from 'src/components/shared/SearchResults';
import * as confirmSignUpActions from 'src/domains/confirm-signup/actions';
// TODO: Find a way to not borrow actions from other domains
import * as accountActions from 'src/domains/account/actions';
import MobileMenuContainer, { MobileMenuHeader } from 'src/components/Layout/MobileMenuContainer';
import useHandleSetAccountUUID from '../../../../../hooks/useHandleSetAccountUUID';
import './styles.scss';

const className = 'all-studies-primary';
const el = (name, mod) => cn(className, name, mod);

const AllStudiesPrimary = ({
	loggedIn,
	setSearchQuery,
	account,
	accounts,
	setAccount,
	setAccountUuid,
	fetchAccount,
	fetchAccounts,
	setStudyFilters,
	fetchStudies,
	user,
	setUser,
}) => {
	const [searchParams] = useSearchParams();
	const query = searchParams.get(urlParams.query) || '';
	const oldQueryRef = useRef(query);

	const [showMobileSmartSearch, setShowMobileSmartSearch] = useState(false);
	const handleSetAccountUUID = useHandleSetAccountUUID();

	const [logoUrl, setLogoUrl] = useState('/public/images/upsiide-color.svg');
	const defaultLogoUrl = '/public/images/upsiide-color.svg';

	const { isPublic } = useIsPublic();

	const maybeUpdateLogo = useCallback(() => {
		let logo = logoUrl;
		if (account.content) {
			if (account.content.settings && account.content.settings.asset) {
				logo = account.content.settings.asset;
			} else {
				logo = defaultLogoUrl;
			}

			// Logo changed, save in local state for during account-swaps
			if (logo !== logoUrl) {
				setLogoUrl(logo);
			}
		}
	}, [account.content, logoUrl]);

	useEffect(() => {
		if (!isPublic) {
			fetchAccounts();
			maybeUpdateLogo();
		}
	}, [fetchAccounts, isPublic, maybeUpdateLogo]);

	// close mobile menu if search query changes
	useEffect(() => {
		if (showMobileSmartSearch && query !== oldQueryRef.current) {
			setShowMobileSmartSearch(false);
			oldQueryRef.current = query;
		}
	}, [query, showMobileSmartSearch]);

	const changeFilters = useCallback(
		values => {
			setStudyFilters(values);
		},
		[setStudyFilters],
	);

	const accountSelectorChangeAccountCallback = useCallback(
		uuid => {
			setSearchQuery('');
			changeFilters({
				clientUuid: [],
				'filter[studyCategories]': [],
				status: [],
			});
		},
		[changeFilters, setSearchQuery],
	);

	return (
		<Container className={className}>
			<Row>
				<Col style={{ flexGrow: 0, padding: '0 40px 0 0' }} className={el('left')} alignItems="center">
					<Link to={`/${isPublic ? 'public/studies' : 'studies'}`} className={el('upsiide-icon')}>
						<img src={logoUrl} alt="Back to studies" />
					</Link>
				</Col>
				<Show above={960}>
					<Col style={{ flexBasis: 'auto' }} justifyContent="center" alignItems="center">
						<SmartSearch />
					</Col>
				</Show>
				<Col
					style={{ flexGrow: 0, padding: '0 0 0 40px' }}
					className={el('right')}
					justifyContent="flex-end"
					alignItems="center"
				>
					<Show below={961}>
						<Iconof
							className={el('mobile-search-icon')}
							icon="search"
							onClick={() => setShowMobileSmartSearch(true)}
						/>
						{showMobileSmartSearch ? (
							<MobileMenuContainer>
								<div style={{ padding: '12px 16px' }}>
									<MobileMenuHeader onClose={() => setShowMobileSmartSearch(false)} />
								</div>
								<SmartSearch mobileUi setShowMobileSmartSearch={setShowMobileSmartSearch} />
							</MobileMenuContainer>
						) : null}
					</Show>
					{!isPublic ? (
						<Show above={960}>
							<AccountSelectorDropdown
								loggedIn={loggedIn}
								account={account}
								accounts={accounts}
								setAccount={setAccount}
								setAccountUuid={uuid => handleSetAccountUUID(uuid)}
								fetchAccount={fetchAccount}
								changeFilters={changeFilters}
								fetchStudies={fetchStudies}
								accountSelectorChangeAccountCallback={accountSelectorChangeAccountCallback}
								setUserEmail={() => {
									setUser({ email: user.email });
								}}
							/>
						</Show>
					) : null}
				</Col>
			</Row>
		</Container>
	);
};

AllStudiesPrimary.propTypes = {
	loggedIn: PropTypes.any,
	setSearchQuery: PropTypes.any,
	account: PropTypes.any,
	accounts: PropTypes.any,
	setAccount: PropTypes.func,
	setAccountUuid: PropTypes.func,
	fetchAccount: PropTypes.func,
	fetchAccounts: PropTypes.func,
	setStudyFilters: PropTypes.func,
	fetchStudies: PropTypes.func,
	user: PropTypes.object,
	setUser: PropTypes.func,
};

const mapStateToProps = state => ({
	studyFilters: selectors.getAllStudiesData(state).studyFilters,
	searchQuery: selectors.getAllStudiesData(state).searchQuery,
	account: selectors.getAccountData(state).account,
	accounts: selectors.getAccountData(state).accounts,
	user: profileSelectors.getUser(state),
});

const mapDispatchToProps = dispatch => ({
	setSearchQuery: searchQuery => dispatch(actions.setSearchQuery(searchQuery)),
	setAccount: account => dispatch(accountActions.setAccount(account)),
	fetchAccount: (accountId, fetchUsersAndRoles = true) =>
		dispatch(accountActions.fetchAccount(accountId, fetchUsersAndRoles)),
	setAccountUuid: accountUuid => dispatch(accountActions.setAccountUuid(accountUuid)),
	setStudyFilters: studyFilters => dispatch(actions.setStudyFilters(studyFilters)),
	fetchStudies: args => dispatch(actions.fetchStudies(args)),
	setUser: user => dispatch(confirmSignUpActions.setUser(user)),
	fetchAccounts: () => dispatch(accountActions.fetchAccounts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllStudiesPrimary);
