import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Button from 'src/components/elements/Button';
import MailSvg from 'public/images/open-mailbox.svg';
import './styles.scss';

const className = 'email-not-confirmed-banner';
const el = (name, mod) => cn(className, name, mod);

const bannerCopy = 'Verify your email to continue using Upsiide';

const EmailNotConfirmedBanner = ({ resendLink }) => (
	<div className={className}>
		<img src={MailSvg} className={el('mailbox-image')} alt="" />
		<span className={el('copy')}>{bannerCopy}</span>
		<Button className={el('resend-button')} label="Resend Email" onClick={resendLink} />
	</div>
);

EmailNotConfirmedBanner.propTypes = {
	resendLink: PropTypes.func,
};

export default EmailNotConfirmedBanner;
