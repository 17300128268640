export const countriesWithFlags = [
	{ id: 37, label: 'Argentina', flag: '🇦🇷' },
	{ id: 17, label: 'Australia', flag: '🇦🇺' },
	{ id: 14, label: 'Austria', flag: '🇦🇹' },
	{ id: 24, label: 'Belgium', flag: '🇧🇪' },
	{ id: 10, label: 'Brazil', flag: '🇧🇷' },
	{ id: 46, label: 'Bulgaria', flag: '🇧🇬' },
	{ id: 1, label: 'Canada', flag: '🇨🇦' },
	{ id: 38, label: 'Chile', flag: '🇨🇱' },
	{ id: 12, label: 'China', flag: '🇨🇳' },
	{ id: 39, label: 'Colombia', flag: '🇨🇴' },
	{ id: 47, label: 'Czech Republic', flag: '🇨🇿' },
	{ id: 49, label: 'Denmark', flag: '🇩🇰' },
	{ id: 36, label: 'Egypt', flag: '🇪🇬' },
	{ id: 3, label: 'France', flag: '🇫🇷' },
	{ id: 4, label: 'Germany', flag: '🇩🇪' },
	{ id: 44, label: 'Greece', flag: '🇬🇷' },
	{ id: 43, label: 'Hong Kong', flag: '🇭🇰' },
	{ id: 32, label: 'Hungary', flag: '🇭🇺' },
	{ id: 22, label: 'India', flag: '🇮🇳' },
	{ id: 19, label: 'Indonesia', flag: '🇮🇩' },
	{ id: 23, label: 'Italy', flag: '🇮🇹' },
	{ id: 18, label: 'Japan', flag: '🇯🇵' },
	{ id: 33, label: 'Kenya', flag: '🇰🇪' },
	{ id: 45, label: 'Malaysia', flag: '🇲🇾' },
	{ id: 15, label: 'Mexico', flag: '🇲🇽' },
	{ id: 31, label: 'Morocco', flag: '🇲🇦' },
	{ id: 8, label: 'Netherlands', flag: '🇳🇱' },
	{ id: 16, label: 'New Zealand', flag: '🇳🇿' },
	{ id: 41, label: 'Nigeria', flag: '🇳🇬' },
	{ id: 34, label: 'Pakistan', flag: '🇵🇰' },
	{ id: 40, label: 'Peru', flag: '🇵🇪' },
	{ id: 25, label: 'Philippines', flag: '🇵🇭' },
	{ id: 13, label: 'Poland', flag: '🇵🇱' },
	{ id: 11, label: 'Portugal', flag: '🇵🇹' },
	{ id: 29, label: 'Romania', flag: '🇷🇴' },
	{ id: 28, label: 'Russia', flag: '🇷🇺' },
	{ id: 35, label: 'Saudi Arabia', flag: '🇸🇦' },
	{ id: 42, label: 'Singapore', flag: '🇸🇬' },
	{ id: 9, label: 'South Africa', flag: '🇿🇦' },
	{ id: 26, label: 'South Korea', flag: '🇰🇷' },
	{ id: 5, label: 'Spain', flag: '🇪🇸' },
	{ id: 48, label: 'Sweden', flag: '🇸🇪' },
	{ id: 27, label: 'Taiwan', flag: '🇹🇼' },
	{ id: 21, label: 'Thailand', flag: '🇹🇭' },
	{ id: 30, label: 'Turkey', flag: '🇹🇷' },
	{ id: 7, label: 'United Kingdom', flag: '🇬🇧' },
	{ id: 2, label: 'USA', flag: '🇺🇸' },
	{ id: 20, label: 'Vietnam', flag: '🇻🇳' },
];
