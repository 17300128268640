import { getAuthData } from '../../selectors';

export const isLoading = state => getAuthData(state).loading;
export const isLoggedIn = state => getAuthData(state).loggedIn;
export const hasAccount = state => getAuthData(state).hasAccount;
export const getPrivileges = state => getAuthData(state).privileges;
export const getExpiry = state => getAuthData(state).expiresAt;
export const getFullToken = state => getAuthData(state).token;
export const getError = state => getAuthData(state).error;
export const getClientId = state => getAuthData(state).clientId;
export const getAddUserModal = state => getAuthData(state).addUserModal;
export const isGhostUser = state => getAuthData(state).ghost;
export const isSamlUser = state => getAuthData(state).isSamlUser;
export const rateLimit = state => getAuthData(state).rateLimit;

export const getMyUuid = state => ((getFullToken(state) || {}).user || {}).uuid || null;

export const isDigAdmin = state => {
	const { privileges } = getAuthData(state);

	if (!privileges) return false;

	return privileges.reduce((digAdmin, { roleCode }) => digAdmin || roleCode === 'DIG_ADMIN', false);
};

export const isDigEmployee = state => {
	const { privileges } = getAuthData(state);

	if (!privileges) return false;

	return privileges.reduce((digEmployee, { roleCode }) => digEmployee || roleCode === 'DIG_EMPLOYEE', false);
};

export const isDigGuest = (state, clientId) => {
	const { privileges } = getAuthData(state);

	if (!privileges) return false;

	return privileges.reduce(
		(isGuest, { roleCode, uuid }) =>
			isGuest || (['CLIENT_VIEWER', 'GUEST'].includes(roleCode) && uuid === clientId),
		false,
	);
};

export const getScopes = state => {
	const { privileges } = getAuthData(state);
	const scopes = {};
	if (privileges) privileges.map(p => (scopes[p.uuid] = p.scopes));

	return scopes;
};

export const getGlobalScopes = state => {
	const { privileges } = getAuthData(state);

	return privileges ? privileges.reduce((scopes, priveledge) => scopes.concat(priveledge.scopes), []) : [];
};
