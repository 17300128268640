export const getQuotaAlerts = ({ groupId, groupUuid, demographicQuestions = [], screeningQuestions = [] }) => {
	if (!demographicQuestions?.length) return;
	const screeningQuestionsWithQuotas = screeningQuestions
		?.filter(({ allowList, interlockedWithinGroup }) => {
			const isInterlocked = interlockedWithinGroup?.find(({ uuid }) => uuid === groupUuid);
			const isOnAllowList = allowList.includes(groupId);

			return isInterlocked || isOnAllowList;
		})
		?.map(q => {
			const getTerminateList = (question) => {
				const isMultiSelect = question?.settings?.find(setting => setting?.label === 'multi-select')?.value === 'true';
				if (!question?.logic?.length) return [];

				const notTerminateLogic = question.logic.filter(
					l => l?.operator === 'is_not' && l?.actionType === 'terminate' && !isMultiSelect,
				);
				const terminateLogic = question.logic
					.filter(l => l?.operator !== 'is_not' && l?.actionType === 'terminate')
					.filter(l => !(l?.operator === 'is' && l?.operand === 'and' && l?.triggerOptions?.length > 1));

				const isNotTriggerOptionsId = [
					...new Set(notTerminateLogic.map(l => l?.triggerOptions?.map(({ id }) => id)).flat(Infinity)),
				];

				const terminateTriggerOptionsId = [
					...new Set(terminateLogic.map(l => l?.triggerOptions?.map(({ id }) => id)).flat(Infinity)),
				];
				if (isNotTriggerOptionsId.length) {
					return question?.options?.filter(opt => !isNotTriggerOptionsId.includes(opt?.id)).map(({ id }) => id);
				}

				return terminateTriggerOptionsId;
			};
			const isMultiSelect = q?.settings?.find(setting => setting?.label === 'multi-select')?.value === 'true';
			const hasInvalidSingleLogic = (
				!isMultiSelect &&
				q?.logic?.some(
					l =>
						l?.operator === 'is_not' &&
						l?.operand === 'and' &&
						l?.actionType === 'terminate' &&
						l?.triggerOptions.length > 1,
				));
			const terminateList = getTerminateList(q);
			const isDqOption = (option) => {
				if (hasInvalidSingleLogic || terminateList.includes(option.id)) {
					return true;
				}
				return false;
			};

			return ({
				...q,
				options: q.options?.map(option => ({
					...option,
					isSelected: true,
					quota: [
						{
							quotaId: option?.quotaId,
							quotaMax: option?.quotaMax,
							quotaMin: option?.quotaMin,
						},
					],
					isDqOption: isDqOption(option)
				})),
			});
		});

	const questionsWithQuota = [...screeningQuestionsWithQuotas, ...demographicQuestions]?.filter(
		({ useQuota }) => useQuota,
	);

	const questionsWithTotalQuota = questionsWithQuota.map(question => {
		const optionsWithQuota = question.options.filter(option => option?.quota?.length);
		const quotas = optionsWithQuota
			?.filter(option => option?.isSelected)
			?.map(option => (option?.quota?.[0] ? option?.quota?.[0]?.quotaMax : null));
		const hasNullQuota = quotas.includes(null) || quotas.includes(undefined);
		const innerHasInvalidIndividualQuota = quotas.some(q => q > 100);

		const questionQuotaSum = optionsWithQuota
			?.filter(option => option?.isSelected)
			?.filter(option => option?.quota[0]?.quotaMax != null && option?.quota[0]?.quotaMax !== '')
			?.map(option => option?.quota[0]?.quotaMax)
			?.reduce((acc, curr) => acc + curr, 0);
		return {
			...question,
			hasNullQuota,
			hasInvalidIndividualQuota: innerHasInvalidIndividualQuota,
			questionQuotaSum: Math.ceil(questionQuotaSum),
		};
	});

	const quotasUnder100Percent = questionsWithTotalQuota.filter(
		({ questionQuotaSum, hasNullQuota }) => !hasNullQuota && questionQuotaSum < 100,
	);

	const hasInvalidIndividualQuota = questionsWithTotalQuota.filter(
		({ hasInvalidIndividualQuota }) => !!hasInvalidIndividualQuota
	);

	const quotasWithZero = questionsWithQuota.filter(({ options, audienceCollectionId }) => {
		// filter screeners
		if (audienceCollectionId) {
			return false;
		}
		const useQuota = !!options?.find(option => option?.quota?.[0]?.quotaMax != null);
		if (!useQuota) return false;

		const quotas = options?.filter(({ isSelected }) => isSelected)?.map(option => option?.quota?.[0]?.quotaMax);
		const totalQuotas = quotas.reduce((acc, curr) => acc + (curr || 0), 0);
		const hasNullQuotas = quotas.includes(null) || quotas.includes(undefined);
		if (totalQuotas >= 100 && hasNullQuotas) {
			return true;
		}
		return quotas.includes(0);
	});

	const partialAbove = questionsWithTotalQuota.filter(({ options, questionQuotaSum, audienceCollectionId }) => {
		if (!options?.length) return;
		// filter screeners
		if (audienceCollectionId) {
			return false;
		}
		const useQuota = !!options?.find(option => option?.quota?.[0]?.quotaMax != null);
		if (!useQuota) return;

		const quotas = options?.filter(({ isSelected }) => isSelected)?.map(option => option?.quota?.[0]?.quotaMax);
		const hasEmptyQuotas = quotas.includes(null) || quotas.includes(undefined);
		return hasEmptyQuotas && questionQuotaSum > 100 && !hasInvalidIndividualQuota?.length;
	});

	const quotaCannotBeZero = screeningQuestionsWithQuotas.filter(({ options }) => {
		if (!options?.length) return;
		const quotas = options?.filter(({ isDqOption }) => !isDqOption)?.map(option => option?.quotaMax);
		const hasEmptyQuotas = quotas.includes(null) || quotas.includes(undefined);
		const questionQuotaSum = options?.map(option => option?.quotaMax)?.reduce((acc, curr) => acc + (curr || 0), 0);
		return hasEmptyQuotas && questionQuotaSum >= 100;
	});

	return [
		...(quotasUnder100Percent?.length ? ['Some of your quotas are too low to complete'] : []),
		...(quotasWithZero?.length ? ['Quotas cannot be zero'] : []),
		...(partialAbove?.length ? ['Quotas cannot be zero'] : []),
		...(quotaCannotBeZero?.length ? ['Quotas for question cannot be zero'] : []),
		...(hasInvalidIndividualQuota?.length ? ['Some of your quotas values are invalid'] : []),
	];
};
