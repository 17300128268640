import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Auth from 'src/utilities/auth';
import { Container, Row, Col } from 'reactstrap';
import Back from 'src/components/icons/Back';
import AddNewItemButton from 'src/components/elements/AddNewItemButton';
import cn from 'src/utilities/bem-cn';
import * as actions from 'src/domains/manage-study/actions';
import * as selectors from 'src/domains/selectors';
import './styles.scss';
import Button from 'src/components/elements/Button';
import withRouter from 'src/hocs/withRouter';

const className = 'single-study-primary';
const el = (name, mod) => cn(className, name, mod);

class ManageTranslationHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount = () => {
		const manageTranslationAccess = Auth.accountHasFeature('manageTranslations');
		const {
			router: { navigate },
		} = this.props;
		if (!manageTranslationAccess) {
			navigate('/');
		}
	};

	renderBackButton = () => {
		const {
			study,
			router: { navigate },
		} = this.props;
		return (
			<button
				type="button"
				className={el('back-button')}
				onClick={() => navigate(`/studies/${study?.uuid || study?.id}/create`)}
			>
				<Back />
			</button>
		);
	};

	addNewLanguage = () => {
		const event = new Event('add-language', { cancelable: true, bubbles: true });
		window.dispatchEvent(event);
	};

	renderBatchEditButton = () => {
		const { setTranslationsModal } = this.props;
		return (
			<Button
				label="Batch Edit"
				onClick={() => {
					setTranslationsModal({ visible: true });
				}}
				className="keyline"
			/>
		);
	};

	disableAddLanguageButton = () => {
		const { study, languages } = this.props;
		return study.translations && languages && study.translations.length >= languages.length;
	};

	renderAddLanguageButton = () => (
		<Button
			disabled={this.disableAddLanguageButton()}
			label="+ Add Language"
			onClick={() => this.addNewLanguage()}
			className="default"
		/>
	);

	render() {
		const { study, title } = this.props;

		const isDigAdmin = Auth.isDigAdmin();
		const userCanStudyUpdate = Auth.userCan('study:update', true);
		if (!study || !userCanStudyUpdate) {
			return false;
		}
		return (
			<Container className={className}>
				<Row>
					<Col className={el('left')}>{this.renderBackButton()}</Col>
					<Col className={el('center')}>{title}</Col>
					<Col className={el('right')}>
						{this.renderBatchEditButton()}
						{this.renderAddLanguageButton()}
					</Col>
				</Row>
			</Container>
		);
	}
}

ManageTranslationHeader.propTypes = {
	id: PropTypes.any,
	title: PropTypes.string,
	study: PropTypes.any,
	setTranslationsModal: PropTypes.func,
	languages: PropTypes.array,
};

const mapStateToProps = state => ({
	study: selectors.getManageStudyData(state).study,
	language: selectors.getManageStudyData(state).language,
	languages: selectors.getManageStudyData(state).languages,
});

const mapDispatchToProps = dispatch => ({
	patchStudy: (id, data) => dispatch(actions.patchStudy(id, data)),
	setTranslationsModal: translationsModal => dispatch(actions.setTranslationsModal(translationsModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageTranslationHeader));
