import { createSelector } from 'reselect';
import { sortBy } from 'lodash';
import { getAllStudiesData } from '../../selectors';

export const getClients = state => getAllStudiesData(state).clients;
export const getSearchQuery = state => getAllStudiesData(state).searchQuery;
export const getStudies = state => getAllStudiesData(state).studies;
export const getProducts = state => getAllStudiesData(state).products;
export const getStudyCategories = state => getAllStudiesData(state).studyCategories;
export const getStudyFilters = state => getAllStudiesData(state).studyFilters;
export const getError = state => getAllStudiesData(state).error;
export const getViewMode = state => getAllStudiesData(state).viewMode;
export const getLoading = state => getAllStudiesData(state).loading;
export const getLanguages = state => getAllStudiesData(state).languages;
export const getGeographies = state => getAllStudiesData(state).geographies;
export const getCollections = state => getAllStudiesData(state).collections;
export const getStudyTags = state => getAllStudiesData(state).studyTags;
export const getProductTags = state => getAllStudiesData(state).productTags;
export const getClientsFromAccount = state => getAllStudiesData(state).clientsFromAccount;
export const getDeleteStudyState = state => getAllStudiesData(state).deleteStudyState;
export const getShowMoveStudyModal = state => getAllStudiesData(state).showMoveStudyModal;

export const getCountriesFromLanguages = createSelector(getLanguages, languages => {
	const countries = [];

	if (!languages) {
		return [];
	}

	languages?.forEach(language => {
		language?.countries?.forEach(languageCountry => {
			const hasCountry = countries?.some(currentCountry => currentCountry.id === languageCountry.id);

			if (!hasCountry) {
				countries.push(languageCountry);
			}
		});
	});

	return countries;
});

export const getStudyById = createSelector([getStudies, (state, id) => id], (studies, studyId) => {
	if (studies[studyId]) {
		return studies[studyId];
	}

	return null;
});

export const getProductById = createSelector([getProducts, (state, id) => id], (products, productId) => {
	if (products[productId]) {
		return products[productId];
	}

	return null;
});

export const getOrderedStudyTags = createSelector(getStudyTags, tags =>
	sortBy(tags, tag => (tag?.label || '').trim().toLowerCase()),
);

export const getAllStudyTagIds = createSelector(getStudyTags, tags => {
	if (!tags) {
		return [];
	}

	return Object.values(tags)?.map(tag => tag?.id);
});

export const getFirst10TagIds = createSelector(getOrderedStudyTags, tags =>
	tags
		?.filter(tag => !tag?.id?.toString()?.includes('optimistic'))
		?.slice(0, 10)
		?.map(tag => tag?.id),
);

export const getOrderedProductTags = createSelector(getProductTags, tags =>
	sortBy(tags, tag => (tag?.label || '').trim().toLowerCase()),
);
export const getOrderedCollections = createSelector(getCollections, collection => sortBy(collection, 'name'));

export const getStudyTagById = createSelector([getStudyTags, (state, id) => id], (tags, tagId) => {
	if (tags[tagId]) {
		return tags[tagId];
	}

	return null;
});
export const getProductTagById = createSelector([getProductTags, (state, id) => id], (tags, tagId) => {
	if (tags[tagId]) {
		return tags[tagId];
	}

	return null;
});

export const getStudyFilterData = createSelector(
	getOrderedCollections,
	getOrderedStudyTags,
	getOrderedProductTags,
	getLanguages,
	getCountriesFromLanguages,
	(collections, studyTagData, productTagData, languages, countries) => {
		const studyTemplates = collections
			?.filter(item => item?.type === 'studyTemplate')
			?.map(collection => ({
				key: collection?.id,
				label: collection?.name,
				value: collection?.id,
			}));

		const audienceTemplates = collections
			?.filter(item => item?.type === 'audienceTemplate')
			?.map(collection => ({
				key: collection?.id,
				label: collection?.name,
				value: collection?.id,
			}));

		const combinedTemplates = collections
			?.filter(item => item?.type === 'combinedTemplate')
			?.map(collection => ({
				key: collection?.id,
				label: collection?.name,
				value: collection?.id,
			}));

		const studyTags = studyTagData?.map(tag => ({
			key: tag?.id,
			label: tag?.label,
			value: tag?.label,
			color: tag?.color,
		}));

		const ideaTags = productTagData?.map(tag => ({
			key: tag?.id,
			label: tag?.label,
			value: tag?.label,
			color: tag?.color,
		}));

		const countryItems = countries?.map(country => ({
			key: country?.id,
			label: country?.label,
			value: country?.id,
		}));

		const languageItems = languages?.map(language => ({
			key: language?.languageCode,
			label: language?.label,
			value: language?.languageCode,
		}));

		const filterTypes = [
			// Study
			{
				filtersFor: ['studies'],
				value: 'template',
				label: 'Template',
				localFilter: true,
				subItems: [
					{
						value: 'studyTemplate',
						label: 'Study Template',
						localFilter: true,
						items: studyTemplates,
						backTo: 'template',
					},
					{
						value: 'audienceTemplate',
						label: 'Audience Template',
						localFilter: true,
						items: audienceTemplates,
						backTo: 'template',
					},
				],
			},
			{
				filtersFor: ['studies', 'ideas', 'questions'],
				value: 'studyTag',
				label: 'Study Tag',
				localFilter: true,
				items: studyTags,
			},
			{
				filtersFor: ['studies', 'templates'],
				value: 'country',
				label: 'Country',
				localFilter: true,
				items: countryItems,
			},
			{
				filtersFor: ['studies', 'ideas', 'questions', 'templates'],
				value: 'language',
				label: 'Language',
				localFilter: true,
				items: languageItems,
			},
			// Idea
			{
				filtersFor: ['ideas'],
				value: 'ideaType',
				label: 'Section Type',
				localFilter: true,
				items: [
					{
						label: 'Idea Split',
						value: 'monadic_split',
					},
					{
						label: 'Idea Screen',
						value: 'swipe',
					},
				],
			},
			{
				filtersFor: ['ideas'],
				value: 'ideaTag',
				label: 'Idea Tag',
				localFilter: true,
				items: ideaTags,
			},
			// Question
			{
				filtersFor: ['questions'],
				value: 'questionType',
				label: 'Question Type',
				localFilter: true,
				items: [
					{
						label: 'Select',
						value: 'multi-select',
					},
					{
						label: 'Open Ended',
						value: 'open-ended',
					},
					{
						label: 'Grid',
						value: 'grid',
					},
					{
						label: 'Emoji',
						value: 'emoji',
					},
					{
						label: 'Heatmap',
						value: 'heatmap',
					},
					{
						label: 'Rank',
						value: 'ranked',
					},
				],
			},
		];

		return filterTypes;
	},
);

export const getPublicStudyFilterData = createSelector(getStudyFilterData, filters =>
	filters.filter(filter => filter.value !== 'template'),
);
