import React, { useCallback, useContext, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIsPublic } from 'src/hooks';
import toastr from 'toastr';
import cn from 'src/utilities/bem-cn';
import WithScope from 'src/components/shared/WithScope';
import Modal from 'src/components/shared/Modal';
import auth from 'src/utilities/auth';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import EllipsisMenu from 'src/components/elements/EllipsisMenu';
import { useSelector, useDispatch } from 'react-redux';
import { getDeleteStudyState } from 'src/domains/all-studies/selectors';
import { checkoutService, studyService, blueprintService } from 'src/services';
import { setDeleteStudyState, upsertStudy } from 'src/domains/all-studies/actions';
import { getAccountData } from 'src/domains/selectors';
import { urlParams } from '../SearchResults';
import { setShowMoveStudyModal } from '../../../domains/all-studies/actions';
import { TemplateCardsContext } from '../TemplateCard/context';
import './styles.scss';

export const DeleteStudyModal = () => {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const deleteState = useSelector(getDeleteStudyState);

	const handleDenyDelete = useCallback(() => {
		dispatch(
			setDeleteStudyState({
				areYouSure: null,
				studyToDelete: null,
				studyContainsLiveAMPAudience: false,
				clientId: null,
				studyId: null,
			}),
		);
	}, [dispatch]);

	const handleConfirmDelete = useCallback(async () => {
		dispatch(
			upsertStudy([
				{
					id: deleteState.studyToDelete,
					deleting: true,
				},
			]),
		);

		try {
			await studyService.deleteStudy(deleteState.studyToDelete, deleteState.studyToDeletesClientId);

			setSearchParams(params => {
				params.set(urlParams.refetch, new Date().getTime());

				return params;
			});
		} catch (e) {
			// Error deleting study
			toastr.error('Error deleting study');

			dispatch(
				upsertStudy([
					{
						id: deleteState.studyId,
						deletedAt: null,
						deleting: false,
					},
				]),
			);
		}

		handleDenyDelete();
	}, [deleteState, dispatch, handleDenyDelete, setSearchParams]);

	return (
		<Modal show={deleteState?.areYouSure} width={600} padding={0} onClose={handleDenyDelete}>
			<AreYouSureForm
				onCancel={deleteState?.studyContainsLiveAMPAudience ? null : handleDenyDelete}
				onConfirm={deleteState?.studyContainsLiveAMPAudience ? handleDenyDelete : handleConfirmDelete}
				headerText={
					deleteState?.studyContainsLiveAMPAudience
						? "Oops! You can't delete this study"
						: 'Delete Study Confirmation'
				}
				bodyText={
					deleteState?.studyContainsLiveAMPAudience
						? 'You just need to stop or complete all Audience Marketplace audiences in order to be able to delete this study.'
						: 'Are you sure you want to delete this study? This cannot be undone.'
				}
				confirmText={deleteState?.studyContainsLiveAMPAudience ? 'Okay' : 'Delete'}
				confirmState="active"
				confirmType={deleteState?.studyContainsLiveAMPAudience ? 'green' : 'red'}
			/>
		</Modal>
	);
};

export const StudyActionMenu = ({ study }) => {
	const dispatch = useDispatch();
	const { isPublic } = useIsPublic();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { accounts } = useSelector(getAccountData) || [];
	const ellipsisMenuRef = useRef();
	const { handleSetStep } = useContext(TemplateCardsContext);

	const isMoveStudyEnabled = auth.isDigAdmin() && accounts && accounts.content && accounts.content.length > 1;

	const className = 'study-action-menu';
	const el = (name, mod) => cn(className, name, mod);

	const handleDelete = useCallback(
		async (studyId, clientId) => {
			const audiences = await checkoutService.getAudiences(studyId);
			const containsLiveStudy = await audiences.data.some(
				audience => audience.status === 'live' && audience.provider !== 'BYO',
			);

			dispatch(
				setDeleteStudyState({
					areYouSure: true,
					studyToDelete: studyId,
					studyToDeletesClientId: clientId,
					studyContainsLiveAMPAudience: containsLiveStudy,
				}),
			);
		},
		[dispatch],
	);

	const handleEdit = useCallback(
		studyId => {
			navigate(
				`${isPublic ? '/public' : ''}/studies/${studyId}/${
					['active', 'closed', 'needs-review'].includes(study?.status) ? 'report' : 'create'
				}`,
			);
		},
		[isPublic, navigate, study?.status],
	);

	const handleDuplicate = useCallback(
		async (studyId, clientId, blueprintAccessLevel = 'space', studyDuplication = true) => {
			const url = window.location.href;
			const domain = new URL(url);

			dispatch(
				upsertStudy([
					{
						id: studyId,
						working: true,
					},
				]),
			);

			await blueprintService
				.createBlueprintFromStudyOrDuplicate(studyId, clientId, blueprintAccessLevel, studyDuplication, false)
				.then(res => {
					if (res.status === 200) {
						if (studyDuplication) {
							toastr.success(
								`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
									<p style="font-size: 16px;">Study has been duplicated!</p>
									<a style="color: #008855; padding-left:54px; font-size: 14px; font-weight: 700" href="http://${domain.host}/studies/${
									res?.data?.uuid || res?.data?.id
								}/create">View</a>
								</div>`,
							);

							setSearchParams(params => {
								params.set(urlParams.refetch, new Date().getTime());
								return params;
							});
						} else {
							toastr.success('Template successfully created');
							if (domain?.pathname.includes('/studies')) {
								navigate(`/templates/${res?.data?.uuid || res?.data?.id}/create`);
							}
						}
					} else if (studyDuplication) {
						toastr.error('Something went wrong with duplicating the study');
					} else {
						toastr.error('Something went wrong with creating the template');
					}

					dispatch(
						upsertStudy([
							{
								id: studyId,
								working: false,
							},
						]),
					);
				});
		},
		[dispatch, navigate, setSearchParams],
	);

	const handleMoveTo = useCallback(() => {
		const data = study;
		data.previousClientUuid = study?.clientId;
		dispatch(setShowMoveStudyModal(data));
	}, [dispatch, study]);

	return (
		<EllipsisMenu customClass={className} ref={ellipsisMenuRef}>
			<WithScope scope="study:update" clientIdProps={study?.clientId}>
				{able =>
					able ? (
						<div className={el('menu')}>
							<div
								onKeyDown={() => {}}
								tabIndex={0}
								role="button"
								className={el('menu-button')}
								onClick={e => {
									e.stopPropagation();
									ellipsisMenuRef?.current?.toggleActions(e);
									handleEdit(study?.uuid || study?.id);
								}}
							>
								Open
							</div>
							{isMoveStudyEnabled && (
								<div
									onKeyDown={() => {}}
									tabIndex={0}
									role="button"
									className={`${el('menu-button')}`}
									onClick={e => {
										e.stopPropagation();
										ellipsisMenuRef?.current?.toggleActions(e);
										handleMoveTo(study?.id, study?.clientId);
									}}
								>
									Move to...
								</div>
							)}
							{(auth.userCanWithinAccount('study:create') || auth.isDigAdmin()) && (
								<>
									<div
										onKeyDown={() => {}}
										tabIndex={0}
										role="button"
										className={`${el('menu-button')}`}
										onClick={e => {
											e.stopPropagation();
											ellipsisMenuRef?.current?.toggleActions(e);
											handleDuplicate(study?.id, study?.clientId, 'space', true);
										}}
									>
										Duplicate
									</div>
									<div
										onKeyDown={() => {}}
										tabIndex={0}
										role="button"
										className={`${el('menu-button')}`}
										onClick={e => {
											e.stopPropagation();
											ellipsisMenuRef?.current?.toggleActions(e);

											const templateData = {
												id: study?.id,
												type: 'study',
												study,
											};
											handleSetStep('preview', {
												visible: true,
												selectedTemplate: templateData,
												previewMode: 'save',
												isMultiStep: false,
											});
										}}
									>
										Save as Template
									</div>
								</>
							)}
							<div
								onKeyDown={() => {}}
								tabIndex={0}
								role="button"
								className={`${el('menu-button')}`}
								onClick={e => {
									e.stopPropagation();
									ellipsisMenuRef?.current?.toggleActions(e);
									if (study?.deleting) return;
									handleDelete(study?.id, study?.clientId);
								}}
							>
								{study?.deleting ? 'working...' : 'Delete'}
							</div>
						</div>
					) : null
				}
			</WithScope>
		</EllipsisMenu>
	);
};
StudyActionMenu.propTypes = {
	study: PropTypes.object,
};
