import { navigate } from 'src/utilities/router/routerScopeLeaker';
import * as Sentry from '@sentry/browser';
import { datadogRum } from '@datadog/browser-rum';

import axios from 'src/utilities/axios';
import { accountService, loginService, userService } from 'src/services';
import * as accountActions from 'src/domains/account/actions';
import * as profileActions from 'src/domains/profile/actions';
import * as studyActions from 'src/domains/all-studies/actions';
import * as misc from 'src/utilities/misc';
import Cookies from 'src/utilities/cookies';
import cannyService from 'src/services/canny.service';
import { TRACKING_ACTIONS, trackingEvent } from 'src/utilities/marketing';
import * as actions from '../actions';

// Handle an existing login session
const authenticate = async (store, { type, payload }) => {
	// The only difference between AUTHENTICATE and REAUTHENTICATE is that the Reducer listens to AUTHENTICATE as well.
	if (type === actions.AUTHENTICATE || type === actions.REAUTHENTICATE) {
		const token = payload.token || Cookies.getToken();
		const redirect = misc.getUrlParameter('redirect').length > 0 ? misc.getUrlParameter('redirect') : '';
		const returnTo = misc.getUrlParameter('return_to').length > 0 ? misc.getUrlParameter('return_to') : '';
		if (token) {
			// Set the bearer token so we don't get logged out with a 403 on refresh.
			axios.setToken(token);

			let fullTokenResponse;
			let newToken;
			try {
				fullTokenResponse = await loginService.validate();
				newToken = fullTokenResponse.data.token;
				if (newToken !== token) {
					console.warn('TOKEN CHANGED, STORE NEW ONE');
					Cookies.setToken(newToken);
					axios.setToken(newToken);
				}
			} catch (error) {
				// Validate error - catch and reject
				console.error(error);
				return store.dispatch(actions.setNoUser());
			}

			const fullTokenData = Cookies.getTokenData(fullTokenResponse.data.fullToken);
			const tokenData = await Cookies.getTokenData();

			/* Fetch user info - manually from here so we can access the data */
			// Get accounts

			try {
				const [userData, accountsResponse] = await Promise.all([
					userService.get(),
					accountService.fetchAccounts(),
				]);
				// boot intercom
				trackingEvent(TRACKING_ACTIONS.boot, {
					name: `${userData?.data?.firstName} ${userData?.data?.lastName}`,
					email: userData?.data?.email,
				});

				store.dispatch(profileActions.setUser(userData.data));
				store.dispatch(accountActions.setAccounts({ content: accountsResponse.data }));

				// Confirm the account UUID matches an account this user has access to.
				const upsiideAccountToken = Cookies.getUpsiideAccountToken();
				const accountTokenMatches =
					(upsiideAccountToken &&
						accountsResponse.data &&
						accountsResponse.data.find(account => account.uuid === upsiideAccountToken)) ||
					false;

				// Valid Account UUID saved
				if (upsiideAccountToken && accountTokenMatches) {
					axios.setAccountUuid(upsiideAccountToken);
					// Get account
					const accountResponse = await accountService.fetchAccount();
					store.dispatch(accountActions.setAccount({ content: accountResponse.data }));
				} else {
					// Set the default account by grabbing the first account in the list that isn't a demo
					let defaultAccount = accountsResponse.data.find(account => {
						if (process.env.generalResourcesAccountUuid !== account.uuid) {
							return account.uuid;
						}
						return false;
					});

					// Handle users only attached to demo/trial accounts
					if (!defaultAccount) {
						defaultAccount = accountsResponse.data[0] || null;
					}

					if (defaultAccount) {
						Cookies.setUpsiideAccountToken(defaultAccount.uuid);
						axios.setAccountUuid(defaultAccount.uuid);
						// Get account
						const accountResponse = await accountService.fetchAccount();
						store.dispatch(accountActions.setAccount({ content: accountResponse.data }));
					} else {
						console.error('no accounts assign to this user');
						Cookies.clearToken();
						navigate('/no-account');
						return store.dispatch(actions.setNoUser());
					}
				}

				// TODO - refactor this area to not be cookie-dependent.
				if (userData) {
					const logoutGhost = Cookies.getLogoutGhost() === 'true';
					if (logoutGhost) {
						Cookies.removeLogoutGhost(); // Clear the 'logout ghost' flag
						Cookies.removeGhost(); // Clear the 'is ghost' flag
					}

					const isGhost = Cookies.getGhost() === 'true';
					store.dispatch(actions.isGhostUser(isGhost));
				}

				if (fullTokenData) {
					// expiry check
					if (tokenData.exp < Date.now()) {
						if (redirect && redirect.includes('canny')) {
							store.dispatch(actions.redirectToCanny(redirect));
						} else {
							if (
								process.env.production ||
								process.env.staging ||
								(process.env.development && !process.env.isProto)
							) {
								Sentry.configureScope(scope => {
									scope.setUser({
										id: fullTokenData.user.uuid,
										email: userData.data.email,
									});
								});

								datadogRum.setUser({
									id: fullTokenData.user.uuid,
									email: userData.data.email,
									// plan: 'premium',
									// Could add "active account" or something here, perhaps. It flattens arrays to the first.
									// roles: fullTokenData.privileges.map(priv => ({
									// 	uuid: priv.uuid,
									// 	role: priv.role,
									// 	displayName: priv.displayName,
									// })),
								});
							}
							return store.dispatch(
								actions.setUserData({
									// user: tokenData.user,
									expiresAt: fullTokenData.exp,
									privileges: fullTokenData.privileges,
									isSamlUser: fullTokenData.user.isSamlUser,
									token: fullTokenData,
								}),
								// This is an example script - don't forget to change it!
							);
						}
					}
				}
			} catch (error) {
				// unable to fetch accounts / user data
				return store.dispatch(actions.setNoUser());
			}
		}

		// If it hasn't returned yet, assume not logged in.
		return store.dispatch(actions.setNoUser());
	}
};

/**
 * Log in as other users
 */
const loginAsUser = (store, action) => {
	if (action.type === actions.LOGIN_AS_USER) {
		const { uuid } = action.payload;
		const userData = {
			keepAlive: true,
			uuid,
		};

		const originalToken = Cookies.getToken();
		loginService.loginAsUser(userData).then(response => {
			if (response) {
				const loginAsToken = response.data.token;

				Cookies.setGhost(true);
				Cookies.storeOriginalToken(originalToken);
				Cookies.setToken(loginAsToken);

				axios.setToken(loginAsToken);
				store.dispatch(actions.authenticate());
				navigate('/studies');
			} else {
				const error = "That's not what we were expecting. Try again.";
				console.error(error);
				store.dispatch(actions.setAddUserModal({ loading: false, error }));
			}
		});
	}
};

// TODO - This isn't related to state, so this shouldn't be an effect.
const isGhostUser = (store, { type, payload }) => {
	if (type === actions.IS_GHOST_USER) {
		const { ghost } = payload;
		if (ghost === true) {
			Cookies.setGhost(true);
		} else {
			Cookies.removeLogoutGhost();
			Cookies.removeGhost();
		}
	}
};

// Handle an existing login session
const logout = (store, { type, payload }) => {
	if (type === actions.LOGOUT) {
		const token = Cookies.getToken();
		loginService.logout({ Authorization: token }).then(resp => {
			if (resp.status === 204) {
				Cookies.clearToken();
				if (Cookies.getGhost()) {
					Cookies.removeGhost();
				}
				store.dispatch(actions.setLogout());
				navigate('/');
			}
		});

		trackingEvent(TRACKING_ACTIONS.logoutUser);
		Cookies.setCompanySize('');
		Cookies.removeUpsiideAccountToken();
		Cookies.removeLogoutGhost();
		localStorage.removeItem('userFiles');
		store.dispatch(accountActions.setChargifySubscriptions({}));
		store.dispatch(actions.isGhostUser(false));
	}
};

const switchToOriginalUser = (store, { type, payload }) => {
	if (type === actions.SWITCH_BACK) {
		const originalToken = Cookies.getOriginalToken();
		Cookies.clearStoredToken();
		Cookies.setToken(originalToken);
		axios.setToken(originalToken);
		Cookies.setLogoutGhost(true);
		store.dispatch(actions.authenticate());
		navigate('/studies');

		const upsiideAccountToken = Cookies.getUpsiideAccountToken();
		if (upsiideAccountToken) {
			store.dispatch(studyActions.fetchStudies({ dropPagination: true, accountUuids: upsiideAccountToken }));
		} else {
			store.dispatch(studyActions.fetchStudies());
		}
	}
};

const handleClientIdChange = (store, action) => {
	if (action.type === actions.SET_CLIENT_ID) {
		const { clientId } = action.payload;

		Cookies.setClientId(clientId);
		axios.setClientId(clientId);
	}
};

const goToSupport = async (store, action) => {
	if (action.type === actions.GO_TO_SUPPORT) {
		const redirectUrl = `https://support.upsiide.com/`;
		const windowTarget = '_blank';
		window.open(redirectUrl, windowTarget);
	}
};

const redirectToCanny = async (store, action) => {
	if (action.type === actions.REDIRECT_TO_CANNY) {
		const response = await cannyService.getCannySSOToken();
		if (response.data && response.data.token) {
			if (action.payload.redirect.indexOf('?') === -1) {
				action.payload.redirect += `?ssoToken=${response.data.token}`;
			} else {
				action.payload.redirect += `&ssoToken=${response.data.token}`;
			}
			window.location.assign(action.payload.redirect);
		}
	}
};

export default [
	authenticate,
	isGhostUser,
	loginAsUser,
	switchToOriginalUser,
	logout,
	handleClientIdChange,
	goToSupport,
	redirectToCanny,
];
