import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { Iconof, Button } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import AgeRangeInput from '../AgeRangeInput';

import './styles.scss';

const className = 'age-dropdown';

const el = (name, mod) => cn(className, name, mod);

const AgeDropdown = ({
	multi,
	data,
	setData,
	value,
	onChange,
	deleteOption,
	onCreateOption,
	onEditAgeRangeValues,
	ageRangeErrors,
	setAgeRangeErrors,
	getDemographicUuid,
	patchDemographic,
}) => {
	const [list, setList] = useState(data);
	const [search, setSearch] = useState('');
	const [selected, setSelected] = useState(Array.isArray(value) ? value : [value]);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const currentGroup = useSelector(manageStudySelectors.getGroup);

	useEffect(() => {
		if (!search) {
			setSearch('');
			setList(data);
		}
	}, [search, data]);

	const onClearText = () => {
		if (search) {
			setSearch('');
			setList(data);
		}
	};

	useEffect(() => {
		if (!isFirstRender) {
			onChange(selected);
		} else {
			setIsFirstRender(false);
		}
		// eslint-disable-next-line
	}, [selected]);

	const onSearch = event => {
		event.stopPropagation();
		event.preventDefault();
		const query = event.target?.value?.trim();
		setSearch(query);
		const filter = data.filter(e => e?.label?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1);
		setList(filter);
	};

	const addNewRange = useCallback(() => {
		if (data.find(range => !range?.audienceQuestionOptionId) === undefined) {
			data.push({});
			const updatedData = data.map(item => {
				if (item?.quota) {
					return {
						...item,
						quota: [
							{
								audienceQuestionOptionId: item?.audienceQuestionOptionId,
								id: item?.quota?.id,
								quotaMax: Number(item?.quota?.quotaMax) || 0,
								quotaMin: Number(item?.quota?.quotaMin) || 0,
								optionId: item?.quota?.optionId,
								parentQuotaId: item?.quota?.parentQuotaId,
							},
						],
					};
				}
				return item;
			});
			setData(updatedData);
		} else {
			toastr.error('Please complete the current age range before adding a new one');
		}
	}, [data, setData]);

	const onRemove = async item => {
		if (item?.audienceQuestionOptionId) {
			await deleteOption(item);
			const newOptions = data.filter(
				e => item?.audienceQuestionOptionId && e?.audienceQuestionOptionId !== item?.audienceQuestionOptionId,
			);
			const useQuota = newOptions?.some(option => {
				const quotaMax = Number(option?.quota?.quotaMax) || 0;
				const quotaMin = Number(option?.quota?.quotaMin) || 0;
				return quotaMax !== 0 || quotaMin !== 0;
			});
			patchDemographic({
				demographicQuestions: [
					{
						useQuota,
						audienceQuestionId: item?.audienceQuestionId,
						options: [{ audienceQuestionOptionId: item?.audienceQuestionOptionId, isSelected: false }],
					},
				],
			});
		}
		const filteredData = data.filter(e => e?.audienceQuestionOptionId !== item?.audienceQuestionOptionId);
		const newData = filteredData?.map(dataPoint => {
			if (dataPoint?.quota) {
				return {
					...dataPoint,
					quota: [
						{
							audienceQuestionOptionId: dataPoint?.audienceQuestionOptionId,
							id: dataPoint?.quota?.id,
							quotaMax: Number(dataPoint?.quota?.quotaMax) || 0,
							quotaMin: Number(dataPoint?.quota?.quotaMin) || 0,
							optionId: dataPoint?.quota?.optionId,
							parentQuotaId: dataPoint?.quota?.parentQuotaId,
						},
					],
				};
			}
			return item;
		});
		setData(newData);
	};

	return (
		<div className={`${className} ${data?.length ? '' : el('empty-age-ranges')}`}>
			<div className={el('input_container')}>
				<input
					className={el('search_input')}
					placeholder="Search Age"
					value={search}
					onChange={e => onSearch(e)}
				/>
				<Iconof
					icon={search ? 'clear' : 'search'}
					size="default"
					className={el('search_icon')}
					onClick={onClearText}
				/>
			</div>

			{multi ? (
				<div className={el('select_all_container')}>
					<span className={el('age_title')}>Age</span>
					<span className={el('age-description')}>Edit or add a range below</span>
				</div>
			) : null}

			<div className={el('container')}>
				<AgeRangeInput
					data={data}
					onRemove={onRemove}
					onEditAgeRangeValues={onEditAgeRangeValues}
					onCreateOption={onCreateOption}
					ageRangeErrors={ageRangeErrors}
					setAgeRangeErrors={setAgeRangeErrors}
					getDemographicUuid={getDemographicUuid}
					search={search}
				/>
			</div>
			<div className={el('add-new-container')}>
				<Button
					buttonClassName={el('add-new-button')}
					label="Add new age range"
					endIcon="add"
					variant="text"
					onClick={addNewRange}
					disabled={currentGroup?.loading}
				/>
				{ageRangeErrors.overlap && (
					<div className={el('overlap-range-error')}>
						<Iconof icon="warning" className={el('error-icon')} />
						<p>Overlapping age ranges</p>
					</div>
				)}
			</div>
		</div>
	);
};

AgeDropdown.propTypes = {
	multi: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.any,
	data: PropTypes.array,
	setData: PropTypes.func,
	deleteOption: PropTypes.func,
	onCreateOption: PropTypes.func,
	onEditAgeRangeValues: PropTypes.func,
	ageRangeErrors: PropTypes.object,
	setAgeRangeErrors: PropTypes.func,
	getDemographicUuid: PropTypes.func,
	patchDemographic: PropTypes.func,
};

export default AgeDropdown;
