export const packInterlockQuotas = ({
	questions,
	currentQuestionIndex = 0,
	currentQuotas = [],
	currentIds = [],
	includeStatistics = false,
}) => {
	const currentQuestion = questions[currentQuestionIndex];
	const results = [];
	let warningLowQuota = false;
	if (currentQuestionIndex === questions.length - 1) {
		for (const option of currentQuestion.options) {
			const quota = [...currentQuotas, option.quota ?? null];
			const ids = [
				...currentIds,
				{ optionId: option.id, audienceQuestionOptionId: option.audienceQuestionOptionId },
			];
			const filledQuota = option.filledQuota || 0;
			let cumulativeQuota = quota.reduce((acc, curr) => acc * (curr ?? 1), 1);
			if (cumulativeQuota > 1) cumulativeQuota = cumulativeQuota / 100 ** (quota.length - 1);
			results.push({ quota, ids, cumulativeQuota, filledQuota });
		}
	} else {
		for (const option of currentQuestion.options) {
			const quota = [...currentQuotas, option.quota ?? null];
			const ids = [
				...currentIds,
				{ optionId: option.id, audienceQuestionOptionId: option.audienceQuestionOptionId },
			];
			const subResults = packInterlockQuotas({
				questions,
				currentQuestionIndex: currentQuestionIndex + 1,
				currentQuotas: quota,
				currentIds: ids,
				includeStatistics,
			});
			results.push(...subResults.quotas);
		}
	}
	if (!includeStatistics) return { quotas: results };
	if (results.some(({ cumulativeQuota }) => cumulativeQuota <= 5)) {
		// TODO: add logic to ask them to remove question based on filling quota speed
		let suggestedQuestionToRemove = false;
		const questionWithManyOptions = questions
			.map(({ id, options }) => ({ id, optionsCount: options.length }))
			.sort((a, b) => b.optionsCount - a.optionsCount)?.[0];
		suggestedQuestionToRemove = questionWithManyOptions;
		// @ts-ignore
		warningLowQuota = {
			suggestedQuestionToRemove,
			questionWithManyOptions,
		};
	}
	return { quotas: results, warningLowQuota };
};
