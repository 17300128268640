import * as React from 'react';

const map = new Map();

const setRef = key => {
	if (!key) {
		return console.warn(`useDynamicRefs: Cannot set ref without key `);
	}
	const ref = React.createRef();
	map.set(key, ref);
	return ref;
};

const getRef = key => {
	if (!key) {
		return console.warn(`useDynamicRefs: Cannot get ref without key`);
	}
	return map.get(key);
};
const useDynamicRefs = () => [getRef, setRef];

export default useDynamicRefs;
