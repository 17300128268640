// React
import React from 'react';

// Proptypes
import PropTypes from 'prop-types';
import modelOptions from 'src/config/modelOptions';

// Style
import 'quill/dist/quill.snow.css';
import './styles.scss';

import Quill from 'quill';
import './mentionBlot';
import quillHelper from 'src/utilities/quill';
import PipingDropdownRecalling from 'src/components/manage/PipingDropdownRecalling';

import masking from 'src/utilities/masking';
import { getAllQuestions } from 'src/domains/manage-study/components/reporting/MonadicSplit/utilities';
import QuillMentionUtil, { MAX_PLACEHOLDER_LENGTH, getQuestionSetting } from './QuillMentionUtil';

const className = 'simple-rich-text-input';

const { toolbarTypes, formatTypes } = modelOptions.richTextEditor;
class SimpleRichText extends React.Component {
	state = {
		focused: false,
		isShow: false,
		mentionCharPos: 0,
		pipeableQuestions: [],
		atValues: [],
		unsavedPipedState: false,
	};

	componentDidMount() {
		let { value, placeholder } = this.props;
		this.$editor = new Quill(this.rootRef, this.getQuilConfig());
		if (this.$editor && this.$editor.root) {
			// removes all <ul> elements that can be added during language translation import, which breaks the quill after 2.0 update
			value = value.split('<ul>').join('<ol>').split('</ul>').join('</ol>');
			this.$editor.root.innerHTML = value || '';

			// Handle change
			this.$editor.on('text-change', (delta, _1, source) => {
				const isEmpty = quillHelper.emptyCheck(this.$editor.root.innerHTML);
				if (isEmpty) {
					this.$editor.root.dataset.placeholder = '';
				}

				const { onChange } = this.props;
				const { focused } = this.state;

				if (source === 'user') {
					onChange(this.$editor.root.innerHTML);
				}

				// track mention start position
				const insertedAt = delta?.ops[1]?.insert === '@';
				// get current text to test regex with
				const currentText = this.$editor?.getText(0);
				// setup regex to identify text
				const regex = /\s*@/;
				// get current piped questions on label
				const pipedQuestions = Array.isArray(_1?.ops)
					? _1?.ops
							.filter(dlt => dlt?.insert?.mention)
							.map(({ insert }) => ({ questionId: insert?.mention?.id, logic: insert?.mention?.logic }))
					: [];
				// set the state accordingly
				this.setState(prevState => ({
					...prevState,
					// define if dropdown is shown
					isShow: focused && regex.test(currentText),
					// define if we should store the latest "@" position
					...(insertedAt ? { mentionCharPos: this?.$editor?.getSelection()?.index || 0 } : {}),
					unsavedPipedState: pipedQuestions,
				}));
			});

			// Handle blur and focus events
			this.$editor.on('selection-change', (range, _1, source) => {
				if (source === 'user' || source === 'api') {
					const { focused } = this.state;

					if (focused) {
						// get current text to test regex with
						const currentText = this.$editor?.getText(0);
						const regex = /\s*@/;
						const pipedQuestions = Array.isArray(_1?.ops)
							? _1?.ops
									.filter(dlt => dlt?.insert?.mention)
									.map(({ insert }) => ({
										questionId: insert?.mention?.id,
										logic: insert?.mention?.logic,
									}))
							: [];
						this.setState(prevState => ({
							...prevState,
							isShow: focused && regex.test(currentText), // prevent load page with all dropdowns opened
							unsavedPipedState: pipedQuestions,
						}));
					}
					if (range === null) {
						const { onBlur } = this.props;
						const classListCheck =
							window.getSelection().anchorNode.parentElement.parentElement.parentElement.classList;
						if (
							!classListCheck.contains('dropdown-recalling__position-select') &&
							!classListCheck.contains('dropdown-recalling__rank-select')
						) {
							this.setState({ focused: false });
						}
						this.$editor.root.dataset.placeholder =
							placeholder.length > MAX_PLACEHOLDER_LENGTH
								? `${placeholder.substr(0, MAX_PLACEHOLDER_LENGTH)}...`
								: placeholder;
						if (onBlur) {
							onBlur();
						}
					} else if (focused === false) {
						const { onFocus } = this.props;
						this.setState({ focused: true });
						if (value !== '') {
							this.$editor.root.dataset.placeholder = '';
						}
						if (onFocus) {
							onFocus();
						}
					}
				}
			});
		}
		// !Fixes an issue with Quill onBlur firing when you interact with the toolbar - https://github.com/quilljs/quill/issues/1290
		const quillToolbar = document.getElementsByClassName('ql-toolbar');
		if (quillToolbar && quillToolbar.length > 0) {
			quillToolbar.forEach(qt => qt.addEventListener('mousedown', e => e.preventDefault(), false));
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.sections) {
			const { question, section: propSection, sections, audience } = this.props;
			const { pipeableQuestions } = this.state;

			let sectionContent = [];
			if (sections?.content && Array.isArray(sections?.content)) {
				sectionContent = sections?.content;
			} else if (sections && Array.isArray(sections)) {
				sectionContent = sections;
			}

			const section = sectionContent.find(sect => sect?.id === propSection?.id);

			if (question && section && sections && !audience) {
				this.setState(prevState => ({
					...prevState,
					pipeableQuestions: QuillMentionUtil.getPipedQuestionsList(question, section, sections),
					atValues: QuillMentionUtil.getAtValues(pipeableQuestions),
				}));
			}
		}
	}

	// Handle value prop change
	componentDidUpdate(prevProps) {
		const { value, placeholder, resetLabel } = this.props;
		if (resetLabel) {
			this.$editor.root.innerHTML = `<p>${value}</p>`;
		}
		if (value !== prevProps.value) {
			// No need to change value if strings are the same
			if (this.$editor && this.$editor.root.innerHTML !== value) {
				this.$editor.root.innerHTML = value || '';
				if (!value) {
					this.$editor.root.dataset.placeholder = placeholder;
				}
			}
		}
	}

	componentWillUnmount() {
		const { focused } = this.state;
		const { onBlur } = this.props;
		if (focused && onBlur) {
			onBlur();
		}
	}

	setSelection = (index = 0, length = 0, source = 'api') => {
		this.$editor.setSelection(index, length, source);
	};

	getQuilConfig() {
		const { onTab, placeholder } = this.props;
		const { toolbar: toolbarConfig, toolbarType } = this.props;

		const bindings = {
			// This will overwrite the default binding also named 'tab'
			...(onTab
				? {
						tab: {
							key: 9,
							handler: onTab,
						},
						shiftTab: {
							key: 9,
							shiftKey: true,
							handler() {
								onTab({ shiftKey: true }); // onShiftTab
							},
						},
				  }
				: {}),
		};

		let toolbar = toolbarTypes.basic;
		let format = formatTypes.basic;

		if (toolbarConfig) {
			toolbar = toolbarConfig;
		} else if (toolbarType) {
			toolbar = toolbarTypes[toolbarType];
			format = formatTypes[toolbarType];
		}

		const quilConfig = {
			theme: 'snow',
			modules: {
				keyboard: {
					bindings,
				},
				toolbar,
				clipboard: { matchVisual: false },
			},
			formats: format,
			...(placeholder
				? {
						placeholder:
							placeholder.length > MAX_PLACEHOLDER_LENGTH
								? `${placeholder.substr(0, MAX_PLACEHOLDER_LENGTH)}...`
								: placeholder,
				  }
				: {}),
		};

		return quilConfig;
	}

	getAllQuestionsLocal = () => {
		const { sections } = this.props;
		if (sections?.content) return getAllQuestions(sections);
		return getAllQuestions({ content: sections });
	};

	focusTextInput = () => {
		if (this.$editor) {
			this.$editor.focus();
			this.$editor.setSelection(0, this.$editor.getLength());
		}
	};

	assignRootRef = node => (this.rootRef = node);

	prepareTag = (questionIndex, questionId, logic) => {
		const allQuestions = this.getAllQuestionsLocal();
		const currentQuestion = allQuestions.find(q => q?.id === questionId);
		const isMultiSelect = getQuestionSetting(currentQuestion, 'multi-select', false) || currentQuestion?.style === 'ranked';
		const selectedIcon = masking.getIconByLogic(logic, isMultiSelect);
		const isIdeaSplits = currentQuestion?.sectionType === 'monadic_split';

		let questionPosition = questionIndex;
		if (isIdeaSplits) {
			const ideaSplitQuestions =
				allQuestions?.filter(
					q => q?.sectionId === currentQuestion?.sectionId && q?.sectionType === 'monadic_split',
				) || [];
			questionPosition = ideaSplitQuestions?.findIndex(q => q?.id === currentQuestion?.id) + 1 || 1;
		}

		const prefix = isIdeaSplits ? 'Idea Split: Q' : 'Q';
		return {
			selectedIcon,
			value: `${prefix}${questionPosition}`,
		};
	};

	insertTag = (questionIndex, questionId, logic, position, rank) => {
		const { selectedIcon, value } = this.prepareTag(questionIndex, questionId, logic);

		const { mentionCharPos } = this.state;
		// find out where the cursor is
		this.$editor.focus();
		const cursorPos = this.$editor.getSelection().index || 0;
		const insertAtPos = mentionCharPos - 1 < 0 ? 0 : mentionCharPos - 1;
		// delete mention & filter text
		this.$editor.deleteText(insertAtPos, cursorPos - mentionCharPos + 1, Quill.sources.USER);
		// insert tag b4 it goes to DB(provision tag)
		this.$editor.insertEmbed(
			// position to start writing the tag
			insertAtPos,
			// our BLOT with the span config
			'mention',
			// the payload that goes to the span element
			{
				id: questionId,
				denotationChar: selectedIcon,
				customClass: logic !== 'selected' ? 'not-selected' : false,
				value,
				logic,
				position,
				rank
			},
			// source(triggers change event)
			Quill.sources.USER,
		);
		// add space after tag
		this.$editor.insertText(insertAtPos + 1, ' ', Quill.sources.USER);
		// move cursor to after the tag and the space
		this.$editor.setSelection(insertAtPos + 2, Quill.sources.USER);
	};

	render() {
		const {
			className: $className,
			question,
			sections,
			section,
			audience,
			filterImportQuestions,
			pipingDropdownStyle = {},
			isManageTranslation = false,
			disablePiping = false,
		} = this.props;
		const { isShow, unsavedPipedState } = this.state;
		const allQuestions = this.getAllQuestionsLocal(sections);

		let containerHeight = this.$editor?.scrollingContainer?.clientHeight + 32;
		if (pipingDropdownStyle?.top === '55px') containerHeight = this.$editor?.scrollingContainer?.clientHeight + 16;

		return (
			<>
				<div className={`${className} ${$className}`}>
					<div ref={this.assignRootRef} />
				</div>

				{isShow && !audience && !filterImportQuestions && !disablePiping && (
					<PipingDropdownRecalling
						isManageTranslation={isManageTranslation}
						containerHeight={containerHeight}
						style={pipingDropdownStyle}
						allQuestions={allQuestions}
						pipeableQuestions={QuillMentionUtil.getPipedQuestionsList(
							question,
							sections?.content
								? sections?.content?.find(sec => sec?.id === section?.id)
								: sections?.find(sec => sec?.id === section?.id),
							sections,
						)}
						currentQuestion={question}
						onChangeRecall={recallParams => {
							this.insertTag(
								recallParams?.questionIndex,
								recallParams?.maskedQuestionId,
								recallParams?.logic,
								recallParams?.position,
								recallParams?.rank,
							);
						}}
						onClose={() => {
							this.setState(prevState => ({ ...prevState, isShow: false }));
						}}
						studyType="study"
						unsavedPiping={unsavedPipedState}
						shouldBlockDuplication={false}
						sections={sections}
					/>
				)}
			</>
		);
	}
}

SimpleRichText.propTypes = {
	className: PropTypes.string,
	value: PropTypes.any,
	placeholder: PropTypes.string,
	toolbar: PropTypes.any,
	toolbarType: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onTab: PropTypes.func,
	question: PropTypes.any,
	section: PropTypes.any,
	sections: PropTypes.any,
	audience: PropTypes.any,
	filterImportQuestions: PropTypes.any,
	pipingDropdownStyle: PropTypes.any,
	isManageTranslation: PropTypes.bool,
	disablePiping: PropTypes.bool,
	resetLabel: PropTypes.bool,
};

SimpleRichText.defaultProps = {
	toolbar: null,
	toolbarType: 'standard',
};

export default SimpleRichText;
