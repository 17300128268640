import { xor } from 'lodash';
import * as actions from '../actions/reporting';
import CONSTANTS from '../../../config/constants';

const INITIAL_STATE = {
	study: null,
	languages: null,
	language: null,
	filters: null,
	localFilters: null,
	lengthOfInterview: null,

	sections: { status: 'initial', content: null },
	currentSection: { status: 'initial', content: null },

	products: null,
	productSort: 'idea',
	productSortDescending: true,
	productTags: null,
	responseData: null,
	ideaMap: null,
	error: null,
	productViewMode: 'grid',
	questionViewMode: 'grid',
	ideaMapLoading: false,
	sortBy: { sortType: 'desc', sortMethod: 'high' },

	/* Manage Languages */
	manageLanguagesModal: { loading: false, visible: false, error: null, data: null },

	/* Translations */
	translationsModal: { loading: false, visible: false, error: null },
	discardProductChangesModal: { loading: false, visible: false, error: null },
	translationPreview: null,

	/* Device preview mode */
	showDevicePreviewMode: false,
	showDevicePreviewIframeBlocker: false,
	devicePreviewMode: 'mobile',
	devicePreviewUrl: '',

	/* CustomizeReport */
	customizeReport: false,
	customizeReportId: null,
	activeBaseFilters: {},

	/* Filter Sidebar */
	showFilterSidebar: false,

	/* Launch Audience Modal */
	launchAudienceModal: { loading: false, visible: false, id: null, launchStatuses: {} },

	/* Question Reporting Asset Preview */
	questionReportingPhotoPreviewUrl: '',
	questionReportingVideoPreviewUrl: '',

	/* Idea Split Reporting Data */
	monadicSplitReportingData: null,
	monadicSplitPin: null,
	monadicSplitSortOrder: null,
	monadicSplitSortQuestionId: null,
	monadicSplitSortQuestionOptionId: null,

	/* Audience Reporting Data */
	audienceReports: null,
	showAudienceReports: null,
	demographicQuestionsReportData: null,
	audienceCollectionReportData: null,

	/* Share Of Choice */
	shareOfChoice: {},
	shareOfChoiceProgress: {},
	shareOfChoiceExistingProductIds: [],
	shareOfChoiceInnovationProductIds: [],
	shareOfChoiceView: CONSTANTS.shareOfChoice.view.card,
	shareOfChoiceSortOrder: null,
	shareOfChoiceSortDirection: null,
	shareOfChoiceExcludedProducts: [],
	shareOfChoiceDataAvailable: false,
	shareOfChoiceGroups: undefined,
	shareOfChoiceLoading: false,
	shareOfChoiceResponseCount: undefined,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_STUDY:
			return { ...state, study: action.payload.study };

		case actions.SET_LANGUAGES:
			return { ...state, languages: action.payload.languages };

		case actions.SET_LANGUAGE:
			return {
				...state,
				language: action.payload.language,
				study: { ...state.study, language: action.payload.language },
			};

		case actions.SET_STUDY_FILTERS:
			return { ...state, filters: action.payload.filters };

		case actions.SET_SECTIONS:
			return { ...state, sections: action.payload.sections };

		case actions.SET_SECTION:
			return { ...state, currentSection: { ...state.currentSection, ...action.payload.currentSection } };

		case actions.SET_SECTION_REPORT_DATA:
			return {
				...state,
				currentSection: {
					...state.currentSection,
					content: {
						...state.currentSection.content,
						reportData: {
							...state.currentSection?.content?.reportData,
							...action.payload.reportData,
						},
					},
				},
			};

		case actions.SET_PRODUCTS:
			return { ...state, products: action.payload.products };

		case actions.SET_PRODUCT_SORT:
			return {
				...state,
				productSort: action.payload.productSort,
				productSortDescending: action.payload.productSortDescending,
			};

		case actions.SET_PRODUCT_TERMS:
			return {
				...state,
				productTags: action.payload.productTags,
			};
		case actions.SET_RESPONSE_DATA:
			return { ...state, responseData: action.payload.responseData };

		case actions.SET_AUDIENCE_REPORTS: {
			const newArray = [];
			if (state.audienceReports) {
				newArray.push(...state.audienceReports);
			}
			const idx = newArray.findIndex(_item => _item.audienceUuid === action.payload.audienceUuid);
			if (idx > -1) {
				newArray[idx].data = action.payload.data;
			} else {
				newArray.push({ audienceUuid: action.payload.audienceUuid, data: action.payload.data });
			}

			return {
				...state, // copying the orignal state
				audienceReports: newArray, // reassigning  to  array
			};
		}

		case actions.CLEAR_AUDIENCE_REPORT: {
			let newArray = [];
			if (state.audienceReports) {
				newArray = state.audienceReports.filter(_item => _item.audienceUuid !== action.payload.audienceUuid);
			}

			return {
				...state,
				audienceReports: newArray,
			};
		}

		case actions.SET_SHOW_AUDIENCE_REPORTS:
			return {
				...state,
				showAudienceReports: action.payload.showReports,
			};

		case actions.SET_IDEA_MAP:
			return { ...state, ideaMap: action.payload };

		case actions.SET_ERROR:
			return { ...state, error: action.payload.error };

		case actions.SET_PRODUCT_VIEW_MODE:
			return { ...state, productViewMode: action.payload.productViewMode };

		case actions.SET_QUESTION_VIEW_MODE:
			return { ...state, questionViewMode: action.payload.questionViewMode };

		case actions.SET_IDEA_MAP_LOADING:
			return { ...state, ideaMapLoading: action.payload.isLoading };

		case actions.SET_GENERATE_REPORT_MODAL:
			return {
				...state,
				generateReportModal: { ...state.generateReportModal, ...action.payload.generateReportModal },
			};

		case actions.GENERATE_REPORT:
			return {
				...state,
				studyId: action.payload.studyId,
				data: action.payload.data,
			};

		case actions.SET_SORT_BY:
			return {
				...state,
				sortBy: action.payload.sortObject,
			};

		case actions.SET_MANAGE_LANGUAGES_MODAL:
			return {
				...state,
				manageLanguagesModal: action.payload,
			};

		case actions.SET_TRANSLATIONS_MODAL:
			return {
				...state,
				translationsModal: action.payload,
			};

		case actions.SET_TRANSLATION_PREVIEW:
			return {
				...state,
				translationPreview: action.payload,
			};

		case actions.SET_DISCARD_PRODUCT_CHANGES_MODAL:
			return {
				...state,
				discardProductChangesModal: action.payload,
			};

		case actions.SHOW_DEVICE_PREVIEW_MODE:
			return { ...state, showDevicePreviewMode: action.payload.showDevicePreviewMode };

		case actions.SET_SHOW_DEVICE_PREVIEW_IFRAME_BLOCKER:
			return { ...state, showDevicePreviewIframeBlocker: action.payload.showDevicePreviewIframeBlocker };

		case actions.SET_DEVICE_PREVIEW_MODE:
			return { ...state, devicePreviewMode: action.payload.devicePreviewMode };

		case actions.SET_DEVICE_PREVIEW_URL:
			return { ...state, devicePreviewUrl: action.payload.devicePreviewUrl };

		case actions.SET_CUSTOMIZE_REPORT:
			return {
				...state,
				customizeReport: action.payload.customizeReport,
				customizeReportId: action.payload.customizeReportId,
			};

		case actions.SET_ACTIVE_BASE_FILTERS:
			return {
				...state,
				activeBaseFilters: action.payload.activeBaseFilters,
			};

		case actions.SET_LOCAL_FILTERS: {
			if (!action.payload.questionId) {
				return {
					...state,
					localFilters: null,
				};
			}
			const {
				questionId,
				questionFilters = {
					values: [],
				},
			} = action.payload;

			const localFiltersArray = [];
			if (state.localFilters) {
				localFiltersArray.push(...state.localFilters);
			}
			const idx = localFiltersArray.findIndex(_item => _item.pipedQuestionId === questionId.toString());

			if (idx > -1) {
				// check if piped question already exists
				const pipedIdx = localFiltersArray[idx].questionFilters.findIndex(
					_pipedQuestion =>
						_pipedQuestion.questionId === questionFilters?.questionId &&
						_pipedQuestion.logic === questionFilters?.logic,
				);
				if (pipedIdx > -1) {
					// piped question exists
					if (questionFilters.values.length === 0) {
						// delete
						localFiltersArray[idx].questionFilters.splice(pipedIdx, 1);
						// also check if parent now empty, if so delete
						if (localFiltersArray[idx].questionFilters.length === 0) {
							localFiltersArray.splice(idx, 1);
						}
					} else {
						localFiltersArray[idx].questionFilters[pipedIdx] = questionFilters;
					}
				} else {
					// piped question doesnt exist
					localFiltersArray[idx].questionFilters.push(questionFilters);
				}
			} else if (questionFilters.values.length !== 0) {
				// question doesnt exist so can add
				localFiltersArray.push({
					pipedQuestionId: questionId.toString(),
					questionFilters: [questionFilters],
				});
			}

			return {
				...state, // copying the orignal state
				localFilters: localFiltersArray, // reassigning  to  array
			};
		}

		case actions.SET_LAUNCH_AUDIENCE_MODAL:
			return {
				...state,
				launchAudienceModal: { ...state.launchAudienceModal, ...action.payload.launchAudienceModal },
			};

		case actions.SET_SHOW_FILTER_SIDEBAR:
			return {
				...state,
				showFilterSidebar: action.payload.showFilterSidebar,
			};

		case actions.SET_SHOW_QUESTION_REPORTING_PHOTO_PREVIEW:
			return {
				...state,
				questionReportingPhotoPreviewUrl: action.payload.questionReportingPhotoPreviewUrl,
			};

		case actions.SET_SHOW_QUESTION_REPORTING_VIDEO_PREVIEW:
			return {
				...state,
				questionReportingVideoPreviewUrl: action.payload.questionReportingVideoPreviewUrl,
			};

		case actions.SET_MONADIC_SPLIT_REPORTING_DATA:
			return {
				...state,
				monadicSplitReportingData: action.payload.monadicSplitReportingData,
			};

		case actions.SET_MONADIC_SPLIT_PIN:
			return {
				...state,
				monadicSplitPin: action.payload.monadicSplitPin,
			};

		case actions.SET_MONADIC_SPLIT_SORT_ORDER:
			return {
				...state,
				monadicSplitSortOrder: action.payload.monadicSplitSortOrder,
			};

		case actions.SET_MONADIC_SPLIT_SORT_QUESTION_ID:
			return {
				...state,
				monadicSplitSortQuestionId: action.payload.monadicSplitSortQuestionId,
			};

		case actions.SET_MONADIC_SPLIT_SORT_QUESTION_OPTION_ID:
			return {
				...state,
				monadicSplitSortQuestionOptionId: action.payload.monadicSplitSortQuestionOptionId,
			};

		case actions.SET_FULL_SHARE_OF_CHOICE:
			return {
				...state,
				shareOfChoice: action.payload.data,
			};

		case actions.SET_SHARE_OF_CHOICE_EXISTING_PRODUCT_IDS: {
			const difference = xor(
				state.shareOfChoiceExistingProductIds,
				action.payload.shareOfChoiceExistingProductIds,
			);

			if (difference.length === 0) {
				return state;
			}

			return {
				...state,
				shareOfChoiceExistingProductIds: action.payload.shareOfChoiceExistingProductIds,
			};
		}

		case actions.SET_SHARE_OF_CHOICE_PROGRESS:
			return {
				...state,
				shareOfChoiceProgress: action.payload.progress,
			};

		case actions.SET_SHARE_OF_CHOICE_INNOVATION_PRODUCT_IDS: {
			const difference = xor(
				state.shareOfChoiceInnovationProductIds,
				action.payload.shareOfChoiceInnovationProductIds,
			);

			if (difference.length === 0) {
				return state;
			}

			return {
				...state,
				shareOfChoiceInnovationProductIds: action.payload.shareOfChoiceInnovationProductIds,
			};
		}

		case actions.SET_SHARE_OF_CHOICE_VIEW:
			return {
				...state,
				shareOfChoiceView: action.payload.shareOfChoiceView,
			};

		case actions.SET_SHARE_OF_CHOICE_SORT_ORDER:
			return {
				...state,
				shareOfChoiceSortOrder: action.payload.shareOfChoiceSortOrder,
			};

		case actions.SET_SHARE_OF_CHOICE_SORT_DIRECTION:
			return {
				...state,
				shareOfChoiceSortDirection: action.payload.shareOfChoiceSortDirection,
			};

		case actions.SET_SHARE_OF_CHOICE_EXCLUDED_PRODUCTS: {
			const difference = xor(state.shareOfChoiceExcludedProducts, action.payload.shareOfChoiceExcludedProducts);

			if (difference.length === 0) {
				return state;
			}

			return {
				...state,
				shareOfChoiceExcludedProducts: action.payload.shareOfChoiceExcludedProducts,
			};
		}

		case actions.SET_SHARE_OF_CHOICE_DATA_AVAILABLE:
			return {
				...state,
				shareOfChoiceDataAvailable: action.payload.shareOfChoiceDataAvailable,
			};

		case actions.SET_SHARE_OF_CHOICE_GROUPS:
			return {
				...state,
				shareOfChoiceGroups: action.payload.shareOfChoiceGroups,
			};

		case actions.SET_SHARE_OF_CHOICE_RESPONSE_COUNT:
			return {
				...state,
				shareOfChoiceResponseCount: action.payload.shareOfChoiceResponseCount,
			};

		case actions.RESET_SHARE_OF_CHOICE:
			return {
				...state,
				...action.payload,
				shareOfChoice: {},
				shareOfChoiceExcludedProducts: INITIAL_STATE.shareOfChoiceExcludedProducts,
				shareOfChoiceExistingProductIds: INITIAL_STATE.shareOfChoiceExistingProductIds,
				shareOfChoiceInnovationProductIds: INITIAL_STATE.shareOfChoiceInnovationProductIds,
				shareOfChoiceResponseCount: INITIAL_STATE.shareOfChoiceResponseCount,
			};

		case actions.SET_SHARE_OF_CHOICE_LOADING:
			return {
				...state,
				shareOfChoiceLoading: action.payload.shareOfChoiceLoading,
			};

		case actions.RESTORE_SHARE_OF_CHOICE:
			return {
				...state,
				shareOfChoiceExcludedProducts: action.payload.shareOfChoiceExcludedProducts,
				shareOfChoiceSortOrder: INITIAL_STATE.shareOfChoiceSortOrder,
				shareOfChoiceSortDirection: INITIAL_STATE.shareOfChoiceSortDirection,
			};

		case actions.SET_LENGTH_OF_INTERVIEW:
			return { ...state, lengthOfInterview: action.payload.lengthOfInterview };

		case actions.SET_DEMOGRAPHIC_QUESTIONS_REPORT_DATA: {
			const newArray = [];
			if (state.demographicQuestionsReportData) {
				newArray.push(...state.demographicQuestionsReportData);
			}
			const audienceCollectionAlreadyExists = newArray.findIndex(
				group => group.groupUuid === action.payload.groupUuid,
			);

			if (audienceCollectionAlreadyExists >= 0) {
				newArray[audienceCollectionAlreadyExists] = {
					groupUuid: action.payload.groupUuid,
					data: action.payload.data,
				};
			} else {
				newArray.push({ groupUuid: action.payload.groupUuid, data: action.payload.data });
			}

			return { ...state, demographicQuestionsReportData: newArray };
		}

		case actions.SET_AUDIENCE_COLLECTION_REPORT_DATA: {
			const newArray = [];
			if (state.audienceCollectionReportData) {
				newArray.push(...state.audienceCollectionReportData);
			}
			const audienceCollectionAlreadyExists = newArray.findIndex(
				collection => collection.collectionId === action.payload.collectionId,
			);

			if (audienceCollectionAlreadyExists >= 0) {
				newArray[audienceCollectionAlreadyExists] = {
					collectionId: action.payload.collectionId,
					data: action.payload.data,
				};
			} else {
				newArray.push({ collectionId: action.payload.collectionId, data: action.payload.data });
			}

			return { ...state, audienceCollectionReportData: newArray };
		}

		default:
			return state;
	}
};
