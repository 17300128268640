import React from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';

import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'template-preview-modal-url-redirect-section';

const UrlRedirectSection = ({ section }) => {
	const link = section?.linkRouting?.[0]?.url;
	return (
		<div className={className}>
			<span className={el('section-title')}>URL Redirect</span>
			<span className={el('section-name')}>{section?.name}</span>
			<div className={el('section-content')}>
				<span className={el('section-description')}>
					In this section, audience members will be sent to another URL you specify. They will then be redirected back to Upsiide to complete the survey.
				</span>
				<span className={el('section-name')}>Where to send audience members to:</span>
				<span className={el('section-description')}>URL to send audience members to</span>
				<span className={el('section-url')}>{link}</span>
			</div>
		</div>
	);
};

UrlRedirectSection.displayName = 'UrlRedirectSection';
UrlRedirectSection.propTypes = {
	section: PropTypes.any,
};

export default UrlRedirectSection;
