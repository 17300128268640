import React, { useState } from 'react';
import cn from 'src/utilities/bem-cn';
import Modal from 'src/components/shared/Modal';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import './OptionalQuestionWarningModal.scss';

const className = 'optional-question-warning-modal';
const el = (name, mod) => cn(className, name, mod);

const headerIcon = {
	icon: 'warning',
	size: 'large',
	color: 'red',
	className: el('warning-icon'),
};
export const OptionalQuestionWarningModal = React.forwardRef((_props, ref) => {
	const [show, setShow] = useState();
	const [promise, setPromise] = useState({});

	const onConfirm = () => {
		promise.resolve(false);
		setShow(false);
	};

	const onCancel = () => {
		promise.resolve(true);
		setShow(false);
	};

	const onDismiss = () => {
		promise.resolve(false);
		setShow(false);
	};

	const onShow = async () =>
		new Promise((resolve, reject) => {
			setPromise({
				resolve,
				reject,
			});
			setShow(true);
		});

	ref.current = { onShow };

	return (
		<Modal show={show} width={420} padding={0} onClose={onDismiss} stopMouseDownPropagation>
			<AreYouSureForm
				headerText="Warning"
				bodyText="This question is being recalled later in the study. Setting it to optional may result in audience members seeing empty recalls.<br/><br/>Do you want to continue?"
				confirmText="Don’t Do this"
				cancelText="Yes, Continue"
				onConfirm={onConfirm}
				onCancel={onCancel}
				headerIcon={headerIcon}
				customClass={className}
				confirmType="green"
				renderHtmlTags
			/>
		</Modal>
	);
});
OptionalQuestionWarningModal.displayName = 'OptionalQuestionWarningModal';
