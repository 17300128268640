import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Iconof, TextRowPlaceholder as TextRow } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as manageStudyActions from 'src/domains/manage-study/actions';
import audienceStatusUtil from 'src/utilities/audiences-status-error-helper';
import DemographicGroupPercentageCircle from 'src/components/shared/DemographicGroupPercentageCircle';
import completeSparkle from 'public/images/audience/study-is-complete-sparkles.svg';

import './styles.scss';

const className = 'study-is-live-banner';
const el = (name, mod) => cn(className, name, mod);

const StudyIsLiveBanner = () => {
	const dispatch = useDispatch();
	const audienceCollection = useSelector(manageStudySelectors.getAudienceCollection);
	const audienceStatusBanner = useSelector(manageStudySelectors.getAudienceStatusBanner);
	const study = useSelector(manageStudySelectors.getStudy);
	const haveLiveAudienceGroups = audienceStatusUtil.haveLiveAudienceGroups();
	const haveCompleteAudienceGroups = audienceStatusUtil.haveCompleteAudienceGroups();
	const studyHasIssues = audienceStatusUtil.studyHasIssues();
	const { header } = useSelector(state => state);

	useEffect(() => {
		if (haveLiveAudienceGroups || haveCompleteAudienceGroups) {
			dispatch(manageStudyActions.setAudienceStatusBanner(true));
		}
	}, [dispatch, haveLiveAudienceGroups, haveCompleteAudienceGroups]);

	const responsesCollectedAndTotal = useMemo(() => {
		let collected = 0;
		let total = 0;
		audienceCollection?.content?.demographicGroups
			?.filter(group => !(group?.provider === 'BYO' && group?.sampleSize === 0))
			.forEach(group => {
				if (group?.status === 'live' || group?.status === 'system-stopped' || group?.status === 'complete') {
					if (group.sampleSize) {
						collected += group.currentSample;
						total += group.sampleSize;
					} else if (!group.sampleSize && group.provider === 'BYO') {
						collected += group.currentSample;
						total += group.currentSample;
					}
				}
			});
		return { collected, total };
	}, [audienceCollection]);

	const groupHasGreaterThanZeroSample = useMemo(
		() =>
			audienceCollection?.content?.demographicGroups?.some(
				group => group?.sampleSize > 0 && (group?.status === 'live' || group?.status === 'system-stopped'),
			),
		[audienceCollection],
	);

	const allStudiesComplete = useMemo(() => {
		const studiesComplete = !!haveCompleteAudienceGroups && !haveLiveAudienceGroups;
		return studiesComplete;
	}, [haveCompleteAudienceGroups, haveLiveAudienceGroups]);

	const renderStudyHasIssues = useCallback(
		() => (
			<>
				<div className={`${el('status-outer-circle')} error`}>
					<div className="circle" />
				</div>
				<div className={el('study-issues')}>
					<Link className={el('link')} to={`/studies/${study?.uuid}/audiences`}>
						You may have issues to resolve
					</Link>
				</div>

				{groupHasGreaterThanZeroSample && !!responsesCollectedAndTotal?.total && (
					<>
						<DemographicGroupPercentageCircle
							groupId={audienceCollection.content.id}
							group={{
								currentSample: responsesCollectedAndTotal?.collected,
								sampleSize: responsesCollectedAndTotal?.total,
							}}
							innerRadius={4}
							outerRadius={8}
							height={24}
						/>
						<p className={el('completion-percentage')}>
							{responsesCollectedAndTotal?.collected && responsesCollectedAndTotal?.total
								? Math.floor(
										(responsesCollectedAndTotal.collected / responsesCollectedAndTotal.total) * 100,
								  )
								: 0}
							%
						</p>
					</>
				)}
			</>
		),
		[groupHasGreaterThanZeroSample, study, audienceCollection, responsesCollectedAndTotal],
	);

	const renderStudyComplete = useCallback(
		() => (
			<>
				<img
					className={el('emoji')}
					src={completeSparkle}
					alt="sparkles that indicate all studies are complete"
				/>
				<p className={el('text')}>Your study is complete</p>
				<Iconof className={el('completion-icon')} icon="checkmark_circle" />
				<p className={el('completion-percentage')}>100%</p>
			</>
		),
		[],
	);

	const renderStudyLive = useCallback(() => {
		if (haveLiveAudienceGroups || haveCompleteAudienceGroups)
			return (
				<>
					<div className={`${el('status-outer-circle')} live`}>
						<div className="circle" />
					</div>
					<p className={el('text')}>Your study is live and collecting responses</p>
					{groupHasGreaterThanZeroSample && !!responsesCollectedAndTotal?.total && (
						<>
							<DemographicGroupPercentageCircle
								groupId={audienceCollection.content.id}
								group={{
									currentSample: responsesCollectedAndTotal?.collected,
									sampleSize: responsesCollectedAndTotal?.total,
								}}
								innerRadius={4}
								outerRadius={8}
								height={24}
							/>
							<p className={el('completion-percentage')}>
								{responsesCollectedAndTotal?.collected && responsesCollectedAndTotal?.total
									? Math.floor(
											(responsesCollectedAndTotal.collected / responsesCollectedAndTotal.total) *
												100,
									  )
									: 0}
								%
							</p>
						</>
					)}
				</>
			);
		return null;
	}, [
		haveLiveAudienceGroups,
		haveCompleteAudienceGroups,
		groupHasGreaterThanZeroSample,
		audienceCollection,
		responsesCollectedAndTotal,
	]);

	if (header.audienceIcon && audienceCollection?.loading)
		return (
			<div className={className}>
				<TextRow color="#E0E0E0" className={el('loading')} />
			</div>
		);

	return (
		(haveLiveAudienceGroups || haveCompleteAudienceGroups) &&
		study &&
		study?.id === audienceCollection?.content?.studyId &&
		audienceStatusBanner && (
			<div className={className}>
				{studyHasIssues ? (
					renderStudyHasIssues()
				) : (
					<div className={el('study-status')}>
						{allStudiesComplete ? renderStudyComplete() : renderStudyLive()}
					</div>
				)}
				{allStudiesComplete && (
					<Iconof
						icon="clear"
						className={el('close-icon')}
						onClick={() => dispatch(manageStudyActions.setAudienceStatusBanner(false))}
					/>
				)}
			</div>
		)
	);
};

export default StudyIsLiveBanner;
