import React, { useCallback, useState, useEffect } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import SectionTypeIcon from 'src/components/shared/SectionTypeIcon';
import Auth from 'src/utilities/auth';
import { Iconof } from '@upsiide/ui-components';
import StudyDescriptionManager from '../../../StudyDescriptionManager';
import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'template-preview-modal-sidebar';

const TYPE_LABEL = {
	statement: 'Statement',
	questions: 'Questions',
	swipe: 'Idea Screen',
	monadic_split: 'Idea Splits',
	link_routing: 'URL Redirect',
};
const TYPE_ICON = {
	statement: <SectionTypeIcon type="statement" />,
	questions: <SectionTypeIcon type="questions" />,
	swipe: <SectionTypeIcon type="idea-screen" />,
	monadic_split: <SectionTypeIcon type="idea-split" />,
	link_routing: <SectionTypeIcon type="link-routing" />,
};

export const StudyAudienceDescription = ({ description, canEdit = false, studyId, audienceId }) => {
	const [showFullDescription, setShowFullDescription] = useState(false);
	const descriptionMaxLength = 90;

	if (!canEdit && !description) {
		return null;
	}

	return (
		<div className={el('description-section')}>
			<div className={el('study-description')}>
				{canEdit ? (
					<StudyDescriptionManager
						inModal
						{...(studyId ? { study: { id: studyId, description } } : {})}
						{...(audienceId ? { audience: { id: audienceId, description } } : {})}
					/>
				) : (
					<>
						<span className={el('study-description-title')}>Description</span>
						<div className={el('study-description-wrapper')}>
							{description?.length > descriptionMaxLength && !showFullDescription ? (
								<span className={el('study-description-copy')}>
									{description?.length > descriptionMaxLength
										? `${description.substr(0, descriptionMaxLength).trim()}...`
										: description}
								</span>
							) : (
								<span className={el('study-description-copy')}>{description}</span>
							)}

							{description?.length > descriptionMaxLength ? (
								<span
									className={el('study-description-more-less')}
									role="button"
									tabIndex={0}
									onKeyDown={() => {}}
									onClick={() => setShowFullDescription(!showFullDescription)}
								>
									{!showFullDescription ? ' Show More' : ' Show Less'}
								</span>
							) : null}
						</div>
					</>
				)}
			</div>
		</div>
	);
};
StudyAudienceDescription.displayName = 'StudyAudienceDescription';
StudyAudienceDescription.propTypes = {
	description: PropTypes.string,
	canEdit: PropTypes.bool,
	studyId: PropTypes.number,
	audienceId: PropTypes.number,
};

const TemplatePreviewSideBar = ({
	study,
	audience,
	mode,
	sections,
	activeSection,
	setActiveSection,
	isLoading,
	saveAudience,
	setSaveAudience,
	saveStudy,
	setSaveStudy,
	type,
	currentTab,
	setCurrentTab,
}) => {
	const saveMode = mode === 'save';
	const isDigEmployee = Auth.userCan('dig:staff', true);

	useEffect(() => {
		if (type === 'audience') {
			setSaveAudience(true);
			setSaveStudy(false);
		} else if (type === 'study') {
			setSaveAudience(false);
			setSaveStudy(true);
		}
		// only do this once on component load
		// eslint-disable-next-line
	}, []);

	const renderStudySections = useCallback(() => {
		if (!isLoading) {
			return sections?.map(section => {
				if (!isDigEmployee && section.type === 'link_routing') return;

				return (
					<div
						aria-hidden
						className={`${el('section-item')} ${section?.id === activeSection ? 'active' : ''} ${
							saveMode && !saveStudy ? 'off' : ''
						}`}
						onClick={() => {
							setActiveSection(section?.id);
							const modalBody = document.querySelector('.template-preview-modal-body');
							if (modalBody) modalBody.scroll(0, 0);
						}}
						key={`study-section-preview-modal-sidebar-id-${section?.id}`}
					>
						{TYPE_ICON[section?.type] || null}
						<span title={section?.name} className={`${el('section-item-name')}`}>
							{section?.name?.length ? section?.name : TYPE_LABEL[section?.type] || section?.type}
						</span>
						{section.type === 'link_routing' && (
							<div className={`${el('icon-lock')}`}>
								<Iconof icon="lock" className="iconof-lock" />
							</div>
						)}
					</div>
				);
			});
		}
		return [
			<div key="loading-square-0" className={`${el('section-item')} loading`} />,
			<div key="loading-square-1" className={`${el('section-item')} loading`} />,
			<div key="loading-square-2" className={`${el('section-item')} loading`} />,
		];
	}, [sections, activeSection, setActiveSection, isLoading, saveMode, isDigEmployee, saveStudy]);

	const description = study?.id ? study?.description : audience?.description;

	return (
		<div className={className}>
			{/* Remove ability to save audience with template */}
			{audience && type === 'audience' ? (
				<div className={el('top-section')}>
					{/* <span className={`${el('section-title')} ${saveMode && !saveAudience ? 'off' : ''}`}>
						{saveMode ? (
							<ToggleSwitch
								toggleCriteria={saveAudience}
								onToggle={(e, newValue) => setSaveAudience(newValue)}
							/>
						) : null}
						Audience
					</span> */}
					<span
						aria-hidden
						className={`${el('audience-item')} ${activeSection === 'demographic-groups' ? 'active' : ''} ${
							isLoading ? 'loading' : ''
						} ${saveMode && !saveAudience ? 'off' : ''}`}
						onClick={() => {
							setCurrentTab('overview');
							setActiveSection('demographic-groups');
						}}
					>
						{isLoading ? '' : 'Demographic Groups'}
					</span>
					<span
						aria-hidden
						className={`${el('audience-item')} ${activeSection === 'screening-questions' ? 'active' : ''} ${
							isLoading ? 'loading' : ''
						} ${saveMode && !saveAudience ? 'off' : ''}`}
						onClick={() => {
							setCurrentTab('screening_overview');
							setActiveSection('screening-questions');
						}}
					>
						{isLoading ? '' : 'Screening questions'}
					</span>
				</div>
			) : null}
			{study && type === 'study' ? (
				<div className={el('mid-section')}>
					<span className={`${el('section-title')} ${saveMode && !saveStudy ? 'off' : ''}`}>
						Study Sections
					</span>
					{renderStudySections()}
				</div>
			) : null}
			<StudyAudienceDescription description={description} />
		</div>
	);
};

TemplatePreviewSideBar.displayName = 'TemplatePreviewSideBar';
TemplatePreviewSideBar.propTypes = {
	study: PropTypes.any,
	audience: PropTypes.any,
	mode: PropTypes.any,
	sections: PropTypes.any,
	activeSection: PropTypes.any,
	setActiveSection: PropTypes.any,
	isLoading: PropTypes.any,
	saveAudience: PropTypes.any,
	setSaveAudience: PropTypes.any,
	saveStudy: PropTypes.any,
	setSaveStudy: PropTypes.any,
	type: PropTypes.string,
};

export default TemplatePreviewSideBar;
