import { navigate } from 'src/utilities/router/routerScopeLeaker';
import toastr from 'toastr';
import * as services from 'src/services';
import * as accountSelectors from 'src/domains/account/selectors';
import * as accountActions from 'src/domains/account/actions';
import * as accountDetailsSelectors from 'src/domains/account-details/selectors';
import * as choosePlanSelectors from 'src/domains/choose-plan/selectors';
import * as actions from '../actions';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};

const activateAccount = async (store, { type, payload }) => {
	if (type === actions.ACTIVATE_ACCOUNT) {
		const { chargifyToken } = payload;
		const chargifySubscriptions = accountSelectors.getChargifySubscription(store.getState());
		const account = accountSelectors.getAccount(store.getState());
		const { uuid } = account.content;
		const professionalSubscription = chargifySubscriptions.find(
			subscription => subscription.subscriptionHandle === 'professional',
		);
		const trialEnded = professionalSubscription.state === 'trial_ended';
		const planDetails = choosePlanSelectors.getChoosePlanDetails(store.getState());
		const monthlyPlanSelected = planDetails?.pricePointHandle === process.env.professionalMonthlyHandle;
		store.dispatch(actions.setLoading(true));
		try {
			// add credit card
			await services.accountService.addChargifyCreditCard(chargifyToken);
			if (monthlyPlanSelected) {
				// update pricepoint if monthly plan is selected
				await services.accountService.updateChargifySubscriptionPricepoint({
					plan: planDetails?.productHandle,
					planFrequency: planDetails?.planFrequency,
				});
			}
			if (trialEnded) {
				// trial has ended, hit activate endpoint
				await services.accountService.activateChargifySubscription();
			} else {
				// pending cancelation, hit reactivate endpoint
				await services.accountService.reactivateChargifySubscription();
			}
			await store.dispatch(accountActions.fetchAccount(uuid, false));
			await store.dispatch(accountActions.fetchAccounts());
			await store.dispatch(actions.setLoading(false));
			navigate('/account/billing');
		} catch (e) {
			toastr.error('Something went wrong, try again later');
		}
	}
};

const previewAccountCreation = async (store, { type, payload }) => {
	if (type === actions.PREVIEW_ACCOUNT_CREATION) {
		const { chargifyToken } = payload;
		store.dispatch(actions.setLoading(true));
		try {
			if (chargifyToken) {
				const accountDetails = accountDetailsSelectors.getAccountDetails(store.getState());
				const planDetails = choosePlanSelectors.getChoosePlanDetails(store.getState());
				const previewResp = await services.userService.previewSubscription({
					...accountDetails,
					...planDetails,
					chargifyToken,
				});
				store.dispatch(actions.setLoading(false));
				store.dispatch(actions.setPreviewSubscriptionInfo(previewResp.data));
			} else {
				store.dispatch(actions.setLoading(false));
				store.dispatch(actions.setPreviewSubscriptionInfo({}));
			}
		} catch (e) {
			// todo test toastr
			toastr.error('Something went wrong, try again later');
		}
	}
};

const previewSubscriptionWithCardOnFile = async (store, { type, payload }) => {
	if (type === actions.PREVIEW_SUBSCRIPTION_WITH_CARD_ON_FILE) {
		store.dispatch(actions.setLoading(true));
		const subscriptions = accountSelectors.getChargifySubscription(store.getState());
		try {
			if (subscriptions?.length) {
				const { productHandle, pricePointHandle } = choosePlanSelectors.getChoosePlanDetails(store.getState());
				const previewResp = await services.userService.previewSubscriptionWithCardOnFile({
					productHandle,
					pricePointHandle,
				});
				await store.dispatch(actions.setPreviewSubscriptionInfo(previewResp.data));
				store.dispatch(actions.setLoading(false));
			} else {
				store.dispatch(actions.setLoading(false));
				store.dispatch(actions.setPreviewSubscriptionInfo({}));
			}
		} catch (e) {
			// todo test toastr
			toastr.error('Something went wrong, try again later');
		}
	}
};

export default [previewAccountCreation, activateAccount, previewSubscriptionWithCardOnFile];
