/**
 * This is a file to define constants related to the database, whether it's field values, or column names, etc.
 */
export default {
	questions: {
		settings: {
			randomizeOptions: 'randomizeOptions',
			isFilter: 'isFilter',
			hasNoneOfTheAboveOption: 'hasNoneOfTheAboveOption',
			hasOtherSpecifyOption: 'hasOtherSpecifyOption',
			addScale: 'addScale',
		},
		class: {
			age: 'age',
			gender: 'gender',
			region: 'region',
		},
		options: {
			isNoneOfTheAbove: 'isNoneOfTheAbove',
			isOtherSpecify: 'isOtherSpecify',
			lockOrder: 'lockOrder',
			defaults: {
				noneOfTheAbove: 'None of the above',
				otherSpecify: 'Other (please specify)',
			},
			style: {
				singleSelect: 'single-select',
				multiSelect: 'multi-select',
				openEnded: 'open-ended',
				grid: 'grid',
				emoji: 'emoji',
				ranked: 'ranked',
			},
		},
		attributes: {
			lockOrder: 'lockOrder',
		},
		emojiOptions: [
			'joy',
			'love',
			'hope',
			'trust',
			'anxious',
			'guilt',
			'fear',
			'amazed',
			'curious',
			'surprised',
			'disapproval',
			'despair',
			'sad',
			'regret',
			'disbelief',
			'disgust',
			'annoyed',
			'jealous',
			'anger',
			'aggressive',
			'cynicism',
			'anticipation',
			'optimistic',
			'proud',
			'shocked',
		],
	},
	sections: {
		type: {
			questions: 'questions',
			ideaSplit: 'monadic_split',
			statement: 'statement',
		},
	},
	ideaScreenSections: {
		type: {
			ideas: 'ideas',
			barChart: 'barchart',
			quadrantChart: 'qchart',
			ideaMap: 'ideamap',
			marketSimulator: 'marketsimulator',
		},
	},
	pipingReference: 'pipedBlock', // references the text used for piping e.g. {{pipedBlock: 123}}
	pipingQuestionId: 'pipingQuestionId', // references the text used for piping e.g. {{pipingQuestionId: 123}}
	pipingLogic: 'logic', // references the text used for piping e.g. {{logic: selected}}
	pipingPosition: 'position', // references the text used for piping e.g. {{position: top}}
	pipingRank: 'rank', // references the text used for piping e.g. {{rank: 5}}
	rankingLimit: 10,
	formattingOptions: {
		statement: 'statement',
		questionStatement: 'question',
		idea: 'idea',
		questionOption: {},
		none: 'none',
	},
	selfSignupSteps: {
		confirmSignup: '/confirm-signup',
		accountDetails: '/account-details',
		choosePlan: '/choose-plan',
		billingDetails: '/billing-details',
	},
	shareOfChoice: {
		progress: 'progress',
		estimatedTimeLeft: 'estimatedTimeLeft',
		hasPreviousReportAvailable: 'hasPreviousReportAvailable',
		freshDataAvailable: 'freshDataAvailable',
		progressWarning: 'progressWarning',
		productTourSettingsKey: 'market-simulator-product-tour-viewed',
		fullSimulatorProductTour: 354583,
		allIdeasProductTour: 353746,
		view: {
			table: 'table',
			card: 'card',
		},
		sortOrder: {
			name: 'name',
			share: 'share',
			percent: 'percent',
			change: 'change',
		},
		sortDirection: {
			ascending: 'asc',
			descending: 'desc',
		},
		sortDirectionDefault: {
			name: 'asc',
			share: 'desc',
			change: 'desc',
			percent: 'desc',
		},
		categoryColors: [
			'78C0B3',
			'6262FF',
			'E4B9E0',
			'5FCDFC',
			'B88061',
			'A196EB',
			'178AB5',
			'0D7B59',
			'D1B094',
			'3D3DB0',
			'FA84A0',
			'26D9A1',
			'972EA0',
			'684924',
			'A0809D',
			'ADCF2B',
			'E8E14E',
			'BD6868',
			'91AC99',
			'EC70C9',
		],
	},
	minimumBase: 20,
	screenSize: {
		screenSmMin: 576,
		screenMdMin: 768,
		screenTabletMin: 960,
		screenSelfSignupLoginMdMin: 1150,
	},
	imageAlignmentSettings: {
		statementSection: 'statement_image_align',
		questionSection: 'question_image_align',
		leftAlign: 'left',
		centerAlign: 'center',
	},
	lzCondensedEmptyFilter: 'N4XyA',
	placeholderNames: {
		audienceCollection: 'Untitled Audience',
	},
};
