import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import useArtificialBlur from 'src/hooks/useArtificialBlur';
import DropdownRecalling from 'src/components/manage/DropdownRecalling';

const className = 'unstyled-textarea';

const UnstyledTextarea = props => {
	const {
		className: customClassName,
		id,
		value,
		placeholder,
		onChange,
		onKeyDown,
		disabled,
		onBlur,
		onFocus,
		question,
		onChangeMasking,
		studyType,
		isMasking,
	} = props;

	const [isShow, setIsShow] = useState(false);
	const inputRef = useRef();

	const replacePlaceholder = useMemo(
		() => !placeholder || placeholder === 'Type answer here...' || placeholder === 'Type option here...',
		[placeholder],
	);

	const adjustHeight = () => {
		const { current } = inputRef;
		current.style.height = `1px`;
		current.style.height = `${current.scrollHeight}px`;
	};

	const change = e => {
		adjustHeight();
		onChange(e);
		if (isMasking) {
			const searchTerm = e.target.value.toLowerCase();
			const regex = /@?q{1}[0-9]/;
			if ((searchTerm === '@' || searchTerm === '@q' || regex.test(searchTerm)) && !isShow) setIsShow(true);
			if (searchTerm.indexOf(' ') !== -1 || searchTerm === '') setIsShow(false);
		}
	};

	const keydown = e => {
		if (onKeyDown) {
			onKeyDown(e);
		}
	};

	useLayoutEffect(() => {
		adjustHeight();
	});

	useArtificialBlur(inputRef, e => {
		onBlur(e);
	});

	return (
		<>
			<textarea
				id={id}
				ref={inputRef}
				className={`${className} ${customClassName}`}
				placeholder={isMasking && replacePlaceholder ? 'Type here or use @ for recall' : placeholder}
				value={value || ''}
				onChange={change}
				onKeyDown={keydown}
				disabled={disabled}
				onFocus={e => {
					onFocus(e);
				}}
				onBlur={() => {}}
			/>
			{isShow && (
				<DropdownRecalling
					currentQuestion={question}
					onChangeMasking={onChangeMasking}
					onClose={() => setIsShow(false)}
					studyType={studyType}
					value={inputRef?.current?.value.toLowerCase() || ''}
				/>
			)}
		</>
	);
};

UnstyledTextarea.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onKeyDown: PropTypes.func,
	disabled: PropTypes.bool,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	question: PropTypes.object,
	onChangeMasking: PropTypes.func,
	studyType: PropTypes.string,
	isMasking: PropTypes.bool,
};

UnstyledTextarea.defaultProps = {
	value: '',
	placeholder: '',
	onChange: null,
	isMasking: false,
};

export default UnstyledTextarea;
