import React from 'react';

import './styles.scss';

export default ({ ...props }) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
		<g fill="none" fillRule="evenodd">
			<path
				fill="#FFF"
				d="M0 6.88c0 3.793 3.087 6.88 6.88 6.88 3.793 0 6.88-3.087 6.88-6.88C13.76 3.087 10.673 0 6.88 0 3.087 0 0 3.087 0 6.88z"
			/>
			<g>
				<path
					fill="#FF3C41"
					d="M5.882 0c3.244 0 5.883 2.638 5.883 5.882 0 3.244-2.639 5.883-5.883 5.883S0 9.126 0 5.882 2.638 0 5.882 0z"
					transform="translate(1 1)"
				/>
				<path
					fill="#FFF"
					d="M5.843 7.804c.542 0 .98-.44.98-.98V1.922c0-.542-.438-.98-.98-.98s-.98.438-.98.98v4.902c0 .54.438.98.98.98zM5.843 10.745c.255 0 .51-.108.696-.284.177-.186.285-.441.285-.696 0-.255-.108-.51-.285-.696-.372-.363-1.03-.363-1.392 0-.177.186-.284.44-.284.696 0 .255.107.51.284.696.185.176.441.284.696.284z"
					transform="translate(1 1)"
				/>
			</g>
		</g>
	</svg>
);
