import React from 'react';

const LockClosed = () => (
	<svg className="lock-closed" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
		<g fill="none" fillRule="evenodd">
			<path
				fill="#666"
				d="M12 5.333h-.667V4C11.333 2.16 9.84.667 8 .667S4.667 2.16 4.667 4v1.333H4c-.733 0-1.333.6-1.333 1.334v6.666c0 .734.6 1.334 1.333 1.334h8c.733 0 1.333-.6 1.333-1.334V6.667c0-.734-.6-1.334-1.333-1.334zm-4 6c-.733 0-1.333-.6-1.333-1.333S7.267 8.667 8 8.667s1.333.6 1.333 1.333-.6 1.333-1.333 1.333zm2.067-6H5.933V4c0-1.14.927-2.067 2.067-2.067 1.14 0 2.067.927 2.067 2.067v1.333z"
			/>
		</g>
	</svg>
);

export default LockClosed;
