import React, { useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { Iconof } from '@upsiide/ui-components';
import { useIsPublic, useIsBlueprint } from 'src/hooks';
import { blueprintService, audienceService, studyService } from 'src/services';
import cn from 'src/utilities/bem-cn';
import Auth from 'src/utilities/auth';
import audienceStatusUtil from 'src/utilities/audiences-status-error-helper';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import StudyImage from 'src/components/shared/SearchResults/shared/StudyImage';
import Modal from 'src/components/shared/Modal';
import { TemplateCardsContext } from 'src/components/shared/TemplateCard/context';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as manageStudyActions from 'src/domains/manage-study/actions';

import './styles.scss';

const className = 'header-navigation-bar';
const el = (name, mod) => cn(className, name, mod);

const HeaderNavigationBar = ({ hasReportData, IRIssue }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const location = useLocation();
	const { isBlueprint } = useIsBlueprint();
	const { isPublic } = useIsPublic();
	const { header } = useSelector(state => state);
	const [showCreateEllipsisButton, setShowCreateEllipsisButton] = useState(false);
	const [showLaunchEllipsisButton, setShowLaunchEllipsisButton] = useState(false);
	const [showCreateMenu, setShowCreateMenu] = useState(false);
	const [showLaunchMenu, setShowLaunchMenu] = useState(false);
	const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);
	const studyTemplateApplied = useSelector(manageStudySelectors.getStudyTemplateApplied);
	const audienceCollectionTemplateApplied = useSelector(manageStudySelectors.getAudienceCollectionTemplateApplied);
	const activeTab = useSelector(manageStudySelectors.getActiveStep);
	const study = useSelector(manageStudySelectors.getStudy);
	const sections = useSelector(manageStudySelectors.getSections);
	const currentSection = useSelector(manageStudySelectors.getCurrentSection);
	const audienceCollection = useSelector(manageStudySelectors.getAudienceCollection);
	const userCanStudyUpdate = Auth.userCan('study:update');
	const haveLiveAudienceGroups = audienceStatusUtil.haveLiveAudienceGroups();
	const haveCompleteAudienceGroups = audienceStatusUtil.haveCompleteAudienceGroups();

	const studyIsLive = study?.status !== 'draft' || haveLiveAudienceGroups || haveCompleteAudienceGroups;

	const { handleSetStep } = useContext(TemplateCardsContext);

	useEffect(() => {
		if (location.pathname.includes('/create') && !location.pathname.includes('audiences')) {
			setShowLaunchEllipsisButton(false);
			setShowCreateEllipsisButton(true);
		}
		if (location.pathname.includes('/audiences')) {
			setShowLaunchEllipsisButton(true);
			setShowCreateEllipsisButton(false);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (study?.templateBlueprintId && study?.templateBlueprintType === 'blueprint') {
			fetchBlueprint(study?.templateBlueprintId);
		}
	}, [study?.templateBlueprintId, study?.templateBlueprintType]);

	useEffect(() => {
		if (audienceCollection?.content?.parentId) {
			fetchAudienceCollectionTemplate(audienceCollection?.content?.parentId);
		}
	}, [audienceCollection?.content?.parentId, fetchAudienceCollectionTemplate]);

	useEffect(() => {
		const bodyElement = document.querySelector('body');
		bodyElement.addEventListener('click', handleClick);

		return () => {
			bodyElement.removeEventListener('click', handleClick);
		};
	}, [handleClick, showCreateEllipsisButton, showLaunchEllipsisButton]);

	const handleClick = useCallback(e => {
		if (
			!e.target.classList.contains('header-navigation-bar__menu-item') &&
			!e.target.classList.contains('header-navigation-bar__menu') &&
			!e.target.classList.contains('header-navigation-bar__ellipsis-menu')
		) {
			setShowCreateMenu(false);
			setShowLaunchMenu(false);
		}
	}, []);

	const fetchBlueprint = async templateBlueprintId => {
		try {
			const result = await blueprintService.getBlueprint(templateBlueprintId);
			dispatch(manageStudyActions.setStudyTemplateApplied(result?.data));
		} catch (e) {
			console.error(e);
		}
	};

	const fetchAudienceCollectionTemplate = useCallback(
		async audienceCollectionId => {
			try {
				const result = await audienceService.getCollection(study?.id, audienceCollectionId);
				dispatch(manageStudyActions.setAudienceCollectionTemplateApplied(result?.data));
			} catch (e) {
				console.error(e);
			}
		},
		[study?.id, dispatch],
	);

	const isModeSelected = modeKey => {
		const modes = {
			report: () => location.pathname.includes('/report'),
			launch: () => location.pathname.includes('/audiences'),
			create: () => location.pathname.includes('/create') && !location.pathname.includes('audiences'),
		};

		return modes[modeKey]?.();
	};

	const audienceIcon = useMemo(() => {
		if (header.audienceErrorIcon) return 'warning';
		if (!header.audienceIcon) return 'circle_outlined';
		return 'checkmark_circle';
	}, [header]);

	const createIcon = useMemo(() => {
		if (header.createErrorIcon) return 'warning';
		if (!header.createIcon) return 'circle_outlined';
		return 'checkmark_circle';
	}, [header]);

	const reportIcon = useMemo(() => {
		if (IRIssue) return 'warning';
		if (!header.reportIcon) return 'circle_outlined';
		return 'checkmark_circle';
	}, [header, IRIssue]);

	const wizardStudyIcon = !header?.wizardStudyIcon ? 'circle_outlined' : 'checkmark_circle';

	const wizardAudienceIcon = !header?.wizardAudienceIcon ? 'circle_outlined' : 'checkmark_circle';

	const studiesOrBlueprints = isBlueprint ? 'templates' : 'studies';

	const openAllStudyTemplates = useCallback(() => {
		setShowCreateMenu(false);
		handleSetStep('templates', {
			visible: true,
			previewMode: 'preview',
			isMultiStep: true,
			defaultTabOverride: 'studyTemplate',
		});
	}, [handleSetStep]);

	const saveAsStudyTemplate = useCallback(async () => {
		if (study?.translations?.length === 1 && study?.defaultLanguage === 'en' && sections?.content?.length > 0) {
			const studyTranslationId = study?.translations?.[0]?.id;
			const res = await studyService.detectStudyLanguage(study?.id, studyTranslationId);
			if (res?.data?.hasUpdatedLanguage) {
				const updatedStudy = await studyService.getStudy(study?.id);
				dispatch(
					manageStudyActions.setStudy({
						...updatedStudy.data,
						defaultLanguage: updatedStudy?.data?.defaultLanguage,
						currentLanguage: updatedStudy?.data?.currentLanguage,
						languages: updatedStudy?.data?.languages,
						translations: updatedStudy?.data?.translations,
					}),
				);
				dispatch(manageStudyActions.setLanguage(updatedStudy?.data?.currentLanguage));
				dispatch(manageStudyActions.fetchSections(study?.id));
				dispatch(manageStudyActions.fetchSection(currentSection?.content?.id));
			}
		}
		setShowCreateMenu(false);
		const templateData = {
			id: study?.id,
			type: 'study',
			study,
		};

		handleSetStep('preview', {
			visible: true,
			selectedTemplate: templateData,
			previewMode: 'save',
			isMultiStep: false,
		});
	}, [currentSection?.content?.id, dispatch, handleSetStep, sections?.content?.length, study]);

	const seeAllAudienceTemplates = () => {
		handleSetStep('templates', {
			template: null,
			visible: true,
			step: 'templates',
			defaultTabOverride: 'audienceTemplate',
		});
		setShowLaunchMenu(false);
	};

	const renderSelectTemplateWarningModal = useCallback(() => {
		const headerText = 'Select Template';
		const bodyText = (
			<p className={el('select-template')}>
				If you apply a template, your current Study content will be overwritten. Would you like to proceed?
			</p>
		);
		return (
			<Modal
				show={showSelectTemplateModal}
				width={600}
				padding={0}
				onClose={() => setShowSelectTemplateModal(false)}
				customModalClass="apply-template-modal"
			>
				<AreYouSureForm
					onCancel={() => {
						setShowSelectTemplateModal(false);
					}}
					onConfirm={() => {
						openAllStudyTemplates();
						setShowSelectTemplateModal(false);
					}}
					headerText={headerText}
					bodyText={bodyText}
					confirmText="Continue"
					confirmState="active"
					confirmType="red"
				/>
			</Modal>
		);
	}, [openAllStudyTemplates, showSelectTemplateModal]);

	if (isPublic || !userCanStudyUpdate) return;
	const overrideProps = isModeSelected('report') ? { 'aria-current': 'page' } : {};

	return (
		<div className={className}>
			{study &&
				(study?.settings?.preStudyCheck ? (
					<>
						<div
							aria-hidden
							onClick={e => {
								if (!e.target.classList.contains('tio-more_vertical')) {
									navigate(`/${studiesOrBlueprints}/${id}/create`);
								}
							}}
							className={`${el('navigation-bar-item', isModeSelected('create') ? 'selected' : '')} ${el(
								'create-button',
							)}`}
						>
							<Iconof
								icon={createIcon}
								size="medium"
								className={header.createErrorIcon ? el('warning') : ''}
							/>
							Create
							{showCreateEllipsisButton ? (
								<Iconof
									icon="more_vertical"
									className={`${el('ellipsis-menu')} ${el('create-ellipsis')}`}
									onClick={() => setShowCreateMenu(!showCreateMenu)}
								/>
							) : (
								<div className={el('space-placeholder')} />
							)}
							{showCreateMenu && (
								<div className={`${el('menu')} ${el('create-menu')}`}>
									{studyTemplateApplied && (
										<div className={el('template-applied')}>
											<p className={el('template-title')}>Template Applied</p>
											<div className={el('template-container')}>
												{((studyTemplateApplied?.asset && studyTemplateApplied?.assetId) ||
													studyTemplateApplied?.emoji) && (
													<div className={el('template-image-emoji')}>
														<StudyImage
															asset={studyTemplateApplied?.asset}
															emoji={studyTemplateApplied?.emoji}
														/>
													</div>
												)}
												<p className={el('template-name')}>
													{studyTemplateApplied?.name || 'Untitled Study Template'}
												</p>
											</div>
										</div>
									)}
									<div className={el('menu-items-container')}>
										<div
											className={el('menu-item')}
											role="button"
											onKeyDown={() => {}}
											tabIndex={0}
											onClick={saveAsStudyTemplate}
										>
											Save as Template
										</div>
										<div
											className={`${el('menu-item')} ${studyIsLive ? 'disabled' : ''}`}
											aria-hidden
											onClick={() => {
												if (!studyIsLive) {
													setShowSelectTemplateModal(true);
													setShowCreateMenu(false);
												} else {
													toastr.error(
														'Cannot apply a template to a study that is not in draft',
													);
												}
											}}
										>
											Apply Template
										</div>
									</div>
								</div>
							)}
						</div>
						<div className={el('divider', hasReportData ? 'selected' : '')} />
						<div
							aria-hidden
							onClick={e => {
								if (!e.target.classList.contains('tio-more_vertical')) {
									navigate(`/${studiesOrBlueprints}/${id}/audiences?tab=audienceTemplate`);
								}
							}}
							className={`${el('navigation-bar-item', isModeSelected('launch') ? 'selected' : '')} ${el(
								'launch-button',
							)}`}
						>
							<Iconof
								icon={audienceIcon}
								size="medium"
								className={header.audienceErrorIcon ? el('warning') : ''}
							/>
							Launch
							{showLaunchEllipsisButton ? (
								<Iconof
									icon="more_vertical"
									className={`${el('ellipsis-menu')} ${el('launch-ellipsis')}`}
									onClick={() => {
										setShowLaunchMenu(!showLaunchMenu);
									}}
								/>
							) : (
								<div className={el('space-placeholder')} />
							)}
							{showLaunchMenu && (
								<div className={`${el('menu')} ${el('launch-menu')}`}>
									{audienceCollectionTemplateApplied && (
										<div className={el('template-applied')}>
											<p className={el('template-title')}>Template Applied</p>
											<div className={el('template-container')}>
												<p className={el('template-name')}>
													{audienceCollectionTemplateApplied?.name ||
														'Untitled Audience Template'}
												</p>
											</div>
										</div>
									)}
									<div className={el('menu-items-container')}>
										<div
											className={el('menu-item')}
											role="button"
											onKeyDown={() => {}}
											tabIndex={0}
											onClick={() => {
												setShowLaunchMenu(false);
												const templateData = {
													type: 'audience',
													audienceCollectionId: audienceCollection?.content?.id,
													id: study?.id,
													study,
												};
												handleSetStep('preview', {
													visible: true,
													selectedTemplate: templateData,
													previewMode: 'save',
													isMultiStep: false,
												});
											}}
										>
											Save as Template
										</div>
										<div
											className={`${el('menu-item')} ${studyIsLive ? 'disabled' : ''}`}
											aria-hidden
											onClick={() => {
												seeAllAudienceTemplates();
											}}
										>
											Apply Template
										</div>
									</div>
								</div>
							)}
						</div>
						{!isBlueprint && (
							<>
								<div className={el('divider', hasReportData ? 'selected' : '')} />
								<Link
									to={`/studies/${id}`}
									className={el('navigation-bar-item', isModeSelected('report') ? 'selected' : '')}
									{...overrideProps}
								>
									<Iconof icon={reportIcon} size="medium" className={IRIssue ? el('warning') : ''} />
									Report
								</Link>
							</>
						)}
					</>
				) : (
					<>
						<div
							className={`${el(
								'navigation-bar-item',
								activeTab === 'studyTemplate' ? 'selected' : '',
							)} ${el('wizard-bar-item')}`}
							onClick={() => dispatch(manageStudyActions.setActiveStep('studyTemplate'))}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									dispatch(manageStudyActions.setActiveStep('studyTemplate'));
								}
							}}
							tabIndex={0}
							role="button"
						>
							<Iconof icon={wizardStudyIcon} size="medium" />
							Select Study Template
						</div>
						<div className={el('divider', hasReportData ? 'selected' : '')} />
						<div
							className={`${el(
								'navigation-bar-item',
								activeTab === 'audienceTemplate' ? 'selected' : '',
							)} ${el('wizard-bar-item')}`}
							onClick={() => dispatch(manageStudyActions.setActiveStep('audienceTemplate'))}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									dispatch(manageStudyActions.setActiveStep('audienceTemplate'));
								}
							}}
							tabIndex={0}
							role="button"
						>
							<Iconof icon={wizardAudienceIcon} size="medium" />
							Select Audience Template
						</div>
					</>
				))}
			{renderSelectTemplateWarningModal()}
		</div>
	);
};

HeaderNavigationBar.displayName = 'HeaderNavigationBar';
HeaderNavigationBar.propTypes = {
	hasReportData: PropTypes.bool,
	studyHasIssues: PropTypes.bool,
	IRIssue: PropTypes.bool,
};

export default HeaderNavigationBar;
