import _ from 'lodash';
import { getAllQuestions } from 'src/domains/manage-study/components/reporting/MonadicSplit/utilities';

const round = (value, decimals) => Number(`${Math.round(`${Number(value)}e${decimals}`)}e-${decimals}`);

export const sortProducts = (products, sortBy = 'idea', descending) => {
	products.sort((a, b) => {
		const aValue = sortBy === 'name' ? a[sortBy].toLowerCase() : Math.round(a[sortBy]);
		const bValue = sortBy === 'name' ? b[sortBy].toLowerCase() : Math.round(b[sortBy]);

		// If equal, fallback of name
		if (aValue === bValue) {
			// If names are also equal, fallback to id
			if (a.name.toLowerCase() !== b.name.toLowerCase()) {
				return a.name.toLowerCase() > b.name.toLowerCase() || -1;
			}
			return a.id > b.id || -1;
		}
		if (descending) {
			return aValue < bValue || -1;
		}

		return aValue > bValue || -1;
	});
	return products;
};

export const attachResponseDataToProducts = (responseData, products, addOriginals = false) =>
	(products || []).map(product => {
		const data = responseData.products.filter(dataItem => dataItem.productId === product.id);

		if (data && data[0]) {
			const productData = data[0];
			return {
				...product,
				idea: round(productData.idea, 4),
				interest: round(productData.interest, 4),
				commitment: round(productData.commitment, 4),
				...(addOriginals
					? {
							originals: {
								idea: round(productData.idea, 4),
								interest: round(productData.interest, 4),
								commitment: round(productData.commitment, 4),
							},
					  }
					: {}),
			};
		}
		// No responseData match for this product.
		return {
			...product,
			idea: 0,
			interest: 0,
			commitment: 0,
			// Allow originals to be maintained
			originals: {
				idea: product.originals ? product.originals.idea : 0,
				interest: product.originals ? product.originals.interest : 0,
				commitment: product.originals ? product.originals.commitment : 0,
			},
		};
	}, []);

export const attachProductsToIdeaMapData = (ideaMapData, products) => {
	if (products && products.length > 0) {
		Object.keys(ideaMapData).forEach(productId => {
			const productItem = products.find(product => product.id == productId);
			if (productItem) {
				ideaMapData[productId].name = productItem.name;
				ideaMapData[productId].idea = productItem.idea;
			}
			// TODO v2.1 - add category colours/indexes
		});
	}

	return ideaMapData;
};

export const ideaScoreRating = (score, index, normMin = null, normMax = null) => {
	if (normMin && parseInt(normMax) !== 0) {
		if (score >= normMax) return 'high';
		if (score <= normMin) return 'low';
		return 'normal';
	}

	if (index > 0) {
		const result = score / index;
		if (result >= 1.2) {
			return 'high';
		}
		if (result <= 0.8) {
			return 'low';
		}
		return 'normal';
	}
	return '';
};

export const renderIdeaScore = (score, index) => {
	const scoreClass = ideaScoreRating(score, index);
	return <span className={`idea ${scoreClass}`}>{score}</span>;
};

/**
 * format filters before calling backend
 * @param {*} filters
 * @returns
 */
export const formatFilters = filters => {
	const formattedFilters = [];
	let audienceFilter = [];
	if (filters) {
		Object.keys(filters).forEach(questionId => {
			const values = filters[questionId];
			if (questionId === 'audience') {
				// Audience Filter
				audienceFilter = values;
			} else if (values && values.length) {
				// Normal Filters
				formattedFilters.push({ questionId, values });
			} else if (values && values.attributeIds && values.attributeIds.length > 0) {
				// Attribute Filters
				const qId = questionId.split('-')[0];
				const attributeId = questionId.split('-')[1];

				formattedFilters.push({
					questionId: qId,
					values: values.value,
					attributeIds: [parseInt(attributeId)],
				});
			} else if (values && values.rank && values.value?.length) {
				formattedFilters.push({
					questionId,
					values: values.value,
					rank: parseInt(values.rank),
				});
			} else if (values && values.min && values.max) {
				// Age Filter
				formattedFilters.push({ questionId, ...values });
			}
		});
	}
	return { formattedFilters, audienceFilter };
};

export const bem = (block, element, modifier) =>
	`${block}${element ? `__${element}` : ''}${modifier ? `--${modifier}` : ''}`;

export const checkLayoutCompatibility = (currentLayout, destinationLayout) => {
	if (currentLayout === destinationLayout) {
		return 'Compatible';
	}
	const compatibilityMap = {
		'textOnly->imageOnly': 'Incompatible',
		'textOnly->doubleText': 'Compatible',
		'textOnly->sideBySide': 'Compatible',
		'textOnly->titleTop': 'Compatible',
		'textOnly->noTitle': 'Compatible',
		'textOnly->titleBottom': 'Compatible',

		'imageOnly->textOnly': 'Incompatible',
		'imageOnly->doubleText': 'Incompatible',
		'imageOnly->sideBySide': 'Compatible',
		'imageOnly->titleTop': 'Compatible',
		'imageOnly->noTitle': 'Compatible',
		'imageOnly->titleBottom': 'Compatible',

		'doubleText->imageOnly': 'Incompatible',
		'doubleText->textOnly': 'Partially Compatible',
		'doubleText->sideBySide': 'Compatible',
		'doubleText->titleTop': 'Compatible',
		'doubleText->noTitle': 'Partially Compatible',
		'doubleText->titleBottom': 'Compatible',

		'sideBySide->imageOnly': 'Partially Compatible',
		'sideBySide->textOnly': 'Partially Compatible',
		'sideBySide->doubleText': 'Partially Compatible',
		'sideBySide->titleTop': 'Compatible',
		'sideBySide->noTitle': 'Partially Compatible',
		'sideBySide->titleBottom': 'Compatible',

		'titleTop->imageOnly': 'Partially Compatible',
		'titleTop->textOnly': 'Partially Compatible',
		'titleTop->doubleText': 'Partially Compatible',
		'titleTop->sideBySide': 'Compatible',
		'titleTop->noTitle': 'Partially Compatible',
		'titleTop->titleBottom': 'Compatible',

		'noTitle->imageOnly': 'Partially Compatible',
		'noTitle->textOnly': 'Partially Compatible',
		'noTitle->doubleText': 'Partially Compatible',
		'noTitle->sideBySide': 'Compatible',
		'noTitle->titleTop': 'Compatible',
		'noTitle->titleBottom': 'Compatible',

		'titleBottom->imageOnly': 'Partially Compatible',
		'titleBottom->textOnly': 'Partially Compatible',
		'titleBottom->doubleText': 'Partially Compatible',
		'titleBottom->sideBySide': 'Compatible',
		'titleBottom->titleTop': 'Compatible',
		'titleBottom->noTitle': 'Partially Compatible',
	};

	return compatibilityMap[`${currentLayout}->${destinationLayout}`] || 'Incompatible';
};

export const shouldShowMeanScoreScale = question =>
	(question?.style === 'single-select' ||
		((question?.style === 'multi-select' || question?.style === 'grid') &&
			question?.settings?.find(({ label }) => label === 'multi-select')?.value !== 'true')) &&
	!!question?.addScale;

export const isExcludedMeanScoreOption = option =>
	!!option?.isOtherSpecify || !!option?.isNoneOfTheAbove || !!option?.maskedQuestionId;

export const getMeanScoreScaleOrder = (options = []) => {
	const scaleOrder = {
		isAsc: false,
		isDesc: false,
		isCustom: false,
	};

	const includedOptions =
		_.orderBy(options, 'order', 'asc')?.filter(option => !isExcludedMeanScoreOption(option)) ?? [];
	const excludedOptions = options?.filter(isExcludedMeanScoreOption) ?? [];

	const excludedOptionsHaveNoValues = excludedOptions.every(option => {
		const scalePoint = parseInt(option?.scalePoint, 10);
		return !scalePoint;
	});

	if (!excludedOptionsHaveNoValues) return { ...scaleOrder, isCustom: true };

	const isAsc = includedOptions.every((option, index) => {
		const scalePoint = parseInt(option?.scalePoint, 10);
		return scalePoint === index + 1;
	});

	if (isAsc) return { ...scaleOrder, isAsc };

	const maxScalePoint = includedOptions.length;

	const isDesc = includedOptions.every((option, index) => {
		const scalePoint = parseInt(option?.scalePoint, 10);
		return scalePoint === maxScalePoint - index;
	});

	if (isDesc) return { ...scaleOrder, isDesc };

	return { ...scaleOrder, isCustom: true };
};

export const customRound = (value, decimals = 2) => {
	if (typeof value !== 'number') return value;
	if (Number.isNaN(value)) return null;

	// * Convert the number to a string with a maximum of 2 decimal digits
	let roundedNumber = value.toFixed(decimals);

	// * Check if the rounded number has a decimal part
	if (roundedNumber.includes('.')) {
		// * Remove trailing zeros from the decimal part
		roundedNumber = roundedNumber.replace(/\.?0+$/, '');
	}

	return roundedNumber;
};

export const createMeanScoreScalePointsDictionary = optionsWithScalePoints =>
	Object.fromEntries(optionsWithScalePoints?.map(({ id, scalePoint = null }) => [id, scalePoint]) ?? []);

export const getMeanScore = ({
	options = [], // * Option has to include `scalePoint` and `base` keys
}) => {
	const optionsWithScalePoints = options?.filter?.(
		({ scalePoint = null }) => !Number.isNaN(parseInt(scalePoint, 10)),
	);

	const meanScore =
		_.sum(optionsWithScalePoints?.map(({ base = 0, scalePoint = null }) => base * parseInt(scalePoint, 10))) /
		_.sumBy(optionsWithScalePoints, 'base');

	return meanScore;
};

export const calculateConfidenceInterval = (meanScore, confidenceLevel) => {
	const margin = (100 - confidenceLevel) / 2 / 100;
	const lowerBound = meanScore - meanScore * margin;
	const upperBound = meanScore + meanScore * margin;
	return [lowerBound, upperBound];
};

export const calculateTotalOptions = (options, sections) => {
	let optionsArray = [];
	options?.forEach(option => {
		const allQuestions = getAllQuestions(sections);
		const maskedOriginQuestion = allQuestions?.find(q => {
			if (option?.maskedParameters) {
				return q?.id === option?.maskedParameters?.maskedQuestionId;
			}
			return q?.id === option?.maskedQuestionId;
		});
		const isRankedQuestion = maskedOriginQuestion?.style === 'ranked';
		const position = option?.position;
		const recallingRankedOptionsTotal = option?.rank;
		const isOriginQuestionMultiSelect =
			maskedOriginQuestion?.settings?.find(setting => setting?.label === 'multi-select')?.value === 'true' ||
			false;
		const isExactSelect =
			maskedOriginQuestion?.settings?.find(setting => setting?.label === 'limit-type')?.value === 'exact';
		const isUpToSelect =
			maskedOriginQuestion?.settings?.find(setting => setting?.label === 'limit-type')?.value === 'range';
		const isUnlimited = !isExactSelect && !isUpToSelect;
		const limitValue = maskedOriginQuestion?.settings?.find(setting => setting?.label === 'limit')?.value || 1;
		const isRequired =
			maskedOriginQuestion?.settings?.find(setting => setting?.label === 'optional')?.value === 'false' || true;

		const isSelected = option?.logic === 'selected' || option?.maskedParameters?.logic === 'selected';
		const isNotSelected = option?.logic === 'not-selected' || option?.maskedParameters?.logic === 'not-selected';
		const filteredOptions = isNotSelected
			? option?.maskedOptions?.filter(opt => !opt?.isOtherSpecify)
			: option?.maskedOptions?.filter(opt => !opt?.isNoneOfTheAbove);
		const totalOptions = calculateTotalOptions(filteredOptions, sections);

		if (isRankedQuestion) {
			if (!option?.maskedOptions?.length) {
				optionsArray.push(option?.id);
			} else {
				optionsArray = optionsArray.concat(totalOptions.slice(0, recallingRankedOptionsTotal));
			}
		} else if (!isUnlimited) {
			if (isSelected) {
				optionsArray = optionsArray.concat(totalOptions.slice(0, limitValue));
			} else if (isNotSelected) {
				const sliceEnd = isUpToSelect ? totalOptions.length - 1 : totalOptions.length - limitValue;
				optionsArray = optionsArray.concat(totalOptions.slice(0, sliceEnd));
			}
		} else if (!option?.maskedOptions?.length) {
			optionsArray.push(option?.id);
		} else if (!isOriginQuestionMultiSelect) {
			if (isSelected) {
				optionsArray.push(option?.id);
			} else if (isNotSelected) {
				optionsArray = optionsArray.concat(totalOptions.slice(0, totalOptions.length - 1));
			}
		} else if (isUnlimited) {
			if (isSelected) {
				optionsArray = optionsArray.concat(totalOptions);
			} else if (isNotSelected) {
				optionsArray = optionsArray.concat(totalOptions.slice(0, totalOptions.length - 1));
			}
		}
	});

	return optionsArray;
};
