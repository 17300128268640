import React, { useEffect, useRef, useState, useCallback } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import ArrowDown from 'public/images/audience/arrow_down.svg';
import Input from 'src/components/inputs/Input';
import TextInput from 'src/components/elements/TextInput';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'src/domains/manage-study/actions';
import StudyCountryAndLanguages from 'src/components/shared/SearchResults/shared/StudyCountryAndLanguages';
import StudyImage from 'src/components/shared/SearchResults/shared/StudyImage';

import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'template-preview-modal-header';

const TemplatePreviewModalHeader = ({
	title = 'Preview Template',
	name,
	studyUuid,
	type,
	tagTypeText,
	countryIds = [],
	languages = [],
	onClickClose,
	modalMode,
	mode,
	goBack,
	includeBack,
	selectedLanguage,
	setSelectedLanguage,
	localTemplateName,
	setLocalTemplateName,
	asset,
	emoji,
}) => {
	const dispatch = useDispatch();
	const subMenuRef = useRef(null);
	const [showSubMenu, setShowSubMenu] = useState(false);
	const [searchLanguage, setSearchLanguage] = useState('');
	const { languages: allLanguages } = useSelector(state => state.manageStudy) || [];
	const fetchLanguages = useCallback(() => dispatch(actions.fetchLanguages()), [dispatch]);

	useEffect(() => {
		if (!allLanguages || allLanguages?.length === 0) {
			fetchLanguages();
		}
	}, [allLanguages, fetchLanguages]);

	const renderLanguageDropdown = () => (
		<div
			ref={subMenuRef}
			className={el('language_dropdown')}
			onClick={e => {
				e.stopPropagation();
				e.preventDefault();

				if (e?.target?.className?.indexOf('search') === -1) setShowSubMenu(prev => !prev);
			}}
			onKeyDown={() => {}}
			tabIndex={0}
			role="button"
		>
			<span className={el('language_dropdown_title')}>
				{languages?.length ? languages.find(lang => lang === selectedLanguage)?.toUpperCase() : null}
				<img src={ArrowDown} className={`caret ${showSubMenu ? 'open' : ''}`} alt="" />
			</span>
			{showSubMenu && (
				<div className={el('language_dropdown_menu')}>
					{languages.length >= 15 && (
						<Input
							className={el('language_dropdown_search_input')}
							placeholder="Search"
							value={searchLanguage}
							onChange={e => setSearchLanguage(e)}
							autofocus
						/>
					)}
					<div className={el('language_dropdown_sub_menu')}>
						{languages
							.filter(lang => {
								if (searchLanguage) {
									return lang.toLowerCase().indexOf(searchLanguage.toLowerCase()) !== -1;
								}
								return lang;
							})
							.map((lang, i) => (
								<span
									key={`language-${lang}-${i}`}
									className={el(
										`language_dropdown_sub_menu_item${selectedLanguage === lang ? '_active' : ''}`,
									)}
									aria-hidden
									onClick={() => setSelectedLanguage(lang)}
								>
									{allLanguages?.find(all => all?.languageCode === lang)?.label}
								</span>
							))}
					</div>
				</div>
			)}
		</div>
	);

	return (
		<div className={className}>
			<div className={el('column-wrapper')}>
				<span className={el('title')}>{title}</span>
				<div className={el('line-wrapper')}>
					<div className={el('name-container')} title={name}>
						{includeBack && !modalMode && !!goBack ? (
							<div className={el('goBack')} onClick={goBack}>
								{' '}
								{'<'}{' '}
							</div>
						) : null}

						{asset || emoji ? <StudyImage asset={asset} emoji={emoji} /> : null}

						{mode === 'save' ? (
							<TextInput
								className={el('editable-name')}
								textClassName={el('name_container')}
								value={localTemplateName}
								placeholder="Untitled Audience"
								onChange={e => setLocalTemplateName(e.target.value)}
								onBlur={() => {}}
								isCharacterCount={false}
								maxLength={100}
							/>
						) : (
							<div className={el('name')}>{name}</div>
						)}
					</div>
				</div>
			</div>
			<div className={el('tags-wrapper')}>
				<div className={el('tag')}>{tagTypeText}</div>
				<StudyCountryAndLanguages
					study={{
						languages: type === 'study' ? languages : [],
						countryIds: type === 'study' ? [] : countryIds,
						uuid: studyUuid,
					}}
				/>

				{languages.length ? (
					<div key="view-in" className={el('view_language_container')}>
						<span className={el('view_in')}> View In: </span>
						{renderLanguageDropdown()}
					</div>
				) : null}
			</div>
			<div aria-hidden className={el('close')} onClick={onClickClose}>
				<Iconof icon="clear" size="large" />
			</div>
		</div>
	);
};

TemplatePreviewModalHeader.displayName = 'TemplatePreviewModalHeader';
TemplatePreviewModalHeader.propTypes = {
	title: PropTypes.any,
	name: PropTypes.any,
	studyUuid: PropTypes.any,
	type: PropTypes.any,
	tagTypeText: PropTypes.any,
	countryIds: PropTypes.any,
	languages: PropTypes.any,
	onClickClose: PropTypes.any,
	modalMode: PropTypes.any,
	mode: PropTypes.string,
	goBack: PropTypes.any,
	includeBack: PropTypes.bool,
	selectedLanguage: PropTypes.any,
	setSelectedLanguage: PropTypes.func,
	setLocalTemplateName: PropTypes.func,
	localTemplateName: PropTypes.string,
	asset: PropTypes.object,
	emoji: PropTypes.string,
};

export default TemplatePreviewModalHeader;
