import React from 'react';

import './styles.scss';

export default ({ width = 1, color = '#707070' }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		width="39"
		height="37"
		viewBox="0 0 39 37"
		className="x-icon"
	>
		<g id="a">
			<g transform="translate(-1456 -1104)">
				<g transform="translate(1464.5 1112.5)">
					<line
						x2="18.886"
						y2="18.346"
						transform="translate(0.54 1.079)"
						fill="none"
						stroke={color}
						strokeWidth={width}
					/>
					<line
						x1="20.074"
						y2="20.065"
						transform="translate(0)"
						fill="none"
						stroke={color}
						strokeWidth={width}
					/>
				</g>
			</g>
		</g>
	</svg>
);
