import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'study-templates-list';
const el = (name, mod) => cn(className, name, mod);

const StudyTemplatesList = ({ templates, children }) => {
	if (!templates?.length) return;
	if (!children) return null;

	return (
		<div className={className}>
			<div className={el('content')}>{children}</div>
		</div>
	);
};

StudyTemplatesList.displayName = 'StudyTemplatesList';
StudyTemplatesList.propTypes = {
	templates: PropTypes.array,
	children: PropTypes.any,
};

export default StudyTemplatesList;
