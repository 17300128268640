import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MenuAnimationPanels = ({ state = 'main', children }) => {
	const userOnIphone = /iPhone/.test(navigator.userAgent);
	return (
	<div className={`animation-panels ${state} ${userOnIphone ? 'user-on-iphone' : '' }`}>{children}</div>
)};

MenuAnimationPanels.propTypes = {
	state: PropTypes.oneOf(['main', 'secondary']),
	children: PropTypes.node,
};

export const MenuAnimationPanel = ({ children }) => <div className="animation-panel">{children}</div>;

MenuAnimationPanel.propTypes = {
	children: PropTypes.node,
};
