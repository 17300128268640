import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import { ColorChip } from 'src/components/shared/StudyTagManager';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'product-tags-list';
const el = (name, mod) => cn(className, name, mod);

const ProductTagsList = ({ product, modal = false }) => {
	const [showMoreTags, setShowMoreTags] = useState(false);

	const renderShowMore = product.tags && product.tags.length > 12;
	const lessTags = renderShowMore ? product.tags.slice(0, 12) : product.tags;
	const moreTags = product.tags;
	const hiddenTagCount = product.tags && product.tags.length - 12;

	return (
		<div className={`${className} ${modal && el('modal')}`}>
			<div className={el('product-tags')}>
				{showMoreTags
					? moreTags &&
					  moreTags.map(tag => <ColorChip key={`product-tag-${tag.termId}`} tag={tag} canUpdate={false} />)
					: lessTags &&
					  lessTags.map(tag => <ColorChip key={`product-tag-${tag.termId}`} tag={tag} canUpdate={false} />)}
				{renderShowMore && modal && <div className={el('hidden-tag-count')}>+ {hiddenTagCount} more</div>}
			</div>
			{!modal && (
				<>
					{renderShowMore && (
						<>
							{!showMoreTags ? (
								<button
									className={el('see-more-button')}
									type="button"
									onClick={() => setShowMoreTags(true)}
								>
									See more
									<Iconof className={el('see-more-icon')} icon="chevron_down" />
								</button>
							) : (
								<button
									className={el('see-more-button')}
									type="button"
									onClick={() => setShowMoreTags(false)}
								>
									See less
									<Iconof className={el('see-more-icon')} icon="chevron_up" />
								</button>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

ProductTagsList.propTypes = {
	product: PropTypes.any,
	modal: PropTypes.bool,
};

export default ProductTagsList;
