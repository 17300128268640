import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'src/hocs/withRouter';
import Cookies from 'src/utilities/cookies';
import { TRACKING_ACTIONS, trackingEvent } from 'src/utilities/marketing';
import cn from 'src/utilities/bem-cn';
import Auth from 'src/utilities/auth';
import loadStripe from 'src/domains/account/utilities/loadStripe';
import DropdownSelect from 'src/components/elements/DropdownSelect';
import { Iconof } from '@upsiide/ui-components';
import './styles.scss';
import { connect } from 'react-redux';
import * as SignupActions from 'src/domains/confirm-signup/actions';
import * as accountActions from 'src/domains/account/actions';
import isNil from 'lodash/isNil';
import * as selectors from '../../../../account/selectors';

const className = 'account-selector-dropdown';
const el = (name, mod) => cn(className, name, mod);
const licenseSubscriptionHandles = ['enterprise', 'professional'];

const CaretComponent = () => (
	<div className={el('chev-component')}>
		<Iconof icon="chevron_up" />
		<Iconof icon="chevron_down" />
	</div>
);

class AccountSelectorDropdown extends React.Component {
	componentDidMount() {
		const { accountSelectorDidMountCallback } = this.props;
		const userCanListAccounts = Auth.userCan('account:list', true);

		if (!userCanListAccounts) {
			// console.warn('User does not have access to list accounts.');
			return;
		}

		if (accountSelectorDidMountCallback) {
			accountSelectorDidMountCallback();
		}

		loadStripe();
	}

	onChangeAccount = value => {
		const { uuid, isChargify } = value;
		// Set the new cookie
		Cookies.setUpsiideAccountToken(uuid);
		const {
			fetchAccount,
			setAccountUuid,
			accountSelectorChangeAccountCallback,
			fetchChargifySubscriptions,
			accounts,
			account,
		} = this.props;
		const switchedToAccountName = accounts?.content?.find(a => a.uuid === uuid)?.name;
		if (uuid !== account?.content?.uuid) {
			trackingEvent(TRACKING_ACTIONS.track, {
				eventName: `Changed Accounts to: ${switchedToAccountName}`,
				metadata: { switchedToAccountName },
				intercom: false,
			});
		}
		// Prevent a 401 error on the initial fetch account call due to a missing x-account-uuid
		if (uuid !== undefined || uuid !== null) {
			setAccountUuid(uuid);
			fetchAccount(uuid, false); // Pass the UUID and whether to fetch USERS and ROLES
			if (isChargify) fetchChargifySubscriptions();
		}

		if (accountSelectorChangeAccountCallback) {
			accountSelectorChangeAccountCallback(uuid);
		}
	};

	changeAccountFromCookies = () => {
		// If the cached cookie exists then change account based on that cookie
		if (Cookies.getUpsiideAccountToken() !== undefined) {
			const upsiideAccountToken = Cookies.getUpsiideAccountToken();
			this.onChangeAccount(upsiideAccountToken);
		}
	};

	dynamicSort = property => {
		let sortOrder = 1;
		if (property[0] === '-') {
			sortOrder = -1;
			property = property.substr(1);
		}
		return (a, b) => {
			if (sortOrder === -1) {
				return b[property].localeCompare(a[property]);
			}
			return a[property].localeCompare(b[property]);
		};
	};

	render() {
		const {
			account,
			accounts,
			loggedIn,
			isAccountSettingsPage,
			setUserEmail,
			setIsSignupFlow,
			chargifySubscriptions,
			router: { navigate },
		} = this.props;
		const errorFound = account.error !== null && account.error !== undefined;

		// If the user is not logged in or there was an error found render nothing
		if (!loggedIn || errorFound) {
			// console.warn('AccountSelectorDropdown: Not logged in or there was an error: ', account.error);
			return false;
		}

		// If the account is still loading or the account content hasn't populated yet show loading
		if (account.loading || accounts.content.length <= 0) {
			return (
				<div className={className}>
					<div className={el('loader')}>
						<DropdownSelect state="loading" options={[]} />
					</div>
				</div>
			);
		}

		// Setup and sort the account options
		let accountOptions = accounts.content.map(a => {
			const hasIssue = a.chargifyCustomerId && a.subscriptionState !== 'active';
			return {
				label: hasIssue ? `⚠ ${a.name}` : a.name,
				value: { uuid: a.uuid, isChargify: !isNil(a.chargifyCustomerId) },
				showTooltip: a.isAccountDisabled,
				permission: a.permission,
			};
		});

		if (isAccountSettingsPage) {
			accountOptions = accountOptions.filter(a => a.permission !== 'CAN_FILTER');
		}

		accountOptions = accountOptions.sort(this.dynamicSort('label'));

		if (accountOptions.length === 0) {
			// console.warn('AccountSelectorDropdown: There were no account options to render.');
			return false;
		}

		const upsiideAccountToken = Cookies.getUpsiideAccountToken();
		const values = upsiideAccountToken
			? accountOptions.filter(option => option.value.uuid === upsiideAccountToken)
			: accountOptions;

		let filteredLicenseSub = { state: false };
		if (chargifySubscriptions && chargifySubscriptions.length > 0) {
			filteredLicenseSub = chargifySubscriptions.find(sub =>
				licenseSubscriptionHandles.includes(sub.subscriptionHandle),
			);
		}
		const isTrialing = filteredLicenseSub?.state === 'trialing' || filteredLicenseSub?.state === 'trial_ended';

		// Determine if the accounts are searchable
		const isSearchable = accountOptions?.length > 6;
		// Determine if allow create account (if its trailing should not allow)
		const allowCreateAccount = process.env.allowAddNewAccount && !isTrialing;

		return (
			<div className={className}>
				<div className={el('dropdown')}>
					<span className={el('account-heading')}>Account</span>
					<DropdownSelect
						value={values[0] && values[0].value}
						maxLabelLength={36}
						onChange={this.onChangeAccount}
						options={accountOptions}
						headerLabel="Choose another account"
						footerLabel={allowCreateAccount ? '+ Add a new account' : false}
						footerLabelAction={() => {
							setUserEmail();
							setIsSignupFlow(false);
							navigate('/account-details');
						}}
						searchable={isSearchable}
						tooltipContent={
							<div className={el('tooltip-container')}>
								<p className={el('tooltip-label')}>
									<Iconof icon="hidden" color={el('tooltip-icon')} /> This account is disabled and is
									not visible to non-admin users
								</p>
							</div>
						}
						tooltipIcon={<Iconof icon="hidden" color={el('tooltip-icon')} />}
						CaretComponent={CaretComponent}
						right
					/>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setIsSignupFlow: isSignupFlow => dispatch(SignupActions.setIsSignupFlow(isSignupFlow)),
	fetchChargifySubscriptions: () => dispatch(accountActions.fetchChargifySubscriptions()),
});
const mapStateToProps = state => ({
	chargifySubscriptions: selectors.getChargifySubscription(state),
	accounts: selectors.getAccounts(state),
	account: selectors.getAccount(state),
});

AccountSelectorDropdown.propTypes = {
	isAccountSettingsPage: PropTypes.bool,
	account: PropTypes.any,
	accounts: PropTypes.any,
	setAccountUuid: PropTypes.func,
	fetchAccount: PropTypes.func,
	loggedIn: PropTypes.bool,
	accountSelectorDidMountCallback: PropTypes.func,
	accountSelectorChangeAccountCallback: PropTypes.func,
	fetchChargifySubscriptions: PropTypes.func,
	setUserEmail: PropTypes.func,
	setIsSignupFlow: PropTypes.func,
	chargifySubscriptions: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountSelectorDropdown));
