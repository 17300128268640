import React, { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'demographic-group-percentage-circle';

const el = (name, mod) => cn(className, name, mod);

const DemographicGroupPercentageCircle = ({
	systemStopped = false,
	groupId,
	group,
	innerRadius = 6,
	outerRadius = 11,
	width = 26,
	height = 26,
}) => {
	const donutRef = useRef();
	const donutContainerRef = useRef();
	const assignDonutContainerRef = node => (donutContainerRef.current = node);
	const assignDonutRef = node => (donutRef.current = node);

	const updateDonut = useCallback(() => {
		const twoPi = 2 * Math.PI;
		const allocated = group?.currentSample;
		const total = group?.sampleSize || 1;
		let progress = 0;

		const arc = d3.arc().startAngle(0).innerRadius(innerRadius).outerRadius(outerRadius);
		d3.select(donutContainerRef.current);

		d3.select(donutRef.current).selectAll('*').remove();

		const svg = d3.select(donutRef.current).attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

		svg.exit().remove();

		const meter = svg.append('g').attr('class', 'meter');
		meter.append('path').attr('class', 'background').attr('d', arc.endAngle(twoPi));
		const i = d3.interpolate(progress, allocated / total);
		const foreground = meter.append('path').attr('class', 'foreground').attr('d', arc.endAngle(twoPi));
		meter
			.transition()
			.duration(1000)
			.tween('progress', () => {
				return t => {
					progress = i(t);
					foreground.attr('d', arc.endAngle(twoPi * progress));
				};
			});
	}, [group]);

	useEffect(() => {
		updateDonut();
	}, [updateDonut]);

	return (
		<div className={className}>
			<svg className={el('donut')} ref={assignDonutContainerRef}>
				<g
					className={`d3-donut demographic-group-donut-${groupId} ${
						systemStopped ? 'system-stopped-background' : ''
					}`}
					ref={assignDonutRef}
				/>
			</svg>
		</div>
	);
};

DemographicGroupPercentageCircle.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	innerRadius: PropTypes.number,
	outerRadius: PropTypes.number,
	systemStopped: PropTypes.bool,
	groupId: PropTypes.number,
	group: PropTypes.object,
};

export default DemographicGroupPercentageCircle;
