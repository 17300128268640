import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import ImageFitImageOnly from 'public/images/idea_screen/image-fit-image-only.png';
import ImageCropImageOnly from 'public/images/idea_screen/image-crop-image-only.png';
import './styles.scss';
import Button from 'src/components/elements/Button';

const className = 'radio-setting-button';
const el = (name, mod) => cn(className, name, mod);

const RadioSettingButton = ({ onChange, selected, value, svgImage = null, label, disabled }) => (
	<Button
		className={`${el('option-layout-button', selected ? 'selected' : '')} ${value}`}
		type="custom"
		onClick={() => onChange()}
		state={disabled ? 'disabled' : 'active'}
	>
		{svgImage || (
			<img
				className={el('image-option')}
				src={[ImageFitImageOnly, ImageCropImageOnly][value === 'fit' ? 0 : 1]}
				alt={label}
			/>
		)}
		<div className={el('option-layout-button-footer')}>
			<div className={el('checkbox__container')}>
				<div className={`${el('checkbox__box')} ${selected ? 'selected' : ''}`}>
					<div className={`${el('checkbox__content')} ${!selected ? 'hidden' : ''}`}></div>
				</div>
			</div>
			<div className={el('option-layout-button-label')}>{label}</div>
		</div>
	</Button>
);

RadioSettingButton.propTypes = {
	onChange: PropTypes.func,
	selected: PropTypes.bool,
	value: PropTypes.any,
	svgImage: PropTypes.any,
	label: PropTypes.string,
	disabled: PropTypes.bool,
};

export default RadioSettingButton;
