import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Input from 'src/components/inputs/Input';
import './styles.scss';
import { Iconof } from '@upsiide/ui-components';
import { Tooltip } from 'react-tippy';

const className = 'interlock-quotas-cel';
const el = (name, mod) => cn(className, name, mod);
const MIN_QUOTA = 3;

const InterlockQuotasCel = memo(
	({
		id,
		label,
		level,
		quota,
		isSelected,
		onChange: onChangeQuota,
		onBlur: onBlurQuota,
		setSelectedId,
		minQuota = MIN_QUOTA,
		editable = false,
		icon,
		iconText,
		customClassName,
	}) => {
		const onChange = value => {
			if (Number.isNaN(parseInt(value))) {
				onChangeQuota({ level, id, newQuota: null });
				return;
			}

			const newQuota = Number(value);
			if (newQuota < 0) return;
			onChangeQuota({ level, id, newQuota });
		};

		const onBlur = e => {
			if (Number.isNaN(parseInt(e.target.value))) {
				onBlurQuota({ level, id, newQuota: null });
				return;
			}

			const newQuota = Number(e.target.value);
			if (newQuota < 0) return;
			onBlurQuota({ level, id, newQuota });
		};

		const formatQuota = quotaNumber => {
			if (typeof quotaNumber !== 'number') {
				return quotaNumber || '';
			}

			if (`${quotaNumber}`?.length > 4) {
				return Number(quotaNumber).toFixed(2);
			}

			return quotaNumber;
		};

		const formatQuotaWithPercentage = quotaNumber => {
			if (typeof quotaNumber !== 'number') {
				return quotaNumber;
			}
			return `${Number(quotaNumber).toFixed(2)}%`;
		};

		const isValid = useMemo(() => {
			if (typeof quota !== 'number') {
				return true;
			}
			return formatQuota(quota) >= minQuota;
		}, [quota, minQuota]);

		const getClasses = () => {
			let classes = `${className}`;
			classes += isSelected ? ' selected' : '';
			classes += editable ? ' editable' : '';
			classes += !isValid ? ' error' : '';
			classes += customClassName ? ` ${customClassName}` : '';
			return classes;
		};

		const renderIcon = () => {
			const iconElement = <Iconof icon={icon} />;
			if (!iconText) return icon;
			return (
				<Tooltip theme="dark-blured" position="top" html={iconText}>
					{iconElement}
				</Tooltip>
			);
		};

		return (
			<div className={getClasses()} onClick={() => setSelectedId?.(id)} aria-hidden>
				{editable ? (
					<div className={el('content')}>
						<span className={el('label')}>{label?.length ? label : ''}</span>
						<span className={el('quota-field')}>
							<Input
								type="number"
								onBlur={onBlur}
								onChange={onChange}
								className={el('quota-input')}
								value={formatQuota(quota)}
							/>
							%
						</span>
					</div>
				) : (
					<span className={el('quota')}>
						{formatQuotaWithPercentage(quota)} {icon ? renderIcon() : null}
					</span>
				)}
			</div>
		);
	},
);

InterlockQuotasCel.displayName = 'InterlockQuotasCel';
InterlockQuotasCel.propTypes = {
	isSelected: PropTypes.bool,
	label: PropTypes.string,
	quota: PropTypes.number,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	setSelectedId: PropTypes.func,
	editable: PropTypes.bool,
	minQuota: PropTypes.number,
	icon: PropTypes.string,
	iconText: PropTypes.string,
	id: PropTypes.object,
	level: PropTypes.number,
	customClassName: PropTypes.string,
};

export default InterlockQuotasCel;
