/* eslint-disable no-nested-ternary */
import React from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import LogicItemSelect from 'src/components/shared/LogicCondition/LogicRow/LogicItemSelect';
import LogicOperandSelect from 'src/components/shared/LogicCondition/LogicRow/LogicOperandSelect';
import LogicOperatorSelect from 'src/components/shared/LogicCondition/LogicRow/LogicOperatorSelect';
import LogicTopBottomSelect from 'src/components/shared/LogicCondition/LogicRow/LogicTopBottomSelect';
import LogicRankSelect from 'src/components/shared/LogicCondition/LogicRow/LogicRankSelect';
import LogicAnswerSelect from 'src/components/shared/LogicCondition/LogicRow/LogicAnswerSelect';
import LogicPlusMinus from 'src/components/shared/LogicCondition/LogicRow/LogicPlusMinus';

import '../styles.scss';
import { useLogicConditionContext } from '../LogicConditionContext';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

const LogicRow = ({
	questionOptions,
	lastOption,
	setParentRefZIndex,
	andOrOptions,
	rowIsOptions,
	grid,
	ranked,
	audience,
	questionType,
	renderPlusIcon,
	renderMinusIcon,
	index,
}) => {
	const { answerOptions, operandSelectIndex } = useLogicConditionContext();

	const ifText = questionOptions ? 'If' : '';
	const andOrText = operandSelectIndex ? 'or' : 'and';

	if (questionType === 'emoji') {
		answerOptions.forEach(option => {
			option.label = option.label.charAt(0).toUpperCase() + option.label.slice(1);
			option.chipLabel = option.chipLabel.charAt(0).toUpperCase() + option.chipLabel.slice(1);
		});
	}

	return (
		<>
			<Row
				key={index}
				className={`${el('condition')} ${grid ? el('grid-condition') : ''} ${
					!questionOptions && grid ? el('grid-additional-condition') : ''
				}`}
			>
				{/* Conditionally render 'if' text */}
				{questionOptions && (
					<Col className={el('if')} xs={1}>
						{ifText}
					</Col>
				)}
				{/* Conditionally render 'and / or' text */}
				{!questionOptions && !andOrOptions && answerOptions && <Col xs={3} />}
				{!questionOptions && !andOrOptions && answerOptions && (
					<Col className={el('and-or')} xs={2}>
						{andOrText}
					</Col>
				)}
				{/* Conditionally render 'question' select */}
				{questionOptions && (
					<LogicItemSelect options={questionOptions} setParentRefZIndex={setParentRefZIndex} />
				)}
				{/* Conditionally render 'and / or' select */}
				{andOrOptions && <Col xs={3} />}
				{andOrOptions && (
					<LogicOperandSelect options={andOrOptions} setParentRefZIndex={setParentRefZIndex} index={index} />
				)}
				{/* Conditionally render 'attribute' select */}
				{grid && questionOptions && (
					<LogicAnswerSelect
						setParentRefZIndex={setParentRefZIndex}
						columnWidth={grid && audience && audience.provider !== 'BYO' ? 2 : grid ? 2 : 5}
						placeholder="attribute"
						index={index}
						isAttribute
					/>
				)}
				{/* Conditionally render 'is / is not' select */}
				{rowIsOptions && <LogicOperatorSelect options={rowIsOptions} setParentRefZIndex={setParentRefZIndex} />}
				{/* Render 'answers' select */}
				<LogicAnswerSelect
					setParentRefZIndex={setParentRefZIndex}
					columnWidth={grid && questionOptions ? 3 : 5}
					index={index}
				/>
				{/* Render icons */}
				<LogicPlusMinus renderPlusIcon={renderPlusIcon} renderMinusIcon={renderMinusIcon} index={index} />
				{/* Conditionally render Rows for ranked question logic */}
			</Row>
			{/* Conditionally render ranked options selects */}
			{ranked && lastOption && (
				<Row className={el('condition')} style={{ marginBottom: 8, alignItems: 'center' }}>
					<Col xs={6} style={{ textAlign: 'right', paddingRight: 10 }}>
						ranked in
					</Col>
					<LogicTopBottomSelect
						setParentRefZIndex={setParentRefZIndex}
						placeholder="position"
						index={index}
					/>
					<LogicRankSelect setParentRefZIndex={setParentRefZIndex} placeholder="rank" index={index} />
					<Col xs={2} />
				</Row>
			)}
		</>
	);
};

LogicRow.propTypes = {
	index: PropTypes.any,
	questionType: PropTypes.string,
	// Options
	questionOptions: PropTypes.any,
	lastOption: PropTypes.bool,
	andOrOptions: PropTypes.any,
	rowIsOptions: PropTypes.any,
	renderPlusIcon: PropTypes.any,
	renderMinusIcon: PropTypes.any,
	setParentRefZIndex: PropTypes.func,
	grid: PropTypes.any,
	ranked: PropTypes.any,
	audience: PropTypes.any,
};

export default LogicRow;
