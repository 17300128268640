// Todo: create new Chip ui-component
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'chip';
const el = (name, mod) => cn(className, name, mod);

const Chip = React.forwardRef(({ customClass, children, ...rest }, ref) => (
	<span className={el('item', customClass)} {...rest} ref={ref}>
		{children}
	</span>
));

Chip.displayName = 'Chip';
Chip.propTypes = {
	customClass: PropTypes.string,
	children: PropTypes.any,
};

export default Chip;
