import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AssetInput from 'src/components/inputs/AssetInput';
import { setStudy } from 'src/domains/manage-study/actions';
import { setStudy as setReportingStudy } from 'src/domains/manage-study/actions/reporting';
import { setStudy as setBlueprint } from 'src/domains/manage-blueprints/actions';
import { blueprintService, studyService } from 'src/services';
import './styles.scss';

const className = 'study-asset-manager';

const StudyAssetManager = ({ study }) => {
	const dispatch = useDispatch();
	const [value, setValue] = useState(study?.assetId);
	const [emoji, setEmoji] = useState(study?.emoji);
	const [studyAsset, setStudyAsset] = useState(study?.asset);

	const patchAsset = useCallback(
		async (id, data) => {
			await studyService.patchStudy(id, data);
			const studyData =
				study.type === 'blueprint' ? await blueprintService.getBlueprint(id) : await studyService.getStudy(id);

			setStudyAsset(studyData?.data?.asset);
			if (study.type === 'blueprint') {
				// upsert blueprint
				dispatch(setBlueprint(studyData.data));
			} else {
				// upsert study
				dispatch(setStudy(studyData.data));
				dispatch(setReportingStudy(studyData.data));
			}
		},
		[dispatch, study],
	);

	const handleChangeAsset = useCallback(
		(asset, option, type = 'asset') => {
			const assetValue = asset[0] > 0 ? asset[0] : null;
			if (type === 'asset') {
				patchAsset(study?.id, { asset: assetValue, emoji: null });

				setEmoji(null);
				setValue(assetValue);
				setStudyAsset(null);
			} else {
				patchAsset(study?.id, { asset: null, emoji: asset[0] });
				setEmoji(asset[0]);
				setValue(null);
			}
		},
		[patchAsset, study],
	);

	let assetType = study?.asset ? 'image-asset' : 'emoji-asset';
	if (assetType === 'emoji-asset' && !study?.emoji) {
		assetType = 'no-asset';
	}

	return (
		<div className={`${className} ${assetType}`} onClick={e => e.stopPropagation()} aria-hidden>
			<AssetInput
				imageOnly
				withEmojis
				value={[value]}
				previewMaxWidth={32}
				previewMaxHeight={32}
				asset={studyAsset}
				emoji={emoji}
				onChange={handleChangeAsset}
				preview={Boolean(studyAsset || emoji)}
				canDelete={false}
				isModal={false}
				givenStudyId={study?.id}
				option
			/>
		</div>
	);
};

StudyAssetManager.propTypes = {
	study: PropTypes.object,
};

export default StudyAssetManager;
