const prefix = 'trial-signup';

export const CONFIRM_USER_SETUP_TOKEN = `${prefix}/CONFIRM_USER_SETUP_TOKEN`;
export const confirmToken = token => ({ type: CONFIRM_USER_SETUP_TOKEN, payload: { token } });

export const SET_TOKEN = `${prefix}/SET_TOKEN`;
export const setToken = token => ({ type: SET_TOKEN, payload: { token } });

export const SET_USER = `${prefix}/SET_USER`;
export const setUser = user => ({ type: SET_USER, payload: { user } });

export const SIGNUP_USER = `${prefix}/SIGNUP_USER`;
export const signUpUser = user => ({ type: SIGNUP_USER, payload: { user } });

export const SET_SIGNED_UP = `${prefix}/SET_SIGNED_UP`;
export const setSignedUp = (signedUp, signedUpEmail) => ({ type: SET_SIGNED_UP, payload: { signedUp, signedUpEmail } });

export const INC_LOADING = `${prefix}/INC_LOADING`;
export const incLoading = () => ({ type: INC_LOADING });

export const DEC_LOADING = `${prefix}/DEC_LOADING`;
export const decLoading = () => ({ type: DEC_LOADING });
