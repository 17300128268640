import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getManageBlueprintData } from '../../selectors';
import { generateLogicOptions } from '../../../utilities/logic';

export const getStudy = state => getManageBlueprintData(state).study;
export const getLanguage = state => getManageBlueprintData(state).language;
export const getLanguages = state => getManageBlueprintData(state).languages;
export const getCategories = state => getManageBlueprintData(state).categories;
export const getProductTags = state => getManageBlueprintData(state).productTags;
export const getProducts = state => getManageBlueprintData(state).products;
export const getSaveButtonState = state => getManageBlueprintData(state).saveButtonState;
export const getSaveButtonText = state => getManageBlueprintData(state).saveButtonText;
export const getProductModal = state => getManageBlueprintData(state).productModal;
export const getQuestionModal = state => getManageBlueprintData(state).questionModal;
export const getQuestions = state => getManageBlueprintData(state).questions;
export const getImportImagesModal = state => getManageBlueprintData(state).importImagesModal;
export const getImportProductsModal = state => getManageBlueprintData(state).importProductsModal;
export const getImportRespondentsModal = state => getManageBlueprintData(state).importRespondentsModal;
export const getExportConfirmationModal = state => getManageBlueprintData(state).exportConfirmationModal;
export const getCountries = state => getManageBlueprintData(state).countries;
export const getCountryLanguages = state => getManageBlueprintData(state).countryLanguages;

export const getStudyLanguages = state => {
	const study = getStudy(state);
	const languages = getLanguages(state);

	return ((study || {}).languages || []).map(languageCode => {
		const accordingLanguage = languages.filter(language => language.languageCode === languageCode).pop();
		const label = accordingLanguage
			? accordingLanguage.label
			: `Unknown language with languageCode ${languageCode}`;

		return { languageCode, label };
	});
};

export const getGeographies = state => getManageBlueprintData(state).geographies;
export const getUploadImageStatus = state => getManageBlueprintData(state).uploadImageStatus;
export const getRoles = state => getManageBlueprintData(state).roles;

export const getSections = state => getManageBlueprintData(state).sections;
export const getCurrentSection = state => getManageBlueprintData(state).currentSection;

export const getShareStudyModal = state => getManageBlueprintData(state).shareStudyModal;

// Audience Related

export const getAudienceCollections = state => getManageBlueprintData(state).audienceCollections;
export const getAudiences = state => getManageBlueprintData(state).audiences;
export const getAudience = state => getManageBlueprintData(state).audience;
export const getAudienceCollection = state => getManageBlueprintData(state).audienceCollection;
export const getGroup = state => getManageBlueprintData(state).group;
export const getTranslationValidations = state =>
	get(getManageBlueprintData(state), 'studyValidations.translations', {});
export const getAudienceTemplates = state => getManageBlueprintData(state).audienceTemplates;
export const getAudienceEditTemplate = state => getManageBlueprintData(state).audienceEditTemplate;
export const getAudienceTemplateCollection = state => getManageBlueprintData(state).audienceTemplateCollection;

// Set Section Name
export const getSectionNameData = state => getManageBlueprintData(state).sectionNameData;

export const getItemLogic = state => getManageBlueprintData(state).logic;

export const getManageLanguagesModal = state => getManageBlueprintData(state).manageLanguagesModal;
export const getTranslationsModal = state => getManageBlueprintData(state).translationsModal;
export const getTranslationPreview = state => getManageBlueprintData(state).translationPreview;
export const getDiscardProductChangesModal = state => getManageBlueprintData(state).discardProductChangesModal;
export const getShowGoogleTranslateModal = state => getManageBlueprintData(state).showGoogleTranslateModal;
// Section settings
export const getSectionSettingsInsertData = state => getManageBlueprintData(state).sectionSettingsInsertData;
export const getSavingSectionSettings = state => getManageBlueprintData(state).isSavingSectionSettings;
export const getShowDevicePreviewMode = state => getManageBlueprintData(state).showDevicePreviewMode;
export const getShowDevicePreviewIframeBlocker = state => getManageBlueprintData(state).showDevicePreviewIframeBlocker;
export const getDevicePreviewMode = state => getManageBlueprintData(state).devicePreviewMode;
export const getDevicePreviewUrl = state => getManageBlueprintData(state).devicePreviewUrl;

export const getShowLaunchWithCustomQualModalForm = state =>
	getManageBlueprintData(state).showLaunchWithCustomQualModalForm;

export const getProductSectionViewMode = state => getManageBlueprintData(state).productSectionViewMode;
export const getLengthOfInterview = state => getManageBlueprintData(state).lengthOfInterview;

export const getAudiencePriceChanges = state => getManageBlueprintData(state).audiencePriceChanges;

export const getStudyId = createSelector(getStudy, study => study?.id || null);
export const getStudyAssetId = createSelector(getStudy, study => study?.assetId || null);
export const getStudyAsset = createSelector(getStudy, study => study?.asset || null);

export const getLogicDropdownOptions = createSelector(
	getStudyId,
	getSections,
	getCurrentSection,
	(state, props) => props,
	(studyId, sections, currentSection, props) => {
		// todo: current section deson't seem to update when question is first added
		const audienceData = {
			content: {
				screeningQuestions: props?.audience?.customQuestions || [],
			},
		};
		const data = generateLogicOptions(studyId, sections, currentSection, audienceData, props);

		return data;
	},
);
