// packages
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import ReactPlayer from 'react-player';
import 'react-tippy/dist/tippy.css';
import { useSelector, connect, useDispatch } from 'react-redux';
import { Tooltip } from 'react-tippy';

// constants
import CONSTANTS from 'src/config/constants';

// utilities
import cn from 'src/utilities/bem-cn';
import * as misc from 'src/utilities/misc';

// helpers
import {
	isExcludedMeanScoreOption,
	shouldShowMeanScoreScale,
	calculateTotalOptions,
	getMeanScoreScaleOrder,
} from 'src/components/helpers';

// actions
import * as manageBlueprintActions from 'src/domains/manage-blueprints/actions';

// hooks
import { useIsBlueprint } from 'src/hooks';

// services
import * as services from 'src/services';

// components
import AddAnswerButton from 'src/components/elements/AddAnswerButton';
import AddQuestionButton from 'src/components/elements/AddQuestionButton';
import IconButton from 'src/components/elements/IconButton';
import AddHeatmapImage from 'src/components/elements/AddHeatmapImage';
import Loader from 'src/components/shared/Loader';

import AdditionalQuestionOptionSettings from '../AdditionalQuestionOptionSettings';
import QuestionOptionsConfig from '../QuestionOptionsConfig';
import QuestionOptions from '../QuestionOptions';
import QuestionHeader from '../QuestionHeader';
import QuestionSettingsTabs from '../QuestionSettingsTabs';
import QuestionLogicConditions from '../QuestionLogicConditions';
import ModifyScaleButton from '../ModifyScaleButton';

// * should be imported after components
import _ from '../../../../utilities/lodashReplacer';
import * as manageStudyActions from '../../../../domains/manage-study/actions';

// styles
import './styles.scss';

const className = 'question-item';
const el = (name, mod) => cn(className, name, mod);
const SELECT_N_LIMIT = CONSTANTS.rankingLimit;

// TODO - remove settings for open-end.
// TODO - rework this into a class based component - too complex and causing un-needed rerenders

const QuestionItem = ({
	audience,
	logic,
	question,
	questions,
	studyQuestionNumber,
	section,
	sections,
	language,
	sectionOrder,
	addQuestionOptions,
	deleteQuestion,
	updateCustomQualifierLabel,
	updateQuestionLabel,
	updateOptionLabel,
	updateOptionScalePoint,
	setOptionScalePointValue,
	updateAttributeLabel,
	updateOptionAsset,
	updateAttributeAsset,
	removeQuestionOption,
	removeQuestionAttribute,
	patchItem,
	deleteItemLogic,
	addOptionPlaceholder,
	removeOptionPlaceholder,
	removeAttributePlaceholder,
	addQuestionOption,
	addQuestionAttribute,
	addAttributePlaceholder,
	patchQuestion,
	createQuestionSetting,
	patchQuestionSetting,
	// Audience marketplace
	tempTemplate,
	setTempTemplate,
	blockUpdateOrBreakTemplate,
	noNewQuestion,
	focusOnQuestion,
	setFocusOnNextQuestion,
	setFocusOnLastQuestion,
	setFocusOnQuestion,
	focusOnOption,
	filterImportQuestions,
	activeQuestion,
	setActiveQuestion,
	optionTempIds,
	setOptionTempIds,
	attributeTempIds,
	setAttributeTempIds,
	familyDomain,
	screeningQuestion = false,
	screeningQuestionAddQuestionOptions = [],
	setOpenQuestionLibrary = false,
	changeScreeningQuestionVisibility,
	updateScreeningOption,
	updateScreeningOptionScalePoint,
	setScreeningOptionScalePointValue,
	updateScreeningAttribute,
	deleteScreeningOption,
	deleteScreeningAttribute,
	updateScreeningQuota,
	updateAllScreeningOptionQuota,
	deleteAllScreeningOptionQuota,
	patchScreeningQuestionSetting,
	addScreeningQuestionOption,
	deleteScreeningQuestionOption,
	studyId,
	updateScreeningQuestionLabel,
	audienceTemplate,
	batchUpdateAudienceTemplateScreeningQuestionOptions,
	batchSetAudienceTemplateScreeningQuestionOptions,
	dragHandleContextId,
}) => {
	const dispatch = useDispatch();
	const { isBlueprint } = useIsBlueprint();
	const actions = isBlueprint ? manageBlueprintActions : manageStudyActions;
	const { study, audienceCollection } = useSelector(state => state[familyDomain]);

	const mainTranslation = useMemo(
		() => question?.translations.find(t => study?.defaultLanguage === t?.languageCode) || 'en',
		[question.translations, study],
	);
	const translation = useMemo(
		() => question?.translations.find(t => language === t.languageCode),
		[language, question.translations],
	);
	const [tempAsset, setTempAsset] = useState(null);
	const [tempAssetUrl, setTempAssetUrl] = useState(null);
	const [tempAssetId, setTempAssetId] = useState(null);
	const [activeTab, setActiveTab] = useState('preview');
	const [isActive, setIsActive] = useState(false);
	const [isPlaceholderActive, setIsPlaceholderActive] = useState(false);
	const [newValue, setNewValue] = useState('');
	const [isFocusOnLastAnswer, setIsFocusOnLastAnswer] = useState(false);
	const questionInputRef = useRef();
	const [hasInterlockedQuotas, setHasInterlockedQuotas] = useState(!!question?.isInterlocked);
	const options = question?.options || [];
	const currentLanguageAttributes = question?.attributes || [];
	const questionIndex = useMemo(() => questions.findIndex(q => q.id === question.id), [question.id, questions]);
	const isLastQuestionItem = questionIndex + 1 === questions?.length;

	const hasQuotas = useMemo(() => !!question?.options?.find(option => option?.quotaMax != null), [question?.options]);
	const [quotaError, setQuotaError] = useState('');

	const showScale = useMemo(() => shouldShowMeanScoreScale(question), [question]);

	/**
	 * focus on last answer
	 */
	useEffect(() => {
		if (isFocusOnLastAnswer) {
			if (!focusOnOption(question.id, question.options.length - 1)) {
				setFocusOnQuestion({ questionId: question.id });
			}
			setIsFocusOnLastAnswer(false);
		}
	}, [focusOnOption, isFocusOnLastAnswer, question.id, question.options.length, setFocusOnQuestion]);

	/**
	 * focus on question
	 */
	useEffect(() => {
		const questionsArr = tempTemplate ? tempTemplate.customQuestions : questions; // audience or manage study uses different objects

		if (
			!!focusOnQuestion &&
			(focusOnQuestion.questionId === question.id ||
				(focusOnQuestion.questionId === -1 &&
					!!questionsArr[focusOnQuestion.length - 1] &&
					questionsArr[focusOnQuestion.length - 1].id === question.id))
		) {
			questionInputRef.current.focusTextInput();
			setFocusOnQuestion({ questionId: null });
		}
	}, [focusOnQuestion, question.id, questionInputRef, questions, setFocusOnQuestion, tempTemplate]);

	const [asset, assetId, assetIsVideo, defaultAssetUsed = false] = useMemo(() => {
		if (
			translation &&
			typeof translation === 'object' &&
			translation?.asset &&
			Object.keys(translation?.asset).length
		) {
			const isVideo = misc.assetIsVideo(translation.asset);
			return [translation.asset, translation.assetId, isVideo, false];
		}

		if (
			mainTranslation &&
			typeof mainTranslation === 'object' &&
			mainTranslation?.asset &&
			Object.keys(mainTranslation?.asset).length
		) {
			const isVideo = misc.assetIsVideo(mainTranslation.asset);
			return [mainTranslation?.asset, mainTranslation?.assetId, isVideo, true];
		}

		if (tempAssetUrl) {
			const isVideo = misc.assetIsVideo(tempAsset);
			return [tempAssetUrl, tempAssetId, isVideo, false];
		}

		return [translation?.asset, translation?.assetId];
	}, [mainTranslation, tempAsset, tempAssetId, tempAssetUrl, translation]);

	useEffect(() => {
		if (question) {
			if (question?.style === 'grid' && question.attributes && question.attributes.length < 1 && !tempTemplate) {
				addAttributePlaceholder(question.id);
				setIsPlaceholderActive(true);
			}
		}
	}, [addAttributePlaceholder, question, question.style, tempTemplate]);

	const onAssetChange = value => {
		const translations = question.translations.map(trans => {
			delete trans.asset;
			delete trans.deletedAt;
			delete trans.questionId;
			return {
				...trans,
				assetId: trans.languageCode === language ? value[0] : trans.assetId,
			};
		});

		if (tempTemplate) {
			const assetDeleted = value.length === 1 && value[0] === 0;
			setTempAssetId(assetDeleted ? 0 : value);
			if (!assetDeleted) {
				services.assetService.getOne(value).then(response => {
					setTempAsset(response);
					setTempAssetUrl(response.url);
				});
			} else {
				setTempAsset(null);
				setTempAssetUrl(null);
			}
			tempTemplate.estimatedIncidenceRate = null;
			const i = tempTemplate.customQuestions.findIndex(q => q.id === question.id);
			tempTemplate.customQuestions[i].translations = translations;
			setTempTemplate({ ...tempTemplate });
		}
		patchQuestion(question.id, { translations });
	};

	const saveSetting = (setting, value, type) => {
		let data = value;
		delete data.languageCode;
		if (
			setting !== 'option_image_cropping' &&
			setting !== 'option_layout' &&
			setting !== 'attribute_layout' &&
			setting !== 'attribute_image_cropping'
		) {
			data = {
				label: setting,
				type,
				value: value.toString(),
			};
		}
		if (question && question.settings) {
			question.settings = [...question.settings];
		} else {
			question.settings = [];
		}

		const settingToUpdateOrCreate = question?.settings?.find(s => s?.label === setting);
		if (tempTemplate) {
			// Save setting or update setting screening question
			patchScreeningQuestionSetting(question?.id, settingToUpdateOrCreate?.id, data);
		} else if (settingToUpdateOrCreate?.id) {
			patchQuestionSetting(studyId, question.id, settingToUpdateOrCreate.id, data);
		} else {
			createQuestionSetting(question.id, data);
		}
	};

	const onFocus = useCallback(
		selectedQuestion => {
			if (blockUpdateOrBreakTemplate) {
				blockUpdateOrBreakTemplate(() => {
					setIsActive(true);
					setActiveQuestion(selectedQuestion);
				});
			} else {
				setIsActive(true);
				setActiveQuestion(selectedQuestion);
			}
		},
		[blockUpdateOrBreakTemplate, setActiveQuestion],
	);

	const onBlur = useCallback(() => {
		setIsActive(false);
	}, [setIsActive]);

	/**
	 * sets focus on active question
	 */
	const setFocusOnCurrentQuestion = useCallback(() => {
		questionInputRef.current.focusTextInput();
	}, [questionInputRef]);

	/**
	 * Sets focus:
	 * First option if not open-ended
	 * If open-ended, then tab to next question
	 * If shift-tab, then tab to previous question
	 * @param {*} tabKey - boolean: true if pure tab, false if shift+tab
	 */
	const focusOnFirstOption = useCallback(
		tabKey => {
			if (tabKey) {
				if (
					question.options.length === 0 ||
					question.style === CONSTANTS.questions.options.style.openEnded ||
					!focusOnOption(question.id, 0)
				) {
					setFocusOnNextQuestion(question.id);
				}
			} else {
				// shift-tab
				setFocusOnLastQuestion(question.id);
			}
		},
		[
			focusOnOption,
			question.id,
			question.options.length,
			question.style,
			setFocusOnLastQuestion,
			setFocusOnNextQuestion,
		],
	);

	const resetRankNLimitSetting = () => {
		const topNSetting = question?.settings?.find(s => s.label === 'top-n')?.value === 'true';
		const topNSettingLimit = question?.settings?.find(s => s.label === 'top-n-limit')?.value;
		if (topNSetting && question?.options?.length - 1 < parseInt(topNSettingLimit)) {
			saveSetting('top-n-limit', question?.options?.length - 1, 'integer');
		}
	};

	const deleteQuestionOption = useCallback(
		(questionId, answerId) => {
			if (question?.style === 'ranked') {
				resetRankNLimitSetting();
			}

			if (typeof answerId === 'string' && answerId.includes('placeholder')) {
				setIsPlaceholderActive(false);
				removeOptionPlaceholder(questionId, answerId);
			} else if (tempTemplate) {
				deleteScreeningOption(questionId, answerId);
			} else {
				removeQuestionOption(questionId, answerId);
			}
		},
		[removeOptionPlaceholder, removeQuestionOption, tempTemplate, deleteScreeningOption, question],
	);

	const deleteQuestionAttribute = useCallback(
		(questionId, attributeId) => {
			if (typeof attributeId === 'string' && attributeId.includes('placeholder')) {
				setIsPlaceholderActive(false);
				removeAttributePlaceholder(questionId, attributeId);
			} else if (tempTemplate) {
				deleteScreeningAttribute(questionId, attributeId);
			} else {
				removeQuestionAttribute(questionId, attributeId);
			}
		},
		[removeAttributePlaceholder, tempTemplate, removeQuestionAttribute, deleteScreeningAttribute],
	);

	const createCustomTargetPlaceholder = useCallback(() => {
		// Prevent duplicate temp IDs
		let randomId = Math.ceil(Math.random() * 1000);
		while (optionTempIds.includes(randomId)) {
			randomId = Math.ceil(Math.random() * 1000);
		}
		const optionTempIdsCopy = [...optionTempIds];
		optionTempIdsCopy.push(randomId);
		setOptionTempIds(optionTempIdsCopy);
		let id;
		let idExists;
		do {
			idExists = false;
			id = `temp_${questionIndex}${randomId}`;
			for (const option of question.options) {
				if (option.id === id) {
					idExists = true;
					break;
				}
			}
		} while (idExists);

		const orderIdx =
			(last(question.options.filter(op => !op?.isNoneOfTheAbove && !op?.isOtherSpecify))?.order || 0) + 1;

		const patchedQuestion = { ...question };

		const { isAsc, isDesc } = getMeanScoreScaleOrder(patchedQuestion?.options);

		// * excludedOptions don't have scale point by default so we have to exclude them to get `maxScalePoint`
		const excludedOptions = patchedQuestion?.options?.filter(option => isExcludedMeanScoreOption(option));
		let includedOptions = patchedQuestion?.options?.filter(option => !isExcludedMeanScoreOption(option));

		// * Max scale point including newly added option
		const maxScalePoint = (includedOptions?.length ?? 0) + 1;

		let scalePoint = null;

		if (isAsc) {
			scalePoint = maxScalePoint;
		}

		if (isDesc) {
			scalePoint = 1;

			includedOptions = orderBy(includedOptions, 'order', 'asc').map((option, index) => ({
				...option,
				scalePoint: isExcludedMeanScoreOption(option) ? null : maxScalePoint - index,
			}));
		}

		const placeholderOption = {
			id,
			order: orderIdx,
			[CONSTANTS.questions.options.isOtherSpecify]: false,
			[CONSTANTS.questions.options.isNoneOfTheAbove]: false,
			[CONSTANTS.questions.options.lockOrder]: false,
			translations: [
				{
					languageCode: study?.language || study?.currentLanguage,
					label: '',
				},
			],
			placeholder: true,
			scalePoint,
		};

		patchedQuestion.options = [
			...includedOptions,
			placeholderOption, // * Excluded from mean score options' position is fixed at the end of the options array
			...excludedOptions,
		];

		const tempTemplateCopy = { ...tempTemplate };

		tempTemplateCopy.customQuestions[questionIndex] = patchedQuestion;

		isDesc &&
			batchSetAudienceTemplateScreeningQuestionOptions({
				questionId: patchedQuestion.id,
				options: patchedQuestion.options,
			});

		setTempTemplate({ ...tempTemplateCopy });
	}, [
		optionTempIds,
		question,
		questionIndex,
		setOptionTempIds,
		setTempTemplate,
		study,
		tempTemplate,
		batchSetAudienceTemplateScreeningQuestionOptions,
	]);

	const createCustomTargetAttributePlaceholder = useCallback(() => {
		// Prevent duplicate temp IDs
		let randomId = Math.ceil(Math.random() * 1000);
		while (attributeTempIds.includes(randomId)) {
			randomId = Math.ceil(Math.random() * 1000);
		}
		const attributeTempIdsCopy = [...attributeTempIds];
		attributeTempIdsCopy.push(randomId);
		setAttributeTempIds(attributeTempIdsCopy);
		let id;
		let idExists;
		do {
			idExists = false;
			id = `temp_${questionIndex}${randomId}`;
			for (const attribute of question.attributes) {
				if (attribute.id === id) {
					idExists = true;
					break;
				}
			}
		} while (idExists);
		question.attributes.push({
			id,
			order: last(question.attributes)
				? last(orderBy(question.attributes, 'order')).order + 1
				: question.attributes.length,
			[CONSTANTS.questions.attributes.lockOrder]: false,
			translations: [
				{
					languageCode: study?.language || study?.currentLanguage,
					label: '',
				},
			],
			placeholder: true,
		});
		tempTemplate.customQuestions[questionIndex] = question;
		setTempTemplate({ ...tempTemplate });
		// TODO set focus to recently added option, determine orderIdx less the number of special cases (other, None)
		// focusOnOption(question.id, orderIdx);
	}, [attributeTempIds, question, questionIndex, setAttributeTempIds, setTempTemplate, study, tempTemplate]);

	const handleOptionScalePointValue = useCallback(
		({ optionId, scalePoint }) => {
			const questionId = question.id;

			if (tempTemplate) {
				setScreeningOptionScalePointValue({ questionId, optionId, scalePoint });
			} else {
				setOptionScalePointValue({
					questionId,
					optionId,
					scalePoint,
					filterImportQuestions, // ? Is it needed?
				});
			}
		},
		[filterImportQuestions, question.id, setOptionScalePointValue, setScreeningOptionScalePointValue, tempTemplate],
	);

	useEffect(() => {
		// always have at least one answer in a question if in manage study or audience
		if (
			question &&
			question.options &&
			question.options.length < 1 &&
			question?.style !== 'open-ended' &&
			question?.style !== 'emoji'
		) {
			if (tempTemplate) {
				createCustomTargetPlaceholder();
			} else {
				addOptionPlaceholder(question.id);
				setIsPlaceholderActive(true);
			}
		}

		if (question && question.attributes && question.attributes.length < 1 && question?.style === 'grid')
			if (tempTemplate) {
				createCustomTargetAttributePlaceholder();
			} else {
				addAttributePlaceholder(question.id);
				setIsPlaceholderActive(true);
			}
	}, [
		addAttributePlaceholder,
		addOptionPlaceholder,
		createCustomTargetAttributePlaceholder,
		createCustomTargetPlaceholder,
		question,
		tempTemplate,
	]);

	// NotA and Other options settings
	const [localSettings, changeLocalSetting] = useState(question || {});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		changeLocalSetting(question);
	}, [question?.settings]);

	const onSettingChange = useCallback(
		(setting, value) => {
			const data = { [setting]: value };

			// Handle adding/removing options for none/other
			if (setting === CONSTANTS.questions.settings.hasNoneOfTheAboveOption && value !== question[setting]) {
				if (value) {
					const notaOption = {
						value: CONSTANTS.questions.options.defaults.noneOfTheAbove,
						[CONSTANTS.questions.options.isNoneOfTheAbove]: true,
						[CONSTANTS.questions.options.isOtherSpecify]: false,
						[CONSTANTS.questions.options.lockOrder]: true,
						order: 101,
						translations: [
							{
								languageCode: language,
								label: CONSTANTS.questions.options.defaults.noneOfTheAbove,
							},
						],
					};

					setIsPlaceholderActive(false);

					if (tempTemplate) {
						addScreeningQuestionOption(question?.id, notaOption);
					} else {
						addQuestionOption(question?.id, notaOption);
					}
				} else {
					// eslint-disable-next-line no-lonely-if
					if (tempTemplate) {
						const notaOption = options?.find(option => option?.isNoneOfTheAbove);
						if (notaOption) deleteScreeningQuestionOption(question?.id, notaOption?.id);
					} else {
						// remove option for NotA
						const notaOption = options?.find(option => option?.isNoneOfTheAbove);
						if (notaOption) removeQuestionOption(question?.id, notaOption?.id);
					}
				}
			} else if (setting === CONSTANTS.questions.settings.hasOtherSpecifyOption && value !== question[setting]) {
				if (value) {
					// add option for Other
					const otherOption = {
						value: CONSTANTS.questions.options.defaults.otherSpecify,
						[CONSTANTS.questions.options.isNoneOfTheAbove]: false,
						[CONSTANTS.questions.options.isOtherSpecify]: true,
						[CONSTANTS.questions.options.lockOrder]: true,
						order: 100,
						translations: [
							{
								languageCode: language,
								label: CONSTANTS.questions.options.defaults.otherSpecify,
							},
						],
					};
					setIsPlaceholderActive(false);
					if (tempTemplate) {
						addScreeningQuestionOption(question?.id, otherOption);
					} else {
						addQuestionOption(question?.id, otherOption);
					}
				} else {
					// eslint-disable-next-line no-lonely-if
					if (tempTemplate) {
						const otherOption = options?.find(option => option?.isOtherSpecify);
						if (otherOption) deleteScreeningQuestionOption(question?.id, otherOption?.id);
					} else {
						// remove option for Other
						const otherOption = options?.find(option => option?.isOtherSpecify);
						if (otherOption) removeQuestionOption(question?.id, otherOption?.id);
					}
				}
			}

			// Set local version as well temporarily
			question[setting] = value;

			if (setting === 'addScale' && !!value) {
				// * Check if the question options have `scalePoint` values
				const optionsHaveScalePoints = options?.some(option => !Number.isNaN(parseInt(option?.scalePoint)));

				// * Set default values if they don't have
				if (!optionsHaveScalePoints) {
					const orderedOptions = orderBy(options, 'order', 'asc');
					const optionsWithDefaultScalePoints = orderedOptions.map(
						(
							{
								// * Exclude extra keys from the api payload
								isDisplayedInReporting,
								maskedOptions,
								asset: excludedAsset,
								assetVariations,
								...option
							},
							index,
						) => ({
							...option,
							scalePoint: isExcludedMeanScoreOption(option) ? null : index + 1,
						}),
					);
					data.options = optionsWithDefaultScalePoints;
				}
			}

			if (tempTemplate) {
				patchScreeningQuestionSetting(question?.id, false, data);
			} else {
				// Save setting in patch
				patchQuestion(question?.id, data);
			}
		},
		[
			addQuestionOption,
			language,
			options,
			patchQuestion,
			question,
			removeQuestionOption,
			tempTemplate,
			patchScreeningQuestionSetting,
			addScreeningQuestionOption,
			deleteScreeningQuestionOption,
		],
	);

	const changeSetting = useCallback(
		setting => {
			const value = !localSettings[setting];
			changeLocalSetting({ ...localSettings, [setting]: value });
			onSettingChange(setting, value);
		},
		[localSettings, onSettingChange],
	);

	const addOptionsPlaceholderCallback = useCallback(() => {
		if (tempTemplate) {
			createCustomTargetPlaceholder();
		} else {
			setIsPlaceholderActive(true);
			addOptionPlaceholder(question?.id);
		}
	}, [addOptionPlaceholder, createCustomTargetPlaceholder, question, tempTemplate]);

	const addAttributePlaceholderCallback = useCallback(() => {
		if (tempTemplate) {
			createCustomTargetAttributePlaceholder();
		} else {
			setIsPlaceholderActive(true);
			addAttributePlaceholder(question?.id);
		}
	}, [addAttributePlaceholder, createCustomTargetAttributePlaceholder, question, tempTemplate]);

	const imageAlignSetting = question?.settings?.find(
		s => s?.label === CONSTANTS.imageAlignmentSettings.questionSection,
	)?.value;

	const centerPreview =
		assetIsVideo ||
		misc.assetIsVideo(asset) ||
		!imageAlignSetting ||
		imageAlignSetting === CONSTANTS.imageAlignmentSettings.centerAlign;

	const renderSelectMessage = useCallback(() => {
		const totalOptions = [...new Set(calculateTotalOptions(question?.options, sections))].length;
		const isUnlimited =
			!question?.settings?.find(setting => setting?.label === 'limit-type') ||
			question?.settings?.find(setting => setting?.label === 'limit-type')?.value === 'unlimited';
		const limitType = question?.settings?.find(setting => setting?.label === 'limit-type')?.value;
		const limitNumber =
			(question?.settings?.find(setting => setting?.label === 'limit')?.value > totalOptions
				? totalOptions
				: question?.settings?.find(setting => setting?.label === 'limit')?.value) || 1;
		const isSingleSelect =
			question?.settings?.length < 1 ||
			question?.settings?.find(setting => setting?.label === 'multi-select')?.value !== 'true' ||
			!question?.settings ||
			(question?.settings?.length === 1 && question?.settings?.find(setting => setting?.label === 'optional'));

		const rankTopN = question?.settings?.find(setting => setting?.label === 'top-n')?.value === 'true';
		const rankTopNLimit = question?.settings?.find(setting => setting?.label === 'top-n-limit')?.value;

		let selectMessage;
		if (isSingleSelect) {
			selectMessage = '';
		} else if (isUnlimited) {
			selectMessage = "Choose as many as you'd like";
		} else if (limitType === 'range') {
			selectMessage = `Choose up to ${limitNumber}`;
		} else if (limitType === 'exact') {
			selectMessage = `Choose ${limitNumber}`;
		}

		if (rankTopN && rankTopNLimit) {
			selectMessage = `Please rank your top ${rankTopNLimit === '1' ? '' : rankTopNLimit} choice${
				rankTopNLimit === '1' ? '' : 's'
			}`;
		} else if (question?.style === 'ranked' && question?.options?.length >= 10) {
			selectMessage = `Please rank your top ${SELECT_N_LIMIT} choices`;
		} else if (question?.style === 'ranked') {
			selectMessage = 'Please rank all the options';
		}

		if (tempTemplate && question?.settings?.length) {
			const isMulti = question?.settings?.find(s => s.label === 'multi-select')?.value;
			if (!isMulti) selectMessage = '';
		}
		const classNameContainer = tempTemplate
			? `${el('audience_select-message')} ${isSingleSelect ? el('single-select') : ''}`
			: `${el('select-message')} ${isSingleSelect ? el('single-select') : ''}`;
		return <div className={classNameContainer}>{selectMessage}</div>;
	}, [tempTemplate, question]);

	const deleteAudienceItemLogic = logicId => {
		dispatch(
			actions.removeEasyLogic(
				studyId,
				question?.audienceCollectionId || audienceCollection?.content?.id,
				question?.id,
				logicId,
			),
		);
	};
	const patchAudienceItemLogic = logicData => {
		// if (logicData.actionType === 'goto') return;

		delete logicData?.audienceUuid;
		delete logicData?.triggerSectionId;
		delete logicData?.triggerType;
		delete logicData?.sectionId;
		delete logicData?.actionSectionId;
		delete logicData?.sectionItemId;

		// const triggerOptions = logicData.triggerOptions[logicData.triggerOptions.length - 1];
		const triggerOptions = logicData?.triggerOptions?.map(option => {
			delete option.acceptableAnswer;
			delete option.isSelected;
			return option;
		});

		if (logicData.actionType !== 'goto') {
			delete logicData.itemId;
			delete logicData.actionItemId;
		}

		dispatch(
			actions.addEasyLogic(
				studyId,
				question?.audienceCollectionId || audienceCollection?.content?.id,
				question?.id,
				{
					logic: [
						{
							...logicData,
							triggerOptions,
						},
					],
				},
				tempTemplate?.isAudienceTemplate,
			),
		);
	};

	const canChangeQuotaOptions = useMemo(() => {
		const liveGroups = question?.interlockedWithinGroup?.filter(group => group.status === 'live');
		return !liveGroups?.length;
	}, [question?.interlockedWithinGroup]);

	return (
		<div className={`question-item-container ${question?.style === 'heatmap' ? 'heat-map' : ''}`}>
			<div
				id={`question-item-${question.id}`}
				className={`${className}`} /* TODO: double bind this with active state on scroll logic ${isActive ? ' active' : ''} */
				style={{ minHeight: question.options.length * 50 + 200 }}
			>
				<div className={`${audience ? el('audience-content') : el('content')} `}>
					{loading && <Loader centered />}

					<div className={`${el('content--left')}${filterImportQuestions ? ' full-width' : ''}`}>
						<div className={el('question-row')}>
							<QuestionHeader
								ref={questionInputRef}
								focusOnFirstOption={focusOnFirstOption}
								study={study}
								question={question}
								section={section}
								sections={sections}
								audience={audience}
								language={language || audience?.languageCode.languageCode}
								studyQuestionNumber={studyQuestionNumber}
								asset={asset}
								defaultAssetUsed={defaultAssetUsed}
								assetId={assetId}
								onAssetChange={onAssetChange}
								setNewValue={setNewValue}
								tempTemplate={tempTemplate}
								setTempTemplate={setTempTemplate}
								blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
								onFocus={onFocus}
								onBlur={onBlur}
								newValue={newValue}
								updateQuestionLabel={updateQuestionLabel}
								updateCustomQualifierLabel={updateCustomQualifierLabel}
								deleteQuestion={deleteQuestion}
								filterImportQuestions={filterImportQuestions}
								updateScreeningQuestionLabel={updateScreeningQuestionLabel}
							/>
						</div>
						{question?.style !== 'heatmap' && assetId && asset ? (
							<div className={el(`asset-preview ${centerPreview ? 'center' : ''}`)}>
								<div className={el('asset-wrapper')}>
									{!defaultAssetUsed ? (
										<div className={el('button-wrapper')}>
											<div className={`${el('asset-clear-button')}`}>
												<IconButton icon="clear" onClick={() => onAssetChange([0])} />
											</div>
										</div>
									) : null}
									<Tooltip
										disabled={!defaultAssetUsed}
										position="top"
										animation="shift"
										trigger="mouseenter"
										theme="basic-text-tooltip"
										distance={misc.assetIsVideo(asset) ? 4 : 66}
										html={
											<div>
												To delete media, switch to the
												<br />
												language where it was added.
											</div>
										}
									>
										{misc.assetIsVideo(asset) ? (
											<div className={el('video-wrapper')}>
												<ReactPlayer url={asset?.url} width="262px" height="148px" controls />
											</div>
										) : (
											<img
												className={el('image-wrapper')}
												src={misc.getAssetVariationUrl(asset, ['thumbnail'])}
												alt={asset?.title}
											/>
										)}
									</Tooltip>
								</div>
							</div>
						) : null}
						{filterImportQuestions ? null : (
							<QuestionSettingsTabs
								activeTab={activeTab}
								audienceCollection={audienceCollection}
								audience={audience || tempTemplate}
								setActiveTab={setActiveTab}
								question={question}
								setActiveQuestion={setActiveQuestion}
								hasQuotas={hasQuotas}
								hasInterlockedQuotas={hasInterlockedQuotas}
								canChangeQuotaOptions={canChangeQuotaOptions}
								quotaError={quotaError}
								updateAllScreeningOptionQuota={updateAllScreeningOptionQuota}
							/>
						)}
						{question?.style !== 'open-ended' && activeTab === 'preview' && renderSelectMessage()}
						{activeTab === 'preview' && (
							<QuestionOptions
								isFocusOnLastAnswer={isFocusOnLastAnswer}
								setIsFocusOnLastAnswer={setIsFocusOnLastAnswer}
								setFocusOnCurrentQuestion={setFocusOnCurrentQuestion}
								setFocusOnNextQuestion={setFocusOnNextQuestion}
								section={section}
								options={options}
								attributes={currentLanguageAttributes}
								language={language || audience?.languageCode?.languageCode}
								answerType={question?.style}
								updateOptionLabel={updateOptionLabel}
								updateOptionScalePoint={updateOptionScalePoint}
								setOptionScalePointValue={handleOptionScalePointValue}
								updateAttributeLabel={updateAttributeLabel}
								updateOptionAsset={updateOptionAsset}
								updateAttributeAsset={updateAttributeAsset}
								questionId={question.id}
								question={question}
								removeQuestionOption={deleteQuestionOption}
								removeQuestionAttribute={deleteQuestionAttribute}
								patchQuestion={patchQuestion}
								addQuestionOption={addQuestionOption}
								addQuestionAttribute={addQuestionAttribute}
								setIsPlaceholderActive={setIsPlaceholderActive}
								isPlaceholderActive={isPlaceholderActive}
								onFocus={onFocus}
								onBlur={onBlur}
								addOptionPlaceholder={addOptionsPlaceholderCallback}
								addAttributePlaceholder={addAttributePlaceholderCallback}
								defaultLanguage={study?.defaultLanguage}
								studyType={study?.type}
								tempTemplate={tempTemplate}
								setTempTemplate={setTempTemplate}
								blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
								filterImportQuestions={filterImportQuestions}
								changeSetting={changeSetting}
								loading={loading}
								setLoading={setLoading}
								localSettings={localSettings}
								audience={audience}
								updateScreeningOption={updateScreeningOption}
								updateScreeningOptionScalePoint={updateScreeningOptionScalePoint}
								setScreeningOptionScalePointValue={setScreeningOptionScalePointValue}
								updateScreeningAttribute={updateScreeningAttribute}
								deleteScreeningOption={deleteScreeningOption}
								hasQuotas={hasQuotas}
								updateScreeningQuota={updateScreeningQuota}
								hasInterlockedQuotas={hasInterlockedQuotas}
								setQuotaError={setQuotaError}
								saveSetting={saveSetting}
								screeningQuestion={screeningQuestion}
								batchUpdateAudienceTemplateScreeningQuestionOptions={
									batchUpdateAudienceTemplateScreeningQuestionOptions
								}
							/>
						)}
						{activeTab === 'preview' && question?.style === 'heatmap' && (
							<AddHeatmapImage
								study={study}
								section={section}
								question={question}
								onAssetChange={onAssetChange}
								asset={asset}
							/>
						)}
						{activeTab === 'preview' &&
							question?.style !== 'open-ended' &&
							question?.style !== 'grid' &&
							question?.style !== 'heatmap' &&
							question?.style !== 'emoji' &&
							!filterImportQuestions && (
								<div
									className={audience ? el('audience-additional-options') : el('additional-options')}
								>
									{showScale && (
										<div className={el('flip-scale-button-wrapper')}>
											<ModifyScaleButton
												setOptionScalePointValue={handleOptionScalePointValue}
												{...{
													question,
													screeningQuestion,
													tempTemplate,
													batchUpdateAudienceTemplateScreeningQuestionOptions,
												}}
											/>
										</div>
									)}

									<AddAnswerButton
										question={question}
										addOptionPlaceholder={() => {
											if (tempTemplate) {
												createCustomTargetPlaceholder();
											} else {
												setIsPlaceholderActive(true);
												addOptionPlaceholder(question.id);
											}
										}}
									/>

									<AdditionalQuestionOptionSettings
										question={question}
										setLoading={setLoading}
										changeSetting={changeSetting}
									/>
								</div>
							)}
						{activeTab === 'logic' && (
							<QuestionLogicConditions
								logic={logic}
								study={study}
								audience={audience || tempTemplate}
								question={question}
								section={section}
								sections={sections}
								sectionOrder={sectionOrder}
								patchItem={patchItem}
								deleteItemLogic={deleteItemLogic}
								tempTemplate={tempTemplate}
								setTempTemplate={setTempTemplate}
								blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
								deleteAudienceItemLogic={deleteAudienceItemLogic}
								patchAudienceItemLogic={patchAudienceItemLogic}
								language={language}
							/>
						)}
					</div>

					<div className={`${el('content--right')}${filterImportQuestions ? ' hidden' : ''}`}>
						<QuestionOptionsConfig
							section={section}
							sections={sections}
							question={question}
							asset={asset || assetId || tempAssetId || tempAssetUrl}
							assetType={assetIsVideo ? 'video' : 'image'}
							saveSetting={saveSetting}
							tempTemplate={tempTemplate}
							blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
							deleteQuestion={deleteQuestion}
							changeSetting={changeSetting}
							localSettings={localSettings}
							setLoading={setLoading}
							audience={audience}
							studyId={studyId}
							changeScreeningQuestionVisibility={changeScreeningQuestionVisibility}
							familyDomain={familyDomain}
							audienceTemplate={audienceTemplate}
							dragHandleContextId={dragHandleContextId}
						/>
					</div>
				</div>
				{!noNewQuestion && !isLastQuestionItem && (
					<div className="add-answer-button-container">
						<AddQuestionButton
							audience={audience}
							question={question}
							addQuestionOptions={
								screeningQuestionAddQuestionOptions.length > 0
									? screeningQuestionAddQuestionOptions
									: addQuestionOptions
							}
							tempTemplate={tempTemplate}
							blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
							screeningQuestion={screeningQuestion}
							setOpenQuestionLibrary={setOpenQuestionLibrary}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

QuestionItem.propTypes = {
	audience: PropTypes.object,
	logic: PropTypes.any,
	sectionOrder: PropTypes.any,
	question: PropTypes.object,
	questions: PropTypes.array,
	studyQuestionNumber: PropTypes.number,
	language: PropTypes.any,
	section: PropTypes.any,
	sections: PropTypes.any,
	addQuestionOptions: PropTypes.array,
	deleteQuestion: PropTypes.func,
	updateQuestionLabel: PropTypes.func,
	updateCustomQualifierLabel: PropTypes.func,
	updateOptionLabel: PropTypes.func,
	updateOptionScalePoint: PropTypes.func,
	setOptionScalePointValue: PropTypes.func,
	updateAttributeLabel: PropTypes.func,
	updateOptionAsset: PropTypes.func,
	updateAttributeAsset: PropTypes.func,
	removeQuestionOption: PropTypes.func,
	removeQuestionAttribute: PropTypes.func,
	patchItem: PropTypes.func,
	deleteItemLogic: PropTypes.func,
	addOptionPlaceholder: PropTypes.func,
	addAttributePlaceholder: PropTypes.func,
	removeOptionPlaceholder: PropTypes.func,
	removeAttributePlaceholder: PropTypes.func,
	addQuestionOption: PropTypes.func,
	addQuestionAttribute: PropTypes.func,
	patchQuestion: PropTypes.func,
	createQuestionSetting: PropTypes.func,
	patchQuestionSetting: PropTypes.func,
	tempTemplate: PropTypes.object,
	setTempTemplate: PropTypes.func,
	blockUpdateOrBreakTemplate: PropTypes.func,
	noNewQuestion: PropTypes.any,
	focusOnQuestion: PropTypes.object,
	setFocusOnNextQuestion: PropTypes.func,
	setFocusOnQuestion: PropTypes.func,
	focusOnOption: PropTypes.any,
	setFocusOnLastQuestion: PropTypes.func,
	filterImportQuestions: PropTypes.any,
	activeQuestion: PropTypes.any,
	setActiveQuestion: PropTypes.func,
	optionTempIds: PropTypes.array,
	setOptionTempIds: PropTypes.func,
	attributeTempIds: PropTypes.array,
	setAttributeTempIds: PropTypes.func,
	familyDomain: PropTypes.string,
	screeningQuestion: PropTypes.bool,
	setOpenQuestionLibrary: PropTypes.any,
	changeScreeningQuestionVisibility: PropTypes.func,
	updateScreeningOption: PropTypes.func,
	updateScreeningOptionScalePoint: PropTypes.func,
	setScreeningOptionScalePointValue: PropTypes.func,
	updateScreeningAttribute: PropTypes.func,
	deleteScreeningOption: PropTypes.func,
	deleteScreeningAttribute: PropTypes.func,
	updateScreeningQuota: PropTypes.func,
	updateAllScreeningOptionQuota: PropTypes.func,
	deleteAllScreeningOptionQuota: PropTypes.func,
	patchScreeningQuestionSetting: PropTypes.func,
	addScreeningQuestionOption: PropTypes.func,
	deleteScreeningQuestionOption: PropTypes.func,
	updateScreeningQuestionLabel: PropTypes.func,
	studyId: PropTypes.number,
	screeningQuestionAddQuestionOptions: PropTypes.array,
	audienceTemplate: PropTypes.any,
	batchUpdateAudienceTemplateScreeningQuestionOptions: PropTypes.func,
	batchSetAudienceTemplateScreeningQuestionOptions: PropTypes.func,
	dragHandleContextId: PropTypes.any,
};

export default connect()(QuestionItem);
