import toastr from 'toastr';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'loading-toaster';
const el = name => cn(className, name);

const toasterContent = text =>
	`<div class=${className}>
		<div class=${el('text')}>${text}</div>
		<div class=${el('spinner')} />
	</div>`;

export const LoadingToaster = {
	show: text => {
		toastr.options = {
			timeOut: 60000,
			extendedTimeOut: 60000,
			positionClass: 'toast-bottom-left',
		};
		toastr.info(toasterContent(text));
	},
};

export default LoadingToaster;
