import { getClientsData } from '../../selectors';

export const getCommonError = state => getClientsData(state).commonError;
export const getClientId = state => getClientsData(state).clientId;
export const getClients = state => getClientsData(state).clients;
export const getUsers = state => getClientsData(state).users;
export const getGroups = state => getClientsData(state).groups;
export const getAddGroupModal = state => getClientsData(state).addGroupModal;
export const getAddUserLoading = state => getClientsData(state).addUserLoading;
export const getAddUserComplete = state => getClientsData(state).addUserComplete;
export const getDeleteUserModal = state => getClientsData(state).deleteUserModal;
export const getDeleteGroupModal = state => getClientsData(state).deleteGroupModal;
export const getRoles = state => getClientsData(state).roles;
export const getManageGroupModal = state => getClientsData(state).manageGroupModal;
export const getSorting = state => getClientsData(state).sorting;
