import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { useDebounce, useIsBlueprint } from 'src/hooks';
import cn from 'src/utilities/bem-cn';
import ArrowDown from 'public/images/audience/arrow_down.svg';
import CHECK from 'public/images/audience/check.svg';
import CHECKED from 'public/images/audience/checked.svg';
import ErroIcon from 'public/images/audience/error.svg';
import Input from 'src/components/inputs/Input';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { Iconof } from '@upsiide/ui-components';
import Button from 'src/components/elements/Button';
import ResponsiveModal from 'src/components/shared/ResponsiveModal';
import { Tooltip } from 'react-tippy';
import * as services from 'src/services';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import * as studySelectors from 'src/domains/manage-study/selectors';
import * as manageBlueprintActions from 'src/domains/manage-blueprints/actions';
import * as manageStudyActions from 'src/domains/manage-study/actions';

import Loader from 'src/components/shared/Loader';
import ScreeningQuestionFilterDropdown from 'src/domains/manage-study/components/audience/ScreeningQuestionFilterDropdown';

import QuestionTypeIcon from 'src/components/shared/QuestionTypeIcon';
import AddQuestionButton from 'src/components/elements/AddQuestionButton';
import { countriesWithFlags } from 'src/utilities/upsiideCountriesWithFlag';

import QuestionLibraryHeader from './QuestionLibraryHeader';
import OptionsToolTip from './OptionsToolTip';

import './styles.scss';

const className = 'question-library';
const el = (name, mod) => cn(className, name, mod);

const QuestionLibrary = ({ open, onClick, onClose, customLabel, isTemplate = false, showButtonAddYourOwn = false }) => {
	const ref = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { isBlueprint } = useIsBlueprint();
	const selectors = isBlueprint ? manageBlueprintSelectors : studySelectors;
	const actions = isBlueprint ? manageBlueprintActions : manageStudyActions;

	const audienceCollectionLocal = useSelector(selectors.getAudienceCollection);
	const audienceEditTemplate = useSelector(selectors.getAudienceEditTemplate);
	const countryLanguages = useSelector(selectors.getLanguages);

	const audienceCollection = useMemo(() => {
		if (isTemplate) return audienceEditTemplate;
		return audienceCollectionLocal;
	}, [isTemplate, audienceEditTemplate, audienceCollectionLocal]);

	const { study } = useSelector(state => (isBlueprint ? state.manageBlueprint : state.manageStudy));

	const [categories, setCategory] = useState(['All']);
	const [selectedCategory, setSelectedCategory] = useState('All');
	const [selectedQuestions, setSelectedQuestions] = useState([]);

	const [text, setText] = useState('');
	const [searchLanguage, setSearchLanguage] = useState('');
	const [questionLibrary, setQuestionLibrary] = useState([]);
	const [filterQuestion, setFilterQuestion] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [currentLanguage, setLanguage] = useState(study?.currentLanguage);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState(null);
	const debouncedSearch = useDebounce(text, 500);

	const languages = useMemo(
		() =>
			study?.languages?.map(lang => ({
				language: countryLanguages?.find(l => l?.languageCode === lang)?.label || lang,
				label: lang?.toUpperCase(),
				value: lang,
			})) || [{ language: 'English', label: 'EN', value: 'en' }],
		[countryLanguages, study],
	);

	const audience = useMemo(
		() => ({
			demographicGroups: audienceCollection?.content?.demographicGroups || [],
			providerQuestions: [],
			customQuestions: [],
			languages,
			languageCode: languages?.find(lang => lang?.languageCode === study?.language) || {
				languageCode: study?.language,
			},
			countryLanguage: {
				languageLabel: languages?.find(lang => lang?.languageCode === study?.language)?.label,
				countryLabel: languages?.find(lang => lang?.languageCode === study?.language)?.countryLabel,
			},
		}),
		[audienceCollection, languages, study],
	);

	const blockUpdateOrBreakTemplate = useCallback(callbackFunction => {
		callbackFunction();
	}, []);

	const [showAudienceSubMenu, setShowAudienceSubMenu] = useState(false);
	const subMenuRef = useRef(null);

	useOutsideClick(subMenuRef, () => {
		setShowAudienceSubMenu(false);
	});

	const addNewQuestion = useCallback(
		async questionType => {
			const translations = study?.languages?.map(lang => ({
				label: '',
				languageCode: lang,
			})) || [{ label: '', languageCode: 'en' }];
			const screeningQuestionLength = audienceCollection?.content?.screeningQuestions.length;
			const newCustomTarget = {
				audienceCollectionId: audienceCollection?.content?.id,
				type: 'qualifier',
				style: questionType,
				label: '',
				filterLabel: '',
				status: 'public',
				sortOrder: screeningQuestionLength,
				isFilter: true,
				randomizeOptions: false,
				translations,
			};

			if (questionType === 'grid') {
				newCustomTarget.attributes = [];
			}

			try {
				await services.audienceService.createScreeningQuestionFromScratch(
					audienceCollection?.content.studyId,
					newCustomTarget,
				);
				const response = await services.audienceService.getCollection(
					audienceCollection?.content.studyId,
					audienceCollection?.content?.id,
				);
				if (isTemplate) {
					dispatch(actions.setAudienceEditTemplate({ loading: false, content: response?.data || null }));
				} else {
					dispatch(actions.setAudienceCollection({ loading: false, content: response?.data || null }));
				}
				if (audienceCollection?.content?.studyId === study?.id) {
					if (!location?.pathname?.includes('/questions')) navigate('questions');
				}
				onClose();
			} catch (error) {
				console.log(error);
				console.error(error);
			}
		},
		[audienceCollection, study, location, dispatch, navigate, onClose, isTemplate],
	);

	const addQuestionOptions = useMemo(
		() => [
			{
				name: 'select',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="multi-select" size="large" tooltip={false} />
						</div>
						Select
					</>
				),
				onClick: questionId => {
					addNewQuestion('multi-select', questionId);
				},
			},

			{
				name: 'grid',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="grid" size="large" tooltip={false} />
						</div>
						Grid
					</>
				),
				onClick: questionId => {
					addNewQuestion('grid', questionId);
				},
			},

			{
				name: 'ranked',
				label: (
					<>
						<div className={el('drag')}>
							<QuestionTypeIcon questionType="ranked" size="large" tooltip={false} />
						</div>
						Rank
					</>
				),
				onClick: questionId => {
					addNewQuestion('ranked', questionId);
				},
			},
		],
		[addNewQuestion],
	);

	// works for question and option
	const getTranslation = item => {
		const current = item?.translations?.find(translation => translation?.languageCode === currentLanguage);
		if (current) return current?.label || '';
		return item?.translations?.[0]?.label || '';
	};

	const getListOptionsTranslation = options =>
		options.map(opt => {
			const current = opt?.translations?.find(translation => translation?.languageCode === currentLanguage);
			if (current) return { label: current?.label || '' };
			return { label: opt?.translations?.[0]?.label || '' };
		});

	const getListAttributesTranslation = attributes =>
		attributes.map(att => {
			const current = att?.translations?.find(translation => translation?.languageCode === currentLanguage);
			if (current) return current?.label || '';
			return att?.translations?.[0]?.label || '';
		});

	const fetchClasses = useCallback(
		() => {
			services.studyService
				.getQuestionLibraryClasses()
				.then(({ data }) => {
					setCategory(['All', ...data]);
				})
				.catch(e => console.error(e));
		},
		// eslint-disable-next-line
		[currentLanguage],
	);

	const fetchQuestion = useCallback(
		(search = [], classes = []) => {
			setIsLoading(true);
			services.studyService
				.getQuestionLibrary(search || '', classes)
				.then(({ data }) => {
					if (data) {
						const filteredList =
							data.filter(q => {
								const foundByLabel =
									getTranslation(q)?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1;
								const foundByName = q?.name?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1;
								const foundByOption = getListOptionsTranslation(q.options)?.some(
									op => op?.label?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1,
								);
								return foundByLabel || foundByName || foundByOption;
							}) || [];
						setQuestionLibrary(filteredList);
					}
				})
				.catch(e => console.error(e))
				.finally(() => setIsLoading(false));
		},
		// eslint-disable-next-line
		[currentLanguage, activeFilters],
	);

	useEffect(() => {
		if (open) fetchClasses();
		if (!open && showAudienceSubMenu) setShowAudienceSubMenu(false);
		if (!open) clearLanguageTag();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		if (open) fetchQuestion(debouncedSearch?.trim());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearch, open]);

	const getLanguageLabel = lang => countryLanguages?.find(l => l?.languageCode === lang)?.label || lang;

	useEffect(() => {
		const countries = [];
		audienceCollection?.content?.demographicGroups?.forEach(group => {
			if (!countries.includes(group?.countryLanguage?.countryLabel))
				countries.push(group?.countryLanguage?.countryLabel);
		});

		const filterLanguages = [];
		questionLibrary.forEach(question => {
			question.translations.map(trans => {
				if (!filterLanguages.includes(trans.languageCode)) {
					filterLanguages.push(trans.languageCode);
				}
			});
		});

		const filterTypes = [
			// {
			// 	value: 'countries',
			// 	label: 'Country',
			// 	items: countries.map(item => ({ value: item, label: item })),
			// },
			{
				value: 'languages',
				label: 'Language',
				items: filterLanguages.map(lang => ({
					label: countryLanguages?.find(l => l?.languageCode === lang)?.label || lang,
					value: lang,
				})),
			},
		];

		setFilters(filterTypes);
		if (activeFilters?.languages?.length) {
			const questions = questionLibrary.filter(question =>
				question.translations.find(trans => activeFilters.languages.includes(trans.languageCode)),
			);
			setFilterQuestion(questions);
		} else {
			setFilterQuestion(questionLibrary);
		}
	}, [activeFilters?.languages, audienceCollection, countryLanguages, questionLibrary]);

	useEffect(() => {
		if (!open) setSelectedQuestions([]);
	}, [open]);

	useOutsideClick(ref, () => {
		onClose();
	});

	const onClearText = () => {
		if (text) {
			setText('');
		}
	};

	const onSelect = (e, question) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		const currentQuestion = selectedQuestions.find(
			quest => quest?.questionLibraryId === question?.questionLibraryId,
		);
		if (currentQuestion) {
			setSelectedQuestions(prev =>
				prev.filter(quest => quest?.questionLibraryId !== currentQuestion?.questionLibraryId),
			);
		} else {
			setSelectedQuestions(prev => [...prev, question]);
		}
	};

	const getCountryFlagById = (countryId = 1) => {
		const country = countriesWithFlags.find(({ id }) => id === countryId);
		return country?.flag;
	};

	const renderMissingTranslation = question => {
		const translation = question?.translations?.find(trans => trans?.languageCode === currentLanguage);
		if (!translation && study) {
			return (
				<div className={el('question_missing_translation')}>
					<img src={ErroIcon} alt="missing translation" />
					<span className={el('missing_translation')}>Translation missing</span>
				</div>
			);
		}

		return null;
	};

	const getLanguagesFromQuestion = question => {
		const questionLanguages = question?.translations.map(translation => ({
			languageCode: translation?.languageCode,
			value: translation?.languageCode?.toUpperCase() || '',
			label:
				countryLanguages?.find(l => l?.languageCode === translation?.languageCode)?.label ||
				translation?.languageCode,
		}));

		return questionLanguages;
	};

	const renderQuestionOptions = options =>
		options?.length && (
			<div className={el('question_tag_container')}>
				<div className={el('question_tags')}>
					{options?.slice(0, 3)?.map((option, i) => (
						<Tooltip
							className={el('question_tag')}
							theme="dark-blured"
							trigger="mouseenter"
							interactive
							html={<div className={el('question-tag-tooltip')}>{getTranslation(option)}</div>}
							key={`tag-${option?.label}-${i}`}
						>
							<span>{getTranslation(option)}</span>
						</Tooltip>
					))}
					{options?.length > 3 && (
						<OptionsToolTip
							label={<span className={el('question_tag_more')}>+{options.length - 3}</span>}
							options={getListOptionsTranslation(options)}
							field="label"
							renderCustomTooltipLabel={opt => opt.label}
						/>
					)}
				</div>
			</div>
		);

	const renderQuestionAttributesOptions = (attributes, options) => (
		<div className={el('question_tag_container')}>
			<div className={el('question_tags')}>
				{attributes?.length && (
					<>
						{attributes?.slice(0, 3)?.map((attribute, i) => (
							<Tooltip
								theme="dark-blured"
								trigger="mouseenter"
								interactive
								html={<div className={el('question-tag-tooltip')}>{getTranslation(attribute)}</div>}
								key={`tag-${attribute?.label}-${i}`}
							>
								<span className={el('question_tag')}>{getTranslation(attribute)}</span>
							</Tooltip>
						))}
						{attributes?.length > 3 && (
							<OptionsToolTip
								label={<span className={el('question_tag_more')}>+{attributes.length - 3}</span>}
								options={getListAttributesTranslation(attributes)}
								field="label"
							/>
						)}
					</>
				)}
			</div>
			{options.length && (
				<OptionsToolTip
					label={<span className={el('question_tag bolded')}>Answer Options</span>}
					options={getListAttributesTranslation(options)}
					field="label"
					title={null}
				/>
			)}
		</div>
	);

	const renderQuestion = (question, index) => (
		<div
			aria-hidden
			className={el(
				`question_container${
					selectedQuestions?.find(quest => quest?.questionLibraryId === question?.questionLibraryId)
						? '_active'
						: ''
				}`,
			)}
			key={`question-${index}`}
			onClick={e => {
				onSelect(e, question);
				if (showAudienceSubMenu) setShowAudienceSubMenu(false);
			}}
		>
			<div className={el('question_category_container')}>
				<div className={el('question_category_details_container')}>
					<QuestionTypeIcon questionType={question.type} size="small" tooltip={false} />
					<span className={el('question_category')}>{question?.name}</span>
					{/* <span className={el('question_category_flag')}>{getCountryFlagById(1)}</span> */}
					<OptionsToolTip
						title="Languages"
						label={
							<span className={el('question_category_language')}>
								{getLanguagesFromQuestion(question)
									.slice(0, 3)
									.map((lang, i, arr) => {
										if (lang.languageCode === currentLanguage)
											return (
												<span
													key={`${lang.value}-${i}`}
													className={el('question_category_language_active')}
												>
													{lang.value}
													{i + 1 < 3 &&
													arr.length > 1 &&
													i + 1 !== getLanguagesFromQuestion(question).length ? (
														<div className={el('dot')} />
													) : null}
												</span>
											);
										return (
											<span key={`${lang.value}-${i}`}>
												{lang.value}
												{i + 1 < 3 &&
												arr.length > 1 &&
												i + 1 !== getLanguagesFromQuestion(question).length ? (
													<div className={el('dot')} />
												) : null}
											</span>
										);
									})}
								{getLanguagesFromQuestion(question).length > 3 ? (
									<span>+{getLanguagesFromQuestion(question).length - 3} </span>
								) : null}
							</span>
						}
						field="label"
						renderCustomTooltipLabel={opt => opt.label}
						options={getLanguagesFromQuestion(question)}
					/>
					{renderMissingTranslation(question)}
				</div>
				<div className={el('question_add_container')} aria-hidden>
					<img
						src={
							!selectedQuestions?.find(quest => quest?.questionLibraryId === question?.questionLibraryId)
								? CHECK
								: CHECKED
						}
						alt="select"
					/>
				</div>
			</div>
			<span className={el('question_title')}>{getTranslation(question)}</span>
			{question.type === 'multi-select' && renderQuestionOptions(question.options)}
			{question.type === 'grid' && renderQuestionAttributesOptions(question.attributes, question.options)}
		</div>
	);

	const removeLanguageTag = lang => {
		const newLanguages = activeFilters?.languages.filter(l => l !== lang);
		setActiveFilters(prev => ({ ...prev, languages: newLanguages }));

		if (newLanguages.length) {
			const questions = questionLibrary.filter(question =>
				question.translations.find(trans => newLanguages.includes(trans.languageCode)),
			);
			setFilterQuestion(questions);
		} else {
			setFilterQuestion(questionLibrary);
		}
	};

	const clearLanguageTag = () => {
		if (activeFilters?.languages?.length) {
			setActiveFilters(prev => ({ ...prev, languages: [] }));
			setFilterQuestion(questionLibrary);
		}
	};

	const renderLanguageTag = (language = '', i = 0) => (
		<div key={`language-${i}`} className={el('language_container')}>
			<span className={el('language')}>{getLanguageLabel(language)}</span>
			<span className={el('remove_language')} aria-hidden onClick={() => removeLanguageTag(language)}>
				X
			</span>
		</div>
	);

	const onChangeCategory = async category => {
		setSelectedCategory(category);
		if (category === 'All') fetchQuestion(debouncedSearch?.trim());
		else fetchQuestion(debouncedSearch?.trim(), [category]);
	};

	const renderCategoryTag = (category = '', i = 0) => (
		<div
			key={`category-${i}`}
			className={el(`category_content${selectedCategory === category ? '_active' : ''}`)}
			aria-hidden
			onClick={() => onChangeCategory(category)}
		>
			<span className={el(`category${selectedCategory === category ? '_active' : ''}`)}>{category}</span>
		</div>
	);

	const renderLanguageDropdown = () => (
		<div
			ref={subMenuRef}
			className={el('language_dropdown')}
			onClick={e => {
				e.stopPropagation();
				e.preventDefault();

				if (e?.target?.className?.indexOf('search') === -1) setShowAudienceSubMenu(prev => !prev);
			}}
			onKeyDown={() => {}}
			tabIndex={0}
			role="button"
		>
			<span className={el('language_dropdown_title')}>
				{languages?.length ? languages.find(lang => lang?.value === currentLanguage)?.label : null}
				<img src={ArrowDown} className={`caret ${showAudienceSubMenu ? 'open' : ''}`} alt="" />
			</span>
			{showAudienceSubMenu && (
				<div className={el('language_dropdown_menu')}>
					{languages.length >= 15 && (
						<Input
							className={el('language_dropdown_search_input')}
							placeholder="Search"
							value={searchLanguage}
							onChange={e => setSearchLanguage(e)}
							autofocus
						/>
					)}
					<div className={el('language_dropdown_sub_menu')}>
						{languages
							.filter(lang => {
								if (searchLanguage) {
									return lang.language.toLowerCase().indexOf(searchLanguage.toLowerCase()) !== -1;
								}
								return lang;
							})
							.map((lang, i) => (
								<span
									key={`language-${lang}-${i}`}
									className={el(
										`language_dropdown_sub_menu_item${
											currentLanguage === lang.value ? '_active' : ''
										}`,
									)}
									aria-hidden
									onClick={() => setLanguage(lang.value)}
								>
									{lang.language}
								</span>
							))}
					</div>
				</div>
			)}
		</div>
	);

	const onChangeFilters = (newFilters = [], key = null) => {
		if (key === 'languages') {
			const questions = questionLibrary.filter(question =>
				question.translations.find(trans => newFilters.includes(trans.languageCode)),
			);
			setFilterQuestion(questions);
			setActiveFilters({ ...activeFilters, languages: newFilters });
		} else if (key === 'countries') {
			// TODO when BE is ready, get the countries
			fetchQuestion('');
		} else {
			fetchQuestion('');
		}
	};

	const renderQuestionLibrary = () => {
		if (!filterQuestion?.length) return <span className={el('empty')}>No results found</span>;
		return (
			<div className={el('questions_list')}>
				{filterQuestion?.map((question, i) => renderQuestion(question, i))}
			</div>
		);
	};

	return (
		<ResponsiveModal show={open} onClose={onClose} customContent>
			<div className={className}>
				<QuestionLibraryHeader title="Add Screening Questions">
					<AnimatePresence>
						<div key="search-bar" className={el('search-bar')}>
							<ScreeningQuestionFilterDropdown
								data={questionLibrary}
								setData={setFilterQuestion}
								filterTypes={filters}
								onChangeFilters={onChangeFilters}
								setActiveFilters={setActiveFilters}
								activeFilters={activeFilters}
							/>
							<Input
								className={el('search-input')}
								placeholder="Search"
								value={text}
								onChange={e => setText(e)}
							/>
							<div className={el('search-bar-icon-container')} onClick={onClearText} aria-hidden>
								<AnimatePresence initial={false}>
									{debouncedSearch && (
										<motion.div
											key="clear"
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
										>
											<Iconof icon="clear" />
										</motion.div>
									)}
									{!debouncedSearch && (
										<motion.div
											key="search"
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
										>
											<Iconof icon="search" />
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						</div>
					</AnimatePresence>
					<div key="view-in" className={el('view_language_container')}>
						<span className={el('view_in')}> View In: </span>
						{renderLanguageDropdown()}
					</div>

					<div key="close" className={el('header-close')} onClick={onClose} aria-hidden>
						<Iconof icon="clear" />
					</div>
				</QuestionLibraryHeader>
				<AnimatePresence>
					<div className={el('content')}>
						<div
							className={el('inner_content')}
							style={isLoading ? { justifyContent: 'center', display: 'flex' } : {}}
						>
							<div className={el('filter')}>
								<div className={el('category_container')}>
									{categories.slice(0, 7).map((category, i) => renderCategoryTag(category, i))}
								</div>
								<div className={el('divider')} />
								{activeFilters?.languages?.length ? (
									<div className={el('filter_container')}>
										<span className={el('filter_name')}>Filtered by</span>
										{activeFilters?.languages
											? activeFilters?.languages.map((lang, i) => renderLanguageTag(lang, i))
											: null}
										{activeFilters?.languages?.length > 0 && (
											<span className={el('clear_all')} aria-hidden onClick={clearLanguageTag}>
												Clear all
											</span>
										)}
									</div>
								) : null}
								{isLoading ? <Loader centered /> : renderQuestionLibrary()}
							</div>
						</div>
					</div>
				</AnimatePresence>
				<div className={el('bottom')}>
					{selectedQuestions?.length > 0 ? (
						<span className={el('selected')}>{`${selectedQuestions?.length} selected`}</span>
					) : null}
					{(!showButtonAddYourOwn && audienceCollection?.content?.screeningQuestions?.length > 0) ||
					selectedQuestions?.length ? null : (
						<AddQuestionButton
							audience={audience}
							addQuestionOptions={addQuestionOptions}
							isLastQuestionItem
							blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
							screeningQuestion
							customLabel={customLabel}
							isModal
						/>
					)}
					<Button
						className={
							!showButtonAddYourOwn && audienceCollection?.content?.screeningQuestions?.length > 0
								? el('add_selected')
								: el('add_selected_margin')
						}
						label="Add Selected"
						onClick={e => {
							setIsLoading(true);
							onClick(e, selectedQuestions);
						}}
						state={!selectedQuestions?.length || isLoading ? 'disabled' : undefined}
					/>
				</div>
			</div>
		</ResponsiveModal>
	);
};
export default QuestionLibrary;
