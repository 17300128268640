import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import 'react-tippy/dist/tippy.css';
import SectionHelp from 'src/components/elements/SectionHelp';
import InlineTextarea from 'src/components/inputs/InlineTextarea';
import cn from 'src/utilities/bem-cn';
import './SectionHeader.scss';

const className = 'section-header';
const el = name => cn(className, name);

class SectionHeader extends React.Component {
	saveSectionLabel = debounce(value => {
		const { studyId, sectionId, patchSectionName } = this.props;
		patchSectionName(studyId.toString(), sectionId.toString(), value);
	}, 500);

	constructor(props) {
		super(props);
		this.state = {
			sectionTitle: props.title,
		};
	}

	onSectionLabelChange = value => {
		const { studyId, sectionId } = this.props;
		const studyIdExists = studyId !== null;
		const sectionIdExists = sectionId !== null;
		if (studyIdExists && sectionIdExists) {
			this.setState({ sectionTitle: value });
			this.saveSectionLabel(value);
		}
	};

	render() {
		const {
			customClasses = '',
			subtitle,
			helpTitle,
			helpBody,
			hintTitle,
			hintBody,
			tippyWidth,
			icon,
			showHelp = true,
		} = this.props;
		const { sectionTitle } = this.state;
		return (
			<div className={`${className} ${customClasses}`}>
				{icon}
				<div className={el('heading')}>
					<div className={el('heading-content')}>
						<InlineTextarea
							autoFocus
							className={el('title')}
							value={sectionTitle}
							placeholder="Title your section"
							onChange={this.onSectionLabelChange}
						/>
						<div className={el('subtitle')}>{subtitle || ''}</div>
					</div>
				</div>
				{showHelp && (
					<div className={el('help')}>
						<SectionHelp
							title={helpTitle || ''}
							body={helpBody || ''}
							hintTitle={hintTitle || ''}
							hintBody={hintBody || ''}
							tippyWidth={tippyWidth || 400}
						/>
					</div>
				)}
			</div>
		);
	}
}

SectionHeader.propTypes = {
	customClasses: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	helpTitle: PropTypes.string,
	helpBody: PropTypes.object,
	hintTitle: PropTypes.string,
	hintBody: PropTypes.string,
	tippyWidth: PropTypes.number,
	studyId: PropTypes.number,
	sectionId: PropTypes.number,
	patchSectionName: PropTypes.func,
	showHelp: PropTypes.bool,
	icon: PropTypes.element,
};

export default SectionHeader;
