import React from 'react';

const LockOpen = () => (
	<svg className="lock-open" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
		<g fill="none" fillRule="evenodd">
			<path
				fill="#666"
				d="M8 11.333c.733 0 1.333-.6 1.333-1.333S8.733 8.667 8 8.667s-1.333.6-1.333 1.333.6 1.333 1.333 1.333zm4-6h-.667V4C11.333 2.16 9.84.667 8 .667S4.667 2.16 4.667 4h1.266c0-1.14.927-2.067 2.067-2.067 1.14 0 2.067.927 2.067 2.067v1.333H4c-.733 0-1.333.6-1.333 1.334v6.666c0 .734.6 1.334 1.333 1.334h8c.733 0 1.333-.6 1.333-1.334V6.667c0-.734-.6-1.334-1.333-1.334zm0 8H4V6.667h8v6.666z"
			/>
		</g>
	</svg>
);

export default LockOpen;
