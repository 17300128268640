import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

const checkoutService = {
	/**
	 *
	 * @param number studyId
	 */
	getAudiences: studyId => axios.get(`${API_PATH}/studies/${studyId}/audiences`),
	getBillingBalance: () => axios.get(`${API_PATH}/billing/balance`),
};

export default checkoutService;
