import React from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/elements/Button';

import './styles.scss';

const className = 'shared-modal-footer';

const Footer = ({
	// General
	children,
	// Close button
	onClose,
	closeLabel = 'Back',
	closeState = 'active',
	// Confirm button
	onConfirm,
	confirmLabel = 'Confirm',
	confirmState = 'active',
	confirmType,
	customClass = '',
}) => (
	<div className={`${className} ${customClass}`}>
		{children || (
			<React.Fragment>
				{onClose && <Button state={closeState} label={closeLabel} type="text" onClick={onClose} />}
				{onConfirm && (
					<Button state={confirmState} label={confirmLabel} onClick={onConfirm} type={confirmType} />
				)}
			</React.Fragment>
		)}
	</div>
);

Footer.propTypes = {
	// General
	children: PropTypes.object,
	// Close button
	onClose: PropTypes.func,
	closeState: PropTypes.string,
	closeLabel: PropTypes.string,
	// Confirm button
	onConfirm: PropTypes.func,
	confirmState: PropTypes.string,
	confirmLabel: PropTypes.string,
	confirmType: PropTypes.string,
	customClass: PropTypes.string,
};

export default Footer;
