import React, { useCallback } from 'react';
import { getClientId } from 'src/domains/auth/selectors';
import cn from 'src/utilities/bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import { setClientId } from 'src/domains/auth/actions';
import { useSearchParams } from 'react-router-dom';
import Select from 'src/components/inputs/Select';
import { urlParams } from '../../../../components/shared/SearchResults';
import { resetStudyTags, resetProductTags, resetCollections } from '../../actions';
import './styles.scss';

const className = 'select-space-dropdown';
const el = (name, mod) => cn(className, name, mod);

const SelectSpace = () => {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const clients = useSelector(store => store.allStudies.clientsFromAccount);

	const activeTab = searchParams.get(urlParams.status) || 'all';

	const currentClientId = useSelector(getClientId) || null;

	const handleSetClient = useCallback(
		val => {
			setSearchParams(params => {
				params.set(urlParams.clientId, val);
				if (params.has('highlightStatus')) params.delete('highlightStatus');

				let pageParam = urlParams.studyPage;
				if (activeTab === 'ideas') {
					pageParam = urlParams.ideaPage;
				} else if (activeTab === 'questions') {
					pageParam = urlParams.questionPage;
				} else if (activeTab === 'templates') {
					pageParam = urlParams.templatePage;
				}
				params.set(pageParam, 1);

				return params;
			});
			dispatch(setClientId(val));
			dispatch(resetStudyTags());
			dispatch(resetProductTags());
			dispatch(resetCollections());
		},
		[activeTab, dispatch, setSearchParams],
	);

	if (clients?.length <= 1) {
		return null;
	}

	return (
		<div className={className}>
			<span className={el('select-space-text')}>Select your space</span>
			<Select
				value={currentClientId}
				onChange={handleSetClient}
				options={clients.map(client => ({
					label: client?.name,
					value: client?.uuid,
				}))}
				title="Select Your Space"
				mobileStyle="list"
			/>
		</div>
	);
};

export default SelectSpace;
