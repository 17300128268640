/* eslint-disable class-methods-use-this */
export class HeapTracking {
	constructor() {
		this.isLoaded = false;
		this.userData = {};
		this.eventProperties = {};
	}

	load(env) {
		if (this.isLoaded) return;

		const script = document.createElement('script');
		script.innerHTML = `(window.heap = window.heap || []),
		(heap.load = function (e, t) {
			(window.heap.appid = e), (window.heap.config = t = t || {});
			const r = document.createElement('script');
			(r.type = 'text/javascript'),
				(r.async = !0),
				(r.src = 'https://cdn.heapanalytics.com/js/heap-'+e+'.js');
			const a = document.getElementsByTagName('script')[0];
			a.parentNode.insertBefore(r, a);
			for (
				let n = function (e) {
						return function () {
							heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
						};
					},
					p = [
						'addEventProperties',
						'addUserProperties',
						'clearEventProperties',
						'identify',
						'resetIdentity',
						'removeEventProperty',
						'setEventProperties',
						'track',
						'unsetEventProperty',
					],
					o = 0;
				o < p.length;
				o++
			)
				heap[p[o]] = n(p[o]);
		});
		
		heap.load(${env});`;

		script.id = 'head-script';
		script.async = true;

		document.body.appendChild(script);

		this.isLoaded = true;
	}

	identify(email) {
		if (!window.heap) return;
		window.heap.identify(email);
	}

	resetIdentity() {
		if (!window.heap) return;
		window.heap.resetIdentity();
	}

	track(eventName, data) {
		if (!window.heap) return;
		window.heap.track(eventName, data);
	}

	addUserProperties() {
		if (!window.heap || !this.userData) return;
		window.heap.addUserProperties(this.userData);
	}

	clearEventProperties() {
		if (!window.heap) return;
		window.heap.clearEventProperties();
	}

	removeEventProperty(key) {
		if (!window.heap) return;
		window.heap.removeEventProperty(key);
	}

	addEventProperties() {
		if (!window.heap) return;
		window.heap.addEventProperties(this.eventProperties);
	}
}
