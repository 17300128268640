import Auth from 'src/utilities/auth';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import cloneDeep from 'lodash/cloneDeep';
import intersection from 'lodash/intersection';
import xor from 'lodash/xor';
import toastr from 'toastr';
import * as services from 'src/services';
import * as authActions from 'src/domains/auth/actions';
import CONSTANTS from 'src/config/constants';
import { getSocLocalStorageKey, getStorageWithExpiry, setStorageWithExpiry } from 'src/utilities/local-storage';

// Split out effects
import sectionEffects from './reporting-sections';

import * as mainActions from '../actions';
import * as mainSelectors from '../selectors';
import * as actions from '../actions/reporting';
import * as selectors from '../selectors/reporting';
import * as helpers from '../../../components/helpers';
import * as questionActions from '../actions/questions';
import * as rootSelectors from '../../selectors';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 5000,
};

const performInitialFetch = async (store, action) => {
	if (action.type === actions.PERFORM_INITIAL_FETCH) {
		const { id, sectionId, preloadFilters } = action.payload;
		try {
			store.dispatch(actions.setError(null));
			store.dispatch(actions.setFilters(null));
			const [languageResponse, studyResponse] = await Promise.all([
				services.languageService.getLanguages(),
				services.studyService.getStudy(id),
			]);
			store.dispatch(actions.setLanguages(languageResponse.data));
			const studyDataBeforeFetching = selectors.getStudy(store.getState());
			const language =
				studyDataBeforeFetching?.language ??
				studyResponse?.data?.currentLanguage ??
				studyResponse?.data?.defaultLanguage ??
				'en';
			const study = { ...studyResponse.data, language };
			store.dispatch(actions.setStudy(study));
			store.dispatch(actions.setFilters(study.questionFilters));
			// store.dispatch(actions.setLanguage(study.currentLanguage));
			store.dispatch(authActions.setClientId(study.clientId));

			store.dispatch(actions.fetchProductsAndResponseData(id, preloadFilters));
			store.dispatch(actions.fetchSections(id, false, true, sectionId, JSON.parse(preloadFilters)));
			store.dispatch(mainActions.fetchAudiencesAndUpdatePrices(id));

			// store.dispatch(mainActions.fetchAllAudienceCollection(id));

			const userCanStudyUpdate = Auth.userCan('study:update');
			// Need update permission for these API calls
			if (userCanStudyUpdate) {
				store.dispatch(mainActions.fetchCategories(id));
				store.dispatch(mainActions.fetchQuestions(id));
				store.dispatch(mainActions.clearAudienceNotice());
			}
			store.dispatch(actions.fetchStudyLoi(id));

			// TODO - maybe add this back in
			// store.dispatch(actions.fetchIdeaMapData(id));
		} catch (error) {
			console.error(error);
			if (error.response && error.response.status === 404) {
				navigate('/not-found', { replace: true });
			} else {
				console.error('There was an error fetching client ID: ', error); // or study?
				store.dispatch(actions.setError(error));
			}
		}
	}
};

const fetchData = (store, { type, payload }) => {
	if (type === actions.FETCH_DATA) {
		const { id } = payload;

		const languageCode = selectors.getLanguage(store.getState());

		store.dispatch(actions.setError(null));
		store.dispatch(actions.setStudy(null));
		store.dispatch(actions.setFilters(null));
		services.studyService
			.getStudy(id, { languageCode })
			.then(({ data }) => {
				store.dispatch(actions.setStudy(data));
				store.dispatch(actions.setFilters(data.questionFilters));
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
			});
	}
};

const fetchAudienceSection = async (store, action) => {
	if (action.type === actions.FETCH_AUDIENCE_SECTION) {
		const { audienceUuid, filters = [] } = action.payload;
		const study = selectors.getStudy(store.getState());

		const preparedFilters = [];
		let audienceFilter = [];

		Object.keys(filters).forEach(filterQuestionId => {
			const values = filters[filterQuestionId];
			if (filterQuestionId === 'audience') {
				// Audience Filter
				audienceFilter = values;
			} else if (values && values.length > 0) {
				// Normal Filters
				preparedFilters.push({
					questionId: filterQuestionId,
					values,
				});
			} else if (values && values.attributeIds && values.attributeIds.length > 0) {
				// Attribute Filters
				const qId = filterQuestionId.split('-')[0];
				const attributeId = filterQuestionId.split('-')[1];

				preparedFilters.push({
					questionId: qId,
					values: values.value,
					attributeIds: [parseInt(attributeId)],
				});
			} else if (values && values.rank && values.value?.length) {
				preparedFilters.push({
					questionId: filterQuestionId,
					values: values.value,
					rank: parseInt(values.rank),
				});
			} else if (values && values.min && values.max) {
				// Age Filter
				preparedFilters.push({ questionId: filterQuestionId, ...values });
			}
		});
		// lookup audience reporting
		try {
			const audienceReports = await services.responseDataService.getAudienceReportData(study.id, audienceUuid, {
				audienceUuids: audienceFilter,
				filters: preparedFilters,
				languages: [],
			});
			store.dispatch(actions.setAudienceReports(audienceUuid, audienceReports.data));
		} catch (error) {
			console.error(error);
		}
	}
};

const fetchProducts = async (store, { type, payload }) => {
	if (type === actions.FETCH_PRODUCTS) {
		const { id } = payload;

		try {
			const languageCode = selectors.getLanguage(store.getState());
			const productSortBy = selectors.getProductSort(store.getState());
			const responseData = selectors.getResponseData(store.getState());

			store.dispatch(actions.setProducts(null));
			const productsResponse = await services.productService.getStudyProducts(id, languageCode);

			const productSortDescending = selectors.getProductSortDescending(store.getState());

			const productsToStore = helpers.sortProducts(
				helpers.attachResponseDataToProducts(responseData, productsResponse.data.products, true),
				productSortBy,
				productSortDescending,
			);
			store.dispatch(actions.setProducts(productsToStore));

			const terms = {
				productTags: productsResponse.data.productTags || [],
			};
			store.dispatch(actions.setProductTerms(terms));
		} catch (error) {
			store.dispatch(actions.setError(error));
			store.dispatch(actions.setProducts([]));

			store.dispatch(
				actions.setProductTerms({
					productTags: [],
				}),
			);
		}
	}
};
const fetchProductsAndResponseData = async (store, { type, payload }) => {
	if (type === actions.FETCH_PRODUCTS_RESPONSE_DATA) {
		const { id, preloadFilters } = payload;

		// TODO - We should be able to remove this action once we have a new  general study-level response data call.
		// TODO - It should return ONLY base counts, all vs. filtered, and audience level breakdowns.
		try {
			store.dispatch(actions.setProducts(null));
			if (!preloadFilters || preloadFilters === '{}') {
				store.dispatch(actions.setResponseData(null));
				const responseDataResponse = await services.responseDataService.getResponseData(id);
				store.dispatch(actions.setResponseData(responseDataResponse.data));
			}

			const languageCode = selectors.getLanguage(store.getState());

			// store.dispatch(actions.setIdeaMapLoading(true));
			const productsResponse = await services.productService.getStudyProducts(id, languageCode);

			const terms = {
				productTags: productsResponse.data.productTags || [],
			};
			store.dispatch(actions.setProductTerms(terms));
		} catch (error) {
			store.dispatch(actions.setError(error));
			store.dispatch(actions.setResponseData([]));
			store.dispatch(
				actions.setProductTerms({
					productTags: [],
				}),
			);
		}
	}
};

const sortProducts = (store, { type, payload }) => {
	if (type === actions.SORT_PRODUCTS) {
		// Get products from the state
		const { productSortBy, productSortDescending } = payload;
		const state = store.getState();
		const products = selectors.getProducts(state);

		store.dispatch(actions.setProductSort(productSortBy, productSortDescending));
		store.dispatch(actions.setProducts(helpers.sortProducts(products, productSortBy, productSortDescending)));
	}
};

function getChangeStatusErrorMessage(serverMessage, action = 'complete') {
	let errorMessage = '';

	switch (serverMessage) {
		case 'AUDIENCES_ARE_NOT_COMPLETED':
			errorMessage +=
				'You cannot complete a study with active audiences. Please stop all audiences and try again. ';
			break;
		default:
			errorMessage = `There was a problem setting the study to ${action}. Please refresh and try again.`;
	}

	return errorMessage;
}

const draftStudy = (store, action) => {
	if (action.type === actions.DRAFT_STUDY) {
		const { id } = action.payload;

		services.studyService
			.changeStatus(id, 'draft')
			.then(() => {
				store.dispatch(actions.fetchData(id));
			})
			.catch(error => {
				const serverMessage = error.response.data.message;
				toastr.error(getChangeStatusErrorMessage(serverMessage, 'draft'));
			});
	}
};

const publishStudy = (store, action) => {
	if (action.type === actions.PUBLISH_STUDY) {
		const { id } = action.payload;

		services.studyService
			.changeStatus(id, 'active')
			.then(() => {
				store.dispatch(actions.fetchData(id));
			})
			.catch(error => {
				const serverMessage = error.response.data.message;
				toastr.error(getChangeStatusErrorMessage(serverMessage, 'live'));
			});
	}
};

const closeStudy = (store, action) => {
	if (action.type === actions.CLOSE_STUDY) {
		const { id } = action.payload;
		services.studyService
			.changeStatus(id, 'closed')
			.then(() => {
				store.dispatch(actions.fetchData(id));
			})
			.catch(error => {
				const serverMessage = error.response.data.message;
				toastr.error(getChangeStatusErrorMessage(serverMessage, 'complete'));
			});
	}
};

const patchStudy = async (store, action) => {
	if (action.type === actions.PATCH_STUDY) {
		try {
			const { id, data } = action.payload;
			await services.studyService.patchStudy(id, data);
			const study = await services.studyService.getStudy(id);
			store.dispatch(actions.setStudy(study.data));
			store.dispatch(mainActions.setStudy(study.data));
		} catch (error) {
			// store.dispatch(actions.setError(error));
			console.error('error');
		}
	}
};

const patchStudySettings = async (store, action) => {
	if (action.type === actions.PATCH_STUDY_SETTINGS) {
		try {
			const { id, data } = action.payload;
			await services.studyService.patchStudySettings(id, data);
			const study = await services.studyService.getStudy(id);
			store.dispatch(actions.setStudy(study.data));
		} catch (error) {
			store.dispatch(actions.setError(error));
		}
	}
};

const generateReport = async (store, action) => {
	if (action.type === actions.GENERATE_REPORT) {
		const { studyId, name, data } = action.payload;
		try {
			await services.studyService.generateReport(studyId, name, data);
		} catch (e) {
			toastr.error('There was an error generating this PowerPoint. Please try again or contact support.');
		}
	}
};

const generateInterestCsv = async (store, action) => {
	if (action.type === actions.GENERATE_INTEREST_CSV) {
		const { studyId, studyName, sectionId, data } = action.payload;
		try {
			await services.studyService.generateInterestCsv(studyId, studyName, sectionId, data);
		} catch (e) {
			toastr.error('There was an error generating this CSV. Please try again or contact support.');
		}
	}
};

const generateSocCsv = async (store, action) => {
	if (action.type === actions.GENERATE_SOC_CSV) {
		let { data } = action.payload;
		const { studyId, studyName, sectionId } = action.payload;
		try {
			const selectedLanguage = selectors.getLanguage(store.getState());
			const groupData = selectors.getShareOfChoiceGroups(store.getState());
			const innovationIds = selectors.getShareOfChoiceInnovationProductIds(store.getState());
			const existingProductIds = selectors.getShareOfChoiceExistingProductIds(store.getState());
			const sortBy = selectors.getShareOfChoiceSortOrder(store.getState());
			const sortDirection = selectors.getShareOfChoiceSortDirection(store.getState());
			const excludedProductIds = selectors.getShareOfChoiceExcludedProducts(store.getState());
			const sectionProducts = selectors.getCurrentSectionProducts(store.getState());

			const groups = groupData
				.filter(group => !group.isBaseCase)
				.map(({ name, productIds }) => ({ name, productIds }));

			const innovations = innovationIds.map(innovationId => ({
				productId: innovationId,
				active: !excludedProductIds.includes(innovationId),
			}));
			const existingIdeas = existingProductIds.map(existingProductId => ({
				productId: existingProductId,
				active: !excludedProductIds.includes(existingProductId),
			}));
			const currentScenario = sectionProducts.map(product => ({
				productId: product.localProductId,
				active: !excludedProductIds.includes(product.localProductId),
			}));

			data = {
				...data,
				...(existingIdeas.length > 0 && { existingIdeas, innovations }),
				...(existingIdeas.length === 0 && { currentScenario }),
				groups,
				selectedLanguage,
				sortBy: {
					type: sortBy || 'name',
					direction: sortDirection || 'ascending',
				},
			};
			await services.studyService.generateSocCsv(studyId, studyName, sectionId, data);
		} catch (e) {
			toastr.error('There was an error generating this CSV. Please try again or contact support.');
		}
	}
};

const setShowDevicePreviewMode = async (store, action) => {
	if (action.type === actions.SHOW_DEVICE_PREVIEW_MODE) {
		const { showDevicePreviewMode } = action.payload;
		const htmlElement = document.getElementsByTagName('html')[0];
		const bodyElement = document.getElementsByTagName('body')[0];
		if (showDevicePreviewMode) {
			htmlElement.classList.add('device-preview-visible');
			bodyElement.classList.add('device-preview-visible');
		} else {
			htmlElement.classList.remove('device-preview-visible');
			bodyElement.classList.remove('device-preview-visible');
		}
	}
};

const patchCustomizeReportItem = (store, action) => {
	if (action.type === actions.PATCH_CUSTOMIZE_REPORT_ITEM) {
		const { studyId, sectionId, itemId, itemData, activeBaseFilters } = action.payload;
		services.sections
			.patchItem(studyId, sectionId, itemId, itemData)
			.then(response => {
				store.dispatch(actions.fetchSection(sectionId, activeBaseFilters, true));
			})
			.catch(error => {});
	}
};

const setCustomizeReport = async (store, action) => {
	if (action.type === actions.SET_CUSTOMIZE_REPORT) {
		const { customizeReport, customizeReportId, activeBaseFilters, toggleSetting } = action.payload;
		if (!customizeReport || toggleSetting) {
			const study = selectors.getStudy(store.getState());
			const currentSection = selectors.getCurrentSection(store.getState());
			const { content } = currentSection;

			if (content) {
				store.dispatch(mainActions.fetchAllAudienceCollection(study.id));
				const sectionId = content && content.id;
				if (content.id === 'audience') {
					const showAudienceReports = selectors.getShowAudienceReports(store.getState());
					if (showAudienceReports && showAudienceReports.isDisplayedInReporting === false) {
						// move to next section
						store.dispatch(actions.fetchSections(customizeReportId, true, true));
						const sections = selectors.getSections(store.getState());

						if (sections.content) {
							const newSectionId = sections.content.reduce((acc, section) => {
								if (acc === null || (section.order < acc.order && section.isDisplayedInReporting)) {
									return section;
								}
								return acc;
							}, null);
							if (newSectionId) {
								return navigate(
									`/studies/${study?.uuid || study?.id}/report/sections/${newSectionId?.id}`,
								);
							}
						}
						navigate(`/studies/${study?.uuid || study?.id}`);
					}

					if (content.audienceUuid) {
						store.dispatch(actions.fetchAudienceSection(content.audienceUuid));
					}

					// Also refetch the study and filters for reporting screen
					const studyResponse = await services.studyService.getStudy(study.id);
					store.dispatch(mainActions.setStudy(studyResponse.data));
					store.dispatch(actions.setStudy(studyResponse.data));
					store.dispatch(actions.setFilters(studyResponse.data.questionFilters));
				} else {
					if (!content.isDisplayedInReporting) {
						store.dispatch(actions.fetchSections(customizeReportId, true, true));
						// 	store.dispatch(actions.performInitialFetch(customizeReportId));
					} else {
						store.dispatch(actions.fetchSections(customizeReportId, true));
						if (content.type === 'monadic_split') {
							store.dispatch(actions.fetchSection(sectionId, activeBaseFilters));
						} else {
							store.dispatch(actions.fetchSection(sectionId, activeBaseFilters, true));
						}
					}
					// Also refetch the study and filters for reporting screen
					const studyResponse = await services.studyService.getStudy(study.id);
					store.dispatch(mainActions.setStudy(studyResponse.data));
					store.dispatch(actions.setStudy(studyResponse.data));
					store.dispatch(actions.setFilters(studyResponse.data.questionFilters));
				}
			}
		}
	}
};

/**
 *
 * @param {*} store
 * @param {*} action
 */
const fetchQuestionReport = async (store, action) => {
	if (action.type === actions.FETCH_QUESTION_REPORT) {
		const { questionId, selectedBaseFilters } = action.payload;
		const study = selectors.getStudy(store.getState());
		const { formattedFilters, audienceFilter } = helpers.formatFilters(selectedBaseFilters);
		const { status, content } = selectors.getCurrentSection(store.getState());
		const localFilters = selectors.getLocalFilters(store.getState()); // current local filters
		const foundQuestion = localFilters.find(question => question.pipedQuestionId === questionId.toString());
		const currentQuestions = content.questions; // using pass by reference ie questionActions.setSectionQuestions below
		const currentQuestion = currentQuestions.find(q => q.id === questionId);

		services.responseDataService
			.getQuestionReportData(study.id, questionId, {
				sectionId: content.id,
				filters: formattedFilters,
				audienceUuids: audienceFilter,
				languages: [],
				questionFilters: foundQuestion ? foundQuestion.questionFilters : [],
			})
			.then(response => {
				// find the report in existing store
				if (content.reportData && content.reportData.data) {
					const foundReportIdx = content.reportData.data.findIndex(({ id }) => id === questionId);
					if (foundReportIdx > -1) {
						// replace the report
						content.reportData.data.splice(foundReportIdx, 1, response.data.data);
						store.dispatch(actions.setSection({ status, content }));
					} else {
						// add new report
						const emptyReportIdx = content.reportData.data.findIndex(({ id }) => !id);
						content.reportData.data.splice(emptyReportIdx, 1, response.data.data);
						store.dispatch(actions.setSection({ status, content }));
					}
				}
			})
			.catch(error => {
				console.error('There was an error fetching filtered question report: ', error);
			});

		// check if an open-ended question, if so need to make extra calls
		if (currentQuestion.style === CONSTANTS.questions.options.style.openEnded) {
			Promise.all([
				services.questionService.getQuestionReport(study.id, currentQuestion.id, {
					sortType: 'desc',
					sortMethod: 'date',
					questionFilters: foundQuestion ? foundQuestion.questionFilters : [],
					filters: formattedFilters,
					audienceUuids: audienceFilter,
					limit: 10,
				}),
				services.questionService.getQuestionKeywords(study.id, currentQuestion.id, {
					...selectors.getSortBy(store.getState()),
					filters: formattedFilters,
					questionFilters: foundQuestion ? foundQuestion.questionFilters : [],
					audienceUuids: audienceFilter,
				}),
			])
				.then(([report, keywords]) => {
					currentQuestion.reportData = report.data;
					currentQuestion.keywords = keywords.data;
					store.dispatch(questionActions.setSectionQuestions(currentQuestions));
				})
				.catch(error => {
					console.error('There was an error fetching filtered open-ended question report: ', error);
				});
		}
	}
};

/**
 *
 * @param {*} store
 * @param {*} action
 */
const fetchIdeaSplitQuestionReport = async (store, action) => {
	if (action.type === actions.FETCH_IDEA_SPLIT_QUESTION_REPORT) {
		const { questionId, selectedBaseFilters } = action.payload;
		const study = selectors.getStudy(store.getState());
		const { formattedFilters, audienceFilter } = helpers.formatFilters(selectedBaseFilters);
		const { content } = selectors.getCurrentSection(store.getState());
		const localFilters = selectors.getLocalFilters(store.getState()); // current local filters
		const foundQuestion = localFilters.find(question => question.pipedQuestionId === questionId.toString());

		// standard report
		services.responseDataService
			.getIdeaSplitQuestionReportData(study.id, content.id, questionId, {
				sectionId: content.id,
				filters: formattedFilters,
				audienceUuids: audienceFilter,
				languages: [],
				questionFilters: foundQuestion ? foundQuestion.questionFilters : [],
			})
			.then(response => {
				// find the report in existing store
				const currentIdeaSplitReports = cloneDeep(selectors.getMonadicSplitReportingData(store.getState()));
				const foundReportIdx = currentIdeaSplitReports.findIndex(
					question => question.questionId === questionId,
				);
				if (foundReportIdx > -1) {
					const reportData = response.data.data.reduce((acc, report) => {
						if (report.attributes) {
							return {
								questionId: report.questionId,
								options: acc.options,
								gridAttributes: report.attributes,
							};
						}
						return {
							...acc,
							...report,
						};
					}, {});

					// replace the report
					currentIdeaSplitReports.splice(foundReportIdx, 1, reportData);
					store.dispatch(actions.setMonadicSplitReportingData(currentIdeaSplitReports));
				}
			})
			.catch(error => {
				console.error('There was an error fetching filtered idea split question report: ', error);
			});
	}
};

const setLanguage = async (store, action) => {
	if (action.type === actions.SET_LANGUAGE) {
		const { language } = action.payload;
		const currentCreateLanguage = mainSelectors.getLanguage(store.getState());
		if (language !== currentCreateLanguage) {
			store.dispatch(mainActions.setLanguage(language));
			store.dispatch(actions.setLanguage(language));
		}
	}
};

const setMonadicSplitReportingData = async (store, action) => {
	if (action.type === actions.SET_MONADIC_SPLIT_REPORTING_DATA) {
	}
};

const exportMonadicSplit = async (store, action) => {
	if (action.type === actions.EXPORT_MONADIC_SPLIT) {
		const { studyId, fileType } = action.payload;
		services.studyService.exportMonadicSplit(studyId, fileType);
	}
};

const exportMonadicSplitComponent = async (store, action) => {
	if (action.type === actions.EXPORT_MONADIC_SPLIT_COMPONENT) {
		const { studyId, studyName, sectionId, data, fileType } = action.payload;
		// lookup question specific filters
		data.localFilters = selectors.getLocalFilters(store.getState()) || [];
		services.studyService.exportMonadicSplitComponent(studyId, studyName, sectionId, data, fileType);
	}
};

const patchShowAudienceReports = (store, action) => {
	if (action.type === actions.PATCH_SHOW_AUDIENCE_REPORTS) {
		const { itemData } = action.payload;
		const showAudienceReports = selectors.getShowAudienceReports(store.getState());

		const updatedShowAudienceReports = {
			...showAudienceReports,
			...itemData,
		};

		store.dispatch(actions.setShowAudienceReports(updatedShowAudienceReports));
	}
};

const patchShowAudienceReportItem = (store, action) => {
	if (action.type === actions.PATCH_SHOW_AUDIENCE_REPORT_ITEM) {
		const { audienceUuid, questionId, itemData } = action.payload;
		const foundShowAudienceReports = selectors.getShowAudienceReports(store.getState());

		const updatedArray = [];
		if (foundShowAudienceReports && foundShowAudienceReports.audiences) {
			let existingAudience = false;
			foundShowAudienceReports.audiences.forEach(audience => {
				if (audience.audienceUuid === audienceUuid) {
					existingAudience = true;
					let existingQuestion = false;
					const updatedAudience = audience.data.map(question => {
						if (question.questionId === questionId) {
							existingQuestion = true;
							return {
								questionId,
								...itemData,
							};
						}
						return question;
					});
					if (!existingQuestion) {
						updatedAudience.push({ questionId, ...itemData });
					}
					updatedArray.push({ audienceUuid, data: updatedAudience });
				} else {
					updatedArray.push(audience);
				}
			});
			if (!existingAudience) {
				updatedArray.push({ audienceUuid, data: [{ questionId, ...itemData }] });
			}
			store.dispatch(
				actions.patchShowAudienceReports({
					audiences: updatedArray,
				}),
			);
		} else {
			store.dispatch(
				actions.patchShowAudienceReports({
					audiences: [{ audienceUuid, data: [{ questionId, ...itemData }] }],
				}),
			);
		}
	}
};

const fetchFilteredBase = async (store, { type, payload }) => {
	if (type === actions.FETCH_FILTERED_BASE) {
		const { id, filters = {}, firstLoad } = payload;
		try {
			const formattedFilters = [];
			let audienceFilter = [];
			Object.keys(filters).forEach(questionId => {
				const values = filters[questionId];

				if (questionId === 'audience') {
					// Audience Filter
					audienceFilter = values;
				} else if (values && values.length) {
					// Normal Filters
					formattedFilters.push({ questionId, values });
				} else if (values && values.attributeIds && values.attributeIds.length > 0) {
					// Attribute Filters
					const qId = questionId.split('-')[0];
					const attributeId = questionId.split('-')[1];

					formattedFilters.push({
						questionId: qId,
						values: values.value,
						attributeIds: [parseInt(attributeId)],
					});
				} else if (values && values.rank && values.value?.length) {
					formattedFilters.push({
						questionId,
						values: values.value,
						rank: parseInt(values.rank),
					});
				} else if (values && values.min && values.max) {
					// Age Filter
					formattedFilters.push({ questionId, ...values });
				}
			});

			// Response Data
			const { content } = selectors.getCurrentSection(store.getState());
			if (firstLoad && (!content || content.type === 'swipe')) {
				const responseDataResponse = await services.responseDataService.getResponseData(
					id,
					formattedFilters,
					null,
					audienceFilter,
				);
				store.dispatch(actions.setResponseData(responseDataResponse.data));
			}

		} catch (error) {
			store.dispatch(actions.setError(error));
			store.dispatch(actions.setResponseData([]));
		}
	}
};

const fetchShareOfChoice = async (store, { type, payload }) => {
	if (type === actions.FETCH_SHARE_OF_CHOICE) {
		const { sectionId, studyId, selectedBaseFilters } = payload;
		const { formattedFilters, audienceFilter } = helpers.formatFilters(selectedBaseFilters);

		const groups = selectors.getShareOfChoiceFilteredGroups(store.getState());
		const innovationIds = selectors.getShareOfChoiceInnovationProductIds(store.getState());
		const existingProductIds = selectors.getShareOfChoiceExistingProductIds(store.getState());
		const excludedProductIds = selectors.getShareOfChoiceExcludedProducts(store.getState());
		const sectionProducts = selectors.getCurrentSectionProducts(store.getState());

		const innovations = innovationIds.map(innovationId => ({
			productId: innovationId,
			active: !excludedProductIds.includes(innovationId),
		}));
		const existingIdeas = existingProductIds.map(existingProductId => ({
			productId: existingProductId,
			active: !excludedProductIds.includes(existingProductId),
		}));
		const currentScenario = sectionProducts.map(product => ({
			productId: product.localProductId,
			active: !excludedProductIds.includes(product.localProductId),
		}));

		const filteredGroups = (groups || []).map(group => ({
			id: group.id,
			name: group.name,
			productIds: group.productIds,
		}));

		const data = {
			filters: formattedFilters,
			audienceUuids: audienceFilter,
			...(existingIdeas.length > 0 && { existingIdeas, innovations }),
			...(existingIdeas.length === 0 && { currentScenario }),
			groups: filteredGroups,
		};

		const responseData = await services.shareOfChoiceService.fetchShareOfChoiceV2(studyId, sectionId, data);

		if (responseData?.data) {
			const { responseCount } = responseData.data;
			delete responseData.data.responseCount;
			store.dispatch(actions.setFullShareOfChoice(responseData.data));
			store.dispatch(actions.setShareOfChoiceResponseCount(responseCount));
		}

		store.dispatch(actions.setShareOfChoiceLoading(false));
	}
};

const fetchShareOfChoiceProgress = (store, { type, payload }) => {
	if (type === actions.FETCH_SHARE_OF_CHOICE_PROGRESS) {
		const { sectionId, studyId } = payload;
		services.shareOfChoiceService
			.fetchShareOfChoiceProgress(sectionId, studyId)
			.then(response => {
				const timestamp = new Date().getTime();
				store.dispatch(
					actions.setShareOfChoiceProgress({
						progress: parseInt(response.data[CONSTANTS.shareOfChoice.progress]),
						timeRemaining: parseInt(response.data[CONSTANTS.shareOfChoice.estimatedTimeLeft]),
						hasPreviousReportAvailable: response.data[CONSTANTS.shareOfChoice.hasPreviousReportAvailable],
						progressWarning: response.data[CONSTANTS.shareOfChoice.progressWarning],
						freshDataAvailable: response.data[CONSTANTS.shareOfChoice.freshDataAvailable],
						timestamp,
					}),
				);
			})
			.catch(error => {
				console.error('Fetch Share of Choice Progress Error:', error);
				const timestamp = new Date().getTime();
				store.dispatch(
					actions.setShareOfChoiceProgress({
						progress: null,
						timeRemaining: undefined,
						timestamp,
					}),
				);
			});
	}
};

const processShareOfChoice = async (store, action) => {
	if (action.type === actions.PROCESS_SHARE_OF_CHOICE) {
		const { studyId, sectionId } = action.payload;
		services.shareOfChoiceService
			.processShareOfChoice(sectionId, studyId)
			.then(() => {
				store.dispatch(actions.fetchShareOfChoiceProgress(sectionId, studyId));
			})
			.catch(error => {
				console.error('Process Share of Choice Error:', error);
			});
	}
};

const fetchShareOfChoiceGroups = (store, { type, payload }) => {
	if (type === actions.FETCH_SHARE_OF_CHOICE_GROUPS) {
		const { studyId, sectionId } = payload;
		services.shareOfChoiceService
			.fetchShareOfChoiceGroups(sectionId, studyId)
			.then(response => {
				store.dispatch(actions.setShareOfChoiceGroups(response.data));

				const baseCaseGroup = response.data.find(group => group.isBaseCase);

				if (baseCaseGroup) {
					store.dispatch(actions.setShareOfChoiceExistingProductIds(baseCaseGroup.productIds));
				}
			})
			.catch(error => {
				console.error('Error fetching share of choice groups:', error);
			});
	}
};

const createShareOfChoiceGroup = (store, { type, payload }) => {
	if (type === actions.SHARE_OF_CHOICE_CREATE_GROUP) {
		const { studyId, sectionId, name, productIds, isBaseCase } = payload;

		const colors = [...CONSTANTS.shareOfChoice.categoryColors];

		const currentGroups = selectors.getShareOfChoiceGroups(store.getState());

		// Remove used colors
		currentGroups.forEach(group => group.hex && colors.splice(colors.indexOf(group.hex), 1));

		const groupHex = colors.length
			? colors[0]
			: `00000${Math.floor(Math.random() * 16777215).toString(16)}`.slice(-6);

		services.shareOfChoiceService
			.createGroup(sectionId, studyId, name, productIds, isBaseCase)
			.then(response => {
				store.dispatch(actions.setShareOfChoiceGroups([...currentGroups, { ...response.data, hex: groupHex }]));

				if (isBaseCase) {
					navigate(-1);
					// navigate(`/studies/${studyId}/report/sections/${sectionId}/${CONSTANTS.ideaScreenSections.type.marketSimulator}`);
				}
			})
			.catch(error => {
				console.error('Error creating group:', error);
				const serverMessage = error.response.data.message;
				toastr.error(serverMessage);
			});
	}
};

const updateShareOfChoiceGroup = (store, { type, payload }) => {
	if (type === actions.UPDATE_SHARE_OF_CHOICE_CREATE_GROUP) {
		const { studyId, sectionId, groupId, name, productIds, isBaseCase } = payload;
		services.shareOfChoiceService
			.updateGroup(sectionId, studyId, groupId, name, productIds)
			.then(response => {
				const currentGroups = selectors.getShareOfChoiceGroups(store.getState());
				const filteredGroups = currentGroups.filter(group => group.id !== groupId);
				const currentGroup = currentGroups.find(group => group.id === groupId);

				store.dispatch(
					actions.setShareOfChoiceGroups([...filteredGroups, { ...currentGroup, ...response.data }]),
				);

				if (isBaseCase) {
					navigate(-1);
					// navigate(`/studies/${studyId}/report/sections/${sectionId}/${CONSTANTS.ideaScreenSections.type.marketSimulator}`);
				}
			})
			.catch(error => {
				console.error('Error creating group:', error);
			});
	}
};

const deleteShareOfChoiceGroup = (store, { type, payload }) => {
	if (type === actions.DELETE_SHARE_OF_CHOICE_GROUP) {
		const { sectionId, studyId, groupId, isBaseCase } = payload;

		services.shareOfChoiceService
			.deleteShareOfChoiceGroup(sectionId, studyId, groupId)
			.then(response => {
				const currentGroups = selectors.getShareOfChoiceGroups(store.getState());
				const filteredGroups = currentGroups.filter(group => group.id !== groupId);

				if (isBaseCase) {
					window.localStorage.removeItem(getSocLocalStorageKey(studyId, sectionId));
					store.dispatch(actions.resetShareOfChoice({ shareOfChoiceGroups: filteredGroups }));
				} else {
					store.dispatch(actions.setShareOfChoiceGroups(filteredGroups));
				}
			})
			.catch(error => {
				console.log('error deleting group: ', error);
			});
	}
};

const setShareOfChoiceInnovationProductIds = (store, { type, payload }) => {
	if (type === actions.SET_SHARE_OF_CHOICE_INNOVATION_PRODUCT_IDS) {
		const { shareOfChoiceInnovationProductIds } = payload;

		const { id: studyId } = selectors.getStudy(store.getState());
		const { content } = selectors.getCurrentSection(store.getState());
		const { id: sectionId } = content;

		setStorageWithExpiry(
			getSocLocalStorageKey(studyId, sectionId),
			shareOfChoiceInnovationProductIds,
			86400000 * 365, // 365 days
		);
	}
};

const startShareOfChoice = (store, { type, payload }) => {
	if (type === actions.START_SHARE_OF_CHOICE) {
		const { studyId, sectionId } = payload;

		store.dispatch(actions.setShareOfChoiceLoading(true));

		services.shareOfChoiceService
			.fetchShareOfChoiceProgress(sectionId, studyId)
			.then(progressResponse => {
				// fetch groups
				services.shareOfChoiceService
					.fetchShareOfChoiceGroups(sectionId, studyId)
					.then(groupsReponse => {
						// get currennt data that may be set already
						const innovationProductIds = selectors.getShareOfChoiceInnovationProductIds(store.getState());
						const existingProductIds = selectors.getShareOfChoiceExistingProductIds(store.getState());
						const currentGroups = selectors.getShareOfChoiceGroups(store.getState());
						const { user } = rootSelectors.getUserProfileData(store.getState());
						const { base: overallBase } = selectors.getResponseData(store.getState());

						const currentBaseCaseGroup = (currentGroups || []).find(group => group.isBaseCase);

						const key = getSocLocalStorageKey(studyId, sectionId);
						const idsInStorage = getStorageWithExpiry(key) || [];

						/**
						 * SET GROUPS AND BASE CASE
						 * */
						const colors = [...CONSTANTS.shareOfChoice.categoryColors];

						const groups = groupsReponse.data.map(group => {
							if (group.isBaseCase) {
								return group;
							}

							const groupHex = colors.length
								? colors[0]
								: `00000${Math.floor(Math.random() * 16777215).toString(16)}`.slice(-6);

							const groupWithHex = { ...group, hex: groupHex };
							colors.splice(0, 1);
							return groupWithHex;
						});

						store.dispatch(actions.setShareOfChoiceGroups(groups));

						const baseCaseGroup = groupsReponse.data.find(group => group.isBaseCase);
						const intersectingIds = intersection(baseCaseGroup?.productIds, idsInStorage);

						if (baseCaseGroup) {
							store.dispatch(actions.setShareOfChoiceExistingProductIds(baseCaseGroup.productIds));

							if (intersectingIds.length) {
								store.dispatch(actions.setShareOfChoiceInnovationProductIds([]));
								store.dispatch(actions.setShareOfChoiceExcludedProducts([]));
								window.localStorage.removeItem(getSocLocalStorageKey(studyId, sectionId));
							} else if (
								!intersectingIds.length &&
								innovationProductIds.length === 0 &&
								idsInStorage.length !== 0
							) {
								store.dispatch(actions.setShareOfChoiceInnovationProductIds(idsInStorage));
								store.dispatch(actions.setShareOfChoiceExcludedProducts(idsInStorage));
							}
						} else if (!baseCaseGroup && existingProductIds.length) {
							// No base case but existing products found, reset data and removing local storage
							store.dispatch(actions.resetShareOfChoice());

							if (idsInStorage && idsInStorage.length) {
								window.localStorage.removeItem(key);
							}
						}

						if (baseCaseGroup) {
							store.dispatch(actions.setShareOfChoiceExistingProductIds(baseCaseGroup.productIds));
						}

						if (
							overallBase >= CONSTANTS.minimumBase &&
							progressResponse.data[CONSTANTS.shareOfChoice.hasPreviousReportAvailable]
						) {
							const userUuid = `user:${user?.uuid}`;

							if (
								(currentBaseCaseGroup?.savedBy === userUuid && baseCaseGroup?.savedBy !== userUuid) ||
								(currentBaseCaseGroup &&
									baseCaseGroup?.savedBy !== userUuid &&
									xor(currentBaseCaseGroup?.productIds, baseCaseGroup?.productIds).length > 0)
							) {
								toastr.warning('Market Simulator has been updated by another user', null, {
									timeOut: 5000,
								});
							}
						}

						/**
						 * SET PROGRESS
						 * */
						const timestamp = new Date().getTime();
						store.dispatch(
							actions.setShareOfChoiceProgress({
								progress: parseInt(progressResponse.data[CONSTANTS.shareOfChoice.progress]),
								timeRemaining: parseInt(
									progressResponse.data[CONSTANTS.shareOfChoice.estimatedTimeLeft],
								),
								hasPreviousReportAvailable:
									progressResponse.data[CONSTANTS.shareOfChoice.hasPreviousReportAvailable],
								progressWarning: progressResponse.data[CONSTANTS.shareOfChoice.progressWarning],
								freshDataAvailable: progressResponse.data[CONSTANTS.shareOfChoice.freshDataAvailable],
								timestamp,
							}),
						);

						/**
						 * Loading complete, but there is no report to show. Removes spinner when in GenerateReport, NoBase, OR SimulatorProgress states
						 */
						if (
							!progressResponse.data[CONSTANTS.shareOfChoice.hasPreviousReportAvailable] ||
							overallBase < CONSTANTS.minimumBase
						) {
							store.dispatch(actions.setShareOfChoiceLoading(false));
						}
					})
					.catch(error => {
						console.error('Error fetching share of choice groups:', error);
					});
			})
			.catch(error => {
				console.error('Fetch Share of Choice Progress Error:', error);
				const timestamp = new Date().getTime();
				store.dispatch(
					actions.setShareOfChoiceProgress({
						progress: null,
						timeRemaining: undefined,
						timestamp,
					}),
				);
			});
	}
};

const fetchStudyLoi = async (store, { type, payload }) => {
	if (type === actions.FETCH_LENGTH_OF_INTERVIEW) {
		const { data: lengthOfInterview } = await services.studySampleService.fetchStudyLoi(payload.studyId);
		store.dispatch(actions.setLengthOfInterview(lengthOfInterview));
	}
};

const fetchDemographicQuestionsReportData = async (store, { type, payload }) => {
	if (type === actions.FETCH_DEMOGRAPHIC_QUESTIONS_REPORT_DATA) {
		const { studyId, groupUuid, filters = [] } = payload;
		const preparedFilters = [];
		let audienceUuids = [];

		Object.keys(filters).forEach(filterQuestionId => {
			const values = filters[filterQuestionId];
			if (filterQuestionId === 'audience') {
				audienceUuids = values;
			} else if (values && values.length > 0) {
				// Normal Filters
				preparedFilters.push({
					questionId: filterQuestionId,
					values,
				});
			} else if (values && values.attributeIds && values.attributeIds.length > 0) {
				// Attribute Filters
				const qId = filterQuestionId.split('-')[0];
				const attributeId = filterQuestionId.split('-')[1];

				preparedFilters.push({
					questionId: qId,
					values: values.value,
					attributeIds: [parseInt(attributeId)],
				});
			} else if (values && values.rank && values.value?.length) {
				preparedFilters.push({
					questionId: filterQuestionId,
					values: values.value,
					rank: parseInt(values.rank),
				});
			} else if (values && values.min && values.max) {
				// Age Filter
				preparedFilters.push({ questionId: filterQuestionId, ...values });
			}
		});

		try {
			const audienceReports = await services.audienceService.getDemographicQuestionsReport(studyId, groupUuid, {
				filters: preparedFilters,
				languages: [],
				audienceUuids,
			});

			store.dispatch(actions.setDemographicQuestionsReportData(groupUuid, audienceReports.data));
		} catch (error) {
			console.error(error);
		}
	}
};

const fetchAudienceCollectionReportData = async (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCE_COLLECTION_REPORT_DATA) {
		const { studyId, collectionId, filters = [] } = payload;
		const preparedFilters = [];
		let audienceUuids = [];

		Object.keys(filters).forEach(filterQuestionId => {
			const values = filters[filterQuestionId];
			if (filterQuestionId === 'audience') {
				audienceUuids = values;
			} else if (values && values.length > 0) {
				// Normal Filters
				preparedFilters.push({
					questionId: filterQuestionId,
					values,
				});
			} else if (values && values.attributeIds && values.attributeIds.length > 0) {
				// Attribute Filters
				const qId = filterQuestionId.split('-')[0];
				const attributeId = filterQuestionId.split('-')[1];

				preparedFilters.push({
					questionId: qId,
					values: values.value,
					attributeIds: [parseInt(attributeId)],
				});
			} else if (values && values.rank && values.value?.length) {
				preparedFilters.push({
					questionId: filterQuestionId,
					values: values.value,
					rank: parseInt(values.rank),
				});
			} else if (values && values.min && values.max) {
				// Age Filter
				preparedFilters.push({ questionId: filterQuestionId, ...values });
			}
		});

		try {
			const audienceReports = await services.audienceService.getAudienceCollectionReport(studyId, collectionId, {
				filters: preparedFilters,
				audienceUuids,
			});

			store.dispatch(actions.setAudienceCollectionReportData(collectionId, audienceReports.data));
		} catch (error) {
			console.error(error);
		}
	}
};

export default [
	performInitialFetch,
	fetchAudienceSection,
	fetchData,
	fetchProducts,
	fetchProductsAndResponseData,
	// sortProducts,
	draftStudy,
	publishStudy,
	closeStudy,
	patchStudy,
	patchStudySettings,
	generateReport,
	generateInterestCsv,
	generateSocCsv,
	setShowDevicePreviewMode,
	patchCustomizeReportItem,
	setCustomizeReport,
	setLanguage,
	fetchIdeaSplitQuestionReport,
	fetchQuestionReport,
	exportMonadicSplit,
	exportMonadicSplitComponent,
	setMonadicSplitReportingData,
	patchShowAudienceReports,
	patchShowAudienceReportItem,
	// Sections
	...sectionEffects,
	fetchFilteredBase,
	// Share Of Choice
	fetchShareOfChoice,
	fetchShareOfChoiceProgress,
	processShareOfChoice,
	createShareOfChoiceGroup,
	updateShareOfChoiceGroup,
	fetchShareOfChoiceGroups,
	deleteShareOfChoiceGroup,
	setShareOfChoiceInnovationProductIds,
	startShareOfChoice,
	fetchStudyLoi,
	fetchDemographicQuestionsReportData,
	fetchAudienceCollectionReportData,
];
