import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
// TODO: Get working with Sentry.ErrorBoundary - https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
// import * as Sentry from '@sentry/react';
import { init } from '@sentry/browser';
import { Integrations as ApmIntegrations } from '@sentry/apm';
import { datadogRum } from '@datadog/browser-rum';

import './styles/App.scss';

import FallbackComponent from 'src/domains/auth/containers/FallbackComponent';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './store';

let trackingEnv = 'local';
if (process.env.beta) {
	trackingEnv = 'beta';
} else if (process.env.production) {
	trackingEnv = 'production';
} else if (process.env.staging) {
	trackingEnv = 'staging';
} else if (process.env.development) {
	trackingEnv = 'development';
}

// note - that might be more appropriate to use process.env.NODE_ENV variable
if (process.env.logToSentry) {
	console.log('enable sentry/dd');

	datadogRum.init({
		applicationId: '913d0391-c7cb-455e-9e70-2440ae964f8e',
		clientToken: 'pub9026cae98610355e5bbe2ec0aea3aa24',
		site: 'datadoghq.com',
		service: 'upsiide-dashboard',
		env: trackingEnv,
		// Specify a version number to identify the deployed version of your application in Datadog
		version: process.env.version,
		sampleRate: 100,
		trackUserInteractions: true,
		trackFrustrations: true,
		defaultPrivacyLevel: 'mask-user-input',
		// Connect APM traces to RUM
		allowedTracingOrigins: [/https:\/\/.*\.upsiide\.com/],
	});
	datadogRum.startSessionReplayRecording();

	init({
		dsn: 'https://1addab38eaff44199efa7ccf811ed70f@o212722.ingest.sentry.io/1339709',
		integrations: [new ApmIntegrations.Tracing()],
		tracesSampleRate: 0.25,
		release: process.env.version,
		// An environment identifier.
		environment: trackingEnv,
		beforeSend(event, hint) {
			event.extra = event.extra || {};

			if (datadogRum.getInternalContext()?.session_id) {
				event.tags = {
					...event.tags,
					ddSessionReplay: `https://app.datadoghq.com/rum/replay/sessions/${
						datadogRum.getInternalContext().session_id
					}`,
				};
			}

			if (hint.originalException != null) {
				// Assign enumerable error properties to extras
				try {
					const keys = Object.keys(hint.originalException);
					if (keys.length) {
						event.extra.errorProperties = {};
						keys.forEach(key => {
							event.extra.errorProperties[key] = hint.originalException[key];
						});
					}
				} catch (error) {
					console.warn('[sentry] Failed to assign enumerable error properties to extras', error);
				}
			}

			return event;
		},
	});
}

const container = document.getElementById('index');
const root = createRoot(container);

const Index = () => (
	<Provider store={store}>
		<FallbackComponent>
			<CookiesProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</CookiesProvider>
		</FallbackComponent>
	</Provider>
);

root.render(<Index />);
