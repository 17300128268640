import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import toastr from 'toastr';
import cn from 'src/utilities/bem-cn';
import ButtonAdd from 'public/images/audience/buttonAdd.svg';
import ButtonAddActive from 'public/images/audience/buttonAddActive.svg';
import ButtonEdit from 'public/images/audience/buttonEdit.svg';
import ButtonEditActive from 'public/images/audience/buttonEditActive.svg';
import InterlockQuotasIcon from 'public/images/audience/interlock-quotas-icon.png';
import TextInputQuota from 'src/components/elements/TextInputQuota';
import useOutsideClick from 'src/hooks/useOutsideClick';
import Button from 'src/components/elements/Button';
import SearchDropdown from '../SearchDropdown';
import AgeDropdown from '../AgeDropdown';
import './styles.scss';

const className = 'multi-select-dropdown';
const el = (name, mod) => cn(className, name, mod);

const MultiDropDown = ({
	label,
	data,
	disabled = false,
	onChange,
	onClose,
	viewMode = false,
	value = [],
	enableQuota = false,
	ageRangeErrors,
	setAgeRangeErrors,
	customDropdown = 'default',
	setData = false,
	searchable = false,
	deleteOption = null,
	onCreateOption = null,
	onBlurQuotaInput = false,
	onEditAgeRangeValues = false,
	hasInterlockedQuotas = false,
	canLowerQuota = true,
	maxLength = false,
	noItemsText = null,
	getDemographicUuid = null,
	patchDemographic = null,
}) => {
	const ref = useRef(null);
	const [focusedQuotaId, setFocusedQuotaId] = useState(null);
	const [openDropdown, setOpenDropdown] = useState(false);
	const [selected, setSelected] = useState(value || []);
	const [showAll, setShowAll] = useState(false);

	const sortByOrder = (a, b) => {
		return a.order - b.order;
	};

	const addOption = () => {
		setOpenDropdown(prev => !prev);
	};

	const onCloseDropdown = () => {
		if (!openDropdown) return;
		setOpenDropdown(false);
		onClose && onClose();
	};

	const getIcon = useMemo(() => {
		if (data?.length) {
			return openDropdown ? ButtonEditActive : ButtonEdit;
		}
		return openDropdown ? ButtonAddActive : ButtonAdd;
	}, [openDropdown, data]);

	const onChangeQuotaList = (itemId, quota) => {
		setSelected(prev => {
			const option = prev.find(item => item.value === itemId);

			const selectedList = prev.filter(item => item.value !== itemId);
			const orderList = [
				...selectedList,
				{
					...option,
					quota: {
						...option.quota,
						min: quota,
						max: quota,
					},
				},
			];
			return orderList.sort(sortByOrder);
		});
	};

	const onBlur = (e, v) => {
		const oldValue = value.find(s => s.value === v);
		if (!canLowerQuota) {
			if (oldValue?.quota?.max == null) {
				toastr.error("You can't change empty quotas after you have launched your group");
				onChangeQuotaList(v, '');
				return;
			}
			if (e.target.value !== '' && oldValue?.quota?.max && Number(e.target.value) < Number(oldValue.quota.max)) {
				toastr.error('You can only increase quotas after you have launched your group');
				onChangeQuotaList(v, oldValue.quota.max);
				return;
			}

			if (e.target.value == null || e.target.value === '') {
				toastr.error("You can't set quota to empty after you have launched your group");
				onChangeQuotaList(v, oldValue.quota.max);
				return;
			}
		}
		onBlurQuotaInput(selected);
		setFocusedQuotaId(null);
	};

	const renderQuota = (v, quota) => {
		const inputQuota = (
			<TextInputQuota
				customClasses={`${hasInterlockedQuotas ? 'is-interlocked' : ''}`}
				value={quota}
				onChange={newValue => onChangeQuotaList(v, newValue)}
				onBlur={e => onBlur(e, v)}
				onFocus={() => setFocusedQuotaId(v)}
				maxLength={5}
				complement="%"
				viewMode={viewMode}
			/>
		);

		if (!hasInterlockedQuotas) return inputQuota;

		return (
			<Tooltip
				theme="dark-blured interlock"
				position="top"
				html={
					<div className="interlock-quota-warning">
						<b>Interlocked quota</b>
						<p>
							Click on
							<img
								className={el('interlock-quotas-icon')}
								src={InterlockQuotasIcon}
								alt="Interlock Quotas"
							/>
							on top of your
						</p>
						<p>demographic details table to</p>
						<p>go to interlock page</p>
					</div>
				}
			>
				{inputQuota}
			</Tooltip>
		);
	};

	const renderData = () => {
		console.log('selected :>> ', selected);
		let orderedQuotas = selected.sort(sortByOrder);
		if (!showAll && orderedQuotas?.length > 7) {
			orderedQuotas = orderedQuotas?.slice(0, 6);
		}
		return orderedQuotas?.map(s => (
			<div
				className={`
					${el('item_container')}
					${s.quota?.max != null ? 'has-quotas' : ''}
					${enableQuota ? 'enable-quotas' : ''}
					${focusedQuotaId && s.value === focusedQuotaId ? 'active' : ''}`}
				key={s.quota?.id}
			>
				<span className={el('label')}>{s.label}</span>
				{enableQuota ? renderQuota(s.value, s.quota?.max) : null}
			</div>
		));
	};

	const onLocalChange = list => {
		const selectedList = list.filter(item => data?.find(e => e?.value === item?.value));
		onChange && onChange(selectedList);
	};

	useOutsideClick(ref, () => {
		onCloseDropdown();
	});

	useEffect(() => {
		if (JSON.stringify(value) !== JSON.stringify(selected)) {
			setSelected(value.sort(sortByOrder));
		}
		// eslint-disable-next-line
	}, [value]);

	const renderDropdown = () => {
		if (customDropdown === 'age') {
			return (
				<AgeDropdown
					data={data.sort(sortByOrder)}
					setData={setData}
					onEditAgeRangeValues={onEditAgeRangeValues}
					ageRangeErrors={ageRangeErrors}
					getDemographicUuid={getDemographicUuid}
					setAgeRangeErrors={setAgeRangeErrors}
					value={selected}
					patchDemographic={patchDemographic}
					onChange={onLocalChange}
					deleteOption={deleteOption}
					onCreateOption={onCreateOption}
					multi
				/>
			);
		}
		return (
			<SearchDropdown
				data={data.sort(sortByOrder)}
				value={selected}
				onChange={onLocalChange}
				multi
				maxLength={maxLength}
				searchable={searchable}
			/>
		);
	};

	return (
		<div
			className={`${className}${disabled ? ' disabled' : ''} ${
				customDropdown === 'age' ? el('age-dropdown') : ''
			}`}
			ref={ref}
		>
			<div className={el('container')}>
				<section
					className={el('content')}
					style={{ maxHeight: '110px' }}
					aria-hidden
					onClick={e => {
						if (e.target.className === el('content')) onCloseDropdown();
					}}
				>
					{renderData()}
					{!showAll && selected?.length > 7 ? (
						<div
							className={`${el('item_container')} show-more`}
							onClick={() => setShowAll(true)}
							aria-hidden
						>
							<span className={el('label')} style={{ minWidth: 28 }}>
								+ {selected.length - 6}
							</span>
						</div>
					) : null}
					{showAll ? <Button type="text" label="Show Less" onClick={() => setShowAll(false)} /> : null}
					{label?.length ? <span>{label}</span> : ''}
					{!viewMode && canLowerQuota ? (
						<>
							{noItemsText ? <span className={el('empty-selection-message')}>{noItemsText}</span> : null}
							{!hasInterlockedQuotas && (
								<div className={el('add-icon-container')} onClick={addOption} aria-hidden>
									<img src={getIcon} alt="" />
								</div>
							)}
						</>
					) : null}
				</section>
			</div>
			{openDropdown && renderDropdown()}
		</div>
	);
};

MultiDropDown.propTypes = {
	label: PropTypes.string,
	data: PropTypes.array,
	value: PropTypes.array,
	hasInterlockedQuotas: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onClose: PropTypes.func,
	viewMode: PropTypes.bool,
	customDropdown: PropTypes.string,
	setData: PropTypes.func,
	searchable: PropTypes.bool,
	deleteOption: PropTypes.func,
	onCreateOption: PropTypes.func,
	onEditAgeRangeValues: PropTypes.func,
	onBlurQuotaInput: PropTypes.func,
	canLowerQuota: PropTypes.bool,
	maxLength: PropTypes.number,
	enableQuota: PropTypes.bool,
	noItemsText: PropTypes.string,
	ageRangeErrors: PropTypes.object,
	setAgeRangeErrors: PropTypes.func,
	getDemographicUuid: PropTypes.func,
	patchDemographic: PropTypes.func,
};

export default MultiDropDown;
