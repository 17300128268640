import * as services from 'src/services';
import * as authActions from 'src/domains/auth/actions';
import * as actions from '../actions';
import * as selectors from '../selectors';

/**
 * Fetch clients (to put them to the dropdown) above the table
 */
const fetchClients = (store, action) => {
	if (action.type === actions.FETCH_CLIENTS) {
		store.dispatch(actions.setClients({ loading: true }));
		services.clientsService
			.getManagedClients()
			.then(({ data: clients }) => {
				const uuid = action.payload.uuid || clients[0].uuid;
				store.dispatch(actions.setClientId(uuid));
				store.dispatch(
					actions.setClients({
						loading: false,
						content: clients,
						error: null,
					})
				);
			})
			.catch(error => {
				store.dispatch(actions.setClients({ loading: false, error }));
			});
	}
};

/**
 * Fetch users for the table
 */
const fetchUsers = (store, action) => {
	if (action.type === actions.FETCH_USERS) {
		const clientId = selectors.getClientId(store.getState());

		store.dispatch(actions.setUsers({ loading: true }));
		services.clientsService
			.getUsers(clientId)
			.then(content => {
				store.dispatch(actions.setUsers({ loading: false, content, error: null }));
			})
			.catch(error => {
				store.dispatch(actions.setUsers({ loading: false, error }));
			});
	}
};

/**
 * Fetch groups for the table
 */
const fetchGroups = (store, action) => {
	if (action.type === actions.FETCH_GROUPS) {
		store.dispatch(actions.setGroups({ loading: true }));
		services.clientsService
			.getGroups()
			.then(content => {
				store.dispatch(actions.setGroups({ loading: false, content, error: null }));
			})
			.catch(error => {
				store.dispatch(actions.setGroups({ loading: false, error }));
			});
	}
};

/**
 * Fetch users when client ID gets updated
 */
const triggerFetches = (store, action) => {
	if (action.type === actions.SET_CLIENT_ID) {
		/* Delay a bit to make the headers update effect happen properly */
		setTimeout(() => store.dispatch(actions.fetchUsers()), 0);
		setTimeout(() => store.dispatch(actions.fetchGroups()), 0);
		setTimeout(() => store.dispatch(actions.fetchRoles()), 0);
	}
};

/**
 * Update auth header when client id gets updated
 */
const updateHeader = (store, action) => {
	if (action.type === actions.SET_CLIENT_ID) {
		const clientId = selectors.getClientId(store.getState());

		store.dispatch(authActions.setClientId(clientId));
	}
};

/**
 * Add User Effect
 */
const addUser = (store, action) => {
	if (action.type === actions.ADD_USER) {
		const { emails, role } = action.payload;
		const clientId = selectors.getClientId(store.getState());

		store.dispatch(actions.setAddUserLoading(true));
		Promise.all(emails.map(email => services.clientsService.addUser(clientId, email, role)))
			.then(() => {
				store.dispatch(actions.setAddUserLoading(false));
				store.dispatch(actions.setAddUserComplete(true));
				store.dispatch(actions.fetchUsers());
				setTimeout(() => store.dispatch(actions.setAddUserComplete(false)), 2000);
			})
			.catch(error => {
				store.dispatch(actions.setAddUserLoading(false));
			});
	}
};

/**
 * ADd Group effect
 */
const addGroup = (store, action) => {
	if (action.type === actions.ADD_GROUP) {
		const { label } = action.payload;

		store.dispatch(actions.setAddGroupModal({ loading: true }));
		services.clientsService
			.addGroup(label)
			.then(() => {
				store.dispatch(actions.setAddGroupModal({ visible: false, loading: false }));
				store.dispatch(actions.fetchGroups());
			})
			.catch(error => {
				store.dispatch(actions.setAddGroupModal({ loading: false, error }));
			});
	}
};

/**
 * Delete user effect
 */
const deleteUser = (store, action) => {
	if (action.type === actions.DELETE_USER) {
		const { id } = action.payload;
		const clientId = selectors.getClientId(store.getState());

		store.dispatch(actions.setDeleteUserModal({ visible: false }));
		store.dispatch(actions.setCommonError(null));
		store.dispatch(actions.setUsers({ loading: true }));
		services.clientsService
			.deleteUser(clientId, id)
			.then(response => {
				store.dispatch(actions.fetchUsers());
			})
			.catch(error => {
				store.dispatch(actions.setUsers({ loading: false }));
				store.dispatch(actions.setCommonError(error));
			});
	}
};

/**
 * Change user role
 */
const changeUserRole = (store, action) => {
	if (action.type === actions.CHANGE_USER_ROLE) {
		const { userId, roleId } = action.payload;
		const clientId = selectors.getClientId(store.getState());

		store.dispatch(actions.setCommonError(null));
		store.dispatch(actions.setUsers({ loading: true }));
		services.clientsService
			.changeUserRole(clientId, userId, roleId)
			.then(() => {
				store.dispatch(actions.fetchUsers());
			})
			.catch(error => {
				store.dispatch(actions.setUsers({ loading: false }));
				store.dispatch(actions.setCommonError(error));
			});
	}
};

/**
 * Fetching roles effect
 */
const fetchRoles = (store, action) => {
	if (action.type === actions.FETCH_ROLES) {
		store.dispatch(actions.setRoles({ loading: true }));
		services.clientsService
			.getRoles()
			.then(content => {
				store.dispatch(actions.setRoles({ loading: false, error: null, content }));
			})
			.catch(error => {
				store.dispatch(actions.setRoles({ loading: false, error }));
			});
	}
};

/**
 * Delete a group
 */
const deleteGroup = (store, action) => {
	if (action.type === actions.DELETE_GROUP) {
		const { id } = action.payload;

		store.dispatch(actions.setDeleteGroupModal({ visible: false }));
		store.dispatch(actions.setGroups({ loading: true }));
		services.clientsService
			.deleteGroup(id)
			.then(() => {
				store.dispatch(actions.setGroups({ loading: false, error: null }));
				store.dispatch(actions.fetchGroups());
			})
			.catch(error => {
				store.dispatch(actions.setGroups({ loading: false, error }));
			});
	}
};

/**
 * Manage group event
 */
const manageGroup = (store, action) => {
	if (action.type === actions.MANAGE_GROUP) {
		const { id } = action.payload;
		const groups = selectors.getGroups(store.getState());
		const group = groups.content.filter(group => group.id === id).pop();
		const label = group ? group.label : null;

		store.dispatch(
			actions.setManageGroupModal({
				group: id,
				label,
				visible: true,
				loading: true,
				error: null,
			})
		);
		store.dispatch(actions.fetchGroupUsers());
	}
};

const fetchGroupUsers = (store, action) => {
	if (action.type === actions.FETCH_GROUP_USERS) {
		const manageGroupModal = selectors.getManageGroupModal(store.getState());
		const groupId = manageGroupModal.group;

		store.dispatch(actions.setManageGroupModal({ loading: true }));
		services.clientsService
			.getUsersByGroup(groupId)
			.then(({ data }) => {
				store.dispatch(
					actions.setManageGroupModal({
						users: data,
						loading: false,
						error: null,
					})
				);
			})
			.catch(error => {
				store.dispatch(actions.setManageGroupModal({ loading: false, error }));
			});
	}
};

const applyManageGroup = (store, action) => {
	if (action.type === actions.APPLY_MANAGE_GROUP) {
		const { diff } = action.payload;
		const manageGroupModal = selectors.getManageGroupModal(store.getState());
		const promises = [];

		store.dispatch(actions.setManageGroupModal({ loading: true }));

		if (diff.label) {
			promises.push(services.clientsService.renameGroup(manageGroupModal.group, diff.label));
		}

		if (diff.users.add.length > 0) {
			diff.users.add.forEach(uuid => {
				promises.push(services.clientsService.addUserToGroup(manageGroupModal.group, uuid));
			});
		}

		if (diff.users.remove.length > 0) {
			diff.users.remove.forEach(uuid => {
				promises.push(services.clientsService.removeUserFromGroup(manageGroupModal.group, uuid));
			});
		}

		Promise.all(promises)
			.then(() => {
				store.dispatch(
					actions.setManageGroupModal({
						visible: false,
						loading: false,
						error: null,
					})
				);
				store.dispatch(actions.fetchGroups());
			})
			.catch(error => {
				store.dispatch(actions.setManageGroupModal({ loading: false, error }));
			});
	}
};

export default [
	fetchClients,
	fetchUsers,
	fetchGroups,
	triggerFetches,
	updateHeader,
	addUser,
	addGroup,
	deleteUser,
	changeUserRole,
	fetchRoles,
	deleteGroup,
	manageGroup,
	fetchGroupUsers,
	applyManageGroup,
];
