// packages
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// utilities
import cn from 'src/utilities/bem-cn';

// helpers
import { getMeanScoreScaleOrder } from 'src/components/helpers';

// actions
import * as actions from 'src/domains/manage-study/actions';
import * as blueprintActions from 'src/domains/manage-blueprints/actions';

// hooks
import { useIsBlueprint } from 'src/hooks';

// components
import ResetScaleButton from './ResetScaleButton';
import FlipScaleButton from './FlipScaleButton';

// styles
import './styles.scss';

const className = 'modify-mean-score-scale';

const el = (name, mod) => cn(className, name, mod);

const ModifyScaleButton = ({
	// props
	question,
	screeningQuestion,
	tempTemplate,
	batchUpdateAudienceTemplateScreeningQuestionOptions,
	setOptionScalePointValue,

	// mapDispatchToProps
	patchManageStudyQuestion,
	patchManageBlueprintQuestion,
	// setManageStudyOptionScalePointValue,
	// setManageBlueprintOptionScalePointValue,
}) => {
	// state
	const options = useMemo(() => question?.options ?? [], [question?.options]);

	const { isBlueprint } = useIsBlueprint();

	const patchQuestion = isBlueprint ? patchManageBlueprintQuestion : patchManageStudyQuestion;

	// * Check whether each answer has a scale point equal to its ordinal position (asc default). Scale points of 'Other' and 'None of the Above' options should be `null` by default
	const isCustomScale = useMemo(() => getMeanScoreScaleOrder(options)?.isCustom, [options]);

	return (
		<div className={el('wrapper')}>
			{isCustomScale ? (
				<ResetScaleButton
					{...{
						question,
						patchQuestion,
						setOptionScalePointValue,
						screeningQuestion,
						tempTemplate,
						batchUpdateAudienceTemplateScreeningQuestionOptions,
					}}
				/>
			) : (
				<FlipScaleButton
					{...{
						question,
						patchQuestion,
						setOptionScalePointValue,
						screeningQuestion,
						tempTemplate,
						batchUpdateAudienceTemplateScreeningQuestionOptions,
					}}
				/>
			)}
		</div>
	);
};

ModifyScaleButton.propTypes = {
	// props
	question: PropTypes.object,
	screeningQuestion: PropTypes.bool,
	tempTemplate: PropTypes.object,
	batchUpdateAudienceTemplateScreeningQuestionOptions: PropTypes.func,
	setOptionScalePointValue: PropTypes.func,

	// mapDispatchToProps
	patchManageStudyQuestion: PropTypes.func,
	patchManageBlueprintQuestion: PropTypes.func,
	// setManageStudyOptionScalePointValue: PropTypes.func,
	// setManageBlueprintOptionScalePointValue: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
	patchManageStudyQuestion: (questionId, data) => dispatch(actions.patchQuestion(questionId, data)),
	patchManageBlueprintQuestion: (questionId, data) => dispatch(blueprintActions.patchQuestion(questionId, data)),
});

export default connect(null, mapDispatchToProps)(ModifyScaleButton);
