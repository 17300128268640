import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { element } from 'src/utilities/bem-cn';
import RedDoor from 'src/components/icons/RedDoor';
import Door from 'src/components/icons/Door';

import { Tooltip } from 'react-tippy';

import './styles.scss';

const className = 'easy-logic';
const el = element(className);

const EasyLogic = ({ answer, index, defaultState, setEasyLogic, question }) => {
	const [enabled, setEnabled] = useState(defaultState);

	useEffect(() => {
		setEnabled(defaultState);
	}, [defaultState]);

	const toggleEasyLogic = e => {
		e.preventDefault();
		e.stopPropagation();
		if (enabled) {
			const logicId = question?.logic?.find(l =>
				l.triggerOptions.find(trigger => trigger?.id === answer?.id),
			)?.id;
			if (!logicId) return;
			setEasyLogic('delete');
			return setEnabled(prev => !prev);
		}
		const option = answer?.translations[0];
		const logic = [
			{
				itemType: 'question',
				triggerItemId: question?.id,
				triggerOptions: [
					{
						id: answer?.id,
						languageCode: option?.languageCode,
						order: 0,
						label: option?.label,
						chipLabel: option?.label,
						value: 0,
						isDisabled: true,
					},
				],
				triggerSectionId: question?.sectionId,
				operator: 'is',
				operand: 'and',
				actionType: 'terminate',
			},
		];

		setEasyLogic('create', logic);
		setEnabled(prev => !prev);
	};

	return (
		<Tooltip
			id={`${className}-tooltip-${answer.questionId}-${answer.id}-${index}`}
			className={el('tooltip')}
			animation="shift"
			animationFill={false}
			trigger="mouseenter"
			theme="basic-text-tooltip"
			html={<div>{!defaultState ? 'Add' : 'Remove'} easy logic</div>}
		>
			<div className={`${className}`} aria-hidden onClick={toggleEasyLogic}>
				{enabled ? <RedDoor /> : <Door />}
			</div>
		</Tooltip>
	);
};

EasyLogic.propTypes = {
	defaultState: PropTypes.bool,
	answer: PropTypes.object,
	question: PropTypes.object,
	index: PropTypes.number,
	setEasyLogic: PropTypes.func,
};

export default EasyLogic;
