import React from 'react';

import './styles.scss';

export default ({ ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
		<g fill="none" fillRule="evenodd">
			<path d="M0 0H16V16H0z" />
			<g fill="#3B3B3B">
				<path d="M10.106 9.574c-.293 0-.532.239-.532.532v2.66H6.383V2.66c0-.218-.133-.414-.335-.495L3.293 1.064h6.281V5.85c0 .294.239.532.532.532.294 0 .532-.238.532-.532V.531c0-.293-.238-.531-.532-.531H.532c-.06 0-.12.011-.176.032H.324L.255.069.197.122H.17C.128.164.095.215.074.272v.031C.033.373.007.451 0 .532v12.766c0 .218.133.414.335.495l5.32 2.127c.062.026.129.038.196.038.106 0 .21-.032.298-.091.147-.1.234-.265.234-.441V13.83h3.723c.294 0 .532-.238.532-.532v-3.192c0-.293-.238-.532-.532-.532zM5.32 14.638l-4.255-1.702V1.32l4.255 1.702v11.617z" />
				<path d="M15.803 7.601l-2.127-2.128c-.209-.208-.547-.208-.756 0-.208.209-.208.547 0 .756l1.224 1.218H7.979c-.294 0-.532.238-.532.532 0 .293.238.532.532.532h6.165L12.92 9.729c-.1.1-.157.236-.157.377 0 .142.057.278.157.378.1.1.236.157.378.157.142 0 .278-.056.378-.157l2.127-2.128c.049-.05.087-.11.112-.175.053-.13.053-.275 0-.404-.025-.066-.063-.125-.112-.176z" />
			</g>
		</g>
	</svg>
);
