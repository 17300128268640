import * as services from 'src/services';
import axios from 'src/utilities/axios';
import cloneDeep from 'lodash/cloneDeep';
import toastr from 'toastr';
import maxBy from 'lodash/maxBy';
import * as actions from '../actions';
import * as loginActions from '../../login/actions';
import * as selectors from '../selectors';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};
/**
 * Fetch clients (to put them to the dropdown) above the table
 */
const setAccountUuid = (store, action) => {
	if (action.type === actions.SET_ACCOUNT_UUID) {
		const { accountUuid } = action.payload;
		axios.setAccountUuid(accountUuid);
	}
};

/**
 * Fetch clients (to put them to the dropdown) above the table
 */
const fetchAccount = (store, action) => {
	if (action.type === actions.FETCH_ACCOUNT) {
		const currentAccount = selectors.getAccount(store.getState());

		store.dispatch(
			actions.setAccount({ ...currentAccount, content: { ...currentAccount?.content }, loading: true }),
		);
		store.dispatch(actions.setAccountUsage({}));
		services.accountService
			.getAccount(action.payload.uuid)
			.then(({ data: account }) => {
				store.dispatch(
					actions.setAccount({
						loading: false,
						content: account,
						error: null,
					}),
				);
				const { fetchUsersAndRoles } = action.payload;
				store.dispatch(actions.setClientId(action.payload.uuid, account.ownerUuid, fetchUsersAndRoles));
				store.dispatch(loginActions.getProvisioningState());
			})
			.catch(error => {
				store.dispatch(actions.setAccount({ loading: false, error }));
			});
	}
};

const updateAccount = (store, action) => {
	if (action.type === actions.UPDATE_ACCOUNT) {
		store.dispatch(actions.setUpdateAccountModal({ loading: true }));
		const { data } = action.payload;
		const clonedData = cloneDeep(data);
		const { account } = store.getState().account;

		delete data.planType;
		delete data.planInterval;
		delete data.usageFrequency;
		delete data.spaceLimit;
		delete data.userLimit;
		delete data.isStripeCustomer;
		delete data.creditCard;
		delete data.currency;
		delete data.isInvoiced;
		delete data.asset;
		delete data.creditCards;
		delete data.samlCertPath;
		delete data.samlCallbackUrl;
		if (data.samlUserConfig === 'null' || data.samlUserConfig === null || data.samlUserConfig === '{}')
			data.samlUserConfig = {};
		services.accountService
			.updateAccountSettings(data)
			.then(() => {
				toastr.success('Account Updated!');
				store.dispatch(
					actions.setUpdateAccountModal({
						loading: false,
						success: true,
					}),
				);

				setTimeout(() => {
					store.dispatch(
						actions.setUpdateAccountModal({
							visible: false,
							success: false,
						}),
					);
					store.dispatch(actions.fetchAccounts());
					store.dispatch(
						actions.setAccount({
							loading: false,
							content: {
								...account.content,
								accountName: data?.businessName,
								settings: { ...clonedData, samlUserConfig: JSON.stringify(clonedData.samlUserConfig) },
							},
							error: null,
						}),
					);
				}, 1000);
			})
			.catch(err => {
				toastr.error('Error Updating Account');

				store.dispatch(
					actions.setUpdateAccountModal({
						loading: false,
					}),
				);
			});
	}
};

const updateCreditCard = (store, action) => {
	if (action.type === actions.UPDATE_ACCOUNT_CREDIT_CARD) {
		store.dispatch(actions.setUpdateCreditCardModal({ loading: true, error: false }));
		const { stripeToken } = action.payload;

		services.accountService
			.updateCreditCard(stripeToken)
			.then(({ data }) => {
				toastr.success('Credit Card Saved!');
				store.dispatch(
					actions.setUpdateCreditCardModal({
						loading: false,
						success: true,
						error: false,
					}),
				);
				setTimeout(() => {
					store.dispatch(
						actions.setUpdateCreditCardModal({
							visible: false,
							success: false,
						}),
					);
					store.dispatch(actions.fetchAccount(action.payload.uuid));
				}, 1000);
			})
			.catch(error => {
				toastr.error('Error Saving Credit Card');
				console.error('credit card error');
				console.error(error);
				store.dispatch(
					actions.setUpdateCreditCardModal({
						loading: false,
						success: false,
						error: true,
					}),
				);
			});
	}
};

const addChargifyCreditCard = async (store, action) => {
	if (action.type === actions.ADD_CHARGIFY_CREDIT_CARD) {
		const { chargifyJsToken, accountUuid } = action.payload;
		try {
			await services.accountService.addChargifyCreditCard(chargifyJsToken);
		} catch (error) {
			toastr.error('Something went wrong, try again later');
			console.error(error);
		} finally {
			await services.accountService.getAccount(accountUuid).then(({ data: account }) => {
				services.accountService.fetchChargifySubscriptions().then(res => {
					store.dispatch(actions.setChargifySubscriptions(res.data));
				});

				store.dispatch(
					actions.setAccount({
						loading: false,
						content: account,
						error: null,
					}),
				);
			});
		}
	}
};
const removeChargifyCreditCard = async (store, action) => {
	if (action.type === actions.REMOVE_CHARGIFY_CREDIT_CARD) {
		const { cardId, accountUuid } = action.payload;
		try {
			await services.accountService.removeChargifyCreditCard(cardId);
		} catch (error) {
			toastr.error('Something went wrong, try again later');
			console.error(error);
		} finally {
			await services.accountService.getAccount(accountUuid).then(({ data: account }) => {
				store.dispatch(
					actions.setAccount({
						loading: false,
						content: account,
						error: null,
					}),
				);
			});
		}
	}
};
const updateDefaultChargifyCreditCard = async (store, action) => {
	if (action.type === actions.UPDATE_DEFAULT_CHARGIFY_CREDIT_CARD) {
		const { cardId, accountUuid } = action.payload;
		try {
			await services.accountService.updateDefaultChargifyCreditCard(cardId);
		} catch (error) {
			toastr.error('Something went wrong, try again later');
			console.error(error);
		} finally {
			await services.accountService.getAccount(accountUuid).then(({ data: account }) => {
				store.dispatch(
					actions.setAccount({
						loading: false,
						content: account,
						error: null,
					}),
				);
			});
		}
	}
};

const updateDefaultCreditCard = (store, action) => {
	if (action.type === actions.UPDATE_DEFAULT_CREDIT_CARD) {
		const { accountUuid, cardId } = action.payload;
		if (cardId) {
			services.accountService
				.updateDefaultCreditCard(cardId)
				.then(response => {
					services.accountService.getAccount(accountUuid).then(({ data: account }) => {
						store.dispatch(
							actions.setAccount({
								loading: false,
								content: account,
								error: null,
							}),
						);
					});
				})
				.catch(error => {
					console.error('There was an error updating your default credit card: error: ', error);
				});
		}
	}
};

const deleteCreditCard = (store, action) => {
	if (action.type === actions.DELETE_CREDIT_CARD) {
		const { accountUuid, creditCardId } = action.payload;
		if (creditCardId) {
			services.accountService.deleteCreditCard(creditCardId);
		}
	}
};

/**
 * Fetch subscription
 */
const fetchAccountSubscription = (store, action) => {
	if (action.type === actions.FETCH_ACCOUNT_SUBSCRIPTION) {
		store.dispatch(actions.setAccountSubscription({ loading: true }));
		services.accountService
			.getAccountSubscription(action.payload.uuid)
			.then(({ data: subscription }) => {
				store.dispatch(
					actions.setAccountSubscription({
						loading: false,
						content: subscription,
						error: null,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setAccountSubscription({ loading: false, error }));
			});
	}
};

/**
 * Fetch subscription
 */
const fetchAccountInvoices = (store, action) => {
	if (action.type === actions.FETCH_ACCOUNT_INVOICES) {
		store.dispatch(actions.setAccountInvoices({ loading: true }));
		services.accountService
			.getAccountInvoices(action.payload.uuid)
			.then(({ data: invoices }) => {
				store.dispatch(
					actions.setAccountInvoices({
						loading: false,
						content: invoices,
						error: null,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setAccountInvoices({ loading: false, error }));
			});
	}
};

const fetchRoles = (store, action) => {
	if (action.type === actions.FETCH_ROLES) {
		services.accountService
			.getRoles()
			.then(data => store.dispatch(actions.setRoles({ loading: false, content: data })));
	}
};

const initialFetch = (store, action) => {
	if (action.type === actions.SET_CLIENT_ID) {
		const { fetchUsersAndRoles } = action.payload;
		if (fetchUsersAndRoles) {
			// Delay a bit to make the headers update effect happen properly
			// need to fetch users to show on the manage users table, no effects exist for this yet
			setTimeout(() => store.dispatch(actions.fetchUsers()), 0);
			setTimeout(() => store.dispatch(actions.fetchRoles()), 0);
		}
	}
};

const addUser = async (store, action) => {
	if (action.type === actions.ADD_USER) {
		const { data } = action.payload;
		const distinctFirst = [];
		const subsequent = [];
		data.forEach(invite => {
			const { email, role, space, accountName } = invite;

			space.forEach(client => {
				const isDistinct = distinctFirst.findIndex(item => item.email === email) < 0;

				if (isDistinct) {
					distinctFirst.push({ email, role, client, accountName, space });
				} else {
					subsequent.push({ email, role, client, accountName });
				}
			});
		});

		return Promise.all(
			distinctFirst.map(invite =>
				services.accountService.addUser(
					invite.client,
					invite.email,
					invite.role,
					invite.accountName,
					invite.space,
				),
			),
		)
			.then(() =>
				Promise.all(
					subsequent.map(invite =>
						services.accountService.addUser(
							invite.client,
							invite.email,
							invite.role,
							invite.accountName,
							null, // To prevent email from sending through sendgrid in upsiide API
						),
					),
				).then(res => {
					toastr.success('Invites Sent!');
					store.dispatch(actions.setAccountUsageUserCount(distinctFirst.length));

					store.dispatch(actions.fetchUsers());
				}),
			)
			.catch(err => {
				toastr.error('Error Sending Invites');
				store.dispatch(actions.setUsers({ error: true }));
			});
	}
};

const giveRoleForNoAccess = async (store, action) => {
	if (action.type === actions.GIVE_ROLE_NEW) {
		const { clientId, emails, role } = action.payload;
		if (Array.isArray(emails)) {
			Promise.all(emails.map(email => services.accountService.addUser(clientId, email, role))).then(res =>
				store.dispatch(actions.fetchUsers()),
			);
		} else {
			try {
				// API Call
				await services.accountService.addUser(clientId, emails, role);

				// Manipulate local redux state to avoid refetching
				const currentUsers = selectors.getUsers(store.getState()).content;
				const currentRoles = selectors.getRoles(store.getState()).content;
				const foundUserIndex = currentUsers.findIndex(user => user.email === emails);
				const foundRole = currentRoles.find(r => role === r.id);
				const foundUser = currentUsers[foundUserIndex];
				const newClient = {
					roleId: foundRole.id,
					roleLevel: foundRole.level,
					uuid: clientId,
				};
				foundUser.clients.push(newClient);
				currentUsers[foundUserIndex] = foundUser;
				store.dispatch(
					actions.setUsers({
						content: currentUsers,
						loading: false,
					}),
				);
			} catch (error) {
				console.error(error);
				toastr.error(`Error giving this user roles in a new client/space. Please try again`);
			}
		}
	}
};

const fetchUsers = async (store, action) => {
	if (action.type === actions.FETCH_USERS) {
		try {
			const { search = null, refetch = false } = action.payload;
			const storeUsers = selectors.getUsers(store.getState());

			const isLoading = storeUsers.loading;

			if (isLoading) {
				console.warn('already re-fetching users, back out!');
				return;
			}
			if (refetch && !storeUsers.hasMore) {
				console.warn('At the end of the user list! Not fetching again');
				return;
			}

			const limit = 20;
			let newSearch = search || null;
			let currentOffset = 0;

			if (refetch) {
				currentOffset = storeUsers.offset || 0;
				newSearch = storeUsers.search;
				store.dispatch(actions.setUsers({ loading: true }));
			} else {
				store.dispatch(actions.setUsers({ loading: true, content: [] }));
			}

			// TODO add search capability
			const usersResponse = await services.accountService.getUsers(newSearch, currentOffset, limit);

			const { data } = usersResponse;
			let hasMore = false;
			let newOffset = currentOffset;
			let newContent = data;
			// If we have results, increase offset for next call and keep track of hasMore
			if (data && data.length > 0) {
				newOffset += data.length;
				hasMore = data.length === limit;
			} else {
				hasMore = false;
			}
			// Refetch/pagination specific
			if (refetch) {
				// reset the content to the current state, we're going to append it.
				newContent = [...(storeUsers.content || [])];
				// If we have results, increase offset for next call and keep track of hasMore
				if (data && data.length > 0) {
					newContent = newContent.concat(data);
				}
			}

			store.dispatch(
				actions.setUsers({
					content: newContent,
					loading: false,
					offset: newOffset,
					hasMore,
					search: newSearch,
				}),
			);
		} catch (error) {
			console.error(error);
			store.dispatch(actions.setUsers({ loading: false, content: [], error }));
			toastr.error('Something went wrong loading users. Please try again later.');
		}
	}
};

const createClient = (store, action) => {
	if (action.type === actions.CREATE_CLIENT) {
		store.dispatch(actions.setAccountModalState({ loading: true }));
		const { account } = store.getState().account;
		const { uuid } = account.content;
		services.accountService
			.createClient({ name: action.payload.client.name }, uuid)
			.then(() => {
				toastr.success('New Space Created!');
				store.dispatch(
					actions.setAccountModalState({
						loading: false,
						visible: false,
					}),
				);
				store.dispatch(actions.fetchAccountUsage());
				store.dispatch(actions.fetchAccount(action.payload.uuid));
			})
			.catch(err => {
				toastr.error('Error Creating Space');
				store.dispatch(actions.setAccountModalState({ loading: false }));
				store.dispatch(actions.setError(err));
			});
	}
};

// const deleteClient = (store, action) => {
// 	if (action.type === actions.DELETE_CLIENT) {
// 		// TODO: Write effect to handle DELETE_CLIENT once endpoint is available.
// 	}
// };

const changeUserRole = async (store, action) => {
	if (action.type === actions.CHANGE_USER_ROLE) {
		const { clientId, userId, roleId } = action.payload;
		try {
			// API Call
			await services.clientsService.changeUserRole(clientId, userId, roleId);

			// Manipulate local redux state to avoid refetching
			const currentUsers = selectors.getUsers(store.getState()).content;
			const currentRoles = selectors.getRoles(store.getState()).content;
			const foundUserIndex = currentUsers.findIndex(user => `user:${user.uuid}` === userId);
			const foundUser = currentUsers[foundUserIndex];
			const foundClientIndex = foundUser.clients.findIndex(client => client.uuid === clientId);
			foundUser.clients[foundClientIndex].roleId = roleId;
			foundUser.clients[foundClientIndex].roleLevel = currentRoles.find(role => role.id === roleId).level;
			currentUsers[foundUserIndex] = foundUser;
			store.dispatch(
				actions.setUsers({
					content: currentUsers,
					loading: false,
				}),
			);
		} catch (error) {
			console.error(error);
			toastr.error(`Error changing this user's role. Please try again`);
		}
	}
};

const deleteUser = (store, action) => {
	if (action.type === actions.DELETE_USER) {
		const { userUuid, clientUuids } = action.payload;

		store.dispatch(actions.setDeleteUserConfirmationModal({ visible: false }));
		store.dispatch(actions.setUsers({ loading: true }));

		// API Call
		Promise.all(clientUuids.map(uuid => services.accountService.deleteUser(uuid, `user:${userUuid}`)))
			.then(res => {
				toastr.success('User Removed!');

				// Manipulate local redux state to avoid refetching
				const currentUsers = selectors.getUsers(store.getState()).content;

				const foundUserIndex = currentUsers.findIndex(user => user.uuid === userUuid);
				currentUsers.splice(foundUserIndex, 1);

				// Also need to reduce the offset by 1.
				const currentOffset = selectors.getUsers(store.getState()).offset;
				store.dispatch(
					actions.setUsers({
						content: currentUsers,
						offset: currentOffset - 1,
						loading: false,
					}),
				);

				store.dispatch(actions.setAccountUsageUserCount(-1));
			})
			.catch(err => {
				toastr.error('Error Removing User');
				store.dispatch(actions.setUsers({ loading: false }));
				store.dispatch(actions.setCommonError(err));
			});
	}
};

const resendInvitation = (store, action) => {
	if (action.type === actions.RESEND_INVITATION) {
		const { email, clientUuid, accountName } = action.payload;

		store.dispatch(actions.setInvitationStatus(email, 'Resending'));

		services.accountService
			.resendInvitation(email, clientUuid, accountName)
			.then(res => {
				store.dispatch(actions.setUsers({ success: true }));
				store.dispatch(actions.setInvitationStatus(email, 'Resent!'));
				toastr.success('Invitation Resent!');
				setTimeout(() => store.dispatch(actions.setInvitationStatus(email, null)), 5000);
			})
			.catch(err => {
				store.dispatch(actions.setUsers({ error: true }));
				store.dispatch(actions.setInvitationStatus(email, 'Error'));
				toastr.error('Error Sending Invitation');
				setTimeout(() => store.dispatch(actions.setInvitationStatus(email, null)), 5000);
			});
	}
};

const fetchAccountUsage = (store, action) => {
	if (action.type === actions.FETCH_ACCOUNT_USAGE) {
		store.dispatch(actions.setAccountUsage({}));
		services.accountService
			.fetchAccountUsage()
			.then(res => {
				store.dispatch(
					actions.setAccountUsage({
						accountUsage: res.data,
						success: true,
					}),
				);
			})
			.catch(err => {
				store.dispatch(actions.setAccountUsage({ err }));
			});
	}
};

const createAccount = (store, action) => {
	if (action.type === actions.CREATE_ACCOUNT) {
		const settings = {
			address: action.payload.data.address || '',
			addressTwo: action.payload.data.addressTwo || '',
			city: action.payload.data.city || '',
			zip: action.payload.data.zip || '',
			state: action.payload.data.state || '',
			country: action.payload.data.country || '',
			currency: action.payload.data.currency,
			billingEmail: action.payload.data.billingEmail,
			// TODO: figure out better way to add planType
			planType: 'enterprise',
		};

		const { name } = action.payload.data;

		store.dispatch(actions.setCreateAccountModal({ loading: true }));

		const parsedData = { name, settings };
		services.accountService
			.createAccount(parsedData)
			.then(res => {
				toastr.success('Account Created!');
				store.dispatch(actions.setCreateAccountModal({ loading: false, success: true }));
				setTimeout(() => {
					store.dispatch(
						actions.setCreateAccountModal({
							visible: false,
							success: false,
						}),
					);
					store.dispatch(actions.fetchAccounts());
				}, 1000);
			})
			.catch(err => {
				toastr.error('Error Creating Account');
			});
	}
};

const fetchAccounts = (store, action) => {
	if (action.type === actions.FETCH_ACCOUNTS) {
		services.accountService
			.fetchAccounts()
			.then(({ data }) => {
				store.dispatch(actions.setAccounts({ content: data }));
			})
			.catch(err => {
				store.dispatch(actions.setAccounts({ error: true }));
			});
	}
};

const removeAccess = (store, action) => {
	if (action.type === actions.REMOVE_ACCESS) {
		const { clientUuid, userUuid } = action.payload;

		services.accountService
			.removeAccess(clientUuid, `user:${userUuid}`)
			.then(res => {
				// Manipulate local redux state to avoid refetching
				const currentUsers = selectors.getUsers(store.getState()).content;
				let currentOffset = selectors.getUsers(store.getState()).offset;

				const foundUserIndex = currentUsers.findIndex(user => user.uuid === userUuid);
				const foundUser = currentUsers[foundUserIndex];

				const foundClientIndex = foundUser.clients.findIndex(client => client.uuid === clientUuid);
				foundUser.clients.splice(foundClientIndex, 1);

				if (foundUser.clients.length > 0) {
					// had more than 1 space, update the user.
					currentUsers[foundUserIndex] = foundUser;
				} else {
					// The user has no more spaces, remove them from the list and decrease pagination offset
					currentUsers.splice(foundUserIndex, 1);
					currentOffset -= 1;
				}
				store.dispatch(
					actions.setUsers({
						content: currentUsers,
						offset: currentOffset,
						loading: false,
					}),
				);

				// store.dispatch(actions.fetchUsers());
				store.dispatch(actions.fetchAccountUsage());
			})
			.catch(err => {
				store.dispatch(actions.setUsers({ error: true }));
			});
	}
};

/* 
	Chargify API specific effects: 
	fetchChargifySubscriptions: calls API -> billing/subscription
	activateChargifySubscription: calls API -> billing/suscription/activate
	cancelChargifySubscription: calls API -> billing/suscription/cancel
	reactivateChargifySubscription: calls API -> billing/suscription/reactivate
	fetchChargifyInvoices: calls API -> billing/invoice
	fetchChargifyUsage: calls API -> billing/invoice
	fetchAccountAudiences: calls API -> billing/audiences
	fetchAccountBillingPeriods: calls API -> billing/periods
*/
const fetchChargifySubscriptions = (store, action) => {
	if (action.type === actions.FETCH_CHARGIFY_SUBSCRIPTIONS) {
		store.dispatch(actions.setChargifySubscriptions({}));
		services.accountService
			.fetchChargifySubscriptions()
			.then(res => {
				store.dispatch(actions.setChargifySubscriptions(res.data));
			})
			.catch(err => {
				store.dispatch(actions.setChargifySubscriptions({ err }));
			});
	}
};

const fetchChargifyInvoices = (store, action) => {
	if (action.type === actions.FETCH_CHARGIFY_INVOICES) {
		const { year } = action.payload;
		store.dispatch(actions.setChargifyInvoices({}));
		services.accountService
			.fetchChargifyInvoices(year)
			.then(res => {
				store.dispatch(actions.setChargifyInvoices(res.data));
			})
			.catch(err => {
				store.dispatch(actions.setChargifyInvoices({ err }));
			});
	}
};

const activateChargifySubscription = (store, action) => {
	if (action.type === actions.ACTIVATE_CHARGIFY_SUBSCRIPTION) {
		store.dispatch(actions.setManageSubscriptionModal({ loading: true }));
		services.accountService
			.activateChargifySubscription()
			.then(() => {
				store.dispatch(
					actions.setManageSubscriptionModal({
						loading: false,
						success: true,
					}),
				);

				services.accountService.fetchChargifySubscriptions().then(res => {
					store.dispatch(actions.setChargifySubscriptions(res.data));
				});
			})
			.catch(err => {
				toastr.error('There was an error activating your subscription, please try again later.');
				console.log(err);
				store.dispatch(
					actions.setManageSubscriptionModal({
						loading: false,
					}),
				);
			});
	}
};

const cancelChargifySubscription = (store, action) => {
	if (action.type === actions.CANCEL_CHARGIFY_SUBSCRIPTION) {
		store.dispatch(actions.setManageSubscriptionModal({ loading: true }));
		services.accountService
			.cancelChargifySubscription()
			.then(() => {
				store.dispatch(
					actions.setManageSubscriptionModal({
						loading: false,
						success: true,
					}),
				);

				services.accountService.fetchChargifySubscriptions().then(res => {
					store.dispatch(actions.setChargifySubscriptions(res.data));
				});
			})
			.catch(err => {
				toastr.error('There was an error cancelling your subscription, please try again later.');

				store.dispatch(
					actions.setManageSubscriptionModal({
						loading: false,
					}),
				);
			});
	}
};

const reactivateChargifySubscription = (store, action) => {
	if (action.type === actions.REACTIVATE_CHARGIFY_SUBSCRIPTION) {
		store.dispatch(actions.setManageSubscriptionModal({ loading: true }));
		services.accountService
			.reactivateChargifySubscription()
			.then(() => {
				toastr.success('Subscription Reactivated!');
				store.dispatch(
					actions.setManageSubscriptionModal({
						loading: false,
						success: true,
					}),
				);
				setTimeout(() => {
					store.dispatch(actions.fetchAccount());
					services.accountService.fetchChargifySubscriptions().then(res => {
						store.dispatch(actions.setChargifySubscriptions(res.data));
					});
					store.dispatch(
						actions.setManageSubscriptionModal({
							visible: false,
							success: false,
						}),
					);
				}, 1000);
			})
			.catch(err => {
				toastr.error('There was an error reactivating your subscription, please try again later.');

				store.dispatch(
					actions.setManageSubscriptionModal({
						loading: false,
					}),
				);
			});
	}
};

const fetchChargifyUsage = (store, action) => {
	if (action.type === actions.FETCH_CHARGIFY_USAGE) {
		store.dispatch(actions.setChargifyUsage({}));
		services.accountService
			.fetchChargifyUsage()
			.then(res => {
				store.dispatch(actions.setChargifyUsage(res.data));
			})
			.catch(err => {
				store.dispatch(actions.setChargifyUsage({ err }));
			});
	}
};

const fetchAccountAudiences = (store, action) => {
	if (action.type === actions.FETCH_ACCOUNT_AUDIENCES) {
		const { id } = action.payload;
		store.dispatch(actions.setAccountAudiences({}));
		services.accountService
			.fetchAccountAudiences(id)
			.then(res => {
				store.dispatch(actions.setAccountAudiences(res.data));
			})
			.catch(err => {
				store.dispatch(actions.setAccountAudiences({ err }));
			});
	}
};

const fetchAccountBillingPeriods = (store, action) => {
	if (action.type === actions.FETCH_ACCOUNT_BILLING_PERIODS) {
		store.dispatch(actions.setAccountBillingPeriods([]));
		services.accountService
			.fetchAccountBillingPeriods()
			.then(res => {
				store.dispatch(actions.setAccountBillingPeriods(res.data));
				const billingPeriods = res.data;
				const currentBillingPeriod = maxBy(billingPeriods, 'periodEnd');
				if (currentBillingPeriod) {
					const accountAudiencesAction = {
						type: actions.FETCH_ACCOUNT_AUDIENCES,
						payload: currentBillingPeriod,
					};
					fetchAccountAudiences(store, accountAudiencesAction);
				}
			})
			.catch(err => {
				store.dispatch(actions.setAccountBillingPeriods({ err }));
				store.dispatch(actions.setAccountAudiences({ err }));
			});
	}
};

const sendMobileBlockLink = (store, action) => {
	if (action.type === actions.SEND_MOBILE_BLOCK_EMAIL) {
		services.accountService
			.sendMobileBlockEmail()
			.then(res => {
				toastr.success('Success, please check your inbox');
			})
			.catch(err => {
				toastr.error('There was an error');
			});
	}
};

export default [
	setAccountUuid,
	fetchAccount,
	fetchAccountSubscription,
	fetchAccountInvoices,
	fetchAccountUsage,
	updateAccount,
	updateCreditCard,
	fetchRoles,
	fetchUsers,
	initialFetch,
	addUser,
	createClient,
	// deleteClient,
	changeUserRole,
	deleteUser,
	resendInvitation,
	createAccount,
	fetchAccounts,
	removeAccess,
	giveRoleForNoAccess,
	deleteCreditCard,
	updateDefaultCreditCard,
	addChargifyCreditCard,
	fetchChargifySubscriptions,
	activateChargifySubscription,
	cancelChargifySubscription,
	reactivateChargifySubscription,
	fetchChargifyInvoices,
	fetchChargifyUsage,
	fetchAccountAudiences,
	updateDefaultChargifyCreditCard,
	removeChargifyCreditCard,
	fetchAccountBillingPeriods,
	sendMobileBlockLink,
];
