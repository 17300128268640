import { getAllBlueprintsData } from '../../selectors';

export const getClients = state => getAllBlueprintsData(state).clients;
export const getSearchQuery = state => getAllBlueprintsData(state).searchQuery;
export const getBlueprint = state => getAllBlueprintsData(state).blueprint;
export const getBlueprints = state => getAllBlueprintsData(state).blueprints;
export const getBlueprintFilters = state => getAllBlueprintsData(state).blueprintFilters;
export const getError = state => getAllBlueprintsData(state).error;
export const getStudy = state => getAllBlueprintsData(state).study;
export const getSections = state => getAllBlueprintsData(state).sections;
export const getLanguages = state => getAllBlueprintsData(state).languages;
export const getGeographies = state => getAllBlueprintsData(state).geographies;
export const getLoading = state => getAllBlueprintsData(state).loading;
export const getClientId = state => getAllBlueprintsData(state).clientId;
export const getAccounts = state => getAllBlueprintsData(state).accounts;
