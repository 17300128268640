const prefix = 'checkout';

/* State setters */

export const SET_CLIENT_ID = `${prefix}/SET_CLIENT_ID`;
export const setClientId = clientId => ({
	type: SET_CLIENT_ID,
	payload: { clientId },
});

export const SET_AUDIENCES = `${prefix}/SET_AUDIENCES`;
export const setAudiences = audiences => ({
	type: SET_AUDIENCES,
	payload: { audiences },
});

export const SET_AUDIENCES_AVAILABLE_FOR_CHECKOUT = `${prefix}/SET_AUDIENCES_AVAILABLE_FOR_CHECKOUT`;
export const setAudiencesAvailableForCheckout = audiencesAvailableForCheckout => ({
	type: SET_AUDIENCES_AVAILABLE_FOR_CHECKOUT,
	payload: { audiencesAvailableForCheckout },
});

export const SET_PREVIEW_TAX = `${prefix}/SET_PREVIEW_TAX`;
export const setPreviewTax = (previewTax, previousTotalTax) => ({
	type: SET_PREVIEW_TAX,
	payload: { previewTax, previousTotalTax },
});

export const SET_PREVIEW_DATA = `${prefix}/SET_PREVIEW_DATA`;
export const setPreviewData = data => ({
	type: SET_PREVIEW_DATA,
	payload: { data },
});

export const FETCH_AUDIENCES = `${prefix}/FETCH_AUDIENCES`;
export const fetchAudiences = (clientId, studyId, audienceUuid) => ({
	type: FETCH_AUDIENCES,
	payload: { clientId, studyId, audienceUuid },
});

export const LAUNCH_AUDIENCES = `${prefix}/LAUNCH_AUDIENCES`;
export const launchAudiences = (
	accountUuid,
	studyId,
	audienceUuids,
	creditCardId,
	externalBilling,
	projectNumber,
	projectMemo,
	taxes,
	paymentMethod,
	amountPaidByCredits,
	autoAcceptIr,
) => ({
	type: LAUNCH_AUDIENCES,
	payload: {
		accountUuid,
		studyId,
		audienceUuids,
		creditCardId,
		externalBilling,
		projectNumber,
		projectMemo,
		taxes,
		paymentMethod,
		amountPaidByCredits,
		autoAcceptIr,
	},
});

export const UPDATE_ACCOUNT_CREDIT_CARD = `${prefix}/UPDATE_ACCOUNT_CREDIT_CARD`;
export const updateCreditCard = (accountUuid, stripeToken, audienceUuids) => ({
	type: UPDATE_ACCOUNT_CREDIT_CARD,
	payload: { accountUuid, stripeToken, audienceUuids },
});

export const DELETE_CREDIT_CARD = `${prefix}/DELETE_CREDIT_CARD`;
export const deleteCreditCard = (creditCardId, accountUuid) => ({
	type: DELETE_CREDIT_CARD,
	payload: { creditCardId, accountUuid },
});

export const FETCH_ACCOUNT = `${prefix}/FETCH_ACCOUNT`;
export const fetchAccount = (uuid, fetchUsersAndRoles = true) => ({
	type: FETCH_ACCOUNT,
	payload: { uuid, fetchUsersAndRoles },
});

export const BUY_NOW_AND_LAUNCH = `${prefix}/BUY_NOW_AND_LAUNCH`;
export const buyNowAndLaunch = (
	accountUuid,
	creditCardId,
	audienceUuids,
	studyId,
	externalBilling,
	projectNumber,
	projectMemo,
	taxes,
	paymentMethod,
	amountPaidByCredits,
	autoAcceptIr,
) => ({
	type: BUY_NOW_AND_LAUNCH,
	payload: {
		accountUuid,
		creditCardId,
		audienceUuids,
		studyId,
		externalBilling,
		projectNumber,
		projectMemo,
		taxes,
		paymentMethod,
		amountPaidByCredits,
		autoAcceptIr,
	},
});

export const PREVIEW_LAUNCH = `${prefix}/PREVIEW_LAUNCH`;
export const previewLaunch = (creditCardId, audienceUuids, studyId, externalBilling) => ({
	type: PREVIEW_LAUNCH,
	payload: { creditCardId, audienceUuids, studyId, externalBilling },
});

export const SET_INDIVIDUAL_LAUNCH_STATUS = `${prefix}/SET_INDIVIDUAL_LAUNCH_STATUS`;
export const setIndividualLaunchStatus = launchStatus => ({
	type: SET_INDIVIDUAL_LAUNCH_STATUS,
	payload: launchStatus,
});

export const CONFIRM_INCIDENCE_RATE = `${prefix}/CONFIRM_INCIDENCE_RATE`;
export const confirmIncidenceRate = (
	study,
	audienceUuid,
	isNewRateAccepted,
	taxes,
	creditCardId,
	paymentMethod,
	amountPaidByCredits,
) => ({
	type: CONFIRM_INCIDENCE_RATE,
	payload: {
		study,
		audienceUuid,
		isNewRateAccepted,
		taxes,
		creditCardId,
		paymentMethod,
		amountPaidByCredits,
	},
});

export const SET_LAUNCH_AUDIENCE_DATA = `${prefix}/SET_LAUNCH_AUDIENCE_DATA`;
export const setLaunchAudienceData = (clientId, studyId, audienceUuid) => ({
	type: SET_LAUNCH_AUDIENCE_DATA,
	payload: {
		clientId,
		studyId,
		audienceUuid,
	},
});

export const SET_LAUNCH_ALL_AUDIENCES_DATA = `${prefix}/SET_LAUNCH_ALL_AUDIENCES_DATA`;
export const setLaunchAllAudiencesData = (clientId, studyId) => ({
	type: SET_LAUNCH_ALL_AUDIENCES_DATA,
	payload: {
		clientId,
		studyId,
	},
});

export const FETCH_BILLING_BALANCE = `${prefix}/FETCH_BILLING_BALANCE`;
export const fetchBillingBalance = () => ({
	type: FETCH_BILLING_BALANCE,
});
export const SET_BILLING_BALANCE = `${prefix}/SET_BILLING_BALANCE`;
export const setBillingBalance = balance => ({
	type: SET_BILLING_BALANCE,
	payload: { balance },
});

export const SET_LOADING_TAXES = `${prefix}/SET_LOADING_TAXES`;
export const setLoadingTaxes = loadingTaxes => ({
	type: SET_LOADING_TAXES,
	payload: { loadingTaxes },
});
