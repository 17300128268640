import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { audienceService } from 'src/services';
import Loading from 'src/components/elements/Loading';
import { packInterlockQuotas } from 'src/utilities/interlock-quotas';
import {
	DimensionDropdown,
	DimensionDropdownItem,
	DimensinoDropdownPane,
} from 'src/domains/manage-study/components/audience/DimensionDropdown';
import InterlockQuotasTable from 'src/domains/manage-study/containers/InterlockQuotasTable';
import AudienceResponses from 'src/components/shared/AudienceComponentHeader/AudienceResponses';
import ConfirmationModal from 'src/components/shared/ConfirmationModal';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import AMPHeaderError from 'src/components/shared/AMPHeaderError';
import * as misc from 'src/utilities/misc';
import './styles.scss';

const className = 'interlock-quotas-container';
const el = (name, mod) => cn(className, name, mod);
const MIN_QUOTA = 3;

const InterlockQuotasContainer = ({
	studyId,
	groupStatus,
	audienceId,
	collectionId,
	demographicQuestions,
	screeningQuestions,
	groupUuid,
	setInterlockQuotasData,
	loading,
	setLoading,
	setErrors,
	selectedLanguage,
	setHasChanged,
	currentSample = 0,
	sampleSize = 0,
}) => {
	const deleteQuotaModalRef = useRef();
	const softQuotasModalRef = useRef();
	const [selectedQuotas, setSelectedQuotas] = useState([]);
	const [interlockQuotasSchema, setInterlockQuotasSchema] = useState([]);
	const [softenQuotaIds, setSoftenQuotaIds] = useState([]);
	const [includeStatistics, setIncludeStatistics] = useState(groupStatus !== 'ready');
	const getOptionLabelTranslation = useCallback(
		({ translations }) => {
			if (!translations?.length) return '';
			const currentTranslation = translations?.find(translation => translation.languageCode === selectedLanguage);
			if (currentTranslation?.label) return currentTranslation?.label;
			const defaultTranslation = translations?.find(translation => translation.languageCode);
			return defaultTranslation?.label;
		},
		[selectedLanguage],
	);

	const getQuestionLabelTranslation = useCallback(
		({ label, translations }) => {
			if (!translations?.length) return misc.cleanHtmlString(label);
			const currentTranslation = translations.find(translation => translation.languageCode === selectedLanguage);
			if (currentTranslation?.label) return misc.cleanHtmlString(currentTranslation.label);
			const defaultTranslation = translations.find(translation => translation.languageCode);
			return misc.cleanHtmlString(defaultTranslation.label);
		},
		[selectedLanguage],
	);

	const hasMissingTranslation = useCallback(
		questionId => {
			const currentQuestion = quotas.find(({ id }) => id === questionId);
			const questionTranslation = currentQuestion?.translations?.find(
				({ languageCode }) => languageCode === selectedLanguage,
			);
			if (!questionTranslation) return true;
			const optionsWithMissingTranslation = currentQuestion?.options?.filter(option => {
				const translatedLanguages = option?.translations?.map(({ languageCode }) => languageCode);
				return translatedLanguages?.includes(selectedLanguage);
			});

			return !optionsWithMissingTranslation?.length;
		},
		[quotas, selectedLanguage],
	);

	const hasMissingOptionLabelTranslation = useCallback(
		({ optionId, audienceQuestionOptionId }) => {
			const currentOption = quotas
				.map(({ options }) => options)
				.flat()
				.find(o => {
					const isEqual = compareQuotaId(
						{ optionId: o.id, audienceQuestionOptionId: o.audienceQuestionOptionId },
						{ optionId, audienceQuestionOptionId },
					);
					return isEqual;
				});

			const translation = currentOption?.translations?.find(
				({ languageCode }) => languageCode === selectedLanguage,
			);
			return !translation;
		},
		[quotas, selectedLanguage],
	);

	const quotas = useMemo(() => {
		if (!demographicQuestions?.length && !screeningQuestions?.length) return [];
		const screeningQuestionsQuotas =
			screeningQuestions
				?.filter(({ useQuota }) => useQuota)
				?.map(question => {
					const optionsIdsInLogic = question.logic
						.map(l => l.triggerOptions.map(trigger => trigger?.id))
						.flat();
					const optionsWithoutLogic = question?.options?.filter(
						option => !optionsIdsInLogic.includes(option.id),
					);
					const options = optionsWithoutLogic?.map(option => ({
						...option,
						quota: option?.quotaMax == null ? null : option.quotaMax,
						label: getOptionLabelTranslation(option),
					}));

					return {
						...question,
						isScreening: true,
						options,
						label: getQuestionLabelTranslation(question),
					};
				}) || [];

		const demographicQuestionsQuotas =
			demographicQuestions
				?.filter(({ useQuota }) => useQuota)
				?.map(question => {
					const options = question.options
						.filter(option => option.isSelected)
						.map(option => ({
							...option,
							quota: option.quota?.[0]?.quotaMax == null ? null : option.quota?.[0]?.quotaMax,
						}));

					return {
						...question,
						options,
					};
				}) || [];

		return [...demographicQuestionsQuotas, ...screeningQuestionsQuotas];
	}, [demographicQuestions, getOptionLabelTranslation, getQuestionLabelTranslation, screeningQuestions]);

	const onChangeQuotaSchema = useCallback(
		schema => {
			const optionQuotaMap = new Map();
			schema.quotas?.forEach(({ ids, quota }) => {
				ids.forEach((item, i) => {
					optionQuotaMap.set(item.optionId || item.audienceQuestionOptionId, quota[i]);
				});
			});
			const selectedQuotasIds = selectedQuotas?.map(({ id }) => id);
			const data = quotas?.map(({ id: questionId, audienceQuestionId, isScreening, options }) => ({
				isInterlocked: selectedQuotasIds?.includes(questionId),
				resetInternalStatus: softenQuotaIds?.includes(questionId),
				isScreening,
				questionId,
				audienceQuestionId: audienceQuestionId || null,
				audienceId,
				collectionId,
				quotas: options.map(option => {
					let { quota } = option;
					if (selectedQuotasIds?.includes(questionId)) {
						quota = optionQuotaMap.get(option.id || option.audienceQuestionOptionId);
					}
					return {
						optionId: option.id || null,
						audienceQuestionOptionId: option.audienceQuestionOptionId || null,
						quota,
					};
				}),
			}));

			setInterlockQuotasData(data);
		},
		[audienceId, collectionId, quotas, selectedQuotas, setInterlockQuotasData, softenQuotaIds],
	);

	useEffect(() => {
		onChangeQuotaSchema(interlockQuotasSchema);
	}, [interlockQuotasSchema, onChangeQuotaSchema]);

	const getSelectedQuotasFromShema = useCallback(
		schema => {
			if (!quotas?.length || !schema?.length) return;
			const schemaOptionIds = schema
				.map(({ ids }) => ids)
				.flat()
				.map(
					({ optionId, audienceQuestionOptionId }) =>
						`id-${optionId ?? ''}|aId-${audienceQuestionOptionId ?? ''}`,
				);

			const filteredschemaOptionIds = [...new Set(schemaOptionIds)];
			const questions = quotas
				.filter(({ options }) => {
					const hasSelectedOption = options.find(({ id, audienceQuestionOptionId }) =>
						filteredschemaOptionIds.includes(`id-${id ?? ''}|aId-${audienceQuestionOptionId ?? ''}`),
					);
					return hasSelectedOption;
				})
				.map(question => ({
					...question,
					value: question.id,
					isInterlocked: true,
				}));

			return questions;
		},
		[quotas],
	);

	const getInterlockQuotas = useCallback(async () => {
		setLoading(true);
		const response = await audienceService.getQuotaInterlock({
			studyId,
			groupUuid,
			includeStatistics,
		});
		const selectedQuotasFromSchema = getSelectedQuotasFromShema(response.data.quotas);
		setSelectedQuotas(selectedQuotasFromSchema);
		setInterlockQuotasSchema(response.data);
		setLoading(false);
	}, [setLoading, studyId, groupUuid, includeStatistics, getSelectedQuotasFromShema]);

	useEffect(() => {
		getInterlockQuotas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const compareQuotaId = (quotaA, quotaB) => {
		const quotaAHash = `id-${quotaA.optionId ?? ''}|aId-${quotaA.audienceQuestionOptionId ?? ''}`;
		const quotaBHash = `id-${quotaB.optionId ?? ''}|aId-${quotaB.audienceQuestionOptionId ?? ''}`;
		return quotaAHash === quotaBHash;
	};

	const onChangeSelectedQuotas = selected => {
		const updatedSelect = selected.map(question => ({ ...question, isInterlocked: true }));
		setSelectedQuotas(updatedSelect);
		if (!updatedSelect?.length) {
			return setInterlockQuotasSchema({ quotas: [] });
		}
		updateSchema(updatedSelect);
	};

	const updateSchema = useCallback(
		selected => {
			const orderedQuotas = selected
				.filter(({ isInterlocked }) => isInterlocked)
				.sort((a, b) => a.options.length - b.options.length);

			if (!orderedQuotas?.length) {
				return setInterlockQuotasSchema([]);
			}

			orderedQuotas.forEach(question => {
				question.options = question.options
						.sort((a, b) => a.order - b.order)
						.map((option, index) => ({
							...option,
							order: index,
						}));
			});

			const schema = packInterlockQuotas({
				questions: orderedQuotas,
			});

			setInterlockQuotasSchema(schema);
			setHasChanged(true);
		},
		[setHasChanged],
	);

	const getQuestionLabelByOptionId = useCallback(
		({ optionId, audienceQuestionOptionId }) => {
			if (!selectedQuotas?.length) return '';
			const question = selectedQuotas.find(({ options }) =>
				options.find(o => {
					const isEqual = compareQuotaId(
						{ optionId: o.id, audienceQuestionOptionId: o.audienceQuestionOptionId },
						{ optionId, audienceQuestionOptionId },
					);
					return isEqual;
				}),
			);
			return question?.label || '';
		},
		[selectedQuotas],
	);

	const getQuestionByOptionId = useCallback(
		({ optionId, audienceQuestionOptionId }) => {
			if (!selectedQuotas?.length) return '';
			const question = selectedQuotas.find(({ options }) =>
				options.find(o => {
					const isEqual = compareQuotaId(
						{ optionId: o.id, audienceQuestionOptionId: o.audienceQuestionOptionId },
						{ optionId, audienceQuestionOptionId },
					);
					return isEqual;
				}),
			);
			return question;
		},
		[selectedQuotas],
	);

	const getQuestionIdByOptionId = useCallback(
		({ optionId, audienceQuestionOptionId }) => {
			if (!selectedQuotas?.length) return '';
			const question = selectedQuotas.find(({ options }) =>
				options.find(o => {
					const isEqual = compareQuotaId(
						{ optionId: o.id, audienceQuestionOptionId: o.audienceQuestionOptionId },
						{ optionId, audienceQuestionOptionId },
					);
					return isEqual;
				}),
			);
			return question?.id;
		},
		[selectedQuotas],
	);

	const getOptionLabelById = useCallback(
		({ optionId, audienceQuestionOptionId }) => {
			if (!selectedQuotas?.length) return '';

			const option = selectedQuotas
				.map(({ options }) => options)
				.flat()
				.find(o => {
					const isEqual = compareQuotaId(
						{ optionId: o.id, audienceQuestionOptionId: o.audienceQuestionOptionId },
						{ optionId, audienceQuestionOptionId },
					);
					return isEqual;
				});
			return option?.label;
		},
		[selectedQuotas],
	);

	const totalQuotas = useMemo(() => {
		if (loading) return [];
		if (!interlockQuotasSchema?.quotas?.length || !selectedQuotas?.length) return [];

		const calculatedQuotas = interlockQuotasSchema.quotas?.map(item => {
			const hasNullQuota = item.quota.includes(null);
			const reportingData = {
				quotaCompletes: item?.quotaCompletes,
			};

			if (hasNullQuota) {
				const nullQuotasWithIndex = item.quota
					.map((quota, index) => ({ quota, index }))
					.filter(({ quota }) => quota === null)
					.map(({ index }) => ({ index, optionId: item.ids[index] }));

				const nullQuotasWithQuestion = nullQuotasWithIndex.map(itemQuota => {
					const question = getQuestionByOptionId(itemQuota.optionId);
					const questionQuotaSum = question?.options
						?.map(option => option?.quota)
						?.reduce((acc, curr) => acc + curr, 0);
					const maxQuotaPossible = 100 - (questionQuotaSum >= 100 ? 100 : questionQuotaSum);
					return {
						...itemQuota,
						maxQuotaPossible,
					};
				});

				const quotasWithMaxPossible = item.quota.map((quota, i) => {
					const nullQuotaFound = nullQuotasWithQuestion.find(({ index }) => index === i);
					if (nullQuotaFound) {
						return nullQuotaFound.maxQuotaPossible;
					}
					return quota;
				});
				const cumulativeMax = quotasWithMaxPossible.reduce((acc, curr) => acc * curr, 1);

				const percentageMax = cumulativeMax / 100 ** (item.quota.length - 1);
				return { ids: item.ids, percentageMax, ...reportingData };
			}

			const cumulative = item.quota.filter(q => q).reduce((acc, curr) => acc * curr, 1);
			const percentage = cumulative / 100 ** (item.quota.length - 1);
			return { ids: item.ids, percentage, ...reportingData };
		});

		return calculatedQuotas;
	}, [getQuestionByOptionId, interlockQuotasSchema.quotas, loading, selectedQuotas]);

	const hasQuotasBelowMinimum = useMemo(() => {
		if (!totalQuotas?.length) return;
		const quotasBelowMinimum = totalQuotas.filter(quota => {
			const quotaValue = quota.percentage || quota.percentageMax;
			if (typeof quotaValue !== 'number') return;
			return quotaValue < MIN_QUOTA;
		});

		return !!quotasBelowMinimum?.length;
	}, [totalQuotas]);

	const hasQuestionWithQuotaSumUnder100 = useMemo(() => {
		if (!selectedQuotas?.length) return;
		const questionWithQuotaSum = selectedQuotas?.map(question => {
			const nullQuotas = question?.options.map(option => option.quota).filter(quota => quota === null);
			if (nullQuotas?.length) return { ...question, questionQuotaSum: 100 };

			const questionQuotaSum = question?.options
				?.map(option => option?.quota)
				?.reduce((acc, curr) => acc + curr, 0);
			return { ...question, questionQuotaSum: Math.ceil(questionQuotaSum) };
		});

		const questionWithQuotaSumUnder100 = questionWithQuotaSum?.filter(
			({ questionQuotaSum }) => questionQuotaSum < 100,
		);
		return !!questionWithQuotaSumUnder100?.length;
	}, [selectedQuotas]);

	const hasIndividualInvalidQuotas = useMemo(() => {
		if (!selectedQuotas?.length) return;
		for (const question of selectedQuotas) {
			const isInvalid = question?.options?.some(option => option?.quota > 100);
			if (isInvalid) {
				return true;
			}
		}
		return false;
	}, [selectedQuotas]);

	const emptyQuotas = useMemo(() => {
		if (interlockQuotasSchema?.quotas?.length) return;
		const interlockedQuotas = quotas?.filter(question => {
			if ('interlockedWithinGroup' in question) {
				return (
					question?.interlockedWithinGroup?.find(group => group.uuid === groupUuid) && question?.isInterlocked
				);
			}
			return question?.isInterlocked;
		});

		return groupStatus !== 'ready' && !interlockedQuotas?.length;
	}, [groupStatus, groupUuid, interlockQuotasSchema?.quotas?.length, quotas]);

	useEffect(
		() =>
			setErrors([
				{ emptyQuotas },
				{ hasQuotasBelowMinimum },
				{ hasQuestionWithQuotaSumUnder100 },
				{ hasIndividualInvalidQuotas },
			]),
		[
			hasIndividualInvalidQuotas,
			hasQuestionWithQuotaSumUnder100,
			selectedQuotas,
			emptyQuotas,
			totalQuotas,
			setErrors,
			hasQuotasBelowMinimum,
		],
	);

	const onRemoveColumn = useCallback(
		questionId => {
			const remainingQuotas = selectedQuotas.filter(question => question.id !== questionId);
			setSelectedQuotas(remainingQuotas);
			updateSchema(remainingQuotas);
			if (includeStatistics) {
				setIncludeStatistics(prev => !prev);
			}
			if (
				includeStatistics &&
				interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove?.id === questionId
			) {
				setSoftenQuotaIds(prev => [...prev, questionId]);
			}
		},
		[
			includeStatistics,
			interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove?.id,
			selectedQuotas,
			updateSchema,
		],
	);

	const renderEmptyState = () => (
		<div className={el('empty-state')}>
			{groupStatus !== 'ready' ? (
				<span>No interlocked quotas found</span>
			) : (
				<span>Click on 'add dimensions' to to interlock your quotas</span>
			)}
		</div>
	);

	const renderContent = () => {
		if (!interlockQuotasSchema?.quotas?.length) {
			return renderEmptyState();
		}

		return (
			<InterlockQuotasTable
				includeStatistics={includeStatistics}
				hasError={hasQuotasBelowMinimum}
				quotas={quotas}
				totalQuotas={totalQuotas}
				interlockQuotasSchema={interlockQuotasSchema.quotas}
				slowQuotaRemoveId={interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove?.id}
				softenQuotaIds={softenQuotaIds}
				setSoftenQuotaIds={setSoftenQuotaIds}
				setInterlockQuotasSchema={setInterlockQuotasSchema}
				setHasChanged={setHasChanged}
				getQuestionLabelByOptionId={getQuestionLabelByOptionId}
				getQuestionIdByOptionId={getQuestionIdByOptionId}
				getOptionLabelById={getOptionLabelById}
				setSelectedQuotas={setSelectedQuotas}
				onRemoveColumn={onRemoveColumn}
				sampleSize={sampleSize}
				currentSample={currentSample}
				hasMissingTranslation={hasMissingTranslation}
				hasMissingOptionLabelTranslation={hasMissingOptionLabelTranslation}
			/>
		);
	};

	const onSoftenQuotas = async () => {
		const softenQuotas = await softQuotasModalRef.current.onShow();
		if (!softenQuotas) return;
		const questionId = interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove?.id;
		const question = selectedQuotas.find(({ id }) => id === questionId);
		onRemoveColumn(question.id);
		setSoftenQuotaIds(prev => [...prev, questionId]);
	};

	const quotasToBeRemoved = useMemo(() => {
		if (!interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove) return [];
		return selectedQuotas
			.filter(({ id }) => id === interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove?.id)
			.map(({ label }) => label);
	}, [interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove, selectedQuotas]);

	const quotasNotToBeRemoved = useMemo(() => {
		if (!interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove) return [];
		return selectedQuotas
			.filter(({ id }) => id !== interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove?.id)
			.map(({ label }) => label);
	}, [interlockQuotasSchema?.warningSlowQuota?.suggestedQuestionToRemove, selectedQuotas]);

	return (
		<div className={className}>
			{interlockQuotasSchema?.warningLowQuota ? (
				<AMPHeaderError
					icon="error"
					errorTitle={<b>Some of your quotas are too low to complete</b>}
					errorMessage={<div className={el('error-desc-container')}>Adjust your quotas</div>}
				/>
			) : null}

			{interlockQuotasSchema?.warningSlowQuota ? (
				<AMPHeaderError
					icon="error"
					errorTitle={
						<b>Your study is going slow. To speed up your study, we recommend softening your quotas!</b>
					}
					errorMessage={
						<div className={el('error-desc-container')}>
							<ul>
								<li>
									For interlocked quotas, you can edit them by going to your interlocked quotas page
								</li>
								<li>
									For non-interlocked quotas, you can edit them below Note that you can only increase
								</li>
							</ul>
							<span>the quota value and you cannot undo these changes.</span>
						</div>
					}
					buttonIcon="magic_wand"
					buttonText="Soften Quotas"
					buttonLogic={onSoftenQuotas}
				/>
			) : null}

			<div className={el('header')}>
				<div className={el('title')}>
					<h1>Interlock quotas</h1>
					<div className={el('description')}>
						<p>Click on a cell to change the quota value</p>
					</div>
				</div>
				<div className={el('info')}>
					{groupStatus !== 'ready' ? (
						<AudienceResponses
							viewMode
							mode="group"
							sampleSize={sampleSize}
							currentSample={currentSample}
							totalSample={sampleSize || '∞'}
							isDraft={groupStatus === 'ready'}
							hasLiveCircle={groupStatus === 'live'}
							isLive={groupStatus !== 'ready'}
							percentage={Math.floor(currentSample ? (currentSample / sampleSize) * 100 : 0)}
						/>
					) : (
						<DimensionDropdown
							maxLength={4}
							label="Add Dimensions"
							onChange={onChangeSelectedQuotas}
							value={selectedQuotas?.filter(({ isInterlocked }) => isInterlocked)}
						>
							<div className={el('dropdown-header')}>
								<h5>Add Quota Dimensions</h5>
								<span>You have a limit of 4 dimensions</span>
							</div>
							{quotas?.filter(quota => !quota.interlockedWithinGroup)?.length ? (
								<DimensinoDropdownPane label="Demographics">
									{quotas
										?.filter(quota => !quota.interlockedWithinGroup)
										?.map(quota => (
											<DimensionDropdownItem
												item={quota}
												key={quota.id}
												isSelected={selectedQuotas?.some(e => e?.id === quota?.id)}
											/>
										))}
								</DimensinoDropdownPane>
							) : null}
							{quotas?.filter(quota => quota.interlockedWithinGroup)?.length ? (
								<DimensinoDropdownPane label="Screening Questions">
									{quotas
										?.filter(quota => quota.interlockedWithinGroup)
										?.map(quota => (
											<DimensionDropdownItem
												item={quota}
												key={quota.id}
												isSelected={selectedQuotas?.some(e => e?.id === quota?.id)}
											/>
										))}
								</DimensinoDropdownPane>
							) : null}
						</DimensionDropdown>
					)}
				</div>
			</div>
			{loading && !interlockQuotasSchema?.length ? (
				<div className={el('loader')}>
					<Loading />
				</div>
			) : (
				renderContent()
			)}
			<ConfirmationModal ref={deleteQuotaModalRef}>
				<AreYouSureForm
					headerText="Delete quota?"
					bodyText="Your changes related to this quota will be lost."
					cancelText="Cancel"
					confirmText="Proceed"
					onCancel={() => deleteQuotaModalRef.current?.onCancel()}
					onConfirm={() => deleteQuotaModalRef.current?.onConfirm()}
					headerIcon={{
						icon: 'warning',
						size: 'large',
						color: 'red',
						className: el('warning-icon'),
					}}
				/>
			</ConfirmationModal>
			<ConfirmationModal ref={softQuotasModalRef}>
				<AreYouSureForm
					headerText="Softening Quotas"
					customClass={el('soft-quotas')}
					bodyText={
						<div>
							<p>You are about to soften your quotas, this action cannot be undone.</p>
							<span>The following question will be removed from interlocking: </span>
							<ul>
								{quotasToBeRemoved?.map(text => (
									<li key={text}>{text}</li>
								))}
							</ul>

							<span>Your interlocked quotas will be retained as: </span>
							<ul>
								{quotasNotToBeRemoved?.map(text => (
									<li key={text}>{text}</li>
								))}
							</ul>
						</div>
					}
					cancelText="Cancel"
					confirmText="Proceed"
					confirmType="Success"
					onCancel={() => softQuotasModalRef.current?.onCancel()}
					onConfirm={() => softQuotasModalRef.current?.onConfirm()}
				/>
			</ConfirmationModal>
		</div>
	);
};

InterlockQuotasContainer.propTypes = {
	studyId: PropTypes.number,
	groupStatus: PropTypes.string,
	groupUuid: PropTypes.string,
	currentSample: PropTypes.number,
	sampleSize: PropTypes.number,
	audienceId: PropTypes.number,
	collectionId: PropTypes.number,
	demographicQuestions: PropTypes.array,
	screeningQuestions: PropTypes.array,
	setInterlockQuotasData: PropTypes.func,
	setErrors: PropTypes.func,
	loading: PropTypes.bool,
	setLoading: PropTypes.func,
	selectedLanguage: PropTypes.string,
	setHasChanged: PropTypes.func,
};

export default InterlockQuotasContainer;
