import debounce from 'lodash/debounce';
import toastr from 'toastr';
import * as services from 'src/services';
import axios from 'src/utilities/axios';
import Cookies from 'src/utilities/cookies';
import { TRACKING_ACTIONS, trackingEvent } from 'src/utilities/marketing';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import * as accountActions from 'src/domains/account/actions';
import * as actions from '../actions';
import * as studyActions from '../../manage-study/actions';
import * as studySelectors from '../../manage-study/selectors';
import * as selectors from '../selectors';
import { setChargifySubscriptions } from '../../account/actions';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};

const updateCreditCard = (store, action) => {
	if (action.type === actions.UPDATE_ACCOUNT_CREDIT_CARD) {
		const { accountUuid, stripeToken, audienceUuids } = action.payload;
		services.accountService
			.updateCreditCard(stripeToken)
			.then(({ data }) => {
				const { id: creditCardId } = data;
				toastr.success('Credit card saved!');

				setTimeout(() => {
					store.dispatch(accountActions.fetchAccount(accountUuid, false));
					store.dispatch(actions.buyNowAndLaunch(accountUuid, creditCardId, audienceUuids));
					services.accountService.fetchChargifySubscriptions().then(res => {
						store.dispatch(setChargifySubscriptions(res.data));
					});
				}, 1000);
			})
			.catch(error => {
				toastr.error('Error saving credit card');
			});
	}
};

const deleteCreditCard = (store, action) => {
	if (action.type === actions.DELETE_CREDIT_CARD) {
		const { accountUuid, creditCardId } = action.payload;
		if (creditCardId) {
			services.accountService.deleteCreditCard(creditCardId).then(({ data }) => {
				setTimeout(() => {
					store.dispatch(accountActions.fetchAccount(accountUuid, false));
				}, 1000);
			});
		}
	}
};

const fetchAudiences = (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCES) {
		const { clientId, studyId, audienceUuid } = payload;
		axios.setClientId(clientId || Cookies.getClientId()); // * Need this getClientId fallback here to prevent being logged out on fetch audiences
		const storedStudyId = studyId || Cookies.getStudyId();
		services.checkoutService.getAudiences(storedStudyId).then(({ data }) => {
			const filteredAudiences = data.filter(a => a.provider.toLowerCase() !== 'byo' && a.uuid);
			if (audienceUuid) {
				const singleAudience = filteredAudiences.filter(a => a.uuid === audienceUuid);
				store.dispatch(actions.setAudiences(singleAudience));
			} else {
				store.dispatch(actions.setAudiences(filteredAudiences));
			}
		});
	}
};

const buyNowAndLaunch = (store, action) => {
	if (action.type === actions.BUY_NOW_AND_LAUNCH) {
		const {
			accountUuid,
			audienceUuids,
			creditCardId,
			studyId,
			externalBilling,
			projectNumber,
			projectMemo,
			taxes,
			paymentMethod,
			amountPaidByCredits,
			autoAcceptIr,
		} = action.payload;
		const storedStudyId = studyId || Cookies.getStudyId();
		store.dispatch(
			actions.launchAudiences(
				accountUuid,
				storedStudyId,
				audienceUuids,
				creditCardId,
				externalBilling,
				projectNumber,
				projectMemo,
				taxes,
				paymentMethod,
				amountPaidByCredits,
				autoAcceptIr,
			),
		);
	}
};

const launchAudiences = async (store, { type, payload }) => {
	if (type === actions.LAUNCH_AUDIENCES) {
		const {
			accountUuid,
			studyId,
			audienceUuids,
			creditCardId,
			externalBilling,
			projectNumber,
			projectMemo,
			taxes,
			paymentMethod,
			amountPaidByCredits,
			autoAcceptIr,
		} = payload;
		const storedStudyId = studyId || Cookies.getStudyId();
		const previewData = selectors.getPreviewData(store.getState());
		const audienceCollection = studySelectors.getAudienceCollection(store.getState());
		const audienceCollectionTemplateApplied = studySelectors.getAudienceCollectionTemplateApplied(store.getState());
		const studyTemplateApplied = studySelectors.getStudyTemplateApplied(store.getState());
		const currentAudiences = audienceCollection?.content?.demographicGroups;
		let updatedAudiencesContent = currentAudiences;

		let totalCreditUsed = amountPaidByCredits;
		for (let i = 0; i < audienceUuids.length; i += 1) {
			// Calculate per audience credits
			const audienceTotal = previewData?.[audienceUuids[i]]?.total_in_cents || 0;

			let audienceCreditsNeeded = 0;
			if (totalCreditUsed > 0) {
				if (audienceTotal >= totalCreditUsed) {
					audienceCreditsNeeded = totalCreditUsed;
					totalCreditUsed = 0;
				} else {
					audienceCreditsNeeded = audienceTotal;
					totalCreditUsed -= audienceTotal;
				}
			}

			let finalTaxes = taxes;
			if (previewData?.[audienceUuids[i]]?.total_tax_in_cents) {
				finalTaxes = previewData[audienceUuids[i]].total_tax_in_cents / 100;
			}

			try {
				const resp = await services.studySampleService.launchAudience(
					storedStudyId,
					audienceUuids[i],
					creditCardId,
					externalBilling,
					projectNumber,
					projectMemo,
					finalTaxes,
					paymentMethod,
					audienceCreditsNeeded,
					autoAcceptIr,
				);
				const launchedAudience = currentAudiences?.find(aud => aud?.uuid === audienceUuids[i]);
				const metadata = {
					studyTemplate: studyTemplateApplied?.name || studyTemplateApplied?.id || 'false',
					audienceTemplate:
						audienceCollectionTemplateApplied?.name || audienceCollectionTemplateApplied?.id || 'false',
					demographicTemplateId: launchedAudience?.templateAudienceId || 'false',
					audienceCountry: launchedAudience?.countryLanguage?.countryLabel,
				};
				trackingEvent(TRACKING_ACTIONS.track, { eventName: 'launchAudience', metadata });

				updatedAudiencesContent = updatedAudiencesContent?.map(aud => {
					if (aud.uuid === audienceUuids[i]) {
						return {
							...aud,
							status: 'live',
						};
					}
					return aud;
				});

				store.dispatch(
					actions.setIndividualLaunchStatus({
						uuid: audienceUuids[i],
						status: 'success',
					}),
				);
				if (i + 1 === audienceUuids.length) {
					// set audiences in state

					store.dispatch(
						studyActions.setAudienceCollection({
							...audienceCollection,
							content: {
								...audienceCollection.content,
								demographicGroups: updatedAudiencesContent,
							},
						}),
					);

					store.dispatch(
						studyActions.setAudienceCollections({
							...audienceCollection,
							content: [
								{
									...audienceCollection.content,
									demographicGroups: updatedAudiencesContent,
								},
							],
						}),
					);

					store.dispatch(accountActions.fetchAccount(accountUuid, false));
					setTimeout(() => {
						navigate(-1);
						setTimeout(() => {
							toastr.success(
								audienceUuids.length > 1
									? `${audienceUuids.length} audiences launched!`
									: 'Audience launched!',
							);
						}, 1000);
					}, 0);
				}
			} catch (error) {
				store.dispatch(
					actions.setIndividualLaunchStatus({
						uuid: audienceUuids[i],
						status: 'failed',
					}),
				);
				if (i + 1 === audienceUuids.length) {
					store.dispatch(accountActions.fetchAccount(accountUuid, false));
					setTimeout(() => {
						navigate(-1);
						setTimeout(() => {
							toastr.error("Audience couldn't launch, please try again later.");
						}, 1000);
					}, 0);
				}
			}
		}
	}
};

const previewLaunch = async (store, { type, payload }) => {
	if (type === actions.PREVIEW_LAUNCH) {
		const { studyId, audienceUuids, creditCardId, externalBilling } = payload;
		const storedStudyId = studyId || Cookies.getStudyId();
		let totalTax = 0;
		store.dispatch(actions.setLoadingTaxes(true));
		let previousTotalTax = 0;
		try {
			const previewData = {};
			for (let i = 0; i < audienceUuids.length; i += 1) {
				const resp = await services.studySampleService.previewAudience(
					storedStudyId,
					audienceUuids[i],
					creditCardId,
					externalBilling,
					null,
				);
				if (resp && resp.data && resp.data.total_tax_in_cents && Number(resp.data.total_tax_in_cents) > 0) {
					totalTax += Number(resp.data.total_tax_in_cents) / 100;
					previousTotalTax = +Number(resp.data.total_previous_tax_in_cents) / 100;
				}

				// set all data
				if (resp?.data) {
					previewData[audienceUuids[i]] = resp.data;
				}
				if (i === audienceUuids.length - 1) {
					store.dispatch(actions.setLoadingTaxes(false));
				}
			}
			store.dispatch(actions.setPreviewTax(totalTax, previousTotalTax));
			store.dispatch(actions.setPreviewData(previewData));
		} catch (error) {
			toastr.error(`Error fetching taxes for audience(s) :${error}`);
			console.error(error);
		}
	}
};

const confirmIncidenceRate = async (store, action) => {
	if (action.type === actions.CONFIRM_INCIDENCE_RATE) {
		const { study, audienceUuid, isNewRateAccepted, taxes, creditCardId, paymentMethod, amountPaidByCredits } =
			action.payload;
		const { id: studyId } = study;
		const storedStudyId = studyId || Cookies.getStudyId();
		try {
			const resp = await services.studyService.confirmIncidenceRate(
				storedStudyId,
				audienceUuid,
				isNewRateAccepted,
				taxes,
				creditCardId,
				paymentMethod,
				amountPaidByCredits,
			);
			setTimeout(() => {
				navigate(`/studies/${study?.uuid || study?.id}/audience`);
				setTimeout(() => {
					toastr.success('Your audience has been resolved.');
				}, 1000);
			}, 0);
		} catch (error) {
			navigate(`/studies/${storedStudyId}/`);
			setTimeout(() => {
				toastr.error('There was an error resolving your audience at this time.');
			}, 1000);
		}
	}
};

const fetchBillingBalance = async (store, action) => {
	if (action.type === actions.FETCH_BILLING_BALANCE) {
		try {
			const response = await services.checkoutService.getBillingBalance();
			store.dispatch(actions.setBillingBalance(response.data));
		} catch (error) {
			console.log(error);
			setTimeout(() => {
				toastr.error('There was an error fetching your balance.');
			}, 1000);
		}
	}
};

export default [
	deleteCreditCard,
	updateCreditCard,
	fetchAudiences,
	buyNowAndLaunch,
	launchAudiences,
	previewLaunch,
	confirmIncidenceRate,
	fetchBillingBalance,
];
