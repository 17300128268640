import React, { useState, useEffect } from 'react';
import cn from 'src/utilities/bem-cn';
import DemographicOverview from 'src/domains/manage-study/containers/AudienceTemplateModal/DemographicOverview';
import Demographic from 'src/domains/manage-study/containers/AudienceContainer/Demographic';
import GreenArrowBack from 'public/images/audience/green_arrow_left.svg';
import GrayArrowBack from 'public/images/audience/gray_arrow_right.svg';
import { renderAudienceName } from 'src/utilities/misc';

import PropTypes from 'prop-types';

import './styles.scss';
import AudienceTemplateNavigation from '../../../../../../../domains/manage-study/containers/AudienceTemplateModal/AudienceTemplates/AudienceTemplateNavigation';

const el = (name, mod) => cn(className, name, mod);
const className = 'demographic-overview';

const Overview = ({
	audience,
	currentTab,
	setCurrentTab,
	setTemplateModalCurrentAudience,
	templateModalCurrentAudience,
}) => {
	const [indexGroup, setIndexGroup] = useState(
		templateModalCurrentAudience
			? audience?.demographicGroups?.findIndex(g => g?.uuid === templateModalCurrentAudience)
			: 0,
	);
	const [demographicGroup, setDemographicGroup] = useState(
		templateModalCurrentAudience
			? audience?.demographicGroups?.find(g => g?.uuid === templateModalCurrentAudience)
			: audience?.demographicGroups?.[0],
	);

	useEffect(() => {
		if (audience?.demographicGroups) {
			setDemographicGroup(audience?.demographicGroups[indexGroup] || {});
		}
	}, [audience, indexGroup]);

	return (
		<div className={el('demo-group')}>
			{currentTab === 'overview' ? (
				<>
					<div className={el('demo-group-title-container')}>
						<p className={el('demo-group-title')}>Demographic Groups </p>
					</div>
					<DemographicOverview
						viewMode
						template={audience}
						setDemographicGroup={setDemographicGroup}
						setCurrentTab={setCurrentTab}
						setIndexGroup={setIndexGroup}
						setTemplateModalCurrentAudience={setTemplateModalCurrentAudience}
					/>
				</>
			) : (
				<>
					<div className={el('back_container')} aria-hidden onClick={() => setCurrentTab('overview')}>
						<img src={GreenArrowBack} alt="" />
						<span className={el('back_button')}>Back</span>
						<span className={el('back_demographic')}>Demographic Groups</span>
						<img src={GrayArrowBack} alt="" className={el('gray_arrow')} />
						<span className={el('demographic_name')}>{renderAudienceName(demographicGroup)}</span>
					</div>

					<AudienceTemplateNavigation
						indexGroup={indexGroup}
						setIndexGroup={setIndexGroup}
						template={audience}
						currentTab={currentTab}
						step="preview"
						setTemplateModalCurrentAudience={setTemplateModalCurrentAudience}
						customCss={{ marginLeft: 0, width: '100%' }}
					/>

					<Demographic viewMode demographicGroup={demographicGroup} templatePreviewMode />
				</>
			)}
		</div>
	);
};

Overview.displayName = 'Overview';
Overview.propTypes = {
	audience: PropTypes.any,
	currentTab: PropTypes.string,
	setCurrentTab: PropTypes.func,
	setTemplateModalCurrentAudience: PropTypes.func,
	templateModalCurrentAudience: PropTypes.string,
};

export default Overview;
