import * as actions from '../actions';

const INITIAL_STATE = {
	languages: [],
	loading: false,
	success: false,
	error: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_LANGUAGES:
			return { ...state, languages: action.payload.languages };
		case actions.SET_LOADING:
			return { ...state, loading: action.payload.loading };
		case actions.SET_SUCCESS:
			return { ...state, success: action.payload.success };
		case actions.SET_ERROR:
			return { ...state, error: action.payload.error };
		default:
			return state;
	}
};
