import React, { useRef, useState, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Button from 'src/components/elements/Button';
import useOutsideClick from 'src/hooks/useOutsideClick';
import StyledCheckbox from 'src/components/inputs/StyledCheckbox';
import './styles.scss';

const className = 'dimension-dropdown';
const el = (name, mod) => cn(className, name, mod);

export const DimensionDropdownItem = ({ onSelect, isSelected, item }) => (
	<div className={`${el('item')} ${isSelected ? 'selected' : ''}`} aria-hidden onClick={e => onSelect(e, item)}>
		<StyledCheckbox checked={isSelected} customClasses={el('checkbox')} onChange={e => onSelect(e, item)} />
		<span className={el('label')}>{item.label}</span>
	</div>
);
DimensionDropdownItem.displayName = 'DimensionDropdownItem';
DimensionDropdownItem.propTypes = {
	isSelected: PropTypes.bool,
	item: PropTypes.object,
	onSelect: PropTypes.func,
};

export const DimensinoDropdownPane = ({ label, children, onSelect }) => (
	<div className={el('dropdown-pane')}>
		<h3>{label}</h3>
		{Children.map(children, child => {
			if (typeof child.type === 'string') {
				return child;
			}
			if (child.type.displayName !== 'DimensionDropdownItem') {
				return child;
			}

			return cloneElement(child, {
				onSelect,
			});
		})}
	</div>
);

DimensinoDropdownPane.displayName = 'DimensinoDropdownPane';
DimensinoDropdownPane.propTypes = {
	label: PropTypes.bool,
	onSelect: PropTypes.func,
	children: PropTypes.any,
};

export const DimensionDropdown = ({ onChange, onClose, children, value = [], maxLength = false }) => {
	const ref = useRef(null);
	const [openDropdown, setOpenDropdown] = useState(false);

	const onCloseDropdown = () => {
		if (!openDropdown) return;
		setOpenDropdown(false);
		onClose && onClose();
	};

	useOutsideClick(ref, () => {
		onCloseDropdown();
	});

	const onSelect = (event, item) => {
		event?.preventDefault();
		event?.stopPropagation();
		const isSelected = value?.some(e => e?.id === item?.id);
		if (maxLength && !isSelected && value?.length >= maxLength) return;

		if (isSelected) {
			const newSelected = value.filter(e => e?.id !== item?.id);
			onChange(newSelected);
			return;
		}
		onChange([...value, item]);
	};

	return (
		<div className={className} ref={ref}>
			<Button
				type="text"
				className={el('add-dimension')}
				label="Add Dimensions"
				onClick={() => setOpenDropdown(true)}
			/>
			{openDropdown && (
				<div className={el('dropdown')}>
					<div className={el('container')}>
						{Children.map(children, child => {
							if (typeof child?.type === 'string') {
								return child;
							}
							if (
								child?.type?.displayName !== 'DimensionDropdownItem' &&
								child?.type?.displayName !== 'DimensinoDropdownPane'
							) {
								return child;
							}

							return cloneElement(child, {
								onSelect,
							});
						})}
					</div>
				</div>
			)}
		</div>
	);
};

DimensionDropdown.propTypes = {
	value: PropTypes.array,
	children: PropTypes.any,
	onClose: PropTypes.func,
	onChange: PropTypes.func,
	maxLength: PropTypes.number,
};
