import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'upgrade-tooltip-content';
const el = (name, mod) => cn(className, name, mod);

function UpgradeTooltipContent({ isTrialing }) {
	return (
		<div className={className}>
			<img src="/public/images/sparkles.svg" aria-label="upgrade sparkles" />
			{isTrialing ? (
				<p>
					This is an <span>Enterprise</span> feature. You've got access to it during your free trial.
				</p>
			) : (
				<p>
					This feature is only available in <span>Upsiide Enterprise</span>. Visit your Account Settings to
					book a personalized demo today!
				</p>
			)}
		</div>
	);
}

UpgradeTooltipContent.propTypes = {
	isTrialing: PropTypes.bool,
};

export default UpgradeTooltipContent;
