import React, { useEffect, useMemo, useState, useCallback } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import misc from 'src/utilities/misc';
import QuillMentionUtil from 'src/components/inputs/SimpleRichText/QuillMentionUtil';

import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'template-preview-modal-statement-section';

const StatementSectionPreview = ({ sections, section, selectedLanguage }) => {
	const [statementValue, setStatementValue] = useState(null);

	const statement = useMemo(
		() => (section !== null && section.statements !== null ? section?.statements?.[0] : false),
		[section],
	);
	const statements = useMemo(
		() => (section !== null && section.statements !== null ? section.statements : []),
		[section],
	);
	const asset = useMemo(() => statement?.translations?.[0]?.asset, [statement]);
	const imageAlignment = useMemo(() => {
		const setting = section?.settings?.find(({ label }) => label === 'statement_image_align');
		if (setting?.value === 'left') {
			return 'flex-start';
		}
		return 'center';
	}, [section]);

	const getStatementValue = useCallback(() => {
		let value = '';
		if (statements?.[0]?.translations?.length > 0) {
			statements[0].translations.forEach(translation => {
				if (translation.languageCode === selectedLanguage) {
					// If updated statement has piped question, statement must be updated to remove quill-mention updates and acquire desired payload format or {{questionId:___}}
					if (QuillMentionUtil.isPipedQuestion(translation.text)) {
						const pipableQuestions = QuillMentionUtil.getPipedQuestionsList(null, section, sections);
						value = QuillMentionUtil.filterPipedQuestionLabel(translation.text, pipableQuestions);
					} else {
						value = translation.text;
					}
				}
			});
		}
		return value;
	}, [section, sections, statements, selectedLanguage]);

	useEffect(() => {
		setStatementValue(getStatementValue());
	}, [getStatementValue]);

	return (
		<div className={className}>
			<span className={el('section-title')}>Statement</span>
			<span className={el('section-name')}>{section?.name || 'Statement'}</span>

			{asset &&
				(misc.assetIsVideo(asset) ? (
					<div className={el('video-wrapper')}>
						<ReactPlayer url={asset?.url} width="262px" height="148px" controls />
					</div>
				) : (
					<div className={el('image-wrapper')} style={{ justifyContent: imageAlignment }}>
						<img className={el('statement-asset')} src={asset?.url} alt={asset?.description} />
					</div>
				))}
			{statementValue ? (
				<div className={el('statement-wrapper')} dangerouslySetInnerHTML={{ __html: statementValue }} />
			) : (
				<div className={el('empty-state-wrapper')}>
					<p className={el('empty-title')}>This section is empty</p>
					<p className={el('empty-subtitle')}>
						You can add your own statement once you start using this template.
					</p>
				</div>
			)}
		</div>
	);
};

StatementSectionPreview.displayName = 'StatementSectionPreview';
StatementSectionPreview.propTypes = {
	section: PropTypes.any,
	sections: PropTypes.any,
	selectedLanguage: PropTypes.any,
};

export default StatementSectionPreview;
