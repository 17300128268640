import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import * as authSelectors from 'src/domains/auth/selectors';
import {
	accountIsRemittance,
	accountIsChargify,
	getLicenseSubscriptionDaysUntilRenewal,
} from 'src/domains/account/selectors';
import { useIsBlueprint } from 'src/hooks';
import PropTypes from 'prop-types';
import FAB from 'src/components/elements/FAB';
import Modal from 'src/components/shared/Modal';
import ContactUsThankYou from 'src/components/shared/ContactUsThankYou';
import './index.scss';
import { bem } from 'src/components/helpers';
import { Tooltip } from 'react-tippy';
import { motion } from 'framer-motion';
import { Iconof } from '@upsiide/ui-components';
import Badge from 'src/components/elements/Badge';
import { IN_CONTEXT_ARTICLES, HELP_MENU_LINKS, IN_CONTEXT_TOURS } from 'src/config/HelpMenuConfig';
import _ from 'lodash';
import { matchPath, useLocation } from 'react-router-dom';

const className = 'global-help-menu';

const HelpMenu = ({ loggedIn }) => {
	const isRemittance = useSelector(accountIsRemittance) || false;
	const isChargify = useSelector(accountIsChargify) || false;
	const daysUntilRenewal = useSelector(getLicenseSubscriptionDaysUntilRenewal);

	const location = useLocation();
	const { isBlueprint } = useIsBlueprint();
	const isCreateMode = location.pathname.includes('/create');
	const isAudiences = location.pathname.includes('/audiences');
	const isInterlockedQuotas = location.pathname.includes('/interlock-quotas');
	const isPublicStudiesMatch = matchPath({ path: 'public/studies/*' }, location?.pathname);
	const helpMenuOpenRef = useRef(false);
	const [tipOpen, setTipOpen] = useState(false);
	const [openState, setOpenState] = useState(false);
	const [tourAvailable, setTourAvailable] = useState(null);
	const [unreadMessages, setUnreadMessages] = useState(0);
	const [contactUsModalOpen, setContactUsModalOpen] = useState(false);

	const checkTours = useCallback(history => {
		let articleFound = false;
		let restrictTours = false;
		Object.keys(IN_CONTEXT_TOURS).every(key => {
			const { urlSearch, tourId, idSearch, restrictId } = IN_CONTEXT_TOURS[key];
			if (restrictTours) {
				return false;
			}

			if (restrictId) {
				restrictId.every(restricted => {
					if (document.getElementById(restricted)) {
						restrictTours = true;
						return false;
					}
					return true;
				});
			}
			if (!restrictTours && urlSearch) {
				urlSearch.every(search => {
					const urlSearchMatch =
						window.location.href.match(new RegExp(search)) || location.pathname.match(new RegExp(search));
					if (urlSearchMatch) {
						articleFound = true;
						setTourAvailable(tourId);
						return false;
					}
					return true;
				});
			}

			if (!restrictTours && idSearch) {
				idSearch.every(search => {
					if (document.getElementById(search)) {
						articleFound = true;
						if (!_.isNil(tourId)) {
							setTourAvailable(tourId);
						}
						return false;
					}
					return true;
				});
			}
			if (articleFound) {
				return false;
			}
			return true;
		});
		if (!articleFound) {
			setTourAvailable(null);
		}
	});

	useEffect(() => {
		checkTours();
	}, [checkTours, location]);

	const helpMenuRef = useRef(null);

	let menuHeight = 174;

	// remove space for lack of "chat with sales" item
	if (isRemittance) {
		menuHeight -= 26;
	}

	// add space for added "tour" item
	if (tourAvailable) {
		menuHeight += 26;
	}

	const contentVariants = {
		complete: () => ({
			height: menuHeight,
			width: 200,
			bottom: 48,
			right: 0,
			borderRadius: 10,
			transition: {
				type: 'spring',
				stiffness: 100,
				mass: 0.65,
			},
		}),
		start: {
			height: 0,
			width: 0,
			bottom: 16,
			right: 16,
			borderRadius: 50,
			transition: {
				type: 'spring',
				stiffness: 100,
				mass: 0.65,
			},
		},
	};

	useEffect(() => {
		try {
			window.Intercom('onUnreadCountChange', unreadCount => {
				setUnreadMessages(unreadCount);
			});
		} catch (e) {
			console.error('Intercom onUnreadCountChange Error');
		}
	}, []);

	useEffect(() => {
		if (!loggedIn && window.Intercom) {
			window.Intercom('shutdown');
		}
	}, [loggedIn]);

	const clickHandler = e => {
		if (helpMenuOpenRef.current && !helpMenuRef.current.contains(e.target)) {
			helpMenuOpenRef.current = false;
			setOpenState(helpMenuOpenRef.current);
		}
	};

	const keyPressHandler = e => {
		if (helpMenuOpenRef.current && e && e.key === 'Escape') {
			helpMenuOpenRef.current = false;
			setOpenState(helpMenuOpenRef.current);
		}
	};

	useEffect(() => {
		window.addEventListener('click', clickHandler);
		window.addEventListener('keyup', keyPressHandler);

		return () => {
			window.removeEventListener('scroll', clickHandler);
			window.removeEventListener('keyup', keyPressHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const closeHelpMenu = () => {
		helpMenuOpenRef.current = !helpMenuOpenRef.current;
		setOpenState(helpMenuOpenRef.current);
	};

	const openArticle = () => {
		let articleFound = false;
		Object.keys(IN_CONTEXT_ARTICLES).every(key => {
			const { urlSearch, idSearch, articleId } = IN_CONTEXT_ARTICLES[key];
			if (urlSearch) {
				urlSearch.every(search => {
					if (window.location.href.match(new RegExp(search))) {
						articleFound = true;
						if (!_.isNil(articleId)) {
							window.Intercom('showArticle', articleId);
							closeHelpMenu();
						}
						return false;
					}
					return true;
				});
			}
			if (idSearch) {
				idSearch.every(search => {
					if (document.getElementById(search)) {
						articleFound = true;
						if (!_.isNil(articleId)) {
							window.Intercom('showArticle', articleId);
							closeHelpMenu();
						}
						return false;
					}
					return true;
				});
			}
			if (articleFound) {
				return false;
			}
			return true;
		});
	};

	const startTour = () => {
		if (tourAvailable) {
			window.Intercom('startTour', tourAvailable);
		}
	};

	return (
		(isPublicStudiesMatch || loggedIn) && (
			<>
				<div
					className={`${className}${openState ? ' open' : ''} ${
						isBlueprint && (isCreateMode || isAudiences) && !isInterlockedQuotas ? 'blueprints' : ''
					}`}
					ref={helpMenuRef}
				>
					<motion.div
						className={bem(className, 'menu-container')}
						initial="start"
						animate={openState ? 'complete' : 'start'}
						variants={contentVariants}
					>
						<a
							role="menuitem"
							tabIndex={0}
							onClick={() => openArticle()}
							onKeyPress={e => {
								if (e.key === 'Enter') openArticle();
							}}
							className={bem(className, 'menu-link')}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Iconof icon="support_vs" className={bem(className, 'menu-link-icon')} />
							{HELP_MENU_LINKS.help.label}
						</a>
						<a
							href={HELP_MENU_LINKS.support.link}
							className={bem(className, 'menu-link')}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Iconof icon="book_opened" className={bem(className, 'menu-link-icon')} />
							{HELP_MENU_LINKS.support.label}
						</a>
						<a
							role="menuitem"
							tabIndex={0}
							onClick={() => {
								window.Intercom('show');
								closeHelpMenu();
							}}
							onKeyPress={e => {
								if (e.key === 'Enter') {
									window.Intercom('show');
									closeHelpMenu();
								}
							}}
							className={bem(className, 'menu-link')}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Iconof icon="sms" className={bem(className, 'menu-link-icon')} />
							{HELP_MENU_LINKS.message.label}
							<div
								className={`${bem(className, 'badge-container')} ${bem(
									className,
									'badge-container',
									'small',
								)}  `}
								has-messages={unreadMessages > 0 ? '' : null}
							>
								<Badge type="warning" label="" />
							</div>
						</a>
						{tourAvailable && (
							<a
								role="menuitem"
								tabIndex={0}
								onClick={() => {
									startTour();
									closeHelpMenu();
								}}
								onKeyPress={e => {
									if (e.key === 'Enter') {
										startTour();
										closeHelpMenu();
									}
								}}
								className={bem(className, 'menu-link')}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Iconof icon="direction" className={bem(className, 'menu-link-icon')} />
								{HELP_MENU_LINKS.tour.label}
								<div
									className={`${bem(className, 'badge-container')} ${bem(
										className,
										'badge-container',
										'small',
									)}  `}
									has-messages={unreadMessages > 0 ? '' : null}
								>
									<Badge type="warning" label="" />
								</div>
							</a>
						)}
						<div className={bem(className, 'menu-item-footer-links')}>
							<a
								className={bem(className, 'menu-link')}
								target="_blank"
								rel="noopener noreferrer"
								href={HELP_MENU_LINKS.new.link}
							>
								{HELP_MENU_LINKS.new.label}
							</a>
							<a
								className={bem(className, 'menu-link')}
								target="_blank"
								rel="noopener noreferrer"
								href={HELP_MENU_LINKS.academy.link}
							>
								{HELP_MENU_LINKS.academy.label}
							</a>
							{(!isChargify || !isRemittance) && (
								<a
									className={bem(className, 'menu-link')}
									target="_blank"
									rel="noopener noreferrer"
									{...(isPublicStudiesMatch || !isChargify
										? {
												href: HELP_MENU_LINKS.chat.link,
										  }
										: {
												role: 'menuitem',
												tabIndex: 0,
												onClick: () => {
													setContactUsModalOpen(true);
													closeHelpMenu();
												},
												onKeyPress: () => {
													setContactUsModalOpen(true);
													closeHelpMenu();
												},
										  })}
								>
									{HELP_MENU_LINKS.chat.label}
								</a>
							)}
						</div>
					</motion.div>
					<Tooltip
						id={`icon-button__tooltip-${Math.ceil(Math.random() * 1000)}`}
						open={!openState && tipOpen}
						className={`${className}__tooltip`}
						animation="shift"
						animationFill={false}
						trigger="manual"
						position="top"
						arrow={false}
						theme="basic-text-tooltip"
						distance="16"
						html={<>Help, support, and chat with us</>}
					>
						<FAB
							onMouseEnter={() => {
								setTipOpen(true);
							}}
							onMouseLeave={() => {
								setTipOpen(false);
							}}
							onClick={() => {
								checkTours();
								helpMenuOpenRef.current = !helpMenuOpenRef.current;
								setOpenState(helpMenuOpenRef.current);
							}}
						>
							<div className={bem(className, 'trigger-content')}>?</div>
						</FAB>
						<div
							className={bem(className, 'badge-container')}
							has-messages={unreadMessages > 0 ? '' : null}
						>
							<Badge type="warning" label={`${unreadMessages > 99 ? '99+' : unreadMessages}`} />
						</div>
					</Tooltip>
				</div>

				<Modal show={contactUsModalOpen} width={400} padding={0} onClose={() => setContactUsModalOpen(false)}>
					<div style={{ height: 300 }}>
						<ContactUsThankYou
							daysUntilRenewal={daysUntilRenewal}
							handleSoundsGoodClick={() => setContactUsModalOpen(false)}
						/>
					</div>
				</Modal>
			</>
		)
	);
};

HelpMenu.propTypes = {
	loggedIn: PropTypes.bool,
};

const mapStateToProps = state => ({
	loggedIn: authSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps, null)(HelpMenu);
