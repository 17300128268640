import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Auth from 'src/utilities/auth';
import { Container, Row, Col } from 'reactstrap';
import Back from 'src/components/icons/Back';
import IconButton from 'src/components/elements/IconButton';
import * as publicReportActions from 'src/domains/public-study/actions/reporting';
import * as publicReportSelectors from 'src/domains/public-study/selectors';
import LanguageSelector from 'src/domains/header/components/shared/LanguageSelector';
import cn from 'src/utilities/bem-cn';
import './styles.scss';
import withRouter from 'src/hocs/withRouter';
import StudyImage from 'src/components/shared/SearchResults/shared/StudyImage/index.js';

const className = 'public-report-header';
const el = (name, mod) => cn(className, name, mod);

class PublicReportHeader extends React.Component {
	changeLanguage = language => {
		const { currentSection, study, setLanguage, fetchProducts, fetchSection } = this.props;
		setLanguage(language);
		if (currentSection && currentSection.content) {
			const { content } = currentSection;
			const { type } = content;
			switch (type) {
				case 'questions':
					fetchSection(currentSection.content.id);
					break;
				case 'statement':
					fetchSection(currentSection.content.id);
					break;
				case 'swipe':
					fetchSection(currentSection.content.id);
					fetchProducts(study.id);
					break;
				default:
			}
		}
	};

	getButtonConfig = () => {
		const { study } = this.props;
		let result = {};
		switch (study.status) {
			case 'draft':
				result = {
					buttonLabel: 'Launch',
					buttonIconType: 'stop',
				};
				break;
			case 'active':
				result = {
					buttonLabel: 'Live',
					buttonIconType: 'play',
				};
				break;
			case 'closed':
				result = {
					buttonLabel: 'Complete',
					buttonIconType: 'check',
				};
				break;
			default:
				result = {
					buttonLabel: 'Launch',
					buttonIconType: 'clock',
				};
		}

		return result;
	};

	open = () => {
		const { setDevicePreviewMode, setShowDevicePreviewMode } = this.props;
		setDevicePreviewMode('mobile');
		setShowDevicePreviewMode(true);
	};

	renderBackButton = () => {
		const {
			router: { navigate },
		} = this.props;
		return (
			<button
				type="button"
				className={el('back-button')}
				onClick={() => {
					const isPublic = /public/.test(window.location.pathname);
					navigate(`${isPublic ? '/public' : ''}/studies`);
				}}
			>
				<Back />
			</button>
		);
	};

	renderStudyName = () => {
		const { study } = this.props;
		if (!study) return;

		return (
			<div className={el('study-name')}>
				<div style={{ marginRight: '8px' }}>
					<StudyImage asset={study?.asset} emoji={study?.emoji} />
				</div>
				{study.name}
			</div>
		);
	};

	renderPreviewIcon = () => {
		const userIsTrial = Auth.userCan('user:trial') && !Auth.isDigAdmin();
		return !userIsTrial ? (
			<div className={el('preview-button')}>
				<IconButton icon="play_circle_outlined" onClick={this.open} tooltip="Preview Study" size="large" position="bottom" />
			</div>
		) : (
			false
		);
	};

	renderLanguageSelector = () => {
		const { language, languages, study, setTranslationsModal } = this.props;

		if (study) {
			const { defaultLanguage, languages: studyLanguages } = study;
			const headingText = 'View report in:';
			return (
				<LanguageSelector
					headingText={headingText}
					disabled={(studyLanguages || []).length < 2}
					language={language || defaultLanguage}
					languages={studyLanguages || []}
					allLanguages={languages}
					onChangeLanguage={this.changeLanguage}
					setTranslationsModal={setTranslationsModal}
					showImportTranslationsOption={false}
					manageLanguages={false}
				/>
			);
		}
		return false;
	};

	render() {
		const { study } = this.props;
		if (!study) {
			return false;
		}
		return (
			<Container className={className}>
				<Row>
					<Col className={el('left')}>
						{this.renderBackButton()}
						{this.renderStudyName()}
					</Col>
					<Col className={el('right')}>
						{this.renderLanguageSelector()}
						{this.renderPreviewIcon()}
					</Col>
				</Row>
			</Container>
		);
	}
}

PublicReportHeader.propTypes = {
	id: PropTypes.any,
	language: PropTypes.any,
	languages: PropTypes.any,
	study: PropTypes.any,
	setTranslationsModal: PropTypes.any,
	setLanguage: PropTypes.any,
	fetchProducts: PropTypes.any,
	fetchSection: PropTypes.any,
	setDevicePreviewMode: PropTypes.any,
	setShowDevicePreviewMode: PropTypes.any,
	currentSection: PropTypes.any,
	isPublic: PropTypes.any,
};

const mapStateToProps = state => ({
	study: publicReportSelectors.getStudy(state),
	language: publicReportSelectors.getLanguage(state),
	languages: publicReportSelectors.getLanguages(state),
	currentSection: publicReportSelectors.getCurrentSection(state),
});

const mapDispatchToProps = dispatch => ({
	fetchProducts: id => dispatch(publicReportActions.fetchProducts(id)),
	fetchSection: (sectionId, refetch = false, studyId = 0) =>
		dispatch(publicReportActions.fetchSection(sectionId, refetch, studyId)),
	setLanguage: language => dispatch(publicReportActions.setLanguage(language)),
	setDevicePreviewMode: devicePreviewMode => dispatch(publicReportActions.setDevicePreviewMode(devicePreviewMode)),
	setShowDevicePreviewMode: showDevicePreviewMode =>
		dispatch(publicReportActions.setShowDevicePreviewMode(showDevicePreviewMode)),
	setTranslationsModal: translationsModal => dispatch(publicReportActions.setTranslationsModal(translationsModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PublicReportHeader));
