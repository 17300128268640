import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import ConfirmationModal from 'src/components/shared/ConfirmationModal';
import InterlockQuotasContainer from 'src/domains/manage-study/containers/InterlockQuotasContainer';

import Loading from '../../../../../../components/elements/Loading';

import './styles.scss';

const className = 'modal-interlock-quotas-wrapper';
const el = (name, mod) => cn(className, name, mod);

const InterlockQuotasWrapper = ({
	studyId,
	demographicQuestions,
	screeningQuestions,
	collectionId,
	isValid,
	setErrors,
	setInterlockQuotasData,
	selectedLanguage,
	groupId,
	groupUuid,
}) => {
	const unsaveChangesModalRef = useRef();
	const [loading, setLoading] = useState();
	const [hasChanged, setHasChanged] = useState(false);

	return (
		<div className={className}>
			{studyId && groupUuid && demographicQuestions && screeningQuestions ? (
				<InterlockQuotasContainer
					studyId={studyId}
					groupUuid={groupUuid}
					collectionId={collectionId}
					audienceId={groupId}
					groupStatus="ready"
					demographicQuestions={demographicQuestions}
					screeningQuestions={screeningQuestions}
					isValid={isValid}
					setErrors={setErrors}
					loading={loading}
					setLoading={setLoading}
					selectedLanguage={selectedLanguage}
					setInterlockQuotasData={setInterlockQuotasData}
					setHasChanged={setHasChanged}
				/>
			) : (
				<div className={el('loader')}>
					<Loading />
				</div>
			)}

			<ConfirmationModal ref={unsaveChangesModalRef}>
				<AreYouSureForm
					headerText="Unsaved Changes"
					bodyText="You have some unsaved changes, are you sure you want to continue?"
					cancelText="Continue"
					confirmText="Go Back"
					confirmType="green"
					onCancel={() => unsaveChangesModalRef.current?.onCancel()}
					onConfirm={() => unsaveChangesModalRef.current?.onCancel()}
				/>
			</ConfirmationModal>
		</div>
	);
};

InterlockQuotasWrapper.propTypes = {
	demographicQuestions: PropTypes.array,
	screeningQuestions: PropTypes.array,
	studyId: PropTypes.number,
	selectedLanguage: PropTypes.string,
	setErrors: PropTypes.func,
	setInterlockQuotasData: PropTypes.func,
	isValid: PropTypes.bool,
	collectionId: PropTypes.number,
	groupId: PropTypes.number,
	groupUuid: PropTypes.string,
};

export default InterlockQuotasWrapper;
