const prefix = 'billing-details';

export const ACTIVATE_ACCOUNT = `${prefix}/ACTIVATE_ACCOUNT`;
export const activateAccount = chargifyToken => ({
	type: ACTIVATE_ACCOUNT,
	payload: { chargifyToken },
});

export const PREVIEW_ACCOUNT_CREATION = `${prefix}/PREVIEW_ACCOUNT_CREATION`;
export const previewAccountCreation = chargifyToken => ({
	type: PREVIEW_ACCOUNT_CREATION,
	payload: { chargifyToken },
});

export const PREVIEW_SUBSCRIPTION_WITH_CARD_ON_FILE = `${prefix}/PREVIEW_SUBSCRIPTION_WITH_CARD_ON_FILE`;
export const previewSubscriptionWithCardOnFile = () => ({
	type: PREVIEW_SUBSCRIPTION_WITH_CARD_ON_FILE,
});

export const SET_PREVIEW_SUBSCRIPTION_INFO = `${prefix}/SET_PREVIEW_SUBSCRIPTION_INFO`;
export const setPreviewSubscriptionInfo = previewInfo => ({
	type: SET_PREVIEW_SUBSCRIPTION_INFO,
	payload: { previewInfo },
});

export const SET_LOADING = `${prefix}/SET_LOADING`;
export const setLoading = loading => ({ type: SET_LOADING, payload: { loading } });

export const SET_UUID = `${prefix}/SET_UUID`;
export const setUuid = uuid => ({ type: SET_UUID, payload: { uuid } });
