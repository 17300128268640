import React, { useContext } from 'react';
import { Tooltip } from 'react-tippy';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { Iconof } from '@upsiide/ui-components';
import { useIsBlueprint, useIsPublic } from 'src/hooks';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as publicStudySelectors from 'src/domains/public-study/selectors';
import { countriesWithFlags } from 'src/utilities/upsiideCountriesWithFlag';
import Chip from 'src/components/shared/Chip/index.js';
import { Table, TableHead, TableBody, TableRow, TableCell } from 'src/components/shared/Table';
import * as misc from 'src/utilities/misc';
import { TemplateCardsContext } from 'src/components/shared/TemplateCard/context';
import './styles.scss';

const QuestionRow = ({ question, index, audienceTemplate, setCurrentTab, selectedLanguage, isOverView = false }) => {
	const className = isOverView ? 'overview-screening-questions' : 'screening-questions';
	const el = (name, mod) => cn(className, name, mod);

	const { templateModalData } = useContext(TemplateCardsContext);

	const { isBlueprint } = useIsBlueprint();
	const { isPublic } = useIsPublic();

	const studySelectors = isBlueprint ? manageBlueprintSelectors : manageStudySelectors;
	const selectors = isPublic ? publicStudySelectors : studySelectors;

	const languages = useSelector(state => selectors.getLanguages(state));

	const audienceCollection = useSelector(state => studySelectors.getAudienceCollection(state));
	const navigate = useNavigate();

	const getTranslations = (data, languageCode) =>
		data?.translations?.find(translation => translation?.languageCode === languageCode);

	const getIcon = type => {
		switch (type) {
			case 'single-select':
				return <Iconof className="question-icon select-icon" icon="checkmark_square" size="small" />;
			case 'custom':
				return <Iconof className="question-icon select-icon" icon="checkmark_square" size="small" />;
			case 'qualifier':
				return <Iconof className="question-icon select-icon" icon="checkmark_square" size="small" />;
			case 'multi-select':
				return <Iconof className="question-icon select-icon" icon="checkmark_square" size="small" />;
			case 'grid':
				return <Iconof className="question-icon grid-icon" icon="grid" size="small" />;
			case 'ranked':
				return <Iconof className="question-icon ranked-icon" icon="chart_bar_3" size="small" />;
			default:
				return null;
		}
	};

	const getQuestionTranslation = () => {
		if (question?.translations?.length) {
			const languageVal =
				typeof selectedLanguage === 'object' ? selectedLanguage?.languageCode : selectedLanguage;
			const currentTranslation = question?.translations?.find(
				translation => translation?.languageCode === languageVal,
			);
			if (typeof currentTranslation?.label === 'string') return misc.cleanHtmlString(currentTranslation?.label);
		}
		return !isOverView ? misc.cleanHtmlString(question?.label) : '';
	};

	let groups = audienceCollection?.content?.demographicGroups || audienceTemplate?.demographicGroups;
	if (templateModalData?.selectedTemplate?.id) {
		groups = audienceTemplate?.demographicGroups;
	}

	const isGlobalQualifier = question?.settings?.find(s => s?.label === 'isGlobalQualifier')?.value === 'true';

	const requiredLanguages = groups
		?.filter(({ id, languageCode }) => languageCode && (isGlobalQualifier || question.allowList.includes(id)))
		?.map(({ languageCode }) => languageCode);

	const groupsInQuestion = groups?.filter(({ id }) => question.allowList.includes(id));

	let isMissingTranslation = false;

	if (requiredLanguages) {
		for (const lang of requiredLanguages) {
			if (isMissingTranslation) break;

			if (!getTranslations(question, lang) || !getTranslations(question, lang)?.label) {
				isMissingTranslation = true;
			}

			if (!isMissingTranslation) {
				let optionMissingTranslation = false;
				question.options.forEach(option => {
					if (!getTranslations(option, lang) || !getTranslations(option, lang)?.label) {
						optionMissingTranslation = true;
					}
				});
				isMissingTranslation = optionMissingTranslation;
			}
			if (!isMissingTranslation) {
				let optionMissingTranslation = false;
				question.attributes.forEach(attribute => {
					if (!getTranslations(attribute, lang) || !getTranslations(attribute, lang)?.label) {
						optionMissingTranslation = true;
					}
				});
				isMissingTranslation = optionMissingTranslation;
			}
		}
	}

	const showInAll = groupsInQuestion?.length === groups?.length;
	const hasNone = groupsInQuestion?.length === 0;

	return (
		<TableRow
			key={`screening-question-${question.id}`}
			aria-hidden
			className="question-row"
			onClick={() => {
				if (audienceTemplate) setCurrentTab('screening');
				else navigate('questions');
			}}
		>
			<TableCell customClass={el('flags-cell')}>
				<Tooltip
					arrow={false}
					className={el('tooltip')}
					trigger="mouseenter"
					theme="dark-blured-small-radius"
					html={
						hasNone ? (
							<div className="shown-in-audiences-tooltip shown-in-audiences-tooltip__none-copy">
								<div>
									<Iconof icon="warning" />
								</div>
								<div className="shown-in-audiences-tooltip__none-copy-text">
									This question is not shown in any groups. Select a group in ‘Question is visible
									for’ section in the settings
								</div>
							</div>
						) : (
							<div className="shown-in-audiences-tooltip">
								<span className="shown-in-audiences-tooltip__title">Shown in</span>
								<ul className="shown-in-audiences-tooltip__list">
									{groupsInQuestion?.map(group => {
										const flag = countriesWithFlags.find(
											({ id }) => id === group?.countryLanguage.countryId,
										)?.flag;

										const foundLanguage = languages?.find(
											lang => lang.id === group?.countryLanguage?.languageId,
										)?.languageCode;

										const language = foundLanguage || group?.countryLanguage?.languageLabel || null;

										return (
											<li>
												<span>{misc.renderAudienceName(group)}</span>
												<div className="shown-in-audiences-tooltip__country-languages">
													{!!flag && group?.provider.toLowerCase() !== 'byo' && (
														<Chip customClass="shown-in-audiences-tooltip__country-language-chip">
															{flag}
														</Chip>
													)}

													{!flag && group?.provider.toLowerCase() === 'byo' && (
														<Chip customClass="shown-in-audiences-tooltip__country-language-chip">
															<Iconof icon="link" />
														</Chip>
													)}

													{!!language && (
														<Chip customClass="shown-in-audiences-tooltip__country-language-chip">
															{language.toUpperCase()}
														</Chip>
													)}
												</div>
											</li>
										);
									})}
								</ul>
							</div>
						)
					}
				>
					<div className={el('flags-container')}>
						{showInAll || hasNone ? (
							<Chip customClass={el('all-groups-chip', hasNone ? 'none' : '')}>
								{!!hasNone && <Iconof icon="warning" />}
								{hasNone ? 'None' : 'All Groups'}
							</Chip>
						) : (
							<>
								{groupsInQuestion?.length > 0 &&
									groupsInQuestion?.slice(0, 2)?.map(group => {
										if (group?.provider.toLowerCase() === 'byo') {
											return (
												<Chip customClass={el('country-flag')}>
													<Iconof icon="link" />
												</Chip>
											);
										}

										const flag = countriesWithFlags.find(
											({ id }) => id === group?.countryLanguage?.countryId,
										)?.flag;

										if (flag) {
											return <Chip customClass={el('country-flag')}>{flag}</Chip>;
										}

										if (groupsInQuestion?.length === 1) {
											return <Chip customClass={el('all-groups-chip', 'none')}>No Country</Chip>;
										}

										return null;
									})}

								{groupsInQuestion?.length > 2 ? (
									<Chip customClass={el('country-flag')}>+{groupsInQuestion.length - 2}</Chip>
								) : null}
							</>
						)}
					</div>
				</Tooltip>
			</TableCell>

			<TableCell customClass={el('question-content-cell')}>
				<div className={el('question-content')}>
					{getIcon(question?.style)}
					<span className="question">
						{index}. {getQuestionTranslation()}
					</span>
					{isMissingTranslation ? (
						<Tooltip
							animation="shift"
							size="small"
							animationFill={false}
							interactive
							trigger="mouseenter"
							position="top"
							distance={2}
							theme="overview-price"
							html={<div className={el('tooltip-body')}>Translations are needed here</div>}
						>
							<img
								className={el('warning-error')}
								src="/public/images/audience/warning_red.svg"
								alt="warning_red"
							/>
						</Tooltip>
					) : null}
					{question?.logic?.length ? (
						<Tooltip
							animation="shift"
							size="small"
							animationFill={false}
							interactive
							trigger="mouseenter"
							position="top"
							distance={2}
							theme="overview-price"
							html={<div className={el('tooltip-body')}>This is a qualifying question</div>}
						>
							<img src="/public/images/icon/exit.svg" alt="exit" />
						</Tooltip>
					) : (
						''
					)}
					{question?.useQuota ? (
						<Tooltip
							animation="shift"
							size="small"
							animationFill={false}
							interactive
							trigger="mouseenter"
							position="top"
							distance={2}
							theme="overview-price"
							html={<div className={el('tooltip-body')}>Quotas applied</div>}
						>
							<Iconof className="chart-icon" icon="chart_donut_1 " />
						</Tooltip>
					) : (
						''
					)}
				</div>
			</TableCell>
		</TableRow>
	);
};
QuestionRow.propTypes = {
	question: PropTypes.object,
	index: PropTypes.number,
	audienceTemplate: PropTypes.object,
	setCurrentTab: PropTypes.func,
	selectedLanguage: PropTypes.object,
	isOverView: PropTypes.bool,
};

const Questions = ({ audienceTemplate, setCurrentTab, isOverView, step, selectedLanguage }) => {
	const className = isOverView ? 'overview-screening-questions' : 'screening-questions';
	const el = (name, mod) => cn(className, name, mod);

	const { isBlueprint } = useIsBlueprint();
	const studySelectors = isBlueprint ? manageBlueprintSelectors : manageStudySelectors;

	const audienceCollection = useSelector(state => studySelectors.getAudienceCollection(state));

	const screeningQuestions =
		audienceTemplate?.screeningQuestions || audienceCollection?.content?.screeningQuestions || [];

	if (screeningQuestions?.length) {
		return screeningQuestions.map((question, i) => (
			<QuestionRow
				question={question}
				index={i + 1}
				audienceTemplate={audienceTemplate}
				setCurrentTab={setCurrentTab}
				isOverView={isOverView}
				selectedLanguage={selectedLanguage}
			/>
		));
	}

	if (audienceTemplate)
		return audienceTemplate && step === 'edit' ? (
			<div aria-hidden onClick={() => setCurrentTab('screening')} className={el('add_screening')}>
				Add screening questions
			</div>
		) : (
			'No screening questions available'
		);
};
Questions.propTypes = {
	audienceTemplate: PropTypes.object,
	setCurrentTab: PropTypes.func,
	isOverView: PropTypes.bool,
	step: PropTypes.string,
	selectedLanguage: PropTypes.object,
};

const AudienceScreeningQuestions = ({
	audienceTemplate,
	selectedLanguage,
	setCurrentTab,
	step = 'preview',
	addQuestion = false,
	isOverView = false,
}) => {
	const className = isOverView ? 'overview-screening-questions' : 'screening-questions';
	const el = (name, mod) => cn(className, name, mod);

	const navigate = useNavigate();

	const renderAddQuestionAndAlerts = () => {
		if (!audienceTemplate)
			return (
				<p aria-hidden className={el('add-question-button')} onClick={addQuestion}>
					Add Question
				</p>
			);
		return null;
	};

	return (
		<div className={el('container')}>
			<div className="title-container">
				<div
					className="title-block"
					aria-hidden
					onClick={() => {
						if (audienceTemplate) setCurrentTab('screening');
						else navigate('questions');
					}}
				>
					<h3 className="title">Screening Questions</h3>

					{!audienceTemplate && (
						<p className={el('title-info')}>
							Screening questions allow you to profile and qualify respondents. Browse our Question
							Library or create your own
						</p>
					)}
				</div>
				<div className={el('error-block')}>{renderAddQuestionAndAlerts()}</div>
			</div>

			<Table customClass={el('body')}>
				<TableHead>
					<TableRow>
						<TableCell>Shown in</TableCell>
						<TableCell>Question</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<Questions
						audienceTemplate={audienceTemplate}
						setCurrentTab={setCurrentTab}
						isOverView={isOverView}
						step={step}
						selectedLanguage={selectedLanguage}
					/>
				</TableBody>
			</Table>
		</div>
	);
};

AudienceScreeningQuestions.propTypes = {
	audienceTemplate: PropTypes.object,
	selectedLanguage: PropTypes.object,
	setCurrentTab: PropTypes.func,
	step: PropTypes.string,
	addQuestion: PropTypes.func,
	isOverView: PropTypes.bool,
};

export default AudienceScreeningQuestions;
