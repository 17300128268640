import { useState, useEffect } from 'react';

// Our hook
export default function useElementSize({ selector, defaultHeight, defaultWidth }) {
	// State and setters for height value
	const [elementSize, setElementSize] = useState({ height: defaultHeight, width: defaultWidth });

	// resize observer for the header component(dom element)
	const ro = new ResizeObserver(([element]) => {
		setElementSize(element?.contentRect);
	});

	useEffect(() => {
		const element = document.querySelector(selector);
		if (element) {
			ro.observe(element);
		}
		// eslint-disable-next-line
	}, []);

	return elementSize;
}
