const mapClientsToFitSelect = clients => clients.map(client => ({ value: client.uuid, label: client.name }));

const mapLanguagesToFitSelect = languages =>
	languages
		.map(l => ({ value: l.languageCode, label: l.label }))
		.sort((a, b) => {
			// All English sub-languages First
			if (a.label.includes('English') && !b.label.includes('English')) {
				return -1;
			}
			if (!a.label.includes('English') && b.label.includes('English')) {
				return 1;
			}
			if (a.label > b.label) {
				return 1;
			}
			if (a.label < b.label) {
				return -1;
			}
			return 0;
		});

export default {
	mapClientsToFitSelect,
	mapLanguagesToFitSelect,
};
