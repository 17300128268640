import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

const clientsService = {
	getRoles: () => axios.get(`${API_PATH}/roles`).then(({ data }) => data.filter(role => !!role)),
	getClients: (sortBy = 'name', orderBy = 'ASC') => axios.get(`${API_PATH}/clients?sort=${sortBy}&order=${orderBy}`),
	getManagedClients: (sortBy = 'name', orderBy = 'ASC') =>
		axios.get(`${API_PATH}/clients/manage?sort=${sortBy}&order=${orderBy}`),
	getUsers: clientId =>
		axios.get(`${API_PATH}/clients/${clientId}/users`).then(({ data }) => data.filter(user => !!user)),
	addUser: (clientId, email, roleId) => axios.post(`${API_PATH}/clients/${clientId}/users`, { email, roleId }),
	deleteUser: (clientId, userId) => axios.delete(`${API_PATH}/clients/${clientId}/users/${userId}`),
	changeUserRole: (clientId, userId, roleId) =>
		axios.put(`${API_PATH}/clients/${clientId}/users/${userId}/roles/${roleId}`),

	addGroup: label => axios.post(`${API_PATH}/groups`, { label }),
	getGroups: () =>
		axios.get(`${API_PATH}/groups`).then(({ data }) =>
			Promise.all(
				data.map(group =>
					clientsService.getUsersByGroup(group.id).then(({ data }) => ({
						...group,
						users: data.map(user => user.email),
					})),
				),
			),
		),
	renameGroup: (id, label) => axios.put(`${API_PATH}/groups/${id}`, { label }),
	deleteGroup: id => axios.delete(`${API_PATH}/groups/${id}`),

	getUsersByGroup: id => axios.get(`${API_PATH}/groups/${id}/users`),
	addUserToGroup: (groupId, userId) => axios.post(`${API_PATH}/groups/${groupId}/users`, { userUuid: userId }),
	removeUserFromGroup: (groupId, userId) => axios.delete(`${API_PATH}/groups/${groupId}/users/${userId}`),

	listInvited: studyId => axios.get(`${API_PATH}/studies/${studyId}/invite`).then(({ data }) => data),
	createAccessEntry: (studyId, data) => axios.post(`${API_PATH}/studies/${studyId}/invite`, data),
	deleteAccessEntry: (studyId, uuid) => axios.delete(`${API_PATH}/studies/${studyId}/invite/${uuid}`),

	emails: (clientId, query) => axios.get(`${API_PATH}/users?emailFilter=${query}`),
	createClient: clientData => axios.post(`${API_PATH}/account/clients`, clientData).then(res => res),
	renameClient: (clientUuid, name) => axios.put(`${API_PATH}/account/clients/${clientUuid}`, { name }),
};

export default clientsService;
