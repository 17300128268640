import SelectedRecall from 'public/images/masking/selected_recall.svg';
import SingleSelectedRecall from 'public/images/masking/single_selected_recall.svg';
import UnSelectedRecall from 'public/images/masking/unselected_recall.svg';
import SingleUnSelectedRecall from 'public/images/masking/single_unselected_recall.svg';

const getMaskingErrorQuestions = (dragResult = null, questions, section) => {
	let maskingError = false;

	if (dragResult) {
		const sourceIndex = dragResult.source.index;
		const destinationIndex = dragResult.destination.index;
		const dragDirection = sourceIndex > destinationIndex ? 'up' : 'down';
		const draggedQuestion = questions && questions.find(q => q.id === Number(dragResult.draggableId));

		if (dragDirection === 'up') {
			// Find all questions between drag start position and drag end position
			const questionsOfInterest = section.questions.filter((q, i) => {
				if (i >= destinationIndex && i < sourceIndex) {
					return !!q;
				}
				return false;
			});
			// If the dragged question itself contains masked options, check if the origin question of any of the masked options is within questionsOfInterest
			if (draggedQuestion?.options?.some(option => option.maskedQuestionId)) {
				draggedQuestion?.options?.forEach(option => {
					questionsOfInterest.forEach(question => {
						if (question.id === option.maskedQuestionId) {
							maskingError = true;
						}
					});
				});
			}
		}

		if (dragDirection === 'down') {
			// Find all questions between drag start position and drag end position
			const questionsOfInterest = section.questions.filter((q, i) => {
				if (i <= destinationIndex && i > sourceIndex) {
					return !!q;
				}
				return false;
			});

			// Find all the masked options within questionsOfInterest
			const allMaskedOptionsWithinQuestionsOfInterest =
				questionsOfInterest
					?.filter(question => question.options.some(option => option.maskedQuestionId))
					?.map(question => question.options)
					?.flat(Infinity)
					.filter(option => option.maskedQuestionId) || [];
			// If any of the options is trying to mask in the question being dragged, give masking error
			allMaskedOptionsWithinQuestionsOfInterest.forEach(option => {
				if (option.maskedQuestionId === draggedQuestion.id) {
					maskingError = true;
				}
			});
		}
	}
	return maskingError;
};

const getMaskingErrorSections = (dragResult = null, sections) => {
	let maskingError = false;

	if (dragResult) {
		const sourceIndex = dragResult.source.index;
		const destinationIndex = dragResult.destination.index;
		const dragDirection = sourceIndex > destinationIndex ? 'up' : 'down';
		const draggedSection =
			sections && sections.content && sections.content.find(s => s.id === Number(dragResult.draggableId));
		if (draggedSection.type !== 'statement' && draggedSection.type !== 'swipe') {
			if (dragDirection === 'up') {
				const sectionsOfInterest = sections.content?.filter((s, i) => {
					if (s.questions && s.questions.length > 0 && i >= destinationIndex && i < sourceIndex) {
						return s;
					}
				});

				const listOfQuestionId = sectionsOfInterest?.map(sec => sec.questions.map(q => q.id)).flat(Infinity);

				draggedSection?.questions?.forEach(quest => {
					quest.options.forEach(op => {
						if (listOfQuestionId.includes(op.maskedQuestionId)) {
							maskingError = true;
						}
					});
				});
			} else if (dragDirection === 'down') {
				const listOfQuestionId = sections?.content
					.filter(sec => sec.id !== draggedSection.id)
					.filter(
						sec =>
							sec.order > sourceIndex &&
							sec.order <= destinationIndex &&
							sec.type !== 'statement' &&
							sec.type !== 'swipe',
					)
					.map(sec => sec?.questions?.map(q => q.options))
					.flat(Infinity)
					.map(opt => opt?.maskedQuestionId);
				draggedSection.questions?.forEach(quest => {
					if (listOfQuestionId.includes(quest.id)) {
						maskingError = true;
					}
				});
			}
		}
	}
	return maskingError;
};

const getMaskingArrayError = (allQuestions = []) => {
	const isErro = [];
	for (const question of allQuestions) {
		// check for each question
		for (const answer of question?.options || []) {
			// check for each option of the question if there is any masking error
			if (answer?.maskedQuestionId) {
				const originQuestion = allQuestions.find(q => q?.id === answer.maskedQuestionId);
				const maskedQuestionNumber = allQuestions.indexOf(originQuestion) + 1;
				const questionNumber = allQuestions.indexOf(question) + 1;
				if (questionNumber < maskedQuestionNumber || !originQuestion)
					isErro.push({ questionNumber, maskedQuestionNumber });
			}
		}
	}
	return isErro;
};

const getIconByLogic = (logic, isMultiSelect) => {
	if (logic === 'selected') {
		if (isMultiSelect) return `<img src=${SelectedRecall} alt="" style="margin-right: 5px;" />`;
		return `<img src=${SingleSelectedRecall} alt="" style="margin-right: 5px; width: 17px;" />`;
	}
	if (logic === 'not-selected') {
		if (isMultiSelect) return `<img src=${UnSelectedRecall} alt="" style="margin-right: 5px;" />`;
		return `<img src=${SingleUnSelectedRecall} alt="" style="margin-right: 5px; width: 17px;" />`;
	}
};

const getMaskedQuestionsRecursively = option => {
	if (option.maskedOptions.length) {
		return option.maskedOptions.map(innerOption => getMaskedQuestionsRecursively(innerOption));
	}
	return option;
};

const getMaskedOptions = options => {
	const maskedOptions = options.map(option => getMaskedQuestionsRecursively(option)).flat(Infinity);
	return maskedOptions;
};

export default {
	getMaskingErrorQuestions,
	getMaskingErrorSections,
	getMaskingArrayError,
	getMaskedOptions,
	getIconByLogic,
};
