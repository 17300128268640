import React from 'react';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import ResponsiveModal from '../ResponsiveModal';

import './styles.scss';

const className = 'detatched-screener-modal';
const el = (name, mod) => cn(className, name, mod);

const DetatchedScreenerModal = ({ onClose, onConfirm, customClass = '', show }) => {
	const five = 5;
	return (
		<div className={className}>
			<ResponsiveModal
				show={show}
				customClass={customClass || 'detach-question-modal'}
				headerLabel={
					<div className={el('modal-header')}>
						<Iconof icon="warning" className={el('icon')} />
						<p>Changing Question</p>
					</div>
				}
				closeLabel="Cancel"
				onClose={onClose}
				confirmLabel="Continue"
				onConfirm={onConfirm}
			>
				<p className={el('modal-text')}>Making any changes to this question will detach it from the template</p>
			</ResponsiveModal>
		</div>
	);
};

DetatchedScreenerModal.propTypes = {
	onClose: PropTypes.func,
	onConfirm: PropTypes.func,
	customClass: PropTypes.string,
	show: PropTypes.any,
};

export default DetatchedScreenerModal;
