import React, { useCallback, useEffect, useState, useRef, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useIsPublic } from 'src/hooks';
import cn from 'src/utilities/bem-cn';
import { audienceService, studyService } from 'src/services';
import { createBlueprint } from 'src/domains/all-blueprints/actions';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as manageStudyActions from 'src/domains/manage-study/actions';
import Loader from 'src/components/shared/Loader';
import Loadable from 'src/components/shared/Loadable';
import sortOrderAscending from 'public/images/share-of-choice/sort/sort-order-ascending.svg';
import sortOrderDescending from 'public/images/share-of-choice/sort/sort-order-descending.svg';
import arrowDown from 'public/images/audience/green_arrow_left.svg';
import AudienceTemplatesSidebar from 'src/domains/manage-study/containers/AudienceTemplateModal/AudienceTemplates/AudienceTemplatesSidebar';
import TemplatesEmptyState from 'src/domains/manage-study/containers/AudienceTemplateModal/AudienceTemplates/AudienceTemplatesEmptyState';
import { forYouCategories } from 'src/domains/manage-study/containers/AudienceTemplateModal/audienceTemplateCategories.mock';
import TemplateCard from 'src/components/shared/TemplateCard';
import TemplateFilterTags from 'src/components/shared/TemplateFilterTags';
import TemplatesSelection from 'src/components/shared/TemplatesSelection';
import StudyTemplatesList from './StudyTemplatesList';
import { TemplateCardsContext } from '../../../../../components/shared/TemplateCard/context';

import './styles.scss';

const className = 'study-templates';
const el = (name, mod) => cn(className, name, mod);
const [DEFAULT_SELECTED_CATEGORY] = forYouCategories();

const StudyTemplates = React.memo(
	({
		query,
		selectedCountries,
		selectedLanguages,
		searchLoading,
		setSearchLoading,
		setSelectedCountries,
		setSelectedLanguages,
		onClose,
		activeTab,
		setActiveTab,
		defaultActiveTab,
	}) => {
		const navigate = useNavigate();
		const [searchParams, setSearchParams] = useSearchParams();
		const { isPublic } = useIsPublic();

		const { setonDuplicatedTemplateCallbackOverride, setonDeletedTemplateCallbackOverride, templateModalData } =
			useContext(TemplateCardsContext);

		const [selectedCategory, setSelectedCategory] = useState(
			templateModalData?.category || DEFAULT_SELECTED_CATEGORY.value,
		);
		const [initialState, setInitialState] = useState(true);
		const [categories, setCategories] = useState([]);
		const [templateCollections, setTemplateCollections] = useState([]);
		const [loading, setLoading] = useState(false);
		const [hideScrollObserver, setHideScrollObserver] = useState(false);
		const study = useSelector(manageStudySelectors.getStudy);
		const audienceCollections = useSelector(manageStudySelectors.getAudienceCollection);

		const templateLimitRef = useRef(20);
		const templateOffsetRef = useRef(0);
		const subMenuRef = useRef(null);
		const templatesPerPage = 20;
		const scrollObserver = useRef();
		const dispatch = useDispatch();

		const [order, setOrder] = useState('desc'); // asc
		const [orderField, setOrderField] = useState('createdAt'); // name
		const [showAudienceSubMenu, setShowAudienceSubMenu] = useState(false);

		useEffect(() => {
			dispatch(manageStudyActions.fetchLanguages());
			const activeTabParam = searchParams.get('tab');
			if (activeTabParam) setActiveTab(activeTabParam);
			else setActiveTab(defaultActiveTab);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const handleSetOrderField = useCallback(field => {
			setOrderField(field);
			if (field === 'name') {
				setOrder('asc');
			} else {
				setOrder('desc');
			}
		}, []);

		const noFiltersOrQueryApplied = useMemo(
			() => !query && !selectedCountries?.length && !selectedLanguages?.length,
			[query, selectedCountries, selectedLanguages],
		);
		const fetchTemplates = useCallback(
			async (filters, maintainOriginalData) => {
				try {
					setLoading(true);
					if (filters?.terms === 'All Templates') delete filters.terms;

					const baseFilters = {
						search: filters.searchQuery?.trim(),
						orderBy: filters?.orderField,
						orderByDirection: filters?.order,
						limit: templateLimitRef.current,
						offset: templateOffsetRef.current,
						filter: {
							country: selectedCountries,
							language: selectedLanguages,
							...(filters?.terms ? { tag: [filters?.terms] } : {}),
						},
					};

					const templatesFetched = await studyService.searchStudies({
						filter: {
							...baseFilters,
							type: 'template',
							subtype: activeTab,
						},
						isPublic,
					});

					setTemplateCollections(current =>
						maintainOriginalData
							? [...current, ...templatesFetched.data.items]
							: templatesFetched.data.items,
					);
					setLoading(false);
					setSearchLoading(false);

					// only re-enable infinite scroll if there are more records to fetch
					if (templatesFetched.data.items.length >= templatesPerPage) {
						setHideScrollObserver(false);
					}
				} catch (error) {
					console.error(error);
					setSearchLoading(false);
				}
			},
			[setSearchLoading, activeTab, isPublic, selectedCountries, selectedLanguages],
		);

		const handleFetchTemplates = useCallback(
			advancedFilters => {
				templateOffsetRef.current += templatesPerPage;

				fetchTemplates(advancedFilters, true);
			},
			[fetchTemplates],
		);

		const getTemplateTags = useCallback(async () => {
			try {
				if (activeTab === 'audienceTemplate') {
					const response = await audienceService.getAudienceCollectionTemplateTags();
					setCategories(response.data);
				} else {
					const response = await studyService.getStudyTags();
					setCategories(response.data);
				}
			} catch (error) {
				console.error(error);
			}
		}, [activeTab]);

		const getAdvancedFilters = useCallback(
			() => ({
				limit: templateLimitRef.current,
				offset: templateOffsetRef.current,
				...(order?.length ? { order } : {}),
				...(orderField?.length ? { orderField } : {}),
				...(selectedCountries?.length ? { countryId: selectedCountries.join(',') } : {}),
				...(selectedLanguages?.length ? { languageCode: selectedLanguages.join(',') } : {}),
				...(selectedCategory?.length ? { terms: selectedCategory } : {}),
				...(query?.length ? { searchQuery: query } : {}),
			}),
			[order, orderField, query, selectedCategory, selectedCountries, selectedLanguages],
		);

		useEffect(() => {
			if (initialState) {
				setInitialState(false);
			} else {
				setHideScrollObserver(true);
				templateOffsetRef.current = 0;
				const advancedFilters = getAdvancedFilters();
				fetchTemplates(advancedFilters);
				getTemplateTags();
			}
		}, [initialState, fetchTemplates, getAdvancedFilters]);

		const endOfListRef = useCallback(
			node => {
				if (loading) return;
				if (scrollObserver.current) scrollObserver.current.disconnect();
				scrollObserver.current = new IntersectionObserver(entries => {
					if (entries[0].isIntersecting) {
						setHideScrollObserver(true);
						const advancedFilters = getAdvancedFilters();
						handleFetchTemplates(advancedFilters);
					}
				});
				if (node) scrollObserver.current.observe(node);
			},
			[getAdvancedFilters, handleFetchTemplates, loading],
		);

		const handleRefetch = useCallback(async () => {
			templateOffsetRef.current = 0;
			const advancedFilters = getAdvancedFilters();
			await fetchTemplates(advancedFilters, false);
		}, [fetchTemplates, getAdvancedFilters]);

		const renderTemplatesList = useCallback(
			({ title, category, templates }) => (
				<StudyTemplatesList key={category} title={title} category={category} templates={templates}>
					{templates.map(template => (
						<TemplateCard
							key={`${category}-${template.id}`}
							template={template}
							{...template}
							cardType={template.type}
							isGlobal={template?.owner?.isGlobal}
							ownerName={template?.owner?.name}
						/>
					))}
				</StudyTemplatesList>
			),
			[],
		);

		const handleCreateFromScratch = useCallback(async () => {
			try {
				if (activeTab === 'audienceTemplate') {
					if (!audienceCollections?.content) {
						const { data: collection } = await audienceService.createCollection(study?.id, {
							name: '',
							description: '',
							studyId: study?.id,
						});
						dispatch(manageStudyActions.fetchStudy(study?.id));
						dispatch(manageStudyActions.setAudienceCollection({ content: collection }));
						dispatch(manageStudyActions.fetchAllAudienceCollection(study?.id));
						navigate('../audiences');
					} else {
						navigate('../audiences');
					}
				} else {
					dispatch(
						createBlueprint({
							defaultLanguage: 'en',
						}),
					);
				}
			} catch (error) {
				console.error(error);
			} finally {
				onClose();
			}
		}, [audienceCollections, study, onClose, dispatch, activeTab, navigate]);

		const renderTemplates = useCallback(() => {
			if (!templateCollections.length)
				return (
					<TemplatesEmptyState
						templatesType={activeTab === 'audienceTemplate' ? 'audience' : 'study'}
						category={selectedCategory}
						onCreateYourOwn={handleCreateFromScratch}
						noFiltersOrQueryApplied={noFiltersOrQueryApplied}
						description="No Templates found"
					/>
				);
			return (
				<>
					{renderTemplatesList({
						title: selectedCategory,
						category: selectedCategory,
						templates: templateCollections,
					})}
					{!hideScrollObserver ? <div ref={endOfListRef} className={el('scroll-observer')} /> : null}
				</>
			);
		}, [
			templateCollections,
			activeTab,
			selectedCategory,
			handleCreateFromScratch,
			noFiltersOrQueryApplied,
			renderTemplatesList,
			hideScrollObserver,
			endOfListRef,
		]);

		const tabComponent = useMemo(() => {
			const onStudyTemplatesSelect = () => {
				setActiveTab('studyTemplate');
				setSearchParams(search => {
					search.delete('tab');
					return search;
				});
			};

			const onAudienceTemplatesSelect = () => {
				setActiveTab('audienceTemplate');
				setSearchParams(search => {
					search.delete('tab');
					return search;
				});
			};

			return (
				<TemplatesSelection
					onStudyTemplatesSelect={onStudyTemplatesSelect}
					onAudienceTemplatesSelect={onAudienceTemplatesSelect}
					activeTab={activeTab}
				/>
			);
		}, [activeTab, setActiveTab, setSearchParams]);

		const switchOrder = () => {
			if (order === 'asc') setOrder('desc');
			else setOrder('asc');
		};

		// Overwrite actions
		useEffect(() => {
			setonDuplicatedTemplateCallbackOverride(() => () => handleRefetch());
			setonDeletedTemplateCallbackOverride(() => () => handleRefetch());

			return () => {
				setonDuplicatedTemplateCallbackOverride(null);
				setonDeletedTemplateCallbackOverride(null);
			};
		}, [handleRefetch, setonDeletedTemplateCallbackOverride, setonDuplicatedTemplateCallbackOverride]);

		const renderOptions = () => (
			<div
				ref={subMenuRef}
				className={el('audience-sub-menu-wrapper')}
				onClick={() => setShowAudienceSubMenu(prevState => !prevState)}
				aria-hidden
			>
				{showAudienceSubMenu && (
					<div className={el('audience-sub-menu')}>
						<span
							aria-hidden
							className={el(`audience-sub-menu-item${orderField === 'createdAt' ? '-active' : ''}`)}
							onClick={() => {
								templateOffsetRef.current = 0;
								if (orderField === 'createdAt') {
									switchOrder();
								} else handleSetOrderField('createdAt');
								setShowAudienceSubMenu(false);
							}}
						>
							{orderField === 'createdAt' && (
								<img src={order === 'asc' ? sortOrderAscending : sortOrderDescending} alt="order-asc" />
							)}
							Most Recent
						</span>

						<span
							aria-hidden
							className={el(`audience-sub-menu-item${orderField === 'name' ? '-active-name' : ''}`)}
							onClick={() => {
								templateOffsetRef.current = 0;
								if (orderField === 'name') {
									switchOrder();
								} else handleSetOrderField('name');
								setShowAudienceSubMenu(false);
							}}
						>
							{orderField === 'name' && (
								<img src={order === 'asc' ? sortOrderAscending : sortOrderDescending} alt="order-asc" />
							)}
							Alphabetical
						</span>
					</div>
				)}
			</div>
		);

		return (
			<div className={className}>
				<div className={el('sidebar')}>
					<AudienceTemplatesSidebar
						selectedCategory={selectedCategory}
						onSelectCategory={setSelectedCategory}
						categories={categories}
					/>
				</div>
				<div className={el('content')}>
					{(!templateCollections?.length && loading) || searchLoading ? (
						<div className={el('loading-area')}>
							<Loader centered />
						</div>
					) : (
						<Loadable loading={loading || searchLoading} className={el('loading-area')}>
							{tabComponent}
							<div className={el('filter_container')}>
								<div className={el('filter_container_country')}>
									<TemplateFilterTags
										selectedCountries={selectedCountries}
										selectedLanguages={selectedLanguages}
										setSelectedCountries={setSelectedCountries}
										setSelectedLanguages={setSelectedLanguages}
									/>
								</div>
								<div className={el('order_language_country')}>
									{selectedCategory !== 'Recently Used' ? (
										<div
											className={el('order_container')}
											aria-hidden
											onClick={() => {
												setShowAudienceSubMenu(prev => !prev);
											}}
										>
											<span className={el('sorted_by')}>Sorted by</span>
											<img
												src={order === 'asc' ? sortOrderAscending : sortOrderDescending}
												alt="order-asc"
											/>
											<span className={el('most_recent')}>
												{orderField === 'createdAt' ? 'Most Recent' : 'Alphabetical'}
											</span>
											<img
												className={el(`${!showAudienceSubMenu ? 'arrow_up' : 'arrow_down'}`)}
												src={arrowDown}
												alt="order-asc"
											/>
											{renderOptions()}
										</div>
									) : null}
								</div>
							</div>
							{renderTemplates()}
						</Loadable>
					)}
				</div>
			</div>
		);
	},
);

StudyTemplates.displayName = 'StudyTemplates';
StudyTemplates.propTypes = {
	query: PropTypes.string,
	onPreview: PropTypes.func,
	selectedCountries: PropTypes.array,
	selectedLanguages: PropTypes.array,
	searchLoading: PropTypes.bool,
	setSearchLoading: PropTypes.func,
	setSelectedCountries: PropTypes.func,
	setSelectedLanguages: PropTypes.func,
	onClose: PropTypes.func,
	setShowTemplateModal: PropTypes.func,
	bypassCreate: PropTypes.bool,
	bypassPreview: PropTypes.bool,
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
	defaultActiveTab: PropTypes.string,
};

export default StudyTemplates;
