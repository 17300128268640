import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

const service = {
	// Profile
	get: () => axios.get(`${API_PATH}/me`),
	update: user => axios.patch(`${API_PATH}/me`, user).then(({ data }) => data),
	// PW Reset
	checkPasswordToken: token => axios.post(`${API_PATH}/auth/check-reset-token`, { token }).then(({ data }) => data),
	updatePassword: user => axios.post(`${API_PATH}/auth/change-password`, user).then(({ data }) => data),

	// Account creation (invite and self-signup)
	checkAccountSetupToken: invitationToken =>
		axios.post(`${API_PATH}/auth/check-invitation-token`, { invitationToken }).then(({ data }) => data),
	setupUser: user => axios.put(`${API_PATH}/users/invite`, user),

	// only validate user email
	checkEmailToken: invitationToken =>
		axios.post(`${API_PATH}/auth/check-email-token`, { invitationToken }).then(({ data }) => data),

	// Trial Sign-up
	selfSignUp: user =>
		axios.post(`${API_PATH}/users/sign-up`, user, {
			validateStatus: resp => resp, // override error checking
		}),
	setupSignUpUser: user => axios.patch(`${API_PATH}/users/sign-up`, user),
	createAccount: payload => axios.patch(`${API_PATH}/users/sign-up`, payload),
	previewSubscription: payload => axios.post(`${API_PATH}/users/sign-up/preview`, payload),
	previewSubscriptionWithCardOnFile: payload =>
		axios.get(`${API_PATH}/users/sign-up/preview/${payload.productHandle}/${payload.pricePointHandle}`, payload),
	updateSettings: payload => axios.post(`${API_PATH}/user/settings`, payload),
	setHistory: ({ userId, type, objectId, studyTranslationId }) =>
		axios.put(`${API_PATH}/users/${userId}/history`, { type, objectId, studyTranslationId }),
	getHistory: userId => axios.get(`${API_PATH}/users/${userId}/history`),
};

export default service;
