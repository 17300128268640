import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import OptionsToolTip from 'src/domains/manage-study/containers/AudienceContainer/QuestionLibrary/OptionsToolTip';
import Chip from 'src/components/shared/Chip/index.js';

import './styles.scss';

const className = 'list-chip';
const el = name => cn(className, name);

const ListChip = ({
	data,
	renderChipContent,
	tooltipTitle = '',
	renderCustomTooltipLabel,
	maxVisible = 2,
	customTooltipClass,
	customChipClass,
}) =>
	data?.length > 0 && maxVisible > 0 ? (
		<OptionsToolTip
			title={tooltipTitle}
			label={
				<Chip customClass={className}>
					{data.slice(0, maxVisible).map((item, i, arr) => (
						<span key={`${item}-${i}`}>
							<div className={`${className}${customChipClass ? ` ${customChipClass}` : ''}`}>
								{renderChipContent(item)}
							</div>
							{i + 1 < maxVisible && data.length > 1 && arr.length !== i + 1 ? (
								<div className={el('dot')} />
							) : null}
						</span>
					))}
					{data.length > maxVisible ? <span>+{data.length - maxVisible} </span> : null}
				</Chip>
			}
			options={data}
			renderCustomTooltipLabel={renderCustomTooltipLabel}
			customClass={customTooltipClass}
		/>
	) : null;

ListChip.propTypes = {
	data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
	renderChipContent: PropTypes.func.isRequired,
	tooltipTitle: PropTypes.string.isRequired,
	renderCustomTooltipLabel: PropTypes.func,
	maxVisible: PropTypes.number,
	customTooltipClass: PropTypes.string,
	customChipClass: PropTypes.string,
};

export default ListChip;
