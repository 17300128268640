import React, { useState, useRef, useCallback } from 'react';
import { element } from 'src/utilities/bem-cn';
import * as services from 'src/services';
import PropTypes from 'prop-types';

import { validateFileSize, validateImageType } from 'src/utilities/file-upload-helper';

import Button from 'src/components/elements/Button';
import Loader from 'src/components/shared/Loader';

import DragZone from '../DragZone';

import './styles.scss';

const className = 'asset-input-gallery-upload';
const el = element(className);

const FILE_TYPE_ERROR = 'File type is invalid';

/**
 * Assets Service API
 */
const upload = file => services.assetService.post(file);

/**
 * Assets Gallery
 */
const Upload = ({ onConfirmAsset, error, setError }) => {
	const fileInputRef = useRef();
	const [loading, setLoading] = useState(false);

	const openFileInput = useCallback(() => fileInputRef.current.click(), []);

	const handleInputError = useCallback(
		e => {
			let errorMessage = 'There was an error uploading your image, please try again.';
			switch (e.response.data.message) {
				case 'INVALID_MIME_TYPE':
					errorMessage = FILE_TYPE_ERROR;
					break;
				default:
					break;
			}

			setError(errorMessage);
			setLoading(false);
		},
		[setError],
	);

	const finishUpload = useCallback(
		file => {
			upload(file)
				.then(response => {
					onConfirmAsset(response.id);
					setLoading(false);
					setError(null);
				})
				.catch(err => {
					handleInputError(err);
				});
		},
		[handleInputError, onConfirmAsset, setError],
	);

	const handleUpload = useCallback(
		e => {
			const file = e.target.files[0];
			if (file) {
				setLoading(true);
				setError(null);
				const sizeError = validateFileSize(file);
				if (sizeError) {
					setError(sizeError);
					setLoading(false);
					return;
				}
				const imageTypeValid = validateImageType(file.name);
				if (!imageTypeValid) {
					setError(FILE_TYPE_ERROR);
					setLoading(false);
					return;
				}
				finishUpload(file);
			}
		},
		[finishUpload, setError],
	);

	const uploadAfterDrop = useCallback(
		file => {
			setLoading(true);
			setError(null);
			const sizeError = validateFileSize(file);
			if (sizeError) {
				setError(sizeError);
				setLoading(false);
				return;
			}
			const imageTypeValid = validateImageType(file.name);
			if (!imageTypeValid) {
				setError(FILE_TYPE_ERROR);
				setLoading(false);
				return;
			}
			finishUpload(file);
		},
		[finishUpload, setError],
	);

	return (
		<div className={className}>
			<input onChange={handleUpload} ref={fileInputRef} hidden type="file" />
			<DragZone onUpload={uploadAfterDrop} singleFileUpload>
				<div className={el(`upload-button-and-loader ${error ? 'error' : ''}`)}>
					{loading ? (
						<div className={el('loading-container')}>
							<div className={el('icon-container')}>
								<img
									className={el('file-upload-image')}
									src="/public/images/gallery/framed-picture.svg"
									alt="File Uploading"
								/>
								<div className={el('loader visible')}>
									<Loader />
								</div>
								<img
									className={el('file-upload-image')}
									src="/public/images/gallery/open-file-folder.svg"
									alt="File Uploading 2"
								/>
							</div>
							<div className={el('helper-text-loading')}>Your image is being uploaded</div>
							<div className={el('info-text-loading')}>Wow, what a beautiful picture that is!</div>
						</div>
					) : (
						<div className={el('upload-button')}>
							<img
								className={el('file-upload-image')}
								src="/public/images/gallery/framed-picture.svg"
								alt="File Upload"
							/>
							<div className={el('helper-text')}>Drag and drop your image file from your computer</div>
							<span>&nbsp;or&nbsp;</span>
							<Button
								label="Choose File"
								state={loading ? 'disabled' : 'active'}
								onClick={openFileInput}
							/>
							<div className={el('info-text')}>
								Image can be .png, .jpg or .gif. Your image should be smaller than 4 MB
							</div>
						</div>
					)}
				</div>
			</DragZone>
		</div>
	);
};

Upload.propTypes = {
	onConfirmAsset: PropTypes.func,
	error: PropTypes.string,
	setError: PropTypes.func,
};

export default Upload;
