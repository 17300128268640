import React from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import './SectionHelp.scss';
import Hint from 'src/components/icons/Hint';

const className = 'section-help';
const el = name => cn(className, name);

class SectionHelp extends React.Component {
	constructor(props) {
		super(props);
		this.defaultTippyWidth = 400;
	}

	getTooltipMarkup = () => {
		const { title, body, hintTitle, hintBody, tippyWidth } = this.props;
		return (
			<div
				className={el('tooltip')}
				style={{
					boxShadow: 'box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1)',
					width: tippyWidth || this.defaultTippyWidth,
					maxWidth: '100%',
				}}
			>
				<Row>
					<Col>
						<h3 className={el('tooltip-title')}>{title || ''}</h3>
					</Col>
				</Row>
				<Row>
					<Col xs={8} className={el('tooltip-left-column')}>
						{body || ''}
					</Col>
					<Col xs={4} className={el('tooltip-right-column')}>
						<h6 className={el('tip')}>{hintTitle || ''}</h6>
						<p className={el('tooltip-copy')}>{hintBody || ''}</p>
					</Col>
				</Row>
			</div>
		);
	};

	applyBoxShadow = () => {
		const { tippyWidth } = this.props;
		// After trying to style using tippy attributes and sass, this is what worked best
		const tippyTooltip = document.getElementsByClassName('tippy-tooltip')[0];
		if (tippyTooltip) {
			const boxShadow = '0 5px 12px 0 rgba(0, 0, 0, 0.1)';
			tippyTooltip.style.boxShadow = boxShadow;
			tippyTooltip.style.width = `${tippyWidth || this.defaultTippyWidth}px`;
		}
	};

	render() {
		return (
			<div className={className} onClick={this.applyBoxShadow}>
				<Tooltip
					arrow
					interactive
					position="bottom"
					trigger="click"
					theme="light"
					offset={-150}
					html={this.getTooltipMarkup()}
				>
					<Hint />
				</Tooltip>
			</div>
		);
	}
}

SectionHelp.propTypes = {
	title: PropTypes.string,
	body: PropTypes.object,
	hintTitle: PropTypes.string,
	hintBody: PropTypes.string,
	tippyWidth: PropTypes.number,
};

export default SectionHelp;
