export const forYouCategories = (accountCategories = []) => [
	{ value: 'Recently Used', label: 'Recently Used' },
	{ value: 'All Templates', label: 'All Templates' },
	{ value: 'My Templates', label: 'My Templates' },
	{ value: 'Dig Solutions', label: 'Dig Solutions', asset: '/public/images/digSolutionsIcon.svg' },
	...accountCategories,
	{ value: 'Starred', label: 'Starred' },
];

export const categories = [
	{ value: 'Gen pop', label: 'Gen pop' },
	{ value: 'Decision Makers', label: 'Decision Makers' },
	{ value: 'Food and Beverage', label: 'Food and Beverage' },
	{ value: 'Generations and Life Stages', label: 'Generations and Life Stages' },
];
