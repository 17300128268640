import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

function getIdeaMapData(studyId, filters = [], quartile = 0.7, sectionId = 0, audienceUuids = []) {
	return axios.post(`${API_PATH}/studies/${studyId}/newIdeaMap`, {
		filters,
		// lowColor: '#e0e0e0',
		quartile,
		sectionId,
		audienceUuids: audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals'),
	});
}

function getPublicIdeaMapData(studyId, filters = [], quartile = 0.7, sectionId = 0, audienceUuids = []) {
	return axios.post(`${API_PATH}/public/studies/${studyId}/newIdeaMap`, {
		filters,
		// lowColor: '#e0e0e0',
		quartile,
		sectionId,
		audienceUuids: audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals'),
	});
}

export default {
	getIdeaMapData,
	getPublicIdeaMapData,
};
