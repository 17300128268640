import Quill from 'quill';

const Embed = Quill.import('blots/embed');

class MentionBlot extends Embed {
	constructor(scroll, node) {
		super(scroll, node);
		this.clickHandler = null;
		this.hoverHandler = null;
		this.mounted = false;
	}

	static create(data) {
		const node = super.create();
		const denotationChar = document.createElement('span');
		denotationChar.className = 'mention-denotation-char';
		denotationChar.innerHTML = data.denotationChar;
		node.appendChild(denotationChar);
		node.innerHTML += data.value;

		// Add Rank and Position for piping in ranking questions
		if (data?.rank && data?.position) {
			node.innerHTML += ` ranked ${data?.position} ${data?.rank}`;
		}

		node.className += data?.customClass ? ` ${data?.customClass}` : '';
		return MentionBlot.setDataValues(node, data);
	}

	static setDataValues(element, data) {
		const domNode = element;
		Object.keys(data).forEach(key => {
			domNode.dataset[key] = data[key];
		});
		return domNode;
	}

	static value(domNode) {
		return domNode.dataset;
	}
}

MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'span';
MentionBlot.className = 'mention';

Quill.register(MentionBlot);
