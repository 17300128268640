import * as actions from '../actions';

const INITIAL_STATE = {
	signedUp: false,
	signedUpEmail: '',
	loading: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.INC_LOADING:
			return { ...state, loading: state.loading + 1 };

		case actions.DEC_LOADING:
			return { ...state, loading: state.loading - 1 };

		case actions.SET_SIGNED_UP:
			return { ...state, signedUp: action.payload.signedUp, signedUpEmail: action.payload.signedUpEmail };

		default:
			return state;
	}
};
