import toastr from 'toastr';
import * as services from 'src/services';
import * as actions from '../actions';

const fetchUser = async (store, { type, payload }) => {
	if (type === actions.FETCH_USER) {
		try {
			store.dispatch(actions.incLoading());
			const userData = await services.userService.get();
			store.dispatch(actions.setUser(userData.data));
			store.dispatch(actions.decLoading());
		} catch (error) {
			store.dispatch(actions.setError(error));
			console.group('Error while updating User');
			console.error('error:', error);
			console.groupEnd();
		}
	}
};

const updateUser = async (store, { type, payload }) => {
	if (type === actions.UPDATE_USER) {
		try {
			store.dispatch(actions.incLoading());
			store.dispatch(actions.setError(false));

			await services.userService.update(payload.user);

			const userData = await services.userService.get();
			store.dispatch(actions.setUser(userData.data));
			store.dispatch(actions.decLoading());
			store.dispatch(actions.setSubmitBtnText('Updated!'));
			setTimeout(() => {
				store.dispatch(actions.setSubmitBtnText('Update'));
			}, 2500);
		} catch (error) {
			store.dispatch(actions.setError(error));
			store.dispatch(actions.decLoading());

			console.group('Error while updating User');
			console.error('error:', error);
			console.groupEnd();
		}
	}
};

const setUserSettings = async (store, { type, payload }) => {
	if (type === actions.UPDATE_USER_SETTINGS) {
		try {
			const response = await services.userService.updateSettings(payload.settings);
			store.dispatch(actions.setUserSettings(response.data));
		} catch (error) {
			console.error('error:', error);
		}
	}
};
const resentLinkInvitation = async (store, { type, payload }) => {
	if (type === actions.RESENT_EMAIL_INVITATION) {
		const { email } = payload;
		if (!email) return;
		try {
			services.accountService
				.resendEmailValidation(email, '-', ' ')
				.then(res => {
					toastr.success('Verification email has been resent!');
				})
				.catch(err => {
					toastr.error('There was a problem sending the email. Please, try again later.');
				});
		} catch (error) {
			console.error('error:', error);
		}
	}
};

export default [fetchUser, updateUser, setUserSettings, resentLinkInvitation];
