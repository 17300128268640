import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-extraneous-dependencies
import HeatMap from 'heatmapjs';

import './styles.scss';

const className = 'react-heatmap';
const HEATMAP_CUTOFF_RENDER = 100;
const ReactHeatMap = ({ config, data, heatmapInstanceCallBack, ...rest }) => {
	const heatMapRef = useRef(null);
	const [heatMap, setHeatMap] = useState(null);

	useEffect(() => {
		const conf = config || { container: heatMapRef.current };
		if (conf) {
			if (!conf.container) {
				conf.container = heatMapRef.current;
			}
			const parentNode = conf?.container?.parentNode;
			if (parentNode) {
				conf.width = conf.width || parentNode?.clientWidth;
				conf.height = conf.height || parentNode?.clientHeight;
			}
		}
		const heatMapInstance = HeatMap.create(conf);
		if (data) {
			const dataWithOffset = data.map(d => ({
				...d,
				x: d.x + HEATMAP_CUTOFF_RENDER / 2,
				y: d.y + HEATMAP_CUTOFF_RENDER / 2,
			}));
			heatMapInstance.setData({ max: config.max, data: dataWithOffset });
		}

		setHeatMap(heatMapInstance);
	}, []);

	useEffect(() => {
		if (data && heatMap) {
			const dataWithOffset = data.map(d => ({
				...d,
				x: d.x + HEATMAP_CUTOFF_RENDER / 2,
				y: d.y + HEATMAP_CUTOFF_RENDER / 2,
			}));
			setTimeout(() => {
				heatMap.setData({ min: 0, max: config.max, data: dataWithOffset });
			});
		}
	}, [data]);

	useEffect(() => {
		if (heatMap) {
			if (heatmapInstanceCallBack) {
				heatmapInstanceCallBack(heatMap);
			}
		}
	}, [heatMap]);

	return <div className={className} {...rest} ref={heatMapRef} />;
};

ReactHeatMap.propTypes = {
	config: PropTypes.objectOf({
		/**
		 * (DOMNode) * optional *
		 * A DOM node where the heatmap canvas should be appended (heatmap will adapt to the node's size)
		 */
		container: PropTypes.node,
		/** * optional * A background color string in form of hexcode, color name, or rgb(a)
		 */
		backgroundColor: PropTypes.string,
		/** * optional *
		 * An object that represents the gradient(syntax: number string [0,1] : color string), check out the example */
		gradient: PropTypes.object,
		/** * optional *
		 * The radius each datapoint will have(if not specified on the datapoint itself) */
		radius: PropTypes.number,
		/** [0, 1] * optional * default = .6
		 * A global opacity for the whole heatmap.This overrides maxOpacity and minOpacity if set! */
		opacity: PropTypes.number,
		/** [0, 1] * optional *
		 * The maximal opacity the highest value in the heatmap will have. (will be overridden if opacity set) */
		maxOpacity: PropTypes.number,
		/** [0, 1] * optional *
		 * The minimum opacity the lowest value in the heatmap will have(will be overridden if opacity set) */
		minOpacity: PropTypes.number,
		/**
		 * Pass a callback to receive extrema change updates. Useful for DOM legends. */
		onExtremaChange: PropTypes.func,
		/** [0, 1] * optional * default = 0.85
		 * The blur factor that will be applied to all datapoints.The higher the blur factor is, the smoother the gradients will be */
		blur: PropTypes.number,
		/** * optional * default = "x"
		 * The property name of your x coordinate in a datapoint */
		xField: PropTypes.string,
		/** * optional * default = "y"
		 * The property name of your y coordinate in a datapoint */
		yField: PropTypes.string,
		/** * optional * default = "value"
		 * The property name of your y coordinate in a datapoint */
		valueField: PropTypes.string,
	}),
	data: PropTypes.array,
	heatmapInstanceCallBack: PropTypes.func,
};

export default ReactHeatMap;
