import React from 'react';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import UpgradeTooltipContent from './UpgradeTooltipContent';
import './styles.scss';

const className = 'upgrade-tooltip';
const el = (name, mod) => cn(className, name, mod);

function UpgradeTooltip({ position, isTrialing }) {
	const preventWindowClick = e => {
		if (!isTrialing) {
			e.preventDefault();
			e.nativeEvent.stopPropagation();
		}
	};

	return (
		<div
			className={className}
			onClick={preventWindowClick}
			onKeyDown={preventWindowClick}
			role="button"
			tabIndex={0}
		>
			<Tooltip
				position={position}
				arrow
				theme="light"
				html={<UpgradeTooltipContent isTrialing={isTrialing} />}
				popperOptions={{
					modifiers: {
						preventOverflow: {
							enabled: false,
						},
						flip: {
							enabled: false,
						},
					},
				}}
			>
				<div className={el('upgrade')}>{isTrialing ? 'trial' : 'upgrade'}</div>
			</Tooltip>
		</div>
	);
}

UpgradeTooltip.propTypes = {
	position: PropTypes.string,
	isTrialing: PropTypes.bool,
};

export default UpgradeTooltip;
