import React from 'react';

const RedDoor = () => (
	<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.2327 9.1626C13.2327 9.14553 13.2327 9.14553 13.2327 9.12845C13.2327 9.0943 13.2496 9.06015 13.2496 9.026V9.00892C13.2496 8.97477 13.2496 8.95769 13.2496 8.92354C13.2496 8.90647 13.2496 8.90647 13.2496 8.88939C13.2496 8.85524 13.2327 8.83816 13.2327 8.80401V8.78694C13.2157 8.75278 13.1987 8.71863 13.1818 8.70156C13.1818 8.68448 13.1648 8.68448 13.1648 8.66741C13.1648 8.65033 13.1478 8.65033 13.1478 8.63325L11.3319 6.20849C11.1452 5.95236 10.7718 5.88405 10.5173 6.08896C10.2627 6.2768 10.1948 6.65246 10.3985 6.9086L11.5016 8.41127L6.24048 8.37712C5.91803 8.37712 5.66346 8.63325 5.64648 8.95769C5.64648 9.28213 5.90105 9.55535 6.22351 9.55535L11.5016 9.5895L10.3475 11.1946C10.1609 11.4508 10.2118 11.8264 10.4833 12.0143C10.5851 12.0826 10.7039 12.1338 10.8227 12.1338C11.0094 12.1338 11.1791 12.0484 11.2979 11.8947L13.1308 9.36751C13.1478 9.35044 13.1648 9.31629 13.1818 9.29921V9.28213C13.1818 9.26506 13.1818 9.26506 13.1987 9.24798C13.2157 9.21383 13.2157 9.19675 13.2327 9.1626Z"
			fill="#FF3C41"
			stroke="#FF3C41"
			strokeWidth="0.25"
		/>
		<path
			d="M9.47326 12.0309C9.1508 12.0309 8.87926 12.2871 8.87926 12.6115L8.86229 15.8218L1.92102 15.7876L2.00588 2.1953L8.94715 2.22946L8.93018 5.40555C8.93018 5.72999 9.18475 6.0032 9.5072 6.0032C9.82966 6.0032 10.0842 5.74707 10.1012 5.42263L10.1182 1.66596C10.1182 1.34152 9.8636 1.0683 9.54114 1.0683L1.41188 1C1.25914 1 1.1064 1.0683 1.00457 1.17076C0.885771 1.27321 0.834857 1.42689 0.834857 1.58058L0.75 16.3511C0.75 16.6756 1.00457 16.9488 1.32702 16.9488L9.43932 17C9.59206 17 9.7448 16.9317 9.84663 16.8292C9.96543 16.7268 10.0163 16.5731 10.0163 16.4194L10.0503 12.6286C10.0503 12.3042 9.79571 12.0309 9.47326 12.0309Z"
			fill="#FF3C41"
			stroke="#FF3C41"
			strokeWidth="0.25"
		/>
	</svg>
);

export default RedDoor;
