import React, { useMemo } from 'react';
import cn from 'src/utilities/bem-cn';
import * as accountSelectors from 'src/domains/account/selectors';

import './styles.scss';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tippy';
import UpgradeTooltipContent from 'src/components/elements/UpgradeTooltip/UpgradeTooltipContent';

const className = 'language-dropdown';
const el = (name, mod) => cn(className, name, mod);

const AddLanguage = ({ navigateToTranslations }) => {
	const { account } = useSelector(state => state.account);
	const subscriptions = useSelector(accountSelectors.getChargifySubscription);

	const hasAccessFeature = useMemo(
		() => account.content?.features?.find(feature => feature.label === 'Manage Translations')?.value,
		[account],
	);
	const isChargify = useMemo(() => account.content?.settings?.isChargify, [account]);

	const isEnterpriseAccount = useMemo(() => {
		if (!isChargify) return true;
		if (subscriptions?.length) {
			const professional = subscriptions?.find(sub => sub?.subscriptionHandle === 'professional');
			if (professional && professional?.state === 'trialing') return false;
			return !!subscriptions?.find(sub => sub?.subscriptionHandle === 'enterprise');
		}
		return false;
	}, [isChargify, subscriptions]);

	const enabled = useMemo(() => hasAccessFeature || isEnterpriseAccount, [hasAccessFeature, isEnterpriseAccount]);

	return (
		<Tooltip
			position="bottom"
			arrow
			theme="light"
			html={
				<div className={el('tooltip_add')}>
					<UpgradeTooltipContent isTrialing={false} />
				</div>
			}
			disabled={enabled}
		>
			<div
				className={el(`add_new_language${enabled ? '' : ' disabled'}`)}
				aria-hidden
				onClick={() => {
					navigateToTranslations();
					setTimeout(() => {
						const event = new Event('add-language', { cancelable: true, bubbles: true });
						window.dispatchEvent(event);
					}, 500);
				}}
			>
				<span> + Add a Language</span>
			</div>
		</Tooltip>
	);
};

export default AddLanguage;
