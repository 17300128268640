import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';
import Button from '../Button';

const className = 'add-answer-button';
const el = (name, mod) => cn(className, name, mod);

const AddAnswerButton = ({ label, question, addOptionPlaceholder }) => (
	<Button
		label={label || 'Add answer'}
		type="text"
		className={el('add-answer-row text')}
		onClick={() => addOptionPlaceholder(question.id)}
		icon="add"
		iconSize="default"
	/>
);

AddAnswerButton.propTypes = {
	label: PropTypes.string,
	question: PropTypes.any,
	addOptionPlaceholder: PropTypes.func,
};

export default AddAnswerButton;
