import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Auth from 'src/utilities/auth';
import audienceStatusUtil from 'src/utilities/audiences-status-error-helper';
import { useIsPublic, useDebounce } from 'src/hooks';
import * as selectors from 'src/domains/selectors';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as actions from 'src/domains/manage-study/actions/reporting';
import * as manageStudyActions from 'src/domains/manage-study/actions';
import * as headerActions from 'src/domains/header/actions';
import IconButton from 'src/components/elements/IconButton';
import { TemplateCardsProvider } from 'src/components/shared/TemplateCard';
import LanguageSelector from 'src/domains/header/components/shared/LanguageSelector';
import HeaderNavigationBar from 'src/domains/header/components/shared/HeaderNavigationBar';
import { Iconof } from '@upsiide/ui-components';
import StudyImage from 'src/components/shared/SearchResults/shared/StudyImage/index.js';
import StudyAssetManager from '../../../../components/shared/StudyAssetManager';
import { StudyInput } from '../ManageStudy/index';
import FileUploadHeader from '../../components/shared/FileUploadHeader';

import './styles.scss';

const className = 'single-study-primary';
const el = (name, mod) => cn(className, name, mod);

const SingleStudyPrimary = ({ defaultStudyName, setDefaultStudyName }) => {
	const { isPublic } = useIsPublic();
	const dispatch = useDispatch();

	const [studyName, setStudyName] = useState(defaultStudyName || study ? study?.name : null);

	const userCanUpdate = Auth.userCan('study:update');
	const { study, sections, currentSection, language, languages } = useSelector(selectors.getStudyData);
	const studyForStudyName = useSelector(selectors.getManageStudyData).study;

	const setDevicePreviewMode = devicePreviewMode => dispatch(actions.setDevicePreviewMode(devicePreviewMode));
	const setShowDevicePreviewMode = showDevicePreviewMode =>
		dispatch(actions.setShowDevicePreviewMode(showDevicePreviewMode));
	const fetchProducts = useCallback(id => dispatch(actions.fetchProducts(id)), [dispatch]);
	const fetchSection = useCallback(
		(sectionId, refetch = false, studyId = 0) => dispatch(actions.fetchSection(sectionId, refetch, studyId)),
		[dispatch],
	);

	const { header } = useSelector(state => state);
	const debouncedStudyName = useDebounce(studyName, 800);

	useEffect(() => {
		if (typeof debouncedStudyName === 'string' && study && debouncedStudyName !== study?.name) {
			setDefaultStudyName(debouncedStudyName);
			dispatch(actions.patchStudy(study?.id, { name: debouncedStudyName }));
		}
		// eslint-disable-next-line
	}, [debouncedStudyName]);

	useEffect(() => {
		if (study) {
			setStudyName(study.name);
		}
	}, [study]);

	useEffect(
		() => () => {
			setDefaultStudyName(null);
		},
		[setDefaultStudyName],
	);

	const onStudyTitleChange = event => {
		setStudyName(event?.target?.value || '');
	};

	const open = () => {
		setDevicePreviewMode('mobile');
		setShowDevicePreviewMode(true);
	};

	// Audience Statuses for Header Banner

	const haveLiveAudienceGroups = audienceStatusUtil.haveLiveAudienceGroups();
	const haveCompleteAudienceGroups = audienceStatusUtil.haveCompleteAudienceGroups();
	const studyHasIssues = audienceStatusUtil.studyHasIssues();
	const IRIssue = audienceStatusUtil.getIRIssue();
	const audienceCollection = useSelector(state => manageStudySelectors.getAudienceCollection(state));

	const collectionWithDemoGroups = useMemo(
		() => !audienceCollection?.loading && !!audienceCollection?.content?.demographicGroups?.length,
		[audienceCollection?.content?.demographicGroups, audienceCollection?.loading],
	);
	const emptySections = useMemo(
		() => sections?.status === 'ready' && !sections?.content?.length,
		[sections?.content, sections?.status],
	);

	const renderStudyName = () => {
		if (!study) return;
		if (!userCanUpdate)
			return (
				<div className={el('study-name')}>
					<div style={{ marginRight: '8px' }}>
						<StudyImage asset={study?.asset} emoji={study?.emoji} />
					</div>
					{study.name}
				</div>
			);

		return (
			<div className={el('study-name')}>
				<StudyAssetManager study={study} key={study?.id} />
				<StudyInput
					defaultValue={studyForStudyName ? studyForStudyName?.name : studyName}
					onStudyTitleChange={onStudyTitleChange}
				/>
			</div>
		);
	};

	const renderPreviewIcon = () => {
		if (isPublic) return;

		const userIsTrial = Auth.userCan('user:trial') && !Auth.isDigAdmin();
		if (userIsTrial) return;

		return (
			<div className={el('preview-button')}>
				<IconButton
					icon="play_circle_outlined"
					onClick={open}
					tooltip="Preview Study"
					size="large"
					position="bottom"
				/>
			</div>
		);
	};

	const renderBackButton = () => (
		<Link to="/studies" className={el('upsiide-icon')}>
			<Iconof icon="arrow_backward" size="large" color="grey" />
		</Link>
	);

	useEffect(() => {
		if (sections.status !== 'ready') return;
		const hasQuestions =
			sections.content?.find(({ questions, statements }) => questions?.length || statements?.length) || false;

		if (!header.createIcon && hasQuestions) {
			dispatch(headerActions.setCreateIcon(true));
		} else if (header.createIcon && !hasQuestions) {
			dispatch(headerActions.setCreateIcon(false));
		}
		// eslint-disable-next-line
	}, [header, sections]);

	useEffect(() => {
		if (header.audienceIcon && audienceCollection.loading) return; // don't change yet

		if (!header.audienceIcon && (haveLiveAudienceGroups || haveCompleteAudienceGroups)) {
			dispatch(headerActions.setAudienceIcon(true));
		} else if (header.audienceIcon && !(haveLiveAudienceGroups || haveCompleteAudienceGroups)) {
			dispatch(headerActions.setAudienceIcon(false));
		}
		// eslint-disable-next-line
	}, [audienceCollection, header, haveLiveAudienceGroups, haveCompleteAudienceGroups]);

	useEffect(() => {
		if (header.audienceErrorIcon && audienceCollection.loading) return;

		if (!header.createErrorIcon && collectionWithDemoGroups && emptySections) {
			dispatch(headerActions.setCreateErrorIcon(true));
		} else if (header.createErrorIcon && !(collectionWithDemoGroups && emptySections)) {
			dispatch(headerActions.setCreateErrorIcon(false));
		}

		if (!header.audienceErrorIcon && studyHasIssues) {
			dispatch(headerActions.setAudienceErrorIcon(true));
			dispatch(headerActions.setAudienceIcon(false));
		} else if (header.audienceErrorIcon && !studyHasIssues) {
			dispatch(headerActions.setAudienceErrorIcon(false));
			dispatch(headerActions.setAudienceIcon(true));
		}
		// eslint-disable-next-line
	}, [
		audienceCollection,
		header?.audienceErrorIcon,
		header?.createErrorIcon,
		collectionWithDemoGroups,
		emptySections,
		studyHasIssues,
	]);

	useEffect(() => {
		if (!header.reportIcon && haveCompleteAudienceGroups) {
			dispatch(headerActions.setReportIcon(true));
		} else if (header.reportIcon && !haveCompleteAudienceGroups) {
			dispatch(headerActions.setReportIcon(false));
		}
		// eslint-disable-next-line
	}, [header, haveCompleteAudienceGroups]);

	const changeLanguage = newLanguage => {
		setLanguage(newLanguage);
		if (currentSection && currentSection.content) {
			const { content } = currentSection;
			const { type } = content;
			switch (type) {
				case 'questions':
					fetchSection(currentSection.content.id);
					break;
				case 'statement':
					fetchSection(currentSection.content.id);
					break;
				case 'swipe':
					fetchSection(currentSection.content.id);
					fetchProducts(study.id);
					break;
				default:
			}
		}
	};
	const setTranslationsModal = useCallback(
		translationsModal => dispatch(actions.setTranslationsModal(translationsModal)),
		[dispatch],
	);

	const setLanguage = useCallback(
		l => {
			dispatch(actions.setLanguage(l));
			dispatch(manageStudyActions.setLanguage(l));
		},
		[dispatch],
	);

	const renderLanguageSelector = () => {
		if (!study) return;
		const headingText = 'View report in:';
		return (
			<LanguageSelector
				headingText={headingText}
				disabled={study.languages?.length < 2}
				language={language || study.defaultLanguage}
				languages={study.languages || []}
				allLanguages={languages}
				onChangeLanguage={changeLanguage}
				setTranslationsModal={setTranslationsModal}
				showImportTranslationsOption={false}
				manageLanguages={false}
			/>
		);
	};

	return (
		<Container className={className}>
			<Row>
				<Col className={el('left')}>
					{renderBackButton()}
					{renderStudyName()}
				</Col>
				<Col className={el('center')}>
					<TemplateCardsProvider>
						<HeaderNavigationBar hasReportData={haveCompleteAudienceGroups} IRIssue={IRIssue} />
					</TemplateCardsProvider>
				</Col>
				<Col className={el('right')}>
					{renderLanguageSelector()}
					{renderPreviewIcon()}
					{!isPublic ? <FileUploadHeader /> : null}
				</Col>
			</Row>
		</Container>
	);
};

SingleStudyPrimary.propTypes = {
	setDefaultStudyName: PropTypes.any,
	defaultStudyName: PropTypes.any,
};

export default SingleStudyPrimary;
