const prefix = 'login';

export const SET_LOGIN_FORM = `${prefix}/SET_LOGIN_FORM`;
export const setLoginForm = loginForm => ({
	type: SET_LOGIN_FORM,
	payload: { loginForm },
});

export const LOGIN_USER = `${prefix}/LOGIN_USER`;
export const login = ({ email, password, remember, redirect }) => ({
	type: LOGIN_USER,
	payload: { email, password, remember, redirect },
});

export const SET_USER = `${prefix}/SET_USER`;
export const setUser = user => ({ type: SET_USER, payload: { user } });

export const FORGET_PASSWORD = `${prefix}/FORGET_PASSWORD`;
export const forgetPassword = ({ email }) => ({
	type: FORGET_PASSWORD,
	payload: { email },
});

export const RESET_FORM = `${prefix}/RESET_FORM`;
export const resetForm = () => ({
	type: RESET_FORM,
});

export const GO_TO_SUPPORT = `${prefix}/GO_TO_SUPPORT`;
export const goToSupport = () => ({ type: GO_TO_SUPPORT });

export const GET_PROVISIONING_STATE = `${prefix}/GET_PROVISIONING_STATE`;
export const getProvisioningState = () => ({ type: GET_PROVISIONING_STATE });

export const SET_PROVISIONING_STATE = `${prefix}/SET_PROVISIONING_STATE`;
export const setProvisioningState = provisioning => ({
	type: SET_PROVISIONING_STATE,
	payload: { provisioning },
});
