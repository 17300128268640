import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'src/utilities/bem-cn';
import { RemoveScrollBar } from 'react-remove-scroll-bar';

import PropTypes from 'prop-types';
import CloseIcon from 'src/components/icons/X';
import VisibilityTransition from 'src/components/shared/VisibilityTransition';

import './styles.scss';

const className = 'modal-block';
const el = (name, modifier) => cn(className, name, modifier);

class Modal extends React.Component {
	static propTypes = {
		show: PropTypes.bool,
		children: PropTypes.any,
		width: PropTypes.any,
		padding: PropTypes.any,
		removeOverflow: PropTypes.any,
		onClose: PropTypes.func,
		customCloseIcon: PropTypes.any,
		stopMouseDownPropagation: PropTypes.bool,
		customClass: PropTypes.string,
		customModalClass: PropTypes.string,
	};

	static contextTypes = {
		modalContainer: () => {
			/* */
		},
	};

	state = {
		target: null,
	};

	closeModal = e => {
		const { onClose } = this.props;
		const { target } = this.state;
		if (target === e.currentTarget) {
			onClose();
		}
	};

	saveTarget = e => {
		const { stopMouseDownPropagation } = this.props;
		if (stopMouseDownPropagation) {
			e.stopPropagation();
		}

		this.setState({
			target: e.target,
		});
	};

	render() {
		const {
			show,
			children,
			width,
			padding,
			removeOverflow,
			onClose,
			customCloseIcon = false,
			customClass = '',
			customModalClass = '',
		} = this.props;
		const { modalContainer } = this.context;
		const modalContainerRef =
			(modalContainer && modalContainer.ref) ||
			/* We may not have the modal container if we run inside the styleguidist */
			document.body;

		return ReactDOM.createPortal(
			<div className={`${className}${customModalClass ? ` ${customModalClass}` : ''}`}>
				{show && <RemoveScrollBar />}
				<div
					aria-hidden
					className={el('wrap', show ? '' : 'hide')}
					onMouseDown={this.saveTarget}
					onClick={this.closeModal}
				>
					<div
						aria-hidden
						style={{ width, padding }}
						className={el(
							'paper',
							`${show ? '' : 'hide'} ${removeOverflow ? '' : 'overflow'}  ${customClass}`,
						)}
					>
						<VisibilityTransition show={show} hideDelay={200}>
							{children}
						</VisibilityTransition>

						<div aria-hidden className={el('close-sign')} onClick={onClose}>
							{customCloseIcon || <CloseIcon />}
						</div>
					</div>
				</div>
			</div>,
			modalContainerRef,
		);
	}
}

export default Modal;
