import React from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/elements/Button';

import './styles.scss';

const className = 'responsive-modal-footer';

const Footer = ({
	// General
	children,
	// Close button
	onClose,
	closeLabel = '',
	closeState = 'active',
	// Confirm button
	onConfirm,
	confirmLabel = 'Confirm',
	confirmState = 'active',
	confirmType,
	declineLabel = '',
	onDecline,
	declineState = 'active',
	declineType = 'red',
}) => (
	<div className={className}>
		{children || (
			<React.Fragment>
				{closeLabel && <Button state={closeState} label={closeLabel} type="text" onClick={onClose} />}
				{declineLabel && (
					<Button state={declineState} label={declineLabel} onClick={onDecline} type={declineType} />
				)}
				{confirmLabel && (
					<Button state={confirmState} label={confirmLabel} onClick={onConfirm} type={confirmType} />
				)}
			</React.Fragment>
		)}
	</div>
);

Footer.propTypes = {
	// General
	children: PropTypes.object,
	// Close button
	onClose: PropTypes.func,
	closeState: PropTypes.string,
	closeLabel: PropTypes.string,
	// Confirm button
	onConfirm: PropTypes.func,
	confirmState: PropTypes.string,
	confirmLabel: PropTypes.string,
	confirmType: PropTypes.string,
	declineLabel: PropTypes.string,
	declineState: PropTypes.string,
	onDecline: PropTypes.func,
	declineType: PropTypes.string,
};

export default Footer;
