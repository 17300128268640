import { navigate } from 'src/utilities/router/routerScopeLeaker';
import toastr from 'toastr';
import * as services from 'src/services';
import axios from 'src/utilities/axios';
import Cookies from 'src/utilities/cookies';
import * as confirmSignupSelectors from 'src/domains/confirm-signup/selectors';
import * as actions from '../actions';
import * as authActions from '../../auth/actions';

const completeAccountCreation = async (store, { type, payload }) => {
	if (type === actions.COMPLETE_ACCOUNT_CREATION) {
		store.dispatch(actions.setLoading(true));
		const user = confirmSignupSelectors.getUser(store.getState());
		try {
			const { firstName, lastName, accountName, howDidYouHear, whoWillBeUsing, companySize } = payload;
			const email = payload.email || user;
			const PatchResp = await services.userService.createAccount({
				email,
				firstName,
				lastName,
				accountName,
				howDidYouHear,
				whoWillBeUsing,
				companySize,
			});
			store.dispatch(actions.setLoading(false));
			if (PatchResp.status === 200) {
				const { accountUuid, token } = PatchResp.data;
				Cookies.setToken(token);
				axios.setToken(token);
				Cookies.setUpsiideAccountToken(accountUuid);
				store.dispatch(authActions.authenticate());
				store.dispatch(actions.setUuid(accountUuid));

				services.studyService.createDemoStudy(accountUuid);
				navigate('/invite-users');
			}
		} catch (e) {
			toastr.error('Something went wrong, try again later');
		}
	}
};

export default [completeAccountCreation];
