import * as actions from '../actions';

const INITIAL_STATE = {
	firstName: null,
	lastName: null,
	organization: null,
	jobTitle: null,
	howDidYouHear: null,
	whoWillBeUsing: null,
	companySize: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_LOADING:
			return { ...state, loading: action.payload.loading };

		case actions.SET_UUID:
			return { ...state, uuid: action.payload.uuid };

		case actions.COMPLETE_ACCOUNT_CREATION:
			return { ...state, ...action.payload };

		default:
			return state;
	}
};
