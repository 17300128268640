import * as services from 'src/services';
import debounce from 'lodash/debounce';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import Cookies from 'src/utilities/cookies';
import toastr from 'toastr';
import { getClientId } from 'src/domains/auth/selectors';
import * as actions from '../actions';
import { setClientId } from '../../auth/actions';
import * as selectors from '../selectors';
import axios from '../../../utilities/axios';

const PER_PAGE = 24;

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};

const performInitialFetch = (store, { type, payload }) => {
	if (type === actions.PERFORM_INITIAL_FETCH) {
		const { isPublic } = payload;
		const accountCookie = Cookies.getUpsiideAccountToken();

		store.dispatch(actions.fetchClientsFromAccount(accountCookie, isPublic));
		store.dispatch(actions.fetchLanguages(isPublic));
		store.dispatch(actions.fetchGeographies(isPublic));
	}
};

const fetchClients = (store, { type }) => {
	if (type === actions.FETCH_CLIENTS) {
		store.dispatch(actions.incLoading());
		services.clientsService
			.getClients()
			.then(({ data }) => {
				store.dispatch(actions.setClients(data));
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				console.group('Error while fetching clients');
				console.error('error:', error);
				console.groupEnd();
			})
			.then(() => {
				store.dispatch(actions.decLoading());
			});
	}
};

const fetchLanguages = (store, { type, payload }) => {
	if (type === actions.FETCH_LANGUAGES) {
		const { isPublic } = payload;

		const serviceFunction = isPublic
			? services.languageService.getPublicLanguages
			: services.languageService.getLanguages;

		store.dispatch(actions.incLoading());
		serviceFunction()
			.then(({ data }) => {
				store.dispatch(actions.setLanguages(data));
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				console.group('Error while fetching languages');
				console.error('error:', error);
				console.groupEnd();
			})
			.then(() => {
				store.dispatch(actions.decLoading());
			});
	}
};

const fetchGeographies = (store, { type, payload }) => {
	if (type === actions.FETCH_GEOGRAPHIES) {
		const { isPublic } = payload;
		const serviceFunction = isPublic
			? services.languageService.getPublicGeographies
			: services.languageService.getGeographies;

		serviceFunction().then(({ data }) => {
			store.dispatch(actions.setGeographies(data));
		});
	}
};

const fetchStudies = (store, { type, payload }) => {
	if (type === actions.FETCH_STUDIES) {
		const {
			page = 1,
			perPage = PER_PAGE,
			filter = null,
			orderByDirection = 'desc',
			orderBy = 'id',
			isPublic,
		} = payload;
		const studies = selectors.getStudies(store.getState());

		const isLoading = studies.loading;

		if (isLoading) {
			return;
		}

		store.dispatch(actions.setStudies({ loading: true }));
		store.dispatch(actions.setError(null));

		const offset = (page - 1) * perPage;

		services.studyService
			.getStudies({ filter, limit: perPage, offset, orderBy, orderByDirection, isPublic })
			.then(({ data }) => {
				store.dispatch(
					actions.setStudies({
						content: data.items,
						totals: data.totals,
						pagination: data.pagination,
						loading: false,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				store.dispatch(actions.setStudies({ loading: false }));
			});
	}
};

const makeIntoBlueprintOrDuplicateStudy = async (store, { type, payload }) => {
	let studyToDuplicate;
	let copyOfStudiesContent;
	if (type === actions.MAKE_INTO_BLUEPRINT_OR_DUPLICATE_STUDY) {
		const { studyId, clientId, blueprintAccessLevel, studyDuplication, domain } = payload;
		if (studyDuplication) {
			const studies = selectors.getStudies(store.getState());
			studyToDuplicate = { ...studies?.content?.find(study => study.id === studyId) };
			studyToDuplicate = {
				...studyToDuplicate,
				isDuplicating: true,
				id: studyToDuplicate.id + 999999,
				status: 'draft',
				name: studyToDuplicate.name.includes('(Copy)')
					? studyToDuplicate.name
					: `${studyToDuplicate.name} (Copy)`,
			};
			copyOfStudiesContent = [...studies?.content];
			copyOfStudiesContent.unshift(studyToDuplicate);
			await store.dispatch(actions.setStudies({ content: copyOfStudiesContent }));
		} else {
			copyOfStudiesContent = [...(selectors.getStudies(store.getState())?.content || [])];
			const newStudyContent = copyOfStudiesContent.map(study => {
				if (study.id === studyId) {
					return { ...study, isDuplicating: true };
				}
				return study;
			});
			await store.dispatch(actions.setStudies({ content: newStudyContent }));
		}
		await services.blueprintService
			.createBlueprintFromStudyOrDuplicate(studyId, clientId, blueprintAccessLevel, studyDuplication, false)
			.then(res => {
				if (res.status === 200) {
					if (studyDuplication) {
						toastr.success(
							`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
								<p style="font-size: 16px;">Study has been duplicated!</p>
								<a style="color: #008855; padding-left:54px; font-size: 14px; font-weight: 700" href="http://${domain.host}/studies/${
								res?.data?.uuid || res?.data?.id
							}/create">View</a>
							</div>`,
						);
						copyOfStudiesContent.shift();
						copyOfStudiesContent.unshift({
							...studyToDuplicate,
							isDuplicating: false,
							id: res.data.id,
							uuid: res.data?.uuid,
							name: res?.data?.name.includes('(Copy)') ? res?.data?.name : `${res?.data?.name} (Copy)`,
						});
						// store.dispatch(actions.setStudies({ content: copyOfStudiesContent }));
					} else {
						toastr.success('Template successfully created');
						if (domain?.pathname.includes('/studies')) {
							navigate(`/templates/${res?.data?.uuid || res?.data?.id}/create`);
						}
					}
				} else if (studyDuplication) {
					toastr.error('Something went wrong with duplicating the study');
				} else {
					toastr.error('Something went wrong with creating the template');
				}
			});
	}
};

const fetchClientsFromAccount = async (store, { type, payload }) => {
	if (type === actions.FETCH_CLIENTS_FROM_ACCOUNT) {
		const { accountUuid, isPublic } = payload;

		if (isPublic) {
			return;
		}

		const response = await services.accountService.getAccount(accountUuid);
		if (response) {
			const currentClientId = getClientId(store.getState());
			const { data } = response;
			const { clients, ownerUuid } = data;
			clients.map(c => (c.accountUuid = accountUuid));
			store.dispatch(actions.setClientsFromAccount(clients));

			const cookieClientId = Cookies.getClientId();

			const defaultClient = clients.find(client => client.uuid === ownerUuid) || clients[0];
			const cookieClient = clients.find(client => client.uuid === cookieClientId);

			const queryParams = new URLSearchParams(window.location.search);
			const urlClientId = queryParams.get('clientId');

			const clientId = urlClientId || cookieClient?.uuid || defaultClient?.uuid;

			if (!axios?.defaults?.headers?.common['x-client-uuid']) {
				store.dispatch(setClientId(clientId));
				Cookies.setClientId(clientId);
			} else if (!currentClientId && axios?.defaults?.headers?.common['x-client-uuid']) {
				store.dispatch(setClientId(clientId));
				Cookies.setClientId(clientId);
			} else if (currentClientId && defaultClient?.uuid && defaultClient?.uuid !== currentClientId) {
				store.dispatch(setClientId(clientId));
				Cookies.setClientId(clientId);
			}
		}
	}
};

const moveStudy = async (store, { type, payload }) => {
	if (type === actions.MOVE_STUDY) {
		const { studyId, previousClientUuid, newClientUuid } = payload;
		await services.studyService
			.moveStudy(studyId, previousClientUuid, newClientUuid)
			.then(res => {
				if (res.status === 200) {
					toastr.success('Study Moved!');
				} else {
					toastr.error('Error Moving Study');
				}
			})
			.catch(err => {
				toastr.error('Error Moving Study');
			});
	}
};

const createStudy = async (store, { type, payload }) => {
	if (type === actions.CREATE_STUDY) {
		store.dispatch(actions.incLoading());
		store.dispatch(actions.setError(false));
		store.dispatch(actions.setCreateStudyLoading(true));
		const { study, navigateTo } = payload;
		try {
			services.studyService
				.createStudy(study)
				.then(newStudy => {
					navigate(`/studies/${newStudy?.data?.uuid || newStudy?.data?.id}/${navigateTo || 'create'}`);
				})
				.then(() => {
					setTimeout(() => store.dispatch(actions.setCreateStudyLoading(false)), [500]);
					store.dispatch(actions.decLoading());
				});
		} catch (error) {
			store.dispatch(actions.setError(error));
			store.dispatch(actions.decLoading());
			store.dispatch(actions.setCreateStudyLoading(false));
			console.group('Error while creating study');
			console.error('error:', error);
			console.groupEnd();
		}
	}
};

export default [
	performInitialFetch,
	fetchClients,
	fetchLanguages,
	fetchGeographies,
	fetchStudies,
	makeIntoBlueprintOrDuplicateStudy,
	fetchClientsFromAccount,
	moveStudy,
	createStudy,
];
