import React, { useEffect, useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Sticky from 'react-stickynode';
import { Routes, Route, matchPath } from 'react-router-dom';
import withRouter from 'src/hocs/withRouter';
import useIsMobile from 'src/hooks/useIsMobile';
import cn from 'src/utilities/bem-cn';
import { useIsBlueprint } from 'src/hooks';
import HeaderContext from 'src/domains/header/components/shared/HeaderContext';
import GenericHeader from 'src/domains/header/containers/GenericHeader';
import CreateStudyHeader from 'src/domains/header/containers/CreateStudyHeader';
import PersonalAccountDropdown from 'src/domains/header/components/shared/PersonalAccountDropdown';
import AllStudiesPrimary from 'src/domains/header/containers/AllStudies/AllStudiesPrimary';
import ManageStudyPrimary from 'src/domains/header/containers/ManageStudy';
import ManageBlueprints from 'src/domains/header/containers/ManageBlueprints';
import SingleStudyPrimary from 'src/domains/header/containers/SingleStudy';
import PublicReportHeader from 'src/domains/header/containers/PublicReportHeader';
import { PublicHeaderBanner } from 'src/domains/header/components/shared/PublicHeaderBanner';
import ManageTranslation from 'src/domains/header/containers/ManageTranslation';
import ManageBlueprintTranslation from 'src/domains/header/containers/ManageBlueprintTranslation';
import BarWithTabs from 'src/domains/manage-study/containers/BarWithTabs';
import * as selectors from 'src/domains/selectors';
import axios from 'src/utilities/axios';
import LockedHeader from 'src/domains/header/components/shared/LockedHeader';
import * as accountActions from '../../../account/actions';
import * as profileActions from '../../../profile/actions';
import * as accountSelectors from '../../../account/selectors';
import * as profileSelectors from '../../../profile/selectors';
import * as manageBlueprintSelectors from '../../../manage-blueprints/selectors';
import ChargifyAccountSubscriptionBanner from '../../components/shared/ChargifyAccountSubscriptionBanner';
import EmailNotConfirmedBanner from '../../components/shared/EmailNotConfirmedBanner';
import StudyIsLiveBanner from '../StudyIsLiveBanner';
import ManageTemplateBanner from '../ManageTemplateBanner';
import './styles.scss';

const className = 'main-header';
const el = (name, mod) => cn(className, name, mod);

const Header = props => {
	const {
		fetchChargifySubscriptions,
		account,
		loggedIn,
		clientId,
		chargifySubscriptions,
		accounts,
		profileData,
		resentEmailInvitation,
		ghost,
		goToSupport,
		logout,
		user,
		switchToOriginalUser,
		customizeReport,
	} = props;

	const isMobile = useIsMobile();

	const [defaultStudyName, setDefaultStudyName] = useState(null);
	const { isBlueprint } = useIsBlueprint();
	const template = useSelector(manageBlueprintSelectors.getStudy);

	const headerRoutes = [
		{ title: 'Settings', path: 'studies/:id/settings/*', backToAllStudies: false, backTo: -2 },
		{ title: 'Settings', path: 'public/studies/:id/settings/*', backToAllStudies: false, backTo: -2 },
		{ title: 'Account Settings', path: '/account/*', backToAllStudies: true },
		{ title: 'Admin', path: 'admin/*', backToAllStudies: true },
		{ title: 'Client', path: 'client/*', backToAllStudies: false },
		{ title: 'Clients', path: 'clients/*', backToAllStudies: false },
		{ title: 'Profile', path: 'me', backToAllStudies: false },
		{ title: 'Reset Password', path: 'reset-password', backToAllStudies: false },
		{ title: 'Demo Request', path: 'demo-request', backToAllStudies: false },
		{ title: '', path: 'choose-plan', backToAllStudies: false },
		{ title: '', path: 'billing-details', backToAllStudies: false },
	];

	useEffect(() => {
		if (account?.content) {
			const { uuid, chargifyCustomerId } = account.content;
			axios.setAccountUuid(uuid);
			if (loggedIn && chargifyCustomerId) {
				fetchChargifySubscriptions();
			}
		}
	}, []);

	const renderGenericHeaders = () =>
		headerRoutes.map((headerRoute, headerRouteIndex) => (
			<Route
				path={headerRoute.path}
				key={`generic-header-${headerRoute.path}-${headerRouteIndex}`}
				element={
					<GenericHeader
						key={`generic-header-${headerRoute.path}-${headerRouteIndex}`}
						title={headerRoute.title}
						path={headerRoute.path}
						backToAllStudies={headerRoute.backToAllStudies}
						backTo={headerRoute.backTo}
					/>
				}
			/>
		));

	const handleSetDefaultStudyName = useCallback(newDefaultStudyName => setDefaultStudyName(newDefaultStudyName), []);

	const renderCreateStudyFromBlueprintRoutes = () => {
		if (account?.content?.clients?.length) {
			const selectedSpace = account.content.clients.find(client => client.uuid === clientId);

			return (
				<Route
					path="/studies/create"
					element={
						<CreateStudyHeader
							title={`Create a New Study${selectedSpace ? ` in ${selectedSpace?.name}` : ''}`}
							path="/studies/create"
							backToAllStudies
						/>
					}
				/>
			);
		}
		return null;
	};

	const renderGenericManageBlueprintsHeader = () => {
		if (account.content && account.content.clients) {
			const selectedSpace = account.content.clients.find(client => client.uuid === clientId);

			return (
				<Route
					path="templates"
					element={
						<GenericHeader
							title={`Manage Templates${selectedSpace ? ` in ${selectedSpace.name}` : ''}`}
							path="templates"
							backToAllStudies
						/>
					}
				/>
			);
		}
		return null;
	};

	const renderSingleStudyPrimary = () => (
		<Route
			path="studies/:id/*"
			element={
				<SingleStudyPrimary
					path="studies/:id/*"
					defaultStudyName={defaultStudyName}
					setDefaultStudyName={handleSetDefaultStudyName}
				/>
			}
		/>
	);

	const renderManageStudyPrimary = () => (
		<>
			<Route
				path="studies/:id/audiences/create"
				element={
					<ManageStudyPrimary
						path="studies/:id/audiences/create"
						defaultStudyName={defaultStudyName}
						setDefaultStudyName={handleSetDefaultStudyName}
					/>
				}
			/>
			<Route
				path="studies/:id/audiences/*"
				element={
					<ManageStudyPrimary
						path="studies/:id/audiences/*"
						defaultStudyName={defaultStudyName}
						setDefaultStudyName={handleSetDefaultStudyName}
					/>
				}
			/>
			<Route
				path="studies/:id/create/*"
				element={
					<ManageStudyPrimary
						path="studies/:id/create/*"
						defaultStudyName={defaultStudyName}
						setDefaultStudyName={handleSetDefaultStudyName}
					/>
				}
			/>
		</>
	);

	const renderManageBlueprintAudiencesHeader = () => (
		<Route
			path="templates/:id/audiences/*"
			element={
				<ManageBlueprints
					path="templates/:id/audiences/*"
					defaultStudyName={defaultStudyName}
					setDefaultStudyName={setDefaultStudyName}
				/>
			}
		/>
	);
	const renderManageBlueprintPrimary = () => (
		<Route
			path="templates/:id/create/*"
			element={
				<ManageBlueprints
					path="templates/:id/create/*"
					defaultStudyName={defaultStudyName}
					setDefaultStudyName={handleSetDefaultStudyName}
				/>
			}
		/>
	);

	const renderCustomizeReportHeader = () => (
		<Route
			path="studies/:id/*"
			element={
				<GenericHeader title="Customize Report" path="studies/:id/*" showBackButton={false} showDoneButton />
			}
		/>
	);

	const renderManageTranslationRoutes = () => (
		<>
			<Route
				path="studies/:id/translations/*"
				element={<ManageTranslation title="Manage Translations" path="studies/:id/translations/*" />}
			/>
			<Route
				path="templates/:id/translations/*"
				element={
					<ManageBlueprintTranslation
						title="Manage Template Translations"
						path="templates/:id/translations/*"
					/>
				}
			/>
		</>
	);

	const renderPublicStudiesRoutes = () => (
		<>
			<Route path="public/studies" element={<AllStudiesPrimary path="public/studies" loggedIn={loggedIn} />} />
			<Route
				path="public/studies/all"
				element={<AllStudiesPrimary path="public/studies/all" loggedIn={loggedIn} />}
			/>
			<Route path="public/search" element={<AllStudiesPrimary path="public/search" loggedIn={loggedIn} />} />
			<Route path="public/studies/:id/*" element={<PublicReportHeader path="public/studies/:id/*" />} />
		</>
	);

	const getLeftColumnRouter = () => (
		<Col className={`${el('left')} ${isBlueprint && !template ? el('empty-header-placeholder') : ''}`}>
			<Routes primary={false}>
				{/* All Studies Primary */}
				<Route path="studies" element={<AllStudiesPrimary path="studies" loggedIn={loggedIn} />} />
				<Route path="templates" element={<AllStudiesPrimary path="templates" loggedIn={loggedIn} />} />
				<Route path="studies/all" element={<AllStudiesPrimary path="studies/all" loggedIn={loggedIn} />} />
				<Route path="search" element={<AllStudiesPrimary path="studies/search" loggedIn={loggedIn} />} />
				{/* Public Studies */}
				{renderPublicStudiesRoutes()}
				{/* Single Study Primary / Customize Report */}
				{!customizeReport ? renderSingleStudyPrimary() : renderCustomizeReportHeader()}
				{/* Create Study from Template Header */}
				{renderCreateStudyFromBlueprintRoutes()}
				{/* Manage Study Primary */}
				{renderManageStudyPrimary()}
				{/* Manage Templates Primary */}
				{renderManageBlueprintPrimary()}
				{/* Manage Templates Audiences Header */}
				{renderManageBlueprintAudiencesHeader()}
				{/* Manage Translations */}
				{renderManageTranslationRoutes()}
				{/* Generic Headers */}
				{renderGenericHeaders()}
			</Routes>
		</Col>
	);
	const getRightColumnHeader = (isPublicProp = false) => {
		// * If you are not viewing customize report then render the right column
		if (!customizeReport) {
			return (
				// * Otherwise you can render the right column
				<Col className={el('right')}>
					{!isPublicProp && loggedIn && (
						<PersonalAccountDropdown
							account={account}
							accounts={accounts}
							ghost={ghost}
							goToSupport={goToSupport}
							loggedIn={loggedIn}
							logout={logout}
							switchToOriginalUser={switchToOriginalUser}
							user={user}
						/>
					)}
				</Col>
			);
		}
		return false;
	};

	const getAllStudiesSecondaryRouter = () =>
		loggedIn ? (
			<Routes primary={false}>
				<Route path="studies/:id/settings/*" element={<BarWithTabs path="studies/:id/settings/*" replace />} />
			</Routes>
		) : (
			false
		);

	const getLoggedInHeader = () => {
		const config = props;
		const { id: accountId, chargifyCustomerId, settings } = account.content || {};
		const isInviteUsers = window.location.pathname.includes('invite-users');
		const emailConfirmed = profileData?.emailConfirmed;

		return (
			<HeaderContext.Provider value={config}>
				<Sticky enabled innerZ={1000}>
					<Container className={className}>
						{!isMobile &&
							emailConfirmed &&
							chargifyCustomerId &&
							chargifySubscriptions &&
							chargifySubscriptions.length && (
								<ChargifyAccountSubscriptionBanner
									accountId={accountId}
									accounts={accounts}
									settings={settings}
									chargifySubscriptions={chargifySubscriptions}
								/>
							)}
						{!emailConfirmed && !isInviteUsers && (
							<EmailNotConfirmedBanner
								resendLink={() => {
									if (profileData?.email) {
										resentEmailInvitation(profileData?.email);
									}
								}}
							/>
						)}
						<Row>
							<StudyIsLiveBanner />
							<ManageTemplateBanner />
							{getLeftColumnRouter()}
							{getRightColumnHeader()}
						</Row>
						{getAllStudiesSecondaryRouter()}
						<div id="sub-header" />
					</Container>
				</Sticky>
			</HeaderContext.Provider>
		);
	};

	const getPublicHeader = () => {
		const config = props;
		return (
			<HeaderContext.Provider value={config}>
				<Sticky enabled innerZ={1000}>
					<Container className={className}>
						{!loggedIn && <PublicHeaderBanner />}
						<Row>
							{getLeftColumnRouter(true)}
							{getRightColumnHeader(true)}
						</Row>
						{getAllStudiesSecondaryRouter()}
						<div id="sub-header" />
					</Container>
				</Sticky>
			</HeaderContext.Provider>
		);
	};

	const getLockedDefaultHeader = () => (
		<Routes>
			<Route path="*" element={<LockedHeader title="" path="*" />} />
		</Routes>
	);

	const isPublic = /public/.test(window.location.pathname);

	if (isPublic) return getPublicHeader();
	if (loggedIn) return getLoggedInHeader();
	return getLockedDefaultHeader();
};

Header.propTypes = {
	account: PropTypes.any,
	accounts: PropTypes.any,
	ghost: PropTypes.any,
	goToSupport: PropTypes.any,
	user: PropTypes.object,
	logout: PropTypes.func,
	switchToOriginalUser: PropTypes.func,
	loggedIn: PropTypes.bool,
	hasAccount: PropTypes.bool,
	customizeReport: PropTypes.bool,
	clientId: PropTypes.string,
	chargifySubscriptions: PropTypes.any,
	fetchChargifySubscriptions: PropTypes.func,
	profileData: PropTypes.object,
	resentEmailInvitation: PropTypes.func,
};

const mapStateToProps = state => ({
	customizeReport: selectors.getStudyData(state).customizeReport,
	account: selectors.getAccountData(state).account,
	accounts: selectors.getAccountData(state).accounts,
	clientId: selectors.getAuthData(state).clientId,
	chargifySubscriptions: accountSelectors.getChargifySubscription(state),
	profileData: profileSelectors.getUser(state),
});

const mapDispatchToProps = dispatch => ({
	fetchChargifySubscriptions: () => dispatch(accountActions.fetchChargifySubscriptions()),
	resentEmailInvitation: email => dispatch(profileActions.resentEmailInvitation(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
