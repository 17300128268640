const prefix = 'confirm-email';

export const CONFIRM_USER_EMAIL_TOKEN = `${prefix}/CONFIRM_USER_EMAIL_TOKEN`;
export const confirmEmailToken = token => ({ type: CONFIRM_USER_EMAIL_TOKEN, payload: { token } });

export const SET_TOKEN = `${prefix}/SET_TOKEN`;
export const setToken = token => ({ type: SET_TOKEN, payload: { token } });

export const SET_USER = `${prefix}/SET_USER`;
export const setUser = user => ({ type: SET_USER, payload: { user } });

export const SETUP_USER = `${prefix}/SETUP_USER`;
export const setupUser = user => ({ type: SETUP_USER, payload: { user } });

export const SET_LOADING = `${prefix}/SET_LOADING`;
export const setLoading = loading => ({ type: SET_LOADING, payload: { loading } });

export const SET_TOKEN_ERROR = `${prefix}/SET_TOKEN_ERROR`;
export const setTokenError = error => ({ type: SET_TOKEN_ERROR, payload: { error } });

export const SET_IS_SIGNUP_FLOW = `${prefix}/SET_IS_SIGNUP_FLOW`;
export const setIsSignupFlow = isSignupFlow => ({ type: SET_IS_SIGNUP_FLOW, payload: { isSignupFlow } });
