import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Iconof, Show, Typography } from '@upsiide/ui-components';
import { Tooltip } from 'react-tippy';
import cn from 'src/utilities/bem-cn';
import { createBlueprint } from 'src/domains/all-blueprints/actions';

import './styles.scss';

const className = 'templates-selection';
const el = (name, mod) => cn(className, name, mod);

const TemplatesSelection = ({
	onStudyTemplatesSelect,
	onAudienceTemplatesSelect,
	activeTab,
	showCreateTemplateButton = false,
}) => {
	const dispatch = useDispatch();
	const handleCreateBlueprint = defaultLanguage => {
		dispatch(
			createBlueprint({
				defaultLanguage,
			}),
		);
	};

	return (
		<div className={className}>
			<button
				className={`${el('study-templates-button-container')} ${
					activeTab === 'studyTemplate' ? el('active') : ''
				}`}
				onClick={() => onStudyTemplatesSelect()}
				type="button"
			>
				<h4>Study Templates</h4>
				<Show above={960}>
					<Typography variant="p">Pre-populate your study with content</Typography>
				</Show>
				{showCreateTemplateButton && activeTab === 'studyTemplate' && (
					<Show above={960}>
						<Tooltip
							position="top"
							html={<div className={el('create-template-tooltip')}>Create Study Template</div>}
							theme="create-template"
						>
							<Iconof
								icon="add"
								className={el('create-template-button')}
								onClick={() => handleCreateBlueprint('en')}
							/>
						</Tooltip>
					</Show>
				)}
			</button>
			<button
				className={`${el('audience-templates-button-container')} ${
					activeTab === 'audienceTemplate' ? el('active') : ''
				}`}
				onClick={() => onAudienceTemplatesSelect()}
				type="button"
			>
				<h4>Audience Templates</h4>
				<Show above={960}>
					<Typography variant="p">Pre-populate your audience</Typography>
				</Show>
			</button>
		</div>
	);
};

TemplatesSelection.propTypes = {
	onStudyTemplatesSelect: PropTypes.func,
	onAudienceTemplatesSelect: PropTypes.func,
	activeTab: PropTypes.string,
	showCreateTemplateButton: PropTypes.bool,
};

export default TemplatesSelection;
