const sanitizeData = object => {
	for (let i = 0; i < object.logic.length; i += 1) {
		delete object.logic[i].action;
		delete object.logic[i].createdAt;
		delete object.logic[i].updatedAt;
		delete object.logic[i].deletedAt;
	}
};

const getItemLogicExists = (logic, logicItem) => {
	const doesItemLogicExist = logic && logic.length > 0 && logicItem !== undefined;
	return doesItemLogicExist;
};

const getOperatorSelectIndex = logicItem => {
	if (Object.keys(logicItem).length === 0) {
		return 0;
	}
	return logicItem.operator.toLowerCase() === 'is' ? 0 : 1;
};

const getOperandSelectIndex = (question, logicItem) => {
	const isSingleSelect = question.style === 'single-select';
	// If the logic item doesn't have a length just return 0
	if (Object.keys(logicItem).length === 0) {
		return 0;
	}
	// If it is a single select question and the operator === 'is' return 1
	if (isSingleSelect) {
		if (logicItem.operator.toLowerCase() === 'is') {
			return 1;
		}
	}
	// Otherwise just return 0 or 1 depending on what the user chose as the operand
	return logicItem.operand.toLowerCase() === 'and' ? 0 : 1;
};

const getShowDestinationSelect = (newSaveData, question, logicId) => {
	if (question.style === 'open-ended') {
		return false;
	}

	const logicItem = newSaveData?.logic?.find(logic => logic?.id === logicId);
	if (logicItem) {
		return question.id === logicItem.triggerItemId;
	}
	return true;
};

export default {
	sanitizeData,
	getOperatorSelectIndex,
	getOperandSelectIndex,
	getShowDestinationSelect,
	getItemLogicExists,
};
