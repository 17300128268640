import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { useStudyLink } from 'src/hooks/useStudyLink';
import { useIsPublic, useIsBlueprint } from 'src/hooks';

import { Tooltip } from 'react-tippy';
import DemographicStatus from 'src/domains/manage-study/containers/AudienceOverviewDemographicItem/DemographicStatus';
import Input from 'src/components/inputs/Input';
import IconButton from 'src/components/elements/IconButton';
import { countriesWithFlags } from 'src/utilities/upsiideCountriesWithFlag';
import { getCostMarkup } from 'src/domains/checkout/utilities/checkout';

import * as studySelectors from 'src/domains/manage-study/selectors';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import * as studyActions from 'src/domains/manage-study/actions';
import * as manageBlueprintActions from 'src/domains/manage-blueprints/actions';
import * as reportingActions from 'src/domains/manage-study/actions/reporting';

import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Auth from 'src/utilities/auth';
import Cookies from 'src/utilities/cookies';
import { renderAudienceName } from 'src/utilities/misc';
import CONSTANTS from 'src/config/constants';

import arrowIcon from 'public/images/audience/red_arrow_upward.svg';
import yellowInfoCircle from 'public/images/audience/yellow_info.svg';
import EllipsisIcon from 'public/images/audience/EllipsisMenu.png';
import { Iconof } from '@upsiide/ui-components';
import studyUtilities from 'src/utilities/study';
import Chip from '../Chip';

import DigPricingTooltip from '../DigPricingTooltip';
import AudienceResponses from './AudienceResponses';

import './styles.scss';

const className = 'audience-component-header';
const el = (name, mod) => cn(className, name, mod);

const PriceMarkup = ({ status, priceInCents, newPriceInCents }) => {
	const getPricePerResponse = () => (priceInCents ? (priceInCents / 100).toFixed(2) : 0);
	const getNewPricePerResponse = () => (newPriceInCents ? (newPriceInCents / 100).toFixed(2) : 0);

	if (status !== 'system-stopped') {
		return (
			<div>
				<b>${getPricePerResponse()} / </b>
				resp
			</div>
		);
	}

	return (
		<div className={el('res_container-bottom-line')}>
			<img src={arrowIcon} className={el('arrow-up')} alt="arrow icon" />
			<span className={el('old-price')}>${getPricePerResponse()}</span>
			<b>${getNewPricePerResponse()} / </b>
			<span>resp</span>
		</div>
	);
};
PriceMarkup.propTypes = {
	status: PropTypes.string,
	priceInCents: PropTypes.number,
	newPriceInCents: PropTypes.number,
};

const AudienceComponentHeader = ({
	mode,
	audienceCollection = null,
	demographicGroup = null,
	byoGroup = null,
	elementName = '',
	onChangeElementName,
	onChangeResponses,
	renderElipsisMenu,
	sampleSize,
	viewMode,
	inDemographicQuestionsReport = false,
	templateGroupEditMode,
	templatePreviewMode,
	defaultPrice = 0,
}) => {
	const isAdminOrEmployee = Auth.isDigAdmin() || Auth.isDigEmployee();
	const { isPublic } = useIsPublic();
	const { isBlueprint } = useIsBlueprint();
	const dispatch = useDispatch();
	const selectors = isBlueprint ? manageBlueprintSelectors : studySelectors;
	const actions = isBlueprint ? manageBlueprintActions : studyActions;
	const [localResponses, setLocalResponses] = useState(sampleSize);
	const [localElementName, setLocalElementName] = useState('');
	const [showAudienceSubMenu, setShowAudienceSubMenu] = useState(false);
	const [editingBYOTitle, setEditingBYOTitle] = useState(false);
	const lengthOfInterview = useSelector(selectors.getLengthOfInterview);
	const audiencePriceChanges = useSelector(selectors.getAudiencePriceChanges);
	const collection = useSelector(selectors.getAudienceCollection);
	const study = useSelector(selectors.getStudy);
	const priceChangedStudies = useMemo(() => Cookies.getPriceChangedStudies() || {}, []);
	const subMenuRef = useRef(null);

	useEffect(() => {
		if (sampleSize) {
			setLocalResponses(sampleSize);
		}
	}, [sampleSize]);

	const lengthOfInterviewLabel = useMemo(() => {
		if (lengthOfInterview <= 5) return '1-5 minutes';
		if (lengthOfInterview <= 10) return '6-10 minutes';
		if (lengthOfInterview <= 15) return '11-15 minutes';
		return '15+ minutes';
	}, [lengthOfInterview]);

	const countryFlag = useMemo(() => {
		const countryId = demographicGroup?.countryLanguage?.countryId;
		const country = countriesWithFlags.find(({ id }) => id === countryId);
		return country?.flag;
	}, [demographicGroup]);

	const showPriceChangeInfo = useMemo(
		() =>
			(demographicGroup?.provider === 'LUCID' || mode === 'collection') &&
			Object.keys(priceChangedStudies).includes(collection?.content?.uuid) &&
			(!audiencePriceChanges?.audiencesSeenBanner?.includes(demographicGroup?.uuid) || mode === 'collection') &&
			(!!defaultPrice || mode === 'collection'),
		[
			audiencePriceChanges?.audiencesSeenBanner,
			collection?.content?.uuid,
			defaultPrice,
			demographicGroup?.provider,
			demographicGroup?.uuid,
			priceChangedStudies,
			mode,
		],
	);

	useOutsideClick(subMenuRef, () => {
		setShowAudienceSubMenu(false);
	});

	useEffect(() => {
		setLocalElementName(elementName);
	}, [elementName]);

	const renderLoi = useCallback(
		({ isInfo }) => (
			<div
				className={`${el('resp_container')} ${isInfo ? 'info' : ''} ${
					Object.keys(priceChangedStudies).includes(collection?.content?.uuid) &&
					!audiencePriceChanges?.audiencesSeenBanner?.includes(demographicGroup?.uuid)
						? el('alert-border')
						: ''
				} ${el('study-loi-container')}`}
			>
				<div className={el('res_container-bottom-line')}>
					{mode !== 'byo' && (
						<Tooltip
							className={el('tooltip')}
							animation="shift"
							animationFill={false}
							interactive
							trigger="mouseenter"
							position="left"
							theme="overview-price"
							html={
								<div className={el('tooltip-text-container')}>
									<p className={el('price-change-text')}>
										Your response price is calculated by multiplying Study length, Country and IR
									</p>
								</div>
							}
						>
							<Iconof icon="info" className={el('info-icon')} />
						</Tooltip>
					)}
					<span>Study Length</span>
				</div>
				<div>
					<b>{lengthOfInterviewLabel}</b>
				</div>
			</div>
		),
		[
			lengthOfInterviewLabel,
			audiencePriceChanges,
			defaultPrice,
			demographicGroup?.uuid,
			mode,
			priceChangedStudies,
			collection?.content?.uuid,
			showPriceChangeInfo,
		],
	);

	const renderPrice = group => {
		const isLive = group?.status === 'live' || group?.status === 'complete';
		return (
			<div className={el('resp_container')}>
				<span>Price</span>
				<div className={el('price_content')}>
					{(isAdminOrEmployee && !isLive) || (isLive && group.employeeBilling) ? (
						<>
							<DigPricingTooltip
								isLive={isLive}
								priceInCents={group.priceInCents}
								sampleSize={group.sampleSize}
								studyId={group.studyId}
								countryLanguageId={group.countryLanguage.id}
								possiblyEmployeeBilling={group.employeeBilling || !!isAdminOrEmployee}
							/>
							{!isLive ? (
								<PriceMarkup
									status={group?.status}
									priceInCents={group?.priceInCents}
									newPriceInCents={group?.newPriceInCents}
									isAdminOrEmployee={isAdminOrEmployee}
									isLive={isLive}
								/>
							) : null}
						</>
					) : (
						<PriceMarkup
							status={group?.status}
							priceInCents={group?.priceInCents}
							newPriceInCents={group?.newPriceInCents}
							isAdminOrEmployee={isAdminOrEmployee}
							isLive={isLive}
						/>
					)}
					{group?.provider === 'LUCID' &&
						Object.keys(priceChangedStudies).includes(collection?.content?.uuid) &&
						!audiencePriceChanges?.audiencesSeenBanner?.includes(group?.uuid) &&
						!!defaultPrice &&
						group?.priceInCents > defaultPrice && (
							<Tooltip
								className={el('tooltip')}
								animation="shift"
								animationFill={false}
								interactive
								trigger="mouseenter"
								position="bottom"
								theme="overview-price"
								html={
									<div className={el('tooltip-text-container')}>
										<p className={el('price-change-text')}>
											Your study length is {lengthOfInterviewLabel}, Price increased from{' '}
											{getCostMarkup(defaultPrice / 100, null)} to{' '}
											{getCostMarkup(group.priceInCents / 100, null)} per response
										</p>
									</div>
								}
							>
								<Iconof icon="info" className={el('info-icon')} />
							</Tooltip>
						)}
				</div>
			</div>
		);
	};

	const renderOptions = useCallback(() => {
		if (!renderElipsisMenu || viewMode) return null;
		return (
			<div
				ref={subMenuRef}
				className={el('audience-sub-menu-wrapper')}
				onClick={() => {
					setShowAudienceSubMenu(prevState => !prevState);
				}}
				onKeyDown={() => {}}
				tabIndex={0}
				role="button"
			>
				<img className={el('audience-title-img')} src={EllipsisIcon} alt="menu" />
				{showAudienceSubMenu && <div className={el('audience-sub-menu')}>{renderElipsisMenu()}</div>}
			</div>
		);
	}, [renderElipsisMenu, showAudienceSubMenu]);

	const renderCollectionOverviewMode = useCallback(() => {
		if (!audienceCollection) return null;
		const showLiveCircle = audienceCollection?.demographicGroups?.some(gr => gr?.status === 'live');
		const hasDemographicGroups = !!audienceCollection?.demographicGroups?.length;
		const isSystemStopped = !!audienceCollection?.demographicGroups?.find(gr => gr?.status === 'system-stopped');
		const allocated = audienceCollection?.demographicGroups?.reduce(
			(acc, val) => acc + (val?.currentSample || 0),
			0,
		);
		// this is to seperate the percentage circle calculation in the responses bubble which only deals with audience groups with sample size from the actual responses number which includes byo audience groups without sample size
		const allocatedForPercentCircle = audienceCollection?.demographicGroups?.reduce(
			(acc, val) => acc + (val?.provider === 'BYO' && val?.sampleSize === 0 ? 0 : val?.currentSample || 0),
			0,
		);
		const total = audienceCollection?.demographicGroups?.reduce(
			(acc, val) => acc + (Number(val?.sampleSize) || 0),
			0,
		);

		const percentage =
			total === 0 ? 100 : Math.floor(allocatedForPercentCircle ? (allocatedForPercentCircle / total) * 100 : 0);
		const isInfo = lengthOfInterview > 10;
		return (
			<div className={el('container')}>
				<div className={el('header-top-line')}>
					<div className={el('header-top-line-left')}>
						{Auth.userCan('study:update') ? (
							<Input
								className={el('audience_input_title')}
								value={localElementName}
								onChange={setLocalElementName}
								onBlur={onChangeElementName}
								placeholder={CONSTANTS.placeholderNames.audienceCollection}
								maxLength={85}
							/>
						) : (
							<p className={el('audience_title')}>{localElementName}</p>
						)}
					</div>
					{Auth.userCan('study:update') ? (
						<div className={el('header-top-line-right')}>{renderOptions()}</div>
					) : null}
				</div>
				{hasDemographicGroups ? (
					<div className={el('header-bottom-line')}>
						{renderLoi({ isInfo })}

						<AudienceResponses
							mode={mode}
							isLive={false}
							isDraft={false}
							viewMode={false}
							hasLiveCircle={showLiveCircle}
							totalSample={total}
							currentSample={allocated}
							sampleSize={sampleSize}
							onChangeResponses={onChangeResponses}
							inDemographicQuestionsReport={inDemographicQuestionsReport}
							percentage={percentage}
						/>
					</div>
				) : (
					<div className={el('header-top-line')}> </div>
				)}
			</div>
		);
	}, [audienceCollection, localElementName, onChangeElementName, renderLoi, renderOptions, lengthOfInterview]);

	const renderDemographicGroupMode = useCallback(() => {
		const isLive = demographicGroup?.status === 'live' || demographicGroup?.status === 'complete';
		const showLiveCircle = demographicGroup?.status === 'live';
		const isDraft = demographicGroup?.status === 'ready';
		const isSystemStopped = demographicGroup?.status === 'system-stopped';
		const isGroupTemplate = !demographicGroup?.audienceCollectionId;
		const isCollectionTemplate = isPublic || !isGroupTemplate ? false : demographicGroup?.studyId !== study?.id;
		const allocated = demographicGroup?.currentSample;
		const total = demographicGroup?.sampleSize || 1;
		const percentage = isCollectionTemplate ? false : Math.floor(allocated ? (allocated / total) * 100 : 0);
		const languageCodeLabel = demographicGroup?.languageCode?.toUpperCase() || false;

		if (isGroupTemplate) return null;
		return (
			<div className={el('container')}>
				{!isCollectionTemplate && !isGroupTemplate && !templatePreviewMode ? (
					<div className={el('header-top-line')}>
						<div className={el('header-top-line-left')}>
							{!(templateGroupEditMode || templateGroupEditMode) && (
								<DemographicStatus group={demographicGroup} customClass={el('status')} />
							)}
							{!viewMode && !inDemographicQuestionsReport ? (
								<Input
									className={el('audience_input_title')}
									value={localElementName}
									onChange={setLocalElementName}
									onBlur={onChangeElementName}
									placeholder={
										languageCodeLabel
											? `${demographicGroup?.countryLanguage?.countryLabel} - ${demographicGroup?.countryLanguage?.languageLabel}`
											: 'Untitled Group'
									}
									style={{
										width: `calc(${localElementName.length} * 12px)`,
									}}
									maxLength={80}
								/>
							) : (
								<span className={el('audience_title')}>{renderAudienceName(demographicGroup)}</span>
							)}
							<div className={el('tag-group')}>
								{countryFlag ? <Chip>{countryFlag}</Chip> : null}
								{languageCodeLabel ? <Chip>{languageCodeLabel}</Chip> : null}
								{demographicGroup?.launchedBy?.length ? (
									<Tooltip
										className={el('tooltip')}
										animation="shift"
										animationFill={false}
										interactive
										trigger="mouseenter"
										position="bottom"
										theme="overview-price"
										distance={2}
										html={
											<div className={el('tooltip-text-container')}>
												<p>Launched on</p>
												<div className={el('tooltip-timestamp')}>
													{studyUtilities.reformatDate(demographicGroup?.launchedAt)}
												</div>
												{demographicGroup?.closedAt ? (
													<>
														<p style={{ marginTop: 8 }}>Completed on</p>
														<div className={el('tooltip-timestamp')}>
															{studyUtilities.reformatDate(demographicGroup?.closedAt)}
														</div>
													</>
												) : null}
											</div>
										}
									>
										<span className={el('tag-item')}>🚀 {demographicGroup?.launchedBy?.replace(/\bnull\b/g, '')}</span>
									</Tooltip>
								) : null}
							</div>
						</div>
						<div className={el('header-top-line-right')}>
							{viewMode || templateGroupEditMode ? null : (
								<IconButton
									icon="play_circle_outlined"
									onClick={() => {
										onPreviewMode(demographicGroup);
									}}
									tooltip="Preview Group"
									size="large"
								/>
							)}
							{Auth.userCan('study:update') && renderOptions()}
						</div>
					</div>
				) : null}
				<div className={el('header-bottom-line')}>
					{isCollectionTemplate ? null : renderLoi({ isInfo: false })}
					{renderPrice(demographicGroup)}
					<AudienceResponses
						mode={mode}
						isLive={isLive}
						isDraft={isDraft}
						viewMode={viewMode}
						isSystemStopped={isSystemStopped}
						hasLiveCircle={showLiveCircle}
						totalSample={total}
						currentSample={allocated}
						sampleSize={sampleSize}
						onChangeResponses={onChangeResponses}
						inDemographicQuestionsReport={inDemographicQuestionsReport}
						percentage={percentage}
					/>
				</div>
			</div>
		);
	}, [
		demographicGroup,
		onChangeElementName,
		localElementName,
		renderLoi,
		renderOptions,
		onPreviewMode,
		viewMode,
		study,
		isPublic,
	]);

	const renderBYOMode = useCallback(() => {
		const allocated = byoGroup?.currentSample;
		const languageCodeLabel = byoGroup?.languageCode?.toUpperCase() || false;
		return (
			<div className={el('container')}>
				<div className={el('header-top-line')}>
					<div className={el('header-top-line-left')}>
						<DemographicStatus group={byoGroup} />
						{!viewMode && !inDemographicQuestionsReport ? (
							<Input
								className={el('audience_input_title')}
								value={localElementName}
								onChange={setLocalElementName}
								onBlur={e => {
									onChangeElementName(e);
									setEditingBYOTitle(false);
								}}
								placeholder="Name your group"
								onFocus={() => {
									setEditingBYOTitle(true);
								}}
								maxLength={50}
								isCharacterCount={editingBYOTitle}
								style={{
									width: `calc(${localElementName.length} * 12px)`,
								}}
							/>
						) : (
							<span className={el('audience_title')}>{localElementName}</span>
						)}
						<div className={el('tag-group')}>
							{languageCodeLabel ? <Chip>{languageCodeLabel}</Chip> : null}
							{byoGroup?.launchedBy?.length ? (
								<Tooltip
									className={el('tooltip')}
									animation="shift"
									animationFill={false}
									interactive
									trigger="mouseenter"
									position="bottom"
									theme="overview-price"
									distance={2}
									html={
										<div className={el('tooltip-text-container')}>
											<p>Launched on</p>
											<div className={el('tooltip-timestamp')}>
												{studyUtilities.reformatDate(byoGroup?.launchedAt)}
											</div>
											{byoGroup?.closedAt ? (
												<>
													<p style={{ marginTop: 8 }}>Completed on</p>
													<div className={el('tooltip-timestamp')}>
														{studyUtilities.reformatDate(byoGroup?.closedAt)}
													</div>
												</>
											) : null}
										</div>
									}
								>
									<Chip>🚀 {byoGroup?.launchedBy}</Chip>
								</Tooltip>
							) : null}
						</div>
					</div>
					<div className={el('header-top-line-right')}>
						<IconButton
							icon="play_circle_outlined"
							onClick={() => {
								onPreviewMode(byoGroup);
							}}
							tooltip="Preview Group"
							size="large"
						/>
						{Auth.userCan('study:update') && renderOptions()}
					</div>
				</div>
				<div className={el('header-bottom-line')}>
					{renderLoi({ isInfo: false })}
					<AudienceResponses
						mode={mode}
						isLive={false}
						isDraft={false}
						viewMode={false}
						isSystemStopped={false}
						hasLiveCircle={false}
						totalSample={byoGroup?.sampleSize || '∞'}
						currentSample={allocated}
						sampleSize={sampleSize}
						onChangeResponses={onChangeResponses}
						inDemographicQuestionsReport={inDemographicQuestionsReport}
						percentage={false}
					/>
				</div>
			</div>
		);
	}, [byoGroup, onChangeElementName, localElementName, renderLoi, renderOptions, onPreviewMode, editingBYOTitle]);

	const renderSubComponent = useCallback(() => {
		switch (mode) {
			case 'collection':
				return renderCollectionOverviewMode();
			case 'group':
				return renderDemographicGroupMode();
			case 'byo':
				return renderBYOMode();
			default:
				return null;
		}
	}, [mode, renderCollectionOverviewMode, renderBYOMode, renderDemographicGroupMode]);

	const onPreviewMode = useCallback(
		previewedAudience => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const previewRedirectUrl = useStudyLink({
				study,
				audience: previewedAudience,
				language: study.language,
				shortLinkType: 'preview',
				isIframe: true,
			});
			// set audience on redux
			dispatch(actions.setAudience({ content: previewedAudience }));
			if (inDemographicQuestionsReport) {
				dispatch(reportingActions.setShowDevicePreviewMode(true));
			} else {
				dispatch(actions.setShowDevicePreviewMode(true));
			}
			dispatch(actions.setDevicePreviewMode('mobile'));
			dispatch(actions.setDevicePreviewUrl(previewRedirectUrl));
		},
		[dispatch, study, inDemographicQuestionsReport, actions],
	);

	return <div className={className}>{renderSubComponent()}</div>;
};

AudienceComponentHeader.propTypes = {
	mode: PropTypes.any,
	audienceCollection: PropTypes.any,
	demographicGroup: PropTypes.any,
	byoGroup: PropTypes.any,
	elementName: PropTypes.any,
	onChangeElementName: PropTypes.any,
	onChangeResponses: PropTypes.any,
	renderElipsisMenu: PropTypes.any,
	sampleSize: PropTypes.any,
	viewMode: PropTypes.any,
	inDemographicQuestionsReport: PropTypes.bool,
	templateGroupEditMode: PropTypes.bool,
	templatePreviewMode: PropTypes.bool,
	defaultPrice: PropTypes.number,
};

export default AudienceComponentHeader;
