import * as actions from '../actions';

const INITIAL_STATE = {
	token: null,
	user: null,
	loading: true,
	tokenError: null,
	isSignupFlow: true,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_TOKEN:
			return { ...state, token: action.payload.token };

		case actions.SET_USER:
			return { ...state, user: action.payload.user };

		case actions.SET_LOADING:
			return { ...state, loading: action.payload.loading };

		case actions.SET_TOKEN_ERROR:
			return { ...state, tokenError: action.payload.error };

		case actions.SET_IS_SIGNUP_FLOW:
			return { ...state, isSignupFlow: action.payload.isSignupFlow };

		default:
			return state;
	}
};
