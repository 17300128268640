import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { flatten, sortBy } from 'lodash';
import { TextBlockPlaceholder, Iconof, Show } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import studyService from 'src/services/study.service';
import auth from 'src/utilities/auth';
import StudyTagManager from 'src/components/shared/StudyTagManager';
import PropTypes from 'prop-types';
import { useIsPublic } from 'src/hooks';
import useIsMobile from 'src/hooks/useIsMobile';
import SelectSpace from 'src/domains/all-studies/components/SelectSpace';
import { Table, TableHead, TableBody, TableRow, TableCell } from 'src/components/shared/Table';
import { WrapperComponent } from 'src/components/shared/SearchResults';
import SearchResultsOrder from '../shared/SearchResultsOrder';
import { urlParams, tableClassName, SearchHeader, FilterDropdownButton, FilteredBy } from '../index';
import SearchResultsContext from '../context';
import { useFetch } from '../hooks';
import ProductReportDonutChart from '../../../../domains/manage-study/components/reporting/ProductsGrid/ProductReportDonutChart';
import StudyTagsAndTemplates, { StudyTag, StudyTagChip, TagsWrapper } from '../shared/StudyTagsAndTemplates';
import IdeaAsset from '../shared/IdeaAsset';
import IdeaDescription from '../shared/IdeaDescription';
import { useSearchHistory } from '../../SmartSearch/hooks';
import ProductDetailsTooltip from '../../../../domains/manage-study/components/reporting/ProductDetailsTooltip';
import { FilterDropdown } from '../../FilterDropdown';
import { Highlighted } from '../../SmartSearch';
import {
	upsertProductTags,
	upsertProduct,
	upsertStudy,
	upsertStudyTags,
} from '../../../../domains/all-studies/actions';
import { getProductById } from '../../../../domains/all-studies/selectors';
import RevisedAt from '../shared/RevisedAt';
import LineClampTooltip from '../../LineClampTooltip';
import EmptySearchResults from '../shared/EmptySearchResults';
import { StudyLink } from '../Studies/index';
import './styles.scss';

const LoadingComponent = ({ limit }) => {
	const rows = [];

	for (let i = 0; i < limit; i += 1) {
		rows.push(
			<TableRow key={`key-${i}`}>
				<TableCell>
					<TextBlockPlaceholder color="#F4F4F4" rows={2} />
				</TableCell>
				<TableCell>
					<TextBlockPlaceholder color="#F4F4F4" rows={1} />
				</TableCell>
				<TableCell>
					<TextBlockPlaceholder color="#F4F4F4" rows={1} />
				</TableCell>
				<Show above={960}>
					<TableCell>
						<TextBlockPlaceholder color="#F4F4F4" rows={1} />
					</TableCell>
					<TableCell>
						<TextBlockPlaceholder color="#F4F4F4" rows={1} />
					</TableCell>
				</Show>
			</TableRow>,
		);
	}
	return rows;
};
LoadingComponent.propTypes = {
	limit: PropTypes.number,
};

const IdeaLink = ({ idea, study, sectionUuid, searchString }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const isMobile = useIsMobile();
	const { isPublic } = useIsPublic();

	if (isMobile) {
		return (
			<span className={el('study-name')}>
				<span className={el('idea-title')}>
					<Highlighted
						text={idea?.name}
						highlight={searchString}
						highlightClassName={el('highlighted-result')}
					/>
				</span>
			</span>
		);
	}

	return (
		<Link
			to={`${isPublic ? '/public' : ''}/studies/${study?.uuid || study?.id}/${
				['active', 'closed', 'needs-review'].includes(study?.status) ? 'report' : 'create'
			}/sections/${sectionUuid}`}
			className={el('study-name')}
		>
			<span className={el('idea-title')}>
				<Highlighted text={idea?.name} highlight={searchString} highlightClassName={el('highlighted-result')} />
			</span>
		</Link>
	);
};
IdeaLink.propTypes = {
	idea: PropTypes.object,
	study: PropTypes.object,
	sectionUuid: PropTypes.string,
	searchString: PropTypes.string,
};

const TableCellWithIdeaTooltip = ({ study, sectionUuid, idea, ideaType, navigateToIdea }) => {
	const isMobile = useIsMobile();

	const itemRef = useRef(null);
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const [searchParams, setSearchParams] = useSearchParams();
	const searchString = searchParams.get(urlParams.query);

	const [show, setShow] = useState(false);

	const sectionType = ideaType === 'swipe' ? 'Idea Screen' : 'Idea Split';

	const ideaData = {
		...idea,
		idea: idea?.ideaScore,
		interest: idea?.interestScore,
		commitment: idea?.commitmentScore,
	};

	return (
		<TableCell onClick={() => navigateToIdea(idea)} customClass={el('table-cell', show ? 'open-idea-tooltip' : '')}>
			<div className={el('idea-title-wrapper')}>
				<div
					onKeyDown={() => {}}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						if (sectionType === 'Idea Screen') setShow(true);
					}}
					tabIndex={0}
					role="button"
					ref={itemRef}
				>
					<IdeaAsset {...idea} />
				</div>
				<div className={el('idea-description-wrapper')}>
					<span className={el('idea-type')}>{sectionType}</span>
					<LineClampTooltip tooltipHtml={idea?.name}>
						<IdeaLink idea={idea} study={study} sectionUuid={sectionUuid} searchString={searchString} />
					</LineClampTooltip>
					<IdeaDescription highlightClassName="results-table__highlighted-result" {...idea} />
				</div>
			</div>

			<ProductDetailsTooltip
				show={show}
				product={ideaData}
				study={study}
				currentSection={{}}
				productNorms={[]}
				attachTo={itemRef.current}
				onClose={() => {
					setShow(false);
				}}
				modal={!!isMobile}
			/>
		</TableCell>
	);
};
TableCellWithIdeaTooltip.propTypes = {
	study: PropTypes.object,
	sectionUuid: PropTypes.string,
	idea: PropTypes.object,
	handleOnClick: PropTypes.func,
	ideaType: PropTypes.string,
	navigateToIdea: PropTypes.func,
};

const StudyTagManagerComp = ({ study, item, children }) => (
	<StudyTagManager
		studyId={study?.id}
		productId={item?.id}
		localProductId={item?.localProductId}
		currentTags={item?.tags || []}
		startIcon={<Iconof icon="add_circle" />}
	>
		{children}
	</StudyTagManager>
);
StudyTagManagerComp.propTypes = {
	study: PropTypes.object,
	item: PropTypes.object,
	children: PropTypes.any,
};

const IdeaTags = ({ item, study }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);
	const { isPublic } = useIsPublic();

	const sortedTags = useMemo(() => {
		const allTags = sortBy(item?.tags || [], 'label');

		return { allTags };
	}, [item]);

	return (
		<div>
			<TagsWrapper
				study={study}
				item={item}
				TagManagerComp={StudyTagManagerComp}
				canEdit={auth.userCan('study:update')}
				displayAddButton={auth.userCan('study:update')}
			>
				{sortedTags?.allTags?.map(tag => (
					<StudyTag
						key={tag?.id}
						productId={item?.id}
						localProductId={item?.localProductId}
						studyId={study?.id}
						tagId={tag?.id}
						canUpdate={false}
						hiddenComponent={<StudyTagChip tag={tag} canUpdate={false} isPublic={isPublic} />}
					/>
				))}
			</TagsWrapper>
		</div>
	);
};
IdeaTags.propTypes = {
	item: PropTypes.object,
	study: PropTypes.object,
};

const IdeaTableRow = React.memo(({ productId, sectionUuid, study, ideaType }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const isMobile = useIsMobile();
	const navigate = useNavigate();

	const searchHistory = useSearchHistory();

	const { isPublic } = useIsPublic();

	const idea = useSelector(state => getProductById(state, productId));

	const handleOnClick = useCallback(
		(ideaId, studyTranslationId) => {
			searchHistory.addHistory({ objectId: ideaId, type: 'idea', studyTranslationId });
		},
		[searchHistory],
	);

	const ideaLink = `${isPublic ? '/public' : ''}/studies/${study?.uuid || study?.id}/${
		['active', 'closed', 'needs-review'].includes(study?.status) ? 'report' : 'create'
	}/sections/${sectionUuid}`;

	const navigateToIdea = useCallback(
		ideaData => {
			if (!isMobile) {
				handleOnClick(ideaData?.id, ideaData?.studyTranslationId);
				navigate(ideaLink);
			}
		},
		[handleOnClick, ideaLink, isMobile, navigate],
	);

	const handleIdeaNavigation = useCallback(
		(e, ideaData) => {
			if (
				typeof e.target.className?.includes === 'function' &&
				(e.target.className?.includes('table__cell') || e.target.className?.includes('table-cell-clickable'))
			) {
				e.preventDefault();
				e.stopPropagation();
				navigateToIdea(ideaData);
			}
		},
		[navigateToIdea],
	);

	if (!idea) {
		return '...loading';
	}

	return (
		<TableRow customClass={el('row-with-link')} key={idea?.id} onClick={e => handleIdeaNavigation(e, idea)}>
			<TableCellWithIdeaTooltip
				study={study}
				idea={idea}
				ideaType={ideaType}
				sectionUuid={sectionUuid}
				navigateToIdea={navigateToIdea}
			/>
			<TableCell customClass={el('study-details-table-cell')}>
				<div>
					<div className={el('study-details-info')}>
						<LineClampTooltip tooltipHtml={study?.name}>
							<StudyLink
								study={study}
								studyLink={ideaLink}
								handleOnClick={() => handleOnClick(idea?.id, idea?.studyTranslationId)}
							/>
						</LineClampTooltip>
						<StudyTagsAndTemplates studyId={study?.id} canEdit={auth.userCan('study:update')} />
					</div>
				</div>
			</TableCell>
			<Show above={960}>
				<TableCell onClick={() => navigateToIdea(idea)}>
					<span className={el('revised-at')}>
						<RevisedAt date={study?.revisedAt} />
					</span>
				</TableCell>
			</Show>
			<TableCell
				onClick={() => navigateToIdea(idea)}
				customClass={el('idea-donut-wrapper', !idea?.ideaScore || idea?.ideaScore === 0 ? 'no-score' : '')}
			>
				{ideaType === 'swipe' && idea?.ideaScore > 0 ? (
					<ProductReportDonutChart data={{ label: null, value: idea?.ideaScore || 0 }} />
				) : (
					<span className={el('no-idea-score')}>-</span>
				)}
			</TableCell>
			<Show above={960}>
				<TableCell customClass={el('study-details-table-cell')}>
					<IdeaTags key={idea?.id} item={idea} study={study} />
				</TableCell>
			</Show>
		</TableRow>
	);
});
IdeaTableRow.displayName = 'IdeaTableRow';
IdeaTableRow.propTypes = {
	productId: PropTypes.number,
	sectionUuid: PropTypes.string,
	study: PropTypes.object,
	ideaType: PropTypes.string,
};

const Ideas = ({ limit = 20, pagination = false, hidden = false, wrapperStyle }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const dispatch = useDispatch();

	const paginationInputRef = useRef(null);
	const timeoutRef = useRef();

	const { setTotals } = useContext(SearchResultsContext);

	const { isPublic } = useIsPublic();
	const [searchParams, setSearchParams] = useSearchParams();
	const searchString = searchParams.get(urlParams.query);

	const [ideaData, setIdeaData] = useState(null);
	const [loading, setLoading] = useState(true);

	// const ideaData = useSelector(getIdeas) || {};
	const ideas = ideaData?.items || [];

	// Pagination
	const currentPage = searchParams.get(urlParams.ideaPage) || 1;
	const paginationData = ideaData?.pagination || {};
	const paginationPages = paginationData?.pageCount;

	// sort order
	const orderBy = searchParams.get(urlParams.ideaOrderBy) || 'revisedAt';
	const orderByDirection = searchParams.get(urlParams.ideaOrderByDirection) || 'desc';

	const fetchIdeasCallback = useCallback(async () => {
		setLoading(true);
		const offset = (currentPage - 1) * limit;

		let applyFilters = { filter: {} };

		// Filter dropdown filters (country, language, tags, templates)
		const filters = searchParams.get(urlParams.filters);
		const parsedFilters = JSON.parse(filters);
		const finalFilters = {};

		if (parsedFilters?.ideaType) {
			finalFilters.ideaType = parsedFilters.ideaType;
		}
		if (parsedFilters?.language) {
			finalFilters.language = parsedFilters.language;
		}
		if (parsedFilters?.ideaTag) {
			finalFilters.tag = parsedFilters.ideaTag;
		}
		if (parsedFilters?.studyTag) {
			finalFilters.studyTag = parsedFilters.studyTag;
		}

		if (filters) {
			applyFilters = {
				...applyFilters,
				filter: {
					...applyFilters.filter,
					...finalFilters,
				},
			};
		}

		let serviceFunction = studyService.getStudies;

		let serviceFunctionProps = {
			limit,
			offset,
			orderBy,
			orderByDirection,
		};

		serviceFunction = studyService.searchStudies;

		applyFilters = {
			...applyFilters,
			search: searchString,
			type: 'idea',
		};

		serviceFunctionProps = {
			...serviceFunctionProps,
			searchString: searchString || '',
		};

		serviceFunctionProps = {
			...serviceFunctionProps,
			filter: applyFilters,
			isPublic,
		};

		const response = await serviceFunction(serviceFunctionProps);

		// upsert data!
		const productResponseData = response?.data?.items?.map(item => item?.product);
		const studyResponseData = response?.data?.items?.map(item => item?.study);

		dispatch(upsertProduct(productResponseData));
		dispatch(upsertStudy(studyResponseData));

		// get all tags and upsert them to state
		const allProductTags = productResponseData?.map(product => product?.tags || []);
		const allStudyTags = studyResponseData?.map(study => study?.tags || []);
		dispatch(upsertProductTags(flatten(allProductTags)));
		dispatch(upsertStudyTags(flatten(allStudyTags)));

		setTotals(prev => ({
			...prev,
			ideas: response?.data?.pagination?.totalCount || 0,
		}));

		setIdeaData(response?.data);
		setLoading(false);
	}, [currentPage, dispatch, isPublic, limit, orderBy, orderByDirection, searchParams, searchString, setTotals]);

	const handlePrevPage = useCallback(() => {
		setSearchParams(params => {
			const current = Number(params.get(urlParams.ideaPage)) || 1;
			const prevPage = current - 1;
			const newPage = Math.max(1, prevPage);

			params.set(urlParams.ideaPage, newPage);
			paginationInputRef.current.value = newPage;
			return params;
		});
	}, [setSearchParams]);

	const handleNextPage = useCallback(() => {
		setSearchParams(params => {
			const current = Number(params.get(urlParams.ideaPage)) || 1;
			const newPage = Math.min(paginationData.pageCount, current + 1);

			params.set(urlParams.ideaPage, newPage);
			paginationInputRef.current.value = newPage;

			return params;
		});
	}, [paginationData.pageCount, setSearchParams]);

	const handlePaginationChange = e => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		let value = Number(e.target.value);
		if (!e.target.value || Number(e.target.value) < 1) {
			paginationInputRef.current.value = 1;
			value = 1;
		}
		if (Number(e.target.value) > paginationPages) {
			paginationInputRef.current.value = paginationPages;
			value = paginationPages;
		}

		timeoutRef.current = setTimeout(() => {
			setSearchParams(params => {
				params.set(urlParams.ideaPage, value);
				return params;
			});
		}, 100);
	};

	const handleChangeOrder = useCallback(
		(type, direction) => {
			setSearchParams(params => {
				params.set(urlParams.ideaOrderByDirection, direction);
				params.set(urlParams.ideaOrderBy, type);
				params.set(urlParams.ideaPage, 1);

				return params;
			});
		},
		[setSearchParams],
	);

	// searchParams Change refetch ideas
	useFetch({ callback: fetchIdeasCallback, type: 'ideas' });

	if (hidden) {
		return null;
	}

	const cellWidths = {
		idea: '25%',
		study: '25%',
		revised: '12%',
		ideaScore: '14%',
		ideaTags: '24%',
	};

	return (
		<>
			<Show below={961}>
				<SelectSpace />
			</Show>

			<WrapperComponent>
				<div className={el('results-table-wrapper', wrapperStyle)}>
					<div className={el('header')}>
						<SearchHeader />

						<div className={el('filters-container')}>
							<Show above={960}>
								<SelectSpace />
							</Show>

							<FilterDropdown ButtonComponent={FilterDropdownButton} />
						</div>
					</div>

					<FilteredBy />

					{!loading && !ideas?.length ? (
						<EmptySearchResults />
					) : (
						<>
							<div className="table-overflow-wrapper ideas">
								<Table customClass={`${className} ideas-table`}>
									<TableHead>
										<TableRow>
											<TableCell header style={{ width: cellWidths.idea }}>
												<div className={el('order-cell-wrapper')}>
													<SearchResultsOrder
														label="Idea"
														isActive={orderBy === 'ideaName'}
														currentOrderDirection={orderByDirection}
														type="ideaName"
														handleChangeOrder={handleChangeOrder}
													/>
												</div>
											</TableCell>
											<TableCell header style={{ width: cellWidths.study }}>
												<div className={el('order-cell-wrapper')}>
													<SearchResultsOrder
														label="Study"
														isActive={orderBy === 'studyName'}
														currentOrderDirection={orderByDirection}
														type="studyName"
														handleChangeOrder={handleChangeOrder}
													/>
												</div>
											</TableCell>
											<Show above={960}>
												<TableCell header style={{ width: cellWidths.revised }}>
													<div className={el('order-cell-wrapper')}>
														<SearchResultsOrder
															label="Revised"
															isActive={orderBy === 'revisedAt'}
															currentOrderDirection={orderByDirection}
															type="revisedAt"
															handleChangeOrder={handleChangeOrder}
														/>
													</div>
												</TableCell>
											</Show>
											<TableCell header style={{ width: cellWidths.ideaScore }}>
												<div className={el('order-cell-wrapper')}>
													<SearchResultsOrder
														label="Idea Score"
														isActive={orderBy === 'ideaScore'}
														currentOrderDirection={orderByDirection}
														type="ideaScore"
														handleChangeOrder={handleChangeOrder}
													/>
												</div>
											</TableCell>
											<Show above={960}>
												<TableCell header style={{ width: cellWidths.ideaTags }}>
													Idea Tags
												</TableCell>
											</Show>
										</TableRow>
									</TableHead>
									{loading ? (
										<TableBody>
											<LoadingComponent limit={limit} />
										</TableBody>
									) : (
										<TableBody>
											{ideas?.map(item => (
												<IdeaTableRow
													key={`${item?.product?.id}-${item?.product?.sectionUuid}-${item?.product?.studyTranslationId}`}
													productId={item?.product?.id}
													ideaType={item?.product?.sectionType}
													sectionUuid={item?.product?.sectionUuid}
													study={item?.study}
												/>
											))}
										</TableBody>
									)}
								</Table>
							</div>

							{pagination ? (
								<div className={el('pagination')}>
									<div>
										<Iconof icon="chevron_left" onClick={handlePrevPage} />
									</div>
									<div className={el('pagination-pages')}>
										<span>Page</span>
										<input
											ref={paginationInputRef}
											className={el('pagination-current-page')}
											defaultValue={currentPage}
											type="number"
											max={paginationPages}
											min={1}
											onChange={handlePaginationChange}
										/>
										<span>of</span>
										<span>{paginationPages}</span>
									</div>
									<div>
										<Iconof icon="chevron_right" onClick={handleNextPage} />
									</div>
								</div>
							) : null}
						</>
					)}
				</div>
			</WrapperComponent>
		</>
	);
};

Ideas.propTypes = {
	limit: PropTypes.number,
	pagination: PropTypes.bool,
	hidden: PropTypes.bool,
	wrapperStyle: PropTypes.string,
};

export default Ideas;
