import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { FormField, Iconof, Typography } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import { useIsMobile } from 'src/hooks';
import './styles.scss';

const className = 'upsiide-select';
const el = (name, modifier) => cn(className, name, modifier);

const SelectWrapper = React.forwardRef(
	(
		{
			className: customClasses,
			value,
			options,
			onChange,
			isMulti,
			isLoading,
			styles,
			theme,
			autoFocus,
			placeholder,
			openMenuOnFocus,
			title,
			mobileStyle = 'checkbox',
			...rest
		},
		ref,
	) => {
		const [show, setShow] = useState(false);
		const [query, setQuery] = useState('');
		const isMobile = useIsMobile(960);

		const [startHideMobile, setStartHideMobile] = useState(false);

		const handleHideMobileMenu = useCallback(() => {
			setStartHideMobile(true);

			setTimeout(() => {
				setShow(false);
				setStartHideMobile(false);
			}, 200);
		}, [setShow]);

		const bypassGlobalClick = e => {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
		};

		const handleOnChange = useCallback(
			option => {
				onChange(option);
				setShow(false);
			},
			[onChange],
		);

		const handleOnClick = e => {
			e.preventDefault();
			e.stopPropagation();
			setShow(!show);
		};

		const handleSearch = useCallback((val = '') => {
			setQuery(val);
		}, []);

		const handleMobileOverlayClick = useCallback(() => {
			handleHideMobileMenu();
			bypassGlobalClick();
		}, [handleHideMobileMenu]);

		if (isMobile) {
			const filteredOptions = options?.filter(option =>
				option?.label?.toLowerCase()?.includes(query.toLowerCase()),
			);

			return (
				<>
					<FormField
						readOnly
						value={value?.[0]?.label}
						options={options?.map(option => ({
							value: option.value,
							name: option.label,
						}))}
						onClick={handleOnClick}
						isMulti={isMulti}
						isLoading={isLoading}
						styles={styles}
						theme={theme}
						autoFocus={autoFocus}
						ref={ref}
						placeholder={placeholder}
						openMenuOnFocus={openMenuOnFocus}
						endAdornment={
							<div
								className="mobile-select__select-end-adornment"
								onClick={handleOnClick}
								role="button"
								onKeyUp={() => {}}
								tabIndex={0}
							>
								<Iconof icon={show ? 'chevron_up' : 'chevron_down'} />
							</div>
						}
					/>
					{show &&
						ReactDOM.createPortal(
							<>
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<div
									className={el('mobile-overlay', startHideMobile ? 'hide' : '')}
									onClick={handleMobileOverlayClick}
									role="button"
									onKeyUp={() => {}}
									tabIndex={0}
								/>

								<div
									className={el('mobile-select-wrapper', startHideMobile ? 'hide' : '')}
									onClick={bypassGlobalClick}
									role="button"
									onKeyUp={() => {}}
									tabIndex={0}
								>
									<div className={el('mobile-select-content')}>
										{isMobile ? (
											<div className={el('mobile-select-header', title ? 'with-title' : '')}>
												{title ? (
													<Typography
														variant="boldBody"
														classes={el('mobile-select-header-title')}
														gutter={false}
													>
														{title}
													</Typography>
												) : null}
												<div>
													<span
														className={el('mobile-select-header-done')}
														role="button"
														tabIndex={0}
														onClick={() => handleMobileOverlayClick()}
														onKeyUp={() => {}}
														label="close filter dropdown"
													>
														Done
													</span>
												</div>
											</div>
										) : null}

										<div className={el('mobile-select-content-panel')}>
											<div className={el('search-filters')}>
												<div className={el('search-filters-header')}>
													<FormField
														placeholder="Search"
														startAdornment={<Iconof icon="search" size="large" />}
														value={query}
														onChange={e => handleSearch(e.target.value)}
													/>
												</div>

												<ul className={el('mobile-select-filter-list', `style-${mobileStyle}`)}>
													{filteredOptions?.map(option => (
														<li
															key={`${option.value}-${option.label}`}
															aria-hidden
															onClick={e => {
																e.stopPropagation();
																e.preventDefault();
																handleOnChange(option);
															}}
														>
															{mobileStyle === 'checkbox' && (
																<Iconof
																	className={el(
																		`mobile-select-check${
																			option.value === value?.[0]?.value
																				? '-active'
																				: ''
																		}`,
																	)}
																	icon={
																		option.value === value?.[0]?.value ? 'done' : ''
																	}
																/>
															)}

															{mobileStyle === 'list' &&
																option.value === value?.[0]?.value && (
																	<div className={el(`mobile-list-checkmark`)}>
																		<svg
																			width="10"
																			height="8"
																			viewBox="0 0 10 8"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M9.3501 2.22999L3.7301 7.84999C3.63936 7.94667 3.51268 8.00151 3.3801 8.00151C3.24751 8.00151 3.12083 7.94667 3.0301 7.84999L0.650096 5.49999C0.55544 5.40611 0.502197 5.27831 0.502197 5.14499C0.502197 5.01167 0.55544 4.88388 0.650096 4.78999L1.1801 4.25999C1.27083 4.16332 1.39751 4.10848 1.5301 4.10848C1.66268 4.10848 1.78936 4.16332 1.8801 4.25999L3.3801 5.74999L8.1201 1.00999C8.31452 0.819423 8.62567 0.819423 8.8201 1.00999L9.3501 1.53999C9.53422 1.73314 9.53422 2.03684 9.3501 2.22999Z"
																				fill="#28B681"
																			/>
																		</svg>
																	</div>
																)}
															<span>{option.label}</span>
														</li>
													))}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</>,
							document.body,
						)}
				</>
			);
		}

		return (
			<Select
				{...rest}
				className={`${className} ${customClasses}}`}
				value={value}
				options={options}
				onChange={onChange}
				isMulti={isMulti}
				isLoading={isLoading}
				styles={styles}
				theme={theme}
				autoFocus={autoFocus}
				ref={ref}
				placeholder={placeholder}
				openMenuOnFocus={openMenuOnFocus}
			/>
		);
	},
);
SelectWrapper.displayName = 'SelectWrapper';
SelectWrapper.propTypes = {
	className: PropTypes.string,
	value: PropTypes.any,
	options: PropTypes.any,
	onChange: PropTypes.func,
	isMulti: PropTypes.bool,
	isLoading: PropTypes.bool,
	styles: PropTypes.object,
	theme: PropTypes.object,
	autoFocus: PropTypes.bool,
	placeholder: PropTypes.string,
	openMenuOnFocus: PropTypes.bool,
	title: PropTypes.string,
	mobileStyle: PropTypes.oneOf(['checkbox', 'list']),
};

class UpsiideSelect extends React.Component {
	constructor(props) {
		super(props);
		this.selectRef = React.createRef();
		this.focusSelect = this.focusSelect.bind(this);
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClick);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClick);
	}

	handleClick = e => {
		const { classNamePrefix } = this.props;
		const isWithinDraggableQuestion = this.selectRef.current?.inputRef?.closest('.drop-zone');
		const canInclude = typeof e?.target?.className === 'string';
		const selectComponentsClicked =
			(canInclude ? e?.target?.className?.includes(`${classNamePrefix}__control`) : false) ||
			(canInclude ? e?.target?.className?.includes(`${classNamePrefix}__input-container`) : false) ||
			e?.target?.closest('.button'); // keep focusing on the input if the user clicks on a button (eg.: add a new language)
		if (!selectComponentsClicked && isWithinDraggableQuestion) {
			this.selectRef.current.blur();
		}
	};

	handleChange = value => {
		const { multi, onNew, onChange } = this.props;
		if (multi) {
			if (value.reduce((acc, v) => acc || v.__isNew__, false)) {
				onNew(value.filter(v => v.__isNew__).pop().value);
			} else {
				onChange(value.map(v => v.value));
			}
		} else if (value && value.__isNew__) {
			onNew(value.value);
		} else if (value) {
			onChange(value.value);
		} else {
			onChange(value);
		}
	};

	focusSelect(e) {
		const isWithinDraggableQuestion = this.selectRef.current.inputRef?.closest('.drop-zone');
		if (isWithinDraggableQuestion) {
			if (this.selectRef.current?.inputRef === document.activeElement) {
				this.selectRef.current.blur();
			} else {
				this.selectRef.current.focus();
			}
		}
	}

	render() {
		const {
			value = [],
			options = [],
			multi = false,
			loading,
			isDisabled,
			className: customClasses,
			onNew,
			autoFocus = false,
			primary,
			primary25,
			placeholder,
			mobileStyle,
			isSearchable = true,
			...props
		} = this.props;

		const remappedValue = multi ? value : [value];
		const filledValue = remappedValue.map(v => options.filter(option => option.value === v).pop());

		const Component = onNew ? Creatable : SelectWrapper;

		const customStyles = {
			control: (styles, { isFocused }) => ({
				...styles,
				boxShadow: 'none',
			}),
			...this.props.styles,
		};

		const customTheme = theme => ({
			...theme,
			colors: {
				...theme.colors,
				primary25: primary25 || 'rgba(92,192,159,0.25)',
				primary: primary || '#5cc09f',
			},
		});

		const getClassModifiers = () => {
			const modifierClasses = [];

			if (isDisabled) {
				modifierClasses.push(el('disabled'));
			}

			return modifierClasses.join(' ');
		};

		return (
			<div onClick={this.focusSelect}>
				<Component
					{...this.props}
					className={`${className} ${customClasses} ${getClassModifiers()}`}
					value={filledValue}
					options={options}
					onChange={this.handleChange}
					isMulti={multi}
					isLoading={loading}
					styles={customStyles}
					theme={customTheme}
					autoFocus={autoFocus}
					ref={this.selectRef}
					placeholder={placeholder}
					openMenuOnFocus
					mobileStyle={mobileStyle}
					isSearchable={isSearchable}
					// menuIsOpen // ! For debugging - Keeps the menu open upon click so you can inspect the menu element
				/>
			</div>
		);
	}
}

UpsiideSelect.propTypes = {
	value: PropTypes.any,
	options: PropTypes.any,
	multi: PropTypes.any,
	loading: PropTypes.any,
	isDisabled: PropTypes.any,
	className: PropTypes.any,
	onNew: PropTypes.any,
	onChange: PropTypes.any,
	autoFocus: PropTypes.bool,
	primary: PropTypes.any,
	primary25: PropTypes.any,
	placeholder: PropTypes.string,
	classNamePrefix: PropTypes.string,
	mobileStyle: PropTypes.oneOf(['checkbox', 'list']),
	isSearchable: PropTypes.bool,
};

export default UpsiideSelect;
