import * as actions from '../actions';

const INITIAL_STATE = {
	audiences: null,
	audiencesAvailableForCheckout: null,
	launchStatuses: {},
	clientId: null,
	studyId: null,
	audienceUuid: null,
	incidenceRateAccepted: null,
	previewTax: 0,
	previewData: {},
	balance: {},
	loadingTaxes: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_AUDIENCES:
			return { ...state, audiences: action.payload.audiences };

		case actions.SET_AUDIENCES_AVAILABLE_FOR_CHECKOUT:
			return { ...state, audiencesAvailableForCheckout: action.payload.audiencesAvailableForCheckout };

		case actions.SET_INDIVIDUAL_LAUNCH_STATUS:
			return {
				...state,
				launchStatuses: {
					...state.launchStatuses,
					[action.payload.uuid]: action.payload.status,
				},
			};

		case actions.SET_LAUNCH_AUDIENCE_DATA:
			return {
				...state,
				clientId: action.payload.clientId,
				studyId: action.payload.studyId,
				audienceUuid: action.payload.audienceUuid,
			};

		case actions.SET_LAUNCH_ALL_AUDIENCES_DATA:
			return {
				...state,
				clientId: action.payload.clientId,
				studyId: action.payload.studyId,
			};

		case actions.SET_PREVIEW_TAX:
			return {
				...state,
				previewTax: action.payload.previewTax,
				previousTotalTax: action.payload.previousTotalTax,
			};

		case actions.SET_PREVIEW_DATA:
			return {
				...state,
				previewData: action.payload.data,
			};

		case actions.SET_BILLING_BALANCE:
			return {
				...state,
				balance: action.payload.balance,
			};
		case actions.SET_LOADING_TAXES:
			return {
				...state,
				loadingTaxes: action.payload.loadingTaxes,
			};

		default:
			return state;
	}
};
