import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { Iconof } from '@upsiide/ui-components';
import Button from 'src/components/elements/Button';
import cn from 'src/utilities/bem-cn';
import FILTER from 'public/images/filter_empty.svg';
import useOutsideClick from 'src/hooks/useOutsideClick';

import './styles.scss';

const className = 'screening-question-filter-dropdown';
const el = (name, mod) => cn(className, name, mod);

const ScreeningQuestionFilterDropdown = ({
	data,
	setData,
	filterTypes,
	onChangeFilters,
	setActiveFilters,
	activeFilters,
}) => {
	const [query, setQuery] = useState('');
	const [show, setShow] = useState(false);
	const [selectedFilterType, setSelectedFilterType] = useState();

	useEffect(() => {
		if (activeFilters?.languageFilter?.length) onChangeFilters(activeFilters?.languageFilter, 'languages');
	}, [activeFilters, onChangeFilters]);

	// const countryFilter = activeFilters?.countries || []; todo: for use when ready

	const selectedFilters = [...(activeFilters?.languages || []), ...(activeFilters?.countries || [])];

	const ref = useRef(null);

	useOutsideClick(ref, () => {
		setShow(false);
	});

	const onSelectFilterToggle = (e, newFilter, key) => {
		e.preventDefault();
		e.stopPropagation();

		if (!selectedFilters.includes(newFilter)) {
			setActiveFilters(prev => ({
				countries: [...(prev?.countries || []), ...(key === 'countries' ? [newFilter] : [])],
				languages: [...(prev?.languages || []), ...(key === 'languages' ? [newFilter] : [])],
			}));
		} else {
			setActiveFilters(prev => ({
				countries: [
					...(key === 'countries'
						? (prev?.countries || []).filter(filter => filter !== newFilter)
						: prev?.countries || []),
				],
				languages: [
					...(key === 'languages'
						? (prev?.languages || []).filter(filter => filter !== newFilter)
						: prev.countries || []),
				],
			}));
		}
	};

	const renderFilterTypesPanel = () => (
		<ul className={el('filter-types')}>
			{filterTypes?.map(filterType => (
				<li key={filterType.value}>
					<button
						type="button"
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							setSelectedFilterType(filterType);
						}}
					>
						<div className={el('filter-number-container')}>
							{filterType.label}{' '}
							{selectedFilters.length ? (
								<span className={el('filter-number')}> {selectedFilters.length} </span>
							) : (
								''
							)}
						</div>
						<Iconof icon="chevron_right" size="large" />
					</button>
				</li>
			))}
		</ul>
	);

	const filtersByType = useMemo(() => {
		if (!selectedFilterType) return [];

		const selectedFilterByType = filterTypes.find(({ value }) => value === selectedFilterType.value);

		if (!selectedFilterByType?.items?.length) return [];

		if (!query) return selectedFilterByType.items;

		const selectedFilterByTypeFiltered = selectedFilterByType.items.filter(({ label }) =>
			label.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
		);
		return selectedFilterByTypeFiltered;
	}, [filterTypes, query, selectedFilterType]);

	const renderFiltersPanel = () => (
		<div className={el('search-filters')}>
			<button
				className={el('back')}
				type="button"
				onClick={e => {
					e.stopPropagation();
					e.preventDefault();
					setSelectedFilterType('');
				}}
			>
				<Iconof icon="chevron_left" />
				<span>{selectedFilterType?.label}</span>
			</button>
			<Input
				className={el('search-input')}
				placeholder="Search"
				value={query}
				onChange={e => setQuery(e.target.value)}
			/>
			<div className={el('search')}>
				<Iconof icon="search" size="large" />
			</div>
			<ul className={el('filter-list')}>
				{filtersByType?.map(filter => (
					<li
						key={filter.value}
						aria-hidden
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							onSelectFilterToggle(e, filter.value, selectedFilterType.value);
						}}
					>
						<Iconof
							className={el(`check${selectedFilters.includes(filter.value) ? '-active' : ''}`)}
							icon={selectedFilters.includes(filter.value) ? 'done' : ''}
						/>
						<span>{filter.label}</span>
					</li>
				))}
			</ul>
		</div>
	);

	const renderPanels = () => (
		<div className={el('panels')}>
			<div className={el('panel-content')}>
				{!selectedFilterType ? renderFilterTypesPanel() : renderFiltersPanel()}
				<div className={el('footer')}>
					<small>
						{selectedFilters.length} {selectedFilters.length === 1 ? 'filter' : 'filters'} applied
					</small>
					<Button
						type="text"
						label="Clear selections"
						state={!selectedFilters.length ? 'disabled' : 'active'}
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							setActiveFilters({ languages: [] });
							setData(data);
						}}
					/>
				</div>
			</div>
		</div>
	);

	return (
		<div className={className} ref={ref}>
			<div aria-hidden onClick={() => setShow(prev => !prev)} className={el('content')}>
				<img src={FILTER} alt="" />
				{selectedFilters?.length ? (
					<div className={el('number_content')}>
						<span className={el('number')}>{selectedFilters.length}</span>
					</div>
				) : null}

				<Iconof icon={show ? 'chevron_up' : 'chevron_down'} />
			</div>
			{show && renderPanels()}
		</div>
	);
};

ScreeningQuestionFilterDropdown.propTypes = {
	data: PropTypes.any,
	setData: PropTypes.func,
	filterTypes: PropTypes.any,
	onChangeFilters: PropTypes.func,
	setActiveFilters: PropTypes.func,
	activeFilters: PropTypes.any,
};

export default ScreeningQuestionFilterDropdown;
