import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router';
import { TextRowPlaceholder as TextRow } from '@upsiide/ui-components';
import { Col, Row } from 'reactstrap';
import { useIsBlueprint } from 'src/hooks';
import cn from 'src/utilities/bem-cn';
import ArrowRight from 'public/images/audience/gray_arrow_right.svg';
import ArrowLeft from 'public/images/audience/green_arrow_left.svg';
import Button from 'src/components/elements/Button';
import * as studySelectors from 'src/domains/manage-study/selectors';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import * as manageBlueprintActions from 'src/domains/manage-blueprints/actions';
import * as manageStudyActions from 'src/domains/manage-study/actions';
import * as services from 'src/services';
import AddQuestionButton from 'src/components/elements/AddQuestionButton';
import QuestionTypeIcon from 'src/components/shared/QuestionTypeIcon';
import Loadable from 'src/components/shared/Loadable';
import AMPHeaderError from 'src/components/shared/AMPHeaderError';

import QuestionLibrary from '../QuestionLibrary';

import './styles.scss';
import ScreeningQuestions from '../ScreeningQuestions';

const className = 'screening-question-wrapper';

const el = (name, mod) => cn(className, name, mod);

const ScreeningQuestionWrapper = ({
	viewMode = false,
	audienceTemplate = null,
	setTemplate = null,
	selectLanguage = null,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { isBlueprint } = useIsBlueprint();
	const selectors = isBlueprint ? manageBlueprintSelectors : studySelectors;
	const actions = isBlueprint ? manageBlueprintActions : manageStudyActions;
	const { study } = useSelector(state => (isBlueprint ? state.manageBlueprint : state.manageStudy));
	const [isOpenLibrary, setOpenLibrary] = useState(false);
	const [blockEdit, setBlockEdit] = useState(false);
	const [understandClicked, setUnderstandClicked] = useState(false);
	const audienceCollectionLocal = useSelector(selectors.getAudienceCollection);
	const audienceEditTemplate = useSelector(selectors.getAudienceEditTemplate);
	const [questions, setQuestions] = useState([]);
	const [shouldRender, setShouldRender] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (audienceEditTemplate?.content && setTemplate) {
			setTemplate(audienceEditTemplate?.content);
		}
		// eslint-disable-next-line
	}, [audienceEditTemplate]);

	const audienceCollection = useMemo(() => {
		if (audienceTemplate) return { content: audienceTemplate };
		return audienceCollectionLocal;
	}, [audienceTemplate, audienceCollectionLocal]);

	const liveGroup = useMemo(() => {
		if (audienceTemplate) return false;
		if (audienceCollection?.content?.demographicGroups) {
			const liveAudience = audienceCollection?.content?.demographicGroups.find(group => group.status === 'live');
			if (liveAudience) setBlockEdit(true);
			return true;
		}
		return false;
	}, [audienceTemplate, audienceCollection]);

	useEffect(() => {
		if (!audienceTemplate) {
			setLoading(true);
		}
		// eslint-disable-next-line
	}, []);

	const getQuestionStyle = question => {
		if (question?.style === 'custom' || question?.style === 'qualifier') {
			if (question?.attributes?.length > 1) return 'grid';
			return 'multi-select';
		}
		return question?.style;
	};

	useEffect(() => {
		if (!audienceCollectionLocal?.content && audienceTemplate) {
			dispatch(actions.setAudienceEditTemplate({ loading: false, content: audienceCollection?.content }));
			setLoading(false);
		}
		if (!audienceCollection?.content?.screeningQuestions?.length) {
			setLoading(false);
			setQuestions([]);
			return;
		}
		const quest = audienceCollection.content.screeningQuestions.map(question => ({
			...question,
			style: getQuestionStyle(question),
		}));
		setLoading(false);

		setQuestions(quest);
		// eslint-disable-next-line
	}, [audienceCollection, audienceTemplate]);

	const languages = useMemo(() => {
		if (audienceCollection?.content?.demographicGroups?.length) {
			return audienceCollection.content.demographicGroups.map(group => ({
				label: group?.countryLanguage?.languageLabel,
				countryLabel: group?.countryLanguage?.countryLabel,
				languageCode: group?.languageCode,
			}));
		}
		return [{ label: 'English', languageCode: 'en', countryLabel: 'Canada' }]; // fallback
	}, [audienceCollection]);

	const audience = useMemo(
		() => ({
			isAudienceTemplate: !!audienceTemplate,
			audienceCollectionId: audienceTemplate?.id || audienceCollection?.content?.id,
			studyId: audienceTemplate?.studyId || audienceCollection?.content?.studyId,
			demographicGroups: audienceCollection?.content?.demographicGroups || [],
			providerQuestions: [],
			customQuestions: questions,
			languages,
			languageCode: languages.find(lang => lang?.languageCode === study?.language) || {
				languageCode: study?.language || 'en',
			},
			countryLanguage: {
				languageLabel: languages.find(lang => lang?.languageCode === study?.language)?.label || 'English',
				countryLabel: languages.find(lang => lang?.languageCode === study?.language)?.countryLabel || 'Canada',
			},
		}),

		[audienceTemplate, audienceCollection, questions, languages, study],
	);

	const [tempTemplate, setTempTemplate] = useState(audience);

	useEffect(() => {
		setTempTemplate(audience);
	}, [audience]);

	const fetchAudienceCollection = useCallback(async () => {
		if (!audienceCollection?.content?.id) return;
		const response = await services.audienceService.getCollection(
			audienceCollection?.content?.studyId,
			audienceCollection.content.id,
		);
		if (audienceTemplate) {
			setTemplate(response?.data || null);
			dispatch(actions.setAudienceEditTemplate({ loading: false, content: response?.data || null }));
		} else {
			dispatch(actions.setAudienceCollection({ loading: false, content: response?.data || null }));
		}
		// eslint-disable-next-line
	}, [study, audienceCollection, dispatch]);

	const addQuestionIntoStudy = useCallback(
		async (questionLibraryId, sortOrder) => {
			try {
				await services.studyService.addQuestionFromLibrary({
					studyId: audienceCollection?.content?.studyId,
					audienceCollectionId: audienceCollection?.content?.id,
					questionLibraryId,
					sortOrder,
				});
				await fetchAudienceCollection();
			} catch (error) {
				console.error(error);
			} finally {
				setOpenLibrary(false);
			}
		},
		// eslint-disable-next-line
		[study, fetchAudienceCollection],
	);

	const onClickQuestionLibrary = async (e, questionList) => {
		e?.preventDefault();
		e?.stopPropagation();

		Promise.all(
			questionList.map(async (question, i) => {
				await addQuestionIntoStudy(question?.questionLibraryId, questions.length + i);
			}),
		);
		if (audienceTemplate) return;
		if (!location?.pathname?.includes('/questions')) navigate('questions');
	};

	const blockUpdateOrBreakTemplate = useCallback(
		callbackFunction => {
			if (viewMode) return;
			if (tempTemplate && tempTemplate.status && tempTemplate.status !== 'ready') {
				document.activeElement.blur();
			} else if (tempTemplate && tempTemplate.templateAudienceId) {
				document.activeElement.blur();
			} else {
				callbackFunction();
			}
		},
		[tempTemplate, viewMode],
	);

	const addNewQuestion = useCallback(
		async (questionType, questionId) => {
			const translations = study?.languages?.map(lang => ({
				label: '',
				languageCode: lang,
			})) || [{ label: '', languageCode: 'en' }];
			const newCustomTarget = {
				audienceCollectionId: audienceCollection?.content?.id,
				type: 'qualifier',
				style: questionType,
				label: '',
				filterLabel: '',
				status: 'public',
				sortOrder: questions.length,
				isFilter: true,
				randomizeOptions: false,
				translations,
			};

			if (questionType === 'grid') {
				newCustomTarget.attributes = [];
			}

			try {
				await services.audienceService
					.createScreeningQuestionFromScratch(audienceCollection?.content.studyId, newCustomTarget)
					.then(async res => {
						const newQuestionId = res.data?.insertId;
						const destinationIndex = questions.findIndex(q => q.id === questionId) + 1;

						const order = questions.map(q => q.id);
						order.splice(destinationIndex, 0, newQuestionId);

						if (questionId) {
							await services.questionService.reorder(audienceCollection?.content.studyId, order);
						}
					});

				const response = await services.audienceService.getCollection(
					audienceCollection?.content.studyId,
					audienceCollection?.content?.id,
				);
				if (audienceTemplate) {
					setTemplate(response?.data);
					dispatch(actions.setEditAudienceModal({ loading: false, content: response?.data || null }));
				} else {
					dispatch(actions.setAudienceCollection({ loading: false, content: response?.data || null }));
				}
			} catch (error) {
				console.error(error);
			}
		},
		[audienceCollection, study, dispatch, questions, actions],
	);

	const addQuestionOptions = useMemo(
		() => [
			{
				name: 'select',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="multi-select" size="large" tooltip={false} />
						</div>
						Select
					</>
				),
				onClick: questionId => {
					addNewQuestion('multi-select', questionId);
				},
			},

			{
				name: 'grid',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="grid" size="large" tooltip={false} />
						</div>
						Grid
					</>
				),
				onClick: questionId => {
					addNewQuestion('grid', questionId);
				},
			},

			{
				name: 'ranked',
				label: (
					<>
						<div className={el('drag')}>
							<QuestionTypeIcon questionType="ranked" size="large" tooltip={false} />
						</div>
						Rank
					</>
				),
				onClick: questionId => {
					addNewQuestion('ranked', questionId);
				},
			},
		],
		[addNewQuestion],
	);

	const renderEmptyState = () => {
		if (loading) return null;
		if (!audienceCollection?.content?.id) return;

		return (
			<div className={el('empty-wrapper')}>
				{audienceTemplate ? null : (
					<div className={el('header')}>
						<div className={el('header-navigation')} aria-hidden onClick={() => navigate('../')}>
							<img src={ArrowLeft} alt="" />
							<span className={el('header-sub-title-back')} onClick={() => navigate('../')}>
								Back
							</span>
							<span className={el('header-sub-title-level')} onClick={() => navigate('../')}>
								Audience Overview
							</span>
							<img src={ArrowRight} alt="" />
							<span className={el('header-sub-title')}>Screening Questions </span>
						</div>
					</div>
				)}

				<div className={el('box-header')}>
					{audienceTemplate ? null : <span className={el('box-header-sub-title')}>Step 2</span>}
					<span className={el('box-header-title')}>Screening Questions</span>
					<span className={el('box-header-sub-title')}>
						Add screening questions from our recommended library — you can also create your own from
						scratch!
					</span>
				</div>
				<div className={el('inline-buttons-wrapper')}>
					<Button
						label="Start Adding Questions"
						type="text"
						className={el('secondary-button')}
						onClick={() => {
							setOpenLibrary(true);
						}}
					/>
				</div>
			</div>
		);
	};

	const renderTranslationErrorMsg = () => {
		let isMissingTranslations = false;
		questions.forEach(question => {
			// check if translation exists
			study?.languages.forEach(lang => {
				const translation = question.translations.find(trans => lang === trans.languageCode);
				if (!translation) isMissingTranslations = true;
			});

			if (isMissingTranslations) return null;

			// if translation exists, check the content and the options
			const emptyQuest = question.translations.find(
				trans => !study?.languages?.includes(trans.languageCode) || trans.label === '',
			);
			if (emptyQuest) {
				isMissingTranslations = false;
			} else {
				question?.options.forEach(option => {
					// check if option translation exists
					study?.languages.forEach(lang => {
						const translation = option.translations?.find(trans => lang === trans.languageCode);
						if (!translation) isMissingTranslations = true;
					});
				});

				if (isMissingTranslations) return null;
			}
		});

		if (!isMissingTranslations) return null;
		return (
			<div className={el('error-header')}>
				<AMPHeaderError
					icon="error"
					errorTitle={<div className={el('error-title-container')}>Translation missing</div>}
					errorMessage={
						<div className={el('error-desc-container')}>
							Screening questions are translated not to all languages of the survey
						</div>
					}
					buttonText=""
					buttonLink=""
				/>
			</div>
		);
	};

	const understandRiskAction = () => {
		setBlockEdit(false);
		setUnderstandClicked(true);
	};

	const renderAudienceLiveErrorMsg = () => {
		if (audienceCollection?.content?.demographicGroups) {
			if (liveGroup && blockEdit && !understandClicked)
				return (
					<div className={el('error-header')}>
						<AMPHeaderError
							icon="error"
							errorTitle={
								<div className={el('error-title-container')}>You currently have a live audience</div>
							}
							errorMessage={
								<div className={el('error-desc-container')}>
									Making changes to your screening questions may affect or risk losing your data, are
									you sure you want to continue?
								</div>
							}
							buttonType="error"
							buttonText="I Understand the Risk, Proceed"
							buttonLogic={understandRiskAction}
						/>
					</div>
				);
		}
		return null;
	};

	const renderHeader = () => {
		if (!audienceCollection?.content?.id) return;
		if (audienceTemplate)
			return (
				<div className={el('header')}>
					<span className={el('header-title')}>Screening Questions</span>
				</div>
			);

		return (
			<div className={el('header')}>
				<div className={el('header-navigation')} aria-hidden>
					<img src={ArrowLeft} alt="" />
					<span className={el('header-sub-title-back')} onClick={() => navigate('../')}>
						Back
					</span>
					<span className={el('header-sub-title-level')} onClick={() => navigate('../')}>
						Audience Overview
					</span>
					<img src={ArrowRight} alt="" />
					<span className={el('header-sub-title')}>Screening Questions </span>
				</div>
				{renderAudienceLiveErrorMsg() || renderTranslationErrorMsg()}
				<span className={el('header-title')}>Screening Questions</span>
			</div>
		);
	};

	const renderPlaceholder = () => {
		if (!audienceCollection?.content?.id) {
			const renderRow = margin => (
				<Col xs={12} className="placeholder-item-title">
					<TextRow color="#E0E0E0" style={{ margin: 0, marginBottom: margin || 0 }} />
				</Col>
			);
			return (
				<div className={el('screening_container')}>
					<div className={el('hidden-blocker-container')}>
						<div className={el('skeleton')}>
							<div className={el('skeleton_container')}>
								<Row>{renderRow(20)}</Row>
								<Row>
									{renderRow()}
									{renderRow()}
									{renderRow()}
								</Row>
							</div>
							<div className={el('skeleton_container')}>
								<Row>{renderRow(20)}</Row>
								<Row>
									{renderRow()}
									{renderRow()}
									{renderRow()}
								</Row>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};

	const renderBottom = () => {
		if (!shouldRender) return null;

		if (!viewMode && (!!questions?.length || location?.pathname?.includes('/questions')))
			return (
				<div className={el('box-footer')}>
					<div className={el('empty-wrapper')}>
						<div className={el('inline-buttons-wrapper_footer')}>
							<Button
								label="Add Question From Library"
								type="button"
								onClick={() => {
									setOpenLibrary(true);
								}}
							/>
							<AddQuestionButton
								audience={audience}
								addQuestionOptions={addQuestionOptions}
								isLastQuestionItem
								blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
								screeningQuestion
							/>
						</div>
					</div>
				</div>
			);
		return null;
	};

	return (
		<div className={el('screening')}>
			<div
				className={
					questions?.length || location?.pathname?.includes('/questions')
						? el('box-container')
						: el('box-container_empty')
				}
			>
				<div className={el('box-content')}>
					{renderPlaceholder()}
					{!questions?.length && (audienceTemplate || location?.pathname?.includes('/questions')) ? (
						renderEmptyState()
					) : (
						<div className={el('screening_container')}>
							{renderHeader()}

							<div className={el('hidden-blocker-container')}>
								<Loadable
									loading={!audienceCollection?.content?.id}
									customStyle={{ width: '100%', height: '100%' }}
								>
									<ScreeningQuestions
										setShouldRender={setShouldRender}
										audienceTemplate={audienceTemplate}
										setTemplate={setTemplate}
										tempTemplate={tempTemplate}
										setTempTemplate={setTempTemplate}
										questions={questions}
										setQuestions={setQuestions}
										setOpenLibrary={setOpenLibrary}
										addQuestionOptions={addQuestionOptions}
										viewMode={viewMode}
										selectLanguage={selectLanguage}
									/>

									{renderBottom()}
								</Loadable>

								<div
									className={el('hidden-blocker')}
									style={{
										display: !viewMode && (!blockEdit || understandClicked) ? 'none' : 'block',
									}}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
			<QuestionLibrary
				open={isOpenLibrary}
				onClick={onClickQuestionLibrary}
				onClose={() => setOpenLibrary(false)}
				customLabel="Add Your Own +"
				isTemplate={!!audienceTemplate}
			/>
		</div>
	);
};

ScreeningQuestionWrapper.propTypes = {
	viewMode: PropTypes.bool,
	audienceTemplate: PropTypes.object,
	setTemplate: PropTypes.any,
	selectLanguage: PropTypes.any,
};

export default ScreeningQuestionWrapper;
