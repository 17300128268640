import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Auth from 'src/utilities/auth';
// import WithScope from 'src/components/shared/WithScope';
import * as authSelectors from 'src/domains/auth/selectors';
import * as selectors from 'src/domains/selectors';
import * as profileSelectors from 'src/domains/profile/selectors';
import * as accountActions from 'src/domains/account/actions';
import CannyContainer from 'src/components/shared/CannyContainer';
import MobileLock from 'src/components/shared/MobileLock';
import CenteredLoader from 'src/components/shared/CenteredLoader';
import NotFound from './not-found/pages/NotFound';

const Studies = lazy(() => import('./all-studies/pages/Studies'));
const AllStudies = lazy(() => import('./all-studies/pages/AllStudies'));
const SearchResults = lazy(() => import('./all-studies/pages/SearchResults'));
const CreateStudy = lazy(() => import('./create-study/pages/CreateStudy'));
const Study = lazy(() => import('./manage-study/pages/ManageStudy'));
const PublicStudyPage = lazy(() => import('./public-study/pages/PublicStudyPage'));
const PublicStudyExport = lazy(() => import('./public-study/pages/PublicStudyExport'));
const Login = lazy(() => import('./login/pages/Login'));
const NoAccount = lazy(() => import('./login/pages/NoAccount'));
const Client = lazy(() => import('./client/pages/Client'));
const Clients = lazy(() => import('./clients/pages/Clients'));
const Profile = lazy(() => import('./profile/pages/Profile'));
const ResetPassword = lazy(() => import('./reset-password/pages/ResetPassword'));
const AccountSetup = lazy(() => import('./account-setup/pages/AccountSetup'));
const Admin = lazy(() => import('./admin/pages/Admin'));
const SignUp = lazy(() => import('./signup/pages/SignUp'));
const ConfirmSignUp = lazy(() => import('./validate-email/pages/ConfirmSignUp'));
const AccountDetails = lazy(() => import('./account-details/pages/AccountDetails'));
const ChoosePlan = lazy(() => import('./choose-plan/pages/ChoosePlan'));
const Enterprise = lazy(() => import('./enterprise/pages/Enterprise'));
const BillingDetails = lazy(() => import('./billing-details/pages/BillingDetails'));
const InviteUsers = lazy(() => import('./invite-users/pages/InviteUsers'));
const DemoRequest = lazy(() => import('./demo-request/pages/DemoRequest'));
const Account = lazy(() => import('./account/pages/Account'));
const ManageBlueprints = lazy(() => import('./all-blueprints/pages/ManageBlueprints'));
const Blueprint = lazy(() => import('./manage-blueprints/pages/ManageBlueprint'));
const DownloadFile = lazy(() => import('./download-file/pages/DownloadFile'));

const MainRoutes = props => {
	const { isLoading, isLoggedIn, user, account, sendMobileBlockLink } = props;
	const { content } = account || {};
	const { chargifyCustomerId, subscriptionState } = content || {}; // acc may still be loading

	const ableToCreateStudies = Auth.userCan('study:create', true);
	const ableToUpdateClients = Auth.userCan('client:update', true);
	const ableToViewAccount = Auth.userCan('account:list', true);
	const isAdmin = Auth.isDigAdmin();
	const isLockedProfessional =
		isLoggedIn && chargifyCustomerId && subscriptionState && subscriptionState !== 'active' && !isAdmin;

	if (isLoading) {
		return <CenteredLoader />;
	}

	const getLockedProfessionalRouter = () => (
		<Suspense fallback={<CenteredLoader />}>
			<CannyContainer user={user}>
				<Routes>
					<Route path="confirm-signup" element={<ConfirmSignUp path="confirm-signup" />} />
					<Route path="studies" element={<Studies path="studies" />} />
					{ableToViewAccount && <Route path="account/*" element={<Account path="account/*" />} />}
					<Route path="me" element={<Profile path="me" />} />
					<Route path="account-details" element={<AccountDetails path="account-details" />} />
					<Route path="choose-plan" element={<ChoosePlan path="choose-plan" />} />
					<Route path="enterprise" element={<Enterprise path="enterprise" />} />
					<Route path="billing-details" element={<BillingDetails path="billing-details" />} />
					<Route path="invite-users" element={<InviteUsers path="invite-users" />} />
					<Route path="*" element={<Studies path="studies" />} />
				</Routes>
			</CannyContainer>
		</Suspense>
	);

	const getMainLoggedInRouter = () => (
		<MobileLock
			sendMobileBlockLink={sendMobileBlockLink}
			allowPaths={[
				// allow add account routes
				'/account-details',
				'/choose-plan',
				'/enterprise',
				'/billing-details',
				'/invite-users',
			]}
		>
			<CannyContainer user={user}>
				<Routes primary={false}>
					<Route path="demo-request" element={<DemoRequest path="demo-request" />} />

					{/* All Studies */}
					<Route path="studies" element={<Studies path="studies" />} />
					<Route path="templates" element={<Studies path="templates" />} />
					<Route path="studies/all" element={<AllStudies path="studies/all" />} />
					<Route path="search" element={<SearchResults path="search" />} />

					{ableToCreateStudies && (
						<Route path="studies/create" element={<CreateStudy path="studies/create" />} />
					)}

					{/* Study */}
					<Route path="studies/:id/*">
						{!ableToCreateStudies && (
							<Route path="studies/:id/audiences" element={<Navigate to="/studies/:id" />} />
						)}
						<Route path="manage/builder" element={<Navigate to="../create" />} />
						<Route path="manage/*" element={<Navigate to="../settings" />} />
						<Route path="settings/exports" element={<Navigate to="../settings/data" />} />
						<Route path="*" element={<Study path="studies/:id/*" />} />
					</Route>

					{/* Public Studies */}
					<Route path="public/studies" element={<Studies path="public/studies" />} />
					<Route path="public/studies/all" element={<AllStudies path="public/studies/all" />} />
					<Route path="public/search" element={<SearchResults path="public/search" />} />

					<Route path="public/studies/:id/*">
						<Route path="settings/data" element={<PublicStudyExport path="settings/data" />} />
						<Route path="settings/" element={<Navigate to="data" />} />
						<Route path="*" element={<PublicStudyPage path="public/studies/:id/*" />} />
					</Route>

					{/* Templates */}
					<Route path="templates/:id/*">
						<Route path="*" element={<Blueprint path="templates/:id/create" />} />
					</Route>
					<Route path="templates/:id/" element={<Navigate to="create" />} />

					{/* {ableToCreateStudies && <Route path="create" element={<CreateBlueprint path="create" />} />} */}

					{/* Account Related */}
					<Route path="me" element={<Profile path="me" />} />
					{ableToUpdateClients && <Route path="clients" element={<Clients path="clients" />} />}
					{ableToUpdateClients && <Route path="client/:uuid/*" element={<Client path="client/:uuid/*" />} />}
					{ableToViewAccount && <Route path="account/*" element={<Account path="account/*" />} />}
					{ableToViewAccount && <Route path="account/" element={<Navigate to="billing" />} />}
					{isAdmin && <Route path="admin/*" element={<Admin path="admin/*" />} />}

					{/* Sign-Up */}
					<Route path="confirm-signup" element={<ConfirmSignUp path="confirm-signup" />} />
					<Route path="account-details" element={<AccountDetails path="account-details" />} />
					<Route path="choose-plan" element={<ChoosePlan path="choose-plan" />} />
					{/* <Route path="enterprise" element={<Enterprise path="enterprise" />} /> */}
					<Route path="billing-details" element={<BillingDetails path="billing-details" />} />
					<Route path="invite-users" element={<InviteUsers path="invite-users" />} />

					<Route path="sav-download/:fileUuid" element={<DownloadFile path="sav-download/:fileUuid" />} />
					{/* Fallback */}
					<Route path="404" element={<NotFound path="404" />} />
					<Route path="*" element={<Navigate to="studies" />} />
				</Routes>
			</CannyContainer>
		</MobileLock>
	);

	const getPublicRouter = () => (
		<MobileLock>
			<Routes primary={false}>
				<Route path="public/studies" element={<Studies path="public/studies" />} />
				<Route path="public/studies/all" element={<AllStudies path="public/studies/all" />} />
				<Route path="public/search" element={<SearchResults path="public/search" />} />
				<Route path="public/studies/:id/*">
					<Route
						path="settings/data"
						element={<PublicStudyExport path="public/studies/:id/settings/data" />}
					/>
					<Route path="settings/*" element={<Navigate to="data" />} />
					<Route path="*" element={<PublicStudyPage path="public/studies/:id/*" />} />
				</Route>
				<Route path="reset-password" element={<ResetPassword path="reset-password" />} />
				<Route path="confirm" element={<AccountSetup path="confirm" />} />
				<Route path="no-account" element={<NoAccount path="no-account" />} />
				<Route path="signup/*" element={<SignUp path="signup/*" />} />
				<Route path="confirm-signup" element={<ConfirmSignUp path="confirm-signup" />} />
				<Route path="account-details" element={<AccountDetails path="account-details" />} />
				<Route path="choose-plan" element={<ChoosePlan path="choose-plan" />} />
				{/* <Route path="enterprise" element={<Enterprise path="enterprise" />} /> */}
				<Route path="billing-details" element={<BillingDetails path="billing-details" />} />
				<Route path="invite-users" element={<InviteUsers path="invite-users" />} />
				<Route path="not-found" element={<NotFound path="404" />} />
				<Route path="*" element={<Login default />} />
			</Routes>
		</MobileLock>
	);
	if (isLockedProfessional) {
		return getLockedProfessionalRouter();
	}
	return (
		<Suspense fallback={<CenteredLoader />}>{isLoggedIn ? getMainLoggedInRouter() : getPublicRouter()}</Suspense>
	);
};

MainRoutes.propTypes = {
	isLoading: PropTypes.bool,
	isLoggedIn: PropTypes.bool,
	user: PropTypes.any,
	account: PropTypes.any,
	sendMobileBlockLink: PropTypes.func,
};

const mapStateToProps = state => ({
	isLoading: authSelectors.isLoading(state),
	isLoggedIn: authSelectors.isLoggedIn(state),
	user: profileSelectors.getUser(state),
	account: selectors.getAccountData(state).account,
});

const mapDispatchToProps = dispatch => ({
	sendMobileBlockLink: () => dispatch(accountActions.sendMobileBlockLink()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);
