import toastr from 'toastr';
import LoadingToaster from 'src/components/shared/LoadingToaster';
import axios from '../utilities/axios';
import * as misc from '../utilities/misc';

const API_PATH = `${process.env.apiPath}`;

function createStudy(data) {
	return axios.post(`${API_PATH}/studies`, data);
}

/**
 *
 * @param {number} studyId
 * @param {object|null} options
 *   - mode: edit
 */
function getStudy(studyId, options = {}) {
	const query = misc.toURL(options);

	return axios.get(`${API_PATH}/studies/${studyId}${query ? '?' : ''}${query}`);
}

/**
 *
 * @param {number} studyId
 * @param {object|null} options
 *   - mode: edit
 */
function getPublicStudy(studyId, options = {}) {
	const query = misc.toURL(options);

	return axios.get(`${API_PATH}/public/studies/${studyId}${query ? '?' : ''}${query}`);
}

function getStudies({
	filter = {},
	limit = 24,
	offset = 0,
	orderBy = 'revisedAt',
	orderByDirection = 'desc',
	isHighlights = false,
	isPublic,
}) {
	const path = isPublic ? `${API_PATH}/public/studies` : `${API_PATH}/studies/list`;
	return axios.post(path, {
		limit,
		offset,
		...(isHighlights ? { highlights: isHighlights } : {}),
		orderBy,
		orderByDirection,
		...filter,
	});
}

function searchStudies({
	filter = {},
	limit = 24,
	offset = 0,
	orderBy = 'revisedAt',
	orderByDirection = 'desc',
	isHighlights = false,
	isPublic = false,
}) {
	const path = isPublic ? `${API_PATH}/public/search-studies` : `${API_PATH}/studies/search`;

	return axios.post(path, {
		limit,
		offset,
		...(isHighlights ? { highlights: isHighlights } : {}),
		orderBy,
		orderByDirection,
		...filter,
	});
}

function getPublicStudies(search, filters, limit = 24, offset = 0, type = 'study') {
	const formattedFilters = {};
	Object.keys(filters).map(key => {
		if (filters[key] && filters[key].length && filters[key].join) {
			formattedFilters[key] = filters[key].join(',');
		}
	});
	return axios.get(`${API_PATH}/public/studies`, {
		params: {
			search: search || undefined,
			limit,
			offset,
			type,
			// accountUuids,
			...formattedFilters,
		},
	});
}

/**
 *
 * @param {number} studyId
 * @param {object|null} options
 *   - mode: edit
 */
function updateStudy(studyId, data) {
	return axios.put(`${API_PATH}/studies/${studyId}`, data);
}

function patchStudy(studyId, data) {
	return axios.patch(`${API_PATH}/studies/${studyId}`, data);
}

function patchStudySettings(studyId, data) {
	return axios.patch(`${API_PATH}/studies/${studyId}/settings`, data);
}

function changeStatus(studyId, status) {
	if (['active', 'draft', 'closed'].indexOf(status) === -1) {
		throw new Error(`The study status ${status} is not allowed. (Allowed statuses are draft, active and close)`);
	}

	const action = { active: 'publish', draft: 'draft', closed: 'close' }[status];

	return axios.put(`${API_PATH}/studies/${studyId}/${action}`);
}

/**
 *
 * @param {number} studyId
 */
async function deleteStudy(studyId, clientId) {
	await axios.delete(`${API_PATH}/studies/${studyId}`, {
		headers: {
			'x-client-uuid': clientId,
		},
	});
}

/**
 * Exports products by study id
 */
function exportStatements(studyId, sectionId = null, filename = `Export - Statements - ${studyId}.csv`) {
	return axios.download(
		`${API_PATH}/portation/studies/${studyId}/statements/export?${sectionId ? `sectionId=${sectionId}` : ''}`,
		filename,
	);
}

function exportQuestions(studyId, sectionId = null, filename = `Export - Questions - ${studyId}.csv`) {
	return axios.download(
		`${API_PATH}/portation/studies/${studyId}/questions/export?${sectionId ? `sectionId=${sectionId}` : ''}`,
		filename,
	);
}

function publicExportQuestions(studyId, sectionId = null, filename = `Export - Questions - ${studyId}.csv`) {
	return axios.download(
		`${API_PATH}/public/portation/studies/${studyId}/questions/export?${sectionId ? `sectionId=${sectionId}` : ''}`,
		filename,
	);
}

function exportProducts(studyId, sectionId = null, filename = `Export - Ideas - ${studyId}.csv`) {
	return axios.download(
		`${API_PATH}/portation/studies/${studyId}/products/export?${sectionId ? `sectionId=${sectionId}` : ''}`,
		filename,
	);
}

function publicExportProducts(studyId, sectionId = null, filename = `Export - Ideas - ${studyId}.csv`) {
	return axios.download(
		`${API_PATH}/public/portation/studies/${studyId}/products/export?${sectionId ? `sectionId=${sectionId}` : ''}`,
		filename,
	);
}

function downloadTemplate(studyId, filename = 'Export - Ideas Template.csv', sectionId) {
	return axios.download(
		`${API_PATH}/portation/studies/${studyId}/products/template?sectionId=${sectionId}`,
		filename,
	);
}

function publicDownloadTemplate(studyId, filename = 'Export - Ideas Template.csv', sectionId) {
	return axios.download(
		`${API_PATH}/public/portation/studies/${studyId}/products/template?sectionId=${sectionId}`,
		filename,
	);
}

/**
 * Exports products by study id
 */
function importProducts(studyId, files, sectionId) {
	const formData = new FormData();
	formData.append('importCsvFile', files[0]);
	formData.append('sectionId', sectionId);
	return axios.post(`${API_PATH}/portation/studies/${studyId}/products/import`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}

function importStatements(studyId, files, sectionId) {
	const formData = new FormData();
	formData.append('importCsvFile', files[0]);
	formData.append('sectionId', sectionId);
	return axios.post(`${API_PATH}/portation/studies/${studyId}/statements/import`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}

function importQuestions(studyId, files, sectionId) {
	const formData = new FormData();
	formData.append('importCsvFile', files[0]);
	formData.append('sectionId', sectionId);
	return axios.post(`${API_PATH}/portation/studies/${studyId}/questions/import`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}

function validateFilters(studyId, filterCount, uuids) {
	return axios.post(`${API_PATH}/portation/studies/${studyId}/filters/validate`, { uuids, filterCount });
}

function importFilters(studyId, file) {
	const formData = new FormData();
	formData.append('importCsvFile', file);
	return axios.post(`${API_PATH}/portation/studies/${studyId}/filters/import`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}

function exportFilters(studyId, studyName) {
	return axios.download(`${API_PATH}/portation/studies/${studyId}/filters/template`, `${studyName} - Imports.csv`);
}

/**
 * Exports data by study id
 */
function exportDataWithStudyID(studyId, filesToExport, fileName, type = 'csv') {
	const includedQuantity = filesToExport.files.filter(file => file.include);
	let route = `${API_PATH}/portation/studies/${studyId}/combined/export`;
	if (type === 'spss') {
		route = `${API_PATH}/portation/studies/${studyId}/combined/export/sav`;
	}

	if (includedQuantity && includedQuantity.length === 1) {
		return axios.postDownload(route, filesToExport, `${fileName}.csv`);
	}
	if (includedQuantity && includedQuantity.length > 1) {
		return axios.postDownload(route, filesToExport, fileName, {
			type: 'application/zip',
		});
	}
}
function publicExportDataWithStudyID(studyId, filesToExport, fileName, type = 'csv') {
	const includedQuantity = filesToExport.files.filter(file => file.include);
	let route = `${API_PATH}/public/portation/studies/${studyId}/combined/export/`;
	if (type === 'spss') {
		route = `${API_PATH}/public/portation/studies/${studyId}/combined/export/sav`;
	}

	if (includedQuantity && includedQuantity.length === 1) {
		return axios.postDownload(route, filesToExport, `${fileName}.csv`);
	}
	if (includedQuantity && includedQuantity.length > 1) {
		return axios.postDownload(route, filesToExport, fileName, {
			type: 'application/zip',
		});
	}
}

/**
 * Exports products by study id
 */
function exportResponsesWithAnswers(studyId, filename = `Export - Responses With Answers - ${studyId}.csv`) {
	return axios.download(`${API_PATH}/portation/studies/${studyId}/responses/answers/export`, filename);
}

/**
 * Exports products by study id
 */
function publicExportResponsesWithAnswers(studyId, filename = `Export - Responses With Answers - ${studyId}.csv`) {
	return axios.download(`${API_PATH}/public/portation/studies/${studyId}/responses/answers/export`, filename);
}

/**
 * Imports Respondents stuff
 */
function importResponsesWithAnswers(studyId, files) {
	const formData = new FormData();

	formData.append('importCsvFile', files[0]);

	return axios.post(`${API_PATH}/portation/studies/${studyId}/filters/import`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}

/**
 * Exports products by study id
 */
function exportResponses(studyId, filename = `Export - Responses - ${studyId}.csv`) {
	return axios.download(`${API_PATH}/portation/studies/${studyId}/responses/export`, filename);
}

/**
 * Exports products by study id
 */
function publicExportResponses(studyId, filename = `Export - Responses - ${studyId}.csv`) {
	return axios.download(`${API_PATH}/public/portation/studies/${studyId}/responses/export`, filename);
}

/**
 * Exports products by study id
 */
function exportInterest(studyId, filename = `Export - Interest - ${studyId}.csv`) {
	return axios.download(`${API_PATH}/portation/studies/${studyId}/interest/export`, filename);
}

/**
 * Exports products by study id
 */
function publicExportInterest(studyId, filename = `Export - Interest - ${studyId}.csv`) {
	return axios.download(`${API_PATH}/public/portation/studies/${studyId}/interest/export`, filename);
}

/**
 * Exports products by study id
 */
function exportCommitment(studyId, filename = `Export - Commitment - ${studyId}.csv`) {
	return axios.download(`${API_PATH}/portation/studies/${studyId}/commitment/export`, filename);
}

/**
 * Exports products by study id
 */
function publicExportCommitment(studyId, filename = `Export - Commitment - ${studyId}.csv`) {
	return axios.download(`${API_PATH}/public/portation/studies/${studyId}/commitment/export`, filename);
}

/**
 * Exports Question Answers to CSV by Question id
 */
function exportQuestionAnswers(
	studyId,
	questionId,
	filters,
	questionFilters = [],
	audienceUuids,
	filename = `Export - Question Answers - Study ${studyId} - Question ${questionId}.csv`,
	language = 'en',
	collectionId,
	filteredDataPoints,
	productId,
	clicks,
	uniqueAudience,
) {
	if (collectionId) {
		// audience report export
		return axios.postDownload(
			`${API_PATH}/portation/studies/${studyId}/audience/collection/${collectionId}/questions/${questionId}/export`,
			{
				filters,
				audienceUuids: audienceUuids?.filter(uuid => uuid !== 'exclude-disquals'),
				excludeDisquals: audienceUuids?.includes('exclude-disquals'),
				language,
			},
			filename,
		);
	}

	return axios.postDownload(
		`${API_PATH}/portation/studies/${studyId}/questions/${questionId}/answers/export`,
		{
			filters,
			questionFilters,
			audienceUuids: audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
			language,
			filteredDataPoints,
			productId,
			clicks,
			uniqueAudience,
		},
		filename,
	);
}

function exportDemographicQuestionAnswers(
	studyId,
	questionId,
	filters,
	questionFilters = [],
	audienceUuids,
	filename = `Export - Question Answers - Study ${studyId} - Question ${questionId}.csv`,
	language = 'en',
	audienceUuid,
) {
	return axios.postDownload(
		`${API_PATH}/portation/studies/${studyId}/audiences/${audienceUuid}/questions/${questionId}/export/v2`,
		{
			filters,
			questionFilters,
			audienceUuids: audienceUuids?.filter(uuid => uuid !== 'exclude-disquals'),
			excludeDisquals: audienceUuids?.includes('exclude-disquals'),
			language,
		},
		filename,
	);
}

/**
 * Exports Question Answers to CSV by Question id
 */
function publicExportQuestionAnswers(
	studyId,
	questionId,
	filters,
	questionFilters = [],
	audienceUuids,
	filename = `Export - Question Answers - Study ${studyId} - Question ${questionId}.csv`,
	language = 'en',
	collectionId,
) {
	if (collectionId) {
		// audience report export
		return axios.postDownload(
			`${API_PATH}/public/portation/studies/${studyId}/audience/collection/${collectionId}/questions/${questionId}/export`,
			{
				filters,
				audienceUuids: audienceUuids?.filter(uuid => uuid !== 'exclude-disquals'),
				excludeDisquals: audienceUuids?.includes('exclude-disquals'),
				language,
			},
			filename,
		);
	}
	return axios.postDownload(
		`${API_PATH}/public/portation/studies/${studyId}/questions/${questionId}/answers/export`,
		{
			filters,
			questionFilters,
			audienceUuids: audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
			language,
		},
		filename,
	);
}

function publicExportDemographicQuestionAnswers(
	studyId,
	questionId,
	filters,
	questionFilters = [],
	audienceUuids,
	filename = `Export - Question Answers - Study ${studyId} - Question ${questionId}.csv`,
	language = 'en',
	audienceUuid,
) {
	return axios.postDownload(
		`${API_PATH}/public/portation/studies/${studyId}/audiences/${audienceUuid}/questions/${questionId}/export/v2`,
		{
			filters,
			questionFilters,
			audienceUuids: audienceUuids?.filter(uuid => uuid !== 'exclude-disquals'),
			excludeDisquals: audienceUuids?.includes('exclude-disquals'),
			language,
		},
		filename,
	);
}

/**
 * Export Idea Split
 */
function exportMonadicSplit(studyId, fileType = 'csv') {
	return axios.download(
		`${API_PATH}/portation/studies/${studyId}/splits`,
		`Export - Idea Split - Study ${studyId}.${fileType}`,
	);
}

/**
 * Export Idea Split
 */
function publicExportMonadicSplit(studyId, fileType = 'csv') {
	return axios.download(
		`${API_PATH}/public/portation/studies/${studyId}/splits`,
		`Export - Idea Split - Study ${studyId}.${fileType}`,
	);
}

/**
 * Export Idea Split
 */
function exportMonadicSplitComponent(studyId, studyName, sectionId, data, fileType = 'csv') {
	const { audienceUuids, filters, languageCode, localFilters, baselineProductId, confidenceLevel, statTesting } =
		data;
	LoadingToaster.show('Your CSV is downloading...');
	return axios
		.postDownload(
			`${API_PATH}/portation/studies/${studyId}/sections/${sectionId}/splits`,
			{
				audienceUuids: audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
				filters,
				languageCode,
				localFilters,
				baselineProductId,
				confidenceLevel,
				statTesting,
			},
			`${studyName} - Idea Split.${fileType}`,
		)
		?.then(response => {
			toastr.remove();
			toastr.options = {
				positionClass: 'toast-bottom-left',
				timeOut: 3000,
			};
			if (response?.status === 200) toastr.success('Your CSV download is complete!');
			else toastr.error('There was an error downloading your CSV');
		});
}

/**
 * Export Idea Split
 */
function publicExportMonadicSplitComponent(studyId, studyName, sectionId, data, fileType = 'csv') {
	const { audienceUuids, filters, languageCode, localFilters, baselineProductId, confidenceLevel, statTesting } =
		data;
	LoadingToaster.show('Your CSV is downloading...');
	return axios
		.postDownload(
			`${API_PATH}/public/portation/studies/${studyId}/sections/${sectionId}/splits`,
			{
				audienceUuids: audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
				filters,
				languageCode,
				localFilters,
				baselineProductId,
				confidenceLevel,
				statTesting,
			},
			`${studyName} - Idea Split.${fileType}`,
		)
		?.then(response => {
			toastr.remove();
			toastr.options = {
				positionClass: 'toast-bottom-left',
				timeOut: 3000,
			};
			if (response?.status === 200) toastr.success('Your CSV download is complete!');
			else toastr.error('There was an error downloading your CSV');
		});
}

/**
 * Updates questions sort order
 */
function updateQuestionsOrder(studyId, ids) {
	return axios.post(`${API_PATH}/studies/${studyId}/questions/order`, ids);
}

function validateStudyAudiences(studyId) {
	return axios.get(`${API_PATH}/studies/${studyId}/audiences/validate`);
}

function validateStudy(studyId) {
	return axios.post(`${API_PATH}/studies/${studyId}/validate`);
}

function validatePublicStudy(studyId) {
	return axios.post(`${API_PATH}/public/studies/${studyId}/validate`);
}

/**
 * Generates Power Point report
 * @param {*} studyId
 * @param {*} data
 */
function generateReport(studyId, studyName, data) {
	return axios.postDownload(
		`${API_PATH}/studies/${studyId}/powerpoint`,
		{
			...data,
			audienceUuids: data?.audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
		},
		`${studyName} - Report.pptx`,
	);
}

/**
 * Generates Power Point report
 * @param {*} studyId
 * @param {*} data
 */
function generatePublicReport(studyId, studyName, data) {
	return axios.postDownload(
		`${API_PATH}/public/studies/${studyId}/powerpoint`,
		{
			...data,
			audienceUuids: data?.audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
		},
		`${studyName} - Report.pptx`,
	);
}

function generateInterestCsv(studyId, studyName, sectionId, data, fileType = 'csv') {
	const url = `${API_PATH}/portation/studies/${studyId}/sections/${sectionId}/interests`;
	const { filters, audienceUuids } = data;
	return axios.postDownload(
		url,
		{
			filters,
			audienceUuids: audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
		},
		`${studyName} - Idea Screen.${fileType}`,
	);
}

function generatePublicInterestCsv(studyId, studyName, sectionId, data, fileType = 'csv') {
	const url = `${API_PATH}/public/portation/studies/${studyId}/sections/${sectionId}/interests`;
	const { filters, audienceUuids } = data;
	return axios.postDownload(
		url,
		{
			filters,
			audienceUuids: audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
		},
		`${studyName} - Idea Screen.${fileType}`,
	);
}

function generateSocCsv(studyId, studyName, sectionId, data, fileType = 'csv') {
	const url = `${API_PATH}/portation/studies/${studyId}/sections/${sectionId}/SOC`;
	return axios.postDownload(
		url,
		{
			...data,
			audienceUuids: data?.audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
		},
		`${studyName} - Idea Screen.${fileType}`,
	);
}

function publicGenerateSocCsv(studyId, studyName, sectionId, data, fileType = 'csv') {
	const url = `${API_PATH}/public/portation/studies/${studyId}/sections/${sectionId}/SOC`;
	return axios.postDownload(
		url,
		{
			...data,
			audienceUuids: data?.audienceUuids?.filter(audience => audience !== 'exclude-disquals'),
		},
		`${studyName} - Idea Screen.${fileType}`,
	);
}

function disablePace(studyId, audienceUuid) {
	return axios.delete(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/pace`);
}

/**
 * Call for migrating study from one account to another
 * @param {*} studyId
 * @param {*} data
 */
function moveStudy(studyId, previousClientUuid, newClientUuid) {
	const data = {
		previousClientUuid,
		newClientUuid,
	};
	return axios.post(`${API_PATH}/studies/${studyId}/migrate`, data);
}

function reorderStudyTranslations(studyId, newOrder) {
	return axios.post(`${API_PATH}/studies/${studyId}/translations/order`, newOrder);
}

function translateStudy(studyId, translationData) {
	return axios.post(`${API_PATH}/studies/${studyId}/translate`, translationData);
}

function previewTranslation(translationData) {
	return axios.post(`${API_PATH}/studies/translate/preview`, translationData);
}

function getQualifications(countryLanguageId) {
	return axios.get(`${API_PATH}/qualifications?countryLanguageId=${countryLanguageId}`);
}

function getQualificationsV2(countryLanguageId, isDemographic = true) {
	return axios.get(
		`${API_PATH}/qualifications/v2?countryLanguageId=${countryLanguageId}&isDemographic=${isDemographic}`,
	);
}

function getQuestionLibrary(search = '', classes = []) {
	if (search.length > 0) return axios.post(`${API_PATH}/qualifications?isDemographic=false`, { search, classes });
	return axios.post(`${API_PATH}/qualifications?isDemographic=false`, { classes });
}
function getQuestionLibraryClasses() {
	return axios.get(`${API_PATH}/qualifications/classes`);
}
function deleteQuestionLibraryFromAudience(studyId, questionId) {
	return axios.delete(`${API_PATH}/studies/${studyId}/questions/${questionId}`);
}
function deleteQuestionOption(studyId, questionId, optionId) {
	return axios.delete(`${API_PATH}/studies/${studyId}/questions/${questionId}/options/${optionId}`);
}
function deleteQuestionAttribute(studyId, questionId, attributeId) {
	return axios.delete(`${API_PATH}/studies/${studyId}/questions/${questionId}/attributes/${attributeId}`);
}

function addQuestionFromLibrary({ studyId, questionLibraryId, audienceCollectionId, sortOrder = 0 }) {
	return axios.post(`${API_PATH}/qualifications/clone`, {
		studyId,
		questionLibraryId,
		audienceCollectionId,
		sortOrder,
	});
}

function confirmIncidenceRate(
	studyId,
	audienceUuid,
	isNewRateAccepted,
	taxes,
	creditCardId,
	paymentMethod,
	amountPaidByCredits,
) {
	return isNewRateAccepted
		? axios.post(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/confirm-ir`, {
				isNewRateAccepted,
				taxes,
				creditCardId,
				paymentMethod,
				amountPaidByCredits,
		  })
		: axios.post(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/confirm-ir`, {
				isNewRateAccepted,
		  });
}

function getResponses(studyId) {
	return axios.post(`${API_PATH}/studies/${studyId}/responses`);
}

function setResponseIsValid(studyId, responseId, isValid) {
	return axios.patch(`${API_PATH}/studies/${studyId}/responses/${responseId}`, { isValid });
}
function createDemoStudy(accountUuid) {
	const config = {};
	if (accountUuid) {
		config.headers = { 'x-account-uuid': accountUuid };
	}
	return axios.post(`${API_PATH}/study/demo/create`, {}, config);
}

function getAllProducts(studyId) {
	return axios.get(`${API_PATH}/studies/${studyId}/products/library`);
}
function addIdeaIntoSection(studyId, sectionId, productIds) {
	return axios.post(`${API_PATH}/studies/${studyId}/products/section/${sectionId}`, { productIds });
}
function patchStudyTranslation(studyId, studyTranslationId, data) {
	return axios.patch(`${API_PATH}/studies/${studyId}/changeLanguage/${studyTranslationId}`, data);
}
function detectStudyLanguage(studyId, studyTranslationId, data) {
	return axios.get(`${API_PATH}/studies/${studyId}/detectLanguage/${studyTranslationId}`, data);
}

function getHelpResources({ limit = 10, offset = 0, type = 'academy' }) {
	return axios.get(`${API_PATH}/help-resource?limit=${limit}&offset=${offset}&type=${type}`);
}

function removeStudyTag(studyId, tagId) {
	return axios.delete(`${API_PATH}/studies/${studyId}/tag/${tagId}`);
}

function getStudyTags() {
	return axios.get(`${API_PATH}/studies/template/tags`);
}

function setDownloadMethod(studyId, downloadId, deliveryMethod) {
	return axios.patch(`${API_PATH}/portation/studies/${studyId}/downloads/${downloadId}`, { deliveryMethod });
}

async function initializeDownload(studyId, type = 'spss') {
	return axios.post(`${API_PATH}/portation/studies/${studyId}/initialize-download`, { type });
}
async function publicInitializeDownload(studyId, type = 'spss') {
	return axios.post(`${API_PATH}/public/portation/studies/${studyId}/initialize-download`, { type });
}

const exportStudyQuestionnaire = async ({ studyId, languages }) =>
	axios.post(
		`${API_PATH}/portation/studies/${studyId}/questionnaire/export`,
		{ languages },
		{ responseType: 'arraybuffer' },
	);

export default {
	createStudy,
	changeStatus,
	getStudy,
	getStudies,
	searchStudies,
	updateStudy,
	patchStudy,
	patchStudySettings,
	deleteStudy,
	exportStatements,
	exportQuestions,
	publicExportQuestions,
	exportProducts,
	publicExportProducts,
	importProducts,
	importStatements,
	importQuestions,
	validateFilters,
	importFilters,
	downloadTemplate,
	publicDownloadTemplate,
	exportDataWithStudyID,
	publicExportDataWithStudyID,
	exportResponsesWithAnswers,
	publicExportResponsesWithAnswers,
	importResponsesWithAnswers,
	exportResponses,
	publicExportResponses,
	exportInterest,
	publicExportInterest,
	exportCommitment,
	publicExportCommitment,
	exportQuestionAnswers,
	exportDemographicQuestionAnswers,
	publicExportDemographicQuestionAnswers,
	publicExportQuestionAnswers,
	updateQuestionsOrder,
	validateStudyAudiences,
	validateStudy,
	validatePublicStudy,
	generateReport,
	generatePublicReport,
	generateInterestCsv,
	generatePublicInterestCsv,
	generateSocCsv,
	publicGenerateSocCsv,
	disablePace,
	moveStudy,
	reorderStudyTranslations,
	getQuestionLibraryClasses,
	translateStudy,
	previewTranslation,
	getQualifications,
	getQuestionLibrary,
	deleteQuestionLibraryFromAudience,
	deleteQuestionOption,
	deleteQuestionAttribute,
	addQuestionFromLibrary,
	getQualificationsV2,
	confirmIncidenceRate,
	exportMonadicSplit,
	publicExportMonadicSplit,
	getResponses,
	setResponseIsValid,
	exportFilters,
	exportMonadicSplitComponent,
	publicExportMonadicSplitComponent,
	createDemoStudy,
	getAllProducts,
	addIdeaIntoSection,
	getHelpResources,
	removeStudyTag,
	patchStudyTranslation,
	detectStudyLanguage,
	getStudyTags,
	setDownloadMethod,
	initializeDownload,
	exportStudyQuestionnaire,

	// public study
	getPublicStudy,
	getPublicStudies,
	publicInitializeDownload,
};
