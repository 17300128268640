import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Iconof } from '@upsiide/ui-components';
import { motion } from 'framer-motion';
import ModelOptions from 'src/config/modelOptions';
import cn from 'src/utilities/bem-cn';
import { assetIsVideo } from 'src/utilities/misc';
import Input from 'src/components/inputs/Input';
import Select from 'src/components/inputs/Select';
import CONSTANTS from 'src/config/constants';
import ToggleSwitch from 'src/components/elements/ToggleSwitch';
import Collapse from 'src/components/shared/Collapse';
import IconButton from 'src/components/elements/IconButton';
import RadioSettingButton from 'src/components/inputs/RadioSettingButton';
import { OptionalQuestionWarningModal } from 'src/components/shared/OptionalQuestionWarningModal';
import QuillMentionUtil from 'src/components/inputs/SimpleRichText/QuillMentionUtil';
import { calculateTotalOptions } from 'src/components/helpers';
import CustomizeVisibilityDropdown from '../CustomizeVisibilityDropdown';
import SVGS from './svgs';
import './styles.scss';

const className = 'config';
const el = (name, mod) => cn(className, name, mod);
const LIMIT_SELECT_OPTIONS = [
	{ label: 'Unlimited', value: 'unlimited' },
	{ label: 'Exactly', value: 'exact' },
	{ label: 'Up to', value: 'range' },
];

const SELECT_N_LIMIT = CONSTANTS.rankingLimit;

const MoreMenu = React.memo(({ question, tempTemplate, blockUpdateOrBreakTemplate, deleteQuestion }) => {
	const [open, setOpen] = useState(false);
	const contentVariants = {
		complete: { scale: 1 },
		start: { scale: 0 },
	};

	const helpLink = () => {
		let link = 'https://support.upsiide.com/en/articles/6295661';
		switch (question && question.style) {
			case 'single-select':
			case 'multi-select':
			case 'ranked':
			case 'heatmap':
				link += '-question-types#h_2ba8d2e99c';
				break;
			case 'open-ended':
				link += '-question-types#h_11bba1fae9';
				break;
			case 'grid':
				link += '-question-types#h_d4b49d9492';
				break;
			case 'emoji':
				link += '-question-types#h_347afc2273';
				break;
			default:
				console.error(`could not create help link based on question style: ${question.style}`);
				break;
		}
		return link;
	};

	return (
		<>
			<div className={`${el('more-menu')} ${open && el('more-menu-open')}`}>
				<IconButton
					icon="more_vertical"
					color="primary-green"
					onClick={() => setOpen(state => !state)}
					tooltip="More"
				/>
			</div>
			<motion.div
				className={el('more-menu-content')}
				initial="start"
				animate={open ? 'complete' : 'start'}
				variants={contentVariants}
			>
				<IconButton
					icon="delete_outlined"
					onClick={() => {
						if (tempTemplate)
							blockUpdateOrBreakTemplate(() => {
								deleteQuestion(question.id);
							});
						else deleteQuestion(question.id);
					}}
					tooltip="Delete"
				/>
				<a target="_blank" rel="noopener noreferrer" href={helpLink()}>
					<IconButton icon="help" tooltip="Help" />
				</a>
			</motion.div>
		</>
	);
});
MoreMenu.displayName = 'MoreMenu';
MoreMenu.propTypes = {
	question: PropTypes.object,
	tempTemplate: PropTypes.any,
	blockUpdateOrBreakTemplate: PropTypes.func,
	deleteQuestion: PropTypes.func,
};

const QuestionOptionsConfig = ({
	section,
	sections,
	question,
	saveSetting,
	tempTemplate,
	blockUpdateOrBreakTemplate,
	deleteQuestion,
	changeSetting,
	localSettings,
	setLoading,
	asset,
	assetType, // required because in custom audience questions (custom targets) asset is sometimes just a url
	audience = false,
	studyId,
	changeScreeningQuestionVisibility,
	audienceTemplate,
	dragHandleContextId,
}) => {
	const [selectLimitInputError, setSelectLimitInputError] = useState(false);
	const [enableOptionImageSettings, setEnableOptionImageSettings] = useState(false);
	const [enableAttributeImageSettings, setEnableAttributeImageSettings] = useState(false);
	const [showNumericRangeError, setShowNumericRangeError] = useState(false);

	/** imageSettingMenuOpen -> [questionImageAlignment, attrLayout, attrFill, optLayout, optFill] */
	const [imageSettingMenuOpen, setImageSettingMenuOpen] = useState([false, false, false, false, false]);
	const [imageAlignment, setImageAlignment] = useState('center');
	const [enableQuestionImageAlignment, setEnableQuestionImageAlignment] = useState(false);
	const optionalModalRef = useRef();
	const { settings } = section || { settings: [] };
	const ideaScreenLayoutSetting = settings.find(s => s.label === 'idea_screen_layout');
	const defaultIdeaScreenLayout = ideaScreenLayoutSetting ? ideaScreenLayoutSetting.value : 'invalid_layout';
	const ideaScreenLayout = defaultIdeaScreenLayout;

	useEffect(() => {
		let optionAssetCount = 0;
		let attributeAssetCount = 0;
		if (tempTemplate) {
			if (question && question.options)
				question.options.forEach(option => {
					optionAssetCount += option.translations.filter(
						optionTranslation => optionTranslation.assetId,
					).length;
				});
			if (question && question.attributes)
				question.attributes.forEach(attribute => {
					attributeAssetCount += attribute.translations.filter(
						attributeTranslation => attributeTranslation.assetId,
					).length;
				});
		} else {
			if (question && question.options)
				optionAssetCount = question.options.filter(option => option.assetId).length;
			if (question && question.attributes)
				attributeAssetCount = question.attributes.filter(attribute => attribute.assetId).length;
		}

		if (question) {
			setEnableQuestionImageAlignment(question.assetId && !assetIsVideo(asset) && assetType !== 'video');
			if (question.settings) {
				const imageAlignSetting = question.settings.find(
					s => s.label === CONSTANTS.imageAlignmentSettings.questionSection,
				);
				if (imageAlignSetting) {
					setImageAlignment(imageAlignSetting.value);
				}
				if (assetIsVideo(asset) || assetType === 'video') {
					setImageAlignment(CONSTANTS.imageAlignmentSettings.centerAlign);
				}
			}
		}

		if (asset && !isEmpty(asset) && !assetIsVideo(asset) && assetType !== 'video') {
			setEnableQuestionImageAlignment(true);
		}

		setEnableOptionImageSettings(!!optionAssetCount);
		setEnableAttributeImageSettings(!!attributeAssetCount);
		setLoading(false);
	}, [asset, assetType, enableQuestionImageAlignment, question, setLoading, tempTemplate]);

	const settingsImageCropping =
		question && question.settings && question.settings.find(s => s.label === 'option_image_cropping')
			? question.settings.find(s => s.label === 'option_image_cropping').value
			: 'fit';

	const settingsAttributeImageCropping =
		question && question.settings && question.settings.find(s => s.label === 'attribute_image_cropping')
			? question.settings.find(s => s.label === 'attribute_image_cropping').value
			: 'fit';

	const getDefaultSetting = (label, defaultValue, checkingString = false) => {
		// If section and section settings exist
		if (question && question.settings) {
			// Filter section.settings for the setting you are looking for
			const result = question.settings.find(item => item.label === label);
			// If the result comes back empty set it to true by default
			if (!result) {
				return defaultValue;
			}
			if (checkingString) {
				// Otherwise set it to whatever the saved value was
				return question.settings.filter(item => item.label === label)[0].value;
			}
			// Otherwise set it to whatever the saved value was
			return question.settings.filter(item => item.label === label)[0].value === 'true';
		}
		// If no conditions were met, set it to true by default
		return defaultValue;
	};

	const [imageCropping, setImageCropping] = useState(
		getDefaultSetting('option_image_cropping', settingsImageCropping || 'fit', true),
	);
	const [attributeImageCropping, setAttributeImageCropping] = useState(
		getDefaultSetting('attribute_image_cropping', settingsAttributeImageCropping || 'fit', true),
	);
	const imageCroppingOptions = ModelOptions.generic.imageCropping;

	const getLocalSetting = useCallback(
		(label, defaultValue) => {
			if (!localSettings || !localSettings.settings) return defaultValue;

			const settings = localSettings.settings.find(s => s.label === label);
			return settings;
		},
		[localSettings],
	);

	const [multipleSelectValue, setMultipleSelectValue] = useState(
		localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'multi-select')
			? localSettings.settings.find(s => s.label === 'multi-select').value === 'true'
			: false,
	);

	const [multipleClickSelectValue, setMultipleClickSelectValue] = useState(
		localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'multi-click')
			? localSettings.settings.find(s => s.label === 'multi-click').value === 'true'
			: true,
	);

	const [rankTopN, setRankTopN] = useState(
		localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'top-n')
			? localSettings.settings.find(s => s.label === 'top-n').value === 'true'
			: false,
	);

	const [rankTopNLimit, setRankTopNLimit] = useState(
		localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'top-n-limit')
			? localSettings.settings.find(s => s.label === 'top-n-limit').value
			: 1,
	);

	const [selectLimit, setSelectLimit] = useState(
		localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'limit')
			? localSettings.settings.find(s => s.label === 'limit').value
			: 1,
	);

	const [clickLimit, setClickLimit] = useState(
		localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'click-limit')
			? localSettings.settings.find(s => s.label === 'click-limit').value
			: 1,
	);

	const [selectLimitRange, setSelectLimitRange] = useState(
		localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'limit-type')
			? localSettings.settings.find(s => s.label === 'limit-type').value
			: 'unlimited',
	);

	const [clickLimitRange, setClickLimitRange] = useState(
		localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'click-limit-type')
			? localSettings.settings.find(s => s.label === 'click-limit-type').value
			: 'unlimited',
	);
	const [numericValueOnly, setNumericValueOnly] = useState(() => {
		const numericSetting = getLocalSetting('numeric');
		if (!numericSetting) return false;

		return numericSetting.value === 'true';
	});

	const [isOptionalQuestion, setIsOptionalQuestion] = useState(() => {
		const optionalSetting = getLocalSetting('optional');
		if (!optionalSetting) return false;

		return optionalSetting.value === 'true';
	});

	const [isOptionalComments, setIsOptionalComments] = useState(() => {
		const optionalSetting = getLocalSetting('optional-comments');
		if (!optionalSetting) return false;

		return optionalSetting.value === 'true';
	});

	const [isHeatmapSplitsImageOnly, setIsHeatmapSplitsImageOnly] = useState(() => {
		const optionalSetting = getLocalSetting('show-image-only');
		if (!optionalSetting) return false;

		return optionalSetting.value === 'true';
	});

	const [openEndNumericMin, setOpenEndNumericMin] = useState(() => {
		const numericMinSetting = getLocalSetting('numeric-min');
		if (!numericMinSetting) return '';

		return parseInt(numericMinSetting.value ? numericMinSetting.value : '0');
	});

	const [openEndNumericMax, setOpenEndNumericMax] = useState(() => {
		const openEndNumericMaxSetting = getLocalSetting('numeric-max');
		if (!openEndNumericMaxSetting) return '';

		return parseInt(openEndNumericMaxSetting.value ? openEndNumericMaxSetting.value : '0');
	});

	const [imageOnlyCheckboxValue, setImageOnlyCheckboxValue] = useState(() => {
		const imageOnlySetting = getDefaultSetting('image_only');
		return imageOnlySetting;
	});

	const [attributeImageOnlyCheckboxValue, setAttributeImageOnlyCheckboxValue] = useState(() => {
		const attributeImageOnlySetting = getDefaultSetting('attribute_image_only');
		return attributeImageOnlySetting;
	});

	const [randomizeAttributesCheckboxValue, setRandomizeAttributesCheckboxValue] = useState(() => {
		const randomizeAttributesSetting = getDefaultSetting('randomize_attributes');
		return randomizeAttributesSetting;
	});

	const updateSetting = useCallback(
		(setting, value, type) => {
			saveSetting(setting, value, type);
		},
		[saveSetting],
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const checkForSettings = () => {
		if (!localSettings.settings) localSettings.settings = [];
	};

	const updateNumericCheckBox = useCallback(
		newValue => {
			setNumericValueOnly(newValue);
			const numericSetting =
				localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'numeric')
					? localSettings.settings.find(s => s.label === 'numeric')
					: null;

			if (numericSetting) {
				numericSetting.value = newValue.toString();
			} else {
				checkForSettings();

				localSettings.settings.push({
					label: 'numeric',
					value: 'true',
					type: 'boolean',
				});
			}

			updateSetting('numeric', newValue.toString(), 'boolean');
		},
		[checkForSettings, localSettings, updateSetting],
	);

	const isPipedInOtherQuestions = useCallback(() => {
		const hasPipedWithCurrentQuestion = sections.content.find(({ questions }) => {
			const pipedQuestionIds = [];
			if (!questions || !questions.length) return false;

			questions.forEach(({ label }) => {
				const questionIds = QuillMentionUtil.getQuestionIds(label);
				if (!questionIds || !questionIds.length) return;
				pipedQuestionIds.push(...questionIds);
			});
			const currentQuestionPipedIds = pipedQuestionIds.filter(id => id === `${question.id}`);
			return currentQuestionPipedIds.length > 0;
		});
		return hasPipedWithCurrentQuestion;
	}, [question.id, sections]);

	const updateOptionalCheckBox = useCallback(
		async newValue => {
			if (newValue) {
				const isPiped = isPipedInOtherQuestions();
				if (isPiped) {
					const confirm = await optionalModalRef.current.onShow();
					if (!confirm) {
						setIsOptionalQuestion(!newValue);
						return;
					}
				}
			}

			setIsOptionalQuestion(newValue);
			const optionalSetting = getLocalSetting('optional');

			if (optionalSetting) {
				optionalSetting.value = newValue.toString();
			} else {
				checkForSettings();

				localSettings.settings.push({
					label: 'optional',
					value: 'true',
					type: 'boolean',
				});
			}

			updateSetting('optional', newValue.toString(), 'boolean');
		},
		[checkForSettings, getLocalSetting, isPipedInOtherQuestions, localSettings.settings, updateSetting],
	);

	const updateHeatmapSplitsImageOnly = useCallback(
		async newValue => {
			if (newValue) {
				const isPiped = isPipedInOtherQuestions();
				if (isPiped) {
					const confirm = await optionalModalRef.current.onShow();
					if (!confirm) {
						setIsHeatmapSplitsImageOnly(!newValue);
						return;
					}
				}
			}

			setIsHeatmapSplitsImageOnly(newValue);
			const imageOnlySetting = getLocalSetting('show-image-only');

			if (imageOnlySetting) {
				imageOnlySetting.value = newValue.toString();
			} else {
				checkForSettings();

				localSettings.settings.push({
					label: 'show-image-only',
					value: 'true',
					type: 'boolean',
				});
			}

			updateSetting('show-image-only', newValue.toString(), 'boolean');
		},
		[checkForSettings, getLocalSetting, isPipedInOtherQuestions, localSettings.settings, updateSetting],
	);

	const updateCommentsOptionalCheckBox = useCallback(
		async newValue => {
			if (newValue) {
				const isPiped = isPipedInOtherQuestions();
				if (isPiped) {
					const confirm = await optionalModalRef.current.onShow();
					if (!confirm) {
						setIsOptionalComments(!newValue);
						return;
					}
				}
			}

			setIsOptionalComments(newValue);
			const optionalSetting = getLocalSetting('optional-comments');

			if (optionalSetting) {
				optionalSetting.value = newValue.toString();
			} else {
				checkForSettings();

				localSettings.settings.push({
					label: 'optional-comments',
					value: 'true',
					type: 'boolean',
				});
			}

			updateSetting('optional-comments', newValue.toString(), 'boolean');
		},
		[checkForSettings, getLocalSetting, isPipedInOtherQuestions, localSettings.settings, updateSetting],
	);

	const updateMultiSelectCheckBox = useCallback(
		newValue => {
			setMultipleSelectValue(newValue);
			const multiSelectSetting =
				localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'multi-select')
					? localSettings.settings.find(s => s.label === 'multi-select')
					: null;

			if (multiSelectSetting) {
				multiSelectSetting.value = newValue.toString();
			} else {
				checkForSettings();
				localSettings.settings.push({
					label: 'multi-select',
					value: newValue.toString(),
					type: 'boolean',
				});
			}

			updateSetting('multi-select', newValue, 'boolean');
		},
		[checkForSettings, localSettings, updateSetting],
	);

	const updateRankTopNCheckBox = useCallback(
		newValue => {
			setRankTopN(newValue);
			const rankTopNValue =
				localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'top-n')
					? localSettings.settings.find(s => s.label === 'top-n')
					: null;

			if (rankTopNValue) {
				rankTopNValue.value = newValue.toString();
			} else {
				checkForSettings();
				localSettings.settings.push({
					label: 'top-n',
					value: newValue.toString(),
					type: 'boolean',
				});
			}

			updateSetting('top-n', newValue, 'boolean');
		},
		[checkForSettings, localSettings, updateSetting],
	);

	const updateMultiSelectTypeDropdown = useCallback(
		newValue => {
			setSelectLimitRange(newValue);
			if (selectLimitInputError) setSelectLimitInputError(false);
			const limitTypeSetting =
				localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'limit-type')
					? localSettings.settings.find(s => s.label === 'limit-type')
					: null;

			if (limitTypeSetting) {
				limitTypeSetting.value = newValue;
			} else {
				checkForSettings();
				localSettings.settings.push({
					label: 'limit-type',
					value: newValue,
					type: 'string',
				});
			}

			updateSetting('limit-type', newValue, 'string');
		},
		[checkForSettings, localSettings, selectLimitInputError, updateSetting],
	);

	const updateRankTopNLimitDropdown = useCallback(
		newValue => {
			setRankTopNLimit(newValue);
			const rankTopNLimit =
				localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'top-n-limit')
					? localSettings.settings.find(s => s.label === 'top-n-limit')
					: null;

			if (rankTopNLimit) {
				rankTopNLimit.value = newValue;
			} else {
				checkForSettings();
				localSettings.settings.push({
					label: 'top-n-limit',
					value: newValue,
					type: 'integer',
				});
			}

			updateSetting('top-n-limit', newValue, 'integer');
		},
		[checkForSettings, localSettings, updateSetting],
	);

	const updateMultiClickTypeDropdown = useCallback(
		newValue => {
			setClickLimitRange(newValue);
			if (selectLimitInputError) setSelectLimitInputError(false);
			const limitTypeSetting =
				localSettings &&
				localSettings.settings &&
				localSettings.settings.find(s => s.label === 'click-limit-type')
					? localSettings.settings.find(s => s.label === 'click-limit-type')
					: null;

			if (limitTypeSetting) {
				limitTypeSetting.value = newValue;
			} else {
				checkForSettings();
				localSettings.settings.push({
					label: 'click-limit-type',
					value: newValue,
					type: 'string',
				});
			}

			updateSetting('click-limit-type', newValue, 'string');
		},
		[checkForSettings, localSettings, selectLimitInputError, updateSetting],
	);

	const updateLocalSetting = useCallback(
		(setting, newValue) => {
			const localSetting =
				localSettings && localSettings.settings && localSettings.settings.find(s => s.label === setting)
					? localSettings.settings.find(s => s.label === setting)
					: null;

			if (localSetting) {
				localSetting.value = newValue;
			} else {
				checkForSettings();
				localSettings.settings.push({
					label: setting,
					value: newValue,
					type: 'integer',
				});
			}
		},
		[checkForSettings, localSettings],
	);

	const setData = useCallback(
		(label, value, type, languageCode) => ({
			label,
			value,
			type,
			languageCode,
		}),
		[],
	);

	useEffect(() => {
		setShowNumericRangeError(
			!!(
				openEndNumericMin &&
				openEndNumericMax &&
				parseInt(openEndNumericMin || '0') > parseInt(openEndNumericMax || '0')
			),
		);
	}, [openEndNumericMax, openEndNumericMin]);

	useEffect(() => {
		setRankTopN(
			localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'top-n')
				? localSettings.settings.find(s => s.label === 'top-n').value === 'true'
				: false,
		);

		setRankTopNLimit(
			localSettings && localSettings.settings && localSettings.settings.find(s => s.label === 'top-n-limit')
				? localSettings.settings.find(s => s.label === 'top-n-limit').value
				: 1,
		);
	}, [localSettings?.settings]);

	const totalOptions = [...new Set(calculateTotalOptions(question?.options, sections))].length;
	const topNDropdownOptions = [];
	const maxTopNOptions = totalOptions > SELECT_N_LIMIT ? SELECT_N_LIMIT : totalOptions;
	for (let i = 1; i <= maxTopNOptions; i++) {
		topNDropdownOptions.push({ value: i, label: i });
	}

	useEffect(() => {
		if (question?.style === 'heatmap') {
			return;
		}
		if (question?.options?.length > 0 && totalOptions < Number(selectLimit)) {
			setSelectLimit(totalOptions);
			saveSetting('limit', totalOptions, 'integer');
			if (selectLimitInputError) setSelectLimitInputError(false);
		}
	}, [question.options, saveSetting, selectLimit, selectLimitInputError, totalOptions]);

	const updateSectionSettings = value => {
		updateSetting(CONSTANTS.imageAlignmentSettings.questionSection, value, 'string');
	};

	const renderQuestionSettings = () => (
		<div className="image-settings">
			<span className={`${el('settings-section-header')}`}>Question Image</span>
			<div className={`${el('setting-option-container')}${!enableQuestionImageAlignment ? ' disabled' : ''}`}>
				<span className={`${el('setting-option-container-item')}`}>Alignment</span>
				<Tooltip
					arrow
					interactive
					position="bottom-end"
					open={imageSettingMenuOpen[0]}
					onRequestClose={() => setImageSettingMenuOpen([false, ...imageSettingMenuOpen.slice(1)])}
					hideOnClick
					theme={`${el('setting-option-container-tool-tip')} basic-text-tooltip-theme`}
					html={
						<div className="alignment-settings-container">
							<RadioSettingButton
								onChange={() => {
									setImageAlignment(CONSTANTS.imageAlignmentSettings.centerAlign);
									updateSectionSettings(CONSTANTS.imageAlignmentSettings.centerAlign);
									setImageSettingMenuOpen([false, ...imageSettingMenuOpen.slice(1)]);
								}}
								value={CONSTANTS.imageAlignmentSettings.centerAlign}
								svgImage={SVGS.CENTER_ALIGNED}
								label="Center"
								selected={imageAlignment === CONSTANTS.imageAlignmentSettings.centerAlign}
								disabled={!enableQuestionImageAlignment}
							/>
							<RadioSettingButton
								onChange={() => {
									setImageAlignment(CONSTANTS.imageAlignmentSettings.leftAlign);
									updateSectionSettings(CONSTANTS.imageAlignmentSettings.leftAlign);
									setImageSettingMenuOpen([false, ...imageSettingMenuOpen.slice(1)]);
								}}
								svgImage={SVGS.LEFT_ALIGNED}
								value={CONSTANTS.imageAlignmentSettings.leftAlign}
								label="Left"
								selected={imageAlignment === CONSTANTS.imageAlignmentSettings.leftAlign}
								disabled={!enableQuestionImageAlignment}
							/>
						</div>
					}
				>
					<span
						role="button"
						tabIndex={0}
						onClick={() =>
							setImageSettingMenuOpen([!imageSettingMenuOpen[0], ...imageSettingMenuOpen.slice(1)])
						}
						onKeyDown={() => {}}
						className={`${el('setting-option-container-item-tip')}`}
					>
						{imageAlignment}
						<Iconof
							className={`${el('setting-option-container-item-tip-icon')}`}
							icon={imageSettingMenuOpen[0] ? 'chevron_up' : 'chevron_down'}
						/>
					</span>
				</Tooltip>
			</div>
		</div>
	);

	const renderGridAttrSettings = () =>
		question.style === 'grid' && (
			<div className={el('grid-question-settings')}>
				<span className={`${el('settings-section-header')}`}>Attribute Image</span>
				<div className={el('item')}>
					<ToggleSwitch
						value={1}
						toggleCriteria={attributeImageOnlyCheckboxValue}
						text="Hide text with images"
						onToggle={() => {
							setAttributeImageOnlyCheckboxValue(!attributeImageOnlyCheckboxValue);
							updateSetting('attribute_image_only', !attributeImageOnlyCheckboxValue, 'boolean');
						}}
						disabled={!enableAttributeImageSettings}
					/>
					<span
						style={{ opacity: !enableAttributeImageSettings ? '50%' : '100%' }}
						className={el('item-label')}
					>
						Hide text with images
					</span>
				</div>
				<div className={`${el('setting-option-container')}${!enableAttributeImageSettings ? ' disabled' : ''}`}>
					<span className={`${el('setting-option-container-item')}`}>Fill</span>
					<Tooltip
						arrow
						interactive
						position="bottom-end"
						open={imageSettingMenuOpen[2]}
						onRequestClose={() =>
							setImageSettingMenuOpen([
								...imageSettingMenuOpen.slice(0, 2),
								false,
								...imageSettingMenuOpen.slice(3, 5),
							])
						}
						hideOnClick
						theme={`${el('setting-option-container-tool-tip')} basic-text-tooltip-theme`}
						html={
							<div className="alignment-settings-container">
								<RadioSettingButton
									onChange={() => {
										setAttributeImageCropping(imageCroppingOptions[0].value);
										const data = setData(
											'attribute_image_cropping',
											String(imageCroppingOptions[0].value),
											'string',
											null,
										);
										updateSetting('attribute_image_cropping', data, 'string');
										setImageSettingMenuOpen([
											...imageSettingMenuOpen.slice(0, 2),
											false,
											...imageSettingMenuOpen.slice(3, 5),
										]);
									}}
									value={imageCroppingOptions[0].value}
									label={imageCroppingOptions[0].label}
									selected={attributeImageCropping === imageCroppingOptions[0].value}
									disabled={!enableAttributeImageSettings}
								/>
								<RadioSettingButton
									onChange={() => {
										setAttributeImageCropping(imageCroppingOptions[1].value);
										const data = setData(
											'attribute_image_cropping',
											String(imageCroppingOptions[1].value),
											'string',
											null,
										);
										updateSetting('attribute_image_cropping', data, 'string');
										setImageSettingMenuOpen([
											...imageSettingMenuOpen.slice(0, 2),
											false,
											...imageSettingMenuOpen.slice(3, 5),
										]);
									}}
									value={imageCroppingOptions[1].value}
									label={imageCroppingOptions[1].label}
									selected={attributeImageCropping === imageCroppingOptions[1].value}
									disabled={!enableAttributeImageSettings}
								/>
							</div>
						}
					>
						<span
							role="button"
							tabIndex={0}
							onClick={() => {
								setImageSettingMenuOpen([
									...imageSettingMenuOpen.slice(0, 2),
									!imageSettingMenuOpen[2],
									...imageSettingMenuOpen.slice(3, 5),
								]);
							}}
							onKeyDown={() => {}}
							className={`${el('setting-option-container-item-tip')}`}
						>
							{attributeImageCropping === imageCroppingOptions[0].value
								? imageCroppingOptions[0].label
								: imageCroppingOptions[1].label}
							<Iconof
								className={`${el('setting-option-container-item-tip-icon')}`}
								icon={imageSettingMenuOpen[2] ? 'chevron_up' : 'chevron_down'}
							/>
						</span>
					</Tooltip>
				</div>
				<div className={el('advanced-settings-header-divider')} />
			</div>
		);

	const renderOptionSettings = () => (
		<>
			<span className={`${el('settings-section-header')}`}>Option Image</span>

			{question?.style !== 'ranked' && (
				<div className={el('item')}>
					<ToggleSwitch
						value={1}
						toggleCriteria={imageOnlyCheckboxValue}
						text="Hide text with images"
						onToggle={() => {
							setImageOnlyCheckboxValue(!imageOnlyCheckboxValue);
							updateSetting('image_only', !imageOnlyCheckboxValue, 'boolean');
						}}
						disabled={!enableOptionImageSettings}
					/>
					<span style={{ opacity: !enableOptionImageSettings ? '50%' : '100%' }} className={el('item-label')}>
						Hide text with images
					</span>
				</div>
			)}
			<div className={`${el('setting-option-container')}${!enableOptionImageSettings ? ' disabled' : ''}`}>
				<span className={`${el('setting-option-container-item')}`}>Fill</span>
				<Tooltip
					arrow
					interactive
					position="bottom-end"
					open={imageSettingMenuOpen[4]}
					onRequestClose={() => setImageSettingMenuOpen([...imageSettingMenuOpen.slice(0, 4), false])}
					hideOnClick
					theme={`${el('setting-option-container-tool-tip')} basic-text-tooltip-theme`}
					html={
						<div className="alignment-settings-container">
							<RadioSettingButton
								onChange={() => {
									setImageCropping(imageCroppingOptions[0].value);
									const data = setData(
										'option_image_cropping',
										String(imageCroppingOptions[0].value),
										'string',
										null,
									);
									updateSetting('option_image_cropping', data, 'string');
									setImageSettingMenuOpen([...imageSettingMenuOpen.slice(0, 4), false]);
								}}
								value={imageCroppingOptions[0].value}
								label={imageCroppingOptions[0].label}
								selected={imageCropping === imageCroppingOptions[0].value}
								disabled={!enableOptionImageSettings}
							/>
							<RadioSettingButton
								onChange={() => {
									setImageCropping(imageCroppingOptions[1].value);
									const data = setData(
										'option_image_cropping',
										String(imageCroppingOptions[1].value),
										'string',
										null,
									);
									updateSetting('option_image_cropping', data, 'string');
									setImageSettingMenuOpen([...imageSettingMenuOpen.slice(0, 4), false]);
								}}
								value={imageCroppingOptions[1].value}
								label={imageCroppingOptions[1].label}
								selected={imageCropping === imageCroppingOptions[1].value}
								disabled={!enableOptionImageSettings}
							/>
						</div>
					}
				>
					<span
						role="button"
						tabIndex={0}
						onClick={() => {
							setImageSettingMenuOpen([...imageSettingMenuOpen.slice(0, 4), !imageSettingMenuOpen[4]]);
						}}
						onKeyDown={() => {}}
						className={`${el('setting-option-container-item-tip')}`}
					>
						{imageCropping === imageCroppingOptions[0].value
							? imageCroppingOptions[0].label
							: imageCroppingOptions[1].label}
						<Iconof
							className={`${el('setting-option-container-item-tip-icon')}`}
							icon={imageSettingMenuOpen[4] ? 'chevron_up' : 'chevron_down'}
						/>
					</span>
				</Tooltip>
			</div>
		</>
	);

	return (
		<div className={className}>
			<h2 className={el('heading')}>Settings</h2>
			<MoreMenu
				question={question}
				tempTemplate={tempTemplate}
				blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
				deleteQuestion={deleteQuestion}
			/>
			{question.style !== 'open-ended' && question.style !== 'heatmap' && (
				<div className={el('first')}>
					{sections && !audience && (
						<div className={el('item')}>
							<ToggleSwitch
								value={isOptionalQuestion}
								toggleCriteria={isOptionalQuestion !== true}
								text="Required"
								onToggle={() => updateOptionalCheckBox(!isOptionalQuestion)}
							/>
							<span className={el('item-label')}>Required</span>
						</div>
					)}
					{question.style === 'grid' && (
						<div className={el('grid-question-settings')}>
							<div className={el('item')}>
								<ToggleSwitch
									value={1}
									toggleCriteria={randomizeAttributesCheckboxValue}
									text="Randomize Attributes"
									onToggle={() => {
										setRandomizeAttributesCheckboxValue(!randomizeAttributesCheckboxValue);
										updateSetting(
											'randomize_attributes',
											!randomizeAttributesCheckboxValue,
											'boolean',
										);
									}}
								/>
								<span className={el('item-label')}>Randomize Attributes</span>
							</div>
						</div>
					)}
					<div className={el('item')}>
						<ToggleSwitch
							value={1}
							disabled={question.style === 'emoji'}
							toggleCriteria={
								(localSettings && localSettings.randomizeOptions) || question.style === 'emoji'
							}
							text="Randomize Options"
							onToggle={() => changeSetting('randomizeOptions')}
						/>
						<span
							style={{ opacity: question.style === 'emoji' ? '50%' : '100%' }}
							className={el('item-label')}
						>
							Randomize Options
						</span>
					</div>

					{question.style !== 'single-select' && question.style !== 'ranked' && (
						<div className={el('item')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={multipleSelectValue}
								text="Multiple Selection"
								onToggle={() => {
									const updatedMultipleSelectValue = !multipleSelectValue;
									updatedMultipleSelectValue &&
										!!localSettings?.addScale &&
										changeSetting('addScale'); // * Scale is only available for single select questions
									updateMultiSelectCheckBox(updatedMultipleSelectValue);
								}}
							/>
							<span className={el('item-label')}>Multiple Selection</span>
						</div>
					)}

					{multipleSelectValue && (
						<div className={`${el('item')} multiple-selection`}>
							<Select
								value={selectLimitRange}
								onChange={e => {
									updateMultiSelectTypeDropdown(e);
								}}
								options={LIMIT_SELECT_OPTIONS}
								classNamePrefix="multi-select-select"
							/>
							{selectLimitRange && (selectLimitRange === 'range' || selectLimitRange === 'exact') && (
								<Input
									className="multiple-selection-input"
									type="number"
									value={selectLimit}
									onBlur={e => {
										if (selectLimit > 0 && selectLimit <= totalOptions)
											updateSetting('limit', selectLimit, 'integer');
									}}
									onChange={e => {
										if (e < 0) return;

										setSelectLimit(e);
										if (e > 0 && e <= totalOptions) updateSetting('limit', e, 'integer');

										if (e <= 0 || e > totalOptions) setSelectLimitInputError(true);
										else {
											setSelectLimitInputError(false);
											updateLocalSetting('limit', e);
										}
									}}
									onKeyPress={e => {
										if (e.key === '.') {
											e.preventDefault();
										}
									}}
								/>
							)}
							{selectLimitInputError && <div className={el('input-error')}>Invalid Select Limit</div>}
						</div>
					)}

					{question.style === 'ranked' && (
						<div className={el('item')}>
							<ToggleSwitch
								// disabled={totalOptions > SELECT_N_LIMIT}
								value={1}
								toggleCriteria={rankTopN}
								text="Rank Top n"
								onToggle={() => updateRankTopNCheckBox(!rankTopN)}
							/>
							<span className={el('item-label')}>
								Rank Top <span className={el('rank-top-n-label')}>n</span>
							</span>
						</div>
					)}
					{rankTopN && (
						<div className={`${el('item')} top-n-selection`}>
							<div>Options to rank</div>
							<div className={el('top-n-limit-select')}>
								<Select
									value={parseInt(rankTopNLimit)}
									onChange={e => {
										updateRankTopNLimitDropdown(e);
									}}
									options={topNDropdownOptions}
									classNamePrefix="top-n-limit-select"
								/>
							</div>
						</div>
					)}
					{maxTopNOptions >= SELECT_N_LIMIT && !rankTopN && question?.style === 'ranked' && (
						<div className={el('top-n-warning')}>
							<div>
								<Iconof icon="info" className={el('info-icon')} />
							</div>
							<span>The maximum number for ranking is {SELECT_N_LIMIT}</span>
						</div>
					)}
					{['single-select', 'multi-select', 'grid'].includes(question.style) && (
						<div className={el('item')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={!!localSettings?.addScale}
								text="Add Scale"
								disabledText="Scale unavailable with multiple selection"
								disabled={!!multipleSelectValue} // * Scale is only available for single select questions
								onToggle={() => changeSetting('addScale')}
							/>

							<span className={el('item-label')}>Add Scale</span>
						</div>
					)}

					{audience ? (
						<CustomizeVisibilityDropdown
							studyId={studyId}
							questionId={question.id}
							changeScreeningQuestionVisibility={changeScreeningQuestionVisibility}
							isTemplate={!!audienceTemplate}
							dragHandleContextId={dragHandleContextId}
						/>
					) : null}
					<Collapse
						title="Image Settings"
						animate
						open={
							!enableQuestionImageAlignment && !enableAttributeImageSettings && !enableOptionImageSettings
								? false
								: null
						}
					>
						{renderQuestionSettings()}
						{question.style !== 'emoji' && (
							<>
								<div className={el('advanced-settings-header-divider')} />
								{renderGridAttrSettings()}
								{renderOptionSettings()}
							</>
						)}
					</Collapse>
				</div>
			)}
			{question.style === 'heatmap' && (
				<div className={el('first')}>
					{sections && !audience && (
						<div className={el('item')}>
							<ToggleSwitch
								value={isOptionalQuestion}
								toggleCriteria={isOptionalQuestion !== true}
								text="Question Required"
								onToggle={() => updateOptionalCheckBox(!isOptionalQuestion)}
							/>
							<span className={el('item-label')}>Required</span>
						</div>
					)}

					<div className={el('item')}>
						<span className={el('item-label')}>Number of clicks allowed</span>
					</div>
					{multipleClickSelectValue && (
						<div
							className={`${el('item')} multiple-selection ${
								clickLimitRange === 'range' || clickLimitRange === 'exact' ? 'limit' : ''
							}`}
						>
							<Select
								value={clickLimitRange}
								onChange={e => {
									updateMultiClickTypeDropdown(e);
								}}
								options={LIMIT_SELECT_OPTIONS}
								classNamePrefix="multi-select-select"
							/>
							{clickLimitRange && (clickLimitRange === 'range' || clickLimitRange === 'exact') && (
								<Input
									className="multiple-selection-input"
									type="number"
									value={clickLimit}
									onBlur={e => {
										if (clickLimit > 0) updateSetting('click-limit', clickLimit, 'integer');
									}}
									onChange={e => {
										if (e < 0) return;
										setClickLimit(e);

										if (e <= 0) setSelectLimitInputError(true);
										else {
											setSelectLimitInputError(false);
											updateLocalSetting('click-limit', e);
										}
									}}
									onKeyPress={e => {
										if (e.key === '.') {
											e.preventDefault();
										}
									}}
								/>
							)}
							{selectLimitInputError && <div className={el('input-error')}>Invalid Select Limit</div>}
						</div>
					)}
					<div className={el('item')}>
						<ToggleSwitch
							value={isOptionalComments}
							toggleCriteria={isOptionalComments !== true}
							text="Collect Comments"
							onToggle={() => updateCommentsOptionalCheckBox(!isOptionalComments)}
						/>
						<span className={el('item-label')}>Collect Comments</span>
						<span className={el('item-label-caption')}>
							When switched off only clicks will be collected
						</span>
					</div>
					{section?.type === 'monadic_split' && (
						<div
							className={`${el('item')}${
								ideaScreenLayout === 'textOnly' ||
								ideaScreenLayout === 'doubleText' ||
								ideaScreenLayout === 'imageOnly'
									? ' disabled'
									: ''
							}`}
						>
							<ToggleSwitch
								value={isHeatmapSplitsImageOnly}
								toggleCriteria={isHeatmapSplitsImageOnly === true}
								text="Show image only"
								onToggle={() => updateHeatmapSplitsImageOnly(!isHeatmapSplitsImageOnly)}
								disabled={
									ideaScreenLayout === 'textOnly' ||
									ideaScreenLayout === 'doubleText' ||
									ideaScreenLayout === 'imageOnly'
								}
							/>
							<span className={el('item-label')}>Show image only</span>
						</div>
					)}
				</div>
			)}
			{question.style === 'open-ended' && (
				<div className={el('first')}>
					<div className={el('header')}>Option Settings</div>
					{sections && (
						<div className={el('item')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={isOptionalQuestion !== true}
								text="Required"
								onToggle={() => updateOptionalCheckBox(!isOptionalQuestion)}
							/>
							<span className={el('item-label')}>Required</span>
						</div>
					)}

					<div className={el('item')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={numericValueOnly}
							text="Numeric Value Only"
							onToggle={() => updateNumericCheckBox(!numericValueOnly)}
						/>
						<span className={el('item-label')}>Numeric Value Only</span>
					</div>
					{numericValueOnly && (
						<div className={`${el('item')} ${el('item-numeric-input')}`}>
							<div className={el('numeric-input-item')}>
								<Input
									placeholder="Min"
									style={{ marginRight: 20 }}
									type="number"
									value={openEndNumericMin}
									onBlur={() => {
										updateSetting('numeric-min', openEndNumericMin, 'integer');
									}}
									onChange={e => {
										setOpenEndNumericMin(e);
										updateLocalSetting('numeric-min', e);
									}}
								/>
							</div>
							<div className={el('numeric-input-item')}>
								<Input
									placeholder="Max"
									style={{ marginRight: 20 }}
									type="number"
									value={openEndNumericMax}
									onBlur={() => {
										updateSetting('numeric-max', openEndNumericMax, 'integer');
									}}
									onChange={e => {
										setOpenEndNumericMax(e);
										updateLocalSetting('numeric-max', e);
									}}
								/>
							</div>
							{showNumericRangeError && (
								<div className={el('input-error')}>Max value must be greater than min value</div>
							)}
						</div>
					)}
					<Collapse title="Image Settings" animate>
						{renderQuestionSettings()}
					</Collapse>
				</div>
			)}
			<OptionalQuestionWarningModal ref={optionalModalRef} />
		</div>
	);
};

QuestionOptionsConfig.propTypes = {
	section: PropTypes.object,
	sections: PropTypes.any,
	question: PropTypes.object,
	saveSetting: PropTypes.func,
	tempTemplate: PropTypes.any,
	blockUpdateOrBreakTemplate: PropTypes.func,
	deleteQuestion: PropTypes.func,
	changeSetting: PropTypes.func,
	localSettings: PropTypes.any,
	setLoading: PropTypes.func,
	asset: PropTypes.any,
	assetType: PropTypes.string,
	audience: PropTypes.any,
	studyId: PropTypes.number,
	changeScreeningQuestionVisibility: PropTypes.func,
	audienceTemplate: PropTypes.any,
	dragHandleContextId: PropTypes.any,
};
export default QuestionOptionsConfig;
