import React from 'react';
import { element } from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';

import './styles.scss';

const className = 'gallery-tabs';
const el = element(className);

/**
 * Assets Gallery
 */
const GalleryTabs = ({ tabs, activeTab, setActiveTab, additionalClass = '' }) => (
	<div className={`${className} ${additionalClass}`}>
		{tabs.map(item => (
			<div
				onClick={e => setActiveTab(item.name, e)}
				onKeyDown={() => {}}
				role="button"
				tabIndex={0}
				key={item.name}
				className={`${el('tab')} ${activeTab === item.name ? 'active' : ''} ${item.disabled ? 'disabled' : ''}`}
			>
				{item.label}
			</div>
		))}
	</div>
);

GalleryTabs.propTypes = {
	tabs: PropTypes.any,
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
	additionalClass: PropTypes.string,
};

export default GalleryTabs;
