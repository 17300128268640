import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

const create = (studyId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/sections/`, data).then(response => response);

const getAll = studyId => axios.get(`${API_PATH}/studies/${studyId}/sections`).then(response => response);

const getPublicAll = studyId => axios.get(`${API_PATH}/public/studies/${studyId}/sections`).then(response => response);

const blueprintGetAll = studyId => axios.get(`${API_PATH}/blueprints/${studyId}/sections`).then(response => response);

const get = (studyId, sectionId, mode = null, languageCode = null) =>
	axios
		.get(`${API_PATH}/studies/${studyId}/sections/${sectionId}`, {
			params: {
				mode,
				languageCode,
			},
		})
		.then(response => response);

const getPublic = (studyId, sectionId, mode = null, languageCode = null) =>
	axios
		.get(`${API_PATH}/public/studies/${studyId}/sections/${sectionId}`, {
			params: {
				mode,
				languageCode,
			},
		})
		.then(response => response);

const reorder = (studyId, sectionIds) =>
	axios.post(`${API_PATH}/studies/${studyId}/sections/order`, sectionIds).then(response => response);

const patch = (studyId, sectionId, data) =>
	axios.patch(`${API_PATH}/studies/${studyId}/sections/${sectionId}`, data).then(response => response);

const deleteSection = (studyId, sectionId) =>
	axios.delete(`${API_PATH}/studies/${studyId}/sections/${sectionId}`).then(response => response);

const addItem = (studyId, sectionId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/sections/${sectionId}/items`, data).then(response => response);

const patchItem = (studyId, sectionId, itemId, itemData) =>
	axios
		.patch(`${API_PATH}/studies/${studyId}/sections/${sectionId}/items/${itemId}`, itemData)
		.then(response => response);

const patchAudienceItem = (studyId, audienceUuid, itemId, itemData) =>
	axios
		.patch(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/items/${itemId}`, itemData)
		.then(response => response);

const deleteItemLogic = (studyId, sectionId, logicId) =>
	axios.delete(`${API_PATH}/studies/${studyId}/sections/${sectionId}/logic/${logicId}`).then(response => response);

const deleteAudienceItemLogic = (studyId, audienceUuid, logicId) =>
	axios
		.delete(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/logic/${logicId}`)
		.then(response => response);

const patchSectionLogic = (studyId, sectionId, logic) =>
	axios
		.patch(`${API_PATH}/studies/${studyId}/sections/${sectionId}`, {
			logic,
		})
		.then(({ data }) => data)
		.catch(err => {
			console.error(err);
		});

const postSectionSettings = (studyId, sectionId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/sections/${sectionId}/settings/`, data).then(response => response);

const patchSectionSettings = (studyId, sectionId, settingId, data) =>
	axios
		.patch(`${API_PATH}/studies/${studyId}/sections/${sectionId}/settings/${settingId}`, data)
		.then(response => response);

const deleteSectionSettings = (studyId, sectionId, settingId) =>
	axios
		.delete(`${API_PATH}/studies/${studyId}/sections/${sectionId}/settings/${settingId}`)
		.then(response => response);

const postMonadicSplitReporting = (studyId, sectionId, data) => {
	const payload = {
		...data,
		audienceUuids: data?.audienceUuids?.filter(audienceUuid => audienceUuid !== 'exclude-disquals'),
	};
	return axios
		.post(`${API_PATH}/studies/${studyId}/sections/${sectionId}/report`, payload)
		.then(response => response);
};

const getMonadicSplitStatistics = (studyId, sectionId, data, isPublic = false) => {
	const { filters = [], localFilters = [], audienceUuids = [], baselineProductId } = data;
	return axios
		.post(`${API_PATH}${isPublic ? '/public' : ''}/studies/${studyId}/sections/${sectionId}/stat-test`, {
			filters,
			audienceUuids,
			sectionId,
			localFilters,
			baselineProductId,
		})
		.then(response => response);
};

const updateScreenLayout = (studyId, sectionId, layout) =>
	axios
		.post(`${API_PATH}/studies/${studyId}/sections/${sectionId}/settings/layout`, { layout })
		.then(response => response);

const postPublicMonadicSplitReporting = (studyId, sectionId, data) =>
	axios
		.post(`${API_PATH}/public/studies/${studyId}/sections/${sectionId}/report`, {
			...data,
			audienceUuids: data?.audienceUuids?.filter(audienceUuid => audienceUuid !== 'exclude-disquals'),
		})
		.then(response => response);

export default {
	create,
	getAll,
	getPublicAll,
	blueprintGetAll,
	getMonadicSplitStatistics,
	get,
	getPublic,
	reorder,
	patch,
	deleteSection,
	addItem,
	patchItem,
	patchAudienceItem,
	deleteItemLogic,
	deleteAudienceItemLogic,
	patchSectionLogic,
	postSectionSettings,
	patchSectionSettings,
	deleteSectionSettings,
	postMonadicSplitReporting,
	updateScreenLayout,
	postPublicMonadicSplitReporting,
};
