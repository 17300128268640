import React from 'react';
import PropTypes from 'prop-types';
import LogicRow from '../LogicRow';
import { useLogicConditionContext } from '../LogicConditionContext';
import './styles.scss';

const LogicRows = ({ question, logicItem, setParentRefZIndex, operandOptions }) => {
	const { answerOptions, selectedItem, isProductLogic, itemOptions, isOptions } = useLogicConditionContext();
	// ensure there is always 1 triggerOption
	const triggerOptions = logicItem?.triggerOptions?.length === 0 ? [{}] : logicItem?.triggerOptions;

	return triggerOptions?.map((option, index) => {
		// only render questions options for the first row
		const questionOptions = index === 0 ? itemOptions : null;
		
		const lastOption = index + 1 === triggerOptions?.length;
		const rowIsOptions = index === 0 ? isOptions : null;
		const andOrOptions = index === 1 ? operandOptions : null;
		const showPlusIcon = index + 1 !== answerOptions.length;

		let renderMinusIcon = true;
		let renderPlusIcon = showPlusIcon;
		const isProduct = selectedItem && selectedItem.data.itemType === 'product';
		if (isProduct || isProductLogic) {
			renderMinusIcon = false;
			renderPlusIcon = false;
		}

		const gridQuestionSelected = selectedItem?.data?.questionType === 'grid';
		const rankedQuestionSelected = selectedItem?.data?.questionType === 'ranked';

		return (
			<LogicRow
				key={`logic-row-${index}`}
				index={index}
				questionType={question.style}
				questionOptions={questionOptions}
				andOrOptions={andOrOptions}
				rowIsOptions={rowIsOptions}
				renderPlusIcon={renderPlusIcon}
				renderMinusIcon={renderMinusIcon}
				setParentRefZIndex={setParentRefZIndex}
				grid={gridQuestionSelected}
				ranked={rankedQuestionSelected}
				lastOption={lastOption}
			/>
		);
	});
};

LogicRows.propTypes = {
	logicItem: PropTypes.object,
	question: PropTypes.object,
	setParentRefZIndex: PropTypes.func,
	operandOptions: PropTypes.array,
};

export default LogicRows;
