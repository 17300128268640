import * as services from 'src/services';

import * as authActions from 'src/domains/auth/actions';
import * as actions from '../actions';

const assignTemplate = (store, { type, payload }) => {
	if (type === actions.ASSIGN_TEMPLATE) {
		const { accountUuid } = payload;

		actions.setAssignTemplateModal({ loading: true });

		const reqBody = {
			...payload.formData,
			accountUuid,
			clientUuid: payload.formData.isGlobal ? null : payload.formData.clientUuid,
		};

		services.adminService
			.assignTemplate(reqBody)
			.then(res => {
				store.dispatch(actions.fetchAccountTemplates(accountUuid));
				store.dispatch(actions.setAssignTemplateModal({ loading: false, visible: false }));
			})
			.catch(err => {
				store.dispatch(
					actions.setAssignTemplateModal({ error: 'Error occured on assigning template', loading: false }),
				);
			});
	}
};

const createAccount = (store, { type, payload }) => {
	if (type === actions.CREATE_ACCOUNT) {
		const settings = {
			address: payload.formData.address || '',
			addressTwo: payload.formData.addressTwo || '',
			city: payload.formData.city || '',
			zip: payload.formData.zip || '',
			state: payload.formData.state || '',
			country: payload.formData.country || '',
			currency: payload.formData.currency,
			billingEmail: payload.formData.billingEmail,
			// TODO: figure out better way to add planType
			planType: 'enterprise',
		};

		const { name } = payload.formData;

		store.dispatch(actions.setAccountForm({ loading: true }));

		const parsedData = { name, settings };
		services.accountService.createAccount(parsedData).then(res => {
			setTimeout(() => {
				store.dispatch(actions.setAccountForm({ loading: false, visible: false, success: false }));
				store.dispatch(actions.fetchAccounts());
			}, 1000);
		});
	}
};

const createTemplate = (store, { type, payload }) => {
	if (type === actions.CREATE_TEMPLATE) {
		store.dispatch(actions.setAdminSampleForm({ loading: true }));
		services.adminService
			.validateTemplate(payload.formData)
			.then(({ data }) => {
				store.dispatch(actions.fetchAllTemplates());
				store.dispatch(actions.setAdminSampleForm({ visible: false, loading: false }));
			})
			.catch(err => {
				store.dispatch(
					actions.setAdminSampleForm({ error: 'Error occured on template creation', loading: false }),
				);
				console.error(err);
			});
	}
};

const fetchAccounts = (store, { type }) => {
	if (type === actions.FETCH_ACCOUNTS) {
		services.accountService.fetchAccounts().then(({ data }) => {
			store.dispatch(actions.setAccounts({ content: data }));
		});
	}
};

const fetchAccount = (store, { type, payload }) => {
	if (type === actions.FETCH_ACCOUNT) {
		services.accountService.fetchAccount(payload.accountUuid).then(({ data }) => {
			store.dispatch(actions.setAccount({ status: 'ready', content: data }));
		});
	}
};

const fetchAccountTemplates = (store, { type, payload }) => {
	if (type === actions.FETCH_ACCOUNT_TEMPLATES) {
		services.adminService.fetchAccountTemplates(payload.accountUuid, payload.clientUuid).then(({ data }) => {
			store.dispatch(actions.setAccountTemplates({ status: 'ready', content: data }));
		});
	}
};

const fetchAllTemplates = (store, { type }) => {
	if (type === actions.FETCH_ALL_TEMPLATES) {
		services.adminService.fetchAllTemplates().then(({ data }) => {
			store.dispatch(actions.setAdminTemplates({ status: 'ready', content: data }));
		});
	}
};

const fetchLanguages = (store, { type, payload }) => {
	if (type === actions.FETCH_LANGUAGES) {
		services.languageService.getLanguages().then(({ data }) => {
			store.dispatch(actions.setAdminSampleForm({ languages: data }));
		});
	}
};

const updateAccountSettings = (store, { type, payload }) => {
	if (type === actions.UPDATE_ACCOUNT_SETTINGS) {
		store.dispatch(actions.setAccountForm({ loading: true }));

		const accountData = payload.data;
		if (accountData.userLimit) {
			accountData.userLimit = Number(accountData.userLimit);
		}
		if (accountData.spaceLimit) {
			accountData.spaceLimit = Number(accountData.spaceLimit);
		}
		services.adminService
			.updateAccountSettings(payload.accountUuid, accountData)
			.then(({ data }) => {
				store.dispatch(actions.setAccountForm({ loading: false, success: true }));
				store.dispatch(actions.fetchAccount());
				setTimeout(() => {
					store.dispatch(actions.setAccountForm({ success: false }));
				}, 2000);
			})
			.catch(err => {
				store.dispatch(actions.setAccountForm({ loading: false }));
				store.dispatch(actions.setError('Error occured on updating the account'));
			});
	}
};

const updateClientTemplate = (store, { type, payload }) => {
	if (type === actions.UPDATE_CLIENT_TEMPLATE) {
		const { accountUuid, templateId, formData } = payload;
		store.dispatch(actions.setAssignTemplateModal({ loading: true }));

		delete formData.sampleTemplateId;
		services.adminService
			.editClientSampleTemplate(templateId, {
				...formData,
				price: formData.price.toString(),
				cost: formData.cost.toString(),
				isGlobal: !!formData.isGlobal === true ? formData.isGlobal === true : false,
			})
			.then(res => {
				store.dispatch(actions.setAssignTemplateModal({ visible: false, loading: false }));
				store.dispatch(actions.fetchAccountTemplates(accountUuid));
			})
			.catch(err => {
				store.dispatch(actions.setAssignTemplateModal({ error: 'Error occured on updating the template' }));
			});
	}
};

const fetchUsage = (store, { type, payload }) => {
	if (type === actions.FETCH_ACCOUNT_USAGE) {
		services.adminService
			.fetchAccountUsage(
				payload.accountUuids,
				payload.clientUuids,
				payload.startDate,
				payload.endDate,
				payload.isBilled,
				payload.component,
				payload.limit,
				payload.offset,
			)
			.then(({ data }) => {
				store.dispatch(actions.setAccountUsage({ content: data }));
			})
			.catch(err => {
				store.dispatch(actions.setError('Error occured when fetching account data'));
			});
	}
};

const exportAccountUsage = (store, { type, payload }) => {
	if (type === actions.EXPORT_ACCOUNT_USAGE) {
		services.adminService
			.exportAccountUsage(
				payload.accountUuids,
				payload.clientUuids,
				payload.startDate,
				payload.endDate,
				payload.isBilled,
				payload.component,
			)
			.catch(err => {
				store.dispatch(actions.setError('Error occured when fetching account data'));
			});
	}
};

const updateAccountUsage = (store, { type, payload }) => {
	if (type === actions.UPDATE_ACCOUNT_USAGE) {
		const { usageId, formData } = payload;
		services.adminService
			.editAccountUsage(usageId, {
				...formData,
			})
			.catch(err => {
				store.dispatch(actions.setError('Error occured when updating the account'));
			});
	}
};

const fetchCountries = (store, { type }) => {
	if (type === actions.FETCH_COUNTRIES) {
		services.languageService.getCountries().then(({ data }) => {
			store.dispatch(actions.setCountries(data));
		});
	}
};

const fetchAudiences = (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCES) {
		const { filters } = payload;
		services.adminService.fetchAudiences(filters).then(({ data }) => {
			store.dispatch(actions.setAudiences(data));
		});
	}
};

const fetchCountryLanguages = (store, { type, payload }) => {
	if (type === actions.FETCH_COUNTRY_LANGUAGES) {
		const { countryId } = payload;
		services.languageService.getCountryLanguages(countryId).then(({ data }) => {
			store.dispatch(actions.setCountryLanguages(data));
		});
	}
};

const fetchAudience = (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCE) {
		const { audienceId } = payload;
		services.adminService
			.fetchAudience(audienceId)
			.then(({ data }) => {
				store.dispatch(actions.setAudience(data));
				store.dispatch(authActions.setClientId(data.clientUuid));
			})
			.catch(err => {
				store.dispatch(actions.setError('Error occured when fetching audience data'));
			});
	}
};

const fetchCustomQualifiers = (store, { type, payload }) => {
	if (type === actions.FETCH_CUSTOM_QUALIFIERS) {
		const { countryLanguageId } = payload;
		store.dispatch(actions.setCustomQualifiers({ loading: true }));
		services.adminService
			.fetchCustomQualifiers(countryLanguageId)
			.then(({ data }) => {
				store.dispatch(actions.setCustomQualifiers({ content: data }));
			})
			.finally(() => {
				store.dispatch(actions.setCustomQualifiers({ loading: false }));
			});
	}
};

const createCustomQualifier = (store, { type, payload }) => {
	if (type === actions.CREATE_CUSTOM_QUALIFIER) {
		const { countryLanguageId } = payload;
		const customQualifier = {
			countryLanguageId,
			questionIds: payload.formData.questionIds,
			accountIds: payload.formData.accountIds || [],
			accessLevel: payload.formData.accessLevel || 'global',
		};

		store.dispatch(actions.setCustomQualifierForm({ loading: true }));

		services.adminService.createCustomQualifier(customQualifier).then(res => {
			setTimeout(() => {
				store.dispatch(actions.setCustomQualifierForm({ loading: false, visible: false, success: false }));
				store.dispatch(actions.fetchCustomQualifiers(countryLanguageId));
			}, 1000);
		});
	}
};

export default [
	assignTemplate,
	createAccount,
	createTemplate,
	fetchAccounts,
	fetchCustomQualifiers,
	fetchAccount,
	fetchAccountTemplates,
	fetchAllTemplates,
	fetchLanguages,
	fetchAudiences,
	fetchAudience,
	updateAccountSettings,
	updateClientTemplate,
	fetchUsage,
	updateAccountUsage,
	exportAccountUsage,
	fetchCountries,
	fetchCountryLanguages,
	createCustomQualifier,
];
