import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getManageStudyData } from 'src/domains/selectors';
import { generateLogicOptions } from '../../../utilities/logic';

export const getStudyFiles = state => getManageStudyData(state).studyFiles;
export const getStudy = state => getManageStudyData(state).study;
export const getLanguage = state => getManageStudyData(state).language;
export const getLanguages = state => getManageStudyData(state).languages;
export const getCategories = state => getManageStudyData(state).categories;
export const getProductTags = state => getManageStudyData(state).productTags;
export const getProducts = state => getManageStudyData(state).products;
export const getSaveButtonState = state => getManageStudyData(state).saveButtonState;
export const getSaveButtonText = state => getManageStudyData(state).saveButtonText;
export const getProductModal = state => getManageStudyData(state).productModal;
export const getQuestionModal = state => getManageStudyData(state).questionModal;
export const getQuestions = state => getManageStudyData(state).questions;
export const getImportImagesModal = state => getManageStudyData(state).importImagesModal;
export const getImportProductsModal = state => getManageStudyData(state).importProductsModal;
export const getImportRespondentsModal = state => getManageStudyData(state).importRespondentsModal;
export const getExportConfirmationModal = state => getManageStudyData(state).exportConfirmationModal;
export const getCountries = state => getManageStudyData(state).countries;
export const getCountryLanguages = state => getManageStudyData(state).countryLanguages;
export const getResponses = state => getManageStudyData(state).responses;
export const getLengthOfInterview = state => getManageStudyData(state).lengthOfInterview;

export const getStudyLanguages = state => {
	const study = getStudy(state);
	const languages = getLanguages(state);

	return ((study || {}).languages || []).map(languageCode => {
		const accordingLanguage = languages.filter(language => language.languageCode === languageCode).pop();
		const label = accordingLanguage
			? accordingLanguage.label
			: `Unknown language with languageCode ${languageCode}`;

		return { languageCode, label };
	});
};

export const getTranslationValidations = state => get(getManageStudyData(state), 'studyValidations.translations', {});
export const getValidationLoi = state => get(getManageStudyData(state), 'studyValidations.lengthOfInterview');
export const getAudienceNotice = state => getManageStudyData(state).audienceNotice;

/* Model access stuff */

export const getAccessModal = state => getManageStudyData(state).accessModal;
export const getAccessEntries = state => getManageStudyData(state).accessEntries;
export const getPossibleEntries = state => getManageStudyData(state).possibleEntries;
export const getAccessCommonError = state => getManageStudyData(state).accessCommonError;

/* Audience Stuff */
export const getAudiences = state => getManageStudyData(state).audiences;
export const getAudience = state => getManageStudyData(state).audience;
export const getGroup = state => getManageStudyData(state).group;
export const getAudienceCollections = state => getManageStudyData(state).audienceCollections;
export const getAudienceCollection = state => getManageStudyData(state).audienceCollection;
export const getAudienceEditTemplate = state => getManageStudyData(state).audienceEditTemplate;
export const getCreateAudienceModal = state => getManageStudyData(state).createAudienceModal;
export const getEditAudienceModal = state => getManageStudyData(state).editAudienceModal;
export const getAudienceTemplates = state => getManageStudyData(state).audienceTemplates;
export const getDemographicGroupTemplates = state => getManageStudyData(state).audienceTemplatesV2;
export const getAudienceTemplateCollection = state => getManageStudyData(state).audienceTemplateCollection;
export const getAudienceTemplateCollectionLoading = state =>
	getManageStudyData(state).audienceTemplateCollectionLoading;
export const getAudiencePrice = state => getManageStudyData(state).audiencePrice;
export const getBYOPrice = state => getManageStudyData(state).BYOPrice;
export const getLaunchAudience = state => getManageStudyData(state).launchAudience;
export const getLaunchAudienceModal = state => getManageStudyData(state).launchAudienceModal;
export const getStopAudienceModal = state => getManageStudyData(state).stopAudienceModal;
export const getAudienceStatusBanner = state => getManageStudyData(state).audienceStatusBanner;

export const getGeographies = state => getManageStudyData(state).geographies;
export const getUploadImageStatus = state => getManageStudyData(state).uploadImageStatus;
export const getRoles = state => getManageStudyData(state).roles;

export const getAudienceModal = state => getManageStudyData(state).audienceModal;
export const getAudienceNorms = state =>
	getManageStudyData(state).currentSection.content && getManageStudyData(state).currentSection.content.audienceNorms;

export const getSections = state => getManageStudyData(state).sections;
export const getCurrentSection = state => getManageStudyData(state).currentSection;

export const getShareStudyModal = state => getManageStudyData(state).shareStudyModal;

// Set Section Name
export const getSectionNameData = state => getManageStudyData(state).sectionNameData;

export const getItemLogic = state => getManageStudyData(state).logic;

export const getManageLanguagesModal = state => getManageStudyData(state).manageLanguagesModal;
export const getTranslationsModal = state => getManageStudyData(state).translationsModal;
export const getTranslationPreview = state => getManageStudyData(state).translationPreview;
export const getDiscardProductChangesModal = state => getManageStudyData(state).discardProductChangesModal;
export const getShowGoogleTranslateModal = state => getManageStudyData(state).showGoogleTranslateModal;

// Section settings
export const getSectionSettingsInsertData = state => getManageStudyData(state).sectionSettingsInsertData;
export const getSavingSectionSettings = state => getManageStudyData(state).isSavingSectionSettings;
export const getShowDevicePreviewMode = state => getManageStudyData(state).showDevicePreviewMode;
export const getShowDevicePreviewIframeBlocker = state => getManageStudyData(state).showDevicePreviewIframeBlocker;
export const getDevicePreviewMode = state => getManageStudyData(state).devicePreviewMode;
export const getDevicePreviewUrl = state => getManageStudyData(state).devicePreviewUrl;

export const getShowLaunchWithCustomQualModalForm = state =>
	getManageStudyData(state).showLaunchWithCustomQualModalForm;

export const getAudienceForReview = state => getManageStudyData(state).audienceForReview;

export const getProductSectionViewMode = state => getManageStudyData(state).productSectionViewMode;

export const getBaseSizeThreshold = state => getManageStudyData(state).baseSizeThreshold;

export const getAudiencePriceChanges = state => getManageStudyData(state).audiencePriceChanges;

export const getActiveStep = state => getManageStudyData(state).activeStep;

export const getSelectedTemplateCard = state => getManageStudyData(state).selectedTemplateCard;

export const getStudyTemplateApplied = state => getManageStudyData(state).studyTemplateApplied;
export const getAudienceCollectionTemplateApplied = state =>
	getManageStudyData(state).audienceCollectionTemplateApplied;

export const getStudyId = createSelector(getStudy, study => study?.id || null);
export const getStudyAssetId = createSelector(getStudy, study => study?.assetId || null);
export const getStudyAsset = createSelector(getStudy, study => study?.asset || null);

export const getLogicDropdownOptions = createSelector(
	getStudyId,
	getSections,
	getCurrentSection,
	getAudienceCollection,
	(state, props) => props,
	(studyId, sections, currentSection, audienceCollection, props) => {
		const audienceData = {
			content: {
				screeningQuestions: props?.audience?.customQuestions || [],
			},
		};

		const data = generateLogicOptions(studyId, sections, currentSection, audienceData || audienceCollection, props);

		return data;
	},
);
