import * as services from 'src/services';
import debounce from 'lodash/debounce';
import Cookies from 'src/utilities/cookies';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import toastr from 'toastr';
import * as actions from '../actions';
import * as selectors from '../selectors';

let PER_PAGE = 24;
let allCount = null;

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};

const performInitialFetch = (store, action) => {
	if (action.type === actions.PERFORM_INITIAL_FETCH) {
		store.dispatch(actions.fetchClients());
		store.dispatch(actions.fetchLanguages());
		store.dispatch(actions.fetchGeographies());
	}
};

const fetchLanguages = (store, { type }) => {
	if (type === actions.FETCH_LANGUAGES) {
		store.dispatch(actions.incLoading());
		services.languageService
			.getLanguages()
			.then(({ data }) => {
				store.dispatch(actions.setLanguages(data));
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				console.group('Error while fetching languages');
				console.error('error:', error);
				console.groupEnd();
			})
			.then(() => {
				store.dispatch(actions.decLoading());
			});
	}
};

const fetchGeographies = (store, { type }) => {
	if (type === actions.FETCH_GEOGRAPHIES) {
		services.languageService.getGeographies().then(({ data }) => {
			store.dispatch(actions.setGeographies(data));
		});
	}
};

const fetchClients = (store, { type }) => {
	if (type === actions.FETCH_CLIENTS) {
		store.dispatch(actions.incLoading());
		services.clientsService
			.getClients()
			.then(({ data }) => {
				store.dispatch(actions.setClients(data));
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				console.group('Error while fetching clients');
				console.error('error:', error);
				console.groupEnd();
			})
			.then(() => {
				store.dispatch(actions.decLoading());
			});
	}
};

// Used to prevent calling the API when no more data is available to fetch
let hasMore = true;

const fetchBlueprints = async (store, { type, payload }) => {
	if (type === actions.FETCH_BLUEPRINTS) {
		const { dropPagination, initialFetch } = payload;
		const searchQuery = initialFetch ? null : selectors.getSearchQuery(store.getState());
		const blueprints = selectors.getBlueprints(store.getState());
		const filters = selectors.getBlueprintFilters(store.getState());
		const isLoading = blueprints.loading;

		// Reset hasMore flag if page has been reset. This will not be an issue
		// if the user has no blueprints since fetchBlueprints() will not be triggered without scrolling
		if (!blueprints || !blueprints.content || (blueprints && !blueprints.content.length) || searchQuery !== null) {
			hasMore = true;
		}

		if (isLoading) {
			return;
		}

		if (!hasMore) {
			return;
		}

		if (initialFetch) {
			store.dispatch(actions.setSearchQuery(null, true));
		}

		if (!dropPagination && allCount !== null && blueprints.content.length === allCount) return;
		if (dropPagination) {
			allCount = null;
			store.dispatch(actions.setBlueprints({ content: [] }));
		}

		store.dispatch(actions.setBlueprints({ loading: true }));
		store.dispatch(actions.setError(null));

		// Rebuilding so that it doesn't effect the redux version.
		const cleanedFilters = {};

		Object.keys(filters).forEach(key => {
			cleanedFilters[key] = [...filters[key]];
			if (
				key === 'status' &&
				cleanedFilters[key].includes('draft') &&
				!cleanedFilters[key].includes('needs-review')
			) {
				cleanedFilters[key].push('needs-review');
			}
		});

		// fetch more products on first fetch to fix the issue where larger screens don't have any scroll
		if (blueprints.content.length === 0) PER_PAGE = 36;

		await services.blueprintService
			.getBlueprints(
				searchQuery,
				cleanedFilters,
				PER_PAGE,
				dropPagination ? 0 : blueprints.content.length,
				'manage',
				blueprints.sortBy ? blueprints.sortBy : 'name',
				blueprints.sortDirection,
			)
			.then(({ data }) => {
				data.blueprints = data.studies;
				const blueprints = selectors.getBlueprints(store.getState());
				const clients = data.clients.sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1));

				if (data.blueprints.length === 0) {
					allCount = Math.max(allCount, blueprints.content.length);
				}

				if (data.blueprints.length < PER_PAGE) hasMore = false;

				store.dispatch(actions.setClients(clients));

				store.dispatch(
					actions.setBlueprints({
						content: dropPagination ? data.blueprints : blueprints.content.concat(data.blueprints),
					}),
				);
				store.dispatch(
					actions.setBlueprints({
						loading: false,
					}),
				);
				store.dispatch(
					actions.setBlueprints({
						...(data.studies.length > 0 ? { hasBlueprints: true } : {}),
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setError(error));
				store.dispatch(actions.setBlueprints({ loading: false }));
			});
	}
};

const fetchBlueprintsDebounced = debounce(store => {
	const upsiideAccountToken = Cookies.getUpsiideAccountToken();
	const { loggedIn } = store.getState().auth;
	if (loggedIn) {
		if (upsiideAccountToken) {
			store.dispatch(actions.fetchBlueprints({ dropPagination: true, initialFetch: false }));
		} else {
			store.dispatch(actions.fetchBlueprints({ dropPagination: true, initialFetch: false }));
		}
	}
}, 500);

/**
 * Triggers a fetch request when search query is changed
 */
const ACTIONS_THAT_TRIGGER_FETCH = [actions.SET_SEARCH_QUERY];
const triggerFetch = (store, { type, payload }) => {
	if (ACTIONS_THAT_TRIGGER_FETCH.indexOf(type) > -1) {
		if (payload.initialLoad) {
		} else {
			fetchBlueprintsDebounced(store);
		}
	}
};

const fetchClientsFromAccount = async (store, { type, payload }) => {
	if (type === actions.FETCH_CLIENTS_FROM_ACCOUNT) {
		const response = await services.accountService.getAccount(payload.accountUuid);
		if (response) {
			const { data } = response;
			const { clients } = data;
			clients.map(c => (c.accountUuid = payload.accountUuid));
			store.dispatch(actions.setClientsFromAccount(clients));
		}
	}
};

const fetchSections = (store, action) => {
	if (action.type === actions.FETCH_SECTIONS) {
		const { blueprintId, refetch, loadFirst } = action.payload;
		if (!refetch) {
			store.dispatch(actions.setSections({ status: 'loading' }));
		}
		services.sections
			.blueprintGetAll(blueprintId)
			.then(response => {
				const content = response.data;

				// TODO - remove when ordering is fixed on the API
				content.sort((a, b) => (a.order > b.order ? 1 : -1));
				store.dispatch(actions.setSections({ status: 'ready', content }));

				if (loadFirst && content.length) {
					store.dispatch(actions.fetchSection(content[0].id, 'edit', false, blueprintId));
				}
			})
			.catch(error => {
				store.dispatch(actions.setSections({ status: 'error', error }));
			});
	}
};

const fetchSection = (store, action) => {
	if (action.type === actions.FETCH_SECTION) {
		const { sectionId, refetch, studyId = 0, mode } = action.payload;
		const study = selectors.getStudy(store.getState());
		const sections = selectors.getSections(store.getState()).content;

		if (!refetch) {
			store.dispatch(actions.setSection({ status: 'loading' }));
		}
		services.sections
			.get(studyId || study.id, sectionId, mode)
			.then(response => {
				const content = response.data;

				if (content.type === 'questions') {
					// number the questions based on the full list
					let questionCount = 0;
					sections.find(previousSection => {
						if (previousSection.id === content.id) {
							// Stop at this section
							return true;
						}

						if (previousSection.type === 'questions') {
							questionCount += previousSection.questions.length;
						}
						return false;
					});

					content.questions = content.questions
						? content.questions.map(question => {
							questionCount += 1;
							question.studyQuestionNumber = questionCount;
							return question;
						})
						: [];
				}

				store.dispatch(actions.setSection({ status: 'ready', content }));
			})
			.catch(error => {
				console.error('error', error);
				store.dispatch(actions.setSection({ status: 'error', error }));
			});
	}
};

const createBlueprint = async (store, { type, payload }) => {
	if (type === actions.CREATE_BLUEPRINT) {
		store.dispatch(actions.incLoading());
		store.dispatch(actions.setError(false));
		try {
			services.blueprintService
				.createBlueprint(payload)
				.then(newBlueprint => {
					navigate(`/templates/${newBlueprint?.data?.uuid || newBlueprint?.data?.id}/create`);
				})
				.then(() => {
					store.dispatch(actions.decLoading());
				});
		} catch (error) {
			store.dispatch(actions.setError(error));
			store.dispatch(actions.decLoading());
			console.group('Error while creating study');
			console.error('error:', error);
			console.groupEnd();
		}
	}
};

const deleteBlueprint = async (store, { type, payload }) => {
	if (type === actions.DELETE_BLUEPRINT) {
		store.dispatch(
			actions.setBlueprints({
				loading: true,
			}),
		);
		const { blueprintId, clientId } = payload;
		await services.blueprintService.deleteBlueprint(blueprintId, clientId);

		const blueprints = selectors.getBlueprints(store.getState());
		const foundIndex = blueprints.content.findIndex(blueprint => blueprint.id === blueprintId);

		blueprints.content.splice(foundIndex, 1);
		store.dispatch(
			actions.setBlueprints({
				content: blueprints.content,
				loading: false,
				...(blueprints.content.length === 0 ? { hasBlueprints: false } : {}),
			}),
		);
	}
};

const updateBlueprint = async (store, { type, payload }) => {
	if (type === actions.UPDATE_BLUEPRINT) {
		const { blueprintId, data } = payload;
		try {
			const response = await services.blueprintService.updateBlueprint(blueprintId, data);
			store.dispatch(actions.setBlueprint(response.data));
			store.dispatch(actions.performInitialFetch(blueprintId, true));
		} catch (error) {
			console.error(error);
		}
	}
};

const fetchAccounts = (store, action) => {
	if (action.type === actions.FETCH_ACCOUNTS) {
		store.dispatch(actions.setAccounts({ loading: true }));
		services.accountService
			.fetchAccounts()
			.then(({ data }) => {
				store.dispatch(actions.setAccounts({ content: data, loading: false }));
			})
			.catch(err => {
				store.dispatch(actions.setAccounts({ error: true, loading: false }));
			});
	}
};

const updateBlueprintAccess = (store, { type, payload }) => {
	if (type === actions.UPDATE_BLUEPRINT_ACCESS) {
		const { studyId, accountIds, isDefaultBlueprint } = payload;
		const blueprints = selectors.getBlueprints(store.getState());
		services.blueprintService.updateBlueprintAccess(studyId, accountIds, isDefaultBlueprint).then(() => {
			const allBlueprints = [...blueprints.content];
			const currentBlueprint = allBlueprints.find(blueprint => blueprint.id === studyId);
			currentBlueprint.accountIds = accountIds;
			currentBlueprint.blueprintNewAccountsDefault = isDefaultBlueprint;
			store.dispatch(actions.setBlueprints({ content: allBlueprints, blueprintIdToHighlight: studyId }));
		});
	}
};

export default [
	performInitialFetch,
	fetchBlueprints,
	triggerFetch,
	fetchClientsFromAccount,
	fetchSections,
	fetchSection,
	fetchLanguages,
	fetchGeographies,
	fetchClients,
	createBlueprint,
	deleteBlueprint,
	updateBlueprint,
	fetchAccounts,
	updateBlueprintAccess,
];
