import React from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const className = 'recall-question';
const el = (name, mod) => cn(className, name, mod);

const RecallQuestionButton = ({ maskingType, pipedInfo, question, selectQuestion, getIconBasedStatus }) => {
	let isDefaultState = !pipedInfo || maskingType !== pipedInfo[0]?.logic;
	let icon = getIconBasedStatus(isDefaultState ? maskingType : 'default', question);

	if (pipedInfo && pipedInfo?.length > 1) {
		// if question has selected + not-selected
		icon = getIconBasedStatus('default');
		isDefaultState = false;
	}
	const disabledClass = !isDefaultState ? '_disabled' : '';
	return (
		<div
			className={el(`dropdown_selectable_container${disabledClass}`)}
			aria-hidden
			onClick={() => {
				if (isDefaultState) {
					selectQuestion(question, maskingType);
				}
			}}
		>
			<img src={icon} alt="" className={el('icon')} />
			<span className={el(`dropdown_select_text${disabledClass}`)}>
				{maskingType === 'selected' ? 'Selected' : 'Unselected'}
			</span>
		</div>
	);
};

RecallQuestionButton.propTypes = {
	maskingType: PropTypes.any,
	pipedInfo: PropTypes.any,
	question: PropTypes.any,
	selectQuestion: PropTypes.any,
	getIconBasedStatus: PropTypes.any,
};

export default RecallQuestionButton;
