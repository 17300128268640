// packages
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import { Iconof } from '@upsiide/ui-components';

// utilities
import cn from 'src/utilities/bem-cn';

// styles
import './styles.scss';

const className = 'mean-score-scale-point-input';

const el = (name, mod) => cn(className, name, mod);

// constants
const RECALLED_OPTION_TOOLTIP_TEXT = 'Recalled option excluded from scale';
const EXCLUDED_OPTION_TOOLTIP_TEXT = 'Option excluded from scale';

const ScalePointInput = ({
	// props
	answer,
	disabled,
	index,
	activeScalePointInputIndex,
	setActiveScalePointInputIndex,
	answers = [],
	focusFirstAvailableLabelInput,
	readOnly,
	updateOptionScalePoint,
	setOptionScalePointValue,
	isDefaultScale,
}) => {
	// state
	const [scalePoint, setScalePoint] = useState(answer?.scalePoint ?? null);
	const [isActive, setIsActive] = useState(false);

	const inputRef = useRef(null);

	const isRecalledOption = !!answer?.maskedQuestionId; // * Used to disable input and display appropriate tooltip for the masked options

	const isTooltipDisabled = !(isRecalledOption || !scalePoint);

	const showClearButton = !!scalePoint && !isActive;
	const wrapperModClassName =
		// eslint-disable-next-line no-nested-ternary
		disabled || isRecalledOption ? 'disable-input' : isActive ? 'active' : isDefaultScale ? 'default-scale' : '';

	// life cycle
	useEffect(() => {
		setScalePoint(answer?.scalePoint ?? null);
	}, [answer, answer?.scalePoint]);

	useEffect(() => {
		activeScalePointInputIndex === index ? inputRef.current.focus() : inputRef.current.blur();
	}, [activeScalePointInputIndex, index]);

	// functions
	const handleScalePointChange = e => {
		if (readOnly) return;

		const { value } = e.target;

		const isDashValue = ['-', '–', '—'].includes(value);

		if (isDashValue) return setScalePoint(null);

		const minValue = 0;
		const maxValue = 999;

		const parsedValue = Math.abs(parseInt(value, 10));
		const isNumeric = !Number.isNaN(parsedValue);
		const isInRange = parsedValue >= minValue && parsedValue <= maxValue;
		const isValid = !value || (isNumeric && isInRange);

		if (!isValid) return;

		setScalePoint(Number.isNaN(parsedValue) ? null : parsedValue);
	};

	const handleScalePointInputsTabbing = e => {
		if (readOnly) return;

		const isEnterKey = e.key === 'Enter';
		const isUpKey = e.key === 'ArrowUp';
		const isDownKey = e.key === 'ArrowDown';
		const isTabKey = !e.shiftKey && e.key === 'Tab';
		const isShiftTabKey = e.shiftKey && e.key === 'Tab';

		const isNext = isEnterKey || isDownKey || isTabKey;
		const isPrev = isUpKey || isShiftTabKey;

		if (!isNext && !isPrev) return;

		e.preventDefault(); // * Keep default behavior for the rest of the keys

		let nextIndex = index;

		// * Masked options are excluded from scale
		const availableIndexes = answers
			.map(({ maskedQuestionId }, i) => (!maskedQuestionId ? i : null))
			.filter(i => i !== null);

		const maxIndex = answers.length - 1;

		if (isNext && index < maxIndex) {
			// eslint-disable-next-line no-plusplus
			for (let i = index + 1; i <= maxIndex; i++) {
				if (availableIndexes.includes(i)) {
					nextIndex = i;
					break;
				}
			}
		}

		if (isPrev && index > 0) {
			// eslint-disable-next-line no-plusplus
			for (let i = index - 1; i >= 0; i--) {
				if (availableIndexes.includes(i)) {
					nextIndex = i;
					break;
				}
			}
		}

		if (nextIndex === index) {
			if (index === maxIndex) focusFirstAvailableLabelInput();
			setActiveScalePointInputIndex(null);
			return;
		}

		// * Focus the target input
		setActiveScalePointInputIndex(nextIndex);
	};

	const clearScalePoint = () => {
		if (readOnly) return;
		setScalePoint(null);
		setOptionScalePointValue({ optionId: answer?.id, scalePoint: null });
		if (answer?.placeholder) return; // * Don't make request if it's a placeholder option
		updateOptionScalePoint({ optionId: answer?.id, scalePoint: null });
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (readOnly) return;
		inputRef.current?.blur();
	};

	const handleFocus = () => {
		if (readOnly) return;
		setIsActive(true);
		inputRef.current.select(); // * Select input text on focus
	};

	const handleBlur = () => {
		if (readOnly) return;
		setIsActive(false);
		setOptionScalePointValue({ optionId: answer?.id, scalePoint }); // * Update store in case it's a placeholder and the option will be created on blur
		if (answer?.placeholder) return; // * Don't make request if it's a placeholder option or the value hasn't changed
		updateOptionScalePoint({ optionId: answer?.id, scalePoint });
	};

	return (
		<div {...{ className }}>
			<form className={el('wrapper', wrapperModClassName)} onSubmit={handleSubmit}>
				<button
					type="button"
					className={el('clear-button', (readOnly || !showClearButton) && 'hidden')}
					onClick={clearScalePoint}
					aria-label="Remove scale point"
				>
					<Iconof icon="clear" />
				</button>

				<Tooltip
					id={`${className}__tooltip`}
					className={`${className}__tooltip`}
					animation="shift"
					animationFill={false}
					trigger="mouseenter"
					position="top"
					theme="basic-text-tooltip"
					disabled={isTooltipDisabled}
					html={<div>{isRecalledOption ? RECALLED_OPTION_TOOLTIP_TEXT : EXCLUDED_OPTION_TOOLTIP_TEXT}</div>}
				>
					<input
						ref={inputRef}
						value={scalePoint ?? ''}
						placeholder="—"
						onChange={handleScalePointChange}
						onKeyDown={e => handleScalePointInputsTabbing(e.nativeEvent)}
						onBlur={handleBlur}
						onFocus={handleFocus}
						aria-label="Scale point input"
						disabled={disabled || isRecalledOption || readOnly}
					/>
				</Tooltip>
			</form>
		</div>
	);
};

ScalePointInput.propTypes = {
	// props
	answer: PropTypes.object,
	disabled: PropTypes.bool,
	index: PropTypes.number,
	activeScalePointInputIndex: PropTypes.any,
	setActiveScalePointInputIndex: PropTypes.func,
	answers: PropTypes.array,
	focusFirstAvailableLabelInput: PropTypes.func,
	readOnly: PropTypes.bool,
	updateOptionScalePoint: PropTypes.func,
	setOptionScalePointValue: PropTypes.func,
	isDefaultScale: PropTypes.bool,
};

export default ScalePointInput;
