import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import X from 'src/components/icons/X';
import './styles.scss';

const el = (name, mod) => cn('tag', name, mod);
class Tag extends React.Component {
	renderCloseButton = () => {
		const { onClose, disabled } = this.props;
		return onClose ? (
			<button className={el('close-button')} type="button" onClick={!disabled && onClose}>
				<X />
			</button>
		) : (
			false
		);
	};

	render() {
		const { label = '', onClick, className, disabled } = this.props;
		return (
			<div
				className={`tag ${className} ${disabled ? 'disabled' : ''}`}
				onClick={!disabled ? onClick : undefined}
				aria-hidden
			>
				<div className={el('label')}>{label}</div>
				{this.renderCloseButton()}
			</div>
		);
	}
}

Tag.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
	onClick: PropTypes.func,
	onClose: PropTypes.func,
	disabled: PropTypes.bool,
};

export default Tag;
