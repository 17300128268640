import * as services from 'src/services';
import * as reportingActions from '../actions/reporting';
import * as actions from '../actions';
import * as selectors from '../selectors';

/**
 * Fetch sections for the table
 */
const fetchSections = (store, action) => {
	if (action.type === actions.FETCH_SECTIONS) {
		const { studyId, refetch, loadFirst } = action.payload;

		if (!refetch) {
			store.dispatch(actions.setSections({ status: 'loading' }));
		}
		services.sections
			.getPublicAll(studyId)
			.then(response => {
				const content = response.data;

				// TODO - remove when ordering is fixed on the API
				content.sort((a, b) => (a.order > b.order ? 1 : -1));
				store.dispatch(actions.setSections({ status: 'ready', content }));

				if (loadFirst && content.length) {
					store.dispatch(actions.fetchSection(content[0].id, 'edit', false, studyId));
				}
			})
			.catch(error => {
				store.dispatch(actions.setSections({ status: 'error', error }));
			});
	}
};

/**
 * Fetch sections for the table
 */
const fetchSection = (store, action) => {
	if (action.type === actions.FETCH_SECTION) {
		const { sectionId, refetch, studyId = 0, mode } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		const sections = selectors.getSections(state).content;
		const currentSection = selectors.getCurrentSection(state);
		const isFetchingDifferentSection =
			currentSection && currentSection.content && currentSection.content.id !== sectionId;

		if (!refetch) {
			store.dispatch(actions.setSection({ status: 'loading' }));
		} else if (isFetchingDifferentSection) {
			// Avoid re-fetching a section after user swaps away from it
			return;
		}
		services.sections
			.getPublic(studyId || study.uuid, sectionId, mode)
			.then(response => {
				const content = response.data;

				if (content.type === 'questions' || content.type === 'monadic_split') {
					// number the questions based on the full list
					let questionCount = 0;
					sections.find(previousSection => {
						if (previousSection.id === content.id) {
							// Stop at this section
							return true;
						}

						if (previousSection.type === 'questions') {
							questionCount += previousSection.questions.length;
						}
						return false;
					});
					content.questions = content.questions
						? content.questions.map(question => {
							questionCount += 1;
							question.studyQuestionNumber = questionCount;
							return question;
						})
						: [];
				}
				store.dispatch(actions.setSection({ status: 'ready', content }));
				if (content.type === 'swipe') {
					store.dispatch(actions.getAudienceNorms({ studyId: studyId || study.uuid, sectionId }));
				}
			})
			.catch(error => {
				console.error('error', error);
				store.dispatch(actions.setSection({ status: 'error', error }));
			});
	}
};

/**
 * Fetch sections for the table
 */
const createSection = (store, action) => {
	if (action.type === actions.CREATE_SECTION) {
		const { studyId, data } = action.payload;
		const sections = selectors.getSections(store.getState());
		const highestOrder = Math.max(
			sections.content.length,
			1 +
			Math.max(
				...sections.content.map(function (section) {
					return section.order;
				}),
			),
		);

		// store.dispatch(actions.setSections({ status: 'loading' }));
		services.sections
			.create(studyId, { ...data, order: highestOrder })
			.then(response => {
				store.dispatch(actions.fetchSections(studyId, true));
				// Go to created section
				store.dispatch(actions.fetchSection(response.data.insertId, 'edit'));
			})
			.catch(error => {
				// TODO
				console.error("couldn't create section", error);
			});
	}
};

const getNewSectionsOrder = (sections, sourceIndex, destinationIndex) => {
	const newSections = [];
	const sortedSectionIds = [];
	if (sourceIndex === destinationIndex) {
		return sections;
	}
	sections.forEach((section, index) => {
		if (index === sourceIndex) {
		} else if (index === destinationIndex) {
			if (sourceIndex < destinationIndex) {
				newSections.push(section);
			}
			newSections.push(sections[sourceIndex]);
			if (sourceIndex > destinationIndex) {
				newSections.push(section);
			}
		} else {
			newSections.push(section);
		}
	});
	return newSections;
};

const reorderSections = (store, action) => {
	if (action.type === actions.REORDER_SECTIONS) {
		const { sourceIndex, destinationIndex } = action.payload;
		const study = selectors.getStudy(store.getState());
		const sections = selectors.getSections(store.getState());
		// Get the new sorted order
		const newSortedSections = getNewSectionsOrder(sections.content, sourceIndex, destinationIndex);
		const newSortedSectionIds = newSortedSections.map(section => section.id);

		store.dispatch(actions.setSections({ status: 'ready', content: newSortedSections }));
		services.sections
			.reorder(study.id, newSortedSectionIds)
			.then(() => {
				store.dispatch(actions.fetchSections(study.id, true));
			})
			.catch(error => {
				// TODO
				console.error("couldn't re-order sections", error);
			});
	}
};

const patchSectionName = (store, action) => {
	if (action.type === actions.PATCH_SECTION_NAME) {
		const { studyId, sectionId, statementName } = action.payload;
		services.statementService
			.patchSectionName(studyId.toString(), sectionId.toString(), statementName)
			.then(res => {
				store.dispatch(actions.fetchSections(studyId, true));
				store.dispatch(actions.fetchSection(sectionId, 'edit', true));
			});
	}
};

const deleteSection = (store, action) => {
	if (action.type === actions.DELETE_SECTION) {
		const { studyId, sectionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const sections = selectors.getSections(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState());

		// If deleting current section
		let newCurrentSection = null;
		let deletingCurrentSection;
		if (currentSection && currentSection.content) {
			deletingCurrentSection = currentSection.content.id === sectionId;
		}
		if (deletingCurrentSection) {
			const currentSectionIndex = (sections.content || []).findIndex(section => sectionId === section.id);
			if (currentSectionIndex === sections.content.length - 1) {
				newCurrentSection = currentSectionIndex > -1 ? sections.content[currentSectionIndex - 1] : null;
			} else {
				newCurrentSection = currentSectionIndex > -1 ? sections.content[currentSectionIndex + 1] : null;
			}
		}

		const newSections = (sections.content || []).filter(section => sectionId !== section.id);
		const newSectionIds = newSections.map(section => section.id);

		store.dispatch(actions.setSections({ status: 'ready', content: newSections }));

		Promise.all([
			services.sections.deleteSection(studyId, sectionId),
			services.sections.reorder(studyId, newSectionIds),
		])
			.then(() => {
				store.dispatch(actions.fetchSections(study.id, true));
				if (deletingCurrentSection) {
					if (newCurrentSection) {
						store.dispatch(actions.fetchSection(newCurrentSection.id, 'edit'));
					} else {
						store.dispatch(actions.setSection({ status: 'initial', content: null }));
					}
				}
				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(actions.fetchAudiences(studyId));
			})
			.catch(error => {
				// TODO
				console.error("couldn't re-order sections", error);
			});
	}
};

const patchSectionLogic = (store, action) => {
	if (action.type === actions.PATCH_SECTION_LOGIC) {
		const { studyId, sectionId, logic } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState());

		services.sections
			.patchSectionLogic(studyId, sectionId, logic)
			.then(response => {
				store.dispatch(actions.fetchSections(studyId, true));
				store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(actions.fetchAudiences(studyId));
			})
			.catch(error => { });
	}
};

const patchSection = (store, action) => {
	if (action.type === actions.PATCH_SECTION) {
		const { studyId, sectionId, data } = action.payload;
		const currentSection = selectors.getCurrentSection(store.getState());
		services.sections
			.patch(studyId, sectionId, data)
			.then(response => {
				store.dispatch(actions.fetchSections(studyId, true));
				store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(actions.fetchAudiences(studyId));
			})
			.catch(error => { });
	}
};

const patchItem = (store, action) => {
	if (action.type === actions.PATCH_ITEM) {
		const { studyId, sectionId, itemId, itemData } = action.payload;
		let currentSectionId;
		if (sectionId) {
			currentSectionId = sectionId;
		} else {
			currentSectionId = selectors.getCurrentSection(store.getState()).content.id;
		}
		services.sections
			.patchItem(studyId, sectionId, itemId, itemData)
			.then(response => {
				store.dispatch(actions.fetchSections(studyId, true));
				store.dispatch(actions.fetchSection(currentSectionId, 'edit', true));
				store.dispatch(actions.fetchQuestions(studyId));
				store.dispatch(actions.fetchQuestion(studyId, itemId));
				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(actions.fetchAudiences(studyId));
			})
			.catch(error => { });
	}
};

const patchAudienceItem = (store, action) => {
	if (action.type === actions.PATCH_AUDIENCE_ITEM) {
		const { studyId, audienceUuid, itemId, itemData } = action.payload;
		services.sections
			.patchAudienceItem(studyId, audienceUuid, itemId, itemData)
			.then(response => {
				services.studySampleService.getAudience(studyId, audienceUuid).then(({ data }) => {
					store.dispatch(
						actions.setAudience({
							content: data,
							loading: false,
						}),
					);
				});

				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(actions.fetchAudiences(studyId));
			})
			.catch(error => { });
	}
};

const deleteItemLogic = (store, action) => {
	if (action.type === actions.DELETE_ITEM_LOGIC) {
		const { studyId, sectionId, logicId } = action.payload;
		services.sections
			.deleteItemLogic(studyId, sectionId, logicId)
			.then(response => {
				store.dispatch(actions.fetchSections(studyId, true));
				store.dispatch(actions.fetchSection(sectionId, 'edit', true));
				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(actions.fetchAudiences(studyId));
			})
			.catch(error => { });
	}
};

const deleteAudienceItemLogic = (store, action) => {
	if (action.type === actions.DELETE_AUDIENCE_ITEM_LOGIC) {
		const { studyId, audienceUuid, logicId } = action.payload;
		services.sections
			.deleteAudienceItemLogic(studyId, audienceUuid, logicId)
			.then(response => {
				services.studySampleService.getAudience(studyId, audienceUuid).then(({ data }) => {
					store.dispatch(
						actions.setAudience({
							content: data,
							loading: false,
						}),
					);
				});

				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(actions.fetchAudiences(studyId));
			})
			.catch(error => { });
	}
};

const postSectionSettings = (store, action) => {
	if (action.type === actions.POST_SECTION_SETTINGS) {
		const { studyId, sectionId, data, refetchSection } = action.payload;
		store.dispatch(actions.setSavingSectionSettings(true));
		services.sections
			.postSectionSettings(studyId, sectionId, data)
			.then(response => {
				const insertData = data;
				insertData.id = response.data.insertId;
				store.dispatch(actions.setSectionSettingsInsertData(insertData));
				store.dispatch(actions.setSavingSectionSettings(false));
				if (refetchSection) {
					store.dispatch(actions.fetchSection(sectionId, 'edit', true));
				}
			})
			.catch(() => {
				store.dispatch(actions.setSavingSectionSettings(false));
			});
	}
};

const patchSectionSettings = (store, action) => {
	if (action.type === actions.PATCH_SECTION_SETTINGS) {
		const { studyId, sectionId, settingId, data, refetchSection } = action.payload;
		store.dispatch(actions.setSavingSectionSettings(true));
		services.sections
			.patchSectionSettings(studyId, sectionId, settingId, data)
			.then(response => {
				// ! Not ideal to be grabbing data off the config, but it fixes an issue of displaying the most recent commitment text.
				const insertData = JSON.parse(response.config.data);
				insertData.id = settingId;
				store.dispatch(actions.setSectionSettingsInsertData(insertData));
				store.dispatch(actions.setSavingSectionSettings(false));
				if (refetchSection) {
					store.dispatch(actions.fetchSection(sectionId, 'edit', true));
					store.dispatch(actions.fetchSections(studyId, true));
				} else {
					const { settings } = selectors.getCurrentSection(store.getState()).content;
					const updatedSettings = settings.map(setting => {
						if (setting.label !== data.label) return setting;
						return { id: setting.id, ...data };
					});
					store.dispatch(actions.setSectionSettings(updatedSettings));
				}
			})
			.catch(arg => {
				store.dispatch(actions.setSavingSectionSettings(false));
			});
	}
};

const deleteSectionSettings = (store, action) => {
	if (action.type === actions.DELETE_SECTION_SETTINGS) {
		const { studyId, sectionId, settingId } = action.payload;
		services.sections
			.deleteSectionSettings(studyId, sectionId, settingId)
			.then(response => { })
			.catch(error => {
				console.error('actions.DELETE_SECTION_SETTINGS: error: ', error);
			});
	}
};

const getAudienceNorms = (store, action) => {
	if (action.type === actions.GET_AUDIENCE_NORMS) {
		const { getAudienceNormsParams } = action.payload;
		services.studySampleService.getPublicAudienceNorms(getAudienceNormsParams).then(response => {
			store.dispatch(actions.setAudienceNorms(response.data));
		});
	}
};

const postAudienceNorm = (store, action) => {
	if (action.type === actions.POST_AUDIENCE_NORM) {
		const { audienceNorm } = action.payload;
		services.studySampleService.createAudienceNorm(audienceNorm).then(response => {
			store.dispatch(actions.getAudienceNorms(audienceNorm));
		});
	}
};

const putAudienceNorm = (store, action) => {
	if (action.type === actions.PUT_AUDIENCE_NORM) {
		const { audienceNorm } = action.payload;
		services.studySampleService.updateAudienceNorm(audienceNorm).then(response => {
			store.dispatch(actions.getAudienceNorms(audienceNorm));
		});
	}
};

export default [
	createSection,
	fetchSections,
	fetchSection,
	reorderSections,
	patchSectionName,
	deleteSection,
	patchSectionLogic,
	patchSection,
	patchItem,
	patchAudienceItem,
	deleteItemLogic,
	deleteAudienceItemLogic,
	postSectionSettings,
	patchSectionSettings,
	deleteSectionSettings,
	getAudienceNorms,
	postAudienceNorm,
	putAudienceNorm,
];
