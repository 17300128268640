const shortSurveyUrl = process.env.shortSurveyUrl || '';
const surveyUrl = process.env.surveyUrl || '';

const buildUrlParams = (customParams, params = []) => {
	if (!customParams?.length && !params?.length) return '';
	const urlParams = params;
	Object.keys(customParams).forEach(key => {
		const value = customParams[key];
		urlParams.push(`${value}={${value}}`);
	});
	return `?${urlParams.join('&')}`;
};

const buildShortLink = ({ shortLinkUuid, audience, shortLinkType, customParams, isIframe }) => {
	const urlParams = buildUrlParams(customParams);
	const baseUrl = isIframe ? surveyUrl : shortSurveyUrl;
	const rid = shortLinkType !== 'preview' && !audience?.allowSingleDeploy ? '/user/{RID}' : '';
	return `${baseUrl}/${shortLinkUuid}${rid}${urlParams}${isIframe ? '?disablePreviewPrompt=1' : ''}`;
};

const buildlongLink = studyUuid => `${surveyUrl}/study/${studyUuid}`;

const getlongLink = ({ study, audience, language, shortLinkType, customParams, isIframe }) => {
	let params = [];

	if (language && !audience?.languageCode) {
		params.push(`language=${language}`);
	}
	if (audience?.uuid) {
		params.push(`audienceUuid=${audience.uuid}`);
		params.push(`language=${audience.languageCode}`);
	}

	const rid = shortLinkType !== 'preview' && !audience?.allowSingleDeploy ? '/user/{RID}' : '';
	if (shortLinkType === 'preview') {
		params.push(`previewUuid=${study?.previewUuid}`);
	}

	const baseUrl = buildlongLink(study.uuid, rid);
	const urlParams = buildUrlParams(customParams, params);
	return `${baseUrl}${rid}${urlParams}${isIframe ? '&disablePreviewPrompt=1' : ''}`;
};

export const useStudyLink = ({ study, audience, language, shortLinkType, customParams = {}, isIframe = false }) => {
	const translation = study.translations?.find(({ languageCode }) => languageCode === language);
	let shortLinkUuid = audience?.shortLink?.[shortLinkType];
	if (!audience || !Object.keys(audience)?.length) {
		shortLinkUuid = translation?.shortLink?.[shortLinkType];
	}

	if (shortLinkUuid) {
		return buildShortLink({ shortLinkUuid, audience, shortLinkType, customParams, isIframe });
	}

	return getlongLink({ study, audience, language, shortLinkType, customParams, isIframe });
};

export const useRedirectLink = ({ study }) => `${surveyUrl}/study/${study.uuid}?rid={RID}&audienceUuid={audienceUuid}`;
