import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { formatEmojiUnicode, getAssetVariationUrl } from 'src/utilities/misc.js';
import { useIsApple } from 'src/hooks';

import './styles.scss';

const className = 'study-image';
const el = (name, mod) => cn(className, name, mod);

// Emojis used as a fallback incase the emoji data endpoint fails
export const localEmojis = [
	'1f4ca', // 📊
	'1f680', // 🚀
	'1f4c4', // 📄
	'1f4da', // 📚
	'1f587-fe0f', // 🖇️
	'1fa84', // 🪄
	'1f4c8', // 📈
	'1f4d8', // 📘
	'1f4d7', // 📗
	'1f4d1', // 📑
];

const StudyImage = ({ asset, emoji, size = 'medium' }) => {
	const { isApple } = useIsApple();
	const set = isApple ? 'native' : 'twitter';

	const randomEmoji = useMemo(() => localEmojis[Math.floor(Math.random() * localEmojis.length)], []);

	if (asset) {
		return (
			<div
				className={el('asset', size)}
				style={{ backgroundImage: `url(${getAssetVariationUrl(asset, ['thumbnail'])})` }}
			/>
		);
	}

	if (emoji) {
		return (
			<div className={el('emoji', size)}>
				<em-emoji set={set} native={formatEmojiUnicode(emoji)} size="2em" />
			</div>
		);
	}

	return (
		<div className={el('emoji', size)}>
			<em-emoji set={set} native={formatEmojiUnicode(randomEmoji)} size="2em" />
		</div>
	);
};

StudyImage.propTypes = {
	asset: PropTypes.any,
	emoji: PropTypes.string,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default StudyImage;
