import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

function getAudienceTemplates(searchQuery = null, offset = 0, limit = 20) {
	return axios.get(`${API_PATH}/templates`, {
		params: {
			searchQuery,
			offset,
			limit,
		},
	});
}

function getDemographicGroupTemplates({
	searchQuery = null,
	offset = 0,
	limit = 20,
	terms,
	languageCode = null,
	countryId = null,
}) {
	return axios.get(`${API_PATH}/templates/demographicGroups`, {
		params: {
			terms,
			searchQuery,
			offset,
			limit,
			languageCode,
			countryId,
		},
	});
}

/**
 *
 * @param number templateId
 * @param number sampleSize
 */
function getAudiencePrice(templateId, sampleSize, currency = 'usd') {
	return axios.get(`${API_PATH}/templates/${templateId}/price?sampleSize=${sampleSize}&currency=${currency}`);
}

/**
 *
 * @param number templateId
 * @param number sampleSize
 */
function getBYOPrice(sampleSize, currency = 'usd') {
	return axios.get(`${API_PATH}/templates/byo/price?sampleSize=${sampleSize}&currency=${currency}`);
}

/**
 *
 * @param number studyId
 * @param {} audienceData
 */
function createAudience(studyId, audienceData) {
	return axios.post(`${API_PATH}/studies/${studyId}/audiences`, audienceData);
}
/**
 *
 * @param number studyId
 * @param {} audienceData
 */
function saveGroupAsTemplate(studyId, audienceData) {
	return axios.post(`${API_PATH}/studies/${studyId}/audiences`, audienceData);
}

/**
 *
 * @param number studyId
 */
function getAudiences(studyId) {
	return axios.get(`${API_PATH}/studies/${studyId}/audiences`);
}

/**
 *
 * @param number studyId
 */
function getAudiencesV2(studyId) {
	return axios.get(`${API_PATH}/studies/${studyId}/audiences/v2`);
}
/**
 *
 * @param number studyUuid
 */
function getPublicAudiencesV2(studyUuid) {
	return axios.get(`${API_PATH}/public/studies/${studyUuid}/audiences/v2`);
}

/**
 *
 * @param number studyId
 */
function fetchStudyLoi(studyId) {
	return axios.get(`${API_PATH}/studies/${studyId}/loi`);
}

/**
 *
 * @param number studyId
 */
function fetchPublicStudyLoi(studyUuid) {
	return axios.get(`${API_PATH}/public/studies/${studyUuid}/loi`);
}

/**
 *
 * @param number studyId
 */
function getPublicAudiences(studyId) {
	return axios.get(`${API_PATH}/public/studies/${studyId}/audiences`);
}

/**
 *
 * @param string studyUuid
 */
function getPublicAudienceCollection(studyUuid) {
	return axios.get(`${API_PATH}/public/studies/${studyUuid}/audience/collection`);
}

/**
 *
 * @param {string} studyUuid
 * @param {number} collectionId
 */
function getPublicAudienceCollectionReport(studyUuid, collectionId, data) {
	const { audienceUuids, filters, languages } = data;
	const excludeDisquals = audienceUuids?.includes('exclude-disquals');
	return axios.post(`${API_PATH}/public/studies/${studyUuid}/audience/collection/${collectionId}/report/stats`, {
		audienceUuids: audienceUuids?.filter(audienceUuid => audienceUuid !== 'exclude-disquals'),
		filters,
		languages,
		excludeDisquals,
	});
}

/**
 *
 * @param {string} studyUuid
 * @param {number} collectionId
 */
function getPublicDemographicQuestionsReport(studyUuid, groupUuid, data) {
	const { audienceUuids, filters, languages } = data;
	const excludeDisquals = audienceUuids?.includes('exclude-disquals');
	return axios.post(`${API_PATH}/public/studies/${studyUuid}/audiences/${groupUuid}/report/stats`, {
		audienceUuids: audienceUuids?.filter(audienceUuid => audienceUuid !== 'exclude-disquals'),
		filters,
		languages,
		excludeDisquals,
	});
}

/**
 *
 * @param number studyId
 * @param string audienceUuid
 */
function getAudience(studyId, audienceUuid) {
	return axios.get(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}`);
}

/**
 *
 * @param number studyId
 */
function getMarketplaceAudiencePrice(studyId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/audiences/pricing`, data);
}

/**
 *
 * @param number studyId
 * @param string audienceUuid
 * @param {sampleSize:number, templateId:number, price: number} audienceData
 */
function updateAudience(studyId, audienceUuid, audienceData) {
	return axios.put(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}`, audienceData);
}

/**
 *
 * @param number studyId
 * @param string audienceUuid
 * @param {sampleSize:number, templateId:number, price: number} audienceData
 */
function patch(studyId, audienceUuid, audienceData) {
	return axios.patch(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}`, audienceData);
}

/**
 *
 * @param number studyId
 * @param string audienceUuid
 */
function launchAudience(
	studyId,
	audienceUuid,
	creditCardId,
	externalBilling,
	projectNumber,
	projectMemo,
	taxes,
	paymentMethod,
	amountPaidByCredits,
	autoAcceptIr,
) {
	return axios.post(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/launch`, {
		creditCardId,
		employeeBilling: externalBilling,
		projectNumber,
		projectMemo,
		taxes,
		paymentMethod,
		amountPaidByCredits,
		autoAcceptIr,
	});
}

/**
 *
 * @param number studyId
 * @param string audienceUuid
 */
function previewAudience(studyId, audienceUuid, creditCardId, externalBilling, projectNumber) {
	return axios.post(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/launch/preview`, {
		creditCardId,
		employeeBilling: externalBilling,
		projectNumber,
	});
}

function stopAudience(studyId, audienceUuid) {
	return axios.post(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/close`);
}

function deleteAudience(studyId, audienceUuid) {
	return axios.delete(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}`);
}

function restartAudience(studyId, audienceUuid) {
	return Promise.resolve().then(() => {
		console.error('Endpoint not implemented.');
	});
}

/**
 *
 * @param {studyId: number, audienceUuid: string, sectionId: number} getAudienceNormsParams
 */
function getAudienceNorms({ studyId, sectionId }) {
	return axios.get(`${API_PATH}/studies/${studyId}/sections/${sectionId}/audience-norms`);
}

/**
 *
 * @param {studyId: number, audienceUuid: string, sectionId: number} getAudienceNormsParams
 */
function getPublicAudienceNorms({ studyId, sectionId }) {
	return axios.get(`${API_PATH}/public/studies/${studyId}/sections/${sectionId}/audience-norms`);
}

/**
 *
 * @param {studyId: number, audienceUuid: string, sectionId: number, min: number, max: number} createAudienceNormParams
 */
function createAudienceNorm({ studyId, audienceUuid, sectionId, min, max }) {
	return axios.post(`${API_PATH}/studies/${studyId}/sections/${sectionId}/audiences/${audienceUuid}/norms`, {
		min,
		max,
	});
}

/**
 *
 * @param {id: number, studyId: number, audienceUuid: string, sectionId: number, min: number, max: number} updateAudienceNormParams
 */
function updateAudienceNorm({ id, studyId, audienceUuid, sectionId, min, max }) {
	return axios.put(`${API_PATH}/studies/${studyId}/sections/${sectionId}/audiences/${audienceUuid}/norms/${id}`, {
		min,
		max,
	});
}

/**
 *
 * @param number studyId
 * @param string audienceQuestionId
 * @param string audienceQuestionOptionId
 */
function deleteAgeRangeDemographicQuestionOption(studyId, audienceQuestionId, audienceQuestionOptionId) {
	return axios.delete(
		`${API_PATH}/studies/${studyId}/audienceQuestions/${audienceQuestionId}/audienceQuestionOptions/${audienceQuestionOptionId}`,
	);
}
/**
 *
 * @param number studyId
 */
function getStudyAllFiles(studyId) {
	return axios.get(`${API_PATH}/studies/${studyId}/files`);
}

/**
 *
 * @param number studyId
 * @param number fileId
 */
function getStudyFile(studyId, fileId) {
	return axios.get(`${API_PATH}/studies/${studyId}/files/${fileId}`);
}


/**
 *
 * @param number studyId
 * @param number fileId
 */
function downloadStudyFile(studyId, fileId, onDownloadProgress) {
	return axios.post(
		`${API_PATH}/studies/${studyId}/files/${fileId}`,
		{},
		{ responseType: 'blob', onDownloadProgress }
	);
}

/**
 *
 * @param number studyId
 * @param number fileId
 */
function deleteStudyFile(studyId, fileId) {
	return axios.delete(`${API_PATH}/studies/${studyId}/files/${fileId}`);
}

/**
 *
 * @param number studyId
 * @param number fileId
 * @param object data
 */
function patchStudyFile(studyId, fileId, data) {
	return axios.patch(`${API_PATH}/studies/${studyId}/files/${fileId}`, data);
}

/**
 *
 * @param number studyId
 */
function notifyStudyFile(studyId) {
	return axios.post(`${API_PATH}/studies/${studyId}/files/notify`);
}

/**
 *
 * @param number studyId
 * @param formData formData
 * @param function onUploadProgress
 * @param function onDownloadProgress
 * @param object cancelTokenSource
 */
function uploadStudyFile(
	studyId,
	formData,
	onUploadProgress = false,
	onDownloadProgress = false,
	cancelTokenSource = axios.CancelToken.source(),
) {
	return axios.post(`${API_PATH}/studies/${studyId}/files/upload`, formData, {
		cancelToken: cancelTokenSource.token,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		onUploadProgress: progressEvent => (onUploadProgress ? onUploadProgress(progressEvent) : () => false),
		onDownloadProgress: progressEvent => (onDownloadProgress ? onDownloadProgress(progressEvent) : () => false),
	});
}

export default {
	getAudienceTemplates,
	getDemographicGroupTemplates,
	getAudiencePrice,
	getBYOPrice,
	createAudience,
	saveGroupAsTemplate,
	deleteAgeRangeDemographicQuestionOption,
	getAudiences,
	getPublicAudiences,
	getPublicAudienceCollection,
	getPublicAudienceCollectionReport,
	getPublicDemographicQuestionsReport,
	getAudience,
	getAudiencesV2,
	getPublicAudiencesV2,
	updateAudience,
	patch,
	launchAudience,
	previewAudience,
	stopAudience,
	deleteAudience,
	getMarketplaceAudiencePrice,
	restartAudience,
	getAudienceNorms,
	getPublicAudienceNorms,
	createAudienceNorm,
	updateAudienceNorm,
	fetchStudyLoi,
	fetchPublicStudyLoi,
	uploadStudyFile,
	getStudyAllFiles,
	getStudyFile,
	downloadStudyFile,
	deleteStudyFile,
	patchStudyFile,
	notifyStudyFile,
};
