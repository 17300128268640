const prefix = 'clients';

/* State setters */

export const SET_COMMON_ERROR = `${prefix}/SET_COMMON_ERROR`;
export const setCommonError = commonError => ({ type: SET_COMMON_ERROR, payload: { commonError } });

export const SET_CLIENT_ID = `${prefix}/SET_CLIENT_ID`;
export const setClientId = clientId => ({ type: SET_CLIENT_ID, payload: { clientId } });

export const SET_CLIENTS = `${prefix}/SET_CLIENTS`;
export const setClients = clients => ({ type: SET_CLIENTS, payload: { clients } });

export const SET_USERS = `${prefix}/SET_USERS`;
export const setUsers = users => ({ type: SET_USERS, payload: { users } });

export const SET_GROUPS = `${prefix}/SET_GROUPS`;
export const setGroups = groups => ({ type: SET_GROUPS, payload: { groups } });

export const SET_ADD_USER_LOADING = `${prefix}/SET_ADD_USER_LOADING`;
export const setAddUserLoading = isLoading => ({ type: SET_ADD_USER_LOADING, payload: { isLoading } });

export const SET_ADD_USER_COMPLETE = `${prefix}/SET_ADD_USER_COMPLETE`;
export const setAddUserComplete = complete => ({ type: SET_ADD_USER_COMPLETE, payload: { complete } });

export const SET_ADD_GROUP_MODAL = `${prefix}/SET_ADD_GROUP_MODAL`;
export const setAddGroupModal = addGroupModal => ({ type: SET_ADD_GROUP_MODAL, payload: { addGroupModal } });

export const SET_DELETE_USER_MODAL = `${prefix}/SET_DELETE_USER_MODAL`;
export const setDeleteUserModal = deleteUserModal => ({ type: SET_DELETE_USER_MODAL, payload: { deleteUserModal } });

export const SET_DELETE_GROUP_MODAL = `${prefix}/SET_DELETE_GROUP_MODAL`;
export const setDeleteGroupModal = deleteGroupModal => ({
	type: SET_DELETE_GROUP_MODAL,
	payload: { deleteGroupModal },
});

export const SET_ROLES = `${prefix}/SET_ROLES`;
export const setRoles = roles => ({ type: SET_ROLES, payload: { roles } });

export const SET_MANAGE_GROUP_MODAL = `${prefix}/SET_MANAGE_GROUP_MODAL`;
export const setManageGroupModal = manageGroupModal => ({
	type: SET_MANAGE_GROUP_MODAL,
	payload: { manageGroupModal },
});

export const SET_SORTING = `${prefix}/SET_SORTING`;
export const setSorting = sorting => ({ type: SET_SORTING, payload: { sorting } });

/* Events */

export const FETCH_ROLES = `${prefix}/FETCH_ROLES`;
export const fetchRoles = () => ({ type: FETCH_ROLES });

export const FETCH_CLIENTS = `${prefix}/FETCH_CLIENTS`;
export const fetchClients = uuid => ({ type: FETCH_CLIENTS, payload: { uuid } });

export const FETCH_USERS = `${prefix}/FETCH_USERS`;
export const fetchUsers = () => ({ type: FETCH_USERS });

export const FETCH_GROUPS = `${prefix}/FETCH_GROUPS`;
export const fetchGroups = () => ({ type: FETCH_GROUPS });

export const ADD_GROUP = `${prefix}/ADD_GROUP`;
export const addGroup = label => ({ type: ADD_GROUP, payload: { label } });

export const ADD_USER = `${prefix}/ADD_USER`;
export const addUser = (emails, role) => ({ type: ADD_USER, payload: { emails, role } });

export const DELETE_USER = `${prefix}/DELETE_USER`;
export const deleteUser = id => ({ type: DELETE_USER, payload: { id } });

export const CHANGE_USER_ROLE = `${prefix}/CHANGE_USER_ROLE`;
export const changeUserRole = (userId, roleId) => ({ type: CHANGE_USER_ROLE, payload: { userId, roleId } });

export const DELETE_GROUP = `${prefix}/DELETE_GROUP`;
export const deleteGroup = id => ({ type: DELETE_GROUP, payload: { id } });

export const MANAGE_GROUP = `${prefix}/MANAGE_GROUP`;
export const manageGroup = id => ({ type: MANAGE_GROUP, payload: { id } });

export const APPLY_MANAGE_GROUP = `${prefix}/APPLY_MANAGE_GROUP`;
export const applyManageGroup = diff => ({ type: APPLY_MANAGE_GROUP, payload: { diff } });

export const FETCH_GROUP_USERS = `${prefix}/FETCH_GROUP_USERS`;
export const fetchGroupUsers = () => ({ type: FETCH_GROUP_USERS });
