import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddNewItemButton from 'src/components/elements/AddNewItemButton';
import LogicCondition from 'src/components/shared/LogicCondition';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'question-logic-conditions';
const el = (name, mod) => cn(className, name, mod);

const QuestionLogicConditions = ({
	logic,
	study,
	audience,
	question,
	section,
	sections,
	sectionOrder,
	patchItem,
	deleteItemLogic,
	tempTemplate,
	setTempTemplate,
	blockUpdateOrBreakTemplate,
	deleteAudienceItemLogic,
	patchAudienceItemLogic,
	language,
}) => {
	const [localLogic, setLocalLogic] = useState(logic);

	useEffect(() => {
		setLocalLogic(logic);
	}, [logic]);

	const zIndexRef = React.createRef();

	const triggerType = section && section.type && section.type === 'swipe' ? 'product' : 'question';

	const getDummyLogicItem = () => {
		let triggerSectionId = !tempTemplate ? section.id : null;
		if (audience) {
			triggerSectionId = audience.uuid;
		}

		return {
			...(section
				? {
						// 1. Item belongs to this section
						sectionId: section.id, // The ID of the section in the sidebar
						sectionItemId: question.id, // The ID of the question itself
				  }
				: {
						itemId: question.id,
				  }),
			// id: `dummy_id`,
			operator: 'is', // Either 'is' or 'is not'
			operand: 'and', // Either 'and' or 'or'
			// 2. Item select (Can also be a 'product')
			triggerType, // * Can be 'question' or 'product'
			triggerItemId: question.id, // ID of the question or product
			triggerOptions: [], // Selections in the answer select (e.g. 'red, blue, green' or 'like, dislike')
			triggerSectionId, // Same as the section ID
			// 3. Destination select
			actionType: question.style === 'open-ended' ? 'skip' : 'goto', // 'GOTO' or 'SKIP'
			actionSectionId: null, // Action ID of the skipped item
			actionItemId: null, // 'GOTO' or 'SKIP' which section or question
		};
	};

	const addNewCondition = () => {
		setLocalLogic(current => [...current, getDummyLogicItem()]);
	};

	const addNewConditionWithPrompt = e => {
		if (blockUpdateOrBreakTemplate) blockUpdateOrBreakTemplate(() => addNewCondition(e));
		else addNewCondition(e);
	};

	const logicConditionOnClose = id => {
		setLocalLogic(current => current.filter(item => item.temp_id !== id && item.id !== id));
	};

	return (
		<div className={el('logic-conditions')} ref={zIndexRef}>
			{localLogic?.map((logicItem, i) => {
				const logicComponentType = audience ? 'AudienceLogicCondition' : 'LogicCondition';

				return (
					<LogicCondition
						key={`conditionItem-${i}`} // todo: fix index based key
						type={logicComponentType}
						logicItem={logicItem}
						logic={localLogic}
						setLogic={setLocalLogic}
						study={study}
						audience={audience || tempTemplate}
						question={question}
						section={section}
						sections={sections}
						// sectionOrder={sectionOrder}
						onClose={logicConditionOnClose}
						patchItem={patchItem}
						deleteItemLogic={deleteItemLogic}
						deleteAudienceItemLogic={deleteAudienceItemLogic}
						patchAudienceItemLogic={patchAudienceItemLogic}
						zIndexRef={zIndexRef}
						tempTemplate={tempTemplate}
						setTempTemplate={setTempTemplate}
						blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
						isAudienceLogic={!!audience}
						language={language}
					/>
				);
			})}
			<AddNewItemButton label="Add New Condition" onClick={addNewConditionWithPrompt} />
		</div>
	);
};

QuestionLogicConditions.propTypes = {
	logic: PropTypes.array,
	study: PropTypes.any,
	audience: PropTypes.any,
	question: PropTypes.any,
	section: PropTypes.any,
	sections: PropTypes.any,
	sectionOrder: PropTypes.any,
	patchItem: PropTypes.func,
	deleteItemLogic: PropTypes.func,
	tempTemplate: PropTypes.any,
	setTempTemplate: PropTypes.func,
	blockUpdateOrBreakTemplate: PropTypes.func,
	deleteAudienceItemLogic: PropTypes.func,
	patchAudienceItemLogic: PropTypes.func,
	language: PropTypes.any,
};

export default QuestionLogicConditions;
