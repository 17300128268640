import React, { useEffect, useState, useId } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import StyledCheckbox from 'src/components/inputs/StyledCheckbox';
import { Iconof } from '@upsiide/ui-components';
import './styles.scss';

const className = 'search-dropdown';

const el = (name, mod) => cn(className, name, mod);

const SearchDropdown = ({
	multi,
	data,
	value,
	onChange,
	searchable = false,
	customizeVisibility = false,
	maxLength = false,
}) => {
	const [search, setSearch] = useState('');
	const [list, setList] = useState(data);
	const [selected, setSelected] = useState(Array.isArray(value) ? value : [value]);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const dropdownBaseID = useId();

	useEffect(() => {
		if (value || value?.length) {
			const activeItem = document.querySelectorAll(`.${el('content')}.${multi ? 'multi_active' : 'active'}`)[0];
			if (activeItem && !customizeVisibility)
				activeItem?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!search) {
			setSearch('');
			setList(data);
		}
	}, [search, data]);

	const onClearText = () => {
		if (search) {
			setSearch('');
			setList(data);
		}
	};

	const selectAll = () => {
		if (list?.length === 0) return;
		const isSelectAll = selected?.length !== list?.length;
		if (isSelectAll) {
			setSelected(list);
		} else {
			setSelected([]);
		}
	};

	useEffect(() => {
		if (!isFirstRender) {
			onChange(selected);
		} else {
			setIsFirstRender(false);
		}
		// eslint-disable-next-line
	}, [selected]);

	const onSelect = (event, item) => {
		event?.preventDefault();
		event?.stopPropagation();
		const isSelected = selected.some(e => e?.value === item?.value);
		if (maxLength && !isSelected && selected?.length >= maxLength) {
			return;
		}

		if (isSelected) {
			setSelected(prev => prev.filter(e => e?.value !== item?.value));
		} else {
			setSelected(prev => [...prev, item]);
		}
	};

	const onSingleSelect = (event, item) => {
		event?.preventDefault();
		event?.stopPropagation();
		if (selected.some(e => e?.value === item?.value)) {
			setSelected([]);
		} else {
			setSelected([item]);
		}
	};

	const onSearch = event => {
		event?.stopPropagation();
		event?.preventDefault();
		const query = event?.target?.value?.trim() || '';
		setSearch(query);
		const filter = data.filter(e => e?.label?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1);
		setList(filter);
	};

	const renderList = () => {
		if (multi) return renderMultiList();
		return renderSingleList();
	};

	const renderMultiList = () =>
		list.map((item, index) => (
			<div
				className={`${el('content')}${selected.some(e => e?.value === item?.value) ? ' multi_active' : ''}`}
				key={`search-dropdown-multi-${dropdownBaseID}-${index}-${item?.value}`}
				aria-hidden
				onClick={e => onSelect(e, item)}
			>
				<StyledCheckbox
					checked={selected.some(e => e?.value === item?.value)}
					customClasses={el('checkbox')}
					onChange={e => onSelect(e, item)}
				/>
				<span className={el('item')}>{item?.label}</span>
			</div>
		));

	const renderSingleList = () =>
		list.map((item, index) => (
			<div
				className={`${el('content')}${selected.some(e => e?.value === item?.value) ? ' active' : ''}`}
				key={`search-dropdown-single-${dropdownBaseID}-${index}-${item?.value}`}
				aria-hidden
				onClick={e => onSingleSelect(e, item)}
			>
				{selected.some(e => e?.value === item?.value) ? (
					<Iconof icon="done" color="green-400" className={el('done_icon')} />
				) : (
					<div style={{ width: 22 }} />
				)}
				<span className={el('item')}>{item?.label}</span>
			</div>
		));

	return (
		<div className={`${className} ${customizeVisibility ? 'customize-visibility' : ''}`}>
			{searchable ? (
				<>
					<div className={el('input_container')}>
						<input
							className={el('search_input')}
							placeholder="Type to search"
							value={search}
							onChange={e => onSearch(e)}
							autoFocus
						/>
						<Iconof
							icon={search ? 'clear' : 'search'}
							size="default"
							className={el('search_icon')}
							onClick={onClearText}
						/>
					</div>

					<div className={el('divider')} />
				</>
			) : null}
			{multi ? (
				<div className={`${el('select_all_container')}${!searchable ? ' margin' : ''}`}>
					<div className={el('select_all')} aria-hidden onClick={selectAll}>
						{selected && selected.length !== list?.length ? 'Select All' : 'Unselect All'}{' '}
					</div>
				</div>
			) : null}

			<div className={el('container')}>{renderList()}</div>
		</div>
	);
};

SearchDropdown.propTypes = {
	multi: PropTypes.bool,
	data: PropTypes.array,
	onChange: PropTypes.func,
	value: PropTypes.any,
	searchable: PropTypes.bool,
	customizeVisibility: PropTypes.bool,
};

export default SearchDropdown;
