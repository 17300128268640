// packages
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import toastr from 'toastr';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import 'react-tippy/dist/tippy.css';
import last from 'lodash/last';

// utilities
import cn from 'src/utilities/bem-cn';

// helpers
import { getMeanScoreScaleOrder, shouldShowMeanScoreScale } from 'src/components/helpers';

// components
import Textarea from 'src/components/inputs/Textarea';
import AddAnswerButton from 'src/components/elements/AddAnswerButton';
import Emoji from 'src/components/shared/Emoji';
import AdditionalQuestionOptionSettings from '../AdditionalQuestionOptionSettings';
import QuestionOption from '../QuestionOption';
import ModifyScaleButton from '../ModifyScaleButton';

// styles
import './styles.scss';

const className = 'question-options';
const audienceClassName = 'audience_question-options';
const el = (name, mod) => cn(className, name, mod);

const QuestionOptions = ({
	// props
	updateOptionLabel,
	updateOptionScalePoint,
	setOptionScalePointValue,
	updateAttributeLabel,
	updateOptionAsset,
	updateAttributeAsset,
	language,
	questionId,
	question,
	removeQuestionOption,
	removeQuestionAttribute,
	options = [],
	attributes = [],
	answerType = 'single-select',
	patchQuestion,
	addQuestionOption,
	addQuestionAttribute,
	setIsPlaceholderActive,
	setIsFocusOnLastAnswer,
	onFocus,
	onBlur,
	addOptionPlaceholder,
	addAttributePlaceholder,
	defaultLanguage,
	tempTemplate,
	setTempTemplate,
	blockUpdateOrBreakTemplate,
	setFocusOnCurrentQuestion,
	setFocusOnNextQuestion,
	filterImportQuestions,
	changeSetting,
	loading,
	setLoading,
	localSettings,
	studyType,
	audience,
	updateScreeningOption,
	updateScreeningOptionScalePoint,
	setScreeningOptionScalePointValue,
	updateScreeningAttribute,
	hasQuotas,
	updateScreeningQuota,
	hasInterlockedQuotas,
	setQuotaError,
	saveSetting,
	screeningQuestion,
	batchUpdateAudienceTemplateScreeningQuestionOptions,
}) => {
	const [activeAnswer, setActiveAnswer] = useState(null);
	const [activeScalePointInputIndex, setActiveScalePointInputIndex] = useState(null);
	const [activeAttribute, setActiveAttribute] = useState(null);
	const [openAnswer, setOpenAnswer] = useState('Open-ended response...');
	const [focusedQuotaId, setFocusedQuotaId] = useState(null);

	const answers = useMemo(
		() =>
			options.sort((a, b) => {
				if (a.order === 0 && b.order === 0) return a.id > b.id ? 1 : -1;
				return a.order > b.order ? 1 : -1;
			}) || [],
		[options],
	);

	const sortedAttributes = useMemo(
		() =>
			attributes.sort((a, b) => {
				if (a.order === 0) return a.id > b.id ? 1 : -1;
				return a.order > b.order ? 1 : -1;
			}) || [],
		[attributes],
	);

	const [lastAnswerFocused, setLastAnswerFocused] = useState(true); // set this state to false if you want to scroll automatically to the bottom
	const [lastAttributeFocused, setLastAttributeFocused] = useState(true); // set this state to false if you want to scroll automatically to the bottom
	const [removeClicked, setRemoveClicked] = useState('');

	const showScale = useMemo(() => shouldShowMeanScoreScale(question), [question]);
	// * Check whether each answer has a scale point equal to its ordinal position (asc default). Scale points of 'Other' and 'None of the Above' options should be `null` by default
	const isDefaultScale = useMemo(() => !getMeanScoreScaleOrder(answers)?.isCustom, [answers]);

	const languageRef = useRef();

	const translationLanguage =
		typeof language === 'object' && language?.languageCode ? language?.languageCode : language;

	languageRef.current = translationLanguage;

	const updateQuestionOption = useCallback(
		(newLabel, option, keepPlaceholder, index = null) => {
			const optionTranslation = option?.translations?.find(
				translation => translation.languageCode === languageRef?.current,
			);

			if (tempTemplate) {
				tempTemplate.estimatedIncidenceRate = null;
				let optionData = { newLabel, value: newLabel };
				const questionOption = question?.options?.find(o => o.id === option.id) ?? {};

				// Create New Translation with label and language code and add to current translation object
				optionData = { ...questionOption, scalePoint: option?.scalePoint ?? null };
				const indexLanguageTranslation = optionData?.translations?.findIndex(
					trans => trans.languageCode === languageRef?.current,
				);

				if (indexLanguageTranslation !== -1 && indexLanguageTranslation !== undefined) {
					optionData.translations[indexLanguageTranslation] = {
						...optionData?.translations?.[indexLanguageTranslation],
						label: newLabel,
					};
				} else {
					optionData?.translations?.push({
						languageCode: languageRef?.current,
						label: newLabel,
					});
				}

				delete optionData?.label;
				delete option.maskedOptions;
				delete option.assetVariations;

				const isPATCH = option.translations.some(trans => !!trans?.id);

				if (isPATCH) {
					const newTranslations = [];
					if (optionData.translations) {
						for (const translation of optionData.translations) {
							delete translation.asset;
							delete translation.assetVariations;
							newTranslations.push(translation);
						}
					}
					delete optionData.newLabel;
					delete optionData.maskedOptions;
					delete optionData.asset;
					delete optionData.assetVariations;

					updateScreeningOption(questionId, option.id, true, {
						...optionData,
						...(question.useQuota
							? {
									quotaMin: null,
									quotaMax: null,
							  }
							: {}),
						translations: newTranslations,
					});
				} else {
					const newTranslations = [];
					for (const translation of option.translations) {
						delete translation.asset;
						delete translation.assetVariations;
						if (!translation?.languageCode?.languageCode) newTranslations.push(translation);
					}
					updateScreeningOption(
						questionId,
						option.id,
						false,
						{
							...option,
							...(question.useQuota
								? {
										...optionData,
										quotaMin: null,
										quotaMax: null,
								  }
								: {}),
							translations: newTranslations,
						},
						keepPlaceholder,
					);
				}
			} else {
				updateOptionLabel(
					option.id,
					optionTranslation?.id,
					questionId,
					newLabel,
					languageRef?.current,
					filterImportQuestions,
				);
			}
		},
		[filterImportQuestions, questionId, tempTemplate, updateOptionLabel, updateScreeningOption, question],
	);

	const handleOptionScalePointUpdate = useCallback(
		({ optionId, scalePoint }) => {
			if (tempTemplate) {
				// ? Not sure why it's needed but who knows what it can break
				tempTemplate.estimatedIncidenceRate = null;

				const targetOption = question?.options?.find(({ id }) => id === optionId) ?? {};

				const updatedOption = {
					...targetOption,
					scalePoint,
					...(question.useQuota
						? {
								quotaMin: null,
								quotaMax: null,
						  }
						: {}),
				};

				// * Delete fields which are not passing the API validation
				delete updatedOption.maskedOptions;
				delete updatedOption.asset;
				delete updatedOption.assetVariations;

				updateScreeningOptionScalePoint({ questionId, optionId, scalePoint, option: updatedOption });
			} else {
				updateOptionScalePoint({
					questionId,
					optionId,
					scalePoint,
					filterImportQuestions, // ? Is it needed?
				});
			}
		},
		[
			filterImportQuestions,
			question?.options,
			question.useQuota,
			questionId,
			tempTemplate,
			updateOptionScalePoint,
			updateScreeningOptionScalePoint,
		],
	);

	const updateQuestionAttribute = useCallback(
		(newLabel, attribute, keepPlaceholder) => {
			let attributeTranslation = attribute.translations.find(
				translation => translation.languageCode === languageRef?.current,
			);

			if (tempTemplate) {
				tempTemplate.estimatedIncidenceRate = null;
				delete attribute.maskedOptions;
				delete attribute.assetVariations;
				const isPATCH = attribute.translations.some(trans => !!trans?.id);
				const questionAttribute = question.attributes.find(o => o.id === attribute.id);

				if (attributeTranslation) {
					const foundTranslation = questionAttribute?.translations?.find(
						translation => translation.id === attributeTranslation.id,
					);
					if (foundTranslation) {
						attributeTranslation = foundTranslation;
					}
				}

				let attributeData = { newLabel, value: newLabel };
				if (!attributeTranslation && questionAttribute) {
					// Create New Translation with label and language code and add to current translation object
					attributeData = questionAttribute;
					// delete attributeData.id;
					attributeData.translations.push({
						language: languageRef?.current,
						newLabel,
					});
				}

				if (isPATCH) {
					delete attributeData.newLabel;
					updateScreeningAttribute(questionId, attribute.id, true, attributeData, attributeTranslation);
				} else {
					const newTranslations = [];
					for (const translation of attribute.translations) {
						delete translation.asset;
						delete translation.assetVariations;
						newTranslations.push(translation);
					}
					updateScreeningAttribute(
						questionId,
						attribute.id,
						false,
						{
							...attribute,
							translations: newTranslations,
						},
						false,
						keepPlaceholder,
					);
				}
			} else {
				updateAttributeLabel(
					attribute?.id,
					attributeTranslation?.id,
					questionId,
					newLabel,
					languageRef?.current,
				);
			}
		},
		[question.attributes, questionId, tempTemplate, updateAttributeLabel, updateScreeningAttribute],
	);

	const createQuestionOption = useCallback(
		(option, index = null) => {
			// Update API call
			const newAnswer = { ...option };
			delete newAnswer.id;
			delete newAnswer.placeholder;

			if (tempTemplate) {
				tempTemplate.estimatedIncidenceRate = null;
				// After creating the new option, we need to look and see if there were any placeholders options that need to be restored
				const currentQuestion = tempTemplate.customQuestions.find(q => q.id === questionId);
				// Last option is placeholder
				const lastOption = last(options);
				// Assign the latest answers with current Ids
				let updatedAnswers = options;
				// Check current options against latest options to see if a placeholder is needed
				if (currentQuestion.options.length > updatedAnswers.length) {
					// Add placeholder back into options
					lastOption.order =
						(last(updatedAnswers.filter(op => !op?.isNoneOfTheAbove && !op?.isOtherSpecify))?.order || 0) +
						1;
					updatedAnswers = updatedAnswers.concat(lastOption);
				}
				// Update current question and set store state
				currentQuestion.options = updatedAnswers;
				const i = tempTemplate.customQuestions.findIndex(q => q.id === question.id);
				tempTemplate.customQuestions[i].options = updatedAnswers;
				setTempTemplate({ ...tempTemplate });
			} else {
				addQuestionOption(questionId, newAnswer);
			}
		},
		[addQuestionOption, options, questionId, setTempTemplate, tempTemplate, question],
	);

	const createQuestionAttribute = useCallback(
		attribute => {
			// Update API call
			const newAnswer = { ...attribute };
			delete newAnswer.id;
			delete newAnswer.placeholder;
			delete newAnswer.undefined;
			delete newAnswer.value;

			if (tempTemplate) {
				tempTemplate.estimatedIncidenceRate = null;
				// After creating the new attribute, we need to look and see if there were any placeholders attributes that need to be restored
				const currentQuestion = tempTemplate.customQuestions.find(q => q.id === questionId);
				// Last attribute is placeholder
				const lastAttribute = last(attributes);
				// Assign the latest answers with current Ids
				let updatedAnswers = attributes;
				// Check current attributes against latest attributes to see if a placeholder is needed
				if (currentQuestion.attributes.length > updatedAnswers.length) {
					// Add placeholder back into attributes
					lastAttribute.order = last(updatedAnswers).order + 1;
					updatedAnswers = updatedAnswers.concat(lastAttribute);
				}
				// Update current question and set store state
				currentQuestion.attributes = updatedAnswers;
				const i = tempTemplate.customQuestions.findIndex(q => q.id === question.id);
				tempTemplate.customQuestions[i].attributes = updatedAnswers;
				setTempTemplate({ ...tempTemplate });
			} else {
				addQuestionAttribute(questionId, newAnswer);
			}
		},
		[addQuestionAttribute, attributes, question.id, questionId, setTempTemplate, tempTemplate],
	);

	const createOrUpdateQuestionOption = useCallback(
		async (option, value, isMasking, keepPlaceholder, index = null) => {
			if (isMasking) {
				// * Recalling options are excluded from the mean score scale
				await Promise.resolve(setOptionScalePointValue({ questionId, optionId: option.id, scalePoint: null }));
			}

			if (option?.placeholder && !tempTemplate) {
				const currentTranslation = option.translations.find(
					translation => translation.languageCode === languageRef.current,
				);
				if (currentTranslation?.label !== value) {
					currentTranslation.label = value;
				}
				if (!isMasking && currentTranslation.label === '@') return;
				createQuestionOption(option);
				setIsPlaceholderActive(false);
			} else {
				delete option?.placeholder;
				if (!isMasking && value === '@') return; // prevent re-render to call api twice
				if (isMasking) {
					updateQuestionOption(option.value, option, null, index);
				} else {
					updateQuestionOption(value, option, keepPlaceholder, index);
				}
			}
		},
		[
			tempTemplate,
			setOptionScalePointValue,
			questionId,
			createQuestionOption,
			setIsPlaceholderActive,
			updateQuestionOption,
		],
	);

	const createOrUpdateQuestionAttribute = useCallback(
		(attribute, value = false, keepPlaceholder) => {
			if (attribute?.placeholder && !tempTemplate) {
				createQuestionAttribute(attribute);
				setIsPlaceholderActive(false);
			} else {
				delete attribute?.placeholder;
				updateQuestionAttribute(value, attribute, keepPlaceholder);
			}
		},
		[createQuestionAttribute, setIsPlaceholderActive, updateQuestionAttribute, tempTemplate],
	);

	useEffect(() => {
		const questionItem = document.getElementById(`question-item-${questionId}`);
		const textareas = questionItem.getElementsByClassName('unstyled-textarea');
		const emptyQuestionItemExists = textareas.length && textareas[textareas.length - 1].value.length === 0;
		const optionPlaceholder = answers.filter(opt => opt.placeholder);
		const filteredAnswers = answers.filter(ans => ans?.order <= 99);
		if (emptyQuestionItemExists || optionPlaceholder) {
			const element = document.getElementById(`answer-textarea-${questionId}-${filteredAnswers.length - 1}`);
			const isFocused = document.activeElement === element;
			if (!isFocused && !lastAnswerFocused) {
				setLastAnswerFocused(true);
				if (element) {
					const { length } = element.value;
					element.focus();
					element.setSelectionRange(length, length);
				}
			}
		}
	}, [answers, lastAnswerFocused, questionId]);

	useEffect(() => {
		const questionItem = document.getElementById(`question-item-${questionId}`);
		const textareas = questionItem.getElementsByClassName('unstyled-textarea');
		const emptyQuestionItemExists = textareas.length && textareas[textareas.length - 1].value.length === 0;
		const attributePlaceholder = sortedAttributes.filter(attr => attr.placeholder);
		if (emptyQuestionItemExists || attributePlaceholder) {
			const element = document.getElementById(`attribute-textarea-${questionId}-${sortedAttributes.length - 1}`);
			const isFocused = document.activeElement === element;
			if (!isFocused && !lastAttributeFocused) {
				setLastAttributeFocused(true);
				if (element) {
					const { length } = element.value;
					element.focus();
					element.setSelectionRange(length, length);
				}
			}
		}
	}, [lastAttributeFocused, questionId, sortedAttributes]);

	const renderOptionForSelect = useCallback(
		(a, i) => (
			<QuestionOption
				key={`select-option-item-${a.id}-index-${i}`}
				answer={a}
				index={i}
				updateOptionAsset={updateOptionAsset}
				language={languageRef?.current}
				questionId={questionId}
				question={question}
				removeQuestionOption={removeQuestionOption}
				patchQuestion={patchQuestion}
				setIsPlaceholderActive={setIsPlaceholderActive}
				onFocus={onFocus}
				onBlur={onBlur}
				addOptionPlaceholder={addOptionPlaceholder}
				defaultLanguage={defaultLanguage}
				tempTemplate={tempTemplate}
				setTempTemplate={setTempTemplate}
				blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
				answers={answers}
				activeScalePointInputIndex={activeScalePointInputIndex}
				setActiveScalePointInputIndex={setActiveScalePointInputIndex}
				activeAnswer={activeAnswer}
				setActiveAnswer={setActiveAnswer}
				removeClicked={removeClicked}
				setRemoveClicked={setRemoveClicked}
				createOrUpdateQuestionOption={createOrUpdateQuestionOption}
				updateOptionScalePoint={handleOptionScalePointUpdate}
				setOptionScalePointValue={setOptionScalePointValue}
				// setOptionScalePointValue={handleOptionScalePointValue}
				setLastAnswerFocused={setLastAnswerFocused}
				setFocusOnCurrentQuestion={setFocusOnCurrentQuestion}
				setFocusOnNextQuestion={setFocusOnNextQuestion}
				createQuestionOption={createQuestionOption}
				setIsFocusOnLastAnswer={setIsFocusOnLastAnswer}
				filterImportQuestions={filterImportQuestions}
				changeSetting={changeSetting}
				studyType={studyType}
				audience={audience}
				hasQuotas={hasQuotas}
				updateScreeningQuota={updateScreeningQuota}
				focusedQuotaId={focusedQuotaId}
				setFocusedQuotaId={setFocusedQuotaId}
				hasInterlockedQuotas={hasInterlockedQuotas}
				setQuotaError={setQuotaError}
				answerType={answerType}
				showScale={showScale}
				isDefaultScale={isDefaultScale}
			/>
		),
		[
			updateOptionAsset,
			questionId,
			question,
			removeQuestionOption,
			patchQuestion,
			setIsPlaceholderActive,
			onFocus,
			onBlur,
			addOptionPlaceholder,
			defaultLanguage,
			tempTemplate,
			setTempTemplate,
			blockUpdateOrBreakTemplate,
			answers,
			activeScalePointInputIndex,
			activeAnswer,
			removeClicked,
			createOrUpdateQuestionOption,
			handleOptionScalePointUpdate,
			setOptionScalePointValue,
			setFocusOnCurrentQuestion,
			setFocusOnNextQuestion,
			createQuestionOption,
			setIsFocusOnLastAnswer,
			filterImportQuestions,
			changeSetting,
			studyType,
			audience,
			hasQuotas,
			updateScreeningQuota,
			focusedQuotaId,
			hasInterlockedQuotas,
			setQuotaError,
			showScale,
			isDefaultScale,
		],
	);

	const renderSelect = useCallback(
		() => (
			<Droppable droppableId={`option-${questionId.toString()}`} type={`${questionId}-options`}>
				{(provided, snapshot) => (
					<div
						className={`drop-zone ${snapshot.isDragging ? ' drag' : ''}`}
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{answers.map((answer, i) => (
							<Draggable
								key={`select-option-${answer.id}-position-${answer.order}-index-${i}`}
								index={i}
								draggableId={`${questionId}-option-${answer.id}-index${i}`}
							>
								{(draggableProvided, draggableSnapshot) => (
									<div
										className={el(
											'option-item',
											`${answer.type}${draggableSnapshot.isDragging ? ' drag' : ''}`,
										)}
										ref={draggableProvided.innerRef}
										{...draggableProvided.draggableProps}
										{...draggableProvided.dragHandleProps}
									>
										{renderOptionForSelect(answer, i)}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		),
		[answers, questionId, renderOptionForSelect],
	);

	const renderGridOptions = useCallback(
		(a, i) => (
			<QuestionOption
				answer={a}
				index={i}
				updateOptionAsset={updateOptionAsset}
				language={languageRef?.current}
				questionId={questionId}
				question={question}
				removeQuestionOption={removeQuestionOption}
				patchQuestion={patchQuestion}
				setIsPlaceholderActive={setIsPlaceholderActive}
				onFocus={onFocus}
				onBlur={onBlur}
				addOptionPlaceholder={addOptionPlaceholder}
				defaultLanguage={defaultLanguage}
				tempTemplate={tempTemplate}
				setTempTemplate={setTempTemplate}
				blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
				answers={answers}
				activeScalePointInputIndex={activeScalePointInputIndex}
				setActiveScalePointInputIndex={setActiveScalePointInputIndex}
				activeAnswer={activeAnswer}
				setActiveAnswer={setActiveAnswer}
				removeClicked={removeClicked}
				setRemoveClicked={setRemoveClicked}
				createOrUpdateQuestionOption={createOrUpdateQuestionOption}
				updateOptionScalePoint={handleOptionScalePointUpdate}
				setOptionScalePointValue={setOptionScalePointValue}
				// setOptionScalePointValue={handleOptionScalePointValue}
				setLastAnswerFocused={setLastAnswerFocused}
				setFocusOnCurrentQuestion={setFocusOnCurrentQuestion}
				setFocusOnNextQuestion={setFocusOnNextQuestion}
				createQuestionOption={createQuestionOption}
				setIsFocusOnLastAnswer={setIsFocusOnLastAnswer}
				changeSetting={changeSetting}
				studyType={studyType}
				audience={audience}
				hasQuotas={hasQuotas}
				setQuotaError={setQuotaError}
				showScale={showScale}
				isDefaultScale={isDefaultScale}
			/>
		),
		[
			updateOptionAsset,
			questionId,
			question,
			removeQuestionOption,
			patchQuestion,
			setIsPlaceholderActive,
			onFocus,
			onBlur,
			addOptionPlaceholder,
			defaultLanguage,
			tempTemplate,
			setTempTemplate,
			blockUpdateOrBreakTemplate,
			answers,
			activeScalePointInputIndex,
			activeAnswer,
			removeClicked,
			createOrUpdateQuestionOption,
			handleOptionScalePointUpdate,
			setOptionScalePointValue,
			setFocusOnCurrentQuestion,
			setFocusOnNextQuestion,
			createQuestionOption,
			setIsFocusOnLastAnswer,
			changeSetting,
			studyType,
			audience,
			hasQuotas,
			setQuotaError,
			showScale,
			isDefaultScale,
		],
	);
	const renderGridAttributes = (a, i) => (
		<QuestionOption
			answer={a}
			index={i}
			updateOptionAsset={updateAttributeAsset}
			language={languageRef?.current}
			questionId={questionId}
			question={question}
			removeQuestionOption={removeQuestionAttribute}
			removeQuestionAttribute={removeQuestionAttribute}
			patchQuestion={patchQuestion}
			setIsPlaceholderActive={setIsPlaceholderActive}
			onFocus={onFocus}
			onBlur={onBlur}
			addOptionPlaceholder={addAttributePlaceholder}
			defaultLanguage={defaultLanguage}
			tempTemplate={tempTemplate}
			setTempTemplate={setTempTemplate}
			blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
			answers={sortedAttributes}
			activeAnswer={activeAttribute}
			setActiveAnswer={setActiveAttribute}
			removeClicked={removeClicked}
			setRemoveClicked={setRemoveClicked}
			createOrUpdateQuestionOption={createOrUpdateQuestionAttribute}
			setLastAnswerFocused={setLastAttributeFocused}
			setFocusOnCurrentQuestion={setFocusOnCurrentQuestion}
			setFocusOnNextQuestion={setFocusOnNextQuestion}
			createQuestionOption={createQuestionAttribute}
			setIsFocusOnLastAnswer={setIsFocusOnLastAnswer}
			attribute
			studyType={studyType}
			audience={audience}
			hasQuotas={hasQuotas}
			setQuotaError={setQuotaError}
		/>
	);

	const renderOpenEnded = useCallback(
		() => <Textarea className={el('text-area')} value={openAnswer} onChange={setOpenAnswer} disabled />,
		[openAnswer],
	);

	const renderGrid = useCallback(
		() => (
			<div className={el('grid-container')}>
				<div className={el('grid-attributes')}>
					<div className={el('grid-column-titles-wrapper')}>
						<span className={el('grid-column-title')}>Attributes</span>
					</div>

					<Droppable droppableId={`${questionId.toString()}-attributes`} type={`${questionId}-attributes`}>
						{(provided, snapshot) => (
							<div
								className={`drop-zone ${snapshot.isDragging ? ' drag' : ''}`}
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								{sortedAttributes
									.sort((a, b) => {
										if (a.order === b.order) {
											return a.id > b.id ? 1 : -1;
										}
										return a.order > b.order ? 1 : -1;
									})
									.map((a, i) => (
										<Draggable
											key={`grid-attribute-${a.id}`}
											index={i}
											draggableId={`${questionId}-attribute-${i}`}
										>
											{(draggableProvided, draggableSnapshot) => (
												<div
													className={el(
														'attribute-item',
														`${a.type}${draggableSnapshot.isDragging ? ' drag' : ''}`,
													)}
													ref={draggableProvided.innerRef}
													{...draggableProvided.draggableProps}
													{...draggableProvided.dragHandleProps}
												>
													{renderGridAttributes(a, i)}
												</div>
											)}
										</Draggable>
									))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
					<AddAnswerButton
						label="Add attribute"
						question={question}
						addOptionPlaceholder={addAttributePlaceholder}
					/>
				</div>

				<div className={el('grid-options')}>
					<div className={el('grid-column-titles-wrapper')}>
						{showScale && (
							<span className={`${el('scale-column-title')} ${el('grid-column-title')}`}>Scale</span>
						)}

						<span className={el('grid-column-title')}>Answer Options</span>
					</div>

					<Droppable droppableId={questionId.toString()} type={`${questionId}-options`}>
						{(provided, snapshot) => (
							<div
								className={`drop-zone ${snapshot.isDragging ? ' drag' : ''}`}
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								{answers
									.sort((a, b) => {
										if (a.order === b.order) {
											return a.id > b.id ? 1 : -1;
										}
										return a.order > b.order ? 1 : -1;
									})
									.map((answer, i) => (
										<Draggable
											key={`grid-option-${answer.id}`}
											index={i}
											draggableId={`${questionId}-option-${i}`}
										>
											{(draggableProvided, draggableSnapshot) => (
												<div
													className={el(
														'option-item',
														`${answer.type}${draggableSnapshot.isDragging ? ' drag' : ''}`,
													)}
													ref={draggableProvided.innerRef}
													{...draggableProvided.draggableProps}
													{...draggableProvided.dragHandleProps}
												>
													{renderGridOptions(answer, i)}
												</div>
											)}
										</Draggable>
									))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>

					<div className={el('grid-options-section-footer')}>
						{showScale && (
							<ModifyScaleButton
								{...{
									question,
									screeningQuestion,
									tempTemplate,
									batchUpdateAudienceTemplateScreeningQuestionOptions,
								}}
							/>
						)}

						<div className={el('additional-options')}>
							<AddAnswerButton question={question} addOptionPlaceholder={addOptionPlaceholder} />

							<AdditionalQuestionOptionSettings
								question={question}
								loading={loading}
								setLoading={setLoading}
								localSettings={localSettings}
								changeSetting={changeSetting}
							/>
						</div>
					</div>
				</div>
			</div>
		),
		[
			questionId,
			question,
			addAttributePlaceholder,
			addOptionPlaceholder,
			loading,
			setLoading,
			localSettings,
			changeSetting,
			sortedAttributes,
			updateAttributeAsset,
			removeQuestionAttribute,
			patchQuestion,
			setIsPlaceholderActive,
			onFocus,
			onBlur,
			defaultLanguage,
			tempTemplate,
			setTempTemplate,
			blockUpdateOrBreakTemplate,
			activeAttribute,
			removeClicked,
			createOrUpdateQuestionAttribute,
			setFocusOnCurrentQuestion,
			setFocusOnNextQuestion,
			createQuestionAttribute,
			setIsFocusOnLastAnswer,
			studyType,
			audience,
			activeAnswer,
			answers,
			createOrUpdateQuestionOption,
			createQuestionOption,
			removeQuestionOption,
			updateOptionAsset,
			showScale,
			isDefaultScale,
			renderGridOptions,
			screeningQuestion,
		],
	);

	const renderEmoji = useCallback(
		languageCode => (
			<div className={`${tempTemplate && el('custom-target-emoji-options')} ${el('emoji-options')}`}>
				{answers.map(option => {
					let translatedOption = option.translations.find(t => t.languageCode === languageCode);

					if (!translatedOption) {
						[translatedOption] = option.translations;
					}

					let emojiName = option.translations.find(t => t.languageCode === 'en');
					if (!emojiName) {
						emojiName = translatedOption;
					}

					return (
						<Emoji
							key={option.id}
							emojiName={emojiName.label}
							label={translatedOption.label}
							tooltip={translatedOption.label.charAt(0).toUpperCase() + translatedOption.label.slice(1)}
						/>
					);
				})}
			</div>
		),
		[answers, tempTemplate],
	);

	return (
		<div className={audience ? audienceClassName : className}>
			<div className={el('container')}>
				{(answerType === 'single-select' || answerType === 'multi-select' || answerType === 'ranked') &&
					renderSelect()}
				{answerType === 'open-ended' && renderOpenEnded()}
				{answerType === 'grid' && renderGrid()}
				{answerType === 'emoji' && renderEmoji(languageRef?.current)}
			</div>
		</div>
	);
};

QuestionOptions.propTypes = {
	// props
	question: PropTypes.object,
	questionId: PropTypes.any,
	language: PropTypes.any,
	defaultLanguage: PropTypes.any,
	section: PropTypes.any,
	options: PropTypes.any,
	attributes: PropTypes.any,
	answerType: PropTypes.string,
	updateOptionLabel: PropTypes.func,
	updateOptionScalePoint: PropTypes.func,
	setOptionScalePointValue: PropTypes.func,
	updateAttributeLabel: PropTypes.func,
	updateOptionAsset: PropTypes.func,
	updateAttributeAsset: PropTypes.func,
	removeQuestionOption: PropTypes.func,
	removeQuestionAttribute: PropTypes.func,
	addOptionPlaceholder: PropTypes.func,
	addAttributePlaceholder: PropTypes.func,
	addQuestionOption: PropTypes.func,
	addQuestionAttribute: PropTypes.func,
	patchQuestion: PropTypes.func,
	setIsPlaceholderActive: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	tempTemplate: PropTypes.object,
	setTempTemplate: PropTypes.func,
	blockUpdateOrBreakTemplate: PropTypes.func,
	changeSetting: PropTypes.func,
	loading: PropTypes.bool,
	setLoading: PropTypes.func,
	localSettings: PropTypes.any,
	setIsFocusOnLastAnswer: PropTypes.func,
	setFocusOnCurrentQuestion: PropTypes.func,
	setFocusOnNextQuestion: PropTypes.func,
	filterImportQuestions: PropTypes.any,
	studyType: PropTypes.string,
	audience: PropTypes.object,
	updateScreeningOption: PropTypes.func,
	updateScreeningOptionScalePoint: PropTypes.func,
	setScreeningOptionScalePointValue: PropTypes.func,
	updateScreeningAttribute: PropTypes.func,
	hasQuotas: PropTypes.bool,
	updateScreeningQuota: PropTypes.func,
	hasInterlockedQuotas: PropTypes.bool,
	setQuotaError: PropTypes.func,
	saveSetting: PropTypes.func,
	screeningQuestion: PropTypes.bool,
	batchUpdateAudienceTemplateScreeningQuestionOptions: PropTypes.func,
};

export default QuestionOptions;
