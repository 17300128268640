import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import isNil from 'lodash/isNil';
import cn from 'src/utilities/bem-cn';
import './styles.scss';
import Button from '../Button';

const className = 'toggle-switch';
const el = name => cn(className, name);

class ToggleSwitch extends React.Component {
	constructor(props) {
		super(props);
		const { toggleCriteria } = props;
		this.state = {
			toggled: toggleCriteria,
		};
	}

	componentDidUpdate() {
		const { toggleCriteria } = this.props;
		const { toggled } = this.state;
		if (toggled !== toggleCriteria) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ toggled: toggleCriteria });
		}
	}

	onToggle = e => {
		e.stopPropagation();
		const { onToggle, blockUpdateOrBreakTemplate, disabled, toggleCriteria } = this.props;
		const { toggled } = this.state;

		const newValue = !toggled;

		if (!disabled) {
			if (blockUpdateOrBreakTemplate)
				blockUpdateOrBreakTemplate(() => {
					this.setState({ toggled: newValue }, () => {
						if (onToggle) {
							onToggle(e, newValue);
						}
					});
				});
			else
				this.setState({ toggled: newValue }, () => {
					if (onToggle) {
						onToggle(e, newValue);
					}
				});
		}
	};

	render() {
		const {
			text, // HTML content for the tooltip
			disabledText, // HTML content for the tooltip when disabled
			disabled,
		} = this.props;
		const { toggled } = this.state;
		const tooltipText = `${toggled ? 'Turn off' : 'Turn on'}`;

		return (
			<Tooltip
				id={`${className}__tooltip-${Math.ceil(Math.random() * 1000)}`}
				className={`${className}__tooltip`}
				animation="shift"
				animationFill={false}
				trigger="mouseenter"
				position="top"
				theme="basic-text-tooltip"
				disabled={disabled && !disabledText}
				html={<div>{disabled ? disabledText : text || tooltipText}</div>}
			>
				<Button
					type="custom"
					disable={(!isNil(disabled) && disabled).toString()}
					className={`${className} ${!toggled ? '' : 'toggled'} ${disabled ? 'disabled' : ''}`}
					onClick={this.onToggle}
				>
					<div className={el('circle')}></div>
				</Button>
			</Tooltip>
		);
	}
}

ToggleSwitch.propTypes = {
	onToggle: PropTypes.func,
	text: PropTypes.any,
	disabledText: PropTypes.any,
	toggleCriteria: PropTypes.any,
	blockUpdateOrBreakTemplate: PropTypes.func,
	disabled: PropTypes.bool,
};

export default ToggleSwitch;
