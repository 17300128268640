import axios from '../utilities/axios';
import * as misc from '../utilities/misc';

const getData = ({ data }) => data;

const API_PATH = `${process.env.apiPath}`;

const getStudyProducts = (studyId, languageCode) =>
	axios.get(`${API_PATH}/studies/${studyId}/products?${misc.toURL({ languageCode })}`);

const getPublicStudyProducts = (studyId, languageCode) =>
	axios.get(`${API_PATH}/public/studies/${studyId}/products?${misc.toURL({ languageCode })}`);

const create = (studyId, data) => axios.post(`${API_PATH}/studies/${studyId}/products`, data).then(getData);

const createBulk = data => axios.post(`${API_PATH}/bulk/products`, data).then(getData);

const get = (studyId, productId, mode) =>
	axios.get(`${API_PATH}/studies/${studyId}/products/${productId}?mode=${mode}`).then(getData);

const update = (studyId, productId, data) =>
	axios.put(`${API_PATH}/studies/${studyId}/products/${productId}`, data).then(getData);

const patch = (studyId, productId, data) =>
	axios.patch(`${API_PATH}/studies/${studyId}/products/${productId}`, data).then(getData);

const postIdeasFromAI = (studyId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/products/import-from-ai`, data).then(getData);

const deleteProduct = (studyId, sectionId, productId) =>
	// TODO: Implement the following route once deleteProducts gets merged, must be localProductId, make sure we are using that.
	axios.delete(`${API_PATH}/studies/${studyId}/products/${productId}/section/${sectionId}`).then(getData);
// axios.delete(`${API_PATH}/studies/${studyId}/sections/${sectionId}/items/${productId}`).then(getData);

const duplicate = (id, count) => axios.post(`${API_PATH}/products/${id}`, { totalDuplicates: count });

function removeProductTag(studyId, localProductId, tagId) {
	return axios.delete(`${API_PATH}/studies/${studyId}/products/${localProductId}/tag/${tagId}`);
}

export default {
	getStudyProducts,
	getPublicStudyProducts,
	create,
	createBulk,
	get,
	update,
	patch,
	postIdeasFromAI,
	duplicate,
	delete: deleteProduct,
	removeProductTag,
};
