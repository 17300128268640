const prefix = 'manage-blueprints';

export * from './sections';
export * from './questions';

/**
 * This is a new action for the re-worked combined Template area
 */
export const PERFORM_NEW_INITIAL_FETCH = `${prefix}/PERFORM_NEW_INITIAL_FETCH`;
export const performNewInitialFetch = (id) => ({ type: PERFORM_NEW_INITIAL_FETCH, payload: { id } });

export const ADD_USER = `${prefix}/ADD_USER`;
export const addUser = (emails, role) => ({ type: ADD_USER, payload: { emails, role } });

export const SET_ADD_USER_LOADING = `${prefix}/SET_ADD_USER_LOADING`;
export const setAddUserLoading = (isLoading) => ({ type: SET_ADD_USER_LOADING, payload: { isLoading } });

export const SET_ADD_USER_COMPLETE = `${prefix}/SET_ADD_USER_COMPLETE`;
export const setAddUserComplete = (complete) => ({ type: SET_ADD_USER_COMPLETE, payload: { complete } });

export const FETCH_USERS = `${prefix}/FETCH_USERS`;
export const fetchUsers = () => ({ type: FETCH_USERS });

export const SET_USERS = `${prefix}/SET_USERS`;
export const setUsers = (users) => ({ type: SET_USERS, payload: { users } });

export const SET_STUDY = `${prefix}/SET_STUDY`;
export const setStudy = (study) => ({ type: SET_STUDY, payload: { study } });

export const SET_SAVE_BUTTON_STATE = `${prefix}/SET_SAVE_BUTTON_STATE`;
export const setSaveButtonState = (saveButtonState) => ({
	type: SET_SAVE_BUTTON_STATE,
	payload: { saveButtonState },
});

export const SET_SAVE_BUTTON_TEXT = `${prefix}/SET_SAVE_BUTTON_TEXT`;
export const setSaveButtonText = (saveButtonText) => ({
	type: SET_SAVE_BUTTON_TEXT,
	payload: { saveButtonText },
});

export const SET_PRODUCTS = `${prefix}/SET_PRODUCTS`;
export const setProducts = (products) => ({
	type: SET_PRODUCTS,
	payload: { products },
});

export const SET_LANGUAGES = `${prefix}/SET_LANGUAGES`;
export const setLanguages = (languages) => ({
	type: SET_LANGUAGES,
	payload: { languages },
});

export const SET_LANGUAGE = `${prefix}/SET_LANGUAGE`;
export const setLanguage = (language) => ({
	type: SET_LANGUAGE,
	payload: { language },
});

export const SET_CATEGORIES = `${prefix}/SET_CATEGORIES`;
export const setCategories = (categories) => ({
	type: SET_CATEGORIES,
	payload: { categories },
});

export const SET_PRODUCT_TAGS = `${prefix}/SET_PRODUCT_TAGS`;
export const setProductTags = (productTags) => ({
	type: SET_PRODUCT_TAGS,
	payload: { productTags },
});

export const SET_PRODUCT_MODAL = `${prefix}/SET_PRODUCT_MODAL`;
export const setProductModal = (productModal) => ({
	type: SET_PRODUCT_MODAL,
	payload: { productModal },
});

export const SET_QUESTION_MODAL = `${prefix}/SET_QUESTION_MODAL`;
export const setQuestionModal = (questionModal) => ({
	type: SET_QUESTION_MODAL,
	payload: { questionModal },
});

export const SET_QUESTIONS = `${prefix}/SET_QUESTIONS`;
export const setQuestions = (questions) => ({
	type: SET_QUESTIONS,
	payload: { questions },
});

export const CHANGE_PRODUCT_MODAL = `${prefix}/CHANGE_PRODUCT_MODAL`;
export const changeProductModal = (productModal) => ({
	type: CHANGE_PRODUCT_MODAL,
	payload: { productModal },
});

export const SET_IMPORT_IMAGES_MODAL = `${prefix}/SET_IMPORT_IMAGES_MODAL`;
export const setImportImagesModal = (importImagesModal) => ({
	type: SET_IMPORT_IMAGES_MODAL,
	payload: { importImagesModal },
});

export const SET_IMPORT_PRODUCTS_MODAL = `${prefix}/SET_IMPORT_PRODUCTS_MODAL`;
export const setImportProductsModal = (importProductsModal) => ({
	type: SET_IMPORT_PRODUCTS_MODAL,
	payload: { importProductsModal },
});

export const SET_EXPORT_CONFIRMATION_MODAL = `${prefix}/SET_EXPORT_CONFIRMATION_MODAL`;
export const setExportConfirmationModal = (exportConfirmationModal) => ({
	type: SET_EXPORT_CONFIRMATION_MODAL,
	payload: { exportConfirmationModal },
});

export const FETCH_CATEGORIES = `${prefix}/FETCH_CATEGORIES`;
export const fetchCategories = () => ({ type: FETCH_CATEGORIES });

export const FETCH_LANGUAGES = `${prefix}/FETCH_LANGUAGES`;
export const fetchLanguages = () => ({ type: FETCH_LANGUAGES });

export const FETCH_STUDY = `${prefix}/FETCH_STUDY`;
export const fetchStudy = (id) => ({ type: FETCH_STUDY, payload: { id } });

export const FETCH_STUDY_FOR_TRANSLATION = `${prefix}/FETCH_STUDY_FOR_TRANSLATION`;
export const fetchStudyForTranslation = (id) => ({ type: FETCH_STUDY_FOR_TRANSLATION, payload: { id } });

export const FETCH_PRODUCTS = `${prefix}/FETCH_PRODUCTS`;
export const fetchProducts = (studyId) => ({
	type: FETCH_PRODUCTS,
	payload: { studyId },
});

export const FETCH_QUESTION = `${prefix}/FETCH_QUESTION`;
export const fetchQuestion = (studyId, questionId) => ({
	type: FETCH_QUESTION,
	payload: { studyId, questionId },
});

export const FETCH_QUESTION_AND_DUPLICATE = `${prefix}/FETCH_QUESTION_AND_DUPLICATE`;
export const fetchQuestionAndDuplicate = (studyId, questionId) => ({
	type: FETCH_QUESTION_AND_DUPLICATE,
	payload: { studyId, questionId },
});

export const FETCH_QUESTIONS = `${prefix}/FETCH_QUESTIONS`;
export const fetchQuestions = (studyId) => ({
	type: FETCH_QUESTIONS,
	payload: { studyId },
});

export const UPDATE_STUDY = `${prefix}/UPDATE_STUDY`;
export const updateStudy = (data) => ({ type: UPDATE_STUDY, payload: data });

export const STUDY_UPDATED = `${prefix}/STUDY_UPDATED`;
export const studyUpdated = (study) => ({
	type: STUDY_UPDATED,
	payload: { study },
});

export const CREATE_PRODUCT = `${prefix}/CREATE_PRODUCT`;
export const createProduct = (studyId, data) => ({
	type: CREATE_PRODUCT,
	payload: { studyId, data },
});

export const UPDATE_PRODUCT = `${prefix}/UPDATE_PRODUCT`;
export const updateProduct = (studyId, productId, data) => ({
	type: UPDATE_PRODUCT,
	payload: { studyId, productId, data },
});

// NEW IDEA CALLS
export const POST_IDEA = `${prefix}/POST_IDEA`;
export const postIdea = (studyId, sectionId, data, shouldClose) => ({
	type: POST_IDEA,
	payload: { studyId, sectionId, data, shouldClose },
});

export const PATCH_IDEA = `${prefix}/PATCH_IDEA`;
export const patchIdea = (studyId, sectionId, productId, data, shouldClose) => ({
	type: PATCH_IDEA,
	payload: { studyId, sectionId, productId, data, shouldClose },
});
//

export const PATCH_PRODUCT = `${prefix}/PATCH_PRODUCT`;
export const patchProduct = (studyId, productId, data) => ({
	type: PATCH_PRODUCT,
	payload: { studyId, productId, data },
});

export const DELETE_PRODUCT = `${prefix}/DELETE_PRODUCT`;
export const deleteProduct = (studyId, sectionId, productId) => ({
	type: DELETE_PRODUCT,
	payload: { studyId, sectionId, productId },
});

export const CREATE_QUESTION = `${prefix}/CREATE_QUESTION`;
export const createQuestion = (data) => ({
	type: CREATE_QUESTION,
	payload: { data },
});

export const UPDATE_QUESTION = `${prefix}/UPDATE_QUESTION`;
export const updateQuestion = (studyId, questionId, data) => ({
	type: UPDATE_QUESTION,
	payload: { studyId, questionId, data },
});

export const PATCH_QUESTION_OPTION = `${prefix}/PATCH_QUESTION_OPTION`;
export const patchQuestionOption = (studyId, questionId, optionId, data) => ({
	type: PATCH_QUESTION_OPTION,
	payload: { studyId, questionId, optionId, data },
});

export const DELETE_QUESTION = `${prefix}/DELETE_QUESTION`;
export const deleteQuestion = (studyId, id) => ({
	type: DELETE_QUESTION,
	payload: { studyId, id },
});

export const DUPLICATE_PRODUCT = `${prefix}/DUPLICATE_PRODUCT`;
export const duplicateProduct = (studyId, id, count) => ({
	type: DUPLICATE_PRODUCT,
	payload: { studyId, id, count },
});

export const IMPORT_IMAGES = `${prefix}/IMPORT_IMAGES`;
export const importImages = (studyId, sectionId, fieldTarget, files) => ({
	type: IMPORT_IMAGES,
	payload: { studyId, sectionId, fieldTarget, files },
});

export const IMPORT_PRODUCTS = `${prefix}/IMPORT_PRODUCTS`;
export const importProducts = (studyId, files, sectionId = 0) => ({
	type: IMPORT_PRODUCTS,
	payload: { studyId, files, sectionId },
});

export const IMPORT_FILTERS = `${prefix}/IMPORT_FILTERS`;
export const importFilters = (studyId, files) => ({
	type: IMPORT_FILTERS,
	payload: { studyId, files },
});

export const DOWNLOAD_TEMPLATE = `${prefix}/DOWNLOAD_TEMPLATE`;
export const downloadTemplate = (studyId, sectionId) => ({
	type: DOWNLOAD_TEMPLATE,
	payload: { studyId, sectionId },
});

export const DOWNLOAD_STATEMENT_CSV = `${prefix}/DOWNLOAD_STATEMENT_CSV`;
export const downloadStatementCsv = (studyId) => ({
	type: DOWNLOAD_STATEMENT_CSV,
	payload: { studyId },
});

export const DOWNLOAD_QUESTION_CSV = `${prefix}/DOWNLOAD_QUESTION_CSV`;
export const downloadQuestionCsv = (studyId) => ({
	type: DOWNLOAD_QUESTION_CSV,
	payload: { studyId },
});

export const DOWNLOAD_SWIPE_CSV = `${prefix}/DOWNLOAD_SWIPE_CSV`;
export const downloadSwipeCsv = (studyId) => ({
	type: DOWNLOAD_SWIPE_CSV,
	payload: { studyId },
});

export const IMPORT_CSV_FILES = `${prefix}/IMPORT_CSV_FILES`;
export const importCsvFiles = (studyId, sectionId, files) => ({
	type: IMPORT_CSV_FILES,
	payload: { studyId, sectionId, files },
});

export const SET_MANAGE_LANGUAGES_MODAL = `${prefix}/SET_MANAGE_LANGUAGES_MODAL`;
export const setManageLanguagesModal = (manageLanguagesModal) => ({
	type: SET_MANAGE_LANGUAGES_MODAL,
	payload: manageLanguagesModal,
});

export const SET_TRANSLATIONS_MODAL = `${prefix}/SET_TRANSLATIONS_MODAL`;
export const setTranslationsModal = (translationsModal) => ({
	type: SET_TRANSLATIONS_MODAL,
	payload: translationsModal,
});

export const SET_TRANSLATION_PREVIEW = `${prefix}/SET_TRANSLATION_PREVIEW`;
export const setTranslationPreview = (translationData) => ({
	type: SET_TRANSLATION_PREVIEW,
	payload: translationData,
});

export const SET_DISCARD_PRODUCT_CHANGES_MODAL = `${prefix}/SET_DISCARD_PRODUCT_CHANGES_MODAL`;
export const setDiscardProductChangesModal = (discardProductChangesModal) => ({
	type: SET_DISCARD_PRODUCT_CHANGES_MODAL,
	payload: discardProductChangesModal,
});

export const EXPORT_PRODUCTS = `${prefix}/EXPORT_PRODUCTS`;
export const exportProducts = (studyId, sectionId, showModal = true) => ({
	type: EXPORT_PRODUCTS,
	payload: { studyId, sectionId, showModal },
});

export const EXPORT_RESPONSES_WITH_ANSWERS = `${prefix}/EXPORT_RESPONSES_WITH_ANSWERS`;
export const exportResponsesWithAnswers = (studyId) => ({
	type: EXPORT_RESPONSES_WITH_ANSWERS,
	payload: { studyId },
});
export const EXPORT_RESPONSES = `${prefix}/EXPORT_RESPONSES`;
export const exportResponses = (studyId) => ({
	type: EXPORT_RESPONSES,
	payload: { studyId },
});

export const EXPORT_INTEREST = `${prefix}/EXPORT_INTEREST`;
export const exportInterest = (studyId) => ({
	type: EXPORT_INTEREST,
	payload: { studyId },
});

export const EXPORT_COMMITMENT = `${prefix}/EXPORT_COMMITMENT`;
export const exportCommitment = (studyId) => ({
	type: EXPORT_COMMITMENT,
	payload: { studyId },
});

export const EXPORT_QUESTION_ANSWERS = `${prefix}/EXPORT_QUESTION_ANSWERS`;
export const exportQuestionAnswers = (studyId, studyName, questionType, questionId, filters) => ({
	type: EXPORT_QUESTION_ANSWERS,
	payload: { studyId, studyName, questionType, questionId, filters },
});

export const SWAP_QUESTIONS = `${prefix}/SWAP_QUESTIONS`;
export const swapQuestions = (studyId, q0, q1) => ({
	type: SWAP_QUESTIONS,
	payload: { studyId, q0, q1 },
});

export const PERFORM_INITIAL_FETCH = `${prefix}/PERFORM_INITIAL_FETCH`;
export const performInitialFetch = (id, canUpdateStudy = false, sectionId = false) => ({
	type: PERFORM_INITIAL_FETCH,
	payload: { id, canUpdateStudy, sectionId },
});

export const DRAFT_STUDY = `${prefix}/DRAFT_STUDY`;
export const draftStudy = (id) => ({ type: DRAFT_STUDY, payload: { id } });

export const PUBLISH_STUDY = `${prefix}/PUBLISH_STUDY`;
export const publishStudy = (id) => ({ type: PUBLISH_STUDY, payload: { id } });

export const CLOSE_STUDY = `${prefix}/CLOSE_STUDY`;
export const closeStudy = (id) => ({ type: CLOSE_STUDY, payload: { id } });

export const SET_IMPORT_RESPONDENTS_MODAL = `${prefix}/SET_IMPORT_RESPONDENTS_MODAL`;
export const setImportRespondentsModal = (importRespondentsModal) => ({
	type: SET_IMPORT_RESPONDENTS_MODAL,
	payload: { importRespondentsModal },
});

export const IMPORT_RESPONSES_WITH_ANSWERS = `${prefix}/IMPORT_RESPONSES_WITH_ANSWERS`;
export const importResponsesWithAnswers = (studyId, files) => ({
	type: IMPORT_RESPONSES_WITH_ANSWERS,
	payload: { studyId, files },
});

export const PATCH_STUDY = `${prefix}/PATCH_STUDY`;
export const patchStudy = (id, data) => ({ type: PATCH_STUDY, payload: { id, data } });

export const PATCH_STUDY_SETTINGS = `${prefix}/PATCH_STUDY_SETTINGS`;
export const patchStudySettings = (id, data) => ({ type: PATCH_STUDY_SETTINGS, payload: { id, data } });

/* Access stuff */

export const SET_ACCESS_MODAL = `${prefix}/SET_ACCESS_MODAL`;
export const setAccessModal = (accessModal) => ({ type: SET_ACCESS_MODAL, payload: { accessModal } });

export const SET_ACCESS_ENTRIES = `${prefix}/SET_ACCESS_ENTRIES`;
export const setAccessEntries = (accessEntries) => ({ type: SET_ACCESS_ENTRIES, payload: { accessEntries } });

export const SET_POSSIBLE_ENTRIES = `${prefix}/SET_POSSIBLE_ENTRIES`;
export const setPossibleEntries = (possibleEntries) => ({ type: SET_POSSIBLE_ENTRIES, payload: { possibleEntries } });

export const SET_ACCESS_COMMON_ERROR = `${prefix}/SET_ACCESS_COMMON_ERROR`;
export const setAccessCommonError = (accessCommonError) => ({
	type: SET_ACCESS_COMMON_ERROR,
	payload: { accessCommonError },
});

export const FETCH_ACCESS_ENTRIES = `${prefix}/FETCH_ACCESS_ENTRIES`;
export const fetchAccessEntries = () => ({ type: FETCH_ACCESS_ENTRIES });

export const FETCH_POSSIBLE_ENTRIES = `${prefix}/FETCH_POSSIBLE_ENTRIES`;
export const fetchPossibleEntries = () => ({ type: FETCH_POSSIBLE_ENTRIES });

export const DELETE_ACCESS_ENTRY = `${prefix}/DELETE_ACCESS_ENTRY`;
export const deleteAccessEntry = (uuid) => ({ type: DELETE_ACCESS_ENTRY, payload: { uuid } });

export const CREATE_ACCESS_ENTRIES = `${prefix}/CREATE_ACCESS_ENTRIES`;
export const createAccessEntries = (uuids) => ({ type: CREATE_ACCESS_ENTRIES, payload: { uuids } });

export const FETCH_GEOGRAPHIES = `${prefix}/FETCH_GEOGRAPHIES`;
export const fetchGeographies = () => ({ type: FETCH_GEOGRAPHIES });

export const SET_GEOGRAPHIES = `${prefix}/SET_GEOGRAPHIES`;
export const setGeographies = (geographies) => ({ type: SET_GEOGRAPHIES, payload: { geographies } });

export const SET_UPLOAD_IMAGE_STATUS = `${prefix}/SET_UPLOAD_IMAGE_STATUS`;
export const setUploadImageStatus = (statuses) => ({ type: SET_UPLOAD_IMAGE_STATUS, payload: { statuses } });

export const SET_INDIV_UPLOAD_IMAGE_STATUS = `${prefix}/SET_INDIV_UPLOAD_IMAGE_STATUS`;
export const setIndivUploadImageStatus = (index, status, message = '') => ({
	type: SET_INDIV_UPLOAD_IMAGE_STATUS,
	payload: { index, status, message },
});

export const SET_ROLES = `${prefix}/SET_ROLES`;
export const setRoles = (roles) => ({ type: SET_ROLES, payload: { roles } });

export const FETCH_ROLES = `${prefix}/FETCH_ROLES`;
export const fetchRoles = () => ({ type: FETCH_ROLES });

export const SET_SHARE_STUDY_MODAL = `${prefix}/SET_SHARE_STUDY_MODAL`;
export const setShareStudyModal = (modal) => ({ type: SET_SHARE_STUDY_MODAL, payload: { modal } });

export const SET_GOOGLE_TRANSLATE_MODAL = `${prefix}/SET_GOOGLE_TRANSLATE_MODAL`;
export const setShowGoogleTranslateModal = (showGoogleTranslateModal) => ({
	type: SET_GOOGLE_TRANSLATE_MODAL,
	payload: { showGoogleTranslateModal },
});

export const GOOGLE_TRANSLATE_LANGUAGE = `${prefix}/GOOGLE_TRANSLATE_LANGUAGE`;
export const googleTranslateLanguage = (translateData) => ({
	type: GOOGLE_TRANSLATE_LANGUAGE,
	payload: { translateData },
});

export const GOOGLE_TRANSLATE_PREVIEW = `${prefix}/GOOGLE_TRANSLATE_PREVIEW`;
export const googleTranslatePreview = (translateData) => ({
	type: GOOGLE_TRANSLATE_PREVIEW,
	payload: { translateData },
});

/*
 * Section Name Actions
 */
export const PATCH_SECTION_NAME = `${prefix}/PATCH_SECTION_NAME`;
export const patchSectionName = (studyId, sectionId, statementName) => ({
	type: PATCH_SECTION_NAME,
	payload: { studyId, sectionId, statementName },
});

/*
 * Statement Actions
 */
export const POST_STATEMENT = `${prefix}/POST_STATEMENT`;
export const postStatement = (statementData) => ({
	type: POST_STATEMENT,
	payload: { statementData },
});

export const PATCH_STATEMENT = `${prefix}/PATCH_STATEMENT`;
export const patchStatement = (statementId, statementData) => ({
	type: PATCH_STATEMENT,
	payload: { statementId, statementData },
});

/*
 * Lnk Routing Actions
 */
export const POST_LINK_ROUTING = `${prefix}/POST_LINK_ROUTING`;
export const postLinkRouting = (linkRoutingData) => ({
	type: POST_LINK_ROUTING,
	payload: { linkRoutingData },
});

export const PATCH_LINK_ROUTING = `${prefix}/PATCH_LINK_ROUTING`;
export const patchLinkRouting = (linkRoutingId, linkRoutingData) => ({
	type: PATCH_LINK_ROUTING,
	payload: { linkRoutingId, linkRoutingData },
});

/*
 * Device Preview Mode Actions
 */

export const SET_DEVICE_PREVIEW_MODE = `${prefix}/SET_DEVICE_PREVIEW_MODE`;
export const setDevicePreviewMode = (devicePreviewMode) => ({
	type: SET_DEVICE_PREVIEW_MODE,
	payload: {
		devicePreviewMode,
	},
});

export const SHOW_DEVICE_PREVIEW_MODE = `${prefix}/SHOW_DEVICE_PREVIEW_MODE`;
export const setShowDevicePreviewMode = (showDevicePreviewMode) => ({
	type: SHOW_DEVICE_PREVIEW_MODE,
	payload: {
		showDevicePreviewMode,
	},
});

export const SET_DEVICE_PREVIEW_URL = `${prefix}/SET_DEVICE_PREVIEW_URL`;
export const setDevicePreviewUrl = (devicePreviewUrl) => ({
	type: SET_DEVICE_PREVIEW_URL,
	payload: {
		devicePreviewUrl,
	},
});

export const SET_SHOW_DEVICE_PREVIEW_IFRAME_BLOCKER = `${prefix}/SET_SHOW_DEVICE_PREVIEW_IFRAME_BLOCKER`;
export const setShowDevicePreviewIframeBlocker = (showDevicePreviewIframeBlocker) => ({
	type: SET_SHOW_DEVICE_PREVIEW_IFRAME_BLOCKER,
	payload: {
		showDevicePreviewIframeBlocker,
	},
});

export const SET_PRODUCT_SECTION_VIEW_MODE = `${prefix}/SET_PRODUCT_SECTION_VIEW_MODE`;
export const setProductSectionViewMode = (productSectionViewMode) => ({
	type: SET_PRODUCT_SECTION_VIEW_MODE,
	payload: { productSectionViewMode },
});

export const REORDER_STUDY_TRANSLATIONS = `${prefix}/REORDER_STUDY_TRANSLATIONS`;
export const reorderStudyTranslations = (newOrder) => ({
	type: REORDER_STUDY_TRANSLATIONS,
	payload: { newOrder },
});

// Country & Language
export const FETCH_COUNTRIES = `${prefix}/FETCH_COUNTRIES`;
export const fetchCountries = () => ({ type: FETCH_COUNTRIES });

export const SET_COUNTRIES = `${prefix}/SET_COUNTRIES`;
export const setCountries = (countries) => ({ type: SET_COUNTRIES, payload: { countries } });

export const FETCH_COUNTRY_LANGUAGES = `${prefix}/FETCH_COUNTRY_LANGUAGES`;
export const fetchCountryLanguages = (countryId) => ({ type: FETCH_COUNTRY_LANGUAGES, payload: { countryId } });

export const SET_COUNTRY_LANGUAGES = `${prefix}/SET_COUNTRY_LANGUAGES`;
export const setCountryLanguages = (countryLanguages) => ({
	type: SET_COUNTRY_LANGUAGES,
	payload: { countryLanguages },
});

export const FETCH_ALL_PRODUCTS = `${prefix}/FETCH_ALL_PRODUCTS`;
export const fetchAllProducts = (studyId) => ({
	type: FETCH_ALL_PRODUCTS,
	payload: { studyId },
});

export const SET_PRODUCT_LIBRARY = `${prefix}/SET_PRODUCT_LIBRARY`;
export const setProductsLibrary = (products) => ({
	type: SET_PRODUCT_LIBRARY,
	payload: { products },
});

export const ADD_IDEA_INTO_SECTION = `${prefix}/ADD_IDEA_INTO_SECTION`;
export const addIdeaIntoSectionBlueprint = (studyId, sectionId, productIds) => ({
	type: ADD_IDEA_INTO_SECTION,
	payload: { studyId, sectionId, productIds },
});

export const UPDATE_BLUEPRINT_SCREEN_LAYOUT = `${prefix}/UPDATE_BLUEPRINT_SCREEN_LAYOUT`;
export const updateBlueprintScreenLayout = (studyId, sectionId, layout) => ({
	type: UPDATE_BLUEPRINT_SCREEN_LAYOUT,
	payload: { studyId, sectionId, layout },
});

export const SET_LENGTH_OF_INTERVIEW = `${prefix}/SET_LENGTH_OF_INTERVIEW`;
export const setLengthOfInterview = (lengthOfInterview) => ({
	type: SET_LENGTH_OF_INTERVIEW,
	payload: { lengthOfInterview },
});

export const FETCH_LENGTH_OF_INTERVIEW = `${prefix}/FETCH_LENGTH_OF_INTERVIEW`;
export const fetchStudyLoi = (studyId) => ({
	type: FETCH_LENGTH_OF_INTERVIEW,
	payload: { studyId },
});

/* Audience Related */

export const FETCH_ALL_AUDIENCE_COLLECTION = `${prefix}/FETCH_ALL_AUDIENCE_COLLECTION`;
export const fetchAllAudienceCollection = studyId => ({
	type: FETCH_ALL_AUDIENCE_COLLECTION,
	payload: studyId,
});

export const FETCH_AUDIENCE_COLLECTION = `${prefix}/FETCH_AUDIENCE_COLLECTION`;
export const fetchAudienceCollection = (studyId, audienceCollectionId, setLoading = true) => ({
	type: FETCH_AUDIENCE_COLLECTION,
	payload: { studyId, audienceCollectionId, setLoading },
});

export const FETCH_GROUP = `${prefix}/FETCH_GROUP`;
export const fetchGroup = (studyId, audienceUuid) => ({ type: FETCH_GROUP, payload: { studyId, audienceUuid } });

export const SET_AUDIENCES = `${prefix}/SET_AUDIENCES`;
export const setAudiences = audiences => ({
	type: SET_AUDIENCES,
	payload: { audiences },
});

export const SET_GROUP = `${prefix}/SET_GROUP`;
export const setGroup = group => ({
	type: SET_GROUP,
	payload: group,
});

export const SET_AUDIENCE_COLLECTION = `${prefix}/SET_AUDIENCE_COLLECTION`;
export const setAudienceCollection = collection => ({
	type: SET_AUDIENCE_COLLECTION,
	payload: collection,
});

export const SET_AUDIENCE_COLLECTIONS = `${prefix}/SET_AUDIENCE_COLLECTIONS`;
export const setAudienceCollections = collections => ({
	type: SET_AUDIENCE_COLLECTIONS,
	payload: collections,
});

export const SET_AUDIENCE_TEMPLATE_COLLECTION = `${prefix}/SET_AUDIENCE_TEMPLATE_COLLECTION`;
export const setAudienceTemplateCollection = audienceTemplateCollection => ({
	type: SET_AUDIENCE_TEMPLATE_COLLECTION,
	payload: { audienceTemplateCollection },
});

export const SET_AUDIENCE_EDIT_TEMPLATE = `${prefix}/SET_AUDIENCE_EDIT_TEMPLATE`;
export const setAudienceEditTemplate = audienceTemplate => ({
	type: SET_AUDIENCE_EDIT_TEMPLATE,
	payload: audienceTemplate,
});

export const CREATE_GROUP_FROM_SCRATCH = `${prefix}/CREATE_GROUP_FROM_SCRATCH`;
export const createGroupFromScratch = (studyId, data) => ({
	type: CREATE_GROUP_FROM_SCRATCH,
	payload: { studyId, data },
});

export const CHANGE_SCREENING_QUESTION_VISIBILITY = `${prefix}/CHANGE_SCREENING_QUESTION_VISIBILITY`;
export const changeScreeningQuestionVisibility = (
	studyId,
	questionId,
	isGlobalQualifier,
	visibleForAudienceIds = null,
	isTemplate = false,
) => ({
	type: CHANGE_SCREENING_QUESTION_VISIBILITY,
	payload: { studyId, questionId, isGlobalQualifier, visibleForAudienceIds, isTemplate  },
});

export const ADD_QUESTION_FROM_LIBRARY = `${prefix}/ADD_QUESTION_FROM_LIBRARY`;
export const addQuestionFromLibrary = (studyId, questionLibraryId, audienceCollectionId, sortOrder) => ({
	type: ADD_QUESTION_FROM_LIBRARY,
	payload: { studyId, questionLibraryId, audienceCollectionId, sortOrder },
});

export const UPDATE_AUDIENCE_COLLECTIONS_SINGLE_AUDIENCE = `${prefix}/UPDATE_AUDIENCE_COLLECTIONS_SINGLE_AUDIENCE`;
export const updateAudienceCollectionsSingleAudience = (groupId, groupData) => ({
	type: UPDATE_AUDIENCE_COLLECTIONS_SINGLE_AUDIENCE,
	payload: { groupId, groupData },
});

export const SET_STOP_AUDIENCE_MODAL = `${prefix}/SET_STOP_AUDIENCE_MODAL`;
export const setStopAudienceModal = stopAudienceModal => ({
	type: SET_STOP_AUDIENCE_MODAL,
	payload: { stopAudienceModal },
});

export const STOP_AUDIENCE = `${prefix}/STOP_AUDIENCE`;
export const stopAudience = (studyId, audienceUuid) => ({
	type: STOP_AUDIENCE,
	payload: { studyId, audienceUuid },
});

export const SET_LAUNCH_AUDIENCE = `${prefix}/SET_LAUNCH_AUDIENCE`;
export const setLaunchAudienceFeedback = response => ({
	type: SET_LAUNCH_AUDIENCE,
	payload: { response },
});

export const VALIDATE_STUDY = `${prefix}/VALIDATE_STUDY`;
export const validateStudy = studyId => ({
	type: VALIDATE_STUDY,
	payload: { studyId },
});

export const SET_STUDY_VALIDATIONS = `${prefix}/SET_STUDY_VALIDATIONS`;
export const setStudyValidations = validations => ({
	type: SET_STUDY_VALIDATIONS,
	payload: { validations },
});

export const CREATE_AUDIENCE = `${prefix}/CREATE_AUDIENCE`;
export const createAudience = (studyId, audienceData) => ({
	type: CREATE_AUDIENCE,
	payload: { studyId, audienceData },
});

export const SET_AUDIENCE_MODAL = `${prefix}/SET_AUDIENCE_MODAL`;
export const setAudienceModal = audienceModal => ({ type: SET_AUDIENCE_MODAL, payload: { audienceModal } });

export const FETCH_AUDIENCES = `${prefix}/FETCH_AUDIENCES`;
export const fetchAudiences = studyId => ({ type: FETCH_AUDIENCES, payload: { studyId } });

export const SET_AUDIENCE_PRICE_CHANGES_INITIAL = `${prefix}/SET_AUDIENCE_PRICE_CHANGES_INITIAL`;
export const setAudiencePriceChangedInitial = () => ({
	type: SET_AUDIENCE_PRICE_CHANGES_INITIAL,
});

export const SET_AUDIENCE = `${prefix}/SET_AUDIENCE`;
export const setAudience = audience => ({
	type: SET_AUDIENCE,
	payload: { audience },
});

export const SET_AUDIENCES_SEEN_BANNER = `${prefix}/SET_AUDIENCES_SEEN_BANNER`;
export const setAudienceSeenBanner = audiencesSeenBanner => ({
	type: SET_AUDIENCES_SEEN_BANNER,
	payload: audiencesSeenBanner,
});

export const DELETE_AUDIENCE = `${prefix}/DELETE_AUDIENCE`;
export const deleteAudience = (studyId, audienceUuid) => ({
	type: DELETE_AUDIENCE,
	payload: { studyId, audienceUuid },
});

export const FETCH_AUDIENCE_TEMPLATES = `${prefix}/FETCH_AUDIENCE_TEMPLATES`;
export const fetchAudienceTemplates = (search, refetch = false, forceRefetch = false) => ({
	type: FETCH_AUDIENCE_TEMPLATES,
	payload: { search, refetch, forceRefetch },
});

export const SET_AUDIENCE_TEMPLATES = `${prefix}/SET_AUDIENCE_TEMPLATES`;
export const setAudienceTemplates = audienceTemplates => ({
	type: SET_AUDIENCE_TEMPLATES,
	payload: { audienceTemplates },
});

export const SET_EDIT_AUDIENCE_MODAL = `${prefix}/SET_EDIT_AUDIENCE_MODAL`;
export const setEditAudienceModal = editAudienceModal => ({
	type: SET_EDIT_AUDIENCE_MODAL,
	payload: { editAudienceModal },
});
