import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Button from '../Button';
import './styles.scss';

const className = 'add-new-item-button';
const el = (name, mod) => cn(className, name, mod);

const AddNewItemButton = ({ tag, label, onClick, disabled = false }) => (
	<Button
		label={label}
		type="text"
		className={el(
			'button no-hover-effect text',
			[disabled ? `${className}-disabled` : `${className}`, tag ? 'tag' : 'green'].join(' '),
		)}
		onClick={onClick}
		icon="add_circle"
		iconSize="default"
		state={`${disabled ? 'disabled' : 'active'}`}
	/>
);

AddNewItemButton.propTypes = {
	tag: PropTypes.bool,
	label: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
};

export default AddNewItemButton;
