import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsPublic = () => {
	const { pathname } = useLocation();
	const [isPublic, setIsPublic] = useState(/public/.test(pathname));

	useEffect(() => {
		const isUrlPublic = /public/.test(pathname);
		setIsPublic(isUrlPublic);
	}, [pathname]);
	return { isPublic };
};
