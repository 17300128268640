const prefix = 'manage-study';

export const FETCH_SECTIONS = `${prefix}/FETCH_SECTIONS`;
export const fetchSections = (studyId, refetch = false, loadFirst = false, sectionId = false) => ({
	type: FETCH_SECTIONS,
	payload: { studyId, refetch, loadFirst, sectionId },
});

export const FETCH_SECTION = `${prefix}/FETCH_SECTION`;
export const fetchSection = (sectionId, mode, refetch = false, studyId = 0, questionIdToHighlight = 0) => ({
	type: FETCH_SECTION,
	payload: { sectionId, mode, refetch, studyId, questionIdToHighlight },
});

export const SET_SECTIONS = `${prefix}/SET_SECTIONS`;
export const setSections = (sections) => ({ type: SET_SECTIONS, payload: { sections } });

export const SET_SECTION = `${prefix}/SET_SECTION`;
export const setSection = (currentSection) => ({ type: SET_SECTION, payload: { currentSection } });

export const CREATE_SECTION = `${prefix}/CREATE_SECTION`;
export const createSection = (studyId, data) => ({ type: CREATE_SECTION, payload: { studyId, data } });

export const REORDER_SECTIONS = `${prefix}/REORDER_SECTIONS`;
export const reorderSections = (sourceIndex, destinationIndex) => ({
	type: REORDER_SECTIONS,
	payload: { sourceIndex, destinationIndex },
});

export const DELETE_SECTION = `${prefix}/DELETE_SECTION`;
export const deleteSection = (studyId, sectionId) => ({
	type: DELETE_SECTION,
	payload: { studyId, sectionId },
});

export const PATCH_SECTION_LOGIC = `${prefix}/PATCH_SECTION_LOGIC`;
export const patchSectionLogic = (studyId, sectionId, logic) => ({
	type: PATCH_SECTION_LOGIC,
	payload: { studyId, sectionId, logic },
});

export const PATCH_SECTION = `${prefix}/PATCH_SECTION`;
export const patchSection = (studyId, sectionId, data) => ({
	type: PATCH_SECTION,
	payload: { studyId, sectionId, data },
});

export const PATCH_ITEM = `${prefix}/PATCH_ITEM`;
export const patchItem = (studyId, sectionId, itemId, itemData) => ({
	type: PATCH_ITEM,
	payload: { studyId, sectionId, itemId, itemData },
});

export const PATCH_AUDIENCE_ITEM = `${prefix}/PATCH_AUDIENCE_ITEM`;
export const patchAudienceItem = (studyId, audienceUuid, itemId, itemData) => ({
	type: PATCH_AUDIENCE_ITEM,
	payload: { studyId, audienceUuid, itemId, itemData },
});

export const DELETE_ITEM_LOGIC = `${prefix}/DELETE_ITEM_LOGIC`;
export const deleteItemLogic = (studyId, sectionId, itemId, logicId) => ({
	type: DELETE_ITEM_LOGIC,
	payload: { studyId, sectionId, itemId, logicId },
});

export const DELETE_AUDIENCE_ITEM_LOGIC = `${prefix}/DELETE_AUDIENCE_ITEM_LOGIC`;
export const deleteAudienceItemLogic = (studyId, audienceUuid, itemId, logicId) => ({
	type: DELETE_AUDIENCE_ITEM_LOGIC,
	payload: { studyId, audienceUuid, itemId, logicId },
});

export const POST_SECTION_SETTINGS = `${prefix}/POST_SECTION_SETTINGS`;
export const postSectionSettings = (studyId, sectionId, data, refetchSection) => ({
	type: POST_SECTION_SETTINGS,
	payload: { studyId, sectionId, data, refetchSection },
});

export const PATCH_SECTION_SETTINGS = `${prefix}/PATCH_SECTION_SETTINGS`;
export const patchSectionSettings = (studyId, sectionId, settingId, data, refetchSection) => ({
	type: PATCH_SECTION_SETTINGS,
	payload: { studyId, sectionId, settingId, data, refetchSection },
});

export const DELETE_SECTION_SETTINGS = `${prefix}/DELETE_SECTION_SETTINGS`;
export const deleteSectionSettings = (studyId, sectionId, settingId) => ({
	type: DELETE_SECTION_SETTINGS,
	payload: { studyId, sectionId, settingId },
});

export const SET_SAVING_SECTION_SETTINGS = `${prefix}/SET_SAVING_SECTION_SETTINGS`;
export const setSavingSectionSettings = (isSavingSectionSettings) => ({
	type: SET_SAVING_SECTION_SETTINGS,
	payload: { isSavingSectionSettings },
});

export const SET_SECTION_SETTINGS_INSERT_DATA = `${prefix}/SET_SECTION_SETTINGS_INSERT_DATA`;
export const setSectionSettingsInsertData = (sectionSettingsInsertData) => ({
	type: SET_SECTION_SETTINGS_INSERT_DATA,
	payload: { sectionSettingsInsertData },
});

export const SET_SECTION_SETTINGS = `${prefix}/SET_SECTION_SETTINGS`;
export const setSectionSettings = (settings) => ({
	type: SET_SECTION_SETTINGS,
	payload: { settings },
});
export const SET_MODAL_SECTION = `${prefix}/SET_MODAL_SECTION`;
export const setModalSection = data => ({
	type: SET_MODAL_SECTION,
	payload: data,
});
