import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

/**
 *
 * @param {number} studyId
 */
function getAllCollections(studyId) {
	return axios.get(`${API_PATH}/studies/${studyId}/audience/collection`);
}
/**
 *
 * @param {number} studyId
 */
function getCollection(studyId, collectionId) {
	return axios.get(`${API_PATH}/studies/${studyId}/audience/collection/${collectionId}`);
}
/**
 *
 * @param {number} studyId
 *   - mode: create
 * {
 *    "name": "test Audience collection", // 250 characters or less
 *   "description": "this should be the description provided to a new test audience collection" // 500 characters or less
 * }
 */
function createCollection(studyId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/audience/collection`, data);
}

/**
 *
 * @param {number} studyId
 *   - mode: create
 * {
 *    "name": "test Audience collection", // 250 characters or less
 *   "description": "this should be the description provided to a new test audience collection" // 500 characters or less
 * }
 */
function createCollectionFromTemplate(studyId, templateId, languageCode) {
	return axios.post(`${API_PATH}/studies/${studyId}/audience/collection/${templateId}?languageCode=${languageCode}`);
}

/**
 *
 * @param {number} studyId
 * @param {number} collectionId
 *   - mode: edit
 */
function updateCollection(studyId, collectionId, data) {
	return axios.patch(`${API_PATH}/studies/${studyId}/audience/collection/${collectionId}`, data);
}
/**
 *
 * @param {number} studyId
 * @param {number} collectionId
 *   - mode: delete
 */
function deleteCollection(studyId, collectionId) {
	return axios.delete(`${API_PATH}/studies/${studyId}/audience/collection/${collectionId}`);
}
/**
 *
 * @param {number} studyId
 *  - mode: create
 * {
 * 	"provider": "LUCID",
 * }
 */
function createDemographicGroup(studyId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/audiences/`, data);
}
/**
 *
 * @param {number} studyId
 * @param {number} optionId
 */
function createScreeningQuestionFromScratch(studyId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/questions/`, data);
}
/**
 *
 *  - mode: create
 * @param {number} studyId
 * @param {number} questionId
 * @param {number} optionId
 */
function updateOptionLabel(studyId, questionId, optionId, data) {
	return axios.patch(`${API_PATH}/studies/${studyId}/questions/${questionId}/options/${optionId}`, data);
}
/**
 *
 *  - mode: create
 * @param {number} studyId
 * @param {number} questionId
 */
function createOption(studyId, questionId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/options`, data);
}
/**
 *
 *  - mode: create
 * @param {number} studyId
 * @param {number} questionId
 * @param {number} attributeId
 * @param {number} translationId
 */
function updateAttributeLabel(studyId, questionId, attributeId, translationId, data) {
	return axios.patch(
		`${API_PATH}/studies/${studyId}/questions/${questionId}/attributes/${attributeId}/translations/${translationId}`,
		data,
	);
}
/**
 *
 *  - mode: create
 * @param {number} studyId
 * @param {number} questionId
 */
function createAttribute(studyId, questionId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/attributes`, data);
}
/**
 *
 *  - mode: create
 * @param {number} studyId
 * @param {number} questionId
 */
function createAttributeTranslation(studyId, questionId, attributeId, data) {
	return axios.post(
		`${API_PATH}/studies/${studyId}/questions/${questionId}/attributes/${attributeId}/translations`,
		data,
	);
}
/**
 *
 *  - mode: create
 * @param {number} studyId
 * @param {number} questionId
 * @param {number} optionId
 * @param {object} data
 */
function createOptionQuota({ studyId, questionId, optionId, data }) {
	return axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/options/${optionId}/quotas`, data);
}
/**
 *
 *  - mode: create
 * @param {number} studyId
 * @param {number} questionId
 * @param {object} data
 */
function createManyOptionsQuota(studyId, questionId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/options/quotas/many`, data);
}
/**
 *
 *  - mode: update
 * @param {number} studyId
 * @param {number} questionId
 * @param {object} data
 */
function updateManyOptionsQuota(studyId, questionId, data) {
	return axios.patch(`${API_PATH}/studies/${studyId}/questions/${questionId}/options/quotas/many`, data);
}
/**
 *
 *  - mode: update
 * @param {number} studyId
 * @param {number} questionId
 * @param {number} optionId
 * @param {number} quotaId
 * @param {object} data
 */
function updateOptionQuota(studyId, questionId, optionId, quotaId, data) {
	return axios.post(
		`${API_PATH}/studies/${studyId}/questions/${questionId}/options/${optionId}/quotas/${quotaId}`,
		data,
	);
}
/**
 *
 *  - mode: delete
 * @param {number} studyId
 * @param {number} questionId
 * @param {object} data
 */
function deleteQuotaFromOption(studyId, questionId, data) {
	const ids = `${data.join(',')}`;
	return axios.delete(`${API_PATH}/studies/${studyId}/questions/${questionId}/options/quotas/many?quotaIds=${ids}`);
}
/**
 *
 *  - mode: update
 * @param {number} studyId
 * @param {number} collectionId
 * @param {number} questionId
 * @param {object} data
 */
function addLogicIntoQuestion(studyId, collectionId, questionId, data) {
	return axios.patch(
		`${API_PATH}/studies/${studyId}/audience/collection/${collectionId}/question/${questionId}/logic`,
		data,
	);
}
/**
 *
 *  - mode: delete
 * @param {number} studyId
 * @param {number} collectionId
 * @param {number} questionId
 * @param {number} logicId
 */
function deleteLogicFromQuestion(studyId, collectionId, questionId, logicId) {
	return axios.delete(
		`${API_PATH}/studies/${studyId}/audience/collection/${collectionId}/question/${questionId}/logic/${logicId}`,
	);
}
/**
 *  - mode: create
 * {
 * 	"provider": "LUCID",
 * }
 */
function saveCollectionAsTemplate(studyId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/audience/collection/template`, data);
}

/**
 *
 * @param {number} studyId
 */
function getTemplateCollection(
	limit = 10,
	offset = 0,
	searchQuery = null,
	terms = null,
	countryId = null,
	languageCode = null,
	order = 'desc',
	orderField = 'createdAt',
) {
	return axios.get(`${API_PATH}/templates/collections`, {
		params: {
			countryId,
			languageCode,
			searchQuery,
			terms,
			offset,
			limit,
			order,
			orderField,
		},
	});
}

/**
 *
 * @param {number} studyId
 */
function getAudienceCollectionTemplateTags() {
	return axios.get(`${API_PATH}/studies/audience/collection/template/tags`);
}

/**
 *
 * @param {number} studyId
 * @param {string} groupUuid
 */
function getDemographicQuestionsReport(studyId, groupUuid, data) {
	const {audienceUuids, filters, languages} = data;
	const excludeDisquals = audienceUuids?.includes('exclude-disquals');
	return axios.post(`${API_PATH}/studies/${studyId}/audiences/${groupUuid}/report/stats`, {
		audienceUuids: audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals'),
		filters,
		languages,
		excludeDisquals
	});
}

/**
 *
 * @param {number} studyId
 * @param {number} collectionId
 */
function getAudienceCollectionReport(studyId, collectionId, data) {
	const {audienceUuids, filters, languages} = data;
	const excludeDisquals = audienceUuids?.includes('exclude-disquals');
	return axios.post(`${API_PATH}/studies/${studyId}/audience/collection/${collectionId}/report/stats`, {
		audienceUuids: audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals'),
		filters,
		languages,
		excludeDisquals
	});
}

/**
 *
 * @param {number} studyId
 * @param {object} data
 *  {
 * 		"collectionId": "1",
 * 		"value": true,
 *  }
 */
function saveFavorite(studyId, data) {
	return axios.patch(`${API_PATH}/studies/${studyId}/audience/collection/template/favorite`, data);
}

function postQuotaInterlock(studyId, groupUuid, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/audiences/${groupUuid}/quota/interlock`, data);
}
/**
 *
 * @param {number} studyId
 * @param {string} groupUuid
 */
function getQuotaInterlock({ studyId, groupUuid, includeStatistics }) {
	return axios.get(
		`${API_PATH}/studies/${studyId}/audiences/${groupUuid}/quota/interlock${
			includeStatistics ? '?includeStatistics=true' : ''
		}`,
	);
}

/**
 *
 * @param {number} studyId
 * @param {string} groupUuid
 * @param {number} questionId
 */
function deleteQuotaInterlock(studyId, groupUuid, questionId) {
	return axios.delete(`${API_PATH}/studies/${studyId}/audiences/${groupUuid}/quota/interlock/${questionId}`);
}

export default {
	getCollection,
	createCollection,
	createCollectionFromTemplate,
	updateCollection,
	deleteCollection,
	getAllCollections,
	createDemographicGroup,
	createScreeningQuestionFromScratch,
	updateOptionLabel,
	createOption,
	updateAttributeLabel,
	createAttributeTranslation,
	createAttribute,
	createOptionQuota,
	updateOptionQuota,
	createManyOptionsQuota,
	deleteQuotaFromOption,
	updateManyOptionsQuota,
	addLogicIntoQuestion,
	deleteLogicFromQuestion,
	getTemplateCollection,
	saveCollectionAsTemplate,
	getDemographicQuestionsReport,
	getAudienceCollectionReport,
	saveFavorite,
	postQuotaInterlock,
	getQuotaInterlock,
	deleteQuotaInterlock,
	getAudienceCollectionTemplateTags,
};
