import misc from 'src/utilities/misc';
// Image Only
import UpsiidePopCan from '../../public/images/idea_screen/1_upsiide_pop_can.png';
import UpsiidePopCan2 from '../../public/images/idea_screen/1_upsiide_pop_can2.png';
// No Title
import UpsiideCoffeeEspresso from '../../public/images/idea_screen/3_upsiide_coffee_ground.png';
import UpsiideCoffeeRoasted from '../../public/images/idea_screen/3_upsiide_coffee_ground2.png';
// Title Top
import UpsiideMoisturizerHydrating from '../../public/images/idea_screen/4_upsiide_moisturizer.png';
import UpsiideMoisturizerRejuvenating from '../../public/images/idea_screen/4_upsiide_moisturizer_rejuvenating.png';
// No Title
import UpsiideDropperBox from '../../public/images/idea_screen/5_upsiide_dropper_box.png';
import UpsiideDropperBox2 from '../../public/images/idea_screen/5_upsiide_dropper_box2.png';
// Not being used atm
import UpsiideWineRed from '../../public/images/idea_screen/6_upsiide_wine.png';
import UpsiideWineRose from '../../public/images/idea_screen/6_upsiide_wine2.png';

const DUMMY_IMAGE = misc.getDummyImage(164, 144, '#e1e1e1', 1.0);

const modelOptions = {
	generic: {
		imageCropping: [
			{ label: 'Crop & fill', value: 'crop' },
			{ label: 'Fit', value: 'fit' },
		],
		imageSize: [
			{ label: 'Small', value: 'small' },
			{ label: 'Medium', value: 'medium' },
			{ label: 'Large', value: 'large' },
		],
		yesNo: [
			{ label: 'Yes', value: true },
			{ label: 'No', value: false },
		],
		estimatedQualRate: [
			{ label: '50%-100%', value: 50, max: 100 },
			{ label: '35%-49%', value: 35, max: 49 },
			{ label: '20%-34%', value: 20, max: 34 },
			{ label: '10%-19%', value: 10, max: 19 },
			{ label: '5%-9%', value: 5, max: 9 },
		],
		questionOptionLayout: [
			{ label: 'Stacked', value: 'default' },
			{ label: 'Side by side', value: 'sideBySide' },
		],
	},
	study: {
		commitmentLayout: {
			classic: [
				{ label: 'Image Only', value: 'image' },
				{ label: 'Image & Text', value: 'image-text' },
			],
			'one-field': [{ label: 'Field One', value: 'field-one' }],
			'two-field': [
				{ label: 'Field One', value: 'field-one' },
				{ label: 'Field Two', value: 'field-two' },
				{ label: 'Field One and Field Two', value: 'both-fields' },
			],
		},
		deviceOptions: [
			{ label: 'Mobile Only', value: true },
			{ label: 'Mobile & Desktop', value: false },
		],
	},
	idea_screen_layouts: {
		imageOnly: {
			fields: ['asset'],
			commitmentFields: [true],
			placeholders: [[{ url: DUMMY_IMAGE.src }]],
			exampleContent1: [[{ url: UpsiidePopCan }]],
			exampleContent2: [[{ url: UpsiidePopCan2 }]],
		},
		textOnly: {
			fields: ['html'],
			commitmentFields: [true],
			placeholders: ['Add details about the idea'],
			exampleContent1: [
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px; color: #3b3b3b;">Flavour Idea 1</h3>',
			],
			exampleContent2: [
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px; color: #3b3b3b;">Flavour Idea 2</h3>',
			],
		},
		doubleText: {
			fields: ['html', 'html'],
			commitmentFields: [true, false],
			placeholders: ['Add details about the idea', 'Add details about the idea'],
			exampleContent1: [
				'<h3 style="display: flex; font-size: 12px;font-weight: 400;margin: 8px auto; margin-top: auto; margin-bottom: auto; color: #3b3b3b; text-align: center; max-width: 170px;">Soft-baked cookies with chocolate chips</h3>',
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px auto; color: #3b3b3b; text-align: center; max-width: 170px;">Chocolate Chip Cookies</h3>',
			],
			exampleContent2: [
				'<h3 style="display: flex; font-size: 12px;font-weight: 400;margin: 8px auto; margin-top: auto; margin-bottom: auto; color: #3b3b3b; text-align: center; max-width: 170px;">Country apple crumble fritter with cinnamon</h3>',
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px auto; color: #3b3b3b; text-align: center; max-width: 170px;">Apple Crumble Fritter</h3>',
			],
		},
		noTitle: {
			fields: ['asset', 'html'],
			commitmentFields: [true, false],
			placeholders: [[{ url: DUMMY_IMAGE.src }], 'Add details about the idea'],
			exampleContent1: [
				[{ url: UpsiideCoffeeEspresso }],
				'<div style="color: #3b3b3b;"><p>Upsiide Snack Series Roasted Coffee Beans imported from Brazil.</p><p>Just the right medium roast to start each morning on the right foot.</p><p>100% fair trade coffee beans.</p></div>',
			],
			exampleContent2: [
				[{ url: UpsiideCoffeeRoasted }],
				'<div style="color: #3b3b3b;"><p>Upsiide Snack Series Roasted Coffee Beans imported from Brazil.</p><p>Just the right medium roast to start each morning on the right foot.</p><p>100% fair trade coffee beans.</p></div>',
			],
		},
		titleTop: {
			fields: ['html', 'asset', 'html'],
			commitmentFields: [true, true, false],
			placeholders: ['Title this idea', [{ url: DUMMY_IMAGE.src }], 'Add details about the idea'],
			exampleContent1: [
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px; color: #3b3b3b;">Hydrating Moisturizer</h3>',
				[{ url: UpsiideMoisturizerHydrating }],
				'<div style="color: #3b3b3b;"><p>Upsiide Moisturizer is an anti-aging serum, moisturizer, and sunscreen in one. It\'s the only thing you\'ll need in your morning routine, packed with hydrating and smoothing agents to take care of even the most problematic patches. This moisturizer has won numerous awards and is backed by a team of dermatologists. Get this cream and say goodbye to dry skin.</p></div>',
			],
			exampleContent2: [
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px; color: #3b3b3b;">Rejuvenating Moisturizer</h3>',
				[{ url: UpsiideMoisturizerRejuvenating }],
				'<div style="color: #3b3b3b;"><p>Upsiide Moisturizer is an anti-aging serum, moisturizer, and sunscreen in one. It\'s the only thing you\'ll need in your morning routine, packed with hydrating and smoothing agents to take care of even the most problematic patches. This moisturizer has won numerous awards and is backed by a team of dermatologists. Get this cream and say goodbye to dry skin.</p></div>',
			],
		},
		titleBottom: {
			fields: ['asset', 'html', 'html'],
			commitmentFields: [true, true, false],
			placeholders: [[{ url: DUMMY_IMAGE.src }], 'Title this idea', 'Add details about the idea'],
			exampleContent1: [
				[{ url: UpsiideDropperBox }],
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px; color: #3b3b3b;">Vitamin D serum</h3>',
				'<div style="color: #3b3b3b;"><p>Upsiide’s award-winning vitamin D serum is a crowdpleaser, giving your skin the bounce-back it needs during cold winters.</p><p>Packed with hyaluronic acid and vitamin B, the serum acts as a refreshing, hydrating, and smoothing agent for your face. Thank us later.</p></div>',
			],
			exampleContent2: [
				[{ url: UpsiideDropperBox2 }],
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px; color: #3b3b3b;">Vitamin D serum</h3>',
				'<div style="color: #3b3b3b;"><p>Upsiide’s award-winning vitamin D serum is a crowdpleaser, giving your skin the bounce-back it needs during cold winters.</p><p>Packed with hyaluronic acid and vitamin B, the serum acts as a refreshing, hydrating, and smoothing agent for your face. Thank us later.</p></div>',
			],
		},
		sideBySide: {
			twoColumn: true,
			fields: ['asset', 'html', 'html'],
			commitmentFields: [true, true, false],
			placeholders: [[{ url: DUMMY_IMAGE.src }], 'Title this idea', 'Add details about the idea'],
			exampleContent1: [
				[{ url: UpsiideWineRed }],
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px; color: #3b3b3b;display: flex;justify-content: center;text-align: left;">Upsiide Estate Red</h3>',
				'<div style="color: #3b3b3b;"><p>At Upsiide Estate Winery in Niagara Ontario, we pride ourselves on harvesting the best grapes by hand.</p><p>Tasting notes include bright red cherry, raspberry, and a hint of sage, with toast, and a blueberry undertone.</p><p>Enjoy with your favorite appetizers or baked salmon.</p></div>',
			],
			exampleContent2: [
				[{ url: UpsiideWineRose }],
				'<h3 style="font-size: 14px;font-weight: 600;margin: 8px; color: #3b3b3b;display: flex;justify-content: center;text-align: left;">Upsiide Estate Rose</h3>',
				'<div style="color: #3b3b3b;"><p>At Upsiide Estate Winery in Niagara Ontario, we pride ourselves on harvesting the best grapes by hand.</p><p>Tasting notes include bright red cherry, raspberry, and a hint of sage, with toast, and a blueberry undertone.</p><p>Enjoy with your favorite appetizers or baked salmon.</p></div>',
			],
		},
	},
	richTextEditor: {
		toolbarTypes: {
			basic: [
				['bold', 'italic', 'underline'], // toggled buttons
			],
			standard: [
				['bold', 'italic', 'underline'], // toggled buttons
				[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }], // text-align
				[{ list: 'ordered' }, { list: 'bullet' }], // Lists
			],
			full: [
				[{ size: ['small', false, 'large'] }], // Font size custom dropdown
				['bold', 'italic', 'underline'], // toggled buttons
				[{ align: [] }], // text-align
				[{ list: 'ordered' }, { list: 'bullet' }], // Lists
				[{ direction: 'rtl' }],
				// [{ color: [] }, { background: [] }], // color selection dropdown with defaults from theme
				['clean'], // remove formatting button
			],
			statement: [
				[
					{ size: ['small', false, 'large'] },
					{
						color: [
							'#3B3B3B',
							'#666666',
							'#6C757D',
							'#BABABA',
							'#CA0505',
							'#CA7B05',
							'#1B8219',
							'#1248B1',
							'#91178C',
							'#178190',
							'#635A89',
							'#41636A',
						],
					},
				],
				['bold', 'italic', 'underline', 'strike'],
				[{ align: [] }],
				[{ list: 'bullet' }, { list: 'ordered' }, { indent: '+1' }, { direction: 'rtl' }],
			],
			question: [
				[
					{ size: ['small', false, 'large'] },
					{
						color: [
							'#3B3B3B',
							'#666666',
							'#6C757D',
							'#BABABA',
							'#CA0505',
							'#CA7B05',
							'#1B8219',
							'#1248B1',
							'#91178C',
							'#178190',
							'#635A89',
							'#41636A',
						],
					},
				],
				['bold', 'italic', 'underline', 'strike'],
				[{ align: [] }],
				[{ list: 'bullet' }, { list: 'ordered' }, { indent: '+1' }, { direction: 'rtl' }],
			],
			idea: [
				[
					{ size: ['small', false, 'large'] },
					{
						color: [
							'#3B3B3B',
							'#666666',
							'#6C757D',
							'#BABABA',
							'#CA0505',
							'#CA7B05',
							'#1B8219',
							'#1248B1',
							'#91178C',
							'#178190',
							'#635A89',
							'#41636A',
						],
					},
				],
				['bold', 'italic', 'underline'],
				['strike'],
				[{ align: [] }],
				[{ list: 'bullet' }, { list: 'ordered' }, { indent: '+1' }, { direction: 'rtl' }],
			],
			none: [],
		},
		formatTypes: {
			basic: ['bold', 'italic', 'underline', 'mention'],
			standard: ['bold', 'italic', 'underline', 'align', 'list', 'mention'],
			full: ['size', 'bold', 'italic', 'underline', 'align', 'list', 'direction', 'mention'],
			statement: [
				'bold',
				'color',
				'italic',
				'size',
				'strike',
				'underline',
				'indent',
				'list',
				'align',
				'direction',
				'mention',
			],
			idea: ['bold', 'color', 'italic', 'size', 'strike', 'underline', 'indent', 'list', 'align', 'direction'],
			question: [
				'bold',
				'color',
				'italic',
				'size',
				'strike',
				'underline',
				'indent',
				'list',
				'align',
				'direction',
				'mention',
			],
		},
	},
	share_link: {
		vendor_options: [
			{ value: 'custom', label: 'Custom' },
			{ value: 'dynata', label: 'Dynata' },
			{ value: 'canView', label: 'CanView' },
			{ value: 'askCanadians', label: 'Asking Canadians' },
			{ value: 'lucid', label: 'Lucid' },
			{ value: 'schlesinger', label: 'Schlesinger Group', deprecated: true, replacedBy: 'sago' },
			{ value: 'sago', label: 'Sago' },
			{ value: 'sagoSampleOnly', label: 'Sago (Sample Only)' },
			{ value: 'leger', label: 'Leger' },
			{ value: 'logit', label: 'Logit' },
		],
		vendor_preset_parameters: {
			custom: ['var1', 'var2'],
			dynata: ['study', 'state'],
			canView: ['DPID', 'state'],
			askCanadians: ['s', 'qid', 'pid'],
			lucid: [],
			schlesinger: ['state', 'project'],
			sago: ['state', 'project'],
			sagoSampleOnly: [],
			leger: ['study', 'state'],
			logit: ['study', 'state'],
		},
		redirect_defaults: {
			custom: '',
			dynata: 'https://survey-ca.yoursurveynow.com/survey/selfserve/{study}?rnid={RID}&state={state}',
			canView: 'http://f.canview.com/DCPR_REDIR?pw={RID}&DPID={DPID}&state={state}',
			askCanadians: 'https://survey.euro.confirmit.com/wix/9/{pid}.aspx?__sid__={s}&__qid={qid}&r={RID}',
			lucid: 'https://samplicio.us/s/ClientCallBack.aspx?RIS=10&RID={RID}',
			schlesinger: 'https://schlesinger.focusvision.com/survey/{project}?state={state}',
			sago: 'https://surveys.sago.com/survey/{project}?state={state}',
			sagoSampleOnly: 'https://surveys.sample-cube.com/ending?RS=1&RID={RID}&secret=1574',
			leger: 'https://di.legeropinion.com/survey/selfserve/542/{study}?state={state}&respid={RID}',
			logit: 'https://logit.decipherinc.com/survey/selfserve/{study}?state={state}',
		},
		redirect_disqualified_default: {
			custom: '',
			dynata: '',
			canView: '',
			askCanadians: '',
			lucid: '',
			schlesinger: '',
			sago: '',
			sagoSampleOnly: 'https://surveys.sample-cube.com/ending?RS=3&RID={RID}',
			leger: '',
			logit: '',
		},
	},
	accountInformation: {
		licenseType: [
			{ value: 'basic', label: 'Basic' },
			{ value: 'professional', label: 'Professional' },
			{ value: 'enterprise', label: 'Enterprise' },
			{ value: 'smallBusiness', label: 'Small Business' },
			{ value: 'fullService', label: 'Full Service' },
			{ value: 'agency', label: 'Agency' },
			{ value: 'custom', label: 'Custom' },
			{ value: 'scale', label: 'Scale' },
			{ value: 'growth', label: 'Growth' },
			{ value: 'study', label: 'Study' },
		],
		currencyOptions: [
			{ value: 'cad', label: 'CAD Canadian Dollars' },
			{ value: 'usd', label: 'USD United States Dollars' },
		],
		salesRepIds: [
			{ value: 'UP3UMDP60', label: 'Patrick Lambert' },
			{ value: 'UU60XBMRT', label: 'David Oladejo' },
			{ value: 'U0CME5QTC', label: 'Frank Beirne' },
			{ value: 'U01G9D93C0M', label: 'Matt Worden' },
			{ value: 'UB8T527ED', label: 'Eric Holmes' },
		],
	},
};

export const generateVendorOptions = val => {
	const options = modelOptions.share_link.vendor_options;

	// Filter out all deprecated options if no value is passed
	if (!val) {
		return options.filter(option => !option.deprecated);
	}

	// if the selected option is deprecated, return all options
	const selectedOption = options.find(option => option.value === val);
	if (val && selectedOption?.deprecated) {
		return options;
	}

	// filter out all deprecated options
	return options.filter(option => !option.deprecated);
};

export default modelOptions;
