import Cookies from 'src/utilities/cookies';
import axios from 'src/utilities/axios';
// import { loginService, zendeskService } from 'src/services';
import { loginService } from 'src/services';
import { getAccount } from 'src/domains/account/selectors';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import * as studyActions from 'src/domains/all-studies/actions';
import * as actions from '../actions';
import * as confirmSignUpActions from '../../confirm-signup/actions';

import * as authActions from '../../auth/actions';

const login = async (store, { type, payload }) => {
	if (type === actions.LOGIN_USER) {
		try {
			// const { email, password, remember, redirect } = payload;
			const { email, password, remember } = payload;
			store.dispatch(actions.setLoginForm({ loading: true, error: null }));
			const response = await loginService.login({
				email,
				password,
				keepAlive: !!remember,
			});
			const authenticateUser = () => {
				Cookies.setToken(response.data.token);
				axios.setToken(response.data.token);
				store.dispatch(authActions.authenticate());
			};

			const redirectToNoAccount = () => {
				store.dispatch(confirmSignUpActions.setUser({ email }));
				navigate('/no-account');
			};
			if (response && response.data && response.data.limboState) {
				switch (response.data.limboState) {
					case 'none': {
						authenticateUser();
						break;
					}
					case 'no_account': {
						redirectToNoAccount();
						break;
					}
					default: {
						authenticateUser();
						break;
					}
				}
			} else {
				const error = "That's not what we were expecting. Try again.";

				store.dispatch(actions.setLoginForm({ loading: false, error }));
			}

			store.dispatch(actions.setLoginForm({ loading: false }));
		} catch (error) {
			let errorMessage = 'Invalid credentials. Please try again.';
			if (error && error.response && error.response.data && error.response.data.message === 'USER_LOCKED_OUT') {
				errorMessage = 'You have been temporarily locked out of your account, please try again in 15 minutes.';
			}
			store.dispatch(
				actions.setLoginForm({
					loading: false,
					error: errorMessage,
					errorCode: error?.response?.status || 401,
				}),
			);
		}
	}
};

const forgetPassword = async (store, { type, payload }) => {
	if (type === actions.FORGET_PASSWORD) {
		try {
			store.dispatch(actions.setLoginForm({ loading: true }));

			const user = await loginService.forgetPassword(payload);

			if (user) {
				store.dispatch(
					actions.setLoginForm({
						passwordRequestSuccess: true,
						loading: false,
					}),
				);
			} else {
				store.dispatch(
					actions.setLoginForm({
						passwordRequestSuccess: false,
						loading: false,
						error: "User doesn't exist.",
					}),
				);
			}
		} catch (error) {
			store.dispatch(
				actions.setLoginForm({
					loading: false,
					error: error.response.statusText,
				}),
			);
		}
	}
};

const resetForm = async (store, { type }) => {
	if (type === actions.RESET_FORM) {
		store.dispatch(
			actions.setLoginForm({ loading: false, error: null, errorCode: null, passwordRequestSuccess: false }),
		);
	}
};

const getProvisioningState = async (store, { type }) => {
	if (type === actions.GET_PROVISIONING_STATE) {
		actions.setProvisioningState({
			loading: true,
		});
		try {
			const isChargify = getAccount(store.getState())?.content?.settings?.isChargify;
			if (!isChargify) {
				return;
			}

			const response = await loginService.fetchProvisioningState(null);
			const { isProvisioning, provisioningState } = response.data;
			if (isProvisioning !== undefined) {
				store.dispatch(
					actions.setProvisioningState({
						isProvisioning,
						provisioningState: isProvisioning ? provisioningState : {},
						loading: false,
					}),
				);
			}
		} catch (error) {
			console.error(error);
			actions.setProvisioningState({
				loading: false,
			});
		}
	}
};

export default [login, forgetPassword, resetForm, getProvisioningState];
