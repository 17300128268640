/* eslint-disable class-methods-use-this */

export class IntercomTracking {
	constructor() {
		this.isLoaded = false;
		this.userData = {};
	}

	load(appId) {
		if (this.isLoaded) return;

		const script = document.createElement('script');
		script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

		script.id = 'intercom-script';
		script.async = true;

		document.body.appendChild(script);

		this.isLoaded = true;
	}

	boot(data) {
		/** timeout to boot intercom on next render cycle,
		 * On initial render intercom throws http_post_session_empty, and doesn't always trigger the boot script for some reason
		 * */
		setTimeout(() => {
			if (window.Intercom) {
				window.Intercom('boot', {
					app_id: process.env.production ? 'gbd7mpwj' : 'oaflgw21',
					hide_default_launcher: true,
					...data,
				});
			}
		});
	}

	// If you have the Inbox product (combined with another product like Messages) you should call the Intercom shutdown method to clear your users’
	// conversations anytime they logout of your application. Otherwise, the cookie we use to track who was most recently logged in on a given device
	// or computer will keep these conversations in the Messenger for one week. This method will effectively clear out any user data that you have been passing through the JS API.
	shutdown() {
		if (!window.Intercom) return;
		window.Intercom('shutdown');
	}

	// 	Calling the update method with some new user data will trigger the JavaScript to look for new messages that should be displayed to the current user (the one whose details are in the window.intercomSettings variable) and show them if they exist.
	// Calling the update method with a JSON object of user details will update those fields on the current user in addition to logging an impression at the current URL and looking for new messages for the user.
	update() {
		if (!window.Intercom) return;
		window.Intercom('update', this.userData);
	}

	// You can submit an event using the trackEvent method. This will associate the event with the currently logged in user and send it to Intercom. The final parameter is a map that can be used to send optional metadata about the event.
	// You can also add custom information to events in the form of event metadata, which can be included in event based messages to your customers.
	track(eventName, metadata) {
		if (!window.Intercom) return;
		window.Intercom('trackEvent', eventName, metadata);
	}

	// If you would like to trigger a tour based on an action a user or visitor takes in your site or application, you can use this API method. You need to call this method with the id of the tour you wish to show. The id of the tour can be found in the “Use tour everywhere” section of the tour editor.
	// Please note that tours shown via this API must be published and the “Use tour everywhere” section must be turned on. If you're calling this API using an invalid tour id, nothing will happen. Nothing will happen if you call this API on mobile web as tours do not work on mobile.
	startTour(tour) {
		if (!window.Intercom) return;
		window.Intercom('startTour', tour);
	}

	// // This will hide the main Messenger panel if it is open. It will not hide the Messenger Launcher.
	// hide() {
	// 	window.Intercom('hide');
	// }

	// // This will show the Messenger. If there are no new conversations, it will open to the Messenger Home. If there are, it will open with the message list.
	// show() {
	// 	window.Intercom('show');
	// }

	// // This will show the specified space in the Messenger, if that space is enabled.
	// showSpace(space) {
	// 	window.Intercom('showSpace', space);
	// }

	// // This will open the Messenger with the message list.
	// showMessages() {
	// 	window.Intercom('showMessages');
	// }

	// // This will open the Messenger as if a new conversation was just created.
	// // This function can also take an optional second parameter, used to pre-populate the message composer as shown in the code example below..
	// showNewMessage() {
	// 	window.Intercom('showNewMessage');
	// }

	// // When we hide the messenger, you can hook into the event. This requires a function argument.
	// onHide(callback) {
	// 	window.Intercom('onHide', callback);
	// }

	// // When we show the messenger, you can hook into the event. This requires a function argument.
	// onShow(callback) {
	// 	window.Intercom('onShow', callback);
	// }

	// // This method allows you to register a function that will be called immediately when invoked, and again whenever the current number of unread messages changes.
	// onUnreadCountChange(callback) {
	// 	window.Intercom('onUnreadCountChange', callback);
	// }

	// // A visitor is someone who goes to your site but does not use the messenger. You can track these visitors via the visitor user_id. This user_id can be used to retrieve the visitor or lead through the REST API.
	// getVisitorId() {
	// 	return window.Intercom.getVisitorId();
	// }

	// // If you would like to trigger an article in the Messenger, you can use the showArticle method. The article will be shown within the Messenger, and clicking the Messenger back button will return to the previous context.
	// // If the Messenger is closed when the method is called, it will be opened first and then the article will be shown.
	// showArticle(article) {
	// 	window.Intercom('showArticle', article);
	// }

	// // If you would like to trigger a news item in the Messenger, you can use the showNews method. The news item will be shown within the Messenger, and clicking the Messenger back button will return to the previous context.
	// // If the Messenger is closed when the method is called, it will be opened first and then the news item will be shown.
	// showNews(newsItem) {
	// 	window.Intercom('showNews', newsItem);
	// }

	// // If you would like to trigger a survey in the Messenger, you can use the startSurvey method. The id of the survey can be found in the “Additional ways to share your survey” section of the survey editor as well as in the URL of the editor.
	// // Please note that surveys shown via this API must be live. If you're calling this API using an invalid survey id, nothing will happen.
	// startSurvey(survey) {
	// 	window.Intercom('startSurvey', survey);
	// }

	// // If you would like to trigger a checklist in the Messenger, you can use the startChecklist method. The id of the checklist can be found in the “Additional ways to share your checklist” section of the checklist editor as well as in the URL of the editor.
	// // Please note that checklists shown via this API must be live. If you're calling this API using an invalid checklist id, nothing will happen.
	// startChecklist(checklist) {
	// 	window.Intercom('startChecklist', checklist);
	// }

	// // If you would like to trigger a ticket in the Messenger, you can use the showTicket method. The ticket will be shown within the Messenger, and clicking the Messenger back button will return to the previous context.
	// // If the Messenger is closed when the method is called, it will be opened first and then the ticket will be shown.
	// showTicket(ticket) {
	// 	window.Intercom('showTicket', ticket);
	// }

	// // You can show a conversation programatically in the Messenger by calling showConversation method
	// showConversation(conversation) {
	// 	window.Intercom('showConversation', conversation);
	// }

	// // When a visitor enters their email into the Messenger, you can hook into the event. This requires a function argument.
	// onUserEmailSupplied(callback) {
	// 	window.Intercom('onUserEmailSupplied', callback);
	// }
}
