import React from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';

import DemographicOverview from './components/Demographic';
import ScreeningOverview from './components/Screening';
import StatementSection from './components/Statement';
import UrlRedirectSection from './components/UrlRedirect';
import IdeaScreenSection from './components/IdeaScreen';
import IdeaSplitsSection from './components/IdeaSplits';
import Questions from './components/Questions';

import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'template-preview-modal-body';

const TemplatePreviewBody = ({
	type,
	audience,
	sections,
	activeSection,
	isLoading,
	study,
	selectedLanguage,
	currentTab,
	setCurrentTab,
	setTemplateModalCurrentAudience,
	templateModalCurrentAudience,
}) => {
	const loadingContent = () => {
		if (type !== 'Study Template ') {
			return (
				<div className={el('load-audience')}>
					<div className={el('load-square')} style={{ width: '150px', height: '18px' }} />
					<div className={el('load-square')} style={{ width: '100%', height: '83px' }} />
					<div className={el('load-square')} style={{ width: '100%', height: '260px' }} />
				</div>
			);
		}
		return (
			<div className={el('load-section')}>
				<div className={el('load-square')} style={{ width: '100%', height: '18px', marginBottom: '2px' }} />
				<div className={el('load-square')} style={{ width: '100%', height: '26px', marginBottom: '6px' }} />
				<div className={el('load-square')} style={{ width: '100px', height: '100px', marginBottom: '6px' }} />
				<div className={el('load-square')} style={{ width: '735px', height: '18px' }} />
				<div className={el('load-square')} style={{ width: '627px', height: '18px' }} />
				<div className={el('load-square')} style={{ width: '735px', height: '18px' }} />
				<div className={el('load-square')} style={{ width: '627px', height: '18px' }} />
			</div>
		);
	};

	const renderEmptyStudySections = () => (
		<div className={el('empty-study-template-wrapper')}>
			<p className={el('empty-title')}>This template is empty</p>
			<p className={el('empty-subtitle')}>You can add your own sections once you start using this template.</p>
		</div>
	);

	const renderContent = () => {
		if (isLoading) {
			return loadingContent();
		}
		let sectionType = activeSection;
		let section = null;
		if (sectionType !== 'demographic-groups' && sectionType !== 'screening-questions') {
			section = sections?.find(({ id }) => id === activeSection);
			sectionType = section?.type;
		}

		if (!sectionType && type === 'Study Template ' && !sections?.length) {
			return renderEmptyStudySections();
		}

		switch (sectionType) {
			case 'demographic-groups':
				return (
					<DemographicOverview
						audience={audience}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
						setTemplateModalCurrentAudience={setTemplateModalCurrentAudience}
						templateModalCurrentAudience={templateModalCurrentAudience}
					/>
				);
			case 'screening-questions':
				return (
					<ScreeningOverview
						audience={audience}
						selectedLanguage={selectedLanguage}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
				);
			case 'statement':
				return <StatementSection section={section} sections={sections} selectedLanguage={selectedLanguage} />;
			case 'questions':
				return <Questions section={section} sections={sections} selectedLanguage={selectedLanguage} />;
			case 'swipe':
				return <IdeaScreenSection study={study} section={section} selectedLanguage={selectedLanguage} />;
			case 'monadic_split':
				return (
					<IdeaSplitsSection
						study={study}
						section={section}
						sections={sections}
						selectedLanguage={selectedLanguage}
					/>
				);
			case 'link_routing':
				return <UrlRedirectSection section={section} selectedLanguage={selectedLanguage} />;
			default:
				return null;
		}
	};

	return <div className={className}>{renderContent()}</div>;
};

TemplatePreviewBody.displayName = 'TemplatePreviewBody';
TemplatePreviewBody.propTypes = {
	type: PropTypes.any,
	audience: PropTypes.any,
	sections: PropTypes.any,
	activeSection: PropTypes.any,
	isLoading: PropTypes.any,
	study: PropTypes.any,
	selectedLanguage: PropTypes.any,
	currentTab: PropTypes.string,
	setCurrentTab: PropTypes.func,
	setTemplateModalCurrentAudience: PropTypes.func,
	templateModalCurrentAudience: PropTypes.string,
};

export default TemplatePreviewBody;
