const MAX_IMAGE_SIZE_MB = 4; // 4MB
const ALLOWED_IMAGE_EXTENSIONS = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

export const validateFileSize = file => {
	if (file?.size > MAX_IMAGE_SIZE_MB * 1000000) {
		return `File too large! Maximum file size: ${MAX_IMAGE_SIZE_MB}MB.`;
	}
	return false; // no error found
};

export const validateImageType = fileName => ALLOWED_IMAGE_EXTENSIONS.exec(fileName);
