const parseUtmValuesFromUrl = urlLocationSearch => {
	const queryParams = new URLSearchParams(urlLocationSearch || window.location.search);
	const utmKeys = ['utm_medium', 'utm_source', 'utm_campaign', 'utm_term', 'utm_content'];
	const utmData = {};
	for (const [key, value] of queryParams) {
		if (utmKeys.includes(key)) {
			utmData[key] = value;
		}
	}
	return utmData;
};

const mapCookieToApiUtmDatFormat = utmCookieData => {
	const utmData = {};
	if (utmCookieData) {
		for (const [key, value] of Object.entries(utmCookieData)) {
			const keysParts = key.split('_');
			const apiPayloadKey = keysParts[0] + keysParts[1].charAt(0).toUpperCase() + keysParts[1].slice(1);
			utmData[apiPayloadKey] = value;
		}
	}
	return utmData;
};

export default {
	parseUtmValuesFromUrl,
	mapCookieToApiUtmDatFormat,
};
