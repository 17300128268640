import { getClientData } from '../../selectors';

export const getCommonError = state => getClientData(state).commonError;
export const getClientId = state => getClientData(state).clientId;
export const getClients = state => getClientData(state).clients;
export const getUsers = state => getClientData(state).users;
export const getGroups = state => getClientData(state).groups;
export const getAddGroupModal = state => getClientData(state).addGroupModal;
export const getAddUserLoading = state => getClientData(state).addUserLoading;
export const getAddUserComplete = state => getClientData(state).addUserComplete;
export const getDeleteUserModal = state => getClientData(state).deleteUserModal;
export const getDeleteGroupModal = state => getClientData(state).deleteGroupModal;
export const getRoles = state => getClientData(state).roles;
export const getManageGroupModal = state => getClientData(state).manageGroupModal;
export const getSorting = state => getClientData(state).sorting;
