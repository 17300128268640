import React from 'react';

export const getCostMarkup = (value, currency = 'USD', lineThrough = false, decimalPoints = 2, showCurrency = true) => {
	const currencySymbolText = '$';
	const negative = value < 0;
	const negativeText = negative ? '-' : '';
	const valueText = `${currencySymbolText}${
		negative
			? (value * -1).toLocaleString(undefined, {
					minimumFractionDigits: decimalPoints,
					maximumFractionDigits: decimalPoints,
			  })
			: value.toLocaleString(undefined, {
					minimumFractionDigits: decimalPoints,
					maximumFractionDigits: decimalPoints,
			  })
	} `;
	const className = lineThrough ? 'cost-markup line-through' : 'cost-markup';
	return (
		<div className={className}>
			<span>
				{negativeText}
				{valueText.trim()}
			</span>
			{showCurrency && currency && <span> {currency.toUpperCase()}</span>}
		</div>
	);
};
