/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import CONSTANTS from 'src/config/constants';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SectionHeader from 'src/components/elements/SectionHeader';
import DetatchedScreenerModal from 'src/components/shared/DetatchedScreenerModal';
import QuestionTypeIcon from 'src/components/shared/QuestionTypeIcon';
import QuestionCheckmark from 'src/components/icons/QuestionCheckmark';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import Modal from 'src/components/shared/Modal';
import PipingErrorModal from 'src/components/shared/PipingErrorModal';
import { Row, Col } from 'reactstrap';
import WarningIcon from 'src/components/icons/Warning';
import EmptyQuestionSection from 'src/components/manage/questions/EmptyQuestionSection';
import QuestionItem from 'src/components/manage/questions/QuestionItem';
import AddQuestionButton from 'src/components/elements/AddQuestionButton';

import QuillMentionUtil from 'src/components/inputs/SimpleRichText/QuillMentionUtil';
import 'react-tippy/dist/tippy.css';
import './styles.scss';
import { Tooltip } from 'react-tippy';
import misc from 'src/utilities/misc';
import masking from 'src/utilities/masking';
import * as actions from 'src/domains/manage-study/actions';

import ReactHeatMap from 'src/components/elements/ReactHeatMap';
import _ from '../../../../utilities/lodashReplacer';

const QuestionsSection = ({
	data,
	languages,
	audience,
	addAudienceQuestion,
	addSectionQuestion,
	deleteQuestion,
	reorderQuestions,
	patchSectionName,
	updateQuestionLabel,
	addQuestionOption,
	updateOptionLabel,
	updateOptionScalePoint,
	setOptionScalePointValue,
	removeQuestionOption,
	reorderOptions,
	patchItem,
	patchAudienceItem,
	deleteItemLogic,
	deleteAudienceItemLogic,
	addOptionPlaceholder,
	addCustomQualifierOptionPlaceholder,
	removeOptionPlaceholder,
	patchQuestion,
	updateQuestion,
	patchQuestionOption,
	createQuestionSetting,
	patchQuestionSetting,
	className,
	deleteCustomQualifier,
	updateCustomQualifierLabel,
	patchStatement,
	// Marketplace Custom Targets
	tempTemplate,
	setTempTemplate,
	blockUpdateOrBreakTemplate,
	setAudienceReady,
	//
	showHeader = true,
	showEmptyQuestionSection = true,

	setSections,
	// Attributes
	addCustomQualifierAttributePlaceholder,
	addAttributePlaceholder,
	removeAttributePlaceholder,
	addQuestionAttribute,
	removeQuestionAttribute,
	updateAttributeLabel,
	reorderAttributes,
	patchQuestionAttribute,
	// For use with filter questions
	filterQuestions,
	noNewQuestion,
	filterImportQuestions,
	reorderQuestionsWithoutSections,
	familyDomain,
	screeningQuestion = false,
	screeningQuestionAddQuestionOptions = [],
	setOpenQuestionLibrary = false,
	deleteScreeningQuestion = false,
	changeScreeningQuestionVisibility,
	updateScreeningOption,
	updateScreeningOptionScalePoint,
	setScreeningOptionScalePointValue,
	updateScreeningAttribute,
	deleteScreeningOption,
	deleteScreeningAttribute,
	updateScreeningQuota,
	updateAllScreeningOptionQuota,
	deleteAllScreeningOptionQuota,
	patchScreeningQuestionSetting,
	addScreeningQuestionOption,
	deleteScreeningQuestionOption,
	studyId,
	updateScreeningQuestionLabel,
	updateScreeningQuestionOption,
	initialSelectLanguage = null,
	audienceTemplate = null,
	reorderOptionsWithoutSections,
	reorderAttributesWithoutSections,
	batchUpdateAudienceTemplateScreeningQuestionOptions,
	batchSetAudienceTemplateScreeningQuestionOptions,
}) => {
	const { currentSection, study, language, sections } = useSelector(store => store[familyDomain]);
	const el = useCallback((name, mod) => cn(className, name, mod), [className]);

	const [section, setSection] = useState(currentSection?.content);
	const [deleteQuestionId, setDeleteQuestionId] = useState(false);
	const [focusOnQuestion, setFocusOnQuestion] = useState({
		questionId: null,
		length: null,
	});
	const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
	const [showPipingErrorModal, setShowPipingErrorModal] = useState(false);
	const [showDetatchedModal, setShowDetatchedModal] = useState(false);
	const questions = useMemo(() => {
		if (filterImportQuestions) {
			return filterQuestions;
		}
		return audience
			? (audience?.customQuestions || [])?.map((item, index) => {
					item.studyQuestionNumber = index + 1;
					return item;
			  })
			: currentSection?.content?.questions;
	}, [audience, currentSection?.content?.questions, filterImportQuestions, filterQuestions]);

	const initialSelectLanguageVal =
		typeof initialSelectLanguage === 'object' && initialSelectLanguage?.languageCode
			? initialSelectLanguage?.languageCode
			: language;

	const [selectedLanguage, setSelectedLanguage] = useState(
		initialSelectLanguageVal || language || study?.defaultLanguage || 'en',
	);

	useEffect(() => {
		if (initialSelectLanguageVal) setSelectedLanguage(initialSelectLanguageVal);
		else {
			setSelectedLanguage(language || study?.defaultLanguage || 'en');
		}
	}, [initialSelectLanguageVal, language, study]);

	const [logicMissing, setLogicMissing] = useState(false);
	const [activeQuestion, setActiveQuestion] = useState(false);
	const [optionTempIds, setOptionTempIds] = useState([]);
	const [attributeTempIds, setAttributeTempIds] = useState([]);
	const [dragResultStateValue, setDragResultStateValue] = useState(null);
	const [labelUpdates, setLabelUpdates] = useState([]);
	const [pipedQuestions, setPipedQuestions] = useState([]);
	const [pipingErrorIds, setPipingErrorIds] = useState([]);
	const [tooltipStyle, setTooltipStyle] = useState({ position: 'absolute', background: '#fff' });
	const tooltipRef = useRef();
	const assignTooltipRef = node => {
		tooltipRef.current = node;
	};

	const languageData = useMemo(
		() =>
			tempTemplate
				? languages.find(
						lang => lang.languageCode === study?.language || lang.languageCode === study?.defaultLanguage,
				  )
				: null,
		[languages, tempTemplate, study],
	);

	useEffect(() => {
		if (data && data.content) {
			const { content } = data;
			setSection(content);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		// If user has added a custom target, at least one question requires disqualification logic
		if (audience && audience.customQuestions && audience.customQuestions.length > 0) {
			const questionsWithDisqualificationLogic = audience.customQuestions.filter(question =>
				question.logic.some(logic => logic.actionType === 'terminate'),
			);

			if (questionsWithDisqualificationLogic.length > 0) {
				setAudienceReady && setAudienceReady(true);
				setLogicMissing(false);
			} else {
				setAudienceReady && setAudienceReady(false);
				setLogicMissing(true);
			}
		} else {
			setAudienceReady && setAudienceReady(true);
			setLogicMissing(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [audience]);

	React.useLayoutEffect(() => {
		if (!questions || !questions.length) return;
		let optionalQuestions;
		setTimeout(() => {
			const $optionalQuestions = window.document.querySelectorAll('.question-header .optional.mention');
			if (!$optionalQuestions || !$optionalQuestions.length) return;
			optionalQuestions = Array.from($optionalQuestions).map($question => {
				const mouseEnterEvent = () => {
					const { x, y } = $question.getBoundingClientRect();
					setTooltipStyle({
						left: `${x}px`,
						top: `${y}px`,
						zIndex: 100,
						position: 'fixed',
					});
					tooltipRef.current.showTooltip();
				};
				const mouseLeaveEvent = () => tooltipRef.current.hideTooltip();

				$question.addEventListener('mouseenter', mouseEnterEvent);
				$question.addEventListener('mouseleave', mouseLeaveEvent);

				return { $question, mouseEnterEvent, mouseLeaveEvent };
			});
		}, 500);

		return () => {
			if (!optionalQuestions || !optionalQuestions.length) return;

			optionalQuestions.forEach(({ $question, mouseEnterEvent, mouseLeaveEvent }) => {
				$question.removeEventListener('mouseover', mouseEnterEvent);
				$question.removeEventListener('mouseleave', mouseLeaveEvent);
			});
		};
	}, [questions, sections]);

	const moveInArray = (arr, from, to) => {
		// Delete the item from it's current position
		const item = arr.splice(from, 1);
		// Move the item to its new position
		arr.splice(to, 0, item[0]);
	};

	/**
	 * focus on specific option
	 * @param {*} optionIndex
	 */
	const focusOnOption = useCallback(
		(questionId, optionIndex) => {
			let element;
			// check if grid question
			const foundGridQuestion = questions.find(
				question => question.id === questionId && question.style === CONSTANTS.questions.options.style.grid,
			);
			if (foundGridQuestion) {
				element = document.getElementById(`attribute-textarea-${questionId}-${optionIndex}`);
			} else {
				element = document.getElementById(`answer-textarea-${questionId}-${optionIndex}`);
			}
			if (element) {
				element.focus();
				element.select();
				return true;
			}
			// cant find element
			return false;
		},
		[questions],
	);

	const scrollToNewQuestion = useCallback(questionId => {
		const prevQuestion = document.getElementById(`question-item-${questionId}`);
		const sibling = prevQuestion?.parentElement?.parentElement?.nextElementSibling;
		if (sibling) {
			sibling.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
		} else {
			const interval = setInterval(() => {
				// this interval is needed to await the dom sync.
				// On the future, we should move away from real dom, or use refs.
				const component = prevQuestion?.parentElement?.parentElement?.nextElementSibling;
				if (component) {
					component.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
					clearInterval(interval);
				}
			}, 100);
		}
	}, []);

	const addNewQuestion = useCallback(
		(questionType, questionId) => {
			if (tempTemplate) {
				tempTemplate.estimatedIncidenceRate = null;

				const translations = study?.languages?.map(lang => ({
					label: '',
					languageCode: lang,
				})) || [{ label: '', languageCode: 'en' }];

				const newCustomTarget = {
					id: `temp_${questions.length + 1}`,
					studyQuestionNumber: questions.length + 1,
					type: 'custom',
					style: questionType,
					label: '',
					filterLabel: '',
					status: 'public',
					sortOrder: questions.length,
					isFilter: questionType !== 'open-ended',
					randomizeOptions: questionType === 'emoji',
					options: [],
					logic: [],
					translations,
				};

				if (questionType === 'grid') {
					newCustomTarget.attributes = [];
				}

				if (questionType === 'emoji') {
					CONSTANTS.questions.emojiOptions.forEach((emojiOption, index) => {
						newCustomTarget.options.push({
							value: emojiOption,
							id: `temp_${index + 1}`,
							order: last(newCustomTarget.options)
								? last(orderBy(newCustomTarget.options, 'order')).order + 1
								: newCustomTarget.options.length,
							translations: [
								{
									languageCode: languageData?.languageCode || study?.currentLanguage || 'en',
									label: emojiOption,
								},
							],
						});
					});
				}

				questions.push(newCustomTarget);
				tempTemplate.customQuestions = questions;
				setTempTemplate({ ...tempTemplate });
				setFocusOnQuestion({ questionId: -1, length: questions.length });
			} else if (audience && audience.uuid) {
				addAudienceQuestion(audience.uuid, questionType, questions.length, questionId);
			} else {
				addSectionQuestion(section.id, questionType, questions.length, questionId);
				setFocusOnQuestion({ questionId: -1, length: questions.length + 1 });
			}
			scrollToNewQuestion(questionId);
		},
		[
			addAudienceQuestion,
			addSectionQuestion,
			audience,
			questions,
			scrollToNewQuestion,
			section,
			setTempTemplate,
			study,
			tempTemplate,
			languageData,
		],
	);

	const addQuestionOptions = useMemo(
		() => [
			{
				name: 'select',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="multi-select" size="large" tooltip={false} />
						</div>
						Select
					</>
				),
				onClick: questionId => {
					addNewQuestion('multi-select', questionId);
				},
			},
			{
				name: 'open-ended',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="open-ended" size="large" tooltip={false} />
						</div>
						Open-ended
					</>
				),
				onClick: questionId => {
					addNewQuestion('open-ended', questionId);
				},
			},
			{
				name: 'grid',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="grid" size="large" tooltip={false} />
						</div>
						Grid
					</>
				),
				onClick: questionId => {
					addNewQuestion('grid', questionId);
				},
			},
			{
				name: 'emoji',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="emoji" size="large" tooltip={false} />
						</div>
						Emoji
					</>
				),
				onClick: questionId => {
					addNewQuestion('emoji', questionId);
				},
			},
			{
				name: 'Heatmap',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="heatmap" size="medium" tooltip={false} />
						</div>
						Heatmap
					</>
				),
				onClick: questionId => {
					addNewQuestion('heatmap', questionId);
				},
			},
			{
				name: 'ranked',
				label: (
					<>
						<div className={el('icon')}>
							<QuestionTypeIcon questionType="ranked" size="medium" tooltip={false} />
						</div>
						Rank
					</>
				),
				onClick: questionId => {
					addNewQuestion('ranked', questionId);
				},
			},
		],
		[addNewQuestion, el],
	);

	const getQuestions = useCallback(
		() => (tempTemplate ? tempTemplate.customQuestions : questions),
		[questions, tempTemplate],
	); // audience or manage study uses different objects
	/**
	 * Move focus to next question
	 * @param {*} currentSortOrder
	 */
	const setFocusOnNextQuestion = useCallback(
		questionId => {
			const questionsArr = getQuestions(); // audience or manage study uses different objects
			const currentQuestionIdx = questionsArr.findIndex(question => question.id === questionId);
			if (currentQuestionIdx < questionsArr.length - 1) {
				// if last on page, dont do anything
				setFocusOnQuestion({ questionId: questionsArr[currentQuestionIdx + 1].id });
			}
		},
		[getQuestions],
	);

	/**
	 * Move focus to last question, unless its the first on the page
	 * If question is open-ended then focus on the question, otherwise focus on the last answer
	 * @param {*} currentSortOrder
	 */
	const setFocusOnLastQuestion = useCallback(
		questionId => {
			const questionsArr = getQuestions(); // audience or manage study uses different objects
			const currentQuestionIdx = questionsArr.findIndex(question => question.id === questionId);

			// relying on ordering of section object - sortOrder only accurate when first entering page
			if (currentQuestionIdx > 0) {
				// if first on page, dont do anything
				const lastQuestion = questionsArr[currentQuestionIdx - 1];
				if (
					lastQuestion.options.length === 0 ||
					lastQuestion.style === CONSTANTS.questions.options.style.openEnded ||
					!focusOnOption(lastQuestion.id, lastQuestion.options.length - 1)
				) {
					setFocusOnQuestion({ questionId: lastQuestion.id });
				}
			}
		},
		[focusOnOption, getQuestions],
	);

	const getNewQuestionsOrder = useCallback(
		(questionsArray, sourceIndex, destinationIndex) => {
			const newQuestions = [];
			if (sourceIndex === destinationIndex) {
				return questionsArray;
			}
			questionsArray.forEach((sect, index) => {
				if (index === sourceIndex) {
					// skip
				} else if (index === destinationIndex) {
					if (sourceIndex < destinationIndex) {
						newQuestions.push(sect);
					}
					newQuestions.push(questions[sourceIndex]);
					if (sourceIndex > destinationIndex) {
						newQuestions.push(sect);
					}
				} else {
					newQuestions.push(sect);
				}
			});
			return newQuestions;
		},
		[questions],
	);

	const updatePipingErrorLabel = useCallback(() => {
		labelUpdates.forEach(q => {
			if (q.sectionType === 'statement') {
				const statementData = { sectionId: q.sectionId };
				const currentStatementTranslation =
					q.translations && q.translations.find(t => t.languageCode === language);
				if (currentStatementTranslation) {
					const updatedStatementValue = QuillMentionUtil.updatePipingErrorLabelSpecific(
						currentStatementTranslation.text,
						pipingErrorIds,
					);
					const translations = q.translations.map(translation => ({
						id: translation.id,
						languageCode: translation.languageCode,
						text: translation.languageCode === language ? updatedStatementValue : translation.text,
						buttonText: translation.buttonText,
					}));
					statementData.translations = translations;

					patchStatement(q.id, statementData);
				}
			} else {
				const newLabel = QuillMentionUtil.updatePipingErrorLabelSpecific(q.label, pipingErrorIds);
				updateQuestionLabel(q.id, newLabel, language, false);
			}
		});
		setLabelUpdates([]);
		setPipingErrorIds([]);
	}, [labelUpdates, language, patchStatement, pipingErrorIds, updateQuestionLabel]);

	const getPipingError = useCallback(
		(dragResult = null, deletedQuestionId = null) => {
			let pipingError = false;

			// onReorder of questions
			if (dragResult) {
				// Check moved question label for piping
				const draggedQuestion = questions && questions.find(q => q.id === Number(dragResult.draggableId));
				const sourceIndex = dragResult.source.index;
				const destinationIndex = dragResult.destination.index;
				const dragDirection = sourceIndex > destinationIndex ? 'up' : 'down';

				// If moving question up, need to make sure that question does not contain piping of questions before it
				if (dragDirection === 'up') {
					const questionTranslation = draggedQuestion.translations.find(
						translation => translation.languageCode === language,
					);
					const questionHasPiping = QuillMentionUtil.isPipedQuestion(questionTranslation.label);

					// If dragged question has piping, find all piped questions in that question
					if (questionHasPiping) {
						const strippedLabel = misc.stripHtmlTags(questionTranslation.label);
						const pipedQuestionIds = QuillMentionUtil.getQuestionIds(strippedLabel);

						// Find questions between destinationIndex and sourceIndex, loop through content and ensure that the questions do not include the piped questions in the dragged question
						const questionsOfInterest = section.questions.filter((q, i) => {
							if (i >= destinationIndex && i < sourceIndex) {
								return !!q;
							}
							return false;
						});

						const pipingErrorIdsArray = [];
						questionsOfInterest.forEach(q => {
							pipedQuestionIds.forEach(id => {
								if (q.id === parseInt(id)) {
									if (!pipingErrorIdsArray.includes(id)) {
										pipingErrorIdsArray.push(id);
									}
									pipingError = true;
								}
							});
						});
						if (pipingError) {
							draggedQuestion.sectionType = section.type;
							const labelUpdatesCopy = [...labelUpdates];
							labelUpdatesCopy.push(draggedQuestion);
							setLabelUpdates(labelUpdatesCopy);
							setPipingErrorIds(pipingErrorIdsArray);
						}
					}
				}

				// If moving question down, need to make sure that question questions are not referenced in subsequent question questions
				else if (dragDirection === 'down') {
					const labelUpdatesCopy = [...labelUpdates];
					// Questions that have dragged question content piped into them
					const pipedElements = QuillMentionUtil.getPipedQuestionsWithQuestionId(
						draggedQuestion,
						section,
						sections.content,
						language,
					).filter(e => e.sectionId === section.id);
					if (pipedElements && pipedElements.length > 0) {
						pipedElements.forEach(e => {
							if (e.sortOrder >= sourceIndex && e.sortOrder <= destinationIndex) {
								pipingError = true;
								labelUpdatesCopy.push(e);
							}
						});
					}
					if (pipingError) {
						setLabelUpdates(labelUpdatesCopy);
						setPipingErrorIds([draggedQuestion.id]);
					}
				}
				// onDelete of questions
			} else if (deletedQuestionId) {
				const deletedQuestion = questions && questions.find(q => q.id === deletedQuestionId);
				const pipedQuestionsArray = QuillMentionUtil.getPipedQuestionsWithQuestionId(
					deletedQuestion,
					section,
					sections && sections.content ? sections.content : sections,
					language,
				);
				if (pipedQuestionsArray && pipedQuestionsArray.length > 0) {
					pipingError = true;
				}
			}
			return pipingError;
		},
		[labelUpdates, language, questions, section, sections],
	);

	const onDragEnd = useCallback(
		dragResult => {
			if (!dragResult.destination) {
				return;
			}
			const questionId = dragResult.draggableId.split('-')[0];
			const patchedQuestion = questions.find(
				question => question.id === Number(questionId) || question.id === questionId,
			);
			const myCustomQuestion = tempTemplate?.customQuestions?.find(
				q => q?.id === Number(questionId) || q?.id === questionId,
			);
			setDragResultStateValue(dragResult);
			if (myCustomQuestion?.parentId && dragResult.type.includes('option')) {
				setShowDetatchedModal({ type: 'option', question: myCustomQuestion });
				return;
			}
			if (myCustomQuestion?.parentId && dragResult.type.includes('attributes')) {
				setShowDetatchedModal({ type: 'attribute', question: myCustomQuestion });
				return;
			}
			if (dragResult.type === 'questions') {
				if (tempTemplate) {
					tempTemplate.estimatedIncidenceRate = null;
					// Get the new sorted order
					const newSortedQuestions = getNewQuestionsOrder(
						audience.customQuestions,
						dragResult.source.index,
						dragResult.destination.index,
					);
					// Re-number the questions
					let questionCount = 0;
					const newSortedQuestionsCopy = newSortedQuestions.map(q => {
						q.sortOrder = questionCount;
						questionCount += 1;
						q.studyQuestionNumber = questionCount;
						return q;
					});
					tempTemplate.customQuestions = [...newSortedQuestionsCopy];

					if (audience) {
						const ids = newSortedQuestionsCopy.map(question => question.id);
						reorderQuestionsWithoutSections(studyId || study?.id, ids);
					}

					setTempTemplate({ ...tempTemplate });
				} else if (filterQuestions) {
					const sourceIndex = dragResult.destination.index;
					const destIndex = dragResult.source.index;
					moveInArray(filterQuestions, sourceIndex, destIndex);
					const filterIds = filterQuestions.map(q => q.id);
					reorderQuestionsWithoutSections(study?.id, filterIds);
				} else if (
					!audience &&
					(masking.getMaskingErrorQuestions(dragResult, questions, currentSection?.content) ||
						getPipingError(dragResult, null))
				) {
					setShowPipingErrorModal(true);
				} else {
					reorderQuestions(dragResult.source.index, dragResult.destination.index);
				}
			} else if (dragResult.type.includes('option')) {
				// eslint-disable-next-line no-lonely-if
				if (tempTemplate) {
					tempTemplate.estimatedIncidenceRate = null;
					const { options } = patchedQuestion && patchedQuestion;

					// uncomment the 2nd check to disable others/none to stay on the bottom
					const optionsWithoutPlaceholders = options.filter(opt => !opt.placeholder /* && opt.order < 99 */);

					// Order options based on order attribute
					optionsWithoutPlaceholders.sort((a, b) => (a.order > b.order ? 1 : -1));

					// Move option to new position in array
					moveInArray(optionsWithoutPlaceholders, dragResult.source.index, dragResult.destination.index);

					// Set new order attributes based on new array
					optionsWithoutPlaceholders.forEach((option, key) => {
						if (option.order <= 99) {
							option.order = key;
						}
					});

					// Set questions with new options
					const newQuestions = [
						...questions.map(q =>
							q.id !== patchedQuestion.id
								? q
								: { ...patchedQuestion, options: optionsWithoutPlaceholders },
						),
					];
					tempTemplate.customQuestions = [...newQuestions];

					if (audience) {
						reorderOptionsWithoutSections(studyId || study?.id, questionId, optionsWithoutPlaceholders);
					}

					setTempTemplate({ ...tempTemplate });
				} else if (filterQuestions) {
					const [draggedQuestionId] = dragResult.draggableId.split('-');
					const question = filterQuestions.find(({ id }) => id === parseInt(draggedQuestionId));
					const sourceIndex = dragResult.source.index;
					const destIndex = dragResult.destination.index;
					moveInArray(question.options, sourceIndex, destIndex);
					const reorderedOptions = question.options.map((option, i) => ({
						id: option.id,
						assetId: option.assertId,
						isNoneOfTheAbove: option.isNoneOfTheAbove,
						isOtherSpecify: option.isOtherSpecify,
						lockOrder: option.lockOrder,
						translations: option.translations,
						order: i + 1,
					}));

					updateQuestion(study?.id, questionId, {
						...question,
						options: reorderedOptions,
						useAsFilter: question.isFilter,
						format: question.style,
						translations: [
							{
								en: question.label || question.filterLabel,
								internal: question.label || question.filterLabel,
							},
						],
					});
				} else {
					reorderOptions(
						questionId,
						dragResult.draggableId,
						dragResult.source.index,
						dragResult.destination.index,
					);
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (tempTemplate) {
					tempTemplate.estimatedIncidenceRate = null;

					const { attributes } = patchedQuestion && patchedQuestion;

					// uncomment the 2nd check to disable others/none to stay on the bottom
					const attributesWithoutPlaceholders = attributes.filter(
						attribute => !attribute.placeholder /* && attribute.order < 99 */,
					);

					// Order attributes based on order attribute
					attributesWithoutPlaceholders.sort((a, b) => (a.order > b.order ? 1 : -1));

					// Move attribute to new position in array
					moveInArray(attributesWithoutPlaceholders, dragResult.source.index, dragResult.destination.index);

					// Set new order attributes based on new array
					attributesWithoutPlaceholders.forEach((attribute, key) => {
						attribute.order = key;
					});

					// Set questions with new attributes
					const newQuestions = [
						...questions.map(q =>
							q.id !== patchedQuestion.id
								? q
								: { ...patchedQuestion, attributes: attributesWithoutPlaceholders },
						),
					];
					tempTemplate.customQuestions = [...newQuestions];

					if (audience) {
						reorderAttributesWithoutSections(
							studyId || study?.id,
							questionId,
							attributesWithoutPlaceholders,
						);
					}

					setTempTemplate({ ...tempTemplate });
				} else {
					reorderAttributes(
						questionId,
						dragResult.draggableId,
						dragResult.source.index,
						dragResult.destination.index,
					);
				}
			}
		},
		[
			tempTemplate,
			filterQuestions,
			audience,
			questions,
			currentSection?.content,
			getPipingError,
			getNewQuestionsOrder,
			setTempTemplate,
			reorderQuestionsWithoutSections,
			studyId,
			study,
			reorderQuestions,
			reorderOptionsWithoutSections,
			updateQuestion,
			reorderOptions,
			reorderAttributesWithoutSections,
			reorderAttributes,
		],
	);

	const detatchedReorderingOnConfirmHandler = useCallback(() => {
		if (showDetatchedModal.type === 'option') {
			tempTemplate.estimatedIncidenceRate = null;
			const { options } = showDetatchedModal.question && showDetatchedModal.question;

			// uncomment the 2nd check to disable others/none to stay on the bottom
			const optionsWithoutPlaceholders = options.filter(opt => !opt.placeholder /* && opt.order < 99 */);

			// Order options based on order attribute
			optionsWithoutPlaceholders.sort((a, b) => (a.order > b.order ? 1 : -1));

			// Move option to new position in array
			moveInArray(
				optionsWithoutPlaceholders,
				dragResultStateValue.source.index,
				dragResultStateValue.destination.index,
			);

			// Set new order attributes based on new array
			optionsWithoutPlaceholders.forEach((option, key) => {
				if (option.order <= 99) {
					option.order = key;
				}
			});

			// Set questions with new options
			const newQuestions = [
				...questions.map(q =>
					q.id !== showDetatchedModal.question.id
						? q
						: {
								...showDetatchedModal.question,
								parentId: null,
								options: optionsWithoutPlaceholders,
								filterLabel: null,
								libraryName: null,
						  },
				),
			];
			tempTemplate.customQuestions = [...newQuestions];
			if (audience) {
				reorderOptionsWithoutSections(
					studyId || study?.id,
					showDetatchedModal.question.id,
					optionsWithoutPlaceholders,
				);
			}
		} else if (showDetatchedModal.type === 'attribute') {
			tempTemplate.estimatedIncidenceRate = null;

			const { attributes } = showDetatchedModal.question && showDetatchedModal.question;

			// uncomment the 2nd check to disable others/none to stay on the bottom
			const attributesWithoutPlaceholders = attributes.filter(
				attribute => !attribute.placeholder /* && attribute.order < 99 */,
			);

			// Order attributes based on order attribute
			attributesWithoutPlaceholders.sort((a, b) => (a.order > b.order ? 1 : -1));

			// Move attribute to new position in array
			moveInArray(
				attributesWithoutPlaceholders,
				dragResultStateValue.source.index,
				dragResultStateValue.destination.index,
			);

			// Set new order attributes based on new array
			attributesWithoutPlaceholders.forEach((attribute, key) => {
				attribute.order = key;
			});

			// Set questions with new attributes
			const newQuestions = [
				...questions.map(q =>
					q.id !== showDetatchedModal.question.id
						? q
						: {
								...showDetatchedModal.question,
								attributes: attributesWithoutPlaceholders,
								parentId: null,
								filterLabel: null,
								libraryName: null,
						  },
				),
			];
			tempTemplate.customQuestions = [...newQuestions];

			if (audience) {
				reorderAttributesWithoutSections(
					studyId || study?.id,
					showDetatchedModal.question.id,
					attributesWithoutPlaceholders,
				);
			}
		}

		setTempTemplate({ ...tempTemplate });
		setShowDetatchedModal(false);
	}, [
		tempTemplate,
		audience,
		questions,
		setTempTemplate,
		studyId,
		study,
		reorderOptionsWithoutSections,
		showDetatchedModal,
		dragResultStateValue?.source?.index,
		dragResultStateValue?.destination?.index,
		reorderAttributesWithoutSections,
	]);

	const renderDeleteQuestionModal = useCallback(() => {
		const headerText = 'Delete Question';
		const bodyText =
			filterImportQuestions === true
				? 'Deleting this question will remove it from available filters and all uploaded data will be lost.'
				: 'Deleting a question also removes any logic related to it in other study sections. Are you sure you want to delete this question?';
		const confirmText = 'Delete';
		const cancelText = 'Cancel';
		const pipingError = getPipingError(null, deleteQuestionId);
		let pipedQuestionsString = '';
		let pipingErrorText = '';

		if (pipingError && pipedQuestions && pipedQuestions.length > 0) {
			const pipedQuestionsStringArray = pipedQuestions.map((q, i) => {
				let listItemString = '';
				if (q.sectionType === 'statement') {
					listItemString = `Section ${q.sectionOrder} - ${q.sectionName || 'Statement'}`;
				} else if (q.sectionType === 'questions') {
					listItemString = `Q${q.studyQuestionNumber}`;
				} else if (q.sectionType === 'monadic_split') {
					listItemString = `Section ${q.sectionOrder} - ${q.sectionName || 'Idea Split'}: Q${
						q.sortOrder + 1
					}`;
				}
				if (i === pipedQuestions.length - 1) {
					listItemString = listItemString.concat('', '.');
				}
				return listItemString;
			});
			pipedQuestionsString = pipedQuestionsStringArray.join(', ');

			pipingErrorText = QuillMentionUtil.getPipingErrorText(pipedQuestionsString);
		}

		return (
			<Modal
				show={showDeleteQuestionModal}
				width={600}
				padding={0}
				onClose={() => {
					setShowDeleteQuestionModal(false);
					setDeleteQuestionId(null);
				}}
			>
				<AreYouSureForm
					headerText={headerText}
					bodyText={bodyText}
					confirmText={confirmText}
					cancelText={cancelText}
					onConfirm={() => {
						if (screeningQuestion) {
							deleteScreeningQuestion(deleteQuestionId);
						}
						if (tempTemplate) {
							tempTemplate.estimatedIncidenceRate = null;
							tempTemplate.customQuestions = tempTemplate.customQuestions.filter(
								q => q.id !== deleteQuestionId,
							);
							setTempTemplate({ ...tempTemplate });
						} else if (audience) {
							deleteCustomQualifier(deleteQuestionId, audience);
						} else {
							updatePipingErrorLabel();
							deleteQuestion(deleteQuestionId);
						}
						setShowDeleteQuestionModal(false);
						setDeleteQuestionId(null);
					}}
					onCancel={() => {
						setShowDeleteQuestionModal(false);
						setDeleteQuestionId(null);
					}}
					additionalText={pipingErrorText}
				/>
			</Modal>
		);
	}, [
		audience,
		deleteCustomQualifier,
		deleteQuestion,
		deleteQuestionId,
		filterImportQuestions,
		getPipingError,
		pipedQuestions,
		setTempTemplate,
		showDeleteQuestionModal,
		tempTemplate,
		updatePipingErrorLabel,
		deleteScreeningQuestion,
		screeningQuestion,
	]);

	const onPipingErrorModalConfirm = useCallback(() => {
		setShowPipingErrorModal(false);
		setDragResultStateValue(null);
		setLabelUpdates([]);
	}, []);

	const onPipingErrorModalCancel = useCallback(() => {
		reorderQuestions(dragResultStateValue.source.index, dragResultStateValue.destination.index);
		updatePipingErrorLabel();
		setShowPipingErrorModal(false);
		setDragResultStateValue(null);
	}, [dragResultStateValue, reorderQuestions, updatePipingErrorLabel]);

	const innerDeleteQuestion = useCallback(
		id => {
			setDeleteQuestionId(id);
			setShowDeleteQuestionModal(true);
		},
		[setDeleteQuestionId, setShowDeleteQuestionModal],
	);

	const updateOptionAsset = useCallback(
		(questionId, optionId, data) => {
			if (tempTemplate) patchQuestionOption(tempTemplate?.studyId, questionId, optionId, data);
			else patchQuestionOption(study?.id, questionId, optionId, data);
		},
		[patchQuestionOption, study, tempTemplate],
	);

	const updateAttributeAsset = useCallback(
		async (questionId, attributeId, data) => {
			if (tempTemplate) {
				await patchQuestionAttribute(tempTemplate?.studyId, questionId, attributeId, data);
				const updatedQuestion = questions.find(q => q.id === questionId);
				if (updatedQuestion?.parentId) {
					const newQuestions = [
						...questions.map(q =>
							q.id !== questionId
								? q
								: {
										...q,
										parentId: null,
										filterLabel: null,
										libraryName: null,
								  },
						),
					];
					tempTemplate.customQuestions = [...newQuestions];
					setTempTemplate({ ...tempTemplate });
				}
			} else patchQuestionAttribute(study?.id, questionId, attributeId, data);
		},
		[patchQuestionAttribute, study, tempTemplate, questions, setTempTemplate],
	);

	useEffect(() => {
		if (currentSection?.content && !audience) {
			if (currentSection?.content?.id !== section?.id) {
				setSection(currentSection?.content);
			}
		}
		// eslint-disable-next-line
	}, [currentSection]);

	useEffect(() => {
		// If user has added a custom target, at least one question requires disqualification logic
		if (audience && audience.customQuestions && audience.customQuestions.length > 0) {
			const questionsWithDisqualificationLogic = audience.customQuestions.filter(question =>
				question.logic.some(logic => logic.actionType === 'terminate'),
			);

			if (questionsWithDisqualificationLogic.length > 0) {
				if (setAudienceReady) {
					setAudienceReady(true);
				}
				setLogicMissing(false);
			} else {
				if (setAudienceReady) {
					setAudienceReady(false);
				}
				setLogicMissing(true);
			}
		} else {
			if (setAudienceReady) {
				setAudienceReady(true);
			}
			setLogicMissing(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [audience]);

	useEffect(() => {
		if (deleteQuestionId) {
			const deletedQuestion = questions && questions.find(q => q.id === deleteQuestionId);
			const pipedQuestionsArray = QuillMentionUtil.getPipedQuestionsWithQuestionId(
				deletedQuestion,
				section,
				sections && sections.content ? sections.content : sections,
				language,
			);
			setPipedQuestions(pipedQuestionsArray);
			setLabelUpdates(pipedQuestionsArray);
			setPipingErrorIds([deleteQuestionId]);
		} else {
			if (pipedQuestions?.length) {
				setPipedQuestions([]);
			}
			if (pipingErrorIds?.length) {
				setPipingErrorIds([]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteQuestionId]);

	// TODO: Find a way to hide that no questions state flicker
	return (
		<div className={audience ? el('custom-qualifiers') : `${className} paper tight rounded`}>
			{audience && !tempTemplate ? (
				<p className={el('custom-qualifiers-subheading')}>
					Write custom qualifications for this study in
					<span> {languageData ? languageData.label : 'English'}</span>.
				</p>
			) : null}
			{section && showHeader ? (
				<div className={el('header')}>
					<SectionHeader
						title={section ? section.name : 'hello'}
						subtitle="Question"
						sectionId={section ? section.id : null}
						studyId={study?.id || tempTemplate?.studyId}
						patchSectionName={patchSectionName}
						showHelp={false}
						icon={<QuestionCheckmark />}
						customClasses="has-tabs-below"
					/>
				</div>
			) : null}
			{!screeningQuestion && audience && logicMissing && (
				<div className={el('logic-missing-warning')}>
					<Row>
						<Col xs={1} className={el('warning-icon')}>
							<WarningIcon className="exclamation-triangle" />
						</Col>
						<Col xs={11}>
							At least one custom target must have disqualification logic to continue with this audience.
						</Col>
					</Row>
				</div>
			)}
			{questions ? (
				<div
					className={`${el('content')} ${
						section && !audience && (!questions || questions.length < 1) ? el('empty') : ''
					}`}
				>
					{showEmptyQuestionSection && section && !audience && (!questions || questions.length < 1) && (
						<EmptyQuestionSection addQuestionOptions={addQuestionOptions} />
					)}
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable" type="questions">
							{(provided, snapshot) => (
								<div
									className={`drop-zone ${snapshot.isDragging ? ' drag' : ''}`}
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{questions.map((q, i) => (
										<Draggable
											key={`draggable-question-${q.id}-position-${i}`}
											index={i}
											draggableId={`${q.id}`}
										>
											{(draggableProvided, draggableSnapshot) => (
												<div
													className={el(
														'question-item',
														`${q.type}${draggableSnapshot.isDragging ? ' drag' : ''}`,
													)}
													// TODO - make this work
													ref={draggableProvided.innerRef}
													{...draggableProvided.draggableProps}
													{...draggableProvided.dragHandleProps}
												>
													{audience ? (
														<QuestionItem
															studyId={studyId || study?.id}
															setFocusOnNextQuestion={setFocusOnNextQuestion}
															setFocusOnLastQuestion={setFocusOnLastQuestion}
															focusOnQuestion={focusOnQuestion}
															setFocusOnQuestion={setFocusOnQuestion}
															focusOnOption={focusOnOption}
															key={`question-${q.id}-item-${i}`}
															audience={audience}
															logic={q?.logic}
															question={q}
															questions={questions}
															studyQuestionNumber={
																section && section.type === 'monadic_split'
																	? i + 1
																	: q.studyQuestionNumber || i + 1
															}
															section={section}
															sections={sections}
															sectionOrder={section ? section.order : ''}
															language={selectedLanguage}
															addQuestionOptions={addQuestionOptions}
															deleteQuestion={innerDeleteQuestion}
															updateCustomQualifierLabel={updateCustomQualifierLabel}
															updateQuestionLabel={updateQuestionLabel}
															addQuestionOption={addQuestionOption}
															updateOptionLabel={updateOptionLabel}
															updateOptionScalePoint={updateOptionScalePoint}
															setOptionScalePointValue={setOptionScalePointValue}
															updateOptionAsset={updateOptionAsset}
															removeQuestionOption={removeQuestionOption}
															patchItem={audience ? patchAudienceItem : patchItem}
															deleteItemLogic={
																audience ? deleteAudienceItemLogic : deleteItemLogic
															}
															addOptionPlaceholder={
																audience
																	? addCustomQualifierOptionPlaceholder
																	: addOptionPlaceholder
															}
															addAttributePlaceholder={
																audience
																	? addCustomQualifierAttributePlaceholder
																	: addAttributePlaceholder
															}
															removeOptionPlaceholder={removeOptionPlaceholder}
															patchQuestion={
																tempTemplate
																	? updateScreeningQuestionOption
																	: patchQuestion
															}
															createQuestionSetting={createQuestionSetting}
															patchQuestionSetting={patchQuestionSetting}
															tempTemplate={tempTemplate}
															setTempTemplate={setTempTemplate}
															blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
															noNewQuestion={noNewQuestion}
															removeAttributePlaceholder={removeAttributePlaceholder}
															addQuestionAttribute={addQuestionAttribute}
															removeQuestionAttribute={removeQuestionAttribute}
															updateAttributeLabel={updateAttributeLabel}
															updateAttributeAsset={updateAttributeAsset}
															filterImportQuestions={filterImportQuestions}
															activeQuestion={activeQuestion}
															setActiveQuestion={setActiveQuestion}
															optionTempIds={optionTempIds}
															setOptionTempIds={setOptionTempIds}
															attributeTempIds={attributeTempIds}
															setAttributeTempIds={setAttributeTempIds}
															familyDomain={familyDomain}
															screeningQuestion={screeningQuestion}
															setOpenQuestionLibrary={setOpenQuestionLibrary}
															changeScreeningQuestionVisibility={
																changeScreeningQuestionVisibility
															}
															updateScreeningOption={updateScreeningOption}
															updateScreeningOptionScalePoint={
																updateScreeningOptionScalePoint
															}
															setScreeningOptionScalePointValue={
																setScreeningOptionScalePointValue
															}
															updateScreeningAttribute={updateScreeningAttribute}
															deleteScreeningOption={deleteScreeningOption}
															deleteScreeningAttribute={deleteScreeningAttribute}
															updateScreeningQuota={updateScreeningQuota}
															updateAllScreeningOptionQuota={
																updateAllScreeningOptionQuota
															}
															deleteAllScreeningOptionQuota={
																deleteAllScreeningOptionQuota
															}
															patchScreeningQuestionSetting={
																patchScreeningQuestionSetting
															}
															addScreeningQuestionOption={addScreeningQuestionOption}
															deleteScreeningQuestionOption={
																deleteScreeningQuestionOption
															}
															updateScreeningQuestionLabel={updateScreeningQuestionLabel}
															audienceTemplate={audienceTemplate}
															screeningQuestionAddQuestionOptions={
																screeningQuestionAddQuestionOptions
															}
															batchUpdateAudienceTemplateScreeningQuestionOptions={
																batchUpdateAudienceTemplateScreeningQuestionOptions
															}
															batchSetAudienceTemplateScreeningQuestionOptions={
																batchSetAudienceTemplateScreeningQuestionOptions
															}
															dragHandleContextId={
																draggableProvided?.dragHandleProps?.[
																	'data-rbd-drag-handle-context-id'
																]
															}
														/>
													) : (
														<QuestionItem
															studyId={studyId || study?.id}
															setFocusOnNextQuestion={setFocusOnNextQuestion}
															setFocusOnLastQuestion={setFocusOnLastQuestion}
															focusOnQuestion={focusOnQuestion}
															setFocusOnQuestion={setFocusOnQuestion}
															focusOnOption={focusOnOption}
															key={`question-${q.id}-item-${i}`}
															audience={audience}
															logic={q?.logic}
															question={q}
															questions={questions}
															studyQuestionNumber={
																section && section.type === 'monadic_split'
																	? i + 1
																	: q.studyQuestionNumber || i + 1
															}
															section={section}
															sections={sections}
															sectionOrder={section ? section.order : ''}
															language={selectedLanguage}
															addQuestionOptions={addQuestionOptions}
															deleteQuestion={innerDeleteQuestion}
															updateCustomQualifierLabel={updateCustomQualifierLabel}
															updateQuestionLabel={updateQuestionLabel}
															addQuestionOption={addQuestionOption}
															updateOptionLabel={updateOptionLabel}
															updateOptionScalePoint={updateOptionScalePoint}
															setOptionScalePointValue={setOptionScalePointValue}
															updateOptionAsset={updateOptionAsset}
															removeQuestionOption={removeQuestionOption}
															patchItem={audience ? patchAudienceItem : patchItem}
															deleteItemLogic={
																audience ? deleteAudienceItemLogic : deleteItemLogic
															}
															addOptionPlaceholder={
																audience
																	? addCustomQualifierOptionPlaceholder
																	: addOptionPlaceholder
															}
															addAttributePlaceholder={
																audience
																	? addCustomQualifierAttributePlaceholder
																	: addAttributePlaceholder
															}
															removeOptionPlaceholder={removeOptionPlaceholder}
															patchQuestion={
																tempTemplate
																	? updateScreeningQuestionOption
																	: patchQuestion
															}
															createQuestionSetting={createQuestionSetting}
															patchQuestionSetting={patchQuestionSetting}
															tempTemplate={tempTemplate}
															setTempTemplate={setTempTemplate}
															blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
															noNewQuestion={noNewQuestion}
															removeAttributePlaceholder={removeAttributePlaceholder}
															addQuestionAttribute={addQuestionAttribute}
															removeQuestionAttribute={removeQuestionAttribute}
															updateAttributeLabel={updateAttributeLabel}
															updateAttributeAsset={updateAttributeAsset}
															filterImportQuestions={filterImportQuestions}
															activeQuestion={activeQuestion}
															setActiveQuestion={setActiveQuestion}
															optionTempIds={optionTempIds}
															setOptionTempIds={setOptionTempIds}
															attributeTempIds={attributeTempIds}
															setAttributeTempIds={setAttributeTempIds}
															familyDomain={familyDomain}
															screeningQuestion={screeningQuestion}
															setOpenQuestionLibrary={setOpenQuestionLibrary}
															changeScreeningQuestionVisibility={
																changeScreeningQuestionVisibility
															}
															updateScreeningOption={updateScreeningOption}
															updateScreeningOptionScalePoint={
																updateScreeningOptionScalePoint
															}
															setScreeningOptionScalePointValue={
																setScreeningOptionScalePointValue
															}
															updateScreeningAttribute={updateScreeningAttribute}
															deleteScreeningOption={deleteScreeningOption}
															deleteScreeningAttribute={deleteScreeningAttribute}
															updateScreeningQuota={updateScreeningQuota}
															updateAllScreeningOptionQuota={
																updateAllScreeningOptionQuota
															}
															deleteAllScreeningOptionQuota={
																deleteAllScreeningOptionQuota
															}
															patchScreeningQuestionSetting={
																patchScreeningQuestionSetting
															}
															addScreeningQuestionOption={addScreeningQuestionOption}
															deleteScreeningQuestionOption={
																deleteScreeningQuestionOption
															}
															updateScreeningQuestionLabel={updateScreeningQuestionLabel}
															batchUpdateAudienceTemplateScreeningQuestionOptions={
																batchUpdateAudienceTemplateScreeningQuestionOptions
															}
															batchSetAudienceTemplateScreeningQuestionOptions={
																batchSetAudienceTemplateScreeningQuestionOptions
															}
														/>
													)}
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					{!screeningQuestion && !noNewQuestion && questions?.length > 0 && (
						<div className="add-answer-button-container">
							<AddQuestionButton
								audience={audience}
								question={questions[questions.length - 1]}
								addQuestionOptions={addQuestionOptions}
								isLastQuestionItem
								tempTemplate={tempTemplate}
								blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
							/>
						</div>
					)}
				</div>
			) : null}
			{showEmptyQuestionSection && audience && questions.length === 0 && (
				<EmptyQuestionSection
					addQuestionOptions={addQuestionOptions}
					blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
					audience={!!(audience || tempTemplate)}
					screeningQuestion={screeningQuestion}
				/>
			)}
			{showDeleteQuestionModal && renderDeleteQuestionModal()}
			{showPipingErrorModal && (
				<PipingErrorModal
					onPipingErrorModalConfirm={onPipingErrorModalConfirm}
					onPipingErrorModalCancel={onPipingErrorModalCancel}
					showPipingErrorModal={showPipingErrorModal}
					setShowPipingErrorModal={setShowPipingErrorModal}
				/>
			)}
			<Tooltip
				id={`${className}-tooltip-optional-question}`}
				ref={assignTooltipRef}
				className={el('tooltip')}
				animation="shift"
				animationFill={false}
				interactive
				style={tooltipStyle}
				theme="basic-text-tooltip"
				html={
					<div>
						The referenced question is currently set to optional. This may result in audiences members
						seeing an empty recall.
					</div>
				}
			/>
			<DetatchedScreenerModal
				show={showDetatchedModal}
				onClose={() => {
					setShowDetatchedModal(false);
				}}
				onConfirm={() => {
					detatchedReorderingOnConfirmHandler();
				}}
			/>
		</div>
	);
};

const mapDispatchToProps = dispatch => ({
	addSectionQuestion: (sectionId, type, sortOrder, questionId) =>
		dispatch(actions.addSectionQuestion(sectionId, type, sortOrder, questionId)),
	addAudienceQuestion: (audienceUuid, type, sortOrder, questionId) =>
		dispatch(actions.addAudienceQuestion(audienceUuid, type, sortOrder, questionId)),
	deleteQuestion: questionId => dispatch(actions.deleteSectionQuestion(questionId)),
	deleteCustomQualifier: (questionId, audience) => dispatch(actions.deleteCustomQualifier(questionId, audience)),
	patchQuestion: (questionId, data) => dispatch(actions.patchQuestion(questionId, data)),
	patchQuestionOption: (studyId, questionId, optionId, data) =>
		dispatch(actions.patchQuestionOption(studyId, questionId, optionId, data)),
	createQuestionSetting: (questionId, data) => dispatch(actions.createQuestionSetting(questionId, data)),
	patchQuestionSetting: (studyId, questionId, settingId, data) =>
		dispatch(actions.patchQuestionSetting(studyId, questionId, settingId, data)),
	reorderQuestions: (sourceIndex, destinationIndex) =>
		dispatch(actions.reorderQuestions(sourceIndex, destinationIndex)),
	patchSectionName: (studyId, sectionId, statementName) =>
		dispatch(actions.patchSectionName(studyId, sectionId, statementName)),
	updateQuestion: (studyId, questionId, data) => dispatch(actions.updateQuestion(studyId, questionId, data)),
	updateQuestionLabel: (questionId, label, languageCode, refetch) =>
		dispatch(actions.updateQuestionLabel(questionId, label, languageCode, refetch)),
	updateCustomQualifierLabel: (questionId, label, languageCode, audience) =>
		dispatch(actions.updateCustomQualifierLabel(questionId, label, languageCode, audience)),
	addQuestionOption: (questionId, option) => dispatch(actions.addQuestionOption(questionId, option)),
	updateOptionLabel: (optionId, translationId, questionId, label, languageCode, filterImports) =>
		dispatch(actions.updateOptionLabel(optionId, translationId, questionId, label, languageCode, filterImports)),
	updateOptionScalePoint: ({ questionId, optionId, scalePoint }) =>
		dispatch(actions.updateOptionScalePoint({ questionId, optionId, scalePoint })),
	setOptionScalePointValue: ({ questionId, optionId, scalePoint }) =>
		dispatch(actions.setOptionScalePointValue({ questionId, optionId, scalePoint })),
	removeQuestionOption: (questionId, optionId) => dispatch(actions.removeQuestionOption(questionId, optionId)),
	removeOptionPlaceholder: (questionId, answerId) => dispatch(actions.removeOptionPlaceholder(questionId, answerId)),
	addOptionPlaceholder: questionId => dispatch(actions.addOptionPlaceholder(questionId)),
	addCustomQualifierOptionPlaceholder: questionId =>
		dispatch(actions.addCustomQualifierOptionPlaceholder(questionId)),
	patchItem: (studyId, sectionId, itemId, itemData) =>
		dispatch(actions.patchItem(studyId, sectionId, itemId, itemData)),
	patchAudienceItem: (studyId, sectionId, itemId, itemData) =>
		dispatch(actions.patchAudienceItem(studyId, sectionId, itemId, itemData)),
	deleteItemLogic: (studyId, sectionId, itemId, logicId) =>
		dispatch(actions.deleteItemLogic(studyId, sectionId, itemId, logicId)),
	deleteAudienceItemLogic: (studyId, audienceUuid, itemId, logicId) =>
		dispatch(actions.deleteAudienceItemLogic(studyId, audienceUuid, itemId, logicId)),
	addCustomQualifierAttributePlaceholder: questionId =>
		dispatch(actions.addCustomQualifierAttributePlaceholder(questionId)),
	addAttributePlaceholder: questionId => dispatch(actions.addAttributePlaceholder(questionId)),
	removeAttributePlaceholder: (questionId, attributeId) =>
		dispatch(actions.removeAttributePlaceholder(questionId, attributeId)),
	addQuestionAttribute: (questionId, attribute) => dispatch(actions.addQuestionAttribute(questionId, attribute)),
	removeQuestionAttribute: (questionId, attributeId) =>
		dispatch(actions.removeQuestionAttribute(questionId, attributeId)),
	updateAttributeLabel: (attributeId, translationId, questionId, label, languageCode) =>
		dispatch(actions.updateAttributeLabel(attributeId, translationId, questionId, label, languageCode)),
	reorderAttributes: (questionId, attributeId, sourceIndex, destinationIndex) =>
		dispatch(actions.reorderAttributes(questionId, attributeId, sourceIndex, destinationIndex)),
	reorderOptions: (questionId, optionId, sourceIndex, destinationIndex, suppliedQuestions) =>
		dispatch(actions.reorderOptions(questionId, optionId, sourceIndex, destinationIndex, suppliedQuestions)),
	patchQuestionAttribute: (studyId, questionId, attributeId, data) =>
		dispatch(actions.patchQuestionAttribute(studyId, questionId, attributeId, data)),
	reorderQuestionsWithoutSections: (studyId, questionIds) =>
		dispatch(actions.reorderQuestionsWithoutSections(studyId, questionIds)),
	patchStatement: (statementId, statementData) => dispatch(actions.patchStatement(statementId, statementData)),
	setSections: sections => dispatch(actions.setSections(sections)),
	changeScreeningQuestionVisibility: (studyId, questionId, isGlobalQualifier, visibleAudiences, isTemplate) =>
		dispatch(
			actions.changeScreeningQuestionVisibility(
				studyId,
				questionId,
				isGlobalQualifier,
				visibleAudiences,
				isTemplate,
			),
		),
	reorderOptionsWithoutSections: (studyId, questionId, options) =>
		dispatch(actions.reorderOptionsWithoutSections(studyId, questionId, options)),
	reorderAttributesWithoutSections: (studyId, questionId, attributes) =>
		dispatch(actions.reorderAttributesWithoutSections(studyId, questionId, attributes)),
});

QuestionsSection.propTypes = {
	data: PropTypes.object,
	languages: PropTypes.any,
	audience: PropTypes.object,
	audiences: PropTypes.any,
	sections: PropTypes.object,
	study: PropTypes.object,
	addSectionQuestion: PropTypes.func,
	addAudienceQuestion: PropTypes.func,
	deleteQuestion: PropTypes.func,
	reorderQuestions: PropTypes.func,
	patchSectionName: PropTypes.func,
	patchQuestion: PropTypes.func,
	patchQuestionOption: PropTypes.func,
	createQuestionSetting: PropTypes.func,
	patchQuestionSetting: PropTypes.func,
	updateQuestionLabel: PropTypes.func,
	addQuestionOption: PropTypes.func,
	updateOptionLabel: PropTypes.func,
	updateOptionScalePoint: PropTypes.func,
	setOptionScalePointValue: PropTypes.func,
	reorderOptions: PropTypes.func,
	removeQuestionOption: PropTypes.func,
	patchItem: PropTypes.func,
	patchAudienceItem: PropTypes.func,
	deleteItemLogic: PropTypes.func,
	deleteAudienceItemLogic: PropTypes.func,
	addOptionPlaceholder: PropTypes.func,
	addCustomQualifierOptionPlaceholder: PropTypes.func,
	removeOptionPlaceholder: PropTypes.func,
	logic: PropTypes.any,
	className: PropTypes.string,
	deleteCustomQualifier: PropTypes.func,
	updateCustomQualifierLabel: PropTypes.func,
	language: PropTypes.string,
	tempTemplate: PropTypes.object,
	setTempTemplate: PropTypes.func,
	blockUpdateOrBreakTemplate: PropTypes.func,
	setAudienceReady: PropTypes.func,
	showHeader: PropTypes.bool,
	showEmptyQuestionSection: PropTypes.bool,
	addCustomQualifierAttributePlaceholder: PropTypes.func,
	addAttributePlaceholder: PropTypes.func,
	removeAttributePlaceholder: PropTypes.func,
	addQuestionAttribute: PropTypes.func,
	removeQuestionAttribute: PropTypes.func,
	updateAttributeLabel: PropTypes.func,
	reorderAttributes: PropTypes.func,
	patchQuestionAttribute: PropTypes.func,
	filterImportQuestions: PropTypes.bool,
	patchStatement: PropTypes.func,
	setSections: PropTypes.func,
	updateQuestion: PropTypes.func,
	screeningQuestion: PropTypes.bool,
	screeningQuestionAddQuestionOptions: PropTypes.bool,
	filterQuestions: PropTypes.any,
	noNewQuestion: PropTypes.any,
	reorderQuestionsWithoutSections: PropTypes.any,
	familyDomain: PropTypes.any,
	setOpenQuestionLibrary: PropTypes.func,
	deleteScreeningQuestion: PropTypes.func,
	changeScreeningQuestionVisibility: PropTypes.func,
	updateScreeningOption: PropTypes.func,
	updateScreeningOptionScalePoint: PropTypes.func,
	setScreeningOptionScalePointValue: PropTypes.func,
	updateScreeningAttribute: PropTypes.func,
	deleteScreeningOption: PropTypes.func,
	deleteScreeningAttribute: PropTypes.func,
	updateScreeningQuota: PropTypes.func,
	updateAllScreeningOptionQuota: PropTypes.func,
	deleteAllScreeningOptionQuota: PropTypes.func,
	patchScreeningQuestionSetting: PropTypes.func,
	addScreeningQuestionOption: PropTypes.func,
	deleteScreeningQuestionOption: PropTypes.func,
	studyId: PropTypes.number,
	updateScreeningQuestionLabel: PropTypes.func,
	updateScreeningQuestionOption: PropTypes.func,
	initialSelectLanguage: PropTypes.string,
	audienceTemplate: PropTypes.any,
	reorderOptionsWithoutSections: PropTypes.func,
	reorderAttributesWithoutSections: PropTypes.func,
	batchUpdateAudienceTemplateScreeningQuestionOptions: PropTypes.func,
	batchSetAudienceTemplateScreeningQuestionOptions: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(QuestionsSection);
