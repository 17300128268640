import React, { useState, useEffect, useMemo } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import QuestionsViewMode from 'src/components/shared/QuestionsViewMode';
import ProductCardsPreview from 'src/components/shared/ProductCardsPreview';

import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'idea-splits-overview';

const IdeaSplitsOverview = ({ section, sections, study, selectedLanguage }) => {
	const [currentTab, setCurrentTab] = useState('ideas');
	const questions = useMemo(() => section?.questions || [], [section]);
	const products = useMemo(() => section?.products || [], [section]);
	const isEmpty = useMemo(() => !questions?.length && !products?.length, [questions, products]);

	useEffect(() => {
		if (products?.length && !questions.length) {
			setCurrentTab('ideas');
		}
		if (!products?.length && questions.length) {
			setCurrentTab('questions');
		}
	}, [products, questions]);

	const getLangClass = lang => (lang === selectedLanguage ? 'lang_active' : 'lang');

	const renderLangTags = () => {
		const languages = questions[0]?.translations?.map(({ languageCode }) => languageCode);

		const langTags = [];

		if (languages?.length >= 1) {
			langTags.push(
				<span key="language-tag-1" className={el(getLangClass(languages[0]))}>
					{languages[0]}
				</span>,
			);
		}

		if (languages?.length > 1) {
			langTags.push(
				<span key="language-tag-2" className={el('lang')}>
					•
				</span>,
			);
			langTags.push(
				<span key="language-tag-3" className={el(getLangClass(languages[1]))}>
					{languages[1]}
				</span>,
			);
			if (languages?.length > 2) {
				langTags.push(
					<span key="language-tag-4" className={el('lang')}>
						+{languages.length - 2}
					</span>,
				);
			}
		}

		return <div className={el('tag')}>{langTags.map(lang => lang)}</div>;
	};

	const renderEmptyState = () => (
		<>
			<span className={el('sub-title')}>Idea Splits</span>
			<span className={el('title')}> {section?.name || 'Study Section'}</span>
			<div className={el('empty')}>
				<span className={el('empty-title')}>No Idea Split in this template</span>
				<span className={el('empty-subtitle')}>
					You can add your own Idea Split once you start using this template.
				</span>
			</div>
		</>
	);

	return (
		<div className={el('overview')}>
			{isEmpty ? (
				renderEmptyState()
			) : (
				<>
					<span className={el('sub-title')}>Idea Splits</span>
					<span className={el('title')}> {section?.name || 'Study Section'}</span>
					<span className={el('description')}>
						An Idea Split is when you have a lot of ideas and want to show only a few to each audience
						member so they can answer follow up questions on each.
						<a
							rel="noreferrer"
							target="_blank"
							href="https://support.upsiide.com/en/articles/6295999-understanding-and-using-the-idea-split"
						>
							{` Read more about this`}
						</a>
					</span>
					<div className={el('card_container')}>
						<div
							className={el(
								`card${currentTab === 'ideas' ? ' active' : ''}${!products?.length ? ' disabled' : ''}`,
							)}
							aria-hidden
							onClick={() => setCurrentTab('ideas')}
						>
							<span className={el('card_title')}>Ideas</span>
							<span className={el('card_number')}>{products?.length}</span>
							<span className={el('card_desc')}>ideas available to each audience member</span>
						</div>
						<div
							className={el(
								`card${currentTab === 'questions' ? ' active' : ''}${
									!questions?.length ? ' disabled' : ''
								}`,
							)}
							aria-hidden
							onClick={() => setCurrentTab('questions')}
						>
							<span className={el('card_title')}>Questions</span>
							<span className={el('card_number')}>{questions?.length}</span>
							<span className={el('card_desc')}>same questions are answered about each idea</span>
						</div>
					</div>
					<div className={el('content')}>
						{currentTab === 'ideas' ? (
							<ProductCardsPreview study={study} section={section} />
						) : (
							<QuestionsViewMode
								questions={questions}
								section={{ ...section, type: 'questions' }}
								sections={sections}
								selectedLanguage={selectedLanguage}
								renderLangTags={renderLangTags}
								study={study}
							/>
						)}
					</div>
				</>
			)}
		</div>
	);
};

IdeaSplitsOverview.displayName = 'IdeaSplitsOverview';
IdeaSplitsOverview.propTypes = {
	study: PropTypes.any,
	section: PropTypes.any,
	sections: PropTypes.any,
	selectedLanguage: PropTypes.any,
};

export default IdeaSplitsOverview;
