import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { urlParams } from '.';

export const useFetch = ({ callback, type }) => {
	const queryStringRef = useRef('');
	const [searchParams] = useSearchParams();

	// list of query params that force a re-fetch
	const studyPage = searchParams.get(urlParams.studyPage);
	const ideaPage = searchParams.get(urlParams.ideaPage);
	const questionPage = searchParams.get(urlParams.questionPage);
	const templatePage = searchParams.get(urlParams.templatePage);
	const filters = searchParams.get(urlParams.filters);
	const query = searchParams.get(urlParams.query);
	const status = searchParams.get(urlParams.status);
	const subtype = searchParams.get(urlParams.subtype);

	const studyOrderBy = searchParams.get(urlParams.studyOrderBy);
	const studyOrderByDirection = searchParams.get(urlParams.studyOrderByDirection);
	const ideaOrderBy = searchParams.get(urlParams.ideaOrderBy);
	const ideaOrderByDirection = searchParams.get(urlParams.ideaOrderByDirection);
	const questionOrderBy = searchParams.get(urlParams.questionOrderBy);
	const questionOrderByDirection = searchParams.get(urlParams.questionOrderByDirection);
	const templateOrderBy = searchParams.get(urlParams.templateOrderBy);
	const templateOrderByDirection = searchParams.get(urlParams.templateOrderByDirection);
	const refetch = searchParams.get(urlParams.refetch);

	const refetchString = JSON.stringify({
		studyPage,
		ideaPage,
		questionPage,
		templatePage,
		subtype,
		filters,
		query,
		status,
		studyOrderBy,
		studyOrderByDirection,
		ideaOrderBy,
		ideaOrderByDirection,
		questionOrderBy,
		questionOrderByDirection,
		templateOrderBy,
		templateOrderByDirection,
		refetch,
	});

	useEffect(() => {
		if (queryStringRef.current !== refetchString) {
			const difference = [];

			const queryStringRefJSON = JSON.parse(queryStringRef.current || '{}');
			const refetchStringJSON = JSON.parse(refetchString || '{}');

			Object.entries(refetchStringJSON).forEach(([key, value]) => {
				if (key === 'filters') {
					// todo - handle filters a bit different
					const queryStringRefFiltersJSON = JSON.parse(queryStringRefJSON?.filters || '{}');
					const refetchStringFiltersJSON = JSON.parse(refetchStringJSON?.filters || '{}');

					Object.entries(refetchStringFiltersJSON).forEach(([filterKey, filterValue]) => {
						const oldString = (queryStringRefFiltersJSON?.[filterKey] || '')?.toString();
						const newString = (filterValue || '')?.toString();

						if (oldString !== newString) {
							difference.push(filterKey);
						}
					});
				} else if (queryStringRefJSON?.[key] !== value) {
					difference.push(key);
				}
			});

			queryStringRef.current = refetchString;

			const globalRefetch = ['language', 'query', 'refetch'];
			const studiesRefetch = [
				'country',
				'status',
				'studyPage',
				'studyTag',
				'studyTemplate',
				'audienceTemplate',
				'combinedTemplate',
				'studyOrderBy',
				'studyOrderByDirection',
			];
			const ideasRefetch = ['ideaPage', 'ideaType', 'ideaTag', 'studyTag', 'ideaOrderBy', 'ideaOrderByDirection'];
			const questionsRefetch = [
				'questionPage',
				'questionType',
				'studyTag',
				'questionOrderBy',
				'questionOrderByDirection',
			];
			const templatesRefetch = [
				'country',
				'templatePage',
				'subtype',
				'templateOrderBy',
				'templateOrderByDirection',
			];

			// return if the only difference is the page number, and the previous number was null
			// Page numbers reset to 1 when changing tabs BUT we do not want to refetch if the page number was null
			if (difference.length === 1 && ['studyPage', 'questionPage', 'ideaPage'].includes(difference[0])) {
				if (
					difference[0] === 'studyPage' &&
					!queryStringRefJSON?.studyPage &&
					Number(refetchStringJSON?.studyPage) === 1
				) {
					return;
				}
				if (
					difference[0] === 'questionPage' &&
					!queryStringRefJSON?.questionPage &&
					Number(refetchStringJSON?.questionPage) === 1
				) {
					return;
				}
				if (
					difference[0] === 'ideaPage' &&
					!queryStringRefJSON?.ideaPage &&
					Number(refetchStringJSON?.ideaPage) === 1
				) {
					return;
				}
			}

			// todo - refetch all the things if global values have changed OR different is empty, meaning filters we're likely cleared
			if (difference.length === 0 || difference.some(dif => globalRefetch.includes(dif))) {
				callback();
				return;
			}

			if (type === 'studies' && difference.some(dif => studiesRefetch.includes(dif))) {
				callback();
			} else if (type === 'ideas' && difference.some(dif => ideasRefetch.includes(dif))) {
				callback();
			} else if (type === 'questions' && difference.some(dif => questionsRefetch.includes(dif))) {
				callback();
			} else if (type === 'templates' && difference.some(dif => templatesRefetch.includes(dif))) {
				callback();
			}
		}
	}, [callback, refetchString, type]);
};
