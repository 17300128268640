import { createSelector } from 'reselect';
import CONSTANTS from 'src/config/constants';
import { getUserProfileData } from '../../selectors';

export const getUser = state => getUserProfileData(state).user;
export const getLoading = state => getUserProfileData(state).loading;
export const getError = state => getUserProfileData(state).error;
export const getSubmitBtnText = state => getUserProfileData(state).submitButtonText;

export const getUserSettings = createSelector(getUser, user => user.settings);

export const userHasSeenMarketSimulatorProductTour = createSelector(getUserSettings, settings =>
	settings.some(item => item.label === CONSTANTS.shareOfChoice.productTourSettingsKey),
);
