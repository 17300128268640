import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Auth from 'src/utilities/auth';
import { Container, Row, Col } from 'reactstrap';
import Back from 'src/components/icons/Back';
import IconButton from 'src/components/elements/IconButton';
import cn from 'src/utilities/bem-cn';
import audienceStatusUtil from 'src/utilities/audiences-status-error-helper';
import * as actions from 'src/domains/manage-blueprints/actions';
import * as headerActions from 'src/domains/header/actions';
import * as selectors from 'src/domains/selectors';
import { getProfessionalLicenseTrialStatus } from 'src/domains/account/selectors';
import * as manageBlueprintsSelectors from 'src/domains/manage-blueprints/selectors';
import LanguageSelector from 'src/domains/header/components/shared/LanguageSelector';
import withRouter from 'src/hocs/withRouter';
import StudyAssetManager from '../../../../components/shared/StudyAssetManager';
import { StudyInput } from '../ManageStudy/index';

import './styles.scss';

const className = 'single-study-primary';
const el = (name, mod) => cn(className, name, mod);

class ManageBlueprintPrimary extends React.Component {
	debouncedPatchStudy = debounce(data => {
		const { study, patchStudy, setDefaultStudyName } = this.props;
		setDefaultStudyName(data.name);
		patchStudy(study.id, data);
	}, 500);

	constructor(props) {
		super(props);
		this.state = {};
		const { defaultStudyName } = props;
		if (defaultStudyName) {
			this.state.studyName = defaultStudyName;
		} else {
			this.state.studyName = props.study ? props.study.name : null;
		}
	}

	componentDidUpdate() {
		const { studyName } = this.state;
		const { study, header, sections, setCreateIcon, setAudienceIcon, setAudienceErrorIcon, audienceCollection } =
			this.props;
		const hasQuestions =
			sections.content?.find(({ questions, statements }) => questions?.length || statements?.length) || false;
		if (study && studyName === null) {
			this.setState({ studyName: study.title });
		}
		if (!header.createIcon && hasQuestions) {
			setCreateIcon(true);
		} else if (header.createIcon && !hasQuestions) {
			setCreateIcon(false);
		}

		const collectionContainsContent = () => {
			const hasDemographicGroups = !!audienceCollection?.content?.demographicGroups?.length;
			const hasScreeningQuestions = !!audienceCollection?.content?.screeningQuestions?.length;
			return hasDemographicGroups || hasScreeningQuestions;
		};

		if (!header.audienceIcon && collectionContainsContent()) {
			setAudienceIcon(true);
		} else if (header.audienceIcon && !collectionContainsContent()) {
			setAudienceIcon(false);
		}

		const studyHasIssues = audienceStatusUtil.studyHasIssues();
		if (!header.audienceErrorIcon && studyHasIssues) {
			setAudienceErrorIcon(true);
			setAudienceIcon(false);
		} else if (header.audienceErrorIcon && !studyHasIssues) {
			setAudienceErrorIcon(false);
			setAudienceIcon(true);
		}
	}

	componentWillUnmount() {
		const { setDefaultStudyName } = this.props;
		setDefaultStudyName(null);
	}

	onStudyTitleChange = event => {
		const { target } = event;
		const { value: studyName } = target;
		this.setState({ studyName });
		this.debouncedPatchStudy({ name: studyName });
	};

	setEditStudyLanguage = language => {
		const { setLanguage } = this.props;
		setLanguage(language);
	};

	changeLanguage = language => {
		// Used for deciding which language dropdown to render
		const isManageStudyDomain = /templates/.test(window.location.href);
		const { study, setLanguage, fetchProducts, fetchQuestions } = this.props;
		setLanguage(language);
		fetchProducts(study.id);
		if (isManageStudyDomain) {
			fetchQuestions(study.id);
		}
	};

	changeStatus = status => {
		const { study, publishStudy, closeStudy, draftStudy } = this.props;
		switch (status) {
			case 'active':
				publishStudy(study.id);
				break;
			case 'closed':
				closeStudy(study.id);
				break;
			case 'draft':
				draftStudy(study.id);
				break;
			default:
		}
	};

	getButtonConfig = () => {
		const { study } = this.props;
		let result = {};
		switch (study.status) {
			case 'draft':
				result = {
					buttonLabel: 'Launch',
					buttonIconType: 'stop',
				};
				break;
			case 'active':
				result = {
					buttonLabel: 'Live',
					buttonIconType: 'play',
				};
				break;
			case 'closed':
				result = {
					buttonLabel: 'Complete',
					buttonIconType: 'check',
				};
				break;
			default:
				result = {
					buttonLabel: 'Launch',
					buttonIconType: 'clock',
				};
		}

		return result;
	};

	open = () => {
		const { setDevicePreviewMode, setShowDevicePreviewMode } = this.props;
		setDevicePreviewMode('mobile');
		setShowDevicePreviewMode(true);
	};

	renderBackButton = () => {
		const {
			router: { navigate },
		} = this.props;
		return (
			<button type="button" className={el('back-button')} onClick={() => navigate('/studies')}>
				<Back />
			</button>
		);
	};

	renderStudyName = () => {
		const { study } = this.props;
		const { studyName } = this.state;
		const userCanUpdate = Auth.userCan('study:update');
		return study ? (
			<div className={el('study-name')}>
				{userCanUpdate ? (
					<StudyInput
						defaultValue={studyName === undefined || studyName === null ? study.name : studyName}
						onStudyTitleChange={this.onStudyTitleChange}
					/>
				) : (
					<>{study.name}</>
				)}
			</div>
		) : (
			false
		);
	};

	renderPreviewIcon = () => {
		const userIsTrial = Auth.userCan('user:trial') && !Auth.isDigAdmin();
		return !userIsTrial ? (
			<div className={el('preview-button')}>
				<IconButton icon="play_circle_outlined" onClick={this.open} tooltip="Preview Study" size="large" />
			</div>
		) : (
			false
		);
	};

	renderLanguageSelector = () => {
		const {
			languages,
			study,
			setTranslationsModal,
			language: studyLanguage,
			currentSection,
			sections,
		} = this.props;
		if (study) {
			const { id: studyId, defaultLanguage, languages: studyLanguages } = study;
			return (
				<LanguageSelector
					disabled={false}
					studyId={studyId}
					language={studyLanguage || defaultLanguage}
					languages={studyLanguages || []}
					allLanguages={languages}
					onChangeLanguage={this.changeLanguage}
					setTranslationsModal={setTranslationsModal}
					showImportTranslationsOption
					manageLanguages
					setEditStudyLanguage={this.setEditStudyLanguage}
					translationLocation="blueprints"
					currentSection={currentSection}
					sections={sections}
					isCreateMode
				/>
			);
		}
		return false;
	};

	render() {
		const { study } = this.props;
		const isDigAdmin = Auth.isDigAdmin();
		const userCanStudyUpdate = Auth.userCan('study:update', true);
		if (!study || !userCanStudyUpdate) {
			return false;
		}
		return (
			<Container className={className}>
				<Row>
					<Col className={el('left')}>
						{this.renderBackButton()}
						<StudyAssetManager study={study} key={study?.id} />
						{this.renderStudyName()}
					</Col>
					<Col className={el('center')}>
						{/* Removing the Navigation bar for current integration to remove the 
						ability to add audiences to study templates */}
						{/* <HeaderNavigationBar hasReportData={false} IRIssue={false} /> */}
					</Col>
					<Col className={el('right')}>
						{this.renderLanguageSelector()}
						{this.renderPreviewIcon()}
					</Col>
				</Row>
			</Container>
		);
	}
}

ManageBlueprintPrimary.propTypes = {
	id: PropTypes.any,
	language: PropTypes.any,
	languages: PropTypes.any,
	study: PropTypes.any,
	patchStudy: PropTypes.any,
	setDefaultStudyName: PropTypes.any,
	defaultStudyName: PropTypes.any,
	setTranslationsModal: PropTypes.any,
	setLanguage: PropTypes.any,
	fetchProducts: PropTypes.any,
	fetchQuestions: PropTypes.any,
	publishStudy: PropTypes.any,
	closeStudy: PropTypes.any,
	draftStudy: PropTypes.any,
	setDevicePreviewMode: PropTypes.any,
	setShowDevicePreviewMode: PropTypes.any,
	currentSection: PropTypes.any,
	sections: PropTypes.any,
	setDevicePreviewUrl: PropTypes.func,
	isTrialing: PropTypes.bool,
	header: PropTypes.object,
	setCreateIcon: PropTypes.func,
	setAudienceIcon: PropTypes.func,
	setAudienceErrorIcon: PropTypes.func,
	sections: PropTypes.object,
	audienceCollection: PropTypes.object,
	audience: PropTypes.object,
};

const mapStateToProps = state => ({
	audiences: selectors.getManageBlueprintData(state).audiences,
	audience: selectors.getManageBlueprintData(state).audience,
	audienceCollection: selectors.getManageBlueprintData(state).audienceCollection,
	header: selectors.getHeaderData(state),
	study: selectors.getManageBlueprintData(state).study,
	sections: selectors.getManageBlueprintData(state).sections,
	language: selectors.getManageBlueprintData(state).language,
	languages: selectors.getManageBlueprintData(state).languages,
	currentSection: manageBlueprintsSelectors.getCurrentSection(state),
	sections: manageBlueprintsSelectors.getSections(state),
	isTrialing: getProfessionalLicenseTrialStatus(state),
});

const mapDispatchToProps = dispatch => ({
	closeStudy: id => dispatch(actions.closeStudy(id)),
	publishStudy: id => dispatch(actions.publishStudy(id)),
	draftStudy: id => dispatch(actions.draftStudy(id)),
	patchStudy: (id, data) => dispatch(actions.patchStudy(id, data)),
	fetchProducts: id => dispatch(actions.fetchProducts(id)),
	fetchQuestions: id => dispatch(actions.fetchQuestions(id)),
	setLanguage: language => dispatch(actions.setLanguage(language)),
	setDevicePreviewMode: devicePreviewMode => dispatch(actions.setDevicePreviewMode(devicePreviewMode)),
	setShowDevicePreviewMode: showDevicePreviewMode =>
		dispatch(actions.setShowDevicePreviewMode(showDevicePreviewMode)),
	setTranslationsModal: translationsModal => dispatch(actions.setTranslationsModal(translationsModal)),
	setDevicePreviewUrl: devicePreviewUrl => dispatch(actions.setDevicePreviewUrl(devicePreviewUrl)),
	setCreateIcon: createIconState => dispatch(headerActions.setCreateIcon(createIconState)),
	setAudienceIcon: audienceIconState => dispatch(headerActions.setAudienceIcon(audienceIconState)),
	setAudienceErrorIcon: audienceErrorIconState =>
		dispatch(headerActions.setAudienceErrorIcon(audienceErrorIconState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageBlueprintPrimary));
