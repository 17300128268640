import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Cookies from 'src/utilities/cookies';
import { TRACKING_ACTIONS, trackingEvent } from 'src/utilities/marketing';
import { getAuthData } from 'src/domains/selectors';
import * as accountActions from 'src/domains/account/actions';
import * as authSelectors from 'src/domains/auth/selectors';
import * as profileSelectors from 'src/domains/profile/selectors';
import * as accountSelector from 'src/domains/account/selectors';

export const MarketingTracker = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const loggedIn = useSelector(authSelectors.isLoggedIn);
	const user = useSelector(profileSelectors.getUser);
	const account = useSelector(accountSelector.getAccount);
	const accounts = useSelector(accountSelector.getAccounts);
	const roles = useSelector(accountSelector.getRoles);
	const companySize = Cookies.getCompanySize();
	const { privileges } = useSelector(getAuthData);

	const chargifySubscriptions = useSelector(accountSelector.getChargifySubscription);

	const accountSubscription = chargifySubscriptions?.length
		? chargifySubscriptions?.find(
				sub => Number(sub.subscriptionId) === Number(account?.content?.chargifySubscriptionId),
		  )?.subscriptionName
		: '';

	const accountName = account?.content?.accountName;
	const accountId = account?.content?.id;

	const accountLength = accounts?.content?.length;

	const userEmail = user?.email;
	let userName = user?.firstName;
	userName = user?.lastName ? `${userName} ${user?.lastName}` : userName;
	if (!userName) {
		userName = user?.email;
	}

	useEffect(() => {
		if (loggedIn) {
			dispatch(accountActions.fetchRoles());
		}
		trackingEvent(TRACKING_ACTIONS.load);
	}, []);

	useEffect(() => {
		trackingEvent(TRACKING_ACTIONS.trackPageView);
	}, [location]);

	useEffect(() => {
		if (!accountName) return;
		trackingEvent(TRACKING_ACTIONS.updateEventData, { currentAccount: accountName });
	}, [accountName]);

	useEffect(() => {
		if (loggedIn) {
			trackingEvent(TRACKING_ACTIONS.identify, userEmail);
			trackingEvent(TRACKING_ACTIONS.updateUser, {
				accountName,
				name: userName,
				email: userEmail,
				privileges,
				roles,

				// intercom company object see https://developers.intercom.com/installing-intercom/web/attributes-objects/#company-object
				company: {
					company_id: accountId,
					name: accountName,
					...(companySize && companySize !== '' && { upsiideCompanySize: companySize }),
					plan: accountSubscription,
				},
			});
		}
		// be careful with the dependencies, only change when the account data changes.... we do not want to trigger this to frequently
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		accountName,
		accountId,
		loggedIn,
		userEmail,
		userName,
		accountLength,
		privileges,
		accountSubscription,
		companySize,
	]);

	return null;
};
