import React, { useCallback, useState } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { Tooltip } from 'react-tippy';
import Tag from 'src/components/elements/Tag';
import { Iconof } from '@upsiide/ui-components';
import TrashWithLines from 'src/components/icons/TrashWithLines';
import InterlockQuotasCel from './InterlockQuotaCel';
import InterlockQuotasProgressCel from './InterlockQuotaProgressCel';

import './styles.scss';

const className = 'interlock-quotas-table';
const el = (name, mod) => cn(className, name, mod);

const InterlockQuotasTable = ({
	interlockQuotasSchema,
	totalQuotas,
	quotas: originalQuotas,
	getQuestionLabelByOptionId,
	getQuestionIdByOptionId,
	getOptionLabelById,
	setInterlockQuotasSchema,
	setSelectedQuotas,
	onRemoveColumn,
	hasError,
	includeStatistics,
	sampleSize,
	currentSample,
	slowQuotaRemoveId,
	softenQuotaIds,
	setSoftenQuotaIds,
	setHasChanged,
	hasMissingTranslation,
	hasMissingOptionLabelTranslation,
}) => {
	const [selectedId, setSelectedId] = useState();

	const sumQuotas = useCallback(quotas => {
		const quotasArray = quotas.map(({ quota }) => quota);
		const hasNullQuota = quotasArray.includes(NaN);
		if (hasNullQuota) {
			const cumulativeQuota = quotasArray.filter(q => q).reduce((acc, cur) => acc + cur, 0);
			const maxQuota = 100 - cumulativeQuota;
			return cumulativeQuota + maxQuota;
		}

		const sum = quotasArray.filter(quota => quota).reduce((partialSum, quota) => partialSum + quota, 0);
		return sum;
	}, []);

	const getPreviousColumnlength = useCallback(
		level => {
			const columnIds = [];

			for (let index = level; index >= 0; index -= 1) {
				const currentIds = [];
				interlockQuotasSchema.forEach(quota => {
					const { optionId, audienceQuestionOptionId } = quota.ids[index];
					currentIds.push(`id-${optionId ?? ''}|aId-${audienceQuestionOptionId ?? ''}`);
				});
				columnIds.push([...new Set(currentIds)]);
			}

			const columnLength = columnIds
				.map(ids => ids.length)
				.reduce((partialMult, length) => partialMult * length, 1);
			return columnLength;
		},
		[interlockQuotasSchema],
	);

	const onBlurQuota = useCallback(
		({ level, id, newQuota }) => {
			if (includeStatistics) {
				const originalQuestion = originalQuotas.find(question =>
					question.options.find(o =>
						compareQuotaId({ optionId: o.id, audienceQuestionOptionId: o.audienceQuestionOptionId }, id),
					),
				);
				const originalOption = originalQuestion.options.find(o => {
					const isEqual = compareQuotaId(
						{ optionId: o.id, audienceQuestionOptionId: o.audienceQuestionOptionId },
						id,
					);
					return isEqual;
				});
				if (slowQuotaRemoveId === originalQuestion.id) {
					setSoftenQuotaIds(prev => [...prev, slowQuotaRemoveId]);
				}

				if (originalOption.quota == null) {
					toastr.error("You can't change empty quotas after you have launched your group");
				}

				if (newQuota == null) {
					toastr.error("You can't set quota to empty after you have launched your group");
				}

				if (newQuota != null && Number(originalOption.quota).toFixed(2) > Number(newQuota).toFixed(2)) {
					toastr.error('You can only increase quotas after you have launched your group');
				}

				if (originalOption.quota === null || originalOption.quota > newQuota) {
					setInterlockQuotasSchema(prev => {
						const quotas = prev.quotas.map(({ ids, quota, ...cel }) => {
							if (!compareQuotaId(ids[level], id)) return { ...cel, ids, quota };
							quota[level] = originalOption.quota;
							return { ...cel, ids, quota };
						});

						return {
							...prev,
							quotas,
						};
					});
					setSelectedQuotas(prev => {
						const changedQuestions = prev.map(question => {
							const changedOptions = question.options.map(option => {
								if (
									compareQuotaId(
										{
											optionId: option.id,
											audienceQuestionOptionId: option.audienceQuestionOptionId,
										},
										id,
									)
								) {
									return {
										...option,
										quota: originalOption.quota,
									};
								}
								return option;
							});
							return {
								...question,
								options: changedOptions,
							};
						});

						return [...changedQuestions];
					});
				}
			}
			setHasChanged(true);
		},
		[
			includeStatistics,
			originalQuotas,
			setHasChanged,
			setInterlockQuotasSchema,
			setSelectedQuotas,
			setSoftenQuotaIds,
			slowQuotaRemoveId,
		],
	);

	const onChangeQuota = useCallback(
		({ level, id, newQuota }) => {
			setInterlockQuotasSchema(prev => {
				const quotas = prev.quotas.map(({ ids, quota, ...cel }) => {
					if (!compareQuotaId(ids[level], id)) return { ...cel, ids, quota };
					quota[level] = newQuota;
					return { ...cel, ids, quota };
				});

				return {
					...prev,
					quotas,
				};
			});
			setSelectedQuotas(prev => {
				const changedQuestions = prev.map(question => {
					const changedOptions = question.options.map(option => {
						if (
							compareQuotaId(
								{ optionId: option.id, audienceQuestionOptionId: option.audienceQuestionOptionId },
								id,
							)
						) {
							return {
								...option,
								quota: newQuota,
							};
						}
						return option;
					});
					return {
						...question,
						options: changedOptions,
					};
				});

				return [...changedQuestions];
			});
		},
		[setInterlockQuotasSchema, setSelectedQuotas],
	);

	const formatQuota = quotaNumber => {
		if (typeof quotaNumber !== 'number') {
			return quotaNumber;
		}
		return Math.ceil(quotaNumber);
	};

	const renderSoftQuotaTag = quotasSum => {
		if (quotasSum <= 100) return;
		return (
			<Tooltip
				className={el('soft')}
				theme="dark-blured"
				position="top"
				delay={[0, 1000]}
				interactive
				html={
					<div className={el('soft-quota-warning')}>
						When quota exceeds 100%, <span className={el('soft-quota-bold')}> soft quotas </span> will
						apply. Each quota will be treated as a max value.
						<a href="https://support.upsiide.com/en/articles/5192123" target="blank">
							{'Learn more ->'}
						</a>
					</div>
				}
			>
				<Tag className="soft-quota" label="Soft" />
			</Tooltip>
		);
	};

	const renderPartialTag = (quotasSum, column) => {
		if (quotasSum >= 100) return;
		if (!column?.length) return;
		const quotas = column?.map(item => item?.quota);
		const nullQuotas = quotas.filter(quota => quota == null);
		const notEmptyQuotas = quotas.filter(quota => quota != null);

		if (!nullQuotas?.length || !notEmptyQuotas?.length) return;

		return (
			<Tooltip
				className={el('soft')}
				theme="dark-blured"
				position="top"
				delay={[0, 1000]}
				interactive
				html={
					<div className={el('soft-quota-warning')}>
						When total entered quotas are below 100%,<b> partial quotas </b> will apply. All answer options
						without a quota value will be grouped together in one quota.
						<a
							href="https://support.upsiide.com/en/articles/5192123-setting-up-quotas#h_c4bcb26595"
							target="blank"
						>
							{'Learn more ->'}
						</a>
					</div>
				}
			>
				<Tag className="partial" label="Partial" />
			</Tooltip>
		);
	};

	const renderColumnHeader = useCallback(
		column => {
			const quotasSum = sumQuotas(column);
			const columnIds = column.map(({ id }) => id);
			const isColumnSelected = columnIds.includes(selectedId);
			const questionId = getQuestionIdByOptionId(column[0].id);
			const showMissingTranslatinWarning = hasMissingTranslation(questionId);
			const questionLabel = getQuestionLabelByOptionId(column[0].id);
			return (
				<div className={el('column-header')}>
					<div className={el('column-header-title')}>
						{isColumnSelected ? (
							<Tooltip
								theme="dark-blured"
								position="top"
								html={
									<div className={el('interlock-quota-warning')}>
										<p>Editing your screening</p>
										<p>questions quota here will</p>
										<p>change it for the actual</p>
										<p>question quotas</p>
									</div>
								}
							>
								<Iconof icon="warning" className={el('interlock-quota-warning-icon alert')} />
							</Tooltip>
						) : null}
						<Tooltip
							theme="dark-blured"
							position="top-start"
							html={
								<div className={el('interlock-quota-warning')}>
									{showMissingTranslatinWarning ? (
										<small>
											<Iconof icon="warning" size="small" />
											Translations missing
										</small>
									) : null}
									<b>{questionLabel}</b>
								</div>
							}
						>
							<b className={el('label')}>
								{showMissingTranslatinWarning ? <Iconof icon="warning" /> : null}
								{questionLabel}
							</b>
						</Tooltip>
						<span>{formatQuota(quotasSum)}%</span>
						{renderSoftQuotaTag(quotasSum) || renderPartialTag(quotasSum, column)}
					</div>
					<div className={el('column-header-icon')} onClick={() => onRemoveColumn(questionId)} aria-hidden>
						<TrashWithLines fill="#666666" />
					</div>
				</div>
			);
		},
		[
			getQuestionIdByOptionId,
			getQuestionLabelByOptionId,
			hasMissingTranslation,
			onRemoveColumn,
			selectedId,
			sumQuotas,
		],
	);

	const compareQuotaId = (quotaA, quotaB) => {
		if (quotaA.optionId && quotaB.optionId) {
			return quotaA.optionId === quotaB.optionId;
		}
		return quotaA.audienceQuestionOptionId === quotaB.audienceQuestionOptionId;
	};

	const renderColumns = useCallback(
		tree => {
			if (!tree?.[0]?.ids?.length) return;
			const currentColumn = [];
			const nextColumns = [];

			tree.forEach(node => {
				const [id, ...nextIds] = node.ids;
				const [quota, ...nextQuotas] = node.quota;
				const hasId = currentColumn.find(row => compareQuotaId(row.id, id));

				if (!hasId) {
					currentColumn.push({
						id,
						quota,
						level: node.level || 0,
						label: getOptionLabelById(id),
						missingTranslation: hasMissingOptionLabelTranslation(id),
					});
				}

				const nextLevel = !node.level ? 1 : node.level + 1;
				nextColumns.push({ ids: nextIds, quota: nextQuotas, level: nextLevel });
			});

			let rows = [currentColumn];
			const [currentCel] = currentColumn;

			if (currentCel.level) {
				const previousColumnLength = getPreviousColumnlength(currentCel.level - 1);
				rows = Array.from({ length: previousColumnLength }).map(() => currentColumn);
			}

			return (
				<>
					<div className={el('column')}>
						{renderColumnHeader(currentColumn)}
						{rows.map((row, rowIndex) => (
							<div key={rowIndex} className={el('row')}>
								{row.map((cel, celIndex) => (
									<InterlockQuotasCel
										{...cel}
										customClassName={cel.missingTranslation ? 'missing-translation' : ''}
										editable
										onBlur={onBlurQuota}
										onChange={onChangeQuota}
										setSelectedId={setSelectedId}
										isSelected={selectedId === cel.id}
										key={`${rowIndex}-${celIndex}-${cel.id}`}
									/>
								))}
							</div>
						))}
					</div>
					{renderColumns(nextColumns)}
				</>
			);
		},
		[
			getOptionLabelById,
			getPreviousColumnlength,
			hasMissingOptionLabelTranslation,
			onBlurQuota,
			onChangeQuota,
			renderColumnHeader,
			selectedId,
		],
	);

	const renderTotalQuotasColumn = useCallback(
		quotas => (
			<div className={`${el('column')} quotas`}>
				<div className={el('column-header')}>
					{hasError ? (
						<Tooltip
							theme="light"
							position="top"
							html={
								<div className={el('interlock-quota-warning')}>
									<p>Quota percentage is too low.</p>
									<p>Remove one or more interlocked</p>
									<p>dimensions to adjust quotas.</p>
								</div>
							}
						>
							<Iconof icon="warning" className={el('interlock-quota-warning-icon error')} />
						</Tooltip>
					) : null}
					<b>Quota</b>
				</div>
				{quotas?.map((quota, i) => (
					<InterlockQuotasCel
						key={`quota-${quota?.percentage || quota?.percentageMax}-${i}`}
						quota={quota?.percentage || quota?.percentageMax}
						icon={quota?.percentageMax ? 'info_outined' : ''}
						iconText={
							quota?.percentageMax
								? 'Our algorithm detected empty values in your dataset and has considered all other available data' +
								  ' points for the corresponding questions except for the empty values to determine the maximum for this quota.'
								: ''
						}
					/>
				))}
			</div>
		),
		[hasError],
	);

	const renderPercentColumn = useCallback(
		percentages => (
			<div className={`${el('column')} progress-column`}>
				<div className={el('column-header')}>
					<b>Progress</b>
				</div>
				{percentages?.map((quota, i) => {
					const precentage = Number(quota?.percentage || quota?.percentageMax || 0);
					const interlockQuotaPercent = precentage / 100;
					const audiencePercentage = Math.ceil(((currentSample || 0) / sampleSize) * 100);
					const interlockProgress = Math.ceil(
						(quota.quotaCompletes / Math.ceil(sampleSize * interlockQuotaPercent)) * 100,
					);

					const sample = Math.ceil(sampleSize * interlockQuotaPercent);
					const id = quota.ids?.[0]?.optionId || quota.ids?.[0]?.audienceQuestionOptionId;

					return (
						<InterlockQuotasProgressCel
							key={`quota-${id}-${i}`}
							percent={interlockProgress}
							respondents={quota?.quotaCompletes}
							sample={sample}
							audiencePercentage={audiencePercentage}
							disabled={softenQuotaIds?.length}
						/>
					);
				})}
			</div>
		),
		[currentSample, sampleSize, softenQuotaIds],
	);

	return (
		<div className={className}>
			{renderColumns(interlockQuotasSchema)}
			{renderTotalQuotasColumn(totalQuotas)}
			{includeStatistics ? renderPercentColumn(totalQuotas) : null}
		</div>
	);
};

InterlockQuotasTable.propTypes = {
	interlockQuotasSchema: PropTypes.array,
	totalQuotas: PropTypes.array,
	quotas: PropTypes.array,
	setInterlockQuotasSchema: PropTypes.func,
	getQuestionLabelByOptionId: PropTypes.func,
	getQuestionIdByOptionId: PropTypes.func,
	getOptionLabelById: PropTypes.func,
	onRemoveColumn: PropTypes.func,
	setSelectedQuotas: PropTypes.func,
	hasError: PropTypes.bool,
	includeStatistics: PropTypes.bool,
	sampleSize: PropTypes.number,
	currentSample: PropTypes.number,
	slowQuotaRemoveId: PropTypes.number,
	softenQuotaIds: PropTypes.array,
	setSoftenQuotaIds: PropTypes.func,
	setHasChanged: PropTypes.func,
	hasMissingTranslation: PropTypes.func,
	hasMissingOptionLabelTranslation: PropTypes.func,
};

export default InterlockQuotasTable;
