import * as actions from '../actions';

const INITIAL_STATE = {
	addUserModal: { visible: false, error: null },
	loading: false,
	loggedIn: null,
	hasAccount: false,
	expiresAt: null,
	privileges: null,
	isSamlUser: null,
	token: null,
	error: null,
	clientId: null,
	ghost: false,
	rateLimit: {
		flagged: false,
		timer: null,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.AUTHENTICATE:
			return {
				...state,
				loading: true,
			};

		case actions.IS_GHOST_USER:
			return { ...state, ghost: action.payload.ghost };

		case actions.SET_ADD_USER_MODAL:
			return { ...state, addUserModal: { ...state.addUserModal, ...action.payload.addUserModal } };

		case actions.SET_USERDATA:
			const { expiresAt, privileges, isSamlUser, token } = action.payload;

			return {
				...state,
				loading: false,
				loggedIn: true,
				hasAccount: true,
				expiresAt,
				privileges,
				token,
				isSamlUser,
			};

		case actions.SET_NO_USER:
			return {
				...state,
				loading: false,
				loggedIn: false,
				hasAccount: false,
				privileges: null,
				token: null,
				expiresAt: null,
			};

		case actions.SET_LOGOUT:
			return { ...INITIAL_STATE, loggedIn: false };

		case actions.SET_ERROR:
			return { ...state, loading: false, error: action.payload.error };

		case actions.SET_CLIENT_ID:
			return { ...state, clientId: action.payload.clientId };

		case actions.SET_ACCOUNT_UUID:
			return { ...state, accountUuid: action.payload.accountUuid };

		case actions.SET_HAS_ACCOUNT:
			return { ...state, hasAccount: action.payload.hasAccount };

		case actions.SET_RATE_LIMIT:
			return { ...state, rateLimit: { flagged: action.payload.flagged, timer: action.payload.timer } };

		default:
			return state;
	}
};
