const prefix = 'reset-password';

export const CONFIRM_RESET_PASSWORD_TOKEN = `${prefix}/CONFIRM_RESET_PASSWORD_TOKEN`;
export const confirmToken = token => ({ type: CONFIRM_RESET_PASSWORD_TOKEN, payload: { token } });

export const UPDATE_PASSWORD = `${prefix}/UPDATE_PASSWORD`;
export const updatePassword = user => ({ type: UPDATE_PASSWORD, payload: { user } });

export const SET_RESET_PASSWORD_TOKEN = `${prefix}/SET_RESET_PASSWORD_TOKEN`;
export const setToken = token => ({ type: SET_RESET_PASSWORD_TOKEN, payload: { token } });

export const SET_RESET_PASSWORD_COMPLETE = `${prefix}/SET_RESET_PASSWORD_COMPLETE`;
export const setResetComplete = complete => ({ type: SET_RESET_PASSWORD_COMPLETE, payload: { complete } });

export const INC_LOADING = `${prefix}/INC_LOADING`;
export const incLoading = () => ({ type: INC_LOADING });

export const DEC_LOADING = `${prefix}/DEC_LOADING`;
export const decLoading = () => ({ type: DEC_LOADING });

export const SET_ERROR = `${prefix}/SET_ERROR`;
export const setError = error => ({ type: SET_ERROR, payload: { error } });
