import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { Tooltip } from 'react-tippy';
import moment from 'moment';
import FileIcon from 'src/components/icons/File';
import downloadIcon from 'public/images/upload/download.svg';
import IconButton from 'src/components/elements/IconButton';
import toastr from 'toastr';
import { useSelector } from 'react-redux';
import { formatRelativeDate } from 'src/utilities/misc';
import Loader from 'src/components/shared/Loader';
import CustomPercentageCircle from 'src/components/shared/CustomPercentageCircle';
import service from '../../../../../services/studySample.service';

const className = 'file-upload-dropdown';
const el = (name, mod) => cn(className, name, mod);

const FileRow = ({ file, onRenameFile, onDeleteFile, fetchStudyFiles, canUpload = false, isScrolling = false }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [fileName, setFileName] = useState(file?.fileName);
	const [isDownloading, setIsDownloading] = useState(false);
	const [downloadPercentage, setDownloadPercentage] = useState(0);
	const { study } = useSelector(state => state.manageStudy);

	useEffect(() => {
		setFileName(file?.fileName);
	}, [file.fileName]);

	const downloads = useMemo(() => file?.logs?.filter(log => log?.action === 'DOWNLOAD') || [], [file]);

	const onDownloadProgress = progressEvent => {
		const percentCompleted = Math.ceil((progressEvent.loaded * 100) / progressEvent.total);
		setDownloadPercentage((percentCompleted || 0) + 1);
	};

	const onDownload = useCallback(async () => {
		try {
			if (isDownloading) return;
			setIsDownloading(true);
			const response = await service.downloadStudyFile(study?.id, file?.id, onDownloadProgress);
			const href = window.URL.createObjectURL(response?.data);
			const anchorElement = document.createElement('a');
			anchorElement.style.display = 'none';

			anchorElement.href = href;
			anchorElement.download = file?.fileName;

			const [parentElement] = document.getElementsByClassName('file-upload-dropdown');
			parentElement.appendChild(anchorElement);
			anchorElement.click();

			parentElement.removeChild(anchorElement);
			window.URL.revokeObjectURL(href);
			setIsDownloading(false);
			setDownloadPercentage(0);
			fetchStudyFiles();
		} catch (error) {
			fetchStudyFiles();
			setIsDownloading(false);
			console.error(error);
			toastr.error(
				`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
						<p style="font-size: 16px;">
						The file <strong>${file?.fileName}</strong> doesn’t exist anymore.	
						</p>
					</div>`,
			);
		}
	}, [file, fetchStudyFiles, isDownloading, study?.id]);

	const formatName = name => {
		const newName = name.split('.');
		if (newName.length > 1) {
			newName.pop();
			return newName.join('.');
		}
		return name;
	};

	const getExtension = name => {
		const newName = name.split('.');
		if (newName.length > 1) {
			return newName.pop();
		}
		return '';
	};

	const renderDownload = () => (
		<div className={el('download-tooltip')}>
			{downloads?.slice(0, 10)?.map((download, index) => (
				<span key={index} className={el('download-tooltip-title')}>
					<span> {download.userName}</span>, {formatRelativeDate(download.entryTime, false)?.date}
				</span>
			))}
		</div>
	);

	const renderListDownloads = () => {
		if (!canUpload) return null;
		if (downloads.length > 0)
			return (
				<Tooltip
					arrow={false}
					interactive
					position="bottom"
					trigger="mouseenter"
					theme="upload-file-download"
					html={renderDownload()}
					hideOnClick
				>
					<span className={el('downloads')}>{downloads.length} downloads</span>
				</Tooltip>
			);

		return <span className={el('no-download')}>0 download</span>;
	};

	useEffect(() => {
		if (file.id && isEditing) {
			setTimeout(() => {
				const inputElement = document.getElementById(`file-uploading-rename-input-${file?.id}`);
				inputElement?.focus();
			}, 300);
		}
	}, [isEditing, file]);

	const renderDownloadLoader = () =>
		downloadPercentage ? (
			<CustomPercentageCircle
				percentage={downloadPercentage}
				customDuration={0}
				width={18}
				height={18}
				customClass={el('progress')}
			/>
		) : (
			<Loader />
		);

	const renderIcons = () => {
		if (isEditing) return null;
		return canUpload ? (
			<div className={el('options')}>
				<div className={el('icon')} data-testid="download">
					{isDownloading ? (
						renderDownloadLoader()
					) : (
						<IconButton
							customTheme="upload-file"
							delay={150}
							distance={8}
							tooltip="Download"
							onClick={onDownload}
							customIcon={<img src={downloadIcon} alt="" />}
							position="bottom"
						/>
					)}
				</div>
				<div className={el('icon')} onClick={() => onDeleteFile(file.id)} aria-hidden data-testid="delete">
					<IconButton
						customTheme="upload-file"
						delay={150}
						distance={8}
						tooltip="Delete"
						onClick={() => onDeleteFile(file.id)}
						icon="delete"
						position="bottom"
					/>
				</div>
			</div>
		) : (
			<div className={el('download-icon')} data-testid="download">
				{isDownloading ? <Loader /> : <img src={downloadIcon} alt="" />}
			</div>
		);
	};

	const renderFileRowContent = () => (
		<div
			className={`${el('file-container')} ${isEditing ? 'hovering' : ''}`}
			aria-hidden
			key={file.id}
			onClick={e => {
				if (!canUpload) onDownload();
				e.stopPropagation();
				e.preventDefault();
			}}
			style={{ cursor: !canUpload ? 'pointer' : 'default' }}
		>
			<div className={el('file-image')}>
				<FileIcon type="doc" />
			</div>
			<div className={el('file-row')}>
				<div className={el('file-content')}>
					{isEditing ? (
						<input
							type="text"
							value={formatName(fileName)}
							className={el('file-uploading-title-editing')}
							id={`file-uploading-rename-input-${file.id}`}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									e.target.blur();
								}
								if (e.key === 'Escape') {
									setFileName(file?.fileName);
									setIsEditing(false);
								}
							}}
							onChange={e => {
								setFileName(e.target.value);
							}}
							onBlur={e => {
								if (e.target.value?.trim()?.length) {
									const fullFileName = `${e.target.value?.trim()}.${getExtension(file?.fileName)}`;
									setFileName(fullFileName); // set local state to quick upgrade local name
									onRenameFile(file?.id, fullFileName);
								} else {
									setFileName(file?.fileName);
								}
								setIsEditing(false);
							}}
							maxLength={250}
						/>
					) : (
						<>
							<span
								aria-hidden
								onClick={() => {
									if (!isEditing && canUpload) setIsEditing(true);
								}}
								data-testid="edit-file-name"
								className={el('file-name')}
								style={{
									borderBottom: canUpload ? '1px dashed #bababa' : 'unset',
									cursor: canUpload ? 'text' : 'pointer',
								}}
								title={fileName}
							>
								{fileName}
							</span>
							<div className={el('file-desc')}>
								<span className={el('file-date')}>
									Uploaded {moment(file?.createdAt).format('MMM DD, YYYY')}
								</span>
								{renderListDownloads()}
							</div>
						</>
					)}
				</div>
				{renderIcons()}
			</div>
		</div>
	);

	if (canUpload || isScrolling) {
		return renderFileRowContent();
	}

	return (
		<Tooltip
			arrow={false}
			position="bottom-end"
			distance={-10}
			trigger="mouseenter"
			theme="upload-file"
			html={<div>Download</div>}
		>
			{renderFileRowContent()}
		</Tooltip>
	);
};

FileRow.propTypes = {
	file: PropTypes.object,
	onDeleteFile: PropTypes.func,
	onRenameFile: PropTypes.func,
	fetchStudyFiles: PropTypes.func,
	canUpload: PropTypes.bool,
	isScrolling: PropTypes.bool,
};

export default FileRow;
