import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Auth from 'src/utilities/auth';
import { Iconof } from '@upsiide/ui-components';
import UpgradeTooltip from 'src/components/elements/UpgradeTooltip';

const className = 'language-dropdown';
const el = (name, mod) => cn(className, name, mod);
const ManageLanguagePopper = ({
	languages,
	allLanguages,
	navigateToTranslations,
	headingText,
	setEditStudyLanguage,
	selectedLanguage,
	currentSection,
	sections,
	isTrialing,
}) => {
	const manageTranslationsAccess = Auth.accountHasFeature('manageTranslations');
	const isAccountAdmin = Auth.userCan('account:update', true);
	const languagesThatAreAttachedToStudy = [];
	languages.forEach(languageCode => {
		let country = null;
		const languageObject = allLanguages.find(l => {
			if (l.languageCode === languageCode) {
				return l;
			}
			country = l.countries.find(c => c.code === languageCode);
			if (country) {
				return l;
			}

			return false;
		});

		if (languageObject) {
			let newLanguage = { ...languageObject };
			// Country-specific overrides
			if (country) {
				newLanguage = { ...newLanguage, languageCode, label: `${newLanguage.label} (${country.label})` };
			}
			languagesThatAreAttachedToStudy.push(newLanguage);
		}
	});

	const translationsHeadingText = headingText || 'Edit Study in:';
	const manageTranslationsText = 'Manage Translations';
	const showManageTranslationButton = useMemo(() => {
		if (
			isAccountAdmin &&
			manageTranslationsAccess &&
			currentSection?.content?.type !== 'link_routing' &&
			sections.content?.length > 1
		) {
			return true;
		}
		const nonLinkRoutingSections = sections.content?.filter(({ type }) => type !== 'link_routing');
		return !!nonLinkRoutingSections?.length;
	}, [currentSection, isAccountAdmin, manageTranslationsAccess, sections]);

	const showChangeLanguageButton = useMemo(() => languages?.length === 1, [languages]);

	return (
		<div
			className={`${el('dropdown')} ${
				!manageTranslationsAccess && isAccountAdmin ? el('manage-translate-disabled') : null
			}`}
		>
			<div className={el('option disabled')}>{translationsHeadingText}</div>
			<div className={el('options')}>
				{languagesThatAreAttachedToStudy.map(language => (
					<button
						key={language.languageCode}
						type="button"
						className={[
							el('option'),
							language.languageCode === selectedLanguage ? el('selected') : null,
						].join(' ')}
						onClick={() => setEditStudyLanguage(language.languageCode)}
					>
						{language.languageCode === selectedLanguage ? (
							<Iconof icon="done" color="green-900" />
						) : (
							<div style={{ width: 20 }} />
						)}
						<span>{language.label}</span>
					</button>
				))}
				{showManageTranslationButton ? (
					<button
						type="button"
						className={[
							el('option'),
							el('manage-translations-option'),
							!manageTranslationsAccess ? el('option-disabled') : null,
						].join(' ')}
						onClick={manageTranslationsAccess ? navigateToTranslations : () => {}}
					>
						<Iconof icon="new_message" />
						<span>{manageTranslationsText}</span>
						{!manageTranslationsAccess || isTrialing ? (
							<UpgradeTooltip position="bottom" isTrialing={isTrialing} />
						) : null}
					</button>
				) : null}

				{!showManageTranslationButton && !showChangeLanguageButton && languages?.length > 1 && (
					<button
						type="button"
						className={[
							el('option'),
							el('manage-translations-option'),
							!manageTranslationsAccess ? el('option-disabled') : null,
						].join(' ')}
						onClick={manageTranslationsAccess ? navigateToTranslations : () => {}}
					>
						<Iconof icon="new_message" />
						<span>{manageTranslationsText}</span>
						{!manageTranslationsAccess || isTrialing ? (
							<UpgradeTooltip position="bottom" isTrialing={isTrialing} />
						) : null}
					</button>
				)}
			</div>
		</div>
	);
};

ManageLanguagePopper.propTypes = {
	languages: PropTypes.any,
	allLanguages: PropTypes.any,
	navigateToTranslations: PropTypes.func,
	headingText: PropTypes.any,
	setEditStudyLanguage: PropTypes.func,
	selectedLanguage: PropTypes.string,
	currentSection: PropTypes.any,
	sections: PropTypes.any,
	isTrialing: PropTypes.bool,
};

export default ManageLanguagePopper;
