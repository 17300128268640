import React from 'react';

import './styles.scss';

export default ({ color }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		width="39"
		height="37"
		viewBox="0 0 39 37"
		className="chrevron-right-icon"
	>
		<defs>
			<clipPath id="chevron-right-cp">
				<rect width="39" height="37" />
			</clipPath>
		</defs>
		<g id="a" clipPath="url(#chevron-right-cp)">
			<path
				d="M109.292,1.674,107.613,0,91.566,16.1l16.047,16.1,1.679-1.674L94.913,16.1Z"
				transform="translate(120.293 34.199) rotate(180)"
				fill={color || '#fff'}
			/>
		</g>
	</svg>
);
