import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import { getMeanScoreScaleOrder } from 'src/components/helpers';

import ToggleSwitch from 'src/components/elements/ToggleSwitch';
import CustomizeVisibilityDropdown from 'src/components/manage/questions/CustomizeVisibilityDropdown';
import Collapse from 'src/components/shared/Collapse';
import ScalePointInput from 'src/components/manage/questions/ScalePointInput';
import { Iconof } from '@upsiide/ui-components';

import './styles.scss';

const className = 'audience-screening-questions';
const el = (name, mod) => cn(className, name, mod);

const AudienceScreeningQuestionsViewMode = ({ audienceTemplate, selectedLanguage }) => {
	const getRandomizeAttributes = question => {
		const multiSelect = question.settings?.find(setting => setting?.label === 'randomize_attributes');
		return multiSelect?.value === 'true';
	};
	const getMultipleSelection = question => {
		const multiSelect = question.settings?.find(setting => setting?.label === 'multi-select');
		return multiSelect?.value === 'true';
	};
	const getTopNSetting = question => {
		const multiSelect = question.settings?.find(setting => setting?.label === 'top-n');
		return multiSelect?.value === 'true';
	};

	const getLabel = item => {
		const label = item.translations?.find(translation => translation?.languageCode === selectedLanguage)?.label;
		return label || item?.translations?.[0]?.label;
	};

	const getImageSettingAlign = question =>
		question?.settings?.find(setting => setting?.label === 'question_image_align')?.value; // left | center

	const renderQuestionAsset = question => {
		const asset = question.translations?.find(translation => translation?.languageCode === selectedLanguage)?.asset;
		const isLeft = getImageSettingAlign(question) === 'left';
		const customStyle = {
			...(isLeft ? { justifyContent: 'flex-start', paddingLeft: 15 } : {}),
		};

		if (asset?.url)
			return (
				<div className={el('question-asset')} style={customStyle}>
					<img alt="" src={asset?.url} />
				</div>
			);
		return null;
	};

	const hasAsset = option =>
		option.translations?.find(translation => translation?.languageCode === selectedLanguage)?.asset;

	const renderOptionAsset = option => {
		const asset = hasAsset(option);
		if (asset)
			return (
				<div className={el('asset')}>
					<img alt="" src={asset} />
				</div>
			);
		return null;
	};

	const renderGridOptions = question => (
		<div className={el('grid')}>
			<div className={el('grid-container')}>
				<span className={el('grid-title')}>Attributes</span>
				{question?.attributes?.map(attribute => (
					<div
						className={hasAsset(attribute) ? el('option-grid-asset') : el('option-grid')}
						key={attribute?.id}
					>
						<span>{getLabel(attribute)} </span>
						{renderOptionAsset(attribute)}
					</div>
				))}
			</div>
			<div className={el('grid-container')}>
				<div className={el('titles-wrapper')}>
					{!!question?.addScale && <span className={el('scale-column-title')}>Scale</span>}

					<span className={el('grid-title')}>Options</span>
				</div>{' '}
				{question?.options?.map(option => {
					// * Check whether each answer has a scale point equal to its ordinal position (asc default). Scale points of 'Other' and 'None of the Above' options should be `null` by default
					const isDefaultScale = !getMeanScoreScaleOrder(question?.options)?.isCustom;

					return (
						<div className={el('option-wrapper')}>
							{!!question?.addScale && (
								<ScalePointInput
									readOnly
									answer={option}
									answers={question?.options}
									questionId={question?.id}
									{...{ isDefaultScale }}
								/>
							)}

							<div
								className={hasAsset(option) ? el('option-grid-asset') : el('option-grid')}
								key={option?.id}
							>
								<div className={el('checkbox-wrapper')}>
									<div
										className={getMultipleSelection(question) ? el('checkbox') : el('squarebox')}
									/>
								</div>
								<span>{getLabel(option)} </span>
								{renderOptionAsset(option)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);

	const renderSelectOptions = question =>
		question?.options?.map(option => {
			// * Check whether each answer has a scale point equal to its ordinal position (asc default). Scale points of 'Other' and 'None of the Above' options should be `null` by default
			const isDefaultScale = !getMeanScoreScaleOrder(question?.options)?.isCustom;

			return (
				<div className={el('option-wrapper')}>
					{!!question?.addScale && (
						<ScalePointInput
							readOnly
							answer={option}
							answers={question?.options}
							questionId={question?.id}
							{...{ isDefaultScale }}
						/>
					)}
					<div className={hasAsset(option) ? el('option-asset') : el('option')} key={option?.id}>
						<div className={el('checkbox-wrapper')}>
							{question?.style === 'ranked' ? (
								<Iconof className={el('drag-icon')} icon="drag" size="default" color="#666666" />
							) : (
								<div className={getMultipleSelection(question) ? el('checkbox') : el('squarebox')} />
							)}
						</div>
						<span>{getLabel(option)} </span>
						{renderOptionAsset(option)}
					</div>
				</div>
			);
		});

	const renderSelectQuestion = (question, i) => (
		<div className={el('question-content')} key={question?.id}>
			<div className={el('question-left')}>
				<div className={el('title-container')}>
					{question?.filterLabel && question?.parentId ? (
						<div className={el('question-name')}> {question.filterLabel} </div>
					) : null}
					<div className={el('title-content')}>
						<span className={el('title-number')}>{i}.</span>
						<div
							className={el('question-title')}
							// eslint-disable-next-line
							dangerouslySetInnerHTML={{ __html: getLabel(question) || question?.label }}
						/>
					</div>

					{renderQuestionAsset(question)}
				</div>

				<div className={el('question-options')}>
					{question.style === 'grid' ? renderGridOptions(question) : renderSelectOptions(question)}
				</div>
			</div>
			<div className={el('question-right')}>
				<span className={el('settings-title')}> Settings </span>
				<div>
					<div className={el('item disabled')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={question?.randomizeOptions}
							text="Required"
							onToggle={() => false}
						/>
						<span className={el('item-label')}>Randomize options</span>
					</div>
					{question.style === 'grid' ? (
						<div className={el('item disabled')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={getRandomizeAttributes(question)}
								text="Required"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Randomize attributes</span>
						</div>
					) : null}
					{question?.style !== 'ranked' && (
						<div className={el('item disabled')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={getMultipleSelection(question)}
								text="Required"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Multiple selection</span>
						</div>
					)}
					{question?.style !== 'ranked' && (
						<div className={el('item disabled')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={question?.addScale}
								text="Add Scale"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Add scale</span>
						</div>
					)}

					{question?.style === 'ranked' && (
						<div className={el('item disabled')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={getTopNSetting(question)}
								text="Rank top n"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Rank top n</span>
						</div>
					)}

					<div className={el('item disabled')}>
						<CustomizeVisibilityDropdown
							studyId={audienceTemplate?.studyId}
							questionId={question.id}
							changeScreeningQuestionVisibility={() => {}}
							isTemplate={!!audienceTemplate}
							audienceTemplate={audienceTemplate}
						/>
					</div>

					<div className={el('image-settings')}>
						<Collapse title="Image Settings" />
					</div>
				</div>
			</div>
		</div>
	);

	const renderQuestions = () => {
		const screeningQuestions = audienceTemplate?.screeningQuestions || [];
		if (screeningQuestions?.length) {
			return screeningQuestions.map((question, i) => renderSelectQuestion(question, i + 1));
		}
		return 'No screening questions available';
	};

	return <div className={el('container')}>{renderQuestions()}</div>;
};

AudienceScreeningQuestionsViewMode.displayName = 'AudienceScreeningQuestionsViewMode';
AudienceScreeningQuestionsViewMode.propTypes = {
	audienceTemplate: PropTypes.object,
	selectedLanguage: PropTypes.string,
};

export default AudienceScreeningQuestionsViewMode;
