import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'question-library-header';
const el = (name, mod) => cn(className, name, mod);

const QuestionLibraryHeader = ({ children, title = 'Add a Screening Question' }) => (
	<div className={className}>
		<div className={el('title')}>
			<h4>{title}</h4>
		</div>
		<div className={el('content')}>{children}</div>
	</div>
);

QuestionLibraryHeader.propTypes = {
	children: PropTypes.object,
	title: PropTypes.string,
};

export default QuestionLibraryHeader;
