import React, { useState, useRef, useCallback, useEffect } from 'react';
import cn from 'src/utilities/bem-cn';

import Caret from 'public/images/audience/arrow_down.svg';
import useOutsideClick from 'src/hooks/useOutsideClick';

import SearchDropDown from '../SearchDropdown';
import './styles.scss';

const className = 'single-select-drop-down';

const el = (name, mod) => cn(className, name, mod);

const SingleDropDown = ({ data = [], onChange, value, placeholder = '', disabled = false, searchable = false }) => {
	const containerRef = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(value);

	const localOnChange = useCallback(
		([item]) => {
			setIsOpen(false);
			setSelected(item);
			onChange(item);
		},
		// eslint-disable-next-line
		[setSelected],
	);

	useOutsideClick(containerRef, () => {
		setIsOpen(false);
	});

	useEffect(() => {
		if (value !== selected) {
			setSelected(value);
		}
		// eslint-disable-next-line
	}, [value]);

	return (
		<div ref={containerRef} className={`${el('container')} ${disabled ? el('disabled') : ''}`}>
			<div
				aria-hidden
				className={el('control-row')}
				onClick={() => {
					if (!disabled) {
						setIsOpen(prevState => !prevState);
					}
				}}
			>
				<div className={el('selected')}>
					{data?.find(e => e?.value === selected?.value) ? (
						<div className={el('item_container')}>
							<span className={el('label')}>{data?.find(e => e?.value === selected?.value)?.label}</span>
						</div>
					) : (
						<span className={el('placeholder')}>{placeholder}</span>
					)}
				</div>
				{!disabled ? (<div className={el(`arrow-container ${isOpen ? el('open') : ''}`)}>
					<img src={Caret} alt="caret" />
				</div>) : null}
			</div>
			{isOpen && <SearchDropDown data={data} value={selected} onChange={localOnChange} searchable={searchable} />}
		</div>
	);
};

export default SingleDropDown;
