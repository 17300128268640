// TODO - CHANGE PERMISSION FOR AUDIENCE (Old todo item, but moves to top here)
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleTabs from 'src/components/shared/SimpleTabs';
import cn from 'src/utilities/bem-cn';
import Auth from 'src/utilities/auth';
import * as authSelectors from 'src/domains/auth/selectors';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import './styles.scss';

const className = 'bar-tabs';
const el = name => cn(className, name);

class BarWithTabs extends React.Component {
	onChangeLanguage = language => {
		const { setLanguage, fetchProducts, fetchQuestions, study } = this.props;
		const { id: studyId } = study;
		setLanguage(language);
		fetchProducts(studyId);
		fetchQuestions(studyId);
	};

	renderSimpleTabs = () => {
		const { replace = false } = this.props;
		// TODO: These were the level of checks in place that used with WithScope, here incase we need this as reference
		// userCanListAudiences && userCanListUsers && userCanListQuestions && userCanListProducts && isAdmin
		// const userCanListAudiences = Auth.userCan('audience:list', true);
		// const userCanListProducts = Auth.userCan('product:list', true);
		const userCanListUsers = Auth.userCan('user:list', true);
		const canInviteToStudies = Auth.userCan('study:invite');
		const userCanListQuestions = Auth.userCan('question:list', true);
		const userCanCreateStudy = Auth.userCan('study:create');
		const userCanUpdateStudy = Auth.userCan('study:update', true);
		const isAdmin = Auth.userCan('account:create', true);
		const tabs = [];
		if (userCanListUsers && canInviteToStudies) {
			tabs.push({
				to: 'access',
				label: 'Access',
			});
		}

		// if (userCanUpdateStudy) {
		// 	tabs.push({
		// 		to: 'data',
		// 		label: 'Data',
		// 	});
		// }
		if (userCanCreateStudy) {
			tabs.push({
				to: 'study-settings',
				label: 'Study Settings',
			});
		}
		if (isAdmin) {
			tabs.push({
				to: 'admin-settings',
				label: 'Admin Settings',
			});
		}

		return <SimpleTabs tabs={tabs} partialMatch replace={replace} />;
	};

	render() {
		const { study, languages } = this.props;
		if (study === null || languages === null) {
			return null;
		}
		return <div className={className}>{this.renderSimpleTabs()}</div>;
	}
}

BarWithTabs.propTypes = {
	replace: PropTypes.bool,
	languages: PropTypes.any,
	setLanguage: PropTypes.any,
	fetchProducts: PropTypes.any,
	fetchQuestions: PropTypes.any,
	study: PropTypes.any,
};

const mapStateToProps = state => ({
	audiences: selectors.getAudiences(state),
	study: selectors.getStudy(state),
	languages: selectors.getLanguages(state),
	isDigAdmin: authSelectors.isDigAdmin(state),
});

const mapDispatchToProps = dispatch => ({
	closeStudy: id => dispatch(actions.closeStudy(id)),
	publishStudy: id => dispatch(actions.publishStudy(id)),
	draftStudy: id => dispatch(actions.draftStudy(id)),
	patchStudy: (id, data) => dispatch(actions.patchStudy(id, data)),
	patchStudySettings: (id, data) => dispatch(actions.patchStudySettings(id, data)),
	setLanguage: language => dispatch(actions.setLanguage(language)),
	fetchProducts: id => dispatch(actions.fetchProducts(id)),
	fetchQuestions: id => dispatch(actions.fetchQuestions(id)),
	setLaunchAudienceModal: launchAudienceModal => dispatch(actions.setLaunchAudienceModal(launchAudienceModal)),
	submitStudyReview: studyId => dispatch(actions.submitStudyReview(studyId)),
	setManageLanguagesModal: manageLanguagesModal => dispatch(actions.setManageLanguagesModal(manageLanguagesModal)),
	setTranslationsModal: translationsModal => dispatch(actions.setTranslationsModal(translationsModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarWithTabs);
