import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import ListChip from 'src/components/shared/ListChip/index.js';
import { countriesWithFlags } from 'src/utilities/upsiideCountriesWithFlag';
import { getAllStudiesData } from 'src/domains/selectors';
import { useSelector } from 'react-redux';
import StudyCountries from 'src/components/shared/SearchResults/shared/StudyCountries';
import './styles.scss';

const className = 'study-country-and-languages';
const el = name => cn(className, name);

const StudyCountryAndLanguages = ({ study }) => {
	const { languages } = useSelector(getAllStudiesData) || [];

	const countryNames = study?.countryIds?.map(
		countryId => countriesWithFlags.find(countryInfo => countryInfo.id === countryId)?.label,
	);

	if (study?.languages?.length || countryNames?.length) {
		return (
			<div className={className}>
				{study?.languages?.length ? (
					<ListChip
						tooltipTitle="Language"
						data={study?.languages}
						renderChipContent={lang => lang?.toUpperCase()}
						renderCustomTooltipLabel={lang =>
							languages?.find(language => language?.languageCode.toLowerCase() === lang?.toLowerCase())
								?.label
						}
						customTooltipClass={el('options-tooltip-margin')}
						key={`languages-${study?.uuid}`}
					/>
				) : null}
				{countryNames?.length ? <StudyCountries study={study} /> : null}
			</div>
		);
	}

	return null;
};

StudyCountryAndLanguages.propTypes = {
	study: PropTypes.object,
};

export default StudyCountryAndLanguages;
