import React from 'react';
import { connect } from 'react-redux';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import Button from 'src/components/elements/Button';
import ButtonWithDropdown from 'src/components/elements/ButtonWithDropdown';
import AlertNotification from 'src/components/elements/AlertNotification';
import capitalize from 'lodash/capitalize';
import * as actions from '../../../../actions';

import './styles.scss';

const className = 'study-status';
const el = (name, modifier) => cn(className, name, modifier);

const StudyStatus = ({
	study,
	audiences,
	setLaunchAudienceModal,
	closeStudy,
	publishStudy,
	draftStudy,
	isDigAdmin,
	setShowLaunchWithCustomQualModalForm,
	showContactSupport = false,
}) => {
	const changeStatus = status => {
		switch (status) {
			case 'active':
				publishStudy(study?.id);
				break;
			case 'closed':
				closeStudy(study?.id);
				break;
			default:
				draftStudy(study?.id);
				break;
		}
	};

	const getStudyStatusLabel = () => {
		let label = study?.status;
		switch (study?.status) {
			case 'active':
				label = 'Live';
				break;
			case 'in-review':
				label = 'Under review';
				break;
			case 'needs-review':
				label = 'Draft';
				break;
			case 'closed':
				label = 'Complete';
				break;
			default:
				label = capitalize(study?.status);
		}
		return label;
	};

	const getButtonWithDropdownOptions = () => {
		const options = [
			{
				label: 'Draft',
				onClick: () => {
					changeStatus('draft');
				},
			},
			{
				label: 'Live',
				onClick: () => {
					changeStatus('active');
				},
			},
			{
				label: 'Complete',
				onClick: () => {
					changeStatus('closed');
				},
			},
		];

		if (
			audiences &&
			audiences.length > 0 &&
			audiences.filter(audience => audience.status === 'needs-review').length
		) {
			options.push({
				label: 'Submit Review',
				onClick: () => {
					setShowLaunchWithCustomQualModalForm(true);
				},
			});
		}

		return options;
	};

	const getButton = () => {
		let label = getStudyStatusLabel();
		if (showContactSupport && (study?.status === 'draft' || study?.status === 'needs-review')) {
			label = 'Launch';
		}
		return (
			<Button
				onClick={() => {
					// If the audiences have been populated...
					if (audiences && audiences.length) {
						const audiencesReady = audiences.filter(audience => audience.status === 'ready').length;
						const needReviews = audiences.filter(audience => audience.status === 'needs-review').length;
						// Check if we are in draft and any of the audiences are ready
						if (study?.status === 'in-review') {
							// contact support
						} else if (needReviews) {
							setShowLaunchWithCustomQualModalForm(true);
						} else if (audiencesReady) {
							// Wait a second and launch the modal
							setTimeout(() => {
								setLaunchAudienceModal({ visible: true });
							}, 1000);
						}
					}
				}}
				label={label}
			/>
		);
	};

	const getMailToButton = () => {
		const text = 'Contact Support';
		return (
			<a
				className="button"
				href={`mailto:support@upsiide.com?subject=Upsiide Support - ${study?.name || 'Study'}`}
			>
				<span className="button__content">{text}</span>
			</a>
		);
	};

	const renderAlertNotification = () => {
		// If the user is an admin
		if (isDigAdmin) {
			// And the study is in 'Needs Review'
			if (study?.status === 'needs-review') {
				// Show the alert notification
				return <AlertNotification />;
			}
			// If the user is not an admin, the button is in the audience area, the study is in 'Needs Review'
		} else if (!isDigAdmin && showContactSupport && study?.status === 'needs-review') {
			return <AlertNotification />;
		}
		// Otherwise don't render the notification
		return false;
	};

	const renderButton = () => {
		if (showContactSupport) {
			// If this is appearing in the 'Audience Summary Total' area and the study is 'In Review' show the mail button
			if (study?.status === 'in-review') {
				// Show the e-mail button
				return getMailToButton();
			}
			// If the study is not in review show the button for non-admins and the dropdown for admins
			return !isDigAdmin ? (
				getButton()
			) : (
				<ButtonWithDropdown label={getStudyStatusLabel()} items={getButtonWithDropdownOptions()} />
			);
		}
		// If the study is not in review show the badge for non-admins and the dropdown for admins
		return !isDigAdmin ? (
			<div className={el('badge')}>{getStudyStatusLabel()}</div>
		) : (
			<ButtonWithDropdown label={getStudyStatusLabel()} items={getButtonWithDropdownOptions()} />
		);
	};

	return (
		<div className={`${className} ${study?.status}`}>
			{renderButton()}
			{renderAlertNotification()}
		</div>
	);
};

StudyStatus.propTypes = {
	study: PropTypes.object,
	audiences: PropTypes.any,
	setLaunchAudienceModal: PropTypes.func,
	closeStudy: PropTypes.func,
	publishStudy: PropTypes.func,
	draftStudy: PropTypes.func,
	isDigAdmin: PropTypes.bool,
	showContactSupport: PropTypes.bool,
	setShowLaunchWithCustomQualModalForm: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
	setShowLaunchWithCustomQualModalForm: showLaunchWithCustomQualModalForm =>
		dispatch(actions.setShowLaunchWithCustomQualModalForm(showLaunchWithCustomQualModalForm)),
});

export default connect(null, mapDispatchToProps)(StudyStatus);
