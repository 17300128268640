import React from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import Modal from 'src/components/shared/Modal';
import './styles.scss';

const className = 'piping-error-modal';
const el = (name, mod) => cn(className, name, mod);

const PipingErrorModal = ({
	onPipingErrorModalConfirm,
	onPipingErrorModalCancel,
	showPipingErrorModal,
	setShowPipingErrorModal,
}) => {
	const headerText = `Warning`;
	const headerIcon = {
		icon: 'warning',
		size: 'large',
		color: 'red',
		className: el('warning-icon'),
	};
	const bodyText = 'Completing this action will detach any recalled content.';
	const confirmText = `Don't Do This`;
	const cancelText = 'Complete Action';
	return (
		<Modal show={showPipingErrorModal} width={500} padding={0} onClose={() => setShowPipingErrorModal(false)}>
			<AreYouSureForm
				headerText={headerText}
				bodyText={bodyText}
				confirmText={confirmText}
				cancelText={cancelText}
				onConfirm={() => {
					onPipingErrorModalConfirm();
				}}
				onCancel={() => {
					onPipingErrorModalCancel();
				}}
				headerIcon={headerIcon}
				customClass={className}
				confirmType="green"
			/>
		</Modal>
	);
};

PipingErrorModal.propTypes = {
	onPipingErrorModalConfirm: PropTypes.func,
	onPipingErrorModalCancel: PropTypes.func,
	showPipingErrorModal: PropTypes.bool,
	setShowPipingErrorModal: PropTypes.func,
};

export default PipingErrorModal;
