import React from 'react';
import { connect } from 'react-redux';

import * as selectors from 'src/domains/auth/selectors';

const and = collection => collection.reduce((acc, item) => acc && item, true);
const or = collection => collection.reduce((acc, item) => acc || item, false);
const MODES = { and, or };

const WithScope = ({
	scopes,
	globalScopes,
	scope,
	mode = 'and',
	global,
	children,
	isDigAdmin,
	clientId,
	clientIdProps,
}) => {
	// client ID can come from either the selector or props passed down to this component
	const currentClientId = clientIdProps || clientId;
	const scopeArray = currentClientId ? scopes[currentClientId] || [] : [];

	const scopesToCheck = global ? globalScopes : scopeArray;
	const able = Array.isArray(scope)
		? MODES[mode](scope.map(item => scopesToCheck.indexOf(item) > -1))
		: scopesToCheck.indexOf(scope) > -1;

	return children(able || isDigAdmin, isDigAdmin);
};

const mapStateToProps = state => ({
	scopes: selectors.getScopes(state),
	globalScopes: selectors.getGlobalScopes(state),
	isDigAdmin: selectors.isDigAdmin(state),
	clientId: selectors.getClientId(state),
});

export default connect(mapStateToProps)(WithScope);
