import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Input from 'src/components/inputs/Input';
import { AnimatePresence, motion } from 'framer-motion';
import AudienceFilterDropdown from 'src/domains/manage-study/components/audience/AudienceFilterDropdown';
import { audienceService } from 'src/services';

import './styles.scss';
import { Iconof } from '@upsiide/ui-components';
import TextInput from 'src/components/elements/TextInput';

const className = 'study-template-header';
const el = (name, mod) => cn(className, name, mod);

const StudyTemplateHeader = ({
	showModal,
	step,
	query,
	setQuery,
	onGoBack,
	onClose,
	selectedCountries,
	setSelectedCountries,
	selectedLanguages,
	setSelectedLanguages,
	setSearchLoading,
	includeBack = true,
	selectedTemplate,
	setSelectedAudienceTemplate,
	templateTypeTab,
}) => {
	const [templateName, setTemplateName] = useState(selectedTemplate?.name || '');

	useEffect(() => {
		setTemplateName(selectedTemplate?.name);
	}, [selectedTemplate]);

	const getTitle = () => {
		if (step === 'templates') {
			return 'Templates';
		}
		if (step === 'preview') {
			return 'Template Preview';
		}
		return 'Template';
	};

	const renderTemplatesView = () => (
		<div key="search-bar">
			<div className={el('search-bar')}>
				<AudienceFilterDropdown
					showModal={showModal}
					selectedCountries={selectedCountries}
					setSelectedCountries={setSelectedCountries}
					selectedLanguages={selectedLanguages}
					setSelectedLanguages={setSelectedLanguages}
					showCountries={templateTypeTab === 'audienceTemplate'}
				/>
				<Input
					className={el('search-input')}
					placeholder="Search"
					value={query}
					onChange={e => {
						setQuery(e);
					}}
				/>
				<div className={el('search-bar-icon-container')}>
					<AnimatePresence initial={false}>
						{query && (
							<motion.div
								key="clear"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							>
								<Iconof
									icon="clear"
									onClick={() => {
										setQuery('');
										setSearchLoading(true);
									}}
									aria-hidden
								/>
							</motion.div>
						)}
						{!query && (
							<motion.div
								key="search"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							>
								<Iconof icon="search" />
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
		</div>
	);

	const renderBackButton = () => {
		if (includeBack && step !== 'templates') {
			return (
				<button type="button" className={el('back-button')} onClick={onGoBack}>
					<Iconof icon="chevron_left" size="large" />
				</button>
			);
		}
	};

	const onChangeAudienceName = async name => {
		await audienceService.updateCollection(selectedTemplate?.studyId, selectedTemplate?.id, { name });
	};

	const onBlurAudienceName = e => {
		const audienceNameInputValue = e.target.value.replace(/\s/g, '');
		if (audienceNameInputValue.length) {
			if (e.target.value !== selectedTemplate?.name) {
				onChangeAudienceName(e.target.value.trim());
				if (setSelectedAudienceTemplate) {
					setSelectedAudienceTemplate({ ...selectedTemplate, name: e.target.value.trim() });
				}
			}
		}
	};

	return (
		<div className={className}>
			<div className={el('name-container')}>
				{renderBackButton()}
				{step === 'editTemplate' ? (
					<TextInput
						className={el('editable-name')}
						textClassName={el('name_container')}
						value={templateName}
						placeholder="Untitled Audience"
						onChange={e => setTemplateName(e.target.value)}
						onBlur={onBlurAudienceName}
						isCharacterCount={false}
						maxLength={100}
					/>
				) : (
					<h4>{getTitle()}</h4>
				)}
			</div>
			<div className={el('content')}>
				<AnimatePresence>
					{step === 'templates' && renderTemplatesView()}
					<div className={el('close')} onClick={onClose} aria-hidden>
						<Iconof icon="clear" />
					</div>
				</AnimatePresence>
			</div>
		</div>
	);
};

StudyTemplateHeader.propTypes = {
	showModal: PropTypes.bool,
	step: PropTypes.string,
	query: PropTypes.string,
	setQuery: PropTypes.func,
	onGoBack: PropTypes.func,
	onClose: PropTypes.func,
	selectedCountries: PropTypes.array,
	setSelectedCountries: PropTypes.func,
	selectedLanguages: PropTypes.array,
	setSelectedLanguages: PropTypes.func,
	setSearchLoading: PropTypes.func,
	includeBack: PropTypes.func,
	selectedTemplate: PropTypes.object,
	setSelectedAudienceTemplate: PropTypes.func,
	templateTypeTab: PropTypes.string,
};

export default StudyTemplateHeader;
