import React from 'react';
import QuestionLady from 'src/components/icons/QuestionLady';
import PropTypes from 'prop-types';
import AddQuestionButton from 'src/components/elements/AddQuestionButton';
import 'react-tippy/dist/tippy.css';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'empty-questions';
const el = (name, mod) => cn(className, name, mod);

const EmptyQuestionSection = ({ addQuestionOptions, audience, blockUpdateOrBreakTemplate }) => (
	<div className={!audience ? el('no-question') : ''}>
		{!audience && (
			<>
				<QuestionLady />
				<p>There are no questions in this section yet.</p>
			</>
		)}
		<AddQuestionButton
			audience={audience}
			addQuestionOptions={addQuestionOptions}
			isLastQuestionItem
			blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
		/>
	</div>
);

EmptyQuestionSection.propTypes = {
	addQuestionOptions: PropTypes.array,
	audience: PropTypes.bool,
	blockUpdateOrBreakTemplate: PropTypes.func,
};

export default EmptyQuestionSection;
