import React from 'react';
import { useIsBlueprint } from 'src/hooks';
import './styles.scss';

const className = 'manage-template-banner';

const ManageTemplateBanner = () => {
	const { isBlueprint } = useIsBlueprint();
	const { location } = window;
	const isCreateMode = location.pathname.includes('/create');
	const isAudienceMode = location.pathname.includes('/audiences');

	return isBlueprint && (isCreateMode || isAudienceMode) && <div className={className}>Manage Template Mode</div>;
};

export default ManageTemplateBanner;
