import axios from '../utilities/axios';
import * as misc from '../utilities/misc';

const getData = ({ data }) => data;

const API_PATH = `${process.env.apiPath}`;

const getStudyQuestion = (studyId, questionId) => axios.get(`${API_PATH}/studies/${studyId}/questions/${questionId}/`);

const getStudyQuestions = (studyId, languageCode) =>
	axios.get(`${API_PATH}/studies/${studyId}/questions?${misc.toURL({ languageCode })}`);

const getPublicStudyQuestions = (studyId, languageCode) =>
	axios.get(`${API_PATH}/public/studies/${studyId}/questions?${misc.toURL({ languageCode })}`);

const create = (studyId, data) => axios.post(`${API_PATH}/studies/${studyId}/questions`, data).then(getData);

const deleteQuestion = (studyId, questionId) =>
	axios.delete(`${API_PATH}/studies/${studyId}/questions/${questionId}/`).then(getData);

const deleteCustomQualifier = (audienceUuid, questionId) =>
	axios.delete(`${API_PATH}/audiences/${audienceUuid}/questions/${questionId}/`).then(getData);

const reorder = (studyId, newOrder) =>
	axios.post(`${API_PATH}/studies/${studyId}/questions/order`, newOrder).then(getData);

const patch = (studyId, questionId, data) =>
	axios.patch(`${API_PATH}/studies/${studyId}/questions/${questionId}`, data);

const getQuestionReport = (studyId, questionId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/report/open-end`, data);

const getPublicQuestionReport = (studyId, questionId, data) =>
	axios.post(`${API_PATH}/public/studies/${studyId}/questions/${questionId}/report/open-end`, data);

const getQuestionKeywords = (studyId, questionId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/keywords`, data);

const getPublicQuestionKeywords = (studyId, questionId, data) =>
	axios.post(`${API_PATH}/public/studies/${studyId}/questions/${questionId}/keywords`, data);

const createOption = (studyId, questionId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/options`, data);

const patchOption = (studyId, questionId, optionId, data) =>
	axios.patch(`${API_PATH}/studies/${studyId}/questions/${questionId}/options/${optionId}`, data);

const deleteOption = (studyId, questionId, optionId) =>
	axios.delete(`${API_PATH}/studies/${studyId}/questions/${questionId}/options/${optionId}`);

const createSetting = (studyId, questionId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/settings`, data);

const patchSetting = (studyId, questionId, settingId, data) =>
	axios.patch(`${API_PATH}/studies/${studyId}/questions/${questionId}/settings/${settingId}`, data);

const deleteSetting = (studyId, questionId, settingId) =>
	axios.delete(`${API_PATH}/studies/${studyId}/questions/${questionId}/settings/${settingId}`);

const createAttribute = (studyId, questionId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/attributes`, data);

const patchAttribute = (studyId, questionId, attributeId, data) =>
	axios.patch(`${API_PATH}/studies/${studyId}/questions/${questionId}/attributes/${attributeId}`, data);

const postAttributeTranslation = (studyId, questionId, attributeId, data) =>
	axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/attributes/${attributeId}/translations`, data);

const patchAttributeTranslation = (studyId, questionId, attributeId, translationId, data) =>
	axios.patch(
		`${API_PATH}/studies/${studyId}/questions/${questionId}/attributes/${attributeId}/translations/${translationId}`,
		data,
	);

const deleteAttribute = (studyId, questionId, attributeId) =>
	axios.delete(`${API_PATH}/studies/${studyId}/questions/${questionId}/attributes/${attributeId}`);

const setScreeningQuestionVisibility = (studyId, questionId, data) =>
	axios.patch(`${API_PATH}/studies/${studyId}/questions/${questionId}/visibility`, data);

export default {
	getStudyQuestion,
	getStudyQuestions,
	getPublicStudyQuestions,

	create,
	delete: deleteQuestion,
	deleteCustomQualifier,
	reorder,
	patch,

	// options
	createOption,
	patchOption,
	deleteOption,

	// settings
	createSetting,
	patchSetting,
	deleteSetting,

	getQuestionReport,
	getPublicQuestionReport,
	getQuestionKeywords,
	getPublicQuestionKeywords,

	setScreeningQuestionVisibility,

	// attributes
	createAttribute,
	patchAttribute,
	postAttributeTranslation,
	patchAttributeTranslation,
	deleteAttribute,
};
