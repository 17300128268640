import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'checkbox';
const el = name => cn(className, name);

const CheckboxButton = ({ value, tooltipProps }) => {
	const TooltipComponent = tooltipProps ? Tooltip : React.Fragment;
	const tooltipFinalProps = tooltipProps
		? {
				...tooltipProps,
		  }
		: {};

	return (
		<TooltipComponent {...tooltipFinalProps}>
			<div className={`${el('box')} ${value ? 'selected' : ''}`}>
				<div className={`${el('content')} ${value ? '' : 'hidden'}`} />
			</div>
		</TooltipComponent>
	);
};

CheckboxButton.propTypes = {
	value: PropTypes.any,
	tooltipProps: PropTypes.object,
};

const Checkbox = ({ label, value, disabled, onChange, tooltipProps }) => (
	<div className={`${className} ${disabled ? 'disabled' : ''}`} onClick={() => onChange(!value)} aria-hidden>
		<CheckboxButton value={value} tooltipProps={tooltipProps} />

		{label && <div className={el('label')}>{label}</div>}
	</div>
);

Checkbox.propTypes = {
	label: PropTypes.any,
	value: PropTypes.any,
	disabled: PropTypes.any,
	onChange: PropTypes.any,
	tooltipProps: PropTypes.object,
};

export default Checkbox;
