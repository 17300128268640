import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getPublicStudyData } from '../../selectors';

export const getStudy = state => getPublicStudyData(state).study;
export const getLanguage = state => getPublicStudyData(state).language;
export const getLanguages = state => getPublicStudyData(state).languages;
export const getCategories = state => getPublicStudyData(state).categories;
export const getProductTags = state => getPublicStudyData(state).productTags;
export const getProducts = state => getPublicStudyData(state).products;
export const getSaveButtonState = state => getPublicStudyData(state).saveButtonState;
export const getSaveButtonText = state => getPublicStudyData(state).saveButtonText;
export const getProductModal = state => getPublicStudyData(state).productModal;
export const getQuestionModal = state => getPublicStudyData(state).questionModal;
export const getQuestions = state => getPublicStudyData(state).questions;
export const getImportImagesModal = state => getPublicStudyData(state).importImagesModal;
export const getImportProductsModal = state => getPublicStudyData(state).importProductsModal;
export const getImportRespondentsModal = state => getPublicStudyData(state).importRespondentsModal;
export const getExportConfirmationModal = state => getPublicStudyData(state).exportConfirmationModal;
export const getCountries = state => getPublicStudyData(state).countries;
export const getCountryLanguages = state => getPublicStudyData(state).countryLanguages;
export const getResponses = state => getPublicStudyData(state).responses;

export const getStudyLanguages = state => {
	const study = getStudy(state);
	const languages = getLanguages(state);

	return ((study || {}).languages || []).map(languageCode => {
		const accordingLanguage = languages.filter(language => language.languageCode === languageCode).pop();
		const label = accordingLanguage
			? accordingLanguage.label
			: `Unknown language with languageCode ${languageCode}`;

		return { languageCode, label };
	});
};

export const getTranslationValidations = state => get(getPublicStudyData(state), 'studyValidations.translations', {});
export const getValidationLoi = state => get(getPublicStudyData(state), 'studyValidations.lengthOfInterview');
export const getAudienceNotice = state => getPublicStudyData(state).audienceNotice;

/* Model access stuff */

export const getAccessModal = state => getPublicStudyData(state).accessModal;
export const getAccessEntries = state => getPublicStudyData(state).accessEntries;
export const getPossibleEntries = state => getPublicStudyData(state).possibleEntries;
export const getAccessCommonError = state => getPublicStudyData(state).accessCommonError;

/* Audience Stuff */
export const getAudiences = state => getPublicStudyData(state).audiences;
export const getAudience = state => getPublicStudyData(state).audience;
export const getCreateAudienceModal = state => getPublicStudyData(state).createAudienceModal;
export const getEditAudienceModal = state => getPublicStudyData(state).editAudienceModal;
export const getAudienceTemplates = state => getPublicStudyData(state).audienceTemplates;
export const getAudiencePrice = state => getPublicStudyData(state).audiencePrice;
export const getBYOPrice = state => getPublicStudyData(state).BYOPrice;
export const getLaunchAudience = state => getPublicStudyData(state).launchAudience;
export const getLaunchAudienceModal = state => getPublicStudyData(state).launchAudienceModal;
export const getStopAudienceModal = state => getPublicStudyData(state).stopAudienceModal;
export const getAudienceCollections = state => getPublicStudyData(state).audienceCollections;
export const getAudienceCollection = state => getPublicStudyData(state).audienceCollection;

export const getGeographies = state => getPublicStudyData(state).geographies;
export const getUploadImageStatus = state => getPublicStudyData(state).uploadImageStatus;
export const getRoles = state => getPublicStudyData(state).roles;

export const getAudienceModal = state => getPublicStudyData(state).audienceModal;
export const getAudienceNorms = state =>
	getPublicStudyData(state).currentSection.content && getPublicStudyData(state).currentSection.content.audienceNorms;

export const getSections = state => getPublicStudyData(state).sections;
export const getCurrentSection = state => getPublicStudyData(state).currentSection;

export const getShareStudyModal = state => getPublicStudyData(state).shareStudyModal;

// Set Section Name
export const getSectionNameData = state => getPublicStudyData(state).sectionNameData;

export const getItemLogic = state => getPublicStudyData(state).logic;

export const getManageLanguagesModal = state => getPublicStudyData(state).manageLanguagesModal;
export const getTranslationsModal = state => getPublicStudyData(state).translationsModal;

export const getDiscardProductChangesModal = state => getPublicStudyData(state).discardProductChangesModal;
export const getShowGoogleTranslateModal = state => getPublicStudyData(state).showGoogleTranslateModal;

// Section settings
export const getSectionSettingsInsertData = state => getPublicStudyData(state).sectionSettingsInsertData;
export const getSavingSectionSettings = state => getPublicStudyData(state).isSavingSectionSettings;

export const getShowLaunchWithCustomQualModalForm = state =>
	getPublicStudyData(state).showLaunchWithCustomQualModalForm;

export const getAudienceForReview = state => getPublicStudyData(state).audienceForReview;

export const getProductSectionViewMode = state => getPublicStudyData(state).productSectionViewMode;

export const getBaseSizeThreshold = state => getPublicStudyData(state).baseSizeThreshold;

export const getStudyId = createSelector(getStudy, study => study?.id || null);
export const getStudyAssetId = createSelector(getStudy, study => study?.assetId || null);
export const getStudyAsset = createSelector(getStudy, study => study?.asset || null);
