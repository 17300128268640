import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

function fetchShareOfChoice(sectionId, studyId, selectedProducts) {
	return axios.post(`${API_PATH}/studies/${studyId}/sections/${sectionId}/share-of-choice`, selectedProducts);
}

let fetchShareOfChoiceV2CancelToken = null;
function fetchShareOfChoiceV2(studyId, sectionId, data) {
	if (fetchShareOfChoiceV2CancelToken) {
		fetchShareOfChoiceV2CancelToken.cancel('Operation canceled due to new request.');
	}
	
	const { CancelToken } = axios;
	fetchShareOfChoiceV2CancelToken = CancelToken.source();

	return axios
		.post(`${API_PATH}/studies/${studyId}/sections/${sectionId}/share-of-choice/v2`, {
			...data,
			audienceUuids: data.audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals'),
		}, {
			cancelToken: fetchShareOfChoiceV2CancelToken.token,
		})
		.then(response => {
			fetchShareOfChoiceV2CancelToken = null;
			return response;
		})
		.catch(function(thrown) {
			console.log(thrown.message);
		});
}

function fetchPublicShareOfChoiceV2(studyId, sectionId, data) {
	if (fetchShareOfChoiceV2CancelToken) {
		fetchShareOfChoiceV2CancelToken.cancel('Operation canceled due to new request.');
	}

	const { CancelToken } = axios;
	fetchShareOfChoiceV2CancelToken = CancelToken.source();

	return axios
		.post(`${API_PATH}/public/studies/${studyId}/sections/${sectionId}/share-of-choice/v2`, {
			...data,
			audienceUuids: data.audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals'),
		}, {
			cancelToken: fetchShareOfChoiceV2CancelToken.token,
		})
		.then(response => {
			fetchShareOfChoiceV2CancelToken = null;
			return response;
		})
		.catch(function(thrown) {
			console.log(thrown.message);
		});
}

// studyId is not needed
function fetchShareOfChoiceProgress(sectionId, studyId) {
	return axios.get(`${API_PATH}/studies/${studyId}/sections/${sectionId}/share-of-choice/process-progress`);
}

// studyId is not needed
function fetchPublicShareOfChoiceProgress(sectionId, studyId) {
	return axios.get(`${API_PATH}/public/studies/${studyId}/sections/${sectionId}/share-of-choice/process-progress`);
}

function processShareOfChoice(sectionId, studyId) {
	return axios.post(`${API_PATH}/studies/${studyId}/sections/${sectionId}/share-of-choice/process`);
}

function publicProcessShareOfChoice(sectionId, studyId) {
	return axios.post(`${API_PATH}/public/studies/${studyId}/sections/${sectionId}/share-of-choice/process`);
}

function createGroup(sectionId, studyId, name, productIds, isBaseCase = false) {
	return axios.put(`${API_PATH}/studies/${studyId}/sections/${sectionId}/share-of-choice/group`, {
		name,
		productIds,
		isBaseCase,
	});
}

function updateGroup(sectionId, studyId, groupId, name, productIds) {
	return axios.put(`${API_PATH}/studies/${studyId}/sections/${sectionId}/share-of-choice/group`, {
		id: groupId,
		name,
		productIds,
	});
}

function fetchShareOfChoiceGroups(sectionId, studyId) {
	return axios.get(`${API_PATH}/studies/${studyId}/sections/${sectionId}/share-of-choice/group`);
}

function fetchPublicShareOfChoiceGroups(sectionId, studyId) {
	return axios.get(`${API_PATH}/public/studies/${studyId}/sections/${sectionId}/share-of-choice/group`);
}

function deleteShareOfChoiceGroup(sectionId, studyId, groupId) {
	return axios.delete(`${API_PATH}/studies/${studyId}/sections/${sectionId}/share-of-choice/group/${groupId}`);
}

export default {
	fetchShareOfChoice,
	fetchShareOfChoiceV2,
	fetchPublicShareOfChoiceV2,
	fetchShareOfChoiceProgress,
	fetchPublicShareOfChoiceProgress,
	processShareOfChoice,
	publicProcessShareOfChoice,
	createGroup,
	updateGroup,
	fetchShareOfChoiceGroups,
	fetchPublicShareOfChoiceGroups,
	deleteShareOfChoiceGroup,
};
