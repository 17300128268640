import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Cookies from 'src/utilities/cookies';
import { useIsBlueprint } from 'src/hooks';
import Loadable from 'src/components/shared/Loadable';
import AMPHeaderError from 'src/components/shared/AMPHeaderError';
import ResponsiveModal from 'src/components/shared/ResponsiveModal';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import InterlockQuotasIcon from 'public/images/audience/interlock-quotas-icon.png';
import * as studySelectors from 'src/domains/manage-study/selectors';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import * as manageStudyActions from 'src/domains/manage-study/actions';
import * as manageBlueprintActions from 'src/domains/manage-blueprints/actions';
import * as reportingActions from 'src/domains/manage-study/actions/reporting';
import * as checkoutActions from 'src/domains/checkout/actions';
import * as services from 'src/services';
import { studySampleService, studyService, blueprintService } from 'src/services';
import DropDownGroup from 'src/domains/manage-study/components/audience/DropDownGroup';
import SingleDropDown from 'src/domains/manage-study/components/audience/SingleDropDown';
import MultiDropDown from 'src/domains/manage-study/components/audience/MultiDropDown';
import AudienceComponentHeader from 'src/components/shared/AudienceComponentHeader';
import { TextRowPlaceholder as TextRow } from '@upsiide/ui-components';

import './styles.scss';
import { Tooltip } from 'react-tippy';

const className = 'demographic';
const el = (name, mod) => cn(className, name, mod);

const sortByOrder = (a, b) => a.order - b.order;

const defaultIncidentRate = 40;

const Demographic = ({
	viewMode = false,
	demographicUuid = null,
	demographicGroup = null,
	templateGroupEditMode = false,
	setCurrentTab = () => {},
	template = {},
	setTemplate = () => {},
	templatePreviewMode = false,
	defaultPrice = 0,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { isBlueprint } = useIsBlueprint();
	const studiesOrBlueprints = isBlueprint ? 'templates' : 'studies';
	const selectors = isBlueprint ? manageBlueprintSelectors : studySelectors;
	const actions = isBlueprint ? manageBlueprintActions : manageStudyActions;
	const countries = useSelector(selectors.getCountries);
	const languages = useSelector(selectors.getCountryLanguages);
	const study = useSelector(selectors.getStudy);
	const groupData = useSelector(selectors.getGroup);
	const { content: group } = groupData;
	const audienceCollection = useSelector(selectors.getAudienceCollection);
	const [loading, setLoading] = useState(false);
	const [qualifications, setQualifications] = useState([]);
	const [selectedRegions, setSelectedRegions] = useState([]);
	const [genders, setGenders] = useState([]);
	const [genderLabel, setGenderLabel] = useState('Gender');
	const [ageLabel, setAgeLabel] = useState('Age');
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedLanguage, setSelectedLanguage] = useState(null);
	const [responses, setResponses] = useState(100);
	const [ir, setIr] = useState(50);
	const [price, setPrice] = useState();
	const [selectedGenders, setSelectedGenders] = useState([]);
	const [ages, setAges] = useState([]);
	const [hasAgeQuestion, setHasAgeQuestion] = useState(false);
	const [ageRangeErrors, setAgeRangeErrors] = useState({});
	const [hasGenderQuestion, setHasGenderQuestion] = useState(false);
	const [selectedAges, setSelectedAges] = useState(ages);
	const [groupName, setGroupName] = useState((group?.name?.length && group.name) || '');
	const [isDeleting, setIsDeleting] = useState(false);
	const [otherQuestions, setOtherQuestions] = useState([]);
	const [selectedOtherQuestionOptions, setSelectedOtherQuestionOptions] = useState([]);
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [creatingAgeRange, setCreatingAgeRange] = useState(false);

	const setAudiences = a => dispatch(checkoutActions.setAudiences(a));
	const setAudiencesAvailableForCheckout = a => dispatch(checkoutActions.setAudiencesAvailableForCheckout(a));
	const setLaunchAudienceData = (clientId, studyId, audienceUuid) =>
		dispatch(checkoutActions.setLaunchAudienceData(clientId, studyId, audienceUuid));

	// Quota
	const [genderQuotas, setGenderQuotas] = useState({ total: 0, useQuota: false });
	const [ageQuotas, setAgeQuotas] = useState({ total: 0, useQuota: false });
	const [regionQuotas, setRegionQuotas] = useState([]);
	const [otherQuestionsQuotas, setOtherQuestionsQuotas] = useState([]);
	const hasInterlockedQuotas = useMemo(() => {
		if (!group?.demographicQuestions?.length && !audienceCollection?.content?.screeningQuestions?.length) return;
		const interlockedDemographicQuestions = group?.demographicQuestions.filter(
			({ isInterlocked }) => isInterlocked,
		);

		const interlockedScreeningQuestions = audienceCollection?.content?.screeningQuestions.filter(
			({ interlockedWithinGroup }) => interlockedWithinGroup?.find(({ uuid }) => uuid === group?.uuid),
		);
		return !!interlockedDemographicQuestions?.length || !!interlockedScreeningQuestions?.length;
	}, [audienceCollection?.content?.screeningQuestions, group?.demographicQuestions, group?.uuid]);

	const hasDemographicQuotas = useMemo(() => {
		if (!group?.demographicQuestions?.length) return;
		const quotaQuestions = group?.demographicQuestions.filter(({ useQuota }) => useQuota);

		return !!quotaQuestions?.length;
	}, [group?.demographicQuestions]);

	const hasScreeningQuotas = useMemo(() => {
		if (!audienceCollection?.content?.screeningQuestions?.length) return;
		const quotaQuestions = audienceCollection?.content?.screeningQuestions.filter(({ useQuota }) => useQuota);

		return !!quotaQuestions?.length;
	}, [audienceCollection?.content?.screeningQuestions]);

	const [demographicRef, setDemographicRef] = useState(demographicGroup);

	const getDemographicUuid = useCallback(() => {
		if (!demographicUuid) return { uuid: demographicRef?.uuid, studyId: demographicRef?.studyId };
		return { uuid: demographicUuid, studyId: study?.id };
	}, [demographicUuid, demographicRef, study]);

	const getQuestionByClass = useCallback(
		questionClass => group?.demographicQuestions?.find(q => q?.class?.toLowerCase() === questionClass),
		[group],
	);

	const getRegionQuestions = useCallback(
		() => group?.demographicQuestions?.filter(q => q?.class?.toLowerCase() === 'region'),
		[group],
	);
	const geOtherQuestions = useCallback(
		() =>
			group?.demographicQuestions?.filter(
				q =>
					q?.class?.toLowerCase() !== 'region' &&
					q?.class?.toLowerCase() !== 'gender' &&
					q?.class?.toLowerCase() !== 'age',
			),
		[group],
	);

	const getQuotaTotal = quest => quest.reduce((acc, item) => acc + (item?.quota?.[0]?.quotaMax || 0), 0);

	const cleanStates = () => {
		setQualifications([]);
		setSelectedRegions([]);
		setGenders([]);
		setSelectedCountry(null);
		setSelectedLanguage(null);
		setSelectedGenders([]);
		setAges([]);
		setHasAgeQuestion(false);
		setHasGenderQuestion(false);
		setSelectedAges([]);
		// setGroupName('New Group');
		setGenderLabel('Gender');
		setAgeLabel('Age');
		setGenderQuotas({ total: 0, useQuota: false });
		setAgeQuotas({ total: 0, useQuota: false });
		setRegionQuotas([]);
		setOtherQuestions([]);
	};

	const canEdit = useMemo(
		() => group?.status !== 'live' && group?.status !== 'complete' && group?.status !== 'system-stopped',
		[group],
	);

	const canChangeOptions = useMemo(
		() => group?.status !== 'live' || group?.status !== 'complete' || group?.status !== 'system-stopped',
		[group],
	);

	// ======= Demographic questions setup ======

	const genderSetup = useCallback(() => {
		const genderQuestion = getQuestionByClass('gender');
		if (!genderQuestion) {
			setHasGenderQuestion(false);
			return;
		}
		setHasGenderQuestion(true);

		let total = 0;
		let allGenders = [];

		const currentSelectedGenders = genderQuestion
			?.options
			?.filter(option => !!option?.isSelected)
			?.sort((a,b) => a.order - b.order);

		total = getQuotaTotal(currentSelectedGenders);
		const useQuota = !!genderQuestion?.options.find(({ quota }) => quota?.quotaMax != null);
		allGenders = currentSelectedGenders?.map(opt => {
			let quota = {
				min: null,
				max: null,
				id: opt?.quota?.[0]?.id,
			};
			if (opt?.quota?.length && !Number.isNaN(parseInt(opt?.quota?.[0]?.quotaMax))) {
				quota = {
					min: Number(opt?.quota?.[0]?.quotaMin).toFixed(1),
					max: Number(opt?.quota?.[0]?.quotaMax).toFixed(1),
					id: opt?.quota?.[0]?.id,
				};
			}
			return {
				label: opt?.label,
				value: opt?.id,
				order: opt?.order,
				quota,
			};
		});

		setGenderLabel(genderQuestion?.label || 'Gender');

		setGenders(genderQuestion?.options?.sort((a,b) => a.order - b.order) || []);
		setGenderQuotas({ total, useQuota });
		
		setSelectedGenders(allGenders);
	}, [getQuestionByClass]);

	const ageSetup = useCallback(() => {
		const ageQuestion = getQuestionByClass('age');
		if (!ageQuestion) {
			setHasAgeQuestion(false);
			return;
		}
		const useQuota = !!ageQuestion?.options?.find(quota => quota.max != null);
		setHasAgeQuestion(true);
		setAgeLabel(ageQuestion?.label || 'Age');
		setAgeQuotas({ total: 0, useQuota: !!ageQuestion?.useQuota });

		if (!ageQuestion?.options?.length) return;

		let total = 0;
		let allAges = [];

		const ageOptions = ageQuestion?.options?.sort((a,b) => a.min - b.min).map((age, index) => ({
			...age,
			min: age?.min,
			max: age?.max,
			value: age?.label,
			label: age?.label,
			isSelected: !!age?.isSelected,
			order: index + 1,
		}));

		const currentSelectedAges = ageQuestion?.options?.filter(option => !!option?.isSelected);

		total = getQuotaTotal(currentSelectedAges);
		allAges = currentSelectedAges?.sort((a,b) => a.min - b.min)?.map((opt, index) => {
			let quota = {
				min: null,
				max: null,
				id: opt?.quota?.[0]?.id,
			};
			if (opt?.quota?.length && !Number.isNaN(parseInt(opt?.quota?.[0]?.quotaMax))) {
				quota = {
					min: Number(opt?.quota?.[0]?.quotaMin).toFixed(1),
					max: Number(opt?.quota?.[0]?.quotaMax).toFixed(1),
					quotaMin: opt?.quota?.[0]?.quotaMin,
					quotaMax: opt?.quota?.[0]?.quotaMax,
					id: opt?.quota?.[0]?.id,
				};
			}

			return {
				label: opt?.label,
				value: opt?.label,
				min: opt?.min,
				max: opt?.max,
				isSelected: opt?.isSelected,
				quota,
				order: index + 1,
			};
		});

		setAges(ageOptions);
		setAgeQuotas({ total, useQuota });
		setSelectedAges(allAges);
	}, [getQuestionByClass]);

	const regionSetup = useCallback(() => {
		const regionQuestions = getRegionQuestions();
		if (!regionQuestions) return;

		const quotasForRegion = [];

		regionQuestions.forEach(question => {
			let total = 0;

			const currentSelectedRegions = question?.options
				?.filter(option => !!option?.isSelected)
				?.map(option => ({
					label: option?.label,
					value: option?.id,
					questionLibraryId: question?.id,
					quota: {
						min: option?.quota?.length ? option?.quota?.[0]?.quotaMin?.toFixed(1) : null,
						max: option?.quota?.length ? option?.quota?.[0]?.quotaMax?.toFixed(1) : null,
						id: option?.id,
					},
				}));

			total = getQuotaTotal(currentSelectedRegions);

			const useQuota = !!question.options.find(({ quota }) => quota?.quotaMax != null);

			quotasForRegion.push({
				id: question.id,
				audienceQuestionId: question.audienceQuestionId,
				useQuota,
				total,
			});
		});

		setRegionQuotas(quotasForRegion);
		const regionQualifications = {};
		const allRegions = [];
		regionQuestions.forEach(question => {
			regionQualifications[question?.id] = question;
			const selectedOptions = question?.options?.filter(option => !!option?.isSelected) || [];
			const currentSelectedRegions = selectedOptions.map(option => {
				let quota = {
					min: null,
					max: null,
					id: option?.quota?.[0]?.id || null,
					optionId: option?.quota?.[0]?.optionId || null,
				};
				if (option?.quota?.length && !Number.isNaN(parseInt(option?.quota?.[0]?.quotaMax))) {
					quota = {
						...quota,
						min: Number(option?.quota?.[0]?.quotaMin).toFixed(1),
						max: Number(option?.quota?.[0]?.quotaMax).toFixed(1),
					};
				}
				return {
					label: option?.label,
					value: option?.id,
					questionLibraryId: question?.id,
					isSelected: option.isSelected,
					quota,
				};
			});

			allRegions.push(...currentSelectedRegions);
		});
		setQualifications(regionQualifications);
		setSelectedRegions(allRegions);
	}, [getRegionQuestions, setQualifications]);

	const otherQuestionsSetup = useCallback(() => {
		const innerOtherQuestions = geOtherQuestions();
		if (!innerOtherQuestions) return;

		const quotasForOtherQuestions = [];
		const otherQuestionsMap = {};
		innerOtherQuestions.forEach(question => {
			let total = 0;
			otherQuestionsMap[question?.id] = question;
			const currentSelectedOtherOptions = question?.options
				?.filter(option => !!option?.isSelected)
				?.map(option => ({
					label: option?.label,
					value: option?.id,
					questionLibraryId: question?.id,
					quota: {
						min: option?.quota?.length ? option?.quota?.[0]?.quotaMin?.toFixed(1) : null,
						max: option?.quota?.length ? option?.quota?.[0]?.quotaMax?.toFixed(1) : null,
						id: option?.id,
					},
				}));

			total = getQuotaTotal(currentSelectedOtherOptions);
			const useQuota = !!question.options.find(option => option?.quota?.quotaMax != null);

			quotasForOtherQuestions.push({
				id: question.id,
				audienceQuestionId: question.audienceQuestionId,
				useQuota,
				total,
			});
		});

		setOtherQuestionsQuotas(quotasForOtherQuestions);
		setOtherQuestions(otherQuestionsMap);

		const allOtherOptions = [];
		innerOtherQuestions.forEach(question => {
			const selectedOptions = question?.options?.filter(option => !!option?.isSelected) || [];
			const currentSelectedOtherOptions = selectedOptions.map(option => {
				let quota = {
					min: null,
					max: null,
					id: option?.quota?.[0]?.id || null,
					optionId: option?.quota?.[0]?.optionId || null,
				};
				if (option?.quota?.length && !Number.isNaN(parseInt(option?.quota?.[0]?.quotaMax))) {
					quota = {
						...quota,
						min: Number(option?.quota?.[0]?.quotaMin).toFixed(1),
						max: Number(option?.quota?.[0]?.quotaMax).toFixed(1),
					};
				}
				return {
					label: option?.label,
					value: option?.id,
					questionLibraryId: question?.id,
					quota,
				};
			});

			allOtherOptions.push(...currentSelectedOtherOptions);
		});
		setSelectedOtherQuestionOptions(allOtherOptions);
	}, [geOtherQuestions, setOtherQuestions]);

	// ======= Fetch & Patch demographic data ======

	const fetchDemographic = useCallback(
		(id, uuid) => {
			dispatch(actions.fetchGroup(id, uuid));
			setTimeout(() => setLoading(false), 350);
		},
		[dispatch],
	);

	const updateGroup = useCallback(async () => {
		try {
			if (selectedCountry && selectedLanguage && responses && ir && !isDeleting) {
				setLoading(true);
				const { uuid, studyId } = getDemographicUuid();
				await studySampleService.patch(studyId, uuid, {
					name: groupName,
					sampleSize: Number(responses),
					incidenceRate: ir,
					languageCode: selectedLanguage?.code,
					provider: 'LUCID',
					countryId: selectedCountry?.value,
					customQuestions: [],
					providerQuestions: [],
					templateAudienceId: null,
					templateAccessLevel: 'private',
					// demographicQuestions: [],
				});
				fetchDemographic(studyId, uuid);
				if (!templateGroupEditMode) {
					// adding new language into study if it doesn't included in it
					if (selectedLanguage && !study.languages.includes(selectedLanguage.code)) {
						const updatedStudy = isBlueprint
							? await blueprintService.getBlueprint(study?.id)
							: await studyService.getStudy(study?.id);
						dispatch(actions.setStudy(updatedStudy.data));
						dispatch(reportingActions.setStudy(updatedStudy.data));
						dispatch(actions.validateStudy(study?.id));
						dispatch(actions.setLanguage(selectedLanguage.code));
					}
					dispatch(actions.fetchAllAudienceCollection(studyId));
				}
				if (templateGroupEditMode) {
					const updatedGroup = await services.studySampleService.getAudience(studyId, uuid);
					const updatedTemplate = {
						...template,
						demographicGroups: template.demographicGroups.map(g =>
							g?.uuid === uuid ? updatedGroup?.data : g,
						),
					};
					setTemplate(updatedTemplate);
				}
				setLoading(false);
			}
		} catch (e) {
			console.error(e);
			toastr.error('There was a problem updating the group, please check the information and try again.');
		}
	}, [selectedCountry, selectedLanguage, responses, ir]);

	const patchDemographic = useCallback(
		async patchPayload => {
			try {
				let uuid = demographicUuid;
				let studyId = study?.id;

				if (!demographicUuid) {
					uuid = demographicRef?.uuid;
					studyId = demographicRef?.studyId;
				}
				await studySampleService.patch(studyId, uuid, patchPayload);

				fetchDemographic(studyId, uuid);
			} catch (error) {
				console.error(error);
				let uuid = demographicUuid;
				let studyId = study?.id;

				if (!demographicUuid) {
					uuid = demographicRef?.uuid;
					studyId = demographicRef?.studyId;
				}
				fetchDemographic(studyId, uuid);
				setLoading(false);
				if (error?.response?.data?.details?.includes('must be less than 101')) {
					toastr.error('Individual quotas cannot exceed 100%');
				} else {
					toastr.error('Fail updating group. Check your data and try again.');
				}
			}
		},
		[demographicUuid, study?.id, fetchDemographic, demographicRef?.uuid, demographicRef?.studyId],
	);

	const ageData = useMemo(() => {
		const useThisData = ages?.length
			? ages.map(opt => ({
					label: opt?.label,
					value: opt?.label,
					optionId: opt?.id,
					audienceQuestionId: opt?.audienceQuestionId,
					audienceQuestionOptionId: opt?.audienceQuestionOptionId,
					min: opt?.min,
					max: opt?.max,
					quota: opt?.quota?.length
						? {
								quotaMin: Number(opt?.quota[0]?.quotaMin).toFixed(1),
								quotaMax: Number(opt?.quota[0]?.quotaMax).toFixed(1),
								id: opt?.quota[0]?.id,
						  }
						: {},
			  }))
			: [];

		return useThisData;
	}, [ages]);

	const updateCountry = useCallback(
		(country, isChangeLanguage = false) => {
			if (!country?.value) return;
			if (isChangeLanguage) setSelectedLanguage(false);
			else cleanStates();
			setSelectedCountry(country);
			dispatch(actions.fetchCountryLanguages(country.value));
		},
		[dispatch],
	);

	const fetchAudiencePrice = useCallback(
		(studyId, currency, incidenceRate, countryLanguageId) => {
			services.studySampleService
				.getMarketplaceAudiencePrice(studyId, {
					incidenceRate,
					currency,
					countryLanguageId,
				})
				.then(({ data }) => setPrice(data?.priceInCents));
		},
		[setPrice],
	);

	// ======= Component Effects ======

	// fetch countries
	useLayoutEffect(() => {
		if (!countries?.length) dispatch(actions.fetchCountries());
	}, [countries, dispatch]);

	// fetch group
	useEffect(() => {
		if (demographicUuid && !demographicGroup) {
			cleanStates();
			setIr(50);
			setPrice(null);
			fetchDemographic(study.id, demographicUuid);
		} else if (!demographicUuid && demographicGroup && viewMode) {
			setSelectedCountry(null);
			fetchDemographic(demographicGroup?.studyId, demographicGroup?.uuid);
		}
		return () => {
			dispatch(actions.setGroup({ loading: false, error: null, content: null }));
		};
		// eslint-disable-next-line
	}, [demographicUuid, demographicGroup, viewMode, study]);

	// Start Group setup
	useEffect(() => {
		if (group) {
			cleanStates();
			if (group?.countryLanguage && group?.languageCode) {
				const { countryLanguage, languageCode } = group;
				updateCountry({ label: countryLanguage?.countryLabel, value: countryLanguage?.countryId });
				setSelectedLanguage({
					label: countryLanguage?.languageLabel,
					value: countryLanguage?.id,
					code: languageCode,
				});
			}
			setGroupName(group?.name);
			setResponses(group?.sampleSize || 100);
			setIr(group?.estimatedIncidenceRate || 50);

			if (group?.demographicQuestions?.length) {
				genderSetup();
				ageSetup();
				regionSetup();
				otherQuestionsSetup();
			}

			setLoading(false);
		}
	}, [group, ageSetup, genderSetup, regionSetup, otherQuestionsSetup, updateCountry]);

	// fetch audiencePrice and set price
	useEffect(() => {
		if (viewMode) return;
		if (ir && selectedLanguage?.value && study?.id)
			fetchAudiencePrice(study?.id, study?.currency, ir, selectedLanguage?.value);
		else setPrice(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ir, selectedLanguage, viewMode]);

	// patch group everyTime the group main data changes
	useEffect(() => {
		if (!selectedCountry || !selectedLanguage || !responses || !ir || !group?.uuid) return;
		if (
			selectedCountry?.value !== group?.countryLanguage?.countryId ||
			selectedLanguage?.code !== group?.languageCode ||
			ir !== group?.estimatedIncidenceRate
		) {
			updateGroup();
		}
		// eslint-disable-next-line
	}, [selectedCountry, selectedLanguage, ir]);

	useEffect(() => {
		if (demographicGroup) {
			setDemographicRef(demographicGroup);
			fetchDemographic(demographicGroup?.studyId, demographicGroup?.uuid);
		}
		// eslint-disable-next-line
	}, [demographicGroup]);

	useEffect(() => {
		if (groupData?.error) navigate(`/${studiesOrBlueprints}/${study?.uuid || study?.id}/audiences`);
		// eslint-disable-next-line
	}, [study, groupData]);

	// ======== Component Events =========
	const onBlurGroupName = async e => {
		if (viewMode) return;
		const audienceNameInputValue = e.target.value;

		try {
			const { uuid, studyId } = getDemographicUuid();
			await studySampleService.patch(studyId, uuid, {
				name: audienceNameInputValue,
			});
			setGroupName(audienceNameInputValue);
			dispatch(
				actions.setGroup({ ...groupData, content: { ...groupData.content, name: audienceNameInputValue } }),
			);
		} catch (error) {
			console.error(error);
		}

		e?.target?.scrollTo(0, 0);
	};

	const onDelete = () => {
		setLoading(true);
		setIsDeleting(true);
		setGenderQuotas({ total: 0, useQuota: false });
		setAgeQuotas({ total: 0, useQuota: false });
		setRegionQuotas([]);
		const { uuid, studyId } = getDemographicUuid();
		dispatch(actions.deleteAudience(studyId, uuid));
		setLoading(false);
		setIsDeleting(false);
		if (templateGroupEditMode) {
			setTemplate({
				...template,
				demographicGroups: template?.demographicGroups?.filter(gp => gp?.id !== group?.id),
			});
			setDeleteConfirmation(false);
			setCurrentTab('overview');
		} else {
			navigate(`/${studiesOrBlueprints}/${study?.uuid || study?.id}/audiences`);
		}
	};

	const onSaveGroupAsTemplate = useCallback(async () => {
		if (responses && ir && selectedLanguage?.code && selectedCountry?.value) {
			try {
				const studyId = templateGroupEditMode ? template?.studyId : study?.id;
				setLoading(true);
				await studySampleService.saveGroupAsTemplate(studyId, {
					sampleSize: responses,
					incidenceRate: ir,
					languageCode: selectedLanguage?.code,
					provider: 'LUCID',
					countryId: selectedCountry?.value,
					customQuestions: [],
					providerQuestions: [],
					demographicQuestions: group?.demographicQuestions || [],
					templateAccessLevel: 'space',
					name: groupName,
					templateAudienceId: group?.id,
				});
				dispatch(actions.fetchAudienceTemplates(false, true, true));
				setLoading(false);
				toastr.success('Template created with success.');
			} catch (error) {
				console.error(error);
				toastr.error('Fail creating template, try again.');
			}
		}
	}, [
		study,
		ir,
		responses,
		selectedCountry,
		selectedLanguage,
		group,
		dispatch,
		groupName,
		template,
		templateGroupEditMode,
		actions,
	]);

	const onCreateAgeRangeOption = async (item, selected) => {
		setCreatingAgeRange(true);
		const ageQuestion = getQuestionByClass('age');
		await patchDemographic({
			demographicQuestions: [
				{
					audienceQuestionId: ageQuestion?.audienceQuestionId,
					options: [
						{
							min: item?.min,
							max: item?.max,
							optionLabel: item?.label,
							isSelected: true,
						},
					],
				},
			],
		});
		const { studyId, uuid } = getDemographicUuid();

		const response = await services.studySampleService.getAudience(studyId, uuid);
		const originalQuestion = response?.data?.demographicQuestions?.find(question => question?.class === 'age');
		const useQuota = !!originalQuestion?.options?.find(option => option?.quota?.[0]?.quotaMax != null);
		if (useQuota) {
			const updatedOptions = originalQuestion.options?.map(option => {
				if (item?.label === option?.label) {
					return {
						audienceQuestionOptionId: option?.audienceQuestionOptionId,
						isSelected: true,
						quota: {},
					};
				}
				return {
					audienceQuestionOptionId: option?.audienceQuestionOptionId,
					isSelected: true,
					quota: {
						id: option?.quota[0]?.id,
						quotaMin: option?.quota[0]?.quotaMin,
						quotaMax: option?.quota[0]?.quotaMax,
					},
				};
			});

			patchDemographic({
				demographicQuestions: [
					{
						audienceQuestionId: originalQuestion?.audienceQuestionId,
						useQuota,
						options: updatedOptions,
					},
				],
			});
		}

		setCreatingAgeRange(false);
	};

	const onDeleteAgeRange = useCallback(
		async age => {
			const ageQuestion = getQuestionByClass('age');
			const ageOption = ageQuestion?.options?.find(
				option => option.audienceQuestionOptionId === age.audienceQuestionOptionId,
			);

			if (ageOption) {
				const { audienceQuestionId, audienceQuestionOptionId } = ageOption;
				try {
					await studySampleService.deleteAgeRangeDemographicQuestionOption(
						study?.id,
						audienceQuestionId,
						audienceQuestionOptionId,
					);
				} catch (error) {
					console.error(error);
				}
			}
		},
		[study, getQuestionByClass],
	);

	const onChangeSelectedValues = useCallback(
		({ selectedList, originalQuestion, useQuota, field = 'id' }) => {
			// age question uses the field 'audienceQuestionOptionId' to find the option
			// region and gender use the field 'id' to find the option
			const changedOptions = [];

			for (const option of originalQuestion?.options || []) {
				const changedOption = selectedList?.find(opt => opt?.value === option[field]);
				changedOptions.push({ ...option, isSelected: !!changedOption });
			}
			if (useQuota) {
				const unselected = changedOptions
					?.filter(option => !option?.isSelected)
					.map(option => ({
						audienceQuestionOptionId: option?.audienceQuestionOptionId,
						isSelected: option?.isSelected,
						quota: {
							id: originalQuestion?.options.find(opt => opt[field] === option[field])?.quota?.[0]?.id,
							quotaMin: null,
							quotaMax: null,
							optionId: option?.id,
						},
					}));

				const selected = selectedList.map(option => ({
					audienceQuestionOptionId: originalQuestion?.options?.find(opt => opt[field] === option.value)
						?.audienceQuestionOptionId,
					isSelected: true,
					quota: {
						id: originalQuestion.options.find(opt => opt[field] === option.value)?.quota?.[0]?.id,
						quotaMin: option?.quota?.quotaMin,
						quotaMax: option?.quota?.quotaMax,
					},
				}));

				const allOptions = [...unselected, ...selected];

				if (allOptions?.length) {
					// Patch them
					patchDemographic({
						demographicQuestions: [
							{
								useQuota,
								audienceQuestionId: originalQuestion?.audienceQuestionId,
								options: allOptions,
							},
						],
					});
				}
			} else if (changedOptions?.length) {
				// use previous approach for all non age demo questions to avoid regression
				if (!changedOptions?.find(option => option?.min || option?.max)) {
					patchDemographic({
						demographicQuestions: [
							{
								audienceQuestionId: originalQuestion?.audienceQuestionId,
								useQuota,
								options: changedOptions.map(option => ({
									audienceQuestionOptionId: option?.audienceQuestionOptionId,
									isSelected: option?.isSelected,
								})),
							},
						],
					});
				} else {
					const { uuid, studyId } = getDemographicUuid();
					fetchDemographic(studyId, uuid);
					dispatch(actions.fetchGroup(studyId, uuid));
				}
			}
		},
		// eslint-disable-next-line
		[patchDemographic],
	);

	const onEditAgeRangeValues = selectedList => {
		const originalQuestion = getQuestionByClass('age');
		if (!originalQuestion) return;

		const useQuota = !!selectedList?.find(({ quota }) => {
			const numberedQuota = Number(quota?.quotaMax) || 0;
			return !!numberedQuota;
		});

		if (selectedList.length) {
			const changedOptions = [];
			for (const option of originalQuestion?.options || []) {
				const optionWithUpdatedValues = selectedList.find(
					opt => opt?.audienceQuestionOptionId === option?.audienceQuestionOptionId,
				);

				changedOptions.push(
					option?.quota?.quotaMax
						? {
								audienceQuestionOptionId: option?.audienceQuestionOptionId,
								min: optionWithUpdatedValues?.min,
								max: optionWithUpdatedValues?.max,
								optionLabel: `${optionWithUpdatedValues?.min} - ${optionWithUpdatedValues?.max}`,
								quota: optionWithUpdatedValues?.quota,
						  }
						: {
								audienceQuestionOptionId: option?.audienceQuestionOptionId,
								min: optionWithUpdatedValues?.min,
								max: optionWithUpdatedValues?.max,
								optionLabel: `${optionWithUpdatedValues?.min} - ${optionWithUpdatedValues?.max}`,
						  },
				);
			}
			patchDemographic({
				demographicQuestions: [
					{
						audienceQuestionId: originalQuestion?.audienceQuestionId,
						useQuota,
						options: changedOptions,
					},
				],
			});
		}
	};

	const onChangeGenders = selectedList => {
		const originalQuestion = getQuestionByClass('gender');
		if (!originalQuestion) return;
		setSelectedGenders(selectedList);

		const useQuota = !!selectedList.find(({ quota }) => quota.max != null);

		onChangeSelectedValues({
			useQuota,
			selectedList,
			originalQuestion,
		});
	};

	const onChangeAges = (selectedList, updatedQuestion = false) => {
		const originalQuestion = updatedQuestion || getQuestionByClass('age');

		if (!originalQuestion) return;
		setSelectedAges(selectedList);

		const useQuota = !!selectedList.find(({ quota }) => quota?.max != null);

		if (creatingAgeRange) return; // prevent onChange because create new range already does the onChange
		onChangeSelectedValues({
			useQuota,
			selectedList,
			originalQuestion,
			field: 'label',
		});
	};

	const onChangeSelectedRegions = useCallback(
		async (selectedList, questionLibraryId) => {
			const originalQuestion = qualifications[questionLibraryId];
			if (!originalQuestion) return;
			const useQuota = !!selectedList.find(({ quota }) => quota.max != null);

			onChangeSelectedValues({
				useQuota,
				selectedList,
				originalQuestion,
				refetch: false,
			});
		},
		[onChangeSelectedValues, qualifications],
	);

	const onChangeResponses = async resp => {
		const { uuid, studyId } = getDemographicUuid();
		await studySampleService
			.patch(studyId, uuid, {
				sampleSize: Number(resp),
			})
			.then(data => {
				const newDemographicGroups = audienceCollection?.content?.demographicGroups?.map(g => {
					if (g?.uuid === group?.uuid) {
						return { ...g, sampleSize: resp };
					}
					return g;
				});
				if (audienceCollection?.content) {
					dispatch(
						actions.setAudienceCollection({
							loading: false,
							content: { ...audienceCollection.content, demographicGroups: newDemographicGroups },
						}),
					);
					dispatch(
						actions.setAudienceCollections({
							loading: false,
							content: [{ ...audienceCollection.content, demographicGroups: newDemographicGroups }],
						}),
					);
				}
			})
			.catch(err => console.error(err));
	};

	const onChangeSelectedOtherOption = useCallback(
		async (selectedList, questionLibraryId) => {
			const originalQuestion = otherQuestions[questionLibraryId];
			if (!originalQuestion) return;

			const useQuota = !!selectedList.find(({ quota }) => quota.max != null);

			onChangeSelectedValues({
				useQuota,
				selectedList,
				originalQuestion,
			});
		},
		[onChangeSelectedValues, otherQuestions],
	);

	// Manage Quotas - Input
	const onBlurQuotaInput = (selectedOptions, name, field) => {
		const total = selectedOptions.reduce((acc, current) => acc + Number(current?.quota?.max || 0), 0);
		const originalQuestion = getQuestionByClass(name);
		const options = [];
		selectedOptions.forEach(opt => {
			const currentOpt = originalQuestion?.options?.find(option => option[field] === opt?.value);
			if (currentOpt) {
				const currentQuota = selectedOptions.find(item => item?.value === currentOpt[field]);
				let quota = {
					quotaMin: null,
					quotaMax: null,
					id: currentOpt.quota?.[0]?.id,
					optionId: currentOpt?.id,
				};
				if (currentQuota?.quota?.max != null && !Number.isNaN(parseInt(currentQuota?.quota?.max))) {
					quota = {
						...quota,
						quotaMin: currentQuota?.quota?.max == null ? null : Number(currentQuota?.quota?.max),
						quotaMax: currentQuota?.quota?.max == null ? null : Number(currentQuota?.quota?.max),
					};
				}
				options.push({
					audienceQuestionOptionId: currentOpt?.audienceQuestionOptionId,
					isSelected: currentOpt?.isSelected,
					quota,
				});
			}
		});
		if (options.length && originalQuestion?.audienceQuestionId) {
			const useQuota = !!options.find(({ quota }) => quota?.quotaMax != null);
			const enableQuota = originalQuestion?.style !== 'multi-select';

			patchDemographic({
				demographicQuestions: [
					{
						audienceQuestionId: originalQuestion?.audienceQuestionId,
						useQuota: useQuota && enableQuota,
						...(useQuota && enableQuota ? { options } : {}),
					},
				],
			});
		}

		return total;
	};

	const onBlurRegionQuotaInput = useCallback(
		(selectedOptions, question) => {
			if (!selectedOptions?.length) return;
			const originalQuestion = group?.demographicQuestions?.find(
				q => q?.class?.toLowerCase() === 'region' && q?.audienceQuestionId === question?.audienceQuestionId,
			);
			const options = [];

			selectedOptions.forEach(opt => {
				const currentOpt = originalQuestion?.options?.find(option => option?.id === opt?.value);
				if (currentOpt) {
					const currentQuota = selectedOptions.find(item => item?.value === currentOpt?.id);
					const quotaMax = currentQuota?.quota?.max;
					options.push({
						audienceQuestionOptionId: currentOpt?.audienceQuestionOptionId,
						isSelected: currentOpt?.isSelected,
						quota: {
							id: currentOpt.quota?.[0].id,
							quotaMin: quotaMax == null ? null : Number(currentQuota?.quota?.min),
							quotaMax: quotaMax == null ? null : Number(currentQuota?.quota?.max),
							optionId: currentOpt?.id,
						},
					});
				}
			});

			if (!options.length || !originalQuestion?.audienceQuestionId) return;

			const useQuota = !!options.find(({ quota }) => quota?.quotaMax != null);
			const enableQuota = !!selectedOptions?.length;

			patchDemographic({
				demographicQuestions: [
					{
						audienceQuestionId: originalQuestion?.audienceQuestionId,
						useQuota: useQuota && enableQuota,
						...(useQuota && enableQuota ? { options } : {}),
					},
				],
			});
		},
		[group?.demographicQuestions, patchDemographic],
	);

	const setStudyForIRChange = useCallback(
		a => {
			// * Set client ID cookie
			Cookies.setStudyId(study.id);
			Cookies.setStudyUuid(study.uuid);
			Cookies.setClientId(study.clientId);
			// * Set new Ids
			const singleAudience = a && Object.keys(a).length > 0 && a.uuid ? a : null;
			setAudiencesAvailableForCheckout([singleAudience]);
			setAudiences([singleAudience]);
			setLaunchAudienceData(study.clientId, study.id, singleAudience.uuid);
			// * Navigate to checkout
			navigate(`../../audiences/checkout/${singleAudience.uuid}`);
		},
		[study, navigate],
	);

	const onBlurOtherQuotaInput = useCallback(
		(selectedOptions, question) => {
			if (!selectedOptions?.length) return;

			const originalQuestion = (geOtherQuestions() || [])?.find(
				q => q?.audienceQuestionId === question?.audienceQuestionId,
			);
			const options = [];

			selectedOptions.forEach(opt => {
				const currentOpt = originalQuestion?.options?.find(option => option?.id === opt?.value);
				if (currentOpt) {
					const currentQuota = selectedOptions.find(item => item?.value === currentOpt?.id);
					const quotaMax = currentQuota?.quota?.max;

					options.push({
						audienceQuestionOptionId: currentOpt?.audienceQuestionOptionId,
						isSelected: currentOpt?.isSelected,
						quota: currentQuota?.quota
							? {
									id: currentOpt.quota?.[0]?.id,
									quotaMin:
										quotaMax === '' || quotaMax === null
											? null
											: Number(currentQuota?.quota?.min || 0),
									quotaMax:
										quotaMax === '' || quotaMax === null
											? null
											: Number(currentQuota?.quota?.max || 0),
									optionId: currentOpt?.id,
							  }
							: {},
					});
				}
			});

			if (!options.length || !originalQuestion?.audienceQuestionId) return;
			const useQuota = !!options.find(({ quota }) => quota?.quotaMax != null);
			const enableQuota = !!selectedOptions?.length;

			patchDemographic({
				demographicQuestions: [
					{
						audienceQuestionId: originalQuestion?.audienceQuestionId,
						useQuota: useQuota && enableQuota,
						...(useQuota && enableQuota ? { options } : {}),
					},
				],
			});
		},
		[geOtherQuestions, patchDemographic],
	);

	const onChangeOptions = useCallback(
		(questionId, options) => {
			const question = group?.demographicQuestions.find(({ id }) => id === questionId);
			const useQuota = !!options.find(({ quota }) => quota?.quotaMax != null);

			patchDemographic({
				demographicQuestions: [
					{
						audienceQuestionId: question.audienceQuestionId,
						options,
						useQuota,
					},
				],
			});
		},
		[group?.demographicQuestions, patchDemographic],
	);

	const duplicateAudience = () => {
		const newGroupName =
			groupData.content?.name ||
			(groupData.content?.countryLanguage?.countryLabel && groupData.content?.countryLanguage?.languageLabel
				? `${groupData.content.countryLanguage.countryLabel} - ${groupData.content.countryLanguage.languageLabel}`
				: 'Untitled Group');
		const audienceToPost = {
			sampleSize: groupData.content?.sampleSize,
			incidenceRate: groupData.content?.estimatedIncidenceRate || defaultIncidentRate,
			languageCode: groupData.content?.languageCode,
			provider: groupData.content?.provider || 'LUCID',
			countryId: groupData.content?.countryLanguage?.countryId,
			customQuestions: [],
			name: `${newGroupName} (Copy)`,
			providerQuestions: [],
			audienceCollectionId: templateGroupEditMode ? template?.id : audienceCollection.content?.id,
			demographicQuestions: groupData.content?.demographicQuestions || [],
			redirectUrl: groupData.content?.redirectUrl,
			disqualifiedUrl: groupData.content?.disqualifiedUrl,
			urlParams: groupData.content?.urlParams,
			...(groupData.content?.vendor && { vendor: groupData.content?.vendor }),
			allowSingleDeploy: groupData.content?.allowSingleDeploy,
			templateAudienceId: groupData.content?.id,
		};
		if (templateGroupEditMode) {
			services.studySampleService.createAudience(template?.studyId, audienceToPost).then(data => {
				services.audienceService.getCollection(template?.studyId, template?.id).then(result => {
					setTemplate(result.data);
					setCurrentTab('overview');
					toastr.success('Audience successfully created');
				});
			});
		} else {
			dispatch(actions.createAudience(groupData.content.studyId, audienceToPost));
			navigate('../');
		}
	};

	const handleShowError = useCallback(() => {
		let showError = false;
		if (Object.keys(ageRangeErrors)?.length !== 0) {
			showError = true;
			ages?.forEach((range, index) => {
				if (!range.audienceQuestionOptionId && ageRangeErrors?.[index]?.text === 'Missing input') {
					showError = false;
				}
			});
		}
		return showError;
	}, [ages, ageRangeErrors]);

	const renderRegion = useCallback(
		region => {
			const selected = selectedRegions
				?.filter(opt => opt?.questionLibraryId === region?.id)
				.map(opt => {
					const regionOption = region?.options.find(re => re?.id === opt?.value);
					const quotas = regionOption?.quota;
					const quotaMax = quotas?.[0]?.quotaMax;
					return {
						...opt,
						order: regionOption?.order || 0,
						quota:
							quotaMax !== '' && quotaMax !== null
								? {
										...quotas?.[0],
										min: quotas?.[0].quotaMin?.toFixed(1),
										max: quotas?.[0].quotaMax?.toFixed(1),
								  }
								: {},
					};
				});

			if (!containsSelectedOptionsViewMode(selected)) return;
			const selectedQuota = regionQuotas.find(option => option?.id === region?.id);
			const quotas = selected
				.filter(option => option.isSelected)
				.map(regionOption => ({
					...regionOption,
					quota: [{ quotaMax: Number(regionOption?.quota?.max) }],
				}));
			selectedQuota.total = getQuotaTotal(quotas);

			const selectedOptions = region.options.filter(option => {
				const quota = selected?.find(opt => opt?.value === option?.id);
				return quota;
			});
			const dataOptions = region?.options?.sort(sortByOrder)?.map(opt => ({
				label: opt?.label,
				value: opt?.id,
				order: opt?.order,
				quota: {
					quotaMin: opt?.quotaMin,
					quotaMax: opt?.quotaMax,
					optionId: opt?.id,
				},
			}));

			return (
				<div className={el('region_province')} key={region?.id}>
					<DropDownGroup
						disabled
						canChangeOptions={canChangeOptions}
						hasInterlockedQuotas={region?.isInterlocked}
						viewMode={viewMode || group?.status === 'complete'}
						title={region?.label}
						hasQuotas
						enableQuota={region?.style !== 'multi-select' && !!selected?.length && canEdit}
						quotas={quotas}
						totalQuota={selectedQuota?.total || 0}
						questionId={selectedQuota?.id}
						options={selectedOptions}
						onChangeOptions={onChangeOptions}
						canEdit={canEdit}
					>
						<MultiDropDown
							anyDataLabel={`Any ${region.class}`}
							viewMode={viewMode}
							disabled={!region?.options || group?.status === 'complete'}
							enableQuota={region?.style !== 'multi-select' && !!selected?.length}
							canLowerQuota={canEdit}
							data={dataOptions}
							value={selected}
							searchable
							onChange={value => onChangeSelectedRegions(value, region?.id)}
							onBlurQuotaInput={selectedQuotas => onBlurRegionQuotaInput(selectedQuotas, region)}
							hasInterlockedQuotas={region?.isInterlocked}
							noItemsText={!selected.length ? 'Click on ‘+’ to add options' : null}
						/>
					</DropDownGroup>
				</div>
			);
		},
		[
			canChangeOptions,
			canEdit,
			containsSelectedOptionsViewMode,
			group?.status,
			onBlurRegionQuotaInput,
			onChangeOptions,
			onChangeSelectedRegions,
			regionQuotas,
			selectedRegions,
			viewMode,
		],
	);

	const renderRegions = useCallback(() => {
		const qualificationValues = Object.values(qualifications);
		if (!qualificationValues?.length) return;

		return (
			<section className={el('region_section')}>
				{qualificationValues.map(region => renderRegion(region))}
			</section>
		);
	}, [qualifications, renderRegion]);

	const renderHeaderError = useCallback(() => {
		const errorTitle = () => (
			<div className={el('error-title-container')}>
				<h4>Your audience may have issues</h4>
			</div>
		);
		const errorMessage = () => (
			<div className={el('error-message')}>
				<p>
					{`This group has been identified as Low Incidence and is currently `}
					<b>stopped</b>
					{`. The price has increased from `}
					<b>{`$${(group.priceInCents / 100).toFixed(2)} `}</b>
					to <b>{`$${(group.newPriceInCents / 100).toFixed(2)} `}</b>
					{`per response. You can accept this price to continue running your study, or reject it. `}
					<Link to="https://support.upsiide.com/en/articles/6289188-why-did-my-audience-stop" target="_blank">
						Learn more
					</Link>
				</p>
			</div>
		);

		const errorQuotaTitle = () => (
			<div className={el('error-title-container')}>
				<h4>Your audience may have issues</h4>
			</div>
		);

		const erroQuotaMessage = () => (
			<div className={el('error-message')}>
				<p>
					Your study is going slow. We recommend increasing quotas on small groups to allow more people to
					enter your study.{' '}
					<Link to="https://support.upsiide.com/en/articles/6289188-why-did-my-audience-stop" target="_blank">
						Learn more
					</Link>
				</p>
				<p className={el('error-message_soft')}>
					(Interlocked only) For Interlocked Quotas, this can be as simple as removing a dimension.{' '}
					<span> Resolve </span>
				</p>
				<p className={el('error-message_soft_note')}>
					Note that you can only increase quota values and you cannot undo these changes.
				</p>
			</div>
		);

		if (group?.status === 'system-stopped') {
			return (
				<AMPHeaderError
					icon="error"
					errorTitle={errorTitle()}
					errorMessage={errorMessage()}
					buttonText="Resolve"
					buttonLink=""
					buttonLogic={() => setStudyForIRChange(group)}
				/>
			);
		}

		if (group?.status === 'slow-quotas') {
			return <AMPHeaderError icon="error" errorTitle={errorQuotaTitle()} errorMessage={erroQuotaMessage()} />;
		}
		return null;
	}, [group, setStudyForIRChange]);

	const renderLiveStudyWarning = useCallback(() => {
		const warningTitle = () => (
			<div className={el('error-title-container')}>
				<h4>This group cannot be changed</h4>
			</div>
		);

		const warningMessage = () => (
			<div className={el('error-message')}>
				<p>This group has already been launched and can no longer be changed.</p>
			</div>
		);

		if (group?.status && group?.status === 'slow-quotas') {
			return null;
		}
		if (group?.status && group?.status !== 'ready') {
			return <AMPHeaderError icon="warning" errorTitle={warningTitle()} errorMessage={warningMessage()} />;
		}
	}, [group]);

	const renderOtherQuestion = useCallback(
		question => {
			const selected = selectedOtherQuestionOptions
				?.filter(opt => opt?.questionLibraryId === question?.id)
				.map(opt => {
					const originalOption = question?.options.find(qo => qo?.id === opt.value);
					let quota = {
						...originalOption?.quota?.[0],
						min: null,
						max: null,
					};

					if (
						originalOption?.quota?.length &&
						!Number.isNaN(parseInt(originalOption?.quota?.[0]?.quotaMax))
					) {
						quota = {
							...originalOption?.quota?.[0],
							min: Number(originalOption?.quota?.[0]?.quotaMin).toFixed(1),
							max: Number(originalOption?.quota?.[0]?.quotaMax).toFixed(1),
						};
					}

					return {
						...opt,
						quota,
						order: originalOption?.order || 0,
					};
				});

			if (!containsSelectedOptionsViewMode(selected)) {
				return;
			}
			const selectedQuota = otherQuestionsQuotas.find(option => option?.id === question?.id);
			const quotas = question?.options?.sort(sortByOrder)?.map(option => ({
				...option,
				value: option?.id,
				quota: [{ quotaMax: Number(option?.quota?.[0]?.quotaMax) }],
			}));
			selectedQuota.total = getQuotaTotal(quotas.filter(option => option.isSelected));
			return (
				<div className={`${el('region_province')} other`} key={question?.id}>
					<DropDownGroup
						viewMode={group?.status === 'complete' || viewMode}
						title={question?.label}
						hasQuotas
						hasInterlockedQuotas={question?.isInterlocked}
						enableQuota={
							question?.style !== 'multi-select' &&
							!!selected?.length &&
							group?.status !== 'live' &&
							group?.status !== 'complete'
						}
						totalQuota={selectedQuota?.total || 0}
						questionId={question?.id}
						options={question.options}
						onChangeOptions={onChangeOptions}
						disabled
						canChangeOptions={canChangeOptions}
						canEdit={canEdit}
					>
						<MultiDropDown
							canLowerQuota={canEdit}
							enableQuota={question?.style !== 'multi-select' && !!selected?.length}
							hasInterlockedQuotas={question?.isInterlocked}
							anyDataLabel="Any Option"
							viewMode={viewMode || group?.status === 'complete'}
							disabled={!question?.options || group?.status === 'complete'}
							data={quotas}
							onChange={value => onChangeSelectedOtherOption(value, question?.id)}
							value={selected}
							searchable
							onBlurQuotaInput={selectedQuotas => onBlurOtherQuotaInput(selectedQuotas, question)}
							noItemsText={!selected.length ? 'Click on ‘+’ to add options' : null}
						/>
					</DropDownGroup>
				</div>
			);
		},
		[
			canChangeOptions,
			canEdit,
			containsSelectedOptionsViewMode,
			group?.status,
			onBlurOtherQuotaInput,
			onChangeOptions,
			onChangeSelectedOtherOption,
			otherQuestionsQuotas,
			selectedOtherQuestionOptions,
			viewMode,
		],
	);

	const renderOtherQuestions = useCallback(() => {
		const otherQuestionsValues = Object.values(otherQuestions);
		if (!otherQuestionsValues?.length) return;
		const questionsRendered = otherQuestionsValues.map(q => renderOtherQuestion(q));
		return questionsRendered;
	}, [otherQuestions, renderOtherQuestion]);

	const renderOptions = () => (
		<>
			{hasDemographicQuotas || hasScreeningQuotas ? (
				<Link
					to={location?.pathname?.includes('/templates') ? '#' : './interlock-quotas'}
					className={el('audience-sub-menu-item')}
				>
					{templateGroupEditMode ? (
						<div
							aria-hidden
							onClick={() => {
								setCurrentTab('interlock-quotas');
								const { uuid } = getDemographicUuid();
								const newGroups = template.demographicGroups.map(g => {
									if (g?.uuid === uuid) {
										return {
											...g,
											demographicQuestions:
												group?.demographicQuestions || g?.demographicQuestions,
										};
									}
									return g;
								});
								setTemplate({
									...template,
									demographicGroups: newGroups,
								});
							}}
						>
							Interlock quotas
						</div>
					) : (
						'Interlock quotas'
					)}
				</Link>
			) : (
				<Tooltip
					className={`${el('audience-sub-menu-item')} interlock`}
					theme="dark-blured"
					position="top"
					html={
						<div className={el('interlock-quota-warning')}>
							<span>Enable quotas on demographic or screening questions to unlock this feature.</span>
						</div>
					}
				>
					<span>Interlocked quota</span>
				</Tooltip>
			)}
			<span
				onClick={onSaveGroupAsTemplate}
				aria-hidden
				className={`${el('audience-sub-menu-item')} ${el('sub-menu-save-group')}`}
			>
				Save Demographic Group
			</span>
			<span onClick={duplicateAudience} aria-hidden className={el('audience-sub-menu-item')}>
				Duplicate group
			</span>
			{group?.status !== 'live' ? (
				<span
					onClick={() => setDeleteConfirmation(true)}
					aria-hidden
					className={el('audience-sub-menu-item-delete')}
				>
					Delete group
				</span>
			) : null}
		</>
	);

	const renderDeleteAudienceModal = () => (
		<ResponsiveModal
			show={deleteConfirmation}
			customClass="delete-audience-modal"
			onClose={() => setDeleteConfirmation(false)}
			maxWidth={600}
			padding={0}
			customContent
		>
			<AreYouSureForm
				headerText="Delete group?"
				bodyText="This will permanently delete all response data and target options."
				confirmText="Delete"
				cancelText="Cancel"
				onConfirm={onDelete}
				onCancel={() => setDeleteConfirmation(false)}
				autoSpinner
			/>
		</ResponsiveModal>
	);

	const containsSelectedOptionsViewMode = useCallback(
		selectedOption => !(!canEdit && !selectedOption?.length),
		[canEdit],
	);

	const renderPlaceholder = () => (
		<>
			<section className={el('skeleton_container')}>
				<div className={el('skeleton_content')}>
					<DropDownGroup viewMode title="" hasQuotas={false}>
						<TextRow color="#E0E0E0" />
					</DropDownGroup>
				</div>
				<div className={el('skeleton_content')}>
					<DropDownGroup viewMode title="" hasQuotas={false}>
						<TextRow color="#E0E0E0" />
					</DropDownGroup>
				</div>
			</section>
			<section className={el('skeleton_container')}>
				<div className={el('skeleton_content')}>
					<DropDownGroup viewMode title="" hasQuotas={false}>
						<TextRow color="#E0E0E0" />
					</DropDownGroup>
				</div>
				<div className={el('skeleton_content')}>
					<DropDownGroup viewMode title="" hasQuotas={false}>
						<TextRow color="#E0E0E0" />
					</DropDownGroup>
				</div>
			</section>
		</>
	);

	const renderDemographicHeader = () => {
		if (!group) return <div className={el('loading_header')}> </div>;
		return (
			<AudienceComponentHeader
				mode="group"
				demographicGroup={group}
				elementName={group?.name || ''}
				onChangeElementName={onBlurGroupName}
				onChangeResponses={onChangeResponses}
				renderElipsisMenu={renderOptions}
				sampleSize={responses}
				viewMode={viewMode}
				templateGroupEditMode={templateGroupEditMode}
				defaultPrice={defaultPrice}
			/>
		);
	};
	return (
		<div className={className}>
			<Loadable loading={loading}>
				{renderDeleteAudienceModal()}
				{renderLiveStudyWarning()}
				<section className={el('container_group')}>
					<section
						className={el(`${templatePreviewMode ? 'header_preview' : 'header'}`)}
						style={{ minHeight: !demographicGroup ? '70px' : '0px' }}
					>
						{renderHeaderError()}
						{!demographicGroup ? renderDemographicHeader() : null}
					</section>
					<div className={el('content')}>
						{demographicGroup ? (
							<AudienceComponentHeader
								mode="group"
								demographicGroup={group}
								elementName={group?.name || ''}
								onChangeElementName={onBlurGroupName}
								onChangeResponses={onChangeResponses}
								renderElipsisMenu={renderOptions}
								sampleSize={responses}
								viewMode={viewMode}
								templateGroupEditMode={templateGroupEditMode}
								templatePreviewMode={templatePreviewMode}
								defaultPrice={defaultPrice}
							/>
						) : null}

						{hasInterlockedQuotas && !demographicGroup ? (
							<div
								className={el('interlock-quotas-section')}
								aria-hidden
								onClick={() => {
									if (templateGroupEditMode) setCurrentTab('interlock-quotas');
								}}
							>
								<Link to="./interlock-quotas">
									<img
										className={el('interlock-quotas-icon')}
										src={InterlockQuotasIcon}
										alt="Interlock Quotas"
									/>
									<span>Interlock Quotas</span>
								</Link>
							</div>
						) : null}

						{group === null && !loading ? renderPlaceholder() : null}
						<section className={selectedCountry?.value ? el('section') : el('empty_section')}>
							{group && (
								<div className={el('country_language')}>
									<DropDownGroup
										viewMode={group?.status === 'complete' || viewMode}
										title="Country"
										hasQuotas={false}
										canChangeOptions={canChangeOptions}
										active={!selectedCountry?.value}
									>
										<SingleDropDown
											data={countries.map(country => ({
												label: country?.label,
												value: country?.id,
											}))}
											value={selectedCountry}
											onChange={country => updateCountry(country, true)}
											placeholder="Select a country"
											searchable
											disabled={viewMode || !canEdit}
										/>
									</DropDownGroup>
								</div>
							)}
							{selectedCountry?.value ? (
								<div className={el('country_language')}>
									<DropDownGroup
										viewMode={viewMode}
										title="Language"
										hasQuotas={false}
										canChangeOptions={canChangeOptions}
										active={!selectedLanguage?.value}
									>
										<SingleDropDown
											data={
												languages?.length
													? languages.map(({ label, id, code }) => ({
															label,
															value: id,
															code,
													  }))
													: []
											}
											value={selectedLanguage}
											onChange={lang => {
												setSelectedLanguage(lang);
											}}
											placeholder="Select a language"
											searchable
											disabled={viewMode || !canEdit}
										/>
									</DropDownGroup>
								</div>
							) : null}
						</section>

						<div className={`${loading ? el('loading') : el('loader_container')}`}>
							<section className={el('section')}>
								{renderRegions()}

								{hasGenderQuestion && containsSelectedOptionsViewMode(selectedGenders) ? (
									<div className={el('gender_age')}>
										<DropDownGroup
											viewMode={group?.status === 'complete' || viewMode}
											title={genderLabel}
											hasQuotas
											hasInterlockedQuotas={getQuestionByClass('gender')?.isInterlocked}
											enableQuota={
												getQuestionByClass('gender')?.style !== 'multi-select' &&
												!!selectedGenders?.length &&
												canEdit
											}
											questionId={getQuestionByClass('gender')?.id}
											options={genders}
											onChangeOptions={onChangeOptions}
											quotas={genders}
											totalQuota={genderQuotas?.total || 0}
											canChangeOptions={canChangeOptions}
											canEdit={canEdit}
										>
											<MultiDropDown
												anyDataLabel="Any Gender"
												viewMode={group?.status === 'complete' || viewMode}
												canLowerQuota={canEdit}
												disabled={!genders?.length || group?.status === 'complete'}
												enableQuota={
													getQuestionByClass('gender')?.style !== 'multi-select' &&
													!!selectedGenders?.length
												}
												data={
													genders?.length
														? genders?.sort(sortByOrder)?.map(opt => ({
																label: opt?.label,
																value: opt?.id,
																order: opt?.order,
																quota: opt?.quotaMax
																	? {
																			quotaMin: Number(opt.quotaMin).toFixed(1),
																			quotaMax: Number(opt.quotaMax).toFixed(1),
																			optionId: opt?.id,
																	  }
																	: {},
														  }))
														: []
												}
												hasInterlockedQuotas={getQuestionByClass('gender')?.isInterlocked}
												onChange={onChangeGenders}
												value={selectedGenders}
												onBlurQuotaInput={selected => {
													if (!selected?.length) return;
													const total = onBlurQuotaInput(selected, 'gender', 'id');
													setGenderQuotas(prev => ({
														total,
														useQuota: prev.useQuota,
													}));
												}}
												noItemsText={
													!selectedGenders.length ? 'Click on ‘+’ to add options' : null
												}
											/>
										</DropDownGroup>
									</div>
								) : null}

								{hasAgeQuestion && containsSelectedOptionsViewMode(selectedAges) ? (
									<div className={`${el('gender_age')} ${handleShowError() ? el('age-error') : ''}`}>
										<DropDownGroup
											canChangeOptions={canChangeOptions}
											viewMode={group?.status === 'complete' || viewMode}
											title={ageLabel}
											hasQuotas
											hasInterlockedQuotas={getQuestionByClass('age')?.isInterlocked}
											enableQuota={
												getQuestionByClass('age')?.style !== 'multi-select' &&
												!!selectedAges?.length &&
												canEdit
											}
											totalQuota={ageQuotas?.total || 0}
											questionId={getQuestionByClass('age')?.id}
											options={ages}
											onChangeOptions={onChangeOptions}
											canEdit={canEdit}
											ageError={handleShowError()}
										>
											<MultiDropDown
												canLowerQuota={canEdit}
												anyDataLabel="Any Age group"
												viewMode={group?.status === 'complete' || viewMode}
												disabled={group?.status === 'complete'}
												ageRangeErrors={ageRangeErrors}
												setAgeRangeErrors={setAgeRangeErrors}
												getDemographicUuid={getDemographicUuid}
												data={ageData}
												enableQuota={
													getQuestionByClass('age')?.style !== 'multi-select' &&
													!!selectedAges?.length
												}
												patchDemographic={patchDemographic}
												onChange={onChangeAges}
												onEditAgeRangeValues={onEditAgeRangeValues}
												value={selectedAges}
												onBlurQuotaInput={selected => {
													if (!selected?.length) return;
													const total = onBlurQuotaInput(selected, 'age', 'label');
													setAgeQuotas(prev => ({
														total,
														useQuota: prev.useQuota,
													}));
												}}
												hasInterlockedQuotas={getQuestionByClass('age')?.isInterlocked}
												customDropdown="age"
												deleteOption={onDeleteAgeRange}
												onCreateOption={onCreateAgeRangeOption}
												setData={setAges}
												noItemsText={
													!selectedAges.length ? 'Click on ‘+’ to add options' : null
												}
											/>
										</DropDownGroup>
									</div>
								) : null}

								{renderOtherQuestions()}
							</section>
						</div>
					</div>
				</section>
			</Loadable>
		</div>
	);
};

Demographic.displayName = 'Demographic';
Demographic.propTypes = {
	viewMode: PropTypes.bool,
	demographicUuid: PropTypes.any,
	demographicGroup: PropTypes.any,
	templateGroupEditMode: PropTypes.bool,
	setCurrentTab: PropTypes.func,
	template: PropTypes.object,
	setTemplate: PropTypes.func,
	templatePreviewMode: PropTypes.bool,
	defaultPrice: PropTypes.number,
};

export default Demographic;
