const prefix = 'all-blueprints';

export const SET_CLIENTS = `${prefix}/SET_CLIENTS`;
export const setClients = clients => ({ type: SET_CLIENTS, payload: { clients } });

export const SET_SEARCH_QUERY = `${prefix}/SET_SEARCH_QUERY`;
export const setSearchQuery = (searchQuery, initialLoad = false) => ({
	type: SET_SEARCH_QUERY,
	payload: { searchQuery, initialLoad },
});

export const SET_BLUEPRINT = `${prefix}/SET_BLUEPRINT`;
export const setBlueprint = blueprint => ({ type: SET_BLUEPRINT, payload: { blueprint } });

export const SET_BLUEPRINTS = `${prefix}/SET_BLUEPRINTS`;
export const setBlueprints = blueprints => ({
	type: SET_BLUEPRINTS,
	payload: { blueprints },
});

export const SET_BLUEPRINT_FILTERS = `${prefix}/SET_BLUEPRINT_FILTERS`;
export const setBlueprintFilters = (blueprintFilters, initialLoad = false) => ({
	type: SET_BLUEPRINT_FILTERS,
	payload: { blueprintFilters, initialLoad },
});

export const SET_ERROR = `${prefix}/SET_ERROR`;
export const setError = error => ({ type: SET_ERROR, payload: { error } });

export const SET_CLIENTS_FROM_ACCOUNT = `${prefix}/SET_CLIENTS_FROM_ACCOUNT`;
export const setClientsFromAccount = clientsFromAccount => ({
	type: SET_CLIENTS_FROM_ACCOUNT,
	payload: { clientsFromAccount },
});

export const SET_CLIENT_ID = `${prefix}/SET_CLIENT_ID`;
export const setClientId = clientId => ({
	type: SET_CLIENT_ID,
	payload: { clientId },
});

export const SET_SECTION = `${prefix}/SET_SECTION`;
export const setSection = currentSection => ({ type: SET_SECTION, payload: { currentSection } });

export const SET_SECTIONS = `${prefix}/SET_SECTIONS`;
export const setSections = sections => ({ type: SET_SECTIONS, payload: { sections } });

export const SET_LANGUAGES = `${prefix}/SET_LANGUAGES`;
export const setLanguages = languages => ({ type: SET_LANGUAGES, payload: { languages } });

export const SET_GEOGRAPHIES = `${prefix}/SET_GEOGRAPHIES`;
export const setGeographies = geographies => ({ type: SET_GEOGRAPHIES, payload: { geographies } });

export const INC_LOADING = `${prefix}/INC_LOADING`;
export const incLoading = () => ({ type: INC_LOADING });

export const DEC_LOADING = `${prefix}/DEC_LOADING`;
export const decLoading = () => ({ type: DEC_LOADING });

export const PERFORM_INITIAL_FETCH = `${prefix}/PERFORM_INITIAL_FETCH`;
export const performInitialFetch = () => ({ type: PERFORM_INITIAL_FETCH });

export const FETCH_BLUEPRINTS = `${prefix}/FETCH_BLUEPRINTS`;
export const fetchBlueprints = args => ({
	type: FETCH_BLUEPRINTS,
	payload: {
		dropPagination: args ? args.dropPagination : false,
		initialFetch: args ? args.initialFetch : false,
	},
});

export const FETCH_CLIENTS = `${prefix}/FETCH_CLIENTS`;
export const fetchClients = () => ({ type: FETCH_CLIENTS });

export const FETCH_CLIENTS_FROM_ACCOUNT = `${prefix}/FETCH_CLIENTS_FROM_ACCOUNT`;
export const fetchClientsFromAccount = accountUuid => ({
	type: FETCH_CLIENTS_FROM_ACCOUNT,
	payload: { accountUuid },
});

export const FETCH_SECTIONS = `${prefix}/FETCH_SECTIONS`;
export const fetchSections = (blueprintId, refetch = false, loadFirst = false) => ({
	type: FETCH_SECTIONS,
	payload: { blueprintId, refetch, loadFirst },
});

export const FETCH_SECTION = `${prefix}/FETCH_SECTION`;
export const fetchSection = (sectionId, mode, refetch = false, blueprintId = 0) => ({
	type: FETCH_SECTION,
	payload: { sectionId, mode, refetch, blueprintId },
});

export const FETCH_LANGUAGES = `${prefix}/FETCH_LANGUAGES`;
export const fetchLanguages = () => ({ type: FETCH_LANGUAGES });

export const FETCH_GEOGRAPHIES = `${prefix}/FETCH_GEOGRAPHIES`;
export const fetchGeographies = () => ({ type: FETCH_GEOGRAPHIES });

export const CREATE_BLUEPRINT = `${prefix}/CREATE_BLUEPRINT`;
export const createBlueprint = blueprint => ({ type: CREATE_BLUEPRINT, payload: blueprint });

export const DELETE_BLUEPRINT = `${prefix}/DELETE_BLUEPRINT`;
export const deleteBlueprint = (blueprintId, clientId) => ({
	type: DELETE_BLUEPRINT,
	payload: { blueprintId, clientId },
});

export const UPDATE_BLUEPRINT = `${prefix}/UPDATE_BLUEPRINT`;
export const updateBlueprint = (blueprintId, data) => ({
	type: UPDATE_BLUEPRINT,
	payload: { blueprintId, data },
});

export const FETCH_ACCOUNTS = `${prefix}/FETCH_ACCOUNTS`;
export const fetchAccounts = () => ({
	type: FETCH_ACCOUNTS,
});

export const SET_ACCOUNTS = `${prefix}/SET_ACCOUNTS`;
export const setAccounts = accounts => ({
	type: SET_ACCOUNTS,
	payload: { accounts },
});

export const UPDATE_BLUEPRINT_ACCESS = `${prefix}/UPDATE_BLUEPRINT_ACCESS`;
export const updateBlueprintAccess = (studyId, accountIds, isDefaultBlueprint) => ({
	type: UPDATE_BLUEPRINT_ACCESS,
	payload: { studyId, accountIds, isDefaultBlueprint },
});
