import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Hint from 'src/components/elements/Hint';
import './styles.scss';

const className = 'labelled';
const el = name => cn(className, name);

const Labelled = ({
	label,
	customClass = '',
	children,
	hint,
	privateHintText,
	sub,
	error,
	conditionalError,
	button = null,
}) => (
	<div className={`${className} ${customClass}`}>
		<div className={el('top')}>
			<div className={customClass || el('label')}>{label}</div>
			{(hint || privateHintText) && (
				<div className={el('hint')}>
					{privateHintText && <Hint text={privateHintText} isPrivate />}
					{hint && <Hint text={hint} />}
				</div>
			)}
		</div>
		{children && <div className={el('bottom')}>{children}</div>}
		{sub && <div className={el('sub')}>{sub}</div>}
		<div className={el('bottom-row')}>
			<div className={`${el('error')} ${conditionalError ? el('conditional-error') : ''}`}>{error}</div>
			{button /* the button element requires the flex attribute */}
		</div>
	</div>
);

Labelled.propTypes = {
	label: PropTypes.any,
	customClass: PropTypes.any,
	children: PropTypes.any,
	hint: PropTypes.any,
	privateHintText: PropTypes.any,
	sub: PropTypes.any,
	error: PropTypes.any,
	conditionalError: PropTypes.bool,
	button: PropTypes.element,
};

export default Labelled;
