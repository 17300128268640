import { createSelector } from 'reselect';
import { differenceInDays } from 'date-fns';
import Auth from 'src/utilities/auth';
import { getAccountData } from '../../selectors';

export const getCommonError = state => getAccountData(state).commonError;
export const getAccount = state => getAccountData(state).account;
export const getAccountSubscription = state => getAccountData(state).subscription;
export const getInvoices = state => getAccountData(state).invoices;
export const getUpdateAccountModal = state => getAccountData(state).updateAccountModal;
export const getUpdateCreditCardModal = state => getAccountData(state).updateCreditCardModal;
export const getClients = state => getAccountData(state).clients; // deprecated ?
export const getRoles = state => {
	const { roles } = getAccountData(state);
	let { content } = roles;

	const isDigAdmin = Auth.userCan('account:create', true);
	if (content && !isDigAdmin) {
		content = content.filter(role => {
			if (role.code === 'TRIAL_USER') return false;
			return role;
		});
		roles.content = content;
	}
	return roles;
};
export const getUsers = state => getAccountData(state).users;
export const getDeleteUserConfirmationModal = state => getAccountData(state).deleteUserConfirmationModal;
export const setAccountModalState = state => getAccountData(state).accountModalState;
export const createClient = state => getAccountData(state).createClient;
export const deleteClient = state => getAccountData(state).deleteClient;
export const getManageAccountClients = state => getAccountData(state).manageAccountClients;
export const fetchAccountUsage = state => getAccountData(state).accountUsage;
export const createAccountModal = state => getAccountData(state).createAccountModal;
export const getAccounts = state => getAccountData(state).accounts;
export const getUserSorting = state => getAccountData(state).userSorting;
export const getSpaceSorting = state => getAccountData(state).spaceSorting;
export const getInvitationStatus = state => getAccountData(state).invitationStatus;

export const getChargifySubscription = state => getAccountData(state).chargifySubscriptions;
export const getChargifyInvoices = state => getAccountData(state).chargifyInvoices;
export const getChargifyUsage = state => getAccountData(state).chargifyUsage;
export const getAccountAudiences = state => getAccountData(state).accountAudiences;
export const getBillingPeriods = state => getAccountData(state).accountBillingPeriods;
export const getManageSubscriptionModal = state => getAccountData(state).manageSubscriptionModal;

export const accountIsRemittance = createSelector(
	getAccount,
	account => account?.content?.settings?.isRemittance || false,
);

export const accountIsChargify = createSelector(getAccount, account => account?.content?.settings?.isChargify || false);

export const licenseSubscriptionHandles = ['enterprise', 'professional'];

export const getLicenseSubscription = createSelector(getChargifySubscription, subscriptions => {
	if (subscriptions && subscriptions.length > 0) {
		const filteredLicenseSub = subscriptions.find(sub =>
			licenseSubscriptionHandles.includes(sub.subscriptionHandle),
		);
		return filteredLicenseSub;
	}

	return null;
});

export const isAccountLoading = createSelector(getAccount, account => {
	const loading = account?.loading;
	if (typeof loading === 'boolean') {
		return account?.loading;
	}

	return true;
});

export const getAccountSettings = createSelector(getAccount, account => {
	if (account?.content?.settings) {
		return account?.content?.settings;
	}

	return null;
});

export const getLicenseSubscriptionDaysUntilRenewal = createSelector(getLicenseSubscription, subscription => {
	if (subscription) {
		const days = differenceInDays(new Date(subscription?.renewalDate), new Date());

		return days;
	}

	return null;
});

export const getAccountDefaultCreditCard = createSelector(getAccountSettings, settings => {
	if (settings?.creditCards) {
		const defaultCard = settings?.creditCards?.find(c => c.isDefault === 1);

		return defaultCard;
	}

	return null;
});

export const getProfessionalLicenseTrialStatus = createSelector(getLicenseSubscription, license => {
	// trailing
	if (license?.state === 'trialing' && !license?.subscriptionHandle?.includes('enterprise')) {
		return true;
	}

	return false;
});

export const isAccountEnterprise = createSelector(getLicenseSubscription, license => {
	if (license?.subscriptionHandle?.includes('enterprise')) {
		return true;
	}

	return false;
});

export const getForYouCategories = createSelector(getAccount, account => {
	const accountName = account?.content?.accountName;

	const blacklistDigSolutionsAccounts = ['pernod ricard', 'pernod-ricard', 'coca cola', 'coca-cola'];
	const failsBlacklistCheck = blacklistDigSolutionsAccounts.some(name =>
		accountName?.toLowerCase().includes(name.toLowerCase()),
	);

	const categories = [];

	categories.push({ value: 'Recently Used', label: 'Recently Used' });
	categories.push({ value: 'All Templates', label: 'All Templates' });
	categories.push({ value: 'My Templates', label: 'My Templates' });

	if (!failsBlacklistCheck) {
		categories.push({
			value: 'Dig Solutions',
			label: 'Dig Solutions',
			asset: '/public/images/digSolutionsIcon.svg',
		});
	}

	if (account?.content?.categories?.length) {
		account?.content?.categories?.forEach(cat => categories.push(cat));
	}

	categories.push({ value: 'Starred', label: 'Starred' });

	return categories;
});
