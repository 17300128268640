import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const MaskedMulti = ({ selected }) =>
	selected === 'selected' ? (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M16 7H4C2.897 7 2 7.897 2 9V19C2 20.103 2.897 21 4 21H16C17.103 21 18 20.103 18 19V9C18 7.897 17.103 7 16 7Z"
				fill="#28B681"
			/>
			<path
				d="M22 5C22 3.897 21.103 3 20 3H7C5.897 3 5 3.897 5 5H18.001C19.102 5 19.997 5.895 20 6.994L20.002 15H20V17C21.103 17 22 16.103 22 15V8.007L22.001 8V6L22 5.99V5Z"
				fill="#28B681"
			/>
			<path
				d="M13.3439 15.1952C12.5902 15.1952 11.9639 15.718 11.7622 16.415H10.4352V12.1348V11.2199H9.54352H8.21656C8.02548 10.512 7.38854 10.0001 6.63482 10.0001C5.73248 9.98922 5 10.7516 5 11.6773C5 12.6031 5.73248 13.3655 6.64544 13.3655C7.39915 13.3655 8.02548 12.8427 8.22718 12.1457H9.55414V16.4368V17.3516H10.4459H11.7728C11.9639 18.0595 12.6008 18.5714 13.3546 18.5714C14.2569 18.5714 15 17.8199 15 16.8833C14.9788 15.9576 14.2463 15.1952 13.3439 15.1952Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M16 7H4C2.897 7 2 7.897 2 9V19C2 20.103 2.897 21 4 21H16C17.103 21 18 20.103 18 19V9C18 7.897 17.103 7 16 7Z"
				fill="#FFC72F"
			/>
			<path
				d="M22 5C22 3.897 21.103 3 20 3H7C5.897 3 5 3.897 5 5H18.001C19.102 5 19.997 5.895 20 6.994L20.002 15H20V17C21.103 17 22 16.103 22 15V8.007L22.001 8V6L22 5.99V5Z"
				fill="#FFC72F"
			/>
			<path
				d="M13.3439 15.1952C12.5902 15.1952 11.9639 15.718 11.7622 16.415H10.4352V12.1348V11.2199H9.54352H8.21656C8.02548 10.512 7.38854 10.0001 6.63482 10.0001C5.73248 9.98922 5 10.7516 5 11.6773C5 12.6031 5.73248 13.3655 6.64544 13.3655C7.39915 13.3655 8.02548 12.8427 8.22718 12.1457H9.55414V16.4368V17.3516H10.4459H11.7728C11.9639 18.0595 12.6008 18.5714 13.3546 18.5714C14.2569 18.5714 15 17.8199 15 16.8833C14.9788 15.9576 14.2463 15.1952 13.3439 15.1952Z"
				fill="white"
			/>
		</svg>
	);

MaskedMulti.propTypes = {
	selected: PropTypes.string,
};

export default MaskedMulti;
