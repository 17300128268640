import React from 'react';
import cn from 'src/utilities/bem-cn';
import CloseIcon from 'src/components/icons/X';
import ResponsiveModal from '../ResponsiveModal';
import Button from '../../elements/Button';

import './styles.scss';

const className = 'ie-warning';
const el = name => cn(className, name);

const IEWarning = ({ version }) => {
	const [open, setOpen] = React.useState(true);

	return (
		<ResponsiveModal show={open} padding={0} customContent>
			<div className={`${className} ${version <= 11 ? 'ie-old' : ''}`}>
				<div className={el('close')} onClick={() => setOpen(false)}>
					<CloseIcon />
				</div>
				<div className={el('header')}>
					<h5>Your browser is not supported</h5>
				</div>
				<div className={el('content')}>
					<span>To have the best experience,</span>
					<br />
					<span>download one of these supported browsers.</span>
				</div>
				<div className={el('buttons')}>
					<Button label="Download Chrome" onClick={() => window.open('https://www.google.ca/chrome/')} />
					<Button
						label="Download Firefox"
						onClick={() => window.open('https://www.mozilla.org/en-CA/firefox/new/')}
					/>
					<Button label="Download Edge" onClick={() => window.open('https://www.microsoft.com/en-us/edge')} />
				</div>
				<div className={el('footer')}>
					<span onClick={() => setOpen(false)}>not now</span>
				</div>
			</div>
		</ResponsiveModal>
	);
};

export default IEWarning;
