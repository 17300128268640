import React, { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import LogicSelect from 'src/components/shared/LogicCondition/LogicRow/LogicSelect';
import { useLogicConditionContext } from '../LogicConditionContext';

import '../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

const LogicDestinationSelect = ({ setParentRefZIndex }) => {
	const {
		doRenderGoTo,
		skipQuestion,
		setShowLoader,
		question,
		section,
		sections,
		saveData,
		logicItem,
		isProductLogic,
		saveLogic,
		isAudienceLogic,
		audience,
		operandSelectIndex,
		operatorSelectIndex,
		selectedItem,
		destinationOptions,
	} = useLogicConditionContext();

	let savedValue = logicItem?.actionItemId;

	// is it a section?
	if (!savedValue && logicItem?.actionSectionId) {
		savedValue = `section-${logicItem?.actionSectionId}`;
	}

	// is it disqualify?
	if (!logicItem.actionSectionId && !logicItem.actionItemId && logicItem.actionType === 'terminate') {
		savedValue = 'disqualify';
	}

	const { itemShortname } = skipQuestion;

	const thenText = 'then';
	const gotoText = 'GO TO';
	const skipText = 'SKIP';
	const thisQuestionText = `this question (${itemShortname}).`;
	let actionText = '';
	let suffixText = '';
	if (doRenderGoTo) {
		actionText = gotoText;
	} else {
		actionText = skipText;
		suffixText = thisQuestionText;
	}

	const destinationSelectOnChange = useCallback(
		destinationId => {
			setShowLoader(true);

			const destinationOption = destinationOptions?.find(option => option.value === destinationId);

			const { id: sectionItemId } = question || {};
			const { id: sectionId } = section || {};
			const actionSectionIdIsNull = destinationOption.data.actionSectionId === null;
			const actionItemIdIsNull = destinationOption.data.actionItemId === null;
			const userSelectedDisqualification = actionSectionIdIsNull && actionItemIdIsNull;

			const saveDataClone = {
				...saveData,
				logic: saveData?.logic?.map((item, index) => {
					if (item?.id === logicItem?.id) {
						return {
							...item,
							id: logicItem.id,
							sectionId,
							sectionItemId: isProductLogic ? null : sectionItemId,
							operator: operatorSelectIndex ? 'is_not' : 'is',
							operand: operandSelectIndex ? 'or' : 'and',

							triggerItemId: selectedItem?.data?.itemId,
							triggerOptions: logicItem.triggerOptions,

							actionType: userSelectedDisqualification ? 'terminate' : 'goto',
							actionSectionId: destinationOption.data.actionSectionId,
							actionItemId: destinationOption.data.actionItemId,

							...(section ? { triggerType: section.type } : {}),
							...(isAudienceLogic
								? {
										audienceUuid: audience?.uuid,
										itemType: 'question',
								  }
								: {
										triggerSectionId:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].id,
								  }),
						};
					}

					return item;
				}),
			};

			saveLogic(saveDataClone);
		},
		[
			audience?.uuid,
			destinationOptions,
			isAudienceLogic,
			isProductLogic,
			logicItem.id,
			logicItem.triggerOptions,
			operandSelectIndex,
			operatorSelectIndex,
			question,
			saveData,
			saveLogic,
			section,
			sections.content,
			selectedItem,
			setShowLoader,
		],
	);

	return (
		<Row className={el(`destination ${doRenderGoTo ? 'show-goto' : 'show-skip'}`)}>
			<Col xs={actionText === gotoText ? 3 : 6}>
				<p className={el('goto-skip')}>
					{thenText} <span>{actionText}</span> {suffixText}
				</p>
			</Col>
			{doRenderGoTo && (
				<LogicSelect
					columnWidth={actionText === gotoText ? 7 : 6}
					selectClassName="result"
					options={destinationOptions}
					onChange={destinationSelectOnChange}
					menuMinWidth={300}
					value={savedValue}
					placeholder="destination"
					setParentRefZIndex={setParentRefZIndex}
				/>
			)}
			{actionText === gotoText && <Col xs={2} />}
		</Row>
	);
};

LogicDestinationSelect.propTypes = {
	setParentRefZIndex: PropTypes.any,
};

export default LogicDestinationSelect;
