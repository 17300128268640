import React from 'react';

import './styles.scss';

const Checkmark = props => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<path
			fill="#5CC09F"
			d="M17.799 7.833c.256.257.256.673 0 .93L9.96 16.6l-.5.192-.476-.244-3.038-3.767c-.228-.282-.184-.696.099-.924.282-.228.696-.184.925.099l2.578 3.197 7.32-7.32c.256-.257.672-.257.93 0z"
		/>
	</svg>
);

export default Checkmark;
