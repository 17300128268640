import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/elements/Button';

import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'not-found';
const el = name => cn(className, name);

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<Container className={className}>
			<Row>
				<Col xs={12} lg={{ size: 10, offset: 1 }}>
					<Container className="raised">
						<Row>
							<Col xs={12} md={{ size: 8, offset: 2 }}>
								<div className={el('content')}>
									<h2>Woops...we can’t seem to find what you’re looking for.</h2>
									<h4>The page you’re after might not exist.</h4>
									<p>
										Please reach out to your account administrator or Upsiide Support for help, or
										head back to the Upsiide dashboard.
									</p>
								</div>
								<div className={el('footer')}>
									<Button label="Back to studies list" onClick={() => navigate('/studies')} />
								</div>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
		</Container>
	);
};

export default NotFound;
