import * as actions from '../actions';

const INITIAL_STATE = {
	createIcon: false,
	createErrorIcon: false,
	reportIcon: false,
	audienceIcon: false,
	audienceErrorIcon: false,
	wizardStudyIcon: false,
	wizardAudienceIcon: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_CREATE_ICON:
			return { ...state, createIcon: action.payload };
		case actions.SET_CREATE_ERROR_ICON:
			return { ...state, createErrorIcon: action.payload };
		case actions.SET_REPORT_ICON:
			return { ...state, reportIcon: action.payload };
		case actions.SET_AUDIENCE_ICON:
			return { ...state, audienceIcon: action.payload };
		case actions.SET_AUDIENCE_ERROR_ICON:
			return { ...state, audienceErrorIcon: action.payload };
		case actions.SET_WIZARD_STUDY_ICON:
			return { ...state, wizardStudyIcon: action.payload };
		case actions.SET_WIZARD_AUDIENCE_ICON:
			return { ...state, wizardAudienceIcon: action.payload };

		default:
			return state;
	}
};
