import { useEffect, useState } from 'react';
import CONSTANTS from 'src/config/constants';

const useIsMobile = (mobileWidth = CONSTANTS.screenSize.screenTabletMin) => {
	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	return width <= mobileWidth;
};

export default useIsMobile;
