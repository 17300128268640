import React, { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import { useLogicConditionContext } from '../LogicConditionContext';

import '../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

const LogicCloseButton = ({ deleteAudienceItemLogic, deleteItemLogic }) => {
	const {
		setShowLoader,
		question,
		section,
		saveData,
		logicItem,
		isAudienceLogic,
		audience,
		tempTemplate,
		study,
		onClose,
		setTempTemplate,
	} = useLogicConditionContext();

	const closeButtonOnClick = useCallback(() => {
		const saveDataClone = cloneDeep(saveData);
		const logicIndex = saveDataClone.logic.findIndex(item => item.id === logicItem.id);

		if (isAudienceLogic) {
			const studyId = study?.id || audience?.studyId;
			const { uuid: audienceUuid } = audience;
			const { id: itemId } = question;

			// If logic exists and the logic item at the index based on ID exists
			if (saveDataClone.logic.length > 0 && saveDataClone.logic[logicIndex]) {
				// Remove that object from the save data array
				saveDataClone.logic.splice(logicIndex, 1);
				// Update the local state cached logic

				setShowLoader(true);
				if (tempTemplate) {
					tempTemplate.estimatedIncidenceRate = null;

					const questionIndex = tempTemplate.customQuestions.findIndex(q => q.id === question.id);
					tempTemplate.customQuestions[questionIndex].logic.splice(logicIndex, 1);

					const logicId = logicItem?.id;
					setTempTemplate({ ...tempTemplate });
					if (logicId) deleteAudienceItemLogic(logicId);
				} else {
					const logicId = logicItem?.id;
					if (logicId) deleteItemLogic(studyId, audienceUuid, itemId, logicId);
				}
			}
		} else {
			const { id: studyId } = study;
			const { id: sectionId } = section || {};
			// Do this if there is saved logic data
			if (logicItem && logicItem.id && logicItem.id > 0) {
				const { id: itemId } = question;
				const clickedLogicId = logicItem.id;

				const indexBasedOnLogicId = logicIndex;
				// If logic exists and the logic item at the index based on ID exists
				if (saveDataClone.logic.length > 0 && saveDataClone.logic[indexBasedOnLogicId]) {
					// Remove that object from the save data array
					saveDataClone.logic.splice(logicIndex, 1);
					// Update the local state cached logic
					setShowLoader(true);
					deleteItemLogic(studyId, sectionId, itemId, clickedLogicId);
				}
			}
		}

		// Do this regardless of whether there is saved logic data
		// The user an create a logic item without patching anything,
		// in which case you will still want to be able to delete it
		onClose(logicItem.id || logicItem.temp_id);
	}, [
		audience,
		deleteAudienceItemLogic,
		deleteItemLogic,
		isAudienceLogic,
		logicItem,
		onClose,
		question,
		saveData,
		section,
		setShowLoader,
		setTempTemplate,
		study,
		tempTemplate,
	]);

	return <Iconof className={el('close')} size="large" icon="clear" onClick={closeButtonOnClick} />;
};

LogicCloseButton.propTypes = {
	// closeButtonOnClick: PropTypes.func,
	deleteAudienceItemLogic: PropTypes.func,
	deleteItemLogic: PropTypes.func,
};

export default LogicCloseButton;
