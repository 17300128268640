import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const className = 'styled-checkbox';

const StyledCheckbox = ({
	customClasses = '',
	label,
	checked,
	value,
	disabled,
	onChange,
	tristate = false,
	preventDefault = false,
	viewOnly = false,
}) => (
	<div className={`${className} ${customClasses} ${disabled ? 'disabled' : ''}`}>
		<label>
			<input
				onChange={e => {
					if (onChange) onChange(!value);
					if (preventDefault) {
						e.stopPropagation();
						e.preventDefault();
					}
				}}
				type="checkbox"
				value={value}
				disabled={disabled || viewOnly}
				checked={checked}
				className={`${tristate ? 'tristate' : ''}`}
			/>
			<span>{label}</span>
		</label>
	</div>
);

StyledCheckbox.propTypes = {
	customClasses: PropTypes.any,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	tristate: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	preventDefault: PropTypes.bool,
};

StyledCheckbox.defaultProps = {
	disabled: false,
	checked: false,
	tristate: false,
};

export default StyledCheckbox;
