import React, { useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { Iconof } from '@upsiide/ui-components';
import Labelled from 'src/components/inputs/Labelled';
import Input from 'src/components/inputs/Input';
import Divider from 'src/components/elements/Divider';
import * as services from 'src/services';
import { debounce } from 'lodash';
import Loader from 'src/components/shared/Loader';

import './styles.scss';

const className = 'asset-input-gallery-video';
const el = (name, mod) => cn(className, name, mod);

/**
 * Assets Service API
 */
const validateVideo = url => services.assetService.validateVideo(url);

const ERRORS = {
	ERROR_INVALID_VIDEO_FORMAT: {
		code: 'INVALID_VIDEO_FORMAT',
		message: "Can't find video. Please ensure your link is from YouTube or Vimeo with correct privacy settings.",
	},
	ERROR_INVALID_EMBED_URL: {
		code: 'INVALID_EMBED_URL',
		message: "Can't find video. Please ensure your link is from YouTube or Vimeo with correct privacy settings.",
	},
	ERROR_VIDEO_NOT_EMBEDDABLE: {
		code: 'VIDEO_NOT_EMBEDDABLE',
		message: "Can't find video. Please ensure your link is from YouTube or Vimeo with correct privacy settings.",
	},
	ERROR_MAXIMUM_DURATION_EXCEEDED: {
		code: 'MAXIMUM_DURATION_EXCEEDED',
		message: 'Video duration cannot exceed 3 minutes.',
	},
};

const getSanitizedUrl = url => {
	const sanitizedUrl = url.split('#t=');
	return sanitizedUrl[0];
};

const Video = ({ videoLink, setVideoLink, error, setError, isValid, setIsValid }) => {
	const [videoTitle, setVideoTitle] = useState('');
	const [videoDuration, setVideoDuration] = useState('');
	const [videoDescription, setVideoDescription] = useState('');
	const [videoPreviewUrl, setVideoPreviewUrl] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = useCallback(
		value => {
			setVideoLink(value);
		},
		[setVideoLink],
	);

	const clearInput = useCallback(() => {
		setVideoLink('');
	}, [setVideoLink]);

	// Backend validation
	const getVideoInfo = useCallback(
		async url => {
			const videoInfo = await validateVideo(url)
				.then(res => res)
				.catch(error => {
					const { message } = error.response.data;
					switch (message) {
						case ERRORS.ERROR_INVALID_VIDEO_FORMAT.code:
							setError(ERRORS.ERROR_INVALID_VIDEO_FORMAT.message);
							break;
						case ERRORS.ERROR_VIDEO_NOT_EMBEDDABLE.code:
							setError(ERRORS.ERROR_VIDEO_NOT_EMBEDDABLE.message);
							break;
						case ERRORS.ERROR_MAXIMUM_DURATION_EXCEEDED.code:
							setError(ERRORS.ERROR_MAXIMUM_DURATION_EXCEEDED.message);
							break;
						default:
							setError(ERRORS.ERROR_INVALID_EMBED_URL.message);
					}
					setIsLoading(false);
				});
			if (!videoInfo) {
				setVideoPreviewUrl(getSanitizedUrl(url));
				setIsValid(false);
				setIsLoading(false);
				return;
			}
			setIsValid(true);
			const { title, description, duration, embedUrl } = videoInfo;
			const canPlay = ReactPlayer.canPlay(getSanitizedUrl(embedUrl));
			if (canPlay) {
				setVideoTitle(title);
				setVideoDescription(description?.slice(0, 280));
				setVideoPreviewUrl(getSanitizedUrl(embedUrl));
				setVideoDuration(new Date(duration * 1000).toISOString().slice(15, 19));
				setError('');
			} else {
				setVideoTitle('');
				setVideoDuration('');
				setVideoDescription('');
				setError(ERRORS.ERROR_INVALID_EMBED_URL.message);
			}
			setIsLoading(false);
		},
		[setError, setIsValid],
	);

	const debouncedGetVideoInfo = useCallback(debounce(getVideoInfo, 200), [getVideoInfo]);

	useEffect(() => {
		if (videoLink.length === 0) {
			setIsValid(false);
			setVideoTitle('');
			setVideoDuration('');
			setVideoDescription('');
			setError('');
			setVideoPreviewUrl('');
		} else {
			setIsLoading(true);
			debouncedGetVideoInfo(videoLink);
		}
	}, [debouncedGetVideoInfo, setError, setIsValid, videoLink]);

	return (
		<div>
			<Divider customClasses={el('divider-top')} />
			<div className={el('container')}>
				<div className={el('header')}>Link a video from YouTube or Vimeo</div>
				<Labelled label="" error={error} customClass={el('input-label')}>
					<Input
						className={el('input-field')}
						value={videoLink}
						onChange={handleChange}
						placeholder="Paste your video link here"
						invalid={error}
						type="url"
					/>
					{videoLink.length ? (
						<Iconof
							onClick={clearInput}
							className={el('clear-icon')}
							icon="clear"
							size="large"
							color="black"
						/>
					) : null}
				</Labelled>

				<div className={el('helper-text')}>Video has a limit of 3 minutes</div>

				<div className={el('preview-container')}>
					{// eslint-disable-next-line no-nested-ternary
					isLoading ? (
						<div className={el('loader')}>
							<Loader centered />
						</div>
					) : isValid ? (
						<>
							<div>
								{/* The div above allows ReactPlayer to take its size */}
								<ReactPlayer width="312px" height="175px" controls url={videoPreviewUrl} />
							</div>
							<div className={el('video-info-container')}>
								{videoTitle && <div className={el('title')}>{videoTitle}</div>}
								{videoDescription && <div className={el('description')}>{videoDescription}</div>}
								{videoDuration && <div className={el('duration')}>{videoDuration}</div>}
							</div>
						</>
					) : (
						<>
							<img
								className={el('video-placeholder')}
								src="/public/images/gallery/video-player-placeholder.svg"
								alt="Video player placeholder"
							/>
							<div className={el('video-info-placeholder')}>Video preview will appear here</div>
						</>
					)}
				</div>
			</div>
			<Divider />
		</div>
	);
};

Video.propTypes = {
	videoLink: PropTypes.string,
	setVideoLink: PropTypes.func,
	error: PropTypes.string,
	setError: PropTypes.func,
	isValid: PropTypes.bool,
	setIsValid: PropTypes.func,
};

export default Video;
