import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const className = 'asset-gallery-unsplash-image-container';

const ScrollContainer = forwardRef(({ children }, ref) => (
	<div className={className} ref={ref}>
		{children}
	</div>
));

ScrollContainer.propTypes = {
	children: PropTypes.any,
};

ScrollContainer.displayName = 'ScrollContainer';

export default ScrollContainer;
