import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import toastr from 'toastr';

import CONSTANTS from 'src/config/constants';
import studyUtilities from 'src/utilities/study';
import * as services from 'src/services';
import * as actions from '../actions';
import * as selectors from '../selectors';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 3000,
};

// QUESTIONS

const addSectionQuestion = async (store, action) => {
	if (action.type === actions.ADD_SECTION_QUESTION) {
		const { sectionId, type, sortOrder, questionId } = action.payload;
		const state = store.getState();
		const studyId = selectors.getStudy(state).id;
		const studyLanguages = selectors.getStudyLanguages(state);
		const currentSection = selectors.getCurrentSection(state).content;
		const { questions } = currentSection;

		const question = {
			type: 'custom',
			style: type,
			label: '',
			filterLabel: '',
			status: 'public',
			sortOrder,
			isFilter: type !== 'open-ended' && currentSection.type !== 'monadic_split',
			randomizeOptions: type === 'emoji',
			translations: studyLanguages.map(lang => ({
				label: '',
				languageCode: lang.languageCode,
			})),
			sectionId,
		};

		await services.questionService
			.create(studyId, question)
			.then(res => {
				// reorder newly created question
				const newQuestionId = res.insertId;
				const destinationIndex = questions.findIndex(q => q.id === questionId) + 1;

				const order = questions.map(q => q.id);
				order.splice(destinationIndex, 0, newQuestionId);

				services.questionService.reorder(studyId, order).then(() => {
					store.dispatch(actions.fetchSections(studyId, true));
					store.dispatch(actions.fetchSection(sectionId, 'edit', true));
				});
			})
			.catch(error => {
				store.dispatch(actions.setSection({ status: 'error', error }));
			});
	}
};

const deleteSectionQuestion = async (store, action) => {
	if (action.type === actions.DELETE_SECTION_QUESTION) {
		const { questionId } = action.payload;
		const state = store.getState();
		const studyId = selectors.getStudy(state).id;
		const currentSection = selectors.getCurrentSection(state).content;
		const { questions } = currentSection;
		const question = (questions && questions.find(q => q.id === questionId)) || {};
		const precedingQuestions = (questions && questions.filter(q => q.sortOrder < question.sortOrder)) || [];
		const laterQuestions = (questions && questions.filter(q => q.sortOrder > question.sortOrder)) || [];
		const updatedLaterQuestions = laterQuestions.map(q => {
			const newQ = q;
			if (newQ.sortOrder) newQ.sortOrder -= 1;
			if (newQ.studyQuestionNumber) newQ.studyQuestionNumber -= 1;
			return newQ;
		});
		const newQuestions = precedingQuestions.concat(updatedLaterQuestions);
		const newSortedQuestionIds = newQuestions.map(section => section.id);
		store.dispatch(actions.setSectionQuestions(newQuestions));

		// Promise.all works because order of operations does not matter
		Promise.all([
			services.questionService.delete(studyId, questionId),
			services.questionService.reorder(studyId, newSortedQuestionIds),
		])
			.then(() => {
				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
				store.dispatch(actions.fetchSections(studyId, true));
			})
			.catch(error => {
				// doesn't determine which operation failed
				store.dispatch(actions.setSection({ status: 'error', error }));
			});
	}
};

const getNewQuestionsOrder = (questions, sourceIndex, destinationIndex) => {
	const newQuestions = [];
	if (sourceIndex === destinationIndex) {
		return questions;
	}

	questions.forEach((section, index) => {
		if (index === destinationIndex) {
			if (sourceIndex < destinationIndex) {
				newQuestions.push(section);
			}
			newQuestions.push(questions[sourceIndex]);
			if (sourceIndex > destinationIndex) {
				newQuestions.push(section);
			}
		} else if (index !== sourceIndex) {
			newQuestions.push(section);
		}
	});

	return newQuestions;
};

const reorderQuestions = (store, action) => {
	if (action.type === actions.REORDER_QUESTIONS) {
		const { sourceIndex, destinationIndex } = action.payload;
		const study = selectors.getStudy(store.getState());
		const sections = selectors.getSections(store.getState()).content;
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		let newSortedQuestions;

		// Get the new sorted order
		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			newSortedQuestions = getNewQuestionsOrder(currentAudience.customQuestions, sourceIndex, destinationIndex);
		} else {
			newSortedQuestions = getNewQuestionsOrder(currentSection.questions, sourceIndex, destinationIndex);
		}

		// Re-number the questions based on the full list
		let questionCount = 0;
		sections.find(previousSection => {
			if (currentSection && previousSection.id === currentSection.id) {
				// Stop at this section
				return true;
			}

			if (previousSection.type === 'questions') {
				questionCount += previousSection.questions.length;
			}
			return false;
		});

		newSortedQuestions.forEach(question => {
			questionCount += 1;
			question.studyQuestionNumber = questionCount;
		});

		const newSortedQuestionIds = newSortedQuestions.map(section => section.id);

		services.questionService
			.reorder(study.id, newSortedQuestionIds)
			.then(() => {
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					store.dispatch(actions.setAudienceQuestions(newSortedQuestions));
					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(({ data }) => {
						store.dispatch(
							actions.setAudience({
								content: data,
								loading: false,
							}),
						);
					});
				} else {
					store.dispatch(actions.setSectionQuestions(newSortedQuestions));
					store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
					store.dispatch(actions.fetchSections(study.id, true));
				}
			})
			.catch(error => {
				// TODO
				console.error("couldn't re-order sections", error);
			});
	}
};

const updateQuestionLabel = (store, action) => {
	if (action.type === actions.UPDATE_QUESTION_LABEL) {
		const { questionId, label, languageCode, refetch } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		const currentSection = selectors.getCurrentSection(state).content;
		const sections = selectors.getSections(state);

		// grab the question under current section
		if (currentSection) {
			const { questions } = currentSection;
			let patchedQuestion = questions.find(question => question.id === questionId);

			// If patched question doesn't exist, check other sections, as may be updating question labels in other sections if handling piping errors onDelete or onReorder of sections
			if (!patchedQuestion) {
				let allQuestions = [];
				sections.content.forEach(sect => {
					if (sect.questions && sect.questions.length > 0) {
						allQuestions = allQuestions.concat(sect.questions);
					}
				});
				patchedQuestion = allQuestions.find(question => question.id === questionId);
			}

			if (patchedQuestion) {
				// rename the question under current section
				patchedQuestion.label = label;

				// grab the question under sections (data used to populate questions under logic)
				const patchedSection = sections.content.find(section => section.id === currentSection.id);
				const { questions: sectionsQuestions } = patchedSection;
				const patchedSectionsQuestion = sectionsQuestions.find(question => question.id === questionId);
				const patchedQuestionLanguage = languageCode || study.currentLanguage;

				// rename the question under sections
				if (patchedSectionsQuestion) {
					patchedSectionsQuestion.label = label;
				}

				// add new translation item under current section's updated question
				const translations = [];
				patchedQuestion.translations = patchedQuestion.translations.map(translation => {
					if (translation.languageCode === patchedQuestionLanguage) {
						translation.label = label;
					}
					translations.push({
						languageCode: translation.languageCode,
						label: translation.label,
						assetId: translation.assetId,
					});
					return translation;
				});

				study.translations.map(studyTranslation => {
					const translationExists = translations.find(
						translation => translation.languageCode === studyTranslation.languageCode,
					);
					if (!translationExists) {
						translations.push({
							languageCode: studyTranslation.languageCode,
							label: studyTranslation.languageCode === patchedQuestionLanguage ? label : '',
						});
					}
				});

				services.questionService
					.patch(study.id, questionId, { label, translations })
					.then(result => {
						store.dispatch(actions.fetchSections(study.id, true));
						if (refetch && currentSection) {
							store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
						}
					})
					.catch(error => {
						toastr.error('There was a problem updating the question. Please refresh and try again.');
						console.error("couldn't patch question", error);
					});
			}
		}
	}
};

const patchQuestion = (store, action) => {
	if (action.type === actions.PATCH_QUESTION) {
		const { questionId, data, section } = action.payload;
		const study = selectors.getStudy(store.getState());
		let currentSection;
		if (section) {
			currentSection = section;
		} else {
			currentSection = selectors.getCurrentSection(store.getState()).content;
		}
		const currentAudience = selectors.getAudience(store.getState()).content;

		if (data.options) {
			const { options } = data;
			const nonPlaceholderArray = options.filter(opt => !opt.placeholder);
			const cleanedOptions = studyUtilities.cleanUpOptionsForPatchQuestion(nonPlaceholderArray);
			data.options = cleanedOptions;
		}
		if (data.attributes) {
			const { attributes } = data;
			const nonPlaceholderArray = attributes.filter(attr => !attr.placeholder);
			const cleanedAttributes = [
				...nonPlaceholderArray.map(attr => {
					delete attr.asset;
					delete attr.assetVariations;
					delete attr.isNoneOfTheAbove;
					delete attr.isOtherSpecify;
					attr.translations.forEach(translation => {
						delete translation.asset;
						delete translation.assetVariations;
					});
					const validAttribute = {};
					Object.keys(attr).map(key =>
						['questionId', 'deletedAt'].includes(key) ? null : (validAttribute[key] = attr[key]),
					);
					return validAttribute;
				}),
			];
			data.attributes = cleanedAttributes;
		}
		services.questionService
			.patch(study.id, questionId, data)
			.then(() => {
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(({ data: res }) => {
						store.dispatch(
							actions.setAudience({
								content: res,
								loading: false,
							}),
						);
					});
				} else {
					store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
				}

				store.dispatch(actions.fetchSections(study.id, true));
				store.dispatch(actions.fetchStudy(study.id));
			})
			.catch(error => {
				toastr.error('There was a problem updating the question. Please refresh and try again.');
			});
	}
};

const createQuestionSetting = (store, action) => {
	if (action.type === actions.CREATE_QUESTION_SETTING) {
		const { questionId, data } = action.payload;
		const study = selectors.getStudy(store.getState());
		services.questionService
			.createSetting(study.id, questionId, [data])
			.then(response => {
				const currentSection = selectors.getCurrentSection(store.getState()).content;
				const currentAudience = selectors.getAudience(store.getState()).content;
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(({ data: res }) => {
						store.dispatch(
							actions.setAudience({
								content: res,
								loading: false,
							}),
						);
					});
				} else {
					store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
				}
			})
			.catch(error => {
				toastr.error('There was a problem creating the question setting. Please refresh and try again.');
				console.error(error);
			});
	}
};

const patchQuestionSetting = (store, action) => {
	if (action.type === actions.PATCH_QUESTION_SETTING) {
		const { studyId, questionId, settingId, data } = action.payload;
		services.questionService
			.patchSetting(studyId, questionId, settingId, data)
			.then(response => {
				const currentSection = selectors.getCurrentSection(store.getState()).content;
				const currentAudience = selectors.getAudience(store.getState()).content;

				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					services.studySampleService.getAudience(studyId, currentAudience.uuid).then(({ data: res }) => {
						store.dispatch(
							actions.setAudience({
								content: res,
								loading: false,
							}),
						);
					});
				} else if (currentSection?.id) {
					store.dispatch(actions.fetchSection(currentSection?.id, 'edit', true));
				}
			})
			.catch(error => {
				toastr.error('There was a problem updating the question setting. Please refresh and try again.');
				console.error(error);
			});
	}
};

// OPTIONS

const addOptionPlaceholder = (store, action) => {
	if (action.type === actions.ADD_OPTION_PLACEHOLDER) {
		const { questionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const { questions } = currentSection;
		const patchedQuestion = questions.find(question => question.id === questionId);

		patchedQuestion.options.push({
			value: '',
			placeholder: true,
			id: `placeholder-${patchedQuestion.options.length}`,
			order:
				last(patchedQuestion.options) &&
				!last(patchedQuestion.options).isNoneOfTheAbove &&
				!last(patchedQuestion.options).isOtherSpecify
					? last(orderBy(patchedQuestion.options, 'order')).order + 1
					: patchedQuestion.options.length,
			[CONSTANTS.questions.options.isOtherSpecify]: false,
			[CONSTANTS.questions.options.isNoneOfTheAbove]: false,
			[CONSTANTS.questions.options.lockOrder]: false,
			translations: [
				{
					languageCode: study.language || study.currentLanguage,
					label: '',
				},
			],
		});

		store.dispatch(actions.setSectionQuestions(questions));
	}
};

const removeOptionPlaceholder = (store, action) => {
	if (action.type === actions.REMOVE_OPTION_PLACEHOLDER) {
		const { questionId, answerId } = action.payload;
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		const patchedQuestion = questions.find(question => question.id === questionId);

		patchedQuestion.options = patchedQuestion.options.filter(option => option.id !== answerId);

		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			store.dispatch(actions.setAudienceQuestions(questions));
		} else {
			store.dispatch(actions.setSectionQuestions(questions));
		}
	}
};

const addQuestionOption = (store, action) => {
	if (action.type === actions.ADD_QUESTION_OPTION) {
		const { questionId, option } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		services.questionService
			.createOption(study.id, questionId, option)
			.then(results => {
				// After creating the new option, we need to look and see if there were any placeholders options that need to be restored
				const currentQuestion = questions.find(q => q.id === questionId);

				// Last option is placeholder
				const lastOption = last(currentQuestion.options);

				// Assign the latest answers with current Ids
				let updatedAnswers = results.data.options;

				// Check current options against latest options to see if a placeholder is needed
				if (currentQuestion.options.length > updatedAnswers.length) {
					// Add placeholder back into options
					lastOption.order = last(updatedAnswers).order + 1;
					updatedAnswers = updatedAnswers.concat(lastOption);
				}

				// Update current question and set store state
				currentQuestion.options = updatedAnswers;

				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					store.dispatch(actions.setAudienceQuestions(questions));
				} else {
					store.dispatch(actions.setSectionQuestions(questions));
				}
			})
			.catch(error => {
				toastr.error('There was a problem creating the option. Please refresh and try again.');
			});
	}
};

const removeQuestionOption = (store, action) => {
	if (action.type === actions.REMOVE_QUESTION_OPTION) {
		const { questionId, optionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		services.questionService
			.deleteOption(study.id, questionId, optionId)
			.then(() => {
				let currentQuestion = questions && questions.find(q => q.id === questionId);

				let deletingFilter = false;
				// Deleting a filter instead of a question section
				if (currentQuestion === undefined) {
					currentQuestion = study.questionFilters && study.questionFilters.find(f => f.id === questionId);
					deletingFilter = currentQuestion && true;
				}
				// Assign the latest answers with current Ids
				const updatedAnswers = currentQuestion.options.filter(option => option.id !== optionId);

				// Update current question and set store state
				currentQuestion.options = updatedAnswers;
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					store.dispatch(actions.setAudienceQuestions(questions));

					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(({ data }) => {
						store.dispatch(
							actions.setAudience({
								content: data,
								loading: false,
							}),
						);
					});
				} else if (deletingFilter && updatedAnswers) {
					store.dispatch(actions.setStudy({ ...study }));
					store.dispatch(actions.fetchQuestions(study.id));
				} else {
					store.dispatch(actions.setSectionQuestions(questions));
				}
			})
			.catch(error => {
				toastr.error('There was a problem deleting the option. Please refresh and try again.');
			});
	}
};

const updateOptionLabel = (store, action) => {
	if (action.type === actions.UPDATE_OPTION_LABEL) {
		const { questionId, translationId, optionId, languageCode, filterImports } = action.payload;
		let { label } = action.payload;
		if (label && typeof label !== 'string') label = JSON.stringify(label);
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questionState = selectors.getQuestions(store.getState()).content;
		let questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		if (filterImports && !questions) {
			questions = questionState;
		}

		const study = selectors.getStudy(store.getState());
		// let updatingFilter = false;
		let optionData = { value: label };

		const question = questions.find(q => q.id === questionId);
		const questionOption = question.options.find(option => option.id === optionId);
		const optionTranslation = questionOption.translations.find(translation => translation.id === translationId);

		if (optionTranslation) {
			if (optionTranslation.asset) delete optionTranslation.asset;
			if (optionTranslation.assetVariations) delete optionTranslation.assetVariations;
			optionTranslation.label = label;
			optionData.translations = questionOption.translations;
		} else {
			// Create New Translation with label and languagae code and add to current translation object
			optionData = questionOption;
			delete optionData.id;
			optionData.translations.push({
				languageCode,
				label,
			});
		}
		if (optionData.label || optionData.label === '') delete optionData.label;
		if (optionData.asset || optionData.asset === '') delete optionData.asset;
		if (optionData.assetVariations) delete optionData.assetVariations;
		if (optionData.translations) {
			optionData.translations.forEach(translation => {
				delete translation.asset;
				delete translation.assetVariations;
			});
		}
		services.questionService
			.patchOption(study.id, questionId, optionId, optionData)
			.then(() => {
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(res => {
						const currentQuestion = questions.find(q => q.id === questionId);

						// Last option is placeholder
						const lastOption = last(currentQuestion.options);

						// Assign the latest answers with current Ids
						let updatedAnswers = res.data.customQuestions.find(q => q.id === questionId).options;

						// Check current options against latest options to see if a placeholder is needed
						if (currentQuestion.options.length > updatedAnswers.length) {
							// Add placeholder back into options
							updatedAnswers = updatedAnswers.concat(lastOption);
						}

						// Update current question and set store state
						currentQuestion.options = updatedAnswers;
						store.dispatch(actions.setAudienceQuestions(questions));

						store.dispatch(
							actions.setAudience({
								content: res.data,
								loading: false,
							}),
						);
					});
				} else if (!filterImports) {
					// After editing the option, we need to look and see if there were any placeholders options that need to be restored
					services.sections.getPublic(study.id, currentSection.id, 'edit').then(res => {
						const currentQuestion = questions.find(q => q.id === questionId);

						// Last option is placeholder
						const lastOption = last(currentQuestion.options);

						// Assign the latest answers with current Ids
						let updatedAnswers =
							res.data.questions &&
							res.data.questions.find(q => q.id === questionId) &&
							res.data.questions.find(q => q.id === questionId).options;

						// Check current options against latest options to see if a placeholder is needed
						if (currentQuestion.options.length > updatedAnswers.length) {
							// Add placeholder back into options
							updatedAnswers = updatedAnswers.concat(lastOption);
						}

						// Update current question and set store state
						currentQuestion.options = updatedAnswers;
						store.dispatch(actions.setSectionQuestions(questions));
					});
				} else {
					store.dispatch(actions.fetchQuestions(study.id));
				}
			})
			.catch(error => {
				toastr.error('There was a problem updating the option. Please refresh and try again.');
			});
	}
};

const reorderOptions = (store, action) => {
	if (action.type === actions.REORDER_OPTIONS) {
		const moveInArray = function (arr, from, to) {
			// Delete the item from it's current position
			const item = arr.splice(from, 1);
			// Move the item to its new position
			arr.splice(to, 0, item[0]);
		};

		const { questionId, optionId, sourceIndex, destinationIndex } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;
		const patchedQuestion = questions.find(question => question.id === Number(questionId));
		const options = patchedQuestion && patchedQuestion.options;

		// uncomment the 2nd check to disable others/none to stay on the bottom
		const optionsWithoutPlaceholders = options.filter(opt => !opt.placeholder /* && opt.order < 99 */);

		// Order options based on order attribute
		optionsWithoutPlaceholders.sort((a, b) => (a.order > b.order ? 1 : -1));

		// Move option to new position in array
		moveInArray(optionsWithoutPlaceholders, sourceIndex, destinationIndex);

		// Set new order attributes based on new array
		optionsWithoutPlaceholders.forEach((option, key) => {
			option.order = key;
			delete option.assetVariations;
			if (option.translations) {
				option.translations.forEach(translation => {
					delete translation.assetVariations;
				});
			}
			if (option.maskedOptions) delete option.maskedOptions;
		});

		// Do not send in isDisplayedInReporting or asset property for question options
		questions.forEach(question => {
			if (question.options) {
				question.options.forEach(option => {
					delete option.isDisplayedInReporting;
					delete option.asset;
					if (option.translations) {
						option.translations.forEach(translation => {
							delete translation.asset;
							delete translation.assetVariations;
						});
					}
				});
			}
		});

		// Set questions with new options
		const newQuestions = [
			...questions.map(q => (q.id !== patchedQuestion.id ? q : { ...patchedQuestion, options })),
		];

		services.questionService
			.patch(study.id, questionId, {
				options: optionsWithoutPlaceholders,
			})
			.then(() => {
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(res => {
						store.dispatch(actions.setAudienceQuestions(newQuestions));
						store.dispatch(
							actions.setAudience({
								content: res.data,
								loading: false,
							}),
						);
					});
				} else {
					store.dispatch(actions.setSectionQuestions(newQuestions));
					if (currentSection) {
						store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
					}
				}
			})
			.catch(error => {
				toastr.error('There was a problem re-ordering the options. Please refresh and try again.');
			});
	}
};

const patchQuestionOption = (store, action) => {
	if (action.type === actions.PATCH_QUESTION_OPTION) {
		const { studyId, questionId, optionId, data } = action.payload;
		const state = store.getState();
		const currentSection = state.study.currentSection.content || selectors.getCurrentSection(state).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		let questions;
		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			questions = currentAudience.customQuestions;
		} else {
			questions = currentSection.questions;
		}
		if (data.translations && data.translations.length > 0) {
			data.translations.forEach(translation => delete translation.assetVariations);
		}
		services.questionService.patchOption(studyId, questionId, optionId, data).then(response => {
			if (currentAudience && Object.keys(currentAudience).length !== 0) {
				services.studySampleService.getAudience(studyId, currentAudience.uuid).then(res => {
					const currentQuestion = questions.find(q => q.id === questionId);
					const updatedAnswers = res.data.customQuestions.find(q => q.id === questionId).options;
					currentQuestion.options = updatedAnswers;
					store.dispatch(actions.setAudienceQuestions(questions));
					store.dispatch(
						actions.setAudience({
							content: res.data,
							loading: false,
						}),
					);
				});
			} else {
				const { id: sectionId } = currentSection;
				store.dispatch(actions.fetchSection(sectionId, 'edit', true));
			}
		});
	}
};

// CUSTOM QUALIFIERS

const addAudienceQuestion = async (store, action) => {
	if (action.type === actions.ADD_AUDIENCE_QUESTION) {
		const { audienceUuid, type, sortOrder, questionId } = action.payload;
		const state = store.getState();
		const studyId = selectors.getStudy(state).id;
		const studyLanguages = selectors.getStudyLanguages(state);
		const currentAudience = selectors.getAudience(state).content;
		const questions = currentAudience.customQuestions;

		const question = {
			type: 'custom',
			style: type,
			label: '',
			filterLabel: '',
			status: 'public',
			sortOrder,
			isFilter: type !== 'open-ended',
			randomizeOptions: type === 'emoji',
			translations: studyLanguages.map(lang => ({
				label: '',
				languageCode: lang.languageCode,
			})),
			audienceUuid,
		};

		await services.questionService
			.create(studyId, question)
			.then(res => {
				// reorder newly created question
				const newQuestionId = res.insertId;
				const destinationIndex = questions.findIndex(q => q.id === questionId) + 1;

				const order = questions.map(q => q.id);
				order.splice(destinationIndex, 0, newQuestionId);

				services.questionService.reorder(studyId, order).then(() => {
					store.dispatch(actions.fetchAudiences(studyId));
					services.studySampleService.getAudience(studyId, audienceUuid).then(({ data }) => {
						store.dispatch(
							actions.setAudience({
								content: data,
								loading: false,
							}),
						);
					});
				});
			})
			.catch(error => {
				store.dispatch(actions.setAudience({ status: 'error', error }));
			});
	}
};

const deleteCustomQualifier = async (store, action) => {
	if (action.type === actions.DELETE_CUSTOM_QUALIFIER) {
		const state = store.getState();
		const studyId = selectors.getStudy(state).id;
		const { questionId } = action.payload;
		const currentAudience = action.payload.audience;
		const questions = currentAudience.customQuestions;

		const remainingQuestions = questions
			.filter(q => q.id !== questionId)
			.map((question, index) => {
				question.sortOrder = index;
				question.studyQuestionNumber = index + 1;
				return question;
			});

		store.dispatch(actions.setAudienceQuestions(remainingQuestions));

		await services.questionService
			.delete(studyId, questionId)
			.then(() => {
				services.studySampleService.getAudience(studyId, currentAudience.uuid).then(({ data }) => {
					store.dispatch(
						actions.setAudience({
							content: data,
							loading: false,
						}),
					);
				});
			})
			.catch(error => {
				store.dispatch(actions.setAudience({ status: 'error', error }));
			});
	}
};

const updateCustomQualifierLabel = (store, action) => {
	if (action.type === actions.UPDATE_CUSTOM_QUALIFIER_LABEL) {
		const { questionId, label, languageCode, audience, refetch } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		const questions = audience.customQuestions;
		const patchedQuestion = questions.find(question => question.id === questionId);
		patchedQuestion.label = label;

		// add new translation item under current section's updated question
		const translations = [];
		patchedQuestion.translations = patchedQuestion.translations.map(translation => {
			if (translation.languageCode === languageCode) {
				translation.label = label;
			}
			translations.push({
				languageCode: translation.languageCode,
				label: translation.label,
				assetId: translation.assetId,
			});
			return translation;
		});

		services.questionService
			.patch(study.id, questionId, { label, translations })
			.then(result => {
				store.dispatch(actions.fetchAudiences(study.id));
			})
			.catch(error => {
				toastr.error('There was a problem updating the question. Please refresh and try again.');
				console.error("couldn't patch question", error);
			});
	}
};

const addCustomQualifierOptionPlaceholder = (store, action) => {
	if (action.type === actions.ADD_CUSTOM_QUALIFIER_OPTION_PLACEHOLDER) {
		const { questionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions = currentAudience.customQuestions;
		const patchedQuestion = questions.find(question => question.id === questionId);

		if (patchedQuestion) {
			patchedQuestion.options.push({
				value: '',
				placeholder: true,
				id: `placeholder-${patchedQuestion.options.length}`,
				order:
					last(patchedQuestion.options) &&
					!last(patchedQuestion.options).isNoneOfTheAbove &&
					!last(patchedQuestion.options).isOtherSpecify
						? last(orderBy(patchedQuestion.options, 'order')).order + 1
						: patchedQuestion.options.length,
				[CONSTANTS.questions.options.isOtherSpecify]: false,
				[CONSTANTS.questions.options.isNoneOfTheAbove]: false,
				[CONSTANTS.questions.options.lockOrder]: false,
				translations: [
					{
						languageCode: study.language || study.currentLanguage,
						label: '',
					},
				],
			});
		}

		store.dispatch(actions.setAudienceQuestions(questions));
	}
};

const addCustomQualifierAttributePlaceholder = (store, action) => {
	if (action.type === actions.ADD_CUSTOM_QUALIFIER_ATTRIBUTE_PLACEHOLDER) {
		const { questionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions = currentAudience.customQuestions;
		const patchedQuestion = questions.find(question => question.id === questionId);

		if (patchedQuestion) {
			patchedQuestion.attributes.push({
				value: '',
				placeholder: true,
				id: `placeholder-${patchedQuestion.attributes.length}`,
				order:
					last(patchedQuestion.attributes) &&
					!last(patchedQuestion.attributes).isNoneOfTheAbove &&
					!last(patchedQuestion.attributes).isOtherSpecify
						? last(orderBy(patchedQuestion.attributes, 'order')).order + 1
						: patchedQuestion.attributes.length,
				[CONSTANTS.questions.attributes.lockOrder]: false,
				translations: [
					{
						languageCode: study.language || study.currentLanguage,
						label: '',
					},
				],
			});
		}

		store.dispatch(actions.setAudienceQuestions(questions));
	}
};

// ATTRIBUTES

const addAttributePlaceholder = (store, action) => {
	if (action.type === actions.ADD_ATTRIBUTE_PLACEHOLDER) {
		const { questionId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const { questions } = currentSection;
		const patchedQuestion = questions.find(question => question.id === questionId);

		if (!patchedQuestion.attributes) {
			patchedQuestion.attributes = [];
		}

		patchedQuestion.attributes.push({
			placeholder: true,
			id: `placeholder-${patchedQuestion.attributes.length}`,
			order: last(patchedQuestion.attributes)
				? last(orderBy(patchedQuestion.attributes, 'order')).order + 1
				: patchedQuestion.attributes.length,
			[CONSTANTS.questions.attributes.lockOrder]: false,
			translations: [
				{
					languageCode: study.language || study.currentLanguage,
					label: '',
				},
			],
		});

		store.dispatch(actions.setSectionQuestions(questions));
	}
};

const removeAttributePlaceholder = (store, action) => {
	if (action.type === actions.REMOVE_ATTRIBUTE_PLACEHOLDER) {
		const { questionId, attributeId } = action.payload;
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		let questions = [];

		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			questions = currentAudience.customQuestions;
		} else {
			questions = currentSection.questions;
		}

		const patchedQuestion = questions.find(question => question.id === questionId);

		patchedQuestion.attributes = patchedQuestion.attributes.filter(attribute => attribute.id !== attributeId);

		if (currentAudience && Object.keys(currentAudience).length !== 0) {
			store.dispatch(actions.setAudienceQuestions(questions));
		} else {
			store.dispatch(actions.setSectionQuestions(questions));
		}
	}
};

const addQuestionAttribute = (store, action) => {
	if (action.type === actions.ADD_QUESTION_ATTRIBUTE) {
		const { questionId, attribute } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		services.questionService
			.createAttribute(study.id, questionId, attribute)
			.then(results => {
				// After creating the new attribute, we need to look and see if there were any placeholders attributes that need to be restored
				const currentQuestion = questions.find(q => q.id === questionId);

				// Last attribute is placeholder
				const lastAttribute = last(currentQuestion.attributes);

				// Assign the latest answers with current Ids
				let updatedAnswers = results.data.attributes;

				// Check current attributes against latest attributes to see if a placeholder is needed
				if (currentQuestion.attributes.length > updatedAnswers.length) {
					// Add placeholder back into attributes
					lastAttribute.order = last(updatedAnswers).order + 1;
					updatedAnswers = updatedAnswers.concat(lastAttribute);
				}

				// Update current question and set store state
				currentQuestion.attributes = updatedAnswers;

				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					store.dispatch(actions.setAudienceQuestions(questions));
				} else {
					store.dispatch(actions.setSectionQuestions(questions));
				}
			})
			.catch(error => {
				toastr.error('There was a problem creating the attribute. Please refresh and try again.');
			});
	}
};

const removeQuestionAttribute = (store, action) => {
	if (action.type === actions.REMOVE_QUESTION_ATTRIBUTE) {
		const { questionId, attributeId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		services.questionService
			.deleteAttribute(study.id, questionId, attributeId)
			.then(() => {
				const currentQuestion = questions.find(q => q.id === questionId);
				// Assign the latest answers with current Ids
				const updatedAnswers = currentQuestion.attributes.filter(attribute => attribute.id !== attributeId);

				// Update current question and set store state
				currentQuestion.attributes = updatedAnswers;
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					store.dispatch(actions.setAudienceQuestions(questions));

					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(({ data }) => {
						store.dispatch(
							actions.setAudience({
								content: data,
								loading: false,
							}),
						);
					});
				} else {
					store.dispatch(actions.setSectionQuestions(questions));
				}
			})
			.catch(error => {
				toastr.error('There was a problem deleting the attribute. Please refresh and try again.');
			});
	}
};

const updateAttributeLabel = (store, action) => {
	if (action.type === actions.UPDATE_ATTRIBUTE_LABEL) {
		const { questionId, translationId, attributeId, label, languageCode } = action.payload;
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;

		const study = selectors.getStudy(store.getState());
		let attributeData = { label };
		const question = questions.find(q => q.id === questionId);
		const questionAttribute = question.attributes.find(attribute => attribute.id === attributeId);
		const attributeTranslation = questionAttribute.translations.find(
			translation => translation.id === translationId,
		);

		if (attributeTranslation) {
			if (!attributeTranslation.assetId) delete attributeTranslation.assetId;
			delete attributeTranslation.asset;
			delete attributeTranslation.assetVariations;
			delete attributeTranslation.id;
			attributeTranslation.label = label;
			attributeTranslation.studyTranslationId = translationId;
			attributeData = attributeTranslation;
		} else {
			// Create New Translation with label and language code and add to current translation object
			attributeData = questionAttribute;
			delete attributeData.id;
			attributeData = {
				languageCode,
				label,
			};
		}

		const serviceRoute = translationId
			? services.questionService.patchAttributeTranslation(
					study.id,
					questionId,
					attributeId,
					translationId,
					attributeData,
			  )
			: services.questionService.postAttributeTranslation(study.id, questionId, attributeId, [attributeData]);

		serviceRoute
			.then(() => {
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(res => {
						const currentQuestion = questions.find(q => q.id === questionId);

						// Last attribute is placeholder
						const lastAttribute = last(currentQuestion.attributes);

						// Assign the latest answers with current Ids
						let updatedAnswers = res.data.customQuestions.find(q => q.id === questionId).attributes;

						// Check current attributes against latest attributes to see if a placeholder is needed
						if (currentQuestion.attributes.length > updatedAnswers.length) {
							// Add placeholder back into attributes
							updatedAnswers = updatedAnswers.concat(lastAttribute);
						}

						// Update current question and set store state
						currentQuestion.attributes = updatedAnswers;
						store.dispatch(actions.setAudienceQuestions(questions));

						store.dispatch(
							actions.setAudience({
								content: res.data,
								loading: false,
							}),
						);
					});
				} else {
					// After editing the attribute, we need to look and see if there were any placeholders attributes that need to be restored
					services.sections.getPublic(study.id, currentSection.id, 'edit').then(res => {
						const currentQuestion = questions.find(q => q.id === questionId);

						// Last attribute is placeholder
						const lastAttribute = last(currentQuestion.attributes);

						// Assign the latest answers with current Ids
						let updatedAnswers =
							res.data.questions &&
							res.data.questions.find(q => q.id === questionId) &&
							res.data.questions.find(q => q.id === questionId).attributes;

						// Check current attributes against latest attributes to see if a placeholder is needed
						if (currentQuestion.attributes.length > updatedAnswers.length) {
							// Add placeholder back into attributes
							updatedAnswers = updatedAnswers.concat(lastAttribute);
						}

						// Update current question and set store state
						currentQuestion.attributes = updatedAnswers;
						store.dispatch(actions.setSectionQuestions(questions));
					});
				}
			})
			.catch(error => {
				toastr.error('There was a problem updating the attribute. Please refresh and try again.');
			});
	}
};

const reorderAttributes = (store, action) => {
	if (action.type === actions.REORDER_ATTRIBUTES) {
		const moveInArray = function (arr, from, to) {
			// Delete the item from it's current position
			const item = arr.splice(from, 1);
			// Move the item to its new position
			arr.splice(to, 0, item[0]);
		};

		const { questionId, attributeId, sourceIndex, destinationIndex } = action.payload;
		const study = selectors.getStudy(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState()).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;
		const patchedQuestion = questions.find(question => question.id === Number(questionId));
		const attributes = patchedQuestion && patchedQuestion.attributes;

		// uncomment the 2nd check to disable others/none to stay on the bottom
		const attributesWithoutPlaceholders = attributes.filter(
			attribute => !attribute.placeholder /* && attribute.order < 99 */,
		);

		// Order attributes based on order attribute
		attributesWithoutPlaceholders.sort((a, b) => (a.order > b.order ? 1 : -1));

		// Move attribute to new position in array
		moveInArray(attributesWithoutPlaceholders, sourceIndex, destinationIndex);

		// Set new order attributes based on new array
		attributesWithoutPlaceholders.forEach((attribute, key) => {
			attribute.order = key;
			delete attribute.assetVariations;
			if (attribute.translations) {
				attribute.translations.forEach(translation => {
					delete translation.assetVariations;
				});
			}
		});

		// Do not send in isDisplayedInReporting or asset property for question attributes
		questions.forEach(question => {
			if (question.attributes) {
				question.attributes.forEach(attribute => {
					delete attribute.isDisplayedInReporting;
					delete attribute.asset;
					if (attribute.translations) {
						attribute.translations.forEach(translation => {
							delete translation.asset;
							delete translation.assetVariations;
						});
					}
				});
			}
		});

		// Set questions with new attributes
		const newQuestions = [
			...questions.map(q => (q.id !== patchedQuestion.id ? q : { ...patchedQuestion, attributes })),
		];

		services.questionService
			.patch(study.id, questionId, {
				attributes: attributesWithoutPlaceholders,
			})
			.then(() => {
				if (currentAudience && Object.keys(currentAudience).length !== 0) {
					services.studySampleService.getAudience(study.id, currentAudience.uuid).then(res => {
						store.dispatch(actions.setAudienceQuestions(newQuestions));
						store.dispatch(
							actions.setAudience({
								content: res.data,
								loading: false,
							}),
						);
					});
				} else {
					store.dispatch(actions.setSectionQuestions(newQuestions));
					if (currentSection) {
						store.dispatch(actions.fetchSection(currentSection.id, 'edit', true));
					}
				}
			})
			.catch(error => {
				toastr.error('There was a problem re-ordering the attributes. Please refresh and try again.');
			});
	}
};

const patchQuestionAttribute = (store, action) => {
	if (action.type === actions.PATCH_QUESTION_ATTRIBUTE) {
		const { studyId, questionId, attributeId, data } = action.payload;
		const state = store.getState();
		const currentSection = state.study.currentSection.content || selectors.getCurrentSection(state).content;
		const currentAudience = selectors.getAudience(store.getState()).content;
		const questions =
			currentAudience && Object.keys(currentAudience).length !== 0
				? currentAudience.customQuestions
				: currentSection.questions;
		if (data.translations && data.translations.length > 0) {
			data.translations.forEach(translation => delete translation.assetVariations);
		}
		services.questionService.patchAttribute(studyId, questionId, attributeId, data).then(response => {
			if (currentAudience && Object.keys(currentAudience).length > 0) {
				services.studySampleService.getAudience(studyId, currentAudience.uuid).then(res => {
					const currentQuestion = questions.find(q => q.id === questionId);
					const updatedAnswers = res.data.customQuestions.find(q => q.id === questionId).attributes;
					currentQuestion.attributes = updatedAnswers;
					store.dispatch(actions.setAudienceQuestions(questions));
					store.dispatch(
						actions.setAudience({
							content: res.data,
							loading: false,
						}),
					);
				});
			} else {
				const { id: sectionId } = currentSection;
				store.dispatch(actions.fetchSection(sectionId, 'edit', true));
			}
		});
	}
};

const reorderQuestionsWithoutSections = (store, action) => {
	if (action.type === actions.REORDER_QUESTIONS_WITHOUT_SECTIONS) {
		const { studyId, questionIds } = action.payload;
		services.questionService.reorder(studyId, questionIds).catch(error => {
			console.error("couldn't re-order sections", error);
		});
	}
};

export default [
	// QUESTIONS
	addSectionQuestion,
	deleteSectionQuestion,
	reorderQuestions,
	updateQuestionLabel,
	patchQuestion,
	createQuestionSetting,
	patchQuestionSetting,

	// OPTIONS
	addOptionPlaceholder,
	removeOptionPlaceholder,
	addQuestionOption,
	removeQuestionOption,
	updateOptionLabel,
	reorderOptions,
	patchQuestionOption,

	// CUSTOM QUALIFIERS
	addAudienceQuestion,
	deleteCustomQualifier,
	updateCustomQualifierLabel,
	addCustomQualifierOptionPlaceholder,
	addCustomQualifierAttributePlaceholder,

	// ATTRIBUTES
	addAttributePlaceholder,
	removeAttributePlaceholder,
	addQuestionAttribute,
	removeQuestionAttribute,
	updateAttributeLabel,
	reorderAttributes,
	patchQuestionAttribute,

	reorderQuestionsWithoutSections,
];
