import * as services from 'src/services';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import * as actions from '../actions';

const getLanguages = (store, action) => {
	if (action.type === actions.FETCH_LANGUAGES) {
		services.languageService.getLanguages().then(({ data: languages }) => {
			const languageList = languages.map(language => ({ label: language.label, value: language.languageCode }));
			store.dispatch(actions.setLanguages(languageList));
		});
	}
};

const createClient = (store, action) => {
	if (action.type === actions.CREATE_CLIENT) {
		store.dispatch(actions.setLoading(true));
		services.clientsService
			.createClient({ name: action.payload.name })
			.then(({ data }) => {
				const cleanUuid = data.uuid;
				store.dispatch(actions.setLoading(false));
				navigate(`/client/${cleanUuid}`);
			})
			.catch(err => {
				store.dispatch(actions.setLoading(false));
				store.dispatch(actions.setError(err));
			});
	}
};

export default [getLanguages, createClient];
