/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import blueprintService from 'src/services/blueprint.service';
import studyService from 'src/services/study.service';
import sectionsService from 'src/services/sections.service';
import audienceCollectionService from 'src/services/audience.service';
import ResponsiveModal from 'src/components/shared/ResponsiveModal';
import TemplatePreviewModalHeader from './elements/Header';
import TemplatePreviewSideBar from './elements/SideBar';
import TemplatePreviewBody from './elements/Body';
import TemplatePreviewFooter from './elements/Footer';

import './styles.scss';
import { TemplateCardsContext } from '../TemplateCard/context';
import Loader from '../Loader';

const el = (name, mod) => cn(className, name, mod);
const className = 'template-preview-modal';

const PreviewTemplateModal = ({
	show,
	template,
	onClose,
	mode = 'preview', // also accepts 'save' for save templates mode
	modalMode = true, // also accepts false to return inner component
	goBack,
	saveTemplateCallback,
	copyToMyTemplatesCallback,
	currentTab,
	setCurrentTab,
	setTemplateModalCurrentAudience,
	templateModalCurrentAudience,
	useTemplateCallback = null,
	includeBack = true,
}) => {
	const [visible, setVisible] = useState(show);
	const [study, setStudy] = useState(template?.study);
	const [audience, setAudience] = useState(null);
	const [owner, setOwner] = useState(template?.audience?.owner);
	const [localTemplateName, setLocalTemplateName] = useState(null);
	const [sections, setSections] = useState([]);
	const [activeSection, setActiveSection] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [saveAudience, setSaveAudience] = useState(true);
	const [saveStudy, setSaveStudy] = useState(true);
	const { onSelect, onEditTemplate, onDuplicateTemplate, handleDeleteClick } = useContext(TemplateCardsContext);
	const [selectedLanguage, setSelectedLanguage] = useState(study?.defaultLanguage || 'en');
	const [languages, setLanguages] = useState([]);
	const [showSpinner, setShowSpinner] = useState(false);

	useEffect(() => {
		if (template?.type === 'audience') {
			// grabbing language from screening questions
			const translations = audience?.screeningQuestions?.[0]?.translations || [];
			const defaultLanguageCode = translations?.[0]?.languageCode || 'en';
			if (defaultLanguageCode) {
				const questionsLanguageCode = [];
				audience?.demographicGroups?.forEach(group => {
					if (!questionsLanguageCode.includes(group?.languageCode) && group?.languageCode) {
						questionsLanguageCode.push(group?.languageCode);
					}
				});
				audience?.screeningQuestions?.forEach(question => {
					for (const translation of question?.translations || []) {
						if (!questionsLanguageCode.includes(translation?.languageCode) && translation?.languageCode) {
							questionsLanguageCode.push(translation?.languageCode);
						}
					}
				});
				setSelectedLanguage(defaultLanguageCode);
				setLanguages(questionsLanguageCode);
			}
		} else {
			setSelectedLanguage(study?.defaultLanguage || 'en');
			setLanguages(study?.languages || ['en']);
		}
	}, [template, study, audience]);

	const type = useMemo(() => {
		if (template?.type === 'combined') {
			return 'Study + Audience Template ';
		}
		if (template?.type === 'study') {
			return 'Study Template ';
		}
		return 'Audience Template ';
	}, [template]);

	const onCloseModal = useCallback(() => {
		setVisible(false);
		onClose();
	}, [onClose]);

	const fetchStudy = useCallback(async () => {
		if (study) {
			// fetch study basic data
			try {
				setIsLoading(true);
				const [studyResponse, sectionsResponse] = await Promise.all([
					mode === 'preview' ? blueprintService.getBlueprint(study?.id) : studyService.getStudy(study?.id),
					mode === 'preview' ? sectionsService.blueprintGetAll(study?.id) : sectionsService.getAll(study?.id),
				]);
				if (studyResponse?.data) {
					setStudy(prevState => ({ ...prevState, ...studyResponse?.data }));
					setLocalTemplateName(studyResponse?.data?.name);
				}
				if (sectionsResponse?.data) {
					setSections(sectionsResponse?.data);
					if (!audience) {
						setActiveSection(sectionsResponse?.data?.length ? sectionsResponse?.data[0]?.id : null);
					}
				}
				setIsLoading(false);
			} catch (error) {
				console.warn(error);
				setIsLoading(false);
			}
		}
	}, [study, audience, mode]);

	const fetchAudience = useCallback(async () => {
		if (audience) {
			// fetch study basic data
			try {
				setIsLoading(true);
				const response = await audienceCollectionService.getCollection(
					audience?.studyId || study?.id,
					audience?.audienceCollectionId || audience?.id,
				);
				if (response?.data) {
					response.data.countryIds =
						response?.data?.demographicGroups?.map(({ countryLanguage }) => countryLanguage?.countryId) ||
						[];
					const demoGroupsWithoutWebLinks = response?.data?.demographicGroups?.filter(
						group => group?.provider !== 'BYO',
					);
					setAudience(prevState => ({
						...prevState,
						...response?.data,
						demographicGroups: demoGroupsWithoutWebLinks,
					}));
					setLocalTemplateName(response?.data?.name);
					if (!activeSection && response?.data?.demographicGroups?.length) {
						setActiveSection('demographic-groups');
					} else if (!activeSection && response?.data?.screeningQuestions?.length) {
						setActiveSection('screening-questions');
					}
					if (response?.data?.owner) {
						setOwner(response?.data?.owner);
					}
				}
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setIsLoading(false);
			}
		}
	}, [audience, study?.id, activeSection]);

	const fetchStudyPreview = useCallback(
		async id => {
			// fetch study basic data
			try {
				setIsLoading(true);
				const [studyResponse, sectionsResponse] = await Promise.all([
					blueprintService.getBlueprint(id),
					sectionsService.blueprintGetAll(id),
				]);
				if (studyResponse?.data) {
					setStudy(prevState => ({ ...prevState, ...studyResponse?.data }));
				}
				if (sectionsResponse?.data) {
					setSections(sectionsResponse?.data);
					if (!audience) {
						setActiveSection(sectionsResponse?.data?.length ? sectionsResponse?.data[0]?.id : null);
					}
				}
				setIsLoading(false);
			} catch (error) {
				console.warn(error);
				setIsLoading(false);
			}
		},
		[audience],
	);

	useEffect(() => {
		if (show) {
			setVisible(true);
		} else {
			setVisible(false);
		}
	}, [show]);

	useEffect(() => {
		// stop body from scrolling behind the modal (only if modal mode)
		if (modalMode) {
			if (visible) {
				document.getElementsByTagName('body')[0].style.overflow = 'hidden';
			} else {
				document.getElementsByTagName('body')[0].style.overflow = 'auto';
			}
		}
	}, [visible, modalMode]);

	useEffect(() => {
		if (template?.type !== 'audience' && modalMode && study?.id && !study?.defaultLanguage && !sections?.length) {
			fetchStudy();
		}

		if (study?.owner) {
			setOwner(study?.owner);
		}
		// eslint-disable-next-line
	}, [study]);

	useEffect(() => {
		if (audience?.id && !audience?.screeningQuestions) {
			fetchAudience();
		} else {
			setIsLoading(false);
			if (currentTab.includes('screening')) {
				setActiveSection('screening-questions');
			} else {
				setActiveSection('demographic-groups');
			}
		}
		// eslint-disable-next-line
	}, [audience]);

	useEffect(() => {
		if (mode !== 'save' && !modalMode && template?.type !== 'audience' && template?.id) {
			fetchStudyPreview(template?.id);
		} else if (mode === 'save' && template?.type !== 'audience') {
			fetchStudy();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setStudy(null);
		setAudience(null);
		setSections([]);
		if (template?.type === 'combined') {
			setStudy(template);
			setAudience({ id: template?.audienceCollectionId, studyId: template?.id });
			setActiveSection('demographic-groups');
		} else if (template?.type === 'study') {
			setStudy(template);
		} else {
			setAudience(template);
		}

		if (template?.owner) {
			setOwner(template?.owner);
		}
	}, [template]);

	const templateName = useMemo(() => {
		if (template?.type === 'audience') {
			return audience?.name || 'Untitled Audience';
		}
		return study?.name || 'Untitled Study';
	}, [template, audience, study]);

	const renderContent = () => (
		<div className={className} style={modalMode ? { maxWidth: 'calc(100% - 64px)' } : {}}>
			<TemplatePreviewModalHeader
				title={mode === 'preview' ? 'Preview Template' : 'Save Template'}
				name={templateName}
				type={template?.type}
				tagTypeText={type}
				countryIds={audience?.countryIds}
				languages={languages}
				studyUuid={study?.uuid}
				onClickClose={onCloseModal}
				isLoading={isLoading}
				modalMode={modalMode}
				mode={mode}
				goBack={goBack}
				includeBack={includeBack}
				selectedLanguage={selectedLanguage}
				setSelectedLanguage={setSelectedLanguage}
				localTemplateName={localTemplateName}
				setLocalTemplateName={setLocalTemplateName}
				asset={study?.asset}
				emoji={study?.emoji}
			/>
			<div className={el('body-group')}>
				<TemplatePreviewSideBar
					study={study}
					audience={audience}
					mode={mode}
					sections={sections}
					activeSection={activeSection}
					setActiveSection={setActiveSection}
					isLoading={isLoading}
					saveAudience={saveAudience}
					setSaveAudience={setSaveAudience}
					saveStudy={saveStudy}
					setSaveStudy={setSaveStudy}
					type={template?.type}
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
				/>
				{showSpinner ? (
					<div className={el('loading-area')}>
						<Loader centered />
					</div>
				) : (
					<TemplatePreviewBody
						type={type}
						audience={audience}
						sections={sections}
						activeSection={activeSection}
						isLoading={isLoading}
						study={study}
						selectedLanguage={selectedLanguage}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
						templateModalCurrentAudience={templateModalCurrentAudience}
						setTemplateModalCurrentAudience={setTemplateModalCurrentAudience}
					/>
				)}
			</div>
			<TemplatePreviewFooter
				mode={mode}
				type={template?.type}
				localTemplateName={localTemplateName}
				saveAudience={saveAudience}
				saveStudy={saveStudy}
				onClose={onCloseModal}
				saveTemplateCallback={saveTemplateCallback}
				useTemplateCallback={() => {
					if (useTemplateCallback) {
						useTemplateCallback(template);
						onClose();
					} else {
						onSelect(template);
					}
				}}
				copyToMyTemplatesCallback={() => copyToMyTemplatesCallback(template)}
				owner={owner}
				templateEditCallback={() => onEditTemplate(template, true)}
				duplicateTemplateCallback={() => {
					setShowSpinner(true);
					onDuplicateTemplate(template, () => setShowSpinner(false));
				}}
				onDeleteCallback={() => handleDeleteClick(template)}
			/>
		</div>
	);

	if (modalMode) {
		return (
			<ResponsiveModal
				show={visible}
				onClose={() => {
					onCloseModal();
				}}
				customContent
				customClass={el('template-preview-modal-blur')}
			>
				{renderContent()}
			</ResponsiveModal>
		);
	}

	return renderContent();
};

PreviewTemplateModal.displayName = 'TemplatePreviewModal';
PreviewTemplateModal.propTypes = {
	show: PropTypes.bool,
	template: PropTypes.any,
	onClose: PropTypes.any,
	mode: PropTypes.string,
	modalMode: PropTypes.bool,
	goBack: PropTypes.any,
	saveTemplateCallback: PropTypes.any,
	copyToMyTemplatesCallback: PropTypes.any,
	useTemplateCallback: PropTypes.any,
	includeBack: PropTypes.bool,
	templateModalCurrentAudience: PropTypes.string,
	setTemplateModalCurrentAudience: PropTypes.func,
	currentTab: PropTypes.string,
	setCurrentTab: PropTypes.func,
};

export default PreviewTemplateModal;
