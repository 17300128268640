// packages
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// helpers
import { isExcludedMeanScoreOption } from 'src/components/helpers';

// components
import IconButton from 'src/components/elements/IconButton';

const ResetScaleButton = ({
	question,
	patchQuestion,
	setOptionScalePointValue,
	screeningQuestion,
	tempTemplate,
	batchUpdateAudienceTemplateScreeningQuestionOptions,
}) => {
	// state
	const questionId = question.id;

	// functions
	const resetMeanScoreScaleToAsc = () => {
		const options = question?.options ?? [];

		const autoScaleOptionsScalePoints = _.fromPairs(
			options?.filter(option => !isExcludedMeanScoreOption(option))?.map(({ id }, index) => [id, index + 1]) ??
				[],
		);

		const optionsWithAscendingScalePoints = options.map(({ isDisplayedInReporting, ...option }) => ({
			...option,
			scalePoint: isExcludedMeanScoreOption(option) ? null : autoScaleOptionsScalePoints?.[option.id] ?? null,
		}));

		const updatedPlaceholderOption = optionsWithAscendingScalePoints?.find(({ placeholder }) => placeholder);

		if (updatedPlaceholderOption) {
			setOptionScalePointValue({
				questionId,
				optionId: updatedPlaceholderOption.id,
				scalePoint: updatedPlaceholderOption.scalePoint,
			});
		}

		if (tempTemplate) {
			return batchUpdateAudienceTemplateScreeningQuestionOptions({
				questionId,
				options: optionsWithAscendingScalePoints,
				refetchAudienceCollection: true,
			});
		}

		const payload = { options: optionsWithAscendingScalePoints, screeningQuestion };

		patchQuestion(questionId, payload);
	};

	return (
		<IconButton
			icon="replay"
			color="primary-green"
			tooltip="Reset Scale"
			size="large"
			onClick={resetMeanScoreScaleToAsc}
		/>
	);
};

ResetScaleButton.propTypes = {
	question: PropTypes.object,
	patchQuestion: PropTypes.func,
	setOptionScalePointValue: PropTypes.func,
	screeningQuestion: PropTypes.bool,
	tempTemplate: PropTypes.object,
	batchUpdateAudienceTemplateScreeningQuestionOptions: PropTypes.func,
};

export default ResetScaleButton;
