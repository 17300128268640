import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { cleanHtmlString } from 'src/utilities/misc';
import './styles.scss';
import { Highlighted } from 'src/components/shared/SmartSearch';
import { useSearchParams } from 'react-router-dom';
import { urlParams } from '../../index';

const IdeaDescription = ({
	searchString,
	fieldOne,
	fieldOneType,
	fieldTwo,
	fieldTwoType,
	fieldThree,
	fieldThreeType,
	highlightClassName,
}) => {
	const className = 'idea-description';

	const [searchParams, setSearchParams] = useSearchParams();

	const urlSearchString = searchParams.get(urlParams.query);

	const highlightString = searchString || urlSearchString;

	let text = null;

	if (fieldOneType === 'html') {
		text = parse(cleanHtmlString(fieldOne));
	}
	if (fieldTwoType === 'html') {
		text = parse(cleanHtmlString(fieldTwo));
	}
	if (fieldThreeType === 'html') {
		text = parse(cleanHtmlString(fieldThree));
	}

	if (text) {
		return (
			<span className={className}>
				<Highlighted text={text} highlight={highlightString} highlightClassName={highlightClassName} />
			</span>
		);
	}
	return null;
};

IdeaDescription.propTypes = {
	searchString: PropTypes.string,
	fieldOne: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	fieldOneType: PropTypes.string,
	fieldTwo: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	fieldTwoType: PropTypes.string,
	fieldThree: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	fieldThreeType: PropTypes.string,
	highlightClassName: PropTypes.string,
};

export default IdeaDescription;
