// packages
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// helpers
import { getMeanScoreScaleOrder, isExcludedMeanScoreOption } from 'src/components/helpers';

// components
import IconButton from 'src/components/elements/IconButton';

const className = 'flip-scale-button'; // * Styled in ModifyScaleButton's styles

const FlipScaleButton = ({
	question,
	patchQuestion,
	setOptionScalePointValue,
	screeningQuestion,
	tempTemplate,
	batchUpdateAudienceTemplateScreeningQuestionOptions,
}) => {
	// state
	const questionId = question.id;

	// functions
	const flipMeanScoreScale = () => {
		const options = _.orderBy(
			question?.options?.map(
				({
					isDisplayedInReporting, // * Exclude `isDisplayedInReporting`
					...option
				}) => option,
			) ?? [],
			'order',
			'asc',
		);
		const { isAsc } = getMeanScoreScaleOrder(options);

		const includedOptions = options.filter(option => !isExcludedMeanScoreOption(option));
		const excludedOptions = options.filter(option => isExcludedMeanScoreOption(option));

		const maxScalePoint = includedOptions?.length ?? 0;

		const flippedIncludedOptions = includedOptions.map((option, index) => ({
			...option,
			scalePoint: isAsc ? maxScalePoint - index : index + 1,
		}));

		const optionsWithFlippedScalePoints = _.orderBy(
			[
				...flippedIncludedOptions,
				...excludedOptions, // Adding back the options with null scalePoint
			],
			'order',
			'asc',
		);

		const updatedPlaceholderOption = optionsWithFlippedScalePoints?.find(({ placeholder }) => placeholder);

		if (updatedPlaceholderOption) {
			setOptionScalePointValue({
				questionId,
				optionId: updatedPlaceholderOption.id,
				scalePoint: updatedPlaceholderOption.scalePoint,
			});
		}

		if (tempTemplate) {
			return batchUpdateAudienceTemplateScreeningQuestionOptions({
				questionId,
				options: optionsWithFlippedScalePoints,
				refetchAudienceCollection: true,
			});
		}

		patchQuestion(questionId, { options: optionsWithFlippedScalePoints, screeningQuestion });
	};

	return (
		<IconButton
			icon="swap_horizontal"
			tooltip="Flip Scale"
			size="large"
			onClick={flipMeanScoreScale}
			{...{ className }}
		/>
	);
};

FlipScaleButton.propTypes = {
	question: PropTypes.object,
	patchQuestion: PropTypes.func,
	setOptionScalePointValue: PropTypes.func,
	screeningQuestion: PropTypes.bool,
	tempTemplate: PropTypes.object,
	batchUpdateAudienceTemplateScreeningQuestionOptions: PropTypes.func,
};

export default FlipScaleButton;
