import CONSTANTS from 'src/config/constants';
import { createSelector } from 'reselect';
import { orderBy, isEqual } from 'lodash';
import misc, { getFloatRounded } from 'src/utilities/misc';
import { formatOptions } from 'src/utilities/filters';
import { getAudiences as getPublicAudiences } from 'src/domains/public-study/selectors';
import { getPublicReportData, getStudyData } from '../../selectors';
import { getAudiences } from '.';

export const getStudy = state => getStudyData(state).study;
export const getLanguages = state => getStudyData(state).languages;
export const getLanguage = state => getStudyData(state).language;
export const getFilters = state => getStudyData(state).filters;
export const getLocalFilters = state => getStudyData(state).localFilters;
export const getProducts = state => getStudyData(state).products;
export const getProductSort = state => getStudyData(state).productSort;
export const getProductSortDescending = state => getStudyData(state).productSortDescending;
export const getProductTags = state => getStudyData(state).productTags;
export const getResponseData = state => getStudyData(state).responseData;
export const getActiveBaseFilters = state => getStudyData(state).activeBaseFilters;
export const getIdeaMap = state => getStudyData(state).ideaMap;
export const getError = state => getStudyData(state).error;
export const getProductViewMode = state => getStudyData(state).productViewMode;
export const getQuestionViewMode = state => getStudyData(state).questionViewMode;
export const getIdeaMapLoading = state => getStudyData(state).ideaMapLoading;
export const getGenerateReportModal = state => getStudyData(state).generateReportModal;
export const getSections = state => getStudyData(state).sections;
export const getCurrentSection = state => getStudyData(state).currentSection;
export const getCurrentSectionProducts = state => getStudyData(state).currentSection.content.products;
export const getSortBy = state => getStudyData(state).sortBy;
export const getTranslationsModal = state => getStudyData(state).translationsModal;
export const getTranslationPreview = state => getStudyData(state).translationPreview;
export const getDiscardProductChangesModal = state => getStudyData(state).discardProductChangesModal;

// Audience Reports
export const getAudiencesReportingData = state => getStudyData(state).audienceReports;
export const getAudienceReportingData = (state, audienceUuid) =>
	getStudyData(state).audienceReports.find(audienceReport => audienceReport.audienceUuid === audienceUuid);
export const getShowAudienceReports = state => getStudyData(state).showAudienceReports;
export const getAudienceCollectionReport = state => getStudyData(state).audienceCollectionReportData;

// Device Preview Mode
export const getShowDevicePreviewMode = state => getStudyData(state).showDevicePreviewMode;
export const getDevicePreviewMode = state => getStudyData(state).devicePreviewMode;
export const getShowDevicePreviewIframeBlocker = state => getStudyData(state).showDevicePreviewIframeBlocker;
export const getDevicePreviewUrl = state => getStudyData(state).devicePreviewUrl;

// Filter sidebar
export const getShowFilterSidebar = state => getStudyData(state).showFilterSidebar;

// Launch Audience Modal
export const getLaunchAudienceModal = state => getStudyData(state).launchAudienceModal;

// Splits
export const getMonadicSplitReportingData = state => getStudyData(state).monadicSplitReportingData;
export const getMonadicSplitPin = state => getStudyData(state).monadicSplitPin;
export const getMonadicSplitSortOrder = state => getStudyData(state).monadicSplitSortOrder;
export const getMonadicSplitSortQuestionId = state => getStudyData(state).monadicSplitSortQuestionId;
export const getMonadicSplitSortQuestionOptionId = state => getStudyData(state).monadicSplitSortQuestionOptionId;

//  Customize report
export const getCustomizeReport = state => getStudyData(state).customizeReport;

// Share of Choice
export const getShareOfChoice = state => getStudyData(state).shareOfChoice;
export const getShareOfChoiceExistingProductIds = state => getStudyData(state).shareOfChoiceExistingProductIds;
export const getShareOfChoiceProgress = state => getStudyData(state).shareOfChoiceProgress;
export const getShareOfChoiceInnovationProductIds = state => getStudyData(state).shareOfChoiceInnovationProductIds;
export const getShareOfChoiceView = state => getStudyData(state).shareOfChoiceView;
export const getShareOfChoiceSortOrder = state => getStudyData(state).shareOfChoiceSortOrder;
export const getShareOfChoiceSortDirection = state => getStudyData(state).shareOfChoiceSortDirection;
export const getShareOfChoiceExcludedProducts = state => getStudyData(state).shareOfChoiceExcludedProducts;
export const getShareOfChoiceDataAvailable = state => getStudyData(state).shareOfChoice.freshDataAvailable;
export const getShareOfChoiceResponseCount = state => getStudyData(state).shareOfChoiceResponseCount;
export const getShareOfChoiceGroups = state => getStudyData(state).shareOfChoiceGroups;
export const getShareOfChoiceLoading = state => getStudyData(state).shareOfChoiceLoading;

export const getLengthOfInterview = state => getStudyData(state).lengthOfInterview;
export const getShareOfChoiceScenarioProducts = createSelector(
	getShareOfChoiceInnovationProductIds,
	getShareOfChoiceExistingProductIds,
	getCurrentSectionProducts,
	(existingProductIds, innovationProductIds, products) => {
		if (existingProductIds.length || innovationProductIds.length) {
			return products.filter(product =>
				[...innovationProductIds, ...existingProductIds].includes(product.localProductId),
			);
		}

		return products;
	},
);

export const getShareOfChoiceHasBaseCase = createSelector(getShareOfChoiceGroups, groups =>
	groups.some(group => group.isBaseCase),
);

export const getShareOfChoiceFilteredGroups = createSelector(getShareOfChoiceGroups, groups =>
	groups.filter(group => !group.isBaseCase),
);

export const getShareOfChoiceFilteredGroupIds = createSelector(getShareOfChoiceFilteredGroups, groups =>
	groups.map(group => group.id),
);

export const getShareOfChoiceBaseCaseProductIds = createSelector(
	getShareOfChoiceExistingProductIds,
	getCurrentSectionProducts,
	(existingProductIds, products) => {
		if (existingProductIds.length) {
			return existingProductIds;
		}
		return products.map(product => product.localProductId);
	},
);

export const getShareOfChoiceProductIds = createSelector(
	getShareOfChoiceScenarioProducts,
	getShareOfChoiceExcludedProducts,
	(products, excludedProducts) =>
		products
			.filter(product => !excludedProducts.includes(product.localProductId))
			.map(product => product.localProductId),
);

export const getShareOfChoiceProducts = createSelector(
	getCurrentSectionProducts,
	getShareOfChoice,
	getShareOfChoiceFilteredGroups,
	(sectionProducts, shareOfChoice, groups) => {
		if (shareOfChoice.products) {
			const data = sectionProducts.map(product => {
				const socItem = (shareOfChoice.products || []).find(
					soc => soc.localProductId === product.localProductId,
				) || {
					shareOfChoice: 0,
					change: -100, // forces item to bottom of list when sorting by change
					changePercent: -100, // forces item to bottom of list when sorting by changePercent
				};

				const itemGroup = (groups || []).find(group => group.productIds.includes(product.localProductId));

				return {
					...product,
					...socItem,
					shareOfChoiceRounded: getFloatRounded(socItem.shareOfChoice).toFixed(1),
					changeRounded: getFloatRounded(socItem.change).toFixed(1),
					changePercentRounded: getFloatRounded(socItem.changePercent, 0),
					...(itemGroup ? { groupColor: itemGroup?.hex, groupName: itemGroup?.name } : {}),
				};
			});

			return data;
		}

		return [];
	},
);

export const getShareOfChoiceSummary = createSelector(getShareOfChoice, shareOfChoice => {
	if (shareOfChoice.summary) {
		return shareOfChoice.summary;
	}

	return [];
});

export const getShareOfChoiceSummaryGroups = createSelector(
	getShareOfChoiceFilteredGroups,
	getShareOfChoice,
	(groups, shareOfChoice) => {
		if (groups.length && shareOfChoice.groups) {
			const groupData = groups.map(group => {
				const socGroupData = shareOfChoice.groups.find(socGroup => socGroup.id === group.id) || {
					shareOfChoice: 0,
					change: 0,
					changePercent: 0,
				};

				return {
					...socGroupData,
					...group,
				};
			});

			const orderedGroupsData = orderBy(groupData, [group => group.name.toLowerCase()]);

			return orderedGroupsData;
		}

		return [];
	},
);

export const getShareOfChoiceProductData = createSelector(
	getShareOfChoiceProducts,
	getShareOfChoiceSortOrder,
	getShareOfChoiceSortDirection,
	(data, sort, sortDirection) => {
		const sortBy = sort || 'name';

		const mainSortDirection = sortDirection || CONSTANTS.shareOfChoice.sortDirectionDefault[sort || 'name'];

		let orderedData;
		if (sortBy === CONSTANTS.shareOfChoice.sortOrder.name) {
			orderedData = orderBy(data, 'name', mainSortDirection);
		}

		if (sortBy === CONSTANTS.shareOfChoice.sortOrder.share) {
			orderedData = orderBy(data, ['shareOfChoice', 'name'], [mainSortDirection, 'asc']);
		}

		if (sortBy === CONSTANTS.shareOfChoice.sortOrder.change) {
			orderedData = orderBy(
				data,
				['change', 'changePercentRounded', 'name'],
				[mainSortDirection, mainSortDirection, 'asc'],
			);
		}

		if (sortBy === CONSTANTS.shareOfChoice.sortOrder.percent) {
			orderedData = orderBy(
				data,
				['changePercent', 'changeRounded', 'name'],
				[mainSortDirection, mainSortDirection, 'asc'],
			);
		}

		return orderedData;
	},
);

export const getShareOfChoiceHasSubscript = createSelector(
	getShareOfChoiceExcludedProducts,
	getShareOfChoiceInnovationProductIds,
	(excludedProducts, innovationProductIds) => {
		if (innovationProductIds.length) {
			return !isEqual(excludedProducts.sort(), innovationProductIds.sort());
		}

		return !!excludedProducts.length;
	},
);

export const getShareOfChoiceHasData = createSelector(
	getShareOfChoice,
	data => data?.products && data?.products.some(item => item.shareOfChoice !== 0),
);

/**
 * FILTERs
 */
export const getSectionsWithQuestionNumbers = createSelector(getStudyData, study => {
	const sectionsData = study?.sections;

	if (!sectionsData || !sectionsData.content || !sectionsData.content.length) {
		return sectionsData;
	}

	sectionsData.content.forEach(section => {
		if (section.type !== 'questions') return section;
		section.questions.forEach(question => {
			question.studyQuestionNumber = misc.getStudyQuestionNumber(question, section, sectionsData.content);
		});
	});

	return sectionsData;
});

export const getStudyFilters = createSelector(
	getStudyData,
	getPublicReportData,
	(study, publicStudy) => study?.filters || publicStudy?.filters,
);

export const getStudyAudienceFilters = createSelector(
	getAudiences,
	getPublicAudiences,
	(gatedAudiences, publicAudiences) => {
		const audiences = gatedAudiences?.content || publicAudiences?.content;
		if (!audiences || !audiences?.length) {
			return [];
		}

		const audienceData = audiences?.filter(audience => {
			if (!['complete', 'live'].includes(audience.status)) {
				return false;
			}
			if (audience.currentSample === 0) {
				return false;
			}

			return true;
		});
		return audienceData;
	},
);

export const getStudyAudienceQuestionFilters = createSelector(getStudyFilters, filters => []);

export const getStudyQuestionFilters = createSelector(
	getStudyFilters,
	getSectionsWithQuestionNumbers,
	(filters, sectionWithNumbers) => {
		if (!filters || !filters.length) {
			return [];
		}

		const baseFilters = filters.map(filter => {
			// temporary cleanup on front-end for identical short name and question text
			let filterLabel = '';
			if (filter.filterLabel && filter.label) {
				filterLabel =
					filter.filterLabel === filter.label ||
					filter.filterLabel.replace(/(<([^>]+)>)/gi, '') === filter.label.replace(/(<([^>]+)>)/gi, '')
						? ''
						: filter.filterLabel;
			}

			const questionWithNumbers = sectionWithNumbers?.content?.map(section => section?.questions || []).flat();
			const questionWithNumber = questionWithNumbers?.find(question => question.id === filter.id);

			const topNLimit = filter?.settings?.find(setting => setting.label === 'top-n-limit')?.value;

			return {
				id: filter.id,
				type: filter.type,
				style: filter.style,
				topNLimit: filter.style === 'ranked' ? topNLimit : null,
				audienceUuid: filter.audienceUuid,
				label: misc.cleanHtmlString(filterLabel),
				title: misc.cleanHtmlString(filterLabel),
				description: misc.cleanHtmlString(filter.label),
				options: formatOptions(filter.options, sectionWithNumbers),
				attributes: filter.attributes
					? filter.attributes.map(attribute => ({
							id: attribute.id,
							label: attribute.label,
					  }))
					: [],
				// TODO - clean this up to only the first when API is ready
				isAgeFilter: filter.isAgeFilter || filter.style === 'custom-age' || filter.label === 'AGE',
				min: filter.min,
				max: filter.max,
				filterType: filter.filterType,
				questionCount: questionWithNumber?.studyQuestionNumber || null,
			};
		});

		return baseFilters;
	},
);

export const filterConfigData = createSelector(
	getStudyQuestionFilters,
	getStudyAudienceFilters,
	(baseFilters, audienceFilters) => {
		const configData = [];

		// Clean out any empty questions
		const itemsWithOptions = baseFilters.filter(i => i.options.length > 0 || i.isAgeFilter);

		// Audience questions filters
		const audienceLucidFilters = itemsWithOptions.filter(i => i.type === 'qualifier');
		const audienceQuestionFilters = itemsWithOptions.filter(i => i.audienceUuid !== null && i.type === 'custom');
		const audienceQuestions = [...audienceLucidFilters, ...audienceQuestionFilters];

		// Study Question Filters
		const studyQuestions = itemsWithOptions.filter(
			i => i.audienceUuid === null && i.type === 'custom' && i.filterType !== 'imported',
		);

		// Imported Question Filters
		const importedQuestions = itemsWithOptions
			.filter(i => i.audienceUuid === null && i.type === 'custom' && i.filterType === 'imported')
			.map((question, i) => ({
				...question,
				questionCount: i + 1,
			}));

		if (audienceFilters.length) {
			configData.push({
				id: 'audience',
				items: audienceFilters,
				title: 'Audiences',
			});
		}

		if (audienceQuestions.length) {
			configData.push({
				id: 'audienceQuestionFilters',
				items: audienceQuestions,
				title: 'Audiences Question Filters',
			});
		}

		if (baseFilters.length) {
			configData.push({
				id: 'studyFilters',
				items: studyQuestions,
				title: 'Study Filters',
			});
		}

		if (importedQuestions.length) {
			configData.push({
				id: 'importedFilters',
				items: importedQuestions,
				title: 'Imported Filters',
			});
		}

		return configData;
	},
);
