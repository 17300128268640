import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

const service = {
	getTerm: termId => axios.get(`${API_PATH}/terms/${termId}`),
	getAllTerms: (limit = 20, offset = 0, search = '', taxonomy = 'productTags,studyTags', isPublic = false) =>
		axios.get(
			`${API_PATH}/${
				isPublic ? 'public/' : ''
			}terms?limit=${limit}&offset=${offset}&taxonomy=${taxonomy}&search=${search}`,
		),
	getTerms: taxonomy => axios.get(`${API_PATH}/taxonomies/${taxonomy}/terms`).then(({ data }) => data.terms),
	createTerm: (taxonomyLabel, label, color) =>
		axios.post(`${API_PATH}/terms`, { taxonomyLabel, label, color }).then(({ data }) => data),

	updateTerm: (taxonomyLabel, id, { label, color }) =>
		axios.put(`${API_PATH}/terms/${id}`, { taxonomyLabel, label, color }).then(({ data }) => data),

	getStudyCategories: () => service.getTerms('studyCategories'),
	getPublicStudyCategories: () => service.getTerms('studyCategories'),
	getProductTags: () => service.getTerms('productTags'),
	getPublicProductTags: () => service.getTerms('productTags'),

	createProductTag: (label, color) => service.createTerm('productTags', label, color),

	deleteTerm: id => axios.delete(`${API_PATH}/terms/${id}`).then(({ data }) => data),
};

export default service;
