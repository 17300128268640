import * as actions from '../actions';

const INITIAL_STATE = {
	account: { status: 'loading', content: null },
	accountNav: 'edit',
	accounts: { loading: false, content: [] },
	accountSampleForm: { visible: false, loading: false, languages: [] },
	adminTemplates: { status: 'loading', content: [] },
	accountUsage: { status: 'loading', content: [] },
	adminSampleForm: { visible: false, loading: false, languages: [] },
	accountForm: { loading: false, success: false },
	accountTemplates: { status: 'loading', content: [] },
	assignTemplateModal: { visible: false, loading: false },
	error: null,
	features: { status: 'loading', content: [] },
	countries: [],
	countryLanguages: [],
	audiences: {},
	audience: {},
	customQualifiers: { loading: false, content: {} },
	customQualifierForm: { loading: false, success: false, visible: false },
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_ACCOUNT:
			return { ...state, account: { ...state.account, ...action.payload.account } };

		case actions.SET_ACCOUNT_NAVIGATION:
			return { ...state, accountNav: action.payload.page };

		case actions.SET_ADMIN_SAMPLE_FORM:
			return { ...state, adminSampleForm: { ...state.adminSampleForm, ...action.payload.formState } };

		case actions.SET_ACCOUNT_SAMPLE_FORM:
			return { ...state, accountSampleForm: { ...state.accountSampleForm, ...action.payload.formState } };

		case actions.SET_ACCOUNTS:
			return { ...state, accounts: { ...state.accounts, ...action.payload.accounts } };

		case actions.SET_ACCOUNT_FORM:
			return { ...state, accountForm: { ...state.accountForm, ...action.payload.accountForm } };

		case actions.SET_ADMIN_TEMPLATES:
			return { ...state, adminTemplates: { ...state.adminTemplates, ...action.payload.templates } };

		case actions.SET_ACCOUNT_TEMPLATES:
			return { ...state, accountTemplates: { ...state.accountTemplates, ...action.payload.templates } };

		case actions.SET_ACCOUNT_USAGE:
			return { ...state, accountUsage: { ...state.accountUsage, ...action.payload.accountUsage } };

		case actions.SET_ASSIGN_TEMPLATE_MODAL:
			return {
				...state,
				assignTemplateModal: { ...state.assignTemplateModal, ...action.payload.modalState },
			};

		case actions.SET_ERROR:
			return { ...state, error: action.payload.errMssg };

		case actions.SET_COUNTRIES:
			return { ...state, countries: action.payload.countries };

		case actions.SET_COUNTRY_LANGUAGES:
			return { ...state, countryLanguages: action.payload.countryLanguages };

		case actions.SET_AUDIENCE:
			return { ...state, audience: action.payload.audience };

		case actions.SET_AUDIENCES:
			return { ...state, audiences: action.payload.audiences };

		case actions.CLEAR_AUDIENCE:
			return { ...state, audience: {} };

		case actions.CLEAR_AUDIENCES:
			return { ...state, audiences: {} };

		case actions.FETCH_AUDIENCES:
			return { ...state, audiences: {} };

		case actions.CLEAR_ACCOUNT:
			return { ...state, account: { status: 'loading', content: null } };

		case actions.FETCH_CUSTOM_QUALIFIERS:
			return { ...state, customQualifiers: {} };

		case actions.SET_CUSTOM_QUALIFIERS:
			return { ...state, customQualifiers: { ...state.customQualifiers, ...action.payload.customQualifiers } };

		case actions.SET_CUSTOM_QUALIFIER_FORM:
			return {
				...state,
				customQualifierForm: { ...state.customQualifierForm, ...action.payload.customQualifierForm },
			};

		default:
			return state;
	}
};
