import { Component } from 'react';
import PropTypes from 'prop-types';

const AppID = '5f6ccfb107df486708d552f9';

function loadCannySDK() {
	!(function(w, d, i, s) {
		function l() {
			if (!d.getElementById(i)) {
				const f = d.getElementsByTagName(s)[0];
				const e = d.createElement(s);
				(e.type = 'text/javascript'),
					(e.async = !0),
					(e.src = 'https://canny.io/sdk.js'),
					f.parentNode.insertBefore(e, f);
			}
		}
		if (typeof w.Canny !== 'function') {
			var c = function() {
				c.q.push(arguments);
			};
			(c.q = []),
				(w.Canny = c),
				d.readyState === 'complete'
					? l()
					: w.attachEvent
					? w.attachEvent('onload', l)
					: w.addEventListener('load', l, !1);
		}
	})(window, document, 'canny-jssdk', 'script');
}

export default class CannyContainer extends Component {
	static propTypes = {
		user: PropTypes.object,
		children: PropTypes.node,
	};

	componentDidMount() {
		loadCannySDK();

		const { user } = this.props;

		if (!user || !user.uuid) {
			return;
		}

		let name = user.firstName;
		if (!name) {
			name = user.email.substring(0, user.email.indexOf('@'));
		}

		Canny('identify', {
			appID: AppID,
			user: {
				id: user.uuid,
				email: user.email,
				name,
			},
		});
	}

	render() {
		const { children } = this.props;
		return children;
	}
}
