import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import DownloadIcon from 'src/components/icons/Download';
import { Iconof } from '@upsiide/ui-components';

const className = 'language-dropdown';
const el = (name, mod) => cn(className, name, mod);

class SelectLanguagePopper extends React.Component {
	renderLanguageOptions = () => {
		const { languages, allLanguages, pickLanguage, selectedLanguage, selectedAudience } = this.props;

		const languagesThatAreAttachedToStudy = [];
		languages.forEach(languageCode => {
			let country = null;
			const languageObject = allLanguages.find(l => {
				if (l.languageCode === languageCode) {
					return l;
				}
				country = l.countries.find(c => c.code === languageCode);
				if (country) {
					return l;
				}

				return false;
			});

			if (languageObject) {
				let newLanguage = { ...languageObject };
				// Country-specific overrides
				if (country) {
					newLanguage = { ...newLanguage, languageCode, label: `${newLanguage.label} (${country.label})` };
				}
				languagesThatAreAttachedToStudy.push(newLanguage);
			}
		});

		const results = languagesThatAreAttachedToStudy.map(lang => (
			<button
				type="button"
				className={[
					el('option'),
					(!selectedAudience || Object.keys(selectedAudience).length === 0) &&
					lang.languageCode === selectedLanguage
						? el('selected')
						: null,
				].join(' ')}
				onClick={() => {
					pickLanguage(lang.languageCode);
				}}
				key={lang.languageCode}
			>
				{(!selectedAudience || Object.keys(selectedAudience).length === 0) &&
				lang.languageCode === selectedLanguage ? (
					<Iconof icon="done" color="green-900" />
				) : (
					<div style={{ width: 20 }} />
				)}
				{lang.label}
			</button>
		));
		return results;
	};

	renderTranslationsOptions = () => {
		const { languages, allLanguages, setTranslationsModal, showImportTranslationsOption } = this.props;
		const languagesThatAreAttachedToStudy = allLanguages.filter(l => languages.indexOf(l.languageCode) > -1);
		const renderImportTranslationsOption =
			showImportTranslationsOption &&
			languagesThatAreAttachedToStudy &&
			languagesThatAreAttachedToStudy.length > 0;
		if (renderImportTranslationsOption) {
			const importTranslationsText = 'Import Translations';
			return (
				<button type="button" className={el('option translations')} onClick={setTranslationsModal}>
					<DownloadIcon />
					<span>{importTranslationsText}</span>
				</button>
			);
		}
		return false;
	};

	renderAudienceOptions = () => {
		const { audiences, pickAudience, selectedAudience } = this.props;
		const results = audiences.map(audience => (
			<button
				type="button"
				className={[
					el('option'),
					selectedAudience && audience.uuid === selectedAudience.uuid ? el('selected') : null,
				].join(' ')}
				onClick={() => {
					pickAudience(audience);
				}}
				key={audience.uuid}
			>
				{selectedAudience && audience.uuid === selectedAudience.uuid ? (
					<Iconof icon="done" color="green-900" />
				) : (
					<div style={{ width: 20 }} />
				)}
				<span className={el('option-audience-name')}>
					{audience.name ||
						(audience?.languageCode && audience?.provider === 'LUCID'
							? `${audience.countryLanguage.countryLabel} - ${audience.countryLanguage.languageLabel}`
							: 'Untitled Group')}
				</span>
			</button>
		));
		return results;
	};

	renderAudienceSelect = () => (
		<div className={el('audience-select')}>
			<div className={el('option disabled')}>Or choose from audience</div>
			<div className={el('options')}>{this.renderAudienceOptions()}</div>
		</div>
	);

	render() {
		const { headingText, audiences } = this.props;
		const translationsHeadingText = headingText || 'Languages';
		return (
			<div className={`${el('dropdown')} ${audiences && el('audience-dropdown')}`}>
				<div className={el('option disabled')}>{translationsHeadingText}</div>
				<div className={el('options')}>
					{this.renderLanguageOptions()}
					{this.renderTranslationsOptions()}
				</div>
				{audiences && audiences.length > 0 && this.renderAudienceSelect()}
			</div>
		);
	}
}

SelectLanguagePopper.propTypes = {
	languages: PropTypes.any,
	allLanguages: PropTypes.any,
	setTranslationsModal: PropTypes.any,
	showImportTranslationsOption: PropTypes.any,
	headingText: PropTypes.any,
	pickLanguage: PropTypes.any,
	pickAudience: PropTypes.func,
	selectedLanguage: PropTypes.string,
	selectedAudience: PropTypes.object,
	audiences: PropTypes.array,
};

export default SelectLanguagePopper;
