import React from 'react';
import cn from 'src/utilities/bem-cn';
import doc from 'public/images/fileManagement/doc_icon.svg';
import img from 'public/images/fileManagement/image_icon.svg';
import mov from 'public/images/fileManagement/video_icon.svg';

import './styles.scss';

const map = {
	'doc': doc,
	'img': img,
	'mov': mov,
};
const className = 'file-icon';
const el = (name, mod) => cn(className, name, mod);
const FileIcon = ({ type = 'doc' }) => (
	<div className={className}>
		<img src={map[type]} className={el('icon')} />
	</div>

);

export default FileIcon;
