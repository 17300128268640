import React from 'react';
import PropTypes from 'prop-types';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import cn from 'src/utilities/bem-cn';
import Aggressive from 'src/components/icons/Emojis/aggressive.svg';
import Amazed from 'src/components/icons/Emojis/amazed.svg';
import Anger from 'src/components/icons/Emojis/anger.svg';
import Annoyed from 'src/components/icons/Emojis/annoyed.svg';
import Anticipation from 'src/components/icons/Emojis/anticipation.svg';
import Anxious from 'src/components/icons/Emojis/anxious.svg';
import Curious from 'src/components/icons/Emojis/curious.svg';
import Cynicism from 'src/components/icons/Emojis/cynicism.svg';
import Despair from 'src/components/icons/Emojis/despair.svg';
import Disapproval from 'src/components/icons/Emojis/disapproval.svg';
import Disbelief from 'src/components/icons/Emojis/disbelief.svg';
import Disgust from 'src/components/icons/Emojis/disgust.svg';
import Fear from 'src/components/icons/Emojis/fear.svg';
import Guilt from 'src/components/icons/Emojis/guilt.svg';
import Hope from 'src/components/icons/Emojis/hope.svg';
import Jealous from 'src/components/icons/Emojis/jealous.svg';
import Joy from 'src/components/icons/Emojis/joy.svg';
import Love from 'src/components/icons/Emojis/love.svg';
import Optimistic from 'src/components/icons/Emojis/optimistic.svg';
import Proud from 'src/components/icons/Emojis/proud.svg';
import Regret from 'src/components/icons/Emojis/regret.svg';
import Sad from 'src/components/icons/Emojis/sad.svg';
import Shocked from 'src/components/icons/Emojis/shocked.svg';
import Surprised from 'src/components/icons/Emojis/surprised.svg';
import Trust from 'src/components/icons/Emojis/trust.svg';

import './styles.scss';

const className = 'emoji-option';
const el = (name, mod) => cn(className, name, mod);

const emojiImageMap = {
	aggressive: Aggressive,
	annoyed: Annoyed,
	disgust: Disgust,
	regret: Regret,
	disapproval: Disapproval,
	cynicism: Cynicism,
	disbelief: Disbelief,
	fear: Fear,
	anxious: Anxious,
	optimistic: Optimistic,
	jealous: Jealous,
	curious: Curious,
	anticipation: Anticipation,
	guilt: Guilt,
	surprised: Surprised,
	despair: Despair,
	proud: Proud,
	sad: Sad,
	anger: Anger,
	joy: Joy,
	love: Love,
	amazed: Amazed,
	hope: Hope,
	trust: Trust,
	shocked: Shocked,
};

const Emoji = ({ label, emojiName, tooltip }) => (
	<div className={className}>
		{tooltip ? (
			<Tooltip
				id={`${className}__tooltip-${Math.ceil(Math.random() * 1000)}`}
				className={el('tooltip')}
				animation="shift"
				animationFill={false}
				trigger="mouseenter"
				position="top"
				theme="basic-text-tooltip"
				arrow={false}
				html={tooltip}
				popperOptions={{
					modifiers: {
						preventOverflow: {
							enabled: false,
							boundariesElement: 'window',
							escapeWithReference: true,
						},
					},
				}}
			>
				<div
					style={{
						backgroundImage: `url(${
							emojiName in emojiImageMap ? emojiImageMap[emojiName] : emojiImageMap[label]
						})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
					}}
				/>
			</Tooltip>
		) : (
			<div
				style={{
					backgroundImage: `url(${
						emojiName in emojiImageMap ? emojiImageMap[emojiName] : emojiImageMap[label]
					})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
				}}
			/>
		)}
	</div>
);

Emoji.propTypes = {
	label: PropTypes.string,
	emojiName: PropTypes.string,
	tooltip: PropTypes.string,
};

export default Emoji;
