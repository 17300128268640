import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Cookies from 'src/utilities/cookies';
import MobileMenuContainer, { MobileMenuHeader } from 'src/components/Layout/MobileMenuContainer';
import { MenuAnimationPanels, MenuAnimationPanel } from 'src/components/Layout/MenuAnimationPanels';
import useHandleSetAccountUUID from 'src/hooks/useHandleSetAccountUUID';
import { Iconof, Tag, FormField } from '@upsiide/ui-components';
import { useSelector, useDispatch } from 'react-redux';
import { getAccountData, getUserProfileData } from 'src/domains/selectors';
import isNil from 'lodash/isNil';
import * as accountActions from 'src/domains/account/actions';

import './styles.scss';

const className = 'mobile-account-menu';
const el = (name, mod) => cn(className, name, mod);

const MobileAccountMenu = ({ setShowMenu, goToSupport, logout }) => {
	const dispatch = useDispatch();

	const handleSetAccountUUID = useHandleSetAccountUUID();

	const [showAccounts, setShowAccounts] = useState(false);
	const [searchString, setSearchString] = useState('');

	const upsiideAccountToken = Cookies.getUpsiideAccountToken();

	const { accounts, account } = useSelector(getAccountData);
	const { user } = useSelector(getUserProfileData);

	const dynamicSort = property => {
		let sortOrder = 1;
		if (property[0] === '-') {
			sortOrder = -1;
			property = property.substr(1);
		}
		return (a, b) => {
			if (sortOrder === -1) {
				return b[property].localeCompare(a[property]);
			}
			return a[property].localeCompare(b[property]);
		};
	};

	let accountOptions = accounts.content.map(a => {
		const hasIssue = a.chargifyCustomerId && a.subscriptionState !== 'active';
		return {
			label: hasIssue ? `⚠ ${a.name}` : a.name,
			value: { uuid: a.uuid, isChargify: !isNil(a.chargifyCustomerId) },
			showTooltip: a.isAccountDisabled,
			permission: a.permission,
		};
	});

	accountOptions = accountOptions.sort(dynamicSort('label'));

	const filteredAccounts =
		searchString?.length > 0
			? accountOptions.filter(account => account.label.toLowerCase().includes(searchString.toLowerCase()))
			: accountOptions;

	const handleChangeAccount = value => {
		const { uuid, isChargify } = value;
		// Set the new cookie
		Cookies.setUpsiideAccountToken(uuid);

		// Prevent a 401 error on the initial fetch account call due to a missing x-account-uuid
		if (uuid !== undefined || uuid !== null) {
			handleSetAccountUUID(uuid);
			dispatch(accountActions.fetchAccount(uuid, false));
			if (isChargify) dispatch(accountActions.fetchChargifySubscriptions());
		}
	};

	const values = upsiideAccountToken
		? accountOptions.filter(option => option.value.uuid === upsiideAccountToken)
		: accountOptions;

	useEffect(() => {
		// on load, set document body overflow to hidden to avoid scroll behind
		document.body.style.overflow = 'hidden';

		return () => {
			// on unmount, set document body overflow to auto
			document.body.style.overflow = 'initial';
		};
	}, []);

	return (
		<MobileMenuContainer>
			<MenuAnimationPanels state={showAccounts ? 'secondary' : 'main'}>
				<MenuAnimationPanel>
					<MobileMenuHeader onClose={() => setShowMenu(false)} />

					<div className={el('mobile-account-menu--content')}>
						<div className={el('mobile-account-menu--heading')}>
							<Iconof icon="user" />
							<span>{`${user?.firstName || ''} ${user?.lastName || ''}`}</span>
						</div>

						<div className={el('mobile-account-menu--menu-list')}>
							<div className={el('mobile-account-menu--menu-list-item', 'disabled')}>
								<span>Account Settings</span>
								<Tag color="default" label="Desktop Only" />
							</div>

							<div className={el('mobile-account-menu--menu-list-item', 'disabled')}>
								<span>Profile</span>
								<Tag color="default" label="Desktop Only" />
							</div>

							<div className={el('mobile-account-menu--menu-list-item-divider')} />

							<div
								className={el('mobile-account-menu--menu-list-item')}
								role="button"
								tabIndex={0}
								onKeyDown={() => {}}
								onClick={() => goToSupport()}
							>
								<span>Support</span>
							</div>

							<a
								href="https://news.upsiide.com"
								className={el('mobile-account-menu--menu-list-item')}
								target="_blank"
								rel="noopener noreferrer"
							>
								<span>✨ What's New</span>
							</a>
						</div>

						<div className={el('mobile-account-menu--heading')}>
							<Iconof icon="group_senior" />
							<span>Account Selector</span>
						</div>

						<div className={el('mobile-account-menu--menu-list')}>
							<div
								className={el('mobile-account-menu--menu-list-item', 'space-between')}
								onClick={() => setShowAccounts(true)}
								onKeyDown={() => {}}
								role="button"
								tabIndex={0}
							>
								<span>{values[0]?.label}</span>

								<Iconof icon="chevron_right" />
							</div>
						</div>
					</div>

					<div className={el('mobile-account-menu--footer')}>
						<div
							className={el('mobile-account-menu--menu-list-item')}
							onClick={() => {
								setShowMenu(false);
								// Remove the stored cookie before logging out
								Cookies.removeUpsiideAccountToken();
								// Logout
								logout();
							}}
							onKeyDown={() => {}}
							role="button"
							tabIndex={0}
						>
							<span>Logout</span>
						</div>
					</div>
				</MenuAnimationPanel>

				<MenuAnimationPanel>
					<MobileMenuHeader onBack={() => setShowAccounts(false)} onClose={() => setShowMenu(false)}>
						<div className={el('mobile-account-menu--menu-list-item', 'small-padding')}>
							<span className={el('mobile-account-menu--menu-list-item-green-header')}>
								Choose another account
							</span>
						</div>
						<div className={el('mobile-account-menu--menu-list-item')}>
							<FormField
								autoComplete="one-time-code"
								wrapperClassName={el('search-input')}
								startAdornment={
									<div className={el('search-start-adornment')}>
										<Iconof size="default" icon="search" />
									</div>
								}
								name="smartSearch"
								placeholder="Search"
								onChange={e => setSearchString(e.target.value)}
							/>
						</div>
					</MobileMenuHeader>

					<div className={el('mobile-account-menu--content')}>
						<div className={el('mobile-account-menu--menu-list')}>
							{filteredAccounts?.map(account => (
								<div
									onClick={() => {
										handleChangeAccount(account.value);
										setShowMenu(false);
									}}
									className={el('mobile-account-menu--menu-list-item')}
									key={account.value.uuid}
									onKeyDown={() => {}}
									role="button"
									tabIndex={0}
								>
									{account.value.uuid === values[0]?.value.uuid ? (
										<svg
											className={el('mobile-account-menu--menu-list-item-checkmark')}
											width="14"
											height="12"
											viewBox="0 0 14 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12.7033 0.87918C12.4142 0.595775 11.9515 0.595775 11.6623 0.87918L4.61331 7.92819L2.38262 5.71236C2.24768 5.56859 2.05929 5.48703 1.86212 5.48703C1.66495 5.48703 1.47655 5.56859 1.34162 5.71236L0.553442 6.50054C0.412675 6.64016 0.333496 6.83021 0.333496 7.02847C0.333496 7.22674 0.412675 7.41679 0.553442 7.55641L4.0482 11.0809C4.18314 11.2247 4.37153 11.3062 4.5687 11.3062C4.76587 11.3062 4.95426 11.2247 5.0892 11.0809L13.4469 2.72322C13.5876 2.58361 13.6668 2.39356 13.6668 2.19529C13.6668 1.99703 13.5876 1.80698 13.4469 1.66736L12.7033 0.87918Z"
												fill="#28B681"
											/>
										</svg>
									) : null}
									<span>{account.label}</span>
								</div>
							))}
						</div>
					</div>
				</MenuAnimationPanel>
			</MenuAnimationPanels>
		</MobileMenuContainer>
	);
};
MobileAccountMenu.propTypes = {
	setShowMenu: PropTypes.func,
	goToSupport: PropTypes.func,
	logout: PropTypes.func,
};

export default MobileAccountMenu;
