import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const TrashWithLines = ({ className, fill }) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={`trash-with-lines ${className}`}
	>
		<path
			d="M2.67606 4.53763H3.53803V12.7419C3.53803 13.3408 3.75171 13.9152 4.13207 14.3386C4.51242 14.7621 5.02829 15 5.5662 15H10.4338C10.9717 15 11.4876 14.7621 11.8679 14.3386C12.2483 13.9152 12.462 13.3408 12.462 12.7419V4.53763H13.3239C13.5032 4.53763 13.6752 4.45833 13.802 4.31718C13.9288 4.17602 14 3.98457 14 3.78495C14 3.58532 13.9288 3.39387 13.802 3.25272C13.6752 3.11156 13.5032 3.03226 13.3239 3.03226H10.3155V2.50538C10.3155 2.10613 10.173 1.72323 9.91947 1.44091C9.6659 1.1586 9.32198 1 8.96338 1H7.03662C6.67802 1 6.3341 1.1586 6.08053 1.44091C5.82696 1.72323 5.68451 2.10613 5.68451 2.50538V3.03226H2.67606C2.49676 3.03226 2.3248 3.11156 2.19801 3.25272C2.07123 3.39387 2 3.58532 2 3.78495C2 3.98457 2.07123 4.17602 2.19801 4.31718C2.3248 4.45833 2.49676 4.53763 2.67606 4.53763ZM11.1099 12.7419C11.1099 12.9416 11.0386 13.133 10.9118 13.2742C10.7851 13.4153 10.6131 13.4946 10.4338 13.4946H5.5662C5.3869 13.4946 5.21494 13.4153 5.08815 13.2742C4.96137 13.133 4.89014 12.9416 4.89014 12.7419V4.53763H11.1099V12.7419ZM7.05352 2.48656H8.96338V3.01344H7.03662L7.05352 2.48656Z"
			fill={fill}
		/>
		<path
			d="M9.26785 12.5155C9.44715 12.5155 9.61911 12.4362 9.7459 12.2951C9.87268 12.1539 9.94391 11.9624 9.94391 11.7628V6.13648C9.94391 5.93685 9.87268 5.7454 9.7459 5.60425C9.61911 5.46309 9.44715 5.38379 9.26785 5.38379C9.08855 5.38379 8.91659 5.46309 8.78981 5.60425C8.66302 5.7454 8.5918 5.93685 8.5918 6.13648V11.7628C8.5918 11.9624 8.66302 12.1539 8.78981 12.2951C8.91659 12.4362 9.08855 12.5155 9.26785 12.5155Z"
			fill={fill}
		/>
		<path
			d="M6.73221 12.5155C6.91151 12.5155 7.08347 12.4362 7.21025 12.2951C7.33704 12.1539 7.40826 11.9624 7.40826 11.7628V6.13648C7.40826 5.93685 7.33704 5.7454 7.21025 5.60425C7.08347 5.46309 6.91151 5.38379 6.73221 5.38379C6.55291 5.38379 6.38095 5.46309 6.25416 5.60425C6.12738 5.7454 6.05615 5.93685 6.05615 6.13648V11.7628C6.05615 11.9624 6.12738 12.1539 6.25416 12.2951C6.38095 12.4362 6.55291 12.5155 6.73221 12.5155Z"
			fill={fill}
		/>
	</svg>
);

TrashWithLines.propTypes = {
	className: PropTypes.string,
	fill: PropTypes.string,
};

export default TrashWithLines;
