const prefix = 'study';

export const FETCH_SECTIONS = `${prefix}/FETCH_SECTIONS`;
export const fetchSections = (studyId, refetch = false, loadFirst = false, sectionId = null, preloadFilters = []) => ({
	type: FETCH_SECTIONS,
	payload: { studyId, refetch, loadFirst, sectionId, preloadFilters },
});

export const FETCH_SECTION = `${prefix}/FETCH_SECTION`;
export const fetchSection = (sectionId, filters = [], refetch = false) => ({
	type: FETCH_SECTION,
	payload: { sectionId, filters, refetch },
});

export const FETCH_IDEA_MAP = `${prefix}/FETCH_IDEA_MAP`;
export const fetchIdeaMapData = (sectionId, filters = [], refetch = false) => ({
	type: FETCH_IDEA_MAP,
	payload: { sectionId, filters, refetch },
});

export const REFETCH_CURRENT_SECTION = `${prefix}/REFETCH_CURRENT_SECTION`;
export const refetchCurrentSection = (filters = [], quartile = 0.7) => ({
	type: REFETCH_CURRENT_SECTION,
	payload: { filters, quartile },
});

export const SET_SECTIONS = `${prefix}/SET_SECTIONS`;
export const setSections = sections => ({ type: SET_SECTIONS, payload: { sections } });

export const SET_SECTION = `${prefix}/SET_SECTION`;
export const setSection = currentSection => ({ type: SET_SECTION, payload: { currentSection } });

export const SET_SECTION_REPORT_DATA = `${prefix}/SET_SECTION_REPORT_DATA`;
export const setSectionReportData = reportData => ({ type: SET_SECTION_REPORT_DATA, payload: { reportData } });

export const FETCH_SECTION_RESPONSE_DATA = `${prefix}/FETCH_SECTION_RESPONSE_DATA`;
export const fetchSectionResponseData = sectionId => ({ type: FETCH_SECTION_RESPONSE_DATA, payload: { sectionId } });

export const SET_SECTION_RESPONSE_DATA = `${prefix}/SET_SECTION_RESPONSE_DATA`;
export const setSectionResponseData = (sectionId, data) => ({
	type: SET_SECTION_RESPONSE_DATA,
	payload: { sectionId, data },
});

export const SET_SECTION_PRODUCTS = `${prefix}/SET_SECTION_PRODUCTS`;
export const setSectionProducts = (sectionId, products) => ({
	type: SET_SECTION_PRODUCTS,
	payload: { sectionId, products },
});

export const SORT_SECTION_PRODUCTS = `${prefix}/SORT_SECTION_PRODUCTS`;
export const sortSectionProducts = (productSortBy, productSortDescending) => ({
	type: SORT_SECTION_PRODUCTS,
	payload: { productSortBy, productSortDescending },
});

export const FETCH_QUESTION_RESPONSE_DATA = `${prefix}/FETCH_QUESTION_RESPONSE_DATA`;
export const fetchQuestionResponseData = (questionId, filters, data) => ({
	type: FETCH_QUESTION_RESPONSE_DATA,
	payload: { questionId, filters, data },
});
