import * as actions from '../actions';

const INITIAL_STATE = {
	loading: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_LOADING:
			return { ...state, loading: action.payload.loading };

		default:
			return state;
	}
};
