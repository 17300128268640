import { reducerUpsertHelper } from '../../../utilities/reducers';
import * as actions from '../actions';

const INITIAL_STATE = {
	clients: [],
	searchQuery: '',
	// studies: { content: [], totals: [], pagination: {}, loading: false },
	studies: {},
	products: {},
	studyCategories: { content: null, loading: false },
	studyFilters: {},
	error: null,
	viewMode: 'grid',
	showMoveStudyModal: null,
	accountUuid: null,
	clientsFromAccount: [],
	loading: 0,
	// Filters
	languages: null,
	geographies: [],
	collections: [],
	studyTags: {},
	productTags: {},
	// utils
	deleteStudyState: {},
	createStudyLoading: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_CLIENTS:
			return { ...state, clients: action.payload.clients };

		case actions.SET_LANGUAGES:
			return { ...state, languages: action.payload.languages };

		case actions.SET_GEOGRAPHIES:
			return { ...state, geographies: action.payload.geographies };

		case actions.SET_SEARCH_QUERY:
			return { ...state, searchQuery: action.payload.searchQuery };

		case actions.SET_STUDIES:
			return {
				...state,
				studies: { ...state.studies, ...action.payload.studies },
			};

		case actions.SET_STUDY_CATEGORIES:
			return {
				...state,
				studyCategories: {
					...state.studyCategories,
					...action.payload.studyCategories,
				},
			};

		case actions.SET_STUDY_FILTERS:
			return { ...state, studyFilters: action.payload.studyFilters };

		case actions.SET_ERROR:
			return { ...state, error: action.payload.error };

		case actions.SET_VIEW_MODE:
			return { ...state, viewMode: action.payload.viewMode };

		case actions.SET_SHOW_MOVE_STUDY_MODAL:
			return { ...state, showMoveStudyModal: action.payload.showMoveStudyModal };

		case actions.FETCH_CLIENTS_FROM_ACCOUNT:
			return { ...state, accountUuid: action.payload.accountUuid };

		case actions.SET_CLIENTS_FROM_ACCOUNT:
			return { ...state, clientsFromAccount: action.payload.clientsFromAccount };

		case actions.INC_LOADING:
			return { ...state, loading: state.loading + 1 };

		case actions.DEC_LOADING:
			return { ...state, loading: state.loading - 1 };

		case actions.UPSERT_STUDY_TAGS: {
			const items = reducerUpsertHelper(state.studyTags, action.payload.studyTags, 'id');
			return { ...state, studyTags: items };
		}

		case actions.UPSERT_PRODUCT_TAGS: {
			const items = reducerUpsertHelper(state.productTags, action.payload.productTags, 'id');
			return { ...state, productTags: items };
		}

		case actions.UPSERT_STUDY: {
			const items = reducerUpsertHelper(state.studies, action.payload, 'id');

			return {
				...state,
				studies: items,
			};
		}

		case actions.UPSERT_PRODUCT: {
			const items = reducerUpsertHelper(state.products, action.payload, 'id');

			return {
				...state,
				products: items,
			};
		}

		case actions.UPSERT_COLLECTIONS: {
			const items = reducerUpsertHelper(state.collections, action.payload.collections, 'id');
			return { ...state, collections: items };
		}

		case actions.RESET_STUDIES: {
			return {
				...state,
				studies: {},
			};
		}

		case actions.RESET_STUDY_TAGS: {
			return {
				...state,
				studyTags: {},
			};
		}

		case actions.RESET_PRODUCT_TAGS: {
			return {
				...state,
				productTags: {},
			};
		}

		case actions.RESET_COLLECTIONS: {
			return {
				...state,
				collections: {},
			};
		}

		case actions.SET_DELETE_STUDY_STATE: {
			return {
				...state,
				deleteStudyState: action.payload,
			};
		}
		case actions.SET_CREATE_STUDY_LOADING:
			return { ...state, createStudyLoading: action.payload };

		default:
			return state;
	}
};
