import React from 'react';
import cn from 'src/utilities/bem-cn';
import Loader from '../Loader';
import './styles.scss';

const className = 'centered-loader';
const el = name => cn(className, name);

const CenteredLoader = ({ text }) => (
	<div className={el('loader-wrapper')}>
		<Loader centered />
		<div className={el('loader-text')}>{text || ' '}</div>
	</div>
);

export default CenteredLoader;
