import React, { forwardRef } from 'react';
import { Iconof } from '@upsiide/ui-components';
import PropTypes from 'prop-types';
import { element } from 'src/utilities/bem-cn';
import TrashWithLines from 'src/components/icons/TrashWithLines';

import './styles.scss';

const className = 'asset-gallery-asset';
const el = element(className);

const AssetGalleryAsset = forwardRef(
	({ id, url, alt, title, linkToAuthor, border, selected, onSelect, onDelete }, ref) => (
		<div
			ref={ref}
			className={`${className} ${el('image', selected ? 'selected' : '')} ${el(
				'image',
				border ? 'with-border' : '',
			)}`}
			onClick={() => onSelect(id)}
			role="button"
			tabIndex={0}
			onKeyDown={null}
		>
			<img src={url} alt={alt} />

			{title && (
				<div className={el('image-title-container')}>
					{linkToAuthor ? (
						<a href={linkToAuthor} target="__blank" className={el('image-title link-color')}>
							{title}
						</a>
					) : (
						<div className={el('image-title')}>{title}</div>
					)}
				</div>
			)}

			{selected && <Iconof className={`${el('checkmark')}`} icon="checkmark_circle" size="large" />}

			{onDelete && (
				<span
					className={`${el('delete')}`}
					role="button"
					onClick={onDelete}
					tabIndex={0}
					onKeyDown={e => {
						if (e.key === 'Enter') onDelete();
					}}
				>
					<TrashWithLines fill="#6C757D" />
				</span>
			)}
		</div>
	),
);

AssetGalleryAsset.displayName = 'AssetGalleryAsset';

AssetGalleryAsset.propTypes = {
	id: PropTypes.any,
	url: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.string,
	linkToAuthor: PropTypes.string,
	selected: PropTypes.bool,
	onSelect: PropTypes.func,
	border: PropTypes.bool,
	onDelete: PropTypes.any,
};

export default AssetGalleryAsset;
