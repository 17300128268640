import React, {  useMemo } from 'react';

import cn from 'src/utilities/bem-cn';
import QuestionsViewMode from 'src/components/shared/QuestionsViewMode';
import PropTypes from 'prop-types';

import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'questions';

const Questions = ({ section, sections, selectedLanguage }) => {
	const questions = useMemo(() => section?.questions || [], [section]);
	const getLangClass = lang => (lang === selectedLanguage ? 'lang_active' : 'lang');

	const renderLangTags = () => {
		const languages = questions[0]?.translations?.map(({ languageCode }) => languageCode);

		const langTags = [];

		if (languages?.length >= 1) {
			langTags.push(
				<span key="language-tag-1" className={el(getLangClass(languages[0]))}>
					{languages[0]}
				</span>,
			);
		}

		if (languages?.length > 1) {
			langTags.push(
				<span key="language-tag-2" className={el('lang')}>
					•
				</span>,
			);
			langTags.push(
				<span key="language-tag-3" className={el(getLangClass(languages[1]))}>
					{languages[1]}
				</span>,
			);
			if (languages?.length > 2) {
				langTags.push(
					<span key="language-tag-4" className={el('lang')}>
						+{languages.length - 2}
					</span>,
				);
			}
		}

		return <div className={el('tag')}>{langTags.map(lang => lang)}</div>;
	};

	const renderEmptyState = () => (
		<>
			<span className={el('sub-title')}> Questions</span>
			<span className={el('title')}> {section?.name || 'Study Questions'}</span>
			<div className={el('empty')}>
				<span className={el('empty-title')}>This section is empty</span>
				<span className={el('empty-subtitle')}>
					You can add your own questions once you start using this template.
				</span>
			</div>
		</>
	);

	return (
		<div className={el('overview')}>
			{!questions?.length ? (
				renderEmptyState()
			) : (
				<>
					<span className={el('sub-title')}> Questions</span>
					<span className={el('title')}> {section?.name || 'Study Questions'}</span>
					<div className={el('content')}>
						<QuestionsViewMode
							questions={questions}
							section={section}
							sections={sections}
							selectedLanguage={selectedLanguage}
							renderLangTags={renderLangTags}
						/>
					</div>
				</>
			)}
		</div>
	);
};

Questions.displayName = 'Questions';
Questions.propTypes = {
	section: PropTypes.any,
	sections: PropTypes.any,
	selectedLanguage: PropTypes.any,
};

export default Questions;
