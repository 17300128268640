import React, { useMemo } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';

import './styles.scss';
import ProductCard from 'src/components/manage/products/ProductCard';

const el = (name, mod) => cn(className, name, mod);
const className = 'template-preview-product-cards-overview';

const ProductsOverview = ({ study, section, selectedLanguage }) => {
	const products = useMemo(() => {
		const sectionSettings = section?.settings;
		return section?.products?.map(product => {
			let imageSize = '';
			let imageCropping = '';
			let ideaScreenLayout = '';
			if (sectionSettings) {
				ideaScreenLayout = sectionSettings.find(s => s.label === 'idea_screen_layout');
				imageCropping = sectionSettings.find(s => s.label === 'image_cropping');
				imageSize = sectionSettings.find(s => s.label === 'image_size');

				if (ideaScreenLayout) {
					ideaScreenLayout = ideaScreenLayout.value;
					imageSize =
						// eslint-disable-next-line no-nested-ternary
						ideaScreenLayout === 'imageOnly'
							? ''
							: imageSize && imageSize.value
							? imageSize.value
							: 'size-medium';
				}
				if (imageCropping) {
					imageCropping = imageCropping.value;
				}
			}

			return (
				<div className={el(`product-card-overlay ${ideaScreenLayout}`)}>
					<ProductCard
						key={`product-card-preview-modal-id-${product?.id}`}
						study={study}
						language={selectedLanguage}
						product={product}
						imageSize={imageSize}
						imageCropping={imageCropping}
						ideaScreenLayout={ideaScreenLayout}
					/>
				</div>
			);
		});
	}, [section, study, selectedLanguage]);

	return <div className={className}>{products}</div>;
};

ProductsOverview.displayName = 'ProductsOverview';
ProductsOverview.propTypes = {
	section: PropTypes.any,
	study: PropTypes.any,
	selectedLanguage: PropTypes.any,
};

export default ProductsOverview;
