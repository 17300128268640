import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import ResponsiveModal from 'src/components/shared/ResponsiveModal';

import './styles.scss';

const className = 'demographic-prelaunch-modal';
const el = (name, mod) => cn(className, name, mod);

const AudiencePreLaunchCheckModal = ({ showModal, closeModal, launchBYOAudience }) => {
	const [show, setShow] = useState(showModal);

	useEffect(() => setShow(showModal), [showModal]);

	return (
		<ResponsiveModal
			customClass={el('responsive-modal')}
			show={show}
			maxWidth={500}
			onClose={() => {
				setShow(false);
				closeModal();
			}}
			headerLabel="Launching Your Web Link"
			closeLabel="Go Back"
			confirmLabel="Continue"
			onConfirm={() => {
				launchBYOAudience(true);
				setShow(false);
				closeModal();
			}}
		>
			<div className={el('container')}>
				<p>
					This web link's language cannot be changed after it has been launched.
				</p>
				<p> Do you wish to continue?</p>
			</div>
		</ResponsiveModal>
	);
};
AudiencePreLaunchCheckModal.propTypes = {
	showModal: PropTypes.bool,
	closeModal: PropTypes.func,
	launchBYOAudience: PropTypes.func,
};

export default AudiencePreLaunchCheckModal;
