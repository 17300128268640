import React from 'react';

import './styles.scss';

export default props => (
	<svg
		className="upsiide-logo"
		width="40"
		height="40"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.5238 11.9045V1.57056H0V17.3797C0 29.9228 7.99706 39.9999 21.0785 39.9999C30.4842 39.9999 36.8672 33.0054 38.5253 24.4696C38.5253 24.4696 34.8569 28.088 28.3052 28.088C19.1709 28.088 12.5238 23.2146 12.5238 11.9412"
			fill="#26D9A1"
		/>
		<path
			d="M29.7285 0C31.76 0 33.7459 0.602639 35.435 1.7317C37.1241 2.86075 38.4407 4.46551 39.2181 6.34306C39.9955 8.22062 40.1989 10.2866 39.8026 12.2798C39.4062 14.273 38.428 16.1039 36.9915 17.5409C35.555 18.9779 33.7248 19.9565 31.7323 20.353C29.7399 20.7495 27.6746 20.546 25.7978 19.7683C23.9209 18.9906 22.3167 17.6736 21.1881 15.9838C20.0594 14.2941 19.457 12.3075 19.457 10.2752C19.457 7.55007 20.5392 4.93652 22.4655 3.00954C24.3917 1.08256 27.0043 0 29.7285 0Z"
			fill="#26D9A1"
		/>
	</svg>
);
