/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import filter from 'lodash/filter';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import InterlockQuotasIcon from 'public/images/audience/interlock-quotas-icon.png';
import CONSTANTS from 'src/config/constants';
import * as services from 'src/services';
import cn from 'src/utilities/bem-cn';
import { useIsBlueprint } from 'src/hooks';
import LockOpen from 'src/components/icons/LockOpen';
import LockClosed from 'src/components/icons/LockClosed';
import X from 'src/components/icons/X';
import MaskedErrorSingle from 'src/components/icons/MaskedErrorSingle';
import MaskedError from 'src/components/icons/MaskedError';
import MaskedMulti from 'src/components/icons/MaskedMulti';
import MaskedSingle from 'src/components/icons/MaskedSingle';
import Checkbox from 'src/components/inputs/Checkbox';
import StyledCheckbox from 'src/components/inputs/StyledCheckbox';
import UnstyledTextarea from 'src/components/inputs/UnstyledTextarea';
import AssetInput from 'src/components/inputs/AssetInput';
import EasyLogic from 'src/components/inputs/EasyLogic';
import TextInputQuota from 'src/components/elements/TextInputQuota';
import QuillMentionUtil, { filterPipedQuestionLabel } from 'src/components/inputs/SimpleRichText/QuillMentionUtil';
import 'react-tippy/dist/tippy.css';
import toastr from 'toastr';
import misc, { getAssetVariationUrl } from 'src/utilities/misc';
import DetatchedScreenerModal from 'src/components/shared/DetatchedScreenerModal';
import * as manageBlueprintActions from 'src/domains/manage-blueprints/actions';
import { Iconof } from '@upsiide/ui-components';

import ScalePointInput from '../ScalePointInput';
import * as manageStudyActions from '../../../../domains/manage-study/actions';

import './styles.scss';

const className = 'question-option';
const el = (name, mod) => cn(className, name, mod);
const QuestionOption = ({
	answer,
	index,
	updateOptionAsset,
	language,
	questionId,
	question,
	removeQuestionOption,
	removeQuestionAttribute,
	patchQuestion,
	setIsPlaceholderActive,
	onFocus,
	onBlur,
	addOptionPlaceholder,
	defaultLanguage,
	tempTemplate,
	setTempTemplate,
	blockUpdateOrBreakTemplate,
	answers,
	activeScalePointInputIndex,
	setActiveScalePointInputIndex,
	activeAnswer,
	setActiveAnswer,
	removeClicked,
	setRemoveClicked,
	createOrUpdateQuestionOption,
	updateOptionScalePoint,
	setOptionScalePointValue,
	setLastAnswerFocused,
	attribute,
	setFocusOnCurrentQuestion,
	setFocusOnNextQuestion,
	setIsFocusOnLastAnswer,
	createQuestionOption,
	filterImportQuestions,
	changeSetting,
	studyType,
	audience,
	hasQuotas,
	updateScreeningQuota,
	focusedQuotaId,
	setFocusedQuotaId,
	hasInterlockedQuotas,
	setQuotaError = false,
	answerType,
	showScale,
	isDefaultScale,
}) => {
	const { isBlueprint } = useIsBlueprint();
	const actions = isBlueprint ? manageBlueprintActions : manageStudyActions;
	const { isNoneOfTheAbove } = answer;
	const [customTargetOptionAssetId, setCustomTargetOptionAssetId] = useState('');
	const [customTargetOptionAssetUrl, setCustomTargetOptionAssetUrl] = useState('');
	const [showDetatchedModal, setShowDetatchedModal] = useState(false);
	const [quota, setQuota] = useState(answer?.quotaMax);

	const dispatch = useDispatch();

	const [isDisqualification, setIsDisqualification] = useState(false);

	const studySections = useSelector(store => store.manageStudy?.sections?.content);
	const blueprintSections = useSelector(store => store.manageBlueprint?.sections?.content);
	const studyCurrentSection = useSelector(store => store.manageStudy?.currentSection?.content);
	const blueprintCurrentSection = useSelector(store => store.manageBlueprint?.currentSection?.content);

	const translationLanguage =
		typeof language === 'object' && language?.languageCode ? language?.languageCode : language;

	const sections = useMemo(() => {
		if (studyType === 'study') return studySections;
		if (studyType === 'blueprint') return blueprintSections;
		return [];
	}, [studySections, blueprintSections, studyType]);

	const currentSection = useMemo(() => {
		if (studyType === 'study') return studyCurrentSection;
		if (studyType === 'blueprint') return blueprintCurrentSection;
		return [];
	}, [studyCurrentSection, blueprintCurrentSection, studyType]);

	const allQuestions = useMemo(
		() =>
			sections
				?.filter(section => {
					if (currentSection?.type === 'questions' && section?.type === 'questions') return true;
					if (currentSection?.type === 'monadic_split') {
						return currentSection?.id === section?.id || section?.type === 'questions';
					}
					return false;
				})
				?.map(section => section?.questions || [])
				?.flat(Infinity) || [],
		[sections, currentSection],
	);

	const ideaSplitQuestions = useMemo(
		() => allQuestions.filter(e => e.sectionType === 'monadic_split') || [],
		[allQuestions],
	);

	const oldValueRef = useRef();
	const isRemovingRef = useRef();
	const isLocking = useRef();
	const optionRef = useRef();
	optionRef.current = answers[index];

	const setOldValue = useCallback(val => (oldValueRef.current = val), []);

	const getCurrentLangLabel = useCallback(
		a => {
			if (a?.translations) {
				const currentOptionTranslation = a.translations.find(
					translation => translation?.languageCode === translationLanguage,
				);
				if (currentOptionTranslation) return currentOptionTranslation.label;
				return '';
			}
			return a?.label;
		},
		[translationLanguage],
	);

	const terminateList = useMemo(() => {
		const isMultiSelect = question?.settings?.find(setting => setting?.label === 'multi-select')?.value === 'true';
		if (!question?.logic?.length) return [];

		const notTerminateLogic = question.logic.filter(
			l => l?.operator === 'is_not' && l?.actionType === 'terminate' && !isMultiSelect,
		);
		const terminateLogic = question.logic
			.filter(l => l?.operator !== 'is_not' && l?.actionType === 'terminate')
			.filter(l => !(l?.operator === 'is' && l?.operand === 'and' && l?.triggerOptions?.length > 1));

		const isNotTriggerOptionsId = [
			...new Set(notTerminateLogic.map(l => l?.triggerOptions?.map(({ id }) => id)).flat(Infinity)),
		];

		const terminateTriggerOptionsId = [
			...new Set(terminateLogic.map(l => l?.triggerOptions?.map(({ id }) => id)).flat(Infinity)),
		];
		if (isNotTriggerOptionsId.length) {
			return question?.options?.filter(opt => !isNotTriggerOptionsId.includes(opt?.id)).map(({ id }) => id);
		}

		return terminateTriggerOptionsId;
	}, [question]);

	const hasInvalidSingleLogic = useMemo(() => {
		const isMultiSelect = question?.settings?.find(setting => setting?.label === 'multi-select')?.value === 'true';

		// if has is_not + AND and it is single select - "invalid logic disqualify"
		return (
			!isMultiSelect &&
			question?.logic?.some(
				l =>
					l?.operator === 'is_not' &&
					l?.operand === 'and' &&
					l?.actionType === 'terminate' &&
					l?.triggerOptions.length > 1,
			)
		);
	}, [question.logic, question.settings]);

	useEffect(() => {
		if (hasInvalidSingleLogic) {
			return setIsDisqualification(true);
		}
		if (terminateList.includes(answer.id)) setIsDisqualification(true);
		else setIsDisqualification(false);
	}, [question, answer, terminateList, hasInvalidSingleLogic]);

	const [labelValue, setLabelValue] = useState(getCurrentLangLabel(answer));

	useEffect(() => {
		setLabelValue(getCurrentLangLabel(answer));
	}, [answer, getCurrentLangLabel, translationLanguage]);

	useEffect(() => {
		setQuota(answer?.quotaMax == null ? null : Number(answer.quotaMax.toFixed(2)));
	}, [answer]);

	const getPlaceholderText = useCallback(
		(a, singleSelect) => {
			if (a.translations) {
				// Look for first available translation that is filled
				if (a.translations) {
					// Look for default translation
					const defaultTranslation = a.translations.find(
						translation => translation.languageCode === defaultLanguage,
					);

					if (defaultTranslation) return defaultTranslation.label;
				}
			}

			return `Type ${singleSelect ? 'answer' : 'option'} here...`;
		},
		[defaultLanguage],
	);

	const onOptionChange = useCallback(
		(newLabel, option) => {
			// Save options locally immediately.
			const newLabelArray = newLabel
				.split(/\r?\n/) // newline could be \r or \r\n
				.reduce((acc, v) => {
					if (v.trim()) {
						acc.push(v.trim()); // remove whitespace
					}
					return acc;
				}, []);

			if (newLabelArray.length > 1) {
				// if a newline found then copy any subsequent text into a separate answer(s).

				if (tempTemplate) {
					// on audience custom target
					tempTemplate.estimatedIncidenceRate = null;
					// After creating the new option, we need to look and see if there were any placeholders options that need to be restored

					const currentQuestion = tempTemplate.customQuestions.find(q => q.id === questionId);

					const currentOrderIdx =
						answers
							.filter(ans => !ans?.isNoneOfTheAbove && !ans?.isOtherSpecify)
							.map(ans => ans.order)
							.reduce((a, b) => Math.max(a, b), 0) + 1;

					// Assign the latest answers with current Ids
					const updatedAnswers = attribute ? currentQuestion.attributes : answers;

					let answersToReplace = updatedAnswers.filter(
						answerToReplace => !answerToReplace.isOtherSpecify && !answerToReplace.isNoneOfTheAbove,
					);

					const indexOfStartingAnswer = answersToReplace.findIndex(a => a.id === option.id);

					if (indexOfStartingAnswer === -1) answersToReplace = [];

					const newOptions = [];
					newLabelArray.forEach((val, idx) => {
						const newOption = {
							id: Number.isNaN(currentQuestion.id)
								? `${currentQuestion.id}${currentOrderIdx + idx}`
								: `temp_${currentQuestion.id}${currentOrderIdx + idx}`,
							[CONSTANTS.questions.options.isOtherSpecify]: false,
							[CONSTANTS.questions.options.isNoneOfTheAbove]: false,
							[CONSTANTS.questions.options.lockOrder]: false,
							order: currentOrderIdx + idx,
							translations: [
								{
									label: val,
									languageCode: translationLanguage,
								},
							],
						};

						if (attribute) {
							delete newOption.isOtherSpecify;
							delete newOption.isNoneOfTheAbove;
							newOption.placeholder = true;
						}

						newOptions.push(newOption);
					});

					for (let newLabelIndex = 0; newLabelIndex < newOptions.length; newLabelIndex++) {
						const opt = newOptions[newLabelIndex];
						if (
							updatedAnswers[indexOfStartingAnswer + newLabelIndex] &&
							!updatedAnswers[indexOfStartingAnswer + newLabelIndex]?.placeholder &&
							!updatedAnswers[indexOfStartingAnswer + newLabelIndex]?.isOtherSpecify &&
							!updatedAnswers[indexOfStartingAnswer + newLabelIndex]?.isNoneOfTheAbove
						) {
							createOrUpdateQuestionOption(
								updatedAnswers[indexOfStartingAnswer + newLabelIndex],
								opt.translations[0].label,
								false,
								false,
								newLabelIndex,
							);
						} else {
							createOrUpdateQuestionOption(opt, opt.translations[0].label, false, false, newLabelIndex);
						}
					}
					if (answer?.placeholder || String(answer?.id)?.indexOf('temp') > -1) {
						if (attribute) {
							removeQuestionAttribute(questionId, answer.id);
						} else {
							removeQuestionOption(questionId, answer.id);
						}
					}

					// // focus on last added item
					// setIsFocusOnLastAnswer(true);
				} else {
					const currentOrder =
						answers
							.filter(ans => !ans?.isNoneOfTheAbove && !ans?.isOtherSpecify)
							.map(ans => ans.order)
							.reduce((a, b) => Math.max(a, b), 0) + 1;

					let answersToReplace = answers.filter(
						answerToReplace =>
							!answerToReplace.isOtherSpecify &&
							!answerToReplace.isNoneOfTheAbove &&
							!answerToReplace.placeholder,
					);

					const indexOfStartingAnswer = answersToReplace.findIndex(
						a => a.id === option.id || a.order === option.order,
					);

					if (indexOfStartingAnswer === -1) answersToReplace = [];
					else answersToReplace = answersToReplace.slice(indexOfStartingAnswer);

					newLabelArray.forEach((val, idx) => {
						const newAnswer = {
							id: `placeholder-${idx}`,
							[CONSTANTS.questions.options.isOtherSpecify]: false,
							[CONSTANTS.questions.options.isNoneOfTheAbove]: false,
							[CONSTANTS.questions.options.lockOrder]: false,
							placeholder: true,
							order: currentOrder + idx,
							translations: [
								{
									label: val,
									languageCode: translationLanguage,
								},
							],
						};

						if (attribute) {
							delete newAnswer.isOtherSpecify;
							delete newAnswer.isNoneOfTheAbove;
						}
						if (answersToReplace[idx]) {
							createOrUpdateQuestionOption(answersToReplace[idx], val);
						} else {
							createQuestionOption(newAnswer, idx);
						}
					});
					// focus on last added item
					setIsFocusOnLastAnswer(true);
				}
			} else if (answer.translations) {
				const currentOptionTranslation = answer.translations.find(
					translation => translation.languageCode === translationLanguage,
				);
				if (currentOptionTranslation) currentOptionTranslation.label = newLabel;
				else
					answer.translations.push({
						label: newLabel,
						languageCode: translationLanguage,
					});
				setLabelValue(getCurrentLangLabel(answer));
			}
		},
		[
			answer,
			answers,
			attribute,
			createOrUpdateQuestionOption,
			createQuestionOption,
			getCurrentLangLabel,
			translationLanguage,
			questionId,
			setIsFocusOnLastAnswer,
			setTempTemplate,
			tempTemplate,
		],
	);

	const setActiveAnswerCallback = useCallback(
		(prevActiveAnswer, changeBy) => {
			const newActiveAnswer = prevActiveAnswer + changeBy;
			const element = attribute
				? document.getElementById(`attribute-textarea-${questionId}-${newActiveAnswer}`)
				: document.getElementById(`answer-textarea-${questionId}-${newActiveAnswer}`);
			try {
				element.focus();
				element.select(); // necessary for some browsers
				return newActiveAnswer;
			} catch (error) {
				console.log(error);
				return newActiveAnswer;
			}
		},
		[attribute, questionId],
	);

	/**
	 * Handle all keyboard shortcuts
	 *
	 * @param {*} e
	 * @param {*} answer
	 */
	const onKeyDown = useCallback(
		e => {
			// define keys
			const isEnterKey = e.key === 'Enter';
			const isUpKey = e.key === 'ArrowUp';
			const isDownKey = e.key === 'ArrowDown';
			const isTabKey = !e.shiftKey && e.key === 'Tab';
			const isShiftTabKey = e.shiftKey && e.key === 'Tab';
			const isDeleteKey = e.key === 'Backspace'; //  delete on mac keyboards

			if (isDeleteKey && e.target.value === '') {
				e.preventDefault();
				if (answer?.isNoneOfTheAbove || answer?.isOtherSpecify) {
					const setting = answer.isNoneOfTheAbove ? 'hasNoneOfTheAboveOption' : 'hasOtherSpecifyOption';
					isRemovingRef.current = true;
					changeSetting(setting);
				} else if (answers.filter(ans => ans?.order < 99)?.length > 1) {
					isRemovingRef.current = true;
					removeQuestionOption(questionId, answer.id);
				}
				if (activeAnswer > 0) {
					setActiveAnswer(prevActiveAnswer => setActiveAnswerCallback(prevActiveAnswer, -1));
				}
			}

			if (isEnterKey || isUpKey || isDownKey || isTabKey || isShiftTabKey) {
				e.preventDefault();
				if (isEnterKey) {
					const filteredAnswers = answers.filter(ans => ans.order <= 99);
					const isLastAnswer = answer?.id === filteredAnswers[filteredAnswers.length - 1]?.id;
					if (isLastAnswer && e.target.value !== '') {
						if (!filterImportQuestions) {
							addOptionPlaceholder();
							setLastAnswerFocused(false);
						}
					} else if (activeAnswer < answers.length - 1) {
						setActiveAnswer(prevActiveAnswer => setActiveAnswerCallback(prevActiveAnswer, 1));
					}
				} else if (isShiftTabKey || (isUpKey && activeAnswer > 0)) {
					if (activeAnswer > 0) {
						setActiveAnswer(prevActiveAnswer => setActiveAnswerCallback(prevActiveAnswer, -1));
					} else {
						// move up to question
						setFocusOnCurrentQuestion();
					}
				} else if (isTabKey || isDownKey) {
					if (activeAnswer < answers.length - 1) {
						setActiveAnswer(prevActiveAnswer => setActiveAnswerCallback(prevActiveAnswer, 1));
					} else {
						setFocusOnNextQuestion(question.id);
					}
				}
			}
		},
		[
			activeAnswer,
			addOptionPlaceholder,
			answer,
			answers,
			changeSetting,
			filterImportQuestions,
			question.id,
			questionId,
			removeQuestionOption,
			setActiveAnswer,
			setActiveAnswerCallback,
			setFocusOnCurrentQuestion,
			setFocusOnNextQuestion,
			setLastAnswerFocused,
		],
	);

	const focusFirstAvailableLabelInput = () => setActiveAnswer(setActiveAnswerCallback(0, 0));

	const toggleLock = useCallback(
		(optionIndex, locked = false) => {
			const newOptions = attribute ? [...question.attributes] : [...question.options];
			newOptions[optionIndex].lockOrder = locked;
			newOptions.forEach(o => {
				delete o.isDisplayedInReporting;
				delete o.parentId;
				delete o.maskedOptions;
				delete o.assetVariations;
				delete o.asset;
				delete o.assetTitle;
			});
			if (!Number.isNaN(Number(newOptions[optionIndex]?.id))) {
				// patch only if option is already saved
				if (tempTemplate) {
					if (attribute) {
						patchQuestion(questionId, { attributes: newOptions });
					} else {
						patchQuestion(questionId, { options: newOptions });
					}
				} else if (attribute) {
					patchQuestion(questionId, { attributes: newOptions });
				} else {
					patchQuestion(questionId, { options: newOptions });
				}
			}
		},
		[attribute, patchQuestion, question.attributes, question.options, questionId],
	);

	const getLockOptionTooltipText = useCallback((lockOrder, isLockDisabled) => {
		let text = '';
		if (lockOrder) {
			if (isLockDisabled) {
				text = 'Position always locked';
			} else {
				text = 'Position locked';
			}
		} else {
			text = 'Position randomized';
		}
		return text;
	}, []);

	const onAssetChange = useCallback(
		(value, option, attrOrOption) => {
			let defaultAssetId;
			const translations = option.translations.map(translation => {
				delete translation.asset;
				delete translation.deletedAt;
				delete translation.questionId;

				const newAssetId = translation.languageCode === translationLanguage ? value[0] : translation?.assetId;
				if (translation.languageCode === defaultLanguage) {
					defaultAssetId = newAssetId;
				}
				return {
					...translation,
					assetId: newAssetId,
				};
			});
			if (tempTemplate) {
				const assetDeleted = value.length === 1 && value[0] === 0;
				setCustomTargetOptionAssetId(assetDeleted ? [] : value);

				tempTemplate.estimatedIncidenceRate = null;

				const questionIndex = tempTemplate.customQuestions.findIndex(q => q.id === question.id);
				const i = question[attrOrOption].findIndex(o => o.id === option.id);
				const translationIndex = option.translations.findIndex(t => t.languageCode === translationLanguage);
				tempTemplate.customQuestions[questionIndex][attrOrOption][i].translations = translations;

				if (!assetDeleted) {
					if (attrOrOption === 'attributes') {
						tempTemplate.customQuestions[questionIndex][attrOrOption][i].assetId = value[0];
					}
					services.assetService.getOne(value).then(response => {
						setCustomTargetOptionAssetUrl(response.url);
						tempTemplate.customQuestions[questionIndex][attrOrOption][i].translations[
							translationIndex
						].asset = response.url;
						setTempTemplate({ ...tempTemplate });
					});
				} else {
					setCustomTargetOptionAssetUrl(null);
					tempTemplate.customQuestions[questionIndex][attrOrOption][i].assetId = null;
					tempTemplate.customQuestions[questionIndex][attrOrOption][i].asset = null;
					tempTemplate.customQuestions[questionIndex][attrOrOption][i].translations[translationIndex].asset =
						null;
					setTempTemplate({ ...tempTemplate });
				}
				updateOptionAsset(question.id, option.id, {
					assetId: defaultAssetId || value[0] || null,
					translations,
				});
			} else {
				updateOptionAsset(question.id, option.id, {
					assetId: defaultAssetId || value[0] || null,
					translations,
				});
			}
		},
		[defaultLanguage, translationLanguage, question, setTempTemplate, tempTemplate, updateOptionAsset],
	);

	const onOptionAssetChange = useCallback(
		(value, option) => {
			onAssetChange(value, option, 'options');
		},
		[onAssetChange],
	);

	const onAttributeAssetChange = useCallback(
		(value, attr) => {
			onAssetChange(value, attr, 'attributes');
		},
		[onAssetChange],
	);

	const singleSelect = useMemo(
		() => !(question?.settings?.find(s => s.label === 'multi-select')?.value === 'true'),
		[question],
	);

	const isOther = answer.isOtherSpecify;
	const isRandomlyOrdered = attribute
		? question &&
		  question.settings &&
		  question.settings.find(s => s && s.label === 'randomize_attributes') &&
		  question.settings.find(s => s && s.label === 'randomize_attributes').value === 'true'
		: question.randomizeOptions;
	const isLockDisabled = isOther || isNoneOfTheAbove;

	const optionTranslation = useMemo(
		() => answer?.translations?.find(t => t?.languageCode === translationLanguage),
		[answer.translations, translationLanguage],
	);

	const mainTranslation = useMemo(
		() => answer?.translations?.find(t => defaultLanguage === t?.languageCode),
		[answer.translations, defaultLanguage],
	);

	const [assetId, asset] = useMemo(() => {
		let selectedAssetId = null;
		let selectedAsset = null;

		if (!selectedAssetId && optionTranslation?.assetId) {
			selectedAssetId = optionTranslation?.assetId;
			selectedAsset = getAssetVariationUrl(optionTranslation);
		}

		if (!selectedAssetId && mainTranslation?.assetId) {
			selectedAssetId = mainTranslation?.assetId;
			selectedAsset = getAssetVariationUrl(mainTranslation);
		}

		if (!selectedAssetId) {
			selectedAssetId = answer?.assetId;
			selectedAsset = answer?.asset;
		}

		return [selectedAssetId, selectedAsset];
	}, [answer.asset, answer.assetId, optionTranslation, mainTranslation]);

	const defaultAssetUsed = !optionTranslation?.asset;

	const displayAsset = !defaultAssetUsed || (getCurrentLangLabel(answer) !== '' && assetId);

	const displayError = useMemo(
		() => getCurrentLangLabel(answer) === '' && !defaultAssetUsed && assetId,
		[answer, assetId, defaultAssetUsed, getCurrentLangLabel],
	);

	const renderMaskedQuestionOption = useCallback(() => {
		const originQuestion = allQuestions.find(q => q.id === answer.maskedQuestionId);
		const isIdeaSplits = originQuestion?.sectionType === 'monadic_split';

		const currentQuestion = allQuestions.find(q => q?.id === questionId);
		const currentQuestionNumber = allQuestions.indexOf(currentQuestion) + 1;

		const allQuestionsIndex = allQuestions.indexOf(originQuestion) + 1;
		const originQuestionNumber = (isIdeaSplits ? ideaSplitQuestions : allQuestions).indexOf(originQuestion) + 1;

		const maskingError = currentQuestionNumber <= allQuestionsIndex || !originQuestion;
		const renderIcon = () => {
			const rankedQuestion = question?.style === 'ranked';
			if (maskingError) {
				return singleSelect && !rankedQuestion ? <MaskedErrorSingle /> : <MaskedError />;
			}
			return !attribute && !rankedQuestion && singleSelect ? (
				<MaskedSingle selected={answer?.logic} />
			) : (
				!attribute && (
					<div className={el('masked-option-multi')}>
						<MaskedMulti selected={answer?.logic} />
					</div>
				)
			);
		};

		let label = misc.stripHtmlTags(getCurrentLangLabel(originQuestion));
		if (QuillMentionUtil.isPipedQuestion(label)) {
			label = filterPipedQuestionLabel(label, allQuestions);
		}

		label = `${isIdeaSplits ? 'Idea Split: ' : ''}Q${originQuestionNumber}. ${label}`;

		const getOptionLabel = () => {
			if (answer?.position && answer?.rank) {
				return `ranked ${answer?.position} ${answer?.rank}`;
			}

			return answer?.logic === 'selected' ? answer.logic : 'unselected';
		};

		const tooltipContent = () => (
			<div className={el('tooltip-content')}>
				<p>
					Recalling <span>{getOptionLabel()}</span> options from
				</p>
				<div className={el('tooltip-second-line')} style={{ flexDirection: isIdeaSplits ? 'column' : 'row' }}>
					<div dangerouslySetInnerHTML={{ __html: label }} />
				</div>
			</div>
		);
		return (
			<div className={`${el('masked-item')} ${maskingError && el('masked-error')}`}>
				{renderIcon()}
				<Tooltip
					id={`${className}-tooltip-${answer.questionId}-${answer.id}-${index}`}
					className={el('tooltip')}
					animation="shift"
					animationFill={false}
					trigger="mouseenter"
					theme="basic-text-tooltip"
					html={tooltipContent()}
					position="top-start"
					delay={500}
					disabled={maskingError}
				>
					{maskingError ? (
						<p>
							<span>Error: </span>This recall is broken. Delete this option
						</p>
					) : (
						<p>
							Recalling <span>{getOptionLabel()}</span> options from{' '}
							<span>
								{isIdeaSplits && 'Idea Split: '}Q{originQuestionNumber}
							</span>
						</p>
					)}
				</Tooltip>
				<div className={el('remove-option')}>
					<button
						type="button"
						className={el('button', 'remove-option-button')}
						onMouseDown={() => {
							setRemoveClicked(answer.id);
							isRemovingRef.current = true;
							if (isLockDisabled) {
								const setting = answer.isNoneOfTheAbove
									? 'hasNoneOfTheAboveOption'
									: 'hasOtherSpecifyOption';
								changeSetting(setting);
							} else if (attribute) {
								removeQuestionAttribute(questionId, answer.id);
							} else if (
								question?.options?.filter(opt => opt.order <= 99)?.length === 1 &&
								question?.options?.filter(opt => opt.maskedQuestionId)
							) {
								removeQuestionOption(questionId, answer.id);
								addOptionPlaceholder();
							} else {
								removeQuestionOption(questionId, answer.id);
							}
							setIsFocusOnLastAnswer(true);
						}}
						disabled={
							attribute
								? question?.attributes?.length === 1
								: question?.options?.filter(opt => opt.order <= 99)?.length === 1 &&
								  !isOther &&
								  !isNoneOfTheAbove &&
								  !question?.options?.filter(opt => opt.maskedQuestionId)
						}
						aria-label="remove option button"
					>
						<X color="#666666" width={5} />
					</button>
				</div>
			</div>
		);
	}, [
		allQuestions,
		ideaSplitQuestions,
		getCurrentLangLabel,
		answer,
		index,
		attribute,
		question,
		isOther,
		isNoneOfTheAbove,
		questionId,
		singleSelect,
		setRemoveClicked,
		isLockDisabled,
		setIsFocusOnLastAnswer,
		changeSetting,
		removeQuestionAttribute,
		removeQuestionOption,
		addOptionPlaceholder,
	]);

	useEffect(() => {
		if (getCurrentLangLabel(answer)) {
			setOldValue(getCurrentLangLabel(answer));
		} else {
			getCurrentLangLabel('');
		}
	}, [answer, getCurrentLangLabel, setOldValue]);

	const setEasyLogic = (action = 'create', logic = null) => {
		const audienceCollectionId = tempTemplate?.audienceCollectionId;
		const studyId = tempTemplate?.studyId;

		if (!audienceCollectionId || !studyId) return;
		if (!isDisqualification && action === 'create') {
			dispatch(
				actions.addEasyLogic(
					studyId,
					audienceCollectionId,
					question?.id,
					{ logic },
					tempTemplate?.isAudienceTemplate,
				),
			);
			setIsDisqualification(prev => !prev);
		} else if (action === 'delete' && question?.logic?.length) {
			const logicId = question?.logic?.find(
				l =>
					(l.operand === 'and' ? l.triggerOptions.length === 1 : true) &&
					l.triggerOptions.find(trigger => trigger?.id === answer?.id),
			)?.id;
			dispatch(
				actions.removeEasyLogic(
					studyId,
					audienceCollectionId,
					question?.id,
					logicId,
					tempTemplate?.isAudienceTemplate,
				),
			);
			setIsDisqualification(prev => !prev);
		}
	};

	const canLowerQuota = useMemo(() => {
		const liveGroups = question.interlockedWithinGroup?.filter(group => group?.status === 'live');
		return !liveGroups?.length;
	}, [question.interlockedWithinGroup]);

	const checkQuotasError = (msg, value) => {
		if (msg && setQuotaError) {
			setQuotaError(msg); // this msg is shown in the QuestionSettingsTab component

			// find question by question.id and grab the option by answer.id
			const currentQuestion = tempTemplate?.customQuestions?.find(q => q?.id === question?.id);
			currentQuestion.options = currentQuestion.options.map(opt => {
				if (opt?.id === answer?.id) {
					return {
						...opt,
						quotaMax: value?.target?.value ? Number(value.target.value) : null,
					};
				}
				return opt;
			});

			// update customQuestions (screening question) in tempTemplate
			const newCustomQuestions = [];
			for (let i = 0; i < tempTemplate?.customQuestions?.length; i++) {
				const quest = tempTemplate?.customQuestions?.[i];
				if (quest?.id === question?.id) newCustomQuestions.push(currentQuestion);
				else newCustomQuestions.push(quest);
			}

			setTempTemplate({ ...tempTemplate, newCustomQuestions });
		}
	};

	const onBlurQuota = async e => {
		if (`${answer?.id}`.includes('temp')) return;
		const oldValue = answer?.quotaMax;
		if (!canLowerQuota) {
			if (oldValue?.quota?.max === null) {
				toastr.error("You can't change empty quotas after you have launched your group");
				const response = await updateScreeningQuota(question.id, answer.id, '');
				checkQuotasError(response, e);
				return;
			}

			if (e.target.value !== '' && oldValue && Number(e.target.value) < Number(oldValue)) {
				toastr.error('You can only increase quotas after you have launched your group');
				const response = await updateScreeningQuota(question.id, answer.id, oldValue);
				checkQuotasError(response, e);
				return;
			}
		}

		if (Number(e.target.value) && quota === null) {
			const response = await updateScreeningQuota(question.id, answer.id, Number(e.target.value));
			checkQuotasError(response, e);
		} else {
			const response = await updateScreeningQuota(question.id, answer.id, quota);
			checkQuotasError(response, e);
		}
	};

	const getOptionDetail = (attribute, singleSelect) => {
		if (answerType === 'ranked')
			return <Iconof className={el('drag-icon')} icon="drag" size="default" color="#666666" />;

		if (!attribute && singleSelect)
			return <Checkbox value={answer.isSelected ? 'selected' : ''} onChange={() => {}} />;

		if (!attribute) return <StyledCheckbox disabled checked={answer.isSelected} onChange={() => {}} />;
	};

	return (
		<div className={el('item-container')}>
			{showScale && (
				<div className={el('scale-point-wrapper')}>
					<ScalePointInput
						{...{
							activeScalePointInputIndex,
							setActiveScalePointInputIndex,
							index,
							answer,
							answers,
							focusFirstAvailableLabelInput,
							updateOptionScalePoint,
							setOptionScalePointValue,
							isDefaultScale,
						}}
					/>
				</div>
			)}

			{!answer?.maskedQuestionId ? (
				<>
					<div
						className={`${el('item', getCurrentLangLabel(answer) ? '' : 'placeholder')}${
							activeAnswer === index ? ' active' : ''
						}
					${displayError ? ' missing-label-error' : ''}`}
						key={index}
					>
						<div className={el('checkbox-wrapper')}>{getOptionDetail(attribute, singleSelect)}</div>

						<UnstyledTextarea
							question={question}
							isMasking={
								!audience &&
								!isOther &&
								!isNoneOfTheAbove &&
								(question?.style === 'multi-select' || question?.style === 'ranked')
							}
							studyType={studyType}
							id={
								attribute
									? `attribute-textarea-${questionId}-${index}`
									: `answer-textarea-${questionId}-${index}`
							}
							value={labelValue}
							placeholder={getPlaceholderText(answer, singleSelect)}
							onChange={e => {
								if (!e) return;
								onOptionChange(e.target.value, answer);
							}}
							onChangeMasking={value => {
								createOrUpdateQuestionOption(
									{ ...answers[index], value, scalePoint: null },
									null,
									true,
								);
							}}
							onKeyDown={onKeyDown}
							onFocus={e => {
								const answersWithoutExtraOptions = filter(
									answers,
									a => !a.isNoneOfTheAbove && !a.isOtherSpecify,
								);
								const lastItem = last(answersWithoutExtraOptions);
								if (lastItem && lastItem.placeholder && lastItem.label === '')
									setIsPlaceholderActive(true);
								else setIsPlaceholderActive(false);

								setActiveAnswer(index);
								setRemoveClicked(false);
								e.target.select(); // focus on the entire text
								onFocus(questionId);
							}}
							onBlur={e => {
								const hasClickedOnX = isRemovingRef.current;
								const hasClickedOnLock = isLocking.current;
								if (removeClicked !== answer.id && !hasClickedOnX && e?.target?.value?.length) {
									createOrUpdateQuestionOption(
										hasClickedOnLock
											? { ...optionRef.current, lockOrder: true }
											: optionRef.current,
										e.target.value,
										false,
									);
								}
								setRemoveClicked(false);
								setActiveAnswer(false);
								onBlur(e);
								isRemovingRef.current = false;
								isLocking.current = false;
							}}
							key={index}
						/>

						{question?.style !== 'grid' &&
						question?.style !== 'ranked' &&
						audience &&
						!isDisqualification &&
						question?.settings?.find(s => s.label === 'multi-select')?.value !== 'true' ? (
							<div className={`${el('quota-container')} ${answer.id === focusedQuotaId ? 'active' : ''}`}>
								<span className={el('quota-container-label')}>Quota</span>
								<Tooltip
									theme="screening-interlock dark-blured"
									position="top"
									disabled={!hasInterlockedQuotas}
									html={
										<div className="interlock-quota-warning">
											<b>Interlocked quota</b>
											<p>
												Click on
												<img
													className={el('interlock-quotas-icon')}
													src={InterlockQuotasIcon}
													alt="Interlock Quotas"
												/>
												on top of your
											</p>
											<p>demographic details table to</p>
											<p>go to interlock page</p>
										</div>
									}
								>
									<TextInputQuota
										complement="%"
										value={quota}
										onChange={setQuota}
										onBlur={e => {
											onBlurQuota(e);
											setFocusedQuotaId(null);
										}}
										onFocus={() => setFocusedQuotaId(answer.id)}
										maxLength={5}
										customClasses={hasInterlockedQuotas ? 'is-interlocked' : ''}
									/>
								</Tooltip>
							</div>
						) : null}
						{question?.style !== 'grid' && question?.style !== 'ranked' && audience ? (
							<EasyLogic
								defaultState={isDisqualification}
								answer={answer}
								index={index}
								question={question}
								setEasyLogic={setEasyLogic}
							/>
						) : null}

						{!isOther &&
							!isNoneOfTheAbove &&
							filterImportQuestions !== true &&
							(displayAsset ? (
								<AssetInput
									preview
									asset={asset || customTargetOptionAssetUrl}
									value={[assetId] || customTargetOptionAssetId}
									onChange={attribute ? onAttributeAssetChange : onOptionAssetChange}
									option={answer}
									imageOnly
									canDelete={!defaultAssetUsed}
									customPreviewStyle={defaultAssetUsed ? { opacity: '50%' } : null}
									connectedToQuestionLibrary={question?.parentId}
								/>
							) : getCurrentLangLabel(answer) !== '' && (tempTemplate || !isNaN(Number(answer?.id))) ? (
								<Tooltip
									id={`${className}-tooltip-${answer.questionId}-${answer.id}-${index}`}
									className={el('tooltip')}
									animation="shift"
									animationFill={false}
									trigger="mouseenter"
									theme="basic-text-tooltip"
									html={<div>{assetId ? 'Replace Media' : 'Add Media'}</div>}
								>
									<AssetInput
										value={[]}
										onChange={attribute ? onAttributeAssetChange : onOptionAssetChange}
										blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
										option={answer}
										imageOnly
										connectedToQuestionLibrary={question?.parentId}
									/>
								</Tooltip>
							) : null)}
						{isRandomlyOrdered ? (
							<Tooltip
								id={`${className}-tooltip-${answer.questionId}-${answer.id}-${index}`}
								className={el('tooltip')}
								animation="shift"
								animationFill={false}
								trigger="mouseenter"
								theme="basic-text-tooltip"
								html={<div>{getLockOptionTooltipText(answer.lockOrder, isLockDisabled)}</div>}
								// open // ! For debugging
							>
								<div
									onClick={() => (isLocking.current = true)}
									aria-hidden
									className={el('lock-option')}
								>
									{answer.lockOrder ? (
										<button
											type="button"
											className={el('button', 'lock-option')}
											onMouseDown={() => {
												if (question?.parentId) {
													setShowDetatchedModal({ type: 'lock', index, lock: false });
												} else {
													isLocking.current = true;
													toggleLock(index, false);
												}
											}}
											disabled={isLockDisabled}
											aria-label="unlock-position-button"
										>
											<LockClosed />
										</button>
									) : (
										<button
											type="button"
											className={el('button', 'lock-option')}
											onMouseDown={() => {
												if (question?.parentId) {
													setShowDetatchedModal({ type: 'lock', index, lock: true });
												} else {
													isLocking.current = true;
													toggleLock(index, true);
												}
											}}
											disabled={isLockDisabled}
											aria-label="lock-position-button"
										>
											<LockOpen />
										</button>
									)}
								</div>
							</Tooltip>
						) : null}
						<div className={el('remove-option')}>
							<button
								type="button"
								className={el('button', 'remove-option-button')}
								onMouseDown={() => {
									setRemoveClicked(answer.id);
									isRemovingRef.current = true;
									if (isLockDisabled) {
										const setting = answer.isNoneOfTheAbove
											? 'hasNoneOfTheAboveOption'
											: 'hasOtherSpecifyOption';
										if (question?.parentId) {
											setShowDetatchedModal({ type: 'option', setting });
										} else {
											changeSetting(setting);
										}
									} else if (attribute) {
										removeQuestionAttribute(questionId, answer.id);
									} else {
										removeQuestionOption(questionId, answer.id);
									}
									setIsFocusOnLastAnswer(true);
								}}
								disabled={
									attribute
										? question?.attributes?.length === 1
										: question?.options?.filter(opt => opt.order <= 99)?.length === 1 &&
										  !isOther &&
										  !isNoneOfTheAbove
								}
								aria-label="remove-option-button"
							>
								<X color="#666666" width={5} />
							</button>
						</div>
					</div>
					{displayError && (
						<div className={el('label-error')}>{attribute ? 'Attribute' : 'Answer'} text is required.</div>
					)}
					<DetatchedScreenerModal
						show={showDetatchedModal}
						onClose={() => {
							setShowDetatchedModal(false);
						}}
						onConfirm={() => {
							if (showDetatchedModal.type === 'option') {
								changeSetting(showDetatchedModal.setting);
							} else if (showDetatchedModal.type === 'lock') {
								isLocking.current = true;
								toggleLock(showDetatchedModal.index, showDetatchedModal.lock);
							}
							setShowDetatchedModal(false);
						}}
					/>
				</>
			) : (
				renderMaskedQuestionOption()
			)}
		</div>
	);
};

QuestionOption.propTypes = {
	answer: PropTypes.any,
	index: PropTypes.any,
	updateOptionAsset: PropTypes.func,
	options: PropTypes.array,
	language: PropTypes.string,
	questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	question: PropTypes.object,
	patchQuestion: PropTypes.func,
	removeQuestionOption: PropTypes.func,
	setIsPlaceholderActive: PropTypes.func,
	addOptionPlaceholder: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	defaultLanguage: PropTypes.string,
	tempTemplate: PropTypes.object,
	setTempTemplate: PropTypes.func,
	blockUpdateOrBreakTemplate: PropTypes.func,
	answers: PropTypes.any,
	activeScalePointInputIndex: PropTypes.any,
	setActiveScalePointInputIndex: PropTypes.func,
	activeAnswer: PropTypes.any,
	setActiveAnswer: PropTypes.func,
	removeClicked: PropTypes.any,
	setRemoveClicked: PropTypes.func,
	createOrUpdateQuestionOption: PropTypes.func,
	updateOptionScalePoint: PropTypes.func,
	setOptionScalePointValue: PropTypes.func,
	setLastAnswerFocused: PropTypes.func,
	attribute: PropTypes.bool,
	setFocusOnCurrentQuestion: PropTypes.func,
	setFocusOnNextQuestion: PropTypes.func,
	setIsFocusOnLastAnswer: PropTypes.func,
	createQuestionOption: PropTypes.func,
	filterImportQuestions: PropTypes.bool,
	changeSetting: PropTypes.func,
	removeQuestionAttribute: PropTypes.func,
	studyType: PropTypes.string,
	audience: PropTypes.object,
	hasQuotas: PropTypes.bool,
	updateScreeningQuota: PropTypes.func,
	focusedQuotaId: PropTypes.number,
	setFocusedQuotaId: PropTypes.func,
	hasInterlockedQuotas: PropTypes.bool,
	audienceTemplate: PropTypes.any,
	setQuotaError: PropTypes.func,
	answerType: PropTypes.any,
	showScale: PropTypes.bool,
	isDefaultScale: PropTypes.bool,
};

export default QuestionOption;
