import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import UpsiideLogo from 'src/components/icons/UpsiideLogo';
import LockClosed from 'src/components/icons/LockClosed';
import { useNavigate } from 'react-router-dom';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'locked-header';
const el = (name, mod) => cn(className, name, mod);

const LockedHeader = () => {
	const navigate = useNavigate();
	return (
		<Container className={className}>
			<Row>
				<Col xs={4} className={el('left')}>
					<button
						className={el('back-to-studies')}
						type="button"
						onClick={() => {
							// TODO: Get the study id so you can navigate back to the specific study
							navigate('/studies');
						}}
					>
						<UpsiideLogo />
					</button>
				</Col>
				<Col xs={4} className={el('center')}></Col>
				<Col xs={4} className={el('right')}>
					<LockClosed />
				</Col>
			</Row>
		</Container>
	);
};

export default LockedHeader;
