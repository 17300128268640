import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Construction from 'public/images/construction.svg';
import Racecar from 'public/images/racing-car.svg';
import * as actions from 'src/domains/auth/actions';
import './styles.scss';

const className = 'maintenance-component';
const el = (name, modifier) => cn(className, name, modifier);

class MaintenanceComponent extends React.Component {
	constructor(props) {
		super(props);
		const isRateLimited = false;
		const statusPath = 'https://status.upsiide.com/';
		const altText = 'construction';
		const titleText = "We're working on something special";
		const maintenanceText =
			"We're undergoing scheduled maintenance to bring you the best possible experience. Check back soon to see the latest update.";
		const supportText = 'Contact Support';
		const statusText = 'Check Status Page';

		this.state = {
			statusPath,
			altText,
			titleText,
			maintenanceText,
			supportText,
			statusText,
			isRateLimited,
		};
	}

	componentDidUpdate() {
		if (this.props?.rateLimit?.flagged === true && !this.state.isRateLimited) {
			this.setState({
				titleText: 'Slow down, you’re moving too fast',
				altText: 'racecar',
				isRateLimited: true,
				maintenanceText: 'Please try again in few minutes or contact support.',
			});
		}
	}

	contactSupport = () => {
		const { goToSupport } = this.props;
		goToSupport();
	};

	render() {
		const { children, visible } = this.props;

		const { isRateLimited, statusPath, altText, titleText, maintenanceText, supportText, statusText } = this.state;
		if (visible) {
			return (
				<div className={className}>
					<div className={el('content')}>
						<img className={el('image')} src={isRateLimited ? Racecar : Construction} alt={altText} />
						<div className={el('title')}>{titleText}</div>
						<div className={el('subtitle')}>{maintenanceText}</div>
						<div className={el('footer')}>
							<a
								className={el('status-button')}
								href={statusPath}
								target="_blank"
								rel="noopener noreferrer"
							>
								{statusText}
							</a>
							<button className={el('support-button')} type="button" onClick={this.contactSupport}>
								{supportText}
							</button>
						</div>
					</div>
				</div>
			);
		}
		return children;
	}
}

MaintenanceComponent.propTypes = {
	visible: PropTypes.bool,
	children: PropTypes.any,
	goToSupport: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
	goToSupport: () => dispatch(actions.goToSupport()),
});

export default connect(null, mapDispatchToProps)(MaintenanceComponent);
