import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import ReactPlayer from 'react-player';
import cn from 'src/utilities/bem-cn';

import ToggleSwitch from 'src/components/elements/ToggleSwitch';
import Collapse from 'src/components/shared/Collapse';
import SimpleRichText from 'src/components/inputs/SimpleRichText';
import QuillMentionUtil, { filterPipedQuestionLabel } from 'src/components/inputs/SimpleRichText/QuillMentionUtil';
import misc from 'src/utilities/misc';
import helpers from 'src/config/constants.js';
import MaskedErrorSingle from 'src/components/icons/MaskedErrorSingle';
import MaskedMulti from 'src/components/icons/MaskedMulti';
import MaskedSingle from 'src/components/icons/MaskedSingle';
import Emoji from 'src/components/shared/Emoji';
import Textarea from 'src/components/inputs/Textarea';
import AddHeatmapImage from 'src/components/elements/AddHeatmapImage';
import ScalePointInput from 'src/components/manage/questions/ScalePointInput';
import { Iconof } from '@upsiide/ui-components';

import './styles.scss';
import { getMeanScoreScaleOrder } from 'src/components/helpers';
import QuestionTypeIcon from '../QuestionTypeIcon';

const className = 'questions-view-mode';
const el = (name, mod) => cn(className, name, mod);
const { formattingOptions } = helpers;

const sortByOrder = items =>
	items?.sort((a, b) => {
		if (a.order === 0 && b.order === 0) return a.id > b.id ? 1 : -1;
		return a.order > b.order ? 1 : -1;
	}) || [];

const QuestionsViewMode = ({ questions, section, sections, selectedLanguage, renderLangTags = null, study }) => {
	const ideaSplitQuestions = useMemo(
		() => questions.filter(e => e.sectionType === 'monadic_split') || [],
		[questions],
	);

	const getSettingValue = (question, label, value = 'true') => {
		const setting = question.settings?.find(s => s?.label === label);
		return setting?.value === value;
	};

	const getLabel = item => {
		const label = item.translations?.find(translation => translation?.languageCode === selectedLanguage)?.label;
		return label;
	};

	const getNumberOfClicks = question => {
		const multiSelect = question.settings?.find(setting => setting?.label === 'click-limit-type');
		if (multiSelect?.value === 'unlimited') return 'Unlimited';

		const clickType = multiSelect?.value === 'range' ? 'Up To' : 'Exact';
		const clickLimit = question.settings?.find(setting => setting?.label === 'click-limit');

		return (
			<>
				{clickType} <span> {clickLimit?.value} </span>
			</>
		);
	};

	const getQuestionAsset = question =>
		question.translations?.find(translation => translation?.languageCode === selectedLanguage)?.asset;

	const renderQuestionAsset = question => {
		const asset = getQuestionAsset(question);
		const alignment = getSettingValue(question, 'question_image_align', 'left') ? 'flex-start' : 'center';

		if (asset?.url) {
			return misc.assetIsVideo(asset) ? (
				<div className={el('video-wrapper')}>
					<ReactPlayer url={asset?.url} width="262px" height="148px" controls />
				</div>
			) : (
				<div className={el('question-asset')} style={{ justifyContent: alignment }}>
					<img alt="" src={asset?.url} />
				</div>
			);
		}
		return null;
	};

	const hasAsset = option =>
		option.translations?.find(translation => translation?.languageCode === selectedLanguage)?.asset;

	const renderOptionAsset = option => {
		const asset = hasAsset(option);
		if (asset)
			return (
				<div className={el('asset')}>
					<img alt="" src={asset} />
				</div>
			);
		return null;
	};

	const renderGridOptions = question => {
		// * Check whether each answer has a scale point equal to its ordinal position (asc default). Scale points of 'Other' and 'None of the Above' options should be `null` by default
		const isDefaultScale = !getMeanScoreScaleOrder(question?.options)?.isCustom;

		return (
			<div className={el('grid')}>
				<div className={el('grid-container')}>
					<span className={el('grid-title')}>Attributes</span>
					{sortByOrder(question?.attributes)?.map(attribute => (
						<div className={el('option-wrapper')}>
							<div
								className={hasAsset(attribute) ? el('option-grid-asset') : el('option-grid')}
								key={attribute?.id}
							>
								<span>{getLabel(attribute)} </span>
								{renderOptionAsset(attribute)}
							</div>
						</div>
					))}
				</div>
				<div className={el('grid-container')}>
					<div className={el('titles-wrapper')}>
						{!!question?.addScale && <span className={el('scale-column-title')}>Scale</span>}

						<span className={el('grid-title')}>Options</span>
					</div>
					{sortByOrder(question?.options)?.map(option => (
						<div className={el('option-wrapper')}>
							{!!question?.addScale && (
								<ScalePointInput
									readOnly
									answer={option}
									answers={question?.options}
									questionId={question?.id}
									{...{ isDefaultScale }}
								/>
							)}

							<div
								className={hasAsset(option) ? el('option-grid-asset') : el('option-grid')}
								key={option?.id}
							>
								<div className={el('checkbox-wrapper')}>
									<div
										className={
											getSettingValue(question, 'multi-select') ? el('checkbox') : el('squarebox')
										}
									/>
								</div>
								<span>{getLabel(option)} </span>
								{renderOptionAsset(option)}
							</div>
						</div>
					))}
				</div>
			</div>
		);
	};

	const getCurrentLangLabel = useCallback(
		a => {
			if (a?.translations) {
				const currentOptionTranslation = a.translations.find(
					translation => translation?.languageCode === selectedLanguage,
				);
				if (currentOptionTranslation) return currentOptionTranslation.label;
				return '';
			}
			return a?.label;
		},
		[selectedLanguage],
	);

	const allQuestions = useMemo(
		() =>
			sections
				?.filter(sec => {
					if (sec?.type === 'questions') return true;
					if (sec?.type === 'monadic_split') {
						return section?.id === sec?.id;
					}
					return false;
				})
				?.map(sec => sec?.questions || [])
				?.flat(Infinity) || [],
		[sections, section],
	);

	const renderMaskedQuestionOption = (question, answer, index) => {
		const originQuestion = allQuestions.find(q => q.id === answer.maskedQuestionId);
		const isIdeaSplits = originQuestion?.sectionType === 'monadic_split';

		const currentQuestion = allQuestions.find(q => q.id === question.id);
		const currentQuestionNumber = allQuestions.indexOf(currentQuestion) + 1;

		const allQuestionsIndex = allQuestions.indexOf(originQuestion) + 1;
		const originQuestionNumber = (isIdeaSplits ? ideaSplitQuestions : allQuestions).indexOf(originQuestion) + 1;

		const maskingError = currentQuestionNumber <= allQuestionsIndex || !originQuestion;

		const singleSelect = !(question?.settings?.find(s => s.label === 'multi-select')?.value === 'true');

		const renderIcon = () => {
			if (maskingError) return <MaskedErrorSingle />;
			return singleSelect && question?.style !== 'ranked' ? (
				<MaskedSingle selected={answer?.logic} />
			) : (
				<MaskedMulti selected={answer?.logic} />
			);
		};

		let label = misc.stripHtmlTags(getCurrentLangLabel(originQuestion));
		if (QuillMentionUtil.isPipedQuestion(label)) {
			label = filterPipedQuestionLabel(label, questions);
		}

		label = `${isIdeaSplits ? 'Idea Split: ' : ''}Q${originQuestionNumber}. ${label}`;

		const getOptionLabel = () => {
			if (answer?.position && answer?.rank) {
				return `ranked ${answer?.position} ${answer?.rank}`;
			}

			return answer?.logic === 'selected' ? answer.logic : 'unselected';
		};

		const tooltipContent = () => (
			<div className={el('tooltip-content')}>
				<p>
					Recalling <span>{getOptionLabel()}</span> options from
				</p>
				<div className={el('tooltip-second-line')} style={{ flexDirection: isIdeaSplits ? 'column' : 'row' }}>
					<div dangerouslySetInnerHTML={{ __html: label }} />
				</div>
			</div>
		);
		return (
			<div className={`${el('masked-item')} ${maskingError && el('masked-error')}`}>
				{renderIcon()}
				<Tooltip
					id={`${className}-tooltip-${answer.questionId}-${answer.id}-${index}`}
					className={el('tooltip')}
					animation="shift"
					animationFill={false}
					trigger="mouseenter"
					theme="basic-text-tooltip"
					html={tooltipContent()}
					position="top-start"
					delay={500}
					disabled={maskingError}
				>
					{maskingError ? (
						<p>
							<span>Error: </span>This recall is broken. Delete this option
						</p>
					) : (
						<p>
							Recalling <span>{getOptionLabel()}</span> options from{' '}
							<span>
								{isIdeaSplits && 'Idea Split: '}Q{originQuestionNumber}
							</span>
						</p>
					)}
				</Tooltip>
			</div>
		);
	};

	const renderSelectOptions = question => {
		// * Check whether each answer has a scale point equal to its ordinal position (asc default). Scale points of 'Other' and 'None of the Above' options should be `null` by default
		const isDefaultScale = !getMeanScoreScaleOrder(question?.options)?.isCustom;

		return (
			<>
				{!!question?.addScale && <span className={el('scale-column-title')}>Scale</span>}

				{sortByOrder(question?.options)?.map((option, index) => (
					<div className={el('option-wrapper')}>
						{!!question?.addScale && (
							<ScalePointInput
								readOnly
								answer={option}
								answers={question?.options}
								questionId={question?.id}
								{...{ isDefaultScale }}
							/>
						)}

						<div style={{ flex: 1 }}>
							{!option?.maskedQuestionId ? (
								<div className={hasAsset(option) ? el('option-asset') : el('option')} key={option?.id}>
									<div className={el('checkbox-wrapper')}>
										{question?.style === 'ranked' ? (
											<Iconof
												className={el('drag-icon')}
												icon="drag"
												size="default"
												color="#666666"
											/>
										) : (
											<div
												className={
													getSettingValue(question, 'multi-select')
														? el('checkbox')
														: el('squarebox')
												}
											/>
										)}
									</div>
									<span>{getLabel(option)} </span>
									{renderOptionAsset(option)}
								</div>
							) : (
								<div className={el('masked_container')} key={option?.id}>
									{renderMaskedQuestionOption(question, option, index)}
									{renderOptionAsset(option)}
								</div>
							)}
						</div>
					</div>
				))}
			</>
		);
	};

	const getQuestionLabel = question => {
		let questionLabel = '';
		if (question.translations && question.translations.length > 0) {
			const currentQuestionTranslation = question.translations.find(
				translation => translation.languageCode === selectedLanguage,
			);
			if (currentQuestionTranslation) {
				questionLabel = currentQuestionTranslation.label;
			}
		} else if (question.label !== 'Untitled') {
			questionLabel = question.label;
		}
		// Check if question label has piped question --> look for {{}} format
		if (QuillMentionUtil.isPipedQuestion(questionLabel)) {
			const pipableQuestions = QuillMentionUtil.getPipedQuestionsList(question, section, sections);
			questionLabel = QuillMentionUtil.filterPipedQuestionLabel(questionLabel, pipableQuestions);
		}

		return questionLabel;
	};

	const getPlaceholderText = question => {
		if (question.translations) {
			// Look for default translation
			const defaultTranslation = question.translations.find(
				translation => translation.languageCode === selectedLanguage,
			);

			if (defaultTranslation && defaultTranslation.label) {
				let questionLabel = misc.stripHtmlTags(defaultTranslation.label);
				// Check if question label has piped question --> look for {{}} format
				if (QuillMentionUtil.isPipedQuestion(questionLabel)) {
					const pipableQuestions = QuillMentionUtil.getPipedQuestionsList(question, section, sections);
					questionLabel = QuillMentionUtil.filterPipedQuestionLabel(questionLabel, pipableQuestions, true);
				}
				return questionLabel;
			}
		}
		return 'Type here or use @ for recall';
	};

	const renderQuestionHeader = question => (
		<div className={el('header-container')}>
			<QuestionTypeIcon questionType={question?.style} sectionType={section?.type} />
			{renderLangTags ? renderLangTags() : null}
		</div>
	);

	const renderSelectQuestion = (question, i) => (
		<div className={el('question-content')} key={question?.id}>
			<div className={el('question-left')}>
				{renderQuestionHeader(question)}
				<div className={el('title-container')}>
					{question?.filterLabel ? <div className={el('question-name')}> {question.filterLabel} </div> : null}
					<div className={el('title-content')}>
						<span className={el('title-number')}>{i}.</span>

						<SimpleRichText
							pipingDropdownStyle={{ top: '55px' }}
							ref={null}
							value={getQuestionLabel(question)}
							onChange={() => false}
							placeholder={getPlaceholderText(question)}
							className=""
							question={question}
							section={section}
							sections={sections}
							language={selectedLanguage}
							audience={null}
							toolbarType={formattingOptions.questionStatement}
						/>
					</div>

					{renderQuestionAsset(question)}
				</div>

				<div className={el('question-options')}>
					{question.style === 'grid' ? renderGridOptions(question) : renderSelectOptions(question)}
				</div>
			</div>
			<div className={el('question-right')}>
				<span className={el('settings-title')}> Settings </span>
				<div>
					<div className={el('item disabled')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={!getSettingValue(question, 'optional')}
							text="Required"
							onToggle={() => false}
						/>
						<span className={el('item-label')}>Required</span>
					</div>
					<div className={el('item disabled')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={question?.randomizeOptions}
							text="Required"
							onToggle={() => false}
						/>
						<span className={el('item-label')}>Randomize options</span>
					</div>
					{question.style === 'grid' ? (
						<div className={el('item disabled')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={getSettingValue(question, 'randomize_attributes')}
								text="Required"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Randomize attributes</span>
						</div>
					) : null}
					{question?.style !== 'ranked' && (
						<div className={el('item disabled')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={getSettingValue(question, 'multi-select')}
								text="Required"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Multiple selection</span>
						</div>
					)}

					{question?.style !== 'ranked' && (
						<div className={el('item disabled')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={question?.addScale}
								text="Add Scale"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Add scale</span>
						</div>
					)}

					{question?.style === 'ranked' && (
						<div className={el('item disabled')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={getSettingValue(question, 'top-n')}
								text="Rank top n"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Rank top n</span>
						</div>
					)}

					<div className={el('image-settings')}>
						<Collapse title="Image Settings" />
					</div>
				</div>
			</div>
		</div>
	);

	const renderEmoji = (question, i) => (
		<div className={el('question-content')} key={question?.id}>
			<div className={el('question-left')}>
				{renderQuestionHeader(question)}
				<div className={el('title-container')}>
					{question?.filterLabel ? <div className={el('question-name')}> {question.filterLabel} </div> : null}
					<div className={el('title-content')}>
						<span className={el('title-number')}>{i}.</span>

						<SimpleRichText
							pipingDropdownStyle={{ top: '55px' }}
							ref={null}
							value={getQuestionLabel(question)}
							onChange={() => false}
							placeholder={getPlaceholderText(question)}
							className=""
							question={question}
							section={section}
							sections={sections}
							language={selectedLanguage}
							audience={null}
							toolbarType={formattingOptions.questionStatement}
						/>
					</div>

					{renderQuestionAsset(question)}
				</div>

				<div className={el('question-options')}>
					<div className={el('emoji-options')}>
						{question.options.map(option => {
							let translatedOption = option.translations.find(t => t.languageCode === selectedLanguage);

							if (!translatedOption) {
								[translatedOption] = option.translations;
							}

							let emojiName = option.translations.find(t => t.languageCode === 'en');
							if (!emojiName) {
								emojiName = translatedOption;
							}

							return (
								<Emoji
									key={option.id}
									emojiName={emojiName.label}
									label={translatedOption.label}
									tooltip={
										translatedOption.label.charAt(0).toUpperCase() + translatedOption.label.slice(1)
									}
								/>
							);
						})}
					</div>
				</div>
			</div>
			<div className={el('question-right')}>
				<span className={el('settings-title')}> Settings </span>
				<div>
					<div className={el('item disabled')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={!getSettingValue(question, 'optional')}
							text="Required"
							onToggle={() => false}
						/>
						<span className={el('item-label')}>Required</span>
					</div>
					<div className={el('item disabled')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={question?.randomizeOptions}
							text="Required"
							onToggle={() => false}
						/>
						<span className={el('item-label')}>Randomize options</span>
					</div>
					{question.style === 'grid' ? (
						<div className={el('item disabled')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={getSettingValue(question, 'randomize_attributes')}
								text="Required"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Randomize attributes</span>
						</div>
					) : null}
					<div className={el('item disabled')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={getSettingValue(question, 'multi-select')}
							text="Required"
							onToggle={() => false}
						/>
						<span className={el('item-label')}>Multiple selection</span>
					</div>

					<div className={el('image-settings')}>
						<Collapse title="Image Settings" />
					</div>
				</div>
			</div>
		</div>
	);

	const renderOpenEnded = (question, i) => (
		<div className={el('question-content')} key={question?.id}>
			<div className={el('question-left')}>
				{renderQuestionHeader(question)}
				<div className={el('title-container')}>
					{question?.filterLabel ? <div className={el('question-name')}> {question.filterLabel} </div> : null}
					<div className={el('title-content')}>
						<span className={el('title-number')}>{i}.</span>

						<SimpleRichText
							pipingDropdownStyle={{ top: '55px' }}
							ref={null}
							value={getQuestionLabel(question)}
							onChange={() => false}
							placeholder={getPlaceholderText(question)}
							className=""
							question={question}
							section={section}
							sections={sections}
							language={selectedLanguage}
							audience={null}
							toolbarType={formattingOptions.questionStatement}
						/>
					</div>

					{renderQuestionAsset(question)}
				</div>

				<div className={el('question-options')}>
					<Textarea
						className={el('text-area')}
						value="Open-ended response..."
						onChange={() => false}
						disabled
					/>
				</div>
			</div>
			<div className={el('question-right')}>
				<span className={el('settings-title')}> Settings </span>
				<div>
					<div className={el('item disabled')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={!getSettingValue(question, 'optional')}
							text="Required"
							onToggle={() => false}
						/>
						<span className={el('item-label')}>Required</span>
					</div>
					<div className={el('item disabled')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={getSettingValue(question, 'numeric')}
							text="Required"
							onToggle={() => false}
						/>
						<span className={el('item-label')}>Numeric Value Only</span>
					</div>

					<div className={el('image-settings')}>
						<Collapse title="Image Settings" />
					</div>
				</div>
			</div>
		</div>
	);

	const renderHeatmap = (question, i) => (
		<div className={el('question-content')} key={question?.id}>
			<div className={el('question-left')}>
				{renderQuestionHeader(question)}
				<div className={el('title-container')}>
					{question?.filterLabel ? <div className={el('question-name')}> {question.filterLabel} </div> : null}
					<div className={el('title-content')}>
						<span className={el('title-number')}>{i}.</span>

						<SimpleRichText
							pipingDropdownStyle={{ top: '55px' }}
							ref={null}
							value={getQuestionLabel(question)}
							onChange={() => false}
							placeholder={getPlaceholderText(question)}
							className=""
							question={question}
							section={section}
							sections={sections}
							language={selectedLanguage}
							audience={null}
							toolbarType={formattingOptions.questionStatement}
						/>
					</div>

					<AddHeatmapImage
						study={study}
						section={{
							...section,
							type: question.sectionType,
						}}
						question={question}
						asset={getQuestionAsset(question)}
					/>
				</div>

				<div className={el('question-options')}>{renderSelectOptions(question)}</div>
			</div>
			<div className={el('question-right')}>
				<span className={el('settings-title')}> Settings </span>
				<div>
					<div className={el('item disabled')}>
						<ToggleSwitch
							value={1}
							toggleCriteria={!getSettingValue(question, 'optional')}
							text="Required"
							onToggle={() => false}
						/>
						<span className={el('item-label')}>Required</span>
					</div>

					<div className={el('item_heatmap_container disabled')}>
						<span className={el('item-label')} style={{ marginLeft: 0 }}>
							Number of clicks allowed
						</span>
						<div className={el('item-number-click-container')}> {getNumberOfClicks(question)}</div>
					</div>

					<div className={el('item_heatmap_container disabled')}>
						<div className={el('item_heatmap')}>
							<ToggleSwitch
								value={1}
								toggleCriteria={!getSettingValue(question, 'optional-comments')}
								text="Required"
								onToggle={() => false}
							/>
							<span className={el('item-label')}>Collect Comments</span>
						</div>
						<span className={el('sub-item-label')}>When switched off only clicks will be collected</span>
					</div>
				</div>
			</div>
		</div>
	);

	const renderQuestions = () => {
		if (questions?.length) {
			return questions.map((question, i) => {
				if (question?.style === 'emoji') return renderEmoji(question, i + 1);
				if (question?.style === 'open-ended') return renderOpenEnded(question, i + 1);
				if (question?.style === 'heatmap') return renderHeatmap(question, i + 1);
				return renderSelectQuestion(question, i + 1);
			});
		}
		return 'No questions';
	};

	return <div className={el('container')}>{renderQuestions()}</div>;
};

QuestionsViewMode.displayName = 'QuestionsViewMode';
QuestionsViewMode.propTypes = {
	questions: PropTypes.array,
	section: PropTypes.object,
	selectedLanguage: PropTypes.string,
	sections: PropTypes.any,
	renderLangTags: PropTypes.any,
	study: PropTypes.any,
};

export default QuestionsViewMode;
