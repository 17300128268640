import React, { useState } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import Select from 'src/components/inputs/Select';
import { calculateTotalOptions } from 'src/components/helpers';
import './styles.scss';

const className = 'dropdown-recalling';
const el = (name, mod) => cn(className, name, mod);

const RecallRankedDropdown = ({ maskingType, question, selectQuestion, sections }) => {
	const [position, setPosition] = useState();
	const [rank, setRank] = useState();
	const [rankOpen, setRankOpen] = useState(false);
	const [positionOpen, setPositionOpen] = useState(false);

	const onPositionChange = e => {
		setPosition(e);
		bothComplete(e, rank);
	};

	const onRankChange = e => {
		setRank(e);
		bothComplete(position, e);
	};

	const bothComplete = (p, r) => {
		const positionText = p === 2 ? 'bottom' : 'top';
		if (p && r) {
			selectQuestion(question, maskingType, positionText, r);
		}
	};

	const positionOptions = [
		{ value: 1, label: 'Top' },
		{ value: 2, label: 'Bottom' },
	];

	const totalOptions = [...new Set(calculateTotalOptions(question?.options, { content: sections }))].length;
	const topNSet = question?.settings?.find(s => s?.label === 'top-n')?.value === 'true';
	const rankTopNLimit = topNSet
		? parseInt(question?.settings?.find(s => s?.label === 'top-n-limit')?.value) || 1
		: Math.min(totalOptions || 0, 10);
	const rankOptions = [];
	const maxRankOptions = totalOptions >= rankTopNLimit ? rankTopNLimit : totalOptions;
	for (let i = 1; i <= maxRankOptions; i += 1) {
		rankOptions.push({ value: i, label: i });
	}

	return (
		<div className={el('ranked-question-inner-container')}>
			<Select
				onClick={() => setRankOpen(!!rankOpen)}
				style={{ marginRight: 10 }}
				value={parseInt(position)}
				placeholder="Position"
				onChange={e => {
					onPositionChange(e);
				}}
				options={positionOptions}
				className={el('position-select')}
				classNamePrefix="position-select"
				menuIsOpen={rankOpen}
				onMenuOpen={() => {
					setRankOpen(true);
				}}
				onMenuClose={() => {
					setTimeout(() => {
						setRankOpen(false);
					}, 100);
				}}
			/>

			<Select
				value={rank}
				placeholder="Rank"
				onChange={e => {
					onRankChange(e);
				}}
				options={rankOptions}
				className={el('rank-select')}
				classNamePrefix="rank-select"
				menuIsOpen={positionOpen}
				onMenuOpen={() => {
					setPositionOpen(true);
				}}
				onMenuClose={() => {
					setTimeout(() => {
						setPositionOpen(false);
					}, 100);
				}}
			/>
		</div>
	);
};

RecallRankedDropdown.propTypes = {
	maskingType: PropTypes.any,
	question: PropTypes.any,
	sections: PropTypes.any,
	selectQuestion: PropTypes.any,
};

export default RecallRankedDropdown;
