import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import store from 'src/store';

const { location } = window;
const isBlueprint = location.pathname.includes('/templates');
const selectors = isBlueprint ? manageBlueprintSelectors : manageStudySelectors;

const haveLiveAudienceGroups = () => {
	const state = store.getState();
	const audienceCollection = manageStudySelectors.getAudienceCollection(state);
	const currentStudy = manageStudySelectors.getStudy(state);

	if (!currentStudy) {
		return false;
	}

	const hasLiveAudienceGroup = audienceCollection?.content?.demographicGroups?.find(
		group => group.status === 'live' || group.status === 'system-stopped' || group.status === 'system-paused',
	);
	return !!hasLiveAudienceGroup;
};

const haveCompleteAudienceGroups = () => {
	const state = store.getState();
	const audienceCollection = manageStudySelectors.getAudienceCollection(state);
	const currentStudy = manageStudySelectors.getStudy(state);

	if (!currentStudy) {
		return false;
	}

	const hasCompleteAudienceGroup = audienceCollection?.content?.demographicGroups?.find(
		group => group.status === 'complete',
	);
	return !!hasCompleteAudienceGroup;
};

const languageCodesInGroups = () => {
	const languageCodesArray = [];
	const state = store.getState();
	const audienceCollection = selectors.getAudienceCollection(state);
	audienceCollection.content?.demographicGroups?.forEach(g => {
		if (g.languageCode && !languageCodesArray.includes(g.languageCode)) {
			languageCodesArray.push(g.languageCode);
		}
	});
	return languageCodesArray;
};

const getIRIssue = () => {
	const state = store.getState();
	const audienceCollection = manageStudySelectors.getAudienceCollection(state);
	return !!audienceCollection?.content?.demographicGroups?.find(group => group?.status === 'system-stopped');
};

const studyHasIssues = () => {
	const validationErrorsArray = [];
	const state = store.getState();
	const translationValidations = selectors.getTranslationValidations(state);
	const languageCodes = languageCodesInGroups();
	const IRIssue = getIRIssue();
	languageCodes?.forEach(languageCode => {
		if (
			translationValidations &&
			translationValidations[
				Object.keys(translationValidations)?.find(
					key => translationValidations[key]?.languageCode === languageCode,
				)
			]?.errorType?.length > 0
		) {
			translationValidations[
				Object.keys(translationValidations)?.find(
					key => translationValidations[key]?.languageCode === languageCode,
				)
			]?.errorType?.forEach(error => {
				if (!validationErrorsArray.find(er => er === error)) {
					validationErrorsArray.push(error);
				}
			});
		}
	});
	const translationIssue = validationErrorsArray.length > 0;
	return !!IRIssue || translationIssue;
};

const haveByoGroupsWithoutSampleSize = () => {
	const state = store.getState();
	const studySelectors = isBlueprint ? manageBlueprintSelectors : manageStudySelectors;
	const audienceCollection = studySelectors.getAudienceCollection(state);
	const byoWithNoSampleSize = audienceCollection?.content?.demographicGroups?.some(
		group => group?.provider === 'BYO' && group?.sampleSize === 0,
	);
	return byoWithNoSampleSize;
};

export default {
	haveLiveAudienceGroups,
	haveCompleteAudienceGroups,
	languageCodesInGroups,
	studyHasIssues,
	getIRIssue,
	haveByoGroupsWithoutSampleSize,
};
