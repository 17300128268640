import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import cn from 'src/utilities/bem-cn';
import { uniq } from 'lodash';
import { Button, FormField, Iconof, UPThemeProvider } from '@upsiide/ui-components';
import { tagColors } from 'src/utilities/misc';
import toastr from 'toastr';
import { useIsPublic } from 'src/hooks';
import useIsMobile from 'src/hooks/useIsMobile';
import PropTypes from 'prop-types';
import { termService, studyService } from 'src/services';
import Auth from 'src/utilities/auth';
import Chip from 'src/components/shared/Chip';
import { useSelector, useDispatch } from 'react-redux';
import useOutsideClick from 'src/hooks/useOutsideClick';
import Modal from 'src/components/shared/Modal';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import { getOrderedStudyTags, getOrderedProductTags } from 'src/domains/all-studies/selectors';
import { upsertStudyTags, upsertProductTags } from 'src/domains/all-studies/actions';
import { upsertProduct, upsertStudy } from '../../../domains/all-studies/actions';
import productService from '../../../services/product.service';

import './styles.scss';

const PER_PAGE = 10;

const className = 'study-tags';
const el = (name, mod) => cn(className, name, mod);

export const ColorChip = React.memo(({ tag, handleRemoveTag, classMod, canUpdate }) => {
	const textColor = tagColors?.find(item => item.lighter === tag?.color)?.darker || '#3B3B3B';

	if (!tag) {
		return null;
	}

	return (
		<Chip
			key={tag.id}
			customClass={el('ellipsis-chip', classMod || null)}
			style={{
				backgroundColor: tag?.color ? tag?.color : 'initial',
				color: textColor,
			}}
		>
			<div className={el('template-chip-text')}>{tag.label}</div>

			{canUpdate && tag.id && typeof handleRemoveTag === 'function' ? (
				<Iconof
					icon="clear"
					className={el('remove-tag-from-study')}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						handleRemoveTag(tag);
					}}
				/>
			) : null}
		</Chip>
	);
});
ColorChip.displayName = 'ColorChip';
ColorChip.propTypes = {
	tag: PropTypes.object,
	handleRemoveTag: PropTypes.func,
	classMod: PropTypes.string,
	canUpdate: PropTypes.bool,
};

const TagItem = ({ tag, canUpdate, setEditTag }) => {
	const editRef = useRef(null);

	const handleSetEditTag = editTag => {
		/** List Dimensions and Positioning */
		const viewportOffset = editRef?.current?.getBoundingClientRect();
		const y = viewportOffset?.top;
		const x = viewportOffset?.left;
		const width = viewportOffset?.width;
		const top = y + window.scrollY;
		const left = x + window.scrollX;

		setEditTag({ tag: editTag, positioning: { y, x, top, left, width } });
	};

	return (
		<div className={el('tag-item')} ref={editRef}>
			<ColorChip tag={tag} canUpdate={canUpdate} />

			<Iconof
				size="default"
				icon="more_vertical"
				color="grey"
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					handleSetEditTag(tag);
				}}
			/>
		</div>
	);
};
TagItem.propTypes = {
	tag: PropTypes.object,
	canUpdate: PropTypes.bool,
	setEditTag: PropTypes.func,
};

const TagList = React.forwardRef(
	(
		{
			listPositioning,
			allTags,
			tags,
			studyId,
			productId,
			localProductId,
			fetchTags,
			handleUpsertTag,
			handleUpsertStudy,
			handleUpsertProduct,
			search,
			setSearch,
			currentPage,
			setCurrentPage,
			hideScrollObserver,
			setHideScrollObserver,
			canUpdate,
			setEditTag,
			editTag,
			setOpen,
			onOpenClose,
			onUpdate,
		},
		ref,
	) => {
		const [nextTagColor, setNextTagColor] = useState(tagColors[Math.floor(Math.random() * tagColors.length)]);
		const scrollObserver = useRef();
		const [height, setHeight] = useState(0);
		const inputRef = useRef(null);

		const handleAddNewTag = useCallback(
			async tag => {
				// check if tag already exists, if so bail
				const tagExistsAlready = tags?.some(currentTag => currentTag.id === tag.id);
				if (tagExistsAlready) {
					return;
				}

				// if its an empty tag, reset the input field without creating a new tag
				if (tag?.label?.trim() === '') return;

				const randomId = Math.ceil(Math.random() * 1000);

				if (search !== '') {
					setSearch('');
				}

				const color = tag?.color || nextTagColor.lighter;

				setNextTagColor(tagColors[Math.floor(Math.random() * tagColors.length)]);

				let responseTags = [];

				const optimisticId = `optimistic${randomId}`;

				handleUpsertTag([
					{
						id: optimisticId,
						label: tag?.label,
						color,
						taxonomy: productId ? 'productTags' : 'studyTags',
					},
				]);

				if (!productId) {
					// optimistically update studyTags
					handleUpsertStudy({
						id: studyId,
						tags: [...tags, { id: optimisticId, label: tag?.label, color, taxonomy: 'studyTags' }],
					});

					try {
						const response = await studyService.patchStudy(studyId, {
							newTag: { label: tag?.label, color },
						});

						// reset to data from server
						responseTags = response?.data?.tags || [];
						handleUpsertStudy({
							id: studyId,
							tags: responseTags,
						});
					} catch (e) {
						toastr.error(`Something went wrong! Please try again.\n\n${e}`);
					}
				} else {
					// Using tag manager in instance where the product has not yet been created...
					handleUpsertProduct({
						id: productId,
						tags: [...tags, { id: optimisticId, label: tag?.label, color, taxonomy: 'productTags' }],
					});

					try {
						const response = await productService.patch(studyId, localProductId, {
							newTag: { label: tag?.label, color },
						});

						// reset to data from server
						responseTags = response?.tags || [];
						handleUpsertProduct({
							id: productId,
							tags: responseTags,
						});
					} catch (e) {
						toastr.error(`Something went wrong! Please try again.\n\n${e}`);
					}
					// }
				}

				responseTags = [...responseTags, { id: optimisticId, actionType: 'remove' }];

				handleUpsertTag(responseTags);
			},
			[
				tags,
				search,
				nextTagColor.lighter,
				handleUpsertTag,
				productId,
				setSearch,
				handleUpsertStudy,
				studyId,
				handleUpsertProduct,
				localProductId,
			],
		);

		const handleRemoveTag = useCallback(
			async tag => {
				try {
					if (!productId) {
						const filteredTags = tags.filter(studyTag => studyTag.id !== tag.id);

						handleUpsertStudy({
							id: studyId,
							tags: filteredTags,
						});

						await studyService.removeStudyTag(studyId, tag?.id);
					} else {
						handleUpsertProduct({
							id: productId,
							tags: [...tags.filter(productTag => productTag.id !== tag.id)],
						});

						await productService.removeProductTag(studyId, localProductId, tag?.id);
					}
				} catch (e) {
					toastr.error(`Something went wrong! Please try again.\n\n${e}`);
				}
			},
			[productId, tags, handleUpsertStudy, studyId, handleUpsertProduct, localProductId],
		);

		const handleSearchChange = useCallback(
			e => {
				setSearch(e.target.value);
			},
			[setSearch],
		);

		const handleKeyUp = useCallback(
			e => {
				if (e.key === 'Enter' && e.target.value !== '') {
					handleAddNewTag({ label: e.target.value });
					setSearch('');
				}
			},
			[handleAddNewTag, setSearch],
		);

		const renderDisplayCreate = useMemo(() => {
			const tagExists = allTags?.some(tag => tag?.label.toLowerCase() === search.toLowerCase());

			if (!tagExists && search?.trim().length) {
				return true;
			}

			return false;
		}, [allTags, search]);

		const searchedTags = useMemo(() => {
			// search filter
			if (search !== '') {
				return allTags?.filter(tag => tag?.label.toLowerCase().includes(search.toLowerCase()));
			}

			return allTags;
		}, [allTags, search]);

		const endOfListRef = useCallback(
			node => {
				if (scrollObserver.current) scrollObserver.current.disconnect();
				scrollObserver.current = new IntersectionObserver(entries => {
					if (entries[0].isIntersecting) {
						setHideScrollObserver(true);

						setCurrentPage(prev => {
							const newPage = prev + 1;
							fetchTags({ page: newPage, searchString: search });
							return newPage;
						});
					}
				});
				if (node) scrollObserver.current.observe(node);
			},
			[fetchTags, search, setCurrentPage, setHideScrollObserver],
		);

		useLayoutEffect(() => {
			// sets height
			setHeight(prev => {
				if (ref?.current?.offsetHeight > prev) {
					return ref?.current?.offsetHeight;
				}
				return prev;
			});
			// NOTE: only recalculate height when tags change
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [allTags]);

		useEffect(() => {
			// re-focus input when editTag closes
			if (!editTag?.tag?.id) {
				inputRef?.current?.focus();
			}
		}, [editTag]);

		const editManagerHeight = height;

		const hasSpaceBelow = listPositioning.viewport.height - listPositioning.list.y - editManagerHeight > 0;

		let finalPositioningTop = listPositioning.list.top + listPositioning.offset.top;
		if (!hasSpaceBelow) {
			finalPositioningTop = listPositioning.list.top + listPositioning.offset.top - editManagerHeight;
		}

		const finalPositioning = {
			top: finalPositioningTop,
			left: listPositioning.list.left + listPositioning.offset.left,
			width: listPositioning.list.width,
		};

		return ReactDOM.createPortal(
			<UPThemeProvider>
				<div
					ref={ref}
					className={el('manage-tag-container')}
					style={{
						top: `${finalPositioning.top}px`,
						left: `${finalPositioning.left}px`,
						width: `${finalPositioning.width}px`,
					}}
				>
					<div className={el('manage-tag-header')}>
						{tags?.map(tag => (
							<ColorChip
								key={tag?.id}
								tag={tag}
								handleRemoveTag={handleRemoveTag}
								classMod="interactive"
								canUpdate={canUpdate}
							/>
						))}

						<FormField
							ref={inputRef}
							maxLength={50}
							value={search}
							autoFocus
							wrapperClassName={el('add-tag-input')}
							name="addTagInput"
							placeholder={tags.length ? null : 'Search for an option...'}
							onKeyUp={handleKeyUp}
							onChange={handleSearchChange}
							onBlur={e => {
								if (!editTag?.tag?.id) {
									e.target.focus();
								}
							}}
						/>
					</div>
					<div className={el('manage-tag-body')}>
						{finalPositioning.width > 190 ? (
							<span className={el('manage-tag-body-header-text')}>Select an option or create one</span>
						) : null}
						<div className={el('manage-tag-body-tag-list-container')}>
							{searchedTags?.map(tag => (
								<div
									key={tag?.id}
									className={el(
										'manage-tag-body-tag-item',
										editTag?.tag?.id === tag?.id ? 'editing' : '',
									)}
									role="button"
									onKeyDown={() => {}}
									tabIndex={0}
									onClick={() => {
										handleAddNewTag(tag);
									}}
								>
									<TagItem tag={tag} canUpdate={canUpdate} setEditTag={setEditTag} />
								</div>
							))}

							{!hideScrollObserver && searchedTags?.length >= PER_PAGE ? (
								<div ref={endOfListRef} className={el('scroll-observer')} />
							) : null}

							{renderDisplayCreate ? (
								<div
									className={el('manage-tag-body-tag-item')}
									role="button"
									onKeyDown={() => {}}
									tabIndex={0}
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();
										handleAddNewTag({ label: search });
									}}
								>
									<div className={el('create-tag')}>
										<span>Create</span>
										<Chip
											customClass={`${el('ellipsis-chip')} ${el('template-chip-text')}`}
											style={{
												backgroundColor: nextTagColor.lighter,
												color: nextTagColor.darker,
											}}
										>
											{search?.trim()}
										</Chip>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</UPThemeProvider>,
			document.body,
		);
	},
);

TagList.displayName = 'TagList';
TagList.propTypes = {
	listPositioning: PropTypes.object,
	allTags: PropTypes.array,
	tags: PropTypes.array,
	studyId: PropTypes.number,
	productId: PropTypes.number,
	localProductId: PropTypes.number,
	fetchTags: PropTypes.func,
	handleUpsertTag: PropTypes.func,
	handleUpsertStudy: PropTypes.func,
	handleUpsertProduct: PropTypes.func,
	search: PropTypes.string,
	setSearch: PropTypes.func,
	setCurrentPage: PropTypes.func,
	currentPage: PropTypes.number,
	hideScrollObserver: PropTypes.bool,
	setHideScrollObserver: PropTypes.func,
	canUpdate: PropTypes.bool,
	setEditTag: PropTypes.func,
	editTag: PropTypes.object,
	setOpen: PropTypes.func,
	onOpenClose: PropTypes.func,
	onUpdate: PropTypes.func,
};

const EditTag = forwardRef(
	({ editTag, listPositioning, handleOnBlur, handleOnKeyUp, handleDelete, handleChangeColor }, ref) => {
		const managerHeight = 400; // NOTE if this changes update CSS class height

		const remainingSpace = listPositioning.viewport.height - editTag.positioning.y - managerHeight;
		const hasSpaceBelow = remainingSpace > 0;

		let finalPositioningTop = editTag.positioning.top + listPositioning.offset.top;
		if (!hasSpaceBelow) {
			finalPositioningTop = editTag.positioning.top + remainingSpace;
		}

		const marginLeft = 10;

		const finalPositioning = {
			top: finalPositioningTop,
			left: listPositioning.list.left + listPositioning.offset.left + listPositioning.list.width + marginLeft,
			width: listPositioning.list.width,
		};

		return ReactDOM.createPortal(
			<div
				className={el('edit-tag-container', editTag?.tag?.id ? 'open' : '')}
				style={{
					top: `${finalPositioning?.top}px`,
					left: `${finalPositioning?.left}px`,
				}}
				ref={ref}
			>
				<div className={el('manage-tag-header', 'flex-column align-start')}>
					<FormField
						autoFocus
						maxLength={30}
						key={editTag?.tag?.id}
						wrapperClassName={el('edit-tag-input')}
						name="editTagInput"
						defaultValue={editTag?.tag?.label}
						onBlur={handleOnBlur}
						onKeyUp={handleOnKeyUp}
					/>

					<Button
						label="Delete"
						variant="text"
						onClick={handleDelete}
						buttonClassName={el('delete-tag-button')}
					/>
				</div>

				<div className={el('manage-tag-body')}>
					<div className={el('manage-tag-body-tag-list-container')}>
						<span className={el('manage-tag-body-tag-list-container-colors-label')}>Colors</span>

						{tagColors?.map(tagColor => (
							<div
								key={tagColor?.lighter}
								className={el(
									'manage-tag-body-tag-item',
									tagColor?.lighter === editTag?.tag?.color ? 'selected' : '',
								)}
								role="button"
								onKeyDown={() => {}}
								tabIndex={0}
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									handleChangeColor(tagColor);
								}}
							>
								<div className={el('manage-tag-body-tag-color-wrapper')}>
									<div
										style={{
											backgroundColor: tagColor?.lighter,
											border: `1px solid ${tagColor?.darker}`,
											width: '19px',
											height: '19px',
											borderRadius: '2px',
										}}
									/>
									<span className={el('manage-tag-body-tag-color-name')}>
										{tagColor?.name || tagColor?.darker}
									</span>
								</div>

								<div className={el('selected-check')}>
									<svg
										width="9"
										height="9"
										viewBox="0 0 9 9"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8.8479 2.29555L3.2279 7.91555C3.13717 8.01222 3.01049 8.06706 2.8779 8.06706C2.74531 8.06706 2.61863 8.01222 2.5279 7.91555L0.147899 5.56555C0.0532428 5.47166 0 5.34386 0 5.21055C0 5.07723 0.0532428 4.94943 0.147899 4.85555L0.677899 4.32555C0.768633 4.22887 0.895313 4.17403 1.0279 4.17403C1.16049 4.17403 1.28717 4.22887 1.3779 4.32555L2.8779 5.81555L7.6179 1.07555C7.81232 0.884974 8.12348 0.884974 8.3179 1.07555L8.8479 1.60555C9.03203 1.79869 9.03203 2.1024 8.8479 2.29555Z"
											fill="#28B681"
										/>
									</svg>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>,
			document.body,
		);
	},
);
EditTag.displayName = 'EditTag';
EditTag.propTypes = {
	editTag: PropTypes.object,
	listPositioning: PropTypes.object,
	handleOnBlur: PropTypes.func,
	handleOnKeyUp: PropTypes.func,
	handleDelete: PropTypes.func,
	handleChangeColor: PropTypes.func,
};

const StudyTagManager = ({
	studyId,
	productId,
	localProductId,
	currentTags = [],
	buttonLabel = null,
	onUpdate = null,
	onOpenClose = null,
	top = -9,
	left = -18,
	syncCurrentTagsOnMount = false,
	defaultTagIds = [],
	disableInteractivity,
	canEdit = true,
	children,
}) => {
	const dispatch = useDispatch();
	const isMobile = useIsMobile();
	const isSearchingRef = useRef(false);
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [hideScrollObserver, setHideScrollObserver] = useState(false);
	const [fetchedTagIds, setFetchedTagIds] = useState([]);
	const [editTag, setEditTag] = useState({});
	const [listPositioning, setListPositioning] = useState({});

	const { isPublic } = useIsPublic();
	const canUpdate = !isPublic && Auth.userCan('study:update');

	const searchTimeoutRef = useRef();

	const tagsContainerRef = useRef(null);
	const tagsListRef = useRef(null);
	const manageTagRef = useRef(null);

	useOutsideClick([tagsContainerRef, tagsListRef], () => {
		if (open && !editTag?.tag?.id) {
			setOpen(false);
			setEditTag({});
			setSearch('');

			if (typeof onOpenClose === 'function') {
				onOpenClose('close');
			}
		}
	});

	useOutsideClick(manageTagRef, () => {
		setEditTag({});
	});

	const handleListPositioning = useCallback(() => {
		/** Viewport size */
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;

		/** List Dimensions and Positioning */
		const viewportOffset = tagsContainerRef?.current?.getBoundingClientRect();
		const listTop = viewportOffset?.top;
		const listLeft = viewportOffset?.left;
		const listWidth = viewportOffset?.width;

		setListPositioning({
			viewport: {
				width: viewportWidth,
				height: viewportHeight,
			},
			list: {
				width: listWidth,
				top: listTop + window.scrollY,
				left: listLeft + window.scrollX,
				y: listTop,
				x: listLeft,
			},
			offset: {
				left,
				top,
			},
		});
	}, [left, top]);

	const handleOpenTagMenu = e => {
		if (isMobile) {
			return;
		}
		if (!canEdit || !canUpdate || open || showDeleteConfirm || disableInteractivity) return;

		handleListPositioning();

		fetchTags({});
		setOpen(true);

		if (typeof onOpenClose === 'function') {
			onOpenClose('open');
		}
	};

	useEffect(() => {
		if (open) {
			handleListPositioning();
		}
	}, [handleListPositioning, open]);

	const allTags = useSelector(productId ? getOrderedProductTags : getOrderedStudyTags).map(tag => ({
		...tag,
		id: tag.id || tag.termId,
		termId: tag.id || tag.termId,
	}));
	const tagIds = currentTags?.map(tag => tag?.id);
	const tags = allTags?.filter(tag => tagIds.includes(tag?.id));

	const tagsToDisplay = useMemo(
		() => allTags.filter(tag => uniq([...fetchedTagIds, ...defaultTagIds]).includes(tag?.id)),
		[allTags, defaultTagIds, fetchedTagIds],
	);

	const fetchTags = useCallback(
		async ({ page = 0, searchString = '', upsertData = [] } = {}) => {
			if (isSearchingRef.current) {
				return;
			}

			isSearchingRef.current = true;

			const tagLimit = PER_PAGE;
			const offset = tagLimit * page;
			const searchQuery = searchString;

			const { data: studyTagData } = await termService.getAllTerms(
				tagLimit,
				offset,
				searchQuery,
				productId ? 'productTags' : 'studyTags',
				isPublic,
			);

			const currentItems = allTags || [];
			const upsertAction = productId ? upsertProductTags : upsertStudyTags;

			const ids = studyTagData?.map(tag => tag.id);
			setFetchedTagIds(prev => [...prev, ...ids]);

			if (page === 0) {
				dispatch(upsertAction([...studyTagData, ...upsertData]));
			} else {
				dispatch(upsertAction([...currentItems, ...studyTagData, ...upsertData]));
			}

			if (studyTagData?.length >= tagLimit) {
				setHideScrollObserver(false);
			}

			isSearchingRef.current = false;
		},
		[allTags, dispatch, isPublic, productId],
	);

	const handleUpsertTag = useCallback(
		data => {
			const upsertAction = productId ? upsertProductTags : upsertStudyTags;

			const upsertData = Array.isArray(data) ? data : [data];
			dispatch(upsertAction(upsertData));
		},
		[dispatch, productId],
	);

	const handleUpsertStudy = useCallback(
		data => {
			if (typeof onUpdate === 'function') {
				onUpdate(data);
			} else {
				dispatch(upsertStudy(data));
			}
		},
		[dispatch, onUpdate],
	);

	const handleUpsertProduct = useCallback(
		data => {
			if (typeof onUpdate === 'function') {
				onUpdate(data);
			} else {
				dispatch(upsertProduct(data));
			}
		},
		[dispatch, onUpdate],
	);

	const handleConfirmDelete = useCallback(
		async tagId => {
			const upsertAction = productId ? upsertProductTags : upsertStudyTags;

			dispatch(
				upsertAction([
					{
						id: tagId,
						actionType: 'remove',
					},
				]),
			);

			try {
				await termService.deleteTerm(tagId);
			} catch (e) {
				toastr.error(`Something went wrong! Please try again.\n\n${e}`);
			}

			if (typeof onUpdate === 'function') {
				const filteredTags = tags?.filter(tag => tag.id !== tagId);
				const filteredTagIds = filteredTags.map(tag => tag.id);

				onUpdate({ tags: filteredTags });
				setFetchedTagIds(filteredTagIds);
			}

			setShowDeleteConfirm(false);
		},
		[dispatch, onUpdate, productId, tags],
	);

	const handleSetSearch = useCallback(
		val => {
			if (val !== '') {
				setFetchedTagIds([]);
				setCurrentPage(0);
			}

			setSearch(val);

			if (searchTimeoutRef.current) {
				clearTimeout(searchTimeoutRef.current);
			}

			searchTimeoutRef.current = setTimeout(() => {
				fetchTags({ page: 0, searchString: val });
			}, 500);
		},
		[fetchTags],
	);

	const handlePatchTag = useCallback(
		data => {
			const mappedTags = tags?.map(tag => {
				if (tag.id === data.id) {
					return data;
				}

				return tag;
			});

			if (!productId) {
				// upsert to redux state
				handleUpsertStudy({
					id: studyId,
					tags: mappedTags,
				});
			} else {
				handleUpsertProduct({
					id: productId,
					tags: mappedTags,
				});
			}
		},
		[handleUpsertStudy, handleUpsertProduct, studyId, productId, tags],
	);

	const handleUpdateTag = useCallback(
		async data => {
			let taxonomy = 'studyTags';
			if (productId) {
				taxonomy = 'productTags';
			}

			handleUpsertTag({
				id: editTag?.tag?.id,
				...data,
			});

			setEditTag(prev => ({
				...prev,
				tag: {
					...prev.tag,
					...data,
				},
			}));

			handlePatchTag({ id: editTag?.tag?.id, ...data });

			try {
				await termService.updateTerm(taxonomy, editTag?.tag?.id, data);
			} catch (e) {
				toastr.error(`Something went wrong! Please try again.\n\n${e}`);
			}
		},
		[handlePatchTag, handleUpsertTag, productId, editTag?.tag?.id],
	);

	const handleOnKeyUp = useCallback(
		e => {
			if (e.key === 'Enter') {
				handleUpdateTag({ label: e.target.value, color: editTag?.tag?.color });
			}
		},
		[handleUpdateTag, editTag?.tag?.color],
	);

	const handleOnBlur = useCallback(
		e => {
			if (editTag?.tag?.label !== e.target.value) {
				handleUpdateTag({ label: e.target.value, color: editTag?.tag?.color });
			}
		},
		[handleUpdateTag, editTag],
	);

	const handleChangeColor = useCallback(
		color => {
			handleUpdateTag({ label: editTag?.tag?.label, color: color?.lighter });
		},
		[handleUpdateTag, editTag?.tag?.label],
	);

	const handleDelete = useCallback(async () => {
		setEditTag({});
		setShowDeleteConfirm(editTag?.tag?.id);
	}, [setShowDeleteConfirm, editTag?.tag?.id]);

	useEffect(() => {
		if (syncCurrentTagsOnMount) {
			const upsertAction = productId ? upsertProductTags : upsertStudyTags;

			dispatch(upsertAction(currentTags));
		}
		// NOTE: This should only run on mount to sync current tags with redux state
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let label = buttonLabel;
	if (!label) {
		label = currentTags?.length ? null : 'Add Tags';
	}

	return (
		<div
			className={el('container', open ? 'open' : '')}
			onClick={e => handleOpenTagMenu(e)}
			aria-hidden
			ref={tagsContainerRef}
		>
			{children}

			{canUpdate ? (
				<>
					<div className={el('tag-manager-container', open ? 'open' : '')}>
						{open ? (
							<>
								{editTag?.tag?.id ? (
									<EditTag
										ref={manageTagRef}
										editTag={editTag}
										listPositioning={listPositioning}
										handleOnBlur={handleOnBlur}
										handleOnKeyUp={handleOnKeyUp}
										handleDelete={handleDelete}
										handleChangeColor={handleChangeColor}
									/>
								) : null}

								<TagList
									ref={tagsListRef}
									listPositioning={listPositioning}
									allTags={tagsToDisplay}
									tags={tags}
									studyId={studyId}
									productId={productId}
									localProductId={localProductId}
									fetchTags={fetchTags}
									handleUpsertTag={handleUpsertTag}
									handleUpsertStudy={handleUpsertStudy}
									handleUpsertProduct={handleUpsertProduct}
									search={search}
									setSearch={handleSetSearch}
									setCurrentPage={setCurrentPage}
									hideScrollObserver={hideScrollObserver}
									setHideScrollObserver={setHideScrollObserver}
									canUpdate={canUpdate}
									setEditTag={setEditTag}
									editTag={editTag}
									setOpen={setOpen}
									onOpenClose={onOpenClose}
									onUpdate={onUpdate}
									setShowDeleteConfirm={tagId => {
										setShowDeleteConfirm(tagId);
										if (typeof onOpenClose === 'function') {
											onOpenClose('close');
										}
									}}
								/>
							</>
						) : null}
					</div>

					<Modal
						show={Boolean(showDeleteConfirm)}
						width={600}
						padding={0}
						onClose={() => setShowDeleteConfirm(false)}
					>
						<AreYouSureForm
							onCancel={() => setShowDeleteConfirm(false)}
							onConfirm={() => handleConfirmDelete(showDeleteConfirm)}
							headerText="Delete Tag"
							bodyText={
								<span className={el('modal-text')}>
									The tag will be deleted from all {!productId ? 'studies' : 'ideas'}. <br />{' '}
									<strong>This action cannot be undone.</strong> Would you like to proceed?
								</span>
							}
							confirmText="Delete Tag"
							confirmState="active"
							confirmType="red"
						/>
					</Modal>
				</>
			) : null}
		</div>
	);
};

StudyTagManager.propTypes = {
	studyId: PropTypes.number,
	productId: PropTypes.number,
	localProductId: PropTypes.number,
	currentTags: PropTypes.array,
	buttonLabel: PropTypes.string,
	onUpdate: PropTypes.func,
	onOpenClose: PropTypes.func,
	top: PropTypes.number,
	left: PropTypes.number,
	syncCurrentTagsOnMount: PropTypes.bool,
	defaultTagIds: PropTypes.array,
	disableInteractivity: PropTypes.bool,
	canEdit: PropTypes.bool,
	children: PropTypes.any,
};

export default StudyTagManager;
