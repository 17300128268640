import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as misc from 'src/utilities/misc';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'product-report-donut-chart';
const el = name => cn(className, name);

const ProductReportDonutChart = ({ data = [], strokeWidth = 3.25 }) => {
	const idea = useMemo(() => data?.label === 'Idea', [data]);

	const getStyle = useCallback(() => {
		if (data?.value === 0) return { stroke: 'transparent' };
		if (data?.label === 'Idea' || data?.label === 'Idea Score')
			return {
				stroke: misc.getChartColors(data?.color).stroke,
			};
		return {};
	}, [data]);

	return (
		<div className={`${className} svg-item`}>
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 40 40"
				className={`${el('donut')} ${idea && el('idea-donut')}`}
			>
				<circle className={el('donut-hole')} cx="20" cy="20" r="15.91549430918954" fill="#fff" />
				<circle
					className={el('donut-ring')}
					cx="20"
					cy="20"
					r="15.91549430918954"
					fill="transparent"
					strokeWidth="0.5"
				/>
				<circle
					className={`${el('donut-segment')} ${!idea && el('light-blue')}`}
					cx="20"
					cy="20"
					r="15.91549430918954"
					fill="transparent"
					strokeWidth={strokeWidth}
					strokeDasharray={`${data.value} ${100 - data.value}`}
					style={getStyle()}
					transform="rotate(-90 20 20)"
				/>

				<g className={el('donut-text')}>
					{data?.label ? (
						<text y="34%" transform="translate(0, 2)">
							<tspan x="50%" textAnchor="middle" className="donut-label">
								{data.label}
							</tspan>
						</text>
					) : null}
					<text y={data?.label ? '65%' : '50%'} transform="translate(0, 2)">
						<tspan
							x="50%"
							textAnchor="middle"
							className="donut-data"
							style={
								idea
									? {
											fill: misc.getChartColors(data.color).text,
											fontWeight: '600',
									  }
									: {}
							}
						>
							{data.value || 0}
						</tspan>
					</text>
				</g>
			</svg>
		</div>
	);
};

ProductReportDonutChart.propTypes = {
	data: PropTypes.any,
	strokeWidth: PropTypes.number,
};

export default ProductReportDonutChart;
