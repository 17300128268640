import React from 'react';
import DisqualificationIcon from 'src/components/icons/Disqualification';
import QuestionsCheckmarkIcon from 'src/components/icons/QuestionCheckmark';
import MonadicSplitIcon from 'src/components/icons/MonadicSplit';
import { Iconof } from '@upsiide/ui-components';
import QuillMentionUtil from 'src/components/inputs/SimpleRichText/QuillMentionUtil';
import parse from 'html-react-parser';
import misc from 'src/utilities/misc';

let enhancedSelectIndex = 0;

const getIsOptions = () => {
	const isText = 'is';
	const isNotText = 'is not';
	const isOption = { value: 0, label: isText, chipLabel: isText };
	const isNotOption = { value: 1, label: isNotText, chipLabel: isNotText };
	return [isOption, isNotOption];
};

const getTopBottomOptions = () => {
	const topText = 'Top';
	const bottomText = 'Bottom';
	const isTop = { value: 0, label: topText, chipLabel: topText };
	const isBottom = { value: 1, label: bottomText, chipLabel: bottomText };
	return [isTop, isBottom];
};
const getAnswersSelectOptions = (options, currentLanguage) => {
	if (options) {
		const optionTranslations = options.filter(
			item =>
				(item.translations &&
					item.translations.length > 0 &&
					item.translations.find(translation => translation.languageCode === currentLanguage)) ||
				item.translations[0],
		);

		const result = optionTranslations.map((item, index) => {
			const itemTranslation =
				item.translations && item.translations.length > 0
					? item.translations.find(translation => translation.languageCode === currentLanguage) ||
					  item.translations[0]
					: null;

			return {
				// For our API purposes
				id: item.id,
				languageCode: item.languageCode,
				isSelected: item.isSelected,
				order: item.order,
				acceptableAnswer: item.acceptableAnswer,
				// For React-Select
				label: itemTranslation ? itemTranslation.label : item.label,
				chipLabel: itemTranslation ? itemTranslation.label : item.label,
				value: item.id,
			};
		});

		return result;
	}
	return [];
};
const getSelectTitleOption = title => {
	const titleText = title;
	const result = {
		value: -1,
		label: <div className="placeholder borderBottom">{titleText}</div>,
		isDisabled: true,
		data: {},
	};
	return result;
};
const getSelectHeadingOption = (prefix, name) => {
	let prefixText = prefix || '';
	if (prefix === 'swipe') {
		prefixText = 'Idea Screen';
	}
	if (prefix === 'monadic_split') {
		prefixText = 'Idea Split';
	}

	const nameText = name ? ` - ${name}` : '';
	const displayHeading = `${prefixText.charAt(0).toUpperCase() + prefixText.slice(1)}${nameText}`;
	return {
		value: -1,
		label: <div className="heading">{displayHeading}</div>,
		isDisabled: true,
		data: {},
	};
};
const getOperandOptions = isSingleSelect => {
	const orOption = [{ value: 1, label: 'or', chipLabel: 'or' }];
	const andOrOption = [
		{ value: 0, label: 'and', chipLabel: 'and' },
		{ value: 1, label: 'or', chipLabel: 'or' },
	];
	const options = isSingleSelect ? orOption : andOrOption;
	return options;
};
const getSelectDisqualificationOption = value => {
	const disqualificationText = 'Disqualification';
	const markup = (
		<div className="section">
			<DisqualificationIcon />
			<span>{disqualificationText}</span>
		</div>
	);
	return {
		value,
		chipLabel: markup,
		label: markup,
		data: {
			actionSectionId: null,
			actionItemId: null,
		},
	};
};

const getSelectQuestionItemOption = (
	item,
	section,
	direction,
	sections,
	unexpandedClass,
	prefixText,
	nameText,
	expandedClass,
) => {
	const getMarkup = (markupClassName, displayName) => {
		let displayNameCopy = displayName;
		if (QuillMentionUtil.isPipedQuestion(displayName)) {
			const pipableQuestions = QuillMentionUtil.getPipedQuestionsList(item, section, sections);
			displayNameCopy = QuillMentionUtil.filterPipedQuestionLabel(displayName, pipableQuestions, false, false);
		}
		return (
			<div className={markupClassName}>
				{item.type === 'product' ? (
					<Iconof
						className="logic-condition__swipe-icon logic-condition__centre-icon"
						icon="gesture_swipe_left_right"
						size="default"
					/>
				) : (
					<QuestionsCheckmarkIcon />
				)}
				<span>{parse(displayNameCopy)}</span>
			</div>
		);
	};

	const result = {
		value: item?.id,
		chipLabel: getMarkup(unexpandedClass, direction === 0 ? prefixText : nameText),
		label: getMarkup(expandedClass, nameText),
		data: {
			actionSectionId: section.id,
			actionItemId: item.id,
			attributes: item.attributes,
			options: item.options,
			itemId: item.id,
			itemType: item.type,
			itemIndex: item.itemIndex,
			sidebarIndex: item.sidebarIndex,
			questionType: item.style,
		},
	};
	return result;
};

const getSelectOtherItemOptions = (study, prefix, section, givenValue) => {
	const { language, currentLanguage } = study;
	const selectedLanguage = language || currentLanguage;
	const prefixText = prefix || '';
	const nameText = section.name ? ` - ${section.name}` : '';
	const displayHeading = `${prefixText.charAt(0).toUpperCase() + prefixText.slice(1)}${nameText}`;
	const unexpandedClass = 'section';
	const expandedClass = 'section borderBottom';
	const getMarkup = markupClassName => (
		<div className={markupClassName}>
			{prefix.toLowerCase() === 'idea split' && <MonadicSplitIcon />}
			{prefix.toLowerCase() === 'statement' && (
				<Iconof
					className="logic-condition__statement-icon logic-condition__centre-icon"
					icon="quotes"
					size="default"
				/>
			)}
			{prefix.toLowerCase() === 'idea screen' && (
				<Iconof
					className="logic-condition__swipe-icon logic-condition__centre-icon"
					icon="gesture_swipe_left_right"
					size="default"
				/>
			)}
			<span>{displayHeading}</span>
		</div>
	);
	const result = {
		value: givenValue || enhancedSelectIndex,
		chipLabel: getMarkup(unexpandedClass),
		label: getMarkup(expandedClass),
		data: {
			itemId: section.id,
			itemType: 'product',
			sidebarIndex: section.sidebarIndex,
			options: [
				{
					// For our API purposes
					id: 0,
					itemId: section.id,
					languageCode: selectedLanguage,
					order: 0,
					acceptableAnswer: 1,
					// For React-Select
					label: 'Swipe Left',
					chipLabel: 'Swipe Left',
					value: 0,
					translations: [
						{
							label: 'Swipe Left',
							languageCode: selectedLanguage,
						},
					],
				},
				{
					// For our API purposes
					id: 1,
					itemId: section.id,
					languageCode: selectedLanguage,
					isSelected: 0,
					order: 1,
					acceptableAnswer: 1,
					// For React-Select
					label: 'Swipe Right',
					chipLabel: 'Swipe Right',
					value: 1,
					translations: [
						{
							label: 'Swipe Right',
							languageCode: selectedLanguage,
						},
					],
				},
			],
			actionSectionId: section.id,
			actionItemId: null,
		},
	};
	enhancedSelectIndex += 1;
	return result;
};

const disableSelectedAnswers = (answerOptions, selectedIndexes) => {
	const updatedAnswerOptions = answerOptions;
	updatedAnswerOptions.forEach(answer => (answer.isDisabled = false));
	selectedIndexes.forEach(item => {
		if (updatedAnswerOptions[item]) {
			updatedAnswerOptions[item].isDisabled = true;
		}
	});

	return updatedAnswerOptions;
};

const getEnhancedSectionData = (sectionsToEnhance, allSections, studyId) => {
	let questionIndex = 0;
	const enhancedSection = sectionsToEnhance.map((s, sIndex) => {
		if ((s.type === 'monadic_split' && s.questions) || s.type === 'questions') {
			return {
				...s,
				sidebarIndex: sIndex,
				questions: s?.questions?.map((q, qIndex) => {
					const questionNumber =
						allSections.content
							.filter(c => c.type === 'questions')
							.map(c => c.questions)
							.reduce((prev, curr) => prev.concat(curr), [])
							.findIndex(i => i.id === q.id) + 1;

					questionIndex += 1;

					return {
						...q,
						itemIndex: questionIndex,
						itemShortname: s.type === 'monadic_split' ? `Q${qIndex + 1}` : `Q${questionNumber}`,
						sidebarIndex: sIndex,
					};
				}),
			};
		}
		if (s.type === 'swipe') {
			return {
				...s,
				sidebarIndex: sIndex,
				products: s.products.map(q => {
					const swipeLeftOption = {
						// For our API purposes
						id: 0,
						languageCode: q.languageCode,
						isSelected: 0,
						order: 0,
						acceptableAnswer: 1,
						// For React-Select
						label: 'Swipe Left',
						chipLabel: 'Swipe Left',
						value: 0,
						translations: [
							{
								label: 'Swipe Left',
								languageCode: q.languageCode,
							},
						],
					};
					const swipeRightOption = {
						// For our API purposes
						id: 1,
						languageCode: q.languageCode,
						isSelected: 0,
						order: 1,
						acceptableAnswer: 1,
						// For React-Select
						label: 'Swipe Right',
						chipLabel: 'Swipe Right',
						value: 1,
						translations: [
							{
								label: 'Swipe Right',
								languageCode: q.languageCode,
							},
						],
					};

					return {
						...q,
						itemIndex: q.id,
						itemShortname: `I${q.id}`,
						sidebarIndex: sIndex,
						type: 'product',
						label: q.name,
						options: [swipeLeftOption, swipeRightOption],
						studyId,
					};
				}),
			};
		}

		return { ...s };
	});

	return enhancedSection;
};

export default {
	getIsOptions,
	getTopBottomOptions,
	getAnswersSelectOptions,
	getSelectTitleOption,
	getSelectHeadingOption,
	getOperandOptions,
	getSelectDisqualificationOption,
	getSelectQuestionItemOption,
	getSelectOtherItemOptions,
	disableSelectedAnswers,
	getEnhancedSectionData,
};
