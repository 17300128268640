import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

function getLanguage(languageId) {
	return axios.get(`${API_PATH}/languages/${languageId}`);
}

function getLanguages() {
	return axios.get(`${API_PATH}/languages`);
}

function getPublicLanguages() {
	return axios.get(`${API_PATH}/public/languages`);
}

function getGeographies() {
	return axios.get(`${API_PATH}/geographies`);
}

function getPublicGeographies() {
	return axios.get(`${API_PATH}/public/geographies`);
}

function getCountries() {
	return axios.get(`${API_PATH}/countries`);
}

function getCountryLanguages(countryId) {
	return axios.get(`${API_PATH}/countries/${countryId}/languages`);
}

export default {
	getLanguage,
	getLanguages,
	getPublicLanguages,
	getGeographies,
	getPublicGeographies,
	getCountries,
	getCountryLanguages,
};
