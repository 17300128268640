const prefix = 'create-client';

export const CREATE_CLIENT = `${prefix}/CREATE_CLIENT`;
export const createClient = client => ({ type: CREATE_CLIENT, payload: client });

export const FETCH_LANGUAGES = `${prefix}/FETCH_LANGUAGES`;
export const fetchLanguages = () => ({ type: FETCH_LANGUAGES });

export const SET_LANGUAGES = `${prefix}/SET_LANGUAGES`;
export const setLanguages = languages => ({ type: SET_LANGUAGES, payload: { languages } });

export const SET_LOADING = `${prefix}/SET_LOADING`;
export const setLoading = isLoading => ({ type: SET_LOADING, payload: { loading: isLoading } });

export const SET_SUCCESS = `${prefix}/SET_SUCCESS`;
export const setSuccess = success => ({ type: SET_SUCCESS, payload: { success } });

export const SET_ERROR = `${prefix}/SET_LOADING`;
export const setError = error => ({ type: SET_LOADING, payload: { error } });
