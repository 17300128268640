import CONSTANTS from 'src/config/constants';
import misc from 'src/utilities/misc';
import masking from 'src/utilities/masking';

export const MAX_PLACEHOLDER_LENGTH = 60;
export const PIPING_REGEX_STR = `{{${CONSTANTS.pipingReference}:\\s*([1-9][0-9]+|invalid)(,\\s*${CONSTANTS.pipingLogic}:\\s*(selected|not-selected))*(,\\s*${CONSTANTS.pipingPosition}:\\s*(top|bottom))*(,\\s*${CONSTANTS.pipingRank}:\\s*([1-9]|10))*}}`;
export const PIPING_ERROR_PILL = '<span class="mention mention-error">@Error</span>';

const PIPING_REGEX = new RegExp(PIPING_REGEX_STR, 'g');
const QUESTION_TYPES = CONSTANTS.questions.options.style;
const isPipedQuestion = question => PIPING_REGEX.test(question);

// Grabs all eligible piping questions for a particular question (i.e. all previous questions among all sections that are either select or emoji type)
const getPipedQuestionsList = (question = null, section, sections) => {
	const prevQuestions = [];
	let prevQuestionSections =
		sections && sections.content
			? sections.content.filter(sect => sect.type === 'questions' && sect.order < section.order)
			: [];

	if (sections && sections instanceof Array && !sections?.content) {
		prevQuestionSections = sections.filter(sect => sect.type === 'questions' && sect?.order < section?.order);
	}

	let splitsQuestionCount = 1;
	let questionCount = 1;
	// Add all previous question sections select and emoji type questions to list of eligible piping question options
	prevQuestionSections.forEach(sect => {
		sect.questions.forEach(q => {
			q.studyQuestionNumber = questionCount;
			questionCount += 1;
			if (
				q.style === QUESTION_TYPES.multiSelect ||
				q.style === QUESTION_TYPES.singleSelect ||
				q.style === QUESTION_TYPES.emoji || 
				q.style === QUESTION_TYPES.ranked
			) {
				q.sectionType = 'questions';
				prevQuestions.push(q);
			}
		});
	});
	// Add all current section previous select and emoji type questions to list of eligible piping question options
	if (!section) {
		return prevQuestions;
	}

	const questions = section.questions || section.statements;
	if (questions && questions.length) {
		questions.forEach(q => {
			if (section.type === 'monadic_split') {
				q.studyQuestionNumber = splitsQuestionCount;
				splitsQuestionCount += 1;
			} else {
				q.studyQuestionNumber = questionCount;
				questionCount += 1;
			}
			if (
				(q.style === QUESTION_TYPES.multiSelect ||
					q.style === QUESTION_TYPES.singleSelect ||
					q.style === QUESTION_TYPES.emoji || 
					q.style === QUESTION_TYPES.ranked) &&
				question &&
				(question.studyQuestionNumber
					? q.studyQuestionNumber < question.studyQuestionNumber
					: q.sortOrder < question.sortOrder)
			) {
				q.sectionType = section.type;
				prevQuestions.push(q);
			}
		});
	}
	return prevQuestions;
};

// Filters piping questions to allow for use in quill-mention dropdown
const getAtValues = pipableQuestions => {
	let atValues = [];
	return atValues;
	const questionSectionQuestions = pipableQuestions.filter(q => q.sectionType === 'questions') || [];
	const splitSectionQuestions = pipableQuestions.filter(q => q.sectionType === 'monadic_split') || [];

	// Add all pipable question section questions
	if (questionSectionQuestions && questionSectionQuestions.length > 0) {
		atValues = questionSectionQuestions.map(q => ({
			id: q.id,
			value: `Q${q.studyQuestionNumber}`,
			question: q,
			studyQuestionNumber: q.studyQuestionNumber,
			logic: 'selected',
		}));

		questionSectionQuestions.forEach(q => {
			atValues.push({
				id: q.id,
				value: `!Q${q.studyQuestionNumber}`,
				question: q,
				studyQuestionNumber: q.studyQuestionNumber,
				logic: 'not-selected',
			});
		});
	}

	// If splits section, add all pipable splits section questions, as well as splits dropdown header
	if (splitSectionQuestions && splitSectionQuestions.length > 0) {
		atValues.push({
			id: `piping-dropdown-splits-header`,
			disabled: true,
		});
		splitSectionQuestions.forEach((q, i) =>
			atValues.push({
				id: q.id,
				value: `Idea Split: Q${q.studyQuestionNumber}`,
				question: q,
				studyQuestionNumber: q.studyQuestionNumber,
			}),
		);
	}

	// If no pipable questions, add empty state
	if (questionSectionQuestions.length === 0 && splitSectionQuestions.length === 0) {
		atValues = [
			{
				id: `piping-dropdown-empty-state`,
				disabled: true,
			},
		];
	}

	// Add dropdown header
	atValues.unshift({
		id: `piping-dropdown-header`,
		disabled: true,
	});

	return atValues;
};

// Returns array of all questions IDs that are piped into a question/statement label
const getQuestionIds = label => {
	// Required to run matchAll() on dragEnd of questions
	const TEMP_PIPING_REGEX = new RegExp(PIPING_REGEX);
	const matches = [...label.matchAll(TEMP_PIPING_REGEX)];
	const questionsIds = matches.map(([_, match]) => match);
	return questionsIds;
};
const getQuestionIdsAndLogics = label => {
	if (!label) {
		return [];
	}
	// Required to run matchAll() on dragEnd of questions
	const TEMP_PIPING_REGEX = new RegExp(PIPING_REGEX);
	const matches = [...label.matchAll(TEMP_PIPING_REGEX)];
	const questionsIds = matches.map(match => {
		if (match.length === 4) {
			return {
				questionId: match[1],
				logic: match[3],
			};
		}
		return {
			questionId: match[1],
			logic: 'selected',
		};
	});
	return questionsIds;
};

const getQuestionIdsAndLogicsFromStatement = (statement, currentLanguage) => {
	if (!statement?.translations?.length) {
		return [];
	}
	const translation = statement?.translations?.find(stmt => stmt?.languageCode === currentLanguage);
	const label = translation?.text;
	if (!label) {
		return [];
	}
	// Required to run matchAll() on dragEnd of questions
	const TEMP_PIPING_REGEX = new RegExp(PIPING_REGEX);
	const matches = [...label.matchAll(TEMP_PIPING_REGEX)];
	const questionsIds = matches.map(match => {
		if (match.length === 4) {
			return {
				questionId: match[1],
				logic: match[3],
			};
		}
		return {
			questionId: match[1],
			logic: 'selected',
		};
	});
	return questionsIds;
};

// Returns array of all questions that are piped into a question/statement label
const getPipedQuestions = (label, questions) => {
	const questionIds = getQuestionIds(label);
	const pipedQuestions = [];

	questions.forEach((q, i) => {
		if (questionIds.includes(q.id.toString())) {
			q.indexInSection = i;
			pipedQuestions.push(q);
		}
	});

	return pipedQuestions;
};

const getCompletePipedQuestions = (label, questions, isStatement = false, currentLanguage) => {
	const questionIds = isStatement
		? getQuestionIdsAndLogicsFromStatement(label, currentLanguage)
		: getQuestionIdsAndLogics(label);

	return questionIds.map(pipe => ({
		...pipe,
		questionIndex: questions?.findIndex(question => Number(question?.id) === Number(pipe?.questionId)),
	}));
};

export const getQuestionSetting = (question, label, defaultValue) => {
	if (!question || !question.settings) return defaultValue;

	const settings = question.settings.find(s => s.label === label);
	return settings && settings.value === 'true';
};

// Required to render question label - removes {{pipedBlock:___}} content from question label and replaces with quill-mention formatted <span> tag containing piped question information
// Required to render question label - removes {{pipedBlock:___}} content from question label and replaces with question pill span for areas of the app that do not have quill.js editor (i.e. logic dropdown, section sidebar, template list, idea split list, etc.)
export const filterPipedQuestionLabel = (label, pipableQuestions, placeholder = false, editor = true) => {
	const newLabel = label.replace(PIPING_REGEX, (match, questionId) => {
		let replacementString = match;
		const isNotSelectedLogic = match?.includes('not-selected');
		const logic = isNotSelectedLogic ? 'not-selected' : 'selected';
		const positionExists = match?.includes('position');
		let position = null;

		if (positionExists && match?.includes('top')) {
			position = 'top';
		} else if (positionExists && match?.includes('bottom')) {
			position = 'bottom';
		}

		const rankExists = match?.includes('rank');
		let rank = null;

		if (rankExists) {
			rank = parseInt(match.split("rank:")[1].match(/\d+/)[0]);
		}
		const pipedQuestion = pipableQuestions.find(q => q.id.toString() === questionId);
		const isOptional = getQuestionSetting(pipedQuestion, 'optional', false);
		const isMultiSelection = getQuestionSetting(pipedQuestion, 'multi-select', false) || pipedQuestion?.style === 'ranked';
		const selectedIcon = masking.getIconByLogic(logic, isMultiSelection);

		const optionalClass = isOptional ? 'optional' : '';
		const optionalIcon = isOptional ? '<span class="tio-warning default base-size-warning-tip__icon"></span>' : '';
		const questionIndex =
			pipedQuestion?.studyQuestionNumber ||
			pipableQuestions?.findIndex(q => q?.id?.toString() === questionId) + 1;

		if (pipedQuestion) {
			const isSplits = pipedQuestion.sectionType === 'monadic_split';
			const dataValue = `${isSplits ? 'Idea Split: ' : ''}Q${questionIndex}`;
			if (editor) {
				replacementString = `<span class="${optionalClass} mention ${
					isNotSelectedLogic ? 'not-selected' : ''
				} ${rankExists ? 'ranked-label' : ''}" data-index="0" 
				data-logic="${logic}" 
				data-denotation-char="@" 
				data-id="${questionId}" 
				data-value="${dataValue}" 
				data-position="${position}" 
				data-rank="${rank}">
					${optionalIcon}<span>${selectedIcon}${dataValue}${positionExists && rankExists ? ` ranked ${position} ${rank}` : ''}</span>
				</span>`;
				if (placeholder) {
					replacementString = `@${dataValue}`;
				}
			} else {
				replacementString = `<span class="${optionalClass} mention">
					${optionalIcon}${selectedIcon}${dataValue}${positionExists && rankExists ? ` ranked ${position} ${rank}` : ''}
				</span>`;
			}
		} else {
			// eslint-disable-next-line no-lonely-if
			if (editor) {
				if (placeholder) {
					replacementString = match ? '' : 'Type here or use @ for recall';
				} else {
					replacementString = `<span class="mention mention-error" data-index="0" data-denotation-char="@" data-id="" data-value="Error"><span><span class="ql-mention-denotation-char">@</span>Error</span></span>`;
				}
			} else {
				replacementString = PIPING_ERROR_PILL;
			}
		}
		return replacementString;
	});
	return newLabel;
};

export const simplePipedQuestionLabel = (label, piped, isOptional, sectionType = 'questions') => {
	let indexCounter = 0;

	const newLabel = label.replace(PIPING_REGEX, (match, questionId) => {
		if (match.includes('invalid')) {
			return PIPING_ERROR_PILL;
		}

		let replacementString = match;
		const isNotSelectedLogic = match?.includes('not-selected');

		const selectedIcon = masking.getIconByLogic(isNotSelectedLogic ? 'not-selected' : 'selected', false);

		const optionalClass = isOptional ? 'optional' : '';
		const optionalIcon = isOptional ? '<span class="tio-warning default base-size-warning-tip__icon"></span>' : '';
		const questionIndex = piped[indexCounter]?.order || 1;

		const isSplits = sectionType === 'monadic_split';
		const dataValue = `${isSplits ? 'Idea Split: ' : ''}Q${questionIndex}`;

		replacementString = `<span class="${optionalClass} mention">${optionalIcon}${selectedIcon}${dataValue}</span>`;

		indexCounter += 1;
		return replacementString;
	});
	return newLabel;
};

// Required to render question label in section sidebar - removes {{pipedBlock:___}} content from question label and replaces with piped question information
// Note - section sidebar does not use quill.js, so no use of quill-mention required
export const renderPipedQuestionLabel = (label, pipableQuestions) => {
	const pipedQuestionIds = getQuestionIds(label);
	let labelCopy = label;
	let replacementString = '';
	const firstIndex = labelCopy.indexOf(`{{`);
	const lastIndex = labelCopy.indexOf('}}');

	if (pipedQuestionIds && pipedQuestionIds.length > 0) {
		pipedQuestionIds.forEach((id, index) => {
			const pipedQuestion = pipableQuestions.find(q => q.id === parseInt(id));
			const spanToRemove = labelCopy.substring(firstIndex, lastIndex + 2);
			if (pipedQuestion) {
				const isSplits = pipedQuestion.sectionType === 'monadic_split';
				replacementString = `<span class='mention'>${isSplits ? 'Idea Split: ' : ''}Q${
					pipedQuestion.studyQuestionNumber ? pipedQuestion.studyQuestionNumber : index + 1
				}</span>`;
				labelCopy = labelCopy.replace(spanToRemove, replacementString);
			} else {
				replacementString = `<span class="mention mention-error" data-index="0" data-denotation-char="@" data-id="" data-value="Error"><span><span class="ql-mention-denotation-char">@</span>Error</span></span>`;
				labelCopy = labelCopy.replace(spanToRemove, replacementString);
			}
		});
	} else {
		const spanToRemove = labelCopy.substring(firstIndex, lastIndex + 2);
		replacementString = `<span class="mention mention-error" data-index="0" data-denotation-char="@" data-id="" data-value="Error"><span><span class="ql-mention-denotation-char">@</span>Error</span></span>`;
		labelCopy = labelCopy.replace(spanToRemove, replacementString);
	}

	return labelCopy;
};

export const renderLabelWithEmptyQuestions = label => {
	if (!label) return label;
	const replacementString = `<span class="empty-state"></span>`;
	const labelWithEmptyQuestions = label.replace(PIPING_REGEX, replacementString);

	return labelWithEmptyQuestions;
};

// Used to acquire piped question Id from original question label string containing {{questionId:__}} format as stored in the database
export const getPipedQuestionId = questionLabel => {
	const pipedQuestion = questionLabel.substring(questionLabel.indexOf('{'), questionLabel.lastIndexOf('}') + 1);
	const pipedQuestionId = parseInt(
		pipedQuestion.substring(pipedQuestion.indexOf(':') + 1, pipedQuestion.indexOf('}')),
	);
	return pipedQuestionId;
};

export const pipedQuestionLabelCleanup = label => {
	const htmlLabel = new DOMParser().parseFromString(label, 'text/html');
	const $mentions = Array.from(htmlLabel.querySelectorAll('.mention'));
	$mentions.forEach(e => {
		const { value: questionId } = Array.from(e.attributes).find(a => a.nodeName === 'data-id');
		const { value: logic } = Array.from(e.attributes).find(a => a.nodeName === 'data-logic') || {
			value: 'selected',
		};

		const { value: position} = Array.from(e.attributes).find(a => a.nodeName === 'data-position') || {
			value: 'undefined',
		};

		const { value: rank} = Array.from(e.attributes).find(a => a.nodeName === 'data-rank') || {
			value: 'undefined',
		};

		let insertText = `{{pipedBlock: ${questionId || 'invalid'}, logic: ${logic}}}`;

		if (position !== 'undefined' && rank !== 'undefined' && position !== 'null' && rank !== 'null') {
			insertText = `{{pipedBlock: ${questionId || 'invalid'}, logic: ${logic}, position: ${position}, rank: ${rank}}}`;
		}
		
		e.insertAdjacentHTML('beforebegin', insertText);

		e.parentElement.removeChild(e);
	});

	return htmlLabel.documentElement.outerHTML
		.replace('<html>', '')
		.replace('</html>', '')
		.replace('<head>', '')
		.replace('</head>', '')
		.replace('<body>', '')
		.replace('</body>', '')
		.replace(/\s{2,}/g, ' ');
};

export const getPipingError = (draggedQuestion, movedQuestion, questions, pipingLocation, dragResult) => {
	let pipingError = false;
	let pipedQuestions = [];
	let pipedQuestionsTwo = [];
	const dragDirection = dragResult.source.index > dragResult.destination.index ? 'up' : 'down';

	if (pipingLocation === 'dragged') {
		pipedQuestions = getPipedQuestions(draggedQuestion.label, questions); // Will not include pipedQuestions that are in another section

		// If dragged question has piping, will cause issues if dragged question is moved UP
		if (pipedQuestions && pipedQuestions.length > 0) {
			if (dragDirection === 'up') {
				// Check if piped question studyNumber is before new index
				pipedQuestions.forEach((q, i) => {
					if (q.indexInSection >= dragResult.destination.index) {
						pipingError = true;
					}
				});
			}
		}
	} else if (pipingLocation === 'moved') {
		pipedQuestions = getPipedQuestions(movedQuestion.label, questions); // Will not include pipedQuestions that are in another section

		// If moved question has piping, will cause issues if dragged question is moved DOWN
		if (pipedQuestions && pipedQuestions.length > 0) {
			if (dragDirection === 'down') {
				// Check if piped question studyNumber is before new index
				pipedQuestions.forEach((q, i) => {
					if (q.indexInSection <= dragResult.destination.index) {
						pipingError = true;
					}
				});
			}
		}
	} else if (pipingLocation === 'both') {
		pipedQuestions = getPipedQuestions(draggedQuestion.label, questions); // Will not include pipedQuestions that are in another section
		pipedQuestionsTwo = getPipedQuestions(movedQuestion.label, questions); // Will not include pipedQuestions that are in another section

		if (pipedQuestions && pipedQuestions.length > 0) {
			if (dragDirection === 'up') {
				// Check if piped question studyNumber is before new index
				pipedQuestions.forEach((q, i) => {
					if (q.indexInSection >= dragResult.destination.index) {
						pipingError = true;
					}
				});
			}
		}

		if (pipedQuestionsTwo && pipedQuestionsTwo.length > 0) {
			if (dragDirection === 'down') {
				// Check if piped question studyNumber is before new index
				pipedQuestionsTwo.forEach((q, i) => {
					if (q.indexInSection <= dragResult.destination.index) {
						pipingError = true;
					}
				});
			}
		}
	}

	return pipingError;
};

// Replaces all instances of {{pipedBlock:xxxx}} with {{pipedBlock:invalid}}
const updatePipingErrorLabel = label => {
	const PIPING_REPLACE_REGEX = new RegExp(`(.*?{{${CONSTANTS.pipingReference}:\\s*)([1-9][0-9]*)(}}.*?)`, 'g');
	return label.replace(PIPING_REPLACE_REGEX, '$1invalid$3');
};

// Replaces only certain instances of {{pipedBlock:xxxx}} with {{pipedBlock:invalid}}
const updatePipingErrorLabelSpecific = (label, ids) => {
	let newLabel = label;
	ids.forEach(id => {
		const PIPING_REPLACE_REGEX = new RegExp(`(.*?{{${CONSTANTS.pipingReference}:\\s*)(${id}*)(.*}}.*?)`, 'g');
		newLabel = newLabel.replace(PIPING_REPLACE_REGEX, '$1invalid$3');
	});
	return newLabel;
};

// This function is used to obtain all questions in a study that have a certain question piped into their question labels. This is used on delete of a question to check if they are involved with any piping later on in the study.
const getPipedQuestionsWithQuestionId = (question, section, sections, language) => {
	const pipedElements = [];
	const pipedLabels = [];
	if (section) {
		const pipableSections = sections.filter(s => s.type !== 'swipe' && s.order >= section.order);
		if (question) {
			pipableSections.forEach(s => {
				if (s.questions) {
					s.questions.forEach(q => {
						const questionTranslation =
							q.translations && q.translations.find(t => t.languageCode === language);
						if (
							questionTranslation &&
							questionTranslation.label &&
							questionTranslation.label.includes(question.id.toString())
						) {
							q.sectionId = s.id;
							q.sectionType = s.type;
							q.sectionName = s.name;
							q.sectionOrder = s.order + 1;
							if (!q.studyQuestionNumber) {
								q.studyQuestionNumber = misc.getStudyQuestionNumber(q, s, sections);
							}
							pipedElements.push(q);
							pipedLabels.push(questionTranslation.label);
						}
					});
				} else if (s.type === 'statement') {
					s.statements.forEach(statement => {
						const statementTranslation =
							statement.translations && statement.translations.find(t => t.languageCode === language);
						if (
							statementTranslation &&
							statementTranslation.text &&
							statementTranslation.text.includes(question.id.toString())
						) {
							statement.sectionId = s.id;
							statement.sectionType = s.type;
							statement.sectionName = s.name;
							statement.sectionOrder = s.order + 1;
							pipedElements.push(statement);
							pipedLabels.push(statementTranslation.label);
						}
					});
				}
			});
		}
	}
	return pipedElements;
};

// This function is used to obtain all questions in a study that have content of a certain section piped into their question labels. This is used on delete and reorder (downwards) of a section to check if the section's content is involved with any piping later on in the study.
const getPipedQuestionsWithSectionId = (section, sections, language) => {
	const pipedElements = [];
	if (section) {
		const pipableSections = sections.filter(s => s.type !== 'swipe' && s.order > section.order);
		if (section.questions && section.questions.length > 0) {
			const sectionQuestionsIds = section.questions.map(q => q.id);
			pipableSections.forEach(s => {
				if (s.questions) {
					s.questions.forEach(q => {
						const questionTranslation =
							q.translations && q.translations.find(t => t.languageCode === language);
						sectionQuestionsIds.forEach(id => {
							if (questionTranslation?.label && questionTranslation?.label?.includes(id.toString())) {
								q.sectionId = s.id;
								q.sectionType = s.type;
								q.sectionName = s.name;
								q.sectionOrder = s.order;
								q.section = s;
								if (!q.studyQuestionNumber) {
									q.studyQuestionNumber = misc.getStudyQuestionNumber(q, s, sections);
								}
								pipedElements.push(q);
							}
						});
					});
				} else if (s.type === 'statement') {
					s.statements.forEach(statement => {
						let statementIncludesPiping = false;
						const statementTranslation =
							statement.translations && statement.translations.find(t => t.languageCode === language);
						sectionQuestionsIds.forEach(id => {
							if (statementTranslation?.text && statementTranslation?.text?.includes(id.toString())) {
								statementIncludesPiping = true;
							}
						});
						if (statementIncludesPiping) {
							statement.sectionId = s.id;
							statement.sectionType = s.type;
							statement.sectionName = s.name;
							statement.sectionOrder = s.order;
							statement.section = s;
							pipedElements.push(statement);
						}
					});
				}
			});
		}
	}
	return pipedElements;
};

// This function is used to obtain all questions/statement LABELS in a study that have a certain question piped into their question labels. This is used on delete of a question to check if they are involved with any piping later on in the study. Takes pipedElements and language as an argument.
const getPipedLabels = (pipedElements, language) => {
	const pipedLabels = [];

	pipedElements.forEach(s => {
		if (s.questions) {
			s.questions.forEach(q => {
				const questionTranslation = q.translations && q.translations.find(t => t.languageCode === language);
				pipedLabels.push(questionTranslation.label);
			});
		} else if (s.type === 'statement') {
			s.statements.forEach(statement => {
				const statementTranslation =
					statement.translations && statement.translations.find(t => t.languageCode === language);
				pipedLabels.push(statementTranslation.label);
			});
		}
	});
	return pipedLabels;
};

// Returns true or false - determines whether a section has piping involved
export const sectionHasPiping = (section, language = null) => {
	const sectionHasQuestions = section.questions && section.questions.length > 0;
	const sectionHasStatement = section.type === 'statement' && section.statements && section.statements.length > 0;
	const sectionHasQuestionsAndPiping = sectionHasQuestions && section.questions.filter(q => isPipedQuestion(q.label));
	const statementTranslation =
		sectionHasStatement && section.statements[0].translations.find(s => s.languageCode === language);
	const sectionHasStatementAndPiping = statementTranslation && isPipedQuestion(statementTranslation.text);
	return sectionHasQuestionsAndPiping || sectionHasStatementAndPiping;
};

// Returns all piped question IDs in a specific section. Loops through each section question/statement label and adds piped questions to an array. Used when reordering sections upwards.
export const getAllSectionPipedQuestionIds = (section, sections, language = null) => {
	let allPipedQuestions = [];
	const pipableQuestions = getPipedQuestionsList(null, section, sections);

	if (section.statements && section.statements.length > 0) {
		const statement = section.statements.length > 0 && section.statements[0];
		const statementTranslation =
			statement && statement.translations && statement.translations.find(s => s.languageCode === language);
		const pipedQuestions = getQuestionIds(statementTranslation.text);
		allPipedQuestions = allPipedQuestions.concat(pipedQuestions);
	} else if (section.questions && section.questions.length > 0) {
		section.questions.forEach(q => {
			const pipedQuestions = getQuestionIds(q.label, pipableQuestions);
			allPipedQuestions = allPipedQuestions.concat(pipedQuestions);
		});
	}

	return allPipedQuestions;
};
export const getPipingErrorText = pipedQuestionsString => `This question is piped in ${pipedQuestionsString}`;

export default {
	filterPipedQuestionLabel,
	renderPipedQuestionLabel,
	getQuestionIds,
	getPipedQuestions,
	getCompletePipedQuestions,
	getPipedQuestionId,
	getPipedQuestionsList,
	getPipingErrorText,
	getAtValues,
	isPipedQuestion,
	pipedQuestionLabelCleanup,
	getPipingError,
	updatePipingErrorLabel,
	updatePipingErrorLabelSpecific,
	renderLabelWithEmptyQuestions,
	getPipedQuestionsWithQuestionId,
	getPipedQuestionsWithSectionId,
	getPipedLabels,
	sectionHasPiping,
	getAllSectionPipedQuestionIds,
	simplePipedQuestionLabel,
};
