import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Iconof } from '@upsiide/ui-components';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import FilterDropdownContext from 'src/components/shared/FilterDropdown/context';
import { getCountriesFromLanguages } from 'src/domains/all-studies/selectors';

import './styles.scss';

const className = 'template-filter-tags';
const el = (name, mod) => cn(className, name, mod);

const TemplateFilterTags = ({ selectedCountries, selectedLanguages, setSelectedCountries, setSelectedLanguages }) => {
	const setActiveFilters = useContext(FilterDropdownContext)?.setActiveFilters;

	const { languages } = useSelector(state => state.manageStudy);
	const countries = useSelector(getCountriesFromLanguages);

	const getFilterLabel = data => {
		if (Number.isNaN(Number(data))) return languages?.find(l => l?.languageCode === data)?.label || data;
		return countries?.find(c => c?.id === data)?.label || data;
	};
	const removeTag = data => {
		if (Number.isNaN(Number(data))) {
			setSelectedLanguages(prev => prev.filter(lang => lang !== data));
			setActiveFilters({
				country: selectedCountries,
				language: selectedLanguages.filter(lang => lang !== data),
			});
		} else {
			setSelectedCountries(prev => prev.filter(country => country !== data));
			setActiveFilters({
				country: selectedCountries.filter(country => country !== data),
				language: selectedLanguages,
			});
		}
	};

	const renderLanguageTag = (filter = '', i = 0) => (
		<div key={`language-${i}`} className={el('language_container')}>
			<span className={el('language')}>{getFilterLabel(filter)}</span>
			<span className={el('remove_language')} aria-hidden onClick={() => removeTag(filter)}>
				<Iconof icon="clear" className={el('clear-icon')} />
			</span>
		</div>
	);

	const clearTag = () => {
		setSelectedCountries([]);
		setSelectedLanguages([]);
		setActiveFilters([]);
	};
	return (
		<div className={`${className} ${[...selectedCountries, ...selectedLanguages]?.length ? '' : el('no-padding')}`}>
			<div className={el('filter_area')}>
				{selectedCountries?.length || selectedLanguages?.length ? (
					<>
						<span className={el('filter_name')}>Filtered by:</span>
						{[...selectedCountries, ...selectedLanguages].map((filter, i) => renderLanguageTag(filter, i))}
						<span className={el('clear_all')} aria-hidden onClick={clearTag}>
							Clear All
						</span>
					</>
				) : null}
			</div>
		</div>
	);
};

TemplateFilterTags.propTypes = {
	selectedCountries: PropTypes.array,
	selectedLanguages: PropTypes.array,
	setSelectedCountries: PropTypes.func,
	setSelectedLanguages: PropTypes.func,
};

export default TemplateFilterTags;
