import toastr from 'toastr';
import * as accountSelectors from 'src/domains/account/selectors';
import * as billingDetailsSelectors from 'src/domains/billing-details/selectors';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import Cookies from 'src/utilities/cookies';
import * as services from 'src/services';
import * as actions from '../actions';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 10000,
};

const inviteUsers = (store, action) => {
	if (action.type === actions.INVITE_USERS) {
		const { emails } = action.payload;
		const { ownerUuid, accountName } = accountSelectors.getAccount(store.getState()).content;
		const { uuid } = billingDetailsSelectors.getBillingDetails(store.getState());

		if (!ownerUuid || !accountName || !emails.length) {
			toastr.error('Something went wrong, try again later');
			return;
		}

		store.dispatch(actions.setLoading(true));
		let invitesSent = 0;
		try {
			Promise.all(
				emails.map(email => {
					if (email.length) {
						return services.accountService
							.addUser(
								ownerUuid,
								email,
								2, // space-creator
								accountName,
								[ownerUuid],
							)
							.then(() => {
								invitesSent += 1;
							});
					}
					return Promise.resolve();
				}),
			).then(() => {
				toastr.success(`Invite${invitesSent > 1 ? 's' : ''} sent!`);
				if (uuid) {
					// set the new account as default in case we are adding it
					Cookies.setUpsiideAccountToken(uuid);
				}
				navigate('/');
			});
		} catch (error) {
			toastr.error('Something went wrong, try again later');
		}
		store.dispatch(actions.setLoading(false));
	}
};

export default [inviteUsers];
