import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { useModalContext } from 'src/utilities/modal-provider';
import cn from 'src/utilities/bem-cn';
import VisibilityTransition from 'src/components/shared/VisibilityTransition';
import Header from './Header';
import Footer from './Footer';
import './styles.scss';

const className = 'responsive-modal';
const el = (name, mod) => cn(className, name, mod);

/**
 * Modal with Header and Footer that contain responsive content
 * add the class .scroll-content for any element contained in this component you want scroll on
 * */

// Hard-coded height values in the constructor here so we don't have to calculate them every render call
const headerHeight = 82;

const ResponsiveModal = ({
	// General
	children,
	customClass, // TODO: Figure out if this is still needed
	maxWidth,
	show,
	subtitleLabel,
	// Confirm
	confirmState,
	onConfirm,
	// decline
	declineState,
	onDecline,
	declineLabel,
	declineType,
	// Close
	closeState = 'active',
	onClose,
	// Tabs
	tabs = [],
	activeTab,
	setActiveTab,
	handleOverflow = true,
	customContent,
	headerLabel: headerLabelProps,
	closeLabel = 'Back',
	confirmLabel = 'Confirm',
}) => {
	const [target, setTarget] = useState();
	const closeModal = e => {
		if (target === e.currentTarget) {
			onClose();
			e.stopPropagation();
		}
	};
	const tabsHeight = tabs.length > 0 ? 48 : 0;
	const footerHeight = closeLabel && confirmLabel ? 88 : 0;
	const offsetHeight = headerHeight + tabsHeight + footerHeight;
	const contentMaxHeight = `calc(90vh - ${offsetHeight}px)`;

	const saveTarget = e => setTarget(e.target);
	// Header
	const headerLabel = headerLabelProps || 'Modal Title';
	// Footer
	const isFooterVisible = !!onConfirm || (!!onDecline && !!declineLabel);
	// Container
	const modalContext = useModalContext();
	/* We may not have the modal container if we run inside the styleguidist */
	const modalContainerRef = modalContext.current || document.body;

	return ReactDOM.createPortal(
		<div
			className={className + (customClass ? ` ${customClass}` : '')}
			style={{ visibility: show ? 'visible' : 'hidden' }}
		>
			{customContent ? (
				<div className={el('wrapper')} onMouseDown={saveTarget} onClick={closeModal} aria-hidden>
					<VisibilityTransition show={show} hideDelay={200}>
						{children}
					</VisibilityTransition>
				</div>
			) : (
				<div className={el('wrapper')} onMouseDown={saveTarget} onClick={closeModal} aria-hidden>
					<VisibilityTransition show={show} hideDelay={200}>
						<Container className={el('dialog-box')} style={maxWidth ? { maxWidth } : null}>
							<Header
								headerLabel={headerLabel}
								subtitleLabel={subtitleLabel}
								tabs={tabs}
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								onClose={onClose}
							/>
							<div
								className={el(`content${handleOverflow ? ' overflow' : ''}`)}
								style={{ maxHeight: contentMaxHeight }}
							>
								{children}
							</div>
							{isFooterVisible && (
								<Footer
									// Close
									closeLabel={closeLabel}
									closeState={closeState}
									onClose={onClose}
									// Confirm
									confirmLabel={confirmLabel}
									confirmState={confirmState}
									onConfirm={onConfirm}
									// Decline
									declineLabel={declineLabel}
									declineState={declineState}
									onDecline={onDecline}
									declineType={declineType}
								/>
							)}
						</Container>
					</VisibilityTransition>
				</div>
			)}
		</div>,
		modalContainerRef,
	);
};

ResponsiveModal.propTypes = {
	show: PropTypes.bool,
	headerLabel: PropTypes.any,
	customClass: PropTypes.string,
	subtitleLabel: PropTypes.any,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	maxWidth: PropTypes.number,
	handleOverflow: PropTypes.bool,
	customContent: PropTypes.bool,
	// Close
	closeLabel: PropTypes.string,
	closeState: PropTypes.string,
	onClose: PropTypes.func,
	// Confirm
	confirmLabel: PropTypes.string,
	confirmState: PropTypes.string,
	onConfirm: PropTypes.func,
	// Decline
	declineLabel: PropTypes.string,
	declineState: PropTypes.string,
	onDecline: PropTypes.func,
	declineType: PropTypes.string,
	// Tabs
	tabs: PropTypes.array,
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
};

export default ResponsiveModal;
