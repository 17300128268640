const quillHelper = {
	emptyCheck: content => {
		if (content && typeof content === 'string' && content.replace(' ', '').toLowerCase() === '<p><br></p>')
			return '';
		return content;
	},

	clean: content => {
		if (content && content.replace) {
			content.replace(/<span class='ql-cursor'>.+<\/span>/gi, '');

			return quillHelper.emptyCheck(content);
		}
		return content;
	},
};

export default quillHelper;
