import * as services from 'src/services';
import Cookies from 'src/utilities/cookies';
import axios from 'src/utilities/axios';
import { navigate } from 'src/utilities/router/routerScopeLeaker';
import * as actions from '../actions';
import * as authActions from '../../auth/actions';

const confirmToken = async (store, { type, payload }) => {
	if (type === actions.CONFIRM_USER_SETUP_TOKEN) {
		try {
			const { token } = payload;
			store.dispatch(actions.incLoading());
			const userData = await services.userService.checkAccountSetupToken(token);
			store.dispatch(actions.setUser({ email: userData.shareId }));
			store.dispatch(actions.setToken(token));

			store.dispatch(actions.decLoading());
		} catch (error) {
			store.dispatch(actions.setError(error));
			store.dispatch(actions.decLoading());
			console.group('Error while confirming user creation token');
			console.error('error:', error);
			console.groupEnd();
		}
	}
};

const setupUser = async (store, { type, payload }) => {
	if (type === actions.SETUP_USER) {
		try {
			store.dispatch(actions.incLoading());
			store.dispatch(actions.setError(null));

			const userToken = await services.userService.setupUser(payload.user);

			Cookies.setToken(userToken.data.token);
			axios.setToken(userToken.data.token);
			store.dispatch(authActions.authenticate());
			store.dispatch(actions.decLoading());
			navigate('/');
		} catch (error) {
			store.dispatch(actions.setError(error));
			console.group('Error while updating User');
			console.error('error:', error);
			console.groupEnd();
		}
	}
};

export default [confirmToken, setupUser];
