import React from 'react';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import './Badge.scss';

class Badge extends React.Component {
	static propTypes = {
		type: PropTypes.oneOf(['default', 'focused', 'secondary', 'warning', 'alert']),
		label: PropTypes.string,
		icon: PropTypes.string,
		state: PropTypes.oneOf(['disabled']),
	};

	static defaultProps = {
		label: '',
		type: 'default',
	};

	createClassName() {
		return ['badge', this.props.type, this.props.state].join(' ').trim();
	}

	render() {
		const { label, icon } = this.props;
		return (
			<span className={this.createClassName()}>
				{icon ? <Iconof type={icon} /> : null} {label}
			</span>
		);
	}
}

export default Badge;
