import React from 'react';
import PropTypes from 'prop-types';
import AlertNotificationIcon from 'src/components/icons/AlertNotification';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import cn from 'src/utilities/bem-cn';
import './AlertNotification.scss';

const className = 'alert-notification';
const el = name => cn(className, name);

class AlertNotification extends React.Component {
	render() {
		const id = `${className}__tooltip-${Math.ceil(Math.random() * 1000)}`;
		const text = 'On launch, we’ll review this study to find an accurate price for the custom qualifiers.';
		const {
			animation = 'shift', // Style of tooltip animation (e.g. shift, perspective, fade, scale, none)
			interactive = false, // Whether you can interact with the tooltip
			trigger = 'mouseenter', // How is the tooltip triggered (e.g. mouseenter, focus, click, manual)
		} = this.props;
		return (
			<Tooltip
				id={id}
				className={className}
				animation={animation}
				animationFill={false}
				arrow
				interactive={interactive}
				trigger={trigger}
				html={<div>{text}</div>}
			>
				<AlertNotificationIcon className={el('icon')} />
			</Tooltip>
		);
	}
}

AlertNotification.propTypes = {
	animation: PropTypes.string, // Style of tooltip animation (e.g. shift, perspective, fade, scale, none)
	interactive: PropTypes.bool, // Whether you can interact with the tooltip
	trigger: PropTypes.string, // How is the tooltip triggered (e.g. mouseenter, focus, click, manual)
};

export default AlertNotification;
