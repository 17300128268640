import React from 'react';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'static-tabs';
const el = name => cn(className, name);

export default ({ tabs, onChange, active }) => (
	<div className={className}>
		{tabs.map(tab => (
			<div
				onClick={() => onChange(tab.name)}
				key={tab.name}
				className={`${el('tab')} ${active === tab.name ? 'active' : ''} ${tab.disabled ? 'disabled' : ''}`}
			>
				{tab.label}
			</div>
		))}
	</div>
);
