import React, { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { format, isAfter, differenceInDays } from 'date-fns';
import { Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import IconButton from 'src/components/elements/IconButton';
import Button from 'src/components/elements/Button';
import Sparkle from 'src/components/icons/Sparkle';
import Modal from 'src/components/shared/Modal';
import ContactUsThankYou from 'src/components/shared/ContactUsThankYou';
import { getStorageWithExpiry, setStorageWithExpiry } from 'src/utilities/local-storage';
import './styles.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'src/utilities/cookies';
import { isAccountEnterprise } from '../../../../account/selectors';

const className = 'chargify-account-subscription-banner';
const el = (name, mod) => cn(className, name, mod);

const ChargifyAccountSubscriptionBanner = ({ chargifySubscriptions, accountId, accounts, settings }) => {
	const localStorageKey = `UPSIIDE_ACCOUNT_${accountId}_CHARGIFY_SUBSCRIPTION_BANNER_HIDDEN`;

	const accountIsEnterprise = useSelector(isAccountEnterprise);

	const initialValue = getStorageWithExpiry(localStorageKey, window.sessionStorage);

	const [bannerHidden, setBannerHidden] = useState(initialValue);
	const [contactUsModalOpen, setContactUsModalOpen] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();

	const isAlreadyOnChoosePlan = location?.pathname?.includes('/choose-plan');

	const hasPermission = useMemo(() => {
		const upsiideAccountToken = Cookies.getUpsiideAccountToken();
		const accountSettingsAccess = upsiideAccountToken
			? accounts.content.filter(a => a.uuid === upsiideAccountToken)
			: accounts.content;
		const permission = accountSettingsAccess[0] ? accountSettingsAccess[0].permission : '';
		return permission === 'CAN_MANAGE' || permission === 'CAN_VIEW_DETAILS';
	}, [accounts.content]);

	const hasCC = useMemo(() => settings?.creditCards?.length, [settings]);

	const license =
		useMemo(() => {
			const licenseSubscriptionHandles = ['enterprise', 'professional'];

			if (!chargifySubscriptions || !chargifySubscriptions.length) {
				return {};
			}

			const filteredLicenseSub = chargifySubscriptions.find(sub =>
				licenseSubscriptionHandles.includes(sub.subscriptionHandle),
			);

			return filteredLicenseSub;
		}, [chargifySubscriptions]) || {};

	const [creditCard] = useState(license?.creditCard || {});

	let daysUntilRenewal = 0;
	if (license?.renewalDate) daysUntilRenewal = differenceInDays(new Date(license.renewalDate), new Date());

	const isTrialing = useMemo(
		() =>
			// eslint-disable-next-line no-nested-ternary
			license?.state === 'trialing' ? (hasCC ? license?.pendingCancellation : true) : false,
		[hasCC, license],
	);

	const handleCloseBanner = useCallback(() => {
		setStorageWithExpiry(localStorageKey, true, 86400000, window.sessionStorage); // 1 day

		setBannerHidden(true);
	}, [localStorageKey]);

	/**
	 * Banner is visible if
	 * The subscription status is not active but the renewal date is less than 30 days away.
	 * The renewal date is past todays date meaning a payment likely failed.
	 * The credit card on file will expire before the renewal date.
	 */
	const banner = useMemo(() => {
		// If for some reason there is no subscription OR the payment method is remittance dont display the banner
		if (license.subscriptionId && license.paymentMethod !== 'remittance') {
			const formattedRenewalDate = format(
				new Date(license.renewalDate || license.endDate || license.trialEnd),
				'MMM do, yyyy',
			);

			const messageIfHasPermission = message =>
				hasPermission
					? message
					: `Your plan was canceled on ${formattedRenewalDate}. Contact your Administrator for more details.`;

			// Trialing
			if (isTrialing) {
				return messageIfHasPermission(
					`Welcome to Upsiide! You've got ${daysUntilRenewal} days left in your trial.`,
				);
			}

			// not trialing with pendingCancellation
			if (license?.pendingCancellation && !isTrialing && daysUntilRenewal <= 30) {
				return messageIfHasPermission(
					`Your plan is about to expire. Your access to Upsiide will end on ${formattedRenewalDate}.`,
				);
			}

			if (license?.state === 'canceled') {
				return messageIfHasPermission(
					`Your plan was canceled on ${formattedRenewalDate}.`, // TODO - verify copy
				);
			}

			// The renewal date has past meaning a payment likely failed
			if (license?.state === 'past_due') {
				return messageIfHasPermission(
					`Your plan expired on ${formattedRenewalDate}. Your access to Upsiide will end soon.`, // TODO - verify copy
				);
			}
			// The trial has ended
			if (license?.state === 'trial_ended') {
				return messageIfHasPermission(`Your plan expired on ${formattedRenewalDate}.`);
			}

			// If for some reason there is no credit card on file and renewal is within 30 days
			if ((!creditCard?.expirationYear || !creditCard?.expirationMonth) && daysUntilRenewal <= 30) {
				return messageIfHasPermission(
					`Your plan is up for renewal on ${formattedRenewalDate} but there is no credit card on file.`, // TODO - verify copy
				);
			}

			// The credit card on file is going to expire before the renewal date.
			const cardWillBeValidAtRenewal = isAfter(
				new Date(creditCard?.expirationYear, creditCard?.expirationMonth), // Add 1 as card is valid until last day of expiration month
				new Date(license?.renewalDate),
			);

			if (!cardWillBeValidAtRenewal && daysUntilRenewal <= 30) {
				return messageIfHasPermission(
					`Your plan is up for renewal on ${formattedRenewalDate} but the credit card we have on file expires beforehand.`, // TODO - verify copy
				);
			}
		}
		return null;
	}, [creditCard, hasPermission, isTrialing, license, daysUntilRenewal]);

	if (banner && !bannerHidden && hasPermission) {
		return (
			<div className={className}>
				{isTrialing ? <Sparkle /> : <Iconof icon="warning" size="large" className={el('warning-icon')} />}

				<span className={el('copy')}>{banner}</span>

				{hasPermission && (
					<>
						{!accountIsEnterprise ? (
							<div className={el('button-wrapper')}>
								{!isAlreadyOnChoosePlan ? (
									<Button
										onClick={() => navigate('/choose-plan')}
										className={el('button')}
										label="Upgrade"
									/>
								) : null}

								<Button
									onClick={() => setContactUsModalOpen(true)}
									className={el('button keyline transparent')}
									type="green"
									label="Book a demo"
								/>
							</div>
						) : null}

						<Modal
							show={contactUsModalOpen}
							width={400}
							padding={0}
							onClose={() => setContactUsModalOpen(false)}
						>
							<div style={{ height: 300 }}>
								<ContactUsThankYou
									daysUntilRenewal={daysUntilRenewal}
									handleSoundsGoodClick={() => setContactUsModalOpen(false)}
								/>
							</div>
						</Modal>
					</>
				)}

				<IconButton className={el('close-btn')} icon="clear" size="large" onClick={handleCloseBanner} />
			</div>
		);
	}

	return null;
};

ChargifyAccountSubscriptionBanner.propTypes = {
	accountId: PropTypes.number,
	chargifySubscriptions: PropTypes.any,
	accounts: PropTypes.any,
	settings: PropTypes.any,
};

export default ChargifyAccountSubscriptionBanner;
