import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const className = 'new-input';

const getInvalidClassName = (isSignup, invalid) => {
	if (isSignup) {
		return invalid ? 'signup-invalid' : '';
	}

	return invalid ? 'invalid' : '';
};

class Input extends React.Component {
	handleClick = e => {
		const { onClick } = this.props;
		if (onClick) {
			onClick(e.target);
		}
	};

	handleChange = e => {
		const { onChange } = this.props;
		onChange(e.target.value);
	};

	handleKeyPress = e => {
		const { onSubmit, onKeyPress } = this.props;
		if (e.keyCode === 13 || e.which === 13) {
			if (onSubmit) {
				onSubmit();
			}
		}
		if (onKeyPress) {
			onKeyPress(e);
		}
	};

	render() {
		const {
			value = '',
			className: $className = '',
			invalid,
			isSignup,
			innerRef,
			placeholder,
			...props
		} = this.props;
		return (
			<input
				{...props}
				ref={innerRef}
				className={`${$className} form-control ${className} ${getInvalidClassName(isSignup, invalid)}`}
				value={value}
				onClick={this.handleClick}
				onChange={this.handleChange}
				onKeyPress={this.handleKeyPress}
				placeholder={placeholder}
			/>
		);
	}
}

Input.propTypes = {
	value: PropTypes.any,
	className: PropTypes.any,
	invalid: PropTypes.any,
	innerRef: PropTypes.any,
	placeholder: PropTypes.any,
	onClick: PropTypes.func,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	onKeyPress: PropTypes.func,
	isSignup: PropTypes.bool,
};

export default Input;
