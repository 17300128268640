import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { urlParams } from 'src/components/shared/SearchResults';
import { fetchClientsFromAccount } from 'src/domains/all-studies/actions';
import { setAccountUuid } from 'src/domains/account/actions';

export default function useHandleSetAccountUUID() {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const activeTab = searchParams.get(urlParams.status) || 'all';

	const handleSetAccountUUID = useCallback(
		uuid => {
			setSearchParams(params => {
				if (params.has(urlParams.clientId)) params.delete(urlParams.clientId);
				if (params.has('highlightStatus')) params.delete('highlightStatus');

				let pageParam = urlParams.studyPage;
				if (activeTab === 'ideas') {
					pageParam = urlParams.ideaPage;
				} else if (activeTab === 'questions') {
					pageParam = urlParams.questionPage;
				} else if (activeTab === 'templates') {
					pageParam = urlParams.templatePage;
				}
				params.set(pageParam, 1);
				return params;
			});

			dispatch(setAccountUuid(uuid));
			dispatch(fetchClientsFromAccount());
		},
		[activeTab, dispatch, setSearchParams],
	);

	return handleSetAccountUUID;
}
