import React from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import LogicSelect from '../LogicSelect';
import { useLogicConditionContext } from '../../LogicConditionContext';

import '../../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

const LogicItemSelect = ({ options, setParentRefZIndex, columnWidth = 2 }) => {
	const {
		setShowLoader,
		question,
		section,
		sections,
		itemOptions,
		saveData,
		logicItem,
		isProductLogic,
		saveLogic,
		isAudienceLogic,
		audience,
		selectedItem,
	} = useLogicConditionContext();

	const handleOnChange = itemId => {
		setShowLoader(true);

		// Setup props and variables
		const { id: sectionItemId } = question;
		const { id: sectionId } = section || {};

		// Set chosen item and answer options
		// const newSelectedItem = itemOptions[itemIndex]; // Set the chosen question
		const newSelectedItem = itemOptions?.find(option => option?.value === itemId);

		const isSameItem = itemId === selectedItem?.value;

		const updatedShowDestinationSelect =
			question.id === newSelectedItem.data.itemId && question.style !== 'open-ended';

		const actionType = updatedShowDestinationSelect ? 'goto' : 'skip';

		const saveDataClone = {
			...saveData,
			logic: saveData?.logic?.map((item, index) => {
				if (item?.id === logicItem?.id) {
					return {
						...item,
						id: logicItem.id,
						sectionId,
						sectionItemId: isProductLogic ? null : sectionItemId,
						operator: isSameItem ? logicItem.operator : 'is',
						operand: isSameItem ? logicItem.operand : 'and',
						triggerItemId: newSelectedItem.data.itemId,
						triggerOptions: isSameItem ? logicItem.triggerOptions : [],
						actionType: isSameItem ? logicItem.actionType : actionType,
						actionSectionId: isSameItem ? logicItem.actionSectionId : null,
						actionItemId: isSameItem ? logicItem.actionItemId : null,
						...(isAudienceLogic
							? {
									audienceUuid: audience?.uuid,
									itemType: 'question',
							  }
							: {
									triggerType:
										newSelectedItem && sections.content[newSelectedItem.data.sidebarIndex].type,
									triggerSectionId:
										newSelectedItem && sections.content[newSelectedItem.data.sidebarIndex].id,
							  }),
					};
				}

				return item;
			}),
		};

		saveLogic(saveDataClone);
	};

	return (
		<LogicSelect
			columnWidth={columnWidth}
			selectClassName="question"
			options={options}
			onChange={handleOnChange}
			menuMinWidth={350}
			value={selectedItem?.value}
			placeholder="item"
			setParentRefZIndex={setParentRefZIndex}
		/>
	);
};

LogicItemSelect.propTypes = {
	options: PropTypes.any,
	setParentRefZIndex: PropTypes.any,
	columnWidth: PropTypes.number,
};

export default LogicItemSelect;
