import React from 'react';
import PropTypes from 'prop-types';
import StaticTabs from 'src/components/shared/StaticTabs';
import { Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'responsive-modals-header';
const el = (name, mod) => cn(className, name, mod);

const Header = ({ headerLabel, subtitleLabel, onClose, tabs, activeTab, setActiveTab }) => (
	<div className={className}>
		<h4 className={el('header-title')}>{headerLabel}</h4>
		{subtitleLabel && <h6 className={el('header-subtitle')}>{subtitleLabel}</h6>}
		{tabs && tabs.length > 0 && (
			<div className={el('tabs')}>
				<StaticTabs tabs={tabs} active={activeTab} onChange={setActiveTab} />
			</div>
		)}
		<div className={el('close')} onClick={onClose} aria-hidden>
			<Iconof icon="clear" />
		</div>
	</div>
);

Header.propTypes = {
	headerLabel: PropTypes.any,
	subtitleLabel: PropTypes.any,
	onClose: PropTypes.func,
	tabs: PropTypes.array,
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
};

export default Header;
