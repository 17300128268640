import React from 'react';
import PropTypes from 'prop-types';

const SparkleIcon = ({ style }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" style={style}>
		<g fill="none" fillRule="evenodd">
			<g fill="#FFC72F">
				<g>
					<path
						d="M3.036 9.377c.145 0 .27.124.27.27 0 2.62 2.245 2.703 2.495 2.703.145 0 .27.124.27.27 0 .167-.125.291-.27.291h-.049c-.355.008-2.467.163-2.467 2.703 0 .167-.125.291-.25.25-.145 0-.27-.125-.27-.27 0-2.62-2.224-2.703-2.495-2.703-.145 0-.27-.125-.27-.27 0-.147.104-.25.25-.271h.048c.356-.007 2.467-.163 2.467-2.703 0-.146.125-.27.27-.27zm8.586-5.801c.146 0 .27.125.27.27 0 4.782 4.117 4.948 4.596 4.948.083.021.187.063.228.146.063.083.063.187.021.27-.062.084-.145.146-.25.146-.478 0-4.574.187-4.574 4.948 0 .145-.124.27-.27.27-.145 0-.27-.125-.27-.27 0-4.782-4.096-4.948-4.574-4.948h-.021c-.104 0-.208-.062-.25-.146-.041-.083-.041-.187 0-.27.02-.021.042-.062.084-.083.041-.042.104-.063.166-.063.457 0 4.574-.166 4.574-4.948 0-.145.125-.27.27-.27zM5.426 0c.146 0 .27.125.27.27 0 2.62 2.246 2.703 2.495 2.703.146 0 .271.125.271.27 0 .167-.125.291-.27.291h-.05c-.355.008-2.466.163-2.466 2.703 0 .146-.125.27-.25.25-.145 0-.27-.125-.27-.27 0-2.62-2.224-2.703-2.495-2.703-.145 0-.27-.125-.27-.27 0-.146.104-.25.25-.27h.02c.25 0 2.495-.084 2.495-2.704 0-.145.125-.27.27-.27z"
						transform="translate(-1275 -172) translate(1275 172)"
					/>
				</g>
			</g>
		</g>
	</svg>
);

SparkleIcon.propTypes = {
	style: PropTypes.object,
};
export default SparkleIcon;
