import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { element } from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import * as services from 'src/services';
import { getStudyId as getBlueprintStudyId } from 'src/domains/manage-blueprints/selectors';
import { Iconof } from '@upsiide/ui-components';
import Button from 'src/components/elements/Button';
import toastr from 'toastr';
import Unsplash from './Unsplash';
import EmojiPicker from './EmojiPicker';
import Video from './Video';
import AssetGallery from './AssetGallery';
import Upload from './Upload';
import GalleryTabs from './Shared/GalleryTabs';

import './styles.scss';

const className = 'gallery';
const el = element(className);

/**
 * Assets Service API
 */
const uploadVideo = url => services.assetService.add(url);

/**
 * Assets Gallery
 */
const Gallery = ({ multi, onChange, onConfirmAsset, picked, imageOnly, onClose, withEmojis, givenStudyId }) => {
	const pickedRef = useRef(picked);
	const blueprintId = useSelector(getBlueprintStudyId);

	const [videoLink, setVideoLink] = useState('');
	const [isVideoValid, setIsVideoValid] = useState(false);
	const [error, setError] = useState(null);

	const [studyAssets, setStudyAssets] = useState([]);
	const [selectedStudyAsset, setSelectedStudyAsset] = useState(picked);
	const [allAssets, setAllAssets] = useState([]);
	const [selectedAllAsset, setSelectedAllAsset] = useState(picked);
	const [gallerySearchValue, setGallerySearchValue] = useState('');
	const galleryCurrentPage = useRef(0);
	const [galleryInitialDataLoaded, setGalleryInitialDataLoaded] = useState(false);
	const [galleryTabs, setGalleryTabs] = useState([
		{ name: 'study-assets', label: `${blueprintId ? 'Template' : 'Study'}`, count: 0 },
		{ name: 'account-assets', label: 'All', count: 0 },
	]);
	const [activeGalleryTab, setActiveGalleryTab] = useState('study-assets');

	const [unsplashAssets, setUnsplashAssets] = useState([]);
	const [selectedUnsplashAsset, setSelectedUnsplashAsset] = useState(null);
	const [unsplashSearchValue, setUnsplashSearchValue] = useState('');
	const unsplashCurrentPage = useRef(1); // unsplash must start at 1 not 0
	const [unsplashInitialDataLoaded, setUnsplashInitialDataLoaded] = useState(false);

	const [tab, setTab] = useState(withEmojis ? 'emoji' : 'upload-images');

	const GALLERY_TABS = [
		...(withEmojis ? [{ name: 'emoji', label: 'Emoji' }] : []),
		{ name: 'upload-images', label: 'Upload' },
		{ name: 'asset-gallery', label: 'My Gallery' },
		{ name: 'unsplash-images', label: 'Images' },
		...(!imageOnly ? [{ name: 'add-video', label: 'Video' }] : []),
	];

	const setActiveTab = useCallback(selectedTab => {
		setError(null);
		setTab(selectedTab);
	}, []);

	const handleAssetGalleryConfirm = useCallback(
		(id, type = 'asset') => {
			if (id) onConfirmAsset([id], type);
			else onConfirmAsset();
		},
		[onConfirmAsset],
	);

	const handleSelectUnsplashAsset = useCallback(async () => {
		// Add asset to DB
		const asset = await services.assetService.addUnsplash({ id: selectedUnsplashAsset });

		// TODO: proper error handling
		if (asset) {
			onConfirmAsset([asset.id]);
		} else {
			console.log('error');
		}
	}, [onConfirmAsset, selectedUnsplashAsset]);

	const handleVideoUpload = useCallback(async () => {
		const video = await uploadVideo(videoLink);
		if (video) {
			toastr.success('Video uploaded');
			onConfirmAsset([video.id]);
		} else {
			toastr.error('Video failed to upload');
		}
		if (onClose) onClose();
	}, [onClose, onConfirmAsset, videoLink]);

	const handleOnChange = useCallback(
		val => {
			if (activeGalleryTab === 'study-assets') {
				setSelectedStudyAsset(val);
			} else {
				setSelectedAllAsset(val);
			}
			onChange(val);
		},
		[activeGalleryTab, onChange],
	);

	const handleSetActiveGalleryTab = useCallback(
		val => {
			onChange(pickedRef.current);
			if (val === 'study-assets' && selectedStudyAsset?.length > 0) {
				setSelectedStudyAsset(pickedRef.current);
			} else if (val === 'account-assets' && selectedAllAsset?.length > 0) {
				setSelectedAllAsset(pickedRef.current);
			}

			setActiveGalleryTab(val);
		},
		[onChange, selectedAllAsset, selectedStudyAsset],
	);

	const handleSelectEmoji = useCallback(
		emoji => {
			handleAssetGalleryConfirm(emoji?.unified, 'emoji');
		},
		[handleAssetGalleryConfirm],
	);

	const studyAssetsButtonState = useMemo(
		() => (selectedStudyAsset?.length > 0 ? 'active' : 'disabled'),
		[selectedStudyAsset],
	);

	const accountAssetsButtonState = useMemo(
		() => (selectedAllAsset?.length > 0 ? 'active' : 'disabled'),
		[selectedAllAsset],
	);

	const videoTabButtonState = useMemo(() => (isVideoValid ? 'active' : 'disabled'), [isVideoValid]);

	return (
		<div className={className}>
			<div className={el('header')}>
				<span className={el('title')}>Add media</span>

				<GalleryTabs
					tabs={GALLERY_TABS}
					setActiveTab={setActiveTab}
					activeTab={tab}
					additionalClass={el('main-tabs')}
				/>
			</div>

			<div>
				{withEmojis && tab === 'emoji' && (
					<div className={`${el('content')} ${el('upload')}`}>
						<EmojiPicker onSelect={handleSelectEmoji} />
					</div>
				)}

				{tab === 'upload-images' && (
					<div className={`${el('content')} ${el('upload')}`}>
						<Upload onConfirmAsset={handleAssetGalleryConfirm} error={error} setError={setError} />
					</div>
				)}

				{tab === 'asset-gallery' && activeGalleryTab === 'study-assets' && (
					<div className={el('content')}>
						<AssetGallery
							multi={multi}
							onChange={handleOnChange}
							picked={selectedStudyAsset}
							setError={setError}
							assets={studyAssets}
							setAssets={setStudyAssets}
							searchValue={gallerySearchValue}
							setSearchValue={setGallerySearchValue}
							currentPageRef={galleryCurrentPage}
							tabs={galleryTabs}
							setTabs={setGalleryTabs}
							initialDataLoaded={galleryInitialDataLoaded}
							setInitialDataLoaded={setGalleryInitialDataLoaded}
							activeTab={activeGalleryTab}
							setActiveTab={handleSetActiveGalleryTab}
							givenStudyId={givenStudyId}
						/>
					</div>
				)}

				{tab === 'asset-gallery' && activeGalleryTab === 'account-assets' && (
					<div className={el('content')}>
						<AssetGallery
							multi={multi}
							onChange={handleOnChange}
							picked={selectedAllAsset}
							setError={setError}
							assets={allAssets}
							setAssets={setAllAssets}
							searchValue={gallerySearchValue}
							setSearchValue={setGallerySearchValue}
							currentPageRef={galleryCurrentPage}
							tabs={galleryTabs}
							setTabs={setGalleryTabs}
							initialDataLoaded={galleryInitialDataLoaded}
							setInitialDataLoaded={setGalleryInitialDataLoaded}
							activeTab={activeGalleryTab}
							setActiveTab={handleSetActiveGalleryTab}
						/>
					</div>
				)}

				{tab === 'unsplash-images' && (
					<div className={el('content')}>
						<Unsplash
							assets={unsplashAssets}
							setAssets={setUnsplashAssets}
							selectedUnsplashAsset={selectedUnsplashAsset}
							setSelectedUnsplashAsset={setSelectedUnsplashAsset}
							searchValue={unsplashSearchValue}
							setSearchValue={setUnsplashSearchValue}
							currentPageRef={unsplashCurrentPage}
							initialDataLoaded={unsplashInitialDataLoaded}
							setInitialDataLoaded={setUnsplashInitialDataLoaded}
						/>
					</div>
				)}

				{tab === 'add-video' && (
					<div className={el('content')}>
						<Video
							videoLink={videoLink}
							setVideoLink={setVideoLink}
							error={error}
							setError={setError}
							isValid={isVideoValid}
							setIsValid={setIsVideoValid}
						/>
					</div>
				)}
			</div>

			<div className={el('footer')}>
				{tab === 'upload-images' && error && (
					<div className={el('error-message')}>
						<Iconof className={el('error-icon')} icon="warning" />
						{error}
					</div>
				)}
				<div className={el('confirm-button')}>
					{tab === 'upload-images' && (
						<Button
							className={el('no-margin-bottom')}
							state="active"
							label="Close"
							onClick={onClose}
							type="text"
						/>
					)}

					{tab === 'asset-gallery' && (
						<Button
							state={
								activeGalleryTab === 'study-assets' ? studyAssetsButtonState : accountAssetsButtonState
							}
							label="Choose File"
							onClick={() => handleAssetGalleryConfirm()}
						/>
					)}

					{tab === 'unsplash-images' && (
						<Button
							state={selectedUnsplashAsset ? 'active' : 'disabled'}
							label="Choose File"
							onClick={handleSelectUnsplashAsset}
						/>
					)}

					{tab === 'add-video' && (
						<Button
							className={el('no-margin-bottom')}
							state={videoTabButtonState}
							label="Done"
							onClick={handleVideoUpload}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

Gallery.propTypes = {
	multi: PropTypes.any,
	onChange: PropTypes.func,
	onConfirmAsset: PropTypes.func,
	onClose: PropTypes.func,
	picked: PropTypes.array,
	imageOnly: PropTypes.bool,
	withEmojis: PropTypes.bool,
	givenStudyId: PropTypes.number,
};

export default Gallery;
