import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const FilterSum = ({ sum }) => {
	if (sum && sum > 0) {
		return <span className="audience-filters-sum">{sum}</span>;
	}

	return null;
};

FilterSum.propTypes = {
	sum: PropTypes.number,
};

export default FilterSum;
