import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { UPThemeProvider } from '@upsiide/ui-components';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { element } from 'src/utilities/bem-cn';
import Modal from 'src/components/shared/Modal';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { useIsApple } from 'src/hooks';
import DetatchedScreenerModal from 'src/components/shared/DetatchedScreenerModal';
import IconButton from 'src/components/elements/IconButton';
import { getAssetVariationUrl, assetIsVideo, formatEmojiUnicode } from 'src/utilities/misc';
import Input from '../Input';
import Gallery from './Gallery';
import './styles.scss';

const className = 'asset-input';
const el = element(className);

const WrapperComponent = React.forwardRef(({ isModal = true, show, close, assetTop, assetLeft, children }, ref) => {
	const viewportHeight = window.innerHeight;

	if (isModal) {
		return (
			<Modal padding={0} width={500} show={show} onClose={close}>
				{children}
			</Modal>
		);
	}

	const editManagerHeight = 473;
	const hasSpaceBelow = viewportHeight - assetTop - editManagerHeight > 0;
	const hasSpaceAbove = assetTop - editManagerHeight > 0;

	let finalPositioningTop = assetTop;
	if (!hasSpaceBelow && hasSpaceAbove) {
		finalPositioningTop = assetTop - editManagerHeight - 60;
	}

	return ReactDOM.createPortal(
		<UPThemeProvider>
			<div
				ref={ref}
				className={el('inline-asset-gallery', show ? 'open' : 'closed')}
				style={{
					left: `${assetLeft}px`,
					top: `${finalPositioningTop + window.scrollY + 50}px`,
				}}
			>
				{children}
			</div>
		</UPThemeProvider>,
		document.body,
	);
});
WrapperComponent.displayName = 'WrapperComponent';
WrapperComponent.propTypes = {
	show: PropTypes.bool,
	close: PropTypes.func,
	isModal: PropTypes.bool,
	assetTop: PropTypes.number,
	assetLeft: PropTypes.number,
	children: PropTypes.any,
};

const AssetInput = ({
	changeLabel,
	chooseLabel,
	placeholderLabel,
	value,
	preview = false,
	multi,
	onChange,
	asset,
	emoji,
	previewMaxWidth = 100,
	previewMaxHeight = 100,
	customPreviewStyle = null,
	inline,
	option = false,
	blockUpdateOrBreakTemplate,
	showModal = true,
	onClick,
	imageOnly,
	withEmojis = false,
	parentShowGallery = false,
	parentHideGallery = () => {},
	canDelete,
	connectedToQuestionLibrary,
	isModal = true,
	givenStudyId,
}) => {
	const [showGallery, setShowGallery] = useState(false);
	const [picked, setPicked] = useState([]);
	const [showDisconnectModal, setShowDisconnectModal] = useState(false);
	const galleryRef = useRef();
	const previewRef = useRef();

	const { isApple } = useIsApple();

	useOutsideClick([galleryRef, previewRef], e => {
		if (showGallery && !isModal) {
			close();
		}
	});
	const viewportOffset = previewRef?.current?.getBoundingClientRect();
	const assetTop = viewportOffset?.top || 0;
	const assetLeft = viewportOffset?.left || 0;

	const open = useCallback(
		e => {
			!!e?.preventDefault && e.preventDefault();
			!!e?.stopPropagation && e.stopPropagation();

			if (onClick) onClick();

			if (blockUpdateOrBreakTemplate) {
				if (connectedToQuestionLibrary) {
					setShowDisconnectModal(true);
				} else {
					blockUpdateOrBreakTemplate(() => {
						setShowGallery(true);
						setPicked(value || []);
					});
				}
			} else {
				setShowGallery(true);
				setPicked(value || []);
			}
		},
		[blockUpdateOrBreakTemplate, onClick, value, connectedToQuestionLibrary],
	);

	const close = useCallback(() => {
		setShowGallery(false);
		parentHideGallery();
	}, [parentHideGallery]);

	const changePicked = useCallback(newPicked => {
		setPicked(newPicked);
	}, []);

	const confirm = useCallback(
		(id = undefined, type = 'asset') => {
			close();
			const confirmAssetId = id || picked;
			onChange(confirmAssetId, option && option, type);
		},
		[close, onChange, option, picked],
	);

	useEffect(() => {
		if (parentShowGallery) {
			open();
		}
	}, [open, parentShowGallery]);

	const renderButton = (buttonFilesSet, buttonText) => {
		const buttonLabel = buttonFilesSet ? changeLabel || 'Change' : chooseLabel || 'Choose';

		return inline ? (
			<div
				className={el('inline')}
				onClick={open}
				role="button"
				tabIndex={0}
				onKeyDown={e => {
					if (e.key === 'Enter') open();
				}}
			/>
		) : (
			<>
				<div className={el('button-wrap')}>
					<button type="button" className={el('button')} onClick={open}>
						{buttonLabel}
					</button>
				</div>

				<Input
					className={el('input')}
					value={buttonText}
					placeholder={placeholderLabel}
					onClick={open}
					readOnly
				/>
			</>
		);
	};

	const renderPreview = () => {
		if (!asset && !emoji) return null;

		const isVideo = assetIsVideo(asset);

		const imageVariations = ['thumbnail'];

		if (previewMaxWidth > 100) {
			imageVariations.unshift('large', 'full');
		}

		const wrapperStyle = {
			maxWidth: `${previewMaxWidth}px`,
		};
		const imageStyle = { maxWidth: `${previewMaxWidth}px`, maxHeight: `${previewMaxHeight}px` };

		const getAssetName = () =>
			isVideo ? asset.title : /[^/]*$/.exec(getAssetVariationUrl(asset).replace(/%20/g, ' '));

		let assetToDisplay = <img src={getAssetVariationUrl(asset, imageVariations)} alt="" style={imageStyle} />;

		if (emoji && !asset) {
			const set = isApple ? 'native' : 'twitter';
			assetToDisplay = <em-emoji set={set} native={formatEmojiUnicode(emoji)} />;
		}

		return inline ? (
			<div className={el('inline')} onClick={e => open(e)} aria-hidden>
				<div className={el('preview-wrap-inline')} style={wrapperStyle}>
					{assetToDisplay}
				</div>
				<div className={el('preview-title')}>{getAssetName()}</div>
			</div>
		) : (
			<div className={el('preview-wrap')} style={wrapperStyle}>
				{option && canDelete !== false ? (
					<div className={`${el('preview-button-wrapper')} ${el('option-asset-clear')}`}>
						<IconButton icon="clear" onClick={() => onChange([0], option)} />
					</div>
				) : null}
				<div aria-hidden onClick={e => open(e)} className={option ? el('option-asset-image') : ''}>
					{isVideo ? (
						<div className={el('video-wrapper')}>
							<ReactPlayer
								url={getAssetVariationUrl(asset, ['video'])}
								width={imageStyle.maxWidth}
								height={imageStyle.maxHeight}
								controls
							/>
						</div>
					) : (
						assetToDisplay
					)}
				</div>
			</div>
		);
	};

	const filesSet = !!value && value.length > 0;
	const text = filesSet ? `${value.length} file${value.length > 1 ? 's' : ''} chosen` : '';

	return (
		<div className={`${className} ${option && el('option')}`}>
			<div ref={previewRef} className={el('asset-input-container')}>
				{/* eslint-disable-next-line no-nested-ternary */}
				{option && !preview ? (
					<IconButton icon="photo_square_outlined" onClick={open} />
				) : preview ? (
					renderPreview(filesSet)
				) : (
					renderButton(filesSet, text)
				)}
			</div>

			{showModal && (
				<WrapperComponent
					show={showGallery}
					close={close}
					isModal={isModal}
					ref={galleryRef}
					assetTop={assetTop}
					assetLeft={assetLeft}
				>
					<Gallery
						picked={picked}
						multi={multi}
						onConfirmAsset={confirm}
						onChange={changePicked}
						onClose={close}
						imageOnly={imageOnly}
						withEmojis={withEmojis}
						givenStudyId={givenStudyId}
					/>
				</WrapperComponent>
			)}
			<DetatchedScreenerModal
				show={showDisconnectModal}
				onClose={() => {
					setShowDisconnectModal(false);
				}}
				onConfirm={() => {
					setShowDisconnectModal(false);
					blockUpdateOrBreakTemplate(() => {
						setShowGallery(true);
						setPicked(value || []);
					});
				}}
			/>
		</div>
	);
};

AssetInput.propTypes = {
	changeLabel: PropTypes.string,
	chooseLabel: PropTypes.string,
	placeholderLabel: PropTypes.string,
	value: PropTypes.any,
	preview: PropTypes.bool,
	multi: PropTypes.any,
	onChange: PropTypes.func,
	asset: PropTypes.any,
	emoji: PropTypes.string,
	previewMaxWidth: PropTypes.any,
	previewMaxHeight: PropTypes.any,
	customPreviewStyle: PropTypes.any,
	inline: PropTypes.bool,
	option: PropTypes.any,
	blockUpdateOrBreakTemplate: PropTypes.func,
	showModal: PropTypes.bool,
	onClick: PropTypes.func,
	imageOnly: PropTypes.bool,
	withEmojis: PropTypes.bool,
	parentShowGallery: PropTypes.bool,
	parentHideGallery: PropTypes.func,
	canDelete: PropTypes.bool,
	connectedToQuestionLibrary: PropTypes.any,
	isModal: PropTypes.bool,
	givenStudyId: PropTypes.number,
};

export default AssetInput;
