import React from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';

import './styles.scss';
import ProductCardsPreview from 'src/components/shared/ProductCardsPreview';

const el = (name, mod) => cn(className, name, mod);
const className = 'template-preview-modal-idea-screen-section';

const IdeaScreenSection = ({ study, section, selectedLanguage }) => (
	<div className={className}>
		<span className={el('section-title')}>Idea Screen</span>
		<span className={el('section-name')}>{section?.name || 'Ideas'}</span>
		{section?.products?.length ? (
			<ProductCardsPreview study={study} section={section} selectedLanguage={selectedLanguage} />
		) : (
			<div className={el('empty')}>
				<span className={el('empty-title')}>This section is empty</span>
				<span className={el('empty-subtitle')}>
					You can add your own ideas once you start using this template.
				</span>
			</div>
		)}
	</div>
);

IdeaScreenSection.displayName = 'IdeaScreenSection';
IdeaScreenSection.propTypes = {
	study: PropTypes.any,
	section: PropTypes.any,
	selectedLanguage: PropTypes.any,
};

export default IdeaScreenSection;
