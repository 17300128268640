import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

function getCannySSOToken() {
	return axios.get(`${API_PATH}/auth/sso/canny`).then(response => response);
}

export default {
	getCannySSOToken,
};
