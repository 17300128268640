import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIsBlueprint } from 'src/hooks';
import { getLogicDropdownOptions } from 'src/domains/manage-study/selectors';
import { getLogicDropdownOptions as getBlueprintLogicDropdownOptions } from 'src/domains/manage-blueprints/selectors';
import LogicDataUtil from './Util/LogicDataUtil';
import LogicOptionsUtil from './Util/LogicOptionsUtil';
import Loader from '../Loader';

const DEFAULT_STATE = {};

const LogicConditionContext = createContext(DEFAULT_STATE);

export const useLogicConditionContext = () => {
	const context = useContext(LogicConditionContext);
	if (!context) {
		throw new Error('useLogicConditionContext must be used within a LogicConditionContextProvider');
	}

	return context;
};

export const LogicConditionContextProvider = ({ ...props }) => {
	const {
		logic,
		study,
		question,
		section,
		sections,
		logicItem,
		language,
		audience,
		tempTemplate,
		isAudienceLogic,
		onClose,
		setTempTemplate,
		patchAudienceItemLogic,
		patchItem,
		isProductLogic,
		patchSectionLogic,
		setLogic,
	} = props;

	const { currentLanguage } = study || {};

	const { isBlueprint } = useIsBlueprint();
	const logicSelector = isBlueprint ? getBlueprintLogicDropdownOptions : getLogicDropdownOptions;

	const logicData = useSelector(state =>
		logicSelector(state, {
			questionId: question?.id,
			currentLanguage: currentLanguage || language || props?.tempTemplate?.languageCode?.languageCode,
			language,
			triggerItemId: logicItem.triggerItemId,
			isAudienceLogic,
			audience,
		}),
	);

	const [showLoader, setShowLoader] = useState(false);

	// Local state used for optimistic UI updates.
	const [operatorSelectIndex, setOperatorSelectIndex] = useState(LogicDataUtil.getOperatorSelectIndex(logicItem));
	const [operandSelectIndex, setOperandSelectIndex] = useState(
		LogicDataUtil.getOperandSelectIndex(question, logicItem),
	);

	// initialize default save data
	const saveData = useMemo(() => {
		const initialSaveData = {}; // Create an empty object for save data
		initialSaveData.logic = logic ? logic.slice() : []; // Clone the logic array into this object
		LogicDataUtil.sanitizeData(initialSaveData); // Remove any values that aren't created by us from the objects in the logic array

		return initialSaveData;
	}, [logic]);

	const showDestinationSelect = useMemo(
		() => LogicDataUtil.getShowDestinationSelect(saveData, question, logicItem?.id),
		[logicItem?.id, question, saveData],
	);

	const getItemsFromPreviousSections = () => {
		let productsAndQuestions = [];
		if (!isAudienceLogic) {
			const clonedSections = sections.content.slice();
			const currentSectionIndex = clonedSections.findIndex(s => s.uuid === section.uuid);
			const previousSections = clonedSections.slice(0, currentSectionIndex + 1);
			productsAndQuestions = previousSections.filter(
				s => s.type === 'monadic_split' || s.type === 'questions' || s.type === 'swipe',
			);
		} else {
			productsAndQuestions = [
				{
					type: 'questions',
					questions: audience?.customQuestions,
				},
			];
		}

		let questionNumber = 0;
		return productsAndQuestions
			.map((s, sIndex) => {
				if (s.type === 'monadic_split' || s.type === 'questions') {
					return s.questions.map((q, qIndex) => {
						questionNumber += 1;
						return {
							...q,
							itemIndex: qIndex,
							itemShortname: `Q${questionNumber}`,
							sidebarIndex: sIndex + 1,
						};
					});
				}

				return s.products.map((p, pIndex) => {
					questionNumber += 1;
					return {
						...p,
						itemIndex: pIndex,
						itemShortname: `I${questionNumber}`,
						sidebarIndex: sIndex + 1,
					};
				});
			})
			.flat();
	};

	const itemOptions = logicData?.options;
	const destinationOptions = logicData?.destinations;
	const answerOptions = logicData?.answers;
	const attributeOptions = logicData?.attributes;
	const selectedItem = logicData?.selectedItem;

	const isOptions = LogicOptionsUtil.getIsOptions();
	const topBottomOptions = LogicOptionsUtil.getTopBottomOptions();

	const itemsFromPreviousSections = getItemsFromPreviousSections(); // Map the 'sidebarIndex' attribute to the questions so I can write a proper label for the questions select
	const currentItemIndex = itemsFromPreviousSections.findIndex(q => q && q.id === question.id); // Find the index of the current question ID in the list of questions
	const data = itemsFromPreviousSections.slice(0, currentItemIndex + 1); // Slice from the first index up until the current question index
	const skipQuestionData = data;

	/**
	 *
	 * CALLBACKS
	 *
	 */
	const saveLogic = useCallback(
		newSaveData => {
			if (isAudienceLogic) {
				const newLogicItem = newSaveData?.logic?.find(item => item.id === logicItem.id);

				const studyId = study?.id || audience?.studyId;
				const { uuid: audienceUuid } = audience;

				// remove temp_id from all logic items
				newSaveData.logic.forEach(item => delete item.temp_id);

				// Remove empty objects from logic items
				newSaveData.logic = newSaveData.logic.filter(item => Object.keys(item).length > 0);

				// Remove empty objects from trigger options
				newLogicItem.triggerOptions = newLogicItem.triggerOptions.filter(item => Object.keys(item).length > 0);

				const { id: itemId } = question;

				if (tempTemplate) {
					tempTemplate.estimatedIncidenceRate = null;
					const questionIndex = tempTemplate.customQuestions.findIndex(q => q.id === question.id);
					if (logic.length - 1 < question.logic.length) {
						// Logic already exists in our audience tempTemplate
						const logicIndex = tempTemplate.customQuestions[questionIndex].logic.findIndex(
							l => l.id === newLogicItem.id,
						);
						tempTemplate.customQuestions[questionIndex].logic[logicIndex] = newLogicItem;
					} else {
						// Logic does not yet exist - add new logic
						tempTemplate.customQuestions[questionIndex].logic.push(newLogicItem);
					}

					setTempTemplate({ ...tempTemplate });
					patchAudienceItemLogic(newLogicItem);
				} else {
					// Path the item
					patchItem(studyId, audienceUuid, itemId, newSaveData);
				}
			} else {
				const { id: studyId } = study;
				const { id: sectionId } = section;

				// remove temp_id from all logic items
				newSaveData.logic.forEach(item => delete item.temp_id);

				// Remove empty objects from logic items
				// Remove empty objects from trigger options
				newSaveData.logic = newSaveData.logic
					.filter(item => Object.keys(item).length > 0)
					.map(item => ({
						...item,
						triggerOptions: item?.triggerOptions?.filter(tItem => Object.keys(tItem).length > 0),
					}));

				if (isProductLogic) {
					patchSectionLogic(studyId, sectionId, newSaveData.logic);
				} else {
					const { id: itemId } = question;
					// Path the item
					patchItem(studyId, sectionId, itemId, newSaveData);
				}
			}
		},
		[
			audience,
			isAudienceLogic,
			isProductLogic,
			logic.length,
			logicItem,
			patchAudienceItemLogic,
			patchItem,
			patchSectionLogic,
			question,
			section,
			setTempTemplate,
			study,
			tempTemplate,
		],
	);

	const skipQuestion = useMemo(
		() => skipQuestionData?.filter(q => q && q.id === question.id)[0] || {},
		[question.id, skipQuestionData],
	);

	const doRenderGoTo = showDestinationSelect || isProductLogic;

	// re-initialize
	useEffect(() => {
		if (showLoader) {
			setShowLoader(false);
		}
		// todo - we only need to reinitialize data if the question changed.
		// initializeData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logic]); // todo - ensure logic doesn't cause infinite loop
	// }, [sections, section, audience, tempTemplate, logic]);

	const value = useMemo(
		() => ({
			answerOptions,
			attributeOptions,
			itemOptions,
			destinationOptions,
			skipQuestionData,
			selectedItem,
			isOptions,
			topBottomOptions,
			showLoader,
			setShowLoader,
			operatorSelectIndex,
			setOperatorSelectIndex,
			operandSelectIndex,
			setOperandSelectIndex,
			showDestinationSelect,
			saveLogic,
			isProductLogic,
			skipQuestion,
			doRenderGoTo,
			question,
			section,
			sections,
			saveData,
			logicItem,
			isAudienceLogic,
			audience,
			setLogic,
			setTempTemplate,
			tempTemplate,
			study,
			onClose,
		}),
		[
			answerOptions,
			attributeOptions,
			itemOptions,
			destinationOptions,
			skipQuestionData,
			selectedItem,
			isOptions,
			topBottomOptions,
			showLoader,
			operatorSelectIndex,
			operandSelectIndex,
			showDestinationSelect,
			saveLogic,
			isProductLogic,
			skipQuestion,
			doRenderGoTo,
			question,
			section,
			sections,
			saveData,
			logicItem,
			isAudienceLogic,
			audience,
			setLogic,
			setTempTemplate,
			tempTemplate,
			study,
			onClose,
			setShowLoader,
		],
	);

	// Return NULL if data has yet to be setup
	if (!answerOptions || !attributeOptions || !itemOptions || !destinationOptions || !skipQuestionData) {
		return null;
	}

	return (
		<LogicConditionContext.Provider value={value} {...props}>
			{showLoader && <Loader centered />}
			{props?.children}
		</LogicConditionContext.Provider>
	);
};

LogicConditionContextProvider.propTypes = {
	zIndexRef: PropTypes.any,
	style: PropTypes.any,
	logic: PropTypes.array,
	setLogic: PropTypes.func,
	study: PropTypes.any,
	question: PropTypes.any,
	section: PropTypes.any,
	sections: PropTypes.any,
	onClose: PropTypes.func,
	patchItem: PropTypes.func,
	deleteItemLogic: PropTypes.func,
	patchSectionLogic: PropTypes.func,
	logicItem: PropTypes.any,
	isProductLogic: PropTypes.bool,
	setTempTemplate: PropTypes.func,
	patchAudienceItemLogic: PropTypes.func,
	tempTemplate: PropTypes.any,
	audience: PropTypes.any,
	isAudienceLogic: PropTypes.bool,
	deleteAudienceItemLogic: PropTypes.func,
	language: PropTypes.string,
	answerOptions: PropTypes.array,
	setAnswerOptions: PropTypes.func,
	attributeOptions: PropTypes.array,
	itemOptions: PropTypes.array,
	destinationOptions: PropTypes.array,
	skipQuestionData: PropTypes.array,
	selectedItem: PropTypes.any,
	setSelectedItem: PropTypes.func,
	isOptions: PropTypes.any,
	topBottomOptions: PropTypes.any,
	showLoader: PropTypes.bool,
	setShowLoader: PropTypes.func,
	children: PropTypes.any,
};
