import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Iconof, Show, TextBlockPlaceholder } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import { useIsPublic } from 'src/hooks';
import { getClientId } from 'src/domains/auth/selectors';
import PropTypes from 'prop-types';
import studyService from 'src/services/study.service';
import Auth from 'src/utilities/auth';
import TemplateCard, { TemplateCardsProvider } from 'src/components/shared/TemplateCard';
import Card from 'src/components/shared/Card/index.js';
import sortOrderAscending from 'public/images/share-of-choice/sort/sort-order-ascending.svg';
import sortOrderDescending from 'public/images/share-of-choice/sort/sort-order-descending.svg';
import arrowDown from 'public/images/audience/green_arrow_left.svg';
import { tabs } from 'src/components/shared/SmartSearch';
import SelectSpace from 'src/domains/all-studies/components/SelectSpace';
import TemplatesSelection from 'src/components/shared/TemplatesSelection/index.js';
import { WrapperComponent } from 'src/components/shared/SearchResults';
import { urlParams, tableClassName, SearchHeader, FilterDropdownButton, FilteredBy } from '../index';
import SearchResultsContext from '../context';
import { useFetch } from '../hooks';
import { FilterDropdown } from '../../FilterDropdown';
import EmptySearchResults from '../shared/EmptySearchResults';
import { TemplateCardsContext } from '../../TemplateCard/context';
import './styles.scss';

const LoadingComponent = ({ limit = 20 }) => {
	const rows = [];

	const className = 'template-card';
	const el = (name, mod) => cn(className, name, mod);

	for (let i = 0; i < limit; i += 1) {
		rows.push(
			<Card variant="study" className={className} key={`key-${i}`}>
				<div className={el('header')}>
					<div>
						<span className={el('type')}>
							<TextBlockPlaceholder color="#F4F4F4" rows={1} style={{ width: '20px' }} />
						</span>
						<div className={el('title')}>
							<TextBlockPlaceholder color="#F4F4F4" rows={1} style={{ width: '120px' }} />
						</div>
					</div>
				</div>

				<div className={el('bottom-section')}>
					<div style={{ width: '100%' }}>
						<TextBlockPlaceholder color="#F4F4F4" rows={2} />
					</div>

					<div className={el('createdBy')}>
						<div className={el('logoContainer')}>
							<TextBlockPlaceholder color="#F4F4F4" rows={1} />
						</div>
						<span className={el('author')} style={{ width: '75px' }}>
							<TextBlockPlaceholder color="#F4F4F4" rows={1} />
						</span>
					</div>
				</div>
			</Card>,
		);
	}
	return rows;
};
LoadingComponent.propTypes = {
	limit: PropTypes.number,
};

const TemplateCardList = ({ templates, activeTab, loading }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);

	const { isPublic } = useIsPublic();

	const [searchParams, setSearchParams] = useSearchParams();
	const { setTemplateModalData } = useContext(TemplateCardsContext);

	const previewTemplate = searchParams.get(urlParams.templatePreview) || null;
	const checkPreviewTemplate = useCallback(
		async givenTemplate => {
			// Check if we should open the preview modal

			if (previewTemplate) {
				let template = givenTemplate;

				// If there is no template passed in, look it up
				if (!template) {
					const response = await studyService.searchStudies({
						filter: {
							search: previewTemplate,
							orderBy: 'createdAt',
							orderByDirection: 'desc',
							limit: 1,
							offset: 0,
							filter: {},
							type: 'template',
							subtype: activeTab,
						},
						isPublic,
					});

					template = response?.data?.items?.find(temp => temp?.uuid === previewTemplate);
				}
				if (template) {
					setTemplateModalData({
						step: 'preview',
						selectedTemplate: template,
						visible: true,
						isMultiStep: false,
					});
				}
			}
		},
		[activeTab, isPublic, previewTemplate, setTemplateModalData],
	);

	const handleShowPreview = useCallback(
		temp => {
			setSearchParams(params => {
				params.set(urlParams.templatePreview, temp?.uuid);

				return params;
			});
			checkPreviewTemplate(temp);
		},
		[setSearchParams, checkPreviewTemplate],
	);

	// First load
	useEffect(() => {
		checkPreviewTemplate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<div className={el('template-list')}>
				<LoadingComponent />
			</div>
		);
	}

	return (
		<>
			{!templates?.length ? <EmptySearchResults /> : null}

			<div className={el('template-list')}>
				{templates?.map(item => (
					<TemplateCard
						key={`${item?.id}${item?.type === 'combined' ? `-${item?.audienceCollectionId}` : ''}`}
						cardType={activeTab}
						id={item?.id}
						uuid={item?.uuid || null}
						name={item?.name}
						description={item?.description}
						languages={item?.languages}
						countryIds={item?.countryIds}
						isFavorite={item?.isFavorite}
						isGlobal={item?.owner?.isGlobal || item?.owner?.isGlobal}
						ownerName={item?.owner?.name || item?.accountName || ''}
						onPreview={() => handleShowPreview(item)}
						previewMultiStep={false}
						template={item}
					/>
				))}
			</div>
		</>
	);
};
TemplateCardList.propTypes = {
	templates: PropTypes.array,
	activeTab: PropTypes.string,
	loading: PropTypes.bool,
};

const Templates = ({ limit = 20, pagination = false, hidden = false, wrapperStyle }) => {
	const className = tableClassName;
	const el = (name, mod) => cn(className, name, mod);
	const { isPublic } = useIsPublic();

	const { setTotals, totals } = useContext(SearchResultsContext);

	const currentClientId = useSelector(getClientId) || null;

	const [searchParams, setSearchParams] = useSearchParams();

	const [templateData, setTemplateData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showAudienceSubMenu, setShowAudienceSubMenu] = useState(false);

	const templates = templateData?.items || [];

	// Pagination
	const currentPage = searchParams.get(urlParams.templatePage) || 1;
	const paginationData = templateData?.pagination || {};
	const paginationPages = paginationData?.pageCount;

	const tabsWithTotals = useMemo(
		() =>
			tabs?.find(tab => tab.type === 'template')?.subTabs?.map(tab => ({ ...tab, count: totals?.[tab.id] || 0 })),
		[totals],
	);

	const activeTab = searchParams.get(urlParams.subtype) || 'studyTemplate';

	const orderBy = searchParams.get(urlParams.templateOrderBy) || 'createdAt';
	const orderByDirection = searchParams.get(urlParams.templateOrderByDirection) || 'desc';

	const fetchTemplatesCallback = useCallback(async () => {
		setLoading(true);
		const offset = (currentPage - 1) * limit;

		// Filter dropdown filters (country, language, tags, templates)
		const filters = searchParams.get(urlParams.filters);
		const parsedFilters = JSON.parse(filters);
		const finalFilters = {};

		if (['audienceTemplate', 'studyTemplate', 'combinedTemplate'].includes(activeTab)) {
			if (parsedFilters?.country) {
				finalFilters.country = parsedFilters.country;
			}
			if (parsedFilters?.language) {
				finalFilters.language = parsedFilters.language;
			}
		}

		const searchString = searchParams.get(urlParams.query);
		const subtype = searchParams.get(urlParams.subtype) || 'studyTemplate';

		const baseFilters = {
			search: searchString,
			orderBy,
			orderByDirection,
			limit,
			offset,
			filter: finalFilters,
		};

		const response = await studyService.searchStudies({
			filter: {
				...baseFilters,
				type: 'template',
				subtype,
			},
			isPublic,
		});

		setTotals(prev => ({
			...prev,
			templates:
				(response?.data?.totals?.studyTemplate || 0) +
				(response?.data?.totals?.combinedTemplate || 0) +
				(response?.data?.totals?.audienceTemplate || 0),
			studyTemplate: response?.data?.totals?.studyTemplate || 0,
			combinedTemplate: response?.data?.totals?.combinedTemplate || 0,
			audienceTemplate: response?.data?.totals?.audienceTemplate || 0,
		}));

		setTemplateData(response?.data);
		setLoading(false);
	}, [activeTab, currentPage, isPublic, limit, orderBy, orderByDirection, searchParams, setTotals]);

	const handleChangeTab = useCallback(
		tab => {
			const type = tabsWithTotals?.find(t => t.type === tab)?.id;
			setSearchParams(params => {
				params.set(urlParams.subtype, type);
				params.set(urlParams.templatePage, 1);

				return params;
			});
		},
		[setSearchParams, tabsWithTotals],
	);

	const handlePrevPage = useCallback(() => {
		setSearchParams(params => {
			const current = Number(params.get(urlParams.templatePage)) || 1;
			const prevPage = current - 1;
			const newPage = Math.max(1, prevPage);

			params.set(urlParams.templatePage, newPage);

			return params;
		});
	}, [setSearchParams]);

	const handleNextPage = useCallback(() => {
		setSearchParams(params => {
			const current = Number(params.get(urlParams.templatePage)) || 1;
			const newPage = Math.min(paginationData.pageCount, current + 1);

			params.set(urlParams.templatePage, newPage);

			return params;
		});
	}, [paginationData.pageCount, setSearchParams]);

	const handleChangeSort = useCallback(
		sortBy => {
			setSearchParams(params => {
				const currentTemplateOrderBy = params.get(urlParams.templateOrderBy) || 'createdAt';
				const currentTemplateOrderByDirection = params.get(urlParams.templateOrderByDirection) || 'desc';

				if (sortBy === currentTemplateOrderBy) {
					// switch directions
					params.set(
						urlParams.templateOrderByDirection,
						currentTemplateOrderByDirection === 'desc' ? 'asc' : 'desc',
					);
				} else {
					params.set(urlParams.templateOrderBy, sortBy);
					params.set(urlParams.templateOrderByDirection, sortBy === 'name' ? 'asc' : 'desc');
				}
				return params;
			});
		},
		[setSearchParams],
	);

	const handleRefetch = useCallback(() => {
		setSearchParams(params => {
			params.set(urlParams.refetch, new Date().getTime());

			return params;
		});
	}, [setSearchParams]);

	const handleClosePreviewModal = useCallback(() => {
		setSearchParams(params => {
			params.delete(urlParams.templatePreview);

			return params;
		});
	}, [setSearchParams]);

	const handlePreviewModal = useCallback(
		template => {
			setSearchParams(params => {
				params.set(urlParams.templatePreview, template?.uuid);

				return params;
			});
		},
		[setSearchParams],
	);

	// searchParams Change refetch templates
	useFetch({ callback: fetchTemplatesCallback, type: 'templates' });

	const displayTemplates = !Auth.isDigGuest(currentClientId) && Auth.userCan('study:update') && !isPublic;
	const typeParam = searchParams.get(urlParams.type);

	useEffect(() => {
		if (!displayTemplates && typeParam?.toLowerCase()?.includes('template')) {
			setSearchParams(params => {
				params.set(urlParams.type, 'studies');

				return params;
			});
		}
	}, [displayTemplates, setSearchParams, typeParam]);

	if (hidden) {
		return null;
	}

	return (
		<>
			<Show below={961}>
				<SelectSpace />
			</Show>
			<WrapperComponent>
				<div className={el('results-table-wrapper', wrapperStyle)}>
					<div className={el('header')}>
						<div style={{ maxWidth: '100%' }}>
							<SearchHeader />
						</div>

						<div className={el('template-filters')}>
							<div className={el('filters-container')}>
								<Show above={960}>
									<SelectSpace />
								</Show>
								<FilterDropdown ButtonComponent={FilterDropdownButton} />
							</div>
						</div>
					</div>

					<div className={el('filtered-by-and-sorting-row')}>
						<FilteredBy />
						<Show above={960}>
							<div
								className={el('order_container')}
								aria-hidden
								onClick={() => {
									setShowAudienceSubMenu(prev => !prev);
								}}
							>
								<span className={el('sorted_by')}>Sorted by</span>
								<img
									src={orderByDirection === 'asc' ? sortOrderAscending : sortOrderDescending}
									alt="order-asc"
								/>
								<span className={el('most_recent')}>
									{orderBy === 'createdAt' ? 'Most Recent' : 'Alphabetical'}
								</span>
								<img
									className={el(`${!showAudienceSubMenu ? 'arrow_up' : 'arrow_down'}`)}
									src={arrowDown}
									alt="order-asc"
								/>
								<div
									className={el('audience-sub-menu-wrapper')}
									onClick={() => setShowAudienceSubMenu(prevState => !prevState)}
									aria-hidden
								>
									{showAudienceSubMenu && (
										<div className={el('audience-sub-menu')}>
											<span
												aria-hidden
												className={el(
													`audience-sub-menu-item${orderBy === 'createdAt' ? '-active' : ''}`,
												)}
												onClick={() => {
													handleChangeSort('createdAt');
													setShowAudienceSubMenu(false);
												}}
											>
												{orderBy === 'createdAt' && (
													<img
														src={
															orderByDirection === 'asc'
																? sortOrderAscending
																: sortOrderDescending
														}
														alt="order-asc"
													/>
												)}
												Most Recent
											</span>

											<span
												aria-hidden
												className={el(
													`audience-sub-menu-item${orderBy === 'name' ? '-active-name' : ''}`,
												)}
												onClick={() => {
													handleChangeSort('name');
													setShowAudienceSubMenu(false);
												}}
											>
												{orderBy === 'name' && (
													<img
														src={
															orderByDirection === 'asc'
																? sortOrderAscending
																: sortOrderDescending
														}
														alt="order-asc"
													/>
												)}
												Alphabetical
											</span>
										</div>
									)}
								</div>
							</div>
						</Show>
					</div>

					<TemplatesSelection
						onStudyTemplatesSelect={() => handleChangeTab('studyTemplate')}
						onAudienceTemplatesSelect={() => handleChangeTab('audienceTemplate')}
						activeTab={activeTab}
					/>

					<TemplateCardsProvider
						onDuplicatedTemplateCallback={handleRefetch}
						onDeletedTemplateCallback={handleRefetch}
						onClosedTemplateModalCallback={handleRefetch}
						onClosedPreviewModalCallback={handleClosePreviewModal}
						onClosedEditModalCallback={handleRefetch}
						onPreviewedCallback={handlePreviewModal}
					>
						<TemplateCardList templates={templates} activeTab={activeTab} loading={loading} />
					</TemplateCardsProvider>

					{pagination ? (
						<div className={el('pagination')}>
							<div>
								<Iconof icon="chevron_left" onClick={handlePrevPage} />
							</div>
							<div className={el('pagination-pages')}>
								<span>Page</span>
								<input className={el('pagination-current-page')} value={currentPage} readOnly />
								<span>of</span>
								<span>{paginationPages}</span>
							</div>
							<div>
								<Iconof icon="chevron_right" onClick={handleNextPage} />
							</div>
						</div>
					) : null}
				</div>
			</WrapperComponent>
		</>
	);
};

Templates.propTypes = {
	limit: PropTypes.number,
	pagination: PropTypes.bool,
	hidden: PropTypes.bool,
	wrapperStyle: PropTypes.string,
};

export default Templates;
