import React from 'react';
import PropTypes from 'prop-types';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Tooltip } from 'react-tippy';
import Input from 'src/components/inputs/Input';
import Caret from 'src/components/icons/Caret';
import { Iconof } from '@upsiide/ui-components';
import { tooltipDebounceDelay } from 'src/utilities/misc';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'button-with-dropdown';
const el = (name, mod) => cn(className, name, mod);

class ButtonWithDropdown extends React.Component {
	static defaultProps = {
		label: '',
		type: 'default',
		items: [],
		state: 'active',
	};

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false,
			query: '',
		};
	}

	setQuery = query => this.setState({ query });

	createClassName() {
		const { style, state, icon, iconPosition, customClassName } = this.props;
		return ['button-with-dropdown', style, state, customClassName, icon ? `icon-${iconPosition}` : '']
			.join(' ')
			.trim();
	}

	toggle() {
		const { dropdownOpen } = this.state;
		this.setState({
			dropdownOpen: !dropdownOpen,
		});
	}

	render() {
		const {
			label,
			headerLabel,
			footerLabel,
			footerLabelAction,
			items,
			type,
			icon,
			tooltipIcon,
			tooltipContent,
			state,
			customClassName,
			searchable,
			right,
			CaretComponent,
		} = this.props;
		const { dropdownOpen, query } = this.state;
		const filteredItems = searchable
			? items.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
			: items;

		const CaretComponentFinal = CaretComponent || Caret;

		return (
			<ButtonDropdown
				isOpen={dropdownOpen}
				toggle={this.toggle}
				disabled={state === 'disabled'}
				className={this.createClassName()}
			>
				<DropdownToggle
					disabled={state === 'disabled'}
					color=""
					className={`button ${customClassName} ${type} ${state} ${icon && `has-icon`}`}
					type={type}
				>
					<span className="button__content">
						{icon && (
							<span className="first-icon">
								<Iconof icon={icon} size="small" />
							</span>
						)}
						{label}{' '}
						{state !== 'loading' && (
							<CaretComponentFinal className={`caret ${dropdownOpen ? 'dropdownOpen' : ''}`} />
						)}
					</span>
				</DropdownToggle>
				<DropdownMenu end={right}>
					{searchable && <Input value={query} onChange={this.setQuery} autoFocus />}
					{headerLabel && <DropdownItem header>{headerLabel}</DropdownItem>}
					<div className={el('item-wrapper')}>
						{filteredItems.map((item, i) => (
							<DropdownItem
								key={`dropdown-item-${item.label}-${i}`}
								disabled={item.disabled}
								onClick={item.onClick}
							>
								<div className={el('item-content-wrapper')}>
									{item.label}
									{item.showTooltip && tooltipContent && tooltipIcon && (
										<Tooltip
											interactive
											theme="light base-size-warning-tip"
											delay={[tooltipDebounceDelay, 0]}
											arrow
											html={tooltipContent}
											popperOptions={{
												modifiers: {
													preventOverflow: { boundariesElement: 'viewport' },
												},
											}}
										>
											{tooltipIcon}
										</Tooltip>
									)}
								</div>
							</DropdownItem>
						))}
					</div>
					{footerLabel && (
						<DropdownItem
							className="dropdown-footer"
							onClick={() => {
								if (footerLabelAction) {
									footerLabelAction();
								}
							}}
						>
							{footerLabel}
						</DropdownItem>
					)}
				</DropdownMenu>
			</ButtonDropdown>
		);
	}
}

ButtonWithDropdown.propTypes = {
	label: PropTypes.string,
	headerLabel: PropTypes.string,
	type: PropTypes.string,
	items: PropTypes.array,
	state: PropTypes.oneOf(['active', 'loading', 'disabled']),
	style: PropTypes.any,
	customClassName: PropTypes.any,
	icon: PropTypes.any,
	tooltipIcon: PropTypes.any,
	tooltipContent: PropTypes.any,
	iconPosition: PropTypes.any,
	searchable: PropTypes.any,
	right: PropTypes.any,
	footerLabel: PropTypes.any,
	footerLabelAction: PropTypes.func,
	CaretComponent: PropTypes.any,
};

export default ButtonWithDropdown;
