import React, { useCallback } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import LogicSelect from '../LogicSelect';
import { useLogicConditionContext } from '../../LogicConditionContext';
import '../../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

const LogicAnswerSelect = ({
	setParentRefZIndex,
	columnWidth = 5,
	placeholder = 'answer',
	isAttribute,
	index: answersPositionIndex,
}) => {
	const {
		setShowLoader,
		question,
		section,
		sections,
		saveData,
		logicItem,
		isProductLogic,
		saveLogic,
		isAudienceLogic,
		audience,
		operandSelectIndex,
		operatorSelectIndex,
		selectedItem,
		answerOptions,
		attributeOptions,
	} = useLogicConditionContext();

	const selectedAnswers = isAttribute
		? logicItem?.triggerOptions?.map(option => option.attributeId)
		: logicItem?.triggerOptions?.map(option => option.id);

	const options = isAttribute
		? attributeOptions.map(option => {
				const isDisabled = selectedAnswers.includes(option.id);
				return {
					...option,
					isDisabled,
				};
		  })
		: answerOptions
				?.filter(option => !option.label?.includes('maskedQuestionId'))
				.map(option => {
					const isDisabled = selectedAnswers.includes(option.id);
					return {
						...option,
						isDisabled,
					};
				});

	const answerValue = logicItem?.triggerOptions[answersPositionIndex]?.id;
	const attributeValue = logicItem?.triggerOptions[answersPositionIndex]?.attributeId;

	const value = isAttribute ? attributeValue : answerValue;

	const answerOnChange = useCallback(
		answerId => {
			setShowLoader(true);

			const { id: sectionItemId } = question;
			const { id: sectionId } = section || {};

			// update trigger options with new data
			let foundOptionToUpdate = false;
			const itemData = answerOptions.find(option => option.id === answerId);

			let triggerOptionAttributeData = {};
			let triggerOptionRankedData = {};
			logicItem?.triggerOptions?.forEach(option => {
				if (option.attributeId) {
					triggerOptionAttributeData = {
						attributeId: option.attributeId,
						attributeLabel: option.attributeLabel,
						attributeChipLabel: option.attributeChipLabel,
					};
				}

				if (option.position) {
					triggerOptionRankedData = {
						position: option.position,
						rank: option.rank,
					};
				}
			});

			const savedTriggerOptions = logicItem?.triggerOptions?.map((item, itemIndex) => {
				if (itemIndex === answersPositionIndex) {
					foundOptionToUpdate = true;
					return {
						...item,
						...itemData,
						...triggerOptionAttributeData,
						...triggerOptionRankedData,
					};
				}

				return item;
			});

			// Nothing to update, must be adding a new option
			if (!foundOptionToUpdate) {
				savedTriggerOptions.push({
					...itemData,
					...triggerOptionAttributeData,
				});
			}

			const saveDataClone = {
				...saveData,
				logic: saveData?.logic?.map((item, itemIndex) => {
					if (item?.id === logicItem?.id) {
						return {
							...item,
							id: logicItem.id,
							sectionId,
							sectionItemId: isProductLogic ? null : sectionItemId,
							operator: operatorSelectIndex ? 'is_not' : 'is',
							operand: operandSelectIndex ? 'or' : 'and',
							triggerItemId: selectedItem?.data?.itemId,
							triggerOptions: savedTriggerOptions,
							...(isAudienceLogic
								? {
										audienceUuid: audience?.uuid,
										itemType: 'question',
								  }
								: {
										triggerType:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].type,
										triggerSectionId:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].id,
								  }),
						};
					}

					return item;
				}),
			};

			saveLogic(saveDataClone);
		},
		[
			saveData,
			logicItem,
			setShowLoader,
			question,
			section,
			answerOptions,
			saveLogic,
			answersPositionIndex,
			isProductLogic,
			operatorSelectIndex,
			operandSelectIndex,
			selectedItem,
			sections.content,
			isAudienceLogic,
			audience?.uuid,
		],
	);

	const attributeOnChange = useCallback(
		attributeId => {
			setShowLoader(true);

			// update trigger options with new data
			let foundOptionToUpdate = false;
			const itemData = attributeOptions.find(attribute => attribute.id === attributeId);

			const savedTriggerOptions = logicItem?.triggerOptions?.map((item, itemIndex) => {
				if (itemIndex === answersPositionIndex) {
					foundOptionToUpdate = true;
					return {
						...item,
						attributeId: itemData.id,
						attributeLabel: itemData.label,
						attributeChipLabel: itemData.chipLabel,
					};
				}

				return item;
			});

			// Nothing to update, must be adding a new option
			if (!foundOptionToUpdate) {
				savedTriggerOptions.push({
					...itemData,
					attributeId: itemData.id,
					attributeLabel: itemData.label,
					attributeChipLabel: itemData.chipLabel,
				});
			}

			const saveDataClone = {
				...saveData,
				logic: saveData?.logic?.map((item, itemIndex) => {
					if (item?.id === logicItem?.id) {
						return {
							...item,
							id: logicItem.id,
							triggerItemId: selectedItem?.data?.itemId,
							triggerOptions: savedTriggerOptions,
							...(isAudienceLogic
								? {
										audienceUuid: audience?.uuid,
										itemType: 'question',
								  }
								: {
										triggerType:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].type,
										triggerSectionId:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].id,
								  }),
						};
					}

					return item;
				}),
			};

			saveLogic(saveDataClone);
		},
		[
			setShowLoader,
			attributeOptions,
			logicItem?.triggerOptions,
			logicItem.id,
			saveData,
			saveLogic,
			answersPositionIndex,
			selectedItem,
			sections.content,
			isAudienceLogic,
			audience?.uuid,
		],
	);

	const onChange = isAttribute ? attributeOnChange : answerOnChange;

	return (
		<LogicSelect
			columnWidth={columnWidth}
			selectClassName="answers"
			options={options}
			onChange={onChange}
			menuMinWidth={270}
			value={value}
			placeholder={placeholder}
			setParentRefZIndex={setParentRefZIndex}
		/>
	);
};

LogicAnswerSelect.propTypes = {
	setParentRefZIndex: PropTypes.any,
	columnWidth: PropTypes.number,
	placeholder: PropTypes.string,
	isAttribute: PropTypes.bool,
	index: PropTypes.number,
};

export default LogicAnswerSelect;
