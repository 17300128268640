import React, { useCallback } from 'react';
import { Col } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import { useLogicConditionContext } from '../../LogicConditionContext';
import '../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

const LogicPlusMinus = ({ renderPlusIcon, renderMinusIcon, index }) => {
	const { setShowLoader, saveData, logicItem, saveLogic, setLogic } = useLogicConditionContext();

	const plusIconOnClick = useCallback(() => {
		// add empty trigger option to localLogic
		setLogic(current => {
			const newLogic = current.map(item => {
				if (item?.id === logicItem?.id) {
					return {
						...item,
						triggerOptions: [...item.triggerOptions, {}],
					};
				}
				return item;
			});
			return newLogic;
		});
	}, [logicItem?.id, setLogic]);

	const minusIconOnClick = useCallback(() => {
		setShowLoader(true);

		const newTriggerItems = logicItem.triggerOptions?.reduce((acc, item, itemIndex) => {
			if (index !== itemIndex) {
				acc.push(item);
			}
			return acc;
		}, []);

		const saveDataClone = {
			...saveData,
			logic: saveData?.logic?.map(item => {
				if (item?.id === logicItem?.id) {
					return {
						...item,
						triggerOptions: newTriggerItems,
					};
				}

				return item;
			}),
		};

		setLogic(saveDataClone.logic);
		saveLogic(saveDataClone);
	}, [index, logicItem, saveData, saveLogic, setLogic, setShowLoader]);

	const renderIcon = (id, enabled, callback) => (
		<Iconof
			className={el(`${id} ${enabled ? '' : 'hide'}`)}
			icon={id === 'minus' ? 'remove_circle' : 'add_circle'}
			onClick={enabled ? callback : null}
		/>
	);

	return (
		<Col xs={1} className={el('icons')}>
			{renderIcon('minus', renderMinusIcon, minusIconOnClick)}
			{renderIcon('plus', renderPlusIcon, plusIconOnClick)}
		</Col>
	);
};

LogicPlusMinus.propTypes = {
	renderPlusIcon: PropTypes.bool,
	renderMinusIcon: PropTypes.bool,
	index: PropTypes.number,
};

export default LogicPlusMinus;
