import axios from '../utilities/axios';
import * as misc from '../utilities/misc';

const API_PATH = `${process.env.apiPath}`;

function createBlueprint(data) {
	return axios.post(`${API_PATH}/blueprints`, data);
}

/**
 *
 * @param {number} blueprintId
 * @param {object|null} options
 *   - mode: edit
 */
function getBlueprint(blueprintId, options = {}) {
	const query = misc.toURL(options);

	return axios.get(`${API_PATH}/blueprints/${blueprintId}${query ? '?' : ''}${query}`);
}

function getBlueprints(search, filters, limit = 24, offset = 0, mode, sortBy = 'name', sortDirection) {
	const formattedFilters = {};
	Object.keys(filters).map(key => {
		if (filters[key] && filters[key].length && filters[key].join) {
			formattedFilters[key] = filters[key].join(',');
		}
	});
	return axios.get(`${API_PATH}/blueprints`, {
		params: {
			search: search || undefined,
			limit,
			offset,
			sortBy,
			sortDirection,
			...(mode ? { mode } : {}),
			...formattedFilters,
		},
	});
}

const getRecommendedBlueprints = (search, limit, offset) =>
	axios.get(`${API_PATH}/blueprints/recommended`, {
		params: {
			search: search || undefined,
			limit,
			offset,
		},
	});

/**
 *
 * @param {number} blueprintId
 * @param {object|null} options
 *   - mode: edit
 */
function updateBlueprint(blueprintId, data) {
	return axios.patch(`${API_PATH}/blueprints/${blueprintId}`, data);
}

/**
 *
 * @param {number} blueprintId
 */
async function deleteBlueprint(blueprintId, clientId) {
	await axios.delete(`${API_PATH}/blueprints/${blueprintId}`, {
		headers: {
			'x-client-uuid': clientId,
		},
	});
}

const updateBlueprintAccess = (studyId, accountIds, isDefaultBlueprint = false) =>
	axios.post(`${API_PATH}/blueprints/recommended`, {
		accountIds,
		studyId,
		blueprintNewAccountsDefault: isDefaultBlueprint,
	});

const overwriteStudyWithBlueprint = (studyId, blueprintId, bypassWizard = true) =>
	axios.post(`${API_PATH}/blueprints/apply`, { studyId, blueprintId, bypassWizard });

const createBlueprintFromStudyOrDuplicate = async (
	studyId,
	clientId,
	blueprintAccessLevel = 'space',
	studyDuplication = false,
	cloneCollection = true,
	ignoreStudySections = false,
	name = null,
) => {
	const data = {
		studyId,
		blueprintAccessLevel,
		studyDuplication,
		cloneCollection,
		ignoreStudySections,
		name,
	};
	const result = await axios.post(`${API_PATH}/blueprints/duplication`, data, {
		headers: {
			'x-client-uuid': clientId,
		},
	});

	return result;
};

const saveFavorite = async (blueprintId, data) => axios.patch(`${API_PATH}/blueprints/${blueprintId}/favorite`, data);

export default {
	createBlueprint,
	getBlueprint,
	getBlueprints,
	updateBlueprint,
	deleteBlueprint,
	updateBlueprintAccess,
	getRecommendedBlueprints,
	createBlueprintFromStudyOrDuplicate,
	overwriteStudyWithBlueprint,
	saveFavorite,
};
