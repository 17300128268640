import React, { useState, useCallback } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { Typography, Container, Row, Col, Iconof } from '@upsiide/ui-components';
import Modal from 'src/components/shared/Modal';
import Gallery from 'src/components/inputs/AssetInput/Gallery';
import * as misc from 'src/utilities/misc';
import ProductCard from 'src/components/manage/products/ProductCard';
import Button from '../Button';
import IconButton from '../IconButton';
import PLACEHOLDER_SPLITS_IMAGE from '../../../../public/images/idea_screen/1_upsiide_milk_oat.png';

const className = 'add-heatmap-image';
const el = (name, mod) => cn(className, name, mod);

const AddHeatmapImage = ({ study, question, asset, section, onAssetChange }) => {
	const [openAssetUpload, setOpenAssetUpload] = useState(false);
	const [picked, setPicked] = useState([]);
	const isSplits = section?.type === 'monadic_split';
	const confirm = useCallback(
		(id = undefined) => {
			setOpenAssetUpload(false);
			const confirmAssetId = id || picked;
			if (onAssetChange) {
				onAssetChange(confirmAssetId);
			}
		},
		[onAssetChange, picked],
	);

	const changePicked = useCallback(newPicked => {
		setPicked(newPicked);
	}, []);

	const clearAsset = useCallback(() => {
		setOpenAssetUpload(false);
		setPicked([0]);
		onAssetChange([0]);
	});

	const renderCard = product => {
		const { language: studyLanguage, currentLanguage: studyCurrentLanguage, defaultLanguage } = study;
		const language = studyLanguage || studyCurrentLanguage;

		const { settings: questionSettings } = question;
		const imageOnly = questionSettings.find(s => s.label === 'show-image-only');
		const { settings } = section;
		const imageSizeSetting = settings.find(s => s.label === 'image_size');
		const defaultImageSize = imageSizeSetting ? imageSizeSetting.value : 'medium';
		const imageSize = defaultImageSize;

		const imageCroppingSetting = settings.find(s => s.label === 'image_cropping');
		const defaultImageCropping = imageCroppingSetting ? imageCroppingSetting.value : 'fit';
		const imageCropping = defaultImageCropping;

		const ideaScreenLayoutSetting = settings.find(s => s.label === 'idea_screen_layout');
		const defaultIdeaScreenLayout = ideaScreenLayoutSetting ? ideaScreenLayoutSetting.value : 'invalid_layout';
		const ideaScreenLayout = defaultIdeaScreenLayout;

		const { translations } = product;
		const defaultTranslation = translations
			? translations.find(translation => translation.languageCode === defaultLanguage)
			: { fieldOne: '', fieldTwo: '', fieldThree: '' };

		let imgTag = <div className={el('image-only--no-image')} />;
		if (defaultTranslation?.fieldOneType === 'asset') {
			imgTag = <img className={el('image-only')} alt="Product" src={defaultTranslation?.fieldOne[0]?.url} />;
		} else if (defaultTranslation?.fieldTwoType === 'asset') {
			imgTag = <img className={el('image-only')} alt="Product" src={defaultTranslation?.fieldTwo[0]?.url} />;
		} else if (defaultTranslation?.fieldThreeType === 'asset') {
			imgTag = <img className={el('image-only')} alt="Product" src={defaultTranslation?.fieldThree[0]?.url} />;
		}

		const renderProductCard =
			imageOnly?.value === 'true' ? (
				imgTag
			) : (
				<ProductCard
					study={study}
					language={language}
					product={product}
					imageSize={imageSize}
					imageCropping={imageCropping}
					ideaScreenLayout={ideaScreenLayout}
				/>
			);
		return renderProductCard;
	};

	const renderHeatmapUpload = () => {
		if (isSplits) {
			const products = section?.products ? [...section.products] : undefined;
			const { settings } = section;
			let ideasPerPerson = settings.find(s => s.label === 'ideas_per_person');
			ideasPerPerson = ideasPerPerson ? parseInt(ideasPerPerson.value) : 1;
			const ideaScreenLayoutSetting = settings.find(s => s.label === 'idea_screen_layout');
			const defaultIdeaScreenLayout = ideaScreenLayoutSetting ? ideaScreenLayoutSetting.value : 'imageOnly';
			const ideaScreenLayout = defaultIdeaScreenLayout;
			const placeholder = (
				<div className="idea-placeholder">
					<img src={PLACEHOLDER_SPLITS_IMAGE} alt="no-product-selected" />
				</div>
			);
			const renderProduct =
				products && products[ideasPerPerson - 1] ? renderCard(products[ideasPerPerson - 1]) : placeholder;
			const splitsHeatmapDescription = (
				<>
					<Typography classes={el('product-container--title')} variant="h5">
						Idea card heatmap
					</Typography>
					<Typography classes={el('product-container--caption')} variant="caption">
						Respondents will be shown the same idea(s) from the splits exercise
					</Typography>
				</>
			);
			const splitsHeatmapDescriptionNoIdeas = (
				<>
					<Typography classes={el('product-container--caption-warning')} variant="caption" gutter={false}>
						<Iconof icon="warning" /> Add idea cards to use the heatmap
					</Typography>
					<Typography classes={el('product-container--title')} variant="h5" gutter={false}>
						Idea card heatmap
					</Typography>
					<Typography classes={el('product-container--caption')} variant="caption" gutter={false}>
						Respondents will be shown the same idea(s) from the splits exercise
					</Typography>
				</>
			);
			return (
				<Container
					className={`${className} ${asset && asset?.id && ' has-asset'}${
						products && products[ideasPerPerson - 1] ? '' : ' no-products'
					}`}
				>
					<Row>
						<Col xs={products?.length > 1 ? 5 : 3} justifyContent="center">
							<div className={el('product-container')}>
								{products?.length > 1 && (
									<div className={el('all-products')}>
										{products &&
											products
												.slice(0, 9)
												.map((product, i) => (
													<div
														className={`${el('product-grid-item')}${
															i < ideasPerPerson ? ' active' : ''
														}`}
													>
														{renderCard(product)}
													</div>
												))}
										{products && products.slice(9).length > 0 && (
											<Typography variant="tiny">+ {products.slice(9).length} more</Typography>
										)}
									</div>
								)}
								{renderProduct}
							</div>
						</Col>

						<Col xs={7} alignItems="center">
							{products && products[0] ? (
								<div className={el('product-container-description')}>{splitsHeatmapDescription}</div>
							) : (
								<div className={el('product-container-description')}>
									{splitsHeatmapDescriptionNoIdeas}
								</div>
							)}
						</Col>
					</Row>
				</Container>
			);
		}
		return (
			<>
				{openAssetUpload && (
					<Modal
						padding={0}
						width={500}
						show
						onClose={() => {
							setOpenAssetUpload(false);
							setPicked([0]);
						}}
					>
						<Gallery
							picked={picked}
							multi={false}
							onConfirmAsset={confirm}
							onChange={changePicked}
							onClose={() => {
								setOpenAssetUpload(false);
							}}
							imageOnly
						/>
					</Modal>
				)}

				<Container className={`${className} ${asset && asset?.id && ' has-asset'}`} alignItems="center">
					{!(asset && asset?.id) && (
						<Row className={el('container-row')}>
							<Col justifyContent="center" alignItems="center" xs={12}>
								<img
									className={el('file-upload-image')}
									src="/public/images/gallery/framed-picture.svg"
									alt="File Uploading"
								/>
							</Col>
							<Col justifyContent="center" alignItems="center" xs={12}>
								<Typography variant="h4">Select an image to optimize</Typography>
							</Col>
							<Col justifyContent="center" alignItems="center" xs={12}>
								<Typography variant="caption">
									This image will be displayed to the respondents and they will be able to click on it
									in the survey
								</Typography>
							</Col>
							{onAssetChange && (
								<Col justifyContent="center" alignItems="center" xs={12}>
									<Button
										label="Add an image"
										type="button"
										className={el('add-image-button')}
										onClick={() => {
											setOpenAssetUpload(true);
										}}
									/>
								</Col>
							)}
						</Row>
					)}
					{asset && asset?.id && (
						<Row className={el('container-row')}>
							<img
								className={el('image-wrapper')}
								src={misc.getAssetVariationUrl(asset, ['thumbnail'])}
								alt={asset?.title}
							/>
						</Row>
					)}
					{onAssetChange && (
						<div className={`${el('image-controls')} ${asset && asset?.id && ' has-asset'}`}>
							<IconButton icon="delete_outlined" tooltip="Delete Image" onClick={clearAsset} />
							<IconButton icon="edit" tooltip="Edit Image" onClick={() => setOpenAssetUpload(true)} />
						</div>
					)}
				</Container>
			</>
		);
	};

	return renderHeatmapUpload();
};

AddHeatmapImage.propTypes = {
	study: PropTypes.object,
	question: PropTypes.object,
	section: PropTypes.object,
	onAssetChange: PropTypes.func,
	asset: PropTypes.object,
};

export default AddHeatmapImage;
