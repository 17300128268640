import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Loadable from 'src/components/shared/Loadable';
import Demographic from 'src/domains/manage-study/containers/AudienceContainer/Demographic';
import AudienceScreeningQuestions from 'src/components/shared/AudienceScreeningQuestions';
import ScreeningQuestionWrapper from 'src/domains/manage-study/containers/AudienceContainer/ScreeningQuestionWrapper';
import InterlockQuotasWrapper from '../interlockQuotasWrapper';
import DemographicOverview from '../../DemographicOverview';

import './styles.scss';

const className = 'audience-edit-content';
const el = (name, mod) => cn(className, name, mod);

const AudienceTemplateEditContent = ({
	template,
	demographicGroup,
	setDemographicGroup,
	type,
	step,
	loading,
	selectedLanguage,
	setTemplate,
	setCurrentTab,
	onAddGroup,
	setIndexGroup,
	setErrors,
	isValid,
	setInterlockQuotasData,
	interlockQuotasData,
	setTemplateModalCurrentAudience,
	errors,
}) => {
	const renderDemographic = () => {
		if (demographicGroup) {
			return (
				<div className={el('content_demographic')}>
					<Demographic
						demographicGroup={demographicGroup}
						viewMode={loading || step === 'editViewMode'}
						templateGroupEditMode
						setCurrentTab={setCurrentTab}
						template={template}
						setTemplate={setTemplate}
					/>
				</div>
			);
		}
		return <div> No groups </div>;
	};
	return (
		<div className={className}>
			<div className={el('content')}>
				{loading ? (
					<Loadable loading={loading}>
						<div style={{ height: 300 }} />
					</Loadable>
				) : (
					<>
						{type === 'overview' ? (
							<DemographicOverview
								viewMode={false}
								template={template}
								setCurrentTab={setCurrentTab}
								setDemographicGroup={setDemographicGroup}
								onAddGroup={onAddGroup}
								setTemplate={setTemplate}
								setIndexGroup={setIndexGroup}
								setTemplateModalCurrentAudience={setTemplateModalCurrentAudience}
							/>
						) : null}

						{type === 'demographic' ? renderDemographic() : null}

						{type === 'interlock-quotas' ? (
							<InterlockQuotasWrapper
								studyId={demographicGroup?.studyId}
								demographicQuestions={
									template.demographicGroups.find(g => g?.uuid === demographicGroup?.uuid)
										?.demographicQuestions
								}
								screeningQuestions={template?.screeningQuestions}
								collectionId={template?.id}
								isValid={isValid}
								interlockQuotasData={interlockQuotasData}
								setInterlockQuotasData={setInterlockQuotasData}
								groupId={demographicGroup?.id}
								groupUuid={demographicGroup?.uuid}
								setErrors={setErrors}
								errors={errors}
								selectedLanguage={selectedLanguage?.languageCode}
							/>
						) : null}

						{type === 'screening_overview' && template?.screeningQuestions?.length ? (
							<div className={el('screening_content')}>
								<AudienceScreeningQuestions
									audienceTemplate={template}
									setCurrentTab={setCurrentTab}
									selectedLanguage={selectedLanguage}
									step="edit"
								/>
							</div>
						) : null}

						{type === 'screening' ||
						(type === 'screening_overview' && template?.screeningQuestions?.length === 0) ? (
							<div className={el('screening')}>
								<ScreeningQuestionWrapper
									viewMode={loading || step === 'editViewMode'}
									audienceTemplate={template}
									setTemplate={setTemplate}
									selectLanguage={selectedLanguage}
								/>
							</div>
						) : null}
					</>
				)}
			</div>
		</div>
	);
};

AudienceTemplateEditContent.displayName = 'AudienceTemplateEditContent';
AudienceTemplateEditContent.propTypes = {
	template: PropTypes.object,
	demographicGroup: PropTypes.object,
	setDemographicGroup: PropTypes.func,
	type: PropTypes.string,
	step: PropTypes.string,
	loading: PropTypes.bool,
	setCurrentTab: PropTypes.func,
	selectedLanguage: PropTypes.object,
	setTemplate: PropTypes.func,
	onAddGroup: PropTypes.func,
	setIndexGroup: PropTypes.func,
	setErrors: PropTypes.func,
	isValid: PropTypes.bool,
	setInterlockQuotasData: PropTypes.func,
	interlockQuotasData: PropTypes.any,
	errors: PropTypes.any,
	setTemplateModalCurrentAudience: PropTypes.func,
};

export default AudienceTemplateEditContent;
