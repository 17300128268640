import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import AddNewItemButton from 'src/components/elements/AddNewItemButton';
import cn from 'src/utilities/bem-cn';
import CONSTANTS from 'src/config/constants';
import './styles.scss';
import Button from '../Button';

const className = 'add-question-button';
const el = (name, mod) => cn(className, name, mod);

const AddQuestionButton = ({
	audience,
	question,
	addQuestionOptions,
	isLastQuestionItem,
	tempTemplate,
	blockUpdateOrBreakTemplate,
	screeningQuestion,
	setOpenQuestionLibrary = false,
	customLabel = 'Create from Scratch +',
	isModal = false,
}) => {
	const [showAddQuestionTooltip, setShowAddQuestionTooltip] = useState(false);
	let addQuestionOptionsFiltered = [...addQuestionOptions];
	const renderChooseAQuestionType = () => {
		if (audience && addQuestionOptions.length === 4) {
			addQuestionOptionsFiltered = addQuestionOptions.filter(
				option =>
					option.name !== CONSTANTS.questions.options.style.openEnded &&
					option.name !== CONSTANTS.questions.options.style.emoji,
			);
		}
		if (!isLastQuestionItem && screeningQuestion) {
			return (
				<div className={el('options')}>
					<span className={el('tooltip-title-screening')}>Select an option</span>

					{addQuestionOptionsFiltered &&
						addQuestionOptionsFiltered.map((option, optionIndex) => (
							<Button
								key={optionIndex}
								label={option.label}
								type="button"
								className={el('option-item', 'keyline no-boder')}
								onClick={() => {
									setShowAddQuestionTooltip(false);
									if (question) {
										option.onClick(question.id);
									} else {
										option.onClick();
									}
								}}
							/>
						))}

					<span
						className={el('tooltip-bottom')}
						onClick={() => {
							setShowAddQuestionTooltip(false);
							if (setOpenQuestionLibrary) setOpenQuestionLibrary(true);
						}}
						aria-hidden
					>
						or <span>Add question from library</span>
					</span>
				</div>
			);
		}

		return (
			<div className={el('options')}>
				{isModal && screeningQuestion ? null : (
					<span className={el('tooltip-title-screening')}>Select an option</span>
				)}

				{addQuestionOptionsFiltered &&
					addQuestionOptionsFiltered.map((option, optionIndex) => (
						<Button
							key={optionIndex}
							label={option.label}
							type="button"
							className={el('option-item', 'keyline no-boder')}
							onClick={() => {
								if (question) {
									option.onClick(question.id);
								} else {
									option.onClick();
								}
								setShowAddQuestionTooltip(false);
							}}
						/>
					))}
			</div>
		);
	};

	return (
		<Tooltip
			arrow
			interactive
			position={isLastQuestionItem ? 'top' : 'bottom'}
			open={showAddQuestionTooltip}
			theme="light add-question"
			onRequestClose={() => {
				setShowAddQuestionTooltip(false);
			}}
			html={renderChooseAQuestionType()}
		>
			{isLastQuestionItem ? (
				<div className={el('add-question-row-last', !question && el('empty'))}>
					{screeningQuestion ? (
						<div style={{ marginTop: -11 }}>
							<Button
								label={customLabel}
								type="text"
								className={el('secondary-button')}
								onClick={() => {
									if (tempTemplate) blockUpdateOrBreakTemplate(() => setShowAddQuestionTooltip(true));
									else setShowAddQuestionTooltip(true);
								}}
							/>
						</div>
					) : !screeningQuestion && audience ? null : (
						<AddNewItemButton
							label="Add new question"
							onClick={() => {
								if (tempTemplate) blockUpdateOrBreakTemplate(() => setShowAddQuestionTooltip(true));
								else setShowAddQuestionTooltip(true);
							}}
						/>
					)}
				</div>
			) : (
				<div className={el('add-question-row', showAddQuestionTooltip ? 'open' : '')}>
					<Button
						type="text"
						areaLabel="Add Question"
						onClick={() => setShowAddQuestionTooltip(true)}
						icon="add_circle"
						iconClasses="icon-no-margin green-400"
						className={el('icon-of-background', 'no-hover-effect button-inbetween')}
					/>
				</div>
			)}
		</Tooltip>
	);
};

AddQuestionButton.propTypes = {
	audience: PropTypes.any,
	question: PropTypes.any,
	addQuestionOptions: PropTypes.any,
	isLastQuestionItem: PropTypes.bool,
	tempTemplate: PropTypes.any,
	blockUpdateOrBreakTemplate: PropTypes.func,
	screeningQuestion: PropTypes.bool,
	setOpenQuestionLibrary: PropTypes.any,
	customLabel: PropTypes.string,
	isModal: PropTypes.bool,
};

export default AddQuestionButton;
