import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import cn from 'src/utilities/bem-cn';
import { useIsPublic, useGetStudyValidations } from 'src/hooks';
import ToggleSwitch from 'src/components/elements/ToggleSwitch';
import { studySampleService } from 'src/services';
import Auth from 'src/utilities/auth';
import * as misc from 'src/utilities/misc';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as manageStudyActions from 'src/domains/manage-study/actions';
import * as services from 'src/services';
import AudiencePreLaunchCheckModal from 'src/domains/manage-study/components/audience/AudiencePreLaunchCheckModal';
import studyUtilities from 'src/utilities/study';
import './styles.scss';

const className = 'demographic-status';
const el = (name, mod) => cn(className, name, mod);

const DemographicStatus = ({ group = {} }) => {
	const dispatch = useDispatch();
	const study = useSelector(state => manageStudySelectors.getStudy(state));
	const [showPrelaunchModal, setShowPrelaunchModal] = useState(false);
	const { isPublic } = useIsPublic();
	const studyErrors = useGetStudyValidations();

	const [isLive, setLive] = useState(group?.status === 'live');

	const updateCollections = async () => {
		const response = await services.audienceService.getCollection(group?.studyId, group?.audienceCollectionId);
		dispatch(manageStudyActions.setAudienceCollection({ loading: false, content: response?.data || null }));
	};

	const preLaunchCheck = liveValue => {
		if (study.languages.length > 1 && group.currentSample === 0) {
			setShowPrelaunchModal(true);
		} else {
			launchBYOAudience(liveValue);
		}
	};

	const launchBYOAudience = async liveValue => {
		try {
			await studySampleService.launchAudience(group?.studyId, group?.uuid);
			setLive(liveValue);
			updateCollections();
		} catch (e) {
			toastr.error('There was a problem launching the audience, please wait a moment and try again.');
			console.error(e);
		}
	};

	const stopBYOAudience = liveValue => {
		setLive(liveValue);
		dispatch(manageStudyActions.stopAudience(group?.studyId, group?.uuid));
		updateCollections();
	};

	const setStudyLaunch = liveValue => {
		if (liveValue) {
			preLaunchCheck(liveValue);
		} else stopBYOAudience(liveValue);
	};

	const missingTranslationsUnlaunchable = useMemo(() => {
		let unlaunchable = false;
		studyErrors.forEach(error => {
			if (error.languageCode === group?.languageCode && error.type.includes(misc.ERROR_TYPES.noContent)) {
				unlaunchable = true;
			}
		});
		return unlaunchable;
	}, [studyErrors, group]);

	const groupHaveIssue = useMemo(() => {
		let hasIssue = false;
		studyErrors.forEach(error => {
			if (error.languageCode === group?.languageCode) {
				if (
					error.type.includes(misc.ERROR_TYPES.noContent) ||
					error.type.includes(misc.ERROR_TYPES.missingTranslation) ||
					error.type.includes(misc.ERROR_TYPES.missingContent)
				) {
					hasIssue = true;
				}
			}
		});
		return hasIssue;
	}, [studyErrors, group]);

	const renderToggle = () => {
		if (!group.languageCode) {
			return (
				<Tooltip
					className={el('tooltip')}
					animation="shift"
					animationFill={false}
					interactive
					trigger="mouseenter"
					position="left"
					theme="overview-price"
					distance={2}
					html={
						<div className={el('tooltip-text-container-byo')}>Select a language to turn this link on</div>
					}
				>
					<>
						<ToggleSwitch
							onToggle={(e, liveValue) => setStudyLaunch(liveValue)}
							toggleCriteria={isLive}
							disabled={!group?.languageCode || missingTranslationsUnlaunchable}
						/>
						{isLive ? 'Live' : 'Off'}
					</>
				</Tooltip>
			);
		}
		return (
			<>
				<ToggleSwitch
					onToggle={(e, liveValue) => setStudyLaunch(liveValue)}
					toggleCriteria={isLive}
					disabled={!group?.languageCode || missingTranslationsUnlaunchable}
				/>
				{isLive ? 'Live' : 'Off'}
			</>
		);
	};

	const closeModal = () => {
		setShowPrelaunchModal(false);
	};

	const BYOReadyStatus = () =>
		isPublic || !Auth.userCan('study:update') ? (
			readyStatus()
		) : (
			<div className={`${el('byo-draft')} ${className}`}>{renderToggle()}</div>
		);

	const BYOLiveStatus = () =>
		isPublic || !Auth.userCan('study:update') ? (
			liveStatus()
		) : (
			<Tooltip
				className={el('tooltip')}
				animation="shift"
				animationFill={false}
				interactive
				trigger="mouseenter"
				position="bottom"
				theme="overview-price"
				distance={2}
				html={
					<div className={el('tooltip-text-container')}>
						<p>Launched on</p>
						<div className={el('tooltip-timestamp')}>{studyUtilities.reformatDate(group?.launchedAt)}</div>
					</div>
				}
			>
				<div className={`${className} ${el('byo-live')}`}>{renderToggle()}</div>
			</Tooltip>
		);

	const BYOCompleteStatus = () =>
		isPublic || !Auth.userCan('study:update') ? (
			completeStatus()
		) : (
			<Tooltip
				className={el('tooltip')}
				animation="shift"
				animationFill={false}
				interactive
				trigger="mouseenter"
				position="bottom"
				theme="overview-price"
				distance={2}
				html={
					<div className={el('tooltip-text-container')}>
						<p>Completed on</p>
						<div className={el('tooltip-timestamp')}>{studyUtilities.reformatDate(group?.closedAt)}</div>
					</div>
				}
			>
				<div className={`${className} ${el('byo-live')}`}>{renderToggle()}</div>
			</Tooltip>
		);

	const readyStatus = () => <div className={`${el('draft')} ${className}`}>Ready</div>;

	const issueStatus = () => <div className={`${el('issue')} ${className}`}>Issue</div>;

	const liveStatus = () => (
		<Tooltip
			className={el('tooltip')}
			animation="shift"
			animationFill={false}
			interactive
			trigger="mouseenter"
			position="bottom"
			theme="overview-price"
			distance={2}
			html={
				<div className={el('tooltip-text-container')}>
					<p>Launched on</p>
					<div className={el('price')}>{studyUtilities.reformatDate(group?.launchedAt)}</div>
				</div>
			}
		>
			<div className={`${className} ${el('live')}`}>
				<div className="circle" />
				Live
			</div>
		</Tooltip>
	);

	const completeStatus = () => (
		<Tooltip
			className={el('tooltip')}
			animation="shift"
			animationFill={false}
			interactive
			trigger="mouseenter"
			position="bottom"
			theme="overview-price"
			distance={2}
			html={
				<div className={el('tooltip-text-container')}>
					<p>Completed on</p>
					<div className={el('tooltip-timestamp')}>{studyUtilities.reformatDate(group?.closedAt)}</div>
				</div>
			}
		>
			<div className={`${className} ${el('complete')}`}>Complete</div>
		</Tooltip>
	);

	const stoppedStatus = () => (
		<Tooltip
			className={el('tooltip')}
			animation="shift"
			animationFill={false}
			interactive
			trigger="mouseenter"
			position="bottom"
			theme="overview-price"
			distance={2}
			html={
				<div className={el('tooltip-text-container')}>
					<p>Stopped on</p>
					<div className={el('tooltip-timestamp')}>{studyUtilities.reformatDate(group?.closedAt)}</div>
				</div>
			}
		>
			<div className={`${el('stopped')} ${className}`}>Stopped</div>
		</Tooltip>
	);

	const pausedStatus = () => (
		<Tooltip
			className={el('tooltip')}
			animation="shift"
			animationFill={false}
			interactive
			trigger="mouseenter"
			position="bottom"
			theme="overview-price"
			distance={2}
			html={
				<div className={el('tooltip-text-container')}>
					<p>Paused on</p>
					<div className={el('tooltip-timestamp')}>{studyUtilities.reformatDate(group?.closedAt)}</div>
				</div>
			}
		>
			<div className={`${el('stopped')} ${className}`}>Paused</div>
		</Tooltip>
	);

	let renderStatus = '';

	if (groupHaveIssue) {
		renderStatus = issueStatus();
	} else if (group?.provider === 'BYO') {
		switch (group?.status) {
			case 'ready':
				renderStatus = BYOReadyStatus();
				break;
			case 'live':
				renderStatus = BYOLiveStatus();
				break;
			case 'complete':
				renderStatus = BYOCompleteStatus();
				break;
		}
	} else {
		switch (group?.status) {
			case 'needs-review':
			case 'live':
				renderStatus = liveStatus();
				break;
			case 'slow-quotas':
				renderStatus = liveStatus();
				break;
			case 'complete':
				renderStatus = completeStatus();
				break;
			case 'ready':
				renderStatus = readyStatus();
				break;
			case 'system-stopped':
				renderStatus = stoppedStatus();
				break;
			case 'system-paused':
				renderStatus = pausedStatus();
				break;
		}
	}

	return (
		<>
			<AudiencePreLaunchCheckModal
				showModal={showPrelaunchModal}
				closeModal={closeModal}
				launchBYOAudience={launchBYOAudience}
			/>
			<div>{renderStatus}</div>
		</>
	);
};

DemographicStatus.propTypes = {
	group: PropTypes.object,
};

export default DemographicStatus;
