const prefix = 'user-profile';

export const FETCH_USER = `${prefix}/FETCH_USER`;
export const fetchUser = () => ({ type: FETCH_USER });

export const SET_USER = `${prefix}/SET_USER`;
export const setUser = user => ({ type: SET_USER, payload: { user } });

export const UPDATE_USER = `${prefix}/UPDATE_USER`;
export const updateUser = user => ({ type: UPDATE_USER, payload: { user } });

export const INC_LOADING = `${prefix}/INC_LOADING`;
export const incLoading = () => ({ type: INC_LOADING });

export const DEC_LOADING = `${prefix}/DEC_LOADING`;
export const decLoading = () => ({ type: DEC_LOADING });

export const SET_ERROR = `${prefix}/SET_ERROR`;
export const setError = error => ({ type: SET_ERROR, payload: { error } });

export const SET_SUBMIT_BUTTON = `${prefix}/SET_SUBMIT_BUTTON`;
export const setSubmitBtnText = submitBtnText => ({ type: SET_SUBMIT_BUTTON, payload: { submitBtnText } });

export const UPDATE_USER_SETTINGS = `${prefix}/UPDATE_USER_SETTINGS`;
export const updateUserSettings = settings => ({ type: UPDATE_USER_SETTINGS, payload: { settings } });

export const SET_USER_SETTINGS = `${prefix}/SET_USER_SETTINGS`;
export const setUserSettings = settings => ({ type: SET_USER_SETTINGS, payload: { settings } });

export const RESENT_EMAIL_INVITATION = `${prefix}/RESENT_EMAIL_INVITATION`;
export const resentEmailInvitation = email => ({ type: RESENT_EMAIL_INVITATION, payload: { email } });
