import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Iconof } from '@upsiide/ui-components';
import { Tooltip } from 'react-tippy';
import cn from 'src/utilities/bem-cn';

const className = 'section-type-icon';
const el = name => cn(className, name);
/**
 * SectionTypeIcon is used within left side navigation for create / report views.
 */
const SectionTypeIcon = ({ type }) => {
	const sectionTypeName = {
		statement: 'Statement',
		questions: 'Questions',
		['screening-questions']: 'Screening Questions',
		['link-routing']: 'URL Redirect',
		['idea-screen']: 'Idea Screen',
		['idea-split']: 'Idea Split',
	}[type];

	const getIcon = () => {
		switch (type) {
			case 'statement':
				return <Iconof className={`${el('statement-icon')} ${el('centre-icon')}`} icon="quotes" size="large" />;
			case 'questions':
				return <Iconof className={el('question-icon')} icon="checkmark_square_outlined" size="large" />;
			case 'screening-questions':
				return <Iconof className={el('screening-question-icon')} icon="chart_bar_4" size="large" />;
			case 'link-routing':
				return <Iconof className={el('link-routing-icon')} icon="route_dashed" size="large" />;
			case 'idea-screen':
				return (
					<Iconof
						className={`${el('swipe-icon')} ${el('centre-icon')}`}
						icon="gesture_swipe_left_right"
						size="large"
					/>
				);
			case 'idea-split':
				return <Iconof className={`${el('idea-split-icon')}`} icon="shuffle" size="large" />;
			default:
				break;
		}
	};

	return (
		<div className={el('icon')}>
			<Tooltip trigger="mouseenter" position="right" theme="upload-file-download" title={sectionTypeName}>
				{getIcon()}
			</Tooltip>
		</div>
	);
};

SectionTypeIcon.propTypes = {
	/** Used to switch icons between different section types. */
	type: PropTypes.oneOf(['statement', 'link-routing', 'questions', 'idea-screen', 'idea-split']),
};

export default SectionTypeIcon;
