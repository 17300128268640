import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Input from 'src/components/inputs/Input';
import audienceStatusUtil from 'src/utilities/audiences-status-error-helper';
import pulsatingCircle from 'public/images/audience/live_pulsating_circle.svg';
import CustomPercentageCircle from 'src/components/shared/CustomPercentageCircle';
import './styles.scss';

const className = 'audience-responses';
const el = (name, mod) => cn(className, name, mod);
const AudienceResponses = ({
	mode,
	currentSample,
	sampleSize,
	totalSample,
	onChangeResponses,
	isLive = false,
	isDraft = false,
	viewMode = false,
	percentage = false,
	hasLiveCircle = false,
	isSystemStopped = false,
	inDemographicQuestionsReport = false,
}) => {
	const [localResponses, setLocalResponses] = useState(sampleSize);

	useEffect(() => {
		if (sampleSize) {
			setLocalResponses(sampleSize);
		}
	}, [sampleSize]);

	const renderContent = () => {
		if (isDraft && !viewMode && mode === 'group' && !inDemographicQuestionsReport) {
			return (
				<Input
					className={el('number_responses', localResponses < 100 || localResponses > 5000 ? 'error' : '')}
					value={localResponses}
					onChange={value => setLocalResponses(Math.max(value, 0))}
					onBlur={() => onChangeResponses && onChangeResponses(localResponses)}
					type="number"
					min={100}
				/>
			);
		}

		if (isDraft && viewMode) {
			return (
				<div className={el('bottom-line')}>
					<span className={el('total')}>{totalSample}</span>
				</div>
			);
		}

		return (
			<div className={el('bottom-line')}>
				<span className={el('total')}>
					<span className={el('res_collected')}>{currentSample || 0}</span> /
					{totalSample === 0 || (audienceStatusUtil.haveByoGroupsWithoutSampleSize() && mode === 'collection')
						? '∞'
						: totalSample}
				</span>
				{typeof percentage !== 'boolean' && totalSample > 0 ? (
					<>
						<span>•</span>
						<span>
							<CustomPercentageCircle
								customClass={false}
								percentage={percentage}
								width={16}
								height={16}
								foregroundColor={isSystemStopped ? 'red' : undefined}
								backgroundColor={isSystemStopped ? '#f4f4f4' : undefined}
							/>
						</span>
						<span className={`${el('resp_percentage')} ${isSystemStopped ? 'stopped' : ''}`}>
							{percentage}%
						</span>
					</>
				) : null}
			</div>
		);
	};

	return (
		<div className={`${className} ${isLive ? 'live' : ''}`}>
			<div className={el('bottom-line')}>
				{hasLiveCircle ? <img src={pulsatingCircle} alt="live" /> : null}
				<span>Responses</span>
			</div>
			{renderContent()}
		</div>
	);
};

AudienceResponses.displayName = 'AudienceResponses';
AudienceResponses.propTypes = {
	mode: PropTypes.any,
	isLive: PropTypes.bool,
	isDraft: PropTypes.bool,
	isSystemStopped: PropTypes.bool,
	inDemographicQuestionsReport: PropTypes.bool,
	viewMode: PropTypes.bool,
	hasLiveCircle: PropTypes.bool,
	currentSample: PropTypes.number,
	sampleSize: PropTypes.number,
	totalSample: PropTypes.number,
	percentage: PropTypes.number,
	onChangeResponses: PropTypes.func,
};

export default AudienceResponses;
