import React from 'react';
import SectionTypeIcon from 'src/components/shared/SectionTypeIcon';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'idea-asset';
const el = (name, mod) => cn(className, name, mod);

const IdeaAsset = ({ fieldOneType, fieldOne, fieldTwoType, fieldTwo, fieldThreeType, fieldThree }) => {
	if (fieldOneType === 'asset' && fieldOne[0]?.mediaType === 'image') {
		return <div className={el('idea-image')} style={{ backgroundImage: `url(${fieldOne[0]?.url})` }} />;
	}

	if (fieldTwoType === 'asset' && fieldTwo[0]?.mediaType === 'image') {
		return <div className={el('idea-image')} style={{ backgroundImage: `url(${fieldTwo[0]?.url})` }} />;
	}

	if (fieldThreeType === 'asset' && fieldThree[0]?.mediaType === 'image') {
		return <div className={el('idea-image')} style={{ backgroundImage: `url(${fieldThree[0]?.url})` }} />;
	}

	return <SectionTypeIcon type="idea-screen" />;
};

IdeaAsset.propTypes = {
	fieldOneType: PropTypes.string,
	fieldOne: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	fieldTwoType: PropTypes.string,
	fieldTwo: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	fieldThreeType: PropTypes.string,
	fieldThree: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default IdeaAsset;
