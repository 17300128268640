import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'asset-input-gallery-drag-zone';
const el = (name, mod) => cn(className, name, mod);

const delay = (f, time) => {
	let timerId = null;
	return () => {
		if (timerId) {
			clearTimeout(timerId);
		}
		timerId = setTimeout(() => {
			if (timerId) {
				clearTimeout(timerId);
			}
			f();
		}, time);
	};
};

class DragZone extends React.Component {
	state = { visible: false };

	hideAfterTimeout = delay(() => {
		this.setState({ visible: false });
	}, 2000);

	dragover = e => {
		const { disabled } = this.props;
		if (!disabled) {
			this.setState({ visible: true });
			this.hideAfterTimeout();
			e.preventDefault();
		}
	};

	drop = e => {
		const { onUpload, singleFileUpload } = this.props;
		e.preventDefault();
		if (singleFileUpload) {
			let file = {};
			file = e.dataTransfer.items ? e.dataTransfer.items[0].getAsFile() : e.dataTransfer.files[0];
			this.setState({ visible: false });
			onUpload(file);
		} else {
			const files = [];
			if (e.dataTransfer.items) {
				for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
					if (e.dataTransfer.items[i].kind === 'file') {
						files.push(e.dataTransfer.items[i].getAsFile());
					}
				}
			} else {
				for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
					files.push(e.dataTransfer.files[i]);
				}
			}
			this.setState({ visible: false });
			onUpload(files);
		}
	};

	render() {
		const { children } = this.props;
		const { visible } = this.state;
		const dropText = 'Drop here to upload';
		return (
			<div className={className} onDragOver={this.dragover} onDrop={this.drop}>
				{children}
				<div className={el('drag-message', visible ? 'visible' : '')}>
					<div className={el('text')}>{dropText}</div>
				</div>
			</div>
		);
	}
}

DragZone.propTypes = {
	children: PropTypes.any,
	disabled: PropTypes.any,
	onUpload: PropTypes.func,
	singleFileUpload: PropTypes.bool,
};

export default DragZone;
