import * as actions from '../actions';

const INITIAL_STATE = {
	username: null,
	loginForm: { passwordRequestSuccess: false, loading: false, error: null, errorCode: null },
	provisioning: { isProvisioning: undefined, provisioningState: {} },
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.LOGIN_USER:
			const { username, remember } = action.payload;
			return { ...state, username, remember };
		case actions.SET_LOGIN_FORM:
			return { ...state, loginForm: { ...state.loginForm, ...action.payload.loginForm } };
		case actions.SET_PROVISIONING_STATE:
			return { ...state, provisioning: { ...state.provisioning, ...action.payload.provisioning } };

		default:
			return state;
	}
};
