import React from 'react';

import './styles.scss';

export default ({ color }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		width="39"
		height="37"
		viewBox="0 0 39 37"
		className="chrevron-left-icon"
	>
		<defs>
			<clipPath id="chevron-right-cp">
				<rect width="39" height="37" />
			</clipPath>
		</defs>
		<g id="a" clipPath="url(#chevron-left-cp)">
			<path
				d="M17.727,30.526,16.047,32.2,0,16.1,16.047,0l1.679,1.674L3.348,16.1Z"
				transform="translate(11.001 2.001)"
				fill={color || '#fff'}
			/>
		</g>
	</svg>
);
