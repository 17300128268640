import capitalize from 'lodash/capitalize';

const getStudyAudienceStatus = (study, audiences) => {
	const { status: studyStatus, questionFilters } = study;
	const anyAudience = status =>
		(audiences || []).findIndex(audience => audience.status.toLowerCase() === status) > -1;

	// if study is live
	if (studyStatus.toLowerCase() === 'active') return 'live';

	// if any audience is on pending and there are quals on the study
	if (anyAudience('pending') && questionFilters.length > 0) return 'in-review';

	// if there are ready audiences without any pending audiences
	if (!anyAudience('pending') && anyAudience('ready')) return 'ready';

	// if there are quals on the study without any pending audiences
	if (!anyAudience('pending') && questionFilters.length > 0) return 'need-review';

	return 'draft';
};

const getAudienceStatus = (study, audience) => {
	const { status: studyStatus } = study;
	const { status: audienceStatus } = audience;
	if (study && audience) {
		if (audienceStatus === 'live') {
			return 'live';
		}
		if (audienceStatus === 'complete') {
			return 'complete';
		}
		if (audienceStatus === 'pending') {
			return 'under-review';
		}
		if (studyStatus === 'pending') {
			return 'need-review';
		}
	}
	return 'ready';
};

const cleanUpOptionsForPatchQuestion = nonPlaceholderArrayOfOptions => {
	const cleanedOptions = [
		...nonPlaceholderArrayOfOptions.map(opt => {
			delete opt.asset;
			delete opt.assetVariations;
			delete opt.assetTitle;
			opt.translations.forEach(translation => {
				delete translation.asset;
				delete translation.assetVariations;
			});
			if (opt.maskedOptions) delete opt.maskedOptions;
			const validOption = {};
			Object.keys(opt).map(key =>
				['questionId', 'deletedAt'].includes(key) ? null : (validOption[key] = opt[key]),
			);
			return validOption;
		}),
	];
	return cleanedOptions;
};

const getLanguagesAttachedToStudy = (languages, studyLanguages) => {
	const languagesThatAreAttachedToStudy = [];
	if (languages) {
		studyLanguages?.forEach(languageCode => {
			let country = null;
			const languageObject = languages.find(lang => {
				if (lang.languageCode === languageCode) {
					return lang;
				}
				country = lang.countries.find(c => c.code === languageCode);
				if (country) {
					return lang;
				}

				return false;
			});

			if (languageObject) {
				let newLanguage = { ...languageObject };
				// Country-specific overrides
				if (country) {
					newLanguage = { ...newLanguage, languageCode, label: `${newLanguage.label} (${country.label})` };
				}
				languagesThatAreAttachedToStudy.push(newLanguage);
			}
		});
	}
	return languagesThatAreAttachedToStudy;
};

const getStudyStatusLabel = status => {
	let label = status;
	switch (status) {
		case 'active':
			label = 'Live';
			break;
		case 'in-review':
			label = 'Under review';
			break;
		case 'needs-review':
			label = 'Draft';
			break;
		case 'closed':
			label = 'Complete';
			break;
		default:
			label = capitalize(status);
	}
	return label;
};

const sanitizeProviderQuestion = selectedTemplate => {
	// labels aren't accepted, for both template and options
	const field = selectedTemplate?.providerQuestions ? 'providerQuestions' : 'demographicQuestions';
	if (!selectedTemplate?.[field]?.length) return [];

	const sanitizeOptions = options => {
		if (!options?.length) return [];
		return options?.map(({ min, max, quota, id }) => ({
			...(min ? { min } : {}),
			...(max ? { max } : {}),
			...(quota ? { quota } : {}),
			...(id ? { id } : {}),
		}));
	};

	selectedTemplate?.[field].map(({ label: _, ...template }) => ({
		...template,
		options: sanitizeOptions(template.options),
		useQuotas: Boolean(template),
	}));

	return selectedTemplate?.[field].map(({ label: _, ...template }) => ({
		...template,
		options: sanitizeOptions(template.options),
		useQuotas: Boolean(template),
	}));
};

const reformatDate = dateStr => {
	if (dateStr) {
		const dArr = dateStr.substring(0, 10).split('-');
		const months = {
			'01': 'January',
			'02': 'February',
			'03': 'March',
			'04': 'April',
			'05': 'May',
			'06': 'June',
			'07': 'July',
			'08': 'August',
			'09': 'September',
			'10': 'October',
			'11': 'November',
			'12': 'December',
		};
		return `${months[dArr[1]]} ${dArr[2]}, ${dArr[0]}`;
	}
	return '';
};

const reformatDateShortMonth = dateStr => {
	if (dateStr) {
		const dArr = dateStr.substring(0, 10).split('-');
		const months = {
			'01': 'Jan',
			'02': 'Feb',
			'03': 'Mar',
			'04': 'Apr',
			'05': 'May',
			'06': 'Jun',
			'07': 'Jul',
			'08': 'Aug',
			'09': 'Sep',
			'10': 'Oct',
			'11': 'Nov',
			'12': 'Dec',
		};
		return `${months[dArr[1]]} ${dArr[2]}, ${dArr[0]}`;
	}
	return '';
};

export default {
	getStudyStatusLabel,
	getStudyAudienceStatus,
	getAudienceStatus,
	cleanUpOptionsForPatchQuestion,
	getLanguagesAttachedToStudy,
	sanitizeProviderQuestion,
	reformatDate,
	reformatDateShortMonth,
};
