import { getMeanScore } from 'src/components/helpers';

export const getTotalQuestionBase = (chartData, productId) => {
	const isOpenEnded = chartData && chartData.products;
	if (isOpenEnded) {
		let tqb = 0; // * total question base
		if (chartData && chartData.products) {
			chartData.products.forEach(p => {
				// * Compare products within options to current product
				if (p.productId === productId) {
					tqb = p.totalQuestionBase;
				}
			});
			return tqb;
		}
	}
	if (chartData && chartData.options) {
		let tqb = 0; // * total question base
		chartData.options.forEach(o => {
			if (o && o.products) {
				o.products.forEach(p => {
					// * Compare products within options to current product
					if (p.productId === productId) {
						tqb = p.totalQuestionBase;
					}
				});
			}
		});
		return tqb;
	}
	return 0;
};

export const getSignificance = ({
	productId,
	optionId,
	products,
	productTotalBase,
	productBase,
	statOptions,
	baseProductId,
	confidenceValue,
}) => {
	if (Array.isArray(statOptions) && !statOptions?.length) return;
	if (typeof statOptions === 'object' && Object.keys(statOptions)?.length === 0) return;
	let statOption;
	// Open Ended Numeric have no options
	if (!optionId) {
		statOption = statOptions;
	} else statOption = statOptions?.find(o => o.optionId === optionId);
	if (!statOption) return 'neutral'; // no option found
	const statProduct = statOption?.products?.find(p => +p.productId === +productId);
	const pinnedProduct = products?.find(
		p => +p.productId === +baseProductId && (+p.optionId === +optionId || !p.optionId),
	);
	if (!statProduct || !pinnedProduct) return 'neutral'; // product not found
	if (optionId === null || optionId === undefined) {
		// Open Ended Numeric compares 'meanValue' instead of 'value' and 'avg' instead of 'base'
		const statProductAvg = products?.find(p => p.productId === productId)?.avg;
		if (statProduct?.meanValue < confidenceValue || statProduct?.meanValue === undefined) return 'neutral'; // not confident enough
		return statProductAvg > pinnedProduct.avg ? 'positive' : 'negative'; // confident positive or negative result
	}
	if (statProduct?.value < confidenceValue || statProduct?.value === undefined) return 'neutral'; // not confident enough

	if (productBase && productTotalBase) {
		const productPercentage = getPercentage(productBase, productTotalBase);
		const pinnedProductPercentage = getPercentage(pinnedProduct.base, pinnedProduct.totalQuestionBase);

		return productPercentage > pinnedProductPercentage ? 'positive' : 'negative'; // confident positive or negative result
	}

	return productBase > pinnedProduct?.base ? 'positive' : 'negative'; // confident positive or negative result
};

export const getMeanScoreSignificance = ({
	targetProductId,
	pinnedProductId,
	options = [],
	statData = {},
	confidenceValue,
	meanScoreScalePointsDictionary,
	products, // * Each product has to include `productId`, `optionId` and `base`
}) => {
	if (!pinnedProductId) return null;

	const { scaleProducts = [] } = statData;

	const targetProductValue = scaleProducts?.find(({ productId }) => +productId === +targetProductId)?.value;

	if (!targetProductValue || !pinnedProductId || targetProductValue < confidenceValue) return 'neutral'; // * Product not found or not confident enough

	const optionsWithScalePoints =
		options
			?.map?.(({ optionId }) => ({
				optionId,
				scalePoint: meanScoreScalePointsDictionary?.[optionId] ?? null,
			}))
			?.filter(({ scalePoint }) => !Number.isNaN(parseInt(scalePoint, 10))) ?? [];

	const optionsWithTargetProductBase = optionsWithScalePoints?.map?.(option => ({
		...option,
		base:
			products?.find(
				({ optionId, productId }) =>
					+productId === +targetProductId && (optionId === option?.optionId || optionId === option?.id),
			)?.base ?? 0,
	}));

	const optionsWithPinnedProductBase = optionsWithScalePoints?.map?.(option => ({
		...option,
		base:
			products?.find(
				({ optionId, productId }) =>
					+productId === +pinnedProductId && (optionId === option?.optionId || optionId === option?.id),
			)?.base ?? 0,
	}));

	const targetProductMeanScore = getMeanScore({ options: optionsWithTargetProductBase });
	const pinnedProductMeanScore = getMeanScore({ options: optionsWithPinnedProductBase });

	// * Confident positive or negative result
	if (targetProductMeanScore < pinnedProductMeanScore) return 'negative';
	if (targetProductMeanScore > pinnedProductMeanScore) return 'positive';

	return 'neutral';
};

const getPercentage = (base, totalQuestionBase) => {
	if (!totalQuestionBase) return 0;
	return parseFloat(((base / totalQuestionBase) * 100).toPrecision(4)) || 0;
};

export const getStatTestingColor = ({
	statTestingEnabled,
	productId,
	selectedProductId,
	significance,
	originalColor,
}) => {
	if (selectedProductId === undefined || selectedProductId === null) {
		return originalColor || 'black';
	}
	if (statTestingEnabled && productId === selectedProductId) return '#178ab5';
	if (statTestingEnabled && significance === 'positive') return '#5BC397';
	if (statTestingEnabled && significance === 'negative') return '#ED7B89';
	if (statTestingEnabled) return '#BABABA';

	return originalColor || 'black';
};

export const getBarChartData = ({
	product,
	question,
	chartData,
	customizeReport,
	statTestingEnabled,
	baseProductId,
	statTestingData,
	confidenceValue,
	isRankedQuestion,
}) => {
	let optionsClone = question.options.slice();
	if (!customizeReport) {
		optionsClone = optionsClone.filter(o => (o ? o.isDisplayedInReporting : true));
	}

	let productSubtotalBase = 0;
	let productTotalQuestionBase = 0;
	let baselineSubtotalBase = 0;
	let baselineTotalBase = 0;
	const result = optionsClone
		.filter(o => o) // * Filtering out some undefined values that are occasionally showing up on single-select on uncheck of a filter
		.map(questionOption => {
			let data = {
				id: questionOption.id,
				value: questionOption.label,
				label: questionOption.label,
				languageCode: questionOption.languageCode,
				questionId: question.id,
				isOtherSpecify: question.hasOtherSpecifyOption,
				isNoneOfTheAbove: question.hasNoneOfTheAboveOption,
				base: 0,
				percentage: 0,
				...(questionOption.maskedParameters ? questionOption.maskedParameters : {}),
			};
			const matchingChartDataOption =
				chartData && chartData.options
					? chartData.options.find(chartDataOption => chartDataOption.optionId === questionOption.id)
					: {};

			if (!matchingChartDataOption || !matchingChartDataOption.products) return data;

			const baselineProduct = matchingChartDataOption.products.find(p => p.productId === baseProductId);
			baselineSubtotalBase += baselineProduct?.base || 0;
			baselineTotalBase += baselineProduct?.totalQuestionBase || 0;

			const matchingProduct = matchingChartDataOption.products.find(p => p.productId === product.id);
			if (!matchingProduct) return data;

			const ranking = matchingProduct?.ranking;

			const getBase = () => matchingProduct.base || 0;
			const getPercentage = () => {
				if (isRankedQuestion) {
					const isTopN = question?.settings?.find(s => s.label === 'top-n')?.value === 'true';

					let topNLimit = isTopN ? question?.settings?.find(s => s.label === 'top-n-limit')?.value : 3;
					if (question?.rankingTopN) {
						topNLimit = question?.rankingTopN;
					}

					let totalRankingInLimit = 0;
					let totalAnswers = ranking.unranked || 0;
					for (let i = 0; i < 10; i += 1) {
						if (i < topNLimit) {
							totalRankingInLimit += ranking[i + 1] || 0;
						}
						totalAnswers += ranking[i + 1] || 0;
					}

					return parseFloat(((totalRankingInLimit / totalAnswers) * 100).toPrecision(4)) || 0;
				}
				return (
					parseFloat(((matchingProduct.base / matchingProduct.totalQuestionBase) * 100).toPrecision(4)) || 0
				);
			};

			const base = getBase();
			const percentage = getPercentage();

			data = {
				...data,
				ranking,
			};

			if (!statTestingEnabled) return { ...data, base, percentage }; // stat testing is disabled
			if (!baseProductId || !statTestingData) return { ...data, base, percentage }; // stat testing data is missing

			productSubtotalBase += base;
			productTotalQuestionBase += matchingProduct.totalQuestionBase;
			const significance = getSignificance({
				baseProductId,
				confidenceValue,
				optionId: questionOption.id,
				productId: product.id,
				productBase: base,
				productTotalBase: matchingProduct.totalQuestionBase,
				statOptions: statTestingData.options,
				products: matchingChartDataOption.products,
			});

			return { ...data, base, percentage, significance, productId: product.id };
		});

	if (statTestingEnabled && statTestingData?.options) {
		const subtotalStatOption = statTestingData.options.find(o => o.optionId === 0);
		if (subtotalStatOption) {
			const subtotalSignificance = getSignificance({
				baseProductId,
				confidenceValue,
				optionId: 0,
				productId: product.id,
				productBase: productSubtotalBase,
				productTotalBase: productTotalQuestionBase,
				statOptions: subtotalStatOption,
				products: [
					{
						productId: baseProductId,
						base: baselineSubtotalBase,
						totalQuestionBase: baselineTotalBase,
					},
				],
			});

			const subtotalData = {
				id: 0,
				significance: subtotalSignificance,
			};
			result.push(subtotalData);
		}
	}

	return result;
};

export const getChartDataFromMonadicSplitReportingData = (monadicSplitReportingData, questionId) =>
	monadicSplitReportingData && monadicSplitReportingData.length
		? monadicSplitReportingData.find(question => question.questionId === questionId)
		: {};

export const getRankedChartData = (chartData, question, sort, columnPinned, products) => {
	const isTopN = question?.settings?.find(s => s.label === 'top-n')?.value === 'true';
	let topNLimit = isTopN ? question?.settings?.find(s => s.label === 'top-n-limit')?.value : 3;

	if (question?.rankingTopN) {
		topNLimit = question?.rankingTopN;
	}

	let missingOptions = question?.options?.filter(o => !chartData.options?.find(c => c.optionId === o?.id));

	const zeroAverageActually = Number(topNLimit) + 1;

	const missingOptionProducts =
		products?.map(p => ({
			productId: p.id,
			ranking: {
				unranked: 0,
				average: Number(topNLimit) + 1,
			},
		})) || [];

	missingOptions = missingOptions?.map?.(option => ({
		optionId: option.id,
		popularity: 0,
		products: missingOptionProducts,
	}));

	const chartDataWithTotalAverages = [...(chartData.options || []), ...missingOptions]?.map(option => {
		const optionMissingProducts = products?.filter(p => !option?.products?.find(pp => pp.productId === p.id));

		const optionWithMissingProducts = {
			...option,
			products: [
				...option.products,
				...optionMissingProducts.map(p => ({
					productId: p.id,
					ranking: {
						unranked: 0,
						average: zeroAverageActually,
					},
				})),
			],
		};

		const productData = optionWithMissingProducts?.products?.map(p => {
			const questionBase = getTotalQuestionBase(chartData, p.productId);

			return {
				average: p?.ranking?.average || 0,
				questionBase,
			};
		});

		const averages = productData?.map(p => p.average);
		const averageTotal = averages?.reduce((a, b) => a + b, 0);
		const average = averageTotal / (optionWithMissingProducts?.products?.length || 0) || 0;

		let totalQuestionBase =
			optionWithMissingProducts?.products?.map(p => p?.ranking.unranked || 0).reduce((a, b) => a + b, 0) || 0;
		let topNAnswers = 0;
		for (let i = 0; i < 10; i += 1) {
			const rankingResponses = optionWithMissingProducts?.products
				?.map(p => p?.ranking[i + 1] || 0)
				.reduce((a, b) => a + b, 0);
			totalQuestionBase += rankingResponses;

			if (i < topNLimit) {
				topNAnswers += rankingResponses;
			}
		}

		const isZero = topNAnswers + totalQuestionBase === 0;
		const topNPercent = isZero ? 0 : (topNAnswers / totalQuestionBase) * 100;

		const finalAverage = average === 0 ? zeroAverageActually : average;
		return {
			...optionWithMissingProducts,
			totalAverage: finalAverage,
			totalQuestionBase,
			topNAnswers,
			topNPercent,
		};
	});

	let orderedChartData = chartDataWithTotalAverages;
	if (sort === 'topN') {
		if (columnPinned && columnPinned !== 'reset') {
			orderedChartData = chartDataWithTotalAverages?.sort((a, b) => {
				const aRanking = a?.products?.find(p => p.productId === columnPinned)?.ranking;
				const bRanking = b?.products?.find(p => p.productId === columnPinned)?.ranking;

				let totalA = 0;
				let limitA = 0;
				let totalB = 0;
				let limitB = 0;

				for (let i = 0; i < 10; i += 1) {
					const aResponses = aRanking?.[i + 1] || 0;
					const bResponses = bRanking?.[i + 1] || 0;
					totalA += aResponses;
					totalB += bResponses;

					if (i < topNLimit) {
						limitA += totalA;
						limitB += totalB;
					}
				}

				return (limitB || 0) - (limitA || 0);
			});
		} else {
			orderedChartData = chartDataWithTotalAverages?.sort((a, b) => {
				if (a.topNPercent === 0) return 1; // Return 1 so that b goes first
				if (b.topNPercent === 0) return -1; // Return -1 so that a goes first
				return b.topNPercent - a.topNPercent;
			});
		}
	} else {
		// eslint-disable-next-line no-lonely-if
		if (columnPinned && columnPinned !== 'reset') {
			orderedChartData = chartDataWithTotalAverages?.sort((a, b) => {
				const aRanking = a?.products?.find(p => p.productId === columnPinned)?.ranking;
				const bRanking = b?.products?.find(p => p.productId === columnPinned)?.ranking;

				return (aRanking?.average || 0) - (bRanking?.average || 0);
			});
		} else {
			orderedChartData = chartDataWithTotalAverages?.sort((a, b) => {
				if (a.topNPercent === 0) return 1; // Return 1 so that b goes first
				if (b.topNPercent === 0) return -1; // Return -1 so that a goes first
				return a.totalAverage - b.totalAverage;
			});
		}
	}

	return orderedChartData;
};

export const getOpenEndedChartData = (chartData, productId) => {
	const findProduct =
		chartData && chartData.products ? chartData.products.find(p => p.productId === productId.id) : {};
	let data = { negative: 0, neutral: 0, positive: 0, total: 0 };
	if (findProduct && Object.keys(findProduct).length > 0) {
		const { negative = 0, neutral = 0, positive = 0, total = 0 } = findProduct;
		data = { negative, neutral, positive, total };
	}
	return data;
};

export const getAllQuestions = sections =>
	sections?.content && sections?.content.length
		? sections?.content
				?.filter(s => s?.type === 'questions' || s?.type === 'monadic_split')
				?.map(s => s?.questions)
				?.flat(Infinity)
		: [];

export const getCurrentSectionQuestions = (sections, section) =>
	sections?.content && sections?.content.length
		? sections?.content
				?.filter(s => section?.id === s?.id)
				?.map(s => s?.questions)
				?.flat(Infinity)
		: [];

export default {
	getTotalQuestionBase,
	getSignificance,
	getStatTestingColor,
	getBarChartData,
	getChartDataFromMonadicSplitReportingData,
	getOpenEndedChartData,
	getAllQuestions,
	getCurrentSectionQuestions,
};
