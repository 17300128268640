import React from 'react';

import './styles.scss';

export default ({ ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
		<g fill="none" fillRule="evenodd">
			<g fillRule="nonzero">
				<path
					fill="#FFF"
					stroke="#DEDEDE"
					strokeWidth=".667"
					d="M12.5 6.35c0 3.681-2.798 6.666-6.25 6.666S0 10.03 0 6.349 2.798-.317 6.25-.317s6.25 2.984 6.25 6.666z"
					transform="translate(1.25 1.25)"
				/>
				<path
					fill="#B9B9B9"
					d="M6.532 7.454v-.962c.67-.013 1.202-.187 1.599-.524.397-.336.595-.822.595-1.457 0-.628-.194-1.114-.581-1.457-.387-.343-.9-.514-1.537-.514-.47 0-.874.087-1.215.262-.34.174-.598.412-.773.714-.175.301-.256.643-.244 1.024h1.247c0-.267.084-.484.253-.653.169-.168.397-.252.685-.252.262 0 .471.078.628.233.156.156.234.373.234.653 0 .419-.14.706-.422.862-.281.155-.675.233-1.181.233h-.534v1.838h1.247zm.104 2.21v-1.4H5.21v1.4h1.425z"
					transform="translate(1.25 1.25)"
				/>
			</g>
			<path d="M0 0H15V15H0z" />
		</g>
	</svg>
);
