import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Back from 'src/components/icons/Back';
// TODO: Need to figure out how to have the browser back button kick back to studies right away
import cn from 'src/utilities/bem-cn';
// TODO: Find a way to not borrow actions from other domains
import * as createStudyActions from 'src/domains/create-study/actions';
import Loader from 'src/components/shared/Loader';

import './styles.scss';
import withRouter from 'src/hocs/withRouter';

const className = 'create-study-header';
const el = (name, mod) => cn(className, name, mod);

class CreateStudyHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			backButtonClicked: false,
			loading: false,
		};
	}

	componentDidMount = () => {
		/*
		 * window.onpopstate will be called if the user clicks the 'back' or 'forward' buttons in the browser
		 */
		window.onpopstate = () => {
			/*
			 * Calling this to set a flag that determines whether account networks calls should be made
			 */
			this.setState({ backButtonClicked: true, loading: false });
		};
	};

	renderBackButton = () => {
		const {
			router: { navigate },
		} = this.props;
		return (
			<button
				type="button"
				className={el('back-button')}
				onClick={() => {
					this.setState({ backButtonClicked: true }, () => {
						navigate('/studies');
					});
				}}
			>
				<Back />
			</button>
		);
	};

	createStudyFromScratch = formData => {
		const { createStudy } = this.props;

		createStudy({
			name: 'Untitled Study',
			description: '',
			defaultLanguage: formData.language,
			translations: [
				{
					introText: '',
					languageCode: formData.language,
				},
			],
			settings: {
				layout: 'two-field',
				commitmentLayout: 'field-one',
				mobileOnly: false,
				commitments: true,
				sendWinningProduct: false,
				sendLikedProducts: false,
				enableDetailView: true,
				redirectUrl: null,
				productIndex: 0,
				enableBarChart: true,
				enableIdeaMap: true,
				enableQuadrantChart: true,
				enableOptimizer: false,
				enableVirtualMarkets: false,
				optimizerUrl: null,
				virtualMarketsUrl: null,
				useSampleProvider: false, // TODO once removed from API
			},
		});
	};

	renderStartFromScratchButton = () => {
		const doneText = 'Skip, start from scratch';
		return (
			<button
				type="button"
				className={el('done-button')}
				onClick={() => {
					this.setState({ loading: true });
					this.createStudyFromScratch({ language: 'en' });
				}}
			>
				{doneText}
			</button>
		);
	};

	render() {
		const { title } = this.props;
		const { loading } = this.state;
		return (
			<Container className={className}>
				<Row>
					<Col xs={4} className={el('left')}>
						{this.renderBackButton()}
					</Col>
					<Col xs={4} className={el('center')}>
						{title}
					</Col>
					<Col xs={4} className={el('right')}>
						{loading ? (
							<div className={el('loader')}>
								<Loader small />
							</div>
						) : (
							this.renderStartFromScratchButton()
						)}
					</Col>
				</Row>
			</Container>
		);
	}
}

CreateStudyHeader.propTypes = {
	title: PropTypes.string,
	createStudy: PropTypes.func,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
	createStudy: study => dispatch(createStudyActions.createStudy(study)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateStudyHeader));
