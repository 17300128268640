const prefix = 'create-study';

export const SET_CLIENTS = `${prefix}/SET_CLIENTS`;
export const setClients = clients => ({ type: SET_CLIENTS, payload: { clients } });

export const SET_LANGUAGES = `${prefix}/SET_LANGUAGES`;
export const setLanguages = languages => ({ type: SET_LANGUAGES, payload: { languages } });

export const SET_ERROR = `${prefix}/SET_ERROR`;
export const setError = error => ({ type: SET_ERROR, payload: { error } });

export const SET_GEOGRAPHIES = `${prefix}/SET_GEOGRAPHIES`;
export const setGeographies = geographies => ({ type: SET_GEOGRAPHIES, payload: { geographies } });

export const SET_SECTION = `${prefix}/SET_SECTION`;
export const setSection = currentSection => ({ type: SET_SECTION, payload: { currentSection } });

export const SET_SECTIONS = `${prefix}/SET_SECTIONS`;
export const setSections = sections => ({ type: SET_SECTIONS, payload: { sections } });

export const SET_SEARCH_QUERY = `${prefix}/SET_SEARCH_QUERY`;
export const setSearchQuery = (searchQuery, initialLoad = false) => ({
	type: SET_SEARCH_QUERY,
	payload: { searchQuery, initialLoad },
});

export const SET_BLUEPRINTS = `${prefix}/SET_BLUEPRINTS`;
export const setBlueprints = blueprints => ({
	type: SET_BLUEPRINTS,
	payload: { blueprints },
});

export const SET_BLUEPRINT_FILTERS = `${prefix}/SET_BLUEPRINT_FILTERS`;
export const setBlueprintFilters = (blueprintFilters, initialLoad = false) => ({
	type: SET_BLUEPRINT_FILTERS,
	payload: { blueprintFilters, initialLoad },
});

export const SET_SHOW_CREATE_NEW_MODAL = `${prefix}/SET_SHOW_CREATE_NEW_MODAL`;
export const setShowCreateNewModal = showCreateNewModal => ({
	type: SET_SHOW_CREATE_NEW_MODAL,
	payload: { showCreateNewModal },
});

export const INC_LOADING = `${prefix}/INC_LOADING`;
export const incLoading = () => ({ type: INC_LOADING });

export const DEC_LOADING = `${prefix}/DEC_LOADING`;
export const decLoading = () => ({ type: DEC_LOADING });

export const PERFORM_INITIAL_FETCH = `${prefix}/PERFORM_INITIAL_FETCH`;
export const performInitialFetch = () => ({ type: PERFORM_INITIAL_FETCH });

export const FETCH_CLIENTS = `${prefix}/FETCH_CLIENTS`;
export const fetchClients = () => ({ type: FETCH_CLIENTS });

export const FETCH_LANGUAGES = `${prefix}/FETCH_LANGUAGES`;
export const fetchLanguages = () => ({ type: FETCH_LANGUAGES });

export const FETCH_GEOGRAPHIES = `${prefix}/FETCH_GEOGRAPHIES`;
export const fetchGeographies = () => ({ type: FETCH_GEOGRAPHIES });

export const FETCH_SECTIONS = `${prefix}/FETCH_SECTIONS`;
export const fetchSections = (studyId, refetch = false, loadFirst = false) => ({
	type: FETCH_SECTIONS,
	payload: { studyId, refetch, loadFirst },
});

export const FETCH_SECTION = `${prefix}/FETCH_SECTION`;
export const fetchSection = (sectionId, mode, refetch = false, studyId = 0) => ({
	type: FETCH_SECTION,
	payload: { sectionId, mode, refetch, studyId },
});

export const FETCH_STUDIES = `${prefix}/FETCH_STUDIES`;
export const fetchStudies = args => ({
	type: FETCH_STUDIES,
	payload: {
		dropPagination: args ? args.dropPagination : false,
		accountUuids: args ? args.accountUuids : null,
	},
});

export const FETCH_BLUEPRINTS = `${prefix}/FETCH_BLUEPRINTS`;
export const fetchBlueprints = args => ({
	type: FETCH_BLUEPRINTS,
	payload: {
		dropPagination: args ? args.dropPagination : false,
		initialFetch: args ? args.initialFetch : false,
	},
});

export const FETCH_RECOMMENDED_BLUEPRINTS = `${prefix}/FETCH_RECOMMENDED_BLUEPRINTS`;
export const fetchRecommendedBlueprints = args => ({
	type: FETCH_RECOMMENDED_BLUEPRINTS,
	payload: {
		dropPagination: args ? args.dropPagination : false,
		initialFetch: args ? args.initialFetch : false,
	},
});

export const SET_RECOMMENDED_BLUEPRINTS = `${prefix}/SET_RECOMMENDED_BLUEPRINTS`;
export const setRecommendedBlueprints = recommendedBlueprints => ({
	type: SET_RECOMMENDED_BLUEPRINTS,
	payload: { recommendedBlueprints },
});

export const CREATE_STUDY = `${prefix}/CREATE_STUDY`;
export const createStudy = (study, navigateTo = null) => ({ type: CREATE_STUDY, payload: { study, navigateTo } });
