import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { Iconof } from '@upsiide/ui-components';
import { useIsBlueprint } from 'src/hooks';
import cn from 'src/utilities/bem-cn';
import { renderAudienceName, renderAudienceFlag } from 'src/utilities/misc';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';

import './styles.scss';

const className = 'customize-visibility-dropdown';
const selectClassName = 'visibility-group-select';

const el = (name, mod) => cn(className, name, mod);

const includesSubClass = (classList, subClasses) =>
	classList.some(item => subClasses.some(subClass => item.includes(subClass)));

const InputOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
	const [isActive, setIsActive] = useState(false);
	const onMouseDown = () => setIsActive(true);
	const onMouseUp = () => setIsActive(false);
	const onMouseLeave = () => setIsActive(false);

	const props = {
		...innerProps,
		onMouseDown,
		onMouseUp,
		onMouseLeave,
	};

	return (
		<components.Option
			{...rest}
			isDisabled={isDisabled}
			isFocused={isFocused}
			isSelected={isSelected}
			getStyles={getStyles}
			innerProps={props}
		>
			{isSelected ? (
				<Iconof icon="checkmark_square" className="checkbox-checked" />
			) : (
				<Iconof icon="square_outlined" className="checkbox-unchecked" />
			)}
			{children}
		</components.Option>
	);
};

const CustomizeVisibilityDropdown = ({
	studyId,
	questionId,
	changeScreeningQuestionVisibility,
	isTemplate = false,
	audienceTemplate = null,
	dragHandleContextId,
}) => {
	const [visibilityMenuIsOpen, setVisibilityMenuIsOpen] = useState(false);
	const { isBlueprint } = useIsBlueprint();
	const selectors = isBlueprint ? manageBlueprintSelectors : manageStudySelectors;
	const audienceCollectionLocal = useSelector(selectors.getAudienceCollection);
	const audienceEditTemplate = useSelector(selectors.getAudienceEditTemplate);

	const audienceCollection = useMemo(() => {
		if (isTemplate && audienceTemplate) return { content: audienceTemplate };
		if (isTemplate) return audienceEditTemplate;
		if (isTemplate) return audienceEditTemplate;
		return audienceCollectionLocal;
	}, [audienceTemplate, isTemplate, audienceCollectionLocal, audienceEditTemplate]);

	const currentScreeningQuestion = useMemo(
		() => audienceCollection?.content?.screeningQuestions?.find(question => question.id === questionId),
		[audienceCollection, questionId],
	);

	const getAllVisibilityOptions = useMemo(
		() =>
			audienceCollection?.content?.demographicGroups.map(audience => ({
				label: (
					<span className={el('audience-title', selectClassName)}>
						<span>{renderAudienceFlag(audience)}</span> <span>{renderAudienceName(audience)}</span>
					</span>
				),
				value: audience?.id,
			})),
		[audienceCollection],
	);

	const selectAllGroupsOption = {
		label: <span className={el('audience-title', selectClassName)}>All groups</span>,
		value: 'all-groups',
	};

	// returns all the audiences for the dropdown options
	const getVisibilityOptions = useMemo(() => {
		let visibilityOptions = [];
		const audienceData = audienceCollection?.content?.demographicGroups?.length ? getAllVisibilityOptions : [];
		if (audienceData?.length) visibilityOptions = [selectAllGroupsOption, ...audienceData];
		return visibilityOptions;
	}, [audienceCollection]);

	// returns all the selected audiences from the store
	const visibleAudiences = useMemo(() => {
		if (!audienceCollection?.content?.demographicGroups.length) return [];
		const filteredAudiences = audienceCollection?.content?.demographicGroups
			?.filter(audience => currentScreeningQuestion?.allowList?.includes(audience.id))
			?.map(audience => ({
				label: (
					<span className={el('audience-title', selectClassName)}>
						<span>{renderAudienceFlag(audience)}</span> <span>{renderAudienceName(audience)}</span>
					</span>
				),
				value: audience?.id,
			}));
		if (filteredAudiences?.length === getAllVisibilityOptions?.length && getAllVisibilityOptions?.length > 0)
			return [selectAllGroupsOption];
		return filteredAudiences;
	}, [audienceCollection, currentScreeningQuestion]);

	const [selectedGroups, setSelectedGroups] = useState(visibleAudiences);

	const onChange = selectedLists => {
		let listValues = [];
		// finds if 'all groups' option is selected
		const containsAllGroupsOption = selectedLists.findIndex(list => list.value === selectAllGroupsOption.value);

		/* if 'all groups' option is selected, all the available groups are send via api else if another group 
		option is selected after 'all groups' option is added into the list, then the latter will be removed from 
		the list */
		if (containsAllGroupsOption > 0 || (selectedLists.length === 1 && containsAllGroupsOption === 0)) {
			setSelectedGroups([selectAllGroupsOption]);
			listValues = getAllVisibilityOptions.map(list => list.value);
		} else {
			if (!containsAllGroupsOption)
				selectedLists = selectedLists.filter(list => list.value !== selectAllGroupsOption.value);
			setSelectedGroups(selectedLists);
			listValues = selectedLists.map(list => list.value);
			if (containsAllGroupsOption < 0 && getAllVisibilityOptions.length === selectedLists.length) {
				setSelectedGroups([selectAllGroupsOption]);
			}
		}

		const isGlobalQualifier = listValues.length === getAllVisibilityOptions.length;

		changeScreeningQuestionVisibility(studyId, questionId, isGlobalQualifier, listValues, isTemplate);
	};

	// checks the target node and its parent to see if it is a part of the react-select component
	// if not, closes the menu
	const handleClickOutsideOfSelect = useCallback(e => {
		const eventClassList = [...(e?.target?.classList ?? [])];
		const parentClassList = [...(e?.target?.parentNode?.classList ?? [])];

		const hasSelectSubClass =
			eventClassList.some(item => item.includes(selectClassName)) ||
			parentClassList.some(item => item.includes(selectClassName));

		if (!hasSelectSubClass) closeMenu();
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideOfSelect, true);
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideOfSelect, true);
		};
	}, [handleClickOutsideOfSelect]);

	// checks the target node, and 2 parent levels above to see if it belongs to a class that is allowed to control opening and closing the menu
	const toggleMenu = e => {
		const eventClassList = [...(e?.target?.classList ?? [])];
		const parentClassList = [...(e?.target?.parentNode?.classList ?? [])];
		const parentOfParentClassList = [...(e?.target?.parentNode?.parentNode?.classList ?? [])];

		const menuControlSubclasses = ['control', 'indicator', 'multi-value__label'];
		const hasMenuControlSubclass =
			includesSubClass(eventClassList, menuControlSubclasses) ||
			includesSubClass(parentClassList, menuControlSubclasses) ||
			includesSubClass(parentOfParentClassList, menuControlSubclasses);

		if (hasMenuControlSubclass && visibilityMenuIsOpen) closeMenu();
		else if (hasMenuControlSubclass && !visibilityMenuIsOpen) openMenu();
		else handleClickOutsideOfSelect(e);
	};

	const openMenu = () => setVisibilityMenuIsOpen(true);

	const closeMenu = () => setVisibilityMenuIsOpen(false);

	return (
		<div className={className}>
			<span className={el('title')}>Question is visible for</span>
			<div
				data-rbd-drag-handle-context-id={dragHandleContextId}
				data-rbd-drag-handle-draggable-id="cancel-drag-event"
				onKeyDown={e => toggleMenu(e)}
				onClick={e => toggleMenu(e)}
				role="button"
				tabIndex="0"
				aria-label={selectClassName}
			>
				<Select
					className={el(selectClassName)}
					classNamePrefix={selectClassName}
					closeMenuOnSelect={false}
					hideSelectedOptions={false}
					components={{
						Option: InputOption,
					}}
					isMulti
					options={getVisibilityOptions}
					placeholder={getVisibilityOptions.length ? 'No group selected' : 'No groups available'}
					isDisabled={!getVisibilityOptions.length}
					theme={theme => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: '#F5F6F8',
							primary: '#28B681',
						},
					})}
					value={selectedGroups}
					onChange={onChange}
					isClearable={false}
					openMenuOnClick
					openMenuOnFocus
					menuIsOpen={visibilityMenuIsOpen}
				/>
			</div>
			{!selectedGroups.length && getVisibilityOptions.length > 0 && (
				<div className={el('no-select-alert')}>Select a group to have this question be visible</div>
			)}
		</div>
	);
};

CustomizeVisibilityDropdown.propTypes = {
	studyId: PropTypes.number,
	questionId: PropTypes.number,
	changeScreeningQuestionVisibility: PropTypes.func,
	isTemplate: PropTypes.bool,
	audienceTemplate: PropTypes.any,
	dragHandleContextId: PropTypes.any,
};

export default CustomizeVisibilityDropdown;
