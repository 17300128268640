import React from 'react';
import { Col } from 'reactstrap';
import cn from 'src/utilities/bem-cn';
import Select from 'src/components/inputs/Select';
import PropTypes from 'prop-types';

import '../../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

const LogicSelect = ({
	columnWidth,
	selectClassName,
	options,
	onChange,
	menuMinWidth,
	value,
	placeholder,
	setParentRefZIndex,
}) => {
	const customSingleValue = ({ data }) => {
		const { icon, chipLabel } = data;
		return (
			<div className="input-select">
				<div className="input-select__single-value">
					{icon && <span className="input-select__icon">{icon}</span>}
					<span>{chipLabel}</span>
				</div>
			</div>
		);
	};

	const getCustomSelectStyles = () => {
		const targetHeight = 28;
		return {
			// For setting the height of the react select
			control: base => ({ ...base, minHeight: 'initial' }),
			valueContainer: base => ({ ...base, height: `${targetHeight - 2}px`, padding: '0 8px' }),
			clearIndicator: base => ({ ...base, padding: `${(targetHeight - 22) / 2}px` }),
			dropdownIndicator: base => ({ ...base, padding: `${(targetHeight - 22) / 2}px` }),
			// Set the minWidth of the expanded menu
			menu: base => ({ ...base, minWidth: `${menuMinWidth}px` }),
			menuList: base => ({ ...base, minWidth: `${menuMinWidth}px` }),
			// For removing the padding from the react select list items
			option: base => ({
				...base,
				paddingTop: 0,
				paddingRight: 0,
				paddingBottom: 0,
				paddingLeft: 0,
			}),
		};
	};

	return (
		<Col xs={columnWidth}>
			<Select
				className={el(selectClassName)}
				classNamePrefix="logic-select"
				options={options}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				styles={getCustomSelectStyles(menuMinWidth)}
				isSearchable={false}
				onFocus={() => setParentRefZIndex(12)}
				blurInputOnSelect
				onBlur={() => setParentRefZIndex(11)}
				onMenuClose={() => setParentRefZIndex(11)}
				components={{
					SingleValue: customSingleValue,
				}}
				// menuIsOpen // For debugging
			/>
		</Col>
	);
};

LogicSelect.propTypes = {
	columnWidth: PropTypes.any,
	selectClassName: PropTypes.any,
	options: PropTypes.any,
	onChange: PropTypes.any,
	menuMinWidth: PropTypes.any,
	value: PropTypes.any,
	placeholder: PropTypes.any,
	setParentRefZIndex: PropTypes.any,
};

export default LogicSelect;
