/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import ModalFooter from 'src/components/shared/Modal/Footer';
import * as misc from 'src/utilities/misc';
import parse from 'html-react-parser';
import { Iconof } from '@upsiide/ui-components';

import './styles.scss';

const className = 'are-you-sure-form';
const el = (name, mod) => cn(className, name, mod);

const AreYouSureForm = ({
	onCancel,
	onConfirm,
	headerText = 'Just checking',
	renderHtmlTags = false,
	bodyText = 'Your changes will be lost.',
	confirmText = 'Continue',
	confirmState = 'active',
	confirmType = 'red',
	cancelText = 'Cancel',
	autoSpinner = false,
	headerIcon = null,
	additionalText = null,
	isCenteredForm = false,
	customClass = '',
}) => {
	const [autoState, setAutoState] = useState('active');
	const wrapButtonCallback = cb =>
		autoSpinner
			? e => {
				setAutoState('loading');
				Promise.all([
					new Promise(resolve => {
						setTimeout(() => resolve(), 2000);
					}),
					cb(e),
				]).finally(() => setAutoState('active'));
			}
			: cb;
	return !isCenteredForm ? (
		<div className={`${className} ${customClass}`}>
			<div className={el('header')}>
				{headerIcon && (
					<Iconof
						size={headerIcon.size}
						icon={headerIcon.icon}
						color={headerIcon.color}
						className={headerIcon.className}
					/>
				)}
				{headerText}
			</div>
			<div className={`${customClass?.includes('piping-error') ? el('piping-error-text') : el('text')}`}>
				{typeof bodyText === 'string'
					? renderHtmlTags
						? parse(bodyText)
						: misc.cleanHtmlString(bodyText)
					: bodyText}
			</div>
			{additionalText && <div className={el('additional-text')}>{parse(additionalText)}</div>}
			<ModalFooter
				closeLabel={cancelText}
				confirmLabel={confirmText}
				onClose={wrapButtonCallback(onCancel)}
				onConfirm={wrapButtonCallback(onConfirm)}
				confirmState={autoSpinner ? autoState : confirmState}
				confirmType={confirmType}
				customClass={`${customClass?.includes('piping-error') && 'piping-error-modal-footer'}`}
			/>
		</div>
	) : (
		<div className={className}>
			<div className={el('centered-header')}>
				{headerIcon && (
					<Iconof
						size={headerIcon.size}
						icon={headerIcon.icon}
						color={headerIcon.color}
						className={headerIcon.className}
					/>
				)}
				{headerText}
			</div>
			<div className={el('centered-text')}>
				{typeof bodyText === 'string'
					? renderHtmlTags
						? parse(bodyText)
						: misc.cleanHtmlString(bodyText)
					: bodyText}
			</div>
			{additionalText && <div className={el('centered-additional-text')}>{parse(additionalText)}</div>}
			<div className={el('centered-modal-footer')}>
				<ModalFooter
					closeLabel={cancelText}
					confirmLabel={confirmText}
					onClose={wrapButtonCallback(onCancel)}
					onConfirm={wrapButtonCallback(onConfirm)}
					confirmState={autoSpinner ? autoState : confirmState}
					confirmType={confirmType}
				/>
			</div>
		</div>
	);
};

AreYouSureForm.propTypes = {
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	headerText: PropTypes.string,
	renderHtmlTags: PropTypes.bool,
	bodyText: PropTypes.any,
	confirmText: PropTypes.string,
	confirmState: PropTypes.string,
	cancelText: PropTypes.string,
	confirmType: PropTypes.string,
	autoSpinner: PropTypes.bool,
	headerIcon: PropTypes.any,
	additionalText: PropTypes.string,
	isCenteredForm: PropTypes.bool,
	customClass: PropTypes.string,
};

export default AreYouSureForm;
