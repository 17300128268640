import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'src/components/shared/Modal';
import cn from 'src/utilities/bem-cn';
import cardDividerIcon from 'public/images/upload/card-divider.svg';
import { Button } from '@upsiide/ui-components';
import CustomPercentageCircle from 'src/components/shared/CustomPercentageCircle';
import toastr from 'toastr';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'src/utilities/axios';
import DragZone from 'src/components/inputs/AssetInput/Gallery/DragZone';
import FileIcon from 'src/components/icons/File';
import CustomModalConfirmation from 'src/components/shared/CustomModalConfirmation';
import IconButton from 'src/components/elements/IconButton';
import Loader from 'src/components/shared/Loader';

import './styles.scss';
import service from '../../../../../services/studySample.service';

const className = 'modal-upload-file';
const el = (name, mod) => cn(className, name, mod);

const MAX_200_MB = 209715200;

const mimeMap = {
	'text/plain': 'doc',
	'application/zip': 'doc',
	'application/octet-stream': 'doc',
	'application/x-zip-compressed': 'doc',
	'multipart/x-zip': 'doc',
	'application/x-zip': 'doc',
	'application/x-compress': 'doc',
	'text/html': 'doc',
	'text/csv': 'doc',
	'application/vnd.oasis.opendocument.text': 'doc',
	'application/vnd.oasis.opendocument.spreadsheet': 'doc',
	'application/vnd.oasis.opendocument.presentation': 'doc',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
	'application/rtf': 'doc',
	'application/pdf': 'doc',
	'application/vnd.ms-powerpoint': 'doc',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'doc',
	'application/x-iwork-keynote-sffkey': 'doc',
	'application/vnd.apple.keynote': 'doc',
	'application/vnd.ms-excel': 'doc',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'doc',
	'image/jpeg': 'img',
	'image/png': 'img',
	'image/gif': 'img',
	'image/tiff': 'img',
	'image/bmp': 'img',
	'image/vnd.adobe.photoshop': 'img',
	'application/vnd.adobe.illustrator': 'img',
	'video/mp4': 'mov',
	'video/x-msvideo': 'mov',
	'video/quicktime': 'mov',
	'video/mpeg': 'mov',
	'video/x-flv': 'mov',
	'video/x-ms-wmv': 'mov',
	'video/x-ms-asf': 'mov',
	'video/x-matroska': 'mov',
	'video/webm': 'mov',
};

const ModalUploadFile = ({ files, setFiles, show, onClose, studyFiles = [], setStudyFiles, fetchStudyFiles }) => {
	const { study } = useSelector(state => state.manageStudy);
	const [fileToDelete, setFileToDelete] = useState(null);
	const [showModalConfirmation, setShowModalConfirmation] = useState(false);
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const [modalState, setModalState] = useState('choose-many');

	useEffect(() => {
		if (files?.length === 0) {
			setModalState('choose-many');
		} else if (files?.some(f => f.status === 'uploading')) {
			setModalState('uploading');
		} else if (files?.some(f => f.status === 'editing')) {
			setModalState('editing');
		} else {
			setModalState('choose-single');
		}
	}, [files]);

	const onCancelUpload = async i => {
		const file = files[i];
		if (!file) return;
		files[i].cancelTokenSource.cancel('Upload cancelled');
	};

	const cancelAllUploads = () => {
		files.forEach((file, i) => {
			onCancelUpload(i);
		});
	};

	const onRetryUpload = async i => {
		const file = files[i];
		if (file?.originalFile) {
			onSelectFile({ target: { files: [file.originalFile] } }, true, file.id);
		}
	};

	const onRemoveFile = id => {
		setFiles(files.filter(file => file.id !== id));
	};

	const onDeleteFile = useCallback(async (id, backendId) => {
		setDisplayDeleteModal(true);
		setFileToDelete({ id, backendId });
	}, []);

	const deleteFile = async () => {
		try {
			if (!fileToDelete?.backendId || !fileToDelete?.id) return;
			setDisplayDeleteModal(false);
			const { id, backendId } = fileToDelete;
			onRemoveFile(id);
			setFileToDelete(null);
			service.deleteStudyFile(study?.id, backendId);
			// setStudyFiles(studyFiles.filter(file => file?.id !== backendId));
		} catch (error) {
			/* eslint-disable no-console */
			console.error(error);
			setFileToDelete(null);
		}
	};

	const onRenameFile = useCallback(
		async (file, i) => {
			try {
				const id = file?.backendId;
				if (!id) return;
				await service.patchStudyFile(study?.id, id, { fileName: file?.fileName });
				const newFiles = [...files];
				newFiles[i].originalFile = {
					...newFiles[i].originalFile,
					name: file?.fileName,
				};
				setFiles(newFiles);
			} catch (error) {
				/* eslint-disable no-console */
				console.error(error);
			}
		},
		[study, files],
	);

	const uploadAfterDrop = async (localFiles = []) => {
		if (Array.isArray(localFiles)) {
			await Promise.all(localFiles.map(f => onSelectFile({ target: { files: [f] } })));
		} else {
			await onSelectFile({ target: { files: [localFiles] } });
		}
		resetFileInput();
	};

	const resetFileInput = () => {
		const input = document.querySelector('input[type="file"]');
		input.value = null;
	};

	const onSelectFile = async (e, isRetry = false, retryId = null) => {
		const file = e.target.files[0];
		const id = isRetry ? retryId : Date.now() + Math.random();
		// eslint-disable-next-line
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		if (!file) return;

		const baseFile = {
			id,
			fileName: file.name,
			mimetype: file.type,
			progress: 0,
			cancelTokenSource: source,
			originalFile: file,
		};

		if (isRetry) {
			setFiles(prev => {
				const currentFileIndex = prev.findIndex(f => f.id === id);
				if (currentFileIndex === -1) return prev;
				const newFiles = [...prev];
				newFiles[currentFileIndex].progress = 0;
				newFiles[currentFileIndex].status = 'uploading';
				newFiles[currentFileIndex].subTitle = 'Loading ...';
				newFiles[currentFileIndex].cancelTokenSource = source;
				return newFiles;
			});
			const res = await onUploadFile(files.find(f => f.id === id));
			return { name: file.name, status: res };
		}

		if (file?.size >= MAX_200_MB) {
			setFiles(prev => [
				...prev,
				{
					...baseFile,
					subTitle: 'The file size is too large. Max file size: 200 MB.',
					status: 'error',
				},
			]);
			return { name: file.name, status: false };
		}
		if (!mimeMap[file?.type]) {
			setFiles(prev => [
				...prev,
				{
					...baseFile,
					subTitle: 'The file format is not supported. Try another.',
					status: 'error',
				},
			]);
			return { name: file.name, status: false };
		}
		setFiles(prev => [
			...prev,
			{
				...baseFile,
				subTitle: 'Ready',
				status: 'queue',
			},
		]);

		const response = await onUploadFile({ ...baseFile, id });
		return { name: file.name, status: response };
	};

	// const onStartUploadFiles = async () => {
	// 	setStartUploadFiles(true);
	// 	console.log(files)
	// 	const filesToUpload = files.filter(file => file?.status !== 'done' && file?.status !== 'error');
	// 	const perChunk = 4;
	// 	const listChunk = filesToUpload.reduce((resultArray, item, index) => {
	// 		const chunkIndex = Math.floor(index / perChunk);
	// 		if (!resultArray[chunkIndex]) resultArray[chunkIndex] = [];
	// 		resultArray[chunkIndex].push(item);
	// 		return resultArray;
	// 	}, []);
	// 	for (const chunk of listChunk) {
	// 		await Promise.all(chunk.map(f => onUploadFile(f)));
	// 	}
	// 	setStartUploadFiles(false);
	// 	fetchStudyFiles(study.id, false);
	// };

	const onUploadFile = async ({ originalFile, id, cancelTokenSource, fileName }) => {
		const formData = new FormData();
		const fileToUpload = new File([originalFile], fileName, {
			type: originalFile.type,
			lastModified: originalFile.lastModified,
		});
		formData.append('file', fileToUpload);

		setFiles(prev => {
			const currentFileIndex = prev.findIndex(f => f.id === id);
			if (currentFileIndex === -1) return prev;
			const newFiles = [...prev];
			newFiles[currentFileIndex].progress = 0;
			newFiles[currentFileIndex].status = 'uploading';
			newFiles[currentFileIndex].subTitle = 'Loading ...';
			return newFiles;
		});

		const updateProgressBar = progressEvent => {
			const localProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total) - 1; // keep 99% until finish
			setFiles(prev => {
				const currentFileIndex = prev.findIndex(f => f.id === id);
				if (currentFileIndex === -1) return prev;
				const newFiles = [...prev];
				// if (localProgress >= 99) {
				// 	newFiles[currentFileIndex].progress = 100;
				// 	newFiles[currentFileIndex].subTitle = `Processing...`;
				// 	newFiles[currentFileIndex].status = `processing`;
				// } else {
				// 	newFiles[currentFileIndex].progress = localProgress;
				// 	newFiles[currentFileIndex].subTitle = `Loading ${localProgress}%`;
				// 	newFiles[currentFileIndex].status = `uploading`;
				// }
				newFiles[currentFileIndex].progress = localProgress;
				newFiles[currentFileIndex].subTitle = `Loading ${localProgress}%`;
				newFiles[currentFileIndex].status = `uploading`;
				return newFiles;
			});
		};

		try {
			const response = await service.uploadStudyFile(
				study?.id,
				formData,
				updateProgressBar,
				updateProgressBar,
				cancelTokenSource,
			);
			setFiles(prev => {
				const currentFileIndex = prev.findIndex(f => f.id === id);
				if (currentFileIndex === -1) return prev;
				const newFiles = [...prev];
				newFiles[currentFileIndex].progress = 100;
				newFiles[currentFileIndex].status = 'done';
				newFiles[currentFileIndex].subTitle = null;
				newFiles[currentFileIndex].backendId = response.data?.id;
				return newFiles;
			});
			return true;
		} catch (error) {
			if (error?.message === 'Upload cancelled') {
				setFiles(prev => {
					const currentFileIndex = prev.findIndex(f => f.id === id);
					if (currentFileIndex === -1) return prev;
					const newFiles = [...prev];
					newFiles[currentFileIndex].progress = 0;
					newFiles[currentFileIndex].status = 'error';
					newFiles[currentFileIndex].subTitle = 'Upload canceled';
					return newFiles;
				});
				return false;
			}
			console.error(error);
			setFiles(prev => {
				const currentFileIndex = prev.findIndex(f => f.id === id);
				if (currentFileIndex === -1) return prev;
				const newFiles = [...prev];
				newFiles[currentFileIndex].progress = 0;
				newFiles[currentFileIndex].status = 'error';
				newFiles[currentFileIndex].subTitle = 'Something went wrong. Try again.';
				return newFiles;
			});
			return false;
		}
	};

	const editFile = i => {
		const newFiles = [...files];
		newFiles[i].status = 'editing';
		setFiles(newFiles);
		setTimeout(() => {
			const inputElement = document.getElementById(`file-uploading-rename-input-${newFiles[i].id}`);
			inputElement?.focus();
		}, 300);
	};

	const revertFile = i => {
		const newFiles = [...files];
		newFiles[i].status = 'done';
		newFiles[i].fileName = newFiles[i].originalFile.name;
		setFiles(newFiles);
	};

	const renderFileUploading = (file, i) => (
		<div
			className={el(
				`file-uploading ${file.status === 'error' ? 'error' : ''} ${
					file.status === 'editing' ? 'editing' : ''
				}`,
			)}
			key={file?.id}
			style={{
				width: files.length >= 4 ? 'calc(100% - 10px);' : 'calc(100% - 13px)',
			}}
		>
			<div className={el('file-image')}>
				<FileIcon type="doc" />
			</div>

			<div className={el('file-uploading-text')}>
				{file.status === 'editing' ? (
					<input
						type="text"
						value={formatName(file.fileName)}
						className={el('file-uploading-title-editing')}
						id={`file-uploading-rename-input-${file.id}`}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.target.blur();
							}
							if (e.key === 'Escape') {
								revertFile(i);
							}
						}}
						onChange={e => {
							const newFiles = [...files];
							newFiles[i].fileName = e.target.value;
							setFiles(newFiles);
						}}
						onBlur={e => {
							const newFiles = [...files];
							newFiles[i].status = file.backendId ? 'done' : 'queue';
							newFiles[i].fileName = `${e.target.value}.${getExtension(newFiles[i].originalFile.name)}`;
							setFiles(newFiles);
							onRenameFile(file, i);
						}}
						maxLength={250}
					/>
				) : (
					<>
						<span
							aria-hidden
							className={el(`file-uploading-title${file.status === 'error' ? ' error' : ''}`)}
							onClick={() =>
								file.status !== 'error' && file.status !== 'uploading' ? editFile(i) : null
							}
							title={`${formatName(file.fileName)}.${getExtension(files[i]?.originalFile?.name)}`}
						>
							{`${formatName(file.fileName)}.${getExtension(files[i]?.originalFile?.name)}`}
						</span>
						{file.subTitle && (
							<span className={el(`file-uploading-status ${file.status === 'error' ? 'error' : ''}`)}>
								{file.subTitle}
							</span>
						)}
					</>
				)}
			</div>
			<div className={el('footer-uploading-actions')}>{renderActions(file, i)}</div>
		</div>
	);

	const renderActions = (file, i) => {
		const { status, id } = file;

		if (status === 'done') {
			return (
				<IconButton
					customTheme="upload-file"
					delay={300}
					distance={10}
					tooltip={
						<div aria-hidden onClick={e => e.stopPropagation()}>
							Delete
						</div>
					}
					icon="delete"
					onClick={() => onDeleteFile(id, file?.backendId)}
				/>
			);
		}
		if (status === 'queue') {
			return (
				<IconButton
					customTheme="upload-file"
					delay={300}
					distance={10}
					icon="clear"
					tooltip={
						<div aria-hidden onClick={e => e.stopPropagation()}>
							Cancel
						</div>
					}
					onClick={() => onRemoveFile(id)}
				/>
			);
		}
		if (status === 'uploading') {
			return (
				<>
					<CustomPercentageCircle
						percentage={files[i].progress}
						customDuration={0}
						width={20}
						height={20}
						customClass={el('progress')}
					/>
					<IconButton
						customTheme="upload-file"
						delay={300}
						distance={10}
						icon="clear"
						tooltip={
							<div aria-hidden onClick={e => e.stopPropagation()}>
								Cancel
							</div>
						}
						onClick={() => onCancelUpload(i)}
					/>
				</>
			);
		}
		if (status === 'processing') {
			return (
				<>
					<Loader />
					<IconButton
						customTheme="upload-file"
						delay={300}
						distance={10}
						icon="clear"
						tooltip={
							<div aria-hidden onClick={e => e.stopPropagation()}>
								Cancel
							</div>
						}
						onClick={() => onCancelUpload(i)}
					/>
				</>
			);
		}
		if (status === 'error') {
			return (
				<>
					{file.subTitle === 'The file size is too large. Max file size: 200 MB.' ||
					file.subTitle === 'The file format is not supported. Try another.' ? null : (
						<IconButton
							customTheme="upload-file"
							delay={300}
							distance={10}
							icon="refresh"
							tooltip={
								<div aria-hidden onClick={e => e.stopPropagation()}>
									Try again
								</div>
							}
							onClick={() => onRetryUpload(i)}
						/>
					)}
					<IconButton
						customTheme="upload-file"
						delay={300}
						distance={10}
						icon="clear"
						tooltip={
							<div aria-hidden onClick={e => e.stopPropagation()}>
								Cancel
							</div>
						}
						onClick={() => onRemoveFile(id)}
					/>
				</>
			);
		}

		return (
			<IconButton
				customTheme="upload-file"
				delay={300}
				distance={10}
				icon="delete"
				tooltip={
					<div aria-hidden onClick={e => e.stopPropagation()}>
						Delete
					</div>
				}
				onClick={() => onRemoveFile(id)}
			/>
		);
	};

	const renderFooterWithButton = () => {
		const uploadedFiles = files.filter(f => f.status === 'done' || f.status === 'uploading');
		if (uploadedFiles.length === 0)
			return (
				<div className={el('footer-uploading')}>
					<Button label="Close" variant="text" onClick={checkFilesOnClose} data-testid="close" />
				</div>
			);
		return (
			<div className={el('footer-uploading')}>
				<Button label="Close" variant="text" onClick={checkFilesOnClose} data-testid="close" />
				<Button
					label="Upload"
					onClick={publishFiles}
					loading={loading}
					disabled={modalState === 'uploading'}
					data-testid="upload"
				/>
			</div>
		);
	};

	const formatName = name => {
		const newName = name.split('.');
		if (newName.length > 1) {
			newName.pop();
			return newName.join('.');
		}
		return name;
	};

	const getExtension = name => {
		const newName = name.split('.');
		if (newName.length > 1) {
			return newName.pop();
		}
		return '';
	};

	const checkFilesOnClose = () => {
		const noErrorFiles = files.filter(f => f.status !== 'error');
		if (noErrorFiles?.length) {
			setShowModalConfirmation(true);
		} else {
			setLoading(false);
			setShowModalConfirmation(false);
			onClose();
			setFiles([]);
		}
	};

	const notifyUsers = async () => {
		try {
			await service.notifyStudyFile(study?.id);
		} catch (error) {
			console.error(error);
		}
	};

	const publishFiles = async () => {
		const doneFiles = files.filter(f => f.status === 'done');
		if (doneFiles?.length) {
			setLoading(true);
			try {
				await Promise.all(
					doneFiles.map(async f => service.patchStudyFile(study?.id, f?.backendId, { isVisible: true })),
				);
			} catch (error) {
				console.error(error);
			}
			await notifyUsers();
			setLoading(false);
			fetchStudyFiles(study?.id, false);
			const croppedFilename =
				doneFiles[doneFiles.length - 1]?.fileName?.length > 20
					? `${doneFiles[doneFiles.length - 1]?.fileName?.substring(0, 20)}...`
					: doneFiles[doneFiles.length - 1]?.fileName;
			const msg =
				doneFiles.length === 1
					? `File <strong>${croppedFilename}</strong> was uploaded successfully.`
					: `${doneFiles.length} files were uploaded successfully.`;

			toastr.success(
				`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
						<p style="font-size: 16px;">${msg}</p>
					</div>`,
			);
			onClose();
			setFiles([]);
		}
	};

	const renderModalState = () => {
		if (modalState === 'uploading' || modalState === 'editing' || modalState === 'choose-single')
			return (
				<>
					<span className={el('subtitle')}>You can rename files here before clients see them.</span>
					<div className={el(`drop-simple ${loading ? 'disabled' : ''}`)}>
						<DragZone onUpload={uploadAfterDrop}>
							<div className={el(`upload-zone-small`)}>
								<span className={el('title-small')}>Drag and drop files here</span>
								<div className={el('input-container-small')}>
									<span className={el('or')}>or</span>
									<div>
										<span
											onClick={e => e?.target?.nextSibling?.click()}
											className={el('input-choose-file')}
											role="button"
											aria-hidden
										/>
										<input
											type="file"
											onChange={e => uploadAfterDrop(Array.from(e.target.files))}
											multiple
											style={{
												display: 'none',
											}}
										/>
									</div>
								</div>
							</div>
						</DragZone>
					</div>
				</>
			);

		if (modalState === 'choose-many')
			return (
				<div className={el(`drop ${loading ? 'disabled' : ''}`)}>
					<DragZone onUpload={uploadAfterDrop}>
						<div className={el('upload-zone')}>
							<img src={cardDividerIcon} alt="" />
							<span className={el('title')}>Drag and drop files here</span>
							<div className={el('input-container')}>
								<span className={el('or')}>or</span>
								<div>
									<span
										aria-hidden
										onClick={e => e?.target?.nextSibling?.click()}
										className={el('input-choose-file')}
									/>
									<input
										type="file"
										onChange={e => uploadAfterDrop(Array.from(e.target.files))}
										multiple
										style={{
											display: 'none',
										}}
									/>
								</div>
							</div>
							<span className={el('supported-files')}>Max file size: 200 MB.</span>
						</div>
					</DragZone>
				</div>
			);
		return null;
	};

	const closeModalAndCancelFiles = () => {
		cancelAllUploads();
		const doneFiles = files.filter(f => f.status === 'done');
		Promise.all(doneFiles.map(f => service.deleteStudyFile(study?.id, f?.backendId)));
		setLoading(false);
		onClose();
		setFiles([]);
		setTimeout(() => {
			setShowModalConfirmation(false);
		}, 300);
	};

	return (
		<Modal
			show={show === true}
			onClose={checkFilesOnClose}
			customClass={className}
			padding={16}
			customCloseIcon={() => null}
		>
			{showModalConfirmation ? (
				<Modal
					show={showModalConfirmation}
					width={500}
					padding={0}
					onClose={() => setShowModalConfirmation(false)}
				>
					<CustomModalConfirmation
						onCancel={() => setShowModalConfirmation(false)}
						onConfirm={closeModalAndCancelFiles}
						headerText="Are you sure you want to cancel this upload?"
						bodyText="Cancelling will remove all incomplete file uploads."
						confirmText="Yes"
						cancelText="No"
					/>
				</Modal>
			) : (
				<div className={el('container')}>
					<span className={el('title')}>Upload files</span>
					{renderModalState()}

					<div className={el('list-files')} style={{ ...(!files?.length && { height: 0 }) }}>
						{files?.length ? files.map((file, i) => renderFileUploading(file, i)) : null}
					</div>
					{files?.length ? (
						renderFooterWithButton()
					) : (
						<div className={el('footer')}>
							<span
								aria-hidden
								onClick={() => {
									onClose();
									setLoading(false);
								}}
							>
								Close
							</span>
						</div>
					)}
				</div>
			)}
			<Modal show={displayDeleteModal} width={500} padding={0} onClose={() => setDisplayDeleteModal(false)}>
				<CustomModalConfirmation
					onCancel={() => {
						setDisplayDeleteModal(false);
						setFileToDelete(null);
					}}
					onConfirm={deleteFile}
					headerText="Are you sure you want to delete this file?"
					bodyText={
						<>
							<span className={el('modal-text')}>
								Deleting this file will permanently remove it from this study.
							</span>
							<span className={el('modal-text')}>Please make sure you have a backup if needed.</span>
						</>
					}
					confirmText="Yes"
					cancelText="No"
				/>
			</Modal>
		</Modal>
	);
};

ModalUploadFile.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func,
	studyFiles: PropTypes.array,
	setStudyFiles: PropTypes.func,
	files: PropTypes.any,
	setFiles: PropTypes.func,
	fetchStudyFiles: PropTypes.func,
};
export default ModalUploadFile;
