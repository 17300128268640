import React from 'react';
import PropTypes from 'prop-types';
import { useIsApple } from 'src/hooks';
import Picker from '@emoji-mart/react';

import './styles.scss';

export const EmojiPicker = ({ onSelect }) => {
	const { isApple } = useIsApple();
	const set = isApple ? 'native' : 'twitter';

	return (
		<div className="emoji-picker-wrapper">
			<Picker
				emojiVersion={14}
				showPreview={false}
				showSkinTones={false}
				theme="light"
				navPosition="none"
				previewPosition="none"
				skinTonePosition="none"
				dynamicWidth
				set={set}
				noCountryFlags
				onEmojiSelect={emoji => {
					if (!emoji) return;
					if (typeof onSelect === 'function') {
						onSelect(emoji);
					}
				}}
			/>
		</div>
	);
};

EmojiPicker.propTypes = {
	onSelect: PropTypes.func,
};

export default EmojiPicker;
