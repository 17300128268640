import * as actions from '../actions';

const INITIAL_STATE = {
	token: null,
	complete: false,
	loading: 0,
	error: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_RESET_PASSWORD_TOKEN:
			return { ...state, token: action.payload.token };

		case actions.SET_RESET_PASSWORD_COMPLETE:
			return { ...state, complete: action.payload.complete };

		case actions.INC_LOADING:
			return { ...state, loading: state.loading + 1 };

		case actions.DEC_LOADING:
			return { ...state, loading: state.loading - 1 };

		case actions.SET_ERROR:
			return { ...state, error: action.payload.error };

		default:
			return state;
	}
};
