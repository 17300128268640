import * as actions from '../actions';

const INITIAL_STATE = {
	loading: false,
	chargifyToken: '',
	previewInfo: {},
	uuid: '', // used to navigate to the new account when creating one from an existing account
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_LOADING:
			return { ...state, loading: action.payload.loading };

		case actions.SET_PREVIEW_SUBSCRIPTION_INFO:
			return { ...state, previewInfo: action.payload.previewInfo };
		case actions.SET_UUID:
			return { ...state, uuid: action.payload.uuid };

		case actions.ACTIVATE_ACCOUNT:
			return { ...state, ...action.payload };

		default:
			return state;
	}
};
