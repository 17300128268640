import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import { Iconof } from '@upsiide/ui-components';
import './styles.scss';

const className = 'mobile-menu-container';
const el = (name, mod) => cn(className, name, mod);

export const MobileMenuHeader = ({ onClose, onBack, children }) => (
	<div className={el('header')}>
		<div className={el('header-buttons', onBack ? 'justify-between' : '')}>
			{onBack ? <Iconof icon="chevron_left" onClick={() => onBack(false)} /> : null}
			<Iconof icon="clear" onClick={() => onClose(false)} />
		</div>
		{children ? <div className={el('header-children')}>{children}</div> : null}
	</div>
);
MobileMenuHeader.propTypes = {
	onClose: PropTypes.func,
	onBack: PropTypes.func,
	children: PropTypes.node,
};

const MobileMenuContainer = ({ children }) =>
	ReactDOM.createPortal(<div className={className}>{children}</div>, document.body);
MobileMenuContainer.propTypes = {
	children: PropTypes.node,
};
export default MobileMenuContainer;
