import { getAccountData } from 'src/domains/selectors';
import Cookies from 'src/utilities/cookies';
import store from '../store';
import * as selectors from '../domains/auth/selectors';
import * as accountSelectors from '../domains/account/selectors';

const and = collection => collection.reduce((acc, item) => acc && item, true);
const or = collection => collection.reduce((acc, item) => acc || item, false);

const MODES = { and, or };

const isDigAdmin = () => {
	const state = store.getState();
	return selectors.isDigAdmin(state);
};

const isDigEmployee = () => {
	const state = store.getState();
	return selectors.isDigEmployee(state);
};

const isDigGuest = clientIdProps => {
	const state = store.getState();
	const currentClientId = clientIdProps || selectors.getClientId(state);
	return selectors.isDigGuest(state, currentClientId);
};

const getPrivileges = () => {
	const state = store.getState();
	return selectors.getPrivileges(state);
};

const accountHasFeature = feature => {
	const state = store.getState();
	const accountFeatures = getAccountData(state).account.content ? getAccountData(state).account.content.features : [];

	if (!accountFeatures) return;

	const accountFeature = accountFeatures.find(item => item.slug === feature);
	const hasAccessToFeature =
		accountFeatures && accountFeatures.length > 0 && accountFeature ? accountFeature.value : true;
	return hasAccessToFeature;
};

const employeeHasFeature = featureLabel => {
	const state = store.getState();
	const { account: accountDomain } = state;
	const { account, accounts } = accountDomain;

	if (!isDigEmployee() && !isDigAdmin()) return false;

	if (account && account.content && accounts && accounts.content) {
		const { features } = account.content;
		return features && features.some(feature => feature.label === featureLabel && feature.value === 'true');
	}

	return false;
};

const userCan = (scope, global, mode = 'and', clientIdProps) => {
	const state = store.getState();
	const isAdmin = isDigAdmin();
	if (isAdmin) return true;

	const currentClientId = clientIdProps || selectors.getClientId(state);
	const scopeArray = currentClientId ? selectors.getScopes(state)[currentClientId] || [] : [];
	const scopesToCheck = global ? selectors.getGlobalScopes(state) : scopeArray;
	const able = Array.isArray(scope)
		? MODES[mode](scope.map(item => scopesToCheck.indexOf(item) > -1))
		: scopesToCheck.indexOf(scope) > -1;

	if (scope === 'account:list') {
		const accounts = accountSelectors.getAccounts(state);
		const upsiideAccountToken = Cookies.getUpsiideAccountToken();
		const accountSettingsAccess = upsiideAccountToken
			? accounts.content.filter(a => a.uuid === upsiideAccountToken)
			: accounts.content;
		const permission = accountSettingsAccess[0] ? accountSettingsAccess[0].permission : '';
		const accountHasPermission = permission === 'CAN_MANAGE' || permission === 'CAN_VIEW_DETAILS';
		return able && accountHasPermission;
	}
	return able;
};

const userCanWithinAccount = scope => {
	const state = store.getState();
	const { account: accountDomain } = state;
	const { account, accounts } = accountDomain;

	if (isDigAdmin()) return isDigAdmin();

	const getUnique = (arr, comp) =>
		arr
			.map(e => e[comp])
			.map((e, i, final) => final.indexOf(e) === i && i)
			.filter(e => arr[e])
			.map(e => arr[e]);

	if (account && account.content && accounts && accounts.content) {
		const privileges = getPrivileges() || [];
		const { clients } = account.content;
		// Get the privileges you need by comparing account.content.clients with privileges
		let filteredPrivileges = [];
		privileges.forEach(p => {
			clients.forEach(c => {
				if (p.uuid === c.uuid) {
					filteredPrivileges.push(p);
				}
			});
		});

		// Get only unique values from the filtered privileges
		filteredPrivileges = getUnique(filteredPrivileges, 'uuid');

		// Get all the scopes and flatten the scopes array and then remove any duplicate scopes
		// !Check to see if there are any results found in comparing privilege and client UUIDs, otherwise scopes will error out on mapping
		const scopes =
			filteredPrivileges && filteredPrivileges.length > 0
				? [...new Set(filteredPrivileges.map(p => p.scopes).reduce((prev, curr) => prev.concat(curr)))]
				: [];

		return scopes.indexOf(scope) !== -1;
	}

	return false;
};

const accountIsNotUUID = uuid => {
	const state = store.getState();
	const { account } = getAccountData(state);

	return account?.content?.uuid !== uuid;
};

export default {
	accountHasFeature,
	employeeHasFeature,
	userCan,
	userCanWithinAccount,
	isDigAdmin,
	isDigEmployee,
	isDigGuest,
	getPrivileges,
	accountIsNotUUID,
};
