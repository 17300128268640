import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
	const handleClick = e => {
		if (Array.isArray(ref)) {
			let clickContains = false;
			ref.forEach(r => {
				if (r.current && r.current.contains(e.target)) {
					clickContains = true;
				}
			});

			if (!clickContains) {
				callback(e.target);
			}
		} else if (ref.current && !ref.current.contains(e.target)) {
			callback(e.target);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	});
};

export default useOutsideClick;
