import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useOutsideClick } from 'src/hooks';
import { Tooltip } from 'react-tippy';
import Auth from 'src/utilities/auth';
import cn from 'src/utilities/bem-cn';
import fileAttach from 'public/images/upload/file-attach.svg';
import { getAuthData } from 'src/domains/selectors';
import * as selectors from 'src/domains/selectors';
import * as actions from 'src/domains/manage-study/actions';
import FileUploadDropdown from '../FileUploadDropdown';

import './styles.scss';

const className = 'file-upload-header';
const el = (name, mod) => cn(className, name, mod);

const FileUploadHeader = () => {
	const ref = useRef(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const { study } = useSelector(selectors.getManageStudyData);
	const { studyFiles } = useSelector(store => store.manageStudy);
	const authData = useSelector(state => getAuthData(state));

	const [fileUploadDropdown, setFileUploadDropdown] = useState(searchParams.get('files'));

	const fetchStudyFiles = useCallback(
		(studyId = 0, addLoading = true) => dispatch(actions.fetchStudyFiles(studyId, addLoading)),
		[dispatch],
	);
	const setStudyFiles = useCallback(
		(files = []) => dispatch(actions.setStudyFiles({ loading: false, content: files })),
		[dispatch],
	);

	useOutsideClick(ref, e => {
		if (!!e?.className && typeof e.className === 'string' && e?.className?.includes('tooltip')) return;
		setFileUploadDropdown(false);
	});

	useEffect(() => {
		if (study?.id) {
			fetchStudyFiles(study.id);
		}
	}, [study, fetchStudyFiles]);

	useEffect(
		() => () => {
			setStudyFiles([]);
		},
		[],
	);

	useEffect(() => {
		if (fileUploadDropdown && searchParams.get('files') !== 'open') {
			setSearchParams(prevState => ({ ...prevState, files: 'open' }), { replace: true });
		} else if (!fileUploadDropdown && searchParams.get('files') === 'open') {
			setFilesAsRead();
			setSearchParams(
				prevState => {
					const { files, ...rest } = prevState;
					return rest;
				},
				{ replace: true },
			);
		}
	}, [fileUploadDropdown, navigate, searchParams, setSearchParams, setFilesAsRead]);

	const setFilesAsRead = useCallback(() => {
		const newFiles = studyFiles?.content?.map(file => ({ ...file, isNew: false })) || [];
		localStorage.setItem('userFiles', JSON.stringify(newFiles));
		setStudyFiles(newFiles);
	}, [studyFiles, setStudyFiles]);

	const renderUploadBadgeIcon = () => {
		// TODO: waiting for decision
		// const thereIsNewFiles =
		// !fileUploadDropdown &&
		// !canUpload &&
		// studyFiles?.content.filter(file => !!file?.isVisible).some(file => !!file?.isNew);
		const thereIsNewFiles = false; // ? Remove it later

		if (canUpload && !fileNumber) return null;
		if (canUpload && fileNumber) return <span className={el('file_number')}>{fileNumber}</span>;
		if (thereIsNewFiles) return <span className={el('file_number_new')}>+</span>;
		if (!canUpload && fileNumber) return <span className={el('file_number')}>{fileNumber}</span>;
		return null;
	};

	const canUpload = useMemo(() => {
		if (Auth.isDigAdmin()) return true;
		if (
			authData.privileges.find(
				priv =>
					priv.uuid === authData.clientId &&
					(priv.roleCode === 'DIG_ADMIN' || priv.roleCode === 'DIG_EMPLOYEE'),
			)
		)
			return true;
		return false;
	}, [authData]);

	const fileNumber = useMemo(
		() => (studyFiles?.content ? studyFiles?.content?.filter(file => !!file?.isVisible).length : 0),
		[studyFiles],
	);

	if (!canUpload && fileNumber === 0) return null;

	return (
		<div style={{ position: 'relative' }}>
			{fileUploadDropdown && (
				<FileUploadDropdown onClose={() => setFileUploadDropdown(false)} setFiles={setStudyFiles} />
			)}

			<Tooltip
				arrow={false}
				interactive
				position="bottom"
				trigger="mouseenter"
				theme="basic-text-tooltip"
				html={<div className={el('document-tooltip')}>Files</div>}
				hideOnClick
				delay={0}
				distance={0}
			>
				<div
					ref={ref}
					className={el('document-container')}
					aria-hidden
					onClick={() => {
						if (!fileUploadDropdown) setFilesAsRead();
						setFileUploadDropdown(prev => !prev);
					}}
				>
					<div
						className={el('document-button')}
						style={{ backgroundColor: fileUploadDropdown ? '#e3f4ec' : '#fff' }}
					>
						<img src={fileAttach} alt="" />
						{renderUploadBadgeIcon()}
					</div>
				</div>
			</Tooltip>
		</div>
	);
};

FileUploadHeader.propTypes = {};

export default FileUploadHeader;
