import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

const UPLOAD_SAML_CERTIFICATE_URL = `${API_PATH}/account/settings/saml-cert`;
const UPLOAD_FILE_OPTIONS = {
	headers: {
		'Content-Type': 'multipart/form-data',
	},
};

const accountService = {
	getAccount: (accountUuid = null) => {
		const config = {};
		if (accountUuid) {
			config.headers = { 'x-account-uuid': accountUuid };
		}
		return axios.get(`${API_PATH}/account`, config);
	},
	createAccount: data => axios.post(`${API_PATH}/accounts`, data),
	updateAccountSettings: data => axios.put(`${API_PATH}/account/settings`, data),
	updateCreditCard: stripeToken => axios.post(`${API_PATH}/account/creditCard`, { stripeToken }),
	addChargifyCreditCard: chargifyJsToken =>
		axios.post(`${API_PATH}/account/creditCard/chargify`, { chargifyJsToken }),
	updateDefaultChargifyCreditCard: cardId =>
		axios.post(`${API_PATH}/account/creditCard/chargify/setDefaultCard`, { cardId }),
	removeChargifyCreditCard: cardId => axios.delete(`${API_PATH}/account/creditCard/chargify/${cardId}`),
	updateDefaultCreditCard: accountCreditCardId =>
		axios.post(`${API_PATH}/account/setDefaultCard`, { accountCreditCardId }),
	deleteCreditCard: creditCardId => axios.delete(`${API_PATH}/account/creditCard/${creditCardId}`),
	getAccountSubscription: () => axios.get(`${API_PATH}/account/subscription`),
	getAccountInvoices: () => axios.get(`${API_PATH}/account/invoices`),
	createClient: (clientData, accountId) => axios.post(`${API_PATH}/account/clients`, clientData),
	// deleteClient: (clientUuid, ()) => axios.delete(`${API_PATH}/clients/${clientUuid}/users/${uuid}`),
	getClients: accountId => axios.get(`${API_PATH}/accounts/${accountId}/`),
	getAllClients: () => axios.get(`${API_PATH}/clients`),
	getUsers: (search = '', offset = 0, limit = 10) =>
		axios.get(`${API_PATH}/account/users`, {
			params: {
				search,
				offset,
				limit,
			},
		}),
	addUser: (clientId, email, roleId, accountName, spaces) =>
		axios.post(
			`${API_PATH}/clients/${clientId}/users`,
			{ email, roleId, accountName, spaces },
			{
				headers: {
					'x-client-uuid': clientId,
				},
			},
		),
	getRoles: () => axios.get(`${API_PATH}/account/roles`).then(({ data }) => data.filter(role => !!role)),
	resendInvitation: (email, clientUuid, accountName) =>
		axios.post(
			`${API_PATH}/auth/users/resendInvitation/`,
			{ email, accountName },
			{ headers: { 'x-client-uuid': clientUuid } },
		),
	resendEmailValidation: (email, clientUuid, accountName) =>
		axios.post(
			`${API_PATH}/auth/users/resendEmailValidation/`,
			{ email, accountName },
			{ headers: { 'x-client-uuid': clientUuid } },
		),
	fetchAccountUsage: () => axios.get(`${API_PATH}/account/usage`),
	deleteUser: (clientId, userId) =>
		axios.delete(`${API_PATH}/clients/${clientId}/users/${userId}`, {
			headers: {
				'x-client-uuid': clientId,
			},
		}),
	fetchAccounts: () => axios.get(`${API_PATH}/accounts/`),
	removeAccess: (clientId, userId) =>
		axios.delete(`${API_PATH}/clients/${clientId}/users/${userId}`, { headers: { 'x-client-uuid': clientId } }),
	fetchAccount: accountId => {
		if (!accountId) {
			return axios.get(`${API_PATH}/account/`);
		}
		return axios.get(`${API_PATH}/account/`, { headers: { 'x-account-uuid': accountId } });
	},
	fetchAccountFeatures: uuid => axios.get(`${API_PATH}/account/features`, { headers: { 'x-client-uuid': uuid } }),
	createAccountFeature: (featureId, isEnabled, uuid) =>
		axios.post(`${API_PATH}/account/features`, { isEnabled, featureId }, { headers: { 'x-client-uuid': uuid } }),
	patchAccountFeature: (accountFeatureId, isEnabled, uuid) =>
		axios.patch(
			`${API_PATH}/account/features/${accountFeatureId}`,
			{ isEnabled },
			{ headers: { 'x-client-uuid': uuid } },
		),
	addEmailEnterpriseContact: data => axios.patch(`${API_PATH}/accounts/enterprise-contact`, data),

	/* 
		Chargify API specific service calls: 
		fetchChargifySubscriptions: calls API -> billing/subscription
		fetchChargifyInvoices: calls API -> billing/invoice
		fetchChargifyUsage: calls API -> billing/invoice
		fetchAccountAudiences: calls API -> billing/audiences
	*/
	/**
	 * Use this endpoint to lists all subscriptions that belong to a customer.
	 * @external_reference https://developers.chargify.com/docs/api-docs/b3A6MTQxMDgyODE-list-customer-subscriptions
	 */
	fetchChargifySubscriptions: () => axios.get(`${API_PATH}/billing/subscription`),

	/**
	 * Use this endpoint to lists all invoices that belong to a customer subscription.
	 * @external_reference https://developers.chargify.com/docs/api-docs/b3A6MTQxMDgzNjM-list-invoices
	 */
	fetchChargifyInvoices: year => axios.get(`${API_PATH}/billing/invoice`, { params: { year } }),

	/**
	 * Use this endpoint to list all usage that belong to a customer subscription group
	 * @external_reference https://developers.chargify.com/docs/api-docs/b3A6MTQxMDgzNzU-list-subscription-components
	 * @external_reference https://developers.chargify.com/docs/api-docs/b3A6MTQxMDgzMjg-list-price-points
	 * @external_reference 	https://developers.chargify.com/docs/api-docs/b3A6MTQxMDgzNzM-read-account-balances
	 */
	fetchChargifyUsage: () => axios.get(`${API_PATH}/billing/usage`),

	/**
	 * Use this endpoint to list all audience data related to an account within a given range
	 */
	fetchAccountAudiences: billingPeriodId =>
		axios.get(`${API_PATH}/billing/audiences`, {
			params: {
				billingPeriodId,
			},
		}),

	/**
	 * Use this endpoint to list all billing periods for an account
	 */
	fetchAccountBillingPeriods: () => axios.get(`${API_PATH}/billing/periods`),

	/**
	 * Use this endpoint to download an account's audience summary CSV for a specific month and year.
	 * If month and year are not given, the current month and year will be used.
	 */
	downloadAccountAudiencesCsv: (billingPeriodId, includeNotBilled, accountName, formattedDateRange) => {
		axios.download(
			`${API_PATH}/portation/billing/audiences`,
			`Upsiide - ${accountName} - Audience Usage ${formattedDateRange}.csv`,
			{
				billingPeriodId,
				includeNotBilled,
			},
		);
	},

	/**
	 * Use this endpoint to download an account's credit history
	 */
	downloadAccountCreditHistory: () =>
		axios.download(`${API_PATH}/portation/billing/credit/history`, `Export - Account Credit History.csv`),

	activateChargifySubscription: () => axios.post(`${API_PATH}/account/subscription/activate`),

	cancelChargifySubscription: () => axios.post(`${API_PATH}/account/subscription/cancel`),

	reactivateChargifySubscription: () => axios.post(`${API_PATH}/account/subscription/reactivate`),

	activateChargifySubscription: () => axios.post(`${API_PATH}/account/subscription/activate`),

	updateChargifySubscriptionPricepoint: data => axios.post(`${API_PATH}/account/subscription/planPricePoint`, data),

	fetchProductPricePoints: () => axios.get(`${API_PATH}/billing/products`),

	sendMobileBlockEmail: () => axios.post(`${API_PATH}/user/send-better-view-link/`),

	uploadSamlCertificate: asset => {
		const formData = new FormData();
		formData.append('certificate', asset);
		return axios.put(UPLOAD_SAML_CERTIFICATE_URL, formData, UPLOAD_FILE_OPTIONS);
	},
};

export default accountService;
