import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import cn from 'src/utilities/bem-cn';
import { useIsBlueprint } from 'src/hooks';
import * as studySelectors from 'src/domains/manage-study/selectors';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import * as manageBlueprintActions from 'src/domains/manage-blueprints/actions';
import * as manageStudyActions from 'src/domains/manage-study/actions';
import * as services from 'src/services';
import { getMeanScoreScaleOrder, isExcludedMeanScoreOption } from 'src/components/helpers';

import DetatchedScreenerModal from 'src/components/shared/DetatchedScreenerModal';
import QuestionsSection from '../../QuestionsSection';

import './styles.scss';

const className = 'screening-questions';

const el = (name, mod) => cn(className, name, mod);

const ScreeningQuestions = ({
	viewMode = false,
	audienceTemplate = null,
	questions = [],
	setQuestions,
	setOpenLibrary,
	tempTemplate,
	setTempTemplate,
	setTemplate,
	selectLanguage,
	addQuestionOptions = [],
	setShouldRender,
}) => {
	const dispatch = useDispatch();
	const { isBlueprint } = useIsBlueprint();
	const selectors = isBlueprint ? manageBlueprintSelectors : studySelectors;
	const actions = isBlueprint ? manageBlueprintActions : manageStudyActions;

	const questionsRef = useRef();
	questionsRef.current = questions;

	const [audienceReady, setAudienceReady] = useState(false);
	const [questionDetachModalCallback, setQuestionDetachModalCallback] = useState(null);

	const audienceCollectionLocal = useSelector(selectors.getAudienceCollection);
	const audienceEditTemplate = useSelector(selectors.getAudienceEditTemplate);
	const study = useSelector(state => selectors.getStudy(state));

	const audienceCollection = useMemo(() => {
		if (audienceTemplate) return audienceEditTemplate;
		return audienceCollectionLocal;
	}, [audienceTemplate, audienceCollectionLocal, audienceEditTemplate]);

	const studyId = useMemo(
		() => (audienceTemplate ? audienceTemplate.studyId : audienceCollectionLocal?.content?.studyId),
		[audienceTemplate, audienceCollectionLocal],
	);

	useEffect(() => {
		fetchAudienceCollection();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTempTemplate(prev => ({
			...prev,
			customQuestions: questions.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)),
		}));
	}, [questions]);

	const blockUpdateOrBreakTemplate = useCallback(
		callbackFunction => {
			if (viewMode) return;
			if (tempTemplate && tempTemplate.status && tempTemplate.status !== 'ready') {
				document.activeElement.blur();
			} else if (tempTemplate && tempTemplate.templateAudienceId) {
				document.activeElement.blur();
			} else {
				callbackFunction();
			}
		},
		[tempTemplate, viewMode],
	);

	const fetchAudienceCollection = useCallback(async () => {
		if (!audienceCollection?.content?.id) return;

		const response = await services.audienceService.getCollection(
			studyId,
			audienceTemplate ? audienceTemplate.id : audienceCollection.content.id,
		);

		const fetchedAudience = response?.data ?? null;

		if (fetchedAudience) {
			fetchedAudience.customQuestions = fetchedAudience?.screeningQuestions ?? [];
		}

		if (audienceTemplate) {
			setTemplate(fetchedAudience);
			dispatch(actions.setAudienceEditTemplate({ loading: false, content: fetchedAudience }));
		} else {
			dispatch(actions.setAudienceCollection({ loading: false, content: fetchedAudience }));
		}
	}, [studyId, audienceCollection, dispatch, actions, audienceTemplate, setTemplate]);

	const createOptionQuota = useCallback(
		async ({ questionId, optionId, data }) => {
			try {
				await services.audienceService.createOptionQuota({
					studyId,
					questionId,
					optionId,
					data,
				});
				await fetchAudienceCollection();
			} catch (error) {
				if (error?.response?.data?.details === '"quotaMax" must be less than or equal to 100') {
					return 'Some quotas values are invalid';
				}
			}
		},
		[studyId, fetchAudienceCollection],
	);

	const createManyOptionsQuota = useCallback(
		async (questionId, data) => {
			try {
				await services.audienceService.createManyOptionsQuota(studyId, questionId, data);
				await fetchAudienceCollection();
			} catch (error) {
				if (error?.response?.data?.details === '"quotaMa" must be less than or equal to 100') {
					return 'Some quotas values are invalid';
				}
			}
		},
		[studyId, fetchAudienceCollection],
	);

	const updateManyOptionsQuota = useCallback(
		async (questionId, optionId, data) => {
			try {
				await services.audienceService.updateManyOptionsQuota(studyId, questionId, optionId, data);
				await fetchAudienceCollection();
			} catch (error) {
				if (error?.response?.data?.details === '"quotaMa" must be less than or equal to 100') {
					return 'Some quotas values are invalid';
				}
			}
		},
		[studyId, fetchAudienceCollection],
	);

	const updateScreeningQuota = useCallback(
		async (questionId, optionId, quota) => {
			const currentQuestion = questions?.find(q => q?.id === questionId);
			if (!currentQuestion) return;
			const currentQuestionOption = currentQuestion?.options?.find(opt => opt.id === optionId);
			if (!currentQuestionOption?.quotaId) {
				// create
				const data = {
					quotaMin: quota == null ? null : quota,
					quotaMax: quota == null ? null : quota,
				};
				return createOptionQuota({ questionId, optionId, data });
			}
			// update
			const data = [
				{
					optionId,
					quotaId: currentQuestionOption.quotaId,
					quotaMin: quota == null ? null : quota,
					quotaMax: quota == null ? null : quota,
				},
			];
			return updateManyOptionsQuota(questionId, data);
		},
		[questions, createOptionQuota, updateManyOptionsQuota],
	);

	const updateAllScreeningOptionQuota = useCallback(
		async (questionId, options) => {
			const data = options.map(option => ({
				quotaMin: option?.quota == null ? null : option?.quota,
				quotaMax: option?.quota == null ? null : option?.quota,
				optionId: option.id,
				quotaId: option?.quotaId || null,
			}));
			return createManyOptionsQuota(questionId, data);
		},
		[createManyOptionsQuota],
	);

	const deleteAllScreeningOptionQuota = useCallback(
		async (questionId, ids) => {
			try {
				await services.audienceService.deleteQuotaFromOption(studyId, questionId, ids);
				await fetchAudienceCollection();
			} catch (error) {
				console.error(error);
			}
		},
		[studyId, fetchAudienceCollection],
	);

	const deleteScreeningQuestion = useCallback(
		async id => {
			try {
				await services.studyService.deleteQuestionLibraryFromAudience(studyId, id);
				await fetchAudienceCollection();
			} catch (e) {
				console.error(e);
			}
		},
		[studyId, fetchAudienceCollection],
	);

	const deleteOption = useCallback(
		async (questionId, optionId) => {
			try {
				// if placeholder only remove from the ui
				if (Number.isNaN(Number(optionId))) {
					const selectedQuestionIndex = questions.findIndex(q => q.id === questionId);
					if (selectedQuestionIndex !== -1) {
						const options = questions[selectedQuestionIndex].options.filter(
							opt => !Number.isNaN(Number(opt.id)),
						);
						questions[selectedQuestionIndex].options = options;
					}
				} else {
					await services.studyService.deleteQuestionOption(studyId, questionId, optionId);
					await fetchAudienceCollection();
				}
			} catch (e) {
				console.error(e);
			}
		},
		[studyId, fetchAudienceCollection, questions],
	);

	const patchScreeningQuestionSetting = useCallback(
		async (questionId, settingId, data) => {
			try {
				if (data?.value !== undefined && !settingId) {
					await services.questionService.createSetting(studyId, questionId, [data]);
				} else if (settingId) {
					await services.questionService.patchSetting(studyId, questionId, settingId, data);
				} else {
					await services.questionService.patch(studyId, questionId, data);
				}
				await fetchAudienceCollection();
			} catch (e) {
				console.error(e);
			}
		},
		[studyId, fetchAudienceCollection],
	);

	const batchSetAudienceTemplateScreeningQuestionOptions = useCallback(
		async ({ questionId, options }) => {
			try {
				setQuestions(prev =>
					prev.map(question =>
						question.id === questionId
							? {
									...question,
									options,
							  }
							: question,
					),
				);
			} catch (e) {
				console.error(e);
			}
		},
		[setQuestions],
	);

	const batchUpdateAudienceTemplateScreeningQuestionOptions = useCallback(
		async ({ questionId, options }) => {
			try {
				await services.questionService.patch(studyId, questionId, {
					options: options
						.map(({ maskedOptions, asset, assetVariations, ...option }) => ({
							...option,
							translations: option?.translations?.map(
								({ asset: excludedAsset, assetVariations: excludedAssetVariations, ...translation }) =>
									translation,
							),
						}))
						?.filter(
							({ placeholder, id: patchedQuestionOptionId }) =>
								!placeholder && typeof patchedQuestionOptionId === 'number',
						),
				});

				setQuestions(prev =>
					prev.map(question =>
						question.id === questionId
							? {
									...question,
									options,
									// options: options.map(existingOption => {
									// 	const updatedOption = updatedOptions?.find(
									// 		({ order }) => order === existingOption.order,
									// 	);
									// 	return updatedOption ?? existingOption;
									// }),
							  }
							: question,
					),
				);
			} catch (e) {
				console.error(e);
			}
		},
		[studyId, setQuestions],
	);

	// this is for "None of the above" and "Other specify"
	const addScreeningQuestionOption = useCallback(
		async (questionId, option) => {
			try {
				await services.questionService.createOption(studyId, questionId, option);
				await fetchAudienceCollection();
			} catch (e) {
				console.error(e);
			}
		},
		[fetchAudienceCollection, studyId],
	);
	const deleteScreeningQuestionOption = useCallback(
		async (questionId, optionId) => {
			try {
				await services.questionService.deleteOption(studyId, questionId, optionId);
				await fetchAudienceCollection();
			} catch (e) {
				console.error(e);
			}
		},
		[studyId, fetchAudienceCollection],
	);

	// Create or Update Option
	const updateOption = useCallback(
		async (questionId, optionId, isUpdate, optionData, keepPlaceholder = true) => {
			try {
				const currentQuestion = questionsRef?.current?.find(q => q?.id === questionId);

				if (isUpdate) {
					delete optionData.id;
					delete optionData.parentId;
					delete optionData.audienceQuestionOptionId;
					const response = await services.audienceService.updateOptionLabel(
						studyId,
						questionId,
						optionId,
						optionData,
					);

					const updatedOption = response.data.options?.find(({ id }) => id === optionId);

					const updatedOptions =
						currentQuestion?.options?.map(existingOption =>
							existingOption.id === optionId ? updatedOption : existingOption,
						) ??
						response?.data?.options ??
						[];

					const updatedQuestions =
						questionsRef.current?.map(question => {
							if (question?.id === response?.data?.id) {
								return {
									...question,
									options: updatedOptions,
								};
							}
							return question;
						}) ?? [];

					setQuestions(updatedQuestions);

					// await fetchAudienceCollection();
				} else {
					delete optionData.id;
					delete optionData.newLabel;

					const response = await services.audienceService.createOption(studyId, questionId, optionData);

					const responseOptions = response.data?.options ?? [];

					const currentOptions = currentQuestion?.options ?? [];

					const { isDesc: areCurrentOptionsDesc } = getMeanScoreScaleOrder(currentOptions);

					if (areCurrentOptionsDesc) {
						const currentOptionsWithUpdatedIds = currentOptions.map(option => ({
							...option,
							id: responseOptions?.find(({ order }) => order === option.order)?.id ?? option.id,
						}));

						return batchUpdateAudienceTemplateScreeningQuestionOptions({
							questionId,
							options: currentOptionsWithUpdatedIds,
						});
					}

					let updatedOptions =
						currentQuestion?.options?.map(existingOption => {
							const updatedOption = responseOptions?.find(({ order }) => order === existingOption.order);
							return updatedOption ?? existingOption;
						}) ??
						response?.data?.options ??
						[];

					// fixes copy / paste issue where options 2, 3, etc are not present in currentOptions, only in responseOptions
					if (updatedOptions?.length !== responseOptions?.length) {
						const missingOptions = responseOptions?.filter(
							({ id }) => !updatedOptions?.find(({ id: existingId }) => existingId === id),
						);

						updatedOptions = [...updatedOptions, ...missingOptions];
					}

					const updatedQuestions = questions.map(question => {
						if (question?.id === response?.data?.id) {
							return {
								...question,
								options: updatedOptions,
							};
						}
						return question;
					});

					const { isDesc } = getMeanScoreScaleOrder(updatedOptions);

					if (isDesc && !isExcludedMeanScoreOption(optionData)) {
						// * Update scale points on extending a descending auto scale
						await Promise.resolve(
							dispatch(
								actions.patchQuestion(questionId, {
									options: updatedOptions?.filter(
										({ placeholder, id }) => !placeholder && typeof id === 'number',
									),
									screeningQuestion: true,
								}),
							),
						);
					}

					setQuestions(updatedQuestions);

					if (currentQuestion.parentId) {
						fetchAudienceCollection();
					}
				}
			} catch (e) {
				console.error(e);
			}
		},
		[
			questions,
			studyId,
			setQuestions,
			batchUpdateAudienceTemplateScreeningQuestionOptions,
			dispatch,
			actions,
			fetchAudienceCollection,
		],
	);

	const setOptionScalePointValue = useCallback(
		async ({ questionId, optionId, scalePoint }) => {
			try {
				const currentQuestion = questionsRef?.current.find(({ id }) => id === questionId);
				const currentOption = currentQuestion?.options?.find(({ id }) => id === optionId);
				const updatedOption = { ...currentOption, scalePoint };
				const updatedOptions =
					currentQuestion?.options?.map(existingOption =>
						existingOption.id === optionId ? updatedOption : existingOption,
					) ?? [];

				const updatedQuestions = questionsRef?.current.map(question => {
					if (question?.id === questionId) {
						return {
							...question,
							options: updatedOptions,
						};
					}
					return question;
				});

				setQuestions(updatedQuestions);
			} catch (error) {
				console.error('Failed to update option scale point', error);
			}
		},
		[setQuestions],
	);

	const updateOptionScalePoint = useCallback(
		async ({ questionId, optionId, scalePoint }) => {
			try {
				const currentQuestion = questionsRef?.current.find(({ id }) => id === questionId);
				const currentOption = currentQuestion?.options?.find(({ id }) => id === optionId);

				const requestBody = {
					...currentOption,
					scalePoint,
					translations:
						currentOption?.translations?.map(({ asset, assetVariations, ...translation }) => translation) ??
						[],
				};

				delete requestBody.audienceQuestionOptionId;
				delete requestBody.asset;
				delete requestBody.assetVariations;
				delete requestBody.parentId;
				delete requestBody.id;
				delete requestBody.maskedOptions;

				const response = await services.audienceService.updateOptionLabel(
					studyId,
					questionId,
					optionId,
					requestBody,
				);

				const updatedOption = response.data.options?.find(({ id }) => id === optionId);

				const updatedOptions =
					currentQuestion?.options?.map(existingOption =>
						existingOption.id === optionId ? updatedOption : existingOption,
					) ??
					response?.data?.options ??
					[];

				const updatedQuestions = questionsRef?.current.map(question => {
					if (question?.id === response?.data?.id) {
						return {
							...question,
							options: updatedOptions,
						};
					}
					return question;
				});

				setQuestions(updatedQuestions);

				await fetchAudienceCollection();
			} catch (error) {
				console.error('Failed to update option scale point', error);
			}
		},
		[studyId, setQuestions, fetchAudienceCollection],
	);

	const deleteAttribute = useCallback(
		async (questionId, attributeId) => {
			try {
				if (Number.isNaN(attributeId)) {
					const updatedQuestions = questionsRef.current.map(q => {
						if (q.id === questionId) {
							return {
								...q,
								attributes: q?.attributes?.filter(att => att?.id !== attributeId) || [],
							};
						}
						return q;
					});
					setQuestions(updatedQuestions);
				} else {
					await services.studyService.deleteQuestionAttribute(studyId, questionId, attributeId);
					await fetchAudienceCollection();
				}
			} catch (e) {
				console.error(e);
			}
		},
		[studyId, fetchAudienceCollection],
	);

	const updateScreeningQuestionOption = useCallback(
		async (questionId, data) => {
			const cleanedUpTranslations = data?.translations?.map(translation => {
				delete translation?.languageLabel;
				return translation;
			});
			const cleanedUpData = { ...data, translations: cleanedUpTranslations };
			try {
				const payload = {
					...cleanedUpData,
					attributes: cleanedUpData?.attributes?.map(a => {
						a.translations = a?.translations.map(t => {
							delete t?.asset;
							delete t?.assetVariations;
							return t;
						});
						return a;
					}),
				};
				await services.questionService.patch(studyId, questionId, payload);
				await fetchAudienceCollection();
			} catch (e) {
				console.error(e);
			}
		},
		[studyId, fetchAudienceCollection],
	);

	// Create or Update Attribute
	const updateAttribute = useCallback(
		async (questionId, attributeId, isUpdate, attributeData, optionTranslation, keepPlaceholder = true) => {
			try {
				if (isUpdate) {
					delete attributeData.id;

					if (optionTranslation?.id) {
						await services.audienceService.updateAttributeLabel(
							studyId,
							questionId,
							attributeId,
							optionTranslation?.id,
							{
								label: attributeData?.value,
								languageCode: optionTranslation?.languageCode,
								studyTranslationId: optionTranslation?.id,
							},
						);
					} else {
						await services.audienceService.createAttributeTranslation(studyId, questionId, attributeId, [
							{
								label: optionTranslation?.label,
								languageCode: optionTranslation?.languageCode,
							},
						]);
					}
					await fetchAudienceCollection();
				} else {
					const currentQuestion = questionsRef?.current.find(q => q?.id === questionId);
					const placeholderAttribute = currentQuestion.attributes.find(
						op => op?.id.toString().indexOf('temp') > -1 && op?.id !== attributeData?.id,
					);
					delete attributeData.id;
					delete attributeData.newLabel;

					const translations = attributeData.translations.filter(({ languageCode }) => !!languageCode);
					const response = await services.audienceService.createAttribute(studyId, questionId, {
						...attributeData,
						translations,
					});
					const updatedAttributes =
						placeholderAttribute && keepPlaceholder
							? [
									...(response?.data?.attributes || currentQuestion?.attributes || []),
									placeholderAttribute,
							  ]
							: response?.data?.attributes || currentQuestion?.attributes;
					const updatedQuestions = questionsRef?.current.map(question => {
						if (question?.id === response?.data?.id) {
							return {
								...question,
								attributes: updatedAttributes,
							};
						}
						return question;
					});
					if (currentQuestion.parentId) {
						fetchAudienceCollection();
					}
					setQuestions(updatedQuestions);
				}
			} catch (e) {
				console.error(e);
			}
		},
		[study, studyId, fetchAudienceCollection],
	);

	const updateQuestionLabel = useCallback(
		async (questionId, content, language) => {
			try {
				const question = audienceTemplate
					? audienceTemplate.screeningQuestions.find(quest => questionId === quest.id)
					: audienceCollection.content.screeningQuestions.find(quest => questionId === quest.id);
				const translations = [];
				question.translations = question?.translations?.map(translation => {
					if (translation.languageCode === language) {
						translations.push({
							languageCode: translation.languageCode,
							label: content,
							assetId: translation.assetId,
						});
					} else {
						translations.push({
							languageCode: translation.languageCode,
							label: translation.label,
							assetId: translation.assetId,
						});
					}
					return translation;
				});

				const studyLanguages = audienceTemplate
					? [...new Set(audienceCollection.content.demographicGroups.map(group => group.languageCode))]
					: study.translations.map(translation => translation.languageCode);

				studyLanguages.forEach(languageCode => {
					const translationExists = translations.find(
						translation => translation.languageCode === languageCode,
					);
					if (!translationExists) {
						translations.push({
							languageCode,
							label: languageCode === language ? content : '',
						});
					}
				});

				await services.questionService.patch(studyId, questionId, { label: content, translations });
				await fetchAudienceCollection();
			} catch (e) {
				console.error(e);
			}
		},
		[study, audienceCollection, studyId, fetchAudienceCollection, audienceTemplate],
	);

	const containerRef = useRef();
	useEffect(() => {
		if (containerRef.current?.clientHeight) {
			setTimeout(() => {
				setShouldRender(true);
			}, 300);
		}
	}, [containerRef]);

	const libraryScreeningQuestionIds = useMemo(
		() =>
			audienceTemplate
				? audienceTemplate?.screeningQuestions?.filter(({ parentId }) => parentId).map(({ id }) => id)
				: audienceCollection?.content?.screeningQuestions
						?.filter(({ parentId }) => parentId)
						.map(({ id }) => id) || [],
		[audienceTemplate, audienceCollection],
	);

	const libraryScreeningQuestionUpdateHandler = useCallback(
		(callback, params) => {
			let questionId;

			if (params?.length) {
				questionId = params?.[0];
				if (libraryScreeningQuestionIds.includes(questionId)) {
					setQuestionDetachModalCallback(() => () => callback(...params));
				} else {
					callback(...params);
				}
				return;
			}

			questionId = params?.questionId;
			if (libraryScreeningQuestionIds.includes(questionId)) {
				setQuestionDetachModalCallback(() => () => callback({ ...params }));
			} else {
				callback({ ...params });
			}
		},
		[libraryScreeningQuestionIds],
	);

	return (
		<div className={el('box-content')} ref={containerRef}>
			<div className={el('screening_container')}>
				<div className={el('container')}>
					<QuestionsSection
						audienceTemplate={audienceTemplate}
						audience={tempTemplate}
						studyId={studyId}
						languages={tempTemplate?.languages}
						initialSelectLanguage={selectLanguage}
						patchItem={null}
						deleteItemLogic={null}
						customTarget
						className={className}
						tempTemplate={tempTemplate}
						setTempTemplate={setTempTemplate}
						blockUpdateOrBreakTemplate={blockUpdateOrBreakTemplate}
						setAudienceReady={setAudienceReady}
						familyDomain={isBlueprint ? 'manageBlueprint' : 'manageStudy'}
						showHeader={false}
						screeningQuestion
						screeningQuestionAddQuestionOptions={addQuestionOptions}
						setOpenQuestionLibrary={setOpenLibrary}
						deleteScreeningQuestion={deleteScreeningQuestion}
						updateScreeningOption={(...params) => {
							libraryScreeningQuestionUpdateHandler(updateOption, params);
						}}
						updateScreeningOptionScalePoint={({ questionId, optionId, scalePoint }) => {
							libraryScreeningQuestionUpdateHandler(updateOptionScalePoint, {
								questionId,
								optionId,
								scalePoint,
							});
						}}
						setScreeningOptionScalePointValue={({ questionId, optionId, scalePoint }) => {
							libraryScreeningQuestionUpdateHandler(setOptionScalePointValue, {
								questionId,
								optionId,
								scalePoint,
							});
						}}
						updateScreeningAttribute={(...params) =>
							libraryScreeningQuestionUpdateHandler(updateAttribute, params)
						}
						deleteScreeningOption={(...params) => {
							libraryScreeningQuestionUpdateHandler(deleteOption, params);
						}}
						deleteScreeningAttribute={(...params) =>
							libraryScreeningQuestionUpdateHandler(deleteAttribute, params)
						}
						updateScreeningQuota={updateScreeningQuota}
						updateAllScreeningOptionQuota={updateAllScreeningOptionQuota}
						deleteAllScreeningOptionQuota={deleteAllScreeningOptionQuota}
						patchScreeningQuestionSetting={patchScreeningQuestionSetting}
						addScreeningQuestionOption={addScreeningQuestionOption}
						deleteScreeningQuestionOption={deleteScreeningQuestionOption}
						updateScreeningQuestionLabel={updateQuestionLabel}
						updateScreeningQuestionOption={updateScreeningQuestionOption}
						batchUpdateAudienceTemplateScreeningQuestionOptions={
							batchUpdateAudienceTemplateScreeningQuestionOptions
						}
						batchSetAudienceTemplateScreeningQuestionOptions={
							batchSetAudienceTemplateScreeningQuestionOptions
						}
					/>
				</div>
			</div>
			<DetatchedScreenerModal
				onClose={async () => {
					fetchAudienceCollection();
					setQuestionDetachModalCallback(null);
				}}
				onConfirm={() => {
					questionDetachModalCallback();
					setQuestionDetachModalCallback(null);
				}}
				show={questionDetachModalCallback !== null}
			/>
		</div>
	);
};

ScreeningQuestions.propTypes = {
	viewMode: PropTypes.bool,
	audienceTemplate: PropTypes.object,
	questions: PropTypes.any,
	setQuestions: PropTypes.any,
	setOpenLibrary: PropTypes.any,
	tempTemplate: PropTypes.any,
	setTempTemplate: PropTypes.any,
	setTemplate: PropTypes.any,
	selectLanguage: PropTypes.any,
	addQuestionOptions: PropTypes.array,
	setShouldRender: PropTypes.func,
};

export default ScreeningQuestions;
