import React from 'react';
import { PropTypes } from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Tag from 'src/components/elements/Tag';

import './styles.scss';

const className = 'tabs';
const el = name => cn(className, name);

const Tabs = ({ title, tabs, activeTab, setActiveTab, disableEmptyTabs = true }) => (
	<div className={className}>
		{title ? <div className={el('title')}>{title}</div> : null}
		{tabs?.map(tab => {
			const isActive = activeTab === (tab.id || tab.label || tab);
			return (
				<Tag
					key={tab.id || tab.label || tab}
					className={isActive ? 'active' : ''}
					label={
						typeof tab === 'object' && tab.label ? (
							<div className={el('content')}>
								<div className={el(`label ${isActive ? 'active' : ''}`)}>{tab.label}</div>
								<div className={el(`count ${isActive ? 'active' : ''}`)}>{tab.count}</div>
							</div>
						) : (
							tab
						)
					}
					onClick={e => setActiveTab(tab.label || tab, e)}
					disabled={!isActive && disableEmptyTabs && tab.count < 1}
				/>
			);
		})}
	</div>
);

Tabs.propTypes = {
	title: PropTypes.string,
	tabs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)]),
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
	disableEmptyTabs: PropTypes.bool,
};

export default Tabs;
