import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'search-results-table-header';
const el = (name, mod) => cn(className, name, mod);

const Header = ({ title, customClass, children }) => (
	<div className={el('title-container', customClass || '')}>
		{title ? <span className={el('title')}>{title}</span> : null}

		{children}
	</div>
);

Header.propTypes = {
	title: PropTypes.string,
	customClass: PropTypes.string,
	children: PropTypes.any,
};

export default Header;
