import React from 'react';
import './styles.scss';

const className = 'new-textarea';

class Textarea extends React.Component {
	handleChange = e => this.props.onChange(e.target.value);

	render() {
		const { minHeight, value, className: $className, invalid, innerRef, ...props } = this.props;
		return (
			<textarea
				{...props}
				className={`form-control ${$className} ${className} ${invalid ? 'invalid' : ''}`}
				value={value || ''}
				onChange={this.handleChange}
				style={{ minHeight }}
				ref={innerRef}
			/>
		);
	}
}

export default React.forwardRef((props, ref) => <Textarea innerRef={ref} {...props} />);
