export const IN_CONTEXT_ARTICLES = {
	audience: {
		urlSearch: ['/audiences', '/checkout'],
		idSearch: [],
		articleId: 6405193,
	},
	ideaScreen: {
		urlSearch: ['/ideas', '/barchart', '/qchart', '/ideamap'],
		idSearch: ['manage-study-idea-screen'],
		articleId: 6405361,
	},
	ideaSplit: {
		urlSearch: [],
		idSearch: ['manage-study-idea-split'],
		articleId: 6405417,
	},
	marketSimulator: {
		urlSearch: ['/marketsimulator'],
		idSearch: [],
		articleId: 6405437,
	},
	settingsScreens: {
		urlSearch: ['/account', '/settings', '/me'],
		idSearch: [],
		articleId: 6405457,
	},
	blueprints: {
		urlSearch: ['/templates'],
		idSearch: ['create-from-blueprint'],
		articleId: 6405522,
	},
	homeScreen: {
		urlSearch: ['/studies$', '/studies/$'],
		idSearch: [],
		articleId: 4775615,
	},
	surveyCreateGeneric: {
		urlSearch: ['/create', '/translations'],
		idSearch: [],
		articleId: 6401955,
	},
	surveyReportGeneric: {
		urlSearch: [],
		idSearch: ['reportingContainer'],
		articleId: 6405856,
	},
};

/**
 * Searches through each key for available tours
 * tourId : The tour to trigger if available
 * urlSearch: [string] : Regex matches the url href
 * idSearch: [string] : Matches a DOM node with the ID specified
 * restrictId: [string] : Regex matches with an ID to prevent a tour from being started
 */
export const IN_CONTEXT_TOURS = {
	// ideaMap: {
	// 	urlSearch: ['ideamap'],
	// 	restrictId: ['shared-filter-bar'],
	// 	tourId: 368926,
	// },
	createIdea: {
		idSearch: ['manage-study-idea-screen'],
		tourId: 378972,
	},
	// quadrantChart: {
	// 	urlSearch: ['qchart'],
	// 	restrictId: ['shared-filter-bar'],
	// 	tourId: 368916,
	// },
	// barChart: {
	// 	urlSearch: ['barchart'],
	// 	restrictId: ['shared-filter-bar'],
	// 	tourId: 368907,
	// },
	ideas: {
		urlSearch: ['ideas'],
		restrictId: ['shared-filter-bar', 'no-data-to-show'],
		tourId: 368901,
	},
};

export const HELP_MENU_LINKS = {
	help: { label: 'Quick help' },
	support: { label: `Help Documentation`, link: `https://support.upsiide.com/` },
	message: { label: 'Support' },
	tour: { label: 'Take a tour' },
	new: { label: `What's new`, link: `https://news.upsiide.com` },
	academy: { label: `Upsiide Academy`, link: `https://academy.upsiide.com/` },
	chat: { label: `Chat with sales`, link: `https://upsiide.com/contact/` },
};
