import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { Tooltip } from 'react-tippy';
import { useDispatch, useSelector } from 'react-redux';
import logoUser from 'public/images/audience/user.png';
import logo from 'public/images/signup/upsiide-logo-small.svg';
import cn from 'src/utilities/bem-cn';
import useOutsideClick from 'src/hooks/useOutsideClick';
import Button from 'src/components/elements/Button';
import GreenArrowBack from 'public/images/audience/green_arrow_left.svg';
import GrayArrowBack from 'public/images/audience/gray_arrow_right.svg';
import ResponsiveModal from 'src/components/shared/ResponsiveModal';
import AreYouSureForm from 'src/components/shared/AreYouSureForm';
import TextInput from 'src/components/elements/TextInput';
import * as selectors from 'src/domains/manage-study/selectors';
import * as services from 'src/services';
import * as actions from 'src/domains/manage-study/actions';
import { audienceService } from 'src/services';
import EllipsisIcon from 'public/images/audience/EllipsisMenu.png';
import { renderAudienceName } from 'src/utilities/misc';
import InterlockQuotasWarning from 'src/domains/manage-study/containers/InterlockQuotasContainer/InterlockQuotasWarning';
import AudiencePreviewSidebar from '../AudienceTemplatePreview/AudiencePreviewSidebar';

import AudienceTemplateNavigation from '../AudienceTemplates/AudienceTemplateNavigation';
import AudienceTemplateEditContent from './AudienceTemplateEditContent';

import './style.scss';

const className = 'audience-template-edit';
const el = (name, mod) => cn(className, name, mod);
const AudienceTemplateEditor = ({
	audienceTemplate,
	setSelectedAudienceTemplate,
	onSelect,
	step,
	setStep,
	onClose,
	currentTab,
	setCurrentTab,
	languages,
	selectedLanguage,
	setSelectedLanguage,
	setTemplateModalCurrentAudience,
	templateModalCurrentAudience,
	onDone = false,
}) => {
	const groupData = useSelector(selectors.getGroup);
	const { content: group } = groupData;
	const [template, setTemplate] = useState(null);
	const [loading, setIsLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [description, setDescription] = useState(audienceTemplate?.description || '');
	const [audienceCollectionId, setAudienceCollectionId] = useState(audienceTemplate?.id);
	const [indexGroup, setIndexGroup] = useState(
		templateModalCurrentAudience
			? audienceTemplate?.demographicGroups?.findIndex(g => g?.uuid === templateModalCurrentAudience)
			: 0,
	);
	const [showAudienceSubMenu, setShowAudienceSubMenu] = useState(false);
	const [showAudienceHeaderMenu, setShowAudienceHeaderMenu] = useState(false);
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [demographicGroup, setDemographicGroup] = useState(audienceTemplate?.demographicGroups?.[0]);
	const [interlockQuotasData, setInterlockQuotasData] = useState();
	const [errors, setErrors] = useState([]);

	const isValid = useMemo(() => {
		const errorsFound = errors.map(e => Object.values(e)[0]).filter(hasError => hasError);
		return !errorsFound?.length;
	}, [errors]);

	useEffect(() => {
		if (loading || !template) setDemographicGroup(audienceTemplate?.demographicGroups?.[0]);
		else setDemographicGroup(template?.demographicGroups?.[indexGroup]);
	}, [loading, audienceTemplate, template, indexGroup]);

	const dispatch = useDispatch();
	const study = useSelector(selectors.getStudy);
	const setAudienceTemplateCollection = useCallback(
		collection => dispatch(actions.setAudienceTemplateCollection(collection)),
		[dispatch],
	);
	const audienceEditTemplate = useSelector(selectors.getAudienceEditTemplate);
	const subMenuRef = useRef(null);
	const subHeaderMenuRef = useRef(null);

	useOutsideClick(subMenuRef, () => {
		setShowAudienceHeaderMenu(false);
	});

	useOutsideClick(subHeaderMenuRef, () => {
		setShowAudienceSubMenu(false);
	});

	const callbackDemographic = useCallback(async () => {
		if (!template?.studyId) return;
		try {
			const responseCollection = await services.audienceService.getCollection(template.studyId, template?.id);
			if (Object.keys(responseCollection?.data?.owner).length) {
				setTemplate(responseCollection?.data);
			} else {
				setTemplate({ ...responseCollection?.data, owner: template?.owner });
			}

			dispatch(actions.setAudienceEditTemplate({ loading: false, content: responseCollection?.data }));
		} catch (error) {
			console.error(error);
		}
	}, [template]);

	const saveAudienceAsTemplate = useCallback(async () => {
		try {
			if (step === 'editTemplate') {
				if (!audienceEditTemplate?.content) {
					dispatch(actions.setAudienceEditTemplate({ loading: false, content: audienceTemplate }));
					setTemplate(audienceTemplate);
				} else {
					setTemplate(audienceEditTemplate.content);
				}
				return;
			}
			if (step === 'editViewMode') {
				return;
			}
			if (step === 'edit') {
				setCurrentTab('overview');
			}

			setIsLoading(true);
			dispatch(actions.setAudienceEditTemplate({ loading: false, content: null }));
			const response = await services.audienceService.saveCollectionAsTemplate(audienceTemplate.studyId, {
				collectionId: audienceTemplate.id,
				languageCode: study?.language || 'en',
				description: audienceTemplate.description,
			});
			dispatch(actions.setAudienceEditTemplate({ loading: false, content: null }));
			if (response?.data?.id) {
				setAudienceCollectionId(response.data.id);
				const responseCollection = await services.audienceService.getCollection(
					response?.data?.studyId,
					response?.data?.id,
				);
				if (responseCollection?.data) {
					setTemplate(responseCollection.data);
					dispatch(actions.setAudienceEditTemplate({ loading: false, content: responseCollection?.data }));
					setIndexGroup(0);
					setIsLoading(false);
				}
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			setTemplate(audienceTemplate);
			setCurrentTab('overview');
			setStep('preview');
		}
	}, [step, dispatch, audienceTemplate, study, audienceEditTemplate.content, setCurrentTab, setStep]);

	useEffect(() => {
		saveAudienceAsTemplate();
		return () => {
			// reset data in redux
			dispatch(actions.setAudienceEditTemplate({ loading: false, content: null }));
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (step === 'editTemplate') {
			setTemplate(audienceEditTemplate.content);
		}
	}, [audienceEditTemplate, step]);

	const onDelete = async () => {
		try {
			setDeleteLoading(true);
			await services.audienceService.deleteCollection(template?.studyId, template?.id);
			const [recentlyUsed, upsiideRecommended, all] = await Promise.all([
				await audienceService.getTemplateCollection(10, 0, null, 'Recently Used'),
				await audienceService.getTemplateCollection(10, 0, null, 'Upsiide Recommended'),
				await audienceService.getTemplateCollection(12, 0, null),
			]);

			setAudienceTemplateCollection({
				all: all?.data || [],
				recentlyUsed: recentlyUsed?.data || [],
				upsiideRecommended: upsiideRecommended?.data || [],
			});
			setDeleteLoading(false);

			onClose();
		} catch (error) {
			console.error(error);
			setDeleteLoading(false);
		}
	};

	const renderDeleteTemplate = () => (
		<ResponsiveModal
			show={deleteConfirmation}
			onClose={() => setDeleteConfirmation(false)}
			maxWidth={600}
			padding={0}
			customContent
		>
			<AreYouSureForm
				headerText="Delete template"
				bodyText="You are about to delete this template from your library. Are you sure you want to continue? This action cannot be undone."
				confirmText="Continue"
				cancelText="Cancel"
				onConfirm={() => {
					onDelete();
					setDeleteConfirmation(false);
				}}
				onCancel={() => setDeleteConfirmation(false)}
				headerIcon={{
					icon: 'warning',
					size: 'large',
					color: 'red',
					className: el('warning-icon'),
				}}
			/>
		</ResponsiveModal>
	);

	const renderOptions = () => (
		<div
			ref={subMenuRef}
			className={el('audience-sub-menu-wrapper')}
			onClick={() => setShowAudienceSubMenu(prevState => !prevState)}
			aria-hidden
		>
			<Button className={el('preview')} type="text" label="More Options +" />
			{showAudienceSubMenu &&
				(step === 'editTemplate' ? (
					<div className={el('audience-sub-menu-delete')}>
						<span
							onClick={() => setDeleteConfirmation(true)}
							aria-hidden
							className={el('audience-sub-menu-item')}
							style={{ color: 'red' }}
						>
							Delete
						</span>
					</div>
				) : (
					<div className={el('audience-sub-menu')}>
						<span
							aria-hidden
							className={el('audience-sub-menu-item')}
							onClick={() => setStep('editTemplate')}
						>
							Edit
						</span>
						<span aria-hidden className={el('audience-sub-menu-item')}>
							Duplicate
						</span>
						<span
							onClick={() => setDeleteConfirmation(true)}
							aria-hidden
							className={el('audience-sub-menu-item')}
							style={{ color: 'red' }}
						>
							Delete
						</span>
					</div>
				))}
		</div>
	);

	const renderErrors = useCallback(() => {
		if (currentTab !== 'interlock-quotas') return null;

		const hasQuotasBelowMinimum = errors.find(e => e.hasQuotasBelowMinimum);
		if (hasQuotasBelowMinimum) {
			return <InterlockQuotasWarning icon="error" description="Some of your quotas are too low" />;
		}
		const hasQuestionWithQuotaSumUnder100 = errors.find(e => e.hasQuestionWithQuotaSumUnder100);
		if (hasQuestionWithQuotaSumUnder100) {
			return <InterlockQuotasWarning icon="error" description="Quotas are lower than 100%" />;
		}
		const hasQuestionWithQuotaSumOver300 = errors.find(e => e.hasQuestionWithQuotaSumOver300);
		if (hasQuestionWithQuotaSumOver300) {
			return <InterlockQuotasWarning icon="error" description="Quotas are over 300%" />;
		}
	}, [errors, currentTab]);

	const renderFooter = () => {
		if (step === 'editViewMode') {
			return (
				<div className={el('preview-actions')}>
					{renderOptions()}

					<Button
						className={el('select')}
						label="Use Template"
						onClick={() => {
							onSelect(audienceCollectionId);
							onClose();
						}}
					/>
				</div>
			);
		}

		if (currentTab === 'interlock-quotas') {
			return (
				<div className={el('preview-actions')}>
					<Button
						className={el('cancel')}
						label="Cancel"
						type="text"
						onClick={() => {
							setCurrentTab('demographic');
						}}
					/>
					<Button
						className={el('select')}
						state={isValid ? 'active' : 'disabled'}
						label="Save"
						onClick={onSave}
					/>
				</div>
			);
		}

		return (
			<div className={el('preview-actions')}>
				<Button
					className={el('select')}
					label="Done"
					onClick={() => {
						if (audienceTemplate.description !== description) onChangeAudienceDescription();
						if (onDone) {
							setIsLoading(true);
							return onDone();
						}
						callbackDemographic();
						setStep('preview');
					}}
					state={loading ? 'loading' : 'active'}
				/>
			</div>
		);
	};

	const renderAuthor = () => {
		if (template?.owner?.isGlobal)
			return (
				<div className={el('createdBy')}>
					<div className={el('logoContainer2')}>
						<img src={logo} alt="logo" />
					</div>
					<span className={el('author')}>Upsiide</span>
				</div>
			);
		return (
			<div className={el('createdBy')}>
				<div className={el('logoContainer')}>
					<img src={logoUser} alt="logo" />
				</div>
				<span className={el('author')}>{template?.owner?.name}</span>
			</div>
		);
	};

	const onAddGroupOnTemplate = async () => {
		setIsLoading(true);
		const createdGroup = await audienceService.createDemographicGroup(audienceTemplate?.studyId, {
			provider: 'LUCID',
			audienceCollectionId: audienceTemplate?.id,
		});
		const responseCollection = await services.audienceService.getCollection(
			audienceTemplate.studyId,
			audienceTemplate?.id,
		);
		if (responseCollection?.data) {
			setTemplate(responseCollection.data);
			dispatch(actions.setAudienceEditTemplate({ loading: false, content: responseCollection?.data }));
			const newGroupIndex =
				responseCollection?.data?.demographicGroups?.findIndex(g => g?.uuid === createdGroup?.data?.uuid) || 0;
			setIndexGroup(newGroupIndex);
			setTemplateModalCurrentAudience(createdGroup?.data?.uuid);
			setIsLoading(false);
			setCurrentTab('demographic');
			setDemographicGroup(responseCollection?.data?.demographicGroups[newGroupIndex]);
		}
		setIsLoading(false);
	};

	const onSave = async () => {
		if (!interlockQuotasData?.length) return;

		try {
			setIsLoading(true);
			await audienceService.postQuotaInterlock(
				demographicGroup.studyId,
				demographicGroup.uuid,
				interlockQuotasData,
			);
			callbackDemographic();
			setIsLoading(false);
			setCurrentTab('demographic');
		} catch (error) {
			setIsLoading(false);
			toastr.error('Error saving interlock quotas');
		}
	};

	useEffect(() => {
		// avoid demographic group out of bounds
		if (indexGroup + 1 > template?.demographicGroups?.length) {
			const newIndex = (template?.demographicGroups?.length || 1) - 1;
			setIndexGroup(newIndex);
		}

		if (template) {
			setSelectedAudienceTemplate(template);
		}
	}, [template]);

	const renderNavigationBack = () => {
		if (currentTab === 'overview') return <div className={el('overview_name')}>Demographic Groups</div>;
		if (currentTab === 'screening_overview')
			return (
				<div className={el('back_container')}>
					<span className={el('back_demographic')}>Screening Questions </span>
				</div>
			);

		if (currentTab === 'screening' && template?.screeningQuestions?.length === 0)
			return (
				<div className={el('back_container')}>
					<span className={el('back_demographic')}>Screening Questions </span>
				</div>
			);

		if (currentTab === 'demographic')
			return (
				<div className={el('back_container')} aria-hidden onClick={() => setCurrentTab('overview')}>
					<img src={GreenArrowBack} alt="" />
					<span className={el('back_button')}>Back</span>
					<span className={el('back_demographic')}>Demographic Groups</span>
					<img src={GrayArrowBack} alt="" className={el('gray_arrow')} />
					<span className={el('demographic_name')}>{renderAudienceName(demographicGroup)}</span>
				</div>
			);

		if (currentTab === 'screening')
			return (
				<div className={el('back_container')} aria-hidden onClick={() => setCurrentTab('screening_overview')}>
					<img src={GreenArrowBack} alt="" />
					<span className={el('back_button')}>Back</span>
					<span className={el('back_demographic')}>Screening Questions</span>
				</div>
			);
		if (currentTab === 'interlock-quotas')
			return (
				<div className={el('back_container')} aria-hidden onClick={() => setCurrentTab('demographic')}>
					<img src={GreenArrowBack} alt="" />
					<span className={el('back_button')}>Back</span>
					<span className={el('back_demographic')}>Interlock quotas</span>
				</div>
			);
	};
	const hasQuotas = useMemo(() => {
		if (!group?.demographicQuestions?.length) return;
		const quotaQuestions = group?.demographicQuestions.filter(({ useQuota }) => useQuota);

		return !!quotaQuestions?.length;
	}, [group?.demographicQuestions]);

	const renderHeaderMenu = () => (
		<div
			ref={subHeaderMenuRef}
			className={el('audience-sub-menu-wrapper')}
			onClick={() => setShowAudienceHeaderMenu(prevState => !prevState)}
			aria-hidden
		>
			<img className={el('audience-title-img')} src={EllipsisIcon} alt="menu" />
			{showAudienceHeaderMenu ? (
				<div className={el('audience-header-menu')}>
					{hasQuotas ? (
						<span
							aria-hidden
							onClick={() => setCurrentTab('interlock-quotas')}
							className={el('audience-sub-menu-item')}
						>
							Interlock quotas
						</span>
					) : (
						<Tooltip
							className={`${el('audience-sub-menu-item')} interlock`}
							theme="dark-blured"
							position="top"
							html={
								<div className={el('interlock-quota-warning')}>
									<span>
										Enable quotas on demographic or screening questions to unlock this feature.
									</span>
								</div>
							}
						>
							<span>Interlock quotas</span>
						</Tooltip>
					)}
					{/* <span aria-hidden className={el('audience-sub-menu-item')}>
						Duplicate
					</span> */}
					<span
						onClick={() => setDeleteConfirmation(true)}
						aria-hidden
						className={el('audience-sub-menu-item')}
						style={{ color: 'red' }}
					>
						Delete
					</span>
				</div>
			) : null}
		</div>
	);

	const onChangeAudienceDescription = async () => {
		try {
			await services.audienceService.updateCollection(audienceTemplate?.studyId, audienceCollectionId, {
				description,
			});
			setSelectedAudienceTemplate({ ...template, description: description.trim() });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className={className}>
			<div className={el('sidebar')}>
				<AudiencePreviewSidebar
					languages={languages}
					selectLanguage={selectedLanguage?.languageLabel}
					onSelectLanguage={setSelectedLanguage}
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
					audienceTemplate={audienceTemplate}
				/>
			</div>
			<div className={el('content')}>
				{renderNavigationBack()}
				{currentTab !== 'interlock-quotas' ? (
					<div className={el('header')}>
						<AudienceTemplateNavigation
							indexGroup={indexGroup}
							setIndexGroup={setIndexGroup}
							template={template}
							disabled={deleteLoading || loading}
							currentTab={currentTab}
							step="edit"
							setSelectedAudienceTemplate={setSelectedAudienceTemplate}
							setTemplateModalCurrentAudience={setTemplateModalCurrentAudience}
						/>
					</div>
				) : null}

				<AudienceTemplateEditContent
					type={currentTab}
					demographicGroup={demographicGroup}
					step={step}
					loading={deleteLoading || loading}
					template={template}
					setTemplate={setTemplate}
					selectedLanguage={selectedLanguage}
					setCurrentTab={setCurrentTab}
					setDemographicGroup={setDemographicGroup}
					onAddGroup={onAddGroupOnTemplate}
					setIndexGroup={setIndexGroup}
					setErrors={setErrors}
					isValid={isValid}
					setLoading={setIsLoading}
					setInterlockQuotasData={setInterlockQuotasData}
					setTemplateModalCurrentAudience={setTemplateModalCurrentAudience}
				/>
			</div>
			<div className={el('footer')}>
				{renderAuthor()}
				{renderErrors()}
				{deleteLoading || loading ? null : renderFooter()}
			</div>
			{renderDeleteTemplate()}
		</div>
	);
};

AudienceTemplateEditor.displayName = 'AudienceTemplateEditor';
AudienceTemplateEditor.propTypes = {
	audienceTemplate: PropTypes.object,
	onSelect: PropTypes.func,
	step: PropTypes.string,
	setStep: PropTypes.func,
	onClose: PropTypes.func,
	setSelectedAudienceTemplate: PropTypes.func,
	currentTab: PropTypes.string,
	setCurrentTab: PropTypes.func,
	selectedLanguage: PropTypes.any,
	languages: PropTypes.array,
	setSelectedLanguage: PropTypes.func,
	templateModalCurrentAudience: PropTypes.string,
	setTemplateModalCurrentAudience: PropTypes.func,
	onDone: PropTypes.func,
};

export default AudienceTemplateEditor;
