import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import cn from 'src/utilities/bem-cn';
import { Iconof } from '@upsiide/ui-components';
import './EllipsisMenu.scss';

const className = 'ellipsis-menu';
const el = name => cn(className, name);

class EllipsisMenu extends React.Component {
	static propTypes = {
		children: PropTypes.any,
		className: PropTypes.string,
		/** Right-aligned dropdown */
		right: PropTypes.bool,
	};

	static defaultProps = {
		right: true,
	};

	constructor(props) {
		super(props);
		this.state = {
			actionsView: false,
		};
	}

	toggleActions = e => {
		const { callback } = this.props;
		e.preventDefault();
		e.stopPropagation();
		if (callback) callback();
		const { actionsView } = this.state;
		this.setState({
			actionsView: !actionsView,
		});
	};

	render() {
		const { actionsView } = this.state;
		const { disabled, children, right, customClass } = this.props;
		const dropdownProps = { end: right };
		return (
			<Dropdown
				className={`${className} ${disabled ? 'disabled' : ''}`}
				isOpen={actionsView}
				toggle={this.toggleActions}
			>
				<DropdownToggle>
					<Iconof size="default" icon="more_vertical" color="grey" className="icon-no-margin" />
				</DropdownToggle>
				<DropdownMenu {...dropdownProps} container={document.body} className={customClass} style={{zIndex: 1001}}>
					{children}
				</DropdownMenu>
			</Dropdown>
		);
	}
}

EllipsisMenu.propTypes = {
	disabled: PropTypes.bool,
	customClass: PropTypes.string,
	callback: PropTypes.func,
};

export default EllipsisMenu;
