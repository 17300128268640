const loadStripe = callback => {
	const existingScript = document.getElementById('stripe-js-v3');

	if (!existingScript) {
		const script = document.createElement('script');
		script.src = 'https://js.stripe.com/v3/';
		script.id = 'stripe-js-v3';
		document.body.appendChild(script);

		script.onload = () => {
			if (callback) callback();
		};
	}

	if (existingScript && callback) callback();
};

export default loadStripe;
