import keyBy from 'lodash/keyBy';
import * as actions from '../actions';

const INITIAL_STATE = {
	study: null,
	products: { content: null, loading: false, error: null },
	languages: null,
	categories: null,
	productTags: null,
	saveButtonText: 'Save',
	saveButtonState: 'active',

	productModal: { content: false, loading: false, error: null },
	importProductsModal: { content: false, loading: false, error: null, showExportMessage: false, visible: false },
	questionModal: { visible: false, content: false, id: null, loading: false, error: null },
	importRespondentsModal: { content: false, loading: false, error: null },
	exportConfirmationModal: { visible: false, filename: null },
	uploadImageStatus: {},

	/* Model Access stuff */
	accessModal: { loading: false, visible: false, mode: null }, // add user/group form
	accessEntries: { loading: false, content: [], error: null }, // the table
	possibleEntries: { loading: false, content: { groups: [], users: [] }, error: null }, // possible entries
	accessCommonError: null, // common error

	/* Audience creation */
	audienceTemplates: { content: false, loading: false, error: null },
	audiencePrice: { content: false, loading: false, error: null },
	BYOPrice: { content: false, loading: false, error: null },

	/* Audience listing */
	audiences: { loading: false, error: null, content: null },
	audience: { loading: false, error: null, content: null },
	audienceCollections: { loading: false, error: null, content: null },
	audienceCollection: { loading: false, error: null, content: null },

	createAudienceModal: { loading: false, visible: false, error: null },
	editAudienceModal: { loading: false, visible: false, error: null },
	launchAudience: { success: null, error: null },
	stopAudienceModal: { loading: false, visible: false, id: null },
	launchAudienceModal: { loading: false, visible: false, id: null, launchStatuses: {} },

	geographies: [],

	// study builder area
	/* Audiences */
	audienceModal: { status: '', visible: false, editContent: null },
	shareStudyModal: { visible: false, audienceUuid: null },

	/* Sections */
	sections: { status: 'loading', content: null },
	currentSection: { status: 'initial', content: null },

	/* Logic */
	itemLogic: [],

	/* Manage Languages */
	manageLanguagesModal: { loading: false, visible: false, error: null, data: null },

	/* Translations */
	translationsModal: { loading: false, visible: false, error: null },
	discardProductChangesModal: { loading: false, visible: false, error: null },
	translationPreview: null,

	/* Section Settings */
	isSavingSectionSettings: false,
	sectionSettingsInsertData: null,

	/* Device preview mode */
	showDevicePreviewMode: false,
	showDevicePreviewIframeBlocker: false,
	devicePreviewMode: 'mobile',
	devicePreviewUrl: '',

	/* Launch with custom qual modal */
	showLaunchWithCustomQualModalForm: false,
	audienceForReview: null,

	/* Idea screen */
	productSectionViewMode: 'CARD_VIEW',

	/* Google Translate Modal */
	showGoogleTranslateModal: { showModal: false },

	/* Country & Language */
	countries: [],
	countryLanguage: [],

	studyValidations: {},
	audienceNotice: null,
	responses: [],
	baseSizeThreshold: process.env.baseSizeThreshold,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_STUDY:
			if (!action.payload.study) {
				return { ...state, study: action.payload.study };
			}

			return { ...state, study: { ...action.payload.study, id: action.payload.study.uuid } };

		case actions.SET_LANGUAGES:
			return { ...state, languages: action.payload.languages };

		case actions.SET_LANGUAGE:
			return {
				...state,
				language: action.payload.language,
				study: { ...state.study, language: action.payload.language },
			};

		case actions.SET_CATEGORIES:
			return { ...state, categories: action.payload.categories };

		case actions.SET_PRODUCT_TAGS:
			return { ...state, productTags: action.payload.productTags };

		case actions.SET_PRODUCTS:
			return { ...state, products: { ...state.products, ...action.payload.products } };

		case actions.STUDY_UPDATED:
			return { ...state, studyUpdated: action.payload };

		case actions.SET_SAVE_BUTTON_TEXT:
			return { ...state, saveButtonText: action.payload.saveButtonText };

		case actions.SET_SAVE_BUTTON_STATE:
			return { ...state, saveButtonState: action.payload.saveButtonState };

		case actions.SET_PRODUCT_MODAL:
			return { ...state, productModal: { ...state.productModal, ...action.payload.productModal } };

		case actions.SET_QUESTION_MODAL:
			return { ...state, questionModal: { ...state.questionModal, ...action.payload.questionModal } };

		case actions.SET_IMPORT_RESPONDENTS_MODAL:
			return {
				...state,
				importRespondentsModal: { ...state.importRespondentsModal, ...action.payload.importRespondentsModal },
			};

		case actions.SET_IMPORT_PRODUCTS_MODAL:
			return {
				...state,
				importProductsModal: { ...state.importProductsModal, ...action.payload.importProductsModal },
			};

		case actions.SET_QUESTIONS:
			return { ...state, questions: { ...state.questions, ...action.payload.questions } };

		case actions.SET_EXPORT_CONFIRMATION_MODAL:
			return {
				...state,
				exportConfirmationModal: {
					...state.exportConfirmationModal,
					...action.payload.exportConfirmationModal,
				},
			};

		case actions.SET_UPLOAD_IMAGE_STATUS:
			return { ...state, uploadImageStatus: { ...action.payload.statuses } };

		case actions.SET_INDIV_UPLOAD_IMAGE_STATUS:
			return {
				...state,
				uploadImageStatus: {
					...state.uploadImageStatus,
					[action.payload.index]: { status: action.payload.status, message: action.payload.message },
				},
			};

		/* Model Access stuff */

		case actions.SET_ACCESS_MODAL:
			return { ...state, accessModal: { ...state.accessModal, ...action.payload.accessModal } };

		case actions.SET_ACCESS_ENTRIES:
			return { ...state, accessEntries: { ...state.accessEntries, ...action.payload.accessEntries } };

		case actions.SET_POSSIBLE_ENTRIES:
			return { ...state, possibleEntries: { ...state.possibleEntries, ...action.payload.possibleEntries } };

		case actions.SET_ACCESS_COMMON_ERROR:
			return { ...state, accessCommonError: { ...state.accessCommonError, ...action.payload.accessCommonError } };

		/**
		 * Audience Stuff
		 */

		case actions.SET_CREATE_AUDIENCE_MODAL:
			return {
				...state,
				createAudienceModal: { ...state.createAudienceModal, ...action.payload.createAudienceModal },
			};

		case actions.SET_AUDIENCES:
			return { ...state, audiences: { ...state.audiences, ...action.payload.audiences } };

		case actions.SET_AUDIENCE_COLLECTIONS:
			return {
				...state,
				audienceCollections: action.payload,
			};

		case actions.SET_AUDIENCE_COLLECTION:
			return {
				...state,
				audienceCollection: action.payload,
			};

		case actions.SET_AUDIENCE:
			return { ...state, audience: { ...state.audience, content: { ...action.payload.audience.content } } };

		case actions.SET_EDIT_AUDIENCE_MODAL:
			return {
				...state,
				editAudienceModal: { ...state.editAudienceModal, ...action.payload.editAudienceModal },
			};

		case actions.SET_STOP_AUDIENCE_MODAL:
			return {
				...state,
				stopAudienceModal: { ...state.stopAudienceModal, ...action.payload.stopAudienceModal },
			};

		case actions.SET_AUDIENCE_TEMPLATES:
			return { ...state, audienceTemplates: { ...state.audienceTemplates, ...action.payload.audienceTemplates } };

		case actions.SET_AUDIENCE_PRICE:
			return { ...state, audiencePrice: action.payload.audiencePrice };

		case actions.SET_BYO_PRICE:
			return { ...state, BYOPrice: action.payload.BYOPrice };

		case actions.SET_LAUNCH_AUDIENCE:
			return { ...state, launchAudience: action.payload.response };

		case actions.SET_LAUNCH_AUDIENCE_MODAL:
			return {
				...state,
				launchAudienceModal: { ...state.launchAudienceModal, ...action.payload.launchAudienceModal },
			};

		case actions.SET_INDIVIDUAL_LAUNCH_STATUS:
			return {
				...state,
				launchAudienceModal: {
					...state.launchAudienceModal,
					launchStatuses: {
						...state.launchAudienceModal.launchStatuses,
						[action.payload.uuid]: action.payload.status,
					},
				},
			};

		case actions.CLEAR_LAUNCH_STATUSES:
			return {
				...state,
				launchAudienceModal: {
					...state.launchAudienceModal,
					launchStatuses: {},
				},
			};

		case actions.SET_GEOGRAPHIES:
			return { ...state, geographies: action.payload.geographies };

		case actions.SET_ROLES:
			return { ...state, roles: { ...state.roles, ...action.payload.roles } };

		case actions.SET_ADD_USER_LOADING:
			return { ...state, addUserLoading: action.payload.isLoading };

		case actions.SET_USERS:
			return { ...state, users: { ...state.users, ...action.payload.users } };

		case actions.SET_AUDIENCE_MODAL:
			return { ...state, audienceModal: { ...state.audienceModal, ...action.payload.audienceModal } };

		case actions.SET_SECTIONS:
			return { ...state, sections: action.payload.sections };

		case actions.SET_SECTION:
			return { ...state, currentSection: { ...state.currentSection, ...action.payload.currentSection } };

		case actions.PATCH_SECTION_NAME:
			return {
				...state,
				sectionNameData: {
					studyId: action.payload.studyId,
					sectionId: action.payload.sectionId,
					statementName: action.payload.statementName,
				},
			};

		case actions.SET_SHARE_STUDY_MODAL:
			return { ...state, shareStudyModal: action.payload.modal };

		case actions.SET_SECTION_QUESTIONS:
			return {
				...state,
				currentSection: {
					...state.currentSection,
					content: { ...state.currentSection.content, questions: action.payload.questions },
				},
			};

		case actions.SET_AUDIENCE_QUESTIONS:
			return {
				...state,
				audience: {
					...state.audience,
					content: { ...state.audience.content, questions: action.payload.questions },
				},
			};

		case actions.PATCH_ITEM:
			return {
				...state,
				...action.payload.itemData,
			};

		case actions.SET_MANAGE_LANGUAGES_MODAL:
			return {
				...state,
				manageLanguagesModal: action.payload,
			};

		case actions.SET_TRANSLATIONS_MODAL:
			return {
				...state,
				translationsModal: action.payload,
			};

		case actions.SET_TRANSLATION_PREVIEW:
			return {
				...state,
				translationPreview: action.payload,
			};

		case actions.SET_DISCARD_PRODUCT_CHANGES_MODAL:
			return {
				...state,
				discardProductChangesModal: action.payload,
			};

		case actions.SET_SAVING_SECTION_SETTINGS:
			return {
				...state,
				isSavingSectionSettings: action.payload.isSavingSectionSettings,
			};

		case actions.SET_SECTION_SETTINGS_INSERT_DATA:
			return {
				...state,
				sectionSettingsInsertData: action.payload.sectionSettingsInsertData,
			};

		case actions.SET_SECTION_SETTINGS:
			return {
				...state,
				currentSection: {
					...state.currentSection,
					content: {
						...state.currentSection.content,
						settings: action.payload.settings,
					},
				},
			};

		case actions.SHOW_DEVICE_PREVIEW_MODE:
			return { ...state, showDevicePreviewMode: action.payload.showDevicePreviewMode };

		case actions.SET_SHOW_DEVICE_PREVIEW_IFRAME_BLOCKER:
			return { ...state, showDevicePreviewIframeBlocker: action.payload.showDevicePreviewIframeBlocker };

		case actions.SET_DEVICE_PREVIEW_MODE:
			return { ...state, devicePreviewMode: action.payload.devicePreviewMode };

		case actions.SET_DEVICE_PREVIEW_URL:
			return { ...state, devicePreviewUrl: action.payload.devicePreviewUrl };

		case actions.SET_SHOW_LAUNCH_WITH_CUSTOM_QUAL_MODAL_FORM:
			return {
				...state,
				...action.payload,
			};

		case actions.SET_PRODUCT_SECTION_VIEW_MODE:
			return {
				...state,
				...action.payload,
			};

		case actions.SET_GOOGLE_TRANSLATE_MODAL:
			return {
				...state,
				showGoogleTranslateModal: action.payload.showGoogleTranslateModal,
			};

		case actions.SET_COUNTRIES:
			return { ...state, countries: action.payload.countries };

		case actions.SET_COUNTRY_LANGUAGES:
			return { ...state, countryLanguages: action.payload.countryLanguages };

		case actions.SET_STUDY_VALIDATIONS: {
			let { translations } = action.payload.validations;
			translations = keyBy(translations, 'id');
			return { ...state, studyValidations: { ...action.payload.validations, translations } };
		}

		case actions.SET_AUDIENCE_NOTICE:
			return {
				...state,
				audienceNotice: action.payload.notice,
			};

		case actions.CLEAR_AUDIENCE_NOTICE:
			return {
				...state,
				audienceNotice: null,
			};

		case actions.SET_RESPONSES: {
			const { responses } = action.payload;
			return {
				...state,
				responses,
			};
		}
		case actions.SET_BASE_SIZE_THRESHOLD:
			return { ...state, baseSizeThreshold: action.payload.baseSizeThreshold };

		case actions.SET_AUDIENCE_NORMS:
			return {
				...state,
				currentSection: {
					...state.currentSection,
					content: {
						...state.currentSection.content,
						audienceNorms: action.payload.audienceNorms,
					},
				},
			};

		default:
			return state;
	}
};
