const prefix = 'account-details';

export const SET_LOADING = `${prefix}/SET_LOADING`;
export const setLoading = loading => ({ type: SET_LOADING, payload: { loading } });

export const SET_UUID = `${prefix}/SET_UUID`;
export const setUuid = uuid => ({ type: SET_UUID, payload: { uuid } });

export const COMPLETE_ACCOUNT_CREATION = `${prefix}/COMPLETE_ACCOUNT_CREATION`;
export const completeAccountCreation = accountDetails => ({
	type: COMPLETE_ACCOUNT_CREATION,
	payload: accountDetails,
});
