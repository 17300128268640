import React, { useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import { element } from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'gallery-search-bar';
const el = element(className);

const GallerySearchBar = ({ value, setValue, onSearch, placeholder, isSearching }) => {
	const valueRef = useRef();
	const timeoutRef = useRef();
	const showClearIcon = useMemo(() => value !== '', [value]);

	const handleSearch = useCallback(
		newVal => {
			if (onSearch) {
				onSearch(newVal);
			}
		},
		[onSearch],
	);

	const handleOnChange = useCallback(
		e => {
			const newVal = e.target.value;
			valueRef.current = newVal;
			setValue(newVal);
			isSearching(true);

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				handleSearch(newVal);
				isSearching(false);
			}, 1000);
		},
		[handleSearch, isSearching, setValue],
	);

	const handleClearSearch = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();

			valueRef.current = '';
			setValue('');

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			handleSearch('');
		},
		[handleSearch, setValue],
	);

	return (
		<div className={className}>
			<input
				autoComplete="off"
				className={el(`input ${showClearIcon ? 'extraPadding' : ''}`)}
				name="gallery-search"
				value={value}
				onChange={handleOnChange}
				placeholder={placeholder}
			/>
			<div className={el('icon-wrapper')}>
				{showClearIcon && (
					<Iconof
						size="large"
						icon="clear"
						className={el('clear-icon')}
						onClick={e => handleClearSearch(e)}
					/>
				)}

				<Iconof size="large" icon="search" className={el('search-icon')} />
			</div>
		</div>
	);
};

GallerySearchBar.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	onSearch: PropTypes.func,
	placeholder: PropTypes.string,
	isSearching: PropTypes.func,
};

export default GallerySearchBar;
