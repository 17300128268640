import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'loader-0';
const el = name => cn(className, name);

const CenteredLoader = ({ small }) => (
	<div className={el('centered')}>
		<div className={`${className} ${small ? el('small') : ''}`} />
	</div>
);

CenteredLoader.propTypes = {
	small: PropTypes.bool,
};
CenteredLoader.defaultProps = {
	small: false,
};

const Loader = ({ centered, small }) =>
	centered ? <CenteredLoader small={small} /> : <div className={`${className} ${small ? el('small') : ''}`} />;

Loader.propTypes = {
	/** Whether to center the loader or not. */
	centered: PropTypes.bool,
	small: PropTypes.bool,
};
Loader.defaultProps = {
	centered: false,
	small: false,
};

export default Loader;
