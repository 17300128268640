// TODO - Button Icon sizing styles

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import './Button.scss';

const Button = ({
	type,
	htmlType,
	label,
	className,
	icon,
	iconSize,
	iconPosition,
	state,
	onClick,
	iconClasses,
	areaLabel,
	children,
}) => {
	const handleClick = useCallback(
		e => {
			if (state === 'active' && onClick != null) {
				onClick(e);
			}
		},
		[state, onClick],
	);

	const createClassName = useMemo(() => {
		if (children) {
			return [type, state, className, icon ? `icon-${iconPosition}` : ''].join(' ').trim();
		}

		return ['button', type, state, className, icon ? `icon-${iconPosition}` : ''].join(' ').trim();
	}, [children, type, state, className, icon, iconPosition]);

	const renderContent = useCallback(() => {
		if (children) {
			return children;
		}

		const iconContent = icon ? <Iconof icon={icon} size={iconSize} className={iconClasses} /> : '';

		return iconPosition === 'left' ? (
			<span className="button__content">
				{iconContent}
				{label || ''}
			</span>
		) : (
			<span className="button__content">
				{label || ''}
				{iconContent}
			</span>
		);
	}, [children, icon, iconClasses, iconPosition, iconSize, label]);

	return (
		// eslint-disable-next-line react/button-has-type
		<button type={htmlType} className={createClassName} onClick={handleClick} aria-labelledby={areaLabel}>
			{renderContent()}
		</button>
	);
};

Button.propTypes = {
	type: PropTypes.oneOf([
		'default',
		'focused',
		'secondary',
		'circular',
		'alert',
		'text',
		'green',
		'red',
		'button',
		'custom',
	]),
	htmlType: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
	icon: PropTypes.string,
	iconSize: PropTypes.string,
	iconPosition: PropTypes.oneOf(['left', 'right']),
	state: PropTypes.oneOf(['active', 'loading', 'disabled']),
	onClick: PropTypes.func,
	iconClasses: PropTypes.string,
	areaLabel: PropTypes.string,
	children: PropTypes.any,
};

Button.defaultProps = {
	label: '',
	type: 'default',
	htmlType: 'button',
	state: 'active',
	iconSize: 'default',
	iconPosition: 'left',
	iconClasses: '',
};

export default Button;
