import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import Cookies from 'src/utilities/cookies';
import Button from 'src/components/elements/Button';
import * as actions from 'src/domains/manage-study/actions';
import './styles.scss';

const className = 'amp-header-error';
const el = (name, mod) => cn(className, name, mod);

const AMPHeaderError = ({
	icon,
	errorTitle,
	errorMessage,
	buttonIcon = '',
	buttonText = '',
	buttonLink = '',
	buttonLogic = null,
	buttonType = false,
	priceChange = false,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const clearPriceChanged = () => {
		dispatch(actions.setAudiencePriceChangedInitial());
	};
	return (
		<div className={`${className} ${icon}`}>
			<div className={el('left')}>
				<div className={`${el(`${icon}-icon-container`)}`}>
					<Iconof icon="warning" className={el(`${icon}-icon`)} />
				</div>
				<div className={el('text-container')}>
					<div className={el('title-container')}>{errorTitle}</div>
					<div className={el('message-container')}>{errorMessage}</div>
				</div>
			</div>
			{buttonText && (buttonLink || buttonLogic) && (
				<Button
					className={buttonText === 'Resolve' || buttonType === 'error' ? el('button') : ''}
					label={buttonText}
					icon={buttonIcon}
					onClick={() => (buttonLogic ? buttonLogic() : navigate(buttonLink))}
				/>
			)}
			{priceChange && (
				<div className={el('clear-price-change')}>
					<Iconof
						icon="clear"
						className={el('clear-icon')}
						onClick={
							buttonLogic
								? () => {
										buttonLogic();
								  }
								: () => clearPriceChanged()
						}
					/>
				</div>
			)}
		</div>
	);
};

AMPHeaderError.propTypes = {
	icon: PropTypes.oneOf(['error', 'warning']),
	errorTitle: PropTypes.any,
	errorMessage: PropTypes.any,
	buttonIcon: PropTypes.string,
	buttonText: PropTypes.string,
	buttonLink: PropTypes.string,
	buttonLogic: PropTypes.func,
	buttonType: PropTypes.string,
	priceChange: PropTypes.bool,
};

export default AMPHeaderError;
