// packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// utilities
import cn from 'src/utilities/bem-cn';

// components
import DetatchedScreenerModal from 'src/components/shared/DetatchedScreenerModal';

// styles
import './styles.scss';

const className = 'additional-settings';
const el = (name, mod) => cn(className, name, mod);

const AdditionalQuestionOptionSettings = ({ question, setLoading, changeSetting }) => {
	// state
	const [showDetatchedModal, setShowDetatchedModal] = useState(false);

	// life cycle
	useEffect(() => {
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question]);

	return (
		<div className={className}>
			{question.style !== 'open-ended' && question.style !== 'emoji' && question.style !== 'ranked' && (
				<>
					{!(question && question.hasNoneOfTheAboveOption) && (
						<button
							type="button"
							tabIndex={0}
							className={`${el('item')} ${el('nota-item')}`}
							onClick={() => {
								if (question?.parentId) {
									setShowDetatchedModal('hasNoneOfTheAboveOption');
								} else {
									changeSetting('hasNoneOfTheAboveOption');
								}
							}}
							onKeyDown={() => {
								if (question?.parentId) {
									setShowDetatchedModal('hasNoneOfTheAboveOption');
								} else {
									changeSetting('hasNoneOfTheAboveOption');
								}
							}}
						>
							<span className={el('item-label')}>Add "None of the above"</span>
						</button>
					)}

					{!(question && question.hasOtherSpecifyOption) && (
						<button
							type="button"
							tabIndex={0}
							className={`${el('item')} ${el('other-item')}`}
							onClick={() => {
								if (question?.parentId) {
									setShowDetatchedModal('hasOtherSpecifyOption');
								} else {
									changeSetting('hasOtherSpecifyOption');
								}
							}}
							onKeyDown={() => {
								if (question?.parentId) {
									setShowDetatchedModal('hasOtherSpecifyOption');
								} else {
									changeSetting('hasOtherSpecifyOption');
								}
							}}
						>
							<span className={el('item-label')}>Add "Other (please specify)"</span>
						</button>
					)}
				</>
			)}

			<DetatchedScreenerModal
				show={showDetatchedModal}
				onConfirm={() => {
					changeSetting(showDetatchedModal);
					setShowDetatchedModal(false);
				}}
				onClose={() => {
					setShowDetatchedModal(false);
				}}
			/>
		</div>
	);
};

AdditionalQuestionOptionSettings.propTypes = {
	question: PropTypes.object,
	setLoading: PropTypes.func,
	changeSetting: PropTypes.func,
};

export default AdditionalQuestionOptionSettings;
