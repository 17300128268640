/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';
import { isEmpty } from 'lodash';
import { Tooltip } from 'react-tippy';
import Tag from 'src/components/elements/Tag';
import { Iconof } from '@upsiide/ui-components';
import * as misc from 'src/utilities/misc';
import QuotaIcon from 'public/images/audience/quota-icon.svg';
import InterlockQuotasIcon from 'public/images/audience/interlock-quotas-icon.png';
import { Link } from 'react-router-dom';
import { TemplateCardsContext } from 'src/components/shared/TemplateCard/context';
import { shouldShowMeanScoreScale } from 'src/components/helpers';

const className = 'question-settings-tabs';
const el = (name, mod) => cn(className, name, mod);

const TABS = [
	{ label: 'Preview', gridLabel: 'Grid', emojiLabel: 'Emoji React', heatmapLabel: 'Heatmap', name: 'preview' },
	{ label: 'Logic', name: 'logic' },
];

const QuestionSettingsTabs = ({
	activeTab,
	setActiveTab,
	question,
	setActiveQuestion,
	audience,
	hasQuotas,
	hasInterlockedQuotas,
	updateAllScreeningOptionQuota,
	audienceCollection,
	viewMode = false,
	canChangeQuotaOptions = false,
	quotaError = '',
}) => {
	const { templateModalData } = useContext(TemplateCardsContext);

	const showScale = useMemo(() => shouldShowMeanScoreScale(question), [question]);

	const getTabs = () => TABS;
	const [showAudienceSubMenu, setShowAudienceSubMenu] = useState(false);
	const [disqualifyOption, setDisqualifyOption] = useState([]);

	const terminateList = useMemo(() => {
		const notTerminateLogic = question.logic.filter(l => l?.operator === 'is_not' && l?.actionType === 'terminate');
		const terminateLogic = question.logic.filter(l => l?.operator !== 'is_not' && l?.actionType === 'terminate');

		const isNotTriggerOptionsId = [
			...new Set(notTerminateLogic.map(l => l?.triggerOptions?.map(({ id }) => id)).flat(Infinity)),
		];

		if (isNotTriggerOptionsId.length) {
			return question?.options?.filter(opt => !isNotTriggerOptionsId.includes(opt?.id)).map(({ id }) => id);
		}
		const terminateTriggerOptionsId = [
			...new Set(terminateLogic.map(l => l?.triggerOptions?.map(({ id }) => id)).flat(Infinity)),
		];
		return terminateTriggerOptionsId;
	}, [question]);

	const hasIndistinctiveLogic = useMemo(() => {
		const isMultiSelect = question?.settings?.find(setting => setting?.label === 'multi-select')?.value === 'true';

		// if has one  is_not and its multi select - "Some options disqualify"
		if (isMultiSelect && question.logic.some(l => l?.operator === 'is_not' && l?.actionType === 'terminate'))
			return true;

		return question.logic.filter(
			l => l?.operator === 'is' && l?.operand === 'and' && l?.triggerOptions?.length >= 2,
		)?.length;
	}, [question.logic, question.settings]);

	const hasInvalidSingleLogic = useMemo(() => {
		const isMultiSelect = question?.settings?.find(setting => setting?.label === 'multi-select')?.value === 'true';

		// if has is_not + AND and it is single select - "invalid logic disqualify"
		return (
			!isMultiSelect &&
			question?.logic?.some(
				l =>
					l?.operator === 'is_not' &&
					l?.operand === 'and' &&
					l?.actionType === 'terminate' &&
					l?.triggerOptions.length > 1,
			)
		);
	}, [question.logic, question.settings]);

	useEffect(() => {
		setDisqualifyOption(terminateList);
	}, [terminateList]);

	const quotasSum = useMemo(() => {
		if (!question.options?.length) return;

		const optionsIdsInLogic = question.logic
			.filter(l => l?.actionType === 'terminate')
			.map(l => l.triggerOptions.map(trigger => trigger?.id))
			.flat();
		const optionsWithoutLogic = question?.options?.filter(option => !optionsIdsInLogic.includes(option.id));

		const totalQuota = optionsWithoutLogic
			.filter(option => option.quotaMax != null && option.quotaMax !== '')
			.map(option => Number(option.quotaMax))
			.reduce((quota, sum) => quota + sum, 0);

		return Math.ceil(totalQuota);
	}, [question.logic, question.options]);

	const hasQuotaBellow100 = useMemo(() => {
		if (quotasSum > 100) return;
		const optionsIdsInLogic = question.logic.map(l => l.triggerOptions.map(trigger => trigger?.id)).flat();
		const optionsWithoutLogic = question?.options?.filter(option => !optionsIdsInLogic.includes(option.id));

		if (!optionsWithoutLogic?.length) return;

		const quotas = optionsWithoutLogic?.map(option => option?.quotaMax);
		const hasEmptyQuotas = quotas.filter(quota => quota == null);
		return !hasEmptyQuotas?.length && quotasSum < 100;
	}, [quotasSum, question.logic, question?.options]);

	const hasZeroQuotas = useMemo(() => {
		if (!question?.options?.length) return;
		const useQuota = !!question?.options?.find(option => option?.quotaMax != null);
		if (!useQuota) return;
		const quotas = question?.options?.map(option => option?.quotaMax);
		return quotas.includes(0);
	}, [question]);

	const hasIndividualInvalid = useCallback(() => {
		if (!question?.options?.length) return;
		const useQuota = !!question?.options?.find(option => option?.quotaMax != null);
		if (!useQuota) return;

		const quotas = question?.options?.some(option => option?.quotaMax > 100);
		return !!quotas;
	}, [question]);

	const isInterlockedWithInvalidSampleSize = useMemo(() => {
		const weblinksWithInvalidSampleSize = question.interlockedWithinGroup?.find(
			group => !group.provider === 'BYO' && !group.sampleSize,
		);
		return weblinksWithInvalidSampleSize;
	}, [question]);

	const partialAbove = useMemo(() => {
		if (!question?.options?.length) return;
		const useQuota = !!question?.options?.find(option => option?.quota?.[0]?.quotaMax != null);
		if (!useQuota) return;

		const quotas = question?.options?.map(option => option?.quota?.[0]?.quotaMax);
		const hasEmptyQuotas = quotas.includes(null);
		return hasEmptyQuotas && quotasSum > 100;
	}, [quotasSum, question?.options]);

	const quotaCannotBeZero = useCallback(() => {
		if (!question?.options?.length) return;
		if (!question?.useQuota) return;

		const isDqOption = option => {
			if (hasInvalidSingleLogic || terminateList.includes(option.id)) {
				return true;
			}
			return false;
		};

		const quotas = question?.options?.filter(option => !isDqOption(option))?.map(option => option?.quotaMax);
		const hasEmptyQuotas = quotas.includes(null) || quotas.includes(undefined);
		return hasEmptyQuotas && quotasSum >= 100;
	}, [quotasSum, question?.options, question?.useQuota, hasInvalidSingleLogic, terminateList]);

	const quotaWarning = useCallback(() => {
		// quotaError is set locally in QuestionOption component when quota is invalid (backend sends the error msg)
		if (quotaError && quotaError !== 'ok')
			return (
				<div className={el('interlock-quota-warning')}>
					<p>
						<Iconof icon="warning" />
						{quotaError}
					</p>
				</div>
			);
		const zeroQuotaIssue = quotaCannotBeZero();
		const individualInvalidQuota = hasIndividualInvalid();
		let errorMessage = '';
		if (hasZeroQuotas) {
			errorMessage = 'Quotas cannot be zero';
		} else if (hasQuotaBellow100) {
			errorMessage = 'Quotas are lower than 100%';
		} else if (partialAbove) {
			errorMessage = 'Quotas cannot be zero';
		} else if (isInterlockedWithInvalidSampleSize) {
			errorMessage = `The audience group ${isInterlockedWithInvalidSampleSize.name} has an invalid sample size.`;
		} else if (zeroQuotaIssue) {
			errorMessage = 'Quotas cannot be zero';
		} else if (individualInvalidQuota) {
			errorMessage = 'Some quota values are invalid';
		}

		if (!errorMessage) return;

		return (
			<div className={el('interlock-quota-warning')}>
				<p>
					<Iconof icon="warning" />
					{errorMessage}
				</p>
			</div>
		);
	}, [
		hasQuotaBellow100,
		hasZeroQuotas,
		isInterlockedWithInvalidSampleSize,
		quotaCannotBeZero,
		partialAbove,
		hasIndividualInvalid,
		quotaError,
	]);

	const renderSoftQuotaTag = value => {
		if (!value) return;
		const finalQuota = Math.ceil(Number(value))?.toFixed(2);
		if (finalQuota <= 100) return;

		return (
			<Tooltip
				className={el('soft')}
				theme="dark-blured soft-quota"
				position="top"
				delay={[0, 1000]}
				interactive
				html={
					<div className={el('soft-quota-warning')}>
						When quota exceeds 100%, <b> soft quotas </b> will apply. Each quota will be treated as a max
						value.
						<a href="https://support.upsiide.com/en/articles/5192123" target="blank">
							{'Learn more ->'}
						</a>
					</div>
				}
			>
				<Tag className="soft-quota" label="Soft" />
			</Tooltip>
		);
	};

	const renderPartialTag = () => {
		if (quotasSum >= 100) return;
		const optionsIdsInLogic = question.logic.map(l => l.triggerOptions.map(trigger => trigger?.id)).flat();
		const optionsWithoutLogic = question?.options?.filter(option => !optionsIdsInLogic.includes(option.id));

		if (!optionsWithoutLogic?.length) return;

		const quotas = optionsWithoutLogic?.map(option => option?.quotaMax);
		const nullQuotas = quotas.filter(quota => quota == null);
		const notEmptyQuotas = quotas.filter(quota => quota != null);

		if (!nullQuotas?.length || !notEmptyQuotas?.length) return;

		return (
			<Tooltip
				className={el('soft')}
				theme="dark-blured"
				position="top"
				delay={[0, 1000]}
				interactive
				html={
					<div className={el('soft-quota-warning')}>
						When total entered quotas are below 100%,<b> partial quotas </b> will apply. All answer options
						without a quota value will be grouped together in one quota.
						<a
							href="https://support.upsiide.com/en/articles/5192123-setting-up-quotas#h_c4bcb26595"
							target="blank"
						>
							{'Learn more ->'}
						</a>
					</div>
				}
			>
				<Tag className="partial" label="Partial" />
			</Tooltip>
		);
	};

	const parseQuota = (a, b) => Number((a / b).toFixed(2));

	const distributeQuotasEvenly = useCallback(() => {
		if (!question?.logic?.length) {
			const changedOptions = question.options
				.filter(option => !`${option?.id}`.includes('temp'))
				.map(option => ({
					...option,
					quota: parseQuota(100, question.options.length),
				}));
			updateAllScreeningOptionQuota(question.id, changedOptions);
			return;
		}
		const optionsIdsInLogic = question.logic
			.filter(l => l?.actionType === 'terminate')
			.map(l => l.triggerOptions.map(trigger => trigger?.id))
			.flat();
		const optionsWithoutLogic = question?.options?.filter(option => !optionsIdsInLogic.includes(option.id));

		const changedOptionsLogic = question.options
			.filter(option => !`${option?.id}`.includes('temp'))
			.map(option => ({
				...option,
				...(optionsIdsInLogic?.includes(option.id)
					? { quota: null }
					: { quota: parseQuota(100, optionsWithoutLogic.length) }),
			}));
		updateAllScreeningOptionQuota(question.id, changedOptionsLogic);
	}, [updateAllScreeningOptionQuota, question]);

	const renderInterlockQuotasMenu = () =>
		question.interlockedWithinGroup?.map(({ uuid }) => {
			const groups = templateModalData?.selectedTemplate
				? templateModalData?.selectedTemplate?.demographicGroups
				: audienceCollection?.content?.demographicGroups;
			const demographicGroup = groups?.find(group => group.uuid === uuid);
			let groupName = demographicGroup?.name;
			if (demographicGroup?.provider === 'LUCID') {
				groupName = misc.renderAudienceName(demographicGroup);
			} else if (!demographicGroup?.name || demographicGroup?.name === 'Web Link') {
				const languageLabel = demographicGroup?.countryLanguage?.languageLabel
					? `- ${demographicGroup?.countryLanguage?.languageLabel}`
					: '';
				groupName = `Web Link${languageLabel}`;
			}

			return (
				<Tooltip
					className={el('audience-sub-menu-item')}
					theme="dark-blured"
					position="left"
					html={
						<div className="interlock-quota-warning">
							<p>Editing your quotas here will</p>
							<p>edit your interlocked quotas</p>
							<p>as well</p>
						</div>
					}
				>
					<Link to={`../groups/${uuid}/interlock-quotas`}>
						<span>{groupName}</span>
					</Link>
				</Tooltip>
			);
		});

	const renderDisqualify = () => {
		if (hasInvalidSingleLogic) return <span className={el('disqualify-alert')}> All options disqualify</span>;
		if (hasIndistinctiveLogic) return <span className={el('disqualify-info')}> Some options disqualify</span>;
		if (disqualifyOption.length === question?.options?.length)
			return <span className={el('disqualify-alert')}> All options disqualify</span>;
		if (disqualifyOption.length)
			return <span className={el('disqualify-info')}>{disqualifyOption.length} options disqualify</span>;
		return <span className={el('disqualify-info')}>All options qualify</span>;
	};

	const renderQuotaContent = () => {
		if (!hasInterlockedQuotas && canChangeQuotaOptions) {
			return (
				<>
					{quotaWarning() ||
						(disqualifyOption.length === question?.options?.length ? null : (
							<span className={el('quota-info')}>
								{disqualifyOption.length ? '|  ' : ''}Hover on an option to edit quota
							</span>
						))}
					<Tooltip
						theme="dark-blured"
						position="top"
						html={
							<div className="interlock-quota-warning">
								<p>Distribute evenly</p>
							</div>
						}
					>
						<img
							aria-hidden
							onClick={distributeQuotasEvenly}
							className={el('quotas-icon')}
							src={QuotaIcon}
							alt="Quotas"
						/>
					</Tooltip>
				</>
			);
		}
		return (
			<>
				{quotaWarning() || <span className={el('interlock-quota-label')}>Interlocked quotas</span>}
				<div
					onMouseEnter={() => setShowAudienceSubMenu(true)}
					onMouseLeave={() => setShowAudienceSubMenu(false)}
					className={el('audience-sub-menu-wrapper')}
				>
					{question.interlockedWithinGroup?.length ? question.interlockedWithinGroup?.length : null}
					<img
						aria-hidden
						className={el('interlock-quotas-icon')}
						src={InterlockQuotasIcon}
						alt="Interlock Quotas"
					/>
					<div
						className={`${el('audience-sub-menu')} ${
							question.interlockedWithinGroup?.length && showAudienceSubMenu ? 'show' : ''
						}`}
					>
						{renderInterlockQuotasMenu()}
					</div>
				</div>
			</>
		);
	};

	return (
		<div className={className}>
			<div className={audience ? el('audience_tabs', 'show') : el('tabs', 'show')}>
				{showScale && question?.style !== 'grid' && <div className={el('scale-column-title')}>Scale</div>}

				{getTabs().map((tab, tabIndex) => (
					<button
						key={tabIndex}
						type="button"
						className={`${activeTab === tab.name ? 'active' : ''}${
							tab.name === 'logic' ? ` ${!isEmpty(question.logic) && 'logic-applied'}` : ''
						}`}
						onClick={() => {
							setActiveTab(tab.name);
							setActiveQuestion(question.id);
						}}
					>
						{tabIndex === 0 && question.style === 'grid'
							? tab.gridLabel
							: tabIndex === 0 && question.style === 'emoji'
							? tab.emojiLabel
							: tab.label}
					</button>
				))}

				{audience ? renderDisqualify() : null}

				{audience &&
				question?.style !== 'grid' &&
				question?.style !== 'ranked' &&
				question?.settings?.find(s => s.label === 'multi-select')?.value !== 'true' ? (
					<div className={el('quota_container')} style={{ marginLeft: '8px' }}>
						<div className={el('quota')}>
							{renderQuotaContent()}
							{hasQuotas ? (
								<>
									Quota
									<span className={el('quota-sum')}>{quotasSum}%</span>
									{renderSoftQuotaTag(quotasSum) || renderPartialTag()}
								</>
							) : null}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};
QuestionSettingsTabs.propTypes = {
	activeTab: PropTypes.any,
	setActiveTab: PropTypes.func,
	question: PropTypes.any,
	setActiveQuestion: PropTypes.func,
	viewMode: PropTypes.bool,
	hasInterlockedQuotas: PropTypes.bool,
	updateAllScreeningOptionQuota: PropTypes.func,
	canChangeQuotaOptions: PropTypes.bool,
	hasQuotas: PropTypes.bool,
	audience: PropTypes.object,
	audienceCollection: PropTypes.object,
	quotaError: PropTypes.string,
};

export default QuestionSettingsTabs;
