import React, { useCallback } from 'react';
import cn from 'src/utilities/bem-cn';
import CONSTANTS from 'src/config/constants';
import PropTypes from 'prop-types';
import { calculateTotalOptions } from 'src/components/helpers';
import LogicSelect from '../LogicSelect';
import { useLogicConditionContext } from '../../LogicConditionContext';

import '../../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);
const SELECT_N_LIMIT = CONSTANTS.rankingLimit;

const LogicRankSelect = ({ setParentRefZIndex, index: answersPositionIndex, placeholder }) => {
	const {
		setShowLoader,
		sections,
		saveData,
		logicItem,
		saveLogic,
		isAudienceLogic,
		audience,
		selectedItem,
		answerOptions,
	} = useLogicConditionContext();

	const answerValue = logicItem?.triggerOptions[answersPositionIndex]?.id || logicItem?.triggerOptions?.[0]?.id;
	const matchedTriggerItem = logicItem?.triggerOptions?.find(item => item.id === answerValue);

	const totalOptions = [...new Set(calculateTotalOptions(selectedItem?.data?.options, sections))].length;
	const topNDropdownOptions = [];
	const maxTopNOptions = totalOptions > SELECT_N_LIMIT ? SELECT_N_LIMIT : totalOptions;
	for (let i = 1; i <= maxTopNOptions; i++) {
		topNDropdownOptions.push({ value: i, label: i, chipLabel: i });
	}

	const rankSelectOnChange = useCallback(
		rank => {
			setShowLoader(true);

			// // update trigger options with new data
			let foundOptionToUpdate = false;
			const itemData = answerOptions?.find(answer => answer.id === answerValue);
			const savedTriggerOptions = logicItem?.triggerOptions?.map(item => {
				if (item.id === answerValue) {
					foundOptionToUpdate = true;
				}

				return {
					...item,
					rank,
				};
			});

			// // Nothing to update, must be adding a new option
			if (!foundOptionToUpdate) {
				savedTriggerOptions.push({
					...itemData,
					rank,
				});
			}

			const saveDataClone = {
				...saveData,
				logic: saveData?.logic?.map((item, itemIndex) => {
					if (item?.id === logicItem?.id) {
						return {
							...item,
							id: logicItem.id,
							triggerItemId: selectedItem?.data?.itemId,
							triggerOptions: savedTriggerOptions,
							...(isAudienceLogic
								? {
										audienceUuid: audience?.uuid,
										itemType: 'question',
								  }
								: {
										triggerType:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].type,
										triggerSectionId:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].id,
								  }),
						};
					}

					return item;
				}),
			};

			saveLogic(saveDataClone);
		},
		[
			setShowLoader,
			logicItem?.triggerOptions,
			logicItem.id,
			saveData,
			saveLogic,
			selectedItem,
			sections.content,
			isAudienceLogic,
			audience?.uuid,
			answerOptions,
			answerValue,
		],
	);

	return (
		<LogicSelect
			columnWidth={2}
			selectClassName="rank"
			options={topNDropdownOptions}
			onChange={rankSelectOnChange}
			menuMinWidth={40}
			value={matchedTriggerItem?.rank}
			placeholder={placeholder}
			setParentRefZIndex={setParentRefZIndex}
		/>
	);
};

LogicRankSelect.propTypes = {
	setParentRefZIndex: PropTypes.any,
	index: PropTypes.any,
	placeholder: PropTypes.string,
};

export default LogicRankSelect;
