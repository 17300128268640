const prefix = 'all-studies';

export const SET_CLIENTS = `${prefix}/SET_CLIENTS`;
export const setClients = clients => ({ type: SET_CLIENTS, payload: { clients } });

export const SET_STUDIES = `${prefix}/SET_STUDIES`;
export const setStudies = studies => ({
	type: SET_STUDIES,
	payload: { studies },
});

export const SET_STUDY_CATEGORIES = `${prefix}/SET_STUDY_CATEGORIES`;
export const setStudyCategories = studyCategories => ({
	type: SET_STUDY_CATEGORIES,
	payload: { studyCategories },
});

export const SET_SEARCH_QUERY = `${prefix}/SET_SEARCH_QUERY`;
export const setSearchQuery = (searchQuery, initialLoad = false) => ({
	type: SET_SEARCH_QUERY,
	payload: { searchQuery, initialLoad },
});

export const SET_STUDY_FILTERS = `${prefix}/SET_STUDY_FILTERS`;
export const setStudyFilters = (studyFilters, initialLoad = false) => ({
	type: SET_STUDY_FILTERS,
	payload: { studyFilters, initialLoad },
});

export const SET_ERROR = `${prefix}/SET_ERROR`;
export const setError = error => ({ type: SET_ERROR, payload: { error } });

export const FETCH_STUDIES = `${prefix}/FETCH_STUDIES`;
export const fetchStudies = args => ({
	type: FETCH_STUDIES,
	payload: {
		dropPagination: args ? args.dropPagination : false,
		accountUuids: args ? args.accountUuids : null,
		orderBy: args ? args.orderBy : null,
		orderByDirection: args ? args.orderByDirection : null,
		filter: args ? args.filter : null,
		isPublic: args ? args.isPublic : false,
	},
});

export const MAKE_INTO_BLUEPRINT_OR_DUPLICATE_STUDY = `${prefix}/MAKE_INTO_BLUEPRINT_OR_DUPLICATE_STUDY`;
export const makeIntoBlueprintOrDuplicateStudy = (
	studyId,
	clientId,
	blueprintAccessLevel = 'space',
	studyDuplication = false,
	domain = null,
) => ({
	type: MAKE_INTO_BLUEPRINT_OR_DUPLICATE_STUDY,
	payload: { studyId, clientId, blueprintAccessLevel, studyDuplication, domain },
});

export const SET_VIEW_MODE = `${prefix}/SET_VIEW_MODE`;
export const setViewMode = viewMode => ({ type: SET_VIEW_MODE, payload: { viewMode } });

export const SET_SHOW_MOVE_STUDY_MODAL = `${prefix}/SET_SHOW_MOVE_STUDY_MODAL`;
export const setShowMoveStudyModal = showMoveStudyModal => ({
	type: SET_SHOW_MOVE_STUDY_MODAL,
	payload: { showMoveStudyModal },
});

export const FETCH_CLIENTS_FROM_ACCOUNT = `${prefix}/FETCH_CLIENTS_FROM_ACCOUNT`;
export const fetchClientsFromAccount = (accountUuid, isPublic) => ({
	type: FETCH_CLIENTS_FROM_ACCOUNT,
	payload: { accountUuid, isPublic },
});

export const SET_CLIENTS_FROM_ACCOUNT = `${prefix}/SET_CLIENTS_FROM_ACCOUNT`;
export const setClientsFromAccount = clientsFromAccount => ({
	type: SET_CLIENTS_FROM_ACCOUNT,
	payload: { clientsFromAccount },
});

export const MOVE_STUDY = `${prefix}/MOVE_STUDY`;
export const moveStudy = (studyId, previousClientUuid, newClientUuid) => ({
	type: MOVE_STUDY,
	payload: { studyId, previousClientUuid, newClientUuid },
});

export const PERFORM_INITIAL_FETCH = `${prefix}/PERFORM_INITIAL_FETCH`;
export const performInitialFetch = ({ isPublic = false }) => ({ type: PERFORM_INITIAL_FETCH, payload: { isPublic } });

export const FETCH_CLIENTS = `${prefix}/FETCH_CLIENTS`;
export const fetchClients = () => ({ type: FETCH_CLIENTS });

export const SET_LANGUAGES = `${prefix}/SET_LANGUAGES`;
export const setLanguages = languages => ({ type: SET_LANGUAGES, payload: { languages } });

export const UPSERT_COLLECTIONS = `${prefix}/UPSERT_COLLECTIONS`;
export const upsertCollections = collections => ({ type: UPSERT_COLLECTIONS, payload: { collections } });

export const UPSERT_STUDY_TAGS = `${prefix}/UPSERT_STUDY_TAGS`;
export const upsertStudyTags = studyTags => ({ type: UPSERT_STUDY_TAGS, payload: { studyTags } });

export const UPSERT_PRODUCT_TAGS = `${prefix}/UPSERT_PRODUCT_TAGS`;
export const upsertProductTags = productTags => ({ type: UPSERT_PRODUCT_TAGS, payload: { productTags } });

export const FETCH_LANGUAGES = `${prefix}/FETCH_LANGUAGES`;
export const fetchLanguages = isPublic => ({ type: FETCH_LANGUAGES, payload: { isPublic } });

export const INC_LOADING = `${prefix}/INC_LOADING`;
export const incLoading = () => ({ type: INC_LOADING });

export const DEC_LOADING = `${prefix}/DEC_LOADING`;
export const decLoading = () => ({ type: DEC_LOADING });

export const FETCH_GEOGRAPHIES = `${prefix}/FETCH_GEOGRAPHIES`;
export const fetchGeographies = isPublic => ({ type: FETCH_GEOGRAPHIES, payload: { isPublic } });

export const SET_GEOGRAPHIES = `${prefix}/SET_GEOGRAPHIES`;
export const setGeographies = geographies => ({ type: SET_GEOGRAPHIES, payload: { geographies } });

export const CREATE_STUDY = `${prefix}/CREATE_STUDY`;
export const createStudy = (study, navigateTo = null) => ({ type: CREATE_STUDY, payload: { study, navigateTo } });

export const UPSERT_STUDY = `${prefix}/UPSERT_STUDY`;
export const upsertStudy = study => ({ type: UPSERT_STUDY, payload: study });

export const UPSERT_PRODUCT = `${prefix}/UPSERT_PRODUCT`;
export const upsertProduct = product => ({ type: UPSERT_PRODUCT, payload: product });

export const RESET_STUDIES = `${prefix}/RESET_STUDIES`;
export const resetStudies = () => ({ type: RESET_STUDIES });

export const RESET_STUDY_TAGS = `${prefix}/RESET_STUDY_TAGS`;
export const resetStudyTags = () => ({ type: RESET_STUDY_TAGS });

export const RESET_PRODUCT_TAGS = `${prefix}/RESET_PRODUCT_TAGS`;
export const resetProductTags = () => ({ type: RESET_PRODUCT_TAGS });

export const RESET_COLLECTIONS = `${prefix}/RESET_COLLECTIONS`;
export const resetCollections = () => ({ type: RESET_COLLECTIONS });

export const SET_DELETE_STUDY_STATE = `${prefix}/SET_DELETE_STUDY_STATE`;
export const setDeleteStudyState = state => ({ type: SET_DELETE_STUDY_STATE, payload: state });

export const SET_CREATE_STUDY_LOADING = `${prefix}/SET_CREATE_STUDY_LOADING`;
export const setCreateStudyLoading = loading => ({ type: SET_CREATE_STUDY_LOADING, payload: loading });
