import React from 'react';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'interlock-quotas-warning';
const el = (name, mod) => cn(className, name, mod);

const InterlockQuotasWarning = ({ icon, description }) => (
	<div className={`${className} ${icon}`}>
		<Iconof icon="warning" />
		<div className={el('text-container')}>
			<span>{description}</span>
		</div>
	</div>
);

InterlockQuotasWarning.propTypes = {
	icon: PropTypes.oneOf(['error', 'warning']),
	description: PropTypes.string,
};

export default InterlockQuotasWarning;
