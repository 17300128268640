import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

/*
 * Link Routing Services
 */
const postLinkRouting = (studyId, linkRoutingData) =>
	axios.post(`${API_PATH}/studies/${studyId}/link_routing/`, linkRoutingData).then(({ data }) => data);

const patchLinkRouting = (studyId, linkRoutingId, linkRoutingData) =>
	axios
		.patch(`${API_PATH}/studies/${studyId}/link_routing/${linkRoutingId}`, linkRoutingData)
		.then(({ data }) => data);

export default {
	// Link Routing Services
	postLinkRouting,
	patchLinkRouting,
};
