import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import LogicCloseButton from './LogicCloseButton';
import LogicDestinationSelect from './LogicDestinationSelect';
import LogicOptionsUtil from './Util/LogicOptionsUtil.js';
import LogicRows from './LogicRows';
import { LogicConditionContextProvider } from './LogicConditionContext';
import './styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

const LogicCondition = ({
	zIndexRef,
	logic,
	setLogic,
	study,
	question,
	section,
	sections,
	onClose,
	patchItem,
	deleteItemLogic,
	patchSectionLogic,
	logicItem,
	isProductLogic,
	setTempTemplate,
	patchAudienceItemLogic,
	tempTemplate,
	audience,
	isAudienceLogic,
	deleteAudienceItemLogic,
	language,
}) => {
	const isSingleSelectQuestionAndIsOperator = () =>
		!isProductLogic ? question.style === 'single-select' && logicItem.operator === 'is' : false;

	const operandOptions = LogicOptionsUtil.getOperandOptions(isSingleSelectQuestionAndIsOperator());

	const setParentRefZIndex = zIndex => {
		// This is currently only needed for the question logic
		// So I am checking if this prop is being passed in before setting it
		if (zIndexRef && zIndexRef.current && zIndexRef.current.style) {
			zIndexRef.current.style.zIndex = zIndex;
		}
	};

	return (
		<Container className={className}>
			<LogicConditionContextProvider
				logic={logic}
				study={study}
				question={question}
				section={section}
				sections={sections}
				logicItem={logicItem}
				language={language}
				audience={audience}
				tempTemplate={tempTemplate}
				isAudienceLogic={isAudienceLogic}
				onClose={onClose}
				setTempTemplate={setTempTemplate}
				patchAudienceItemLogic={patchAudienceItemLogic}
				patchItem={patchItem}
				isProductLogic={isProductLogic}
				patchSectionLogic={patchSectionLogic}
				setLogic={setLogic}
			>
				<LogicCloseButton deleteItemLogic={deleteItemLogic} deleteAudienceItemLogic={deleteAudienceItemLogic} />

				<LogicRows
					logicItem={logicItem}
					question={question}
					setParentRefZIndex={setParentRefZIndex}
					operandOptions={operandOptions}
				/>

				<LogicDestinationSelect setParentRefZIndex={setParentRefZIndex} />
			</LogicConditionContextProvider>
		</Container>
	);
};

LogicCondition.propTypes = {
	zIndexRef: PropTypes.any,
	style: PropTypes.any,
	logic: PropTypes.array,
	setLogic: PropTypes.func,
	study: PropTypes.any,
	question: PropTypes.any,
	section: PropTypes.any,
	sections: PropTypes.any,
	onClose: PropTypes.func,
	patchItem: PropTypes.func,
	deleteItemLogic: PropTypes.func,
	patchSectionLogic: PropTypes.func,
	logicItem: PropTypes.any,
	isProductLogic: PropTypes.bool,
	setTempTemplate: PropTypes.func,
	patchAudienceItemLogic: PropTypes.func,
	tempTemplate: PropTypes.any,
	audience: PropTypes.any,
	isAudienceLogic: PropTypes.bool,
	deleteAudienceItemLogic: PropTypes.func,
	language: PropTypes.string,
};

export default LogicCondition;
