import React from 'react';
import cn from 'src/utilities/bem-cn';

import Button from 'src/components/elements/Button';
import Loader from 'src/components/shared/Loader';

import './styles.scss';

const className = 'loadable';
const el = (name, mod) => cn(className, name, mod);

const Loadable = ({ loading, error, onRetry, children, customStyle = {} }) => (
	<div style={customStyle} className={className}>
		{loading && (
			<div className={el('loader')}>
				<Loader centered />
			</div>
		)}

		{error && (
			<div className={el('error')}>
				<div className={el('error-label')}>{error}</div>

				{onRetry && (
					<div className={el('retry-button')}>
						<Button label="retry" onClick={onRetry} />
					</div>
				)}
			</div>
		)}

		<div className={el('children', loading ? 'loading' : '')}>{children}</div>
	</div>
);

export default Loadable;
