import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'src/utilities/bem-cn';
import { Tooltip } from 'react-tippy';
import toastr from 'toastr';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import Chip from 'src/components/shared/Chip';
import useIsMobile from 'src/hooks/useIsMobile';
import StudyTagManager from 'src/components/shared/StudyTagManager';
import auth from 'src/utilities/auth';
import { tagColors } from 'src/utilities/misc';
import { studyService } from 'src/services';
import { useIsPublic } from 'src/hooks';
import useWindowSize from 'src/hooks/useWindowSize';
import { getStudyById, getStudyTagById } from 'src/domains/all-studies/selectors';
import { upsertStudy } from 'src/domains/all-studies/actions';
import {
	getFirst10TagIds,
	getProductById,
	getProductTagById,
	getAllStudyTagIds,
} from '../../../../../domains/all-studies/selectors';
import { upsertProduct } from '../../../../../domains/all-studies/actions';
import productService from '../../../../../services/product.service';
import './styles.scss';

const className = 'study-tags-and-templates';
const el = (name, mod) => cn(className, name, mod);

export const StudyTagChip = React.forwardRef(
	({ tag, canUpdate, handleRemoveTag, isPublic, showMobileTooltip }, ref) => {
		const isMobile = useIsMobile(428);
		if (!tag?.label) {
			return null;
		}

		const textColor = tagColors?.find(item => item.lighter === tag?.color)?.darker || '#3B3B3B';

		const tagLabel = tag?.label?.length > 30 ? `${tag?.label.slice(0, 30)}...` : tag?.label;

		return (
			<Chip
				ref={ref}
				key={tag?.label}
				customClass={el('template-chip', `tags ${canUpdate ? 'interactive' : null}`)}
				style={{
					backgroundColor: tag?.color ? tag?.color : 'initial',
					color: textColor,
				}}
			>
				{isMobile && showMobileTooltip ? (
					<Tooltip
						className={el('template-chip-text')}
						theme="light study-tags-theme"
						animationFill={false}
						arrow
						html={tagLabel}
					>
						{tagLabel}
					</Tooltip>
				) : (
					<div className={el('template-chip-text')}>{tagLabel}</div>
				)}

				{!isPublic && canUpdate && tag.id ? (
					<Iconof
						icon="clear"
						className={el('remove-tag-from-study')}
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							if (typeof handleRemoveTag === 'function') {
								handleRemoveTag(tag);
							}
						}}
					/>
				) : null}
			</Chip>
		);
	},
);
StudyTagChip.displayName = 'StudyTagChip';
StudyTagChip.propTypes = {
	tag: PropTypes.object,
	handleRemoveTag: PropTypes.func,
	canUpdate: PropTypes.bool,
	isPublic: PropTypes.bool,
	showMobileTooltip: PropTypes.bool,
};

export const StudyTag = React.forwardRef(
	({ tagId, studyId, productId, localProductId, canUpdate, showMobileTooltip }, ref) => {
		const { isPublic } = useIsPublic();
		const dispatch = useDispatch();
		const dataSelector = productId ? getProductTagById : getStudyTagById;
		const tag = useSelector(state => dataSelector(state, tagId));

		const study = useSelector(state => getStudyById(state, studyId));
		const product = useSelector(state => getProductById(state, productId));

		const handleRemoveTag = useCallback(
			async tagData => {
				try {
					if (!productId) {
						const filteredTags = study?.tags.filter(studyTag => studyTag.id !== tagData.id);

						dispatch(
							upsertStudy({
								id: studyId,
								tags: filteredTags,
							}),
						);

						await studyService.removeStudyTag(studyId, tag?.id);
					} else {
						const filteredTags = product?.tags.filter(productTag => productTag.id !== tagData.id);

						dispatch(
							upsertProduct({
								id: productId,
								tags: filteredTags,
							}),
						);

						await productService.removeProductTag(studyId, localProductId, tag?.id);
					}
				} catch (e) {
					toastr.error(`Something went wrong! Please try again.\n\n${e}`);
				}
			},
			[dispatch, localProductId, product, productId, study, studyId, tag],
		);

		if (!tag?.label) {
			return null;
		}

		return (
			<StudyTagChip
				ref={ref}
				tag={tag}
				canUpdate={canUpdate}
				handleRemoveTag={handleRemoveTag}
				isPublic={isPublic}
				showMobileTooltip={showMobileTooltip}
			/>
		);
	},
);
StudyTag.displayName = 'StudyTag';
StudyTag.propTypes = {
	tagId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	studyId: PropTypes.number,
	productId: PropTypes.number,
	localProductId: PropTypes.number,
	canUpdate: PropTypes.bool,
	showMobileTooltip: PropTypes.bool,
};

export const TagsWrapper = ({ study, item, TagManagerComp, allowTwoRows, canEdit, numberOfTags, children }) => {
	const containerRef = useRef();
	const [totalHidden, setTotalHidden] = useState(0);
	const { isPublic } = useIsPublic();
	const isMobile = useIsMobile();

	const userCanUpdateStudy = auth.userCan('study:update');

	const { width: windowWidth } = useWindowSize();

	// width of add button and +2 chip
	const buttonWidth = 24;

	const handleSetTotal = useCallback(() => {
		let count = 0;
		let totalChildWidth = 0;

		if (containerRef.current) {
			const containerHeight = containerRef.current.offsetHeight;
			const containerWidth = containerRef.current.offsetWidth;

			const containerGap = window.getComputedStyle(containerRef.current).getPropertyValue('gap');

			if (containerRef.current.children.length) {
				containerRef.current.children?.forEach(childNode => {
					const childOffsetTop = childNode?.offsetTop;
					const childWidth = Number(childNode?.offsetWidth) + Number(containerGap.replace('px', ''));

					totalChildWidth += childWidth;

					// If the child is on line 2 OR the total children width + the buttons width is greater than the container width
					// Add to the hidden children count
					if (
						childOffsetTop > containerHeight ||
						totalChildWidth + (count > 0 ? buttonWidth : 0) > containerWidth
					) {
						count += 1;
					}
				});
			}
		}
		setTotalHidden(count);
	}, [buttonWidth]);

	useEffect(() => {
		handleSetTotal();
	}, [children, handleSetTotal, windowWidth]);

	let totalChildren = 0;
	React.Children.forEach(children, child => {
		if (!child) {
			return null;
		}

		totalChildren += 1;
	});

	const visibleItems = totalChildren - totalHidden;

	let tooltipCount = 0;
	const tooltipChildren = React.Children.map(children, child => {
		if (!child) {
			return null;
		}

		tooltipCount += 1;

		if (tooltipCount <= visibleItems) {
			return null;
		}

		return child?.props?.hiddenComponent || child;
	});

	let visibleCount = 0;
	const visibleChildren = React.Children.map(children, child => {
		if (!child) {
			return null;
		}
		visibleCount += 1;
		if (visibleCount <= visibleItems) {
			return child;
		}
		return null;
	});

	const canEditTags = !isPublic && userCanUpdateStudy && canEdit;

	return (
		<div
			className={el(
				'study-tags',
				`table-cell-clickable ${allowTwoRows && totalHidden > 0 ? 'multiRow' : ''} ${
					(numberOfTags === 0 && !canEdit) || (numberOfTags === 0 && isPublic) ? 'empty' : ''
				}`,
			)}
		>
			<div
				ref={containerRef}
				style={{
					opacity: 0,
					position: 'absolute',
					zIndex: -1,
					top: 0,
					width: '100%',
					paddingRight: `${buttonWidth}px`,
				}}
				className={el(
					'study-tags-list',
					`${allowTwoRows && totalChildren > 0 ? 'multiRow' : ''} ${totalHidden > 0 ? 'withHidden' : ''}`,
				)}
			>
				{children}
			</div>

			<TagManagerComp study={study} item={item} canEdit={canEdit}>
				<div className={el('tags-container', `${totalChildren > 1 ? 'multiChildren' : ''}`)}>
					<div
						className={el(
							'study-tags-list',
							`visible ${canEditTags ? 'canEdit' : ''} ${
								visibleChildren.length < totalChildren ? 'withHidden' : ''
							} ${totalChildren > 1 ? 'multiChildren' : ''} ${
								allowTwoRows && totalChildren > 0 ? 'multiRow' : ''
							} ${totalChildren === 0 && !canEdit ? 'empty' : ''}`,
						)}
					>
						{visibleChildren}

						{totalHidden > 0 ? (
							<Tooltip
								className={el('template-chip', 'hidden-tag-count')}
								theme="light study-tags-theme"
								animationFill={false}
								arrow
								html={tooltipChildren}
							>
								<Chip customClass={el('template-chip', 'more-tags')}>
									<div className={el('template-chip-text')}>+{totalHidden}</div>
								</Chip>
							</Tooltip>
						) : null}

						{!isMobile && !isPublic && canEdit && totalChildren === 0 ? (
							<div className={el('add-tags-wrap')}>
								<span className={el('add-tags-text')}>+ Add Tag</span>
							</div>
						) : null}
					</div>
				</div>
			</TagManagerComp>
		</div>
	);
};
TagsWrapper.propTypes = {
	study: PropTypes.object,
	item: PropTypes.object,
	TagManagerComp: PropTypes.any,
	allowTwoRows: PropTypes.bool,
	canEdit: PropTypes.bool,
	numberOfTags: PropTypes.number,
	children: PropTypes.any,
};

const StudyTagManagerComp = ({ study, canEdit, children }) => {
	const allTags = sortBy(study?.tags || [], 'label');

	const defaultTagIds = useSelector(getFirst10TagIds);

	return (
		<StudyTagManager studyId={study?.id} currentTags={allTags} defaultTagIds={defaultTagIds} canEdit={canEdit}>
			{children}
		</StudyTagManager>
	);
};
StudyTagManagerComp.propTypes = {
	study: PropTypes.object,
	canEdit: PropTypes.bool,
	children: PropTypes.any,
};

const StudyTagsAndTemplates = React.memo(({ studyId, canEdit = true, allowTwoRows = false }) => {
	const { isPublic } = useIsPublic();
	const isMobile = useIsMobile();

	const study = useSelector(state => getStudyById(state, studyId));
	const allStudyTagIds = useSelector(getAllStudyTagIds);

	const sortedTags = useMemo(() => {
		const allTags = sortBy(
			study?.tags?.filter(tag => allStudyTagIds?.includes(tag?.id)),
			'label',
		);

		return { allTags };
	}, [allStudyTagIds, study?.tags]);

	if (isMobile && sortedTags?.allTags?.length === 0) {
		return null;
	}

	return (
		<div>
			<TagsWrapper
				study={study}
				TagManagerComp={StudyTagManagerComp}
				allowTwoRows={allowTwoRows}
				canEdit={canEdit}
				numberOfTags={study?.tags?.length || 0}
			>
				{sortedTags?.allTags?.map(tag => (
					<StudyTag
						key={tag?.id}
						tagId={tag?.id}
						studyId={studyId}
						canUpdate={false}
						showMobileTooltip
						hiddenComponent={<StudyTagChip tag={tag} canUpdate={false} isPublic={isPublic} />}
					/>
				))}
			</TagsWrapper>
		</div>
	);
});

StudyTagsAndTemplates.displayName = 'StudyTagsAndTemplates';
StudyTagsAndTemplates.propTypes = {
	studyId: PropTypes.number,
	canEdit: PropTypes.bool,
	allowTwoRows: PropTypes.bool,
};

export default StudyTagsAndTemplates;
