import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Back from 'src/components/icons/Back';
// TODO: Need to figure out how to have the browser back button kick back to studies right away
import withRouter from 'src/hocs/withRouter';
import AccountSelectorDropdown from 'src/domains/header/components/shared/AccountSelectorDropdown';
import cn from 'src/utilities/bem-cn';
import * as selectors from 'src/domains/selectors';
import * as profileSelectors from 'src/domains/profile/selectors';
// TODO: Find a way to not borrow actions from other domains
import * as accountActions from 'src/domains/account/actions';
import * as studyActions from 'src/domains/manage-study/actions/reporting';
import * as confirmSignUpActions from 'src/domains/confirm-signup/actions';
import './styles.scss';

const className = 'generic-header';
const el = (name, mod) => cn(className, name, mod);

class GenericHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			backButtonClicked: false,
		};
	}

	componentDidMount = () => {
		/*
		 * window.onpopstate will be called if the user clicks the 'back' or 'forward' buttons in the browser
		 */
		window.onpopstate = () => {
			/*
			 * Calling this to set a flag that determines whether account networks calls should be made
			 */
			this.setState({ backButtonClicked: true });
		};
	};

	renderBackButton = () => {
		const {
			backToAllStudies,
			router: { navigate },
			backTo,
		} = this.props;
		let previousPath = '';
		if (backToAllStudies) {
			previousPath = '/studies';
		}
		return (
			<button
				type="button"
				className={el('back-button')}
				onClick={() => {
					this.setState({ backButtonClicked: true }, () => {
						if (backToAllStudies) {
							navigate(previousPath);
						} else if (backTo) {
							navigate(backTo);
							// window.history.pushState('');
						} else {
							window.history.back();
						}
					});
				}}
			>
				<Back />
			</button>
		);
	};

	renderDoneButton = () => {
		const { setCustomizeReport, customizeReportId, activeBaseFilters } = this.props;
		const doneText = 'Done';
		return (
			<button
				type="button"
				className={el('done-button')}
				onClick={() => {
					// Remove the 'blur' class from the body
					document.body.classList.remove('blur-customize-reporting');
					// Show the 'Customize Report' header
					setCustomizeReport(false, customizeReportId, activeBaseFilters);
				}}
			>
				{doneText}
			</button>
		);
	};

	accountSelectorChangeAccountCallback = () => {
		const { backButtonClicked } = this.state;
		if (!backButtonClicked) {
			const {
				account,
				accounts,
				fetchAccount,
				fetchAccountInvoices,
				fetchAccountSubscription,
				fetchAccountUsage,
			} = this.props;
			const isAccountPage = window.location.pathname.indexOf('/account/') !== -1;
			const isChoosePlanOrBillingDetailsPage =
				window.location.pathname.indexOf('/choose-plan' || '/billing-details') !== -1;
			if (isAccountPage || isChoosePlanOrBillingDetailsPage) {
				fetchAccount();
			}
			// Check permissions if we should even render the account summary dropdown item
			if (!account || !account.content || account.loading || !accounts || !accounts.content) {
				return false;
			}
			// Get account settings access data
			const accountSettingsAccess = accounts.content.filter(a => a.uuid === account.content.uuid);
			const permission = accountSettingsAccess[0] ? accountSettingsAccess[0].permission : '';
			const accountHasPermissionToManage = permission === 'CAN_MANAGE';
			if ((isAccountPage || isChoosePlanOrBillingDetailsPage) && accountHasPermissionToManage) {
				fetchAccountSubscription();
				fetchAccountInvoices();
				fetchAccountUsage();
			}
		}
	};

	renderAccountSelectorDropdown = () => {
		const { loggedIn, account, accounts, setAccount, setAccountUuid, fetchAccount, user, setUser } = this.props;
		return (
			<AccountSelectorDropdown
				loggedIn={loggedIn}
				account={account}
				accounts={accounts}
				setAccount={setAccount}
				setAccountUuid={setAccountUuid}
				fetchAccount={fetchAccount}
				isAccountSettingsPage
				accountSelectorDidMountCallback={this.accountSelectorChangeAccountCallback}
				accountSelectorChangeAccountCallback={this.accountSelectorChangeAccountCallback}
				setUserEmail={() => {
					setUser({ email: user.email });
				}}
			/>
		);
	};

	render() {
		const { path, title, showBackButton = true, showDoneButton = false } = this.props;
		const isAccountPage = path === '/account/*';
		const isChoosePlanOrBillingDetailsPage = path === 'choose-plan' || path === 'billing-details';
		return (
			<Container className={className}>
				<Row>
					<Col xs={4} className={el('left')}>
						{showBackButton && this.renderBackButton()}
					</Col>
					<Col xs={4} className={el('center')}>
						{title}
					</Col>
					<Col xs={4} className={el('right')}>
						{(isAccountPage || isChoosePlanOrBillingDetailsPage) && this.renderAccountSelectorDropdown()}
						{showDoneButton && this.renderDoneButton()}
					</Col>
				</Row>
			</Container>
		);
	}
}

GenericHeader.propTypes = {
	backToAllStudies: PropTypes.bool,
	path: PropTypes.string,
	title: PropTypes.string,
	showBackButton: PropTypes.bool,
	showDoneButton: PropTypes.bool,
	loggedIn: PropTypes.any,
	account: PropTypes.any,
	accounts: PropTypes.any,
	setAccount: PropTypes.func,
	fetchAccount: PropTypes.func,
	fetchAccountInvoices: PropTypes.func,
	fetchAccountSubscription: PropTypes.func,
	fetchAccountUsage: PropTypes.func,
	setAccountUuid: PropTypes.func,
	setCustomizeReport: PropTypes.func,
	customizeReportId: PropTypes.any,
	activeBaseFilters: PropTypes.any,
	user: PropTypes.object,
	setUser: PropTypes.func,
};

const mapStateToProps = state => ({
	loggedIn: selectors.getAuthData(state).loggedIn,
	account: selectors.getAccountData(state).account,
	accounts: selectors.getAccountData(state).accounts,
	customizeReportId: selectors.getStudyData(state).customizeReportId,
	activeBaseFilters: selectors.getStudyData(state).activeBaseFilters,
	user: profileSelectors.getUser(state),
});

const mapDispatchToProps = dispatch => ({
	setAccount: account => dispatch(accountActions.setAccount(account)),
	fetchAccount: (accountId, fetchUsersAndRoles = true) =>
		dispatch(accountActions.fetchAccount(accountId, fetchUsersAndRoles)),
	fetchAccountInvoices: accountId => dispatch(accountActions.fetchAccountInvoices(accountId)),
	fetchAccountSubscription: accountId => dispatch(accountActions.fetchAccountSubscription(accountId)),
	fetchAccountUsage: accountId => dispatch(accountActions.fetchAccountUsage(accountId)),
	setAccountUuid: accountUuid => dispatch(accountActions.setAccountUuid(accountUuid)),
	setUser: user => dispatch(confirmSignUpActions.setUser(user)),
	setCustomizeReport: (customizeReport, customizeReportId, activeBaseFilters) =>
		dispatch(studyActions.setCustomizeReport(customizeReport, customizeReportId, activeBaseFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GenericHeader));
