import React from 'react';
import PropTypes from 'prop-types';

const NorthEastArrow = ({ style }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
		<path d="M1.03419 9.94975L0.562783 9.47835C0.432608 9.34817 0.432608 9.13712 0.562783 9.00694L8.18539 1.38433L3.99461 1.38905C3.90573 1.38941 3.82039 1.35427 3.75754 1.29142C3.69469 1.22857 3.65955 1.14323 3.65991 1.05435L3.66462 0.389672C3.66426 0.300793 3.69941 0.21545 3.76225 0.152603C3.8251 0.0897557 3.91044 0.0546099 3.99932 0.0549744L9.78345 0.0502598C9.91602 0.0501774 10.0432 0.102741 10.137 0.196395L10.3161 0.375529C10.4086 0.470148 10.4609 0.596804 10.4623 0.729082L10.4576 6.51321C10.4579 6.60209 10.4228 6.68744 10.3599 6.75028C10.2971 6.81313 10.2117 6.84828 10.1229 6.84791H9.45347C9.36568 6.84881 9.28123 6.81433 9.21915 6.75225C9.15707 6.69017 9.12259 6.60572 9.12349 6.51793L9.1282 2.32714L1.50559 9.94975C1.37542 10.0799 1.16436 10.0799 1.03419 9.94975Z" />
	</svg>
);

NorthEastArrow.propTypes = {
	style: PropTypes.object,
};
export default NorthEastArrow;
