import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const ProgressBar = ({ width, percent, customClasses = '' }) => {
	const [value, setValue] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setValue(percent);
		}, 10);
	}, [percent]);

	return (
		<div
			className={customClasses ? `progress-div ${customClasses}` : 'progress-div'}
			style={{ width: `${width}%` }}
		>
			<div style={{ width: `${value}%` }} className="progress" />
		</div>
	);
};

ProgressBar.propTypes = {
	width: PropTypes.number,
	percent: PropTypes.number,
	customClasses: PropTypes.string,
};

export default ProgressBar;
