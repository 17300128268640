import React from 'react';
import './PublicHeaderBanner.scss';
import cn from 'src/utilities/bem-cn';
import { useIsMobile } from 'src/hooks';
import Button from 'src/components/elements/Button';
import signupIcon from 'public/images/public-study/signup-icon.svg';
import signupIconMobile from 'public/images/public-study/signup-icon-mobile.svg';

import { useNavigate } from 'react-router-dom';

const className = 'public-header-banner';
const el = (name, mod) => cn(className, name, mod);

export const PublicHeaderBanner = () => {
	const navigate = useNavigate();
	const isMobileBannerWidth = useIsMobile(560);
	return (
		<div className={`${className}`}>
			<div className={el('content')}>
				<div className={el('icon-container')}>
					<img src={isMobileBannerWidth ? signupIconMobile : signupIcon} alt="Get Started" />
				</div>
				<div className={el('text')}>
					<span>
						<b>Like what you see?</b>
						<p>Sign up for a free trial now!</p>
					</span>
				</div>
				<div>
					<Button
						label="Get started"
						className={el('get-started-button')}
						type="text"
						onClick={() => navigate('/signup')}
					/>
				</div>
			</div>
		</div>
	);
};
