import React from 'react';
import PropTypes from 'prop-types';
import { Button, Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import Caret from 'src/components/icons/Caret';
import { Tooltip } from 'react-tippy';
import withRouter from 'src/hocs/withRouter';
import SelectLanguagePopper from './SelectLanguagePopper';
import ManageLanguagePopper from './ManageLanguagePopper';
import AddLanguage from './AddLanguage';
import './styles.scss';

const className = 'language-dropdown';
const el = (name, mod) => cn(className, name, mod);

class LanguageSelector extends React.PureComponent {
	state = { open: false };

	componentDidMount() {
		window.addEventListener('click', this.hide);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.hide);
	}

	show = () => this.setState({ open: true });

	hide = () => this.setState({ open: false });

	toggle = () => this.setState(({ open }) => ({ open: !open }));

	preventWindowClick = e => {
		e.preventDefault();
		e.nativeEvent.stopPropagation();
	};

	pickLanguage = language => {
		const { onChangeLanguage } = this.props;
		onChangeLanguage(language);
		this.setState({ open: false });
	};

	pickAudience = audience => {
		const { onChangeAudience } = this.props;
		onChangeAudience(audience);
		this.setState({ open: false });
	};

	getLabel = () => {
		const { allLanguages, language, selectedAudience } = this.props;
		// If audience exists AND is not an empty object
		if (selectedAudience && Object.keys(selectedAudience)?.length > 0) {
			return (
				selectedAudience?.name ||
				`${selectedAudience?.countryLanguage?.countryLabel} - ${selectedAudience?.countryLanguage?.languageLabel}`
			);
		}
		const languageObject = (allLanguages || []).find(
			lang => lang?.languageCode === language || lang?.languageCode === language?.languageCode,
		);

		if (languageObject) {
			return languageObject?.label;
		}
		// Fallback to the last version of the shorthand thing.
		return `${language?.toUpperCase()?.replace('_', ' (')})`;
	};

	renderSelectorMarkup = () => {
		const {
			languages,
			allLanguages,
			setTranslationsModal,
			showImportTranslationsOption,
			headingText,
			language,
			selectedAudience,
			audiences,
		} = this.props;
		return (
			<SelectLanguagePopper
				languages={languages}
				allLanguages={allLanguages}
				setTranslationsModal={() => {
					this.setState({ open: false }, () => {
						setTranslationsModal({ visible: true });
					});
				}}
				pickLanguage={this.pickLanguage}
				pickAudience={this.pickAudience}
				showImportTranslationsOption={showImportTranslationsOption}
				headingText={headingText}
				selectedLanguage={language}
				selectedAudience={selectedAudience}
				audiences={audiences}
			/>
		);
	};

	navigateToTranslations = () => {
		const {
			translationLocation = 'studies',
			router: { navigate, params },
		} = this.props;

		this.setState({ open: false }, () => {
			setTimeout(() => {
				navigate(`/${translationLocation === 'blueprints' ? 'templates' : translationLocation}/${
					params?.id}/translations`);
				const elements = document.getElementsByClassName('tippy-popper');
				while (elements.length > 0) {
					elements[0].parentNode.removeChild(elements[0]);
				}
			}, 100);
		});
	};

	renderManagerMarkup = () => {
		const {
			languages,
			allLanguages,
			headingText,
			setEditStudyLanguage,
			language,
			sections,
			currentSection,
			isTrialing,
		} = this.props;
		return (
			<ManageLanguagePopper
				languages={languages}
				allLanguages={allLanguages}
				currentSection={currentSection}
				sections={sections}
				navigateToTranslations={this.navigateToTranslations}
				headingText={headingText}
				setEditStudyLanguage={setEditStudyLanguage}
				selectedLanguage={language}
				isTrialing={isTrialing}
			/>
		);
	};

	renderButtonAddLanguage = () => <AddLanguage navigateToTranslations={this.navigateToTranslations} />;

	render() {
		const {
			disabled,
			manageLanguages,
			onShow,
			onRequestClose,
			audiences,
			languages,
			router: { navigate, params },
			translationLocation = 'studies',
			isPreview,
			isCreateMode = false,
		} = this.props;
		const { open } = this.state;
		if (languages.length === 1 && isCreateMode) return this.renderButtonAddLanguage();
		return (
			<div className={`${className} ${disabled ? 'disabled' : ''}`} onClick={this.preventWindowClick}>
				<Tooltip
					id={`${className}-tooltip-${Math.ceil(Math.random() * 1000)}`}
					className={`${el('tooltip')} ${audiences && 'audience-language-dropdown'}`}
					open={open}
					disabled={disabled === true}
					theme={`light ${audiences && 'audience-language-dropdown'}`}
					animation="shift"
					animationFill={false}
					arrow
					interactive
					trigger="click"
					size="big"
					position={audiences ? 'bottom-end' : 'top-start'}
					onShow={onShow}
					onRequestClose={onRequestClose}
					popperOptions={{
						preventOverflow: {
							enabled: true,
							escapeWithReference: false,
							boundariesElement: 'viewport',
						},
					}}
					html={manageLanguages ? this.renderManagerMarkup() : this.renderSelectorMarkup()}
				>
					{languages?.length > 1 || isPreview || disabled ? (
						<button type="button" className={el('main', disabled ? 'disabled' : '')} onClick={this.toggle}>
							<div className={el('label')}>{this.getLabel()}</div>
							{!disabled && <Caret className={el('caret', `${open ? 'dropdownOpen' : ''}`)} />}
						</button>
					) : (
						<Button
							variant="text"
							label="Add a Language"
							buttonClassName={el('add-language-button')}
							startIcon={<Iconof icon="add" />}
							onClick={() => {
								this.setState({ open: false }, () => {
									setTimeout(() => {
										navigate(`/${translationLocation === 'blueprints' ? 'templates' : translationLocation}/${
											params?.id}/translations`);
										const elements = document.getElementsByClassName('tippy-popper');
										while (elements.length > 0) {
											elements[0].parentNode.removeChild(elements[0]);
										}
									}, 100);
								});
							}}
						/>
					)}
				</Tooltip>
			</div>
		);
	}
}

LanguageSelector.propTypes = {
	headingText: PropTypes.string,
	studyId: PropTypes.number,
	disabled: PropTypes.any,
	language: PropTypes.any,
	languages: PropTypes.any,
	allLanguages: PropTypes.any,
	onChangeLanguage: PropTypes.func,
	onChangeAudience: PropTypes.func,
	setTranslationsModal: PropTypes.func,
	showImportTranslationsOption: PropTypes.bool,
	onShow: PropTypes.func,
	onRequestClose: PropTypes.func,
	manageLanguages: PropTypes.any,
	setEditStudyLanguage: PropTypes.func,
	translationLocation: PropTypes.string,
	selectedAudience: PropTypes.object,
	audiences: PropTypes.array,
	currentSection: PropTypes.any,
	sections: PropTypes.any,
	isTrialing: PropTypes.bool,
	isPreview: PropTypes.bool,
	isCreateMode: PropTypes.bool,
};

export default withRouter(LanguageSelector);
