import React from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';

const defaultClassName = 'upsiide-card';

const Card = ({ className = '', onClick, children, variant }) => (
	<div
		{...(typeof onClick === 'function' ? { onClick: e => onClick(e) } : {})}
		className={`${defaultClassName} ${className} ${variant} ${onClick ? 'with-click' : ''}`}
	>
		{children}
	</div>
);

Card.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.any,
	variant: PropTypes.oneOf(['audience-template', 'study']),
};

export default Card;
