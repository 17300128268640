import React from 'react';
import PropTypes from 'prop-types';
import { Iconof } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import { motion } from 'framer-motion';

import './styles.scss';

const className = 'dropdown';
const el = name => cn(className, name);

const contentVariants = {
	complete: { height: '100%', opacity: 1 },
	start: { height: 0, opacity: 0, overflow: 'hidden' },
};

class Collapse extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		subtitle: PropTypes.string,
		open: PropTypes.bool,
		animate: PropTypes.bool,
	};

	static defaultProps = {
		title: '',
		subtitle: '',
		open: false,
		animate: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			open: props.open || false,
		};
	}

	render() {
		const { title, subtitle, children, animate } = this.props;
		const { open } = this.state;

		const content = () =>
			animate ? (
				<motion.div
					className={`${el('content')} open`}
					initial="start"
					animate={open ? 'complete' : 'start'}
					variants={contentVariants}
				>
					{children}
				</motion.div>
			) : (
				<div className={`${el('content')} ${open ? 'open' : ''}`}>{children}</div>
			);

		return (
			<div className={className}>
				<div className={el('header')} onClick={() => this.setState({ open: !open })}>
					<div className={el('label')}>
						<div className={el('title')}>{title}</div>
						<div className={el('subtitle')}>{subtitle}</div>
					</div>

					<div className={el('arrow')}>
						<Iconof icon={open ? 'chevron_up' : 'chevron_down'} />
					</div>
				</div>

				{content()}
			</div>
		);
	}
}

export default Collapse;
