import { keyBy, pickBy, reduce } from 'lodash';

const upsert = (stateItems, upsertItem, identifier) => {
	if (stateItems && stateItems[upsertItem[identifier]]) {
		return {
			[upsertItem[identifier]]: {
				...stateItems[upsertItem[identifier]],
				...upsertItem,
			},
		};
	}

	if (upsertItem[identifier]) {
		return {
			[upsertItem[identifier]]: upsertItem,
		};
	}

	return {};
};

export const reducerUpsertHelper = (stateItems, upsertItem, identifier = 'id', remove = false) => {
	if (upsertItem) {
		// multiple items, pass in "payload"
		if (Array.isArray(upsertItem)) {
			const items = keyBy(upsertItem, identifier);

			return reduce(
				items,
				(results, item) => {
					if (item?.actionType && item?.actionType === 'remove') {
						return pickBy(results, result => result[identifier] !== item[identifier]);
					}

					return {
						...results,
						...upsert(stateItems, item, identifier),
					};
				},
				stateItems,
			);
		}
		// single item
		if (upsertItem?.actionType && upsertItem?.actionType === 'remove') {
			return pickBy(stateItems, result => result[identifier] !== upsertItem[identifier]);
		}
		return {
			...stateItems,
			...upsert(stateItems, upsertItem, identifier),
		};
	}

	return stateItems;
};
