const prefix = 'admin';

// ADMIN LEVEL SETTERS

export const SET_ADMIN_SAMPLE_FORM = `${prefix}/SET_ADMIN_SAMPLE_FORM`;
export const setAdminSampleForm = formState => ({ type: SET_ADMIN_SAMPLE_FORM, payload: { formState } });

export const SET_ADMIN_TEMPLATES = `${prefix}/SET_ADMIN_TEMPLATES`;
export const setAdminTemplates = templates => ({ type: SET_ADMIN_TEMPLATES, payload: { templates } });

export const SET_ERROR = `${prefix}/SET_ERROR`;
export const setError = errMssg => ({ type: SET_ERROR, payload: { errMssg } });

// ACCOUNT LEVEL SETTERS

export const SET_ACCOUNT_NAVIGATION = `${prefix}/SET_ACCOUNT_NAVIGATION`;
export const setAccountNav = page => ({ type: SET_ACCOUNT_NAVIGATION, payload: { page } });

export const SET_ACCOUNT_SAMPLE_FORM = `${prefix}/SET_ACCOUNT_SAMPLE_FORM`;
export const setAccountSampleForm = formState => ({ type: SET_ACCOUNT_SAMPLE_FORM, payload: { formState } });

export const SET_ACCOUNTS = `${prefix}/SET_ACCOUNTS`;
export const setAccounts = accounts => ({ type: SET_ACCOUNTS, payload: { accounts } });

export const SET_ACCOUNT = `${prefix}/SET_ACCOUNT`;
export const setAccount = account => ({ type: SET_ACCOUNT, payload: { account } });

export const SET_ACCOUNT_TEMPLATES = `${prefix}/SET_ACCOUNT_TEMPLATES`;
export const setAccountTemplates = templates => ({ type: SET_ACCOUNT_TEMPLATES, payload: { templates } });

export const SET_ACCOUNT_FORM = `${prefix}/SET_ACCOUNT_FORM`;
export const setAccountForm = accountForm => ({ type: SET_ACCOUNT_FORM, payload: { accountForm } });

export const SET_ASSIGN_TEMPLATE_MODAL = `${prefix}/SET_ASSIGN_TEMPLATE_MODAL`;
export const setAssignTemplateModal = modalState => ({ type: SET_ASSIGN_TEMPLATE_MODAL, payload: { modalState } });

export const SET_CLIENT_TEMPLATES = `${prefix}/SET_CLIENT_TEMPLATES`;
export const setClientTemplates = templates => ({ type: SET_CLIENT_TEMPLATES, payload: { templates } });

export const SET_ACCOUNT_USAGE = `${prefix}/SET_ACCOUNT_USAGE`;
export const setAccountUsage = accountUsage => ({ type: SET_ACCOUNT_USAGE, payload: { accountUsage } });

// EVENT HANDLERS

export const ASSIGN_TEMPLATE = `${prefix}/ASSIGN_TEMPLATE`;
export const assignTemplate = (accountUuid, formData) => ({
	type: ASSIGN_TEMPLATE,
	payload: { accountUuid, formData },
});

export const CREATE_ACCOUNT = `${prefix}/CREATE_ACCOUNT`;
export const createAccount = (uuid, formData) => ({ type: CREATE_ACCOUNT, payload: { uuid, formData } });

export const CLEAR_ACCOUNT = `${prefix}/CLEAR_ACCOUNT`;
export const clearAccount = () => ({ type: CLEAR_ACCOUNT });

export const CREATE_TEMPLATE = `${prefix}/CREATE_TEMPLATE`;
export const createTemplate = formData => ({ type: CREATE_TEMPLATE, payload: { formData } });

export const FETCH_ACCOUNTS = `${prefix}/FETCH_ACCOUNTS`;
export const fetchAccounts = () => ({ type: FETCH_ACCOUNTS });

export const FETCH_ACCOUNT = `${prefix}/FETCH_ACCOUNT`;
export const fetchAccount = accountUuid => ({ type: FETCH_ACCOUNT, payload: { accountUuid } });

export const FETCH_ACCOUNT_TEMPLATES = `${prefix}/FETCH_ACCOUNT_TEMPLATES`;
export const fetchAccountTemplates = (accountUuid, clientUuid) => ({
	type: FETCH_ACCOUNT_TEMPLATES,
	payload: { accountUuid, clientUuid },
});

export const FETCH_ALL_TEMPLATES = `${prefix}/FETCH_ALL_TEMPLATES`;
export const fetchAllTemplates = () => ({ type: FETCH_ALL_TEMPLATES });

export const FETCH_LANGUAGES = `${prefix}/FETCH_LANGUAGES`;
export const fetchLanguages = () => ({ type: FETCH_LANGUAGES });

export const UPDATE_ACCOUNT_SETTINGS = `${prefix}/UPDATE_ACCOUNT_SETTINGS`;
export const updateAccountSettings = (accountUuid, data) => ({
	type: UPDATE_ACCOUNT_SETTINGS,
	payload: { accountUuid, data },
});

export const UPDATE_CLIENT_TEMPLATE = `${prefix}/UPDATE_CLIENT_TEMPLATE`;
export const updateClientTemplate = (accountUuid, templateId, formData) => ({
	type: UPDATE_CLIENT_TEMPLATE,
	payload: { accountUuid, templateId, formData },
});

export const FETCH_ACCOUNT_USAGE = `${prefix}/FETCH_ACCOUNT_USAGE`;
export const fetchAccountUsage = (
	accountUuids,
	clientUuids,
	startDate,
	endDate,
	isBilled,
	component,
	limit,
	offset,
) => ({
	type: FETCH_ACCOUNT_USAGE,
	payload: { accountUuids, clientUuids, startDate, endDate, isBilled, component, limit, offset },
});

export const EXPORT_ACCOUNT_USAGE = `${prefix}/EXPORT_ACCOUNT_USAGE`;
export const exportAccountUsage = (accountUuids, clientUuids, startDate, endDate, isBilled, component) => ({
	type: EXPORT_ACCOUNT_USAGE,
	payload: { accountUuids, clientUuids, startDate, endDate, isBilled, component },
});

export const UPDATE_ACCOUNT_USAGE = `${prefix}/UPDATE_ACCOUNT_USAGE`;
export const updateAccountUsage = (usageId, formData) => ({
	type: UPDATE_ACCOUNT_USAGE,
	payload: { usageId, formData },
});

// Country & Language
export const FETCH_COUNTRIES = `${prefix}/FETCH_COUNTRIES`;
export const fetchCountries = () => ({ type: FETCH_COUNTRIES });

export const SET_COUNTRIES = `${prefix}/SET_COUNTRIES`;
export const setCountries = countries => ({ type: SET_COUNTRIES, payload: { countries } });

export const FETCH_COUNTRY_LANGUAGES = `${prefix}/FETCH_COUNTRY_LANGUAGES`;
export const fetchCountryLanguages = countryId => ({ type: FETCH_COUNTRY_LANGUAGES, payload: { countryId } });

export const SET_COUNTRY_LANGUAGES = `${prefix}/SET_COUNTRY_LANGUAGES`;
export const setCountryLanguages = countryLanguages => ({ type: SET_COUNTRY_LANGUAGES, payload: { countryLanguages } });

export const FETCH_AUDIENCES = `${prefix}/FETCH_AUDIENCES`;
export const fetchAudiences = filters => ({
	type: FETCH_AUDIENCES,
	payload: { filters },
});

export const FETCH_AUDIENCE = `${prefix}/FETCH_AUDIENCE`;
export const fetchAudience = audienceId => ({
	type: FETCH_AUDIENCE,
	payload: { audienceId },
});

export const SET_AUDIENCES = `${prefix}/SET_AUDIENCES`;
export const setAudiences = audiences => ({ type: SET_AUDIENCES, payload: { audiences } });

export const SET_AUDIENCE = `${prefix}/SET_AUDIENCE`;
export const setAudience = audience => ({ type: SET_AUDIENCE, payload: { audience } });

export const CLEAR_AUDIENCES = `${prefix}/CLEAR_AUDIENCES`;
export const clearAudiences = () => ({ type: CLEAR_AUDIENCES });

export const CLEAR_AUDIENCE = `${prefix}/CLEAR_AUDIENCE`;
export const clearAudience = () => ({ type: CLEAR_AUDIENCE });

// CUSTOM_QUALIFIERS
export const FETCH_CUSTOM_QUALIFIERS = `${prefix}/FETCH_CUSTOM_QUALIFIERS`;
export const fetchCustomQualifiers = countryLanguageId => ({ type: FETCH_CUSTOM_QUALIFIERS, payload: { countryLanguageId } });

export const SET_CUSTOM_QUALIFIERS = `${prefix}/SET_CUSTOM_QUALIFIERS`;
export const setCustomQualifiers = customQualifiers => ({ type: SET_CUSTOM_QUALIFIERS, payload: { customQualifiers } });

export const SET_CUSTOM_QUALIFIER_FORM = `${prefix}/SET_CUSTOM_QUALIFIER_FORM`;
export const setCustomQualifierForm = customQualifierForm => ({ type: SET_CUSTOM_QUALIFIER_FORM, payload: { customQualifierForm } });

export const CREATE_CUSTOM_QUALIFIER = `${prefix}/CREATE_CUSTOM_QUALIFIER`;
export const createCustomQualifier = (countryLanguageId, formData) => ({ type: CREATE_CUSTOM_QUALIFIER, payload: { countryLanguageId, formData } });
