import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import { Iconof } from '@upsiide/ui-components';

import './styles.scss';

const IconButton = ({
	icon,
	size,
	color,
	className,
	onClick,
	tooltip,
	disabled,
	delay = 0,
	distance = 0,
	customTheme = '',
	arrow = false,
	customIcon = null,
	position = 'top',
	...props
}) => {
	const renderButton = () => (
		<button
			{...props}
			type="button"
			className={`icon-button ${className} ${size}`}
			onClick={onClick}
			disabled={disabled}
		>
			{customIcon || <Iconof icon={icon} color={color} size={size} className="icon-no-margin" />}
		</button>
	);
	if (tooltip) {
		return (
			<Tooltip
				id={`icon-button__tooltip-${Math.ceil(Math.random() * 1000)}`}
				className={`${className}__tooltip`}
				animation="shift"
				animationFill={false}
				interactive
				trigger="mouseenter"
				position={position}
				arrow={arrow}
				theme={customTheme || 'basic-text-tooltip'}
				delay={delay}
				distance={distance}
				html={<div>{tooltip}</div>}
			>
				{renderButton()}
			</Tooltip>
		);
	}
	return renderButton();
};

IconButton.propTypes = {
	/** iconof, i.e. plus, chevron-left, file-alt */
	icon: PropTypes.string,
	size: PropTypes.oneOf(['default', 'large']),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func,
	delay: PropTypes.any,
	customTheme: PropTypes.any,
	distance: PropTypes.any,
	disabled: PropTypes.bool,
	arrow: PropTypes.bool,
	position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
	customIcon: PropTypes.any,
};

IconButton.defaultProps = {
	icon: 'add',
	size: 'default',
	tooltip: '',
	className: '',
	onClick: null,
};

export default IconButton;
