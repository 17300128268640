import React, { useCallback } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import LogicSelect from '../LogicSelect';
import { useLogicConditionContext } from '../../LogicConditionContext';

import '../../styles.scss';

const className = 'logic-condition';
const el = (name, mod) => cn(className, name, mod);

// OPERATOR can equal IS or IS_NOT
const LogicOperatorSelect = ({ options, setParentRefZIndex }) => {
	const {
		setShowLoader,
		question,
		section,
		sections,
		saveData,
		logicItem,
		isProductLogic,
		saveLogic,
		isAudienceLogic,
		audience,
		operandSelectIndex,
		operatorSelectIndex,
		selectedItem,
		setOperatorSelectIndex,
	} = useLogicConditionContext();

	const operatorSelectOnChange = useCallback(
		operatorIndex => {
			setShowLoader(true);
			setOperatorSelectIndex(operatorIndex);

			const { id: sectionItemId } = question;
			const { id: sectionId } = section || {};

			const isSingleSelect = question.style === 'single-select';
			const operand = operandSelectIndex;

			let operandFinal = operand ? 'or' : 'and';
			if (isSingleSelect && operatorIndex === 0) {
				operandFinal = 'or';
			}

			const saveDataClone = {
				...saveData,
				logic: saveData?.logic?.map((item, index) => {
					if (item?.id === logicItem?.id) {
						return {
							...item,
							id: logicItem.id,
							sectionId,
							sectionItemId: isProductLogic ? null : sectionItemId,
							operator: operatorIndex ? 'is_not' : 'is',
							operand: operandFinal,
							triggerItemId: selectedItem?.data?.itemId,
							triggerOptions: logicItem.triggerOptions,
							...(isAudienceLogic
								? {
										audienceUuid: audience?.uuid,
										itemType: 'question',
								  }
								: {
										triggerType:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].type,
										triggerSectionId:
											selectedItem && sections.content[selectedItem.data.sidebarIndex].id,
								  }),
						};
					}

					return item;
				}),
			};

			saveLogic(saveDataClone);
		},
		[
			audience?.uuid,
			isAudienceLogic,
			isProductLogic,
			logicItem,
			operandSelectIndex,
			question,
			saveData,
			saveLogic,
			section,
			sections.content,
			selectedItem,
			setOperatorSelectIndex,
			setShowLoader,
		],
	);

	return (
		<LogicSelect
			columnWidth={2}
			selectClassName="operator"
			options={options}
			onChange={operatorSelectOnChange}
			menuMinWidth={80}
			value={operatorSelectIndex}
			placeholder="condition"
			setParentRefZIndex={setParentRefZIndex}
		/>
	);
};

LogicOperatorSelect.propTypes = {
	options: PropTypes.any,
	setParentRefZIndex: PropTypes.any,
};

export default LogicOperatorSelect;
