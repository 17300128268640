import * as misc from 'src/utilities/misc';
import { useNavigate } from 'react-router-dom';

const useLicenseCheckoutRedirect = planDetails => {
	const navigate = useNavigate();
	const planDetailsLoaded = planDetails?.pricePointsData?.pricePoints?.length;
	if (!misc.isLocal() && !planDetailsLoaded) navigate('/studies', { replace: true });
};

export default useLicenseCheckoutRedirect;
