import * as actions from '../actions';

const INITIAL_STATE = {
	commonError: null,
	account: { loading: false, content: {}, error: null },
	subscription: { loading: false, content: {}, error: null },
	invoices: { loading: false, content: [], error: null },
	updateAccountModal: { visible: false, loading: false, error: null },
	updateCreditCardModal: { visible: false, loading: false, error: false, success: null },
	manageAccountClients: { clients: {} },
	deleteUserConfirmationModal: { loading: false, visible: false, userUuid: null, clientUuids: null },
	roles: { loading: false, content: [], error: null },
	users: { loading: false, content: [], error: null },
	userSorting: { field: 'name', direction: 'asc' },
	spaceSorting: { field: 'name', direction: 'asc' },
	accountModalState: { loading: false, visible: false, success: false },
	createAccountModal: { loading: false, visible: false, success: false },
	manageSubscriptionModal: { loading: false, visible: false, success: false },
	accountUsage: {},
	accounts: { content: [] },
	invitationStatus: {},
	accountBillingPeriods: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_COMMON_ERROR:
			return { ...state, commonError: action.payload.commonError };

		case actions.SET_ACCOUNT:
			return { ...state, account: action.payload.account };

		case actions.SET_ACCOUNT_SUBSCRIPTION:
			return { ...state, subscription: action.payload.subscription };

		case actions.SET_ACCOUNT_INVOICES:
			return { ...state, invoices: action.payload.invoices };

		case actions.SET_UPDATE_ACCOUNT_MODAL:
			return {
				...state,
				updateAccountModal: { ...state.updateAccountModal, ...action.payload.updateAccountModal },
			};

		case actions.SET_UPDATE_CREDIT_CARD_MODAL:
			return {
				...state,
				updateCreditCardModal: { ...state.updateCreditCardModal, ...action.payload.updateCreditCardModal },
			};

		case actions.SET_CREATE_ACCOUNT_MODAL:
			return {
				...state,
				createAccountModal: { ...state.createAccountModal, ...action.payload.createAccountModal },
			};

		case actions.SET_MANAGE_SUBSCRIPTION_MODAL:
			return {
				...state,
				manageSubscriptionModal: {
					...state.manageSubscriptionModal,
					...action.payload.manageSubscriptionModal,
				},
			};

		case actions.SET_ROLES:
			return { ...state, roles: { ...state.roles, ...action.payload.roles } };

		case actions.SET_USERS:
			return { ...state, users: { ...state.users, ...action.payload.users } };

		case actions.SET_DELETE_USER_CONFIRMATION_MODAL:
			return {
				...state,
				deleteUserConfirmationModal: {
					...state.deleteUserConfirmationModal,
					...action.payload.confirmationModal,
				},
			};
		case actions.SET_ACCOUNT_MODAL_STATE:
			return { ...state, accountModalState: { ...action.payload.accountModalState } };

		case actions.CREATE_CLIENT:
			return { ...state, createClient: action.payload.createClient };

		// case actions.DELETE_CLIENT:
		// 	return { ...state, deleteClient: action.payload.deleteClient };

		case actions.SET_LOADING:
			return { ...state, loading: action.payload.loading };

		case actions.SET_ERROR:
			return { ...state, error: action.payload.error };

		case actions.FETCH_ACCOUNT_USAGE:
			return { ...state, ...action.payload.accountUsage };

		case actions.SET_ACCOUNT_USAGE:
			return { ...state, ...action.payload.accountUsage };

		case actions.SET_ACCOUNT_USAGE_USER_COUNT:
			return {
				...state,
				accountUsage: {
					...state.accountUsage,
					usersCount: state.accountUsage.usersCount + action.payload.count,
				},
			};

		case actions.SET_ACCOUNTS:
			return { ...state, accounts: { ...state.accounts, ...action.payload.accounts } };

		case actions.CHANGE_USER_SORTING:
			return { ...state, userSorting: { ...state.userSorting, ...action.payload.sorting } };

		case actions.CHANGE_SPACE_SORTING:
			return { ...state, spaceSorting: { ...state.spaceSorting, ...action.payload.sorting } };

		case actions.SET_INVITATION_STATUS:
			return { ...state, invitationStatus: { [action.payload.email]: action.payload.status } };

		case actions.DELETE_CREDIT_CARD: {
			let { settings } = state.account.content;
			const { creditCardId } = action.payload;

			settings = { ...settings, creditCards: settings.creditCards.filter(cc => cc.id !== creditCardId) };
			return {
				...state,
				account: { ...state.account, content: { ...state.account.content, settings } },
			};
		}
		case actions.SET_CHARGIFY_SUBSCRIPTIONS:
			return { ...state, chargifySubscriptions: action.payload };
		case actions.SET_CHARGIFY_INVOICES:
			return { ...state, chargifyInvoices: action.payload };
		case actions.SET_CHARGIFY_USAGE:
			return { ...state, chargifyUsage: action.payload };
		case actions.SET_ACCOUNT_AUDIENCES:
			return { ...state, accountAudiences: action.payload };
		case actions.SET_ACCOUNT_BILLING_PERIODS:
			return { ...state, accountBillingPeriods: action.payload };

		default:
			return state;
	}
};
