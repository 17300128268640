import * as actions from '../actions';

const INITIAL_STATE = {
	user: null,
	loading: 0,
	error: null,
	submitButtonText: 'Update',
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_USER:
			return { ...state, user: action.payload.user };

		case actions.SET_USER_SETTINGS:
			return {
				...state,
				user: {
					...state.user,
					settings: action.payload.settings,
				},
			};

		case actions.INC_LOADING:
			return { ...state, loading: state.loading + 1 };

		case actions.DEC_LOADING:
			return { ...state, loading: state.loading - 1 };

		case actions.SET_ERROR:
			return { ...state, error: action.payload.error };

		case actions.SET_SUBMIT_BUTTON:
			return { ...state, submitButtonText: action.payload.submitBtnText };

		default:
			return state;
	}
};
