import React from 'react';
import { Tooltip } from 'react-tippy';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';

import './styles.scss';

const className = 'options-tooltip';
const el = (name, mod) => cn(className, name, mod);

const OptionsToolTip = ({ title = 'Options', label, options, renderCustomTooltipLabel, customClass = '' }) => (
	<div className={`${className} ${customClass}`}>
		<Tooltip
			className={el('tooltip')}
			animation="shift"
			animationFill={false}
			interactive
			trigger="mouseenter"
			position="top"
			theme="dark-blured"
			distance={2}
			html={
				<div className={el('tooltip-text-container')}>
					{title && <p>{title}</p>}
					{options.map((opt, i) => (
						<span key={i}>{renderCustomTooltipLabel ? renderCustomTooltipLabel(opt) : opt}</span>
					))}
				</div>
			}
		>
			{label}
		</Tooltip>
	</div>
);

OptionsToolTip.propTypes = {
	title: PropTypes.string,
	label: PropTypes.any,
	options: PropTypes.any,
	renderCustomTooltipLabel: PropTypes.func,
	customClass: PropTypes.string,
};

export default OptionsToolTip;
