import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

/*
 * Section Name Services
 */

const patchSectionName = (studyId, sectionId, statementName) =>
	axios
		.patch(`${API_PATH}/studies/${studyId}/sections/${sectionId}`, {
			name: statementName,
		})
		.then(({ data }) => data)
		.catch(err => {
			console.error(err);
		});

/*
 * Statement Services
 */
const postStatement = statementData => axios.post(`${API_PATH}/statements/`, statementData).then(({ data }) => data);

const patchStatement = (statementId, statementData) =>
	axios.patch(`${API_PATH}/statements/${statementId}`, statementData).then(({ data }) => data);

const deleteSection = (studyId, sectionId) =>
	axios.delete(`${API_PATH}/studies/${studyId}/sections/${sectionId}`).then(({ data }) => data);

export default {
	// Section Name Services
	patchSectionName,

	// Statement Services
	postStatement,
	patchStatement,
	deleteSection,
};
