import { getCheckoutData, getStudyData } from '../../selectors';

export const getAudiences = state => getCheckoutData(state).audiences;
export const getAudiencesAvailableForCheckout = state => getCheckoutData(state).audiencesAvailableForCheckout;
export const getStudy = state => getStudyData(state).study;
export const getLaunchStatuses = state => getCheckoutData(state).launchStatuses;
export const getClientId = state => getCheckoutData(state).clientId;
export const getStudyId = state => getCheckoutData(state).studyId;
export const getAudienceUuid = state => getCheckoutData(state).audienceUuid;
export const getIncidenceRateAccepted = state => getCheckoutData(state).incidenceRateAccepted;
export const getPreviewTax = state => getCheckoutData(state).previewTax;
export const getPreviousTotalTax = state => getCheckoutData(state).previousTotalTax;
export const getBalance = state => getCheckoutData(state).balance;
export const getLoadingTaxes = state => getCheckoutData(state).loadingTaxes;
export const getPreviewData = state => getCheckoutData(state).previewData;
