import React, { useState, useMemo, useRef, useLayoutEffect, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'src/utilities/bem-cn';
import { Iconof } from '@upsiide/ui-components';
import PropTypes from 'prop-types';
import Auth from 'src/utilities/auth';
import { useIsPublic, useDebounce } from 'src/hooks';
import Button from 'src/components/elements/Button';
import { patchStudy } from 'src/domains/manage-study/actions';
import * as blueprintActions from 'src/domains/manage-blueprints/actions';
import audienceService from 'src/services/audience.service';
import './styles.scss';

const className = 'study-description-manager';
const el = (name, mod) => cn(className, name, mod);

const MAX_DESCRIPTION_LENGTH = 150;
const MAX_DESCRIPTION_DISPLAY_CUTOFF = 75;

const StudyDescriptionManager = ({ study, audience, inModal = false }) => {
	const dispatch = useDispatch();

	const { isPublic } = useIsPublic();
	const canUpdate = !isPublic && Auth.userCan('study:update');
	const textInput = useRef(null);

	const description = study?.description?.trim() || audience?.description?.trim() || '';

	const [newDescription, setNewDescription] = useState(description);
	const [isEditMode, setIsEditMode] = useState(false);
	const [collapsed, setCollapsed] = useState(true);
	const debouncedStudyDescription = useDebounce(newDescription?.trim(), 500);

	const canExpand = newDescription?.length > MAX_DESCRIPTION_DISPLAY_CUTOFF;

	const characterCount = useMemo(() => newDescription?.length || 0, [newDescription?.length]);

	const truncatedDescription = useMemo(
		() => (canExpand ? `${newDescription.substring(0, MAX_DESCRIPTION_DISPLAY_CUTOFF)}...` : newDescription),
		[canExpand, newDescription],
	);

	useEffect(() => {
		if (!Auth.userCan('study:update')) {
			return;
		}

		const descriptionVal = debouncedStudyDescription?.trim();
		if (audience?.id) {
			// note: no await here on purpose to avoid blocking the UI
			audienceService.updateCollection(study?.id, audience?.id, {
				description: descriptionVal,
			});
		} else if (
			typeof debouncedStudyDescription === 'string' &&
			study &&
			debouncedStudyDescription !== (study?.description || '')
		) {
			dispatch(
				study?.type === 'blueprint'
					? blueprintActions.patchStudy(study?.id, { description: descriptionVal })
					: patchStudy(study?.id, { description: descriptionVal }),
			);
		}
		// eslint-disable-next-line
	}, [debouncedStudyDescription]);

	const adjustTextAreaHeight = () => {
		if (textInput?.current) {
			textInput.current.style.height = 'inherit';
			textInput.current.style.height = `${textInput.current.scrollHeight}px`;
		}
	};

	useLayoutEffect(adjustTextAreaHeight, []);

	const onDescriptionChange = newDesc => {
		setNewDescription(newDesc);
		adjustTextAreaHeight();
	};

	useEffect(() => {
		if (isEditMode) adjustTextAreaHeight();
	}, [isEditMode]);

	const emptyState = () =>
		canUpdate ? (
			<div className={el('empty-state-container')} onClick={() => setIsEditMode(true)} aria-hidden>
				<Iconof icon="add_circle" className={el('add-description-icon')} />
				<Button className={el('button')} label="Add Description" type="text" />
			</div>
		) : null;

	const editMode = () => {
		const descriptionType = study?.type === 'study' ? 'Study' : 'Template';

		return (
			<div className={el('edit-mode-container', inModal ? 'inModal' : '')}>
				<div className={el('edit-mode-header')}>
					<div className={el('text')}>Description</div>
					<div className={el('character-count')}>
						{characterCount} / {MAX_DESCRIPTION_LENGTH}
					</div>
				</div>
				<textarea
					className={el('description-container', 'edit')}
					name="description"
					value={newDescription}
					placeholder={`Enter ${descriptionType} Description`}
					onFocus={adjustTextAreaHeight()}
					onBlur={() => setIsEditMode(false)}
					onChange={e => onDescriptionChange(e.target.value)}
					maxLength={MAX_DESCRIPTION_LENGTH}
					ref={textInput}
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
				/>
			</div>
		);
	};

	const readMoreButton = () => (
		<Button
			className={el('button', 'show-more')}
			label={collapsed ? 'Show More' : 'Show Less'}
			onClick={e => {
				setCollapsed(!collapsed);
				e.stopPropagation();
			}}
			type="text"
		/>
	);

	const displayMode = () => (
		<div className={el('display-mode-container', inModal ? 'inModal' : '')}>
			<div className={el('display-mode-header')}>
				<div className={el('text')}>Description</div>
			</div>
			<div
				className={el('display-mode-body', canUpdate ? 'canUpdate' : '')}
				onClick={() => canUpdate && setIsEditMode(true)}
				aria-hidden
			>
				<div className={el('description-container', `${canExpand && collapsed ? 'display-less' : ''}`)}>
					{canExpand && collapsed ? `${truncatedDescription} ` : `${newDescription} `}
					{canExpand ? readMoreButton() : null}
				</div>
			</div>
		</div>
	);

	if (isEditMode) return editMode();
	if ((!newDescription || newDescription?.trim()?.length === 0) && !isEditMode) return emptyState();
	return displayMode();
};

StudyDescriptionManager.propTypes = {
	study: PropTypes.object,
	audience: PropTypes.object,
	inModal: PropTypes.bool,
};

export default StudyDescriptionManager;
