import * as services from 'src/services';
import * as actions from '../actions';

const confirmToken = async (store, { type, payload }) => {
	if (type === actions.CONFIRM_RESET_PASSWORD_TOKEN) {
		try {
			const { token } = payload;
			store.dispatch(actions.incLoading());
			await services.userService.checkPasswordToken(token);
			store.dispatch(actions.setToken(token));
			store.dispatch(actions.decLoading());
		} catch (error) {
			store.dispatch(actions.setError(error));
			store.dispatch(actions.decLoading());
			console.group('Error while checking password token');
			console.error('error:', error);
			console.groupEnd();
		}
	}
};

const updatePassword = async (store, { type, payload }) => {
	if (type === actions.UPDATE_PASSWORD) {
		try {
			store.dispatch(actions.incLoading());
			store.dispatch(actions.setError(false));

			await services.userService.updatePassword(payload.user);

			store.dispatch(actions.setResetComplete(true));
			store.dispatch(actions.decLoading());
		} catch (error) {
			store.dispatch(actions.setError(error));
			store.dispatch(actions.decLoading());
			console.group('Error while updating User');
			console.error('error:', error);
			console.groupEnd();
		}
	}
};

export default [confirmToken, updatePassword];
