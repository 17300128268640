import allAuthEffects from './auth/effects';
import allStudiesEffects from './all-studies/effects';
import allBlueprintsEffects from './all-blueprints/effects';
import createStudyEffects from './create-study/effects';
import manageStudyEffects from './manage-study/effects';
import studyEffects from './manage-study/effects/reporting';
import publicStudyEffects from './public-study/effects';
import publicReportEffects from './public-study/effects/reporting';
import manageBlueprintsEffects from './manage-blueprints/effects';
import loginUserEffects from './login/effects';
import clientEffects from './client/effects';
import clientsEffects from './clients/effects';
import createClientEffects from './create-client/effects';
import userProfileEffects from './profile/effects';
import userPasswordResetEffects from './reset-password/effects';
import userAccountSetupEffects from './account-setup/effects';
import accountEffects from './account/effects';
import adminEffects from './admin/effects';
import signUpEffects from './signup/effects';
import confirmSignUpEffects from './confirm-signup/effects';
import confirmEmailEffects from './validate-email/effects';
import billingDetails from './billing-details/effects';
import accountDetails from './account-details/effects';
import inviteUsers from './invite-users/effects';
import checkoutEffects from './checkout/effects';

export default [
	...allAuthEffects,
	...allStudiesEffects,
	...allBlueprintsEffects,
	...studyEffects,
	...createStudyEffects,
	...manageStudyEffects,
	...publicStudyEffects,
	...publicReportEffects,
	...loginUserEffects,
	...clientEffects,
	...createClientEffects,
	...userProfileEffects,
	...userPasswordResetEffects,
	...userAccountSetupEffects,
	...accountEffects,
	...clientsEffects,
	...adminEffects,
	...signUpEffects,
	...confirmSignUpEffects,
	...billingDetails,
	...accountDetails,
	...inviteUsers,
	...checkoutEffects,
	...manageBlueprintsEffects,
	...confirmEmailEffects,
];
