import axios from '../utilities/axios';

const getData = ({ data }) => data;
const API_PATH = `${process.env.apiPath}`;
const ASSET_UPLOAD_URL = process.env.assetUploadUrl ? `${process.env.assetUploadUrl}` : `${API_PATH}/assets/upload`;
const ASSET_ADD_VIDEO_URL = `${API_PATH}/assets/add/video-embed`;
const ASSET_VALIDATE_URL = `${API_PATH}/assets/validate-video`;

const UPLOAD_FILE_OPTIONS = {
	headers: {
		'Content-Type': 'multipart/form-data',
	},
};

const ADD_VIDEO_OPTIONS = {
	headers: { 'Content-Type': 'application/json' },
};

const buildQuery = params => {
	const esc = encodeURIComponent;
	const query = Object.keys(params)
		.map(k => `${esc(k)}=${esc(params[k])}`)
		.join('&');

	return query;
};
let getUnsplashCancelToken = null;
export default {
	get: (searchString, limit = 20, offset = 0, studyId = null) => {
		if (getUnsplashCancelToken) {
			getUnsplashCancelToken.cancel('Operation cancelled due to new request.');
		}
		const { CancelToken } = axios;
		getUnsplashCancelToken = CancelToken.source();

		const params = {
			search: searchString,
			sizes: 'thumbnail,medium,full',
			excludeVideo: true,
			limit,
			offset,
			...(studyId ? { studyId } : ''),
		};
		const query = buildQuery(params);
		return axios
			.get(`${API_PATH}/assets?${query}`, {
				cancelToken: getUnsplashCancelToken.token,
			})
			.then(data => {
				getUnsplashCancelToken = null;
				return getData(data);
			})
			.catch(function(thrown) {
				console.log(thrown.message);
			});
	},
	getOne: id => axios.get(`${API_PATH}/assets/${id}?sizes=thumbnail,medium,video`).then(getData),
	post: asset => {
		const formData = new FormData();
		formData.append('asset', asset);
		return axios.post(ASSET_UPLOAD_URL, formData, UPLOAD_FILE_OPTIONS).then(getData);
	},
	add: url => axios.post(ASSET_ADD_VIDEO_URL, { url }, ADD_VIDEO_OPTIONS).then(getData),
	validateVideo: url => axios.post(ASSET_VALIDATE_URL, { url }).then(getData),
	addUnsplash: data => axios.post(`${API_PATH}/assets/add/unsplash`, data).then(getData),
	searchUnsplash: (searchString, perPage = 30, page = 0) => {
		const params = {
			...(searchString ? { search: searchString } : ''),
			pageLimit: perPage,
			page,
		};

		const query = buildQuery(params);

		return axios.get(`${API_PATH}/assets/search/unsplash?${query}`).then(getData);
	},
	toBase64: url => axios.get(`${API_PATH}/assets/to-base64?source=${url}`).then(getData),
	delete: assetId => axios.delete(`${API_PATH}/assets/remove/${assetId}`).then(getData),
};
