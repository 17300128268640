import * as actions from '../actions';

const INITIAL_STATE = {
	accounts: { loading: true, content: [] },
	clients: [],
	searchQuery: null,
	blueprint: null,
	blueprints: {
		content: [],
		loading: false,
		hasBlueprints: false,
		sortBy: null,
		sortDirection: 'asc',
		blueprintIdToHighlight: null,
	},
	blueprintFilters: {},
	error: null,
	accountUuid: null,
	clientsFromAccount: [],
	clientId: null,
	sections: { status: 'loading', content: null },
	languages: null,
	geographies: [],
	loading: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actions.SET_CLIENTS:
			return { ...state, clients: action.payload.clients };

		case actions.SET_SEARCH_QUERY:
			return { ...state, searchQuery: action.payload.searchQuery };

		case actions.SET_BLUEPRINT:
			return { ...state, blueprint: action.payload.blueprint };

		case actions.SET_BLUEPRINTS:
			return {
				...state,
				blueprints: { ...state.blueprints, ...action.payload.blueprints },
			};

		case actions.SET_BLUEPRINT_FILTERS:
			return { ...state, blueprintFilters: action.payload.blueprintFilters };

		case actions.SET_ERROR:
			return { ...state, error: action.payload.error };

		case actions.FETCH_CLIENTS_FROM_ACCOUNT:
			return { ...state, accountUuid: action.payload.accountUuid };

		case actions.SET_CLIENTS_FROM_ACCOUNT:
			return { ...state, clientsFromAccount: action.payload.clientsFromAccount };

		case actions.SET_CLIENT_ID:
			return { ...state, clientId: action.payload.clientId };

		case actions.SET_SECTION:
			return { ...state, currentSection: { ...state.currentSection, ...action.payload.currentSection } };

		case actions.SET_SECTIONS:
			return { ...state, sections: action.payload.sections };

		case actions.SET_LANGUAGES:
			return { ...state, languages: action.payload.languages };

		case actions.SET_GEOGRAPHIES:
			return { ...state, geographies: action.payload.geographies };

		case actions.INC_LOADING:
			return { ...state, loading: state.loading + 1 };

		case actions.DEC_LOADING:
			return { ...state, loading: state.loading - 1 };

		case actions.SET_ACCOUNTS:
			return { ...state, accounts: { ...state.accounts, ...action.payload.accounts } };

		default:
			return state;
	}
};
