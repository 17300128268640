import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

function getResponseData(studyId, filters = [], sectionId = null, audienceUuids = []) {
	return axios.post(`${API_PATH}/studies/${studyId}/responseData`, {
		filters,
		audienceUuids: audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals'),
		sectionId,
	});
}

function getPublicResponseData(studyId, filters = [], sectionId = null, audienceUuids = []) {
	return axios.post(`${API_PATH}/public/studies/${studyId}/responseData`, {
		filters,
		audienceUuids: audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals'),
		sectionId,
	});
}

/**
 * Get report data for an audience (custom targets)
 * @param {*} studyId
 * @param {*} audienceUuid
 * @param {*} data
 * /studies/:studyId/audience/:audienceUuid/report
 */
function getAudienceReportData(studyId, audienceUuid, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/report`, data);
}

/**
 * Get report data for an audience (custom targets)
 * @param {*} studyId
 * @param {*} audienceUuid
 * @param {*} data
 * /studies/:studyId/audience/:audienceUuid/report
 */
function getPublicAudienceReportData(studyId, audienceUuid, data) {
	return axios.post(`${API_PATH}/public/studies/${studyId}/audiences/${audienceUuid}/report`, data);
}

/**
 * Get report data for specific study
 * @param {*} studyId
 * @param {*} data
 * sectionId is required. audienceUuids, filters and languages should be array
 */
function getReportData(studyId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/report`, {
		...data,
		audienceUuids: data?.audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals')
	});
}

/**
 * Get report data for specific study
 * @param {*} studyId
 * @param {*} data
 * sectionId is required. audienceUuids, filters and languages should be array
 */
function getPublicReportData(studyId, data) {
	return axios.post(`${API_PATH}/public/studies/${studyId}/report`, {
		...data,
		audienceUuids: data?.audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals')
	});
}

/**
 * Get report data for specific question
 * @param {*} questionId
 * @param {*} data
 * questionId is required. in body audienceUuids, filters, localFilters, and languages should be an array
 */
function getQuestionReportData(studyId, questionId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/questions/${questionId}/report`, {
		...data,
		audienceUuids: data?.audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals')
	});
}

/**
 * Get report data for specific question
 * @param {*} questionId
 * @param {*} data
 * questionId is required. in body audienceUuids, filters, localFilters, and languages should be an array
 */
function getPublicQuestionReportData(studyId, questionId, data) {
	return axios.post(`${API_PATH}/public/studies/${studyId}/questions/${questionId}/report`, {
		...data,
		audienceUuids: data?.audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals')
	});
}

/**
 * Get report data for specific Idea Split question
 * @param {*} questionId
 * @param {*} data
 * questionId is required. in body audienceUuids, filters, localFilters, and languages should be an array
 */
function getIdeaSplitQuestionReportData(studyId, sectionId, questionId, data) {
	return axios.post(`${API_PATH}/studies/${studyId}/sections/${sectionId}/questions/${questionId}/report`, {
		...data,
		audienceUuids: data?.audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals')
	});
}

/**
 * Get report data for specific Idea Split question
 * @param {*} questionId
 * @param {*} data
 * questionId is required. in body audienceUuids, filters, localFilters, and languages should be an array
 */
function getPublicIdeaSplitQuestionReportData(studyId, sectionId, questionId, data) {
	return axios.post(
		`${API_PATH}/public/studies/${studyId}/sections/${sectionId}/questions/${questionId}/report`,
		{
			...data,
			audienceUuids: data?.audienceUuids?.filter((audienceUuid) => audienceUuid !== 'exclude-disquals')
		},
	);
}

export default {
	getPublicQuestionReportData,
	getAudienceReportData,
	getPublicAudienceReportData,
	getIdeaSplitQuestionReportData,
	getPublicIdeaSplitQuestionReportData,
	getQuestionReportData,
	getResponseData,
	getPublicResponseData,
	getReportData,
	getPublicReportData,
};
