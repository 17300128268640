import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { Button } from '@upsiide/ui-components';
import { AnimatePresence } from 'framer-motion';
import { useDebounce } from 'src/hooks';
import ResponsiveModal from 'src/components/shared/ResponsiveModal';
import PreviewTemplateModal from 'src/components/shared/PreviewTemplateModal';
import { TemplateCardsContext } from 'src/components/shared/TemplateCard/context';
import { audienceService, blueprintService } from 'src/services';
import audienceCollectionService from 'src/services/audience.service';
import { useSelector } from 'react-redux';
import { getDuplicateAudienceTemplateName } from 'src/utilities/misc';
import * as authSelectors from 'src/domains/auth/selectors';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import StudyTemplateHeader from './StudyTemplateHeader';
import StudyTemplates from './StudyTemplates';
import AudienceTemplateEditor from '../AudienceTemplateModal/AudienceTemplateEditor';
import Loader from '../../../../components/shared/Loader';

import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'study-template-modal';

const StudyTemplateModal = ({
	onClose,
	selectClicked,
	step = 'templates',
	setStep,
	data,
	setTemplate,
	currentTab,
	setCurrentTab,
	defaultActiveTab = 'studyTemplate',
	saveTemplateCallback,
	useTemplateCallback = null,
	isWizard = false,
}) => {
	const navigate = useNavigate();
	const [query, setQuery] = useState('');
	const [searchLoading, setSearchLoading] = useState(false);
	const [selectedCountries, setSelectedCountries] = useState([]);
	const [selectedLanguages, setSelectedLanguages] = useState([]);
	const debouncedQuery = useDebounce(query, 1000);

	const clientId = useSelector(authSelectors.getClientId);
	const [selectedLanguage, setSelectedLanguage] = useState(null);
	const [templateModalCurrentAudience, setTemplateModalCurrentAudience] = useState(null);
	const [selectedAudienceTemplate, setSelectedAudienceTemplate] = useState(null);
	const [templateTypeTab, setTemplateTypeTab] = useState(data?.defaultTabOverride || defaultActiveTab);

	const selectedTemplateCard = useSelector(manageStudySelectors.getSelectedTemplateCard);

	const { onSelect, onShowPreview, templateModalData, useTemplateLoading, handleSetStep } =
		useContext(TemplateCardsContext);

	const languages = useMemo(() => {
		const langs = [];
		if (selectedAudienceTemplate?.demographicGroups?.length) {
			selectedAudienceTemplate?.demographicGroups.forEach(group => {
				if (!langs.some(l => l.languageCode === group?.languageCode))
					langs.push({
						...group.countryLanguage,
						languageCode: group.languageCode,
					});
			});

			if (
				!selectedAudienceTemplate?.demographicGroups.some(
					group => group?.languageCode === selectedLanguage?.languageCode,
				)
			) {
				if (
					selectedAudienceTemplate?.demographicGroups?.[0]?.countryLanguage &&
					selectedAudienceTemplate?.demographicGroups?.[0]?.languageCode
				) {
					setSelectedLanguage({
						...selectedAudienceTemplate.demographicGroups[0].countryLanguage,
						languageCode: selectedAudienceTemplate.demographicGroups[0].languageCode,
					});
				}
			}
		}

		if (selectedAudienceTemplate?.screeningQuestions?.length) {
			selectedAudienceTemplate?.screeningQuestions.forEach(question => {
				const translationLanguages = question.translations?.map(({ languageCode, languageLabel }) => ({
					languageCode,
					languageLabel,
				}));
				for (const translationLanguage of translationLanguages) {
					if (!langs.some(l => l.languageCode === translationLanguage?.languageCode)) {
						langs.push(translationLanguage);
					}
				}
			});
		}

		return langs.filter(l => !!l.languageCode);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAudienceTemplate]);

	useEffect(() => {
		if (debouncedQuery) setSearchLoading(true);
	}, [debouncedQuery]);

	const onGoBack = useCallback(() => {
		setQuery('');
		setStep('templates');
	}, [setStep]);

	const onPreview = useCallback(
		async template => {
			setStep('preview');
			setTemplate(template);
		},
		[setStep, setTemplate],
	);

	const onCloseModal = useCallback(() => {
		setQuery('');

		onClose();
	}, [onClose]);

	const copyToMyTemplates = useCallback(
		async (template, blueprintAccessLevel = 'space') => {
			try {
				if (template.type !== 'audience') {
					const result = await blueprintService.createBlueprintFromStudyOrDuplicate(
						template.id,
						clientId,
						blueprintAccessLevel,
						false,
					);
					toastr.success(
						`<div style="display: flex; justify-content: space-between; align-items: center; color: #3b3b3b;">
							<p style="font-size: 14px; font-weight: 400;">Template copied to 'My Templates' successfully!</p>
							<p style="color: #008855; padding-left:54px; font-size: 14px; font-weight: 600">View Template</p>
						</div>`,
						null,
						{
							onclick: () => navigate(`/templates/${result.data.uuid}/create`),
						},
					);
				} else {
					const result = await audienceService.saveCollectionAsTemplate(template.studyId, {
						collectionId: template.id,
						languageCode: 'en',
						description: template.description || '',
						name: getDuplicateAudienceTemplateName(template.name),
					});
					toastr.success(
						`<div style="display: flex; justify-content: space-between; align-items: center; color: #3b3b3b;">
							<p style="font-size: 14px; font-weight: 400;">Template copied to 'My Templates' successfully!</p>
							<p style="color: #008855; padding-left:54px; font-size: 14px; font-weight: 600">View Template</p>
						</div>`,
						null,
						{
							onclick: () => {
								const templateData = {
									type: 'audience',
									audienceCollectionId: result.data.id,
									id: result.data.id,
								};
								handleSetStep('preview', {
									visible: true,
									selectedTemplate: templateData,
									isMultiStep: false,
								});
							},
						},
					);
				}
				onCloseModal();
			} catch (e) {
				toastr.error("Error copying template to 'My Templates', Please try again later.");
				console.error(e);
			}
		},
		[clientId, onCloseModal, handleSetStep, navigate],
	);

	const handleOnDone = useCallback(async () => {
		try {
			if (data.isMultiStep) {
				const response = await audienceCollectionService.getCollection(
					data?.selectedTemplate?.studyId,
					data?.selectedTemplate?.id,
				);
				setStep('preview', {
					selectedTemplate: { ...response.data, type: 'audience', audience: response.data },
					isMultiStep: true,
				});
			} else {
				onCloseModal();
			}
		} catch (e) {
			console.error(e);
		}
	}, [data, onCloseModal, setStep]);

	return (
		<ResponsiveModal
			show={data.visible}
			onClose={() => {
				onCloseModal();
			}}
			customContent
			customClass={el('audience-template-modal-blur')}
		>
			<div className={className} style={step === 'preview' ? {} : { paddingTop: 16 }}>
				{selectClicked && (
					<div className={el('selected-loader')}>
						<Loader centered />
					</div>
				)}

				{step === 'preview' ? (
					<PreviewTemplateModal
						modalMode={data.previewModalMode || false}
						mode={data.previewMode || 'preview'}
						show
						template={data?.selectedTemplate}
						includeBack={isWizard ? false : data?.isMultiStep}
						goBack={() => {
							setStep('templates');
						}}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
						onClose={onCloseModal}
						saveTemplateCallback={saveTemplateCallback}
						copyToMyTemplatesCallback={copyToMyTemplates}
						useTemplateCallback={isWizard ? useTemplateCallback : null}
						templateModalCurrentAudience={templateModalCurrentAudience}
						setTemplateModalCurrentAudience={setTemplateModalCurrentAudience}
					/>
				) : (
					<>
						<StudyTemplateHeader
							showModal={data.visible}
							step={step}
							query={query}
							setQuery={setQuery}
							onGoBack={onGoBack}
							onClose={onCloseModal}
							includeBack={data?.isMultiStep}
							selectedCountries={selectedCountries}
							setSelectedCountries={setSelectedCountries}
							selectedLanguages={selectedLanguages}
							setSelectedLanguages={setSelectedLanguages}
							setSearchLoading={setSearchLoading}
							selectedTemplate={data?.selectedTemplate}
							setSelectedAudienceTemplate={setSelectedAudienceTemplate}
							templateTypeTab={templateTypeTab}
						/>
						<AnimatePresence>
							{data?.step === 'editTemplate' && data?.selectedTemplate ? (
								<AudienceTemplateEditor
									audienceTemplate={data.selectedTemplate}
									setSelectedAudienceTemplate={setSelectedAudienceTemplate}
									onSelect={null}
									step={step}
									setStep={setStep}
									onClose={onCloseModal}
									onCopy={null}
									currentTab={currentTab}
									setCurrentTab={setCurrentTab}
									languages={languages}
									selectedLanguage={selectedLanguage}
									setSelectedLanguage={setSelectedLanguage}
									templateModalCurrentAudience={templateModalCurrentAudience}
									setTemplateModalCurrentAudience={setTemplateModalCurrentAudience}
									onDone={handleOnDone}
								/>
							) : (
								<StudyTemplates
									query={debouncedQuery}
									selectedCountries={selectedCountries}
									selectedLanguages={selectedLanguages}
									onPreview={onPreview}
									selectClicked={selectClicked}
									searchLoading={searchLoading}
									setSearchLoading={setSearchLoading}
									setSelectedCountries={setSelectedCountries}
									setSelectedLanguages={setSelectedLanguages}
									onClose={onClose}
									activeTab={templateTypeTab}
									setActiveTab={setTemplateTypeTab}
									defaultActiveTab={defaultActiveTab}
								/>
							)}
						</AnimatePresence>
					</>
				)}
				{templateModalData?.step === 'templates' && (
					<div className={el('footer')}>
						<div className={el('buttons-container')}>
							<Button
								label="Preview Template"
								variant="outlined"
								disabled={useTemplateLoading || !selectedTemplateCard}
								onClick={() => onShowPreview(selectedTemplateCard, { isMultiStep: true })}
							/>
							<Button
								label="Use Template"
								loading={useTemplateLoading}
								disabled={!selectedTemplateCard}
								onClick={() => {
									onSelect(selectedTemplateCard);
								}}
							/>
						</div>
					</div>
				)}
			</div>
		</ResponsiveModal>
	);
};

StudyTemplateModal.displayName = 'StudyTemplateModal';
StudyTemplateModal.propTypes = {
	onClose: PropTypes.any,
	selectClicked: PropTypes.bool,
	step: PropTypes.string,
	setStep: PropTypes.func,
	data: PropTypes.object,
	setTemplate: PropTypes.func,
	defaultActiveTab: PropTypes.string,
	saveTemplateCallback: PropTypes.func,
	useTemplateCallback: PropTypes.any,
	isWizard: PropTypes.bool,
	currentTab: PropTypes.string,
	setCurrentTab: PropTypes.func,
};

export default StudyTemplateModal;
