import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Cookies from 'src/utilities/cookies';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import Auth from 'src/utilities/auth';
import withRouter from 'src/hocs/withRouter';
import { useIsMobile } from 'src/hooks';
import Sparkle from 'src/components/icons/Sparkle';
import MobileAccountMenu from 'src/domains/header/components/MobileAccountMenu';

import './styles.scss';

const className = 'personal-account-dropdown';
const el = (name, mod) => cn(className, name, mod);

const getMainDisplayName = ({ user, loggedIn }) => {
	if (user && loggedIn) {
		// If you are logged in as a ghost user then use the display name of the stored display name
		if (Cookies.getOriginalToken()) {
			return Cookies.getOriginalTokenData().user.displayName;
		}
		// If first name and last name exists then return the users full name
		if (user.firstName && user.lastName) {
			return `${user.firstName} ${user.lastName}`;
		}
		// If first name or last name doesn't exist then use the username
		if (user.username) {
			return user.username;
		}
		// Otherwise just use the email address
		return user.email;
	}
	return '';
};

const ToggleComponent = ({ user, loggedIn, ghost }) => {
	const getGhostDisplayName = () =>
		user && loggedIn && Cookies.getOriginalToken() ? Cookies.getTokenData().user.displayName : '';

	const getDisplayInitials = displayName => {
		// If the user is a guest just use 'G'
		if (displayName.toLowerCase().indexOf('guest') !== -1) {
			return 'G';
		}
		// If the user has only one name like "John" vs "John Doe" just use "J"
		if (displayName.indexOf(' ') === -1) {
			return displayName.charAt(0).toUpperCase();
		}
		// Otherwise grab the first initial of the first name and the first initial of the last name
		return displayName.charAt(0).toUpperCase() + displayName.charAt(displayName.indexOf(' ') + 1).toUpperCase();
	};

	const mainDisplayName = getMainDisplayName({ user, loggedIn });
	const ghostDisplayName = getGhostDisplayName();
	const loggedInAsText = 'Logged in as';

	if (ghost) {
		return (
			ghostDisplayName.length > 0 && (
				<>
					<div className={el('logged-in-as')}>
						<div className={el('logged-in-as-text')}>{loggedInAsText}</div>
						<div className={el('logged-in-as-name')}>{ghostDisplayName}</div>
					</div>
					<div className={el('initials')}>{getDisplayInitials(ghostDisplayName)}</div>
				</>
			)
		);
	}
	return (
		<div>
			<div className={el('initials')}>{getDisplayInitials(mainDisplayName)}</div>
		</div>
	);
};
ToggleComponent.propTypes = {
	user: PropTypes.any,
	loggedIn: PropTypes.any,
	ghost: PropTypes.any,
};

const PersonalAccountDropdownDesktop = ({
	accounts,
	ghost,
	goToSupport,
	loggedIn,
	logout,
	switchToOriginalUser,
	user,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const navigate = useNavigate();

	const toggleDropdown = () => {
		setDropdownOpen(prevState => !prevState);
	};

	const renderAccountSettingsOption = () => {
		// Check permissions if we should even render the account summary dropdown item
		if (!accounts || !accounts.content) {
			return false;
		}

		const upsiideAccountToken = Cookies.getUpsiideAccountToken();
		const accountSettingsAccess = upsiideAccountToken
			? accounts.content.filter(a => a.uuid === upsiideAccountToken)
			: accounts.content;
		const permission = accountSettingsAccess[0] ? accountSettingsAccess[0].permission : '';
		const accountHasPermission = permission === 'CAN_MANAGE' || permission === 'CAN_VIEW_DETAILS';
		const pathName = window.location.pathname;
		// Setup text for dropdown item
		const accountSettingsText = 'Account Settings';
		// If user can list accounts and has permission render the dropdown item
		if (accountHasPermission) {
			return (
				<DropdownItem
					className={el('item')}
					onClick={() => navigate('/account/billing')}
					active={pathName.includes('/account')}
				>
					{accountSettingsText}
				</DropdownItem>
			);
		}
		return false;
	};

	const renderDashboardLink = () => {
		const pathName = window.location.pathname;
		const profileText = 'Dashboard';
		const isDigEmployee = Auth.userCan('dig:staff', true);
		const isDigAdmin = Auth.isDigAdmin();
		if (isDigEmployee && !isDigAdmin) {
			return (
				<DropdownItem
					className={el('item')}
					onClick={() => navigate('/clients')}
					active={pathName === '/clients'}
				>
					{profileText}
				</DropdownItem>
			);
		}
		return false;
	};

	const renderProfileOption = () => {
		const pathName = window.location.pathname;
		const profileText = 'Profile';
		return (
			<DropdownItem className={el('item')} onClick={() => navigate('/me')} active={pathName === '/me'}>
				{profileText}
			</DropdownItem>
		);
	};

	const renderSupportOption = () => {
		const isTrialUser = Auth.userCan('user:trial', true) && !Auth.isDigAdmin();
		const supportText = 'Support';
		const whatsNewText = "What's New";
		if (!isTrialUser) {
			return (
				<>
					<DropdownItem className={el('item')} onClick={goToSupport}>
						{supportText}
					</DropdownItem>
					<DropdownItem
						className={el('item has-icon')}
						href="https://news.upsiide.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Sparkle />
						{whatsNewText}
					</DropdownItem>
				</>
			);
		}
		return false;
	};

	const renderSwitchBackOption = () => {
		const mainDisplayName = getMainDisplayName({ user, loggedIn });
		const switchBackText = `Switch back to ${mainDisplayName}`;
		if (ghost) {
			return (
				<DropdownItem className={el('item')} onClick={() => switchToOriginalUser()}>
					{switchBackText}
				</DropdownItem>
			);
		}
		return false;
	};

	const renderLogoutOption = () => {
		const logoutText = 'Logout';
		return (
			<DropdownItem
				className={el('item')}
				onClick={() => {
					// Remove the stored cookie before logging out
					Cookies.removeUpsiideAccountToken();
					// Logout
					logout();
				}}
			>
				{logoutText}
			</DropdownItem>
		);
	};

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className={className}>
			<div className={ghost ? 'ghost' : ''}>
				<DropdownToggle className={el('toggle')}>
					<ToggleComponent user={user} loggedIn={loggedIn} ghost={ghost} />
				</DropdownToggle>
			</div>
			<DropdownMenu className={el('menu')} end>
				{renderAccountSettingsOption()}
				{renderDashboardLink()}
				{renderProfileOption()}
				<DropdownItem className={el('divider')} divider />
				{renderSupportOption()}
				<DropdownItem className={el('divider')} divider />
				{renderSwitchBackOption()}
				{renderLogoutOption()}
			</DropdownMenu>
		</Dropdown>
	);
};

PersonalAccountDropdownDesktop.propTypes = {
	accounts: PropTypes.any,
	ghost: PropTypes.any,
	goToSupport: PropTypes.any,
	loggedIn: PropTypes.any,
	logout: PropTypes.any,
	switchToOriginalUser: PropTypes.any,
	user: PropTypes.any,
};

const PersonalAccountDropdownMobile = ({
	accounts,
	ghost,
	goToSupport,
	loggedIn,
	logout,
	switchToOriginalUser,
	user,
}) => {
	const [showMenu, setShowMenu] = useState(false);

	return (
		<>
			<button
				className={el('mobile-account-menu-button')}
				onClick={() => setShowMenu(!showMenu)}
				type="button"
				tabIndex="0"
			>
				<ToggleComponent user={user} loggedIn={loggedIn} ghost={ghost} />
			</button>
			{!!showMenu && <MobileAccountMenu setShowMenu={setShowMenu} goToSupport={goToSupport} logout={logout} />}
		</>
	);
};
PersonalAccountDropdownMobile.propTypes = {
	accounts: PropTypes.any,
	ghost: PropTypes.any,
	goToSupport: PropTypes.any,
	loggedIn: PropTypes.any,
	logout: PropTypes.any,
	switchToOriginalUser: PropTypes.any,
	user: PropTypes.any,
};

const PersonalAccountDropdown = props => {
	const isMobile = useIsMobile(960);

	if (isMobile) {
		return <PersonalAccountDropdownMobile {...props} />;
	}

	return <PersonalAccountDropdownDesktop {...props} />;
};

PersonalAccountDropdown.propTypes = {
	accounts: PropTypes.any,
	ghost: PropTypes.any,
	goToSupport: PropTypes.any,
	loggedIn: PropTypes.any,
	logout: PropTypes.any,
	switchToOriginalUser: PropTypes.any,
	user: PropTypes.any,
};

export default withRouter(PersonalAccountDropdown);
