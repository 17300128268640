import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as accountSelectors from 'src/domains/account/selectors';
import cn from 'src/utilities/bem-cn';
import misc from 'src/utilities/misc';
import { forYouCategories } from '../../audienceTemplateCategories.mock';
import { getForYouCategories } from '../../../../../account/selectors';
import './styles.scss';

const className = 'audience-templates-sidebar';
const el = (name, mod) => cn(className, name, mod);
const isSelected = (selectedCategory, label, id) => selectedCategory === id || selectedCategory === label;

const AudienceTemplatesSidebar = memo(({ categories, selectedCategory, onSelectCategory }) => {
	const accountForYouCategories = useSelector(getForYouCategories);

	return (
		<div className={className}>
			<div className={`${el('categories')} ${categories?.length ? '' : 'remove-padding'}`}>
				<h3 className={`${el('category-title')}`}>For you</h3>
				<div className={el('category-list')}>
					{accountForYouCategories.map(({ id, label, asset }) => (
						<button
							key={id}
							type="button"
							className={`category-list-item ${
								isSelected(selectedCategory, label, id) ? 'selected' : ''
							}`}
							onClick={() => onSelectCategory(label)}
						>
							{asset && (
								<img
									className={el('image-wrapper')}
									src={misc.getAssetVariationUrl(asset, ['thumbnail'])}
									alt={asset?.title}
								/>
							)}
							{label}
						</button>
					))}
				</div>
			</div>

			{!!categories?.length && (
				<div className={el('categories')}>
					<h3 className={`${el('category-title')}`}>Categories</h3>
					<div className={el('category-list')}>
						{categories?.map(({ value, label, asset }) => (
							<button
								key={value}
								type="button"
								className={`category-list-item ${selectedCategory === label ? 'selected' : ''}`}
								onClick={() => onSelectCategory(label)}
							>
								{asset && (
									<img
										className={el('image-wrapper')}
										src={misc.getAssetVariationUrl(asset, ['thumbnail'])}
										alt={asset?.title}
									/>
								)}
								{label}
							</button>
						))}
					</div>
				</div>
			)}
		</div>
	);
});

AudienceTemplatesSidebar.displayName = 'AudienceTemplatesSidebar';
AudienceTemplatesSidebar.propTypes = {
	categories: PropTypes.array,
	selectedCategory: PropTypes.string,
	onSelectCategory: PropTypes.func,
};

export default AudienceTemplatesSidebar;
