import React, { useContext, useMemo, useState, useRef, useCallback } from 'react';
import cn from 'src/utilities/bem-cn';
import PropTypes from 'prop-types';
import Button from 'src/components/elements/Button';
import * as accountSelectors from 'src/domains/account/selectors';
import { useSelector } from 'react-redux';
import useOutsideClick from 'src/hooks/useOutsideClick';
import logoUser from 'public/images/audience/user.png';
import lampImgSrc from 'public/images/audience/lamp.png';
import logo from 'public/images/signup/upsiide-logo-small.svg';
import audienceStatusUtil from 'src/utilities/audiences-status-error-helper';
import { TemplateCardsContext } from '../../../TemplateCard/context';

import './styles.scss';

const el = (name, mod) => cn(className, name, mod);
const className = 'template-preview-modal-footer';

const TemplatePreviewFooter = ({
	mode,
	saveAudience,
	saveStudy,
	onClose,
	saveTemplateCallback,
	useTemplateCallback,
	copyToMyTemplatesCallback,
	owner,
	type,
	templateEditCallback,
	duplicateTemplateCallback,
	onDeleteCallback,
	localTemplateName,
}) => {
	const subMenuRef = useRef(null);

	const [disableButtons, setDisableButtons] = useState(false);
	const [disableCopy, setDisableCopy] = useState(false);
	const [showAudienceSubMenu, setShowAudienceSubMenu] = useState(false);

	const containsLiveOrCompleteGroup =
		audienceStatusUtil.haveCompleteAudienceGroups() || audienceStatusUtil.haveLiveAudienceGroups();

	const { selectClicked, templateModalData, onCloseTemplateModal, disableMoreOptions } =
		useContext(TemplateCardsContext);

	const [savingTemplate, setSavingTemplate] = useState(false);
	const account = useSelector(state => accountSelectors.getAccount(state));
	const isOwner = useMemo(() => account?.content?.ownerUuid === owner?.uuid, [account, owner]);

	useOutsideClick(subMenuRef, () => {
		setShowAudienceSubMenu(false);
	});

	const renderLogo = useMemo(() => {
		if (mode === 'save' || !owner) {
			return null;
		}
		if (owner?.isGlobal) {
			return (
				<div className={el('logo')}>
					<img src={logo} alt="U" />
					<span>Upsiide</span>
				</div>
			);
		}
		return (
			<div className={el('logo')}>
				<img src={logoUser} alt="U" />
				<span>{owner?.name}</span>
			</div>
		);
	}, [owner, mode]);

	const renderMessage = useMemo(() => {
		if (mode === 'preview' || (mode === 'save' && !saveStudy && !saveAudience)) {
			return null;
		}
		if (type === 'audience') {
			return 'You are saving Audience Template';
		}
		return 'You are saving Study Template';
	}, [mode, saveStudy, saveAudience, type]);

	let useButtonState = selectClicked ? 'loading' : 'active';
	if (containsLiveOrCompleteGroup) {
		useButtonState = 'disabled';
	}

	const handleDuplicate = useCallback(() => {
		duplicateTemplateCallback();
	}, [duplicateTemplateCallback]);

	const handleDelete = useCallback(() => {
		onDeleteCallback();
	}, [onDeleteCallback]);

	const renderButtons = useMemo(() => {
		if (mode === 'save') {
			return [
				<Button key="button-save-cancel" type="text" label="Cancel" onClick={onClose} />,
				<Button
					key="button-save"
					state={!saveStudy && !saveAudience ? 'disabled' : savingTemplate ? 'loading' : 'active'}
					type="default"
					label="Save Template"
					onClick={() => {
						setSavingTemplate(true);
						saveTemplateCallback(saveStudy, saveAudience, localTemplateName || 'Untitled Audience');
					}}
				/>,
			];
		}
		if (isOwner) {
			return [
				<div
					ref={subMenuRef}
					className={el('audience-sub-menu-wrapper')}
					onClick={() => setShowAudienceSubMenu(prevState => !prevState)}
					aria-hidden
				>
					{!disableMoreOptions ? (
						<Button className={el('preview')} type="text" label="More Options +" />
					) : null}

					{showAudienceSubMenu &&
						(templateModalData?.step === 'editTemplate' ? (
							<div className={el('audience-sub-menu-delete')}>
								<span
									aria-hidden
									className={el('audience-sub-menu-item')}
									style={{ color: 'red' }}
									onClick={handleDelete}
								>
									Delete
								</span>
							</div>
						) : (
							<div className={el('audience-sub-menu')}>
								<span
									aria-hidden
									className={el('audience-sub-menu-item')}
									onClick={templateEditCallback}
								>
									Edit
								</span>
								<span aria-hidden className={el('audience-sub-menu-item')} onClick={() => {
									setDisableButtons(true);
									handleDuplicate();
								}}>
									Duplicate
								</span>
								<span
									aria-hidden
									className={el('audience-sub-menu-item')}
									style={{ color: 'red' }}
									onClick={handleDelete}
								>
									Delete
								</span>
							</div>
						))}
				</div>,
				<Button
					key="button-preview-use"
					type="default"
					label="Use Template"
					onClick={() => {
						useTemplateCallback();
						setDisableButtons(true);
					}}
					state={disableButtons ? 'loading' : 'active'}
				/>,
			];
		}
		return [
			...(!disableMoreOptions
				? [
						<Button
							key="button-preview-copy-to-my-templates"
							className={el('copy-button')}
							type="text"
							label="Copy To My Templates"
							onClick={async () => {
								setDisableCopy(true);
								await copyToMyTemplatesCallback();
								setDisableCopy(false);
							}}
							state={disableCopy ? 'disabled' : 'active'}
						/>,
				  ]
				: []),
			<Button
				key="button-preview-use"
				type="default"
				label="Use Template"
				onClick={() => {
					useTemplateCallback();
				}}
				state={useButtonState}
			/>,
		];
	}, [
		mode,
		isOwner,
		disableCopy,
		useButtonState,
		onClose,
		saveStudy,
		saveAudience,
		savingTemplate,
		saveTemplateCallback,
		disableMoreOptions,
		showAudienceSubMenu,
		templateModalData?.step,
		handleDelete,
		templateEditCallback,
		handleDuplicate,
		disableButtons,
		useTemplateCallback,
		copyToMyTemplatesCallback,
	]);

	return (
		<div className={className}>
			<div className={el('footer-left')}>
				{renderLogo ? <div className={el('logo-wrapper')}>{renderLogo}</div> : null}

				{mode === 'preview' ? (
					<div className={el('footer-warning-wrapper')}>
						<img className={el('footer-warning-image')} src={lampImgSrc} alt="lamp" />
						<span className={el('footer-warning-title')}>This is a read-only preview.</span>
					</div>
				) : null}
			</div>

			{/* No longer need message since only saving study template always */}
			<div className={el('buttons-wrapper')}>{renderButtons}</div>
		</div>
	);
};

TemplatePreviewFooter.displayName = 'TemplatePreviewFooter';
TemplatePreviewFooter.propTypes = {
	mode: PropTypes.any,
	saveAudience: PropTypes.any,
	saveStudy: PropTypes.any,
	onClose: PropTypes.any,
	saveTemplateCallback: PropTypes.any,
	useTemplateCallback: PropTypes.any,
	copyToMyTemplatesCallback: PropTypes.func,
	owner: PropTypes.any,
	type: PropTypes.any,
	templateEditCallback: PropTypes.func,
	duplicateTemplateCallback: PropTypes.func,
	onDeleteCallback: PropTypes.func,
	localTemplateName: PropTypes.string,
};

export default TemplatePreviewFooter;
