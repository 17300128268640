import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import cn from 'src/utilities/bem-cn';
import SelectedRecall from 'public/images/masking/selected_recall.svg';
import UnSelectedRecall from 'public/images/masking/unselected_recall.svg';
import DisabledRecall from 'public/images/masking/disabled_recall_bold.svg';
import SingleSelectedRecall from 'public/images/masking/single_selected_recall.svg';
import SingleUnSelectedRecall from 'public/images/masking/single_unselected_recall.svg';
import SingleDisabledRecall from 'public/images/masking/single_disabled_recall_bold.svg';

import { useSelector } from 'react-redux';

import useOutsideClick from 'src/hooks/useOutsideClick';
import RecallRankedDropdown from 'src/components/shared/RecallRankedDropdown';
import RecallQuestionHeader from 'src/components/shared/RecallQuestionHeader';
import RecallQuestionButton from 'src/components/shared/RecallQuestionButton';

const className = 'dropdown-recalling';
const el = (name, mod) => cn(className, name, mod);

const DropdownRecalling = ({ studyType, currentQuestion, onChangeMasking, onClose, value }) => {
	const studySections = useSelector(store => store.manageStudy?.sections?.content);
	const blueprintSections = useSelector(store => store.manageBlueprint?.sections?.content);
	const studyCurrentSection = useSelector(store => store.manageStudy?.currentSection?.content);
	const blueprintCurrentSection = useSelector(store => store.manageBlueprint?.currentSection?.content);
	const studyLanguage = useSelector(store => store.manageStudy?.language);
	const blueprintLanguage = useSelector(store => store.manageBlueprint?.language);

	const currentLanguage = useMemo(() => {
		if (studyType === 'study') return studyLanguage;
		if (studyType === 'blueprint') return blueprintLanguage;
	}, [blueprintLanguage, studyLanguage, studyType]);

	const sections = useMemo(() => {
		if (studyType === 'study') return studySections;
		if (studyType === 'blueprint') return blueprintSections;
		return [];
	}, [studySections, blueprintSections, studyType]);

	const currentSection = useMemo(() => {
		if (studyType === 'study') return studyCurrentSection;
		if (studyType === 'blueprint') return blueprintCurrentSection;
		return [];
	}, [studyCurrentSection, blueprintCurrentSection, studyType]);

	const [actualQuestion, setActualQuestion] = useState(currentQuestion);
	const [emptySearch, setEmptySearch] = useState(false);

	const ref = useRef(null);

	useEffect(() => {
		const question = currentSection.questions.find(e => e.id === currentQuestion.id);
		setActualQuestion(question);
	}, [currentQuestion, currentSection]);

	const isIdeaSplits = useMemo(() => actualQuestion.sectionType === 'monadic_split' || false, [actualQuestion]);

	const allQuestions = useMemo(
		() =>
			sections
				?.filter(section => {
					if (currentSection?.type === 'questions' && section?.type === 'questions') return true;
					if (currentSection?.type === 'monadic_split') {
						return currentSection?.id === section?.id || section?.type === 'questions';
					}
					return false;
				})
				?.map(section => section?.questions)
				?.flat(Infinity) || [],

		[sections, currentSection],
	);

	const ideaSplitQuestions = useMemo(
		() => allQuestions.filter(e => e.sectionType === 'monadic_split') || [],
		[allQuestions],
	);

	const currentQuestionIndex = useMemo(
		() => allQuestions.findIndex(e => e.id === currentQuestion.id),
		[allQuestions, currentQuestion],
	);
	const currentIdeaSplitsIndex = useMemo(
		() => ideaSplitQuestions.findIndex(e => e.id === currentQuestion.id),
		[ideaSplitQuestions, currentQuestion],
	);

	const questionSection = useMemo(
		() =>
			allQuestions?.filter(
				(question, index) =>
					(question?.style === 'multi-select' || question?.style === 'ranked') &&
					question?.sectionType !== 'monadic_split' &&
					currentQuestionIndex > index,
			) || [],
		[allQuestions, currentQuestionIndex],
	);

	const isFirstQuestion = useMemo(() => currentQuestionIndex === 0, [currentQuestionIndex]);

	const maskedQuestions = useMemo(() => {
		const array = [];
		for (const question of allQuestions) {
			if (question.id === currentQuestion.id) {
				for (const opt of actualQuestion.options) {
					if (opt?.maskedQuestionId) {
						array.push(opt);
					}
				}
			}
		}
		return array;
	}, [allQuestions, actualQuestion, currentQuestion]);

	const filterQuestionByValue = useCallback(
		(index, callback = false) => {
			if (value !== '@' && value !== '@q' && value !== `@q${index}` && value !== `@${index}`) return false;
			if (callback) return callback();
			return true;
		},
		[value],
	);

	const getQuestionIndex = useCallback(
		(list, question) => list.findIndex(quest => quest?.id === question?.id) + 1,
		[],
	);

	const filterDropdown = useCallback(() => {
		const arrayQuestions = questionSection?.filter(question => {
			const index = getQuestionIndex(allQuestions, question);
			return filterQuestionByValue(index, false);
		});
		const arrayIdeaSplits = ideaSplitQuestions?.filter((question, i) => {
			if (question?.style === 'multi-select' && currentIdeaSplitsIndex > i) {
				const index = getQuestionIndex(ideaSplitQuestions, question);
				return filterQuestionByValue(index, false);
			}
			return false;
		});
		setEmptySearch([...arrayQuestions, ...arrayIdeaSplits].length === 0);
	}, [
		questionSection,
		ideaSplitQuestions,
		currentIdeaSplitsIndex,
		allQuestions,
		filterQuestionByValue,
		getQuestionIndex,
	]);

	useEffect(() => {
		filterDropdown();
	}, [value, filterDropdown]);

	useOutsideClick(ref, e => {
		onClose(e);
	});

	const renderEmptyOptions = () => (
		<div className={el('dropdown_content')}>
			<div className={el('dropdown_content_empty')}>
				<span className={el('dropdown_content_no_data')}>No questions</span>
			</div>
		</div>
	);
	const renderNoMatch = () => (
		<div className={el('dropdown_content')}>
			<div className={el('dropdown_content_empty')}>
				<span className={el('dropdown_content_no_data')}>No data matches what you’re looking for</span>
			</div>
		</div>
	);

	const selectQuestion = (question, logic, position = null, rank = null) => {
		const masked = {
			maskedQuestionId: question.id,
			logic,
		};

		if (position && rank) {
			masked.position = position;
			masked.rank = rank;
		}

		onChangeMasking(masked);
		onClose();
	};

	const getIconBasedStatus = useCallback(
		logic => {
			const isMultiSelect = currentQuestion.settings.find(s => s?.label === 'multi-select')?.value === 'true';

			if (logic === 'selected') return isMultiSelect ? SelectedRecall : SingleSelectedRecall;
			if (logic === 'not-selected') return isMultiSelect ? UnSelectedRecall : SingleUnSelectedRecall;
			return isMultiSelect ? DisabledRecall : SingleDisabledRecall;
		},
		[currentQuestion],
	);

	const renderOption = (question, index = 0) => {
		if (question?.id === currentQuestion?.id) return null;
		const masked = maskedQuestions.filter(ex => ex.maskedQuestionId === question?.id) || [];
		return (
			<div className={el('dropdown_content_item')} key={`recalling-${question?.id}`}>
				<RecallQuestionHeader
					question={question}
					index={index}
					currentLanguage={currentLanguage}
					allQuestions={allQuestions}
				/>
				{question?.style === 'ranked' ? (
					<div className={el('dropdown_ranked_container')}>
						<img src={SelectedRecall} alt="" className={el('icon')} style={{ marginRight: 10 }} />
						<span style={{ minWidth: 63 }}>Ranked as</span>
						<RecallRankedDropdown
							question={question}
							maskingType="selected"
							selectQuestion={selectQuestion}
							sections={sections}
						/>
					</div>
				) : (
					<div className={el('dropdown_button_container')}>
						<RecallQuestionButton
							question={question}
							maskingType="selected"
							selectQuestion={selectQuestion}
							getIconBasedStatus={getIconBasedStatus}
							pipedInfo={masked}
						/>
						<RecallQuestionButton
							question={question}
							maskingType="not-selected"
							selectQuestion={selectQuestion}
							getIconBasedStatus={getIconBasedStatus}
							pipedInfo={masked}
						/>
					</div>
				)}
			</div>
		);
	};

	const renderQuestionSection = () => (
		<>
			{questionSection.length ? <div className={el('header_splits')} /> : null}
			{questionSection?.map(question => {
				const index = getQuestionIndex(allQuestions, question);
				return filterQuestionByValue(index, () => renderOption(question, index)) || null;
			})}
		</>
	);

	const renderIdeasSplit = () => (
		<>
			{ideaSplitQuestions?.length
				? ideaSplitQuestions?.filter((q, index) => currentIdeaSplitsIndex > index)?.length > 0 && (
						<div className={el('header_splits')}>
							<p className={el('header_title')}>This splits section:</p>
						</div>
				  )
				: null}
			{ideaSplitQuestions
				?.filter(
					(question, i) =>
						question?.style === 'multi-select' ||
						(question?.style === 'ranked' && currentIdeaSplitsIndex > i),
				)
				?.map(question => {
					const index = getQuestionIndex(ideaSplitQuestions, question);
					return filterQuestionByValue(index, () => renderOption(question, index)) || null;
				})}
		</>
	);

	const renderNoOptions = () => {
		if (isFirstQuestion) return renderEmptyOptions();
		if (emptySearch) return renderNoMatch();
		return null;
	};

	const styleHeaderIdeaSplits = isIdeaSplits
		? {
				flexDirection: 'column',
				alignItems: 'flex-start',
		  }
		: {};

	return (
		<div className={el('dropdown_container')} ref={ref}>
			<div className={el('dropdown_center_container')}>
				<div className={el('dropdown_header')} style={styleHeaderIdeaSplits}>
					<span className={el('dropdown_title')}>Recall</span>
					<span className={el('dropdown_subtitle')}>
						Recall selected or unselected from a previous question
					</span>
				</div>

				{isFirstQuestion || emptySearch ? (
					renderNoOptions()
				) : (
					<div className={el('dropdown_content')}>
						{questionSection?.length ? renderQuestionSection() : null}
						{ideaSplitQuestions?.length ? renderIdeasSplit() : null}
					</div>
				)}
			</div>
		</div>
	);
};

DropdownRecalling.propTypes = {
	studyType: PropTypes.string,
	currentQuestion: PropTypes.object,
	onChangeMasking: PropTypes.func,
	onClose: PropTypes.func,
	value: PropTypes.string,
};

export default DropdownRecalling;
