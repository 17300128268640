import React from 'react';
import PropTypes from 'prop-types';

// react-tippy implementation can be found here: https://tvkhoa.github.io/testlib/
// Full react-tippy docs can be found here: https://github.com/tvkhoa/react-tippy
import { Tooltip } from 'react-tippy';
import { Iconof } from '@upsiide/ui-components';
import 'react-tippy/dist/tippy.css';
import './Hint.scss';

class Hint extends React.Component {
	constructor(props) {
		super(props);
		this.id = `tooltip-${Math.ceil(Math.random() * 1000)}`;
	}

	render() {
		const {
			animation = 'shift', // Style of tooltip animation (e.g. shift, perspective, fade, scale, none)
			interactive = false, // Whether you can interact with the tooltip
			isPrivate = false, // Does the tooltip use the '?' or 'lock' icon
			text, // HTML content for the tooltip
			trigger = 'mouseenter', // How is the tooltip triggered (e.g. mouseenter, focus, click, manual)
			theme = 'dark',
		} = this.props;
		return (
			<Tooltip
				id={this.id}
				className={!isPrivate ? 'hint' : 'private-hint'}
				animation={animation}
				animationFill={false}
				arrow
				interactive={interactive}
				trigger={trigger}
				theme={theme}
				html={<div>{text}</div>}
			>
				{!isPrivate ? '?' : <Iconof icon="lock" className="iconof-lock" />}
			</Tooltip>
		);
	}
}

Hint.propTypes = {
	animation: PropTypes.string,
	isPrivate: PropTypes.bool,
	interactive: PropTypes.bool,
	trigger: PropTypes.string,
	text: PropTypes.any,
	theme: PropTypes.string,
};

export default Hint;
