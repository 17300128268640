import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsBlueprint = () => {
	const { pathname } = useLocation();
	const [isBlueprint, setIsBlueprint] = useState(/templates/.test(pathname));

	useEffect(() => {
		const isUrlBlueprint = /templates/.test(pathname);
		setIsBlueprint(isUrlBlueprint);
	}, [pathname]);
	return { isBlueprint };
};
