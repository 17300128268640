import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import cn from 'src/utilities/bem-cn';
import misc from 'src/utilities/misc';
import ModelOptions from 'src/config/modelOptions';
import parse from 'html-react-parser';
import { Iconof } from '@upsiide/ui-components';
import cards from '@upsiide/product-cards';
import './styles.scss';

const className = 'product-card';
const el = (name, modifier) => cn(className, name, modifier);
class ProductCard extends React.Component {
	constructor(props) {
		super(props);
		this.dummyImage = misc.getDummyImage(164, 144, '#e1e1e1', 1.0);
	}

	getPropertyFromJSON = (options, property, defaultValue) => {
		if (options) {
			try {
				return JSON.parse(options)[property];
			} catch (error) {
				return defaultValue;
			}
		}
		return defaultValue;
	};

	getOptionsFromJSON = options => {
		const obj = { backgroundColor: '#ffffffff' };
		if (options) {
			try {
				return JSON.parse(options);
			} catch (error) {
				return obj;
			}
		}
		return obj;
	};

	getImagesObject = (field, fieldOptions) => ({
		type: 'Images',
		content: (field || []).map(asset => misc.getAssetVariationUrl(asset, ['large', 'full'])),
		options: {
			...this.getOptionsFromJSON(fieldOptions),
			isVideoThumbnail: (field || []).some(asset => misc.assetIsVideo(asset)),
		},
	});

	getVideoObject = (field, fieldOptions) => ({
		type: 'Video',
		content: (field || []).map(asset => misc.getAssetVariationUrl(asset, ['video'])),
		options: this.getOptionsFromJSON(fieldOptions),
	});

	getTextObject = (field, fieldOptions) => ({
		type: 'Text',
		content: field,
		options: this.getOptionsFromJSON(fieldOptions),
	});

	getFieldData = (index, field, fieldType, fieldOptions) => {
		const { ideaScreenLayout, product, assetVariationSize } = this.props;
		let result = false;
		const isFieldTypeAsset = fieldType === 'asset';
		const isFieldTypeHtml = fieldType === 'html';

		const assetMethod = assetVariationSize === 'video' ? this.getVideoObject : this.getImagesObject;

		if (isFieldTypeAsset) {
			result = assetMethod(field, fieldOptions);
		}
		if (isFieldTypeHtml) {
			result = this.getTextObject(field, fieldOptions);
		}
		const placeholder =
			ModelOptions.idea_screen_layouts[ideaScreenLayout] &&
			ModelOptions.idea_screen_layouts[ideaScreenLayout].placeholders[index]
				? ModelOptions.idea_screen_layouts[ideaScreenLayout].placeholders[index]
				: false;

		const { noImagePreview = false } = this.props;
		if (noImagePreview === true) {
			return result;
		}
		if (!field && placeholder) {
			if (placeholder[0].url) {
				result = assetMethod(placeholder, fieldOptions);
			} else {
				result = this.getTextObject(
					this.getIdeaScreenLayoutPlaceholderText(index, ideaScreenLayout, product),
					fieldOptions,
				);
			}
		}
		return result;
	};

	// TODO: Turn this into a shared util
	getIdeaScreenLayoutPlaceholderText = (fieldDataIndex, ideaScreenLayout, product) => {
		const { study } = this.props;
		const { defaultLanguage } = study;
		const { translations } = product;
		const defaultTranslation = translations
			? translations.find(translation => translation.languageCode === defaultLanguage)
			: { fieldOne: '', fieldTwo: '', fieldThree: '' };
		const titleThisIdeaText = 'Title this idea';
		const addDetailsAboutText = 'Add details about the idea';
		const isTextOnly = ideaScreenLayout === 'textOnly';
		const isDoubleText = ideaScreenLayout === 'doubleText';
		const isNoTitle = ideaScreenLayout === 'noTitle';
		const isTitleTop = ideaScreenLayout === 'titleTop';
		const isTitleBottom = ideaScreenLayout === 'titleBottom';
		const isSideBySide = ideaScreenLayout === 'sideBySide';
		if (isTextOnly) {
			return defaultTranslation && defaultTranslation.fieldOne && defaultTranslation.fieldOne.length > 0
				? misc.cleanHtmlString(defaultTranslation.fieldOne)
				: addDetailsAboutText;
		}
		if (isDoubleText) {
			if (fieldDataIndex === 0) {
				return defaultTranslation && defaultTranslation.fieldOne && defaultTranslation.fieldOne.length > 0
					? misc.cleanHtmlString(defaultTranslation.fieldOne)
					: titleThisIdeaText;
			}
			return defaultTranslation && defaultTranslation.fieldTwo && defaultTranslation.fieldTwo.length > 0
				? misc.cleanHtmlString(defaultTranslation.fieldTwo)
				: addDetailsAboutText;
		}
		if (isNoTitle) {
			return defaultTranslation && defaultTranslation.fieldTwo && defaultTranslation.fieldTwo.length > 0
				? misc.cleanHtmlString(defaultTranslation.fieldTwo)
				: addDetailsAboutText;
		}
		if (isTitleTop) {
			if (fieldDataIndex === 0) {
				return defaultTranslation && defaultTranslation.fieldOne && defaultTranslation.fieldOne.length > 0
					? misc.cleanHtmlString(defaultTranslation.fieldOne)
					: titleThisIdeaText;
			}
			return defaultTranslation && defaultTranslation.fieldThree && defaultTranslation.fieldThree.length > 0
				? misc.cleanHtmlString(defaultTranslation.fieldThree)
				: addDetailsAboutText;
		}
		if (isTitleBottom || isSideBySide) {
			if (fieldDataIndex === 1) {
				return defaultTranslation && defaultTranslation.fieldTwo && defaultTranslation.fieldTwo.length > 0
					? misc.cleanHtmlString(defaultTranslation.fieldTwo)
					: titleThisIdeaText;
			}
			return defaultTranslation && defaultTranslation.fieldThree && defaultTranslation.fieldThree.length > 0
				? misc.cleanHtmlString(defaultTranslation.fieldThree)
				: addDetailsAboutText;
		}
		return '';
	};

	getSections = () => {
		const sections = [];
		const { language, product, study } = this.props;
		const { defaultLanguage } = study || 'en';
		const { translations } = product;
		// If the product has a translations property then you are viewing this product card on the product grid
		let translation = null;
		if (translations) {
			translation = translations.find(t => t?.languageCode.toLowerCase() === language?.toLowerCase());
		}
		let defaultTranslation = null;
		if (translations) {
			defaultTranslation = translations.find(
				t => t?.languageCode.toLowerCase() === defaultLanguage?.toLowerCase(),
			);
		}
		const {
			fieldOne,
			fieldOneType,
			fieldOneOptions,
			fieldTwo,
			fieldTwoType,
			fieldTwoOptions,
			fieldThree,
			fieldThreeType,
			fieldThreeOptions,
		} = translation || defaultTranslation || product; // * If translation does not exist then you are viewing this product card on the get started screen
		const fieldOneData = this.getFieldData(0, fieldOne, fieldOneType, fieldOneOptions);
		const fieldTwoData = this.getFieldData(1, fieldTwo, fieldTwoType, fieldTwoOptions);
		const fieldThreeData = this.getFieldData(2, fieldThree, fieldThreeType, fieldThreeOptions);
		if (fieldOneData) {
			sections.push(fieldOneData);
		}
		if (fieldTwoData) {
			sections.push(fieldTwoData);
		}
		if (fieldThreeData) {
			sections.push(fieldThreeData);
		}
		return sections;
	};

	render() {
		const {
			customClasses = '',
			imageSize,
			imageCropping,
			ideaScreenLayout,
			language,
			product,
			showProductTitle = false,
			assetVariationSize = ['large', 'full', 'thumbnail'],
			isShared = false,
			displaySharedTitle = true,
		} = this.props;
		const sections = this.getSections();
		const twoColumn = ideaScreenLayout === 'sideBySide';
		const { translations } = product;
		// If the product has a translations property then you are viewing this product card on the product grid
		let productTranslation = null;
		if (translations) {
			productTranslation = translations.find(t => t?.languageCode?.toLowerCase() === language?.toLowerCase());
		}

		const previewImageUrl = misc.getPreviewImageFromProduct(product || productTranslation, assetVariationSize);

		return (
			<div className={`${className} ${customClasses}`}>
				<cards.StackCard
					product={{ id: 0, sections, type: 'Fixed', twoColumn, imageSize, imageCropping }}
					language={language}
					imageSize={imageSize}
					imageCropping={imageCropping}
					ideaScreenLayout={ideaScreenLayout}
					previewImageUrl={previewImageUrl}
					interactionsDisabled
				/>

				{showProductTitle &&
					(isShared > 1 && displaySharedTitle ? (
						<Tooltip
							theme="light audience-norm"
							html={
								<p className={el('idea-in-section')}>
									This idea is used in {isShared - 1} other section{isShared > 2 ? 's' : ''}
								</p>
							}
							position="top"
							interactive={false}
							delay={300}
							arrow
							style={{ display: 'flex', position: 'absolute', top: 301, left: 12, zIndex: 999 }}
						>
							<div className={el('link-bullet')} />
							<div className={el('link-bullet-2')} />
							<div className={el('product-title-shared')}>{parse(product.name)}</div>
						</Tooltip>
					) : (
						<div className={el('product-title')}>{parse(product.name)}</div>
					))}
			</div>
		);
	}
}

ProductCard.propTypes = {
	study: PropTypes.any,
	product: PropTypes.object,
	language: PropTypes.string,
	imageSize: PropTypes.string,
	imageCropping: PropTypes.string,
	ideaScreenLayout: PropTypes.string,
	customClasses: PropTypes.string,
	showProductTitle: PropTypes.bool,
	assetVariationSize: PropTypes.string,
	isShared: PropTypes.number,
	displaySharedTitle: PropTypes.bool,
	noImagePreview: PropTypes.bool,
};

export default ProductCard;
