import React from 'react';
import PropTypes from 'prop-types';
import ButtonWithDropdown from 'src/components/elements/ButtonWithDropdown';

const DropdownSelect = ({
	value,
	onChange,
	options,
	placeholder,
	searchable,
	state,
	tooltipContent,
	tooltipIcon,
	maxLabelLength = null,
	...props
}) => {
	const selectedOption = options.filter(option => option.value === value).pop();

	const label = selectedOption ? selectedOption.label : placeholder;

	const items = options.map(option => ({
		label: option.label,
		onClick: () => onChange(option.value),
		showTooltip: option.showTooltip,
	}));

	return (
		<ButtonWithDropdown
			{...props}
			label={maxLabelLength && label?.length > maxLabelLength ? `${label.slice(0, maxLabelLength)}...` : label}
			items={items}
			state={state}
			searchable={searchable}
			tooltipContent={tooltipContent}
			tooltipIcon={tooltipIcon}
		/>
	);
};

DropdownSelect.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.any,
	options: PropTypes.any,
	placeholder: PropTypes.any,
	searchable: PropTypes.any,
	state: PropTypes.any,
	tooltipContent: PropTypes.any,
	tooltipIcon: PropTypes.any,
	maxLabelLength: PropTypes.number,
};

export default DropdownSelect;
