import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import ListChip from 'src/components/shared/ListChip/index.js';
import { countriesWithFlags } from 'src/utilities/upsiideCountriesWithFlag';
import { uniq } from 'lodash';
import './styles.scss';

const className = 'study-countries';
const el = name => cn(className, name);

const StudyCountries = ({ study }) => {
	const getCountryFlag = country => countriesWithFlags.find(({ label }) => label === country)?.flag;

	const uniqueCountryIds = uniq(study?.countryIds?.filter(id => id !== undefined));
	const countryNames = uniqueCountryIds?.map(
		countryId => countriesWithFlags.find(countryInfo => countryInfo.id === countryId)?.label,
	);
	return countryNames?.length ? (
		<ListChip
			tooltipTitle="Countries"
			data={countryNames}
			renderChipContent={countryName => getCountryFlag(countryName)}
			renderCustomTooltipLabel={countryName => (
				<span>
					<span className={el('flag-font')}>{getCountryFlag(countryName)}</span> {countryName}
				</span>
			)}
			key={`countries-${study?.uuid}`}
			maxVisible={3}
			customChipClass={el('flag-font')}
			customTooltipClass={el('tooltip-container')}
		/>
	) : null;
};

StudyCountries.propTypes = {
	study: PropTypes.object,
};

export default StudyCountries;
