import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'src/domains/profile/selectors';
import { useIsPublic } from 'src/hooks';
import userService from 'src/services/user.service';

export const useSearchHistory = () => {
	const { uuid: userUuid } = useSelector(getUser) || {};
	const { isPublic } = useIsPublic();

	const addHistory = useCallback(
		async ({ objectId, studyTranslationId, type }) => {
			if (isPublic) {
				return;
			}

			await userService.setHistory({ userId: userUuid, objectId, type, studyTranslationId });
		},
		[isPublic, userUuid],
	);

	return { addHistory };
};
