import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

function TextInput({
	className,
	textClassName,
	value,
	onChange,
	onBlur,
	maxLength = 50,
	isCharacterCount = false,
	placeholder = '',
	disabled,
	onFocus = () => {},
}) {
	const textAreaRef = useRef();

	const onClickOut = e => e.stopPropagation();

	const autoSizeTextArea = element => {
		element.style.height = 'auto';
		element.style.height = `${element.scrollHeight + 2}px`;
	};

	const renderGroupDescField = () => {
		const remainingCharacters = maxLength - (value?.length || 0);
		if (textAreaRef.current) {
			autoSizeTextArea(textAreaRef.current);
		}
		return (
			<div className={textClassName}>
				<textarea
					rows={1}
					type="text"
					ref={textAreaRef}
					value={value}
					maxLength={maxLength}
					className="new-textarea form-control"
					onKeyDown={e => {
						if (e.key === 'Enter') {
							e.stopPropagation();
						}
						autoSizeTextArea(e.target);
					}}
					onChange={onChange}
					onBlur={onBlur}
					suppressContentEditableWarning
					onClick={onClickOut}
					placeholder={placeholder}
					disabled={disabled}
					onFocus={onFocus}
				/>
				{isCharacterCount ? <div className="character-count">{remainingCharacters}</div> : null}
			</div>
		);
	};
	return <span className={className}>{renderGroupDescField()}</span>;
}

TextInput.propTypes = {
	className: PropTypes.string,
	textClassName: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	maxLength: PropTypes.number,
	disabled: PropTypes.bool,
	onFocus: PropTypes.func,
};

export default TextInput;
