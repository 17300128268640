import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const className = 'section-card';

const SectionCard = ({ children, ...rest }) => (
	<div className={className} {...rest}>
		{children}
	</div>
);

SectionCard.propTypes = {
	children: PropTypes.any,
};

export default SectionCard;
