import axios from '../utilities/axios';

const API_PATH = `${process.env.apiPath}`;

function identifyEmail(email) {
	return axios.get(`${API_PATH}/auth/saml/check-email?email=${email}`).then(response => response);
}

function login(user) {
	return axios.post(`${API_PATH}/auth/login`, user).then(response => response);
}

function loginAsUser(payload) {
	return axios.post(`${API_PATH}/auth/ghost`, { ...payload }).then(response => response);
}

function validate() {
	return axios.post(`${API_PATH}/auth/validate`).then(response => response);
}

function forgetPassword(user) {
	return axios.post(`${API_PATH}/auth/reset-password`, user);
}

function logout(token) {
	return axios.post(`${API_PATH}/auth/logout`, token).then(response => response);
}

function fetchProvisioningState(accountUuid) {
	const config = {};
	if (accountUuid) {
		config.headers = { 'x-account-uuid': accountUuid };
	}
	return axios.get(`${API_PATH}/account/provisioning`, config).then(response => response);
}

export default {
	identifyEmail,
	login,
	loginAsUser,
	validate,
	forgetPassword,
	logout,
	fetchProvisioningState,
};
