import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIsPublic, useIsBlueprint } from 'src/hooks';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import * as publicStudySelectors from 'src/domains/public-study/selectors';
import { ERROR_TYPES } from 'src/utilities/misc';
import masking from 'src/utilities/masking';
import audienceStatusUtil from 'src/utilities/audiences-status-error-helper';
import { getAllQuestions } from 'src/domains/manage-study/components/reporting/MonadicSplit/utilities';

export const useGetStudyValidations = () => {
	const validationErrorsArray = [];
	const { isPublic } = useIsPublic();
	const { isBlueprint } = useIsBlueprint();
	const studySelectors = useMemo(() => {
		if (isPublic) {
			return publicStudySelectors;
		}
		return isBlueprint ? manageBlueprintSelectors : manageStudySelectors;
	}, [isBlueprint, isPublic]);
	const study = useSelector(state => studySelectors.getStudy(state));
	const collection = useSelector(state => studySelectors.getAudienceCollection(state));
	const sections = useSelector(state => studySelectors.getSections(state));
	const translationValidations = useSelector(state => studySelectors.getTranslationValidations(state));

	let respondentCount = 0;
	let collectedResponses = 0;
	const minResponsesArray = [];
	let minResponses;

	// The three if statments below determines if a warning regarding spits respondents should be displayed
	// Set respondentCount
	if (collection?.content?.demographicGroups?.length > 0) {
		collection?.content?.demographicGroups?.forEach(aud => (respondentCount += aud.sampleSize));
	}

	// Set collectedResponses
	if (collection?.content?.demographicGroups?.length > 0) {
		collection?.content?.demographicGroups?.forEach(aud => (collectedResponses += aud.currentSample));
	}

	// Set minResponses
	if (sections && sections.content && sections.content.length > 0) {
		const splitsSections = sections.content.filter(section => section.type === 'monadic_split');
		if (splitsSections) {
			splitsSections.forEach(section => {
				let ideasPerPerson = 1;
				if (section.settings) {
					const ideasPerPersonSetting = section.settings.find(
						setting => setting.label === 'ideas_per_person',
					);
					if (ideasPerPersonSetting) {
						ideasPerPerson = ideasPerPersonSetting.value;
					}
				}
				if (section && section.products) {
					const minResponsesCopy = (100 * section.products.length) / ideasPerPerson;
					minResponsesArray.push(minResponsesCopy);
				}
			});
			minResponses = Math.max(...minResponsesArray);
		}
	}

	if (
		respondentCount < minResponses &&
		collectedResponses < minResponses &&
		!audienceStatusUtil.haveByoGroupsWithoutSampleSize() &&
		collection?.content?.demographicGroups?.length > 0
	) {
		validationErrorsArray.push({ type: ERROR_TYPES.ideaSplitsLow, minResponses });
	}

	// Finding all translation or no content related errors for languages that exist for the language groups of this study

	const translationIds = [];
	if (study?.translations) {
		Object.keys(study?.translations).map(translation => translationIds.push(study?.translations[translation].id));
		translationIds.map(translation => {
			if (translationValidations[translation]?.errorType?.length) {
				validationErrorsArray.push({
					type: translationValidations[translation].errorType,
					languageCode: translationValidations[translation].languageCode,
				});
			}
		});
	}

	collection?.content?.demographicGroups?.forEach(group => {
		if (group?.status === 'system-stopped') {
			validationErrorsArray.push({
				type: ERROR_TYPES.systemStopped,
				groupUuid: group?.uuid,
				groupName: group?.name,
			});
		}

		if (!group?.countryLanguage || Object.keys(group?.countryLanguage).length === 0) {
			validationErrorsArray.push({
				type: ERROR_TYPES.missingCountryLanguage,
				groupUuid: group?.uuid,
				groupName: group?.name,
			});
		}
	});

	// Finding out if there are masking errors within the study
	const allQuestions = useMemo(() => getAllQuestions(sections), [sections]);
	const isMaskingError = useMemo(() => masking.getMaskingArrayError(allQuestions), [allQuestions]);
	if (isMaskingError.length) {
		validationErrorsArray.push({
			type: ERROR_TYPES.maskingError,
		});
	}

	// If respondents less than 100
	collection?.content?.demographicGroups?.forEach(group => {
		if ((group?.sampleSize < 100 || group?.sampleSize > 5000) && group?.provider !== 'BYO') {
			validationErrorsArray.push({
				type: ERROR_TYPES.invalidSample,
				groupUuid: group?.uuid,
				groupName: group?.name,
			});
		}
	});

	// If there are demo groups/BYO links added, check if there are screening questions not attached to any groups
	if (collection?.content?.demographicGroups?.length > 0) {
		let screenerWithoutGroup = false;
		collection?.content?.screeningQuestions?.forEach(question => {
			if (question?.allowList?.length === 0) {
				screenerWithoutGroup = true;
			}
		});
		if (screenerWithoutGroup) {
			validationErrorsArray.push({
				type: ERROR_TYPES.screenerWithoutGroup,
			});
		}
	}

	return validationErrorsArray;
};
