import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as authActions from 'src/domains/auth/actions';
import * as authSelectors from 'src/domains/auth/selectors';
import * as profileSelectors from 'src/domains/profile/selectors';
import * as accountSelector from 'src/domains/account/selectors';
import * as misc from 'src/utilities/misc';
import HeaderRouter from 'src/domains/header/containers/Header';
import DomainRoutes from 'src/domains/Routes';
import IEWarning from 'src/components/shared/IEWarning';
import Checkmark from 'src/components/icons/Checkmark';
import ResponsiveModal from 'src/components/shared/ResponsiveModal';
import MaintenanceComponent from 'src/domains/auth/containers/MaintenanceComponent';
import Auth from 'src/utilities/auth';
import * as Sentry from '@sentry/browser';
import { UPThemeProvider } from '@upsiide/ui-components';
import Cookies from 'src/utilities/cookies';
import UtmDataMapper from 'src/utilities/utm-data-mapper';
import { MarketingTracker } from 'src/components/marketing/Tracker';
import ModalProvider from './utilities/modal-provider';
import MobileLock from './components/shared/MobileLock';
import EmojiPicker from './components/inputs/AssetInput/Gallery/EmojiPicker';
import './App.scss';
import HelpMenu from './components/manage/Help';
import { setRouterUtils } from './utilities/router/routerScopeLeaker';
import withRouter from './hocs/withRouter';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
			cacheTime: 1000 * 60 * 5, // 5 minutes
		},
	},
});

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showAnnouncement: false,
		};
	}

	componentDidMount() {
		// add win class to body if windows
		if (/windows/i.test(navigator.userAgent)) {
			document.body.classList.add('win');
		}

		/** START Capturing UTM Values */
		// UTM Values from upsiide.com
		const upsiideUTMCookie = Cookies.getUpsiideUTMData();
		if (upsiideUTMCookie) {
			const persistedUTMDataFromUpsiideProductSite = UtmDataMapper.parseUtmValuesFromUrl(
				upsiideUTMCookie.split('?')[1],
			);
			if (Object.keys(persistedUTMDataFromUpsiideProductSite).length > 0) {
				Cookies.setUtmData(persistedUTMDataFromUpsiideProductSite);
			}
		}

		// UTM Values present when landing on the dashboard
		const UTMvaluesPresentOnLanding = UtmDataMapper.parseUtmValuesFromUrl();
		if (Object.keys(UTMvaluesPresentOnLanding).length > 0) {
			Cookies.setUtmData(UTMvaluesPresentOnLanding);
		}
		/** END Capturing UTM Values */

		const { authenticate, router } = this.props;

		setRouterUtils(router);

		const isPublic = /public/.test(window.location.pathname);
		const token = Cookies.getToken();
		// Don't try to authenticate when it is on public mode and unlogged user.
		if (isPublic && !token) return;

		authenticate();
		window.localStorage.removeItem('UPSIIDE__HIDE_ANNOUNCEMENT_2_5');
		// const showAnnouncement = window.localStorage.getItem('UPSIIDE__HIDE_ANNOUNCEMENT_2_5');
		// if (!showAnnouncement) {
		// 	this.setState({ showAnnouncement: true });
		// }
	}


	closeAnnouncement = () => {
		this.setState({ showAnnouncement: false });
		window.localStorage.setItem('UPSIIDE__HIDE_ANNOUNCEMENT_2_5', true);
	};

	announcementAction = () => {
		// this.closeAnnouncement();
		window.open('https://feedback.upsiide.com/changelog', '_blank');
		// TODO - this should be programmable to linked to an Announcement setting somewhere.
		// Hard-coded link for the UUID of the public studies account
		// LIVE
		// const redirectUrl = '/studies?clientUuid[]=a1a4fbc5-427e-4f6d-8a4e-aac553f23d7f';
		// DEV
		// const redirectUrl = '/studies?clientUuid[]=35596834-ab9c-46a5-9856-7a1e675a20f3';
		// if (window.location.href.includes('/studies')) {
		// 	// Reload page for this one - things in studies list run on mount and don't trigger with a navigate.
		// 	window.location.href = redirectUrl;
		// } else {
		// 	navigate(redirectUrl);
		// }
	};

	render() {
		const { showAnnouncement } = this.state;
		const { loading, loggedIn, hasAccount, user, goToSupport, logout, switchToOriginalUser, ghost, rateLimit } =
			this.props;
		const ieVer = misc.isBrowserIE();
		const isAdmin = Auth.isDigAdmin();
		const isPublic = /public/.test(window.location.pathname);

		return (
			<QueryClientProvider client={queryClient}>
				<MarketingTracker />
				<MaintenanceComponent
					visible={
						(loggedIn && process.env.maintenanceMode && !isAdmin) ||
						rateLimit.flagged ||
						process.env.apiDownMode
					}
					rateLimit={rateLimit}
				>
					{/* generateCSS will generate typography helper classes to provide an exact 1-1 match with figma design,
					 such as .type-h1, .type-body */}
					<UPThemeProvider generateCss>
						<Helmet>
							<title>Upsiide Dashboard</title>
							<meta name="description" content="" />
						</Helmet>
						<ModalProvider>
							{(loggedIn || isPublic) && (
								<MobileLock displayRenderLock={false}>
									<Routes>
										{!loading && (
											<Route
												path="/*"
												element={
													<HeaderRouter
														loggedIn={loggedIn}
														hasAccount={hasAccount}
														user={user}
														goToSupport={goToSupport}
														logout={logout}
														switchToOriginalUser={switchToOriginalUser}
														ghost={ghost}
														path="/*"
													/>
												}
											/>
										)}
									</Routes>
									{ieVer ? <IEWarning version={ieVer} /> : null}
								</MobileLock>
							)}
							<Routes>
								<Route path="/*" element={<DomainRoutes />} />
							</Routes>
							{loggedIn && (
								<ResponsiveModal
									show={showAnnouncement}
									customClass="upsiide-announcement"
									headerLabel="Introducing the NEW Audience Marketplace!"
									closeLabel="Close"
									onClose={this.closeAnnouncement}
									confirmLabel="See the release notes"
									onConfirm={this.announcementAction}
								>
									{/* <h4 className="upsiide-announcement__sub-title">
								Introducing the <strong>NEW</strong> Audience Marketplace!
							</h4> */}
									<div className="upsiide-announcement__content">
										<p>With our new Audience feature, you can:</p>
										<ul>
											<li>
												<Checkmark />
												Access an audience of millions of consumers globally
											</li>
											<li>
												<Checkmark />
												Select your exact target audiences within Upsiide, including advanced
												demographics and custom screener questions
											</li>
											<li>
												<Checkmark />
												Save particular audience profiles to re-use again and again
											</li>
										</ul>
									</div>
								</ResponsiveModal>
							)}
						</ModalProvider>
						<HelpMenu />
					</UPThemeProvider>

					{/** Loading in the picker is required to bootstrap all of the data when displaying emojis. This is only needed for accounts that cannot update studies */}
					{!Auth.userCan('study:update') && (
						<div style={{ display: 'none', overflow: 'hidden', height: 0, width: 0 }}>
							<EmojiPicker />
						</div>
					)}
				</MaintenanceComponent>
			</QueryClientProvider>
		);
	}
}

App.propTypes = {
	loading: PropTypes.bool,
	authenticate: PropTypes.func,
	loggedIn: PropTypes.bool,
	hasAccount: PropTypes.bool,
	user: PropTypes.object,
	goToSupport: PropTypes.func,
	logout: PropTypes.func,
	switchToOriginalUser: PropTypes.func,
	ghost: PropTypes.any,
	rateLimit: PropTypes.object,
	account: PropTypes.any,
};

const mapStateToProps = state => ({
	loading: authSelectors.isLoading(state),
	loggedIn: authSelectors.isLoggedIn(state),
	hasAccount: authSelectors.hasAccount(state),
	user: profileSelectors.getUser(state),
	ghost: authSelectors.isGhostUser(state),
	rateLimit: authSelectors.rateLimit(state),
	account: accountSelector.getAccount(state),
});

const mapDispatchToProps = dispatch => ({
	authenticate: () => dispatch(authActions.authenticate()),
	goToSupport: () => dispatch(authActions.goToSupport()),
	logout: () => dispatch(authActions.logout()),
	switchToOriginalUser: () => dispatch(authActions.switchToOriginalUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
