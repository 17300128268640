import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIsPublic } from 'src/hooks';
import Auth from 'src/utilities/auth';
import cn from 'src/utilities/bem-cn';
import audienceStatusUtil from 'src/utilities/audiences-status-error-helper';
import * as selectors from 'src/domains/selectors';
import * as manageStudySelectors from 'src/domains/manage-study/selectors';
import { Iconof } from '@upsiide/ui-components';
import * as sectionActions from 'src/domains/manage-study/actions/sections';
import { TemplateCardsProvider } from 'src/components/shared/TemplateCard';
import * as actions from 'src/domains/manage-study/actions';
import * as headerActions from 'src/domains/header/actions';
import IconButton from 'src/components/elements/IconButton';
import LanguageSelector from 'src/domains/header/components/shared/LanguageSelector';
import HeaderNavigationBar from 'src/domains/header/components/shared/HeaderNavigationBar';
import StudyStatus from 'src/domains/manage-study/components/studyBuilder/Audiences/StudyStatus';
import ChooseLanguageModal from 'src/components/elements/ChooseLanguageModal';
import { studyService } from 'src/services';
import StudyAssetManager from '../../../../components/shared/StudyAssetManager';
import FileUploadHeader from '../../components/shared/FileUploadHeader';

import './styles.scss';

const className = 'manage-study-primary';
const el = (name, mod) => cn(className, name, mod);

export const StudyInput = ({ defaultValue, onStudyTitleChange }) => {
	const valueRef = useRef();
	const [inputValue, setInputValue] = useState(defaultValue);
	const [widthToAdd, setWidthToAdd] = useState(20);
	const [width, setWidth] = useState(0);

	const handleWidthUpdate = useCallback(() => {
		const widthVal = valueRef.current?.offsetWidth;
		setWidth(widthVal);
	}, []);

	const handleOnStudyTitleChange = e => {
		setInputValue(e.target.value);
		handleWidthUpdate();
		onStudyTitleChange(e);
	};

	useEffect(() => {
		const isOnReportPage = document.querySelector('.single-study-primary');
		if (isOnReportPage) {
			setWidthToAdd(15);
		}
	}, []);

	useEffect(() => {
		handleWidthUpdate();

		window.addEventListener('resize', handleWidthUpdate);

		return () => window.removeEventListener('resize', handleWidthUpdate);
	}, [handleWidthUpdate, inputValue]);

	return (
		<div className={el('title-container')}>
			<input
				style={{ width: `${width + widthToAdd}px` }}
				defaultValue={defaultValue}
				className={el('title')}
				placeholder="Title your study"
				maxLength="200"
				onChange={handleOnStudyTitleChange}
			/>
			<div className={el('hidden-input-width')} ref={valueRef}>
				{inputValue}
			</div>
		</div>
	);
};
StudyInput.propTypes = {
	defaultValue: PropTypes.string,
	onStudyTitleChange: PropTypes.func,
};

const ManageStudyPrimary = ({ defaultStudyName, setDefaultStudyName }) => {
	const dispatch = useDispatch();
	const timeoutRef = useRef();
	const { isPublic } = useIsPublic();

	const { audiences, study, sections, currentSection, language, languages } = useSelector(
		selectors.getManageStudyData,
	);
	const { header } = useSelector(state => state);

	const fetchStudy = useCallback(studyId => dispatch(actions.fetchStudy(studyId)), [dispatch]);
	const closeStudy = useCallback(id => dispatch(actions.closeStudy(id)), [dispatch]);
	const publishStudy = useCallback(id => dispatch(actions.publishStudy(id)), [dispatch]);
	const draftStudy = useCallback(id => dispatch(actions.draftStudy(id)), [dispatch]);
	const patchStudy = (id, data) => dispatch(actions.patchStudy(id, data));
	const fetchProducts = useCallback(id => dispatch(actions.fetchProducts(id)), [dispatch]);
	const fetchQuestions = useCallback(id => dispatch(actions.fetchQuestions(id)), [dispatch]);
	const setTranslationsModal = useCallback(
		translationsModal => dispatch(actions.setTranslationsModal(translationsModal)),
		[dispatch],
	);
	const setLanguage = useCallback(l => dispatch(actions.setLanguage(l)), [dispatch]);
	const setLaunchAudienceModal = useCallback(
		launchAudienceModal => dispatch(actions.setLaunchAudienceModal(launchAudienceModal)),
		[dispatch],
	);
	const setDevicePreviewMode = devicePreviewMode => dispatch(actions.setDevicePreviewMode(devicePreviewMode));
	const setShowDevicePreviewMode = showDevicePreviewMode =>
		dispatch(actions.setShowDevicePreviewMode(showDevicePreviewMode));

	const [showChooseLanguage, setShowChooseLanguage] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => () => setDefaultStudyName(null), [setDefaultStudyName]);

	// Audience Status
	const haveLiveAudienceGroups = audienceStatusUtil.haveLiveAudienceGroups();
	const haveCompleteAudienceGroups = audienceStatusUtil.haveCompleteAudienceGroups();
	const studyHasIssues = audienceStatusUtil.studyHasIssues();
	const IRIssue = audienceStatusUtil.getIRIssue();
	const audienceCollection = useSelector(state => manageStudySelectors.getAudienceCollection(state));
	const collectionWithDemoGroups = useMemo(
		() => !audienceCollection?.loading && !!audienceCollection?.content?.demographicGroups?.length,
		[audienceCollection?.content?.demographicGroups, audienceCollection?.loading],
	);
	const emptySections = useMemo(
		() => sections?.status === 'ready' && !sections?.content?.length,
		[sections?.content, sections?.status],
	);

	const onStudyTitleChange = e => {
		const newStudyName = e.target.value;

		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(() => {
			setDefaultStudyName(newStudyName);
			patchStudy(study.id, { name: newStudyName });
		}, 800);
	};

	const onOpen = () => {
		setDevicePreviewMode('mobile');
		setShowDevicePreviewMode(true);
	};

	const renderStudyName = () => {
		if (!study) return;

		const userCanUpdate = Auth.userCan('study:update');
		if (!userCanUpdate) return <div className={el('study-name')}>{study.name}</div>;

		return (
			<div className={el('study-name')}>
				<StudyAssetManager study={study} key={study?.id} />
				<StudyInput
					defaultValue={study ? study?.name : defaultStudyName}
					onStudyTitleChange={onStudyTitleChange}
				/>
			</div>
		);
	};

	const renderPreviewIcon = () => {
		const userIsTrial = Auth.userCan('user:trial') && !Auth.isDigAdmin();
		if (userIsTrial) return;

		return (
			<div className={el('preview-button')}>
				<IconButton
					icon="play_circle_outlined"
					onClick={onOpen}
					tooltip="Preview Study"
					size="large"
					position="bottom"
				/>
			</div>
		);
	};

	const renderBackButton = () => (
		<Link to="/studies" className={el('upsiide-icon')}>
			<Iconof icon="arrow_backward" size="large" color="grey" />
		</Link>
	);

	useEffect(() => {
		if (sections.status !== 'ready') return;
		const hasQuestions =
			sections.content?.find(({ questions, statements }) => questions?.length || statements?.length) || false;

		if (!header.createIcon && hasQuestions) {
			dispatch(headerActions.setCreateIcon(true));
		} else if (header.createIcon && !hasQuestions) {
			dispatch(headerActions.setCreateIcon(false));
		}
	}, [header, sections]);

	useEffect(() => {
		if (header.audienceIcon && audienceCollection.loading) return; // don't change yet

		if (!header.audienceIcon && (haveLiveAudienceGroups || haveCompleteAudienceGroups)) {
			dispatch(headerActions.setAudienceIcon(true));
		} else if (header.audienceIcon && !(haveLiveAudienceGroups || haveCompleteAudienceGroups)) {
			dispatch(headerActions.setAudienceIcon(false));
		}
	}, [audienceCollection, header, sections, haveLiveAudienceGroups, haveCompleteAudienceGroups]);

	useEffect(() => {
		if (header.audienceErrorIcon && audienceCollection.loading) return;

		if (!header.createErrorIcon && collectionWithDemoGroups && emptySections) {
			dispatch(headerActions.setCreateErrorIcon(true));
		} else if (header.createErrorIcon && !(collectionWithDemoGroups && emptySections)) {
			dispatch(headerActions.setCreateErrorIcon(false));
		}

		if (!header.audienceErrorIcon && studyHasIssues) {
			dispatch(headerActions.setAudienceErrorIcon(true));
			dispatch(headerActions.setAudienceIcon(false));
		} else if (header.audienceErrorIcon && !studyHasIssues) {
			dispatch(headerActions.setAudienceErrorIcon(false));
			dispatch(headerActions.setAudienceIcon(true));
		}
	}, [
		audienceCollection,
		header?.audienceErrorIcon,
		header?.createErrorIcon,
		studyHasIssues,
		collectionWithDemoGroups,
		emptySections,
	]);

	useEffect(() => {
		if (!header.reportIcon && haveCompleteAudienceGroups) {
			dispatch(headerActions.setReportIcon(true));
		} else if (header.reportIcon && !haveCompleteAudienceGroups) {
			dispatch(headerActions.setReportIcon(false));
		}
	}, [header, haveCompleteAudienceGroups]);

	const changeLanguage = useCallback(
		newLanguage => {
			// Used for deciding which language dropdown to render
			const isManageStudyDomain = /manage/.test(window.location.href);

			setLanguage(newLanguage);
			fetchProducts(study?.id);
			if (isManageStudyDomain) {
				fetchQuestions(study?.id);
			}
		},
		[fetchProducts, fetchQuestions, setLanguage, study?.id],
	);

	const renderLanguageSelector = () => {
		if (!study) return;

		return (
			<LanguageSelector
				disabled={false}
				showIcon={false}
				studyId={study.id}
				allLanguages={languages || []}
				languages={study.languages || []}
				onChangeLanguage={changeLanguage}
				language={language || study.defaultLanguage}
				setTranslationsModal={setTranslationsModal}
				setEditStudyLanguage={setLanguage}
				currentSection={currentSection}
				sections={sections}
				showImportTranslationsOption
				manageLanguages
				isCreateMode
			/>
		);
	};

	const isDigAdmin = Auth.isDigAdmin();

	const changeStudyLanguage = async lang => {
		try {
			setLoading(true);
			await studyService.patchStudyTranslation(study?.id, study?.translations?.[0]?.id, {
				destinationLanguageCode: lang,
			});

			fetchStudy(study?.id);
			setLanguage(lang);
			setShowChooseLanguage(false);

			if (currentSection?.content.id)
				dispatch(sectionActions.fetchSection(currentSection?.content?.id, 'edit', true, study?.id));
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container className={className}>
			<Row>
				{study?.settings?.preStudyCheck && (
					<Col className={el('left')}>
						{renderBackButton()}
						{renderStudyName()}
					</Col>
				)}
				<Col className={el('center')}>
					{!study?.settings?.preStudyCheck && renderBackButton()}
					<TemplateCardsProvider>
						<HeaderNavigationBar hasReportData={haveCompleteAudienceGroups} IRIssue={IRIssue} />
					</TemplateCardsProvider>
				</Col>
				{study?.settings?.preStudyCheck && (
					<Col className={el('right')}>
						{renderLanguageSelector()}
						{renderPreviewIcon()}
						{!isPublic ? <FileUploadHeader /> : null}
						<ChooseLanguageModal
							show={showChooseLanguage}
							setShow={setShowChooseLanguage}
							languages={languages}
							globalLanguages={languages}
							onClick={lang => changeStudyLanguage(lang)}
							loading={loading}
						/>
						{isDigAdmin && (
							<StudyStatus
								study={study}
								audiences={audiences ? audiences.content : []}
								setLaunchAudienceModal={setLaunchAudienceModal}
								closeStudy={closeStudy}
								publishStudy={publishStudy}
								draftStudy={draftStudy}
								isDigAdmin={isDigAdmin}
								showContactSupport
							/>
						)}
					</Col>
				)}
			</Row>
		</Container>
	);
};

ManageStudyPrimary.propTypes = {
	setDefaultStudyName: PropTypes.any,
	defaultStudyName: PropTypes.any,
};

export default ManageStudyPrimary;
