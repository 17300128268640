import { useEffect } from 'react';

const useArtificialBlur = (ref, callback) => {
	const handleLoseFocus = e => {
		callback(e);
	};

	useEffect(() => {
		if (ref?.current) {
			ref.current.addEventListener('focusout', handleLoseFocus);
			const refCopy = ref.current;
			return () => {
				refCopy.removeEventListener('focusout', handleLoseFocus);
			};
		}
		// eslint-disable-next-line
	}, [callback]);
};

export default useArtificialBlur;
