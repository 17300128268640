import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UnstyledTextarea from 'src/components/inputs/UnstyledTextarea';

import './styles.scss';

const InlineTextarea = ({ className: $className, value, placeholder, onChange, onFocus, onClick, onBlur, ...props }) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<div className={`inline-textarea ${$className} ${isActive ? 'active' : ''}`} onClick={onClick}>
			<UnstyledTextarea
				{...props}
				value={value || ''}
				placeholder={placeholder}
				onChange={e => onChange(e.target.value)}
				onFocus={e => {
					setIsActive(true);
					if (onFocus) onFocus(e);
				}}
				onKeyDown={e => {
					const { key, target } = e;
					if (key === 'Enter') {
						e.preventDefault();
						onChange(target.value);
						e.target.blur();
					} else if (key === 'Escape') {
						e.preventDefault(); // Prevents the content editable from dropping to a new line when you hit enter
						e.target.blur();
					}
				}}
				onBlur={e => {
					setIsActive(false);
					if (onBlur) onBlur(e);
				}}
			/>
		</div>
	);
};

InlineTextarea.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
};

export default InlineTextarea;
