import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import Auth from 'src/utilities/auth';
import { TextRowPlaceholder } from '@upsiide/ui-components';
import { useSelector, useDispatch } from 'react-redux';
import toastr from 'toastr';
import CustomModalConfirmation from 'src/components/shared/CustomModalConfirmation';
import Modal from 'src/components/shared/Modal';
import * as studyActions from 'src/domains/manage-study/actions';
import { getAuthData } from 'src/domains/selectors';

import ModalUploadFile from '../ModalUploadFile';
import FileRow from './FileRow';
import service from '../../../../../services/studySample.service';
import './styles.scss';

const className = 'file-upload-dropdown';
const el = (name, mod) => cn(className, name, mod);

const skeletonStyle = { maxWidth: 100, height: 14, borderRadius: 4, margin: 0 };

const FileUploadDropdown = ({ onClose, setFiles }) => {
	const [showModal, setShowModal] = useState(false);
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [fileToDelete, setFileToDelete] = useState(null);
	const [localFiles, setLocalFiles] = useState([]);
	const { study, studyFiles } = useSelector(state => state.manageStudy);
	const authData = useSelector(state => getAuthData(state));
	const [isScrolling, setScrolling] = useState(false);

	const ref = useRef();

	const handleScroll = useCallback(() => {
		if (!isScrolling) setScrolling(true);
	}, [isScrolling]);

	const handleScrollEnd = useCallback(() => {
		if (isScrolling) setScrolling(false);
	}, [isScrolling]);

	useEffect(() => {
		const div = ref.current;
		if (div) {
			div.addEventListener('scroll', handleScroll);
			div.addEventListener('scrollend', handleScrollEnd);
		}
	}, [handleScroll, handleScrollEnd]);

	const canUpload = useMemo(() => {
		if (Auth.isDigAdmin()) return true;
		if (
			authData.privileges.find(
				priv =>
					priv.uuid === authData.clientId &&
					(priv.roleCode === 'DIG_ADMIN' || priv.roleCode === 'DIG_EMPLOYEE'),
			)
		)
			return true;
		return false;
	}, [authData]);

	const dispatch = useDispatch();

	const fetchStudyFiles = useCallback(
		(studyId = 0, addLoading = true) => dispatch(studyActions.fetchStudyFiles(studyId, addLoading)),
		[dispatch],
	);

	const onDeleteFile = useCallback(async id => {
		setDisplayDeleteModal(true);
		setFileToDelete(id);
	}, []);

	const deleteFile = useCallback(async () => {
		const fileName = studyFiles?.content.find(file => file.id === fileToDelete)?.fileName;
		if (!fileName) return;

		try {
			setDisplayDeleteModal(false);
			setFiles(studyFiles?.content.filter(file => file.id !== fileToDelete));
			setFileToDelete(null);
			await service.deleteStudyFile(study?.id, fileToDelete);
			fetchStudyFiles(study.id, false);
			const croppedFilename = fileName?.length > 20 ? `${fileName?.substring(0, 20)}...` : fileName;
			toastr.success(
				`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
					<p style="font-size: 16px;">File <strong>${croppedFilename}</strong> was deleted successfully.</p>
				</div>`,
			);
		} catch (error) {
			setDisplayDeleteModal(false);
			setFileToDelete(null);
			fetchStudyFiles(study.id, false);
			/* eslint-disable no-console */
			const croppedFilename = fileName?.length > 20 ? `${fileName?.substring(0, 20)}...` : fileName;
			console.error(error);
			toastr.error(
				`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
					<p style="font-size: 16px;">
						An error occurred during the file <strong>${croppedFilename}</strong> delete. Please try again
					</p>
				</div>`,
			);
		}
	}, [study, fetchStudyFiles, fileToDelete, studyFiles]);

	const onRenameFile = async (id, fileName) => {
		if (!fileName || fileName === studyFiles?.content.find(file => file.id === id)?.fileName) return;
		try {
			await service.patchStudyFile(study?.id, id, { fileName });
			fetchStudyFiles(study.id, false);
		} catch (error) {
			/* eslint-disable no-console */
			const croppedFilename = fileName?.length > 20 ? `${fileName?.substring(0, 20)}...` : fileName;
			console.error(error);
			toastr.error(
				`<div style="display: flex; justify-content: space-between; color: #3b3b3b;">
					<p style="font-size: 16px;">
						An error occurred during the file <strong>${croppedFilename}</strong> rename. Please try again
					</p>
				</div>`,
			);
		}
	};

	const renderFooter = () => {
		if (canUpload)
			return (
				<div className={el('footer')}>
					<span
						className={el('upload-files')}
						aria-hidden
						onClick={() => {
							setShowModal(true);
						}}
						data-testid="upload"
					>
						Upload Files
					</span>
				</div>
			);
		return null;
	};

	const renderShareUploadFooter = () => {
		if (canUpload)
			return (
				<div className={el('share-footer')}>
					<span
						className={el('upload-files')}
						aria-hidden
						onClick={() => {
							setShowModal(true);
						}}
						data-testid="upload"
					>
						Upload Files
					</span>
				</div>
			);
		return null;
	};

	const renderEmpty = () => (
		<>
			<div className={el('empty-container')}>
				<span className={el('empty-title')}>There are no files here yet.</span>
				<span className={el('empty-subtitle')}>Get started by clicking the "Upload Files" button.</span>
			</div>
			{renderFooter()}
		</>
	);

	const renderFiles = () => (
		<div className={el('container')}>
			<div className={el('list-files')} ref={ref}>
				{studyFiles?.content
					?.filter(file => !!file?.isVisible)
					.map(file => (
						<FileRow
							file={file}
							onDeleteFile={onDeleteFile}
							fetchStudyFiles={() => fetchStudyFiles(study.id, false)}
							onRenameFile={onRenameFile}
							onClose={onClose}
							canUpload={canUpload}
							key={`file-${file.id}`}
							isScrolling={isScrolling}
						/>
					))}
			</div>
			{renderShareUploadFooter()}
		</div>
	);

	const renderSkeleton = () => (
		<div className={el('container')}>
			{[0].map(i => (
				<div className={el('skeleton-file')} key={i}>
					<div className={el('placeholder-square')} />
					<div className={el('skeleton-file-row')}>
						<TextRowPlaceholder color="#F4F4F4" style={{ ...skeletonStyle, maxWidth: 328 }} />
						<TextRowPlaceholder color="#F4F4F4" style={{ ...skeletonStyle, maxWidth: 208 }} />
					</div>
					<TextRowPlaceholder color="#F4F4F4" style={{ ...skeletonStyle, maxWidth: 24, height: 24 }} />
				</div>
			))}
			<div className={el('footer')}>
				<TextRowPlaceholder color="#F4F4F4" style={{ ...skeletonStyle, maxWidth: 86 }} />
			</div>
		</div>
	);

	const renderContent = () => {
		if (studyFiles?.loading) return renderSkeleton();
		return !studyFiles?.content?.filter(file => !!file?.isVisible).length ? renderEmpty() : renderFiles();
	};

	return (
		<div className={className} aria-hidden onClick={e => e.stopPropagation()}>
			{renderContent()}
			<ModalUploadFile
				files={localFiles}
				setFiles={setLocalFiles}
				studyFiles={studyFiles?.content?.filter(file => !!file?.isVisible) || []}
				setStudyFiles={setFiles}
				show={showModal}
				onClose={() => setShowModal(false)}
				fetchStudyFiles={fetchStudyFiles}
			/>
			<Modal show={displayDeleteModal} width={500} padding={0} onClose={() => setDisplayDeleteModal(false)}>
				<CustomModalConfirmation
					onCancel={() => {
						setDisplayDeleteModal(false);
						setFileToDelete(null);
					}}
					onConfirm={deleteFile}
					headerText="Are you sure you want to delete this file?"
					bodyText={
						<>
							<span className={el('modal-text')}>
								Deleting this file will permanently remove it from this study.
							</span>
							<span className={el('modal-text')}>Please make sure you have a backup if needed.</span>
						</>
					}
					confirmText="Yes"
					cancelText="No"
				/>
			</Modal>
		</div>
	);
};

FileUploadDropdown.propTypes = {
	setFiles: PropTypes.func,
	onClose: PropTypes.func,
};

export default FileUploadDropdown;
