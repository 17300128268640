import { useEffect, useCallback } from 'react';

export const useScroll = ({ scrollRef, scrollRefVisible, fetchAssets, searchValue }) => {
	const normalScrollHandler = useCallback(() => {
		const top = scrollRef.current.scrollTop;
		const { scrollHeight } = scrollRef.current;
		const { offsetHeight } = scrollRef.current;
		const scrollbarPosition = top / (scrollHeight - offsetHeight);

		if (scrollbarPosition > 0.8) {
			fetchAssets(searchValue);
		}
	}, [fetchAssets, scrollRef, searchValue]);

	useEffect(() => {
		if (scrollRef.current && scrollRefVisible) {
			scrollRef.current.addEventListener('scroll', normalScrollHandler);
		}

		return () => scrollRef?.current?.removeEventListener('scroll', normalScrollHandler);
	}, [normalScrollHandler, scrollRef, scrollRefVisible]);
};

export const useFetchAssetsOnLoad = ({ loading, assets, fetchAssets, searchValue }) => {
	useEffect(() => {
		if (assets.length === 0 && !loading) {
			fetchAssets(searchValue);
		}
	}, [assets.length, fetchAssets, loading, searchValue]);
};

export const useResetDataOnUnMount = ({ resetAllThings, setAssets, setInitialDataLoaded }) => {
	useEffect(
		() => () => {
			resetAllThings();
			setAssets([]);
			setInitialDataLoaded(false);
		},
		[resetAllThings, setAssets, setInitialDataLoaded],
	);
};
