import { navigate, location } from 'src/utilities/router/routerScopeLeaker';
import _, { uniqBy, chunk } from 'lodash';
import * as services from 'src/services';
import * as authSelectors from 'src/domains/auth/selectors';
import * as authActions from 'src/domains/auth/actions';
import * as helpers from 'src/components/helpers';
import quillUtils from 'src/utilities/quill';
import toastr from 'toastr';
import Cookies from 'src/utilities/cookies';
import { validateFileSize } from 'src/utilities/file-upload-helper';
import masking from 'src/utilities/masking';
import * as manageBlueprintSelectors from 'src/domains/manage-blueprints/selectors';
import { upsertProductTags } from '../../all-studies/actions';
import * as accountActions from '../../account/actions';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as reportingActions from '../actions/reporting';
import * as sectionActions from '../actions/sections';
import * as reportingSelectors from '../selectors/reporting';

// Split out effects
import sectionEffects from './sections';
import questionEffects from './questions';

import { getAllQuestions } from '../components/reporting/MonadicSplit/utilities';

toastr.options = {
	positionClass: 'toast-bottom-left',
	timeOut: 5000,
};

const csvImportErrorTextFormat = 'There was an error importing the CSV. Please make sure it is the correct format.';
const csvImportErrorTextTagLength =
	'There was an error importing the CSV. Please make sure that tag lengths do not exceed 50 characters.';

const dataToProduct = data => ({
	name: data.label,
	categories: data.category ? [data.category] : [],
	tags: data.tags || [],
	defaultLanguageCode: 'en_CA',
	layout: data.style,
	status: data.status,
	sectionId: data.sectionId,
	translations: data.languageDependent.map(values => ({
		languageCode: values.language,
		isDefaultLanguageCode: values.isDefaultLanguageCode,

		...(data.style === 'classic'
			? {
					fieldOneType: 'asset',
					fieldOne: values.image,
			  }
			: {}),

		...(data.style === 'one-field' && values.firstField
			? {
					fieldOneType: values.firstField.type,
					fieldOne:
						values.firstField.type === 'html'
							? quillUtils.clean(values.firstField.content)
							: values.firstField.content,
					fieldOneOptions: {
						imagesWidth: values.fieldOneImagesWidth,
					},
					options: {
						textColor: values.textColor,
						backgroundColor: values.backgroundColor,
					},
			  }
			: {}),

		...(data.style === 'two-field' && values.firstField
			? {
					fieldOneType: values.firstField.type,
					fieldOne:
						values.firstField.type === 'html'
							? quillUtils.clean(values.firstField.content)
							: values.firstField.content,
					fieldOneOptions: {
						imagesWidth: values.fieldOneImagesWidth,
					},

					...(values.secondField
						? {
								fieldTwoType: values.secondField.type,
								fieldTwo:
									values.secondField.type === 'html'
										? quillUtils.clean(values.secondField.content)
										: values.secondField.content,
								fieldTwoOptions: {
									imagesWidth: values.fieldTwoImagesWidth,
								},
						  }
						: {}),
					options: {
						textColor: values.textColor,
						backgroundColor: values.backgroundColor,
					},
			  }
			: {}),

		description: quillUtils.clean(values.description),
		title: values.productName,
		subtitle: values.subtitle,
		price: values.price,
	})),
});

/** THIS IS A REWORK */
const performNewInitialFetch = (store, action) => {
	if (action.type === actions.PERFORM_NEW_INITIAL_FETCH) {
		const { id } = action.payload;
		services.studyService
			.getStudy(id)
			.then(study => {
				if (!Cookies.getUpsiideAccountToken() || study.data.accountUuid !== Cookies.getUpsiideAccountToken()) {
					Cookies.setUpsiideAccountToken(study.data.accountUuid);
					services.accountService
						.getAccount(study.data.accountUuid)
						.then(({ data: account }) => {
							store.dispatch(
								accountActions.setAccount({
									loading: false,
									content: account,
									error: null,
								}),
							);
							store.dispatch(
								accountActions.setClientId(study.data.accountUuid, account.ownerUuid, false),
							);
						})
						.catch(error => {
							store.dispatch(accountActions.setAccount({ loading: false, error }));
						});
				}
				store.dispatch(authActions.setClientId(study.data.clientId));
				store.dispatch(actions.setStudy(study.data));
				store.dispatch(actions.setLanguage(study.data.defaultLanguage));
				store.dispatch(actions.fetchStudyLoi(study.data.id));
				store.dispatch(actions.fetchAllAudienceCollection(study.data.id));
			})
			.then(() => {
				// store.dispatch(actions.fetchSections(id, false, true));
			})
			.catch(error => {
				if (error.response && error.response.status === 404) {
					navigate('/study-not-found', { replace: true });
				} else {
					console.error('There was an error fetching client ID: ', error);
					store.dispatch(actions.setStudy(new Error(error)));
				}
			});
	}
};

const performInitialFetch = (store, action) => {
	if (action.type === actions.PERFORM_INITIAL_FETCH) {
		const { id, canUpdateStudy, sectionId } = action.payload;
		try {
			// store.dispatch(actions.fetchProducts(id));
			// store.dispatch(actions.fetchStudy(id));
			store.dispatch(actions.fetchLanguages());
			store.dispatch(reportingActions.setActiveBaseFilters({}));
			// Need update permission for these API calls
			if (canUpdateStudy) {
				store.dispatch(actions.clearAudienceNotice());
				store.dispatch(actions.fetchCategories(id));
				store.dispatch(actions.fetchQuestions(id));
				// store.dispatch(actions.fetchAudiencesAndUpdatePrices(id));
				// store.dispatch(actions.fetchAudienceTemplates());
			}
			// store.dispatch(actions.fetchGeographies());
			store.dispatch(actions.fetchSections(id, false, !sectionId, sectionId));
			services.studyService
				.validateStudy(id)
				.then(data => {
					store.dispatch(actions.setStudyValidations(data.data));
				})
				.catch(error => {
					console.log(error);
				});
		} catch (error) {
			console.error('There was an error fetching client ID: ', error);
			store.dispatch(actions.setStudy(new Error(error)));
		}
	}
};

const fetchStudy = (store, { type, payload }) => {
	if (type === actions.FETCH_STUDY) {
		console.warn('effects/index Fetch study');
		const { id } = payload;

		const sections = selectors.getSections(store.getState());
		const currentStudy = selectors.getStudy(store.getState());
		const allQuestions = getAllQuestions(sections);
		const maskingError = masking.getMaskingArrayError(allQuestions)?.length > 0 || false;

		services.studyService
			.getStudy(id, { mode: 'edit' })
			.then(({ data }) => {
				if (data?.languages?.length === 1 && currentStudy?.defaultLanguage !== data?.defaultLanguage) {
					store.dispatch(actions.setLanguage(data.defaultLanguage));
				}
				store.dispatch(actions.setStudy(data, maskingError));
			})
			.catch(error => {
				store.dispatch(actions.setStudy(new Error(error)));
			});
	}
};

const fetchStudyForTranslation = async (store, { type, payload }) => {
	if (type === actions.FETCH_STUDY_FOR_TRANSLATION) {
		const { id } = payload;
		const currentSection = selectors.getCurrentSection(store.getState());
		const audienceCollection = selectors.getAudienceCollection(store.getState()).content;
		services.studyService
			.getStudy(id, { mode: 'edit' })
			.then(({ data }) => {
				store.dispatch(actions.setStudy(data));

				let hasPending = false;
				data.translations.forEach(translation => {
					if (translation.googleTranslateStatus === 'pending') hasPending = true;
				});

				if (!hasPending) {
					if (currentSection?.content?.id) {
						store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
					}
					if (audienceCollection?.screeningQuestions) {
						store.dispatch(actions.fetchAudienceCollection(id, audienceCollection.id, false));
					}
				} else {
					setTimeout(() => store.dispatch(actions.fetchStudyForTranslation(id)), 3000);
				}
			})
			.catch(error => {
				store.dispatch(actions.setStudy(new Error(error)));
			});
	}
};

const updateStudy = async (store, { type, payload }) => {
	if (type === actions.UPDATE_STUDY) {
		const { id, data } = payload;
		try {
			store.dispatch(actions.setSaveButtonState('loading'));
			const response = await services.studyService.updateStudy(id, data);
			store.dispatch(actions.setStudy(response.data));
			// Button Text
			store.dispatch(actions.setSaveButtonState('active'));
			store.dispatch(actions.setSaveButtonText('Updated!'));
			setTimeout(() => {
				store.dispatch(actions.setSaveButtonText('Save'));
			}, 2500);
			store.dispatch(actions.performInitialFetch(id, true));
		} catch (error) {
			console.error(error);
			store.dispatch(actions.studyUpdated(new Error(error)));
		}
	}
};

const reorderStudyTranslations = (store, { type, payload }) => {
	if (type === actions.REORDER_STUDY_TRANSLATIONS) {
		const { newOrder } = payload;
		const study = selectors.getStudy(store.getState());
		services.studyService
			.reorderStudyTranslations(study.id, newOrder)
			.then(({ result }) => {
				store.dispatch(actions.fetchStudy(study.id));
			})
			.catch(error => {
				toastr.error('There was an error re-ordering study languages, please refresh and try again.');
				console.error('Error reordering translations: ', error);
			});
	}
};

const fetchLanguages = (store, { type }) => {
	if (type === actions.FETCH_LANGUAGES) {
		services.languageService.getLanguages().then(({ data }) => {
			store.dispatch(actions.setLanguages(data));
		});
	}
};

const fetchCategories = (store, { type }) => {
	if (type === actions.FETCH_CATEGORIES) {
		services.termService.getStudyCategories().then(categories => {
			store.dispatch(actions.setCategories(categories));
		});
		services.termService.getProductTags().then(productTags => {
			store.dispatch(actions.setProductTags(productTags));
		});
	}
};

const fetchProducts = (store, { type, payload }) => {
	if (type === actions.FETCH_PRODUCTS) {
		const study = selectors.getStudy(store.getState());
		const languageCode = study ? study.language : null;
		store.dispatch(actions.setProducts({ loading: true, error: null }));

		services.productService
			.getStudyProducts(payload.studyId, languageCode)
			.then(({ data }) => {
				store.dispatch(
					actions.setProducts({
						content: helpers.sortProducts(data.products, 'name', false),
						loading: false,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setProducts({ loading: false, error }));
			});
		if (!payload?.studyId) return;
		services.studyService
			.getAllProducts(payload?.studyId)
			.then(products => {
				store.dispatch(actions.setProductsLibrary(products.data.products));
			})
			.catch(() => {
				toastr.error('There was an error retrieving the idea library.');
			});
	}
};

const createProduct = async (store, { type, payload }) => {
	if (type === actions.CREATE_PRODUCT) {
		const { studyId, data } = payload;
		store.dispatch(actions.setProductModal({ loading: true, error: null }));
		try {
			/* Create product */
			await services.productService.create(studyId, dataToProduct(data));
			store.dispatch(actions.setProductModal({ content: false, loading: false }));
			store.dispatch(actions.fetchStudyLoi(studyId));
		} catch (error) {
			console.error(error);
			store.dispatch(actions.setProductModal({ loading: false, error }));
		}
		/* Fetch Products after creating */
		store.dispatch(actions.fetchProducts(studyId));
		store.dispatch(actions.fetchCategories());
		store.dispatch(actions.fetchSections(studyId));
		store.dispatch(actions.fetchSection(data.sectionId, 'edit'));
		store.dispatch(actions.fetchAllProducts(studyId));
	}
};

const updateProduct = async (store, { type, payload }) => {
	if (type === actions.UPDATE_PRODUCT) {
		const { studyId, productId, data } = payload;

		const currentSection = selectors.getCurrentSection(store.getState());

		store.dispatch(actions.setProductModal({ loading: true, error: null }));
		try {
			await services.productService.update(studyId, productId, dataToProduct(data));
			store.dispatch(actions.setProductModal({ content: false, loading: false }));
		} catch (error) {
			store.dispatch(actions.setProductModal({ loading: false, error }));
		}

		// Refetch the section
		if (currentSection && currentSection.content && currentSection.content.id) {
			store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
		}

		/* Fetch Products after creating */
		store.dispatch(actions.fetchCategories());
		// TODO - remove once other screens removed.
		store.dispatch(actions.fetchProducts(studyId));
	}
};

const formatProductForTranslation = data => {
	const newData = {
		name: data.name,
		translations: data.translations,
	};

	newData.translations.map(translation => {
		if (translation.fieldOne && translation.fieldOneType && translation.fieldOneType === 'asset')
			translation.fieldOne = translation.fieldOne.map(asset => asset.id || asset);
		if (translation.fieldTwo && translation.fieldTwoType && translation.fieldTwoType === 'asset')
			translation.fieldTwo = translation.fieldTwo.map(asset => asset.id || asset);
		if (translation.fieldThree && translation.fieldThreeType && translation.fieldThreeType === 'asset')
			translation.fieldThree = translation.fieldThree.map(asset => asset.id || asset);

		if (translation.fieldOne === null) {
			delete translation.fieldOne;
			delete translation.fieldOneType;
			delete translation.fieldOneOptions;
		}

		if (translation.fieldTwo === null) {
			delete translation.fieldTwo;
			delete translation.fieldTwoType;
			delete translation.fieldTwoOptions;
		}

		if (translation.fieldThree === null) {
			delete translation.fieldThree;
			delete translation.fieldThreeType;
			delete translation.fieldThreeOptions;
		}
	});

	return newData;
};

const patchProduct = async (store, { type, payload }) => {
	if (type === actions.PATCH_PRODUCT) {
		const { studyId, productId, data } = payload;

		const currentSection = selectors.getCurrentSection(store.getState());
		const formattedData = formatProductForTranslation(data);
		try {
			await services.productService
				.patch(studyId, productId, formattedData)
				.catch(e => console.error('there was an error saving product', e));
			store.dispatch(actions.fetchStudyLoi(studyId));
		} catch (error) {
			console.error('There was an error saving product translation');
		}

		// Refetch the section
		if (currentSection && currentSection.content && currentSection.content.id) {
			store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
		}
	}
};

const deleteProduct = async (store, { type, payload }) => {
	if (type === actions.DELETE_PRODUCT) {
		const { studyId, sectionId, productId } = payload;
		const state = store.getState();
		const section = selectors.getCurrentSection(state).content;

		if (section.type === 'monadic_split') {
			let ideasPerPerson = 1;
			const ideasPerPersonSetting = section.settings.find(setting => setting.label === 'ideas_per_person');
			if (ideasPerPersonSetting) {
				ideasPerPerson = ideasPerPersonSetting.value;
			}
			if (ideasPerPerson > section.products.length - 1) {
				let value = String(1);
				if (section.products.length > 1) {
					value = String(section.products.length - 1);
				}
				const data = {
					label: 'ideas_per_person',
					value,
					type: 'string',
					languageCode: null,
				};
				store.dispatch(
					sectionActions.patchSectionSettings(studyId, sectionId, ideasPerPersonSetting.id, data, true),
				);
			}
		}

		store.dispatch(actions.setProducts({ loading: true, error: null }));

		try {
			await services.productService.delete(studyId, sectionId, productId);
			store.dispatch(actions.fetchProducts(studyId));
			store.dispatch(actions.fetchCategories());
			store.dispatch(actions.fetchSections(studyId, true));
			store.dispatch(actions.fetchSection(sectionId, 'edit', true));
			store.dispatch(actions.fetchAllProducts(studyId));
			store.dispatch(actions.fetchStudyLoi(studyId));
		} catch (error) {
			store.dispatch(actions.setProducts({ loading: false, error }));
		}
	}
};

const fetchProductWhenModalSet = async (store, { type, payload }) => {
	if (type === actions.CHANGE_PRODUCT_MODAL) {
		const { productModal } = payload;
		const { studyId, content } = productModal;
		if (content !== true && content !== false && !!content) {
			store.dispatch(actions.setProducts({ loading: true }));
			const state = store.getState();
			const currentSection = selectors.getCurrentSection(state);
			const productId = currentSection.content.type === 'monadic_split' ? content.localProductId : content.id;
			const product = await services.productService.get(studyId, productId, 'edit');
			store.dispatch(actions.setProducts({ loading: false }));
			store.dispatch(actions.setProductModal({ ...productModal, content: product, open: true, mode: 'edit' }));
		} else {
			store.dispatch(actions.setProductModal(productModal));
		}
	}
};

const fetchQuestion = (store, { type, payload }) => {
	if (type === actions.FETCH_QUESTION) {
		store.dispatch(actions.setQuestionModal({ loading: true, error: null }));
		const { studyId, questionId } = payload;
		services.questionService
			.getStudyQuestion(studyId, questionId)
			.then(({ data }) => {
				store.dispatch(
					actions.setQuestionModal({
						visible: true,
						def: data,
						content: 'edit-question',
						loading: false,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setQuestionModal({ loading: false, error }));
			});
	}
};

const fetchQuestionAndDuplicate = (store, { type, payload }) => {
	if (type === actions.FETCH_QUESTION_AND_DUPLICATE) {
		store.dispatch(actions.setQuestionModal({ loading: true, error: null }));
		const { studyId, questionId } = payload;
		services.questionService
			.getStudyQuestion(studyId, questionId)
			.then(({ data }) => {
				/** Set id 'undefined' from translations and options for question duplication */
				const def = {
					...data,
					id: undefined,
					translations: data.translations.map(t => ({
						...t,
						id: undefined,
					})),
					options: data.options.map(o => ({
						...o,
						id: undefined,
					})),
				};
				store.dispatch(
					actions.setQuestionModal({
						visible: true,
						content: 'add-question',
						def,
						loading: false,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setQuestionModal({ loading: false, error }));
			});
	}
};

const fetchQuestions = (store, { type, payload }) => {
	if (type === actions.FETCH_QUESTIONS) {
		const study = selectors.getStudy(store.getState());
		const languageCode = study ? study.language : null;
		store.dispatch(actions.setQuestions({ loading: true, error: null }));
		services.questionService
			.getStudyQuestions(payload.studyId, languageCode)
			.then(({ data }) => {
				store.dispatch(actions.setQuestions({ content: data, loading: false }));
			})
			.catch(error => {
				store.dispatch(actions.setQuestions({ loading: false, error }));
			});
	}
};

const dataToQuestion = (languages, data) => {
	const {
		status,
		format,
		sortOrder,
		useAsFilter,
		options,
		hasNoneOfTheAboveOption,
		hasOtherSpecifyOption,
		randomizeOptions,
		translations: [translation],
	} = data;
	const result = {
		type: 'custom',
		style: format, // question format dropdown
		label: translation.internal,
		status,
		isFilter: useAsFilter ? 1 : 0,
		sortOrder,

		// Update saved question to have 'hasNoneOfTheAboveOption, hasNoneOfTheAboveOption' options
		hasNoneOfTheAboveOption: Boolean(hasNoneOfTheAboveOption),
		hasOtherSpecifyOption: Boolean(hasOtherSpecifyOption),
		randomizeOptions: Boolean(randomizeOptions),

		// Translations
		translations: languages
			.filter(lang => translation[lang.languageCode])
			.map(lang => ({
				label: translation[lang.languageCode],
				languageCode: lang.languageCode,
			})),
		options,
	};
	return result;
};

const createQuestion = async (store, { type, payload }) => {
	if (type === actions.CREATE_QUESTION) {
		const state = store.getState();
		const languages = selectors.getStudyLanguages(state);
		const questions = selectors.getQuestions(state);
		const studyId = selectors.getStudy(state).id;
		const currentSection = selectors.getCurrentSection(state);
		const { data } = payload;

		const question = dataToQuestion(languages, {
			...data,
			sortOrder: questions.content.length,
		});

		store.dispatch(actions.setQuestionModal({ loading: true, error: null }));

		try {
			/* Create question */
			await services.questionService.create(studyId, question);
			store.dispatch(actions.setQuestionModal({ visible: false, loading: false }));
			store.dispatch(actions.fetchQuestions(studyId));
			store.dispatch(actions.fetchSection(currentSection.id, 'edit', true, studyId));
		} catch (error) {
			store.dispatch(actions.setQuestionModal({ loading: false, error }));
		}
	}
};

const updateQuestion = async (store, { type, payload }) => {
	if (type === actions.UPDATE_QUESTION) {
		const state = store.getState();
		const languages = selectors.getStudyLanguages(state);
		const questions = selectors.getQuestions(state);
		const { studyId, questionId, data } = payload;
		store.dispatch(actions.setQuestionModal({ loading: true, error: null }));
		try {
			store.dispatch(
				actions.setQuestions({
					content: questions?.content?.map(question => {
						if (Number(question?.id) === Number(questionId)) {
							return { ...data };
						}
						return question;
					}),
					loading: false,
				}),
			);
			await services.questionService.patch(studyId, questionId, dataToQuestion(languages, data));
			store.dispatch(actions.setQuestionModal({ visible: false, loading: false }));
			store.dispatch(actions.fetchQuestions(studyId));
		} catch (error) {
			store.dispatch(actions.setQuestionModal({ loading: false, error }));
		}
	}
};

const deleteQuestion = async (store, { type, payload }) => {
	if (type === actions.DELETE_QUESTION) {
		const { studyId, id } = payload;

		store.dispatch(actions.setQuestions({ loading: true, error: null }));
		try {
			await services.questionService.delete(studyId, id);
			store.dispatch(actions.fetchQuestions(studyId));
		} catch (error) {
			store.dispatch(actions.setQuestions({ loading: false, error }));
		}
	}
};

const duplicateProduct = async (store, action) => {
	if (action.type === actions.DUPLICATE_PRODUCT) {
		const { studyId, id, count } = action.payload;

		store.dispatch(actions.setProducts({ loading: true }));
		services.productService
			.duplicate(id, count)
			.then(() => {
				store.dispatch(actions.fetchProducts(studyId));
			})
			.catch(error => {
				store.dispatch(actions.setProducts({ loading: false, error }));
			});
	}
};

const importImages = async (store, action) => {
	if (action.type === actions.IMPORT_IMAGES) {
		const { studyId, sectionId, fieldTarget, files } = action.payload;
		const batchSize = 5;

		store.dispatch(actions.setImportProductsModal({ loading: true }));
		store.dispatch(
			actions.setUploadImageStatus(
				files.map((f, i) => ({
					status: 'loading',
				})),
			),
		);

		try {
			/* Step 1. Upload files to our assets service */

			const batches = chunk(files, batchSize);
			const productBatches = [];
			for (const [batchIndex, batch] of batches.entries()) {
				productBatches.push(
					await Promise.all(
						batch.map(async (file, index) => {
							const fileIndex = batchSize * batchIndex + index;
							try {
								// use helper to validate if the file size is within the limit
								const sizeError = validateFileSize(file);
								if (sizeError) {
									// set the error message for the given asset
									store.dispatch(actions.setIndivUploadImageStatus(fileIndex, 'failed', sizeError));
									// short circuit here to avoid the backend beeing ping for a invalid file
									return;
								}
								const asset = await services.assetService.post(file);
								store.dispatch(actions.setIndivUploadImageStatus(fileIndex, 'success'));
								store.dispatch(actions.fetchStudyLoi(studyId));

								const name = file.name.split('.').slice(0, -1).join('.');
								const field = [asset.id];

								return { name, [fieldTarget]: field };
							} catch (err) {
								console.error('error upload');

								const message = 'Only JPG, PNG and GIF image formats are supported.';
								store.dispatch(actions.setIndivUploadImageStatus(fileIndex, 'failed', message));
							}
						}),
					),
				);
			}

			const products = productBatches.flat();

			const filteredProducts = products.filter(p => p);

			/* Step 2. Create Products bulk */
			const test = await services.productService.createBulk({
				studyId,
				sectionId,
				products: filteredProducts,
			});

			store.dispatch(actions.fetchProducts(studyId));
			store.dispatch(actions.fetchAllProducts(studyId));
			store.dispatch(actions.fetchSection(sectionId, 'edit', true));

			// if product count and success count match (no failed upload), after 400 msec close the modal
			if (products.length === filteredProducts.length) {
				setTimeout(() => {
					store.dispatch(actions.setImportProductsModal({ loading: false, visible: false }));
					store.dispatch(actions.setUploadImageStatus({}));
				}, 400);
			} else {
				// maybe errors
				store.dispatch(actions.setImportProductsModal({ loading: false }));
			}
		} catch (error) {
			store.dispatch(actions.setImportProductsModal({ loading: false, error }));
		}
	}
};

/**
 * Export Products effect
 */
const exportProducts = (store, action) => {
	if (action.type === actions.EXPORT_PRODUCTS) {
		const { studyId, sectionId = null, showModal = false } = action.payload;
		const study = selectors.getStudy(store.getState());
		const filename = `Export - Ideas - ${study.name}.csv`;

		if (showModal) {
			store.dispatch(actions.setImportProductsModal({ content: 'csv', showExportMessage: true, visible: true }));
		}
		services.studyService.exportProducts(studyId, sectionId, filename);
	}
};

const importProducts = (store, action) => {
	if (action.type === actions.IMPORT_PRODUCTS) {
		const { studyId, files, sectionId } = action.payload;
		const currentSection = selectors.getCurrentSection(store.getState());

		store.dispatch(actions.setImportProductsModal({ loading: true }));
		services.studyService
			.importProducts(studyId, files, sectionId)
			.then(() => {
				toastr.success('Successfully imported CSV file.');
				store.dispatch(
					actions.setImportProductsModal({
						loading: false,
						visible: false,
					}),
				);
			})
			.then(() => {
				if (currentSection.content) {
					services.termService.getProductTags().then(productTags => {
						store.dispatch(upsertProductTags(productTags));
						store.dispatch(actions.setProductTags(productTags));
					});
					store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
				}
				store.dispatch(actions.fetchStudyLoi(studyId));
			})
			// TODO - remove this products call
			.then(() => store.dispatch(actions.fetchProducts(studyId)))
			.catch(error => {
				console.log({ msg: error?.response?.data?.message });
				if (error?.response?.data?.details?.toLowerCase().includes('tag length cannot be more than')) {
					toastr.error(csvImportErrorTextTagLength);
				} else {
					toastr.error(csvImportErrorTextFormat);
				}
				store.dispatch(actions.setImportProductsModal({ loading: false, error, visible: true }));
			});
	}
};

const downloadStatementCsv = (store, action) => {
	if (action.type === actions.DOWNLOAD_STATEMENT_CSV) {
		const { studyId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const filename = `Export - Statements - ${study.name}.csv`;
		// TODO: Call the correct service once it is written
		services.studyService.exportStatements(studyId, null, filename);
	}
};

const downloadQuestionCsv = (store, action) => {
	if (action.type === actions.DOWNLOAD_QUESTION_CSV) {
		const { studyId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const filename = `Export - Questions - ${study.name}.csv`;
		// TODO: Call the correct service once it is written
		services.studyService.exportQuestions(studyId, null, filename);
	}
};

const downloadSwipeCsv = (store, action) => {
	if (action.type === actions.DOWNLOAD_SWIPE_CSV) {
		const { studyId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const filename = `Export - Ideas - ${study.name}.csv`;
		services.studyService.exportProducts(studyId, null, filename);
	}
};

const importCsvFiles = async (store, action) => {
	if (action.type === actions.IMPORT_CSV_FILES) {
		const { studyId, sectionId, files } = action.payload;
		const statementFile = files[0];
		const questionFile = files[1];
		const swipeFile = files[2];
		const promises = [];
		const audienceCollection = selectors.getAudienceCollection(store.getState()).content;

		store.dispatch(actions.setTranslationsModal({ loading: true, visible: true, error: null }));
		if (statementFile) {
			promises.push(services.studyService.importStatements(studyId, [statementFile], sectionId));
		}
		if (questionFile) {
			promises.push(services.studyService.importQuestions(studyId, [questionFile], sectionId));
		}
		if (swipeFile) {
			promises.push(services.studyService.importProducts(studyId, [swipeFile], sectionId));
		}

		Promise.all(promises)
			.then(() => {
				store.dispatch(
					actions.setTranslationsModal({
						loading: false,
						visible: false,
						error: null,
					}),
				);
				store.dispatch(actions.setTranslationsModal({ loading: false, visible: false, error: null }));

				services.termService.getProductTags().then(productTags => {
					store.dispatch(actions.setProductTags(productTags));
				});

				store.dispatch(actions.fetchSection(sectionId, 'edit'));
				if (audienceCollection?.screeningQuestions) {
					store.dispatch(actions.fetchAudienceCollection(studyId, audienceCollection.id, false));
				}

				toastr.success('Successfully imported all CSV files.');
			})
			.catch(error => {
				toastr.error(csvImportErrorTextFormat);
				store.dispatch(actions.setTranslationsModal({ loading: false, visible: true, error }));
			});
	}
};

const importResponsesWithAnswers = (store, action) => {
	if (action.type === actions.IMPORT_RESPONSES_WITH_ANSWERS) {
		const { studyId, files } = action.payload;

		store.dispatch(actions.setImportRespondentsModal({ loading: true }));
		services.studyService
			.importResponsesWithAnswers(studyId, files)
			.then(() => {
				toastr.success('Successfully imported CSV file.');
				store.dispatch(
					actions.setImportRespondentsModal({
						loading: false,
						content: false,
					}),
				);
			})
			.then(() => store.dispatch(actions.fetchQuestions(studyId)))
			// .then(() => store.dispatch(actions.fetchProducts(studyId)))
			.catch(error => {
				toastr.error(csvImportErrorTextFormat);
				store.dispatch(actions.setImportRespondentsModal({ loading: false, error }));
			});
	}
};

const importFilters = (store, action) => {
	if (action.type === actions.IMPORT_FILTERS) {
		const { studyId, files } = action.payload;
		store.dispatch(actions.setQuestionModal({ loading: true }));
		services.studyService
			.importFilters(studyId, files)
			.then(() => store.dispatch(actions.setQuestionModal({ visible: false, loading: false })))
			.then(() => store.dispatch(actions.fetchQuestions(studyId)))
			.catch(error => store.dispatch(actions.setQuestionModal({ loading: false, error })));
	}
};

const downloadTemplate = (store, action) => {
	if (action.type === actions.DOWNLOAD_TEMPLATE) {
		const { studyId, sectionId } = action.payload;

		services.studyService
			.downloadTemplate(studyId, 'Export - Ideas Template.csv', sectionId)
			.catch(error => store.dispatch(actions.setProductModal({ error })));
	}
};

const exportResponsesWithAnswers = (store, action) => {
	if (action.type === actions.EXPORT_RESPONSES_WITH_ANSWERS) {
		const { studyId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const filename = `Export - Responses With Answers - ${study.name}.csv`;

		services.studyService.exportResponsesWithAnswers(studyId, filename);
	}
};

const exportResponses = (store, action) => {
	if (action.type === actions.EXPORT_RESPONSES) {
		const { studyId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const filename = `Export - Responses - ${study.name}.csv`;

		services.studyService.exportResponses(studyId, filename);
	}
};

const exportInterest = (store, action) => {
	if (action.type === actions.EXPORT_INTEREST) {
		const { studyId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const filename = `Export - Interest - ${study.name}.csv`;

		services.studyService.exportInterest(studyId, filename);
	}
};

const exportCommitment = (store, action) => {
	if (action.type === actions.EXPORT_COMMITMENT) {
		const { studyId } = action.payload;
		const study = selectors.getStudy(store.getState());
		const filename = `Export - Commitment - ${study.name}.csv`;

		services.studyService.exportCommitment(studyId, filename);
	}
};

const exportQuestionAnswers = (store, action) => {
	if (action.type === actions.EXPORT_QUESTION_ANSWERS) {
		const {
			studyId,
			studyName,
			questionType,
			questionId,
			filters,
			collectionId,
			isDemographicQuestions = false,
			filteredDataPoints,
			productId,
			clicks,
			uniqueAudience,
		} = action.payload;
		const study = selectors.getStudy(store.getState());
		const { language } = study;
		const filename = `${studyName} - ${questionType}.csv`;

		const formattedFilters = [];
		let audienceFilter = [];

		Object.keys(filters).forEach(filterQuestionId => {
			const values = filters[filterQuestionId];

			if (filterQuestionId === 'audience') {
				// Audience Filter
				audienceFilter = values;
			} else if (values && values.length) {
				// Normal Filters
				formattedFilters.push({ questionId: filterQuestionId, values });
			} else if (values && values.attributeIds && values.attributeIds.length > 0) {
				// Attribute Filters
				const qId = filterQuestionId.split('-')[0];
				const attributeId = filterQuestionId.split('-')[1];

				formattedFilters.push({
					questionId: qId,
					values: values.value,
					attributeIds: [parseInt(attributeId)],
				});
			} else if (values && values.rank && values.value?.length) {
				formattedFilters.push({
					questionId,
					values: values.value,
					rank: parseInt(values.rank),
				});
			} else if (values && values.min && values.max) {
				// Age Filter
				formattedFilters.push({ questionId: filterQuestionId, ...values });
			}
		});

		// lookup question specific filters
		const localFilters = reportingSelectors.getLocalFilters(store.getState());
		const foundQuestionFilters =
			localFilters && localFilters.find(question => question.pipedQuestionId === questionId.toString());

		const formattedQuestionFilters = foundQuestionFilters ? foundQuestionFilters.questionFilters : [];

		if (isDemographicQuestions) {
			services.studyService.exportDemographicQuestionAnswers(
				studyId,
				questionId,
				formattedFilters,
				formattedQuestionFilters,
				audienceFilter,
				filename,
				language,
				collectionId, // this collectionId here is actually the audienceUuid if it's for demographic question export
			);
		} else {
			services.studyService.exportQuestionAnswers(
				studyId,
				questionId,
				formattedFilters,
				formattedQuestionFilters,
				audienceFilter,
				filename,
				language,
				collectionId,
				filteredDataPoints,
				productId,
				clicks,
				uniqueAudience,
			);
		}
	}
};

const swap = (collection, id0, id1) => {
	let result = collection.slice();
	const intermediate0 = result.filter(item => item.id === id0).pop().sortOrder;
	const intermediate1 = result.filter(item => item.id === id1).pop().sortOrder;
	result = result.map(item => ({
		...item,
		sortOrder: item.id === id0 ? intermediate1 : item.id === id1 ? intermediate0 : item.sortOrder,
	}));
	return result;
};

const swapQuestions = async (store, action) => {
	if (action.type === actions.SWAP_QUESTIONS) {
		const questions = selectors.getQuestions(store.getState());
		const { studyId, q0, q1 } = action.payload;

		/* If we actually do swap */
		if (q0.id !== q1.id) {
			const sortedQuestionsCollection = swap(questions.content, q0.id, q1.id);

			store.dispatch(
				actions.setQuestions({
					loading: true,
					error: null,
					content: sortedQuestionsCollection,
				}),
			);
			try {
				const ids = sortedQuestionsCollection.sort((a, b) => a.sortOrder - b.sortOrder).map(({ id }) => id);

				await services.studyService.updateQuestionsOrder(studyId, ids);
				store.dispatch(actions.fetchQuestions(studyId));
			} catch (error) {
				store.dispatch(actions.setQuestions({ loading: false, error }));
			}
		}
	}
};

function getChangeStatusErrorMessage(serverMessage, action = 'complete') {
	let errorMessage = '';

	switch (serverMessage) {
		case 'AUDIENCES_ARE_NOT_COMPLETED':
			errorMessage +=
				'You cannot complete a study with active audiences. Please stop all audiences and try again. ';
			break;
		default:
			errorMessage = `There was a problem setting the study to ${action}. Please refresh and try again.`;
	}

	return errorMessage;
}

const draftStudy = (store, action) => {
	if (action.type === actions.DRAFT_STUDY) {
		const { id } = action.payload;

		services.studyService
			.changeStatus(id, 'draft')
			.then(() => {
				store.dispatch(actions.fetchStudy(id));
			})
			.catch(error => {
				const serverMessage = error.response.data.message;
				toastr.error(getChangeStatusErrorMessage(serverMessage, 'draft'));
			});
	}
};

const publishStudy = (store, action) => {
	if (action.type === actions.PUBLISH_STUDY) {
		const { id } = action.payload;

		services.studyService
			.changeStatus(id, 'active')
			.then(() => {
				store.dispatch(actions.fetchStudy(id));
			})
			.catch(error => {
				const serverMessage = error.response.data.message;
				toastr.error(getChangeStatusErrorMessage(serverMessage, 'live'));
			});
	}
};

const closeStudy = (store, action) => {
	if (action.type === actions.CLOSE_STUDY) {
		const { id } = action.payload;
		services.studyService
			.changeStatus(id, 'closed')
			.then(() => {
				store.dispatch(actions.fetchStudy(id));
			})
			.catch(error => {
				const serverMessage = error.response.data.message;
				toastr.error(getChangeStatusErrorMessage(serverMessage, 'complete'));
			});
	}
};

/* Access Effects */

/* Fetch access entries */
const fetchAccessEntries = (store, action) => {
	if (action.type === actions.FETCH_ACCESS_ENTRIES) {
		const study = selectors.getStudy(store.getState());
		const clientId = authSelectors.getClientId(store.getState());

		store.dispatch(actions.setAccessEntries({ loading: true }));
		Promise.all([services.clientsService.listInvited(study.id), services.clientsService.getUsers(clientId)])
			.then(([invited, users]) => {
				// TODO - show any users - should be fine.
				const usersWithAccess = users.filter(
					user => user.roleLevel === 2 || user.roleLevel === 3 || user.roleLevel === 4,
				); // since viewers are in invited array
				const sortedUsersWithAccess = usersWithAccess.sort((a, b) => (a.roleLevel < b.roleLevel ? 1 : -1));

				const contentRaw = sortedUsersWithAccess.map(user => ({ ...user, undeletable: true })).concat(invited);
				const content = uniqBy(contentRaw, entity => entity.uuid); // that should not be required, but just in case

				store.dispatch(
					actions.setAccessEntries({
						loading: false,
						content,
						error: null,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setAccessEntries({ loading: false, error }));
			});
	}
};

/* Fetch possible entries */
const fetchPossibleEntries = (store, action) => {
	if (action.type === actions.FETCH_POSSIBLE_ENTRIES) {
		const clientId = authSelectors.getClientId(store.getState());

		store.dispatch(actions.setPossibleEntries({ loading: true }));
		Promise.all([services.clientsService.getUsers(clientId), services.clientsService.getGroups(clientId)])
			.then(([users, groups]) => {
				store.dispatch(
					actions.setPossibleEntries({
						loading: false,
						content: { users, groups },
						error: null,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setPossibleEntries({ loading: false, error }));
			});
	}
};

/* Delete user */
const deleteAccessEntry = (store, action) => {
	if (action.type === actions.DELETE_ACCESS_ENTRY) {
		const { uuid } = action.payload;
		const study = selectors.getStudy(store.getState());

		store.dispatch(actions.setAccessEntries({ loading: true }));
		services.clientsService
			.deleteAccessEntry(study.id, uuid)
			.then(content => {
				store.dispatch(actions.fetchAccessEntries());
			})
			.catch(error => {
				store.dispatch(actions.setAccessCommonError(error));
			});
	}
};

/* Invite user */
const createAccessEntries = (store, action) => {
	if (action.type === actions.CREATE_ACCESS_ENTRIES) {
		// TODO: Look into renaming the uuids property to emails if that's what studies/:id/invite requires
		const { uuids } = action.payload;
		const study = selectors.getStudy(store.getState());
		store.dispatch(actions.setAccessModal({ loading: true }));
		Promise.all(uuids.map(uuid => services.clientsService.createAccessEntry(study.id, { email: uuid })))
			.then(response => {
				store.dispatch(
					actions.setAccessModal({
						visible: false,
						loading: false,
						error: null,
					}),
				);
				store.dispatch(actions.fetchAccessEntries());
			})
			.catch(error => {
				store.dispatch(actions.setAccessModal({ loading: false, error }));
			});
	}
};

const patchStudy = async (store, action) => {
	if (action.type === actions.PATCH_STUDY) {
		try {
			const { id, data } = action.payload;
			await services.studyService.patchStudy(id, data);
			const study = await services.studyService.getStudy(id);
			store.dispatch(actions.setStudy(study.data));
			store.dispatch(reportingActions.setStudy(study.data));
		} catch (error) {
			// store.dispatch(actions.setError(error));
			console.error('error');
		}
	}
};

const patchStudySettings = async (store, action) => {
	if (action.type === actions.PATCH_STUDY_SETTINGS) {
		try {
			const { id, data } = action.payload;
			await services.studyService.patchStudySettings(id, data);
			const study = await services.studyService.getStudy(id);
			store.dispatch(actions.setStudy(study.data));
		} catch (error) {
			// store.dispatch(actions.setError(error));
			console.error('error');
		}
	}
};

// offset - 0
// limit - 10
const fetchAudienceTemplates = (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCE_TEMPLATES) {
		const { search, refetch = false, forceRefetch = false } = payload;

		const storeTemplates = selectors.getAudienceTemplates(store.getState());

		const isLoading = storeTemplates.loading;

		if (isLoading) {
			console.warn('already re-fetching templates, back out!');
			return;
		}
		if (refetch && !storeTemplates.hasMore && !forceRefetch) {
			console.warn('At the end of the list! Not fetching again');
			return;
		}

		const limit = 20;
		let newSearch = search || null;
		let currentOffset = 0;

		if (refetch) {
			currentOffset = storeTemplates.offset || 0;

			newSearch = storeTemplates.search;

			store.dispatch(actions.setAudienceTemplates({ loading: true, error: null }));
		} else {
			store.dispatch(actions.setAudienceTemplates({ loading: true, content: [], error: null }));
		}

		if (forceRefetch) {
			currentOffset = 0;
		}
		services.studySampleService
			.getDemographicGroupTemplates({ searchQuery: newSearch, offset: currentOffset, limit })
			.then(({ data }) => {
				let hasMore = false;
				let newOffset = currentOffset;
				let newContent = data;

				// If we have results, increase offset for next call and keep track of hasMore
				if (data?.marketplaceTemplates?.space?.length) {
					newOffset += data.marketplaceTemplates.space.length;
					hasMore = data.marketplaceTemplates.space.length === limit;
				} else {
					hasMore = false;
				}

				// Refetch/pagination specific
				if (refetch && !forceRefetch) {
					// reset the content to the current state, we're going to append it.
					newContent = { ...(storeTemplates.content || {}) };
					// If we have results, increase offset for next call and keep track of hasMore
					if (data?.marketplaceTemplates?.space?.length) {
						newContent.marketplaceTemplates.space = newContent.marketplaceTemplates.space.concat(
							data.marketplaceTemplates.space,
						);
					}
				}

				store.dispatch(
					actions.setAudienceTemplates({
						content: newContent,
						loading: false,
						offset: newOffset,
						hasMore,
						search: newSearch,
					}),
				);
			})
			.catch(error => {
				console.error(error);
				store.dispatch(actions.setAudienceTemplates({ loading: false, error }));
			});
	}
};
const fetchAudienceTemplatesV2 = (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCE_TEMPLATES_V2) {
		const { search, refetch = false, category = false } = payload;

		const storeTemplates = selectors.getDemographicGroupTemplates(store.getState());

		const isLoading = storeTemplates.loading;

		if (isLoading) {
			console.warn('already re-fetching templates, back out!');
			return;
		}
		if (refetch && !storeTemplates.hasMore) {
			console.warn('At the end of the list! Not fetching again');
			return;
		}

		const limit = 20;
		let newSearch = search || null;
		let currentOffset = 0;

		if (refetch) {
			currentOffset = storeTemplates.offset || 0;

			newSearch = storeTemplates.search;

			store.dispatch(actions.setAudienceTemplatesV2({ loading: true, error: null }));
		} else {
			store.dispatch(actions.setAudienceTemplatesV2({ loading: true, content: [], error: null }));
		}
		services.studySampleService
			.getDemographicGroupTemplates({ searchQuery: newSearch, offset: currentOffset, limit, terms: category })
			.then(({ data }) => {
				let hasMore = false;
				let newOffset = currentOffset;
				let newContent = data;

				// If we have results, increase offset for next call and keep track of hasMore
				if (data?.marketplaceTemplates?.space?.length) {
					newOffset += data.marketplaceTemplates.space.length;
					hasMore = data.marketplaceTemplates.space.length === limit;
				} else {
					hasMore = false;
				}

				// Refetch/pagination specific
				if (refetch) {
					// reset the content to the current state, we're going to append it.
					newContent = { ...(storeTemplates.content || {}) };
					// If we have results, increase offset for next call and keep track of hasMore
					if (data?.marketplaceTemplates?.space?.length) {
						newContent.marketplaceTemplates.space = newContent.marketplaceTemplates.space.concat(
							data.marketplaceTemplates.space,
						);
					}
				}
				store.dispatch(
					actions.setAudienceTemplatesV2({
						content: newContent,
						loading: false,
						offset: newOffset,
						hasMore,
						search: newSearch,
					}),
				);
			})
			.catch(error => {
				console.error(error);
				store.dispatch(actions.setAudienceTemplatesV2({ loading: false, error }));
			});
	}
};

const fetchAudiencePrice = (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCE_PRICE) {
		const { templateId, sampleSize, currency } = payload;
		store.dispatch(
			actions.setAudiencePrice({
				...store.getState().manageStudy.audiencePrice,
				loading: true,
				error: null,
			}),
		);
		services.studySampleService
			.getAudiencePrice(templateId, sampleSize, currency)
			.then(({ data }) => {
				store.dispatch(
					actions.setAudiencePrice({
						content: { ...data, sampleSize },
						loading: false,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setAudiencePrice({ loading: false, error }));
			});
	}
};

const fetchBYOPrice = (store, { type, payload }) => {
	if (type === actions.FETCH_BYO_PRICE) {
		const { sampleSize, currency } = payload;
		store.dispatch(
			actions.setBYOPrice({
				...store.getState().manageStudy.BYOPrice,
				loading: true,
				error: null,
			}),
		);
		services.studySampleService
			.getBYOPrice(sampleSize, currency)
			.then(({ data }) => {
				store.dispatch(
					actions.setBYOPrice({
						content: { ...data, sampleSize },
						loading: false,
					}),
				);
			})
			.catch(error => {
				store.dispatch(actions.setBYOPrice({ loading: false, error }));
			});
	}
};

const createAudience = async (store, { type, payload }) => {
	if (type === actions.CREATE_AUDIENCE) {
		const { studyId, audienceData } = payload;
		const { audienceCollectionId } = audienceData;
		store.dispatch(actions.setAudienceModal({ status: 'loading', error: null }));
		try {
			/* Create product */
			services.studySampleService
				.createAudience(studyId, audienceData)
				.then(async res => {
					store.dispatch(
						actions.setAudienceModal({
							status: 'ready',
						}),
					);
					store.dispatch(actions.fetchAudiences(studyId));
					store.dispatch(actions.fetchStudy(studyId));
					const response = await services.audienceService.getCollection(studyId, audienceCollectionId);
					store.dispatch(actions.setAudienceCollection({ loading: false, content: response?.data || null }));
					toastr.success('Audience successfully created');
				})
				.catch(e => {
					console.error(e);
					toastr.error('There was a problem creating the audience, please try again.');
				});
		} catch (error) {
			toastr.error('There was a problem creating the audience, please try again.');
			store.dispatch(
				actions.setAudienceModal({
					loading: false,
					error: error.response.statusText,
				}),
			);
		}
	}
};

const updateAudience = async (store, { type, payload }) => {
	if (type === actions.UPDATE_AUDIENCE) {
		const { studyId, audienceUuid, audienceData } = payload;

		const patchData = {};
		Object.keys(audienceData).forEach(key => {
			if (audienceData[key] !== null) {
				if (key === 'customPrice') {
					patchData.price = audienceData[key];
				} else if (!(key === 'price' && audienceData.customPrice)) {
					patchData[key] = audienceData[key];
				}
			}
		});

		store.dispatch(actions.setAudienceModal({ status: 'loading', error: null }));

		const apiCalls = [];

		if (Object.keys(patchData).length > 0) {
			apiCalls.push(services.studySampleService.patch(studyId, audienceUuid, patchData));
		}

		await Promise.all(apiCalls).catch(error => {
			store.dispatch(
				actions.setAudienceModal({
					status: 'ready',
					error: error.response.statusText,
				}),
			);
		});

		/* Fetch Audiences after creating */
		store.dispatch(actions.fetchAudiences(studyId));
		store.dispatch(actions.fetchGroup(studyId, audienceUuid));
		const audienceCollection = selectors.getAudienceCollection(store.getState());
		const response = await services.audienceService.getCollection(studyId, audienceCollection?.content?.id);
		store.dispatch(actions.setAudienceCollection({ loading: false, content: response?.data || null }));

	}
};

const patchAudience = (store, { type, payload }) => {
	if (type === actions.PATCH_AUDIENCE) {
		const { audienceUuid, data: requestData, refetchCurrentAudience } = payload;
		const study = selectors.getStudy(store.getState());
		services.studySampleService
			.patch(study.id, audienceUuid, requestData)
			.then(() => {
				if (refetchCurrentAudience) {
					services.studySampleService.getAudience(study.id, audienceUuid).then(({ data }) => {
						store.dispatch(
							actions.setAudience({
								content: data,
								loading: false,
							}),
						);
						store.dispatch(actions.setGroup({ loading: false, content: data }));
					});
				}
				store.dispatch(actions.fetchAudiences(study.id));
			})
			.catch(e => {
				console.error(e);
				toastr.error('There was a problem updating the audience, please try again.');
			});
	}
};

const fetchAudiences = (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCES) {
		const { studyId, callback } = payload;
		store.dispatch(actions.setAudiences({ loading: true, error: null }));
		services.studySampleService
			.getAudiences(studyId)
			.then(({ data }) => {
				store.dispatch(
					actions.setAudiences({
						content: data,
						loading: false,
					}),
				);
				if (callback && typeof callback === 'function') {
					callback(data);
				}
			})
			.catch(error => {
				store.dispatch(actions.setProducts({ loading: false, error }));
			});
	}
};

const fetchGroup = (store, { type, payload }) => {
	if (type === actions.FETCH_GROUP) {
		const { studyId, audienceUuid } = payload;
		const group = selectors.getGroup(store.getState());
		store.dispatch(actions.setGroup({ loading: true, error: null, content: group?.content }));
		services.studySampleService
			.getAudience(studyId, audienceUuid)
			.then(({ data }) => {
				store.dispatch(actions.setGroup({ loading: false, content: data }));
			})
			.catch(error => {
				console.error(error);
				store.dispatch(actions.setGroup({ loading: false, content: null, error }));
			});
	}
};
const createGroupFromScratch = (store, { type, payload }) => {
	if (type === actions.CREATE_GROUP_FROM_SCRATCH) {
		const { studyId, data } = payload;
		store.dispatch(actions.setGroup({ loading: false, content: null }));
		services.audienceService
			.createDemographicGroup(studyId, data)
			.then(response => {
				store.dispatch(actions.setGroup({ loading: false, content: response.data }));
				if (!location?.pathname?.includes('groups')) {
					navigate(`/studies/${studyId}/audiences/groups/${response.data.uuid}`);
				}
			})
			.catch(error => {
				console.error(error);
				store.dispatch(actions.setGroup({ loading: false, content: null, error }));
			});
	}
};

const fetchStudyLoi = async (store, { type, payload }) => {
	if (type === actions.FETCH_LENGTH_OF_INTERVIEW) {
		const { data: lengthOfInterview } = await services.studySampleService.fetchStudyLoi(payload.studyId);
		store.dispatch(actions.setLengthOfInterview(lengthOfInterview));
	}
};

const fetchAudiencesAndUpdatePrices = async (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCES_AND_UPDATE_PRICES) {
		try {
			const { studyId } = payload;
			// fetch audiences
			store.dispatch(actions.setAudiences({ loading: true, error: null }));
			let { data: audiences } = await services.studySampleService.getAudiencesV2(payload.studyId);
			const { data: validations } = await services.studyService.validateStudy(studyId);
			const validationLoi = validations.lengthOfInterview;
			const rounded = val => Math.min(Number(Math.ceil(val / 5) * 5), 15);
			const audiencesToUpdate = audiences.reduce((targetAudiences, aud) => {
				if (
					!['live', 'complete'].includes(aud.status) &&
					aud.provider !== 'BYO' &&
					!aud.sampleTemplateId &&
					aud?.lengthOfInterview &&
					rounded(aud.lengthOfInterview) !== rounded(validationLoi)
				) {
					targetAudiences.push(aud);
				}
				return targetAudiences;
			}, []);

			try {
				await Promise.all(
					audiencesToUpdate.map(audience => {
						const { customQuestions, providerQuestions, estimatedIncidenceRate: incidenceRate } = audience;
						return services.studySampleService.patch(studyId, audience.uuid, {
							lengthOfInterview: validationLoi,
							incidenceRate,
						});
					}),
				);
			} catch (e) {
				console.error(e);
			}

			if (audiencesToUpdate.length) {
				audiences = (await services.studySampleService.getAudiencesV2(payload.studyId)).data;
				const updated = !audiencesToUpdate.every(oldAud => {
					const newAud = audiences.find(a => oldAud.uuid === a.uuid);
					return oldAud.priceInCents === newAud.priceInCents;
				});

				if (updated) {
					store.dispatch(actions.setAudienceNotice('UPDATED'));
				}
			}
			store.dispatch(
				actions.setAudiences({
					content: audiences,
					loading: false,
				}),
			);

			store.dispatch(actions.setStudyValidations(validations));
			store.dispatch(actions.fetchStudyLoi(studyId));
		} catch (error) {
			store.dispatch(actions.setProducts({ loading: false, error }));
		}
	}
};

const editAudience = (store, { type, payload }) => {
	if (type === actions.EDIT_AUDIENCE) {
		store.dispatch(
			actions.setEditAudienceModal({
				visible: true,
				editContent: payload.audienceData,
			}),
		);
	}
};

const launchAudiences = async (store, { type, payload }) => {
	if (type === actions.LAUNCH_AUDIENCES) {
		const { studyId, audienceUuids } = payload;

		for (let i = 0; i < audienceUuids.length; i += 1) {
			try {
				const resp = await services.studySampleService.launchAudience(studyId, audienceUuids[i]);

				if (store.getState().manageStudy.launchAudienceModal.visible) {
					store.dispatch(
						actions.setIndividualLaunchStatus({
							uuid: audienceUuids[i],
							status: 'success',
						}),
					);
				}
				if (i + 1 === audienceUuids.length) {
					store.dispatch(actions.setLaunchAudienceModal({ loading: false }));
				}
			} catch (error) {
				store.dispatch(
					actions.setIndividualLaunchStatus({
						uuid: audienceUuids[i],
						status: 'failed',
					}),
				);
				if (i + 1 === audienceUuids.length) {
					store.dispatch(actions.setLaunchAudienceModal({ loading: false }));
				}
			}
		}
	}
};

const stopAudience = (store, { type, payload }) => {
	if (type === actions.STOP_AUDIENCE) {
		const { studyId, audienceUuid } = payload;
		const audienceCollection = selectors.getAudienceCollection(store.getState());
		store.dispatch(actions.setStopAudienceModal({ loading: true }));
		services.studySampleService
			.stopAudience(studyId, audienceUuid)
			.then(result => {
				const newDemographicGroups = audienceCollection?.content?.demographicGroups.map(group => {
					if (group?.uuid === audienceUuid) {
						return { ...group, status: 'complete' };
					}
					return group;
				});
				store.dispatch(
					actions.setAudienceCollection({
						loading: false,
						content: { ...audienceCollection.content, demographicGroups: newDemographicGroups },
					}),
				);
				store.dispatch(
					actions.setAudienceCollections({
						loading: false,
						content: [{ ...audienceCollection.content, demographicGroups: newDemographicGroups }],
					}),
				);
				store.dispatch(
					actions.setStopAudienceModal({
						loading: false,
						visible: false,
						id: null,
					}),
				);
				store.dispatch(actions.fetchStudy(studyId));
				store.dispatch(
					actions.setLaunchAudienceFeedback({
						error: null,
						success: 'The audience has been successfully stopped.',
					}),
				);
			})
			.catch(error => {
				store.dispatch(
					actions.setStopAudienceModal({
						loading: false,
						visible: false,
						id: null,
					}),
				);
				store.dispatch(
					actions.setLaunchAudienceFeedback({
						error,
						success: null,
					}),
				);
			});
	}
};

const deleteAudience = (store, { type, payload }) => {
	if (type === actions.DELETE_AUDIENCE) {
		const { studyId, audienceUuid } = payload;

		services.studySampleService.deleteAudience(studyId, audienceUuid).then(() => {
			store.dispatch(actions.setGroup({ loading: false, content: null }));
		});
	}
};

const unshareAudience = (store, { type, payload }) => {
	if (type === actions.UNSHARE_AUDIENCE) {
		const { studyId, audienceUuid } = payload;
		services.studySampleService.patch(studyId, audienceUuid, { templateAccessLevel: 'private' }).then(() => {
			store.dispatch(actions.fetchAudienceTemplates());
		});
	}
};

const renameAudience = (store, { type, payload }) => {
	if (type === actions.RENAME_AUDIENCE) {
		const { studyId, audienceUuid, name } = payload;
		services.studySampleService.patch(studyId, audienceUuid, { name });
	}
};

const fetchGeographies = (store, { type }) => {
	if (type === actions.FETCH_GEOGRAPHIES) {
		services.languageService.getGeographies().then(({ data }) => {
			store.dispatch(actions.setGeographies(data));
		});
	}
};

const fetchRoles = (store, action) => {
	if (action.type === actions.FETCH_ROLES) {
		store.dispatch(actions.setRoles({ loading: true }));
		services.clientsService
			.getRoles()
			.then(content => {
				store.dispatch(actions.setRoles({ loading: false, error: null, content }));
			})
			.catch(error => {
				store.dispatch(actions.setRoles({ loading: false, error }));
			});
	}
};

const validateAudiences = async (store, { type, payload }) => {
	if (type === actions.VALIDATE_AUDIENCES) {
		const { studyId } = payload;
		store.dispatch(actions.setLaunchAudienceModal({ visible: true, loading: true }));

		try {
			const validate = await services.studyService.validateStudyAudiences(studyId);

			if (validate && Object.keys(validate.data.validations).length > 0) {
				// if there are any error on the validation

				store.dispatch(
					actions.setLaunchAudienceModal({
						loading: false,
						validationWarning: validate.data.validations.mismatchedBins[0],
					}),
				);
			}
		} catch (err) {
			console.error(err.response);
		}
	}
};

const validateStudy = (store, { type, payload }) => {
	if (type === actions.VALIDATE_STUDY) {
		const { studyId } = payload;
		services.studyService.validateStudy(studyId).then(({ data }) => {
			store.dispatch(actions.setStudyValidations(data));
		});
	}
};

const submitStudyReview = (store, { type, payload }) => {
	if (type === actions.SUBMIT_STUDY_REVIEW) {
		const { studyId, audienceForReview } = payload;
		let needReviewAudiences = [];
		if (audienceForReview) {
			needReviewAudiences = [audienceForReview];
		} else {
			const audiences = selectors.getAudiences(store.getState());
			needReviewAudiences = audiences.content.filter(audience => audience.status === 'needs-review');
		}
		const apiCalls = [];
		needReviewAudiences.forEach(audience =>
			apiCalls.push(services.studySampleService.patch(studyId, audience.uuid, { status: 'in-review' })),
		);

		Promise.all(apiCalls)
			.then(responses => {
				setTimeout(store.dispatch(actions.fetchAudiences(studyId)), 1000);
				store.dispatch(actions.fetchStudy(studyId));
			})
			.catch(err => {
				console.error(err);
			});
	}
};

/**
 * Add User Effect
 */
const addUser = (store, action) => {
	if (action.type === actions.ADD_USER) {
		const { emails, role } = action.payload;
		const clientId = authSelectors.getClientId(store.getState());
		store.dispatch(actions.setAddUserLoading(true));
		Promise.all(emails.map(email => services.clientsService.addUser(clientId, email, role)))
			.then(res => {
				store.dispatch(actions.setAddUserLoading(false));
				store.dispatch(actions.setAddUserComplete(true));
				store.dispatch(actions.fetchUsers());
				setTimeout(() => {
					store.dispatch(actions.setAddUserComplete(false));
					store.dispatch(actions.createAccessEntries(emails));
				}, 0); // }, 2000);
			})
			.catch(error => {
				store.dispatch(actions.setAddUserLoading(false));
			});
	}
};

/**
 * Fetch users for the table
 */
const fetchUsers = (store, action) => {
	if (action.type === actions.FETCH_USERS) {
		const clientId = authSelectors.getClientId(store.getState());

		store.dispatch(actions.setUsers({ loading: true }));
		services.clientsService
			.getUsers(clientId)
			.then(content => {
				store.dispatch(actions.setUsers({ loading: false, content, error: null }));
			})
			.catch(error => {
				store.dispatch(actions.setUsers({ loading: false, error }));
			});
	}
};

const disablePace = (store, { type, payload }) => {
	if (type === actions.DISABLE_PACE) {
		const { studyId, audienceUuid } = payload;

		store.dispatch(actions.setUsers({ loading: true }));
		services.studyService
			.disablePace(studyId, audienceUuid)
			.then(() => {
				store.dispatch(actions.setUsers({ loading: false }));
				store.dispatch(actions.fetchAudiences(studyId));
			})
			.catch(error => {
				store.dispatch(actions.setUsers({ loading: false, error }));
			});
	}
};

/*
 * Link Routing Effects
 */
const postLinkRouting = (store, action) => {
	if (action.type === actions.POST_LINK_ROUTING) {
		const { linkRoutingData } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		const currentSection = selectors.getCurrentSection(store.getState());

		services.linkRoutingService
			.postLinkRouting(study.id, {
				...linkRoutingData,
				studyId: study.id,
				sectionId: currentSection.content.id,
				order: currentSection.content.order,
			})
			.then(res => {
				store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
				store.dispatch(actions.fetchSections(study.id, true));
				store.dispatch(actions.fetchStudyLoi(study.id));
			})
			.catch(err => err);
	}
};

const patchLinkRouting = (store, action) => {
	if (action.type === actions.PATCH_LINK_ROUTING) {
		const { linkRoutingId, linkRoutingData } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		const currentSection = selectors.getCurrentSection(store.getState());

		services.linkRoutingService
			.patchLinkRouting(study.id, linkRoutingId, {
				...linkRoutingData,
				studyId: study.id,
				order: currentSection.content.order,
				sectionId: currentSection.content.id,
			})
			.then(res => {
				// * Push to back of call-stack to avoid async issue
				setTimeout(() => {
					// * Fetch the current section
					if (currentSection.content) {
						store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
						store.dispatch(actions.fetchSections(study.id, true));
						const prevLoi = currentSection.content?.linkRouting[0]?.loi;
						const newLoi = linkRoutingData?.loi;
						if (prevLoi !== newLoi) {
							store.dispatch(actions.fetchStudyLoi(study.id));
						}
					}
				}, 0);
			})
			.catch(err => err);
	}
};

/*
 * Statement Effects
 */
const postStatement = (store, action) => {
	if (action.type === actions.POST_STATEMENT) {
		const { statementData } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		services.statementService
			.postStatement(statementData)
			.then(res => {
				store.dispatch(actions.fetchSection(statementData.sectionId, 'edit', true));
				store.dispatch(actions.fetchSections(study.id, true));
			})
			.catch(err => err);
	}
};

const patchStatement = (store, action) => {
	if (action.type === actions.PATCH_STATEMENT) {
		const { statementId, statementData } = action.payload;
		const state = store.getState();
		const study = selectors.getStudy(state);
		const sections = selectors.getSections(state);
		let shouldFetchLoi;
		if (statementData.translations?.length) {
			const [translation] = statementData.translations;
			if ('assetId' in translation) {
				const statementTranslation = sections.content
					.filter(({ statements }) => statements?.find(({ id }) => id === statementId))
					.map(({ statements }) => statements[0])
					.find(statement => statement.translations?.find(({ id }) => id === translation.id));

				shouldFetchLoi = statementTranslation.assetId !== translation.assetId;
			}
		}

		services.statementService
			.patchStatement(statementId, statementData)
			.then(res => {
				// * Push to back of call-stack to avoid async issue
				setTimeout(() => {
					// * Fetch the current section
					const currentSection = selectors.getCurrentSection(store.getState());
					if (currentSection.content) {
						store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
						store.dispatch(actions.fetchSections(study.id, true));
					}
					if (shouldFetchLoi) {
						store.dispatch(actions.fetchStudyLoi(study.id));
					}
				}, 0);
			})
			.catch(err => err);
	}
};

const setShowDevicePreviewMode = async (store, action) => {
	if (action.type === actions.SHOW_DEVICE_PREVIEW_MODE) {
		const { showDevicePreviewMode } = action.payload;
		const htmlElement = document.getElementsByTagName('html')[0];
		const bodyElement = document.getElementsByTagName('body')[0];
		if (showDevicePreviewMode) {
			htmlElement.classList.add('device-preview-visible');
			bodyElement.classList.add('device-preview-visible');
		} else {
			htmlElement.classList.remove('device-preview-visible');
			bodyElement.classList.remove('device-preview-visible');
		}
	}
};

const postIdea = async (store, action) => {
	if (action.type === actions.POST_IDEA) {
		const { studyId, sectionId, data, shouldClose } = action.payload;
		services.productService.create(studyId, data).then(async res => {
			store.dispatch(actions.fetchSection(sectionId, 'edit', true));
			store.dispatch(actions.fetchSections(studyId, true));
			if (shouldClose) store.dispatch(actions.changeProductModal({ studyId, open: false }));

			services.studyService
				.getAllProducts(studyId)
				.then(products => {
					store.dispatch(actions.setProductsLibrary(products.data.products));
					if (!shouldClose) {
						const lastItem = products.data.products[products.data.products.length - 1];
						store.dispatch(actions.setProductModal({ localProductId: lastItem.localProductId }));
					}
					store.dispatch(actions.fetchStudyLoi(studyId));
				})
				.catch(() => {
					toastr.error('There was an error retrieving the idea library.');
				});
		});
	}
};

const patchIdea = async (store, action) => {
	if (action.type === actions.PATCH_IDEA) {
		const { studyId, productId, data, shouldClose } = action.payload;
		services.productService.patch(studyId, productId, data).then(() => {
			// * Push to back of call-stack to avoid async issue
			setTimeout(() => {
				// * Fetch the current section
				const currentSection = selectors.getCurrentSection(store.getState());
				if (currentSection?.content) {
					store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
					if (shouldClose) store.dispatch(actions.changeProductModal({ studyId, open: false }));
				}
				store.dispatch(actions.fetchStudyLoi(studyId));
			}, 0);
		});
	}
};

const postIdeasFromAI = async (store, action) => {
	if (action.type === actions.POST_IDEAS_FROM_AI) {
		const { studyId, sectionId, ideas, layout } = action.payload;
		const data = {
			ideas,
			sectionId,
		};
		services.productService.postIdeasFromAI(studyId, data).then(() => {
			// * Push to back of call-stack to avoid async issue
			setTimeout(() => {
				// * Fetch the current section
				const currentSection = selectors.getCurrentSection(store.getState());
				if (currentSection?.content) {
					store.dispatch(actions.fetchSection(currentSection.content.id, 'edit', true));
				}
				store.dispatch(actions.fetchStudyLoi(studyId));
			}, 0);
		});
	}
};

const googleTranslateLanguage = (store, action) => {
	if (action.type === actions.GOOGLE_TRANSLATE_LANGUAGE) {
		const { translateData } = action.payload;
		const study = selectors.getStudy(store.getState());

		services.studyService
			.translateStudy(study.id, translateData)
			.then(res => {
				store.dispatch(actions.fetchStudyForTranslation(study.id));
			})
			.catch(err => err);
	}
};

const googleTranslatePreview = (store, action) => {
	if (action.type === actions.GOOGLE_TRANSLATE_PREVIEW) {
		const { translateData } = action.payload;
		if (translateData.sourceLanguageCode.split('_')[0] === translateData.targetLanguageCode.split('_')[0]) {
			toastr.error('Target and Source Language must be different.');
		} else {
			services.studyService
				.previewTranslation(translateData)
				.then(res => {
					store.dispatch(actions.setTranslationPreview(res.data));
				})
				.catch(err => err);
		}
	}
};

const fetchCountries = (store, { type }) => {
	if (type === actions.FETCH_COUNTRIES) {
		services.languageService.getCountries().then(({ data }) => {
			store.dispatch(actions.setCountries(data));
		});
	}
};

const fetchCountryLanguages = (store, { type, payload }) => {
	if (type === actions.FETCH_COUNTRY_LANGUAGES) {
		const { countryId } = payload;
		services.languageService.getCountryLanguages(countryId).then(({ data }) => {
			store.dispatch(actions.setCountryLanguages(data));
		});
	}
};

const setLanguage = async (store, action) => {
	if (action.type === actions.SET_LANGUAGE) {
		const { language } = action.payload;
		const currentReportLanguage = reportingSelectors.getLanguage(store.getState());
		if (language !== currentReportLanguage) {
			store.dispatch(reportingActions.setLanguage(language));
		}
	}
};

const confirmIncidenceRate = async (store, action) => {
	if (action.type === actions.CONFIRM_INCIDENCE_RATE) {
		const { study, audienceUuid, isNewRateAccepted, taxes, creditCardId, paymentMethod, amountPaidByCredits } =
			action.payload;
		const { id: studyId } = study;
		try {
			const resp = await services.studyService.confirmIncidenceRate(
				studyId,
				audienceUuid,
				isNewRateAccepted,
				taxes,
				creditCardId,
				paymentMethod,
				amountPaidByCredits,
			);
			store.dispatch(actions.fetchAudiences(studyId));
		} catch (error) {
			toastr.error('There was an error resolving your audience at this time.');
		}
	}
};

const fetchResponses = (store, { type, payload }) => {
	if (type === actions.FETCH_RESPONSES) {
		const { studyId } = payload;
		services.studyService
			.getResponses(studyId)
			.then(({ data }) => {
				store.dispatch(actions.setResponses(studyId, data));
			})
			.catch(() => {
				toastr.error('There was an error fetching responses.');
			});
	}
};

const setResponseIsValid = (store, { type, payload }) => {
	if (type === actions.SET_RESPONSE_IS_VALID) {
		const { responseId, studyId, isValid } = payload;
		services.studyService.setResponseIsValid(studyId, responseId, isValid).catch(() => {
			toastr.error('There was an error updating response validity.');
		});
	}
};

const downloadExportFilters = (store, { type, payload }) => {
	if (type === actions.DOWNLOAD_EXPORT_FILTERS) {
		const { studyId, studyName } = payload;
		services.studyService.exportFilters(studyId, studyName).catch(() => {
			toastr.error('There was an error downloading filter template.');
		});
	}
};

const restartAudience = (store, { type, payload }) => {
	if (type === actions.RESTART_AUDIENCE) {
		const { studyId, audienceUuid } = payload;
		services.studySampleService.restartAudience(studyId, audienceUuid);
	}
};

const acceptAudiencePrice = (store, { type, payload }) => {
	if (type === actions.ACCEPT_AUDIENCE_PRICE) {
		const { studyId, audienceUuid } = payload;
		services.studyService.confirmIncidenceRate(studyId, audienceUuid, true);
		store.dispatch(actions.fetchAudiences(studyId));
	}
};
const declineAudiencePrice = (store, { type, payload }) => {
	if (type === actions.DECLINE_AUDIENCE_PRICE) {
		const { studyId, audienceUuid } = payload;
		services.studyService.confirmIncidenceRate(studyId, audienceUuid, false);
		store.dispatch(actions.fetchAudiences(studyId));
	}
};

const fetchBaseSizeThreshold = (store, { type }) => {
	if (type === actions.FETCH_BASE_SIZE_THRESHOLD) {
		// TODO: Implement this service call to point to admin setting API
		services.adminService
			.getBaseSizeThreshold()
			.then(baseSize => {
				store.dispatch(actions.setBaseSizeThreshold(baseSize));
			})
			.catch(() => {
				toastr.error('There was an error retrieving the base threshold size.');
			});
	}
};

const fetchAllProducts = (store, { type, payload }) => {
	if (type === actions.FETCH_ALL_PRODUCTS) {
		const { studyId } = payload;
		if (!studyId) return;
		services.studyService
			.getAllProducts(studyId)
			.then(products => {
				store.dispatch(actions.setProductsLibrary(products.data.products));
				services.sections
					.getAll(studyId)
					.then(innerResponse => {
						const content = innerResponse.data;
						content.sort((a, b) => (a.order > b.order ? 1 : -1));
						store.dispatch(actions.setSections({ status: 'ready', content }));
					})
					.catch(error => {
						store.dispatch(actions.setSections({ status: 'error', error }));
					});
			})
			.catch(() => {
				toastr.error('There was an error retrieving the idea library.');
			});
	}
};

const addIdeaIntoSection = (store, { type, payload }) => {
	if (type === actions.ADD_IDEA_INTO_SECTION) {
		const { productIds, studyId, sectionId } = payload;

		services.studyService
			.addIdeaIntoSection(studyId, sectionId, productIds)
			.then(res => {
				store.dispatch(actions.fetchProducts(studyId));
				store.dispatch(actions.fetchCategories());
				store.dispatch(actions.fetchSections(studyId, true));
				store.dispatch(actions.fetchSection(sectionId, 'edit', true));
				store.dispatch(actions.fetchStudyLoi(studyId));
			})
			.catch(e => {
				console.log(e);
				toastr.error('There was an error adding the idea into the section.');
			});
	}
};

const fetchAllAudienceCollection = (store, { type, payload }) => {
	if (type === actions.FETCH_ALL_AUDIENCE_COLLECTION) {
		const studyId = payload;
		const audienceCollection = selectors.getAudienceCollection(store.getState());
		store.dispatch(
			actions.setAudienceCollections({
				loading: true,
				content: audienceCollection?.content ? [audienceCollection?.content] : null,
			}),
		);

		services.audienceService
			.getAllCollections(studyId)
			.then(({ data }) => {
				if (data.length) {
					store.dispatch(actions.setAudienceCollections({ loading: false, content: data }));

					store.dispatch(actions.fetchAudienceCollection(studyId, data[0].id));
					store.dispatch(
						actions.setAudiences({
							content: data[0].demographicGroups,
							loading: false,
						}),
					);
				} else {
					store.dispatch(actions.setAudienceCollections({ loading: false, content: [] }));
					store.dispatch(actions.setAudienceCollection({ loading: false, content: null }));
					store.dispatch(
						actions.setAudiences({
							content: null,
							loading: false,
						}),
					);
				}
			})
			.catch(error => {
				store.dispatch(actions.setAudienceCollections({ loading: false, content: [], error }));
			});
	}
};

const fetchAudienceCollection = (store, { type, payload }) => {
	if (type === actions.FETCH_AUDIENCE_COLLECTION) {
		const { studyId, audienceCollectionId, setLoading } = payload;
		// Make this optional so that it doesn't always set it to loading true and cause a refresh of the screen
		if (setLoading) {
			store.dispatch(
				actions.setAudienceCollection({
					loading: true,
				}),
			);
		}
		services.audienceService
			.getCollection(studyId, audienceCollectionId)
			.then(({ data }) => {
				if (data) {
					const content = { ...data };

					const audienceCollection = selectors.getAudienceCollection(store.getState());

					const placeholderOptionsDictionary = _.fromPairs(
						audienceCollection?.content?.screeningQuestions
							?.map(question => {
								const placeholderOptions = question?.options?.filter(
									({ placeholder = false }) => placeholder,
								);
								if (placeholderOptions?.length) return [question.id, placeholderOptions];
								return [];
							})
							?.filter(pair => pair?.length) ?? [],
					);

					content.screeningQuestions = content.screeningQuestions
						? content.screeningQuestions.map(question => {
								const placeholderOptions = placeholderOptionsDictionary[question.id] || [];
								if (placeholderOptions?.length) {
									question.options = _.orderBy(
										[...question.options, ...placeholderOptions],
										'order',
										'asc',
									);
								}
								return question;
						  })
						: [];

					store.dispatch(actions.setAudienceCollection({ loading: false, content }));
				} else {
					store.dispatch(actions.setAudienceCollection({ loading: false, content: null }));
				}
			})
			.catch(error => {
				store.dispatch(actions.setAudienceCollection({ loading: false, content: null, error }));
			});
	}
};

const updateAudienceCollectionsSingleAudience = (store, { type, payload }) => {
	if (type === actions.UPDATE_AUDIENCE_COLLECTIONS_SINGLE_AUDIENCE) {
		const { content } = selectors.getAudienceCollections(store.getState());
		const newDemographicGroups = content[0]?.demographicGroups?.map(group => {
			if (group?.id === payload?.groupId) {
				return payload.groupData;
			}
			return group;
		});
		const newContent = [{ ...content[0], demographicGroups: newDemographicGroups }];

		store.dispatch(actions.setAudienceCollections({ loading: false, content: newContent }));
		store.dispatch(
			actions.setAudienceCollection({
				loading: false,
				content: { ...content[0], demographicGroups: newDemographicGroups },
			}),
		);
	}
};

const changeScreeningQuestionVisibility = (store, { type, payload }) => {
	if (type === actions.CHANGE_SCREENING_QUESTION_VISIBILITY) {
		const { studyId, questionId, isGlobalQualifier, visibleForAudienceIds, isTemplate } = payload;
		const isBlueprint = window.location.pathname.includes('/templates');
		let content = isBlueprint
			? manageBlueprintSelectors.getAudienceCollection(store.getState())?.content
			: selectors.getAudienceCollection(store.getState())?.content;
		if (isTemplate) {
			content = selectors.getAudienceEditTemplate(store.getState())?.content;
		}
		const audienceCollectionId = content?.id;
		services.questionService
			.setScreeningQuestionVisibility(
				studyId,
				questionId,
				visibleForAudienceIds ? { isGlobalQualifier, visibleForAudienceIds } : { isGlobalQualifier },
			)
			// update collection after sending data to API
			.then(() => {
				services.audienceService.getCollection(studyId, audienceCollectionId).then(response => {
					if (isTemplate) {
						store.dispatch(
							actions.setAudienceEditTemplate({
								loading: false,
								content: response?.data || null,
							}),
						);
					} else {
						store.dispatch(
							actions.setAudienceCollection({
								loading: false,
								content: response?.data || null,
							}),
						);
					}
				});
			})
			.catch(error => {
				toastr.error(`Error changing screening question visibility: ${error}`);
			});
	}
};

const fetchStudyFiles = async (store, { type, payload }) => {
	if (type === actions.FETCH_STUDY_FILES) {
		const { studyId, addLoading = false } = payload;
		const studyFiles = selectors.getStudyFiles(store.getState());
		try {
			if (addLoading) {
				store.dispatch(
					actions.setStudyFiles({
						loading: true,
						content: studyFiles?.content || [],
						error: null,
					}),
				);
			}
			const response = await services.studySampleService.getStudyAllFiles(studyId);
			const localFiles = localStorage.getItem('userFiles');
			let newFiles = [];
			if (localFiles && localFiles !== '[]') {
				const userFiles = JSON.parse(localFiles);
				response.data.forEach(file => {
					const oldFile = userFiles.find(f => f.id === file.id);
					newFiles.push({ ...file, logs: file.logs, isNew: !!oldFile });
				});
			} else {
				newFiles = response.data.map(f => ({ ...f, logs: f.logs, isNew: true })) || [];
				localStorage.setItem('userFiles', JSON.stringify(newFiles));
			}

			store.dispatch(
				actions.setStudyFiles({
					loading: false,
					content: newFiles,
				}),
			);
		} catch (error) {
			store.dispatch(
				actions.setStudyFiles({
					loading: false,
					error: error?.data,
				}),
			);
			console.error(error);
		}
	}
};

export default [
	performNewInitialFetch,
	performInitialFetch,
	fetchBaseSizeThreshold,
	fetchStudy,
	fetchStudyForTranslation,
	fetchLanguages,
	fetchCategories,
	fetchProducts,
	updateStudy,
	createProduct,
	updateProduct,
	patchProduct,
	deleteProduct,
	duplicateProduct,
	createQuestion,
	fetchQuestion,
	fetchQuestionAndDuplicate,
	fetchQuestions,
	updateQuestion,
	deleteQuestion,
	fetchProductWhenModalSet,
	importImages,
	exportProducts,
	importProducts,
	importFilters,
	downloadTemplate,
	exportResponses,
	exportResponsesWithAnswers,
	importResponsesWithAnswers,
	exportInterest,
	exportCommitment,
	exportQuestionAnswers,
	swapQuestions,
	draftStudy,
	publishStudy,
	closeStudy,
	fetchAccessEntries,
	fetchPossibleEntries,
	createAccessEntries,
	deleteAccessEntry,
	patchStudy,
	patchStudySettings,
	disablePace,

	// Audience
	fetchAudienceTemplates,
	fetchAudienceTemplatesV2,
	fetchAudiencePrice,
	fetchBYOPrice,
	fetchGroup,
	createGroupFromScratch,
	createAudience,
	updateAudience,
	patchAudience,
	fetchAudiences,
	editAudience,
	stopAudience,
	launchAudiences,
	validateAudiences,
	deleteAudience,
	unshareAudience,
	renameAudience,
	fetchAudiencesAndUpdatePrices,

	validateStudy,

	fetchGeographies,

	fetchRoles,

	addUser,
	fetchUsers,
	submitStudyReview,

	// Statement Effects
	postStatement,
	patchStatement,

	// Link Routing Effects
	postLinkRouting,
	patchLinkRouting,

	// Translation Modal
	downloadStatementCsv,
	downloadQuestionCsv,
	downloadSwipeCsv,
	importCsvFiles,

	// Device Preview
	setShowDevicePreviewMode,
	reorderStudyTranslations,

	// Translate Languages
	googleTranslateLanguage,
	googleTranslatePreview,

	// Country & Language
	fetchCountries,
	fetchCountryLanguages,

	// Ideas
	postIdea,
	patchIdea,
	postIdeasFromAI,

	// Incidence rate
	confirmIncidenceRate,

	// Language
	setLanguage,

	// Responses
	fetchResponses,
	setResponseIsValid,

	downloadExportFilters,

	restartAudience,
	acceptAudiencePrice,
	declineAudiencePrice,

	// Idea library
	fetchAllProducts,
	addIdeaIntoSection,
	fetchStudyLoi,

	// Audience Collection
	fetchAllAudienceCollection,
	fetchAudienceCollection,
	updateAudienceCollectionsSingleAudience,

	// Screening Question Visibility
	changeScreeningQuestionVisibility,

	// Study Files
	fetchStudyFiles,

	...sectionEffects,
	...questionEffects,
];
